<template>
  <div>
    <div class="text-center">
      <div v-if="isCompleted">
        <h4>{{ operationLabel }} is completed!</h4>
        <ElButton
          v-if="completedResult && completedResult.result_file"
          type="primary"
          @click="download"
        >
          Download
        </ElButton>
      </div>

      <div v-else-if="isFailed">
        <h4>{{ operationLabel }} has failed.</h4>
        <h4>
          Please try again or contact
          <a :href="`mailto:${contactEmail}`" target="_blank">
            {{ contactEmail }}
          </a>
          for help
        </h4>
      </div>

      <div v-else>
        <h4>{{ operationLabel }} is in progress.</h4>
        <DrLoader />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrLoader from "@shared/ui/dr-loader";

import { APP_SETTINGS } from "@setups/data";
import { Urls } from "@setups/urls";
import {
  AsyncOperationStatuses,
  UserAsyncOperationApiService,
} from "@drVue/store/modules/room/user-async-operation/AsyncOperationApiService";

import type { UserAsyncOperationResult } from "@drVue/store/modules/room/user-async-operation/AsyncOperationApiService";
import type { PropType } from "vue";

export async function pollAsyncOperation(operation_uid: string) {
  const apiService = new UserAsyncOperationApiService();
  const maxFailedCount = 20;
  const pollInterval = 5 * 1000; // ms

  let failedCount = 0;

  const pollFunction = async function (resolve: Function, reject: Function) {
    let data;
    try {
      data = await apiService.getDetails(operation_uid);
      if (data.status == AsyncOperationStatuses.Success) {
        return resolve(data);
      }
      if (data.status == AsyncOperationStatuses.Fail) {
        return reject(data);
      }
      failedCount = 0;
    } catch (err) {
      failedCount += 0;
      if (failedCount > maxFailedCount) {
        return reject(err);
      }
    }

    setTimeout(pollFunction, pollInterval, resolve, reject);
  };
  return new Promise<UserAsyncOperationResult>(pollFunction);
}

interface Data {
  isCompleted: boolean;
  completedResult: UserAsyncOperationResult | null;
  isFailed: boolean;
  contactEmail: any;
}

export default defineComponent({
  name: "VueAsyncOperationDialogContent",
  components: { DrLoader },
  props: {
    operationUid: { required: true, type: String as PropType<string> },
    operationLabel: { required: true, type: String as PropType<string> },
  },
  emits: ["close"],
  data(): Data {
    return {
      isCompleted: false,
      completedResult: null,
      isFailed: false,
      contactEmail: APP_SETTINGS.CONTACT_EMAIL,
    };
  },
  mounted() {
    pollAsyncOperation(this.operationUid).then(
      (result) => {
        this.isCompleted = true;
        this.completedResult = result;
      },
      () => {
        this.isFailed = true;
      },
    );
  },
  methods: {
    closeDialog() {
      window.location.reload();
    },
    download: function () {
      const url = Urls["api:operations-download-result"](this.operationUid);
      window.open(url, "_blank");
      this.$emit("close");
    },
  },
});
</script>
