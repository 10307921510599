import { get } from "lodash-es";
import { defineComponent } from "vue";

import { $notifyDanger } from "@drVue/common";

import type { Dictionary } from "@drVue/types";

export interface BackendErrors {
  [key: string]: string[] | BackendErrors;
}

export interface Data {
  isChanged: boolean;
  isSubmitting: boolean;

  backendErrors: BackendErrors;
  validationErrorCodes: number[];
}

export type SubmitPromiseType = <T>(
  promise: Promise<T>,
  errorMessage?: string,
) => Promise<T>;

export default defineComponent({
  data: function (): Data {
    return {
      isChanged: false,
      isSubmitting: false,

      backendErrors: {},
      validationErrorCodes: [400],
    };
  },
  methods: {
    $deleteNested(obj: Dictionary<any>, path: string): void {
      const segments = path.split(".");

      let segment;
      if (segments.length > 1) {
        segment = segments.pop();
        path = segments.join(".");
        obj = get(obj, path);
      } else {
        segment = path;
      }

      if (obj !== undefined && typeof segment === "string") {
        delete obj[segment];
      }
    },
    getBackendError(fieldPath: string): string {
      const value = get(this.backendErrors, fieldPath);
      if (!Array.isArray(value)) return "";

      return value.join("\n");
    },
    resetBackendError(fieldPath: string): void {
      delete this.backendErrors["non_field_errors"];
      this.$deleteNested(this.backendErrors, fieldPath);
    },
    setBackendErrors(errors: BackendErrors): void {
      this.backendErrors = errors;
    },
    submitPromise<T>(
      promise: Promise<T>,
      errorMessage: string = "Failed to submit changes.",
    ): Promise<T> {
      this.isSubmitting = true;

      return promise
        .catch((error) => {
          if (
            error.response &&
            this.validationErrorCodes.includes(error.response.status)
          ) {
            this.setBackendErrors(error.response.data);
          } else {
            $notifyDanger(errorMessage);
          }

          return Promise.reject(error);
        })
        .finally(() => (this.isSubmitting = false)) as Promise<T>;
    },
    submitAction(
      type: string,
      payload?: any,
      errorMessage?: string,
    ): Promise<any> {
      const promise = this.$store.dispatch(type, payload);

      return this.submitPromise(promise, errorMessage);
    },
  },
});
