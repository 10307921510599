<template>
  <ElSelect
    :model-value="row._selectedGroup"
    :disabled="!row._isChecked"
    @change="onChange"
    placeholder="Select room first"
    value-key="id"
    class="el-select--full-width"
    filterable
  >
    <ElOption
      v-for="group in row.groups"
      :key="group.id"
      :label="group.name"
      :value="group"
    />
  </ElSelect>
</template>

<script setup lang="ts">
import { pinia } from "@app/vue/store/pinia";
import { type SelectableRoom, useRoomGroupsStore } from "./store";

import type { RoomGroup } from "@app/vue/store/modules/client-dashboard/deals/types";

interface Props {
  row: SelectableRoom;
}

const props = defineProps<Props>();

const roomGroupsStore = useRoomGroupsStore(pinia);

const onChange = (group: RoomGroup) => {
  roomGroupsStore.selectRoomGroup(props.row.id, group);
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
</style>
