import { ApiService } from "@drVue/api-service";

export interface BaseCategory {
  id: number;
  is_archived: boolean;
  key: string;
  name: string;
  order: number;
  parent_id: number | null;
  uid: string;
}

export interface Category extends BaseCategory {
  parent?: Category;
  parents: Category[];
  get_name: string;
  full_path: string;
  categories: Category[];
  descendants: number[];
  treePosition: number;
  expanded: boolean;
}

export class CategoriesApiService extends ApiService {
  public async getAllCategories() {
    const url = this.Urls["api:room:categories"]();
    return this.get<Category[]>(url);
  }

  public getArchivedCategories() {
    const url = this.Urls["api:room:categories_archived"]();
    return this.get<Category[]>(url);
  }

  public editCategory(categoryId: number, category: Partial<Category>) {
    const url = this.Urls["api:room:category_detail"](categoryId);
    return this.post<Category>(url, category);
  }

  public createCategory(name: string, parentId?: Category["parent_id"]) {
    const url = this.Urls["api:room:categories"]();
    return this.post<Category>(url, { name, parent_id: parentId });
  }
}
