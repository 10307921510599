const COLUMNS = [
  {
    title: "",
    columns: [
      { title: "Group", data_index: "group_name", width: 20 },
      { title: "Company", data_index: "company_name", width: 20 },
      { title: "User", data_index: "user_name", width: 24 },
      { title: "Type", data_index: "type", width: 10 },
      {
        title: "Logins",
        data_index: "user_room_session_begin:total",
        width: 12,
      },
      {
        title: "Time (min)",
        data_index: "user_room_session_begin,task_viewed,document_viewed:time",
        width: 12,
        float_precision: 0,
      },
    ],
  },
  {
    title: "Requests Viewed",
    columns: [
      { title: "Unique", data_index: "task_viewed:uniq", width: 12 },
      { title: "Total", data_index: "task_viewed:total", width: 12 },
    ],
  },
  {
    title: "Documents Viewed",
    columns: [
      { title: "Unique", data_index: "document_viewed:uniq", width: 12 },
      { title: "Total", data_index: "document_viewed:total", width: 12 },
    ],
  },
  {
    title: "Documents Uploaded",
    columns: [
      {
        title: "Unique",
        data_index: "document_upload,document_overwrite:uniq",
        width: 12,
      },
      {
        title: "Total",
        data_index: "document_upload,document_overwrite:total",
        width: 12,
      },
    ],
  },
  {
    title: "Documents Downloaded",
    columns: [
      {
        title: "Unique",
        data_index: "document_download,document_bulk_download:uniq",
        width: 12,
      },
      {
        title: "Total",
        data_index: "document_download,document_bulk_download:total",
        width: 12,
      },
    ],
  },
];

export default COLUMNS;
