import { brightness, hexToRgb } from "@drVue/utils/color";

import type { TaskStatus } from "./../types";

const ELEMENT_ID = "dealroom-tasks-statuses";

function getOrCreateStyleElement() {
  let styleElement: null | HTMLElement = document.getElementById(ELEMENT_ID);

  if (!styleElement) {
    styleElement = document.createElement("style");
    styleElement.id = ELEMENT_ID;

    const head = document.querySelector("head");
    if (!head) throw "<head> element was not found in the document.";

    head.appendChild(styleElement);
  }

  return styleElement;
}

function getStyleElementContent(list: TaskStatus[]) {
  let cssStyles = "";
  list.forEach(function (status) {
    const rgbColor = hexToRgb(status.color);
    const darken = brightness(rgbColor, -0.25);

    cssStyles += `.${status.colorClass} { --status-color: ${status.color}; --darken-status-color: ${darken}; color: ${status.color} !important; }`;
    cssStyles += `.${status.bgClass} { --status-color: ${status.color}; --darken-status-color: ${darken}; background-color: ${status.color} !important; }`;

    // for dr-status-ico
    const klass = `.dr-status-ico__container__${status.id}`;
    const statusBgColor = `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 0.24)`;
    cssStyles += `${klass} { background-color: ${statusBgColor} !important; }`;
    cssStyles += `${klass} .dr-status-ico__core { background-color: ${status.color} !important; }`;
  });
  return cssStyles;
}

export default function updateStyleElement(list: TaskStatus[]) {
  const element = getOrCreateStyleElement();
  element.innerHTML = getStyleElementContent(list);
}
