import { Toasts } from "@shared/ui/dr-alert/service";

import { $notifyWarning } from "@drVue/common";

const TOAST_TAG = "online-offline_toast";
let _isOnline = true;
let _isInitialized = false;

export function isOnline() {
  return _isOnline;
}

export function setupOfflineAlert() {
  if (_isInitialized) {
    return;
  }
  _isInitialized = true;

  window.addEventListener("online", () => {
    _isOnline = true;
    Toasts.removeByTag("online-offline_toast");
  });

  window.addEventListener("offline", () => {
    _isOnline = false;
    Toasts.removeByTag("online-offline_toast");
    $notifyWarning(
      "You appear to be offline right now. Some functionality may be unavailable until you come back online.",
      {
        tag: TOAST_TAG,
        duration: 0,
      },
    );
  });
}
