<template>
  <div>
    <ElForm
      ref="loadCodeForm"
      :model="loadCodesModel"
      :disabled="isSubmitting"
      :label-position="'top'"
      @submit.prevent="loadCodes"
    >
      <ElFormItem
        label="Confirmation token"
        prop="token"
        :required="true"
        :error="getBackendError('token')"
      >
        <ElInput v-model="loadCodesModel.token" />
      </ElFormItem>

      <ElAlert
        v-if="getBackendError('non_field_errors')"
        :title="getBackendError('non_field_errors')"
        type="error"
        show-icon
        :closable="false"
      />

      <ElFormItem>
        <ElButton
          type="primary"
          :disabled="!!recoveryCodes"
          :loading="isSubmitting"
          @click="loadCodes"
        >
          Load recovery codes
        </ElButton>
      </ElFormItem>
    </ElForm>
    <div v-if="!!recoveryCodes">
      <br />
      <p>Message with recovery codes:</p>
      <p>
        <code style="white-space: pre-line">
          {{ recoveryCodesMsg }}
        </code>
      </p>
      <div>
        <ElButton type="primary" size="small" @click="copyCodesToClipboard()">
          Copy to Clipboard
        </ElButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrForm from "@shared/ui/dr-form";

import { ManagementApiService } from "@drVue/api-service/management";
import { $notifyDanger, $notifySuccess, copyToClipboard } from "@drVue/common";

import type {
  MFALoadRecoveryCodes,
  MFALoadRecoveryCodesResp,
} from "@drVue/api-service/management";
import type { SubmitPromiseType } from "@shared/ui/dr-form";
import type { PropType } from "vue";

interface Data {
  loadCodesModel: MFALoadRecoveryCodes;
  api: ManagementApiService;
  recoveryCodes: string[] | null;
}

export default defineComponent({
  name: "MFARecoveryLoadCodes",
  extends: DrForm,
  props: {
    userId: { required: true, type: Number as PropType<number> },
  },
  data(): Data {
    return {
      loadCodesModel: {
        token: "",
      },
      api: new ManagementApiService(),
      recoveryCodes: null,
    };
  },
  computed: {
    recoveryCodesMsg(): string {
      if (!this.recoveryCodes) {
        return "";
      }
      const codes = this.recoveryCodes.join(", ");
      return (
        `Here are your one-time recovery codes: ${codes}.` +
        `\nOnce you log in, please reset your codes from Settings>Security and also update your two-factor authentication settings.` +
        `\nIf you have any questions please let me know!`
      );
    },
  },
  methods: {
    copyCodesToClipboard() {
      if (copyToClipboard(this.recoveryCodesMsg)) {
        $notifySuccess("Codes have been copied.");
      } else {
        $notifyDanger("Failed to copy codes to clipboard.");
      }
    },
    loadCodes() {
      (this.$refs["loadCodeForm"] as any).validate((valid: boolean) => {
        if (!valid) {
          return false;
        }

        this.setBackendErrors({});
        const promise = this.api.loadMFARecoveryCodes(
          this.userId,
          this.loadCodesModel,
        );
        return (this.submitPromise as SubmitPromiseType)(
          promise,
          "Failed to load recovery codes",
        ).then((resp: MFALoadRecoveryCodesResp) => {
          this.recoveryCodes = resp.codes;
        });
      });
    },
  },
});
</script>
