<template>
  <ElButtonGroup>
    <ElButton
      type="danger"
      size="small"
      class="dr-vxe-cell__content-row-hover-visible"
      title="Revoke rooms access"
      :disabled="memberships.length === 0"
      @click.prevent.stop="revoke"
    >
      <DrIcon name="ban" />
    </ElButton>
    <ElButton
      v-if="isAdmin"
      type="danger"
      size="small"
      class="dr-vxe-cell__content-row-hover-visible"
      title="Delete"
      @click.prevent.stop="remove"
    >
      <DrIcon name="trash" />
    </ElButton>
  </ElButtonGroup>
</template>

<script setup lang="ts">
import { ElMessageBox } from "element-plus";
import DrIcon from "@shared/ui/dr-icon";

import { DashboardPeopleTableEvent, insightTrack } from "@app/insight";
import { ORG_MEMBER_DATA } from "@app/setups";
import { $notifyDanger, $notifySuccess } from "@drVue/common";
import DrStore from "@drVue/store";

import type { MembershipInfo } from "@drVue/components/client-dashboard/users/UsersTable/types";
import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";

interface Props {
  user: OrgUser;
  memberships: MembershipInfo[];
}

const props = defineProps<Props>();

const isAdmin = ORG_MEMBER_DATA.group.is_administrator;

const SUCCESS = { success: "true" };
const FAILURE = { success: "false" };

const revoke = () => {
  const prefix = props.user.name ? `User ${props.user.name}` : "Invite";

  ElMessageBox.confirm(
    `${prefix} <${props.user.email}> will be removed from all your rooms except owned rooms. Be sure you are an admin of these rooms as well to proceed with revoking access.`,
    "Revoke rooms access",
    {
      confirmButtonClass: "el-button--danger",
      confirmButtonText: "Revoke",
      cancelButtonText: "Cancel",
      type: "warning",
    },
  ).then(() => {
    DrStore.dispatch("clientDashboard/orgUsers/bulkRevokeAccess", props.user)
      .then(() => {
        insightTrack(DashboardPeopleTableEvent.Revoke, SUCCESS);

        $notifySuccess("Rooms access was successfully revoked");
      })
      .catch((error) => {
        insightTrack(DashboardPeopleTableEvent.Revoke, FAILURE);

        $notifyDanger(`Failed to revoke access ${prefix}`);
        throw error;
      });
  });
};

const remove = () => {
  ElMessageBox.confirm(
    "This record will be deleted from the People tab and all associated deals (if any). Deleted records cannot be restored. Ensure the person has no access to rooms or pipelines before proceeding with deletion.",
    "Delete",
    {
      confirmButtonClass: "el-button--danger",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      type: "warning",
    },
  ).then(() => {
    DrStore.dispatch("clientDashboard/orgUsers/remove", props.user.id)
      .then(() => {
        insightTrack(DashboardPeopleTableEvent.Remove, SUCCESS);

        $notifySuccess("User was successfully removed");
      })
      .catch((r) => {
        insightTrack(DashboardPeopleTableEvent.Remove, FAILURE);

        if (r.response.data.non_field_errors) {
          $notifyDanger(r.response.data.non_field_errors.join("\n"));
        } else {
          $notifyDanger("Failed to remove user");
        }
      });
  });
};
</script>
