import analyticsCardLinechartHtml from "./index.html?raw";

Controller.$inject = [
  "DocumentsService",
  "ActivityCategoriesService",
  "ActivityLabelService",
];
angular
  .module("dealroom.analytics.card.linechart.tasks", [
    "dealroom.analytics.service.data",
    "dealroom.analytics.service.labels",
    "dealroom.documents",
  ])
  .component("drAnalyticsRequestsRelatedDocumentsActivityCard", {
    template: analyticsCardLinechartHtml,
    controller: Controller,
  });

function Controller(
  DocumentsService,
  ActivityCategoriesService,
  ActivityLabelService,
) {
  const $ctrl = this;

  $ctrl.title = "Linked Documents Activity";
  $ctrl.displayCount = 4;
  $ctrl.metricsTypes = [
    {
      title: "Most Viewed Documents",
      getY,
      label: "Views",
      verbs: ["document_viewed"],
      default: true,
      totalLabels: ["view", "views"],
    },
    {
      title: "Most Time Spended Documents",
      getY,
      label: "Time Spent",
      verbs: ["document_viewed"],
      value: (d) => d.time_spent,
      totalLabels: "min",
    },
    {
      title: "Most Downloaded Documents",
      getY,
      label: "Downloads",
      verbs: ["document_download", "document_bulk_download"],
      totalLabels: ["download", "downloads"],
    },
    {
      title: "Most Uploaded Documents",
      getY,
      label: "Uploads",
      verbs: ["document_upload", "document_overwrite"],
      totalLabels: ["upload", "uploads"],
    },
  ];

  $ctrl.filterBy = "requests";
  $ctrl.getYList = getYList;

  function getYList() {
    return ActivityCategoriesService.attachments.map((key) => ({
      key,
      title: getTitle(key),
    }));
  }

  function getTitle(docId) {
    const doc = DocumentsService.Files[docId];
    return `${doc.treeIndex} ${doc.name}`;
  }

  function getY(d) {
    return ActivityLabelService.itemId(d, "document");
  }
}
