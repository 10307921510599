import { defineStore } from "pinia";

import { synergiesApi } from "@drVue/store/modules/room/synergies/SynergiesApiService";
import { generateList } from "@drVue/store/pinia/helpers/generators";

import type {
  CreateSynergyPayload,
  Synergy,
  UpdateSynergyPayload,
} from "@drVue/store/modules/room/synergies/SynergiesApiService";

export const useSynergiesStore = defineStore("synergies", () => {
  const { list, dict, load, create, update, remove, isLoading, isLoadError } =
    generateList<Synergy, CreateSynergyPayload, UpdateSynergyPayload>({
      name: "Synergies",
      load: synergiesApi.loadSynergies.bind(synergiesApi),
      create: synergiesApi.createSynergy.bind(synergiesApi),
      update: synergiesApi.updateSynergy.bind(synergiesApi),
      remove: synergiesApi.deleteSynergy.bind(synergiesApi),
    });

  return {
    list,
    dict,
    load,
    create,
    update,
    remove,
  };
});
