<template>
  <div class="user-room-tasks">
    <UserRoomTasksFilter
      v-model="filters"
      :number-of-tasks="filteredTasks.length"
    />
    <h1 v-if="isDataLoadError">Failed to load user requests information.</h1>
    <div v-else class="user-room-tasks__wrapper">
      <UserRoomTasksTable :loading="isDataLoading" :tasks="filteredTasks" />
    </div>
  </div>
</template>

<script lang="ts">
import { debounce } from "lodash-es";
import { defineComponent } from "vue";

import { ParticipantsType } from "@app/vue/components/room/tasks/participants/TaskParticipantsList.vue";
import { $notifyDanger, isStringContains } from "@drVue/common";
import { OrgUsersApiService } from "@drVue/store/modules/client-dashboard/org-users/OrgUsersApiService";
import UserRoomTasksFilter from "./Filter/UserRoomTasksFilter.vue";
import UserRoomTasksTable from "./UserRoomTasksTable.vue";

import type { UserParticipantTaskData } from "@drVue/store/modules/client-dashboard/org-users/OrgUsersApiService";
import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { PropType } from "vue";

const api = new OrgUsersApiService();

export interface UserTasksFilters {
  search: string;
  priorities: string[];
  rooms: number[];
  statuses: number[];
  roles: string[];
  due_date: [Date, Date] | [];
}

interface Data {
  tasks: UserParticipantTaskData[];
  filteredTasks: UserParticipantTaskData[];
  isDataLoading: boolean;
  isDataLoadError: boolean;
  filters: UserTasksFilters;
}

export default defineComponent({
  name: "UserRoomTasksList",
  components: {
    UserRoomTasksTable,
    UserRoomTasksFilter,
  },
  props: {
    user: { required: true, type: Object as PropType<OrgUser> },
  },
  data(): Data {
    return {
      tasks: [],
      filteredTasks: [],
      isDataLoading: false,
      isDataLoadError: false,
      filters: {
        search: "",
        priorities: [],
        rooms: [],
        statuses: [],
        roles: [],
        due_date: [],
      },
    };
  },
  computed: {
    isLoading(): boolean {
      return (
        this.isDataLoading ||
        this.$store.state.room.tasksStatuses.isLoading === true
      );
    },
  },
  watch: {
    tasks() {
      this.debouncedUpdateFilteredTasks();
    },
    filters: {
      handler() {
        this.debouncedUpdateFilteredTasks();
      },
      deep: true,
    },
  },
  beforeMount() {
    this.loadTasks();
    this.checkStatuses();
  },
  methods: {
    debouncedUpdateFilteredTasks: debounce(function (this: any) {
      this.updateFilteredTasks();
    }, 200),
    updateFilteredTasks() {
      const hasSearchText = (task: UserParticipantTaskData) =>
        !this.filters.search ||
        isStringContains(this.filters.search, task.title) ||
        isStringContains(this.filters.search, task.key);

      const hasSelectedPriority = (task: UserParticipantTaskData) =>
        !this.filters.priorities.length ||
        this.filters.priorities.includes(task.priority);

      const hasSelectedRoom = (task: UserParticipantTaskData) =>
        !this.filters.rooms.length || this.filters.rooms.includes(task.room_id);

      const hasSelectedStatus = (task: UserParticipantTaskData) =>
        !this.filters.statuses.length ||
        this.filters.statuses.includes(task.status_id);

      const hasSelectedRole = (task: UserParticipantTaskData) => {
        const hasAssigneeSelected =
          this.filters.roles.includes(ParticipantsType.Assignee) &&
          (task.is_assignee_user || task.is_assignee_group);
        const hasFollowerSelected =
          this.filters.roles.includes(ParticipantsType.Follower) &&
          task.is_follower_user;
        const hasReviewerSelected =
          this.filters.roles.includes(ParticipantsType.Reviewer) &&
          task.is_reviewer_user;

        return (
          !this.filters.roles.length ||
          hasAssigneeSelected ||
          hasFollowerSelected ||
          hasReviewerSelected
        );
      };

      this.filteredTasks = this.tasks.filter(
        (task) =>
          hasSearchText(task) &&
          hasSelectedPriority(task) &&
          hasSelectedRoom(task) &&
          hasSelectedStatus(task) &&
          hasSelectedRole(task),
      );
    },
    async loadTasks() {
      if (this.isDataLoading) {
        return;
      }
      this.isDataLoading = true;
      try {
        this.tasks = await api.roomsTasks(this.user.id);
      } catch (err) {
        this.isDataLoadError = true;
        $notifyDanger("Failed to load user requests information");
        throw err;
      } finally {
        this.isDataLoading = false;
      }
    },
    checkStatuses() {
      if (!this.$store.state.room.tasksStatuses.list.length) {
        this.$store.dispatch("room/tasksStatuses/syncAll");
      }
    },
  },
});
</script>
