export type DigitGrouping = "space" | "dot" | "comma";

const spaceFormatter = new Intl.NumberFormat("en-FI").format;

const formatterMap: Record<DigitGrouping, (value: number) => string> = {
  space: (value: number) => spaceFormatter(value).replace(",", "."),
  comma: new Intl.NumberFormat("en-US").format,
  dot: new Intl.NumberFormat("en-DE").format,
};

export const numberFormat = (
  value: number,
  symbol: string,
  digitGrouping: DigitGrouping,
): string => {
  return `${symbol}${formatterMap[digitGrouping](value)}`;
};
