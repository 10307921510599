<template>
  <DrTooltip :content="assigneeNames">
    <ViewFieldWrapper
      :is-read-only="!!viewProps.schema.isReadOnly"
      @edit="viewProps.enterEditMode"
    >
      <ParticipantsView :view-props="viewPropsWithAvatars" />
      <span v-if="assignees.length === 1">{{ assigneeNames }}</span>
    </ViewFieldWrapper>
  </DrTooltip>
</template>

<script setup lang="ts">
import { computed } from "vue";
import DrTooltip from "@shared/ui/dr-tooltip/DrTooltip.vue";

import { DrStore } from "@app/vue";
import { ParticipantsView } from "@drVue/components/room/tasks/shared/widgets/participants";
import ViewFieldWrapper from "../ViewCellWrapper.vue";

import type { ViewFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { ParticipantUserV1, Task } from "@drVue/store/pinia/room/tasks";
import type { Avatar } from "@shared/ui/dr-avatar";

interface Props {
  viewProps: ViewFieldProps<ParticipantUserV1[], Task>;
}

const props = defineProps<Props>();

const assignees = computed(() => {
  const members = DrStore.state.room.members;
  if (members.isError || !members.membersList.length) return [];

  return props.viewProps.entityValue.map(
    ({ user_id }) => members.members[user_id],
  );
});

const assigneeNames = computed(() =>
  assignees.value
    .map(({ name }) => {
      const [firstName, lastName] = name.split(" ");
      return `${firstName[0]}. ${lastName}`;
    })
    .join(", "),
);

const viewPropsWithAvatars = computed<ViewFieldProps<Avatar[], Task>>(() => {
  return {
    ...props.viewProps,
    entityValue: assignees.value.map(({ name, avatar }) => ({
      name,
      url: avatar.thumbnail,
    })),
  };
});
</script>
