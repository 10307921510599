<template>
  <DrVxeGrid
    :loading="isLoading"
    :data="data"
    :columns="tableColumns.columns"
    @cell-click="handleCellClick"
  >
    <template #empty>No members found</template>
  </DrVxeGrid>
</template>

<script setup lang="ts">
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import TableColumns from "./tableColumns";

import type { TableDataRow } from "./tableColumns";

interface Props {
  isLoading: boolean;
  data: TableDataRow[];
}

defineProps<Props>();
const emit = defineEmits<{
  (event: "select", id: TableDataRow["member"]["id"]): void;
}>();

const handleCellClick = ({ row }: { row: TableDataRow }) => {
  emit("select", row.member.id);
};

const tableColumns = new TableColumns();
</script>
