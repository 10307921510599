angular
  .module("dealroom.analytics.linechart.container", [
    "dealroom.analytics.linechart.service.config",
  ])
  .directive("drAnalyticsLinechartContainer", () => ({
    scope: {
      drAnalyticsLinechartContainer: "<",
    },
    restrict: "A",
    controller: Controller,
  }));

Controller.$inject = ["$scope", "$element", "AnalyticsLinechartConfigService"];
function Controller($scope, $element, AnalyticsLinechartConfigService) {
  const dispatcher = $scope.drAnalyticsLinechartContainer;
  const pk = "drAnalyticsLinechartContainer-" + $scope.$id;
  const update = $element.prop("tagName") === "svg" ? updateSvg : updateInner;

  const height = AnalyticsLinechartConfigService.YAXIS.height;
  const margin =
    AnalyticsLinechartConfigService.XAXIS.name.full +
    AnalyticsLinechartConfigService.SHADOW.marginTop;

  dispatcher.on("update." + pk, update);

  function updateInner({ r = 0 } = {}) {
    $element.attr(
      "transform",
      `translate(0, ${r + AnalyticsLinechartConfigService.SHADOW.marginTop})`,
    );
  }

  function updateSvg({ r = 0, width = 1, height = 1 } = {}) {
    $element.attr("height", margin + height + r * 2).attr("width", width);
  }
}
