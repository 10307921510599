import { orderBy } from "lodash-es";

import type { State } from "./state";
import type { DealNote, Note, UserNote } from "./types";
import type { RootState } from "@drVue/store/state";
import type { GetterTree } from "vuex";

export interface NotesGetterTree<
  NoteType extends Note,
  RelatedObjectIdType extends string | number,
> extends GetterTree<State<NoteType>, RootState> {
  byObjectId(
    state: State<NoteType>,
  ): (objectId: RelatedObjectIdType) => NoteType[];
}

export const dealsNotesGetters: NotesGetterTree<DealNote, number> = {
  byObjectId:
    (state) =>
    (dealId: number): DealNote[] => {
      return orderBy(
        state.items.filter((rn: DealNote) => rn.deal_id === dealId),
        (rn: DealNote) => rn.date_added,
        "desc",
      );
    },
};

export const usersNotesGetters: NotesGetterTree<UserNote, string> = {
  byObjectId:
    (state) =>
    (userId: string): UserNote[] => {
      return orderBy(
        state.items.filter((un: UserNote) => un.user_id === userId),
        (un: UserNote) => un.date_added,
        "desc",
      );
    },
};
