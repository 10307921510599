<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <div>
    <ElFormItem :class="$style.selectTypeSwitch">
      <ElRadioGroup v-model="selectAll">
        <ElRadio :label="true">Everything</ElRadio>
        <ElRadio :label="false">Selected items</ElRadio>
      </ElRadioGroup>
    </ElFormItem>
    <ElDivider :class="$style.divider" />
    <ElForm :disabled="selectAll">
      <ElFormItem size="small" class="el-form-item--no-spacing">
        <ElCheckbox v-model="copyGroups" @change="groupsToggle">
          Copy groups
        </ElCheckbox>
      </ElFormItem>
      <ElForm>
        <ElFormItem
          v-show="copyGroups"
          prop="copy_options.copy_groups"
          :error="getError('copy_options.copy_groups')"
        >
          <GroupsToCopyControl
            v-model="roomData.copy_options.copy_groups"
            :room-id="roomData.copy_options.source_room"
            :select-all="selectAll"
          />
        </ElFormItem>
      </ElForm>
      <ElFormItem size="small" class="el-form-item--no-spacing">
        <ElCheckbox
          v-model="roomData.copy_options.copy_folders"
          @change="foldersToggle"
        >
          Copy folders
        </ElCheckbox>
      </ElFormItem>
      <ElFormItem size="small" class="el-form-item--no-spacing">
        <ElCheckbox
          v-model="roomData.copy_options.copy_documents"
          @change="documentsToggle"
        >
          Copy documents
        </ElCheckbox>
      </ElFormItem>
      <ElFormItem
        v-if="allowRequests"
        size="small"
        class="el-form-item--no-spacing"
      >
        <ElCheckbox
          v-model="roomData.copy_options.copy_labels"
          @change="labelsToggle"
        >
          Copy labels
        </ElCheckbox>
      </ElFormItem>
      <ElFormItem
        v-if="allowRequests"
        class="el-form-item--no-spacing"
        size="small"
      >
        <ElCheckbox
          v-model="roomData.copy_options.copy_tasks.copy_categories"
          @change="categoriesToggle"
          >Copy categories
        </ElCheckbox>
      </ElFormItem>
      <ElFormItem
        v-if="allowRequests"
        class="el-form-item--no-spacing"
        size="small"
      >
        <ElCheckbox v-model="roomData.copy_options.copy_tasks.copy_tasks">
          Copy requests
        </ElCheckbox>
      </ElFormItem>
      <ElForm
        :disabled="!roomData.copy_options.copy_tasks.copy_tasks || selectAll"
      >
        <ElFormItem
          v-if="allowRequests"
          class="el-form-item--no-spacing deal-create__copy-option-subitem"
          size="small"
        >
          <ElCheckbox v-model="roomData.copy_options.copy_tasks.keep_statuses">
            Keep statuses
          </ElCheckbox>
        </ElFormItem>
        <ElFormItem
          v-if="allowRequests"
          class="el-form-item--no-spacing deal-create__copy-option-subitem"
          size="small"
        >
          <ElCheckbox v-model="roomData.copy_options.copy_tasks.keep_assignees">
            Keep assignees
          </ElCheckbox>
        </ElFormItem>
        <ElFormItem
          v-if="allowRequests"
          class="el-form-item--no-spacing deal-create__copy-option-subitem"
          size="small"
        >
          <ElCheckbox v-model="roomData.copy_options.copy_tasks.keep_reviewers">
            Keep reviewers
          </ElCheckbox>
        </ElFormItem>
        <ElFormItem
          v-if="allowRequests"
          class="el-form-item--no-spacing deal-create__copy-option-subitem"
          size="small"
        >
          <ElCheckbox
            v-model="roomData.copy_options.copy_tasks.keep_attachments"
          >
            Keep attachments
          </ElCheckbox>
        </ElFormItem>
        <ElFormItem
          v-if="allowRequests"
          class="el-form-item--no-spacing deal-create__copy-option-subitem"
          size="small"
        >
          <ElCheckbox
            v-model="roomData.copy_options.copy_tasks.keep_dependencies"
          >
            Keep dependencies
          </ElCheckbox>
        </ElFormItem>
        <ElFormItem
          v-if="allowRequests"
          class="el-form-item--no-spacing deal-create__copy-option-subitem"
          size="small"
        >
          <ElCheckbox v-model="roomData.copy_options.copy_tasks.keep_dates">
            Keep dates
          </ElCheckbox>
        </ElFormItem>
        <ElFormItem
          v-if="allowRequests"
          class="el-form-item--no-spacing deal-create__copy-option-subitem"
          size="small"
        >
          <ElCheckbox
            v-model="roomData.copy_options.copy_tasks.keep_labels"
            @change="keepLabelsToggle"
          >
            Keep labels
          </ElCheckbox>
        </ElFormItem>

        <ElFormItem
          v-if="allowRequests"
          class="el-form-item--no-spacing deal-create__copy-option-subitem"
          size="small"
        >
          <ElCheckbox
            v-model="roomData.copy_options.copy_tasks.keep_custom_fields"
          >
            Keep custom data
          </ElCheckbox>
        </ElFormItem>
      </ElForm>
    </ElForm>
  </div>
</template>
<script lang="ts">
/* eslint-disable vue/no-mutating-props */

import { defineComponent } from "vue";

import { APP_SETTINGS } from "@setups/data";
import GroupsToCopy from "@drVue/components/client-dashboard/deals/GroupsToCopy/GroupsToCopy.vue";

import type {
  RoomCreateData,
  RoomTaskCopyOptions,
} from "@drVue/api-service/common/rooms";
import type { CheckboxValueType } from "element-plus";
import type { PropType } from "vue";

interface Data {
  allowRequests: boolean;
  copyGroups: boolean;
  selectAll: boolean;
}

export default defineComponent({
  name: "CopyOptions",
  components: {
    GroupsToCopyControl: GroupsToCopy,
  },
  props: {
    roomData: {
      type: Object as PropType<RoomCreateData>,
      required: true,
    },
    getError: {
      type: Function as PropType<(path: string) => string>,
      required: true,
    },
  },
  data(): Data {
    return {
      allowRequests: APP_SETTINGS.WEBSITE.IS_DEALROOM,
      copyGroups: true,
      selectAll: true,
    };
  },
  watch: {
    "roomData.copy_options.copy_tasks.copy_tasks"(isSelected: boolean) {
      this.onCopyRequestChanged(isSelected);
    },
    "roomData.copy_options.copy_tasks.keep_attachments"(value: boolean) {
      this.onKeepAttachmentsOrDependenciesChanged(value);
    },
    "roomData.copy_options.copy_folders"(value: boolean) {
      this.onCopyFoldersOrDocumentsChanged(value);
    },
    "roomData.copy_options.copy_documents"(value: boolean) {
      this.onCopyFoldersOrDocumentsChanged(value);
    },
    selectAll(value: string) {
      this.onSelectAllChanged(value);
    },
  },
  methods: {
    onCopyRequestChanged(isSelected: boolean) {
      for (const option of Object.keys(this.roomData.copy_options.copy_tasks)) {
        if (option === "copy_categories" && isSelected) {
          // do not uncheck copy_cats on unchecking copy_tasks
          continue;
        }
        this.roomData.copy_options.copy_tasks[
          option as keyof RoomTaskCopyOptions
        ] = isSelected;
      }
    },
    onKeepAttachmentsOrDependenciesChanged(value: boolean) {
      if (value) {
        this.roomData.copy_options.copy_documents = true;
        this.roomData.copy_options.copy_folders = true;
      }
    },
    onCopyFoldersOrDocumentsChanged(value: boolean) {
      if (!value) {
        this.roomData.copy_options.copy_tasks.keep_attachments = false;
      }
    },
    onSelectAllChanged(value: string) {
      if (value) this.copyGroups = true;
      this.roomData.copy_options = {
        ...this.roomData.copy_options,
        copy_folders: true,
        copy_documents: true,
        copy_labels: true,
      };
      for (const option of Object.keys(this.roomData.copy_options.copy_tasks)) {
        this.roomData.copy_options.copy_tasks[
          option as keyof RoomTaskCopyOptions
        ] = true;
      }
    },
    groupsToggle(value: CheckboxValueType) {
      if (!value) {
        this.roomData.copy_options.copy_groups = [];
      }
    },
    foldersToggle(value: CheckboxValueType) {
      if (!value) {
        this.roomData.copy_options.copy_documents = false;
      }
    },
    documentsToggle(value: CheckboxValueType) {
      if (value) {
        this.roomData.copy_options.copy_folders = true;
      }
    },
    categoriesToggle(value: CheckboxValueType) {
      if (!value) {
        this.roomData.copy_options.copy_tasks.copy_tasks = false;
      }
    },
    labelsToggle(value: CheckboxValueType) {
      if (!value) {
        this.roomData.copy_options.copy_tasks.keep_labels = false;
      }
    },
    keepLabelsToggle(value: CheckboxValueType) {
      if (value) {
        this.roomData.copy_options.copy_labels = true;
      }
    },
  },
});
</script>

<style module lang="scss">
.selectTypeSwitch {
  margin-bottom: unset !important;
}

.divider {
  margin: 16px 0;
}
</style>
