<template>
  <div
    role="tab"
    :class="{ [$style.container]: true, [$style.container_isActive]: isActive }"
  >
    <DrIcon
      style="justify-self: flex-end"
      :class="{ [$style.icon]: true, [$style.icon_isActive]: isActive }"
      :name="iconName"
    />
    <span :class="{ [$style.text]: true, [$style.text_isActive]: isActive }">
      {{ text }}
    </span>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { DrIcon } from "@shared/ui/dr-icon";

import { INITIAL_DATA_TYPES } from "@drVue/components/user-dashboard/room-create/utils";

import type { IconName } from "@shared/ui/dr-icon";

interface Props {
  isActive: boolean;
  iconName: IconName;
  text: string;
}

defineProps<Props>();

const blankButton = {
  id: "blank",
  type: INITIAL_DATA_TYPES.Blank,
  label: "Keep blank",
  icon: "fa-plus",
};
const copyButton = {
  id: "copy",
  type: INITIAL_DATA_TYPES.CopyRoom,
  label: "Copy room",
  icon: "fa-clone",
};
const templateButton = {
  id: "template",
  type: INITIAL_DATA_TYPES.Template,
  label: "Use playbook",
  icon: "fa-book",
};

const switchDataTypeButtons = [blankButton, copyButton, templateButton];
const createBy = ref();
const selectedPlaybook = ref(null);
const setCreateBy = (value: any) => {
  createBy.value = value;
  selectedPlaybook.value = null;
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors" as colors;

.container {
  cursor: pointer;

  min-height: 32px;
  border-radius: 8px;
  border: 1px solid colors.$pr-300;

  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  gap: 8px;

  transition: all 0.2s ease-in-out;

  background-color: transparent;

  &:hover {
    background-color: colors.$pr-100;
  }

  // Push icon to the right
  div {
    justify-self: flex-end;
  }
}

.icon {
  color: colors.$pr-350;
}

.text {
  color: colors.$pr-500;
}

// Is Active
.container_isActive {
  background-color: colors.$sc-600;
  border: 1px solid colors.$sc-600;

  &:hover {
    background-color: colors.$sc-700;
    border: 1px solid colors.$sc-700;
  }
}

.icon_isActive {
  color: colors.$pr-0;
}

.text_isActive {
  color: colors.$pr-0;
}
</style>
