import { ApiService } from "@drVue/api-service";
import { buildQueryParams } from "@drVue/api-service/utils";

type IntId = number;
export type ProseMirrorDocument = { [key: string]: any };

export interface PostAnnouncementApiData {
  room_id: IntId;
  recipients_groups: IntId[] | undefined;
  recipients: IntId[] | undefined;
  message: ProseMirrorDocument;
}

export interface Notification {
  date: string;
  notifications: string[];
}

export interface Notifications {
  next: string | null;
  results: Notification[];
}

export interface Counter {
  unread: number;
}

export class NotificationsApiService extends ApiService {
  public postAnnouncement(data: PostAnnouncementApiData) {
    const url = this.Urls["api:notifications:announcement"]();
    return this.post(url, data);
  }

  public loadNotifications(
    url: null | string,
    roomId?: number,
  ): Promise<Notifications> {
    if (url === null) {
      url = this.Urls["api:notifications:list"]();
      url += buildQueryParams({
        room_id: roomId,
      });
    }

    return this.get<Notifications>(url);
  }

  public markAllAsRead() {
    const url = this.Urls["api:notifications:list"]();
    return this.post(url, { is_viewed: true });
  }

  public markAsRead(noticeId: number): Promise<void> {
    const url = this.Urls["api:notifications:detail"](noticeId);
    return this.post(url, { is_viewed: true });
  }

  public markAsUnread(noticeId: number): Promise<void> {
    const url = this.Urls["api:notifications:detail"](noticeId);
    return this.post(url, { is_viewed: false });
  }

  public getCounter() {
    const url = this.Urls["api:notifications:count"]();
    return this.get<Counter>(url);
  }
}
