<template>
  <div>
    <div class="dash-content-panel-info__item-label">{{ meta.label }}</div>
    <div class="dash-content-panel-info__item-value">
      <!-- @vue-skip -->
      <DrTruncatedTextTooltip
        v-if="item[meta.fieldPath]"
        :content="item[meta.fieldPath]"
      >
        {{ item[meta.fieldPath] }}
      </DrTruncatedTextTooltip>
      <span v-else>-</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { PropType } from "vue";

export default defineComponent({
  name: "ViewTextField",
  components: {
    DrTruncatedTextTooltip,
  },
  props: {
    item: { required: true, type: Object as PropType<OrgUser> },
    meta: { required: true, type: Object as PropType<any> },
  },
});
</script>
