<template>
  <div :class="$style.header">
    <SearchInput v-model="searchTerm" :class="$style.searchInput" />
    <ElButton type="primary" :class="$style.newTaskBtn" @click="makeNewTask">
      New task
    </ElButton>
  </div>

  <ElCollapseTransition>
    <DealsTaskForm
      v-if="!!editableTask"
      v-model="editableTask"
      :form-errors="formErrors"
      :grouped-assignees="formGroupedAssignees"
      @submit="handleSubmit"
      @cancel="purgeEditableTask"
    />
  </ElCollapseTransition>

  <template v-if="!tasks.length && !editableTask">
    <ElDivider :class="$style.divider" />
    <span v-if="isLoading">Please wait, loading…</span>
    <span v-else>
      Create task to better keep track of things you need to do and get
      notified.
    </span>
  </template>

  <template v-else>
    <DealsTaskCard
      v-for="task in searchedTasks"
      :key="task.id"
      v-bind="task"
      :assignees-dict="cardAssigneesDict"
      @toggle-resolved="handleToggleTaskResolved"
      @remove="handleRemoveTask"
      @edit="handleEditTask"
    />
  </template>
</template>

<script setup lang="ts">
import { addWeeks } from "date-fns";
import { ElMessageBox } from "element-plus";
import { cloneDeep } from "lodash-es";
import {
  computed,
  nextTick,
  onMounted,
  ref,
  useCssModule,
  watchEffect,
} from "vue";
import { useFormHelper } from "@shared/ui/dr-form";
import SearchInput from "@shared/ui/search-input";

import { Document, flexSearchAllResultsArray } from "@app/flex";
import { DealTasksEvent, insightTrack } from "@app/insight";
import { ORG_MEMBER_DATA } from "@app/setups";
import { $notifyDanger } from "@drVue/common";
import { useDealGroups } from "../use-deal-groups";
import {
  type DealTask,
  type DealTaskCreatePayload,
  useDealTasks,
} from "../use-deal-tasks";
import DealsTaskCard from "./DealsTaskCard.vue";
import DealsTaskForm from "./DealsTaskForm.vue";

import type { User } from "@shared/ui/dr-user-box";
import type { Node as ProsemirrorNode } from "@tiptap/pm/model";

type EditableDealTask = DealTaskCreatePayload & Partial<Pick<DealTask, "id">>;

interface Props {
  dealId: number;
}

interface Emits {
  (e: "fill-task-form", isNew: boolean): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const $style = useCssModule();

const dealIdRef = computed(() => props.dealId);

const {
  list: tasks,
  dict: tasksMap,
  isLoading: tasksIsLoading,
  isLoadError: tasksIsLoadingError,
  create,
  update,
  remove,
} = useDealTasks(dealIdRef);

const {
  listOfAccepted: dealGroups,
  isLoading: dealGroupsIsLoading,
  isLoadError: dealGroupsIsLoadingError,
} = useDealGroups(dealIdRef);

const { hookFormSubmitPromise, formErrors } = useFormHelper<DealTask>();

watchEffect(() => {
  if (tasksIsLoadingError.value) {
    $notifyDanger("Something went wrong while loading this deal's tasks.");
  }
});
watchEffect(() => {
  if (dealGroupsIsLoadingError.value) {
    $notifyDanger(
      "Something went wrong while loading assignees data for tasks.",
    );
  }
});

const searchTerm = ref("");
const editableTask = ref<EditableDealTask | null>(null);

const isLoading = computed(
  () => tasksIsLoading.value || dealGroupsIsLoading.value,
);

const formGroupedAssignees = computed(() =>
  dealGroups.value.map((group) => {
    return {
      label: group.name,
      options: group.members.map((member) => {
        const label = `${member.first_name} ${member.last_name}`;
        return {
          label,
          value: member.id,
        };
      }),
    };
  }),
);

const cardAssigneesDict = computed(() => {
  const dict: Record<string, User> = {};

  dealGroups.value.forEach((group) => {
    group.members.forEach((member) => {
      dict[member.id] = {
        ...member,
        avatar: { thumbnail: member.avatar?.thumbnail || "" },
      };
    });
  });

  return dict;
});

const searchedTasks = computed(() => {
  const searchValue = searchTerm.value.toLowerCase().trim();
  if (searchValue.length) {
    const flexIndex = new Document({
      document: {
        id: "id",
        index: [
          {
            field: "title",
            charset: "latin:advanced",
            tokenize: "full",
          },
        ],
      },
    });

    tasks.value.forEach((task) => flexIndex.add(task));
    const foundIds = flexSearchAllResultsArray(flexIndex, searchValue);

    return foundIds.map((id) => tasksMap.value[id]);
  }

  return tasks.value;
});

const makeNewTask = () => {
  emit("fill-task-form", true);
  // allow scroll to top in parent component
  nextTick(() => {
    editableTask.value = {
      id: undefined,
      title: "",
      description: {
        type: "doc",
        content: [{ type: "paragraph" }],
      } as unknown as ProsemirrorNode,
      due_datetime: addWeeks(new Date(), 1),
      assigned_to_id: ORG_MEMBER_DATA?.user,
    };
  });
};

const purgeEditableTask = () => {
  editableTask.value = null;
};

const handleSubmit = async () => {
  if (editableTask.value === null) {
    $notifyDanger("Something went wrong while creating a new deal task...");
    return;
  }

  if (editableTask.value.id) {
    const { id, ...payload } = editableTask.value;
    await hookFormSubmitPromise(update(id, payload));
    insightTrack(DealTasksEvent.TaskChanged);
  } else {
    await hookFormSubmitPromise(create(editableTask.value));
    insightTrack(DealTasksEvent.TaskAdded);
  }

  purgeEditableTask();
};

const handleToggleTaskResolved = async (
  id: DealTask["id"],
  is_resolved: boolean,
) => {
  await update(id, { is_resolved });
  insightTrack(
    is_resolved ? DealTasksEvent.TaskResolved : DealTasksEvent.TaskUnresolved,
  );
};

const handleRemoveTask = (id: DealTask["id"]) => {
  ElMessageBox.confirm(
    "Are you sure you want to delete this task?",
    "Remove deal`s task",
    {
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      customClass: $style.warnMessageBox,
      showClose: false,
    },
  ).then(async () => {
    await remove(id);
    insightTrack(DealTasksEvent.TaskRemoved);
  });
};

const handleEditTask = async (id: DealTask["id"]) => {
  emit("fill-task-form", false);
  // allow scroll to top in parent component
  nextTick(() => {
    editableTask.value = cloneDeep(tasksMap.value[id]) || null;
  });
};

onMounted(() => insightTrack(DealTasksEvent.TabOpened));
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.divider {
  margin-top: unset;
}

.searchInput {
  width: 204px !important;
}

.newTaskBtn {
  width: 92px;
  position: relative;
  right: 0;
}

.warnMessageBox {
  &:global(.el-message-box) {
    max-width: 448px;
    border-left: 2px solid colors.$ad-10;
    padding: spacing.$l spacing.$xl spacing.$xl;
  }

  :global {
    .el-message-box__header {
      padding: 0 0 spacing.$xs;
    }

    .el-message-box__title {
      color: colors.$pr-900;
      font: typography.$title_bold;
      border-top: none;
      border-bottom: none;
    }

    .el-message-box__content {
      padding: 0 0 spacing.$xl;
      color: colors.$pr-900;
      font: typography.$body_regular;
    }

    .el-message-box__btns {
      padding: 0;

      .el-button.el-button--primary {
        --el-button-bg-color: #{colors.$ad-10};
        --el-button-border-color: #{colors.$ad-10};
        --el-button-hover-bg-color: #{colors.$ad-10_5};
        --el-button-hover-border-color: #{colors.$ad-10_5};
        --el-button-active-bg-color: #{colors.$ad-10_5};
        --el-button-active-border-color: #{colors.$ad-10_5};
        --el-button-outline-color: transparent;
      }
    }
  }
}
</style>
