import { ApiService } from "@drVue/api-service";

import type { State } from "./types";

export default class PreferencesApiService extends ApiService {
  public save(preferences: Partial<State>) {
    const url = this.Urls["api:client-dashboard:user-preferences"]();
    return this.put<Partial<State>, Partial<State>>(url, preferences);
  }
}
