import { escape } from "lodash-es";

import { ROOM_DATA } from "@setups/data";
import { categoryUrl } from "@setups/room-urls";
import { pinia } from "@drVue/store/pinia";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";

const categoriesStore = useCategoriesStore(pinia);

export default function CategoriesService() {
  var service = {
    isLoaded: isLoaded,

    loadCategories: (skipErrorAlert) => categoriesStore.load(skipErrorAlert),
    getCategoryForAlert: getCategoryForAlert,
    getCategoryType: getCategoryType,
    expandTree: expandTree,
  };

  Object.defineProperties(service, {
    loadingPromise: {
      get: () => categoriesStore.loadingPromise,
    },
    categories: {
      get: () => categoriesStore.categories,
    },
    categoriesList: {
      get: () => categoriesStore.categoriesList,
    },
    rootCategories: {
      get: () => categoriesStore.rootCategories,
    },
    childIdToRoot: {
      get: () => categoriesStore.childIdToRoot,
    },
    loading: {
      get: () => categoriesStore.isLoading,
    },
    error: {
      get: () => categoriesStore.isError,
    },
  });

  return service;

  function isLoaded() {
    return service.loading === false && service.error === false;
  }

  ///////////////////////////////////////////////////////////////////////////////

  function getCategoryForAlert(category, params) {
    params = params || {};
    var el = "<strong>" + escape(category.name) + "</strong>";
    if (!params.skipHref) {
      const url = categoryUrl(ROOM_DATA.url, category.id);
      el = '<a href="' + url + '" target="_blank">' + el + "</a>";
    }
    if (params.withType) {
      var categoryType = service.getCategoryType(category.parent_id);
      el = categoryType + " " + el;
    }
    return el;
  } /* End getCategoryForAlert */

  function getCategoryType(parent) {
    return parent ? "category" : "list";
  }

  ///////////////////////////////////////////////////////////////////////////////

  /** @todo: move to store !!! */
  function expandTree(category) {
    // expand categories tree
    var parentId = category.id;
    while (parentId) {
      var cat = service.categories[parentId];
      // if category was closed (False) do not expand it
      if (angular.isUndefined(cat.expanded)) {
        cat.expanded = true;
      }
      parentId = cat.parent_id;
    }
  } /* End expandTree */
}
