<template>
  <div :class="{ [$style.wrapper]: true, [$style.inline]: inline }">
    <div
      v-for="item in items"
      :key="item.id"
      :class="{
        [$style.item]: true,
        [$style.item_isActive]: item.id === value,
      }"
    >
      <div :class="$style.radio">
        <ElRadio v-model="value" :label="item.id" :disabled="disabled" />
      </div>
      <div :class="$style.body">
        <span :class="$style.title" @click="!disabled && (value = item.id)">
          {{ item.name }}
        </span>

        <div
          v-if="item.id === value && item.description"
          :class="$style.description"
        >
          {{ item.description }}
        </div>

        <div v-if="$slots[item.id]" :class="$style.subcontrol">
          <slot :name="item.id" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" generic="Value extends string = string">
/**
 * Radio stack component
 * (according style guide).
 */
import { computed } from "vue";

import type { RadioStackItem } from "./types";

interface Props {
  modelValue: Value;
  items: RadioStackItem<Value>[];
  disabled?: boolean;
  inline?: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: "update:modelValue", id: Props["modelValue"]): void;
}>();

const value = computed({
  get(): Props["modelValue"] {
    return props.modelValue;
  },
  set(value: Props["modelValue"]) {
    emit("update:modelValue", value);
  },
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;
@use "@app/styles/scss/values";

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: spacing.$xxs;
  align-items: stretch;

  &.inline {
    flex-direction: row;
  }
}

.item {
  display: flex;
  flex-direction: row;
  gap: spacing.$xs;
  padding: spacing.$xxs spacing.$s;
  border-radius: values.$base-border-radius;

  :global {
    .el-radio {
      height: spacing.$xl;
      margin: 0;
    }

    .el-radio__label {
      display: none;
    }
  }
}

.item_isActive {
  background: colors.$pr-50;
}

.radio {
  height: spacing.$xl;
  display: flex;
  align-items: center;
}

.body {
  flex: 1 1 auto;
}

.title {
  font: typo.$body_regular;
  cursor: pointer;
}

.description {
  padding: spacing.$xxs 0;
  color: colors.$pr-500;
  font: typo.$caption_regular;
}

.subcontrol {
  padding-top: spacing.$xs;
}
</style>
