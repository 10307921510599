<template>
  <div>
    <ElAlert
      v-if="saveTemplateError"
      type="error"
      title="Failed to save template"
    />
    <ElButton
      text
      size="small"
      :disabled="isSavingTemplate"
      @click="newTemplate()"
    >
      <DrIcon name="bookmark" size="sm" />
    </ElButton>
  </div>
</template>

<script setup lang="ts">
import DrIcon from "@shared/ui/dr-icon";
import { useAsyncState } from "@vueuse/core";

import { pinia } from "@drVue/store/pinia";
import { useAiTemplatesStore } from "./AiTemplatesStore";

import type { DocVerQA } from "./types";

interface Props {
  qa: DocVerQA;
}

const props = defineProps<Props>();
const aiTemplatesStore = useAiTemplatesStore(pinia);

const {
  error: saveTemplateError,
  isLoading: isSavingTemplate,
  execute: newTemplate,
} = useAsyncState(
  () => aiTemplatesStore.create({ question: props.qa.question }),
  undefined,
  { immediate: false },
);
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.iconSaved {
  --el-button-icon-disabled-color: #{colors.$ad-3_5};
}
</style>
