<template>
  <ElButton size="small" @click="handleOpenDialog"
    >Delete client and all rooms</ElButton
  >
  <ElDialog v-model="isDialogVisible" title="Delete client and all rooms">
    <SimpleTabNav
      :active-tab="activeTab"
      :tabs="[Tabs.Confirm, Tabs.Execute]"
      @tab-click="handleSwitchTab($event)"
    />
    <br />
    <h5>
      By making this change you are confirming that this client is no longer
      actively using application and all data will be permanently removed from
      our servers.
    </h5>
    <ElForm
      v-if="activeTab === Tabs.Confirm"
      ref="confirmFormRef"
      v-loading="isFormSubmitting"
      label-position="top"
      :class="$style.container"
      :model="formModel"
    >
      <div v-if="token" :class="$style.token">
        <code>{{ token }}</code>
        <ElButton text size="small" @click="() => copyToClipboard(token)">
          <template #icon>
            <DrIcon name="copy" />
          </template>
        </ElButton>
      </div>
      <ElAlert
        v-if="formErrors.non_field_errors"
        :title="formErrors.non_field_errors"
        type="error"
        show-icon
        :closable="false"
      />
    </ElForm>
    <ElForm
      v-if="activeTab === Tabs.Execute"
      ref="executeFormRef"
      v-loading="isFormSubmitting"
      label-position="top"
      :class="$style.container"
      :model="formModel"
    >
      <ElFormItem required label="Token" prop="token">
        <ElInput v-model="formModel.token" placeholder="abc123" />
      </ElFormItem>
      <ElAlert
        v-if="formErrors.non_field_errors"
        :title="formErrors.non_field_errors"
        type="error"
        show-icon
        :closable="false"
      />
    </ElForm>
    <ElFormItem>
      <ElButton
        :type="activeTab === Tabs.Confirm ? 'primary' : 'danger'"
        @click="submit"
      >
        {{ activeTab === Tabs.Confirm ? "Confirm" : "Delete" }}
      </ElButton>
      <ElButton @click="handleCloseDialog">Cancel</ElButton>
    </ElFormItem>
  </ElDialog>
</template>

<script setup lang="ts">
import { ElForm } from "element-plus";
import { ref } from "vue";
import { useFormHelper } from "@shared/ui/dr-form";
import DrIcon from "@shared/ui/dr-icon/DrIcon.vue";
import SimpleTabNav from "@shared/ui/simple-tabnav/index.vue";

import { ManagementApiService } from "@drVue/api-service/management";
import { $notifyDanger, $notifySuccess, copyToClipboard } from "@drVue/common";

const enum Tabs {
  Confirm = "confirm",
  Execute = "execute",
}

interface Props {
  clientId: number;
}
const props = defineProps<Props>();
const emit = defineEmits(["change"]);

const { formErrors, hookFormSubmitPromise, isFormSubmitting } = useFormHelper();
const executeFormRef = ref<InstanceType<typeof ElForm> | null>(null);
const confirmFormRef = ref<InstanceType<typeof ElForm> | null>(null);
const handleReset = () => {
  executeFormRef.value?.resetFields();
  confirmFormRef.value?.resetFields();
};

const activeTab = ref<Tabs>(Tabs.Confirm);
const handleSwitchTab = (tab: Tabs) => {
  activeTab.value = tab;
  handleReset();
};

const api = new ManagementApiService();

const isDialogVisible = ref(false);
const handleOpenDialog = () => {
  isDialogVisible.value = true;
};
const handleCloseDialog = () => {
  isDialogVisible.value = false;
};

const formModel = ref({ token: "" });
const token = ref("");

const submit = async () => {
  switch (activeTab.value) {
    case Tabs.Confirm:
      await confirmDeleteClient();
      handleReset();
      break;
    case Tabs.Execute:
      await executeDeleteClient(formModel.value.token);
      handleReset();
      break;
    default:
      throw new Error("Unknown tab");
  }
  emit("change");
};
const confirmDeleteClient = async () => {
  try {
    const resp = await hookFormSubmitPromise(
      api.confirmDeleteClient(props.clientId),
    );
    token.value = resp.token;
  } catch {
    $notifyDanger("Failed to confirm delete client");
  }
};
const executeDeleteClient = async (token: string) => {
  try {
    await hookFormSubmitPromise(
      api.executeDeleteClient(props.clientId, { token }),
    );
    handleCloseDialog();
    $notifySuccess("Client and rooms have been deleted");
  } catch {
    $notifyDanger("Failed to execute client delete");
  }
};
</script>

<style module lang="scss">
.container {
  padding: 20px 0;
}

.token {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}
</style>
