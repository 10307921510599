<template>
  <DrComments
    :items="comments"
    :user-id="currentUserId"
    :mentions="mentions"
    :is-pending="isPending"
    :is-error="isLoadError"
    :has-manager-access="hasManagerAccess"
    :is-read-only="viewOnly"
    condensed
    @retry="load"
    @add="addComment"
    @remove="removeComment"
  />
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { DrComments } from "@shared/ui/dr-comments";

import { ROOM_DATA, USER_DATA } from "@setups/data";
import { insightTrack, RoomFindingsDetailsEvent } from "@app/insight";
import { DrStore } from "@app/vue";
import { $notifyDanger } from "@drVue/common";
import { pinia } from "@drVue/store/pinia";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import { useFindingComments } from "./use-finding-comments";

import type { FindingComment } from "./use-finding-comments";
import type { Comment, CommentDraft } from "@shared/ui/dr-comments/types";

interface Props {
  findingId: FindingComment["finding_id"];
  viewOnly: boolean;
}

const props = defineProps<Props>();

const tasksStore = useTasksStore(pinia);

const { list, isLoadError, isLoading, load, create, remove } =
  useFindingComments(computed(() => props.findingId));

watch(
  () => isLoadError,
  (isError) => {
    if (isError) {
      $notifyDanger("Something went wrong while fetching the comments!");
    }
  },
);

const isProcessing = ref(false);
const isPending = computed(() => isLoading.value || isProcessing.value);

const currentUserId = computed(() => `${USER_DATA.id}`);
const hasManagerAccess = computed(
  () => ROOM_DATA.userPermissions.administrator,
);

const mentions = computed(() => ({
  tasks: tasksStore.tasksList,
  users: DrStore.state.room.members.membersList,
}));

const comments = computed<Comment[]>(() => {
  return list.value.map((comment) => {
    const authorUser =
      DrStore.state.room.members.membersByUid[comment.sender_id];

    return {
      id: comment.id,
      body: comment.body,
      date_added: comment.date_created,
      author: {
        id: comment.sender_id,
        name: authorUser?.name ?? "unknown",
        avatar: authorUser?.avatar.reduced ?? "",
      },
    };
  });
});

const addComment = async ({ body }: CommentDraft) => {
  if (!body) return;

  isProcessing.value = true;
  try {
    await create({ body });
    insightTrack(RoomFindingsDetailsEvent.CommentAdded);
  } catch (_err) {
    $notifyDanger("Something went wrong while adding the comment!");
  }
  isProcessing.value = false;
};

const removeComment = async (commentId: Comment["id"]) => {
  isProcessing.value = true;
  try {
    await remove(commentId);
    insightTrack(RoomFindingsDetailsEvent.CommentRemoved);
  } catch (_err) {
    $notifyDanger("Something went wrong while updating the comment!");
  }
  isProcessing.value = false;
};
</script>
