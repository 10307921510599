<template>
  <div :class="$style.item">
    <div :class="$style.label" data-testid="dform-label">
      {{ viewProps.schema.label }}
    </div>
    <div
      :class="{
        [$style.value]: true,
        [$style.value_isHoverable]: !viewProps.schema.isReadOnly,
      }"
      data-testid="dform-value"
      @click.stop="enterEditMode"
    >
      <Status :status-id="viewProps.entityValue">
        <template #empty>-</template>
      </Status>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Status from "../../components/FindingStatus.vue";

import type { ViewFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";

interface Props {
  viewProps: ViewFieldProps;
}

const props = defineProps<Props>();

const enterEditMode = () => {
  if (!props.viewProps.schema.isReadOnly) {
    props.viewProps.enterEditMode();
  }
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

.item {
  flex: 1;
  margin-bottom: 12px;
}

.label {
  font-size: 13px;
  line-height: 20px;
  color: colors.$pr-600;
}

.value {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: colors.$pr-900;
  padding: 4px 6px;
  margin-left: -6px;
  margin-right: -2px;
  border-radius: 7px;
  transition: background-color 100ms ease-out;
}

.value_isHoverable {
  &:hover {
    background-color: colors.$pr-100;
    cursor: pointer;
  }
}
</style>
