import { Document } from "flexsearch";

import { FieldItemTypes } from "@drVue/api-service/client-dashboard";

import type { DealsState } from "./DealsState";
import type {
  Deal,
  DealAttachment,
  DealDialogState,
  Room,
  RoomGroup,
} from "./types";
import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { Tokenizer } from "flexsearch";
import type { MutationTree } from "vuex";

export interface SetContactsOfDealPayload {
  dealId: number;
  contactsIds: string[];
}

interface DealsMutationTree extends MutationTree<DealsState> {
  setDeals(state: DealsState, deals: Deal[]): void;
  updateDeal(state: DealsState, deal: Deal): void;
  buildIndex(state: DealsState, customFields: FieldItem[]): void;
  updateIndex(
    state: DealsState,
    { customFields, deal }: { customFields: FieldItem[]; deal: Deal },
  ): void;
  setIsLoading(state: DealsState, isLoading: boolean): void;
  setIsError(state: DealsState, isError: boolean): void;
  saveDeal(state: DealsState, deal: Deal): void;
  detailsDialog(state: DealsState, payload: Partial<DealDialogState>): void;
  setClientRooms(state: DealsState, clientRooms: Room[]): void;
  setGroups(state: DealsState, groupsList: RoomGroup[]): void;
  updateGroup(state: DealsState, group: RoomGroup): void;
}

export const dealsMutations = {
  setDeals(state: DealsState, deals: Deal[]) {
    state.items = deals;
  },

  updateDeal(state: DealsState, deal: Deal) {
    const i = state.items.findIndex((d) => d.uid === deal.uid);
    state.items.splice(i, 1, deal);

    if (deal.room?.access.administrator) {
      const i = state.clientRooms.findIndex((cr) => cr.uid === deal.room!.uid);
      state.clientRooms.splice(i, 1, deal.room);
    }
  },

  buildIndex(state: DealsState, customFields: FieldItem[]) {
    const textFields = customFields.filter(
      (cf) => cf.field_type === FieldItemTypes.Text,
    );

    state.index = new Document<Deal>({
      document: {
        id: "uid",
        index: [
          { field: "title", charset: "latin:advanced", tokenize: "full" },

          ...textFields.map((cf) => ({
            field: `custom_data:${cf.key}`,
            charset: "latin:advanced",
            tokenize: "forward" as Tokenizer,
          })),
        ],
      },
    });

    state.items.forEach((d) => state.index.add(d.uid, d));
  },

  updateIndex(state: DealsState, { customFields, deal }) {
    state.index.update(deal.uid, deal);
  },

  setIsLoading(state, isLoading: boolean): void {
    state.isLoading = isLoading;
  },

  setIsError(state, isError: boolean): void {
    state.isError = isError;
  },

  saveDeal(state: DealsState, deal: Deal) {
    const dealToUpdate = state.items.find((d) => d.id === deal.id);
    if (dealToUpdate) {
      Object.assign(dealToUpdate, deal);
      return;
    } else {
      state.items.push(deal);
    }
  },

  detailsDialog(state: DealsState, payload: Partial<DealDialogState>) {
    Object.assign(state.detailsDialog, payload);
    if (payload?.visible === false) {
      // reset active tab on dialog close
      state.detailsDialog.tab = "notes";
    }
  },

  setClientRooms(state, clientRooms: Room[]) {
    state.clientRooms = clientRooms;
  },

  setGroups(state: DealsState, groupsList: RoomGroup[]) {
    const groups: { [key: number]: RoomGroup } = {};
    groupsList.forEach((group) => {
      groups[group.id] = group;
    });
    state.groups = groups;
  },

  updateGroup(state: DealsState, group: RoomGroup) {
    if (group.id in state.groups) {
      Object.assign(state.groups[group.id], group);
    }
  },

  setContactsOfDeal(state: DealsState, payload: SetContactsOfDealPayload) {
    const deal = state.items.find((d) => d.id === payload.dealId);
    if (!deal) return;

    deal.contacts = payload.contactsIds;
  },

  deleteEmailAttachments(state, payload: { dealId: number; emailId: string }) {
    const dealIndex = state.items.findIndex(({ id }) => id === payload.dealId);
    if (dealIndex === -1) return;

    const emailAttachments = state.items[dealIndex].email_attachments?.filter(
      ({ email_id }) => email_id !== payload.emailId,
    );
    state.items[dealIndex].email_attachments = emailAttachments;
  },
} as DealsMutationTree;
