import { cloneDeep } from "lodash-es";
import { remove } from "lodash-es";

import { TasksStatusesApiService } from "./TasksStatusesApiService";

import type { TasksStatusesState } from "./TasksStatusesState";
import type { TaskStatus } from "./types";
import type { RootState } from "@drVue/store/state";
import type { ActionContext, ActionTree } from "vuex";

export type IContext = ActionContext<TasksStatusesState, RootState>;

const api = new TasksStatusesApiService();

interface TasksStatusesActionTree {
  init(context: IContext, statuses: TaskStatus[]): Promise<void>;

  syncAll(context: IContext): Promise<TaskStatus[] | undefined>;

  add(context: IContext, payload: TaskStatus): Promise<void>;

  update(context: IContext, payload: TaskStatus): Promise<void>;

  delete(context: IContext, statusId: number): Promise<void>;
}

export const tasksStatusesActions: ActionTree<TasksStatusesState, RootState> &
  TasksStatusesActionTree = {
  init({ commit, state }, statuses: TaskStatus[]) {
    commit("setStatuses", statuses);

    return Promise.resolve();
  },

  async syncAll({ state, commit }) {
    if (state.isLoading) {
      return;
    }
    state.isError = false;
    state.isLoading = true;
    let statuses;
    try {
      statuses = await api.list();
    } catch (e) {
      state.isError = true;
      throw e;
    } finally {
      state.isLoading = false;
    }

    commit("setStatuses", statuses);
    return state.list;
  },

  async add({ state, commit, dispatch }, status: TaskStatus) {
    const addedStatus = await api.add(status);
    const statuses = cloneDeep(state.list);

    statuses.push(addedStatus);

    commit("setStatuses", statuses);
  },

  async update({ state, commit, dispatch }, status: TaskStatus) {
    const updatedStatus = await api.upd(status);
    const statuses = cloneDeep(state.list);

    remove(statuses, { id: updatedStatus.id });
    statuses.push(updatedStatus);

    commit("setStatuses", statuses);
  },

  async delete({ state, commit, dispatch }, statusId: number) {
    await api.del(statusId);
    const statuses = cloneDeep(state.list);

    remove(statuses, { id: statusId });

    commit("setStatuses", statuses);
  },
};
