<template>
  <div :class="$style.wrapper">
    <div :class="$style.attachments">
      <slot name="attachments" :attachments="attachments" />
    </div>

    <DrPopup ref="popupRef" paddingless>
      <template #reference>
        <div :class="$style.attachButton">
          <DrIcon name="paperclip" size="sm" />
        </div>
      </template>
      <template #default>
        <TreeBrowser
          is-multiple
          :selected-node-ids="attachmentUids"
          :width="POPUP_SIZES.wideTree.width"
          :height="POPUP_SIZES.wideTree.height"
          :is-loading="isTreeLoading"
          :tree="dataRoomTree"
          @save="handleRoomBrowserSubmit"
          @cancel="handleCloseRoomBrowser"
        >
          <template #tree-item="{ item }">
            <div :class="$style.treeItemContainer">
              <DrIcon
                v-if="(item as Partial<Folder>).type === 'folder'"
                :class="$style.folderIcon"
                name="folder"
              />
              <i
                v-else
                class="mimetypes"
                :class="[
                  $style.fileIcon,
                  getIconClass((item as Partial<Document>).mimetype),
                ]"
              />
              <span>{{ (item as unknown as Document).name }}</span>
            </div>
          </template>
        </TreeBrowser>
      </template>
    </DrPopup>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { POPUP_SIZES } from "@shared/ui/constants";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrPopup } from "@shared/ui/dr-popups";

import getIconClass from "@app/common/mimetype";
import { DrStore } from "@app/vue";
import { TreeBrowser } from "@drVue/components/room/tasks/shared/tree-browser";
import {
  type Document,
  type Folder,
} from "@drVue/store/modules/room/documents/DocumentsApiService";
import { toNode } from "../lists/attachments/utils";

import type { NodeId } from "@drVue/components/room/tasks/shared/tree-browser/types";

type FileUid = string;
type FolderUid = string;

interface Props {
  fileUids: FileUid[];
  folderUids: FolderUid[];
}

interface Emits {
  (event: "update", payload: Props): void;
}

const FILE_UID_PREFIX = "doc_";
const FOLDER_UID_PREFIX = "fldr_";

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const popupRef = ref<InstanceType<typeof DrPopup> | null>(null);
const isTreeLoading = computed(() => !!DrStore.state.room.documents.isLoading);

const attachments = computed(() => [
  ...props.folderUids.map((folderUid) => {
    const folder = DrStore.state.room.documents.foldersByUid[folderUid];
    return {
      ...folder,
      date_added: folder.dateCreated,
      mimetype: undefined, // type safety in template
    };
  }),
  ...props.fileUids.map((documentUid) => {
    const file = DrStore.state.room.documents.filesByUid[documentUid];
    return {
      ...file,
      date_added: file.dateCreated,
    };
  }),
]);

const attachmentUids = computed(() => [...props.folderUids, ...props.fileUids]);

const dataRoomTree = computed(
  () =>
    DrStore.state.room.documents.rootFolder?.folders.map((folder) =>
      toNode(folder, true, true),
    ) ?? [],
);

const handleRoomBrowserSubmit = (payload: NodeId[]) => {
  const typedPayload = payload.map(String);
  const update = {
    fileUids: typedPayload.filter((uid) => uid.startsWith(FILE_UID_PREFIX)),
    folderUids: typedPayload.filter((uid) => uid.startsWith(FOLDER_UID_PREFIX)),
  };
  emit("update", update);
  handleCloseRoomBrowser();
};

const handleCloseRoomBrowser = () => {
  popupRef.value?.hide();
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";
@use "@app/styles/scss/values";

.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 6px;
  align-items: flex-end;
}

.attachments {
  flex: 1;
}

.attachButton {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px colors.$pr-300;
  border-radius: values.$base-border-radius;
  color: colors.$pr-500;
  width: 28px;
  height: 28px;

  &:hover {
    cursor: pointer;
    color: colors.$sc-600;
    border-color: colors.$sc-400;
  }
}

.treeItemContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 6px;
  align-items: center;
}

.folderIcon {
  color: colors.$pr-400;
}

.fileIcon {
  scale: 0.7;
}
</style>
