<template>
  <div class="mfa-interface">
    <div
      class="mfa-interface__icon"
      :class="{
        'mfa-interface__icon--enabled': interface.is_enrolled,
        'mfa-interface__icon--disabled': !interface.is_enrolled,
      }"
    >
      <i class="fal fa-comment-minus" />
    </div>

    <div>
      <div class="mfa-interface__header">{{ interface.name }}</div>
      <p class="mfa-interface__description">{{ interface.description }}</p>

      <ElButton
        v-if="!interface.is_enrolled && !isAdding"
        type="primary"
        @click="enable"
      >
        Enable
      </ElButton>

      <div v-if="!interface.is_enrolled && isAdding">
        <p class="mfa-interface__description">
          Enter your phone number in international format and then provide the
          confirmation code we send you to active it.
        </p>

        <ElAlert
          v-if="getBackendError('non_field_errors')"
          class="auth-form__alert"
          :title="getBackendError('non_field_errors')"
          type="error"
          show-icon
          :closable="false"
        />

        <ElForm
          v-if="!isSmsRequested"
          ref="enableForm"
          :rules="enableFormRules"
          :model="enableFormModel"
          :disabled="isSubmitting"
          label-position="top"
        >
          <ElFormItem
            label="Phone"
            prop="phone"
            :error="getBackendError('phone')"
          >
            <ElInput
              v-model="enableFormModel.phone"
              autofocus
              autocomplete="tel"
              placeholder="+15555550155"
              @input="resetBackendError('phone')"
            />
          </ElFormItem>

          <div class="text-right">
            <ElButton
              type="default"
              :disabled="isSubmitting"
              @click="cancelEnabling"
              >Cancel</ElButton
            >
            <ElButton
              type="primary"
              :disabled="isSubmitting"
              @click="requestSms"
            >
              Send me a code
            </ElButton>
          </div>
        </ElForm>

        <div v-if="isSmsRequested">
          <ElForm
            v-if="isSmsRequested"
            ref="confirmForm"
            :rules="confirmFormRules"
            :model="confirmFormModel"
            :disabled="isSubmitting"
            label-position="top"
          >
            <ElFormItem
              label="Authentication code"
              prop="challenge"
              :error="getBackendError('challenge')"
            >
              <ElInput
                v-model="confirmFormModel.challenge"
                autocomplete="one-time-code"
              />
            </ElFormItem>

            <ElFormItem
              label="Your Current Password"
              prop="password"
              :error="getBackendError('password')"
            >
              <ElInput
                v-model="confirmFormModel.password"
                type="password"
                autocomplete="current-password"
              />
            </ElFormItem>
          </ElForm>

          <div class="text-right">
            <ElButton
              type="default"
              :disabled="isSubmitting"
              @click="cancelEnabling"
              >Cancel</ElButton
            >
            <ElButton
              type="primary"
              :disabled="isSubmitting"
              @click="confirmEnabling"
            >
              Confirm
            </ElButton>
          </div>
        </div>
      </div>

      <ElButton v-if="interface.is_enrolled && !isRemoving" @click="disable">
        Disable
      </ElButton>

      <div v-if="interface.is_enrolled && isRemoving">
        <ElForm
          ref="disableForm"
          :rules="disableFormRules"
          :model="disableFormModel"
          :disabled="isSubmitting"
          label-position="top"
        >
          <ElFormItem label="Current password" prop="password">
            <ElInput
              v-model="disableFormModel.password"
              type="password"
              autocomplete="current-password"
            />
          </ElFormItem>
        </ElForm>
        <ElButton :disabled="isSubmitting" @click="confirmDisabling"
          >Disable</ElButton
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrForm from "@shared/ui/dr-form";

import { $notifySuccess } from "@drVue/common";
import {
  Interface,
  MfaApiService,
} from "@drVue/components/client-dashboard/users/AccountSettings/MfaApiService";

import type {
  ConfirmFormModel,
  DisableFormModel,
  EnableSmsFormModel,
} from "./types";
import type {
  ConfirmRequest,
  MfaInterface,
  SecretData,
} from "@drVue/components/client-dashboard/users/AccountSettings/MfaApiService";
import type { Dictionary } from "@drVue/types";
import type { ElForm } from "element-plus";
import type { PropType } from "vue";

interface Data {
  enableFormRules: Dictionary<any[]>;
  confirmFormRules: Dictionary<any[]>;
  disableFormRules: Dictionary<any[]>;
  api: MfaApiService;
  enableFormModel: EnableSmsFormModel;
  confirmFormModel: ConfirmFormModel;
  disableFormModel: DisableFormModel;
  secretData: SecretData;
  isAdding: boolean;
  isRemoving: boolean;
}

export default defineComponent({
  name: "SmsInterface",
  extends: DrForm,
  props: {
    interface: { required: true, type: Object as PropType<MfaInterface> },
  },
  emits: ["confirmed", "removed"],
  data(): Data {
    return {
      enableFormRules: {
        phone: [
          {
            required: true,
            trigger: "blur",
            message: "This field may not be blank.",
          },
          {
            min: 7,
            message: "Ensure this field has at least 7 characters.",
          },
          {
            pattern: /^[0-9-+\s()]+$/,
            message:
              'Please use digits, symbols "+", "-", round brackets and spaces.',
          },
          {
            max: 25,
            message: "Ensure this field has no more than 25 characters.",
          },
        ],
      },
      confirmFormRules: {
        challenge: [
          {
            required: true,
            trigger: "blur",
            message: "This field may not be blank.",
          },
        ],
        password: [
          {
            required: true,
            trigger: "blur",
            message: "This field may not be blank.",
          },
        ],
      },
      disableFormRules: {
        password: [
          {
            required: true,
            trigger: "blur",
            message: "This field may not be blank.",
          },
        ],
      },
      api: new MfaApiService(),
      enableFormModel: {
        phone: "",
      },
      confirmFormModel: {
        challenge: "",
        password: "",
      },
      disableFormModel: {
        password: "",
      },
      secretData: {
        interface: Interface.sms,
        secret: "",
      },
      isAdding: false,
      isRemoving: false,
    };
  },
  computed: {
    isSmsRequested(): any {
      return Boolean(this.secretData.secret);
    },
  },
  methods: {
    getEnableForm(): typeof ElForm {
      return this.$refs.enableForm as typeof ElForm;
    },
    getConfirmForm(): typeof ElForm {
      return this.$refs.confirmForm as typeof ElForm;
    },
    getDisableForm(): typeof ElForm {
      return this.$refs.disableForm as typeof ElForm;
    },
    enable() {
      this.isAdding = true;
      this.isRemoving = false;
    },
    requestSms() {
      this.setBackendErrors({});

      this.getEnableForm()
        .validate()
        .then(() => {
          this.submitPromise(
            this.api.addInterface(Interface.sms, this.enableFormModel),
          ).then((r) => (this.secretData = r.data));
        });
    },
    cancelEnabling() {
      this.setBackendErrors({});

      this.isAdding = false;
      this.isRemoving = false;

      this.confirmFormModel = {
        challenge: "",
        password: "",
      };

      this.secretData = {
        interface: Interface.sms,
        secret: "",
      };
    },
    confirmEnabling() {
      const request: ConfirmRequest = {
        phone: this.enableFormModel.phone,
        challenge: this.confirmFormModel.challenge,
        password: this.confirmFormModel.password,
        secret: this.secretData.secret,
      };

      this.getConfirmForm()
        .validate()
        .then(() => {
          this.submitPromise(
            this.api.confirmInterface(Interface.sms, request).then((r) => {
              $notifySuccess("The SMS interface was successfully enabled");
              this.setBackendErrors({});
              this.$emit("confirmed", r.data.codes);
            }),
          );
        });
    },
    disable() {
      this.isAdding = false;
      this.isRemoving = true;
    },
    confirmDisabling() {
      this.getDisableForm()
        .validate()
        .then(() =>
          this.submitPromise(
            this.api
              .removeInterface(Interface.sms, this.disableFormModel.password)
              .then(() => {
                $notifySuccess("The SMS interface was successfully disabled");
                this.setBackendErrors({});
                this.$emit("removed");
              }),
          ),
        );
    },
  },
});
</script>
