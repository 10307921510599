import { h } from "vue";
import UserEmailCell from "@shared/ui/users/user-email";

import BaseMgmtTableColumns from "@drVue/components/management/baseTable/baseMgmtTableColumns";
import MgmtLinkTableCell from "../baseTable/MgmtLinkTableCell.vue";

import type { UserRow } from "./types";
import type { DrVxeTableColumn } from "@drVue/components/types";

export default class TableColumns extends BaseMgmtTableColumns<UserRow> {
  getBaseColumns() {
    return [
      this.fullName(),
      this.email(),
      this.simpleTextColumn("Phone", "office_number"),
      this.simpleTextColumn("Company", "company"),
      this.simpleTextColumn("Title", "title"),
    ];
  }

  getExtraColumn(column: string): DrVxeTableColumn<UserRow> {
    switch (column) {
      case "is_administrator":
        return this.simpleTextColumn("Is Admin", "is_administrator", {
          sortType: "string",
        });
      default:
        return this.simpleTextColumn(`Unknown ${column}`, "");
    }
  }

  private fullName(): DrVxeTableColumn<UserRow> {
    return {
      sortable: true,
      field: "name",
      title: "Full Name",
      fixed: "left",
      minWidth: 170,
      slots: {
        default: ({ row }) => {
          const label = row.name;
          const link = `#/users/${row.id}`;
          return [h(MgmtLinkTableCell, { label, link })];
        },
      },
    };
  }

  private email(): DrVxeTableColumn<UserRow> {
    return {
      sortable: true,
      field: "email",
      title: "Email",
      minWidth: 170,
      slots: {
        default: ({ row }) => {
          row = row as any;
          return [
            h(UserEmailCell, {
              email: row.email,
              class: "rooms-table__cell",
            }),
          ];
        },
      },
    };
  }
}
