import { reactive } from "vue";

import type { DocumentViewerInfo } from "../types";

type InitializeCb = () => void;

export abstract class BaseViewerManager {
  static readonly ID: string;
  abstract readonly isRotateSupported: boolean;
  abstract readonly isFitZoomSupported: boolean;
  abstract readonly isRedactionSupported: boolean;

  public readonly state: {
    isLoaded: boolean;
    currentPage: null | number;
    totalPages: null | number;
    zoomLevel: null | number; // float, 1 means 100% zoom
    isTextSelectionSupported: boolean;
    textSelectionMode: boolean;
  } = reactive({
    isLoaded: false,
    currentPage: null,
    totalPages: null,
    zoomLevel: null,
    isTextSelectionSupported: false,
    textSelectionMode: false,
  });
  constructor(
    protected viewerContainerId: string,
    protected doc: DocumentViewerInfo,
    protected beforeViewerInitializeCb: InitializeCb,
    protected initialPage: number | null,
  ) {}

  abstract initialize(): void;

  abstract goToPage(page: number): void;
  abstract prevPage(): void;
  abstract nextPage(): void;

  abstract zoomDocument(zoomIn: boolean): void;

  abstract fitWidth(): void;
  abstract fitHeight(): void;
  abstract rotate(): void;

  abstract setTextSelectionMode(enable: boolean): void;
}
