<template>
  <div>
    <ElFormItem label="Sort by">
      <ElSelect
        v-model="sortField"
        :append-to-body="'body'"
        class="dash-container__toolbar-item el-select--full-width"
      >
        <ElOption
          v-for="field in sortHelper.sortFields"
          :key="field.field"
          :label="field.label"
          :value="field.field"
        />
      </ElSelect>
    </ElFormItem>
    <ElFormItem label="Sort direction">
      <ElRadioGroup v-model="sortOrder">
        <ElRadio label="asc" class="el-radio--no-bottom-margin"
          >Ascending</ElRadio
        >
        <ElRadio label="desc" class="el-radio--no-bottom-margin"
          >Descending</ElRadio
        >
      </ElRadioGroup>
    </ElFormItem>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  DealsBoardSortHelper,
  DEFAULT_BOARDS_ORDER,
} from "@drVue/components/client-dashboard/deals/DealsBoard/utils";
import DrStore from "@drVue/store";

import type { TableLayoutSort } from "@setups/types";

interface Data {
  sortHelper: any;
}

export default defineComponent({
  name: "DealsBoardSortSelector",
  data(): Data {
    return {
      sortHelper: new DealsBoardSortHelper(DrStore),
    };
  },
  computed: {
    layoutSort: {
      get() {
        return (
          this.$store.state.common.customViews.current.settings
            .tableLayoutSort || DEFAULT_BOARDS_ORDER
        );
      },
      set(sort: TableLayoutSort) {
        this.$store.commit("common/customViews/updateCurrentSettings", {
          tableLayoutSort: sort,
        });
      },
    },
    sortField: {
      get() {
        return this.layoutSort.field;
      },
      set(field: string) {
        this.layoutSort = { field, order: this.sortOrder };
      },
    },
    sortOrder: {
      get() {
        return this.layoutSort.order;
      },
      set(order: Exclude<TableLayoutSort["order"], null>) {
        this.layoutSort = { field: this.sortField, order };
      },
    },
  },
});
</script>
