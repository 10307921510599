<template>
  <span>
    <span
      class="phase-info__circle"
      :style="{ 'background-color': row.phase_color }"
    />
    <span
      class="phase-title"
      :class="{
        'phase-title--active': row.active,
      }"
      >{{ row.phase_name }}</span
    >
    <i v-if="row.active" class="fas fa-play-circle phase-active-icon" />
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { PropType } from "vue";

export default defineComponent({
  name: "PhaseCell",
  props: {
    row: { required: true, type: Object as PropType<any> },
  },
});
</script>
