<template>
  <div :class="$style.container">
    <label
      :class="{
        [$style.label]: true,
        [$style.disabled]: disabled,
      }"
    >
      <slot>
        {{ label }}
      </slot>
    </label>

    <DrRadioStack
      :model-value="value"
      :class="$style.control"
      :items="items"
      :inline="inline"
      :disabled="disabled"
      @update:model-value="$emit('update:modelValue', $event)"
    />
  </div>
</template>

<script lang="ts" setup generic="Value extends string">
import { computed } from "vue";

import DrRadioStack from "./DrRadioStack.vue";

import type { RadioStackItem } from "./types";

interface Props {
  modelValue: Value;
  items: RadioStackItem<Value>[];
  label?: string;
  disabled?: boolean;
  inline?: boolean;
}

interface Events {
  (event: "update:modelValue", id: Value): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Events>();

const value = computed<Value>(() => props.modelValue);
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

.container {
  display: grid;
  grid-template-columns: 1fr minmax(40px, auto);
  grid-template-rows: minmax(40px, auto);

  .label {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
    padding: 8px 8px 12px;
  }

  .control {
    align-self: center;
  }

  .disabled {
    color: colors.$pr-400;
    cursor: not-allowed;
  }
}
</style>
