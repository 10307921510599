<template>
  <div class="dash-rooms-table-customize__rows">
    <ColumnCustomization
      v-for="column in fixedColumns"
      :key="column.field"
      :column="column"
      :is-disabled="isSaving"
      @update-visible="(value) => setIsVisible(column, value)"
    />

    <Draggable
      v-model="draggableColumns"
      item-key="field"
      handle=".fa-grip-vertical"
      :disabled="isSaving"
      class="dash-rooms-table-customize__draggable"
    >
      <template #item="{ element: column }">
        <ColumnCustomization
          :column="column"
          :is-disabled="isSaving"
          @update-visible="(value) => setIsVisible(column, value)"
        />
      </template>
    </Draggable>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Draggable from "vuedraggable";

import ColumnCustomization from "./ColumnCustomization.vue";

import type { DrVxeTableColumn } from "@drVue/components/types";
import type { CustomViewColumn } from "@setups/types";
import type { PropType } from "vue";

interface Data {
  isSaving: boolean;
}

export default defineComponent({
  name: "TableCustomize",
  components: {
    Draggable,
    ColumnCustomization,
  },
  props: {
    columns: {
      required: true,
      type: Array as PropType<DrVxeTableColumn<any>[]>,
    },
  },
  emits: ["update", "reset"],
  data(): Data {
    return {
      isSaving: false,
    };
  },
  computed: {
    visibleColumns() {
      return this.columns.filter((col) => col.title);
    },
    fixedColumns() {
      return this.visibleColumns.filter((col) => col.fixed);
    },
    draggableColumns: {
      get: function () {
        return this.visibleColumns.filter((col) => !col.fixed);
      },
      set: function (columns: DrVxeTableColumn[]) {
        const updates = columns.map((column, index) => ({
          field: column.field,
          order: index,
        }));

        this.updateColumns(updates);
      },
    },
  },
  methods: {
    setIsVisible(column: DrVxeTableColumn, isVisible: boolean) {
      this.updateColumns([{ field: column.field, hidden: !isVisible }]);
    },
    updateColumns(updatedColumns: CustomViewColumn[]) {
      this.$emit("update", updatedColumns);
    },
    reset() {
      this.$emit("reset");
    },
  },
});
</script>
