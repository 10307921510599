<template>
  <DrItemList :items="reviewersV2">
    <template #header>
      <ParticipantsEdit :edit-props="editProps">
        <template #reference="{ show }">
          <DrItemListHeader
            title="Reviewers"
            :light-weight="!reviewers.length"
            :allow-action="!viewOnly"
            @action="show"
          />
        </template>
      </ParticipantsEdit>
    </template>
    <template #item="{ item }">
      <ReviewersItem
        :disallow-remove="viewOnly"
        :reviewer="item"
        :reminded="remindedIdsMap[item.id]"
        @remove="handleRemove"
        @remind="handleRemind"
      />
    </template>
  </DrItemList>
</template>

<script setup lang="ts">
import { differenceWith } from "lodash-es";
import { computed } from "vue";
import { DrItemList, DrItemListHeader } from "@shared/ui/dr-item-list";

import { insightTrack, TaskDetailsTrackingEvent } from "@app/insight";
import { DrStore } from "@app/vue";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import { ParticipantsEdit } from "@drVue/components/room/tasks/shared/widgets/participants";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import ReviewersItem from "./ReviewersItem.vue";

import type { ReviewerItem } from "@drVue/components/room/tasks/TaskDetails/lists/reviewers/types";
import type {
  ParticipantUserV1,
  TaskReviewer,
  UserUid,
} from "@drVue/store/pinia/room/tasks";

interface Props {
  taskId: number;
  viewOnly: boolean;
  reviewers: TaskReviewer[];
  remindedIds?: ParticipantUserV1[];
}

interface Emits {
  (e: "remind", assignee: ParticipantUserV1): void;
}

const props = withDefaults(defineProps<Props>(), {
  remindedIds: () => [],
});

const emit = defineEmits<Emits>();

const tasksStore = useTasksStore();

const reviewersV2 = computed<ReviewerItem[]>(() =>
  props.reviewers.map(({ user_id, mark_complete }) => {
    const user = DrStore.state.room.members.members[user_id];
    return {
      id: user.id,
      user_id: user.uid,
      avatar: user.avatar,
      name: user.name,

      mark_complete,
    };
  }),
);

const remindedIdsMap = computed(() =>
  props.remindedIds.reduce(
    (acc, item) => {
      acc[item.user_id] = true;
      return acc;
    },
    {} as Record<string, boolean>,
  ),
);

const editProps = computed(() => ({
  schema: {
    type: FieldSchemaType.Select,
    prop: "assignees",
    label: "Assignees",
    extra: {
      categoryId: tasksStore.tasks[props.taskId]?.category_id,
    },
  },
  value: reviewersV2.value,
  veeField: {
    onBlur: () => {},
    onInput: () => {},
    onChange: async (add: UserUid[]) => {
      const remove = differenceWith(
        reviewersV2.value,
        add,
        (a, b) => a.user_id === b.user_id,
      ).map((r) => ({ user_id: r.user_id }));

      await tasksStore.updateReviewers(props.taskId, add, remove);
      if (add.length) {
        insightTrack(TaskDetailsTrackingEvent.ReviewersAdded);
      }
      if (remove.length) {
        insightTrack(TaskDetailsTrackingEvent.ReviewersRemoved);
      }
    },
  },
}));

const handleRemove = async (user_id: string) => {
  await tasksStore.updateReviewers(props.taskId, [], [{ user_id }]);
  insightTrack(TaskDetailsTrackingEvent.ReviewersRemoved);
};

const handleRemind = ({ id }: ReviewerItem) => {
  emit("remind", { user_id: id });
};
</script>
