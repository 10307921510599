import { h } from "vue";

import IdCell from "./cells/IdCell.vue";
import PriorityCell from "./cells/PriorityCell.vue";
import StatusCell from "./cells/StatusCell.vue";
import TitleCell from "./cells/TitleCell.vue";

import type { ITableRow } from "@drVue/components/client-dashboard/users/UserDetails/RoomTasks/types";
import type { DrVxeTableColumn } from "@drVue/components/types";

export default class TableColumns {
  get columns(): DrVxeTableColumn<ITableRow>[] {
    return [
      this.idColumn(),
      this.priorityColumn(),
      this.statusColumn(),
      this.titleColumn(),
    ];
  }
  private idColumn(): DrVxeTableColumn<ITableRow> {
    return {
      sortable: true,
      field: "key",
      title: "ID",
      minWidth: 85,
      width: 85,
      slots: {
        default: ({ row }: any) => [
          h(IdCell, {
            row: row,
          }),
        ],
      },
    };
  }

  private priorityColumn(): DrVxeTableColumn<ITableRow> {
    return {
      sortable: true,
      field: "priority",
      title: "PR",
      minWidth: 85,
      width: 110,
      slots: {
        default: ({ row }) => h(PriorityCell, { row }),
      },
    };
  }

  private statusColumn(): DrVxeTableColumn<ITableRow> {
    return {
      sortable: true,
      field: "status_id",
      title: "Status",
      minWidth: 85,
      width: 140,
      slots: {
        default: ({ row }) => h(StatusCell, { row }),
      },
    };
  }

  private titleColumn(): DrVxeTableColumn<ITableRow> {
    return {
      sortable: true,
      field: "title",
      title: "Request",
      minWidth: 200,
      resizable: false,
      headerClassName: "col--show-separator",
      slots: {
        default: ({ row }: any) => [
          h(TitleCell, {
            row: row,
          }),
        ],
      },
    };
  }
}
