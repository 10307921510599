<template>
  <div>
    <div
      :class="$style.item"
      :style="{
        '--parents-length': parentsLength,
      }"
    >
      <button
        v-if="children.length"
        :class="$style.button"
        @click="isOpen = !isOpen"
      >
        <DrIcon
          name="caret-right"
          :class="{
            [$style.icon]: true,
            [$style.icon_open]: isOpen,
          }"
        />
      </button>

      <ElCheckbox
        :model-value="category.allowed"
        :indeterminate="hasAllowed"
        :disabled="disabled"
        :class="$style.checkbox"
        @update:model-value="toggleAllowed($event === true)"
      >
        {{ category.name }}
      </ElCheckbox>

      <button
        ref="addCategoryBtnRef"
        :disabled="disabled"
        :class="[$style.button, $style.buttonAdd]"
        @click="$emit('create:category', addCategoryBtnRef!, category)"
      >
        <span>New worklist</span>&nbsp;<DrIcon
          name="plus"
          :class="$style.iconAdd"
        />
      </button>
    </div>

    <Transition :name="$style.transitionVerticalExpand">
      <div v-show="isOpen">
        <div>
          <RequestPermissionsCategoryItem
            v-for="child in children"
            :key="child.id"
            ref="childItem"
            :category="child"
            :categories="categories"
            :parents-length="parentsLength + 1"
            :disabled="disabled"
            @update:allowed="handleChildUpdateAllowed"
            @update:has-allowed="hasAllowedInnerMap[child.id] = $event"
            @create:category="(ref, cat) => $emit('create:category', ref, cat)"
          />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, reactive, ref, watch } from "vue";
import DrIcon from "@shared/ui/dr-icon/DrIcon.vue";

import type RequestPermissionsCategoryItem from "./RequestPermissionsCategoryItem.vue";
import type { RoomActions } from "@app/vue/store/modules/room/groups/GroupsActions";

type Category = RoomActions["categories"][number];

interface Props {
  category: Category;
  categories: Category[];
  parentsLength?: number;
  disabled?: boolean;
  initIsOpen?: boolean;
}

interface Events {
  (e: "update:allowed", value: Pick<Category, "id" | "allowed">): void;
  (e: "update:has-allowed", value: boolean): void;
  (e: "create:category", reference: HTMLElement, parent: Category): void;
}

const props = withDefaults(defineProps<Props>(), { parentsLength: 0 });

const emit = defineEmits<Events>();

const addCategoryBtnRef = ref<HTMLElement>();

const isOpen = ref(props.initIsOpen);
const childItem = ref<InstanceType<typeof RequestPermissionsCategoryItem>[]>(
  [],
);

const children = computed(() =>
  props.categories.filter((item) => item.parent_id === props.category.id),
);

const hasAllowedInnerMap = reactive<Record<number, boolean>>({});

const hasAllowed = computed(
  () =>
    props.category.allowed &&
    children.value.length !== 0 &&
    (Object.values(hasAllowedInnerMap).includes(true) ||
      children.value.some((item) => !item.allowed)),
);

const toggleAllowed = (allowed: boolean) => {
  emit("update:allowed", { id: props.category.id, allowed });

  childItem.value.forEach((item) => item.toggleAllowed(allowed));
};

const handleChildUpdateAllowed = (
  value: Pick<Category, "id" | "allowed">,
): void => {
  emit("update:allowed", value);

  if (value.allowed && !props.category.allowed) {
    emit("update:allowed", { id: props.category.id, allowed: true });
  } else if (
    !value.allowed &&
    props.category.allowed &&
    children.value.every(
      (item) => item.id === value.id || item.allowed === false,
    )
  ) {
    emit("update:allowed", { id: props.category.id, allowed: false });
  }
};

watch(
  hasAllowed,
  (value) => {
    emit("update:has-allowed", value);
  },
  { immediate: true },
);

onBeforeUnmount(() => {
  emit("update:has-allowed", false);
});

defineExpose({
  toggleAllowed,
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.item {
  display: grid;
  grid-template-columns: 20px 1fr auto;
  grid-template-rows: 38px;
  border-top: 1px solid colors.$pr-150;
  padding-left: calc(var(--parents-length, 0) * 17px);

  .checkbox {
    grid-column: 2;
    height: 100%;
  }

  &:hover {
    background: colors.$pr-50;

    .buttonAdd {
      opacity: 1;
    }
  }
}

.button {
  background: none;
  border: none;
  border-radius: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonAdd {
  padding: 0 12px;
  opacity: 0;

  .iconAdd {
    color: colors.$pr-400;
  }

  &:hover {
    & > span {
      text-decoration: underline;
    }

    .iconAdd {
      color: colors.$sc-600;
    }
  }
}

.icon {
  font-size: 14px;
  transition: transform 0.1s ease-out;
  color: colors.$pr-400;

  &.icon_open {
    transform: rotate(90deg);
    color: colors.$sc-600;
  }
}

.transitionVerticalExpand {
  &:global(-enter-active),
  &:global(-leave-active) {
    display: grid;
    transition: grid-template-rows 0.2s ease-out;
    align-items: flex-start;
    overflow: hidden;

    & > div {
      overflow: hidden;
    }
  }

  &:global(-enter-to),
  &:global(-leave-from) {
    grid-template-rows: 1fr;
  }

  &:global(-enter-from),
  &:global(-leave-to) {
    grid-template-rows: 0fr;
  }
}
</style>
