import { uniqBy } from "lodash-es";

import { isStringContains } from "@drVue/common";
import { markDisabledPhases } from "@drVue/components/client-dashboard/deals/utils";

import type { PhaseItem } from "@drVue/api-service/client-dashboard";
import type { RootState } from "@drVue/store/state";
import type { HEXColor } from "@drVue/utils/color";
import type { Store } from "vuex";

export type PhasesValue = (number | null)[] | undefined;
type IStore = Store<RootState>;

// this values is used since el-select does not work with null ID field
const UNKNOWN_PHASE_CUSTOM_ID = -1;

export function convertPhaseIdToFilterValue(phaseId: number | null) {
  return phaseId === null ? UNKNOWN_PHASE_CUSTOM_ID : phaseId;
}

export function convertPhasesStoreToFilterValue(phases: PhasesValue) {
  return phases ? phases.map(convertPhaseIdToFilterValue) : undefined;
}

export function convertPhasesFilterValueToStore(phases: PhasesValue) {
  return phases
    ? phases.map((p) => (p === UNKNOWN_PHASE_CUSTOM_ID ? null : p))
    : undefined;
}

export function dealsCount($store: IStore, phaseId: number | null): number {
  return $store.getters["clientDashboard/deals/getDealsByPhase"](phaseId)
    .length;
}

export interface PhaseSelectItem {
  id: number;
  name: string;
  color: HEXColor;
  dealsCount: number;
}
export function getPhasesSelectList($store: IStore) {
  return markDisabledPhases(
    $store.getters["clientDashboard/phases/phasesWithDeals"],
  )
    .filter((phase) => !phase.isDisabled)
    .map((phase: PhaseItem) => {
      return {
        id: convertPhaseIdToFilterValue(phase.id),
        name: phase.name,
        color: phase.color,
        dealsCount: dealsCount($store, phase.id),
      };
    });
}

export function getTextFieldAutoComplete($store: IStore) {
  return function (
    query: string,
    cb: (results: string[]) => void,
    fieldKey: string,
  ) {
    const formatFields =
      $store.getters["clientDashboard/customFields/formattedFields"];
    const allDeals = ($store.state as RootState).clientDashboard.deals.items;
    const filteredDeals =
      $store.getters["clientDashboard/filters/filterDeals"](allDeals);
    const results = [];
    for (const deal of filteredDeals) {
      const formattedFields = formatFields("deal", deal.custom_data);
      for (const field of formattedFields) {
        if (
          field.key === fieldKey &&
          field.value !== "-" &&
          isStringContains(query, field.value)
        ) {
          results.push(field);
        }
      }
    }

    cb(uniqBy(results, (r: any) => r.value));
  };
}
