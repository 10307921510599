<template>
  <DrPopup ref="popupRef" paddingless>
    <template #reference>
      <Trigger
        ref="triggerRef"
        visible-without-hover
        @click="editProps.quitEditMode"
      />
    </template>
    <template #default>
      <ul :class="$style.container">
        <template v-for="item in menu" :key="item.code">
          <li
            v-if="item.name"
            :class="$style.option"
            @click="handleMenu(item.code)"
          >
            {{ item.name }}
          </li>
          <li v-else>
            <hr :class="$style.separator" />
          </li>
        </template>
      </ul>
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, unref } from "vue";
import { DrPopup } from "@shared/ui/dr-popups";

import {
  makeTableMenu,
  type TaskContextActionCode,
} from "@drVue/components/room/tasks/TasksTable/makeTableMenu";
import { useTaskTableBus } from "@drVue/components/room/tasks/TasksTable/tasksTableBus";
import Trigger from "./Trigger.vue";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { Task } from "@drVue/store/pinia/room/tasks";

interface Props {
  editProps: EditFieldProps<any, Task, { inTheArchive: boolean }>;
}

const props = defineProps<Props>();

const popupRef = ref<InstanceType<typeof DrPopup> | null>(null);
const triggerRef = ref<InstanceType<typeof Trigger> | null>(null);

onMounted(() => {
  unref(popupRef)?.show();
});

const tasksTableBus = useTaskTableBus();
const tableMenu = makeTableMenu({
  inTheArchive: props.editProps.extra.inTheArchive,
});

const menu = computed(() => {
  return (tableMenu.body!.options || []).reduce((acc, section, index) => {
    if (index && acc.length) {
      acc.push({
        code: `separator_${index}`,
        name: "",
      });
    }

    const sectionItems = section
      .filter((item) => item.visible)
      .map(({ code, name }) => ({
        code,
        name,
      }));

    if (sectionItems.length) {
      acc.push(...sectionItems);
    }

    return acc;
  }, []);
});

const handleMenu = (code?: string) => {
  if (!code || !triggerRef.value) return;

  // We need to get a reference to the cell itself because this "Edit" component
  // will be unmounted in the next step. The cell will exit the "editing" state,
  // and the "View" component will be shown.
  const reference = triggerRef.value.$el.closest(".vxe-body--column");

  tasksTableBus.emit(code as TaskContextActionCode, {
    task: props.editProps.entity,
    reference,
  });
  props.editProps.quitEditMode();
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.container {
  width: 212px;
  padding: 8px;
}

.option {
  cursor: pointer;
  padding: 5px 10px;
  font: typography.$body_regular;

  &:hover {
    border-radius: 6px;
    background-color: colors.$pr-100;
    color: colors.$sc-600;
    transition: background-color 0.02s ease-in-out;
  }
}

.separator {
  margin: 5px -8px;
  border-top: 1px solid colors.$pr-200;
}
</style>
