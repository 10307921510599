<template>
  <a
    :class="$style.link"
    :href="documentViewUrl(ROOM_DATA.url, doc.id)"
    target="_blank"
  >
    <i class="mimetypes" :class="[$style.icon, getIconClass(doc.mimetype)]" />
    <div class="truncate">{{ doc.treeIndex }} {{ doc.name }}</div>
  </a>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { documentViewUrl } from "@setups/room-urls";
import getIconClass from "@app/common/mimetype";
import { ROOM_DATA } from "@app/setups";
import { DrStore } from "@app/vue";

import type { Document } from "@drVue/store/modules/room/documents/DocumentsApiService";

interface Props {
  documentId: number;
}

const props = defineProps<Props>();

const doc = computed<Document>(
  () => DrStore.state.room.documents.files[props.documentId],
);
</script>

<style module lang="scss">
@use "@app/styles/scss/_colors.scss";
@use "@app/styles/scss/typography.scss";

.link {
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  color: unset !important;
  text-decoration: none !important;
}

.icon {
  width: 12px !important;
  height: 14px !important;
  margin: 0 10px 0 8px;
}
</style>
