import { memoize } from "lodash-es";

export type RGBColor = { r: number; g: number; b: number };
export type HEXColor = `#${string}`;

export const isDarkColor = memoize(({ r, g, b }: RGBColor): boolean => {
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance < 0.5;
});

export const hexToRgb = memoize((hex: HEXColor): RGBColor => {
  const matchArray = hex.match(/^#([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i);
  if (matchArray === null) throw new Error("");

  const r = parseInt(matchArray[1], 16);
  const g = parseInt(matchArray[2], 16);
  const b = parseInt(matchArray[3], 16);

  return { r, g, b };
});

export const rgbToHex = memoize((rgb: RGBColor): HEXColor => {
  return ("#" +
    [rgb.r, rgb.g, rgb.b]
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      })
      .join("")) as HEXColor;
});

export function brightness(rgbColor: RGBColor, percent: number): HEXColor {
  let { r, g, b } = rgbColor;

  // Calculate the adjustment factor
  const factor = 1 + percent;
  r = Math.min(255, Math.max(0, Math.round(r * factor)));
  g = Math.min(255, Math.max(0, Math.round(g * factor)));
  b = Math.min(255, Math.max(0, Math.round(b * factor)));

  return rgbToHex({ r, g, b });
}
