import { diff } from "./diff";

const POSITIVE_FUTURE = new Date(3000, 0, 1).getTime();
const NEGATIVE_FUTURE = POSITIVE_FUTURE * -1;

export const compareDates: (
  a: Date | null | undefined,
  b: Date | null | undefined,
  isReversed: boolean,
) => number = (a, b, isReversed) => {
  const future = isReversed ? NEGATIVE_FUTURE : POSITIVE_FUTURE;

  const aTime = a?.getTime() ?? future;
  const bTime = b?.getTime() ?? future;

  return diff(aTime, bTime, isReversed);
};
