import selectAllCheckboxHtml from "./index.html?raw";

DropdownSelectAllController.$inject = ["$element"];
import * as d3 from "d3";

angular
  .module("dealroom.analytics.dropdown.base.select-all-checkbox", [])
  .component("drAnalyticsDropdownSelectAllCheckbox", {
    bindings: {
      dispatcher: "<",
      itemsLength: "<",
    },
    controller: DropdownSelectAllController,
    template: selectAllCheckboxHtml,
  });

function DropdownSelectAllController($element) {
  const $ctrl = this;
  const rootElement = d3.select($element[0]).select("a");
  const checkbox = rootElement.select('[type="checkbox"]');

  $ctrl.$onInit = function () {
    $ctrl.dispatcher.on("handleSelect.toggle", handleSelect);
  };

  rootElement.on("click", () => {
    d3.event.preventDefault();
    d3.event.stopPropagation();
    checkbox.property("checked", !checkbox.property("checked"));

    $ctrl.dispatcher.call(
      "toggleSelectAll",
      null,
      checkbox.property("checked"),
    );
  });

  checkbox.on("click", () => {
    d3.event.stopPropagation();

    $ctrl.dispatcher.call(
      "toggleSelectAll",
      null,
      checkbox.property("checked"),
    );
  });

  function handleSelect(selectedLabels) {
    const isAllChecked = selectedLabels.length === $ctrl.itemsLength;
    checkbox.property("checked", isAllChecked);
  }
}
