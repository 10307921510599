<template>
  <DrLayoutContentCentered wide>
    <DrLayoutContentCenteredSection title="Custom fields">
      <template #description>
        Create custom fields to track data relevant to your organization.
        Customize the data tracked and it will be shown across all of your deals
        and rooms.
      </template>

      <template #toolbar>
        <DrNav :items="tabs" @select="handleSelectTab">
          <template #item-postfix="{ item }">
            <DrChip v-if="item.id === 'findings'" text="NEW" color="blue" />
          </template>
        </DrNav>
      </template>

      <template #toolbar-right>
        <ElButton type="primary" icon-right @click="addField">
          Add Field
          <template #icon>
            <DrIcon name="plus" size="sm" />
          </template>
        </ElButton>
      </template>

      <CustomFieldsList
        v-show="selectedTab === 'deal'"
        ref="dealListRef"
        object-type="deal"
      />

      <CustomFieldsList
        v-show="selectedTab === 'user'"
        ref="userListRef"
        object-type="user"
      />

      <CustomFieldsList
        v-show="selectedTab === 'data_room'"
        ref="dataRoomListRef"
        object-type="data_room"
      />

      <CustomFieldsList
        v-if="showSynergies"
        v-show="selectedTab === 'synergy'"
        ref="synergyListRef"
        object-type="synergy"
      />

      <div v-show="selectedTab === 'task'">
        <CustomFieldsList
          v-if="showTasks"
          ref="taskListRef"
          object-type="task"
          @added="handleCustomFieldAction('added', $event)"
          @updated="handleCustomFieldAction('updated', $event)"
          @deleted="trackFieldDelete"
        />
        <RequestsCustomFieldsBanner v-else />
      </div>

      <CustomFieldsList
        v-show="selectedTab === 'value_driver'"
        ref="valueDriverListRef"
        object-type="value_driver"
      />

      <CustomFieldsList
        v-show="selectedTab === 'value_driver_period_data'"
        ref="valueDriverPeriodDataListRef"
        object-type="value_driver_period_data"
      />

      <div v-show="selectedTab === 'findings'">
        <CustomFieldsList
          v-if="showFindings"
          ref="findingsListRef"
          object-type="finding"
        />
        <FindingsCustomFieldsBanner v-else />
      </div>
    </DrLayoutContentCenteredSection>
  </DrLayoutContentCentered>
</template>

<script lang="ts" setup>
import { computed, reactive, ref } from "vue";
import DrChip from "@shared/ui/dr-chip";
import DrIcon from "@shared/ui/dr-icon";
import {
  DrLayoutContentCentered,
  DrLayoutContentCenteredSection,
} from "@shared/ui/dr-layouts";
import { DrNav } from "@shared/ui/dr-nav";

import { ORG_MEMBER_DATA } from "@setups/data";
import {
  insightTrack,
  OrgSettingsCustomFieldsTrackingEvent,
} from "@app/insight";
import { DrStore } from "@app/vue";
import {
  getPipelineSettingsAccess,
  PIPELINE_SETTINGS_ACCESS_TYPES,
} from "@drVue/components/client-dashboard/utils";
import FindingsCustomFieldsBanner from "./banners/FindingsCustomFields.vue";
import RequestsCustomFieldsBanner from "./banners/RequestsCustomFields.vue";
import CustomFieldsList from "./CustomField";

import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { NavItemTab } from "@shared/ui/dr-nav";
import type { Ref } from "vue";

const settingsAccess = getPipelineSettingsAccess(DrStore.state);

const TABS_LIST: (NavItemTab & { shown: boolean })[] = [
  {
    id: "deal",
    name: "Deal",
    isActive: false,
    shown:
      settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.PipelineOnly ||
      settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.ExecutionSuit,
  },
  {
    id: "user",
    name: "User",
    isActive: false,
    shown:
      settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.PipelineOnly ||
      settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.ExecutionSuit,
  },
  {
    id: "data_room",
    name: "Data Room",
    isActive: false,
    shown: !!settingsAccess,
  },
  {
    id: "task",
    name: "Requests",
    isActive: false,
    shown: settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.ExecutionSuit,
  },
  {
    id: "value_driver",
    name: "Synergies",
    isActive: false,
    shown:
      settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.ExecutionSuit &&
      ORG_MEMBER_DATA.client.enable_synergies,
  },
  {
    id: "value_driver_period_data",
    name: "Synergies (Tracking)",
    isActive: false,
    shown:
      settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.ExecutionSuit &&
      ORG_MEMBER_DATA.client.enable_synergies,
  },
  {
    id: "findings",
    name: "Findings",
    isActive: false,
    shown: settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.ExecutionSuit,
  },
];

const tabs: Ref<NavItemTab[]> = ref(
  TABS_LIST.filter((tab) => tab.shown).map((tab, index) => ({
    ...tab,
    isActive: !index,
  })),
);
type CustomFieldList = InstanceType<typeof CustomFieldsList> | null;
const dealListRef = ref<CustomFieldList>(null);
const userListRef = ref<CustomFieldList>(null);
const dataRoomListRef = ref<CustomFieldList>(null);
const synergyListRef = ref<CustomFieldList>(null);
const taskListRef = ref<CustomFieldList>(null);
const valueDriverListRef = ref<CustomFieldList>(null);
const valueDriverPeriodDataListRef = ref<CustomFieldList>(null);
const findingsListRef = ref<CustomFieldList>(null);

const listRefsMap = reactive<Record<string, Ref<CustomFieldList>>>({
  deal: dealListRef,
  user: userListRef,
  data_room: dataRoomListRef,
  synergy: synergyListRef,
  task: taskListRef,
  value_driver: valueDriverListRef,
  value_driver_period_data: valueDriverPeriodDataListRef,
  findings: findingsListRef,
});

const selectedTab = computed(() => {
  return tabs.value.find((tab) => tab.isActive)?.id || "";
});

const showSynergies = computed(() => {
  return ORG_MEMBER_DATA.client.enable_synergies;
});

const showTasks = computed(() => {
  return ORG_MEMBER_DATA.client.enable_task_custom_fields;
});

const showFindings = computed(() => {
  return (
    ORG_MEMBER_DATA.client.enable_findings &&
    ORG_MEMBER_DATA.client.enable_finding_custom_fields
  );
});

const handleSelectTab = (tabId: string) => {
  tabs.value = tabs.value.map((tab) => {
    return {
      ...tab,
      isActive: tab.id === tabId,
    };
  });
};

const addField = () => {
  // @ts-ignore: showAddForm
  listRefsMap[selectedTab.value]?.showAddForm();
};

const handleCustomFieldAction = (
  action: "added" | "updated",
  field: FieldItem,
) => {
  const payload: Record<string, string> = {
    object_type: field.object_type,
    type: field.field_type,
    name: field.label,
  };

  if (field.field_type === "number") {
    payload.digit_grouping = field.extra.digit_grouping;

    if (field.extra.symbol) {
      payload.symbol_filled = field.extra.symbol;
    }
  }

  if (field.field_type === "select") {
    payload.has_color = field.extra.select_options
      .some((item) => item.color)
      .toString();
  }

  insightTrack(
    action === "added"
      ? OrgSettingsCustomFieldsTrackingEvent.FieldAdded
      : OrgSettingsCustomFieldsTrackingEvent.FieldModified,
    payload,
  );
};

const trackFieldDelete = () => {
  insightTrack(OrgSettingsCustomFieldsTrackingEvent.FieldDeleted);
};
</script>
