<template>
  <ElInput
    ref="textareaRef"
    type="textarea"
    :rows="3"
    :validate-event="false"
    :class="$style.scrollbar"
    :model-value="editProps.value"
    :readonly="editProps.schema.isReadOnly"
    :placeholder="editProps.schema.placeholder"
    v-bind="editProps.veeField"
  />
</template>

<script setup lang="ts">
import { ElInput } from "element-plus";
import { get } from "lodash-es";
import { onMounted, ref } from "vue";

import { delayedCall } from "@drVue/common";

import type { FieldProps } from "../types";

interface Props {
  editProps: FieldProps;
}

const props = defineProps<Props>();

const textareaRef = ref<InstanceType<typeof ElInput> | null>(null);

onMounted(() => {
  const delay = get(props.editProps.schema, "extra.autofocus") ?? false;
  delayedCall(() => textareaRef.value?.focus(), delay);
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

.scrollbar {
  :global(textarea::-webkit-scrollbar) {
    width: 10px;
  }

  :global(textarea::-webkit-scrollbar-track) {
    border-radius: 10px;
    box-shadow: inset 0 0 10px 10px colors.$pr-200;
    border: solid 3px transparent;
  }

  :global(textarea::-webkit-scrollbar-thumb) {
    border-radius: 10px;
    box-shadow: inset 0 0 10px 10px colors.$pr-400;
    border: solid 3px transparent;
  }
}
</style>
