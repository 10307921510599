<template>
  <div
    :class="{
      [$style.skeleton]: true,
      [$style.skeleton_isCircle]: shape === 'circle',
      [$style.skeleton_isAnimated]: animate,
    }"
    :style="style"
    aria-hidden="true"
  />
</template>

<script setup lang="ts">
/**
 * Base skeleton component.
 */

import { computed } from "vue";

interface Props {
  shape?: "rectangle" | "circle";
  /**
   * Width of skeleton block.
   * For a circle, specifying the width means the same height value.
   */
  width?: string;
  /** Height of skeleton block. */
  height?: string;
  /** Display a "wave" animation on top of an element. */
  animate?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  shape: "rectangle",
  width: "100%",
  height: "1em",
  rounded: false,
  animate: false,
});

const style = computed(() => {
  const style: Record<string, string> = {
    width: props.width,
    height: props.height,
  };

  if (props.shape === "circle") {
    style.height = props.width;
    style.borderRadius = "50%";
  }

  return style;
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

.skeleton {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  background-color: colors.$pr-50;
  border-radius: 8px;
}

.skeleton_isCircle {
  border-radius: 50%;
}

@keyframes skeletonAnimation {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

.skeleton_isAnimated:after {
  content: "";
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  transform: translateX(-100%);
  animation: skeletonAnimation 1.2s infinite;

  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0)
  );
}
</style>
