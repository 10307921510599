import { ApiService } from "@drVue/api-service";

import type {
  FindingStatus,
  FindingStatusCreateOrUpdatePayload,
} from "./types";

export class FindingsStatusesApiService extends ApiService {
  public load() {
    const url: string = this.Urls["api:client-dashboard:fd-statuses-list"]();
    return this.get<FindingStatus[]>(url);
  }

  public create(payload: FindingStatusCreateOrUpdatePayload) {
    const url: string = this.Urls["api:client-dashboard:fd-statuses-list"]();
    return this.post<FindingStatus, FindingStatusCreateOrUpdatePayload>(
      url,
      payload,
    );
  }

  public update(
    id: FindingStatus["id"],
    payload: FindingStatusCreateOrUpdatePayload,
  ) {
    const url: string =
      this.Urls["api:client-dashboard:fd-statuses-detail"](id);
    return this.put<FindingStatus, FindingStatusCreateOrUpdatePayload>(
      url,
      payload,
    );
  }

  public remove(id: FindingStatus["id"]) {
    const url: string =
      this.Urls["api:client-dashboard:fd-statuses-detail"](id);
    return this.delete<void>(url);
  }
}
