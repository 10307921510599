import espace from "lodash/escape";

import { generateUUID } from "@drVue/common";

export enum ToastType {
  Success = "success",
  Info = "info",
  Warning = "warning",
  Danger = "danger",
}

export interface ToastOptions {
  tag?: string;
  duration?: number;
  onClick?: () => void;
}

export interface Toast {
  options?: ToastOptions;

  id: string;
  type: ToastType;
  message: string;
}

class ToastsService {
  items: Toast[] = [];

  setItems(items: Toast[]) {
    this.items = items;
  }

  add(type: ToastType, message: string, options?: ToastOptions) {
    const toast: Toast = {
      id: generateUUID(),
      type: type,
      message: espace(message),
      options,
    };

    if (toast.options?.tag) {
      const i = this.items.findIndex((i) => {
        if (!i.options) return false;

        return i.options.tag === toast.options?.tag;
      });

      if (i > -1) {
        this.items.splice(i, 1, toast);
      } else {
        this.items.push(toast);
      }
    } else {
      this.items.push(toast);
    }
  }

  remove(id: string) {
    const i = this.items.findIndex((i) => i.id === id);
    if (i > -1) {
      this.items.splice(i, 1);
    }
  }

  removeByTag(tag: string) {
    this.items = this.items.filter((i) => {
      const hasOptionsAndTheSameTag = !!i.options && i.options.tag === tag;
      return !hasOptionsAndTheSameTag;
    });
  }
}

export const Toasts = new ToastsService();
