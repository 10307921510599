<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <ElForm :model="editItem" label-position="top">
    <div v-if="fields.length === 0" class="text-center">
      There are no metadata fields
      <p v-if="isAbleToCreateFields">
        <a :href="dashboardUrl">Create fields for {{ label }}</a>
      </p>
    </div>
    <ElFormItem v-for="field in fields" :key="field.key" :label="field.label">
      <DrDatePicker
        v-if="field.field_type === FieldItemTypes.Date"
        v-model="editItem[field.key]"
        type="date"
        clearable
        style="width: 100%"
      />

      <ElSelect
        v-else-if="field.field_type === FieldItemTypes.Select"
        v-model="editItem[field.key]"
        style="width: 100%"
        clearable
        placeholder=""
      >
        <!-- @vue-ignore: Property `options` does not exist on type of extra -->
        <ElOption
          v-for="item in field.extra.options"
          :key="item"
          :label="item"
          :value="item"
        />
      </ElSelect>

      <ElSelect
        v-else-if="field.field_type === FieldItemTypes.MultiSelect"
        v-model="editItem[field.key]"
        style="width: 100%"
        clearable
        multiple
        placeholder=""
      >
        <!-- @vue-ignore: Property `options` does not exist on type of extra -->
        <ElOption
          v-for="item in field.extra.options"
          :key="item"
          :label="item"
          :value="item"
        />
      </ElSelect>

      <ElInput
        v-else
        v-model="editItem[field.key]"
        clearable
        :autosize="{ minRows: 2 }"
        :type="
          field.field_type === FieldItemTypes.Number ? 'number' : 'textarea'
        "
      />
    </ElFormItem>
    <slot name="footer" />
  </ElForm>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrDatePicker from "@shared/ui/dr-datepicker/DrDatepicker.vue";

import { APP_SETTINGS, ORG_MEMBER_DATA } from "@setups/data";
import { FieldItemTypes } from "@drVue/api-service/client-dashboard";

import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { PropType } from "vue";

interface Data {
  FieldItemTypes: any;
}

export default defineComponent({
  name: "CustomFieldsEditForm",
  components: { DrDatePicker },
  props: {
    editItem: { required: true, type: Object as PropType<any> },
    fields: { required: true, type: Array as PropType<FieldItem[]> },
    label: { required: true, type: String as PropType<string> },
  },
  data(): Data {
    return {
      FieldItemTypes: FieldItemTypes,
    };
  },
  computed: {
    dashboardUrl(): any {
      const clientId = ORG_MEMBER_DATA?.client.id;
      if (!clientId) {
        return "";
      }
      return `${location.protocol}//${APP_SETTINGS.WEBSITE.HOST}/dashboard/${clientId}/#/settings/metadata`;
    },
    isAbleToCreateFields(): any {
      return (
        ORG_MEMBER_DATA.client.enable_dashboard &&
        ORG_MEMBER_DATA.group.is_administrator
      );
    },
  },
});
</script>
