import { ElMessageBox } from "element-plus";
import { markRaw, type Raw } from "vue";

import { insightTrack, RoomDataroomMenuEvent } from "@app/insight";
import { APP_SETTINGS, ROOM_DATA } from "@app/setups";
import { ROOM_MEMBER_DATA } from "@app/setups/data";
import { AiAccess } from "@app/setups/enums";
import { DrStore } from "@app/vue";
import { $notifyDanger, $notifySuccess } from "@app/vue/common";
import {
  type Document,
  DocumentsApiService,
  type Folder,
  isDocument,
  isFolder,
} from "@app/vue/store/modules/room/documents/DocumentsApiService";
import FolderMenuAiDialog from "../components/FolderMenuAiDialog.vue";
import FolderMenuAttachToRequest from "../components/FolderMenuAttachToRequest.vue";
import FolderMenuChangeIndex from "../components/FolderMenuChangeIndex.vue";
import FolderMenuCopy from "../components/FolderMenuCopy.vue";
import FolderMenuMove from "../components/FolderMenuMove.vue";
import FolderMenuNewFolder from "../components/FolderMenuNewFolder.vue";
import FolderMenuRename from "../components/FolderMenuRename.vue";
import {
  type FolderMenuParams,
  getFolderMenuEntityTitle,
  getFolderMenuSelectedText,
} from "../utils";
import {
  type MenuComponent,
  useFolderMenuPersistedBus,
} from "./useFolderMenuBus";

export type FolderMenuItem = {
  name: string;
  action?:
    | ((value: Folder | Document) => void)
    | ((value: Folder) => void)
    | ((value: Document) => void);
  menuComponent?: Raw<MenuComponent>;
  getIsVisible: (value: Folder | Document) => boolean;
  track?: (value: Folder | Document) => void;
};

const getIsNotRoot = <Value extends Folder | Document>(
  value: Value,
): value is Value & Required<Pick<Value, "parent">> =>
  isFolder(value) && value.parent !== undefined;

const getHasChildren = (value: Folder | Document): boolean =>
  isFolder(value) && value.folders.length !== 0;

const api = new DocumentsApiService();

export const useFolderMenu = (actions: {
  view?: (file: Document) => void;
  open?: (folder: Folder) => void;
  download?: (value: Document | Folder) => void;
  editCustomData?: (value: Document | Folder) => void;
  expandFolder?: () => void;
  collapseFolder?: () => void;
  activity?: (value: Document) => void;
  updateItemsList: () => void;
}) => {
  const folderMenuPersistedBus = useFolderMenuPersistedBus();

  const bookmarkItem = async (value: Document | Folder) => {
    await DrStore.dispatch("room/documents/toggleBookmark", value);

    actions.updateItemsList();
  };

  const deleteItem = (value: Document | Folder) => {
    folderMenuPersistedBus.emit("menu:persisted", true);

    const isDocumentValue = isDocument(value);

    DrStore.dispatch("room/documents/archiveItems", {
      folders: isDocumentValue ? [] : [value],
      documents: isDocumentValue ? [value] : [],
    } satisfies FolderMenuParams).finally(() => {
      folderMenuPersistedBus.emit("menu:persisted", false);
    });
  };

  const exportIndexesPdf = async (value: Folder) => {
    await api.exportIndexes({ fmt: "pdf", folder_id: value.id });
  };

  const exportIndexesXlsx = async (value: Folder) => {
    await api.exportIndexes({ fmt: "xlsx", folder_id: value.id });
  };

  const restoreItems = (params: FolderMenuParams) => {
    const selectedText = getFolderMenuSelectedText(params);
    const pluralisedAction =
      params.documents.length + params.folders.length === 1 ? "has" : "have";

    return new Promise<void>((resolve) => {
      ElMessageBox({
        title: `Restore ${getFolderMenuEntityTitle(params)}`,
        message: `Are you sure you want to restore ${selectedText}?`,
        confirmButtonText: "Restore",
        showCancelButton: true,
        icon: undefined,
        callback: async (action: string) => {
          if (action === "confirm") {
            await api
              .restoreItems({
                document_ids: params.documents.map((item) => item.id),
                folder_ids: params.folders.map((item) => item.id),
              })
              .then((response) => {
                actions.updateItemsList();
                DrStore.dispatch("room/documents/syncTree");

                $notifySuccess(
                  `The ${selectedText} ${pluralisedAction} been restored.`,
                );

                return response;
              })
              .catch(() => {
                $notifyDanger(`Failed to restore ${selectedText}.`);
              });
          }

          resolve();
        },
      });
    });
  };

  const deleteItems = (params: FolderMenuParams) => {
    const selectedText = getFolderMenuSelectedText(params);
    const pluralisedAction =
      params.documents.length + params.folders.length === 1 ? "has" : "have";

    return new Promise<void>((resolve) => {
      ElMessageBox({
        title: `Delete ${getFolderMenuEntityTitle(params)} permanently`,
        message: `Are you sure you want to permanently delete ${selectedText}? This action cannot be undone.`,
        confirmButtonText: "Delete",
        customClass: "el-message-box--warning",
        showCancelButton: true,
        icon: undefined,
        callback: async (action: string) => {
          if (action === "confirm") {
            await api
              .deleteItems({
                document_ids: params.documents.map((item) => item.id),
                folder_ids: params.folders.map((item) => item.id),
              })
              .then((response) => {
                actions.updateItemsList();

                $notifySuccess(
                  `The ${selectedText} ${pluralisedAction} been deleted.`,
                );

                return response;
              })
              .catch(() => {
                $notifyDanger(`Failed to delete ${selectedText}.`);
              });
          }

          resolve();
        },
      });
    });
  };

  const folderMenu: FolderMenuItem[][] = [
    [
      {
        name: "View",
        action: actions?.view,
        getIsVisible: isDocument,
      },
      {
        name: "Analyse with AI",
        menuComponent: markRaw(FolderMenuAiDialog),
        getIsVisible: (value) =>
          isDocument(value) &&
          value.processing?.is_viewable === true &&
          ROOM_DATA.isAiEnabled &&
          ROOM_MEMBER_DATA.group.ai_access === AiAccess.BASIC,
        track: () => {
          insightTrack(RoomDataroomMenuEvent.AiDialogOpen, { source: "menu" });
        },
      },
      {
        name: "Open",
        action: actions?.open,
        getIsVisible: isFolder,
      },
      {
        name: "Download",
        action: actions?.download,
        getIsVisible: (value) =>
          value.download &&
          (isDocument(value) ||
            (ROOM_DATA.enableBulkDownload &&
              ROOM_DATA.userPermissions.administrator)),
      },
      {
        name: "Bookmark",
        action: bookmarkItem,
        getIsVisible: (value) =>
          (getIsNotRoot(value) || isDocument(value)) && !value.bookmarked,
        track: (value) => {
          insightTrack(RoomDataroomMenuEvent.Bookmark, {
            item: isFolder(value) ? "folder" : "document",
          });
        },
      },
      {
        name: "Unbookmark",
        action: bookmarkItem,
        getIsVisible: (value) =>
          (getIsNotRoot(value) || isDocument(value)) && !!value.bookmarked,
        track: (value) => {
          insightTrack(RoomDataroomMenuEvent.Unbookmark, {
            item: isFolder(value) ? "folder" : "document",
          });
        },
      },
      {
        name: "Attach To Request",
        menuComponent: markRaw(FolderMenuAttachToRequest),
        getIsVisible: (value) =>
          (getIsNotRoot(value) || isDocument(value)) &&
          ROOM_DATA.userPermissions.viewTasks &&
          ROOM_DATA.userPermissions.canManageTasks,
        track: (value) => {
          insightTrack(RoomDataroomMenuEvent.AttachToRequest, {
            item: isFolder(value) ? "folder" : "document",
          });
        },
      },
    ],
    [
      {
        name: "New Folder",
        menuComponent: markRaw(FolderMenuNewFolder),
        getIsVisible: (value) => isFolder(value) && value.edit,
        track: (value) => {
          insightTrack(RoomDataroomMenuEvent.NewFolder, {
            item: "folder",
          });
        },
      },
      {
        name: "Rename",
        menuComponent: markRaw(FolderMenuRename),
        getIsVisible: (value) =>
          (getIsNotRoot(value) || isDocument(value)) && value.edit,
        track: (value) => {
          insightTrack(RoomDataroomMenuEvent.Rename, {
            item: isFolder(value) ? "folder" : "document",
          });
        },
      },
      {
        name: "Edit Index",
        menuComponent: markRaw(FolderMenuChangeIndex),
        getIsVisible: (value) =>
          getIsNotRoot(value) ? value.parent.edit : value.edit,
        track: (value) => {
          insightTrack(RoomDataroomMenuEvent.IndexChange, {
            item: isFolder(value) ? "folder" : "document",
          });
        },
      },
      {
        name: "Edit Custom Data",
        action: actions?.editCustomData,
        getIsVisible: (value) => value.edit && APP_SETTINGS.WEBSITE.IS_DEALROOM,
      },
    ],
    [
      {
        name: "Move",
        menuComponent: markRaw(FolderMenuMove),
        getIsVisible: (value) =>
          (getIsNotRoot(value) || isDocument(value)) && value.edit,
        track: (value) => {
          insightTrack(RoomDataroomMenuEvent.Move, {
            item: isFolder(value) ? "folder" : "document",
          });
        },
      },
      {
        name: "Copy",
        menuComponent: markRaw(FolderMenuCopy),
        getIsVisible: (value) =>
          (getIsNotRoot(value) || isDocument(value)) && value.edit,
        track: (value) => {
          insightTrack(RoomDataroomMenuEvent.Copy, {
            item: isFolder(value) ? "folder" : "document",
          });
        },
      },
      {
        name: "Delete",
        action: deleteItem,
        getIsVisible: (value) =>
          (getIsNotRoot(value) || isDocument(value)) && value.edit,
        track: (value) => {
          insightTrack(RoomDataroomMenuEvent.Remove, {
            item: isFolder(value) ? "folder" : "document",
          });
        },
      },
    ],
    [
      {
        name: "Expand all folders",
        action: actions?.expandFolder,
        getIsVisible: getHasChildren,
      },
      {
        name: "Collapse all folders",
        action: actions?.collapseFolder,
        getIsVisible: getHasChildren,
      },
      {
        name: "Export indexes to PDF",
        action: exportIndexesPdf,
        getIsVisible: getHasChildren,
        track: () => insightTrack(RoomDataroomMenuEvent.ExportIndexPdf),
      },
      {
        name: "Export indexes to XLSX",
        action: exportIndexesXlsx,
        getIsVisible: getHasChildren,
        track: () => insightTrack(RoomDataroomMenuEvent.ExportIndexXlsx),
      },
      {
        name: "Activity",
        action: actions?.activity,
        getIsVisible: (value) =>
          isDocument(value) && ROOM_DATA.userPermissions.administrator,
      },
    ],
  ];

  return {
    folderMenu,
    bookmarkItem,
    exportIndexesPdf,
    exportIndexesXlsx,
    restoreItems,
    deleteItems,
  };
};
