<template>
  <ElSelect
    v-model="zoomLevel"
    :append-to-body="'body'"
    class="dash-rooms-timeline-zoom dash-container__toolbar-item"
  >
    <ElOption
      v-for="zoom in ZOOM_LEVELS"
      :key="zoom.level"
      :label="zoom.label"
      :value="zoom.level"
    />
  </ElSelect>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { ZOOM } from "@setups/types";

import type { ZoomLevel } from "@setups/types";

interface Data {
  ZOOM_LEVELS: any;
}

export default defineComponent({
  name: "TimelineZoomSelector",
  data(): Data {
    return {
      ZOOM_LEVELS: [
        { level: ZOOM.FitToScreen, label: "Auto" },
        { level: ZOOM.Days, label: "Days" },
        { level: ZOOM.Weeks, label: "Weeks" },
        { level: ZOOM.Months, label: "Months" },
        { level: ZOOM.Quarters, label: "Quarters" },
        { level: ZOOM.Years, label: "Years" },
      ],
    };
  },
  computed: {
    zoomLevel: {
      get: function (): ZoomLevel {
        return (
          this.$store.state.common.customViews.current.settings.timelineZoom ||
          ZOOM.FitToScreen
        );
      },
      set: function (level: ZoomLevel) {
        this.$store.commit("common/customViews/updateCurrentSettings", {
          timelineZoom: level,
        });
      },
    },
  },
});
</script>
