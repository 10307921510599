import { insightTrack } from "@app/insight";
import taskAttachments_attachmentsSelectDropdownHtml from "../../../templates/components/task-attachments/attachments-select-dropdown.html?raw";

export default function drAttachmentsDropdown() {
  return {
    template: taskAttachments_attachmentsSelectDropdownHtml,
    bindings: {
      selectedFolders: "=",
      selectedFiles: "=",
      onSubmit: "=",
      btnTop: "=",
    },
    controller: [
      "$scope",
      "RoomConfig",
      "DocumentsService",
      function ($scope, RoomConfig, DocumentsService) {
        var $ctrl = this;
        $ctrl.rows = [];
        $ctrl.hasChanges = false;

        $ctrl.RoomConfig = RoomConfig;
        $ctrl.Documents = DocumentsService;
        $ctrl.bookmarkedItems = { items: [] };
        $ctrl.expandedFolders = {};
        $ctrl.searchDocumentQuery = undefined;

        $ctrl.rowElementHeight = rowElementHeight;
        $ctrl.toggleFolderExpanded = toggleFolderExpanded;
        $ctrl.isItemSelected = isItemSelected;
        $ctrl.toggleItemSelected = toggleItemSelected;
        $ctrl.submitChanges = submitChanges;

        var bookmarksExpanded = false;
        var suggestionsExpanded = false;
        var ELEMENT_TYPES = {
          SectionBegin: "section-begin",
          Divider: "divider",
          Subheader: "subheader",
          Item: "item",
        };
        $ctrl.$onInit = function () {
          $scope.$watchCollection(
            "[$ctrl.searchDocumentQuery, $ctrl.storage, $ctrl.Documents.loading, $ctrl.Documents.errors]",
            function () {
              if (!$ctrl.Documents.isLoaded() || !$ctrl.storage) {
                return;
              }
              setItems();
            },
          );

          // convert source data
          // [{folder_id: ID}..] or [{document_id; ID}, ..]
          // into simple objects ( {id: true, ...} ) to make it easy to check if an element is active
          $scope.$watchCollection(
            "[$ctrl.selectedFolders, $ctrl.selectedFiles]",
            function () {
              if (
                angular.isUndefined($ctrl.selectedFolders) ||
                angular.isUndefined($ctrl.selectedFiles)
              )
                return;

              $ctrl.storage = { folder: {}, file: {} };
              $ctrl.selectedFolders.forEach(function (data) {
                $ctrl.storage.folder[data.folder_id] = true;
              });
              $ctrl.selectedFiles.forEach(function (data) {
                var docId = data.document_id;
                $ctrl.storage.file[docId] = true;
              });
            },
          );
        };

        function rowElementHeight(item) {
          switch (item.type) {
            case ELEMENT_TYPES.Subheader:
              return 27;
            case ELEMENT_TYPES.Divider:
              return 15;
            default:
              return 31;
          }
        }

        function setItems() {
          $ctrl.rows = [];
          fillBookmarks();

          if ($ctrl.rows.length) {
            $ctrl.rows.push({
              type: ELEMENT_TYPES.Divider,
              level: "0",
            });
          }
          fillDocuments();

          function addItem(item, level) {
            $ctrl.rows.push({
              type: ELEMENT_TYPES.Item,
              level: level,
              item: item,
            });
            if (item.type === "folder" && $ctrl.expandedFolders[item.id]) {
              item.items.forEach(function (child) {
                addItem(child, level + 1);
              });
            }
          }

          function fillBookmarks() {
            var bookmarkedStorages =
              $ctrl.Documents.getFlattedBookmarkedStorages();
            var expanded = $ctrl.bookmarkedItems.expanded;
            $ctrl.bookmarkedItems.items = $ctrl.Documents.findItems(
              $ctrl.searchDocumentQuery,
              bookmarkedStorages.files,
              bookmarkedStorages.folders,
            );

            $ctrl.bookmarkedItems.expanded =
              ($ctrl.searchDocumentQuery &&
                $ctrl.bookmarkedItems.items.length) ||
              expanded;

            if (!$ctrl.bookmarkedItems.items.length) {
              return;
            }

            $ctrl.rows.push({
              type: ELEMENT_TYPES.SectionBegin,
              level: 0,
              title: "Bookmarked Files",
              iconClass: "fa-bookmark",
              expanded: bookmarksExpanded,
              onToggle: function () {
                bookmarksExpanded = !bookmarksExpanded;
                setItems();
              },
            });

            if (!bookmarksExpanded) {
              return;
            }
            $ctrl.bookmarkedItems.items.forEach(function (item) {
              addItem(item, 1);
            });
          }

          function fillDocuments() {
            var query = $ctrl.searchDocumentQuery;
            $ctrl.documents = query
              ? $ctrl.Documents.findItems(query)
              : $ctrl.Documents.rootFolder.items;

            $ctrl.documents.forEach(function (item) {
              addItem(item, 0);
            });
          }
        }

        function toggleFolderExpanded(folder) {
          $ctrl.expandedFolders[folder.id] = !$ctrl.expandedFolders[folder.id];
          setItems();
        }

        function isItemSelected(item) {
          return $ctrl.storage[item.type][item.id];
        }

        function toggleItemSelected(item) {
          $ctrl.hasChanges = true;
          $ctrl.storage[item.type][item.id] =
            !$ctrl.storage[item.type][item.id];
        }

        function submitChanges() {
          // get all ids with value == true from {id: true, id: false, ...}
          function clear(storage, isDocument) {
            var tmp = [];
            Object.keys(storage).forEach(function (itemId) {
              var val = storage[itemId];
              if (val) {
                var item = isDocument
                  ? { document_id: itemId }
                  : { folder_id: itemId };
                tmp.push(item);
              }
            });
            return tmp;
          }

          const folders = clear($ctrl.storage.folder);
          const documents = clear($ctrl.storage.file, true);

          $ctrl.onSubmit(folders, documents);
        }
      },
    ],
  };
}
