import type { TasksRelatedState } from "./TasksRelatedState";
import type { RootState } from "@drVue/store/state";
import type { GetterTree } from "vuex";

const emptyDocumentsArray: [] = [];
const emptyFoldersArray: [] = [];

export const tasksRelatedGetters: GetterTree<TasksRelatedState, RootState> = {
  getTaskAllFolders(state) {
    return function (taskId: number | string) {
      return state.taskFolders[taskId] || emptyFoldersArray;
    };
  },

  getTaskAllDocuments(state) {
    return function (taskId: number | string) {
      return state.taskDocs[taskId] || emptyDocumentsArray;
    };
  },

  getDocumentTasks(state) {
    return function (documentId: number | string) {
      return state.documentTasks[documentId];
    };
  },

  getFolderTasks(state) {
    return function (folderId: number | string) {
      return state.folderTasks[folderId];
    };
  },

  getIsReady(state) {
    return state.isReady;
  },
};
