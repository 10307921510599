import { actions } from "./actions";
import { mutations } from "./mutations";
import { PreferencesState } from "./state";

import type { RootState } from "@drVue/store/state";
import type { Module } from "vuex";

export type { State } from "./types";

export const Preferences: Module<PreferencesState, RootState> = {
  actions,
  mutations,
  namespaced: true,
  state: new PreferencesState(),
};
