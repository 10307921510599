const VERBS = {
  category_add: {
    label: "Created category",
    item_src: "object",
    tasksDepended: true,
  },
  category_is_archived: {
    label: "Deleted / restored category",
    item_src: "target",
    tasksDepended: true,
  },
  category_name: {
    label: "Renamed category",
    item_src: "target",
    tasksDepended: true,
  },
  category_parent: {
    label: "Moved category",
    item_src: "object",
    tasksDepended: true,
  },
  task_viewed: {
    label: "Viewed request",
    item_src: "target",
    tasksDepended: true,
  },
  task_add: {
    label: "Created request",
    item_src: "object",
    tasksDepended: true,
  },
  task_is_archived: {
    label: "Deleted / restored request",
    item_src: "target",
    tasksDepended: true,
  },
  task_due_date: {
    label: "Changed request due date",
    item_src: "target",
    tasksDepended: true,
  },
  task_start_date: {
    label: "Changed request start date",
    item_src: "target",
    tasksDepended: true,
  },
  task_body: {
    label: "Changed request description",
    item_src: "target",
    tasksDepended: true,
  },
  task_description: {
    label: "Changed request description",
    item_src: "target",
    tasksDepended: true,
  },
  task_priority: {
    label: "Changed request priority",
    item_src: "target",
    tasksDepended: true,
  },
  task_status: {
    label: "Changed request status",
    item_src: "target",
    tasksDepended: true,
  },
  task_category: {
    label: "Moved request",
    item_src: "object",
    tasksDepended: true,
  },
  task_comment_add: {
    label: "Added comment in request",
    item_src: "target",
    tasksDepended: true,
  },
  task_comment_body: {
    label: "Changed comment text in request",
    item_src: "target",
    tasksDepended: true,
  },
  task_assignee_add: {
    label: "Task assignee is added",
    item_src: "target",
    tasksDepended: true,
  },
  task_assignee_delete: {
    label: "Task assignee is removed",
    item_src: "target",
    tasksDepended: true,
  },
  task_reviewer_add: {
    label: "Task reviewer is added",
    item_src: "target",
    tasksDepended: true,
  },
  task_reviewer_delete: {
    label: "Task reviewer is removed",
    item_src: "target",
    tasksDepended: true,
  },
  folder_add: {
    label: "Created folder",
    item_src: "object",
  },
  folder_name: {
    label: "Renamed folder",
    item_src: "target",
  },
  folder_index: {
    label: "Changed index of folder",
    item_src: "target",
  },
  folder_parent: {
    label: "Moved folder",
    item_src: "target",
  },
  folder_is_archived: {
    label: "Deleted / restored folder",
    item_src: "target",
  },
  folder_is_removed: {
    label: "Removed folder",
    item_src: "target",
  },
  document_upload: {
    label: "Uploaded document",
    item_src: "target",
  },
  document_overwrite: {
    label: "Updated document",
    item_src: "target",
  },
  document_name: {
    label: "Renamed document",
    item_src: "target",
  },
  document_index: {
    label: "Changed index of document",
    item_src: "target",
  },
  document_folder: {
    label: "Moved document",
    item_src: "object",
  },
  document_viewed: {
    label: "Viewed document",
    item_src: "target",
  },
  document_is_archived: {
    label: "Deleted / restored document",
    item_src: "target",
  },
  document_is_removed: {
    label: "Removed document",
    item_src: "target",
  },
  document_download: {
    label: "Downloaded document",
    item_src: "target",
  },
  document_bulk_download: {
    label: "Bulk downloaded document",
    item_src: "target",
  },

  user_room_session_begin: {
    label: "Logged in",
  },
  nda_accept: {
    label: "NDA is accepted",
    item_src: null,
  },
  group_permission_invite_add: {
    label: "Invite is added",
    item_src: "target",
  },
  group_permission_invite_delete: {
    label: "Invite is removed",
    item_src: "object",
  },
  group_permission_invite_group: {
    label: "Invite is moved",
    item_src: "object",
  },
};

export default VERBS;
