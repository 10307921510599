import { ApiService } from "../index";

import type { OpenIDProviders } from "./open-id-providers";

export interface OpenIDIntegration {
  title: string;
  openid_configuration_url: string;
  app_client_id: string;
  app_client_secret?: string;
}

export class OpenIDIntegrationApiService extends ApiService {
  private readonly listUrl: string;
  private readonly provider: OpenIDProviders;
  private readonly detailsUrl: string;

  constructor(provider: OpenIDProviders) {
    super();
    this.provider = provider;
    this.listUrl =
      this.Urls[`api:client-dashboard:sso:openid-integrations-list`]();
    this.detailsUrl = this.Urls[
      `api:client-dashboard:sso:openid-integrations-details`
    ](this.provider);
  }

  public details() {
    return this.get<OpenIDIntegration>(this.detailsUrl);
  }

  public create(data: { [key: string]: any }) {
    data.provider_id = this.provider;

    return this.post<OpenIDIntegration>(this.listUrl, data);
  }

  public update(data: { [key: string]: any }) {
    return this.put<OpenIDIntegration>(this.detailsUrl, data);
  }

  public destroy() {
    return this.delete<any>(this.detailsUrl);
  }
}
