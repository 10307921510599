<template>
  <DueDateEdit :edit-props="editProps" @change="handleChange" />
</template>

<script setup lang="ts">
import { insightTrack, TaskDetailsTrackingEvent } from "@app/insight";
import { DueDateEdit } from "@drVue/components/room/tasks/shared/widgets/due-date";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { Task } from "@drVue/store/pinia/room/tasks";

interface Props {
  editProps: EditFieldProps<Date, Task>;
}
defineProps<Props>();

function handleChange() {
  insightTrack(TaskDetailsTrackingEvent.DueDateSet);
}
</script>
