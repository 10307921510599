<template>
  <div class="deal-create__playbooks">
    <div
      v-for="playbook in playbooks"
      :key="playbook.id"
      class="deal-create__playbook"
      @click="select(playbook)"
    >
      <img
        :src="`/assets/internal/images/playbooks/${playbook.id}.jpg`"
        :srcset="
          `/assets/internal/images/playbooks/${playbook.id}.jpg 1x,` +
          `/assets/internal/images/playbooks/${playbook.id}@2x.jpg 2x`
        "
        alt=""
        class="deal-create__playbook-image"
      />
      <h4 class="deal-create__playbook-title">{{ playbook.title }}</h4>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { Playbook } from "@drVue/components/user-dashboard/playbooks/types";
import type { PropType } from "vue";

export default defineComponent({
  name: "PlaybooksList",
  props: {
    playbooks: { required: true, type: Array as PropType<Playbook[]> },
  },
  emits: ["select"],
  methods: {
    select(playbook: Playbook) {
      this.$emit("select", playbook);
    },
  },
});
</script>
