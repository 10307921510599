<template>
  <form ref="form" :action="endpointUrl" style="display: none" method="post">
    <!-- @vue-skip -->
    <input
      v-for="(value, name) in endpointParams"
      :key="name"
      :value="value"
      :name="name"
    />
    <input type="hidden" ref="csrfTokenInput" name="csrfmiddlewaretoken" />
    <input ref="drOrgUserIdInput" name="dr_org_user_id" />
    <input ref="allowInput" name="allow" />
  </form>
</template>

<script lang="ts" setup>
import { ref } from "vue";

import { getCsrfToken } from "@setups/data";

import type { Dictionary } from "@drVue/types";
import type { PropType } from "vue";

const props = defineProps({
  endpointUrl: {
    type: String as PropType<string>,
    required: true,
  },
  endpointParams: {
    type: Object as PropType<Dictionary<string>>,
    required: true,
  },
});

const form = ref<HTMLFormElement | null>(null);
const csrfTokenInput = ref<HTMLInputElement | null>(null);
const drOrgUserIdInput = ref<HTMLInputElement | null>(null);
const allowInput = ref<HTMLInputElement | null>(null);

const submitForm = (allow: boolean, drOrgUserId: string = "") => {
  allowInput.value!.value = allow ? "true" : "";
  drOrgUserIdInput.value!.value = drOrgUserId;
  csrfTokenInput.value!.value = getCsrfToken();
  form.value!.submit();
};

defineExpose({
  submitForm,
});
</script>
