export enum TasksTableDefaultViewSettingsTrackingEvent {
  Opened = "room.tasks.default_view_settings_opened",
  Saved = "room.tasks.default_view_settings_saved",
}

export enum TasksTableTrackingEvent {
  PriorityChanged = "room.tasks.priority_change",
  StatusChanged = "room.tasks.status_change",
  AssigneeSet = "room.tasks.assignee_set",
  ReviewerSet = "room.tasks.reviewer_set",
  LabelsSet = "room.tasks.labels_set",
  StartDateSet = "room.tasks.start_date_set",
  DueDateSet = "room.tasks.due_date_set",
  CommentModalOpened = "room.tasks.comment_modal_opened",
  CommentModalCommentAdded = "room.tasks.comment_modal_comment_added",
  RequestModalOpened = "room.tasks.request_modal_opened",
  RowContextMenuClicked = "room.tasks.row_context_menu_clicked",
}

export enum TasksTimelineTrackingEvent {
  AssigneeSet = "room.timeline.assignee_set",
  DueDateSet = "room.timeline.due_date_set",
  StartDateSet = "room.timeline.start_date_set",
  PriorityChanged = "room.timeline.priority_change",
  StatusChanged = "room.timeline.status_change",
  TodayClicked = "room.timeline.today_clicked",
  ColumnsCustomized = "room.timeline.columns_customized",
}

export enum TaskDetailsTrackingEvent {
  StatusChanged = "room.task_details.status_change",
  PriorityChanged = "room.task_details.priority_change",
  StartDateSet = "room.task_details.start_date_set",
  DueDateSet = "room.task_details.due_date_set",
  CommentAdded = "room.task_details.comment_added",
  CommentRemoved = "room.task_details.comment_removed",
  CommentApproved = "room.task_details.comment_approved",
  CommentUpdated = "room.task_details.comment_updated",
  OpenModal = "room.task_details.open_modal",
  Follow = "room.task_details.follow",
  Unfollow = "room.task_details.unfollow",
  NavBack = "room.task_details.go_to_previous_task",
  NavForward = "room.task_details.go_to_next_task",
  Archived = "room.task_details.archived",
  Restored = "room.task_details.restored",
  LabelsAdded = "room.task_details.labels_added",
  LabelsRemoved = "room.task_details.labels_removed",
  ActivityShown = "room.task_details.activity_shown",
  CategoryChanged = "room.task_details.category_changed",
  AssigneesAdded = "room.task_details.assignees_added",
  AssigneesRemoved = "room.task_details.assignees_removed",
  ReviewersAdded = "room.task_details.reviewers_added",
  ReviewersRemoved = "room.task_details.reviewers_removed",
  FollowersAdded = "room.task_details.followers_added",
  FollowersRemoved = "room.task_details.followers_removed",
  FindingAdded = "room.task_details.finding_added",
  FindingRemoved = "room.task_details.finding_removed",
  FindingOpened = "room.task_details.finding_opened",
  AttachmentDrop = "room.task_details.attachment_drop",
  AttachmentUpload = "room.task_details.attachment_upload_click",
  AttachmentAdd = "room.task_details.attachment_add_click",
  AttachmentAdded = "room.task_details.attachment_added",
  AttachmentRemoved = "room.task_details.attachment_removed",
  DependencyAdded = "room.task_details.dependency_added",
  DependencyRemoved = "room.task_details.dependency_removed",
  DependencyOpened = "room.task_details.dependency_opened",
}

export enum TasksTableLayoutSettingsTrackingEvent {
  LayoutSettingsOpened = "room.tasks.table.layout_settings_opened",
  LayoutColumnsReordered = "room.tasks.table.layout_columns_reordered",
  LayoutColumnToggled = "room.tasks.table.layout_column_toggled",
}

export enum DynamicDatesTrackingEvent {
  DueDateCreated = "room.tasks.dynamic_due_date_created",
  StartDateCreated = "room.tasks.dynamic_start_date_created",
  DueDateRemoved = "room.tasks.dynamic_due_date_removed",
  StartDateRemoved = "room.tasks.dynamic_start_date_removed",
}

export enum DynamicDatesWidget {
  TaskTable = "room.tasks.table",
  TaskDetails = "room.tasks.details",
}

export enum RoomTasksCategoriesEvent {
  Added = "room.tasks.categories.added",
  Renamed = "room.tasks.categories.renamed",
  Moved = "room.tasks.categories.moved",
  Deleted = "room.tasks.categories.deleted",
  Reordered = "room.tasks.categories.reordered",
  Exported = "room.tasks.categories.exported",
}

export enum RoomTasksNavTreeMenuEvent {
  NewCategory = "room.tasks.nav_tree_menu.new_category",
  NewRequest = "room.tasks.nav_tree_menu.new_request",
  NewFinding = "room.tasks.nav_tree_menu.new_finding",
  ExpandAll = "room.tasks.nav_tree_menu.expand_all",
  CollapseAll = "room.tasks.nav_tree_menu.collapse_all",
  Rename = "room.tasks.nav_tree_menu.rename",
  Export = "room.tasks.nav_tree_menu.export",
  Move = "room.tasks.nav_tree_menu.move",
  Delete = "room.tasks.nav_tree_menu.delete",
}

export enum RoomTasksFiltersEvent {
  Filtered = "room.tasks.filtered",
  ShownAllFilters = "room.tasks.shown.all_filters_form",
}

export enum RoomTasksImportExportEvent {
  Imported = "room.tasks.import_export.imported",
  Exported = "room.tasks.import_export.exported",
}

export enum DealsScopeCriteriaFormEvent {
  PhaseSelected = "dashboard.settings.pipeline_access.deals_scope.phase_selected",
  CustomFieldSelected = "dashboard.settings.pipeline_access.deals_scope.custom_field_selected",
}

export enum RoomFindingsOverviewEvent {
  PageOpened = "room.findings.overview.page_opened",
  Filtered = "room.findings.overview.filtered",
  CategoryNavigated = "room.findings.overview.category_navigated",
  Searched = "room.findings.overview.searched",
  ArchiveOpened = "room.findings.overview.archive_opened",
  LayoutSaved = "room.findings.overview.layout_saved",
}

export enum RoomFindingsCreationEvent {
  ModalOpened = "room.findings.creation.modal_opened",
  Created = "room.findings.creation.created",
}

export enum RoomFindingsDetailsEvent {
  ModalOpened = "room.findings.details.modal_opened",
  TypeChanged = "room.findings.details.type_changed",
  StatusChanged = "room.findings.details.status_changed",
  SeverityChanged = "room.findings.details.severity_changed",
  LikelihoodChanged = "room.findings.details.likelihood_changed",
  TitleChanged = "room.findings.details.title_changed",
  DescriptionChanged = "room.findings.details.description_changed",
  MitigationPlanChanged = "room.findings.details.mitigation_plan_changed",
  ActualMitigationChanged = "room.findings.details.actual_mitigation_changed",
  AssigneesChanged = "room.findings.details.assignees_changed",
  FollowersChanged = "room.findings.details.followers_changed",
  TiesToChanged = "room.findings.details.ties_to_changed",
  CommentAdded = "room.findings.details.comment_added",
  CommentRemoved = "room.findings.details.comment_added",
}

export enum DealTasksEvent {
  TabOpened = "dashboard.deals.details.tasks_tab.opened",
  PageOpened = "dashboard.your_work.deal_tasks_page.opened",
  TaskOpening = "dashboard.your_work.deal_tasks_page.task_opening",
  TaskAdded = "dashboard.deals.details.tasks_tab.task_added",
  TaskRemoved = "dashboard.deals.details.tasks_tab.task_removed",
  TaskChanged = "dashboard.deals.details.tasks_tab.task_changed",
  TaskResolved = "dashboard.deals.details.tasks_tab.task_resolved",
  TaskUnresolved = "dashboard.deals.details.tasks_tab.task_unresolved",
}

export enum RoomTasksCreationEvent {
  ModalOpened = "room.tasks.creation.modal_opened",
  TaskCreated = "room.tasks.creation.created",
  TaskCreatedAdd = "room.tasks.creation.created_and_add",
  CategorySelected = "room.tasks.creation.category_selected",
  DescriptionFilled = "room.tasks.creation.description_filled",
  DocumentsAttached = "room.tasks.creation.document_attached",
  PrioritySet = "room.tasks.creation.priority_set",
  StatusSet = "room.tasks.creation.status_set",
  StartDateSet = "room.tasks.creation.start_date_set",
  DueDateSet = "room.tasks.creation.due_date_set",
  LabelsSet = "room.tasks.creation.labels_set",
  AssigneesSet = "room.tasks.creation.assignees_set",
  ReviewersSet = "room.tasks.creation.reviewers_set",
  FollowersSet = "room.tasks.creation.followers_set",
}

export enum OrgSettingsCustomFieldsTrackingEvent {
  FieldAdded = "dashboard.settings.custom_fields.field_added",
  FieldModified = "dashboard.settings.custom_fields.field_modified",
  FieldDeleted = "dashboard.settings.custom_fields.field_deleted",
  FieldValueChanged = "dashboard.settings.custom_fields.value_changed",
}

export enum OrgSettingsFindingsStatusesTrackingEvent {
  StatusAdded = "dashboard.settings.findings_statuses.status_added",
  StatusChanged = "dashboard.settings.findings_statuses.status_changed",
}

export enum RoomFindingsImportExportEvent {
  Imported = "room.findings.import_export.imported",
  Exported = "room.findings.import_export.exported",
}

export enum RoomFindingsCustomFieldsTrackingEvent {
  FieldAdded = "room.findings.custom_fields.field_added",
  FieldModified = "room.findings.custom_fields.field_modified",
  FieldDeleted = "room.findings.custom_fields.field_deleted",
  FieldValueChanged = "room.findings.custom_fields.value_changed",
}

export enum RoomDataroomMenuEvent {
  Rename = "room.dataroom.menu.rename",
  Move = "room.dataroom.menu.move",
  Copy = "room.dataroom.menu.copy",
  Create = "room.dataroom.menu.create",
  AiDialogOpen = "room.dataroom.menu.ai_dialog_open",
  AttachToRequest = "room.dataroom.menu.attach_to_request",
  NewFolder = "room.dataroom.menu.new_request",
  Bookmark = "room.dataroom.menu.bookmark",
  Unbookmark = "room.dataroom.menu.unbookmark",
  Download = "room.dataroom.menu.download",
  Remove = "room.dataroom.menu.remove",
  IndexChange = "room.dataroom.menu.index_change",
  IndexResetByName = "room.dataroom.menu.index_reset_by_name",
  IndexResetByOldIndex = "room.dataroom.menu.rename.index_reset_by_old_index",
  ExportIndexPdf = "room.dataroom.menu.export_index_pdf",
  ExportIndexXlsx = "room.dataroom.menu.export_index_xlsx",
}

export enum RoomRequestsBulkEvent {
  StartDateDynamic = "room.requests.bulk.start_date.set_dynamic",
  StartDateStatic = "room.requests.bulk.start_date.set_static",
  DueDateDynamic = "room.requests.bulk.due_date.set_dynamic",
  DueDateStatic = "room.requests.bulk.due_date.set_static",
  AssigneesSet = "room.requests.bulk.assignees",
  RemindUsed = "room.requests.bulk.remind",
  StatusUpdate = "room.requests.bulk.status",
  PriorityUpdate = "room.requests.bulk.priority",
  LabelsUpdate = "room.requests.bulk.labels",
  Move = "room.requests.bulk.move",
  Copy = "room.requests.bulk.copy",
  Delete = "room.requests.bulk.delete",
  Restore = "room.requests.bulk.restore",
  DynamicDatesDelete = "room.requests.bulk.dynamic_dates_delete",
}

export enum RoomDataroomBulkEvent {
  Download = "room.dataroom.bulk.download",
  Upload = "room.dataroom.bulk.upload",
  NewFolder = "room.dataroom.bulk.new_folder",
  NewRequest = "room.dataroom.bulk.new_request",
  Move = "room.dataroom.bulk.move",
  Copy = "room.dataroom.bulk.copy",
  AiReportOpen = "room.dataroom.bulk.ai_report_open",
  AiReportApply = "room.dataroom.bulk.ai_report_apply",
  Bookmark = "room.dataroom.bulk.bookmark",
  Remove = "room.dataroom.bulk.remove",
  Restore = "room.dataroom.bulk.restore",
  DeletePermanently = "room.dataroom.bulk.delete_permanently",
  IndexChange = "room.dataroom.bulk.index_change",
  AttachToRequest = "room.dataroom.bulk.attach_to_request",
  ExportIndexPdf = "room.dataroom.bulk.export_index_pdf",
  ExportIndexXlsx = "room.dataroom.bulk.export_index_xlsx",
}

export enum DashboardPeopleTableEvent {
  Revoke = "dashboard.people.table.revoke",
  Remove = "dashboard.people.table.remove",
}

export enum DashboardCardEvent {
  Added = "dashboard.card.note.added",
  Modified = "dashboard.card.note.modified",
  Removed = "dashboard.card.note.removed",
}

export enum AiReportsEvent {
  ReportCreated = "room.dataroom.ai_reports.report_created",
  ReportModified = "room.dataroom.ai_reports.report_modified",
  FieldAdded = "room.dataroom.ai_reports.field_added",
  FieldDeleted = "room.dataroom.ai_reports.field_deleted",
  ReportDeleted = "room.dataroom.ai_reports.report_deleted",
  ReportGenerated = "room.dataroom.ai_reports.report_generated",
  ReportRegenerated = "room.dataroom.ai_reports.report_regenerated",
  QuestionAsked = "room.dataroom.ai_reports.question_asked",
  ReportRevoked = "room.dataroom.ai_reports.report_revoked",
  BuiltinReportCreated = "room.dataroom.ai_reports.builtin_report_created",
  FieldReviewSetLike = "room.dataroom.ai_reports.field_review_set_like",
  FieldReviewSetDislike = "room.dataroom.ai_reports.field_review_set_dislike",
  ReportsExport = "room.dataroom.ai_reports.reports_export",
  ReportsPageOpen = "room.dataroom.ai_reports.reports_page_open",
}

export enum RoomSynergiesEvent {
  Created = "room.synergies.created",
  Renamed = "room.synergies.renamed",
  Deleted = "room.synergies.deleted",
  ValueDriverCreated = "room.synergies.value_driver.created",
  ValueDriverRenamed = "room.synergies.value_driver.renamed",
  ValueDriverDeleted = "room.synergies.value_driver.deleted",
  EnabledByUpdated = "room.synergies.value_driver.enabled_by.updated",
  EnabledByRemoved = "room.synergies.value_driver.enabled_by.removed",
}

export enum DashboardViewsEvent {
  Save = "dashboard.views.save",
  Create = "dashboard.views.create",
}
