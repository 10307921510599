/* tslint:disable:no-magic-numbers */
import { d3 } from "@shared/ui";

import type { D3Selection } from "@shared/ui/timeline/index";

export const SCROLLBAR_SIZE = getScrollbarWidth();

export class TimelineLayout {
  public timelineContainer: D3Selection<HTMLDivElement>;
  public gridContainer: D3Selection<HTMLDivElement>;
  public gridHeader: D3Selection<HTMLDivElement>;
  public gridData: D3Selection<HTMLDivElement>;
  public sidebarContainer: D3Selection<HTMLDivElement>;
  public sidebarHeader: D3Selection<HTMLDivElement>;
  public sidebarData: D3Selection<HTMLDivElement>;
  public verScrollShown: boolean = false;
  public horScrollShown: boolean = false;

  protected notifyScrollScheduled = false;
  protected dispatcher: d3.Dispatch<EventTarget>;
  protected vertScroll: D3Selection<HTMLDivElement>;
  protected vertScrollPlaceholder: D3Selection<HTMLDivElement>;
  protected horScroll: D3Selection<HTMLDivElement>;
  protected horScrollPlaceholder: D3Selection<HTMLDivElement>;
  protected axisHeight: number;
  protected sidebarWidth: number;

  constructor(
    container: HTMLElement,
    axisHeight: number,
    sidebarWidth: number,
  ) {
    this.dispatcher = d3.dispatch("onScroll");
    this.axisHeight = axisHeight;
    this.sidebarWidth = sidebarWidth;
    this.timelineContainer = d3
      .select(container)
      .append("div")
      .attr("class", "dr-timeline__container");
    this.sidebarContainer = this.timelineContainer
      .append("div")
      .attr("class", "dr-timeline__sidebar-container")
      .style("width", sidebarWidth + "px");
    this.sidebarHeader = this.sidebarContainer
      .append("div")
      .attr("class", "dr-timeline__sidebar-header");
    this.sidebarData = this.sidebarContainer
      .append("div")
      .attr("class", "dr-timeline__sidebar-data");
    this.gridContainer = this.timelineContainer
      .append("div")
      .attr("class", "dr-timeline__grid-container");
    this.gridHeader = this.gridContainer
      .append("div")
      .attr("class", "dr-timeline__grid-header");
    this.gridData = this.gridContainer
      .append("div")
      .attr("class", "dr-timeline__grid-data");
    this.vertScroll = this.timelineContainer
      .append("div")
      .attr("class", "dr-timeline__scroll-vert")
      .style("width", SCROLLBAR_SIZE);
    this.vertScrollPlaceholder = this.vertScroll
      .append("div")
      .attr("class", "dr-timeline__scroll-placeholder");
    this.horScroll = this.timelineContainer
      .append("div")
      .attr("class", "dr-timeline__scroll-hor")
      .style("left", sidebarWidth + "px")
      .style("height", SCROLLBAR_SIZE);
    this.horScrollPlaceholder = this.horScroll
      .append("div")
      .attr("class", "dr-timeline__scroll-placeholder");

    (this.timelineContainer.node() as HTMLDivElement).addEventListener(
      "wheel",
      this.onWheel.bind(this),
    );
    (this.vertScroll.node() as HTMLDivElement).addEventListener(
      "scroll",
      this.onScroll.bind(this),
    );
    (this.horScroll.node() as HTMLDivElement).addEventListener(
      "scroll",
      this.onScroll.bind(this),
    );
  }

  public destroy() {
    this.timelineContainer.remove();
  }

  public on(typenames: string, callback: any) {
    this.dispatcher.on(typenames, callback);
  }

  public getVisibleDataArea() {
    const gridData = this.gridData.node() as HTMLDivElement;
    const top = gridData.scrollTop;
    const left = gridData.scrollLeft;
    const height = gridData.clientHeight;
    const width = $(gridData).width() as number;

    return {
      top,
      left,
      height,
      width,
      XFrom: left,
      XTo: left + width,
      YFrom: top,
      YTo: top + height,
    };
  }

  public getScrollPosition() {
    // percent of scroll position, can be used to restore on redraw
    const hScroll = this.horScroll.node() as HTMLDivElement;
    const vScroll = this.vertScroll.node() as HTMLDivElement;
    const top =
      (vScroll.scrollTop + vScroll.clientHeight / 2) / vScroll.scrollHeight;
    const left =
      (hScroll.scrollLeft + hScroll.clientWidth / 2) / hScroll.scrollWidth;

    return { top, left };
  }

  public setScrollPosition(top: number, left: number) {
    // restore scroll position
    const hScroll = this.horScroll.node() as HTMLDivElement;
    const vScroll = this.vertScroll.node() as HTMLDivElement;
    vScroll.scrollTop = top * vScroll.scrollHeight - vScroll.clientHeight / 2;
    hScroll.scrollLeft = left * hScroll.scrollWidth - hScroll.clientWidth / 2;
  }

  public setDataSize(dataHeight: number, dataWidth: number) {
    const timeline = this.timelineContainer.node() as HTMLDivElement;
    // start with space without scrollbars
    let viewH = timeline.clientHeight - this.axisHeight;
    let viewW = timeline.clientWidth - this.sidebarWidth;
    this.horScrollShown = false;
    this.verScrollShown = false;
    if (dataHeight > viewH && dataWidth > viewW) {
      // both overflow, show scrollbars
      this.horScrollShown = true;
      this.verScrollShown = true;
    } else if (dataHeight > viewH) {
      this.verScrollShown = true;
      // check if there is still enough hor space after adding vert scrollbar
      if (dataWidth + SCROLLBAR_SIZE > viewW) {
        this.horScrollShown = true;
      }
    } else if (dataWidth > viewW) {
      this.horScrollShown = true;
      // check if there is still enough vert space after adding hor scrollbar
      if (dataHeight + SCROLLBAR_SIZE > viewH) {
        this.horScrollShown = true;
      }
    }
    if (this.verScrollShown) {
      viewW -= SCROLLBAR_SIZE;
    }
    if (this.horScrollShown) {
      viewH -= SCROLLBAR_SIZE;
    }
    const viewWPx = `${viewW}px`;
    const viewHPx = `${viewH}px`;
    this.gridContainer
      .style("min-width", viewWPx) // expand in IE11
      .style("width", viewWPx) // expand in IE11
      .style("max-width", viewWPx);
    this.sidebarData.style("max-height", viewHPx);
    this.gridData.style("max-height", viewHPx);
    if (this.verScrollShown) {
      this.vertScroll.styles({
        display: "block",
        height: viewHPx,
        width: SCROLLBAR_SIZE + "px",
        top: this.axisHeight + "px",
      });
      this.vertScrollPlaceholder.style("height", dataHeight + "px");
    }
    if (this.horScrollShown) {
      this.horScroll.styles({
        display: "block",
        left: this.sidebarWidth + "px",
        top:
          Math.min(this.axisHeight + dataHeight, this.axisHeight + viewH) +
          "px",
        width: viewWPx,
        height: SCROLLBAR_SIZE + "px",
      });
      this.horScrollPlaceholder.style("width", dataWidth + "px");
    }
  }

  protected notifyScroll() {
    this.dispatcher.call("onScroll", undefined);
    this.notifyScrollScheduled = false;
  }

  protected onScroll() {
    const top = (this.vertScroll.node() as HTMLDivElement).scrollTop;
    const left = (this.horScroll.node() as HTMLDivElement).scrollLeft;
    const gridData = this.gridData.node() as HTMLDivElement;
    gridData.scrollTop = top;
    gridData.scrollLeft = left;
    (this.sidebarData.node() as HTMLDivElement).scrollTop = top;
    (this.gridHeader.node() as HTMLDivElement).scrollLeft = left;

    if (!this.notifyScrollScheduled) {
      this.notifyScrollScheduled = true;
      requestAnimationFrame(() => this.notifyScroll());
    }
  }

  protected onWheel(event: WheelEvent) {
    const wheel = normalizeMouseWheelEvent(event);
    const hScroll = this.horScroll.node() as HTMLDivElement;
    const vScroll = this.vertScroll.node() as HTMLDivElement;
    if (event.shiftKey && wheel.y) {
      hScroll.scrollLeft += wheel.y;
    } else if (wheel.x) {
      hScroll.scrollLeft += wheel.x;
    } else {
      vScroll.scrollTop += wheel.y;
    }
    event.preventDefault();
    event.stopPropagation();

    return false;
  }
}

function getScrollbarWidth() {
  const div = document.createElement("div");
  div.innerHTML =
    '<div style="width:50px;height:50px;position:absolute;left:-50px;top:-50px;overflow:auto;"><div style="width:1px;height:100px;"></div></div>';
  const child = div.firstChild as HTMLDivElement;
  document.body.appendChild(child);
  const width = child.offsetWidth - child.clientWidth;
  document.body.removeChild(child);

  return width;
}

// wheel scroll reasonable defaults
const LINE_HEIGHT = 40;
const PAGE_HEIGHT = document.documentElement.clientHeight;

function normalizeMouseWheelEvent(event: WheelEvent) {
  let x = event.deltaX || 0;
  let y = event.deltaY || 0;
  let z = event.deltaZ || 0;
  const mode = event.deltaMode;
  let scale = 1;
  switch (mode) {
    case 1: // by lines
      scale = LINE_HEIGHT;
      break;
    /* tslint:disable:no-magic-numbers */
    case 2: // by pages
      scale = PAGE_HEIGHT;
      break;
  }
  x *= scale;
  y *= scale;
  z *= scale;

  return { x, y, z, event };
}
