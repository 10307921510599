import { useEventBus } from "@vueuse/core";

export const enum findingTypeTableEventNames {
  edit = "edit",
  delete = "delete",
}

export type FindingTypeTableEvents = {
  [findingTypeTableEventNames.edit]: { id: string };
  [findingTypeTableEventNames.delete]: { id: string };
};

export const useFindingTypesTableEventBus = <
  FieldT extends keyof FindingTypeTableEvents,
>() => {
  return useEventBus<FieldT, FindingTypeTableEvents[FieldT]>(
    "finding-types-table",
  );
};
