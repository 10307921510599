<template>
  <DrTooltip :disabled="!isSupported" :content="tooltipText" placement="bottom">
    <div
      :class="{
        [$style.container]: true,
        [$style.container_isHoverable]: isSupported,
      }"
      @click="copy"
    >
      <slot />
      <Transition :name="$style.fadeIn">
        <div v-if="hasCopied" :class="$style.success">
          <DrIcon :size="SIZES.sm" name="check" />
        </div>
      </Transition>
    </div>
  </DrTooltip>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { SIZES } from "@shared/ui/constants";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrTooltip } from "@shared/ui/dr-tooltip";
import { useClipboard } from "@vueuse/core";

interface Props {
  text: string;
}

const props = defineProps<Props>();

const { copy: copyToClipboard, isSupported } = useClipboard();

const hasCopied = ref(false);
const tooltipText = ref("Copy URL");

const copy = () => {
  copyToClipboard(props.text);

  hasCopied.value = true;
  tooltipText.value = "Copied!";

  setTimeout(() => {
    hasCopied.value = false;
    tooltipText.value = "Copy URL";
  }, 700);
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.container {
  border-radius: 6px;
  color: colors.$pr-900;
  cursor: pointer;
  font: typography.$body_semibold;
  padding: 2px 8px;
  width: max-content;
  position: relative;
  overflow: hidden;
}

.container_isHoverable {
  transition: background-color 100ms ease-in-out;

  &:hover {
    background-color: colors.$pr-100;
  }
}

.success {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: colors.$ad-3;
  color: #fff;
}

.fadeIn {
  &:global(-leave-active) {
    transition: 0.2s opacity ease-out;
  }

  &:global(-enter-from),
  &:global(-leave-to) {
    opacity: 0;
  }
}
</style>
