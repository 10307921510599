<template>
  <div class="upload-avatar">
    <div class="upload-avatar__preview">
      <img v-if="previewUrl" :src="previewUrl" alt="" />
      <UserAvatar v-else :user="USER_DATA.profile" size="64px" />
    </div>
    <div class="upload-avatar__panel">
      <div class="upload-avatar__label">Profile photo</div>
      <div class="upload-avatar__actions">
        <span>
          <span v-if="previewUrl" class="upload-avatar__action" @click="upload"
            ><i class="fal fa-check" /> Save</span
          >
          <span v-else class="upload-avatar__action" @click="openUploadDialog"
            ><i class="fal fa-camera" /> Upload new</span
          >
        </span>

        <span v-if="previewUrl || isAvatarExists" class="upload-avatar__pipe"
          >|</span
        >

        <span>
          <span
            v-if="previewUrl"
            class="upload-avatar__action"
            @click="clearForm"
            ><i class="fal fa-times" /> Cancel</span
          >
          <span
            v-else-if="isAvatarExists"
            class="upload-avatar__action"
            @click="deleteAvatar"
            ><i class="fal fa-times" /> Delete</span
          >
        </span>
      </div>
      <input
        ref="upload"
        style="display: none"
        type="file"
        name="avatar"
        accept="image/jpg, image/jpeg"
        @change="updatePreview"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { UserAvatar } from "@shared/ui/users";

import { USER_DATA } from "@setups/data";
import { ProfileApiService } from "@drVue/api-service/client-dashboard/profile";
import { $notifyDanger, $notifySuccess } from "@drVue/common";
import { ProfileService } from "@drVue/components/client-dashboard/users/AccountSettings/ProfileService";

import type { UserData } from "@setups/data";

const api = new ProfileApiService();

interface Data {
  profileService: ProfileService;
  USER_DATA: any;
  previewUrl: string;
}

// There are two USER_DATA objects:
//   1. The global one that is used across the project
//   2. One that used inside the component (copy of the 1-st)
//
// We create the 2-nd one to use Vue's reactivity. We keep them in sync by
// updating both: the global one will be updated in ProfileService callbacks,
// and then we update the component's one by calling the callback again with
// this.USER_DATA
export default defineComponent({
  name: "UploadAvatar",
  components: {
    UserAvatar,
  },
  data(): Data {
    return {
      profileService: new ProfileService(api),
      USER_DATA: USER_DATA,
      previewUrl: "",
    };
  },
  computed: {
    isAvatarExists(): any {
      const a = this.USER_DATA.profile.avatar;
      return Boolean(a.thumbnail) || Boolean(a.reduced);
    },
  },
  methods: {
    getUploadElement() {
      return this.$refs.upload as HTMLInputElement;
    },
    updatePreview() {
      const files = this.getUploadElement().files;
      if (!files || files.length === 0) return;

      const file = files[0];
      this.previewUrl = URL.createObjectURL(file);
    },
    openUploadDialog() {
      this.getUploadElement().click();
    },
    clearForm() {
      this.previewUrl = "";
      this.getUploadElement().value = "";
    },
    notify(data: UserData) {
      this.clearForm();

      const ava = data.profile.avatar;
      const isAvatarExists = ava && ava.thumbnail && ava.reduced;
      const action = isAvatarExists ? "updated" : "deleted";
      $notifySuccess(`Your avatar has been ${action}`);
    },
    deleteAvatar() {
      this.profileService
        .deleteAvatar()
        .then((avatar) =>
          ProfileService.updateUserAvatar(avatar, this.USER_DATA),
        )
        .then(
          () => this.notify(this.USER_DATA),
          () => $notifyDanger("Failed to delete avatar, please try again"),
        );
    },
    upload() {
      const files = this.getUploadElement().files;
      if (!files || files.length === 0) return;

      const avatar = files[0];
      this.profileService
        .uploadAvatar(avatar)
        .then((avatar) =>
          ProfileService.updateUserAvatar(avatar, this.USER_DATA),
        )
        .then(
          () => this.notify(this.USER_DATA),
          () => $notifyDanger("Failed to upload avatar, please try again"),
        );
    },
    setUserData(userData: UserData) {
      this.USER_DATA = userData;
    },
  },
});
</script>
