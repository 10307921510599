import baseHtml from "./index.html?raw";

Datagrid.$inject = [
  "$scope",
  "$element",
  "AnalyticsExportExcelService",
  "AnalyticsDatagridFilterService",
  "AnalyticsDatagridNestService",
];
import "./nest";
import "./table";

angular
  .module("dealroom.analytics.datagrid-table.base", [
    "dealroom.analytics.datagrid-table.service.nest",
    "dealroom.analytics.datagrid-table.table",

    "dealroom.analytics.service.export",
  ])
  .component("drDatagrid", {
    bindings: {
      cells: "<", // tree: array of objects (which may contains array of objects in `childs`)
      activityRowCells: "<", // array of str
      filterBy: "<", // str
      uid: "<", // str: for saving tablesort state
      cls: "<", // str
      nestByArray: "<", // array of strings for creating tree

      getRootItems: "<",
      getRowFromItem: "<",

      /* OPTIONAL */
      subParentFieldName: "<", // str
      limitTo: "<", // str
      verbsForFilter: "<", // array | `true` for using only verbs from `cells`
      defaultSort: "<",
    },
    controller: Datagrid,
    template: baseHtml,
  });

function Datagrid(
  $scope,
  $element,
  AnalyticsExportExcelService,
  AnalyticsDatagridFilterService,
  AnalyticsDatagridNestService,
) {
  const $ctrl = this;

  $ctrl.tableUid = "Datagrid-" + $ctrl.uid;
  $ctrl.dispatcher = d3.dispatch(
    "filtersChanged",
    "update",
    "setSortBy",
    "sortBy",
  );

  $ctrl.$onInit = function () {
    addKeys($ctrl.cells);
    $ctrl.visibleCells = $ctrl.cells.filter((c) => c.visible !== false);

    AnalyticsDatagridFilterService.watchFilters(
      $ctrl.dispatcher,
      $scope,
      $element,
    );
  };

  $ctrl.dispatcher.on("filtersChanged.Datagrid", mkNest);

  function mkNest(filtered) {
    const rows = AnalyticsDatagridNestService.nest($ctrl);
    AnalyticsExportExcelService.setExcelDataContext(rows);
    $ctrl.dispatcher.call("update", null, rows);
  }
}

function addKeys(cells) {
  cells.forEach((c) => {
    _addKey(c, c.visible);
  });

  function _addKey(cell, parentVisible = true) {
    if (cell.verbs instanceof Array) {
      cell.key = cell.verbs.join(",") + ":" + cell.src;
    } else {
      cell.key = cell.label;
    }

    cell.visible = parentVisible;

    if (cell.childs) {
      cell.childs.forEach((c) => _addKey(c, cell.visible));
    }
  }
}

function cellsToKeys(bucket, cell) {
  putKeys(cell);
  return bucket;

  function putKeys(cell) {
    (cell.childs || []).forEach(putKeys);

    if (cell.key) bucket[cell.key] = cell.verbs;
  }
}
