const boardItemsVisibilityState = new Map<Element, boolean | null>();
const boardItemsVisibilityCallback = new Map<
  Element,
  (isVisible: boolean) => any
>();

const observer = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    const isVisible = boardItemsVisibilityState.get(entry.target);
    if (isVisible !== undefined || isVisible !== entry.isIntersecting) {
      boardItemsVisibilityState.set(entry.target, entry.isIntersecting);
      const callback = boardItemsVisibilityCallback.get(entry.target);
      if (callback) {
        callback(entry.isIntersecting);
      }
    }
  });
});

export const useBoardItemIntersection = () => {
  function addBoardItem(
    target: Element,
    callback: (isVisible: boolean) => any,
  ) {
    observer.observe(target);
    boardItemsVisibilityState.set(target, null);
    boardItemsVisibilityCallback.set(target, callback);
  }

  function removeBoardItem(target: Element) {
    observer.unobserve(target);
    boardItemsVisibilityState.delete(target);
    boardItemsVisibilityCallback.delete(target);
  }

  return {
    addBoardItem,
    removeBoardItem,
  };
};
