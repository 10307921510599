<template>
  <div
    v-if="isDialogVisible"
    id="download-dialog"
    class="container"
    :style="{ 'margin-bottom': `${bottomOffset}px` }"
  >
    <div class="row dialog-title">
      <div class="col-md-10">
        <span v-show="hasActiveDownloads">
          <i class="fa fa-spinner fa-spin" />
          Processing {{ processingTasks.length }}
          {{ processingTasks.length === 1 ? "item" : "items" }}
        </span>

        <span v-show="!hasActiveDownloads">
          <i
            v-if="failedTasks.length"
            class="fas fa-exclamation-triangle color-red"
          />
          BULK DOWNLOADS READY
          <span v-if="failedTasks.length">
            - {{ failedTasks.length }}
            {{ failedTasks.length === 1 ? "error" : "errors" }}
          </span>
        </span>
      </div>

      <div class="col-md-2 text-right">
        <span
          class="cursor upload-dialog__minimize"
          @click="isDialogMinimized = !isDialogMinimized"
        >
          <i
            class="fa"
            :class="{
              'fa-chevron-up': isDialogMinimized,
              'fa-chevron-down': !isDialogMinimized,
            }"
            aria-hidden="true"
          />
        </span>
        <span
          class="cursor"
          :class="{ disabled: hasActiveDownloads }"
          @click="hideDialog"
        >
          <i class="fa fa-times" aria-hidden="true" />
        </span>
      </div>
    </div>

    <div v-if="!isDialogMinimized" class="dialog-queue row">
      <table>
        <tbody>
          <tr v-for="d in downloads" :key="d.task_id">
            <td class="index">
              <div
                v-if="
                  d.status === DownloadStatus.Pending ||
                  d.status === DownloadStatus.Processing
                "
                class="progress"
              >
                <div
                  class="progress-bar"
                  role="progressbar"
                  :style="{
                    width:
                      (d.progress.processed / d.progress.total) * 100 + '%',
                  }"
                >
                  <span class="sr-only">{{ d.status }}</span>
                </div>
              </div>
              <span v-if="d.status === DownloadStatus.Fail">
                <i class="fa fa-exclamation-triangle" />
                Error
              </span>
            </td>

            <td class="title" style="flex-direction: column">
              <div>
                <span v-if="d.status === DownloadStatus.Pending"
                  >Please wait...</span
                >
                <span
                  v-if="
                    d.status === DownloadStatus.Processing && d.progress.total
                  "
                >
                  <span v-if="d.progress.processed !== undefined">
                    {{ d.progress.processed }} of </span
                  >{{ d.progress.total }} files
                </span>
                <span
                  v-if="d.status === DownloadStatus.Success && d.progress.total"
                  >{{ d.progress.total }} files</span
                >
                <span v-if="d.status === DownloadStatus.Fail"
                  >Something went wrong...</span
                >
                <span v-if="d.log.length">
                  <span> | </span>
                  <span>
                    {{ d.log.length }}
                    {{ d.log.length === 1 ? "error" : "errors" }}
                  </span>
                  <span> - </span>
                  <a href="" class="error-link" @click="showLog(d.task_id)"
                    >show errors</a
                  >
                </span>
              </div>
            </td>

            <td class="status">
              <div v-if="d.status === DownloadStatus.Success">
                <a :href="getDownloadUrl(d.task_id)">Download</a>
              </div>
              <div
                v-else-if="
                  d.status === DownloadStatus.Pending ||
                  d.status === DownloadStatus.Processing
                "
              >
                {{ d.status }}
                <i
                  class="fa-envelope"
                  :class="d.need_email ? 'fas' : 'far'"
                  :title="
                    d.need_email
                      ? 'Email will be sent, click to change'
                      : 'Email won\'t be sent, click to change'
                  "
                  @click="setNeedEmail(d.task_id, !d.need_email)"
                />
              </div>
              <div v-else>
                {{ d.status }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <RoomDownloadsErrorsDialog ref="errorsDialog" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { isOnline } from "@shared/ui/dr-offline/DrOffline";

import { Urls } from "@setups/urls";
import RoomDownloadsErrorsDialog from "@drVue/components/room/RoomDownloadsErrorsDialog.vue";
import { DownloadStatus } from "@drVue/store/modules/room/downloads/DownloadsApiService";
import Idle from "../../../idle";

import type { Download } from "@drVue/store/modules/room/downloads/DownloadsApiService";

interface Data {
  downloadsIntervalId: number | null;
  offsetIntervalId: number | null;
  isDialogMinimized: boolean;
  bottomOffset: number;
}

export default defineComponent({
  name: "RoomDownloadDialog",
  components: { RoomDownloadsErrorsDialog },
  data(): Data {
    return {
      downloadsIntervalId: null,
      offsetIntervalId: null,
      isDialogMinimized: false,
      bottomOffset: 0,
    };
  },
  computed: {
    DownloadStatus(): any {
      return DownloadStatus;
    },
    isDialogVisible(): any {
      return this.$store.state.room.downloads.isDialogVisible;
    },
    hasActiveDownloads(): any {
      return this.pendingTasks.length > 0 || this.processingTasks.length > 0;
    },
    pendingTasks(): any {
      return this.downloads.filter(
        (d: Download) => d.status === DownloadStatus.Pending,
      );
    },
    processingTasks(): any {
      return this.downloads.filter(
        (d: Download) => d.status === DownloadStatus.Processing,
      );
    },
    failedTasks(): any {
      return this.downloads.filter(
        (d: Download) => d.status === DownloadStatus.Fail,
      );
    },
    downloads(): any {
      return this.$store.state.room.downloads.downloads;
    },
  },
  watch: {
    "downloads.length": {
      immediate: true,
      handler(numberOfDownloads: number) {
        if (numberOfDownloads) {
          this.$store.commit("room/downloads/showDialog");
        }
      },
    },
    hasActiveDownloads: {
      immediate: true,
      handler(hasActiveDownloads: boolean) {
        if (hasActiveDownloads) {
          if (!this.downloadsIntervalId) {
            this.downloadsIntervalId = window.setInterval(
              this.getDownloads,
              1000,
            );
          }
        } else if (this.downloadsIntervalId) {
          clearInterval(this.downloadsIntervalId);
          this.downloadsIntervalId = null;
        }
      },
    },
    isDialogVisible: {
      immediate: true,
      handler(isDialogVisible: boolean) {
        if (isDialogVisible) {
          if (!this.offsetIntervalId) {
            this.offsetIntervalId = window.setInterval(
              this.updateBottomOffset,
              200,
            );
          }
        } else if (this.offsetIntervalId) {
          clearInterval(this.offsetIntervalId);
          this.offsetIntervalId = null;
        }
      },
    },
  },
  beforeMount() {
    this.getDownloads();

    Idle.onAway.subscribe(() => {
      if (this.downloadsIntervalId) {
        clearInterval(this.downloadsIntervalId);
        this.downloadsIntervalId = null;
      }

      if (this.offsetIntervalId) {
        clearInterval(this.offsetIntervalId);
        this.offsetIntervalId = null;
      }
    });

    Idle.onAwayBack.subscribe(() => {
      if (!this.downloadsIntervalId && this.hasActiveDownloads) {
        this.downloadsIntervalId = window.setInterval(this.getDownloads, 1000);
      }

      if (!this.offsetIntervalId && this.isDialogVisible) {
        this.offsetIntervalId = window.setInterval(
          this.updateBottomOffset,
          200,
        );
      }

      if (isOnline()) {
        this.getDownloads();
      }
    });
  },
  methods: {
    setNeedEmail(taskId: string, needEmail: boolean) {
      this.$store.dispatch("room/downloads/setNeedEmail", {
        taskId,
        needEmail,
      });
    },
    updateBottomOffset() {
      const uploadDialog = document.getElementById("upload-dialog");
      this.bottomOffset = uploadDialog ? uploadDialog.offsetHeight + 10 : 0;
    },
    showLog(taskId: string) {
      (this.$refs.errorsDialog as any).showDialog(taskId);
    },
    getDownloads() {
      this.$store.dispatch("room/downloads/getDownloads");
    },
    getDownloadUrl(taskId: string) {
      const url = Urls["api:room:documents_download_bulk_archive"];
      if (url) return url(taskId);

      return "";
    },
    hideDialog() {
      if (this.hasActiveDownloads) return;
      this.$store.commit("room/downloads/hideDialog");
    },
  },
});
</script>
