import "./cell";

import * as d3 from "d3";

import CLASSES from "./classes";

angular
  .module("dealroom.analytics.datagrid-table.table.body.row", [
    "dealroom.analytics.datagrid-table.table.body.cell",
  ])
  .service(
    "AnalyticsDatagridTableBodyRowFactory",
    AnalyticsDatagridTableBodyRowFactory,
  );

AnalyticsDatagridTableBodyRowFactory.$inject = [
  "AnalyticsDatagridTableCellRenderService",
];
function AnalyticsDatagridTableBodyRowFactory(
  AnalyticsDatagridTableCellRenderService,
) {
  return {
    create,
    update,
    remove,
  };

  function create(d) {
    const container = d3
      .select(this)
      .attr("class", CLASSES.rowContainer)
      // DEBUG
      .on("click", (d) => {
        if (window["_AnalyticsActivityDataService"] === undefined) return;
        const activity = window["_AnalyticsActivityDataService"].alldata.find(
          (a) => a.id == d.id,
        );
        console.debug(d, activity);
      });

    container.append("div").attr("class", CLASSES.row + " " + (d.class || ""));
  }

  function update(container, isOpen, onExpand) {
    const d = container.datum();

    const cellsData = d.cells.filter((c) => c.visible === true);

    let cells = getCells(container, cellsData);
    if (cells.enter().size() > 0) {
      // the amount of cells in row is static, so if they enter
      // there is no old cells (no need to merge with existed)
      cells = cells.enter().append("div");
    } else {
      // if type !== undefined => content cell,
      // like title or activity row, which is no need to update
      cells = cells.filter((d) => d.type === undefined || d.key === "title");
    }
    cells.call(
      AnalyticsDatagridTableCellRenderService.renderCells,
      isOpen,
      getOnExpand(),
    );

    function getOnExpand() {
      const hasSubRows = d.getSubRows instanceof Function;
      if (!hasSubRows) return undefined;

      return function () {
        return onExpand(d.itemId);
      };
    }
  }

  function remove(d) {
    const container = d3.select(this);
    const cells = getCells(container, []);
    cells.exit().each(AnalyticsDatagridTableCellRenderService.removeCell);
    container.remove();
  }

  function getCells(container, data) {
    const row = container.select("." + CLASSES.row);
    return row.selectAll("." + CLASSES.column).data(data, (d) => d.key);
  }
}
