<template>
  <DefaultViewFieldLayout :view-props="viewProps">
    <template #label>
      {{ viewProps.schema.label }}
    </template>
    <template #control>
      <DrTruncatedTextTooltip v-if="email" :content="email">
        <a :href="`mailto:${email}`" target="_blank">
          <i class="fas fa-envelope dash-content-panel-info__item-icon" />
          {{ email }}
        </a>
      </DrTruncatedTextTooltip>
      <div v-else>-</div>
    </template>
  </DefaultViewFieldLayout>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import DefaultViewFieldLayout from "./DefaultViewFieldLayout.vue";

import type { ViewFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";

interface Props {
  viewProps: ViewFieldProps;
}

const props = defineProps<Props>();
const email = computed(
  () => props.viewProps.entity[props.viewProps.schema.prop],
);
</script>
