import { ApiService } from "../../index";

import type { GroupWithAccess } from "./types";

type DealId = number;

export class DealGroupsApiService extends ApiService {
  public loadGroupsWithAccess(dealId: DealId) {
    const url =
      this.Urls["api:client-dashboard:deals-detail-groups-with-access-list"](
        dealId,
      );
    return this.get<GroupWithAccess[]>(url);
  }
}
