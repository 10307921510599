import { cloneDeep } from "lodash-es";
import { keyBy } from "lodash-es";

import { cleanUpFilters } from "@drVue/store/modules/client-dashboard/fields/utils";
import { copyView } from "./utils";

import type { State } from "./state";
import type { CustomView, CustomViewColumn, CustomViewLayouts } from "./types";
import type {
  CustomViewSettings,
  CustomViewsFilterFields,
} from "@setups/types";
import type { MutationTree } from "vuex";

export const mutations: MutationTree<State> = {
  set(state, customViews: CustomView[]): void {
    state.items = customViews;
  },

  setCurrent(state, view: CustomView): void {
    state.current = copyView(view);
  },

  updateCurrentSettings(state, updates: Partial<CustomViewSettings>) {
    state.current.settings = { ...state.current.settings, ...updates };
  },

  updateCurrentSettingsColumns(state, updatedColumns: CustomViewColumn[]) {
    const viewColumns = cloneDeep(state.current.settings.columns || []);
    const columnsMap = keyBy(viewColumns, "field");
    updatedColumns.forEach((column) => {
      const current = columnsMap[column.field] || {};
      columnsMap[column.field] = { ...current, ...column };
    });

    state.current.settings.columns = Object.values(columnsMap);
  },

  updateCurrentLayout(state, layout: CustomViewLayouts) {
    state.current.layout = layout;
  },

  setCurrentFiltersSearch(state, query: string) {
    state.current.filters.search = query;
  },

  setCurrentFiltersFields(state, fields: CustomViewsFilterFields): void {
    state.current.filters.fields = cleanUpFilters(fields);
  },

  setCurrentFilterValue: function (
    state,
    { field, value, op = "EQ" }: { field: string; value: any; op: string },
  ) {
    const fields = { ...state.current.filters.fields };
    if (value) {
      fields[field] = { op, value };
    } else {
      delete fields[field];
    }
    state.current.filters.fields = cleanUpFilters(fields);
  },

  clearCurrentFiltersFields(state): void {
    state.current.filters.fields = {};
  },

  clearCurrentFilters(state): void {
    state.current.filters.search = "";
    state.current.filters.fields = {};
  },

  setIsLoading(state: State, isLoading: boolean): void {
    state.isLoading = isLoading;
  },

  setIsError(state: State, isError: boolean): void {
    state.isError = isError;
  },

  delete(state: State, customView: CustomView) {
    const existingIdx = state.items.findIndex(
      (v) => v.slug === customView.slug,
    );
    if (existingIdx === -1) return;
    state.items.splice(existingIdx, 1);
  },

  upsert(state: State, customView: CustomView) {
    const existingIdx = state.items.findIndex(
      (v) => v.slug === customView.slug,
    );
    if (existingIdx === -1) {
      state.items.push(customView);
    } else {
      state.items[existingIdx] = customView;
    }
  },
};
