<template>
  <StatusEdit :edit-props="editProps" @change="handleChange" />
</template>

<script setup lang="ts">
import { insightTrack, TaskDetailsTrackingEvent } from "@app/insight";
import { StatusEdit } from "@drVue/components/room/tasks/shared/widgets/status";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { Task } from "@drVue/store/pinia/room/tasks";

interface Props {
  editProps: EditFieldProps<number, Task>;
}
defineProps<Props>();

function handleChange() {
  insightTrack(TaskDetailsTrackingEvent.StatusChanged);
}
</script>
