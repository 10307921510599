import { toDate } from "date-fns-tz";
import { capitalize } from "lodash-es";

import { drUserTime } from "@drVue/filters/drUserTime";
import auditLog_auditLogHtml from "../../templates/components/audit-log/audit-log.html?raw";
import dashboardWidget_errorMessageHtml from "../../templates/components/dashboard-widget/error-message.html?raw";

(function () {
  "use strict";
  var moduleAuditLogDirective = angular
    .module("dealroom.audit-log.directive", [
      "ui.bootstrap",
      "dealroom.date-fns",
      "dealroom.config",
      "dealroom.common",
    ])
    .controller("AuditController", AuditController)
    .controller(
      "DashboardErrorMessageController",
      DashboardErrorMessageController,
    )
    .service("AuditService", AuditService)
    .directive("errorMessage", function () {
      return {
        restrict: "E",
        replace: true,
        scope: true,
        bindToController: {
          msg: "@",
          showError: "<",
          paddingLeft: "@",
        },
        template: dashboardWidget_errorMessageHtml,
        controller: "DashboardErrorMessageController",
        controllerAs: "ctrl",
      };
    })
    .directive("auditLog", function () {
      return {
        restrict: "E",
        replace: true,
        scope: true,
        bindToController: {
          action: "@",
          timeFrom: "<",
          timeTo: "<",
          user: "<",
          name: "@",
        },
        template: auditLog_auditLogHtml,
        controller: "AuditController",
        controllerAs: "ctrl",
      };
    });

  function AuditController(
    $scope,
    $q,
    $timeout,
    AuditService,
    MembersService,
    VerbsService,
    ActivityRoomServiceLoadedPromise,
  ) {
    var self = this;

    self.aggregates = [];
    self.lastActionPromise = null;

    self.tasksByCat = {};
    self.errorBlock = {
      loading: false,
      noData: false,
    };
    self.setOrder = function (order) {
      self.order = order;
    };
    self.roomDataLoaded = false;

    ActivityRoomServiceLoadedPromise.then(() => {
      self.roomDataLoaded = true;
    });

    $scope.Members = MembersService;
    $scope.$watchCollection(
      "[ctrl.roomDataLoaded, ctrl.timeFrom, ctrl.timeTo, ctrl.user, ctrl.action, Members.loading]",
      function () {
        if (!self.roomDataLoaded) {
          return;
        }
        if (
          angular.isDefined(self.timeFrom) &&
          angular.isDefined(self.timeTo)
        ) {
          getActions();
        }
      },
    );

    function getActions() {
      self.errorBlock.loading = true;
      self.errorBlock.noData = false;
      var data = {
        timeFrom: self.timeFrom,
        timeTo: self.timeTo,
        user: self.user,
        action: self.action !== "all" ? self.action : undefined,
      };
      const actionPromise = AuditService.getActivities(data);
      self.lastActionPromise = actionPromise;
      actionPromise.then(function (response) {
        if (self.lastActionPromise !== actionPromise) {
          return;
        }
        if (!response || !response.data) {
          return;
        }
        var res = response.data.results;
        self.rowData = getStructure(res);
        self.errorBlock.loading = false;
        if (self.rowData.length === 0) {
          self.errorBlock.noData = true;
        }
      });
    }

    function getStructure(data) {
      var res = [],
        members = MembersService.members,
        obj = {},
        label = "",
        typeOfTarget = "",
        values,
        itemInRes;

      data.map(function (item) {
        itemInRes = {};
        obj = {
          action: item.action,
          target: item.target,
          object: item.object,
        };
        label = VerbsService.getObjectLabel(obj);
        typeOfTarget = VerbsService.getType(item.action);
        itemInRes.raw = item;
        itemInRes.actor = members[item.user]
          ? members[item.user].name
          : "No name";
        itemInRes.email = members[item.user]
          ? members[item.user].email
          : "No email";
        itemInRes.group = members[item.user]
          ? members[item.user].pgroup.name
          : "No group";
        itemInRes.action = VerbsService.getLabel(item.action);
        itemInRes.itemLabel = label;
        if (item[typeOfTarget] && item[typeOfTarget].type) {
          itemInRes.type =
            item[typeOfTarget].type.toLowerCase() !== "task"
              ? capitalize(item[typeOfTarget].type)
              : "Request";
        } else {
          itemInRes.type = "";
        }

        itemInRes.dt = item.dt;
        const dt = toDate(item.dt);
        itemInRes.dateDay = drUserTime(dt, "MMM dd");
        itemInRes.dateTime = drUserTime(dt, "hh:mm a");

        values = VerbsService.getValues(item);
        if (values) {
          itemInRes.oldVal = values.old_value;
          itemInRes.newVal = values.new_value;
        }
        res.push(itemInRes);
      });
      return res;
    }
  }

  AuditController.$inject = [
    "$scope",
    "$q",
    "$timeout",
    "AuditService",
    "MembersService",
    "VerbsService",
    "ActivityRoomServiceLoadedPromise",
  ];

  function AuditService(URLS, $http) {
    var service = {};
    service.getActivities = function (data) {
      var params = {};
      if (data.timeFrom) {
        params["from_d"] = data.timeFrom;
      }
      if (data.timeTo) {
        params["to_d"] = data.timeTo;
      }
      if (data.group) {
        params["group"] = data.group;
      }
      if (data.user) {
        params["user"] = data.user;
      }
      if (data.action) {
        params["action"] = data.action;
        // todo(task_body): remove this when we get rid of task_body verb on BE completely
        if (data.action === "task_description") {
          params["action"] = "task_description,task_body";
        }
      }
      params["include_archived"] = true;
      return $http({
        url: URLS["api:room:activity"](),
        method: "GET",
        params: params,
      });
    };
    return {
      getActivities: service.getActivities,
    };
  }

  AuditService.$inject = ["URLS", "$http"];

  function DashboardErrorMessageController() {
    var self = this;
    self.msg = self.msg || "No data";
    self.paddingLeft = self.paddingLeft || 0;
  }
})();
