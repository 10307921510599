<template>
  <ElSelect
    ref="selectRef"
    :class="$style.select"
    :model-value="modelValue"
    :placeholder="editProps.schema.placeholder"
    :persistent="false"
    style="width: 100%"
    collapse-tags
    multiple
    :popper-class="`dr-popper--inline-control ${$style.select}`"
    @update:model-value="updateVeeField"
  >
    <ElOption
      v-for="opt in editProps.extra.options"
      :key="opt"
      :label="opt"
      :value="opt"
    />
    <div :class="$style.buttons">
      <ElButton
        size="small"
        title="Cancel"
        @click="() => editProps.quitEditModeConfirm()"
      >
        Cancel
      </ElButton>
      <ElButton size="small" type="primary" @click="editProps.submitField">
        Save
      </ElButton>
    </div>
  </ElSelect>
</template>

<script setup lang="ts">
import { ElSelect } from "element-plus";
import { xor } from "lodash-es";
import { onMounted, ref } from "vue";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";

interface Props {
  editProps: EditFieldProps;
}

const props = defineProps<Props>();

const updateVeeField = (value: string[]) => {
  modelValue.value = value;
  props.editProps.veeField.onChange(value);
};

const value = Array.isArray(props.editProps.value) ? props.editProps.value : [];
const initialValue = [...value];
const modelValue = ref<string[]>([...value]);

const selectRef = ref<InstanceType<typeof ElSelect> | null>(null);
onMounted(() => {
  if (!selectRef.value) return;
  selectRef.value.states.hoveringIndex = 0;
  selectRef.value.toggleMenu();

  props.editProps.setHasValueRemained(() => {
    const initial = initialValue.map(parseFloat);
    const model = modelValue.value.map(parseFloat);

    return xor(initial, model).length === 0;
  });
});
</script>

<style module lang="scss">
.buttons {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 8px;
  text-align: right;
  background-color: #fff;
}

.select {
  min-width: 150px;
  max-width: 250px;

  :global(.el-select-dropdown__list) {
    padding-bottom: 38px !important;
  }
}
</style>
