import { sortBy } from "lodash-es";
import { values } from "lodash-es";

import {
  DOC_ITEM_TYPES,
  DocumentsApiService,
} from "@drVue/store/modules/room/documents/DocumentsApiService";

import type {
  DocsItem,
  Document,
  Folder,
  SearchItem,
} from "@drVue/store/modules/room/documents/DocumentsApiService";
import type { Dictionary } from "@drVue/types";

const api = new DocumentsApiService();

interface DocumentsFilterService {
  loading: boolean;
  error: boolean;

  searchQuery: null | string;

  filtersEnabled: boolean;
  dateUpdatedRange: {
    startDate: any;
    endDate: any;
  };

  setSearchQuery(query: string): any;

  isFilterActive(field: string): boolean;
  isFiltersActive(): boolean;

  clearFilter(field: string): void;
  clearFilters(): void;

  findItems(files: DocsItem[], folders: DocsItem[]): DocsItem[];
  getTextSnippet(obj: DocsItem): string | null;
}

function DocumentsFilterService() {
  const service: DocumentsFilterService = {
    loading: false,
    error: false,

    searchQuery: null,

    filtersEnabled: true,
    dateUpdatedRange: {
      startDate: null,
      endDate: null,
    },

    // The main and initialization method for of the service.
    setSearchQuery: setSearchQuery,

    isFilterActive: isFilterActive,
    isFiltersActive: isFiltersActive,

    clearFilter: clearFilter,
    clearFilters: clearFilters,

    findItems: findItems,
    getTextSnippet: getTextSnippet,
  };

  let foundFolders: Dictionary<SearchItem> = {};
  let foundFiles: Dictionary<SearchItem> = {};

  return service;

  function isFilterActive(field: string) {
    if (field == "dateUpdated") {
      const range = service.dateUpdatedRange;
      return !!range.startDate || !!range.endDate;
    }

    return false;
  }

  function isFiltersActive() {
    return service.filtersEnabled && service.isFilterActive("dateUpdated");
  }

  function clearFilter(field: string) {
    if (field == "dateUpdated") {
      service.dateUpdatedRange.startDate = null;
      service.dateUpdatedRange.endDate = null;
    }
  }

  function clearFilters() {
    service.clearFilter("dateUpdated");
  }

  function findItems(files: DocsItem[], folders: DocsItem[]) {
    if (service.loading) {
      return [];
    }

    let items = files.concat(folders);
    if (service.searchQuery) {
      items = filterBySearchQuery(items);
    }

    if (!service.filtersEnabled) return items;

    if (service.isFilterActive("dateUpdated")) {
      items = filterByDateUpdatedRange(items);
    }

    return items;
  }

  function setSearchQuery(query: string) {
    if (service.searchQuery === query && !service.error) {
      return Promise.resolve();
    }

    service.searchQuery = query;
    service.error = false;

    if (!query) {
      foundFolders = {};
      foundFiles = {};
      service.loading = false;
      return Promise.resolve();
    }

    service.loading = true;

    return api
      .searchDocuments(query)
      .then((items) => {
        foundFiles = {};
        foundFolders = {};

        items.forEach((i) => {
          const bucket =
            i.type === DOC_ITEM_TYPES.Document ? foundFiles : foundFolders;
          bucket[i.id] = i;
        });
      })
      .finally(() => (service.loading = false))
      .catch(() => (service.error = true));
  }

  function getTextSnippet(obj: DocsItem) {
    if (service.loading || !service.searchQuery) {
      return null;
    }

    if (obj.type === DOC_ITEM_TYPES.Document && foundFiles[obj.id]) {
      return foundFiles[obj.id].snippet;
    }

    return null;
  }

  function getFoundItem(obj: DocsItem) {
    const bucket =
      obj.type === DOC_ITEM_TYPES.Document ? foundFiles : foundFolders;
    return bucket[obj.id];
  }

  function getRank(obj: DocsItem) {
    if (service.loading || !service.searchQuery) {
      return 0;
    }

    const foundItem = getFoundItem(obj);
    if (!foundItem) {
      return 0;
    }

    return foundItem.rank;
  }

  function filterBySearchQuery(items: DocsItem[]) {
    return sortBy(
      items.filter((obj) => !!getFoundItem(obj)),
      (item) => -getRank(item),
    );
  }

  function filterByDateUpdatedRange(items: DocsItem[]) {
    function containsDate(
      range: { startDate: Date; endDate: Date },
      date: Date,
    ) {
      if (!!range.startDate && date < range.startDate) return false;
      if (!!range.endDate && date > range.endDate) return false;

      return true;
    }

    // get all files which have dateUpdated between dateUpdatedRange
    const files = items
      .filter(
        (i) =>
          i.type == DOC_ITEM_TYPES.Document &&
          containsDate(service.dateUpdatedRange, (i as Document).dateUpdated),
      )
      .reduce((files, file) => {
        files[file.id] = file as Document;
        return files;
      }, {} as Dictionary<Document>);

    // get all dirs of these files:
    const folders = values(files).reduce((folders, file) => {
      folders[file.parent.id] = file.parent;
      file.parent.parents.forEach((p) => {
        folders[p.id] = p;
      });
      return folders;
    }, {} as Dictionary<Folder>);

    // select files and folders from items in exact order
    items = items.filter((item: DocsItem) => {
      if (item.type === DOC_ITEM_TYPES.Document) {
        return item.id in files;
      }
      return item.id in folders;
    });

    return items;
  }
}

const service = DocumentsFilterService();
export default service;
