import processDocuments from "./../../ng/documents/services/helpers/processDocuments";

angular
  .module("dealroom.analytics.service.data.documents", [
    "dealroom.analytics.service.data.loader",

    "dealroom.documents",
  ])
  .service("ActivityDocumentsService", ActivityDocumentsService);

ActivityDocumentsService.$inject = [
  "ActivityRoomServiceLoadedPromise",
  "DocumentsService",
];
function ActivityDocumentsService(
  ActivityRoomServiceLoadedPromise,
  DocumentsService,
) {
  const service = {
    loadingPromise: ActivityRoomServiceLoadedPromise.then(processData),

    rootFolder: undefined,
    Folders: {},
    Files: {},

    // visibleRootFoldersIds: null,
    stats: null,
  };
  // ActivityDataLoadedService.on('loaded.ActivityDocumentsService', mkStats);
  return service;

  function processData({ documents, folders }) {
    const data = processDocuments(folders.data, documents.data);
    service.rootFolder = data.rootFolder;
    service.Files = data.files;
    service.Folders = data.folders;

    const rootFolderId = +service.rootFolder.id;
    // service.visibleRootFoldersIds = [rootFolderId];
    service.stats = DocumentsService.rootFolder.folders.reduce(
      (bucket, folder) => {
        if (folder.is_archived == false) {
          // service.visibleRootFoldersIds.push(+folder.id);
          bucket[folder.id] = {
            // total: getDescendants(folder),
            id: +folder.id,
          };
        }
        return bucket;
      },
      {},
    );

    service.stats[rootFolderId] = {
      id: rootFolderId,
    };

    return service;
  }
}
