<template>
  <DrPopup ref="popupRef" paddingless @hide="$emit('hide')">
    <template #default="{ hide }">
      <TreeBrowser
        v-model:selected-node-ids="selectedCategory"
        :is-loading="isTreeBrowserLoading"
        :tree="catTree"
        disallow-reset
        disallow-empty
        :title="labels.title"
        :submit-text="labels.submitText"
        autofocus
        @save="handleSave($event, hide)"
        @create-node="handleCreateCategory"
        @cancel="hide"
      />
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { DrPopup } from "@shared/ui/dr-popups";

import { insightTrack, RoomRequestsBulkEvent } from "@app/insight";
import { $notifyDanger, createDictionary } from "@drVue/common";
import TreeBrowser from "@drVue/components/room/tasks/shared/tree-browser/TreeBrowser.vue";
import { pinia } from "@drVue/store/pinia";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";

import type { NodeId } from "@drVue/components/room/tasks/shared/tree-browser/types";
import type { Category } from "@drVue/store/pinia/room/categories";
import type { NavTreeItem } from "@shared/ui/dr-nav";
import type { TreeItem } from "@shared/ui/dr-tree";

interface Props {
  selected?: Category["id"];
}

interface Emits {
  (event: "submit", payload: Category["uid"]): void;
  (e: "hide"): void;
}

const props = withDefaults(defineProps<Props>(), {
  selected: undefined,
});

const emit = defineEmits<Emits>();

const categoriesStore = useCategoriesStore(pinia);

const popupRef = ref<InstanceType<typeof DrPopup>>();

type ActionType = "copy" | "move" | "move-single";
const actionType = ref<ActionType>("copy");

const LABELS = createDictionary({
  copy: {
    title: "Copy requests",
    submitText: "Copy",
  },
  move: {
    title: "Move requests",
    submitText: "Move",
  },
  "move-single": {
    title: "Move request",
    submitText: "Move",
  },
});

const labels = computed(() => LABELS[actionType.value]);

const selectedCategory = ref<Category["id"][]>([]);

watch(
  () => props.selected,
  (value) => {
    if (value) selectedCategory.value = [value];
    else selectedCategory.value = [];
  },
  { immediate: true },
);

type CategoryTreeItem = TreeItem<
  Pick<Category, "order" | "uid"> & { canParent: true }
>;

const toNode = (
  cat: NavTreeItem<Pick<Category, "order" | "uid">>,
): CategoryTreeItem => {
  if (cat && cat.children) {
    const children = cat.children.map(toNode);
    return {
      ...cat,
      children,
      canParent: true,
    };
  }

  return {
    ...cat,
    canParent: true,
    children: (cat.children ?? []) as CategoryTreeItem[],
  };
};

const catTree = computed(() => categoriesStore.categoriesNavTree.map(toNode));

const handleSave = (selectedNodeIds: NodeId[], hidePopup: () => void) => {
  const selectedCategory = selectedNodeIds.map(
    (id) => categoriesStore.categories[id].uid,
  );

  if (selectedCategory.length) {
    emit("submit", selectedCategory[0]);
    hidePopup();

    insightTrack(
      actionType.value === "copy"
        ? RoomRequestsBulkEvent.Copy
        : RoomRequestsBulkEvent.Move,
    );
  }
};

const isTreeBrowserLoading = ref(false);
const handleCreateCategory = async ({
  parentId,
  name,
}: {
  parentId: NodeId;
  name: string;
}) => {
  isTreeBrowserLoading.value = true;
  try {
    await categoriesStore.createCategory(name, parentId as number);
  } catch (error) {
    $notifyDanger("Failed to create new category.");
  } finally {
    isTreeBrowserLoading.value = false;
  }
};

const setSelected = (categoryId: Category["id"]) => {
  selectedCategory.value = [categoryId];
};

const show = (reference: HTMLElement, type: ActionType) => {
  actionType.value = type;
  popupRef.value?.show(reference);
};

defineExpose({ show, setSelected });
</script>
