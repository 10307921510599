import type { PhaseItem } from "@drVue/api-service/client-dashboard";
import type { PhasesTableRow } from "@drVue/components/client-dashboard/deals/PhasesTable/models";
import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";

export function calculatePhases(phases: PhaseItem[], deal: Deal) {
  const merged = phases.map((i): PhasesTableRow => {
    const phase = deal.phases_info[i.id];
    if (phase) {
      return {
        active: i.id === deal.phase,
        skipped: false,
        phase_name: i.name,
        phase_color: i.color,

        planned_duration: null,
        actual_duration: null,

        next_phase_name: null,

        ...phase,
      };
    }

    return {
      // PhasesTableRow
      active: i.id === deal.phase,
      skipped: false,
      phase_name: i.name,
      phase_color: i.color,
      planned_duration: null,
      actual_duration: null,

      // IDealPhaseInfo
      phase_id: i.id,
      deal_id: deal.id,
      comment: "",
      planned_start_date: null,
      actual_start_date: null,

      next_phase_name: null,
    };
  });

  const ONE_DAY_MS = 1000 * 60 * 60 * 24;

  // Compute Skipped State
  for (let i = 0; i < merged.length; i++) {
    if (merged[i].active) break;
    if (!merged[i].planned_start_date && !merged[i].actual_start_date)
      merged[i].skipped = true;
  }

  // Compute Planned Durations
  for (let i = 0; i < merged.length; i++) {
    if (!merged[i].planned_start_date) continue;

    const curr = merged[i];
    const next = merged.find((m, j) => j > i && m.planned_start_date);
    if (next) {
      const nextTime = next.planned_start_date!.getTime();
      const currTime = curr.planned_start_date!.getTime();

      merged[i].next_phase_name = next.phase_name;
      merged[i].planned_duration = Math.floor(
        (nextTime - currTime) / ONE_DAY_MS,
      );
    }
  }

  // Compute Actual Durations
  for (let i = 0; i < merged.length; i++) {
    // If there is nothing to compute - continue...
    if (!merged[i].actual_start_date) continue;

    // If it's an active phase - calculate difference for [actual_start_date] and [now].
    if (merged[i].phase_id === deal.phase) {
      merged[i].actual_duration = Math.floor(
        (Date.now() - merged[i].actual_start_date!.getTime()) / ONE_DAY_MS,
      );
      break;
    }

    // Default case. Calculate difference for Curr.[actual_start_date] and Next.[actual_start_date].
    const curr = merged[i];
    const next = merged.find((m, j) => j > i && m.actual_start_date);
    if (next) {
      const nextTime = next.actual_start_date!.getTime();
      const currTime = curr.actual_start_date!.getTime();

      merged[i].next_phase_name = next.phase_name;
      merged[i].actual_duration = Math.floor(
        (nextTime - currTime) / ONE_DAY_MS,
      );
    }
  }

  return merged;
}
