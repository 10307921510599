<template>
  <div v-if="row.type === 'task'">
    <span>{{ row.task.title }}</span>
    <span v-if="row.task.due_date" class="date">({{ userTime }})</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { drUserTime } from "@drVue/filters/drUserTime";

import type { ITableRow } from "../types";
import type { PropType } from "vue";

export default defineComponent({
  name: "TitleCell",
  props: {
    row: { required: true, type: Object as PropType<ITableRow> },
  },
  computed: {
    userTime() {
      const row = this.row as any;
      return drUserTime(row.task.due_date, "short-date");
    },
  },
});
</script>
