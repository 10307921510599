<template>
  <DrTooltip
    placement="top"
    :content="tooltipContent"
    :disabled="disableTooltip"
  >
    <div :class="$style.container">
      <DrIcon size="xxs" name="link" />
    </div>
  </DrTooltip>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrTooltip } from "@shared/ui/dr-tooltip";

import { ORG_MEMBER_DATA } from "@app/setups";

interface Props {
  disableTooltip?: boolean;
}

withDefaults(defineProps<Props>(), { disableTooltip: false });

const tooltipContent = computed(() => {
  if (ORG_MEMBER_DATA.group?.is_administrator) {
    return "The date depends on the deal milestone.";
  }
  return "The date depends on deal milestone. Editing permission not granted.";
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.container {
  width: 14px;
  min-width: 14px;
  height: 14px;
  background: colors.$pr-400;
  color: white;
  border-radius: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
</style>
