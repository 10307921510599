<template>
  <DrLayoutContent
    destroy-aside-on-close
    :show-aside="isFilterPanelShown"
    :class="$style.container"
  >
    <!-- <template #nav><FindingsNavBar /></template> -->

    <template #panel>
      <FindingsCategoriesTree
        :category-id="selectedCategoryId"
        @select="handleSelectCategory"
        @show-archive="handleShowArchive"
      />
    </template>

    <template #toolbar>
      <FindingsToolbarFilters
        :search="toolbarSearch"
        :filters="toolbarFilters"
        @update:filter="handleToolbarFilterUpdate"
        @update:search="handleToolbarSearchUpdate"
      >
        <template #append>
          <DrToolbarFilterButton
            label="Filters"
            icon="filter"
            :is-active="hasActiveFilter"
            :hide-dropdown="!hasActiveFilter"
            @click="toggleFilterPanel"
            @clear="clearListFilters"
          />
        </template>
      </FindingsToolbarFilters>
    </template>

    <template #toolbar-right>
      <FindingsToolbarMenu
        :columns="tableColumns.columns"
        :in-archive="isInArchive"
        @create="findingsCreate"
        @import="findingsImport"
        @export-pdf="findingsExportPdf"
        @export-xls="exportFindings('xlsx')"
      />
    </template>

    <template #aside>
      <FindingsFiltersForm
        :filters="toolbarFilters"
        :custom-data-filters="currentCustomDataFilters"
        :custom-fields="customFields"
        :has-active-filter="hasActiveFilter"
        :get-text-field-autocomplete="getTextFieldAutocomplete"
        @close="toggleFilterPanel"
        @clear="clearListFilters"
        @update:filter="handleToolbarFilterUpdate"
        @update:custom-data-filter="handleCustomDataFilterUpdate"
      />
    </template>

    <DrOverlayEmpty
      v-if="noDisplayData.active"
      icon="tasks"
      :title="noDisplayData.title"
    >
      <template #action>
        <ElButton
          v-if="noDisplayData.btnClearSearch"
          @click="toolbarSearch = ''"
        >
          Clear search query
        </ElButton>
        <ElButton
          v-if="noDisplayData.btnClearFilters"
          @click="clearListFilters"
        >
          Reset filters
        </ElButton>
        <ElButton
          v-if="noDisplayData.btnNewFinding"
          type="primary"
          @click="findingsCreate"
        >
          New finding
        </ElButton>
      </template>
    </DrOverlayEmpty>

    <FindingsTable
      ref="findingsTableRef"
      :items="searchedFindings"
      :columns="tableColumns.columns"
      :custom-fields="customFields"
      :is-loading="isDataLoading"
      @select="handleTableSelect"
      @reordered="handleTableReordered"
    />

    <FindingsCreatePanel ref="createPanelRef" />

    <FindingsDetailsPanel
      v-if="selectedFindingId"
      :shown="showDetails"
      :finding-id="selectedFindingId"
      @close="handleCloseDetails"
    />
  </DrLayoutContent>
</template>

<script setup lang="ts">
import { uniqBy } from "lodash-es";
import { computed, onBeforeMount, onMounted, ref, unref, watch } from "vue";
import { DrLayoutContent } from "@shared/ui/dr-layouts";
import { DrOverlayEmpty } from "@shared/ui/dr-overlay";
import { DrToolbarFilterButton } from "@shared/ui/dr-toolbar";
import { useBrowserLocation } from "@vueuse/core";

import { ROOM_DATA } from "@setups/index";
import {
  findingArchiveDetailsUrl,
  findingDetailsUrl,
  findingsArchiveUrl,
  findingsUrl,
} from "@setups/room-urls";
import { CustomViewObjectTypes } from "@setups/types";
import {
  insightTrack,
  RoomFindingsImportExportEvent,
  RoomFindingsOverviewEvent,
} from "@app/insight";
import { $notifyDanger, isStringContains } from "@drVue/common";
import FindingsTable from "@drVue/components/room/findings/table/FindingsTable.vue";
import TableColumns from "@drVue/components/room/findings/table/tableColumns";
import DrStore from "@drVue/store";
import { filterCustomFields } from "@drVue/store/modules/client-dashboard/fields/utils";
import { pinia } from "@drVue/store/pinia";
import { useFindingsStatusesStore } from "@drVue/store/pinia/pipeline/findings-statuses";
import { useFindingsTypesStore } from "@drVue/store/pinia/pipeline/findings-types";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";
import { useFindingsStore } from "@drVue/store/pinia/room/findings";
import { useFindingsArchiveStore } from "@drVue/store/pinia/room/findingsArchived";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import FindingsCategoriesTree from "./FindingsCategoriesTree.vue";
import FindingsCreatePanel from "./FindingsCreatePanel.vue";
import FindingsDetailsPanel from "./FindingsDetailsPanel.vue";
import FindingsFiltersForm from "./FindingsFiltersForm.vue";
import FindingsToolbarFilters from "./FindingsToolbarFilters.vue";
import FindingsToolbarMenu from "./FindingsToolbarMenu.vue";
import {
  FINDING_ROOT_CATEGORY_KEY,
  FINDING_UNTIED_CATEGORY_KEY,
  mapFindingsFieldToMatchingFunction,
} from "./types";
import { selectedCategoryId } from "./utils";

import type {
  FindingsCustomDataFiltersModel,
  FindingsCustomDataFiltersUpdate,
  FindingsFilters,
  FindingsTableRow,
  FindingsToolbarFiltersModel,
  FindingsToolbarFiltersUpdate,
} from "./types";
import type { FindingCategoryId } from "./types";
import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { CustomViewColumn } from "@setups/types";

interface Props {
  findingKey: string | null;
  listKey: string | null;
}

const props = withDefaults(defineProps<Props>(), {
  findingKey: null,
  listKey: null,
});

const createPanelRef = ref<InstanceType<typeof FindingsCreatePanel> | null>(
  null,
);

const $location = useBrowserLocation();
const isInArchive = computed(
  () => $location.value.hash?.startsWith("#/findings/archive") ?? false,
);

const findingsStore = useFindingsStore(pinia);
const findingsArchiveStore = useFindingsArchiveStore(pinia);
const findingsStatusesStore = useFindingsStatusesStore(pinia);
const findingsTypesStore = useFindingsTypesStore(pinia);
const categoriesStore = useCategoriesStore(pinia);
const tasksStore = useTasksStore(pinia);

const viewColumns = computed<CustomViewColumn[]>(() => {
  const view = DrStore.getters["common/customViews/defaultView"](
    CustomViewObjectTypes.Finding,
  );

  return view.settings.columns;
});

const { enableFindingCustomFields } = ROOM_DATA;

const customFields = computed<FieldItem[]>(() => {
  return enableFindingCustomFields
    ? DrStore.getters["clientDashboard/customFields/byObjectType"]("finding")
    : [];
});

const tableColumns = new TableColumns(viewColumns, customFields, isInArchive);

const currentFilter = ref<FindingsFilters>({});
const currentCustomDataFilters = ref<FindingsCustomDataFiltersModel>({});
const isFilterPanelShown = ref(false);

const showDetails = ref(false);
const selectedFindingId = ref<FindingsTableRow["id"] | null>(null);

const findingsTableRef = ref<InstanceType<typeof FindingsTable> | null>(null);

const isDataLoading = computed(
  () => activeStore.value.isLoading || categoriesStore.isLoading,
);

const tableStoredSettings = computed(() => {
  const view = DrStore.getters["common/customViews/defaultView"](
    CustomViewObjectTypes.Finding,
  );

  return view.settings.columns as CustomViewColumn[];
});

const extractToolbarFieldValueFromFilter = (
  field: keyof FindingsToolbarFiltersModel,
) => {
  return currentFilter.value[field]?.value ?? [];
};

const toolbarSearch = ref("");
const toolbarFilters = computed(() => {
  return {
    type_id: extractToolbarFieldValueFromFilter("type_id"),
    severity: extractToolbarFieldValueFromFilter("severity"),
    likelihood: extractToolbarFieldValueFromFilter("likelihood"),
    status_id: extractToolbarFieldValueFromFilter("status_id"),
    assignees: extractToolbarFieldValueFromFilter("assignees"),
  } as FindingsToolbarFiltersModel;
});

const hasActiveFilter = computed(() => {
  const commonActiveFilter = Object.keys(currentFilter.value).some(
    (filterName) => {
      return currentFilter.value[filterName as keyof FindingsFilters]?.value
        .length;
    },
  );

  if (commonActiveFilter) return true;

  return Object.keys(currentCustomDataFilters.value).some((cdKey) => {
    const customDataFilter = currentCustomDataFilters.value[cdKey];
    if (!customDataFilter) return false;

    const { value } = customDataFilter;
    if (Array.isArray(value)) {
      return !!value.length;
    }
    return !!value;
  });
});

const currentCategoryWithSubsUids = computed(() => {
  if (!selectedCategoryId.value) return [];

  const categoryInfo = categoriesStore.categories[selectedCategoryId.value];
  if (!categoryInfo) {
    return [];
  }
  return categoryInfo.descendants.map(
    (catId) => categoriesStore.categories[catId].uid,
  );
});

const currentCategoryUid = computed(() => {
  if (!selectedCategoryId.value) return;

  const categoryInfo = categoriesStore.categories[selectedCategoryId.value];
  if (!categoryInfo) {
    return;
  }
  return categoryInfo.uid;
});

const checkCurrentCategoryInclude = (
  categories: FindingsTableRow["categories"],
  tasks: FindingsTableRow["tasks"],
) => {
  if (!categories.length && !tasks.length) return false;

  if (
    categories.length &&
    categories.some((cat) =>
      currentCategoryWithSubsUids.value.includes(cat.category_uid),
    )
  ) {
    return true;
  }

  return tasks.some(({ task_uid }) => {
    const task = tasksStore.tasksByUid[task_uid];
    const categoryUid = task
      ? categoriesStore.categories[task.category_id]?.uid
      : null;
    return categoryUid
      ? currentCategoryWithSubsUids.value.includes(categoryUid)
      : false;
  });
};

const activeStore = computed(() => {
  return isInArchive.value ? findingsArchiveStore : findingsStore;
});

const filteredFindings = computed(() => {
  const commonFilters = Object.keys(currentFilter.value);
  const customDataFilters = Object.keys(currentCustomDataFilters.value);

  const currentFiltersCount = commonFilters.length || customDataFilters.length;

  if (
    !currentFiltersCount &&
    (!selectedCategoryId.value ||
      selectedCategoryId.value === FINDING_ROOT_CATEGORY_KEY)
  ) {
    return activeStore.value.list;
  }

  return activeStore.value.list.filter((finding) => {
    if (
      selectedCategoryId.value === FINDING_UNTIED_CATEGORY_KEY &&
      (finding.categories.length || finding.tasks.length)
    ) {
      return false;
    }

    if (
      selectedCategoryId.value &&
      selectedCategoryId.value !== FINDING_ROOT_CATEGORY_KEY &&
      selectedCategoryId.value !== FINDING_UNTIED_CATEGORY_KEY &&
      !checkCurrentCategoryInclude(finding.categories, finding.tasks)
    ) {
      return false;
    }

    if (!currentFiltersCount) {
      return true;
    }

    const matchesCommonFilters = commonFilters.every((filterName) => {
      const filterValue =
        currentFilter.value[filterName as keyof FindingsFilters]?.value;
      const findingValue = finding[filterName as keyof FindingsTableRow];

      if (filterName === "assignees" && Array.isArray(filterValue)) {
        return finding.assignees?.some(({ user_id }) => {
          return filterValue?.includes(user_id);
        });
      }

      return filterValue?.includes(findingValue as string);
    });

    const matchesCustomDataFilters = filterCustomFields(
      customFields.value,
      currentCustomDataFilters.value,
      finding.custom_data ?? {},
    );

    return matchesCommonFilters && matchesCustomDataFilters;
  });
});

const searchedFindings = computed(() => {
  if (toolbarSearch.value) {
    const fieldKey = tableStoredSettings.value.find(
      (item) => item.field === "key",
    );
    const fieldTitle = tableStoredSettings.value.find(
      (item) => item.field === "title",
    );

    const isKeyVisible = fieldKey ? !fieldKey.hidden : true;
    const isTitleVisible = fieldTitle ? !fieldTitle.hidden : true;

    return filteredFindings.value.filter((item) => {
      if (!isKeyVisible && !isTitleVisible) {
        return true;
      }
      return (
        (isKeyVisible && isStringContains(toolbarSearch.value, item.key)) ||
        (isTitleVisible && isStringContains(toolbarSearch.value, item.title))
      );
    });
  }

  return filteredFindings.value;
});

const noDisplayData = computed(() => {
  const data = {
    active: false,
    title: "",
    btnClearSearch: false,
    btnClearFilters: false,
    btnNewFinding: false,
  };

  if (isDataLoading.value) {
    return data;
  }

  if (!searchedFindings.value.length) {
    data.active = true;

    if (isInArchive.value) {
      data.title = "No findings are in the archive.";
    } else {
      data.title = "No findings were found.";
      data.btnNewFinding = true;

      if (
        selectedCategoryId.value &&
        selectedCategoryId.value !== FINDING_ROOT_CATEGORY_KEY
      ) {
        data.title = "No findings in this worklist.";
      }
    }

    if (hasActiveFilter.value || toolbarSearch.value) {
      data.btnNewFinding = false;
      data.title = hasActiveFilter.value
        ? "No findings were found matching your criterias."
        : `No results for "${toolbarSearch.value}".`;
      data.btnClearFilters = hasActiveFilter.value;
      data.btnClearSearch = !!toolbarSearch.value;
    }
  }

  return data;
});

const handleToolbarSearchUpdate = (searchValue: string) => {
  toolbarSearch.value = searchValue;

  if (searchValue) {
    insightTrack(RoomFindingsOverviewEvent.Searched);
  }
};

const handleToolbarFilterUpdate = (payload: FindingsToolbarFiltersUpdate) => {
  if (!payload.value.length && currentFilter.value[payload.field]) {
    delete currentFilter.value[payload.field];
    return;
  }

  currentFilter.value[payload.field] = {
    op: mapFindingsFieldToMatchingFunction[payload.field] || "eq",
    value: payload.value,
  };

  insightTrack(RoomFindingsOverviewEvent.Filtered, {
    filtered_by: payload.field,
  });
};

const handleCustomDataFilterUpdate = ({
  field,
  value: payload,
}: FindingsCustomDataFiltersUpdate) => {
  const { op, value } = payload;

  if ((Array.isArray(value) && !value[0]) || !value) {
    delete currentCustomDataFilters.value[field];
  } else {
    currentCustomDataFilters.value[field] = payload;
  }
};

type SuggestionsItem = { value: string };
const getTextFieldAutocomplete = (
  query: string,
  cb: (results: Record<string, any>[]) => void,
  fieldKey: string,
) => {
  const itemsData = activeStore.value.list.reduce<SuggestionsItem[]>(
    (acc, item) => {
      const value = item.custom_data?.[fieldKey];
      if (value && value !== "-" && isStringContains(query, value)) {
        acc.push({ value });
      }
      return acc;
    },
    [],
  );

  cb(uniqBy(itemsData, (r: SuggestionsItem) => r.value));
};

const toggleFilterPanel = () => {
  isFilterPanelShown.value = !isFilterPanelShown.value;
};
const clearListFilters = () => {
  currentFilter.value = {};
  currentCustomDataFilters.value = {};
};
const findingsCreate = () => {
  createPanelRef.value?.open({ categoryUid: currentCategoryUid.value });
};

const findingsImport = () => {
  console.log("stub: import findings");
};
const findingsExportPdf = () => {
  console.log("stub: export findings as pdf");
};

watch(
  (): [string, number] => [
    $location.value.hash ?? "",
    activeStore.value.list.length,
  ],
  ([hash, length]) => {
    const match = hash && hash.match(/^#\/findings\/(archive|details)\/(\d+)/i);
    const key = match && match[2];

    if (key && length) {
      const finding = activeStore.value.list.find((item) => item.key === key);
      if (finding) {
        selectedFindingId.value = finding.id;
        showDetails.value = true;
      }
    }
  },
  {
    immediate: true,
  },
);

const FINDING_NAMED_CATEGORIES = [
  FINDING_ROOT_CATEGORY_KEY,
  FINDING_UNTIED_CATEGORY_KEY,
];

watch(
  () => props.listKey,
  (listKey) => {
    if (listKey) {
      const parsedValue = FINDING_NAMED_CATEGORIES.includes(listKey)
        ? listKey
        : Number(listKey);

      if (selectedCategoryId.value !== parsedValue) {
        selectedCategoryId.value = parsedValue as FindingCategoryId;
      }
    }
  },
  {
    immediate: true,
  },
);

onBeforeMount(() => {
  if (!props.findingKey && !props.listKey && selectedCategoryId.value) {
    if (isInArchive.value) {
      $location.value.href = findingsArchiveUrl(ROOM_DATA.url);
    } else {
      $location.value.href = findingsUrl(
        ROOM_DATA.url,
        `${selectedCategoryId.value}`,
      );
    }
  }
});

const handleTableSelect = (id: FindingsTableRow["id"]) => {
  const finding = activeStore.value.dict[id];
  if (finding) {
    const getUrl = isInArchive.value
      ? findingArchiveDetailsUrl
      : findingDetailsUrl;

    $location.value.href = getUrl(ROOM_DATA.url, finding.key);
  } else {
    $notifyDanger("Failed to open finding details.");
  }
};

const handleTableReordered = async (
  moveId: FindingsTableRow["id"],
  beforeId: FindingsTableRow["id"] | null,
  afterId: FindingsTableRow["id"] | null,
  callbackReorderingCompleted: () => void,
) => {
  if (isInArchive.value) return;

  if (beforeId) {
    await findingsStore.reorderFinding(moveId, "insert_before", beforeId);
  } else if (afterId) {
    await findingsStore.reorderFinding(moveId, "insert_after", afterId);
  }

  callbackReorderingCompleted();
};

const handleCloseDetails = () => {
  const getUrl = isInArchive.value ? findingsArchiveUrl : findingsUrl;
  $location.value.href = getUrl(ROOM_DATA.url);

  if (isInArchive.value) {
    $location.value.href = findingsArchiveUrl(ROOM_DATA.url);
  } else {
    $location.value.href = findingsUrl(
      ROOM_DATA.url,
      `${selectedCategoryId.value}`,
    );
  }

  /**
   * @todo now angular router don`t react on this change location
   * so watcher of "props.findingKey" not triggered in case "!findingKey"
   */
  showDetails.value = false;
  selectedFindingId.value = null;
};

const handleSelectCategory = (id: FindingCategoryId) => {
  selectedCategoryId.value = id;
  $location.value.href = findingsUrl(ROOM_DATA.url, `${id}`);

  if (id) {
    insightTrack(RoomFindingsOverviewEvent.CategoryNavigated);
  }

  if (isInArchive.value) {
    $location.value.hash = "#/findings/";
  }
};

if (!tasksStore.tasksList.length && !tasksStore.isError) {
  tasksStore.load();
}

watch(
  () => $location.value.hash,
  () => {
    const store = unref(activeStore);

    if ((!store.list.length && !store.isLoading) || store.isLoadError) {
      activeStore.value.load().finally(() => {
        if (store.isLoadError) {
          $notifyDanger("Failed to load findings data.");
        }
      });
    }
  },
  {
    immediate: true,
  },
);
findingsStatusesStore.load().finally(() => {
  if (findingsStatusesStore.isLoadError) {
    $notifyDanger("Failed to load findings` statuses data.");
  }
});
findingsTypesStore.load().finally(() => {
  if (findingsTypesStore.isLoadError) {
    $notifyDanger("Failed to load findings` types data.");
  }
});

const handleShowArchive = () => {
  selectedCategoryId.value = FINDING_ROOT_CATEGORY_KEY;

  $location.value.hash = "#/findings/archive";

  insightTrack(RoomFindingsOverviewEvent.ArchiveOpened);
};

const exportFindings = async (format: "pdf" | "xlsx") => {
  if (format !== "xlsx") return;

  const findingsIds = findingsTableRef.value?.sortedItems.map((t) => t.id);
  if (!findingsIds || !findingsIds.length) return;

  await findingsStore.exportFindings(findingsIds, format);

  insightTrack(RoomFindingsImportExportEvent.Exported, {
    format,
    findings: "all",
  });
};

onMounted(() => insightTrack(RoomFindingsOverviewEvent.PageOpened));
</script>

<style module lang="scss">
@use "@app/styles/scss/values";

.container {
  height: calc(100vh - #{values.$header-height});
}
</style>
