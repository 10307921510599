<template>
  <div>
    <div class="deal-create__toolbar">
      <ElInput
        v-model="searchQuery"
        type="text"
        placeholder="Search"
        class="deal-create__search"
        clearable
      />
      <ElButton
        v-for="tag in tagsKeys"
        :key="tag"
        class="deal-create__tag"
        round
        size="small"
        :type="tags[tag] ? 'primary' : ''"
        @click="tags[tag] = !tags[tag]"
      >
        {{ tag }}
      </ElButton>
    </div>

    <PlaybooksList :playbooks="playbooks" @select="select" />
    <h4 v-show="!playbooks.length" class="text-center">No playbooks found</h4>
  </div>
</template>

<script lang="ts">
import { Document } from "flexsearch";
import { keyBy } from "lodash-es";
import { defineComponent } from "vue";

import { flexSearchAllResultsArray } from "@app/flex";
import playbooks from "./data";
import PlaybooksList from "./PlaybooksList.vue";

import type { Playbook } from "./types";
import type { Dictionary } from "@drVue/types";

interface Data {
  tags: Dictionary<boolean>;
  tagsKeys: string[];
  playbooksIds: Dictionary<Playbook>;
  searchQuery: string;
  flexIndex: Document<Playbook>;
}

const playbooksDictionary = keyBy(playbooks, "id");

export default defineComponent({
  name: "Playbooks",
  components: { PlaybooksList },
  emits: ["select"],
  data(): Data {
    const tags = playbooks.reduce((acc: Dictionary<boolean>, cur) => {
      for (const tag of cur.tags) {
        acc[tag] = false;
      }
      return acc;
    }, {});

    return {
      tags: tags,
      tagsKeys: Object.keys(tags),
      playbooksIds: playbooks.reduce((acc: Dictionary<Playbook>, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, {}),
      searchQuery: "",
      flexIndex: new Document({
        document: {
          id: "id",
          index: [],
        },
      }),
    };
  },
  computed: {
    playbooks(): any {
      let result = playbooks;

      const query = this.searchQuery.trim();
      if (query.length > 0) {
        const foundSortedIds = flexSearchAllResultsArray(this.flexIndex, query);
        result = foundSortedIds.map((id) => playbooksDictionary[id]);
      }

      const selectedTags = this.tagsKeys.reduce((acc: string[], cur) => {
        if (this.tags[cur]) {
          acc.push(cur);
        }
        return acc;
      }, []);

      if (selectedTags && selectedTags.length > 0) {
        result = result.filter((p) =>
          selectedTags.every((t) => p.tags.indexOf(t) > -1),
        );
      }

      return result;
    },
  },
  beforeMount() {
    this.flexIndex = new Document({
      document: {
        id: "id",
        index: [
          {
            field: "title",
            charset: "latin:advanced",
            tokenize: "full",
          },
          {
            field: "description",
            charset: "latin:advanced",
            tokenize: "forward",
          },
        ],
      },
    });

    playbooks.forEach((p) => this.flexIndex.add(p));
  },
  methods: {
    select(pb: Playbook) {
      this.$emit("select", pb);
    },
  },
});
</script>
