<template>
  <ElButton type="default" :class="$style.button" @click="emit('close')">
    <template #icon><i class="far fa-times" /></template>
  </ElButton>
</template>

<script lang="ts" setup>
const emit = defineEmits(["close"]);
</script>

<style lang="scss" module>
.button {
  position: absolute;
  top: 20px;
  left: -60px;
  z-index: 10000;
  width: 34px;
  height: 34px;
}
</style>
