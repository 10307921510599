import { ApiService } from "../index";

import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";

export interface RoomGroupMemberCopyOptions {
  user_id: string;
}

export interface RoomGroupCopyOptions {
  group_id: number;
  members: RoomGroupMemberCopyOptions[];
}

export interface RoomTaskCopyOptions {
  copy_categories?: boolean;
  copy_tasks?: boolean;
  keep_labels?: boolean;
  keep_statuses?: boolean;
  keep_assignees?: boolean;
  keep_reviewers?: boolean;
  keep_attachments?: boolean;
  keep_dependencies?: boolean;
  keep_dates?: boolean;
  keep_custom_fields?: boolean;
}

export interface RoomCopyOptions {
  source_room: number | null;
  copy_folders: boolean;
  copy_documents: boolean;
  copy_labels: boolean;
  copy_tasks: RoomTaskCopyOptions;
  copy_groups: RoomGroupCopyOptions[];
}

export interface RoomCreateData {
  title: string;
  subdomain?: string; // required only if not ORG_MEMBER_DATA.client.enable_domain
  client: number | undefined;
  copy_options: RoomCopyOptions;
  template?: string;
  new_deal_fields?: Pick<Deal, "phase" | "custom_data">;
}

export interface RoomCreateResp extends RoomCreateData {
  url: string;
}

export interface SubdomainValidateResp {
  title: string;
  subdomain: string;
  __suggested_subdomain__: string;
}

export interface RoomListItem {
  id: number;
  uid: string;
  title: string;
  subdomain: string;
  url: string;
  client_id: number;
  role: string;
}

export interface RoomGroupListItem {
  id: number;
  name: string;
}

export interface OrgRecentRoom {
  id: number;
  uid: string;
  title: string;
  url: string;
  logo: string;
}

export class RoomApiService extends ApiService {
  public create(data: RoomCreateData) {
    // todo: move this endpoint to domain api (i.e. just api:rooms:list)
    //       and remove withXSRFToken
    const url = this.Urls["root-api:rooms:list"]();

    return this.post<RoomCreateResp, RoomCreateData>(url, data, {
      // NB: enable passing XSRF token from domain origin to root-api
      withXSRFToken: true,
    });
  }

  public validateSubdomain(data: Partial<RoomCreateData>) {
    // todo: move this endpoint to domain api (i.e. just api:rooms:list)
    //       and remove withXSRFToken
    const url = this.Urls["root-api:rooms:validate-subdomain"]();

    return this.post<SubdomainValidateResp, Partial<RoomCreateData>>(
      url,
      data,
      // NB: enable passing XSRF token from domain origin to root-api
      { withXSRFToken: true },
    );
  }

  public list() {
    const url = this.Urls["root-api:rooms:list"]();

    return this.get<RoomListItem[]>(url);
  }

  public roomGroups(roomUid: string) {
    const url = this.Urls["root-api:rooms:groups"](roomUid);

    return this.get<RoomGroupListItem[]>(url);
  }

  public orgRecentRooms() {
    const url: string = this.Urls["api:client-dashboard:rooms-recent-list"]();

    return this.get<OrgRecentRoom[]>(url);
  }
}
