import { createApp } from "vue";

import { initSentry } from "@drVue/components/initSentry";
import App from "./OAuth2AuthorizeApp.vue";

export function createOAuth2AuthorizationApp() {
  const app = createApp(App);
  initSentry(app, null);
  return app;
}
