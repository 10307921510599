import documents_docsFilterHtml from "../../../templates/components/documents/docs-filter.html?raw";
import DocumentsFilterService from "../services/DocumentsFilterService";

export default function drDocsFilter() {
  return {
    bindings: {
      onFiltersChanged: "&",
    },
    template: documents_docsFilterHtml,

    controller: [
      "$scope",
      function ($scope) {
        $scope.$watch(
          "$ctrl.DocsFilter.dateUpdatedRange",
          function () {
            $ctrl.onFiltersChanged();
          },
          true,
        );

        var $ctrl = this;
        $ctrl.isFilterOpen = false;
        $ctrl.DocsFilter = DocumentsFilterService;

        $ctrl.onClearAllClick = function () {
          $ctrl.DocsFilter.clearFilters();
          $ctrl.isFilterOpen = false;
        };

        $ctrl.initialDateRangeValue = [];
        $ctrl.onDateRangeChanged = function (range) {
          $ctrl.DocsFilter.dateUpdatedRange = {
            startDate: range[0],
            endDate: range[1],
          };
        };
      },
    ],
  };
}
