<template>
  <div>
    <ElFormItem label="Symbol" :error="errorSymbol">
      <ElInput
        :model-value="extra.symbol"
        label="Symbol"
        placeholder="Optional prefix like $, USD etc."
        @update:model-value="
          (symbol) => $emit('update:extra', { ...extra, symbol })
        "
      />
    </ElFormItem>

    <ElFormItem label="Digit grouping" size="small">
      <DrRadioStack
        :model-value="extra.digit_grouping"
        :items="digitGroupingItems"
        inline
        @update:model-value="
          (digit_grouping) =>
            $emit('update:extra', { ...extra, digit_grouping })
        "
      />
    </ElFormItem>

    <div :class="$style.example">
      <span :class="$style.label">Preview: </span>
      <span>
        {{
          numberFormat(
            exampleValue,
            extra.symbol,
            extra.digit_grouping ?? "dot",
          )
        }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, onBeforeUnmount } from "vue";
import { DrRadioStack, type RadioStackItem } from "@shared/ui/dr-radio";

import { numberFormat } from "@app/vue/utils/number";

import type { FieldItemTypes } from "@app/vue/api-service/client-dashboard";
import type { FieldExtraByType } from "@app/vue/api-service/client-dashboard/types";

const digitGroupingItems: RadioStackItem<
  FieldExtraByType[FieldItemTypes.Number]["digit_grouping"]
>[] = [
  {
    id: "space",
    name: "Space",
  },
  {
    id: "comma",
    name: "Comma",
  },
];

interface Props {
  extra: FieldExtraByType[FieldItemTypes.Number];
  errorSymbol?: string;
}

interface Events {
  (
    e: "update:extra",
    value: Partial<FieldExtraByType[FieldItemTypes.Number]>,
  ): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Events>();

const exampleValue = 1234200.25;

const initializeNumberExtra = () => {
  const wasSetupBefore = !!props.extra.digit_grouping;

  if (wasSetupBefore) return;

  emit("update:extra", {
    digit_grouping: "space",
    symbol: "",
  });
};

const clearNumberExtra = () => {
  emit("update:extra", {});
};

onBeforeMount(() => {
  initializeNumberExtra();
});

onBeforeUnmount(() => {
  clearNumberExtra();
});
</script>

<style lang="scss" module>
.example {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 14px;

  .label {
    font-weight: 400;
  }
}
</style>
