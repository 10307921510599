<template>
  <div :class="$style.nav">
    <div v-for="item in itemsLocal" :key="item.id" :class="$style.itemWrapper">
      <slot
        name="item"
        v-bind="{
          item,
          itemClass: $style.item,
          itemClassActive: $style.item_isActive,
        }"
      >
        <RouterLink
          v-if="'path' in item"
          v-slot="{ isActive, href, navigate }"
          :key="item.id"
          :to="item.path"
          custom
        >
          <slot
            name="item-link"
            v-bind="{
              item,
              isActive,
              navigate,
              href,
              itemClass: $style.item,
              itemClassActive: $style.item_isActive,
            }"
          >
            <a
              :href="href"
              :class="{
                [$style.item]: true,
                [$style.item_isActive]: isActive,
              }"
              @click.prevent="selectItem(item)"
            >
              <slot name="item-prefix" v-bind="{ item, isActive }" />
              <span :class="$style.itemName">{{ item.name }}</span>
              <slot name="item-postfix" v-bind="{ item, isActive }" />
            </a>
          </slot>
        </RouterLink>

        <slot
          v-else
          name="item-button"
          v-bind="{
            item,
            itemClass: $style.item,
            itemClassActive: $style.item_isActive,
          }"
        >
          <button
            :class="{
              [$style.item]: true,
              [$style.item_isActive]: item.isActive,
            }"
            @click="selectItem(item)"
          >
            <slot
              name="item-prefix"
              v-bind="{ item, isActive: item.isActive }"
            />
            <span :class="$style.itemName">{{ item.name }}</span>
            <slot
              name="item-postfix"
              v-bind="{ item, isActive: item.isActive }"
            />
          </button>
        </slot>
      </slot>
      <div :class="$style.activeIndicator" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { keyBy } from "lodash-es";
import { computed } from "vue";
import { RouterLink, useRouter } from "vue-router";
import { useBrowserLocation } from "@vueuse/core";

import { ROOM_DATA } from "@setups/data";

import type { NavItem } from "./types";

interface Props {
  items: NavItem[];
}

/**
 * Vue.Router is not available in Angular app.
 */
const $router = ROOM_DATA.id ? null : useRouter();

const props = withDefaults(defineProps<Props>(), {
  items: () => [],
});

const emit = defineEmits<{
  (event: "select", id: NavItem["id"]): void;
}>();

const itemsLocal = computed<NavItem[]>(() => {
  if ($router) {
    return props.items;
  }

  const currentPath = useBrowserLocation().value.hash?.slice(1) || "";

  return props.items.map((item) => {
    // Transform link items to tabs because vue.router is not available here.
    if ("path" in item) {
      return {
        id: item.id,
        name: item.name,
        isActive: currentPath.startsWith(item.path),
      };
    }

    return item;
  });
});
const itemsMap = computed(() => {
  return keyBy(props.items, "id");
});

const selectItem = (item: NavItem) => {
  if ("isActive" in item && item.isActive) {
    return;
  }

  emit("select", item.id);

  const itemOriginal = itemsMap.value[item.id];

  if ("path" in itemOriginal) {
    if ($router) {
      $router.push(itemOriginal.path);
    } else {
      document.location.hash = itemOriginal.path;
    }
  }
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography" as typo;

$item-max-width: 168px; // ~20 symbols

.nav {
  display: grid;
  grid-auto-flow: column;
  align-items: stretch;
  justify-content: start;
  gap: 20px;
  height: 100%;
  min-height: 36px;
  box-shadow: 0 -1px 0 colors.$pr-200 inset;
}

.itemWrapper {
  position: relative;
  max-width: $item-max-width;
  overflow-x: hidden;
}

.activeIndicator {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: colors.$sc-600;
  transition: transform 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: scale(0);
  transform-origin: center;

  .item_isActive ~ & {
    transform: scale(1);
  }
}

.item {
  // button and link style reset
  background-color: transparent;
  border: none;
  border-radius: 0;
  text-decoration: none;

  display: grid;
  height: 100%;
  max-width: $item-max-width;
  overflow-x: hidden;
  grid-auto-flow: column;
  align-items: center;
  gap: 6px;
  padding: 0 2px;
  font: typo.$body_regular;
  color: colors.$pr-500;
  cursor: pointer;
  transition: color 400ms ease;

  &:focus,
  &:hover {
    color: colors.$pr-900;
    text-decoration: none;
    cursor: pointer;
  }
}

.item_isActive {
  font: typo.$body_semibold;
  color: colors.$pr-900;
  cursor: default;

  &:after {
    transform: scale(1);
  }
}

.itemName {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
