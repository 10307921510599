<template>
  <DynamicForm :entity="user" :schema="schema" :submit-fn="onSubmit" />
</template>

<script setup lang="ts">
import { reactive } from "vue";

import { DrStore } from "@app/vue";
import DynamicForm from "@drVue/components/client-dashboard/dynamic-form/DynamicForm.vue";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import { mapCustomFieldToSchema } from "@drVue/components/client-dashboard/dynamic-form/utils";

import type { FormSchema } from "@drVue/components/client-dashboard/dynamic-form/types";
import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";

const props = defineProps<{
  user: OrgUser;
}>();

const customFields = reactive(
  DrStore.getters["clientDashboard/customFields/byObjectType"]("user"),
);

const onSubmit = (value: any) => {
  return DrStore.dispatch("clientDashboard/orgUsers/patch", {
    userId: props.user.id,
    updates: value,
  });
};

const schema: FormSchema = {
  fields: [
    {
      type: FieldSchemaType.Email,
      label: "Email",
      prop: "email",
      isReadOnly: true,
    },
    {
      type: FieldSchemaType.Text,
      label: "First name",
      prop: "first_name",
      isReadOnly: !props.user.pending,
    },
    {
      type: FieldSchemaType.Text,
      label: "Last name",
      prop: "last_name",
      isReadOnly: !props.user.pending,
    },
    {
      type: FieldSchemaType.Text,
      label: "Company",
      prop: "company",
      isReadOnly: !props.user.pending,
    },
    {
      type: FieldSchemaType.Text,
      label: "Role",
      prop: "title",
      isReadOnly: !props.user.pending,
    },
    {
      type: FieldSchemaType.Phone,
      label: "Phone",
      prop: "office_number",
      isReadOnly: !props.user.pending,
    },
    ...customFields.map(mapCustomFieldToSchema),
  ],
};
</script>

<style lang="scss" module></style>
