import DrEditor from "@shared/ui/editor/DrEditor.vue";

angular
  .module("dealroom.components.editor", ["ngVue"])
  .component("ngVueEditor", ngVueEditor())
  .value("VueEditor", DrEditor);

function ngVueEditor() {
  return {
    require: {
      ngModel: "ngModel",
    },
    bindings: {
      mentionUsers: "<?",
      mentionTasks: "<?",
      editable: "<?",
      placeholder: "<?",
    },
    template: `<vue-component
                  name="VueEditor"
                  v-props="$ctrl.props"
                  v-on-update:model-value="$ctrl.updateModel"
                  v-on-dr-editor="$ctrl.onEditorEvent"
               ></vue-component>`,
    controller: [
      "$scope",
      "$injector",
      function ($scope, $injector) {
        const $ctrl = this;
        const defaultValue = null;

        $ctrl.props = {
          modelValue: defaultValue,
          editable: false,
          mentions: {},
        };

        $ctrl.$onInit = function () {
          const ngModel = $ctrl.ngModel;
          onModelChange();
          ngModel.$viewChangeListeners.push(onModelChange);
          ngModel.$render = onModelChange;
          updateMentionsProp();

          $ctrl.props = {
            ...$ctrl.props,

            editable: $ctrl.editable === undefined ? true : $ctrl.editable,
            options: { placeholder: $ctrl.placeholder },
          };
        };

        function onModelChange() {
          $ctrl.props = {
            ...$ctrl.props,

            modelValue: $ctrl.ngModel.$viewValue || defaultValue,
          };
        }

        $ctrl.updateModel = function (content) {
          $ctrl.ngModel.$setViewValue(content);
        };

        function updateMentionsProp() {
          $ctrl.props = {
            ...$ctrl.props,

            mentions: {
              users: $ctrl.mentionUsers?.slice(),
              tasks: $ctrl.mentionTasks?.slice(),
            },
          };
        }

        $scope.$watchCollection(
          "[$ctrl.mentionUsers, $ctrl.mentionTasks]",
          function () {
            updateMentionsProp();
          },
        );

        $ctrl.onEditorEvent = function (data) {
          switch (data.type) {
            case "task": {
              const TasksService = $injector.get("TasksService");
              if (TasksService && TasksService.tasks[data.id]) {
                const key = TasksService.tasks[data.id].key;
                TasksService.openTaskDetails(key);
              }

              break;
            }

            case "user": {
              const MembersService = $injector.get("MembersService");
              if (
                MembersService &&
                MembersService.members[data.id] &&
                !MembersService.members[data.id].is_archived
              ) {
                window.open(
                  location.href.replace(/#.+$/, `#/members/${data.id}`),
                  "_blank",
                );
              }

              break;
            }
          }
        };
      },
    ],
  };
}
