<template>
  <ElForm
    ref="usersForm"
    :model="formData"
    :rules="rules"
    :disabled="isSubmitting"
    label-position="top"
    data-testid="add-user-form"
    @submit="submit"
  >
    <ElFormItem
      prop="emails"
      class="el-form-item--label-full-width dr-add-from"
    >
      <template #label="">
        Emails:
        <AddFromGroupLink
          v-if="showAddFromGroup"
          :room-id="roomId"
          @add="addEmails"
        />
      </template>
      <DrEmailInput ref="emailInput" v-model="formData.emails" :users="users" />
    </ElFormItem>

    <ElCheckbox v-if="!roomId" v-model="isBulkAdminInvite">
      Add as administrator(s) to all your active rooms
    </ElCheckbox>

    <ElFormItem
      v-if="!isBulkAdminInvite && !roomId"
      label="Room:"
      prop="rawSelectedRoom"
    >
      <ElSelect
        v-model="formData.rawSelectedRoom"
        :disabled="isBulkAdminInvite"
        placeholder="Select a Room"
        clearable
        filterable
        :append-to-body="'body'"
        value-key="id"
        class="el-select--full-width"
      >
        <ElOption
          v-for="room in rooms"
          :key="room.id"
          :label="room.title"
          :value="room"
        />
      </ElSelect>
    </ElFormItem>

    <ElFormItem
      v-if="!isBulkAdminInvite"
      label="Permission Group:"
      prop="rawSelectedGroup"
    >
      <ElSelect
        v-model="formData.rawSelectedGroup"
        :disabled="isBulkAdminInvite || !selectedRoom"
        value-key="id"
        :placeholder="selectedRoom ? 'Select a Group' : 'Select a Room first'"
        class="el-select--full-width"
        filterable
      >
        <ElOption
          v-for="group in roomGroups"
          :key="group.id"
          :label="group.name"
          :value="group"
        />
      </ElSelect>
    </ElFormItem>

    <ElFormItem
      label="Message:"
      prop="message"
      :error="getBackendError('message')"
    >
      <ElInput
        v-model="formData.message"
        placeholder="Optional message that will be sent to invitees"
        type="textarea"
      />
    </ElFormItem>

    <div class="text-right">
      <ElButton type="primary" :loading="isSubmitting" @click="submit">
        Submit
      </ElButton>
    </div>
  </ElForm>
</template>

<script lang="ts">
import { differenceWith } from "lodash-es";
import { defineComponent, h } from "vue";
import DrEmailInput from "@shared/ui/dr-email-input/DrEmailInput.vue";
import DrForm from "@shared/ui/dr-form";

import { ORG_MEMBER_DATA } from "@setups/data";
import { $notifyDanger, $notifyInfo, $notifySuccess } from "@drVue/common";
import AddFromGroupLink from "@drVue/components/client-dashboard/users/AddUserModal/AddFromGroupLink.vue";
import InviteConfirmationMessage from "@drVue/components/client-dashboard/users/AddUserModal/invite-confirmation-message.vue";

import type {
  InviteRequest,
  InviteResponse,
} from "@drVue/api-service/client-dashboard/users";
import type {
  Room,
  RoomGroup,
} from "@drVue/store/modules/client-dashboard/deals/types";
import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { Dictionary } from "@drVue/types";
import type { Email } from "@shared/ui/dr-email-input/DrEmailInput.vue";
import type { ElForm, ElFormItem } from "element-plus";
import type { PropType } from "vue";

interface AddUserModalFormData {
  emails: Email[];
  message: string;
  rawSelectedRoom: Room | string;
  rawSelectedGroup: RoomGroup | string;
}

interface Data {
  showAddFromGroup: any;
  formData: AddUserModalFormData;
  isBulkAdminInvite: boolean;
}

export default defineComponent({
  name: "AddUserModalContent",
  components: { AddFromGroupLink, DrEmailInput },
  extends: DrForm,
  props: {
    roomId: {
      required: false,
      type: Number as PropType<number | undefined>,
      default: undefined,
    },
    groupId: {
      required: false,
      type: Number as PropType<number | null>,
      default: null,
    },
    rooms: { required: true, type: Array as PropType<Room[]> },
    api: {
      required: true,
      type: Object as PropType<{
        inviteCheck: (
          groupId: number,
          req: InviteRequest,
        ) => Promise<InviteResponse>;
        invite: (
          groupId: number,
          req: InviteRequest,
        ) => Promise<InviteResponse>;
        bulkMakeAdmin: (req: InviteRequest) => Promise<InviteResponse>;
      }>,
    },
    users: { required: true, type: Array as PropType<OrgUser[]> },
    emails: {
      required: false,
      default: () => [],
      type: Array as PropType<Email[]>,
    },
  },
  emits: ["close"],
  data(): Data {
    return {
      showAddFromGroup: !!ORG_MEMBER_DATA?.client.enable_dashboard,
      formData: {
        emails: [],
        message: "",
        rawSelectedRoom: "",
        rawSelectedGroup: "",
      },
      isBulkAdminInvite: false,
    };
  },
  computed: {
    emailsField(): any {
      const form = this.$refs["usersForm"] as any;
      if (!form) return null;

      return form.fields.find((f: typeof ElFormItem) => f.prop === "emails");
    },
    rules(): any {
      const _rules: Dictionary<any[]> = {
        emails: [
          {
            required: true,
            message: "Please enter at least one email",
            trigger: "change",
          },
          {
            validator: (
              rule: any,
              value: Email[],
              callback: (e?: Error) => void,
            ) => {
              if (value.some((e) => !e.isValid)) {
                callback(new Error("Please check email addresses"));
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
        message: [{ max: 2048, message: "Message is too long" }],
      };

      if (!this.isBulkAdminInvite) {
        _rules.rawSelectedRoom = [
          {
            required: true,
            message: "Please select room",
            trigger: "change",
          },
        ];
        _rules.rawSelectedGroup = [
          {
            required: true,
            message: "Please select group",
            trigger: "change",
          },
        ];
      }

      return _rules;
    },
    selectedRoom(): Room | null {
      if (this.roomId) {
        return this.rooms.find((r: Room) => r.id === this.roomId) || null;
      }

      if (this.formData.rawSelectedRoom === "") return null;
      return this.formData.rawSelectedRoom as Room;
    },
    selectedGroup(): RoomGroup | null {
      if (this.formData.rawSelectedGroup === "") return null;
      return this.formData.rawSelectedGroup as RoomGroup;
    },
    roomGroups(): RoomGroup[] {
      if (!this.selectedRoom) return [];
      return this.selectedRoom.groups;
    },
  },
  watch: {
    rooms() {
      if (this.roomGroups.length === 0) return;
      if (this.groupId && !this.formData.rawSelectedGroup) {
        this.formData.rawSelectedGroup =
          this.roomGroups.find((pg) => pg.id === this.groupId) || "";
      }
    },
  },
  beforeMount() {
    this.isBulkAdminInvite = false;
    this.formData = {
      emails: [],
      message: "",
      rawSelectedRoom: "",
      rawSelectedGroup: "",
    };

    if (this.emails && this.emails.length) {
      this.formData.emails = this.emails;
    }
  },
  methods: {
    addEmails(emails: string[]) {
      if (emails.length === 0) {
        $notifyInfo("The group was empty, no one was added");
      }

      // Deduplicate.
      const previousLength = emails.length;
      emails = differenceWith(
        emails,
        this.formData.emails,
        (left: string, right: Email) => left === right.address,
      );

      if (previousLength !== emails.length) {
        $notifyInfo("Some emails were filtered out as duplicates");
      }

      this.formData.emails = this.formData.emails.concat(
        emails.map((e) => ({ address: e, isValid: true })),
      );
    },
    submit() {
      // performAddTags is just a method that is being "proxied" in the following order:
      // DrEmailInput -> DrTagsInput -> VueTagsInput.performAddTags(...)
      // The method allows us to call "parsing logic" manually.
      // The internal addTag method is asynchronously updates the DOM.
      // That means that we will validate an empty input if we call it right after performAddTags.
      // That is why we use setTimeout here - to wait for the real DOM updates.
      // Read more: https://stackoverflow.com/a/47636157
      const $emailInput = this.$refs["emailInput"] as any;
      $emailInput.performAddTags();

      setTimeout(() => {
        const $usersForm = this.$refs["usersForm"] as typeof ElForm;
        $usersForm.validate((valid: boolean) => {
          if (valid) {
            if (this.isBulkAdminInvite) {
              this.inviteAdministrators(this.getInviteRequest());
            } else {
              this.inviteMembers(this.getInviteRequest());
            }
          }
        });
      });
    },
    inviteMembers(req: InviteRequest) {
      if (this.selectedGroup === null) return;

      const checkPromise = this.api.inviteCheck(this.selectedGroup?.id, req);

      let isInvite: boolean;
      let isMove: boolean;

      this.submitPromise(checkPromise, "Failed to check invites.")
        .then((r) => {
          let title = "Warning";
          let confirmButtonText = "Ok";

          isInvite = r.invited.length > 0;
          isMove = r.moved.length > 0;

          if (isInvite && isMove) {
            title = "Invite & Move member(s)";
            confirmButtonText = "Invite & Move";
          } else if (isInvite) {
            title = "Invite member(s)";
            confirmButtonText = "Invite";
          } else if (isMove) {
            title = "Move member(s)";
            confirmButtonText = "Move";
          }

          return this.$msgbox({
            type: "warning",
            title: title,
            message: h(InviteConfirmationMessage, { result: r }),
            confirmButtonText: confirmButtonText,
            showCancelButton: isInvite || isMove,
            showClose: true,
            cancelButtonText: "No",
            closeOnClickModal: false,
            closeOnHashChange: false,
            closeOnPressEscape: false,
            beforeClose: (action, instance, done) => {
              if (action === "confirm") {
                if (!(isInvite || isMove)) {
                  done();
                  return;
                }

                instance.showCancelButton = false;
                //instance.showClose = false;
                instance.confirmButtonLoading = true;

                if (this.selectedGroup === null) return;

                this.api
                  .invite(this.selectedGroup.id, req)
                  .then(done, () => {
                    $notifyDanger("Failed to invite users.");

                    instance.confirmButtonText = "Try again";
                  })
                  .finally(() => {
                    instance.showCancelButton = true;
                    //instance.showClose = true;
                    instance.confirmButtonLoading = false;
                  });
              } else {
                done();
              }
            },
          });
        })
        .then(
          () => {
            this.close(isInvite, isMove);
          },
          () => {
            // dummy cancel handler to prevent error output
          },
        );
    },
    inviteAdministrators(req: InviteRequest) {
      const members = req.members.map((m) => m.email).join(", ");
      this.$msgbox({
        message: `Are you sure want to add ${members} to all your rooms as administrator?`,
        title: "Add members",
        type: "warning",
        confirmButtonText: "Add",
        cancelButtonText: "No",
        closeOnClickModal: false,
        closeOnHashChange: false,
        closeOnPressEscape: false,
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.showCancelButton = false;
            //instance.showClose = false;
            instance.confirmButtonLoading = true;

            this.api
              .bulkMakeAdmin(req)
              .then(done, () => {
                $notifyDanger("Failed to invite users.");
                instance.confirmButtonText = "Try again";
              })
              .finally(() => {
                instance.showCancelButton = true;
                //instance.showClose = true;
                instance.confirmButtonLoading = false;
              });
          } else {
            done();
          }
        },
      }).then(() => {
        this.close(true, false);
      });
    },
    getInviteRequest(): InviteRequest {
      return {
        members: this.formData.emails.map((e: Email) => ({
          email: e.address,
        })),
        message: this.formData.message || undefined,
      };
    },
    close(isInvite: boolean, isMove: boolean) {
      if (isInvite || isMove) {
        $notifySuccess(
          isInvite && isMove
            ? "User(s) invited and moved"
            : isInvite
              ? "User(s) invited"
              : isMove
                ? "User(s) moved"
                : "",
        );
      }

      this.$emit("close");
    },
    resetForm() {
      // Reset validation state.
      const form = this.$refs["usersForm"] as typeof ElForm;
      if (form) form.resetFields();

      // Focus Email field.
      setTimeout(() => {
        const tagInput = this.$el.querySelector(
          "input.ti-new-tag-input",
        ) as HTMLInputElement;

        if (tagInput) this.$nextTick(() => tagInput.focus());
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.dr-add-from {
  position: relative;

  :deep(.el-button) {
    position: absolute;
    right: 3px;
    top: -6px;
  }
}
</style>
