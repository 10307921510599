<template>
  <DrItemListItem
    :allow-remove="!disallowRemove"
    :actions="actions"
    @remove="$emit('remove', assignee)"
    @action="$emit('remind', assignee)"
  >
    <span :class="$style.container">
      <DrAvatar
        :url="assignee.avatar?.thumbnail"
        :name="assignee.name ?? anon"
      />
      <span :class="$style.name">{{ assignee.name ?? anon }}</span>
    </span>
  </DrItemListItem>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DrAvatar } from "@shared/ui/dr-avatar";
import { DrItemListItem } from "@shared/ui/dr-item-list";

import { USER_DATA } from "@app/setups";

import type { ParticipantUserV2 } from "@drVue/store/pinia/room/tasks";
import type { ActionItem } from "@shared/ui/dr-item-list";

interface Props {
  assignee: ParticipantUserV2;
  disallowRemove: boolean;
  reminded?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  reminded: false,
});

interface Emits {
  (e: "remove", assignee: ParticipantUserV2): void;
  (e: "remind", assignee: ParticipantUserV2): void;
}

defineEmits<Emits>();

const anon = "Anonymous";

const actions = computed(() => {
  const action: ActionItem = {
    id: "remind",
    icon: "bell",
    title: "Remind",
    disabled: undefined,
  };

  if (USER_DATA.id === props.assignee.id) {
    action.disabled = true;
    action.title = "Cannot create reminder for yourself";
  }

  if (props.reminded) {
    action.disabled = true;
    action.title = "You can remind once in 24h";
  }

  return [action];
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.name {
  font: typography.$body_regular;
  color: colors.$pr-900;
}
</style>
