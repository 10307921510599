<template>
  <DrDrawer
    size="large"
    fixed-footer
    :title="title"
    :submit-btn="submitBtnText"
    :shown="shown"
    :is-submitting="isFormSubmitting"
    @submit="handleSubmit"
    @close="checkAndHandleClose"
    @cancel="resetAndClose"
  >
    <DrDynamicFormClassic
      ref="formRef"
      :schema="findingTypeFormSchema"
      :entity="findingTypeFormEntity"
      :errors="formErrors"
      @update="handleUpdateForm"
      @submit="handleSubmit"
    />

    <ElAlert
      v-if="formErrors.non_field_errors"
      type="error"
      :title="formErrors.non_field_errors"
    />
  </DrDrawer>
</template>

<script lang="ts" setup>
import { ElMessageBox } from "element-plus";
import { cloneDeep } from "lodash-es";
import { isEqual } from "lodash-es";
import { set } from "lodash-es";
import { computed, reactive, ref, watch } from "vue";
import { DrDrawer } from "@shared/ui/dr-drawer";
import { DrDynamicFormClassic } from "@shared/ui/dr-dynamic-form";
import { validateString } from "@shared/ui/dr-dynamic-form/utils";
import { useFormHelper } from "@shared/ui/dr-form";

import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import { pinia } from "@drVue/store/pinia";
import { useFindingsTypesStore } from "@drVue/store/pinia/pipeline/findings-types";

import type { FindingTypeCreateOrUpdatePayload } from "@drVue/api-service/modules/findings-types/types";
import type { UpdatePayload } from "@shared/ui/dr-dynamic-form/types";
import type { FormSchema } from "@shared/ui/dr-dynamic-form/types";

const findingsTypeStore = useFindingsTypesStore(pinia);

interface Props {
  shown: boolean;
  findingId?: string | null;
}
interface Emits {
  (event: "update:shown", isShown: boolean): void;
}

const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(), { findingId: null });

const title = computed(() =>
  props.findingId ? "Edit finding type" : "New finding type",
);
const submitBtnText = computed(() => (props.findingId ? "Edit" : "Create"));

const findingTypeEmptyEntity: FindingTypeCreateOrUpdatePayload = {
  name: "",
  color: "#ff0000",
};
const findingTypePristineEntity = reactive<FindingTypeCreateOrUpdatePayload>(
  cloneDeep(findingTypeEmptyEntity),
);

const findingTypeFormEntity = reactive<FindingTypeCreateOrUpdatePayload>(
  cloneDeep(findingTypeEmptyEntity),
);

const formRef = ref<InstanceType<typeof DrDynamicFormClassic> | null>(null);

const {
  formErrors,
  hookFormSubmitPromise,
  isFormSubmitting,
  resetErrors,
  resetError,
} = useFormHelper<FindingTypeCreateOrUpdatePayload>();

const findingTypeFormSchema = computed<FormSchema>(() => {
  return [
    {
      type: FieldSchemaType.Text,
      prop: "name",
      label: "Name",
      placeholder: "Specify name",
      rules: validateString().required("name is required"),
      required: true,
    },
    {
      type: FieldSchemaType.Color,
      prop: "color",
      label: "Color",
      rules: validateString().required("color is required"),
      required: true,
    },
  ];
});

const handleUpdateForm = ({ field, value }: UpdatePayload) => {
  resetError(field);
  set(findingTypeFormEntity, field, value);
};

const resetForms = () => {
  resetErrors();
  Object.assign(findingTypeFormEntity, cloneDeep(findingTypeEmptyEntity));
  formRef.value?.reset();
};

const handleClose = () => {
  emit("update:shown", false);
};

const resetAndClose = () => {
  resetForms();
  handleClose();
};

watch(
  props,
  () => {
    if (!props.shown) return;
    resetForms();
    if (props.findingId) {
      const finding = findingsTypeStore.list.find(
        (finding) => finding.id === props.findingId,
      );
      Object.assign(findingTypeFormEntity, cloneDeep(finding));
    }
    Object.assign(findingTypePristineEntity, findingTypeFormEntity);
  },
  { immediate: true },
);

const checkAndHandleClose = () => {
  if (isEqual(findingTypeFormEntity, findingTypePristineEntity)) {
    handleClose();
  } else {
    ElMessageBox.confirm(
      "You have unsaved changes made to the new finding type form. Are you sure you wish to discard these changes?",
      "Unsaved changes",
      {
        confirmButtonText: "Discard",
        cancelButtonText: "Cancel",
        showClose: false,
      },
    ).then(resetAndClose);
  }
};

const handleSubmit = async () => {
  if (!formRef.value) return;

  resetErrors();
  const isValid = await formRef.value.validate();

  if (isValid) {
    let promise;
    if (props.findingId) {
      promise = findingsTypeStore.update(
        props.findingId,
        findingTypeFormEntity,
      );
    } else {
      promise = findingsTypeStore.create(findingTypeFormEntity);
    }
    await hookFormSubmitPromise(promise);

    resetAndClose();
  }
};
</script>
