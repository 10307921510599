<template>
  <DynamicForm :entity="task" :schema="schema" :submit-fn="submitFn" />
</template>

<script setup lang="ts">
import { computed } from "vue";

import { ORG_MEMBER_DATA, ROOM_MEMBER_DATA } from "@setups/data";
import { TaskFieldAccessType } from "@setups/enums";
import { CustomViewObjectTypes } from "@setups/types";
import { DrStore } from "@app/vue";
import DynamicForm from "@drVue/components/client-dashboard/dynamic-form/DynamicForm.vue";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import { mapCustomFieldToSchema } from "@drVue/components/client-dashboard/dynamic-form/utils";
import { DueDateEdit, DueDateView } from "../fields/due-date";
import { LabelsEdit, LabelsView } from "../fields/labels";
import { PriorityEdit, PriorityView } from "../fields/priority";
import { StartDateEdit, StartDateView } from "../fields/start-date";
import { StatusEdit, StatusView } from "../fields/status";

import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { FormSchema } from "@drVue/components/client-dashboard/dynamic-form/types";
import type { Task } from "@drVue/store/pinia/room/tasks";

interface Props {
  task: Task;
  submitFn: (value: any) => Promise<unknown>;
}

const props = defineProps<Props>();

const accessCustomFields =
  ROOM_MEMBER_DATA.group.task_custom_fields_access ||
  TaskFieldAccessType.NoAccess;

const allowViewCustomFields = [
  TaskFieldAccessType.View,
  TaskFieldAccessType.Edit,
].includes(accessCustomFields);

const allowEditCustomFields = computed(
  () =>
    TaskFieldAccessType.Edit === accessCustomFields && !props.task.is_archived,
);

const taskCustomFields = computed((): FieldItem[] => {
  if (!allowViewCustomFields) {
    return [];
  }

  return DrStore.getters["clientDashboard/customFields/byObjectType"](
    CustomViewObjectTypes.Task,
  );
});

const accessStartDueDatesFields =
  ROOM_MEMBER_DATA.group.task_start_and_due_dates_access ||
  TaskFieldAccessType.NoAccess;

const allowViewStartDueDatesFields = [
  TaskFieldAccessType.View,
  TaskFieldAccessType.Edit,
].includes(accessStartDueDatesFields);

const allowEditStartDueDatesFields =
  TaskFieldAccessType.Edit === accessStartDueDatesFields;

const schema = computed(() => {
  const fields = [
    {
      type: FieldSchemaType.Custom,
      isReadOnly: props.task.is_archived,
      prop: "status_id",
      label: "Status",
      viewComponent: StatusView,
      editComponent: StatusEdit,
    },
    {
      type: FieldSchemaType.Custom,
      isReadOnly: props.task.is_archived,
      prop: "priority",
      label: "Priority",
      viewComponent: PriorityView,
      editComponent: PriorityEdit,
    },
    allowViewStartDueDatesFields
      ? {
          type: FieldSchemaType.Custom,
          isReadOnly:
            props.task.is_archived ||
            (props.task.is_start_date_dynamic
              ? !(
                  ORG_MEMBER_DATA.group?.is_administrator &&
                  allowEditStartDueDatesFields
                )
              : !allowEditStartDueDatesFields),
          prop: "start_date",
          label: "Start date",
          viewComponent: StartDateView,
          editComponent: StartDateEdit,
        }
      : null,
    allowViewStartDueDatesFields
      ? {
          type: FieldSchemaType.Custom,
          isReadOnly:
            props.task.is_archived ||
            (props.task.is_due_date_dynamic
              ? !(
                  ORG_MEMBER_DATA.group?.is_administrator &&
                  allowEditStartDueDatesFields
                )
              : !allowEditStartDueDatesFields),
          prop: "due_date",
          label: "Due date",
          viewComponent: DueDateView,
          editComponent: DueDateEdit,
        }
      : null,
    {
      type: FieldSchemaType.Custom,
      isReadOnly: props.task.is_archived,
      prop: "labels",
      label: "Labels",
      viewComponent: LabelsView,
      editComponent: LabelsEdit,
    },
    ...taskCustomFields.value
      .map(mapCustomFieldToSchema)
      .map((fieldSchema) => ({
        ...fieldSchema,
        isReadOnly: !allowEditCustomFields.value,
      })),
  ].filter((field) => field !== null);

  return { fields } as FormSchema;
});
</script>

<style scoped lang="scss"></style>
