import { ApiService } from "../index";

export interface InviteRequest {
  members: { email: string }[];
  message: string | undefined;
}

export interface InviteResponse {
  room_owner: string[];
  exists: string[];
  in_another: string[];
  moved: string[];
  invited: string[];
  invite_limited: string[];
  invite_ignored: string[];
  group_name: string;
}

export class UsersApiService extends ApiService {
  public bulkMakeAdmin(req: InviteRequest) {
    const url: string = this.Urls["api:client-dashboard:users-bulk-add"]();
    return this.post<InviteResponse>(url, req);
  }

  public invite(groupId: number, req: InviteRequest) {
    const url: string =
      this.Urls["api:client-dashboard:users-invite-add"](groupId);

    return this.post<InviteResponse>(url, req);
  }

  public inviteCheck(groupId: number, data: InviteRequest) {
    const url: string =
      this.Urls["api:client-dashboard:users-invite-add-check"](groupId);

    return this.post<InviteResponse>(url, data);
  }
}
