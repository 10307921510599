HeatmapCardController.$inject = [
  "$scope",
  "$element",
  "AnalyticsDispatcherFactory",
  "MembersService",
  "PermissionsService",
  "AnalyticsDataDispatcher",
  "AnalyticsActivityDataService",
  "ActivityNestService",
  "ActivityGroupPermissionsVisibleService",
];
import * as d3 from "d3";

const TOTAL_KEY = "Total Coverage";
const X_NAME = "Groups";
const STATS_TYPES = {
  viewsNumbers: {
    label: "Views",
    default: true,
  },
  viewsCoverage: {
    isPercent: true,
    showTotal: true,
    uniq: true,
    toFixed: 1,
    label: "Views Coverage",
  },
  timeSpent: {
    label: "Time Spent",
    toFixed: 0,
    get: (d) => d.time_spent || 0,
  },
};

function HeatmapCardController(
  $scope,
  $element,
  AnalyticsDispatcherFactory,
  MembersService,
  PermissionsService,
  AnalyticsDataDispatcher,
  AnalyticsActivityDataService,
  ActivityNestService,
  ActivityGroupPermissionsVisibleService,
) {
  const $ctrl = this;
  // private
  const rootElement = d3.select($element[0]);
  let _selectedMetricsName,
    _showValues,
    _yNestedData = [];

  $ctrl.dispatcher = AnalyticsDispatcherFactory("update", "toggleValues");

  $ctrl.handleSelect = handleMetricsSelect;
  $ctrl.handleShowValuesChecked = handleValueCheckbox;

  $ctrl.$onInit = function () {
    $ctrl.metricTypes = d3
      .entries(STATS_TYPES)
      .map(({ key, value }) => ({
        label: value.label,
        id: key,
        default: value.default,
      }))
      .filter(({ id }) => !!$ctrl.statsTypes[id]);
    _selectedMetricsName = $ctrl.metricTypes[0].id;
    update();
  };

  AnalyticsDataDispatcher.on("filtersChanged", nestByY, $element, $scope);

  // functions
  function handleMetricsSelect({ id }) {
    _selectedMetricsName = id;
    update();
  }

  function handleValueCheckbox(checked) {
    _showValues = checked;
    $ctrl.dispatcher.call("toggleValues", _showValues);
  }

  function update() {
    if ($scope.$$destroyed) return;
    // {xKey: {yKey: value, ..}, ...}
    const data = _yNestedData.reduce(nestByX, {});

    const xKeys = ActivityGroupPermissionsVisibleService.visiblePgroupIds || [];
    const yKeys = ($ctrl.getYKeys() || []).concat(
      STATS_TYPES[_selectedMetricsName].showTotal ? TOTAL_KEY : [],
    );

    const xLabels = xKeysToLabels(xKeys);
    const yLabels = yKeysToLabels(yKeys);

    const getData = (xKey, yKey) => {
      const keys = yKey === TOTAL_KEY ? $ctrl.getYKeys(null, false) : [yKey];
      const descendants = $ctrl.getDescendants(keys);
      let value = null;
      const debug = {};
      if (descendants != null) {
        const group = data[xKey] || {};
        if (STATS_TYPES[_selectedMetricsName].uniq === true) {
          const total = $ctrl.getTotalValues(descendants);
          debug.total = total;
          if (total > 0) {
            const viewed = descendants.reduce((count, y) => {
              const viewed = group[y] || {};
              return count + d3.keys(viewed).length;
            }, 0);
            debug.viewed = viewed;
            value = (100 * viewed) / total;
          }
        } else {
          value = descendants.reduce((c, yKey) => c + (group[yKey] || 0), 0);
        }
      }

      const toFixed = STATS_TYPES[_selectedMetricsName].toFixed;
      if (value && angular.isNumber(toFixed)) {
        value = value.toFixed(toFixed).replace(/(?!^)0+$/g, "");
        if (toFixed === 0) value = parseInt(value, 10);
        else value = parseFloat(value, 10);
      }

      return {
        value,
        debug,
      };
    };
    $ctrl.dispatcher.call("update", {
      getData,
      showPercentage: STATS_TYPES[_selectedMetricsName].isPercent,
      x: { values: xLabels, name: X_NAME },
      y: { values: yLabels, name: $ctrl.yLabel },
    });

    handleValueCheckbox(_showValues);
  }

  function nestByY() {
    const filtered = AnalyticsActivityDataService.filter($ctrl.filterBy);
    _yNestedData = ActivityNestService.nest(filtered, $ctrl.nestBy);
    update();
  }

  function nestByX(xStorages, yActivity) {
    const yKey = yActivity.key;
    yActivity.values.forEach((d) => {
      const member = MembersService.members[d.user];
      if (!member) return null;
      const xKey = member.pgroup.id;
      if (xStorages[xKey] === undefined) xStorages[xKey] = {};

      if (d.verb !== $ctrl.verb) return null;

      const isUniq = STATS_TYPES[_selectedMetricsName].uniq;
      if (xStorages[xKey][yKey] === undefined) {
        xStorages[xKey][yKey] = isUniq ? {} : 0;
      }
      const subYkey = $ctrl.getDKey(d);
      if (subYkey) {
        if (isUniq) xStorages[xKey][yKey][subYkey] = true;
        else {
          const get = STATS_TYPES[_selectedMetricsName].get;
          const value = angular.isFunction(get) ? get(d) : 1;
          xStorages[xKey][yKey] += value;
        }
      }
    });

    return xStorages;
  }

  function yKeysToLabels(yKeys = [], lvl = 0) {
    return yKeys
      .map(function (yKey) {
        let label;
        if (yKey === TOTAL_KEY) {
          label = {
            key: TOTAL_KEY,
            label: TOTAL_KEY,
            order: -1,
            cls: "bold",
          };
        } else {
          label = $ctrl.getYLabel(yKey);
          label.key = yKey;
        }
        label.lvl = lvl;
        if (label.hasChilds) {
          label.childs = (lvl = 0) => {
            return yKeysToLabels($ctrl.getYKeys(yKey), lvl + 1);
          };
        }
        return label;
      })
      .sort((a, b) => d3.descending(+a.order, +b.order));
  }

  function xKeysToLabels(xKeys = []) {
    return xKeys.map((groupId) => ({
      key: groupId,
      label: PermissionsService.pgroups[groupId].name,
    }));
  }
}

export default HeatmapCardController;
