<template>
  <DrItemListItem
    :allow-remove="!disallowRemove"
    @remove="$emit('remove', follower)"
  >
    <span :class="$style.container">
      <DrAvatar
        :url="follower.avatar?.thumbnail"
        :name="follower.name ?? anon"
      />
      <span :class="$style.name">{{ follower.name ?? anon }}</span>
    </span>
  </DrItemListItem>
</template>

<script setup lang="ts">
import { DrAvatar } from "@shared/ui/dr-avatar";
import { DrItemListItem } from "@shared/ui/dr-item-list";

import type { ParticipantUserV2 } from "@drVue/store/pinia/room/tasks";

interface Props {
  follower: ParticipantUserV2;
  disallowRemove: boolean;
}

interface Emits {
  (e: "remove", follower: ParticipantUserV2): void;
}

defineProps<Props>();

defineEmits<Emits>();

const anon = "Anonymous";
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.name {
  font: typography.$body_regular;
  color: colors.$pr-900;
}
</style>
