<template>
  <ElDialog
    v-model="isDialogVisible"
    :title="view.slug ? 'Update view' : 'Create view'"
    width="400px"
    :before-close="close"
    :append-to-body="true"
  >
    <ElForm
      ref="dealsViewForm"
      v-loading="isSubmitting"
      :model="dealsViewData"
      :disabled="isSubmitting"
      :rules="rules"
      label-position="top"
      @submit.prevent="formSubmit"
    >
      <ElFormItem
        v-if="showNameInput"
        label="View name"
        prop="name"
        :error="getBackendError('name')"
      >
        <ElInput v-model="dealsViewData.name" />
      </ElFormItem>
      <ElFormItem
        v-if="showSharingInput"
        label="Shared with"
        prop="is_personal"
      >
        <ElSelect
          v-model="dealsViewData.is_personal"
          placeholder="Select"
          class="el-select--full-width"
        >
          <ElOption
            v-for="option in SHARING_OPTIONS"
            :key="option.value"
            :label="option.label"
            :value="option.value"
            :class="$style.selectItem"
          >
            <span>{{ option.label }}</span>
            <small>{{ option.description }}</small>
          </ElOption>
        </ElSelect>
      </ElFormItem>
      <ElFormItem>
        <ElButton type="primary" :loading="isSubmitting" @click="formSubmit">
          {{ view.slug ? "Update view" : "Create view" }}
        </ElButton>
        <ElButton @click="close"> Cancel</ElButton>
      </ElFormItem>
    </ElForm>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrForm from "@shared/ui/dr-form";

import { DashboardViewsEvent, insightTrack } from "@app/insight";

import type { CustomView } from "@setups/types";
import type { PropType } from "vue";

interface Data {
  dealsViewData: Partial<CustomView>;
  SHARING_OPTIONS: any;
  rules: any;
}

export default defineComponent({
  name: "EditDealsViewDialog",
  extends: DrForm,
  props: {
    view: { required: true, type: Object as PropType<CustomView> },
    showNameInput: {
      required: false,
      default: true,
      type: Boolean as PropType<boolean>,
    },
    showSharingInput: {
      required: false,
      default: true,
      type: Boolean as PropType<boolean>,
    },
  },
  emits: ["close"],
  data(): Data {
    return {
      dealsViewData: {
        name: "",
        is_personal: true,
      },
      SHARING_OPTIONS: [
        { label: "Personal", value: true, description: "Only you can see it" },
        {
          label: "Everyone",
          value: false,
          description: "All users can see and use it",
        },
      ],
      rules: {
        name: [
          { required: true, message: "Please input name", trigger: "blur" },
          { max: 64, message: "Name is too long" },
        ],
        is_personal: [
          {
            required: true,
            message: "Please select visibility",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    isDialogVisible() {
      return true;
    },
  },
  mounted() {
    this.dealsViewData.name = this.view.name;
    this.dealsViewData.is_personal = this.view.is_personal;
  },
  methods: {
    close() {
      this.$emit("close");
    },
    formSubmit() {
      (this.$refs["dealsViewForm"] as any).validate((valid: boolean) => {
        if (!valid) {
          return;
        }
        const data = {
          ...this.view,
          ...this.dealsViewData,
        };
        const isNewView = !this.view.slug;
        const method = isNewView ? "create" : "update";
        const action = `common/customViews/${method}`;
        this.submitAction(action, data, "Failed to save view").then(
          (view: CustomView) => {
            if (isNewView) {
              insightTrack(DashboardViewsEvent.Create);
              this.$router.push(`/deals/${view.slug}`);
            } else {
              insightTrack(DashboardViewsEvent.Save);
            }
            this.close();
          },
        );
      });
    },
  },
});
</script>

<style module lang="scss">
@use "@app/styles/scss/spacing";

.selectItem {
  display: flex;
  justify-content: space-between;
  padding-right: spacing.$xxxl;
}
</style>
