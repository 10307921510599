import documents_docsItemSelectHtml from "../../../templates/components/documents/docs-item-select.html?raw";

export default function drDocsItemSelect() {
  //allows select either folder or file
  return {
    bindings: {
      item: "=",
    },
    template: documents_docsItemSelectHtml,
    controller: [
      "DocumentsService",
      function (DocumentsService) {
        var $ctrl = this;
        $ctrl.Documents = DocumentsService;
        $ctrl.expanded = {};

        $ctrl.select = function (item) {
          $ctrl.item = item;
        };

        $ctrl.isActive = function (item) {
          return item === $ctrl.item;
        };

        $ctrl.isExpanded = function (folder) {
          return $ctrl.expanded[folder.id];
        };

        $ctrl.toggleExpanded = function (folder) {
          $ctrl.expanded[folder.id] = !$ctrl.expanded[folder.id];
        };
      },
    ],
  };
}
