import { range } from "lodash-es";

angular
  .module("dealroom.analytics.heatmap.service.config", [
    "dealroom.analytics.service.colors-scheme",
  ])
  .service("AnalyticsHeatmapConfigService", AnalyticsHeatmapConfigService);

AnalyticsHeatmapConfigService.$inject = ["AnalyticsColorsSchemeService"];
function AnalyticsHeatmapConfigService(AnalyticsColorsSchemeService) {
  const { YAXIS, XAXIS, LEGEND } = getParams();
  const service = {
    getDefaultData,
    MIN_CELL_HEIGHT: 30,
    YAXIS,
    XAXIS,
    LEGEND,
    DEFAULT_COLOR: AnalyticsColorsSchemeService.grey.light,
    COLORS: [
      AnalyticsColorsSchemeService.blue.light,
      AnalyticsColorsSchemeService.blue.normal,
    ],
  };
  return service;
}

function getDefaultData() {
  return {
    x: {
      values: range(4).map((key) => ({ key, label: "Insufficient data" })),
    },
    y: {
      values: range(4).map((key) => ({ key, label: "N/A" })),
    },
    getData: (x, y) => ({ x, y, value: null }),
  };
}

function getParams() {
  const LEGEND = {
    width: 100,
    height: 10,
    margin: 7,
  };
  const YAXIS = {
    width: 55,
    right: 10,
    height: 250,
    name: {
      height: 13,
      top: 5,
    },
    collapse: {
      width: 10,
      fontSize: 13,
    },
  };
  const XAXIS = {
    height: 30,
    top: 5,
    name: {
      height: 13,
      top: 3,
    },
  };

  XAXIS.name.full = XAXIS.name.height + XAXIS.name.top;
  YAXIS.name.full = YAXIS.name.height + YAXIS.name.top;

  XAXIS.left = YAXIS.full = YAXIS.width + YAXIS.right + YAXIS.name.full;

  YAXIS.bottom = XAXIS.full = XAXIS.height + XAXIS.top + XAXIS.name.full;
  YAXIS.top = LEGEND.height + LEGEND.margin;

  return { YAXIS, XAXIS, LEGEND };
}
