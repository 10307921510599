<template>
  <div
    :class="{
      [$style.header]: true,
      [$style.header_isActionAllowed]: allowAction,
    }"
    @click="handleClick"
  >
    <div
      :class="{
        [$style.title]: true,
        [$style.title_isLightWeight]: lightWeight,
      }"
    >
      {{ title }}
    </div>

    <div v-if="allowAction" :class="$style.action">
      <DrIcon name="plus" size="xxs" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { DrIcon } from "@shared/ui/dr-icon";

interface Props {
  title: string;
  lightWeight?: boolean;
  allowAction?: boolean;
}

interface Emits {
  (e: "action"): void;
}

const props = withDefaults(defineProps<Props>(), {
  lightWeight: false,
  allowAction: false,
});

const emit = defineEmits<Emits>();

const handleClick = () => {
  if (!props.allowAction) return;

  emit("action");
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";
@use "@app/styles/scss/spacing";

.header {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 6px;
  padding-right: spacing.$xxs;
}

.title {
  font: typography.$body_medium;
  font-weight: 500;
  color: colors.$pr-900;
}

.title_isLightWeight {
  font: typography.$body_regular;
  color: colors.$pr-600;
}

.action {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: colors.$pr-600;
  border: solid 1px colors.$pr-300;
  border-radius: 4px;
}

.header_isActionAllowed:hover {
  cursor: pointer;

  .title_isLightWeight {
    color: colors.$pr-900;
  }

  .action {
    color: colors.$sc-600;
    border-color: colors.$sc-400;
  }
}
</style>
