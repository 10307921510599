<template>
  <DrDrawer
    size="large"
    fixed-footer
    title="Edit all"
    submit-btn="Save changes"
    cancel-btn="Discard"
    :shown="shown"
    :disallow-submit="dealDataNotChanged"
    :is-submitting="isFormSubmitting"
    @submit="handleSubmit"
    @cancel="handleCancel"
    @close="handleClose"
  >
    <DrDynamicFormClassic
      ref="formRef"
      :schema="dealFormSchema"
      :entity="dealFormEntity"
      :errors="dealFormErrors"
      @update="handleUpdateDealForm"
      @submit="handleSubmit"
    />

    <ElAlert
      v-if="dealFormErrors.non_field_errors"
      type="error"
      :title="dealFormErrors.non_field_errors"
    />
  </DrDrawer>
</template>

<script setup lang="ts">
import { cloneDeep } from "lodash-es";
import { isEqual } from "lodash-es";
import { set } from "lodash-es";
import { computed, reactive, ref, watch } from "vue";
import { DrDrawer } from "@shared/ui/dr-drawer";
import { DrDynamicFormClassic } from "@shared/ui/dr-dynamic-form";
import {
  mapCustomFieldToSchema,
  validateDate,
} from "@shared/ui/dr-dynamic-form/utils";
import { useFormHelper } from "@shared/ui/dr-form";

import { DrStore } from "@app/vue";
import { FieldItemTypes } from "@drVue/api-service/client-dashboard/enums";
import $utils from "@drVue/utils";

import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";
import type { FormSchema } from "@shared/ui/dr-dynamic-form/types";

interface Props {
  deal: Deal;
  shown: boolean;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (event: "update:shown", isShown: boolean): void;
}>();

const formRef = ref<InstanceType<typeof DrDynamicFormClassic> | null>(null);
const customFields = reactive(
  DrStore.getters["clientDashboard/customFields/byObjectType"]("deal"),
);

const {
  formErrors: dealFormErrors,
  hookFormSubmitPromise,
  isFormSubmitting,
  resetErrors,
  resetError,
} = useFormHelper<Deal>();

// @ts-ignore: No overload matches this call.
const dealFormSchema = computed<FormSchema>(() => {
  return [
    {
      type: FieldItemTypes.Date,
      prop: "start_date",
      label: "Start date",
      rules: validateDate().required("Start date is required"),
    },
  ].concat(customFields.map(mapCustomFieldToSchema));
});

const dealFormEntity = reactive<Deal>(cloneDeep(props.deal));
watch(
  () => props.deal,
  (value) => {
    Object.assign(dealFormEntity, cloneDeep(value));
  },
);

const handleUpdateDealForm = ({
  field,
  value,
}: {
  field: string;
  value: any;
}) => {
  resetError(field);
  set(dealFormEntity, field, value);
};

const dealDataNotChanged = computed(() => isEqual(dealFormEntity, props.deal));

const handleClose = () => {
  emit("update:shown", false);
};
// reset form on "discard"
const handleCancel = () => {
  handleClose();
  resetErrors();
  Object.assign(dealFormEntity, cloneDeep(props.deal));
  formRef.value?.reset();
};

const handleSubmit = async () => {
  if (!formRef.value) return;

  resetErrors();
  const isValid = await formRef.value.validate();

  if (isValid) {
    const updates = $utils.objects.diff(
      dealFormEntity,
      props.deal as unknown as Record<string, unknown>,
    );

    await hookFormSubmitPromise(
      DrStore.dispatch("clientDashboard/deals/patchDeal", {
        dealId: props.deal.id,
        updates,
      }),
    );

    handleClose();
  }
};
</script>
