import * as d3 from "d3";

angular
  .module("dealroom.analytics.factory.templates.controllers", [
    "dealroom.analytics.service.data",
    "dealroom.members",
  ])
  .service(
    "AnalyticsTemplatesControllersService",
    AnalyticsTemplatesControllersService,
  );

AnalyticsTemplatesControllersService.$inject = [
  "ActivityCategoriesService",
  "ActivityTasksService",
  "ActivityDocumentsService",
  "PermissionsService",
  "MembersService",
];
function AnalyticsTemplatesControllersService(
  ActivityCategoriesService,
  ActivityTasksService,
  ActivityDocumentsService,
  PermissionsService,
  MembersService,
) {
  const controllers = {
    task: getTaskController,
    category: getCategoryController,
    user: getUserController,
    group: getPgroupController,
    "group permission": getPgroupController,
    folder: getFolderController,
    document: getDocumentController,
    dt: getDtController,
    progressbar: getProgressBarController,
    tooltip: getTooltipController,
  };

  return { getController };

  function getController(d) {
    const _getController = controllers[d.type] || getValue;
    return _getController(d);
  }

  function getTaskController({ id, showPath }) {
    const task = ActivityTasksService.tasks[id];
    if (!task) return getValueController({ value: "Unknown request" });
    const category = ActivityCategoriesService.categories[task.category_id];
    return {
      task,
      isLink: !category.is_archived,
      isArchived: category.is_archived,
      categoryId: showPath && category.id,

      src: "task",
    };
  }

  function getCategoryController({ id }) {
    const category = ActivityCategoriesService.categories[id];
    if (!category) return getValueController({ value: "Unknown list" });
    return {
      category,
      isLink: !category.is_archived,
      isArchived: category.is_archived,

      src: "category",
    };
  }

  function getFolderController({ id }) {
    const folder = ActivityDocumentsService.Folders[id];
    if (!folder) return getValueController({ value: "Unknown folder" });
    return {
      folder,
      isLink: !folder.is_archived,

      src: "folder",
    };
  }

  function getDocumentController({ id }) {
    const doc = ActivityDocumentsService.Files[id];
    if (!doc) return getValueController({ value: "Unknown document" });
    return {
      document: doc,
      isLink: !doc.is_archived,

      src: "document",
    };
  }

  function getUserController({ id }) {
    return {
      userId: id,

      src: "user",
    };
  }

  function getPgroupController({ id, userId }) {
    let name = null;
    if (userId) {
      const user = MembersService.members[userId];
      id = user && user.pgroup.id;
    }
    if (id) {
      const pgroup = PermissionsService.pgroups[id];
      name = pgroup ? pgroup.name : "Unknown group";
    }
    return getValueController({ value: name });
  }

  function getDtController({ dt }) {
    return getValue({ value: dt });
  }

  function getValue({ value, getDisplayedValue }) {
    if (value === null) return "Insufficient data";
    return getDisplayedValue ? getDisplayedValue(value) : value;
  }

  function getValueController(d) {
    return {
      value: getValue(d),

      src: "value",
    };
  }

  function getProgressBarController({ value, showPercentage, dispatcher }) {
    return {
      value,
      showPercentage,
      src: "progressbar",
      dispatcher: dispatcher ? d3.dispatch("update") : undefined,
    };
  }

  function getTooltipController({ shadowId, timeout }) {
    return {
      dispatcher: d3.dispatch("update"),
      shadowId,
      timeout,

      attrs: [
        ["dr-analytics-tooltip", "::$ctrl.dispatcher"],
        ["timeout", "{{ ::$ctrl.timeout }}"],
        ["shadow-id", "{{ ::$ctrl.shadowId }}"],
      ],
    };
  }
}
