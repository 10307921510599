import "./service.data.js";

import * as d3 from "d3";

import animation from "./animation";
window.animation = animation;

angular
  .module("dealroom.analytics.progressbar", [
    "dealroom.analytics.progressbar.service.data",
    "dealroom.analytics.factory.templates",
  ])
  .component("drAnalyticsProgressbar", {
    bindings: {
      values: "<",
      width: "@",
      showPercentage: "<?",
      dispatcher: "<?",
    },
    controller: AnalyticsProgressbar,
  });

const CLASSES = {
  root: {
    main: "analytics-stats-progress-bar",
    percentage: "analytics-stats-progress-bar--percentage",
  },
  bar: "analytics-stats-progress-bar__bar",
  label: "analytics-stats-progress-bar__label",
};
const SELECTORS = Object.keys(CLASSES).reduce((b, n) => {
  b[n] = "." + CLASSES[n];
  return b;
}, {});

/*
ITEMS array of properties for each bar
BAR PROPERTIES:
  value: int, to calculate the width ( = totalValue / value);

  optional:
  color:   bg of a bar
           ! has higher priority than `percantageColorize` !;
           (default: AnalyticsProgressbarData.DEFAULT_COLOR
            or AnalyticsProgressbarData.DEFAULT_TOTAL_COLOR)
  tooltip: props of tooltip directive;
  isTotal: if there is no item with `isTotal` in array,
           totalValue = sum of all values.
           if an item with `isTotal` is given
           totalValue = value of this item;
  percantageColorize:
           calculate bg of a bar
           true:  if value < 25 : color = red
                  if value < 50 : color = orange
                  if value > 50 : color = green;

           false: if value < 25 : color = green
                  if value < 50 : color = orange
                  if value > 50 : color = red;
*/

AnalyticsProgressbar.$inject = [
  "$element",
  "$scope",
  "AnalyticsProgressbarDataService",
  "AnalyticsTemplatesFactory",
];
function AnalyticsProgressbar(
  $element,
  $scope,
  AnalyticsProgressbarDataService,
  AnalyticsTemplatesFactory,
) {
  const $ctrl = this;
  const pk = "AnalyticsProgressbar" + $scope.$id;
  const rootElement = d3.select($element[0]).style("display", "block");
  const container = rootElement
    .append("div")
    .attr("class", CLASSES.root.main)
    .classed(CLASSES.root.percentage, $ctrl.showPercentage);
  let elementWidth = 0;

  $ctrl.$onInit = function () {
    elementWidth = $ctrl.width || rootElement.node().offsetWidth;
    container.style("width", elementWidth + "px");
    update($ctrl.values);
    $scope.$on("$destroy", () => {
      container.selectAll(SELECTORS.bar).each(removeBar);
    });

    $ctrl.dispatcher && $ctrl.dispatcher.on("update." + pk, update);
  };

  function removeBar() {
    AnalyticsTemplatesFactory.remove(this);
  }

  function update(items) {
    // shrink before updating
    const bars = container
      .selectAll(SELECTORS.bar)
      .call(animation.shrink, updateBars);

    function updateBars() {
      const data = AnalyticsProgressbarDataService.convert(items, elementWidth);
      const bars = container
        .selectAll(SELECTORS.bar)
        .data(data, (d, i) => d.key || i);

      bars.exit().each(removeBar);

      bars
        .enter()
        .append("div")
        .attr("class", CLASSES.bar)
        .attr("style", getInitalStyle)
        .property("dispatcher", createTooltip)
        .merge(bars)
        .call(animation.expand)
        .each(updateLabel)
        .each(renderTooltip);
    }

    function getInitalStyle(d, i) {
      return animation.getStyleAsAttr(d.start, d.zIndex);
    }

    function createTooltip() {
      const node = d3.select(this);
      const { dispatcher } = AnalyticsTemplatesFactory.render(node.node(), {
        type: "tooltip",
      });
      return dispatcher;
    }

    function renderTooltip({ tooltip }) {
      const node = d3.select(this);
      const dispatcher = node.property("dispatcher");
      dispatcher.call("update", null, tooltip);
    }

    function updateLabel(d) {
      if ($ctrl.showPercentage !== true) return;

      const node = d3.select(this);
      const label = getLabel();
      label.text(d.label);

      function getLabel() {
        let label = node.select(SELECTORS.label);
        if (label.empty()) {
          label = node
            .append("span")
            .attr("class", CLASSES.label)
            .attr(animation.LABEL_ATTR, true)
            .style("opacity", d.start.labelOpacity);
        }
        return label;
      }
    }
  }
}
