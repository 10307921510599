<template>
  <StartDateEdit :edit-props="editProps" @change="handleChange" />
</template>

<script setup lang="ts">
import { insightTrack, TaskDetailsTrackingEvent } from "@app/insight";
import { StartDateEdit } from "@drVue/components/room/tasks/shared/widgets/start-date";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { Task } from "@drVue/store/pinia/room/tasks";

interface Props {
  editProps: EditFieldProps<Date, Task>;
}
defineProps<Props>();

function handleChange() {
  insightTrack(TaskDetailsTrackingEvent.StartDateSet);
}
</script>
