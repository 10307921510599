<template>
  <DrToolbarFilterSearch
    :model-value="search"
    @update:model-value="emit('update:search', $event)"
  />

  <DrToolbarFilterDatepicker
    v-model="localFilters.due_datetime"
    label="Due date"
    @update:model-value="updateField('due_datetime')"
  />
</template>
<script setup lang="ts">
import { cloneDeep } from "lodash-es";
import { reactive, watch } from "vue";
import {
  DrToolbarFilterDatepicker,
  DrToolbarFilterSearch,
} from "@shared/ui/dr-toolbar";

import type {
  YourWorkDealTasksFilters,
  YourWorkDealTasksToolbarFiltersUpdate,
} from "./types";

interface Props {
  search: string;
  filters: YourWorkDealTasksFilters;
}
const props = defineProps<Props>();

const emit = defineEmits<{
  (
    event: "update:filter",
    payload: YourWorkDealTasksToolbarFiltersUpdate,
  ): void;
  (event: "update:search", payload: string): void;
}>();

const localFilters = reactive<YourWorkDealTasksFilters>({
  due_datetime: [],
});

watch(
  () => props.filters,
  (value: YourWorkDealTasksFilters) => {
    Object.assign(localFilters, cloneDeep(value));
  },
  {
    deep: true,
    immediate: true,
  },
);

const updateField = (field: keyof YourWorkDealTasksFilters) => {
  emit("update:filter", {
    field,
    value: cloneDeep(localFilters[field]),
  });
};
</script>
