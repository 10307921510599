<template>
  <div>
    <ElButton size="small" @click="showModal"> View AI usage </ElButton>
    <ElDialog v-model="isModalVisible" width="800px" append-to-body>
      <template #header>
        <h4>AI Usage</h4>
        <span>Usage information for the selected date range.</span>
      </template>

      <div class="date-range-picker">
        <ElDatePicker
          v-model="datesRange"
          type="daterange"
          start-placeholder="From"
          end-placeholder="To"
          @change="loadData"
        />
      </div>

      <DrVxeGrid
        outlined
        :data="tableData"
        :columns="columns"
        :loading="isLoading"
        :sort-config="{ trigger: 'cell' }"
        empty-text="No data"
        :max-height="500"
      />
      <br />
      <div class="text-right">
        <ElButton type="primary" @click="exportData"> Export </ElButton>
      </div>
    </ElDialog>
  </div>
</template>

<script lang="ts" setup>
import { format } from "date-fns";
import { sub } from "date-fns";
import { ElDatePicker } from "element-plus";
import { ref, watch } from "vue";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import {
  type ClientAiUsage,
  ManagementApiService,
} from "@drVue/api-service/management";
import { $notifyDanger } from "@drVue/common";
import { drUserTime } from "@drVue/filters/drUserTime";
import { ExcelReport, exportToExcel } from "@drVue/store/modules/reporting";

import type { DrVxeTableColumn } from "@drVue/components/types";
import type { Column, ReportItem } from "@drVue/store/modules/reporting";

const props = defineProps<{
  clientId: number;
}>();

const isLoading = ref(false);
const isModalVisible = ref(false);
const tableData = ref<ClientAiUsage[]>([]);

const datesRange = ref<[Date, Date]>([
  sub(new Date(), { months: 1 }),
  new Date(),
]);

// Define the columns for the AI usage table
const columns: DrVxeTableColumn<ClientAiUsage>[] = [
  {
    sortable: true,
    field: "date",
    title: "Date",
    showOverflow: "tooltip",
    formatter: ({ cellValue }: { cellValue: Date }) =>
      drUserTime(cellValue, "full-date")!,
  },
  {
    sortable: true,
    field: "applied_reports_count",
    title: "Applied Reports",
    showOverflow: "tooltip",
    formatter: ({ cellValue }: { cellValue: number }) => cellValue,
  },
  {
    sortable: true,
    field: "answered_questions_count",
    title: "Answered Questions",
    showOverflow: "tooltip",
    formatter: ({ cellValue }: { cellValue: number }) => cellValue,
  },
  {
    sortable: true,
    field: "processed_documents_count",
    title: "Processed Documents",
    showOverflow: "tooltip",
    formatter: ({ cellValue }: { cellValue: number }) => cellValue,
  },
];

// Function to export data to Excel
const exportData = () => {
  const report = new AIUsageExcelReport();
  exportToExcel(report);
};

// Excel report class for exporting AI usage data
class AIUsageExcelReport extends ExcelReport {
  constructor() {
    super("ai_usage.xlsx", "AI Usage");
  }

  get columns(): Column[] {
    return columns.map((col) => ({
      title: col.title!,
      data_index: col.field,
      width: 24,
    }));
  }

  get items(): ReportItem[] {
    return tableData.value.map((row: ClientAiUsage) => {
      return {
        date: drUserTime(row.date, "full-date")!,
        applied_reports_count: row.applied_reports_count,
        answered_questions_count: row.answered_questions_count,
        processed_documents_count: row.processed_documents_count,
      };
    });
  }
}

// Function to load AI usage data from the API
const loadData = () => {
  if (isLoading.value) return;

  isLoading.value = true;

  const api = new ManagementApiService();

  const [fromDate, toDate] = datesRange.value;

  // Ensure dates are valid
  if (!fromDate || !toDate) {
    isLoading.value = false;
    return;
  }

  api
    .getClientAiUsage(
      props.clientId,
      format(fromDate, "yyyy-MM-dd"),
      format(toDate, "yyyy-MM-dd"),
    )
    .then((aiUsage) => {
      tableData.value = aiUsage;
    })
    .catch(() => {
      $notifyDanger("Failed to load AI usage");
    })
    .finally(() => {
      isLoading.value = false;
    });
};

// Function to show the modal and load data
const showModal = () => {
  isModalVisible.value = true;
  loadData();
};

// Watch datesRange and reload data when it changes
watch(datesRange, () => {
  if (isModalVisible.value) loadData();
});
</script>

<style scoped>
.date-range-picker {
  margin-bottom: 16px;
}
</style>
