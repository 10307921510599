<template>
  <div :class="$style.wrapper">
    <div v-if="hasHeader" :class="$style.header">
      <div :class="$style.analysis">
        <IconAiAnalysis active />
        <div>AI Analysis</div>
      </div>
      <i class="mimetypes" :class="getIconClass(props.docMimetype)" />
      <span :class="$style.headerDocTitle">
        {{ docIndex }}
        {{ docName }}
      </span>
    </div>
    <div>
      <DrNav
        :class="$style.tabs"
        :items="computedTabs"
        :active-tab="activeTab"
        @select="openTab"
      />
    </div>
    <div
      ref="questionsList"
      :class="$style.questions"
      v-show="activeTab == 'Chat'"
      v-loading="questionsIsLoading && !questions.length"
    >
      <h4>
        <span v-if="questionsLoadError"> Error: {{ questionsLoadError }} </span>
      </h4>
      <div v-for="qa in questions" :key="qa.id" :class="$style.category">
        <Question
          :qa="qa"
          :doc-id="docId"
          :doc-uid="docUid"
          :saved="!!aiTemplatesStore.dictByQuestion[qa.question]"
          @review-submitted="onNewReview"
          @expanded="onQaExpanded"
          @delete="deleteQuestion"
        />
      </div>
    </div>
    <div :class="$style.footer" v-show="activeTab == 'Chat'">
      <NewMessage
        ref="newMessageEl"
        :is-disabled="questionsIsLoading && !questions.length"
        :doc-uid="props.docUid"
        @new-q-a="onNewMessage"
        @show-templates="showTemplates"
      />
    </div>
    <AiTemplates
      ref="aiTemplatesEl"
      :doc-uid="docUid"
      :applied-q-a="questions"
      @new-applied="handleAppliedCategory"
    />
    <AiReports
      v-show="activeTab == 'Reports'"
      :doc-id="docId"
      :doc-uid="docUid"
      :applied-q-a="questions"
    />
    <AiDev
      v-if="FEATURE_SWITCH_ENABLE_AI_DEV_MODE"
      v-show="activeTab == 'AI Dev'"
      :doc-uid="docUid"
      :applied-q-a="questions"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, ref, watch } from "vue";
import { DrNav } from "@shared/ui/dr-nav";
import { useAsyncState, useIntervalFn } from "@vueuse/core";

import getIconClass from "@app/common/mimetype";
import { AiReportsEvent, insightTrack } from "@app/insight";
import { APP_SETTINGS, USER_DATA } from "@app/setups";
import { loadFromLocalStorage } from "@app/vue/common";
import { pinia } from "@drVue/store/pinia";
import IconAiAnalysis from "../documents/svg/IconAiAnalysis.vue";
import AiDev from "./AiDev.vue";
import AiReports from "./AiReports.vue";
import { useAiReportsStore } from "./AiReportsStore";
import AiTemplates from "./AiTemplates.vue";
import { useAiTemplatesStore } from "./AiTemplatesStore";
import { DocVerQaApiService } from "./api";
import NewMessage from "./NewMessage.vue";
import Question from "./Question.vue";
import {
  DEFAULT_TAB,
  type DocVerQA,
  DocVerQAStatuses,
  type TabIds,
} from "./types";

const FEATURE_SWITCH_ENABLE_AI_DEV_MODE =
  (!!loadFromLocalStorage("dr:enable_ai_dev_mode") || false) &&
  (APP_SETTINGS.DEBUG || USER_DATA.email.endsWith("@dealroom.net"));

interface Props {
  docId: number;
  docUid: string;
  docIndex: string;
  docName: string;
  docMimetype: string;
  hasHeader?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  hasHeader: false,
});

const api = new DocVerQaApiService();

const activeTab = ref<TabIds>(DEFAULT_TAB);

const openTab = (tab: string) => {
  activeTab.value = tab as unknown as TabIds;
};

const computedTabs = computed(() => {
  const tabs = [
    {
      id: "Reports",
      name: "Summaries",
      isActive: activeTab.value === "Reports",
    },
    {
      id: "Chat",
      name: "Chat",
      isActive: activeTab.value === "Chat",
    },
  ];

  if (FEATURE_SWITCH_ENABLE_AI_DEV_MODE) {
    tabs.push({
      id: "AI Dev",
      name: "AI Dev",
      isActive: activeTab.value === "AI Dev",
    });
  }

  return tabs;
});

const aiTemplatesStore = useAiTemplatesStore(pinia);
if (!aiTemplatesStore.list.length) {
  aiTemplatesStore.load();
}

const aiReportsStore = useAiReportsStore(pinia);
if (!aiReportsStore.list.length) {
  aiReportsStore.load();
}

const newMessageEl = ref<InstanceType<typeof NewMessage> | null>(null);
const aiTemplatesEl = ref<InstanceType<typeof AiTemplates> | null>(null);

const questions = ref<DocVerQA[]>([]);
const {
  error: questionsLoadError,
  isLoading: questionsIsLoading,
  execute: loadQuestions,
} = useAsyncState(
  () =>
    api.loadQA(props.docUid).then((respConv) => {
      respConv.reverse();
      questions.value = respConv;
    }),
  undefined,
  { immediate: false },
);

const handleAppliedCategory = () => {
  activeTab.value = "Chat";
  loadQuestions();
};

watch(
  () => props.docUid,
  () => {
    loadQuestions(0).then(() => {
      nextTick(() => {
        scrollToBottom();
      });
    });
  },
  { immediate: true },
);

const questionsList = ref<HTMLElement | null>(null);
const scrollToBottom = () => {
  if (questionsList.value) {
    questionsList.value.scrollTop = questionsList.value.scrollHeight;
  }
};

const onQaExpanded = ({ qa_id }: { qa_id: DocVerQA["id"] }) => {
  const qaIndex = questions.value.findIndex((qa) => qa.id === qa_id);
  if (qaIndex === questions.value.length - 1) {
    setTimeout(() => {
      scrollToBottom();
    }, 0);
  }
};
const onNewMessage = async ({ qa }: { qa: DocVerQA }) => {
  insightTrack(AiReportsEvent.QuestionAsked, {
    question_title: qa.question,
    document_name: props.docName,
  });

  questions.value.push(qa);
  setTimeout(() => {
    scrollToBottom();
  });
};

const deleteQuestion = async ({ qa_id }: { qa_id: DocVerQA["id"] }) => {
  await api.deleteQA(props.docUid, qa_id);
  const qaIndex = questions.value.findIndex((qa) => qa.id === qa_id);
  if (qaIndex !== -1) {
    questions.value.splice(qaIndex, 1);
  }
};

const onNewReview = ({
  qa_id,
  review,
}: {
  qa_id: DocVerQA["id"];
  review: DocVerQA["review"];
}) => {
  const qa = questions.value.find((q) => q.id === qa_id);
  if (qa) {
    qa.review = review;
  }
};

const showTemplates = () => {
  aiTemplatesEl.value?.show();
};

useIntervalFn(() => {
  const isAnyGenerating = questions.value.some(
    (qa) =>
      qa.status == DocVerQAStatuses.PENDING ||
      qa.status == DocVerQAStatuses.PROCESSING,
  );
  if (isAnyGenerating) {
    loadQuestions(0);
  }
}, 1000);

defineExpose({
  openTab,
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.analysis {
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-right: 1px solid colors.$pr-300;
  padding-right: 12px;
  margin-right: 12px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.header {
  height: 44px;
  flex-shrink: 0;
  display: flex;
  gap: spacing.$xs;
  align-items: center;
  padding: spacing.$s spacing.$m 0 spacing.$m;
  position: sticky;
  top: 0;
}

.headerDocTitle {
  font: typography.$title_semibold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabs {
  height: 40px;
  padding-left: 20px;
}

.questions {
  flex-grow: 1;
  overflow: auto;
  background: colors.$pr-50;
}

.category {
  display: flex;
  padding: spacing.$s spacing.$m;
  flex-direction: column;
  align-items: flex-start;
  gap: spacing.$xs;
  border-radius: spacing.$xs;
  border: 1px solid colors.$pr-150;
  background: white;
  margin: spacing.$xs spacing.$s;
  &:hover {
    border-color: colors.$sc-400;
  }
}

.categoryTitle {
  font: typography.$title_bold;
  margin-bottom: -4px;
}

.categoryQuestion {
  width: 100%;
}

.categorySeparator {
  margin: spacing.$xs 0;
  border-bottom: 1px solid colors.$pr-150;
  height: 1px;
  width: 100%;
}

.footer {
  border-top: 1px solid colors.$pr-150;
  padding: spacing.$s;
}

.banner {
  margin: spacing.$s;
}
</style>
