import analyticsCardLinechartHtml from "./index.html?raw";

Controller.$inject = [
  "ActivityGroupPermissionsVisibleService",
  "PermissionsService",
  "MembersService",
];
angular
  .module("dealroom.analytics.card.linechart.groups", [
    "dealroom.analytics.service.data",
    "dealroom.members",
  ])
  .component("drAnalyticsGroupsTimeSpentLinechartCard", {
    template: analyticsCardLinechartHtml,
    controller: Controller,
  });

function Controller(
  ActivityGroupPermissionsVisibleService,
  PermissionsService,
  MembersService,
) {
  const $ctrl = this;

  $ctrl.title = "Group Activity";
  $ctrl.metricsTypes = [
    {
      title: "Time Spent (Min)",
      test: isTimeSpendActivity,
      value: getTimeSpent,
      getY: getActivityUser,
      label: $ctrl.title, // used as key of metrics
    },
  ];

  $ctrl.filterBy = "users";
  $ctrl.getYList = getYList;

  function getYList() {
    return (ActivityGroupPermissionsVisibleService.visiblePgroupIds || []).map(
      (key) => ({ key, title: getTitle(key) }),
    );
  }

  function getTitle(pgroupId) {
    const pgroup = PermissionsService.pgroups[pgroupId];
    return pgroup.name;
  }

  function getActivityUser(d) {
    return MembersService.members[d.user].pgroup.id;
  }

  function isTimeSpendActivity(d) {
    return angular.isNumber(d.time_spent);
  }

  function getTimeSpent(d) {
    return d.time_spent;
  }
}
