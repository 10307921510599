import { endOfDay, parseISO, startOfDay } from "date-fns";

import { drUserTime } from "@drVue/filters/drUserTime";
import timeFilter_timeFilterHtml from "../../templates/components/time-filter/time-filter.html?raw";

(function () {
  "use strict";
  angular
    .module("dealroom.time-filter.directive", [
      "ui.bootstrap",
      "dealroom.date-fns",
      "dealroom.config",
      "dealroom.common",

      "dealroom.analytics.service.filter",
    ])
    .controller("TimeFilterController", TimeFilterController)
    .directive("timeFilter", function () {
      return {
        restrict: "E",
        replace: true,
        scope: true,
        bindToController: {
          startTimeFrom: "@",
          startTimeTo: "@",
          callback: "&",
        },
        template: timeFilter_timeFilterHtml,
        controller: "TimeFilterController",
        controllerAs: "ctrl",
      };
    });

  TimeFilterController.$inject = [
    "$scope",
    "$window",
    "$element",
    "RoomConfig",
    "ActivityFilterDatesService",
  ];
  function TimeFilterController(
    $scope,
    $window,
    $element,
    RoomConfig,
    ActivityFilterDatesService,
  ) {
    const ctrl = this;

    ctrl.onDateRangeChanged = (range) => {
      const timeFrom = drUserTime(startOfDay(range[0]), "yyyy-MM-dd");
      const timeTo = drUserTime(endOfDay(range[1]), "yyyy-MM-dd");

      if (ctrl.callback) {
        ctrl.callback({
          timeInterval: {
            from: timeFrom,
            to: timeTo,
          },
        });
      }

      ActivityFilterDatesService.setDatesRange(range[0], range[1]);
    };

    ctrl.initialDateRangeValue = [];

    ctrl.init = () => {
      ctrl.initialDateRangeValue = [
        parseISO(ctrl.startTimeFrom),
        parseISO(ctrl.startTimeTo),
      ];

      ctrl.callback({
        timeInterval: {
          from: ctrl.startTimeFrom,
          to: ctrl.startTimeTo,
        },
      });
    };

    $scope.$watchGroup(["ctrl.startTimeFrom", "ctrl.startTimeTo"], () => {
      if (ctrl.startTimeFrom && ctrl.startTimeTo) {
        ctrl.init();
      }
    });
  }
})();
