import type { ElDrawer } from "element-plus";
import type { ComponentInternalInstance } from "vue";

export function disableElBackdrop(elDrawerInstance: typeof ElDrawer) {
  const i = elDrawerInstance.$ as ComponentInternalInstance;

  if (
    i.refs.drawerRef instanceof HTMLElement &&
    i.refs.drawerRef.parentElement
  ) {
    const drawerEl = i.refs.drawerRef;
    const parentEl = i.refs.drawerRef.parentElement;

    drawerEl.style.pointerEvents = "auto";
    parentEl.style.pointerEvents = "none";
  }
}

export function resetElBackdrop(elDrawerInstance: typeof ElDrawer) {
  const i = elDrawerInstance.$ as ComponentInternalInstance;

  if (
    i.refs.drawerRef instanceof HTMLElement &&
    i.refs.drawerRef.parentElement
  ) {
    const drawerEl = i.refs.drawerRef;
    const parentEl = i.refs.drawerRef.parentElement;

    drawerEl.style.pointerEvents = "";
    parentEl.style.pointerEvents = "";
  }
}
