<template>
  <DrItemListItem
    :class="$style.wrapper"
    :allow-remove="!disallowRemove"
    @remove="$emit('remove', attachment)"
  >
    <AttachmentCard :item="attachment" has-index has-link>
      <div :class="$style.right">
        <div :class="$style.actions">
          <DownloadDocumentButton
            v-if="attachment.type === 'file'"
            :download-original="attachment.download_original"
            :document-id="attachment.id"
          >
            <template #default="{ openDialog }">
              <DrIcon
                name="download"
                :class="$style.actionsIcon"
                @click.stop.prevent="openDialog"
              />
            </template>
          </DownloadDocumentButton>
        </div>
        <div :class="$style.dateAdded">{{ formattedDateAdded }}</div>
      </div>
    </AttachmentCard>
  </DrItemListItem>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import DrIcon from "@shared/ui/dr-icon";
import { DrItemListItem } from "@shared/ui/dr-item-list";

import DownloadDocumentButton from "@drVue/components/room/viewer/DownloadDocumentButton.vue";
import { drUserTime } from "@drVue/filters/drUserTime";
import AttachmentCard from "./AttachmentCard.vue";

import type { Attachment } from "./types";

interface Props {
  attachment: Attachment;
  disallowRemove: boolean;
}

interface Emits {
  (e: "remove", attachment: Attachment): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const formattedDateAdded = computed(() =>
  drUserTime(props.attachment.date_added),
);
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";
@use "@app/styles/scss/spacing";

.wrapper:hover {
  .actions {
    display: flex;
  }

  .dateAdded {
    display: none;
  }
}

.right {
  white-space: nowrap;
}

.actions {
  display: none;
  color: colors.$pr-500;
}

.actionsIcon:hover {
  color: colors.$pr-900;
}

.dateAdded {
  font: typography.$caption_regular;
  color: colors.$pr-400;
}
</style>
