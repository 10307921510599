<template>
  <DrDynamicFormClassic
    ref="dealFormRef"
    :schema="dealSchema"
    :entity="deal"
    :errors="formErrors"
    @update="handleUpdate"
    @submit="handleSubmit"
  />
</template>

<script setup lang="ts">
import { computed, reactive, ref } from "vue";
import { DrDynamicFormClassic } from "@shared/ui/dr-dynamic-form";
import { mapCustomFieldToSchema } from "@shared/ui/dr-dynamic-form/utils";

import { DrStore } from "@app/vue";
import {
  markDisabledCustomFieldOptions,
  markDisabledPhases,
} from "@drVue/components/client-dashboard/deals/utils";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";

import type { FieldItem, PhaseItem } from "@drVue/api-service/client-dashboard";
import type {
  Deal,
  IDealCreateData,
} from "@drVue/store/modules/client-dashboard/deals/types";
import type { FormSchema } from "@shared/ui/dr-dynamic-form/types";
import type { FormErrors } from "@shared/ui/dr-form/useFormHelper";

const dealFormRef = ref<InstanceType<typeof DrDynamicFormClassic> | null>(null);

interface Props {
  deal: IDealCreateData;
  formErrors: FormErrors<Deal>;
}
const props = defineProps<Props>();

const emit = defineEmits(["submit", "update"]);

const customFields = reactive<FieldItem[]>(
  markDisabledCustomFieldOptions(
    DrStore.getters["clientDashboard/customFields/byObjectType"]("deal"),
  ),
);

const dealSchema = computed<FormSchema>(() => [
  {
    type: FieldSchemaType.Text,
    required: true,
    prop: "title",
    label: "Deal name",
    extra: {
      autofocus: true,
    },
  },
  {
    type: FieldSchemaType.Select,
    prop: "phase",
    label: "Phase",
    extra: {
      select_options: markDisabledPhases(
        DrStore.state.clientDashboard.phases.items,
      ).map(
        ({ id, name, isDisabled }: PhaseItem & { isDisabled: boolean }) => ({
          label: name,
          value: id,
          isDisabled,
        }),
      ),
    },
  },
  ...customFields.map(mapCustomFieldToSchema),
]);

function handleUpdate<T>({ field, value }: { field: string; value: T }) {
  emit("update", { field, value });
}

async function handleSubmit() {
  if (!dealFormRef.value) return;
  const isValid = await dealFormRef.value.validate();
  if (isValid) emit("submit");
}
</script>
