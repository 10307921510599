<template>
  <div>
    <ElPopover
      v-model="isMenuShown"
      placement="bottom"
      trigger="click"
      :width="350"
    >
      <p :class="$style.viewTitle">Save view</p>
      <p :class="$style.viewText">
        <span v-if="currentView.is_default">
          This is a standard view. Save as new view to keep your changes.
        </span>
        <span v-else>
          You can save filters, sort, and other options to this view to keep
          your changes.
        </span>
      </p>
      <div :class="$style.buttonRow">
        <ElButton :disabled="isSavingChanges" @click="resetCurrentView">
          Reset
        </ElButton>
        <ElButton
          plain
          type="primary"
          :disabled="isSavingChanges"
          @click="showNewDialog"
        >
          Save as new
        </ElButton>
        <ElButton
          :disabled="currentView.is_default"
          :loading="isSavingChanges"
          type="primary"
          @click="saveView"
        >
          Save
        </ElButton>
      </div>
      <template #reference>
        <ElButton>
          <span>Save view</span>
          <i class="fas fa-save" />
        </ElButton>
      </template>
    </ElPopover>

    <EditDealsViewDialog
      v-if="showNewViewDialog && newView"
      :view="newView"
      @close="closeNewDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { DashboardViewsEvent, insightTrack } from "@app/insight";
import { $notifyDanger } from "@drVue/common";
import { copyView } from "@drVue/store/modules/common/custom-views/utils";
import EditDealsViewDialog from "./EditDealsViewDialog.vue";

import type { CustomView } from "@setups/types";

interface Data {
  showNewViewDialog: boolean;
  isSavingChanges: boolean;
  newView: CustomView | null;
  isMenuShown: boolean;
}

export default defineComponent({
  name: "SaveView",
  components: {
    EditDealsViewDialog,
  },
  data(): Data {
    return {
      showNewViewDialog: false,
      isSavingChanges: false,
      newView: null,
      isMenuShown: false,
    };
  },
  computed: {
    currentView(): CustomView {
      return this.$store.state.common.customViews.current;
    },
  },
  methods: {
    async saveView() {
      if (this.isSavingChanges) {
        return;
      }
      try {
        this.isSavingChanges = true;
        await this.$store.dispatch(
          "common/customViews/update",
          this.currentView,
        );
        insightTrack(DashboardViewsEvent.Save);
        this.isMenuShown = false;
      } catch (err) {
        $notifyDanger("Failed to update view");
        throw err;
      } finally {
        this.isSavingChanges = false;
      }
    },
    async resetCurrentView() {
      await this.$store.dispatch("common/customViews/resetCurrentView");
      this.isMenuShown = false;
    },
    showNewDialog() {
      this.newView = copyView(this.currentView, true);
      this.showNewViewDialog = true;
      this.isMenuShown = false;
    },
    closeNewDialog() {
      this.showNewViewDialog = false;
      this.newView = null;
    },
  },
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/typography" as typo;
@use "@app/styles/scss/colors";

.viewTitle {
  font-weight: 600;
  font-size: typo.$font-size-title;
  color: colors.$pr-800;
}
.viewText {
  margin-bottom: 24px;
  word-break: normal;
  color: colors.$pr-600;
}
.buttonRow {
  text-align: right;
}
</style>
