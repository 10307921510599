<template>
  <ElSelect
    ref="selectRef"
    value-key="id"
    style="width: 100%"
    :size="size!"
    :model-value="currentStatus"
    :class="$style.selectContainer"
    @visible-change="handleVisibleChange"
    @change="handleSubmit"
  >
    <template #prefix>
      <DrIconStatus size="sm" :color="currentStatus.color" />
    </template>
    <ElOption
      v-for="status in statuses"
      :key="status.id"
      :label="status.name"
      :value="status"
      :class="$style.optionContainer"
    >
      <template #default>
        <DrIconStatus size="sm" :color="status.color" />
        <span :class="$style.optionLabel">{{ status.name }}</span>
      </template>
    </ElOption>
  </ElSelect>
</template>

<script setup lang="ts">
import { ElOption, ElSelect } from "element-plus"; // In timeline el-components are required to be explicitly imported
import { nextTick, onMounted, ref } from "vue";
import DrIconStatus from "@shared/ui/dr-icon-status";

import { $notifyDanger } from "@drVue/common";
import DrStore from "@drVue/store";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { TaskStatus } from "@drVue/store/modules/room/tasks-statuses/types";
import type { Task } from "@drVue/store/pinia/room/tasks";

interface Props {
  editProps: EditFieldProps<number, Task>;
  size?: "default" | "small" | "large";
}

const props = withDefaults(defineProps<Props>(), { size: "default" });

interface Emits {
  (e: "change"): void;
}

const emit = defineEmits<Emits>();

const statuses: TaskStatus[] = DrStore.state.room.tasksStatuses.list;
const currentStatus: TaskStatus = DrStore.getters["room/tasksStatuses/byId"](
  props.editProps.value,
);

const handleVisibleChange = (visible: boolean): void => {
  if (visible) return;
  props.editProps.quitEditMode();
};

const handleSubmit = ({ id }: TaskStatus): void => {
  try {
    props.editProps.veeField.onChange(id);
    props.editProps.submitField();

    emit("change");
  } catch {
    $notifyDanger("Something went wrong while modifying status");
  }
};

const selectRef = ref<InstanceType<typeof ElSelect> | null>(null);
onMounted(() => {
  selectRef.value?.toggleMenu();

  nextTick(() => {
    // Un-focusing inner input, so text overflow style apply
    selectRef.value?.$el.querySelector("input").blur();
  });
});
</script>

<style module lang="scss">
.selectContainer {
  width: 100%;

  :global(.el-input__wrapper) {
    padding: 1px 7px;
  }

  :global(.el-input__prefix-inner > :last-child) {
    margin-right: 6px;
  }

  :global(.el-input__inner) {
    text-overflow: ellipsis;
  }
}

.optionContainer {
  align-items: center;
  display: flex;
  gap: 8px;
}

.optionLabel {
  font-weight: normal; // Select options are bold by default
}
</style>
