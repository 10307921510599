import { reactive } from "vue";

import taskDetail_attachDocumentHtml from "../../../templates/components/task-detail/attach-document.html?raw";
import task_attachItemHtml from "../../../templates/task/attach-item.html?raw";
import task_taskImportHtml from "../../../templates/task/task-import.html?raw";
import task_taskSelectDocumentsHtml from "../../../templates/task/task-select-documents.html?raw";

TasksService.$inject = [
  "$uibModal",
  "$state",
  "$stateParams",
  "$location",
  "URLS",
  "Upload",
  "TrackActivity",
  "FileUploadService",
  "AlertService",
  "CategoriesService",
  "MembersService",
  "TasksAttachmentsService",
  "drLabelKey",
  "NG_APP",
];

import * as Sentry from "@sentry/browser";

import { insightTrack } from "@app/insight";
import { DrStore } from "@drVue";
import { pinia } from "@drVue/store/pinia";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import { useTasksArchivedStore } from "@drVue/store/pinia/room/tasksArchived/tasksArchived";
import AttachToRequestModalController from "../controllers/AttachToRequestModalController";
import updateTaskAttributes from "./helpers/updateTaskAttributes";

export default function TasksService(
  $uibModal,
  $state,
  $stateParams,
  $location,
  URLS,
  Upload,
  TrackActivity,
  FileUploadService,
  AlertService,
  CategoriesService,
  MembersService,
  TasksAttachmentsService,
  drLabelKey,
  NG_APP,
) {
  const tasksStore = useTasksStore(pinia);
  const tasksArchivedStore = useTasksArchivedStore(pinia);

  var service = {
    // Moved to Tasks Store, see below...
    // tasks: {},
    // tasksList: undefined,
    // tasksLoading: undefined,
    // tasksLoadError: undefined,
    // loadingPromise: undefined,
    // isLoaded: isLoaded,
    // archived tasks info
    // archivedTasks: {},
    // archivedTasksList: undefined,
    // archivedTasksLoading: false,
    // archivedTasksLoadError: false,

    isLoaded: () => {
      return tasksStore.isLoading === false && tasksStore.isError === false;
    },

    // get data
    loadTasks: (skipErrorAlert) => tasksStore.load(skipErrorAlert),
    loadArchivedTasks: (skipErrorAlert) =>
      tasksArchivedStore.load(skipErrorAlert),
    patchTaskV2: (taskId, changes) => tasksStore.patchTaskV2(taskId, changes),

    // bulk tasks actions
    importTasks: importTasks,

    // task detail actions
    openTaskDetails: openTaskDetails,
    updateTaskAttachments: updateTaskAttachments,
    attachItem: attachItem,
    onUploadToTaskFilesDrop: onUploadToTaskFilesDrop,
    showAddToTaskFilesModal: showAddToTaskFilesModal,
    showAttachFilesWithCallbackModal: showAttachFilesWithCallbackModal,
  };

  Object.defineProperties(service, {
    tasks: {
      get: () => tasksStore.tasks,
    },
    tasksByUid: {
      get: () => tasksStore.tasksByUid,
    },
    tasksList: {
      get: () => tasksStore.tasksList,
    },
    tasksLoading: {
      get: () => tasksStore.isLoading,
    },
    tasksLoadError: {
      get: () => tasksStore.isError,
    },
    loadingPromise: {
      get: () => tasksStore.loadingPromise,
    },
    archivedTasks: {
      get: () => tasksArchivedStore.tasks,
    },
    archivedTasksList: {
      get: () => tasksArchivedStore.tasksList,
    },
    archivedTasksLoading: {
      get: () => tasksArchivedStore.isLoading,
    },
    archivedTasksLoadError: {
      get: () => tasksArchivedStore.isError,
    },
  });

  return service;

  function openTaskDetails(taskKey) {
    const listTask = service.tasksList?.find((t) => t.key === taskKey);

    // We call `loadTaskDetails` every time to get the latest data. Internally,
    // it will update the `tasksList` within the store even if the task is
    // already loaded, and we `Promise.resolve(listTask)`.
    const loadTaskPromsie = tasksStore.loadTaskDetails(taskKey);

    const promises = [
      listTask ? Promise.resolve(listTask) : loadTaskPromsie,
      MembersService.isLoaded()
        ? Promise.resolve(MembersService.membersList)
        : DrStore.dispatch("room/members/load", true),
    ];

    Promise.all(promises).then(([task, _members]) => openModal(task));

    function openModal(task) {
      updateTaskAttributes(task);

      const modalInstance = $uibModal.open({
        controller: [
          "$scope",
          "task",
          ($scope, task) => {
            $scope.task = reactive(task);
            $scope.onClose = () => {
              // $scope.$close will resolve modalInstance.result promise in TasksService.js
              $scope.$close();
            };
          },
        ],
        template: `<vue-component id="room_TaskDetails" name="VueTaskDetails" v-props="{ task: task, onClose: onClose }"></vue-component>`,
        keyboard: false,
        backdrop: false,
        windowClass: "modal--hidden",
        animation: false,
        resolve: {
          task: () => task,
        },
      });

      const uiRouterEnabled = $location.url(); // there is no # part in fileviewer

      modalInstance.rendered.then(() => {
        if (NG_APP === "dealroom") {
          if (uiRouterEnabled) {
            $state.go(
              "tasks.details",
              { taskId: task.key },
              {
                notify: false,
                inherit: false,
              },
            );
          }

          TrackActivity.setObject({ type: "task", id: task.id });
        }
      });

      const lastState = $state.$current.name;
      const lastParams = angular.copy($stateParams);

      modalInstance.result.finally(() => {
        if (uiRouterEnabled) {
          $state.go(lastState, lastParams, {
            notify: false,
            inherit: false,
          });
        }

        TrackActivity.setObject(undefined);
      });
    }
  } /* End openTaskDetails */

  //////////////////////////////////////////////////////////////////////////////
  // Helpers

  function importTasks() {
    const uploadUrl = URLS["api:room:tasks_import"]();
    const templateUrl = URLS["api:room:tasks_import_example"]();

    return $uibModal.open({
      template: task_taskImportHtml,
      windowClass: "task-import-modal",
      controllerAs: "ctrl",
      controller: [
        "$uibModalInstance",
        function ($uibModalInstance) {
          var self = this;
          self.importForm = undefined;
          self.formData = {};
          self.isProgress = false;
          self.importResult = undefined;
          self.import_url = templateUrl;

          self.fileHasUnsupportedFormat = function () {
            if (!self.formData.file) return false;
            return !self.formData.file.name.toLowerCase().endsWith(".xlsx");
          };

          self.submit = function () {
            self.isProgress = true;
            self.importError = "";
            self.importResult = undefined;
            Upload.upload({ url: uploadUrl, data: self.formData })
              .then((resp) => {
                drLabelKey.syncAll();
                service.loadTasks();
                DrStore.dispatch("room/members/load");
                CategoriesService.loadCategories();
                if (resp.data.status === "success") {
                  AlertService.success("Requests were successfully imported");
                  $uibModalInstance.close();
                  return;
                } else if (resp.data.status === "partial") {
                  AlertService.warning("Some requests were not imported");
                } else if (resp.data.status === "failed") {
                  AlertService.danger("Requests were not imported");
                }
                self.importResult = resp.data;
              })
              .catch((resp) => {
                let knownError;
                if (resp.status === 400) {
                  if (resp.data.file) {
                    knownError = resp.data.file;
                  }
                } else if (resp.status === 413) {
                  knownError = "file is too large";
                }

                if (knownError) {
                  AlertService.danger(
                    `Requests were not imported: ${knownError}`,
                  );

                  return;
                }
                AlertService.danger(
                  "Unexpected error occurred. Requests were not imported",
                );

                Sentry.captureEvent({
                  message: "Requests import error",
                  extra: { response: resp },
                  level: "error",
                });
              })
              .finally(() => {
                self.isProgress = false;
              });
          };
        },
      ],
    }).result;
  } /* end importTasks */

  //////////////////////////////////////////////////////////////////////////////
  // Task detail edit

  function attachItem(folderId, documentId) {
    const modalInstance = $uibModal.open({
      template: task_attachItemHtml,
      resolve: {
        folderId: function () {
          return folderId;
        },
        documentId: function () {
          return documentId;
        },
      },
      controllerAs: "$ctrl",
      controller: [
        "$uibModalInstance",
        "TasksAttachmentsService",
        "folderId",
        "documentId",
        function (
          $uibModalInstance,
          TasksAttachmentsService,
          folderId,
          documentId,
        ) {
          const $ctrl = this;
          $ctrl.submitting = false;
          $ctrl.selectedTask = undefined;

          $ctrl.selectedItems = {
            folderIds: folderId ? [folderId] : [],
            documentIds: documentId ? [documentId] : [],
          };

          $ctrl.setSelectedItems = function (folderIds, documentIds) {
            $ctrl.selectedItems = {
              folderIds: folderIds,
              documentIds: documentIds,
            };
          };

          $ctrl.submit = function () {
            $ctrl.submitting = true;

            const folders = $ctrl.selectedItems.folderIds.map((id) => ({
              folder_id: id,
            }));
            const documents = $ctrl.selectedItems.documentIds.map((id) => ({
              document_id: id,
              page: null,
            }));

            TasksAttachmentsService.createAttachments(
              $ctrl.selectedTask,
              folders,
              documents,
            ).then(
              () => $uibModalInstance.dismiss(),
              () => ($ctrl.submitting = false),
            );
          };
        },
      ],
    });

    return modalInstance.result;
  }

  function updateTaskAttachments(params) {
    return $uibModal.open({
      template: task_taskSelectDocumentsHtml,
      controllerAs: "ctrl",
      controller: [
        "$uibModalInstance",
        "TasksAttachmentsService",
        function ($uibModalInstance, TasksAttachmentsService) {
          const ctrl = this;

          const task = (ctrl.task = params.sourceTask);

          ctrl.submit = function (folders, documents) {
            TasksAttachmentsService.updateAttachments(task, folders, documents);
            $uibModalInstance.dismiss();
          };
        },
      ],
    });
  } /* end updateTaskAttachments */

  function onUploadToTaskFilesDrop(task, files) {
    // ignore text paste to description
    if (!files || !files.length) return;
    showAddToTaskFilesModal(task, files);
  }

  function showAddToTaskFilesModal(task, files) {
    function callback(folders, documents, hasDestinationFolderChanged) {
      TasksAttachmentsService.createAttachments(task, folders, documents);

      insightTrack("room.documents.upload", {
        source: "task",
        task_id: task.id,
        files: documents.length,
        destination_changed: hasDestinationFolderChanged,
      });
    }

    const taskPath = CategoriesService.categories[task.category_id].full_path;
    service.showAttachFilesWithCallbackModal(taskPath, files, callback);
  }

  function showAttachFilesWithCallbackModal(itemPath, files, callback) {
    FileUploadService.dragLabel = undefined;

    $uibModal.open({
      backdrop: true,
      openedClass: "upload-window-opened",
      windowClass: "upload-window",
      template: taskDetail_attachDocumentHtml,
      resolve: {
        itemPath: () => itemPath,
        onUploadComplete: () => callback,
        files: () => files,
      },
      animation: false,
      controllerAs: "$ctrl",
      controller: AttachToRequestModalController,
    });
  }
}
