import { getModule } from "../module";
import PhasesApiService from "./PhasesApiService";
import { phasesGetters } from "./PhasesGetters";

import type { IModule } from "../module";

export type { State } from "./types";
export { nullPhaseData } from "./types";
export const PhasesModule: IModule = getModule(
  new PhasesApiService(),
  phasesGetters,
);
