import { UsersNotesModule } from "@drVue/store/modules/client-dashboard/notes";
import { orgUsersActions } from "@drVue/store/modules/client-dashboard/org-users/OrgUsersActions";
import { orgUsersGetters } from "@drVue/store/modules/client-dashboard/org-users/OrgUsersGetters";
import { orgUsersMutations } from "@drVue/store/modules/client-dashboard/org-users/OrgUsersMutations";
import { OrgUsersState } from "@drVue/store/modules/client-dashboard/org-users/OrgUsersState";

import type { RootState } from "@drVue/store/state";
import type { Module, ModuleTree } from "vuex";

const modules: ModuleTree<RootState> = {
  notes: UsersNotesModule,
};

export const OrgUsersModule: Module<OrgUsersState, RootState> = {
  namespaced: true,

  actions: orgUsersActions,
  getters: orgUsersGetters,
  modules,
  mutations: orgUsersMutations,

  state: new OrgUsersState(),
};
