<template>
  <div class="mfa-interface">
    <div
      class="mfa-interface__icon"
      :class="{
        'mfa-interface__icon--enabled': interface.is_enrolled,
        'mfa-interface__icon--disabled': !interface.is_enrolled,
      }"
    >
      <i class="fal fa-shield-check" />
    </div>

    <div>
      <div class="mfa-interface__header">
        {{ interface.name }}
        <ElTag :class="$style.recommended">Recommended</ElTag>
      </div>
      <p class="mfa-interface__description">{{ interface.description }}</p>

      <ElButton
        v-if="!interface.is_enrolled && !isAdding"
        type="primary"
        @click="enable"
      >
        Enable
      </ElButton>

      <div v-if="!interface.is_enrolled && isAdding">
        <div class="totp-challenge">
          <div class="totp-challenge__steps">
            <ol>
              <li>
                Install authentication app on your phone. Recommended:
                <a
                  href="https://www.microsoft.com/en/security/mobile-authenticator-app"
                  target="_blank"
                  >Microsoft Authenticator</a
                >,
                <a
                  href="https://support.google.com/accounts/answer/1066447"
                  target="_blank"
                  >Google Authenticator</a
                >.
              </li>
              <li>
                Use an application on your phone to scan the QR code on the
                right
              </li>
              <li>
                Get two-factor authentication code and then enter it to confirm
                the enrollment
              </li>
            </ol>

            <ElAlert
              v-if="getBackendError('non_field_errors')"
              class="auth-form__alert"
              :title="getBackendError('non_field_errors')"
              type="error"
              show-icon
              :closable="false"
            />

            <ElForm
              ref="confirmForm"
              :model="confirmFormModel"
              :rules="confirmRules"
              :disabled="isSubmitting"
              label-position="top"
            >
              <ElFormItem
                label="Authentication code"
                prop="challenge"
                :error="getBackendError('challenge')"
              >
                <ElInput
                  v-model="confirmFormModel.challenge"
                  autofocus
                  autocomplete="one-time-code"
                />
              </ElFormItem>

              <ElFormItem
                label="You Current Password"
                prop="password"
                :error="getBackendError('password')"
              >
                <ElInput
                  v-model="confirmFormModel.password"
                  type="password"
                  autocomplete="current-password"
                />
              </ElFormItem>
            </ElForm>
          </div>
          <div class="totp-challenge__code">
            <QrCode
              v-if="secretData && secretData.qrcode"
              :code="secretData.qrcode"
            />
          </div>
        </div>

        <div class="text-right">
          <ElButton
            type="default"
            :disabled="isSubmitting"
            @click="cancelEnabling"
            >Cancel</ElButton
          >
          <ElButton
            v-if="secretData"
            type="primary"
            :disabled="isSubmitting"
            @click="confirmEnabling"
            >Confirm</ElButton
          >
        </div>
      </div>

      <ElButton v-if="interface.is_enrolled && !isRemoving" @click="disable">
        Disable
      </ElButton>

      <div v-if="interface.is_enrolled && isRemoving">
        <ElForm
          ref="disableForm"
          :model="disableFormModel"
          :rules="disableRules"
          :disabled="isSubmitting"
          label-position="top"
        >
          <ElFormItem
            prop="password"
            label="Current password"
            :error="
              getBackendError('password') || getBackendError('non_field_errors')
            "
          >
            <ElInput
              v-model="disableFormModel.password"
              type="password"
              autocomplete="current-password"
            />
          </ElFormItem>
        </ElForm>
        <ElButton :disabled="isSubmitting" @click="confirmDisabling"
          >Disable</ElButton
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrForm from "@shared/ui/dr-form";

import { $notifySuccess } from "@drVue/common";
import {
  Interface,
  MfaApiService,
} from "@drVue/components/client-dashboard/users/AccountSettings/MfaApiService";
import QrCode from "@drVue/components/client-dashboard/users/AccountSettings/tabs/interfaces/QrCode.vue";

import type { ConfirmFormModel, DisableFormModel } from "./types";
import type {
  ConfirmRequest,
  MfaInterface,
  SecretData,
} from "@drVue/components/client-dashboard/users/AccountSettings/MfaApiService";
import type { ElForm } from "element-plus";
import type { PropType } from "vue";

interface Data {
  api: MfaApiService;
  confirmRules: any;
  disableRules: any;
  confirmFormModel: ConfirmFormModel;
  disableFormModel: DisableFormModel;
  secretData: SecretData;
  isAdding: boolean;
  isRemoving: boolean;
}

export default defineComponent({
  name: "TotpInterface",
  components: {
    QrCode,
  },
  extends: DrForm,
  props: {
    interface: { required: true, type: Object as PropType<MfaInterface> },
  },
  emits: ["confirmed", "removed"],
  data(): Data {
    return {
      api: new MfaApiService(),
      confirmRules: {
        challenge: [
          {
            required: true,
            message: "This field may not be blank.",
            trigger: ["blur"],
          },
        ],
        password: [
          {
            required: true,
            message: "This field may not be blank.",
            trigger: ["blur"],
          },
        ],
      },
      disableRules: {
        password: [
          {
            required: true,
            message: "This field may not be blank.",
            trigger: ["blur"],
          },
        ],
      },
      confirmFormModel: {
        challenge: "",
        password: "",
      },
      disableFormModel: {
        password: "",
      },
      secretData: {
        interface: Interface.totp,
        secret: "",
      },
      isAdding: false,
      isRemoving: false,
    };
  },
  methods: {
    enable() {
      this.isAdding = true;
      this.isRemoving = false;

      this.api
        .addInterface(Interface.totp, {})
        .then((r) => (this.secretData = r.data));
    },
    cancelEnabling() {
      this.setBackendErrors({});

      this.isAdding = false;
      this.isRemoving = false;

      this.confirmFormModel = {
        challenge: "",
        password: "",
      };

      this.secretData = {
        interface: Interface.totp,
        secret: "",
      };
    },
    getConfirmForm() {
      return this.$refs.confirmForm as typeof ElForm;
    },
    getDisableForm() {
      return this.$refs.disableForm as typeof ElForm;
    },
    confirmEnabling() {
      const request: ConfirmRequest = {
        challenge: this.confirmFormModel.challenge,
        password: this.confirmFormModel.password,
        secret: this.secretData.secret,
      };

      this.getConfirmForm()
        .validate()
        .then(() => {
          const confirmPromise = this.api.confirmInterface(
            Interface.totp,
            request,
          );

          this.submitPromise(confirmPromise).then(
            (r) => {
              $notifySuccess(
                "The Authentication Application interface was successfully enabled",
              );
              this.setBackendErrors({});
              this.$emit("confirmed", r.data.codes);
            },
            () => {},
          );
        });
    },
    disable() {
      this.isRemoving = true;
      this.isAdding = false;
    },
    confirmDisabling() {
      this.getDisableForm()
        .validate()
        .then(() => {
          const removePromise = this.api.removeInterface(
            Interface.totp,
            this.disableFormModel.password,
          );

          this.submitPromise(removePromise).then(() => {
            $notifySuccess(
              "The Authentication Application interface was successfully disabled",
            );
            this.setBackendErrors({});
            this.$emit("removed");
          });
        });
    },
  },
});
</script>

<style lang="scss" module>
.recommended {
  margin-left: 10px;
}
</style>
