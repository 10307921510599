export default getCells;

function getCells(roomHasTasks, type) {
  const CELLS = [
    // common
    {
      label: "Logins",
      class: "datagrid-table__column--data-logins",
      verbs: ["user_room_session_begin"],
      src: "total",
      type: "common",
    },
    {
      label: "Time (min)",
      class: "datagrid-table__column--data-timespent",
      verbs: ["user_room_session_begin", "task_viewed", "document_viewed"],
      src: "time",
      getDisplayedValue: Math.round,
      type: "common",
    },
    {
      label: "Requests Viewed",
      class: "",
      childs: [
        {
          label: "Unique",
          class: "datagrid-table__column--data-uniq",
          verbs: ["task_viewed"],
          src: "uniq",
        },
        {
          label: "Total",
          class: "datagrid-table__column--data-total",
          verbs: ["task_viewed"],
          src: "total",
        },
      ],
      type: "common",
      taskDepended: true,
    },
    // documents
    {
      label: "Documents Viewed",
      class: "",
      childs: [
        {
          label: "Unique",
          class: "datagrid-table__column--data-uniq",
          verbs: ["document_viewed"],
          src: "uniq",
        },
        {
          label: "Total",
          class: "datagrid-table__column--data-total",
          verbs: ["document_viewed"],
          src: "total",
        },
      ],
      type: "documents",
    },
    {
      label: "Documents Uploaded",
      class: "",
      childs: [
        {
          label: "Unique",
          class: "datagrid-table__column--data-uniq",
          verbs: ["document_upload", "document_overwrite"],
          src: "uniq",
        },
        {
          label: "Total",
          class: "datagrid-table__column--data-total",
          verbs: ["document_upload", "document_overwrite"],
          src: "total",
        },
      ],
      type: "documents",
    },
    {
      label: "Documents Downloaded",
      class: "",
      childs: [
        {
          label: "Unique",
          class: "datagrid-table__column--data-uniq",
          verbs: ["document_download", "document_bulk_download"],
          src: "uniq",
        },
        {
          label: "Total",
          class: "datagrid-table__column--data-total",
          verbs: ["document_download", "document_bulk_download"],
          src: "total",
        },
      ],
      type: "documents",
    },
  ];
  return CELLS.filter((c) => roomHasTasks || !c.taskDepended).map((c) => {
    c.visible = c.type === type;
    return c;
  });
}
