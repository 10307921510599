<template>
  <ElDialog
    v-model="isDialogVisible"
    :title="currentSynergyId ? 'Renamed synergy' : 'Create synergy'"
    width="30%"
    destroy-on-close
    :close-on-click-modal="!isFormSubmitting"
    :close-on-press-escape="!isFormSubmitting"
    :show-close="!isFormSubmitting"
    :before-close="close"
  >
    <template #default>
      <DrDynamicFormClassic
        ref="synergyFormRef"
        :schema="synergySchema"
        :entity="synergy"
        :errors="formErrors"
        @update="handleUpdate"
        @submit="handleSubmit"
      />
    </template>
    <template #footer>
      <span class="dialog-footer">
        <ElButton :disabled="isFormSubmitting" @click="close">Cancel</ElButton>
        <ElButton type="primary" :disabled="isFormSubmitting" @click="submit">
          {{ currentSynergyId ? "Rename" : "Create" }}
        </ElButton>
      </span>
    </template>
  </ElDialog>
</template>

<script lang="ts" setup>
import { set } from "lodash-es";
import { computed, reactive, ref } from "vue";
import { DrDynamicFormClassic } from "@shared/ui/dr-dynamic-form";
import { useFormHelper } from "@shared/ui/dr-form";

import { insightTrack, RoomSynergiesEvent } from "@app/insight";
import { $notifySuccess } from "@drVue/common";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import { useSynergiesStore } from "@drVue/store/modules/room/synergies/synergies";
import { pinia } from "@drVue/store/pinia";

import type { Finding } from "@drVue/api-service/modules/findings/types";
import type { Synergy } from "@drVue/store/modules/room/synergies/SynergiesApiService";
import type { FormSchema } from "@shared/ui/dr-dynamic-form/types";

const isDialogVisible = ref(false);
const synergyFormRef = ref<InstanceType<typeof DrDynamicFormClassic> | null>(
  null,
);

const synergiesStore = useSynergiesStore(pinia);

const currentSynergyId = ref<Synergy["id"]>();

const open = (id?: Synergy["id"]) => {
  if (id) {
    currentSynergyId.value = id;
    synergy.title = synergiesStore.dict[currentSynergyId.value].title;
  }

  isDialogVisible.value = true;
};

const close = () => {
  synergy.title = "";
  currentSynergyId.value = undefined;

  isDialogVisible.value = false;
};

const submit = () => {
  const promise: Promise<Synergy> | null = currentSynergyId.value
    ? synergiesStore.update(currentSynergyId.value, synergy)
    : synergiesStore.create(synergy);

  hookFormSubmitPromise(promise).then(() => {
    if (currentSynergyId.value) {
      $notifySuccess("Synergy updated");
      insightTrack(RoomSynergiesEvent.Renamed);
    } else {
      $notifySuccess("Synergy created");
      insightTrack(RoomSynergiesEvent.Created);
    }

    resetErrors();
    close();
  });
};

const synergy = reactive({
  title: "",
});

const {
  formErrors,
  hookFormSubmitPromise,
  isFormSubmitting,
  resetErrors,
  resetError,
} = useFormHelper<Finding>();

const handleUpdate = (payload: { field: string; value: any }) => {
  set(synergy, payload.field, payload.value);
};

const handleSubmit = async () => {
  if (!synergyFormRef.value) return;

  const isValid = await synergyFormRef.value.validate();
  if (!isValid) return;

  submit();
};

const synergySchema = computed<FormSchema>(() => [
  {
    type: FieldSchemaType.Text,
    required: true,
    prop: "title",
    label: "Title",
    extra: {
      autofocus: 100,
    },
  },
]);

defineExpose({
  open,
  close,
});
</script>

<style lang="scss" module></style>
