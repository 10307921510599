import "vite/modulepreload-polyfill";
import "./styles/main.scss";
// Globals
import "./global-jquery";
import "./global-define";
import "./shared";
// app vendor imports
import "angular-bootstrap-contextmenu";
import "angular-ui-tree";
import "angular-tablesort";
import "angular-ui-indeterminate";
import "angular-elastic";
// app imports
import "./components/dashboard-services.js";
import "./components/dr-table-sort/dr-table-sort.directive.js";
import "./components/tree-filter/tree-filter.directive.js";
import "./components/activity-filter/activity-filter.directive.js";
import "./components/audit-log/audit-log.directive.js";
import "./components/group-filter/group-filter.directive.js";
import "./components/time-filter/time-filter.directive.js";
import "./components/docs-multi-select/docs-multi-select.js";
import "./billing";
import "./room_nda";
import "./settings.js";
import "./file-upload.js";
import "./documents.js";
import "./members.js";
import "./activity.js";
import "./analytics";
import "./sentry.js";
import "./task.js";
import "./time-tracking.js";
import "./room-data.js";
import "./sw.js";
import "./management";
import "./file-viewer";
import "./vue/crisp/crisp";
import "./../vendor/ngVue/src/index";
import "./../vendor/ngVue/src/plugins/provider";

import { setupOfflineAlert } from "@shared/ui/dr-offline/DrOffline";

import { APP_SETTINGS, ROOM_DATA, ROOM_MEMBER_DATA } from "@setups/data";
import { TaskFieldAccessType } from "@setups/enums";
// vue support staff
import dashboard_auditLogHtml from "./templates/dashboard/audit_log.html?raw";
import dashboard_dashboardHtml from "./templates/dashboard/dashboard.html?raw";
import dashboard_indexActivityHtml from "./templates/dashboard/index_activity.html?raw";
import dashboard_overviewHtml from "./templates/dashboard/overview.html?raw";
import dashboard_tasksActivityHtml from "./templates/dashboard/tasks_activity.html?raw";
import dashboard_tasksProgressHtml from "./templates/dashboard/tasks_progress.html?raw";
import dashboard_topActiveHtml from "./templates/dashboard/top_active.html?raw";
import documents_archiveHtml from "./templates/documents/archive.html?raw";
import documents_documentsHtml from "./templates/documents/documents.html?raw";
import documents_folderHtml from "./templates/documents/folder.html?raw";
import documents_searchHtml from "./templates/documents/search.html?raw";
import findings_findingsOverviewPageHtml from "./templates/findings/findings-overview-page.html?raw";
import help_welcomeModalHtml from "./templates/help/welcome-modal.html?raw";
import members_membersArchivedHtml from "./templates/members/members.archived.html?raw";
import members_membersBulkTasksHtml from "./templates/members/members.bulk-tasks.html?raw";
import members_membersDetailHtml from "./templates/members/members.detail.html?raw";
import members_membersFilesHtml from "./templates/members/members.files.html?raw";
import members_membersHtml from "./templates/members/members.html?raw";
import members_membersListHtml from "./templates/members/members.list.html?raw";
import members_membersOthersHtml from "./templates/members/members.others.html?raw";
import members_membersSynergiesHtml from "./templates/members/members.synergies.html?raw";
import settings_baseHtml from "./templates/settings/base.html?raw";
import settings_roomBillingHtml from "./templates/settings/room.billing.html?raw";
import settings_roomNdaHtml from "./templates/settings/room.nda.html?raw";
import settings_roomProfileHtml from "./templates/settings/room.profile.html?raw";
import settings_roomSlackHtml from "./templates/settings/room.slack.html?raw";
import settings_roomTemplateHtml from "./templates/settings/room.template.html?raw";
import settings_roomWatermarkHtml from "./templates/settings/room.watermark.html?raw";
import task_baseHtml from "./templates/task/base.html?raw";

window.DR.isNewRequestDetailsOpened = false;

(function () {
  "use strict";
  initData.$inject = ["NG_APP", "TrackActivity", "RoomDataService"];
  setupStateChangeHandler.$inject = [
    "$rootScope",
    "$state",
    "$window",
    "APP_SETTINGS",
    "RoomConfig",
    "AppVersionService",
    "FileUploadService",
  ];
  showVideoOnFirstLogin.$inject = [
    "$uibModal",
    "UserData",
    "TrainingVideosService",
  ];
  routes.$inject = [
    "$stateProvider",
    "$urlRouterProvider",
    "RoomConfig",
    "UserData",
    "ORG_MEMBER_DATA",
    "NG_APP",
  ];

  angular
    .module("dealroom", [
      "ngVue",
      "ngVue.plugins",

      "dealroom.sentry",
      "ngMessages",
      "ui.bootstrap",
      "ui.router",
      "ui.select",
      "angular.filter",

      "dealroom.common",
      "dealroom.config",
      "dealroom.uploads",

      "dealroom.time-tracking",
      "dealroom.activity",
      "dealroom.analytics",
      "dealroom.documents",
      "dealroom.members",
      "dealroom.room",
      "dealroom.task",
      "dealroom.settings",
      "dealroom.room-data",
    ])
    .config(routes)
    .config([
      "vsRepeatConfigProvider",
      function run(vsRepeatConfigProvider) {
        vsRepeatConfigProvider.silenceSizeMismatchWarnings();
      },
    ])
    .run(showVideoOnFirstLogin)
    .run(setupStateChangeHandler)
    .run(initData);

  function routes(
    $stateProvider,
    $urlRouterProvider,
    RoomConfig,
    UserData,
    ORG_MEMBER_DATA,
    NG_APP,
  ) {
    if (NG_APP !== "dealroom") {
      return;
    }

    $urlRouterProvider.otherwise(function ($injector) {
      var $state = $injector.get("$state");
      if (["", "your-work", "notifications"].includes($state.current.name)) {
        // check current state empty, because some old non-angular ulrs may break routing
        $state.go(RoomConfig.defaultTab);
      }
    });

    $stateProvider
      .state("legacy", {
        abstract: true,
        url: "",
        template: "<ui-view></ui-view>",
        insightIgnore: true,
      })
      .state("analytics", {
        url: "/analytics",
        template: dashboard_dashboardHtml,
        controller: "DashboardController",
        controllerAs: "analyticsCtrl",
        permissions: RoomConfig.userPermissions.administrator,
        no_permissons: "documents",
        insightIgnore: true,
      })
      .state("analytics.overview", {
        url: "/overview",
        template: dashboard_overviewHtml,
        controller: "DashboardOverviewController",
        controllerAs: "ctrl",
        permissions: RoomConfig.userPermissions.viewTasks,
      })
      .state("analytics.users", {
        url: "/users?group_id&time_from&time_to",
        controller: "DashboardActiveController",
        params: {
          group_id: "",
          time_from: "",
          time_to: "",
        },
        template: dashboard_topActiveHtml,
        controllerAs: "ctrl",
      })
      .state("analytics.documents", {
        url: "/documents",
        controller: "DashboardIndexActivityController",
        template: dashboard_indexActivityHtml,
        controllerAs: "ctrl",
      })
      .state("analytics.tasksProgress", {
        url: "/requests-progress",
        controller: "DashboardTasksProgressController",
        template: dashboard_tasksProgressHtml,
        controllerAs: "ctrl",
        permissions: RoomConfig.userPermissions.viewTasks,
      })
      .state("analytics.tasksActivity", {
        url: "/requests-activity",
        controller: "DashboardTasksActivityController",
        template: dashboard_tasksActivityHtml,
        controllerAs: "ctrl",
        permissions: RoomConfig.userPermissions.viewTasks,
      })
      .state("analytics.audit", {
        url: "/audit",
        controller: "DashboardAuditController",
        template: dashboard_auditLogHtml,
        controllerAs: "ctrl",
      })
      .state("documents", {
        url: "/documents",
        template: documents_documentsHtml,
        controller: "RoomDocumentsController",
        controllerAs: "docsCtrl",
        insightIgnore: true,
      })
      .state("documents.folder", {
        url: "/folder/{folderId:int}?query",
        template: documents_folderHtml,
        controller: "RoomDocumentsSearchListController",
        controllerAs: "ctrl",
      })
      .state("documents.archive", {
        url: "/archive",
        params: {
          archive: true,
        },
        template: documents_archiveHtml,
        controller: "RoomDocumentsArchiveListController",
        controllerAs: "ctrl",
      })
      .state("documents.recent", {
        url: "/recent?query",
        params: {
          recent: true,
        },
        template: documents_searchHtml,
        controller: "RoomDocumentsSearchListController",
        controllerAs: "ctrl",
      })
      .state("documents.reports", {
        url: "/summaries",
        params: {
          ai_reports: true,
        },
        template:
          "<vue-component id='app_VueAiReportsPageContent' name='VueAiReportsPageContent'></vue-component>",
        controller: "RoomDocumentsAiReportsController",
        controllerAs: "ctrl",
        permissions: APP_SETTINGS.WEBSITE.IS_DEALROOM,
        no_permissons: "documents.recent",
      })
      .state("documents.bookmarks", {
        url: "/bookmarks?query",
        params: {
          bookmarks: true,
        },
        template: documents_searchHtml,
        controller: "RoomDocumentsSearchListController",
        controllerAs: "ctrl",
      })
      .state("findings", {
        url: "/findings",
        template: findings_findingsOverviewPageHtml,
        permissions: RoomConfig.userPermissions.isFindingsAccessible,
        no_permissons: "documents",
        insightIgnore: true,
        controllerAs: "ctrl",
        controller: [
          "$state",
          function ($state) {
            const ctrl = this;
            ctrl.$state = $state;
          },
        ],
      })
      .state("findings.list", {
        url: "/list/{listKey:string}",
        params: {
          listKey: { value: null, squash: true },
        },
      })
      .state("findings.details", {
        url: "/details/{findingKey:string}",
        params: {
          findingKey: { value: null, squash: true },
        },
      })
      .state("findings.archive", {
        url: "/archive/{findingKey:string}",
        params: {
          findingKey: { value: null, squash: true },
        },
      })
      .state("tasks", {
        url: "/tasks",
        controller: "RoomTaskController",
        template: task_baseHtml,
        controllerAs: "tasksCtrl",
        permissions: RoomConfig.userPermissions.viewTasks,
        no_permissons: "documents",
        insightIgnore: true,
      })
      .state("tasks.list", {
        url: "/list/{listId:int}",
        params: {
          listId: { value: null, squash: true },
          needMyReview: { value: false, squash: true },
          assignedToMe: { value: false, squash: true },
        },
        resolve: {
          previousState: [
            "$state",
            function ($state) {
              return $state.current.name;
            },
          ],

          resolvedWhenTasksAndCategoriesLoaded: [
            "$q",
            "TasksService",
            "CategoriesService",
            "RoomConfig",
            function ($q, TasksService, CategoriesService, RoomConfig) {
              if (RoomConfig.enableListsTabs) {
                if (!TasksService.isLoaded() && !CategoriesService.isLoaded()) {
                  return $q.all([
                    TasksService.loadTasks(false),
                    CategoriesService.loadCategories(false),
                  ]);
                }
              }

              return $q.resolve();
            },
          ],
        },
        template: "<ui-view></ui-view>",
        controller: "TaskListController",
        controllerAs: "ctrl",
        insightIgnore: true,
      })
      .state("tasks.details", {
        url: "/{taskId:string}",
        controller: "TaskDetailsPageController",
        resolve: {
          resolvedWhenTasksAndCategoriesLoaded: [
            "$q",
            "TasksService",
            "CategoriesService",
            "RoomConfig",
            function ($q, TasksService, CategoriesService, RoomConfig) {
              if (RoomConfig.enableListsTabs) {
                if (!TasksService.isLoaded() && !CategoriesService.isLoaded()) {
                  return $q.all([
                    TasksService.loadTasks(false),
                    CategoriesService.loadCategories(false),
                  ]);
                }
              }

              return $q.resolve();
            },
          ],
        },
      })
      .state("tasks.list.overview", {
        url: "/overview",
        template: "<div></div>",
      })
      .state("tasks.list.timeline", {
        url: "/timeline",
        template:
          '<vue-component id="task_TasksTimelineView" name="VueTasksTimelineContent"></vue-component>',
        permissions:
          (ROOM_MEMBER_DATA.group.task_start_and_due_dates_access ||
            TaskFieldAccessType.NoAccess) !== TaskFieldAccessType.NoAccess,
        no_permissons: "tasks.list.overview",
      })
      .state("tasks.archived", {
        url: "/archived",
        controller: "TaskArchivedListController",
        template: "<ui-view></ui-view>",
        controllerAs: "ctrl",
        permissions: RoomConfig.userPermissions.administrator,
        no_permissons: "tasks.list.overview",
      })
      .state("tasks.archived.overview", {
        url: "/overview",
        template: "<div></div>",
      })
      .state("members", {
        url: "/members?{pgroupId:int}",
        params: {
          // hacky way to keep scroll position, check MembersController
          scrollPosition: { value: null, squash: true },
        },
        template: members_membersHtml,
        controller: "MembersController",
        controllerAs: "membersCtrl",
        insightIgnore: true,
      })
      .state("members.list", {
        url: "/list",
        template: members_membersListHtml,
        controller: "MembersListController",
        controllerAs: "ctrl",
      })
      .state("members.detail", {
        url: "/{userId:int}",
        template: members_membersDetailHtml,
        controller: "MembersDetailController",
        controllerAs: "ctrl",
      })
      .state("members.files", {
        url: "/files?query",
        template: members_membersFilesHtml,
        controller: "MembersFilesController",
        controllerAs: "ctrl",
        permissions: RoomConfig.userPermissions.administrator,
      })
      .state("members.tasks", {
        url: "/tasks",
        templateProvider: [
          "$stateParams",
          function ($stateParams) {
            return $stateParams.pgroupId
              ? "<vue-component name='VueRequestPermissionsPage' v-props='{ pgroupId: ctrl.pgroupId }'></vue-component>"
              : members_membersBulkTasksHtml;
          },
        ],
        controllerProvider: [
          "$stateParams",
          function ($stateParams) {
            return $stateParams.pgroupId
              ? "MembersPermissionsSimpleController"
              : "MemberBulkRequestsController";
          },
        ],
        controllerAs: "ctrl",
        permissions:
          RoomConfig.userPermissions.administrator &&
          RoomConfig.userPermissions.viewTasks,
      })
      // Synergies Settings Page
      .state("members.synergies", {
        url: "/synergies",
        template: members_membersSynergiesHtml,
        controller: "MembersActionsController",
        controllerAs: "ctrl",
        permissions:
          APP_SETTINGS.WEBSITE.IS_DEALROOM &&
          (ROOM_DATA.synergySettings?.enable ?? false) &&
          RoomConfig.userPermissions.administrator,
      })
      .state("members.others", {
        url: "/others",
        template: members_membersOthersHtml,
        controller: "MembersActionsController",
        controllerAs: "ctrl",
        permissions: RoomConfig.userPermissions.administrator,
      })
      .state("members.archived", {
        url: "/archived",
        template: members_membersArchivedHtml,
        controller: "MembersArchivedController",
        controllerAs: "ctrl",
        permissions: RoomConfig.userPermissions.administrator,
      })
      // Synergies Page
      .state("synergies", {
        url: "/synergies",
        template: '<vue-component name="VueSynergiesPage"></vue-component>',
        permissions:
          APP_SETTINGS.WEBSITE.IS_DEALROOM &&
          (ROOM_DATA.synergySettings?.enable ?? false) &&
          ROOM_MEMBER_DATA.group.synergies_access !==
            TaskFieldAccessType.NoAccess,
        title: "Synergies",
      })
      .state("settings", {
        url: "/settings",
        controller: "SettingsController",
        template: settings_baseHtml,
        insightIgnore: true,
      })
      .state("settings.room", {
        url: "/room",
        controller: "RoomSettingsController",
        template: settings_roomProfileHtml,
        permissions: RoomConfig.userPermissions.administrator,
        no_permissons: "documents",
        title: "General",
      })
      .state("settings.billing", {
        url: "/billing",
        controller: "RoomSettingsController",
        template: settings_roomBillingHtml,
        permissions:
          !!RoomConfig.show_billing_in_room &&
          !!ORG_MEMBER_DATA?.has_billing_access,
        no_permissons: "settings.room",
        title: "Billing",
      })
      .state("settings.watermark", {
        url: "/watermark",
        controller: "RoomSettingsController",
        template: settings_roomWatermarkHtml,
        permissions: RoomConfig.userPermissions.administrator,
        no_permissons: "settings.user",
        title: "Watermark",
      })
      .state("settings.nda", {
        url: "/nda",
        controller: "RoomSettingsController",
        template: settings_roomNdaHtml,
        permissions: RoomConfig.userPermissions.administrator,
        no_permissons: "settings.user",
        title: "Non-disclosure agreement",
      })
      .state("settings.synergies", {
        url: "/synergies",
        template:
          '<vue-component name="VueSynergiesSettingsPage"></vue-component>',
        permissions: APP_SETTINGS.WEBSITE.IS_DEALROOM,
        title: "Synergies",
      })
      .state("settings.slack", {
        url: "/slack",
        controller: "SlackSettingsController",
        template: settings_roomSlackHtml,
        title: "Slack integration",
        controllerAs: "ctrl",
        permissions:
          RoomConfig.userPermissions.administrator &&
          RoomConfig.userPermissions.integrationsEnabled,
        no_permissons: "settings.user",
      })
      .state("settings.template", {
        url: "/template",
        controller: "RoomTemplateController",
        template: settings_roomTemplateHtml,
        title: "Room template",
        controllerAs: "ctrl",
        no_permissons: "settings.user",
      })
      .state("notifications", {
        url: "/notifications?state",
        template: '<vue-component name="VueNotificationsPage"></vue-component>',
      })
      .state("notification", {
        url: "/notification",
        permissions: false,
        no_permissons: "notifications",
      })
      .state("org-settings", {
        url: "/org-settings",
        template: '<vue-component name="VueBaseOrgSettings"></vue-component>',
        permissions: ORG_MEMBER_DATA?.group?.is_administrator,
        no_permissons: "documents",
      })
      .state("your-work", {
        url: "/your-work",
        template:
          '<vue-component style="height:100vh" name="VueYourWorkRoom"></vue-component>',
      });
  }

  function showVideoOnFirstLogin($uibModal, UserData, TrainingVideosService) {
    if (!UserData.logged_in_first_time) {
      return;
    }

    if (!TrainingVideosService.hasViewableVideos()) return;

    $uibModal.open({
      template: help_welcomeModalHtml,
      windowClass: "welcome-modal modal--wide",
      controllerAs: "ctrl",
      controller: [
        "TrainingVideosService",
        function (TrainingVideosService) {
          const ctrl = this;
          ctrl.welcomeVideo = TrainingVideosService.getIntroVideoModel();
        },
      ],
    });
  }

  function setupStateChangeHandler(
    $rootScope,
    $state,
    $window,
    APP_SETTINGS,
    RoomConfig,
    AppVersionService,
    FileUploadService,
  ) {
    //TODO: remove me, quick dirty fix
    $rootScope.showRoomLogo = RoomConfig.logo && RoomConfig.isAlpha;

    // validate that user allow to be in this tab
    // for example if tab is hidden, but user open the direct url
    var tabStates = {
      // default params
      tasks: { state: "tasks.list.overview", params: { listId: null } },
      documents: {
        state: "documents.folder",
        params: { folderId: RoomConfig.rootFolderId },
      },
    };

    $rootScope.$on(
      "$stateChangeSuccess",
      function (event, toState, toParams, fromState, fromParams) {
        var needReload =
          AppVersionService.isVersionChanged() &&
          !APP_SETTINGS.DEBUG &&
          !FileUploadService.uploadingFiles;
        var toTab = toState.name.split(".")[0],
          fromTab = fromState.name.split(".")[0];
        if (toTab !== fromTab && needReload) {
          $window.location.reload();
        }
      },
    );

    $rootScope.$on(
      "$stateChangeStart",
      function (event, toState, toParams, fromState, fromParams) {
        var fromStates = fromState.name.split(".");
        var toStates = toState.name.split(".");
        // check parents permissions one by one
        var currentPath = "";
        toStates.forEach(function (stateName) {
          currentPath = currentPath ? currentPath + "." + stateName : stateName;
          var state = $state.get(currentPath);
          if (angular.isDefined(state.permissions) && !state.permissions) {
            event.preventDefault();
            $state.go(state.no_permissons || RoomConfig.defaultTab);
          }
        });

        var toTab = toStates[0];
        var fromTab = fromStates[0];
        // redirect only to nested states if exists
        var savedTabState =
          tabStates[toTab] && tabStates[toTab].state !== toState.name
            ? tabStates[toTab]
            : undefined;
        if (savedTabState) {
          // do not open saved state if some new params defined or selected
          Object.keys(savedTabState.params).forEach(function (key) {
            if (
              savedTabState &&
              toParams[key] !== undefined &&
              toParams[key] !== savedTabState.params[key]
            ) {
              savedTabState = undefined;
            }
          });
        }
        // user navigate to new tab, not specific location
        if (toTab !== fromTab && toStates.length === 1 && savedTabState) {
          event.preventDefault();
          $state.go(savedTabState.state, savedTabState.params);
          return;
        }
        tabStates[toTab] = {
          state: toState.name,
          params: angular.copy(toParams),
        };
      },
    );
  }

  function initData(NG_APP, TrackActivity, RoomDataService) {
    if (NG_APP === "dealroom") {
      RoomDataService.setupInitialRoomData();
      RoomDataService.updateData();
      TrackActivity.startTracking();
      setupOfflineAlert();
    }
  }
})();
