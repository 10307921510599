<template>
  <DrUserBox :users="[user]" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DrUserBox } from "@shared/ui/dr-user-box";

import { DrStore } from "@app/vue";

interface Props {
  userUid: string;
}

const props = defineProps<Props>();

const user = computed(
  () => DrStore.state.room.members.membersByUid[props.userUid],
);
</script>
