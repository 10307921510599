import { ApiService } from "../index";

export interface BaseOrder {
  field_key: string;
  direction: string;
}

export interface DealOrder extends BaseOrder {
  uuid: string;
}

export interface ClientSettings {
  require_2fa: boolean;
  bg_color: string;
  public_name: string;
}

export interface RoomStorageUsage {
  room_id: number;
  room_name: string | null;
  room_is_archived: boolean | null;
  active_storage: number;
  archived_storage: number;
  total_storage: number;
}

export class ClientSettingsApiService extends ApiService {
  public update(data: ClientSettings) {
    const detailsUrl = this.Urls["api:client-dashboard:settings"]();

    return this.put<ClientSettings>(detailsUrl, data);
  }

  public getStorageUsage() {
    const usageUrl = this.Urls["api:client-dashboard:settings-storage-usage"]();

    return this.get<RoomStorageUsage[]>(usageUrl);
  }
}
