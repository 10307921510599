import { CLIENT_DASHBOARD_DATA, ORG_MEMBER_DATA } from "@setups/data";

import type { RootState } from "@drVue/store/state";

export const enum PIPELINE_SETTINGS_ACCESS_TYPES {
  PipelineOnly = "pipeline-only",
  NoPipeline = "no-pipeline",
  ExecutionSuit = "execution-suit",
}

export function getPipelineSettingsAccess(
  storeState: RootState,
): PIPELINE_SETTINGS_ACCESS_TYPES | null {
  if (!ORG_MEMBER_DATA?.group.is_administrator) {
    return null; // no need to show any settings
  }

  if (
    ORG_MEMBER_DATA?.client.enable_dashboard &&
    CLIENT_DASHBOARD_DATA?.payment_type === "pipeline_only" &&
    storeState.clientDashboard.deals.items.every(
      (deal) => deal.room && deal.room.payment_type === "pipeline_only",
    )
  ) {
    return PIPELINE_SETTINGS_ACCESS_TYPES.PipelineOnly; // hide all room related settings
  } else if (!ORG_MEMBER_DATA?.client.enable_dashboard) {
    return PIPELINE_SETTINGS_ACCESS_TYPES.NoPipeline; // hide all pipeline related settings
  }

  return PIPELINE_SETTINGS_ACCESS_TYPES.ExecutionSuit; // show all settings (both pipeline and room)
}
