<template>
  <div>
    <span
      class="phase-info__circle"
      :style="{ 'background-color': row.color }"
    />{{ row.name }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { PropType } from "vue";

export default defineComponent({
  name: "NameCell",
  props: {
    row: { required: true, type: Object as PropType<any> },
  },
});
</script>
