<template>
  <div>
    <ElButton type="primary" @click="open">New trial client </ElButton>
    <ElDialog v-model="isModalVisible" title="Client new client">
      <ElForm
        ref="form"
        v-loading="isFormSubmitting"
        label-position="top"
        :model="formData"
        :rules="formRules"
      >
        <ElAlert
          v-if="formErrors.non_field_errors"
          :title="formErrors.non_field_errors"
          type="error"
          show-icon
          :closable="false"
        />
        <ElFormItem
          label="New client administrator email"
          prop="email"
          :error="formErrors.email"
          :required="true"
        >
          <ElInput v-model="formData.email" placeholder="Admin email" />
        </ElFormItem>
        <ElFormItem
          v-if="!ORGS_TO_COPY_FROM.length"
          label="Room title"
          prop="room_title"
          :error="formErrors.room_title"
        >
          <ElInput v-model="formData.room_title" />
        </ElFormItem>
        <ElFormItem
          v-if="ORGS_TO_COPY_FROM.length"
          label="Copy pipeline data from"
          prop="copy_data_from_org"
          :error="formErrors.copy_data_from_org"
        >
          <!-- @vue-ignore -->
          <ElSelect
            v-model="formData.copy_data_from_org"
            :clearable="true"
            placeholder="Select organization"
          >
            <ElOption
              v-for="item in ORGS_TO_COPY_FROM"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            />
          </ElSelect>
        </ElFormItem>
        <ElFormItem class="el-form-item--pull-right">
          <ElButton @click="close">Cancel</ElButton>
          <ElButton type="primary" @click="submit">Submit</ElButton>
        </ElFormItem>
      </ElForm>
    </ElDialog>
  </div>
</template>

<script lang="ts" setup>
import { ElForm } from "element-plus";
import { cloneDeep } from "lodash-es";
import { reactive, ref } from "vue";
import { useFormHelper } from "@shared/ui/dr-form";

import { APP_SETTINGS } from "@setups/data";
import { ManagementApiService } from "@drVue/api-service/management";

import type { Dictionary } from "../../types";
import type { NewTrialClient } from "@drVue/api-service/management";
import type { FormRules } from "element-plus";

const ORG_TO_COPY_FROM_PER_ENV: Dictionary<{ label: string; id: number }[]> = {
  // TODO: load this data from backend
  "dealroom.net": [
    { label: "Clean", id: 3428 },
    { label: "Light Trial", id: 3427 },
  ],
  "staging.dealroom.net": [
    { label: "Clean", id: 207 },
    { label: "Light Trial", id: 205 },
  ],
  "dealroom.localhost": [{ label: "ROOT", id: 1 }],
};

const ORGS_TO_COPY_FROM =
  ORG_TO_COPY_FROM_PER_ENV[APP_SETTINGS.WEBSITE.HOST] || [];

const form = ref<InstanceType<typeof ElForm> | null>(null);
const formData = reactive<NewTrialClient>({
  email: "",
  room_title: "",
  copy_data_from_org: ORGS_TO_COPY_FROM[0]?.id || null,
});

const { formErrors, hookFormSubmitPromise, isFormSubmitting } =
  useFormHelper<NewTrialClient>();

const formRules: FormRules = {
  email: [
    {
      type: "email",
      message: "Enter a valid email",
      required: true,
    },
  ],
};

const isModalVisible = ref(false);

const open = () => {
  isModalVisible.value = true;
};

const close = () => {
  form.value?.resetFields();
  isModalVisible.value = false;
};

const submit = () => {
  form.value?.validate((valid: boolean) => {
    if (!valid) {
      return Promise.reject();
    }

    const api = new ManagementApiService();
    const data = cloneDeep(formData);
    if (!data.copy_data_from_org) {
      // ElSelect sets empty string instead of null on clear
      data.copy_data_from_org = null;
    }
    hookFormSubmitPromise(
      api.createTrialClient(data),
      "Failed to create client",
    ).then((resp) => {
      const newTabUrl =
        resp.client_url || resp.room_url || `#/clients/${resp.client_id}`;
      window.open(newTabUrl, "_blank");
      close();
    });
  });
};

defineExpose({
  open,
  close,
});
</script>
