<template>
  <div :class="$style.subdomain">
    <ElInput
      v-if="!ORG_MEMBER_DATA.client.enable_domain"
      v-model="subdomain"
      placeholder="Subdomain"
    >
      <template #append> .{{ APP_SETTINGS.WEBSITE.HOST }} </template>
    </ElInput>
    <ElAlert
      v-if="!!formErrors['subdomain']"
      type="error"
      :title="formErrors['subdomain']"
    />
  </div>
  <Nav :active-tab="activeTab" @tab-click="handleTabClick" />
  <Transition :name="$style.fadeIn">
    <div v-if="activeTab === RoomFormTabs.BLANK" />
  </Transition>
  <Transition :name="$style.fadeIn">
    <div v-if="activeTab === RoomFormTabs.PLAYBOOK">
      <PlaybooksModern @select="handleSelectPlaybook" />
    </div>
  </Transition>
  <Transition :name="$style.fadeIn">
    <div v-if="activeTab === RoomFormTabs.COPY">
      <CopyRoom
        :room-data="roomData"
        :get-error="getError"
        :reset-error="resetErrorFn"
      />
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

import { APP_SETTINGS, ORG_MEMBER_DATA } from "@app/setups";
import PlaybooksModern from "@drVue/components/user-dashboard/playbooks-modern/PlaybooksModern.vue";
import * as CopyOptions from "@drVue/components/user-dashboard/room-create/copy_room/index";
import CopyRoom from "@drVue/components/user-dashboard/room-create/copy_room/index.vue";
import Nav from "./RoomFormNav.vue";
import { RoomFormTabs } from "./types";

import type { RoomFormTab } from "./types";
import type { RoomCreateData } from "@drVue/api-service/common/rooms";
import type { Room } from "@drVue/store/modules/client-dashboard/deals/types";
import type { FormErrors } from "@shared/ui/dr-form/useFormHelper";

const emit = defineEmits(["update"]);

interface Props {
  roomData: RoomCreateData;
  formErrors: FormErrors<Room>;
  resetErrorFn: () => void;
}

const props = defineProps<Props>();

const activeTab = ref<RoomFormTab>(RoomFormTabs.BLANK);

const subdomain = ref("");

watch(
  () => props.roomData.subdomain,
  (value) => {
    if (value) subdomain.value = value;
  },
  { immediate: true },
);

watch(
  () => subdomain.value,
  (value) => {
    emit("update", { ...props.roomData, subdomain: value });
  },
);

function handleTabClick(tab: RoomFormTab) {
  switch (tab) {
    case RoomFormTabs.COPY:
      emit("update", {
        ...props.roomData,
        template: undefined,
        copy_options: CopyOptions.DEFAULT_COPY_OPTIONS,
      });
      break;
    case RoomFormTabs.BLANK:
    case RoomFormTabs.PLAYBOOK:
      emit("update", {
        ...props.roomData,
        template: undefined,
        // `copy_options` must be undefined, otherwise backend will complain there's no source_room
        copy_options: undefined!,
      });
      break;
  }
  activeTab.value = tab;
}

function handleSelectPlaybook(playbookId: string) {
  emit("update", { ...props.roomData, template: playbookId });
}

function getError(field: keyof Room) {
  return props.formErrors[field];
}
</script>

<style module lang="scss">
.subdomain {
  display: grid;
  gap: 20px;
  margin-bottom: 20px;
}

.fadeIn {
  &:global(-enter-active) {
    transition: 0.3s;
  }
  &:global(-enter-from),
  &:global(-leave-to) {
    opacity: 0;
  }
}
</style>
