<template>
  <div>
    <p>
      Please confirm with the admin of the account of the user requesting MFA
      recovery codes that it is ok to create codes for this user before
      proceeding
    </p>
    <ElButton
      type="primary"
      :loading="isSubmitting"
      :disabled="!!confirmationToken"
      @click="confirm"
    >
      Confirm recovery codes request
    </ElButton>

    <br />
    <br />

    <ElAlert
      v-if="getBackendError('non_field_errors')"
      :title="getBackendError('non_field_errors')"
      type="error"
      show-icon
    />

    <div v-if="!!confirmationToken">
      <p>Token to load recovery codes:</p>

      <p>
        <code>{{ confirmationToken }}</code>
      </p>

      <div>
        <ElButton type="primary" size="small" @click="copyToken()">
          Copy to Clipboard
        </ElButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrForm from "@shared/ui/dr-form";

import { ManagementApiService } from "@drVue/api-service/management";
import { $notifyDanger, $notifySuccess, copyToClipboard } from "@drVue/common";

import type { MFALoadRecoveryCodes } from "@drVue/api-service/management";
import type { SubmitPromiseType } from "@shared/ui/dr-form";
import type { PropType } from "vue";

interface Data {
  api: ManagementApiService;
  confirmationToken: string | null;
}

export default defineComponent({
  name: "MFARecoveryRequestConfirmation",
  extends: DrForm,
  props: {
    userId: { required: true, type: Number as PropType<number> },
  },
  data(): Data {
    return {
      api: new ManagementApiService(),
      confirmationToken: null,
    };
  },
  methods: {
    copyToken() {
      if (copyToClipboard(this.confirmationToken!)) {
        $notifySuccess("The token has been copied.");
      } else {
        $notifyDanger("Failed to copy token to clipboard.");
      }
    },
    confirm() {
      const promise = this.api.confirmMFARecoveryCodesRequest(this.userId);

      this.setBackendErrors({});
      return (this.submitPromise as SubmitPromiseType)(
        promise,
        "Failed to confirm request",
      ).then((resp: MFALoadRecoveryCodes) => {
        this.confirmationToken = resp.token;
      });
    },
  },
});
</script>
