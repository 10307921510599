<template>
  <div class="deal-room-link" :data-test-deal-title="deal.title">
    <div v-if="isVisible" class="deal-room-link__title-row">
      <span class="deal-room-link__title truncate">
        {{ deal.title }}
      </span>

      <div>
        <Transition name="el-fade-in" mode="out-in" :duration="150">
          <ElImage
            v-if="showLogo && deal.room?.logo && !showRoomLinkButton"
            class="deal-room-link__logo"
            fit="scale-down"
            :src="deal.room?.logo"
          />

          <RoomLinkBtn
            v-else-if="showRoomLink"
            :room="deal.room"
            @no-access="showSelectRoomGroupModal"
          />
        </Transition>
      </div>
    </div>

    <SelectRoomGroupModal
      v-if="isSelectRoomGroupModalOpen"
      ref="selectRoomGroupModalRef"
      :deal="deal"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, ref } from "vue";

import RoomLinkBtn from "@drVue/components/client-dashboard/deals/Common/RoomLinkBtn.vue";
import SelectRoomGroupModal from "./SelectRoomGroupModal.vue";

import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";

interface Props {
  deal: Deal;
  showRoomLinkButton: boolean;
  showLogo?: boolean;
  isVisible: boolean;
}

const isSelectRoomGroupModalOpen = ref(false);

const props = withDefaults(defineProps<Props>(), {
  showLogo: true,
});

const showRoomLink = computed(() => {
  return props.showRoomLinkButton && props.deal.room;
});

const selectRoomGroupModalRef = ref<InstanceType<
  typeof SelectRoomGroupModal
> | null>(null);

const showSelectRoomGroupModal = () => {
  isSelectRoomGroupModalOpen.value = true;
  // let vue render the component first
  nextTick(() => {
    selectRoomGroupModalRef.value?.open();
  });
};
</script>

<style scoped lang="scss">
@use "@app/styles/scss/colors" as colors;
@use "@app/styles/scss/typography" as typo;

.deal-room-link {
  color: colors.$med-gray;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  height: 42px;

  &__logo {
    max-width: 40px;
    height: 28px;
    margin-left: 16px;
    :deep(img) {
      border-radius: 2px;
    }
  }

  &__title-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    color: colors.$black;
    font-size: typo.$font-size-title;
    font-weight: typo.$font-weight-medium;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 28px);
  }
}
</style>
