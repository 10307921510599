import { orderBy } from "lodash-es";

import type { GroupsState } from "./GroupsState";
import type { Group } from "@drVue/store/modules/room/groups/GroupsApiService";
import type { Dictionary } from "@drVue/types";
import type { MutationTree } from "vuex";

export interface SetStatePayload {
  pgroups: Dictionary<Group>;
  pgroupsList: Group[];
  archivedPgroupsList: Group[];
}

export const groupsMutations: MutationTree<GroupsState> = {
  setPromise(state: GroupsState, promise: Promise<Group[]>) {
    state.updatePromise = promise;
  },

  setState(state: GroupsState, payload: SetStatePayload) {
    state.pgroups = payload.pgroups;
    state.pgroupsList = orderBy(
      payload.pgroupsList,
      ["is_administrator", "name"],
      ["desc", "asc"],
    );
    state.archivedPgroupsList = payload.archivedPgroupsList;
  },

  setIsLoading(state: GroupsState, isLoading: boolean) {
    state.isLoading = isLoading;
  },

  setIsError(state: GroupsState, isError: boolean) {
    state.isError = isError;
  },
};
