<template>
  <ElScrollbar ref="scrollbar" class="dash-rooms-board__content-scroll">
    <div
      v-loading="isLoading"
      element-loading-spinner="page-loader"
      class="dash-rooms-board__wrapper"
      data-testid="deals-board-phase-column"
    >
      <DealsBoardPhaseColumn
        v-for="(phase, index) in phasesList"
        :key="phase.id"
        :position="index + 1"
        :phase="phase"
        :deals="phaseDeals(phase.id)"
        @deal-moved-phase="dealPhaseMoved"
        data-testid="deals-board-phase-column-counter"
      />
    </div>

    <MoveDealToPhaseDialog ref="MoveDealToPhaseDialog" />
  </ElScrollbar>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  DealsBoardSortHelper,
  DEFAULT_BOARDS_ORDER,
} from "@drVue/components/client-dashboard/deals/DealsBoard/utils";
import MoveDealToPhaseDialog from "@drVue/components/client-dashboard/deals/MoveDealToPhaseDialog";
import { getOrCreatePhaseInfo } from "@drVue/components/client-dashboard/deals/MoveDealToPhaseDialog/utils";
import { markDisabledPhases } from "@drVue/components/client-dashboard/deals/utils";
import DrStore from "@drVue/store";
import { nullPhaseData } from "@drVue/store/modules/client-dashboard/phases/types";
import DealsBoardPhaseColumn from "./DealsBoardPhaseColumn.vue";

import type { PhaseItem } from "@drVue/api-service/client-dashboard";
import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";
import type { Dictionary } from "@drVue/types";
import type { TableLayoutSort } from "@setups/types";

type PhaseId = number | null;

interface Data {
  store: any;
  sortHelper: any;
}

export default defineComponent({
  name: "DealsBoard",
  components: { DealsBoardPhaseColumn, MoveDealToPhaseDialog },
  data(): Data {
    return {
      store: DrStore,
      sortHelper: new DealsBoardSortHelper(DrStore),
    };
  },
  computed: {
    layoutSort(): TableLayoutSort {
      return (
        this.$store.state.common.customViews.current.settings.tableLayoutSort ||
        DEFAULT_BOARDS_ORDER
      );
    },
    dealsByPhases(): any {
      const allDeals = this.store.state.clientDashboard.deals.items;
      let dealsList: Deal[] =
        this.store.getters["clientDashboard/filters/filterDeals"](allDeals);
      dealsList = this.sortHelper.sortDeals(dealsList, this.layoutSort);
      const deals: Dictionary<Deal[]> = {};
      for (const deal of dealsList) {
        const phase = String(deal.phase);
        deals[phase] = deals[phase] || [];
        deals[phase].push(deal);
      }
      return deals;
    },
    phasesList(): any {
      const dealsWithNullPhaseExists =
        this.store.getters["clientDashboard/deals/getDealsByPhase"](null)
          .length > 0;
      const phases: PhaseItem[] = markDisabledPhases(
        this.$store.state.clientDashboard.phases.items,
      ).filter((phase) => !phase.isDisabled);
      const allPhases: PhaseItem[] = dealsWithNullPhaseExists
        ? [...phases, nullPhaseData as unknown as PhaseItem]
        : phases;
      return allPhases;
    },
    isLoading(): any {
      // eslint-disable-next-line vue/no-async-in-computed-properties
      this.$nextTick(() => {
        // manually trigger update, since scrollbar does not handle initial load
        const scrollbar: any = this.$refs.scrollbar;
        if (scrollbar) {
          scrollbar.update();
        }
      });
      return this.store.state.clientDashboard.deals.isLoading;
    },
  },
  methods: {
    phaseDeals(phaseId: PhaseId): Deal[] {
      return this.dealsByPhases[String(phaseId)] || [];
    },
    dealPhaseMoved({
      dealId,
      phaseId,
    }: {
      dealId: number;
      phaseId: number;
    }): any {
      const deal: Deal =
        this.$store.getters["clientDashboard/deals/getDealById"](dealId);
      if (deal.phase === phaseId) {
        // ignore moving deal to current phase
        return;
      }

      const selectedPhaseInfo = getOrCreatePhaseInfo(deal, phaseId);
      (
        this.$refs.MoveDealToPhaseDialog as InstanceType<
          typeof MoveDealToPhaseDialog
        >
      ).open(selectedPhaseInfo);
    },
  },
});
</script>
