<template>
  <ElDrawer
    v-model="isOpen"
    :append-to-body="true"
    :with-header="false"
    destroy-on-close
    class="el-drawer--narrow"
    size=""
    @close="isOpen = false"
  >
    <ContentPanelClose @close="isOpen = false" />

    <div class="dash-content-panel__container">
      <div class="dash-content-panel-atop">
        <h4 class="dash-content-panel-atop__title">Add contact</h4>
      </div>
      <section class="dash-content-panel__main">
        <div class="dash-content-panel-nav">
          <SimpleTabNav
            :tabs="tabs"
            :active-tab="activeTab"
            class="dash-content-panel-nav__grow"
            @tab-click="onTabClick"
          />
        </div>

        <AddContactForm
          v-if="activeTab === Tabs.New"
          @cancel="close"
          @contact-added="onContactsListUpdated"
        />

        <ExistingContactsTable
          v-if="activeTab === Tabs.Existing"
          @contacts-list-updated="onContactsListUpdated"
        />
      </section>
    </div>
  </ElDrawer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SimpleTabNav } from "@shared/ui";
import ContentPanelClose from "@shared/ui/content-panel/close.vue";

import AddContactForm from "@drVue/components/client-dashboard/deals/Contacts/AddContactForm.vue";
import ExistingContactsTable from "@drVue/components/client-dashboard/deals/Contacts/ExistingContactsTable/ExistingContactsTable.vue";

enum Tabs {
  New = "New contact",
  Existing = "Existing contacts",
}

interface Data {
  isOpen: boolean;
  activeTab: Tabs;
}

export default defineComponent({
  name: "AddContactPanel",
  components: {
    ExistingContactsTable,
    AddContactForm,
    ContentPanelClose,
    SimpleTabNav,
  },
  data(): Data {
    return {
      isOpen: false,
      activeTab: Tabs.New,
    };
  },
  computed: {
    Tabs(): any {
      return Tabs;
    },
    tabs(): Tabs[] {
      return [Tabs.New, Tabs.Existing];
    },
  },
  methods: {
    onTabClick(tab: Tabs) {
      this.activeTab = tab;
    },
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    onContactsListUpdated() {
      this.close();
    },
  },
});
</script>
