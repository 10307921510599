<template>
  <div
    v-if="!isEditing"
    :class="$style.wrapper"
    :data-test-field-label="schema.label"
  >
    <slot
      name="view"
      :view-props="{
        entity,
        schema,
        entityValue,
        enterEditMode,
      }"
    >
      <Component
        :is="viewComponentsGetter(schema)"
        :view-props="{
          entity,
          schema,
          entityValue,
          enterEditMode,
        }"
      />
    </slot>
  </div>
  <ElForm
    v-else
    label-position="top"
    class="dr-form--inline"
    :disabled="isFormSubmitting"
    @click.stop
  >
    <Form ref="formRef" :initial-values="entity" keep-values @submit="onSubmit">
      <button ref="submitButtonRef" type="submit" style="display: none" />
      <Field
        ref="veeFieldRef"
        v-slot="{ errorMessage, field: veeField, value }"
        :name="schema.prop"
        :rules="schema.rules"
      >
        <ElFormItem
          :label="showLabel ? schema.label : ''"
          :error="get(formErrors, schema.prop) || errorMessage"
          :class="formItemClass"
        >
          <!-- @vue-ignore: veeField -->
          <slot
            name="edit"
            :edit-props="{
              schema,
              value,
              entity,
              extra: schema.extra,
              isFormSubmitting,
              veeField: {
                ...veeField,
                onInput: (...args) => {
                  veeField.onInput(...args);
                  updateCurrentValue(...args);
                },
                onChange: (...args) => {
                  veeField.onChange(...args);
                  updateCurrentValue(...args);
                },
              },
              submitField,
              quitEditMode,
              quitEditModeConfirm,
              setHasValueRemained,
              forceUpdateValue,
            }"
          >
            <!-- @vue-ignore: veeField -->
            <Component
              :is="editComponentsGetter(schema)"
              :edit-props="{
                schema,
                value,
                entity,
                extra: schema.extra,
                isFormSubmitting,
                veeField: {
                  ...veeField,
                  onInput: (...args) => {
                    veeField.onInput(...args);
                    updateCurrentValue(...args);
                  },
                  onChange: (...args) => {
                    veeField.onChange(...args);
                    updateCurrentValue(...args);
                  },
                },
                submitField,
                quitEditMode,
                quitEditModeConfirm,
                setHasValueRemained,
                forceUpdateValue,
              }"
            />
          </slot>
          <ElAlert v-if="formErrors['non_field_errors']">
            {{ formErrors["non_field_errors"] }}
          </ElAlert>
        </ElFormItem>
      </Field>
    </Form>
  </ElForm>
</template>

<script setup lang="ts">
import { ElAlert, ElForm, ElFormItem, ElMessageBox } from "element-plus";
import { get } from "lodash-es";
import { Field, Form } from "vee-validate";
import { computed, onBeforeUnmount, ref, useCssModule } from "vue";
import { useFormHelper } from "@shared/ui/dr-form/useFormHelper";

import { $notifyDanger } from "@drVue/common";
import {
  editFieldComponents,
  viewFieldComponents,
} from "@drVue/components/client-dashboard/dynamic-form/constants";
import {
  type FieldSchema,
  FieldSchemaType,
} from "@drVue/components/client-dashboard/dynamic-form/types";

import type { Dictionary } from "@drVue/types";
import type { ComponentInstance, Ref } from "vue";

const modes = {
  form: "form",
  table: "table",
} as const;

type MODES = keyof typeof modes;

interface Props {
  schema: FieldSchema;
  entity: any;
  submitFn: (value: any) => Promise<unknown>;
  showLabel?: boolean;
  mode?: MODES;
  viewComponentsGetter?: (schema: FieldSchema) => any;
  editComponentsGetter?: (schema: FieldSchema) => any;
}

const props = withDefaults(defineProps<Props>(), {
  showLabel: true,
  mode: "form",
  viewComponentsGetter: (schema: FieldSchema) => {
    if (schema.type === FieldSchemaType.Custom) {
      return schema.viewComponent;
    }

    return viewFieldComponents[schema.type];
  },
  editComponentsGetter: (schema: FieldSchema) => {
    if (schema.type === FieldSchemaType.Custom) {
      return schema.editComponent;
    }

    return editFieldComponents[schema.type];
  },
});

const emit = defineEmits<{
  (e: "request-edit", onGranted: () => void): void;
  (e: "toggle-edit", prop: string | null): void;
}>();

const veeFieldRef: Ref<typeof Field | null> = ref(null);
const formRef = ref<ComponentInstance<typeof Form> | undefined>();
const $styles = useCssModule();
const formItemClass = computed(() => {
  if (props.mode === "form") return $styles.formItem_inForm;
  if (props.mode === "table") return $styles.formItem_inTable;

  throw new Error(`Unknown 'mode': ${props.mode}`);
});

const entityValue = computed(() => get(props.entity, props.schema.prop));

const currentValue = ref(null);
const updateCurrentValue = (value: any) => {
  return (currentValue.value = value);
};

const checkFieldIsValid = async (): Promise<boolean> => {
  if (veeFieldRef.value) {
    const result = await veeFieldRef.value.validate();
    return result.valid;
  }
  return true;
};

const bypassClickFor = [
  ".dr-form--inline",
  ".dr-popper--inline-control",
  ".el-overlay.is-message-box",
  ".el-popper",
  "[data-tippy-root]",
].join(", ");
const onGlobalMouseDown = (e: MouseEvent) => {
  const target = e.target as Element;

  // We use submitButtonRef as a workaround for submitting the form. This
  // handler will be executed when we .click() on it. We must ignore that
  // click as there has nothing happened to be able to submit.
  if (Object.is(target, submitButtonRef.value)) return;

  // We have <ElForm /> in <DynamicField />. If the click was happened within
  // the form we do not capture it to bypass it to the form component.
  //
  // <ElSelect /> and <ElDatepicker /> create sub-controls such as dropdown and
  // dates table via .el-popper. If the click was happened within an .el-popper
  // element we do not capture it to bypass it to a sub-control.
  //
  // We also ignore the clicks within .el-overlay.is-message-box as there is
  // 'Are you sure?' (and maybe other) message box that we show when user wants
  // to discard an edited field.
  const mustBypassTheClick = !!target.closest(bypassClickFor);
  if (mustBypassTheClick) return;

  window.addEventListener(
    "click",
    (e) => {
      // If several listeners are attached to the same element for the same
      // event type, they are called in the order in which they were added. If
      // stopImmediatePropagation() is invoked during one such call, no
      // remaining listeners will be called.
      e.stopImmediatePropagation();

      // Even if we stopped propagation of the click event in the capture phase
      // there may be 'native' target listeners (e.g. <a href="" />) that has
      // default behaviour.
      e.preventDefault();

      quitEditModeConfirm();
    },
    { capture: true, once: true },
  );
};

const onGlobalKeydown = (e: KeyboardEvent) => {
  if (!isQuitting && e.key === "Escape") {
    e.preventDefault();
    e.stopImmediatePropagation();

    quitEditModeConfirm();
  }
};

const isEditing: Ref<boolean> = ref(false);
const enterEditMode = () => {
  if (props.schema.isReadOnly) return;

  window.addEventListener("mousedown", onGlobalMouseDown, { capture: true });
  window.addEventListener("keydown", onGlobalKeydown, { capture: true });

  emit("request-edit", () => {
    isEditing.value = true;

    emit("toggle-edit", props.schema.prop);
  });
};

const quitEditMode = () => {
  resetErrors();

  currentValue.value = null;
  isEditing.value = false;

  window.removeEventListener("mousedown", onGlobalMouseDown, { capture: true });
  window.removeEventListener("keydown", onGlobalKeydown, { capture: true });
  emit("toggle-edit", null);
};

let hasValueRemained: () => boolean = () =>
  currentValue.value === entityValue.value;
const setHasValueRemained = (getter: () => boolean) => {
  hasValueRemained = getter;
};

let isQuitting = false;
const quitEditModeConfirm = (
  message: string = "Are you sure want to discard your changes?",
) => {
  isQuitting = true;

  // eslint-disable-next-line no-async-promise-executor
  return new Promise<void>(async (resolve, reject) => {
    // 1 User entered the editing mode
    // 2 User exited the editing mode via:
    //   > Clicking on 'Discard' or 'Cancel'
    //   > Clicking outside the control
    // 3 User has not interacted with the field
    //
    // Then 'isPristine' is true!
    const isPristine = currentValue.value === null;

    const isValid = await checkFieldIsValid();
    const _hasValueRemained = isPristine || hasValueRemained();

    if (_hasValueRemained) {
      quitEditMode();
      isQuitting = false;
      resolve();
    } else {
      ElMessageBox.confirm(message, "Unsaved changes", {
        closeOnClickModal: false,
        distinguishCancelAndClose: true,
        showConfirmButton: isValid,
        confirmButtonText: "Save",
        cancelButtonText: "Discard",
        callback: (value: string) => {
          switch (value) {
            case "confirm": {
              submitField();
              quitEditMode();
              resolve();
              break;
            }
            case "cancel": {
              quitEditMode();
              resolve();
              break;
            }
            case "close": {
              reject(null);
            }
          }

          isQuitting = false;
        },
      });
    }
  });
};

const submitButtonRef: Ref<HTMLButtonElement | null> = ref(null);
const submitField = () => {
  resetErrors();
  submitButtonRef.value?.click();
};

const submitValidatedField = () => {
  resetErrors();

  if (!formRef.value || !veeFieldRef.value) return;

  if (veeFieldRef.value.errors.length) return Promise.reject();

  onSubmit(formRef.value.values);
};

const { formErrors, resetErrors, isFormSubmitting, hookFormSubmitPromise } =
  useFormHelper();

const onSubmit = (values: any) => {
  const update: Dictionary<any> = {};
  const value = get(values, props.schema.prop);

  if (props.schema.prop.includes("custom_data")) {
    const parts = props.schema.prop.split(".");
    const prop = parts[1];

    update.custom_data = {
      [prop]: value,
    };
  } else {
    update[props.schema.prop] = value;
  }

  if (props.schema.optimistic) {
    resetErrors();
    quitEditMode();
  }

  hookFormSubmitPromise(props.submitFn(update)).then(
    () => {
      if (!props.schema.optimistic) {
        resetErrors();
        quitEditMode();
      }
    },
    () => {
      $notifyDanger("Saving failed");
    },
  );
};

const forceUpdateValue = (value?: any) => {
  const newValue = value ?? get(props.entity, props.schema.prop);

  currentValue.value = newValue;
  formRef.value?.setValues({
    [props.schema.prop]: newValue,
  });
};

onBeforeUnmount(() => {
  window.removeEventListener("mousedown", onGlobalMouseDown, { capture: true });
  window.removeEventListener("keydown", onGlobalKeydown, { capture: true });
});

defineExpose({
  prop: props.schema.prop,
  enterEditMode,
  quitEditMode,
  quitEditModeConfirm,
  submitValidatedField,
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

.wrapper {
  display: flex;
  align-items: center;
}

.actions {
  display: flex !important;
  justify-content: flex-end;
  width: 100%;
  padding: 0 7px 7px 0;
}

.formItem_inForm {
  width: 100%;
  margin-bottom: 12px !important;

  :global {
    .el-form-item__error {
      position: relative;
    }

    .el-form-item__label {
      font-size: 13px;
      line-height: 20px !important;
      margin-bottom: 0 !important;
      color: colors.$pr-600;
    }

    .el-form-item__error {
      padding-left: 6px;
    }
  }
}

.formItem_inTable {
  width: 100%;
  margin-bottom: 0 !important;

  :global {
    .el-form-item__error {
      position: relative;
    }

    .el-form-item__label {
      font-size: 13px;
      line-height: 20px !important;
      margin-bottom: 0 !important;
      color: colors.$pr-600;
    }
  }
}
</style>
