<template>
  <div>
    <ElPopover
      :teleported="false"
      :width="330"
      placement="auto"
      trigger="click"
    >
      <template #reference>
        <div class="task-comment-viewers__toggle truncate" :title="viewersText">
          <i :class="isPublic ? 'fal fa-lock-open' : 'fas fa-lock'" />
          {{ viewersText }}
          <i class="el-icon-arrow-down el-icon--right" />
        </div>
      </template>
      <div class="task-comment-viewers__menu">
        Select who can view this comment:
        <hr style="margin: 10px 0" />
        <div>
          <div
            class="el-switch el-switch--default dr-switch dr-switch--default"
            :class="{ 'is-checked': isPublic }"
            @click="togglePublic"
          >
            <span class="el-switch__core"
              ><span class="el-switch__action"
            /></span>
            <span
              class="el-switch__label el-switch__label--right"
              :class="{ 'is-active': isPublic }"
              style=""
            >
              Open: all groups with access
            </span>
          </div>
        </div>
        <p>
          <ElSwitch
            :model-value="isMyGroupOnly"
            active-text="My group only"
            @change="toggleMyGroup"
          />
        </p>
        <div v-if="allowedViewers.length">
          <small>Send private comment to other groups:</small>
        </div>
        <div v-for="group in allowedViewers" :key="group.id">
          <ElCheckbox
            class="task-comment-viewers__group-checkbox"
            :model-value="isGroupSelected(group.id)"
            @change="toggleGroup(group)"
          >
            {{ group.name }}
          </ElCheckbox>
        </div>
      </div>
    </ElPopover>
  </div>
</template>

<script lang="ts">
import { pull } from "lodash-es";
import { defineComponent } from "vue";

import { ROOM_DATA } from "@setups/data";
import { isCategoryViewable } from "@drVue/components/room/tasks/utils";
import { MY_GROUP_ONLY_LABEL } from "./CommentViewersTag.vue";

import type { Group } from "@drVue/store/modules/room/groups/GroupsApiService";
import type { Dictionary } from "@drVue/types";
import type { PropType } from "vue";

interface Data {
  userGroupId: number;
}

export default defineComponent({
  name: "CommentViewersTag",
  props: {
    isPublic: { required: true, type: Boolean as PropType<boolean> },
    viewers: { required: true, type: Array as PropType<number[]> },
    taskCategoryId: { required: true, type: Number as PropType<number> },
    groups: {
      required: true,
      type: Object as PropType<Dictionary<Group>>,
    },
  },
  emits: ["change"],
  data(): Data {
    return {
      userGroupId: ROOM_DATA.userPermissions.id,
    };
  },
  computed: {
    isMyGroupOnly(): any {
      return (
        !this.isPublic &&
        this.viewers.length === 1 &&
        this.viewers[0] === this.userGroupId
      );
    },
    allowedViewers(): any {
      return Object.values<Group>(this.groups).filter((pg) => {
        return (
          pg.id !== ROOM_DATA.userPermissions.id &&
          !pg.is_archived &&
          pg.symmetrical_view &&
          isCategoryViewable(pg, this.taskCategoryId) &&
          (!ROOM_DATA.userPermissions.needCommentsApprove ||
            pg.is_administrator)
        );
      });
    },
    viewersText(): any {
      if (this.isPublic) {
        return "Open";
      }
      if (this.isMyGroupOnly) {
        return MY_GROUP_ONLY_LABEL;
      }

      const viewers = this.viewers
        .filter((groupId) => {
          return groupId !== this.userGroupId;
        })
        .map((groupId) => this.groups[groupId].name);
      viewers.sort();
      return "to " + viewers.join(", ");
    },
  },
  watch: {
    allowedViewers: {
      immediate: true,
      handler() {
        this.checkViewers();
      },
    },
  },
  mounted() {
    this.checkViewers();
  },
  methods: {
    checkViewers() {
      const allowedViewersIds = this.allowedViewers.map((pg: Group) => pg.id);
      const allViewers = this.viewers.filter(
        (viewerId) =>
          allowedViewersIds.includes(viewerId) || viewerId === this.userGroupId,
      );
      this.updateViewers(this.isPublic, allViewers);
    },
    toggleMyGroup() {
      if (this.isMyGroupOnly) {
        this.togglePublic();
      } else {
        return this.updateViewers(false, [this.userGroupId]);
      }
    },
    togglePublic() {
      if (this.isPublic) {
        this.toggleMyGroup();
      } else {
        this.updateViewers(true, [this.userGroupId]);
      }
    },
    toggleGroup(group: Group) {
      const viewers = [...this.viewers];
      if (this.isGroupSelected(group.id)) {
        pull(viewers, group.id);
      } else {
        viewers.push(group.id);
      }
      this.updateViewers(false, viewers);
    },
    updateViewers(isPublic: boolean, viewers: number[]) {
      // user group is always must be in viewers
      if (!viewers.includes(this.userGroupId)) {
        viewers.push(this.userGroupId);
      }
      this.$emit("change", { isPublic, viewers });
    },
    isGroupSelected(groupId: number) {
      return !this.isPublic && this.viewers.includes(groupId);
    },
    isMyGroupSelected() {
      return this.isGroupSelected(this.userGroupId);
    },
  },
});
</script>
