import { diff } from "./helpers/diff";

import type { TaskComparator } from "../types/TaskComparator";

export const files: TaskComparator = (a, b, isReversed = false) => {
  const aFilesSum =
    a.documents.length +
    a.folders.length +
    a.comments_documents.length +
    a.comments_folders.length;

  const bFilesSum =
    b.documents.length +
    b.folders.length +
    b.comments_documents.length +
    b.comments_folders.length;

  return diff(aFilesSum, bFilesSum, isReversed);
};
