import { defineStore } from "pinia";

import { generateList } from "@drVue/store/pinia/helpers/generators";
import { OrganizationMembersApiService } from "./api";

import type {
  NewOrganizationMemberPayload,
  OrganizationMember,
  UpdatedOrganizationMemberPayload,
} from "./api";

const api = new OrganizationMembersApiService();

export const useOrganizationMembers = defineStore(
  "pipelineOrganizationMembers",
  () => {
    const { list, dict, load, create, update, remove, isLoading, isLoadError } =
      generateList<
        OrganizationMember,
        NewOrganizationMemberPayload,
        UpdatedOrganizationMemberPayload
      >({
        name: "Pipeline organization members",
        load: api.load.bind(api),
        create: api.createMember.bind(api),
        update: api.updateMember.bind(api),
        remove: api.deleteMember.bind(api),
      });

    return {
      list,
      dict,
      isLoading,
      isLoadError,
      load,
      create,
      update,
      remove,
    };
  },
);
