import { cloneDeep } from "lodash-es";

import {
  deserializeCustomData,
  serializeCustomData,
} from "@drVue/api-service/parse";
import { $notifyDanger } from "@drVue/common";
import { OrgUsersApiService } from "@drVue/store/modules/client-dashboard/org-users/OrgUsersApiService";

import type { OrgUserPatchRequestData } from "@drVue/store/modules/client-dashboard/org-users/OrgUsersApiService";
import type { OrgUsersState } from "@drVue/store/modules/client-dashboard/org-users/OrgUsersState";
import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { RootState } from "@drVue/store/state";
import type { ActionContext, ActionTree } from "vuex";

export type IContext = ActionContext<OrgUsersState, RootState>;

export interface OrgUserPatchPayload {
  userId: string;
  updates: OrgUserPatchRequestData;
}

interface OrgUsersActionTree extends ActionTree<OrgUsersState, RootState> {
  load(context: IContext): Promise<void>;
  update(context: IContext, user: OrgUser): Promise<void>;
  bulkRevokeAccess(context: IContext, user: OrgUser): Promise<void>;
}

const api = new OrgUsersApiService();

export const orgUsersActions = {
  async load({ commit, rootGetters }: IContext) {
    commit("setIsError", false);
    commit("setIsLoading", true);

    try {
      const orgUsers = await api.list();
      commit("setOrgUsers", orgUsers);
    } catch (err) {
      commit("setIsError", true);
      throw err;
    } finally {
      commit("setIsLoading", false);
    }
  },

  create({ commit, rootGetters, dispatch }: IContext, user: OrgUser) {
    const payload = {
      ...user,
      custom_data: cloneDeep(user.custom_data),
    };

    serializeCustomData(
      payload.custom_data,
      rootGetters["clientDashboard/customFields/byObjectType"]("user"),
    );

    return api.create(payload).then((created) => {
      deserializeCustomData(
        created,
        rootGetters["clientDashboard/customFields/byObjectType"]("user"),
      );

      dispatch("load");
      return created;
    });
  },

  update({ commit, rootGetters }: IContext, user: OrgUser) {
    const payload = {
      ...user,
      custom_data: cloneDeep(user.custom_data),
    };

    serializeCustomData(
      payload.custom_data,
      rootGetters["clientDashboard/customFields/byObjectType"]("user"),
    );

    return api.update(user.id, payload).then((updated) => {
      deserializeCustomData(
        updated,
        rootGetters["clientDashboard/customFields/byObjectType"]("user"),
      );
      commit("update", updated);
    });
  },

  remove({ commit, rootGetters, dispatch }: IContext, orgUserId: string) {
    return api.remove(orgUserId).then(() => {
      commit("remove", orgUserId);
    });
  },

  async patch(
    { commit, rootGetters }: IContext,
    { userId, updates }: OrgUserPatchPayload,
  ) {
    try {
      updates = cloneDeep(updates);
      const customFields =
        rootGetters["clientDashboard/customFields/byObjectType"]("user");

      if (updates.custom_data) {
        serializeCustomData(updates.custom_data, customFields);
      }
      const updatedOrgUser = await api.patch(userId, updates);
      deserializeCustomData(updatedOrgUser, customFields);

      commit("update", updatedOrgUser);
    } catch (err) {
      $notifyDanger("Failed to update deal");
      throw err;
    }
  },

  async bulkRevokeAccess({ dispatch, rootGetters }: IContext, user: OrgUser) {
    await api.bulkRevokeAccess(user);
    dispatch("clientDashboard/deals/load", null, { root: true });
  },
} as OrgUsersActionTree;
