<template>
  <div>
    <DrVxeGrid
      :data="statuses"
      :columns="tableColumns.columns"
      @cell-click="onCellClick"
    />

    <TaskStatusesCreateOrEditModal ref="modal" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrForm from "@shared/ui/dr-form";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import { ROOM_DATA } from "@setups/data";
import { $notifyDanger, $notifySuccess } from "@drVue/common";
import TableColumns from "@drVue/components/room/settings/tableColumns";
import TaskStatusesCreateOrEditModal from "@drVue/components/room/settings/TaskStatusesCreateOrEditModal.vue";
import { pinia } from "@drVue/store/pinia";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";

import type { TaskStatus } from "@drVue/store/modules/room/tasks-statuses/types";

interface Data {
  tableColumns: any;
}

const tasksStore = useTasksStore(pinia);

export default defineComponent({
  name: "TaskStatuses",
  components: {
    DrVxeGrid,
    TaskStatusesCreateOrEditModal,
  },
  extends: DrForm,
  data(): Data {
    return {
      tableColumns: new TableColumns(),
    };
  },
  computed: {
    statuses(): TaskStatus[] {
      return this.$store.state.room.tasksStatuses.list;
    },
  },
  async mounted() {
    await this.$store.dispatch("room/tasksStatuses/syncAll");
  },
  methods: {
    onCellClick({ row, $event }: { row: TaskStatus; $event: PointerEvent }) {
      if (!$event.target) return;
      if ($event.target instanceof HTMLAnchorElement) {
        type Action = "edit" | "delete" | undefined;
        $event.preventDefault(); // href's empty, prevent window reload
        const action = $event.target.dataset.action as Action;
        if (action === "edit") this.openEditModal(row);
        if (action === "delete") this.deleteStatus(row);
      }
    },
    openEditModal(row: TaskStatus) {
      const modal = this.$refs.modal as InstanceType<
        typeof TaskStatusesCreateOrEditModal
      >;
      modal.openEditModal(row);
    },
    deleteStatus(row: TaskStatus) {
      const statusType = row.type;
      const statusTypeCount = this.statuses.filter(
        (item) => item.type === statusType,
      ).length;

      if (statusTypeCount < 2) {
        const typeLabel =
          this.$store.state.room.tasksStatuses.TYPES_DEFAULT_LABEL[statusType];
        this.$msgbox({
          title: "Unacceptable action",
          message: `This status can not be deleted because there must be at least one "${typeLabel}" status type.`,
          confirmButtonText: "Ok",
          showCancelButton: false,
        });
        return;
      }

      this.$confirm(
        `This action will switch all requests currently labeled as "${row.name}" to a different status of the same type.`,
        "Are you sure?",
        {
          confirmButtonText: "Remove",
          cancelButtonText: "Cancel",
        },
      ).then(
        () => {
          this.$store.dispatch("room/tasksStatuses/delete", row.id).then(
            () => {
              if (ROOM_DATA.id) tasksStore.load();

              $notifySuccess(`Status "${row.name}" was removed`);
            },
            (err) => {
              let msg = "Failed to remove selected status.";
              const backendRespError = err.response?.data?.non_field_errors;
              if (backendRespError) {
                msg = backendRespError.join(" ");
              }
              $notifyDanger(msg);
            },
          );
        },
        () => {},
      );
    },
  },
});
</script>
