<template>
  <div :class="$style.cell">
    <ElTooltip :content="status?.name" placement="top">
      <DrIconStatus :color="status?.color" />
    </ElTooltip>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useStore } from "vuex";
import DrIconStatus from "@shared/ui/dr-icon-status";

import type { TaskStatus } from "@drVue/store/modules/room/tasks-statuses/types";

const $store = useStore();
const getStatusById = $store.getters["room/tasksStatuses/byId"];

const props = defineProps<{ statusId: number }>();
const status = computed((): TaskStatus | null => {
  return getStatusById(props.statusId);
});
</script>

<style lang="scss" module>
.cell {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
