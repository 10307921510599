import * as d3 from "d3";

angular
  .module("dealroom.analytics.progressbar.service.data", [
    "dealroom.analytics.service.colors-scheme",
  ])
  .service("AnalyticsProgressbarDataService", AnalyticsProgressbarData);

const MIN_WIDTH = 25;

export { MIN_WIDTH };

AnalyticsProgressbarData.$inject = ["AnalyticsColorsSchemeService"];
function AnalyticsProgressbarData(AnalyticsColorsSchemeService) {
  const DEFAULT_TOTAL_COLOR = AnalyticsColorsSchemeService.grey.normal;
  const DEFAULT_COLOR = AnalyticsColorsSchemeService.grey.dark;
  const EMPTY_WIDTH = 0;

  return { convert };

  function convert(_items, elementWidth) {
    const { items, totalValue } = validateItems(_items);
    const percentScale = d3
      .scaleLinear()
      .domain([0, totalValue])
      .rangeRound([0, 100]);
    const scale = d3
      .scaleLinear()
      .domain([0, totalValue])
      .range([0, elementWidth]);
    return items.reduce(_convert, []);

    function _convert(bucket, item, i) {
      const width = +scale(item.value);
      let prev = bucket[i - 1],
        isFirst = i === 0;
      if (prev && prev.isTotal) {
        isFirst = i === 1;
        if (isFirst === false) {
          prev = bucket[i - 2];
        }
      }
      const color = getColor(item, totalValue);
      const zIndex = item.isTotal ? 1 : 10;
      const start = {
        width: item.isTotal ? width : EMPTY_WIDTH,
        left: 0,
        bg: item.isTotal ? color : DEFAULT_COLOR,
        labelOpacity: item.isEmpty ? 1 : 0,
      };
      const isAnimatable = !(item.isTotal || item.isEmpty);
      const label = item.value + (item.prefix || "");
      bucket.push({
        label: getLabel(label),
        zIndex,
        key: item.isTotal ? "total" : undefined,
        title: item.isEmpty || item.isTotal ? null : item.title,
        isTotal: item.isTotal,

        start,

        end:
          isAnimatable === false
            ? start
            : {
                width,
                left: isFirst ? 0 : prev ? prev.end.width + prev.end.left : 0,
                bg: color,
                labelOpacity: 1,
              },

        tooltip:
          isAnimatable === false
            ? {}
            : {
                color,
                title: item.title,
                value: label,
              },
      });
      return bucket;

      function getLabel(label) {
        if (item.isTotal) return item.isEmpty ? "N/A" : "";
        if (width > MIN_WIDTH) return label;
        return "";
      }
    }
  }

  function validateItems(items) {
    if (items instanceof Array) {
      items = items.filter((item) => item.value > 0);
    } else {
      items = [];
    }

    const totalItem = items.find((i) => i.isTotal);
    const minLength = totalItem === undefined ? 0 : 1;
    if (items.length <= minLength) return getDeafult();

    let totalValue;
    if (totalItem === undefined) {
      totalValue = d3.sum(items, (item) => item.value);
    } else {
      totalValue = totalItem.value;
    }

    return { items, totalValue };

    function getDeafult() {
      const totalItem = { value: 100, isTotal: true, isEmpty: true };
      const items = [{ isEmpty: true }, totalItem];
      return { items, totalValue: totalItem.value };
    }
  }

  function getColor(item, totalValue) {
    if (item.isTotal) return DEFAULT_TOTAL_COLOR;
    if (item.color) return item.color;
    if (item.percantageColorize === true || item.percantageColorize === false)
      return getColorFromValue(item, totalValue);
    return DEFAULT_COLOR;

    function getColorFromValue(item, totalValue) {
      const percentage = [25, 50, 100];
      let colors = [
        AnalyticsColorsSchemeService.red.normal,
        AnalyticsColorsSchemeService.orange.normal,
        AnalyticsColorsSchemeService.green.normal,
      ];
      if (item.percantageColorize === false) colors = colors.reverse();

      const p = (item.value / totalValue) * 100;
      return colors.find((c, i) => p <= percentage[i]);
    }
  }
}
