<template>
  <ElPopover
    width="350"
    trigger="click"
    popper-class="el-popover--no-padding el-popover--no-margin"
  >
    <div class="el-select-dropdown is-multiple is-inline">
      <ul
        class="el-scrollbar__view el-select-dropdown__list"
        data-testid="deal-dashboard-toolbar-filter-deal-type-dropdown"
      >
        <!-- @vue-ignore: extra -->
        <ElTooltip
          v-for="item in field.extra.options"
          :key="item"
          :content="item"
          :show-after="400"
        >
          <li
            class="el-select-dropdown__item"
            :class="{
              selected: isSelected(item),
            }"
            @click.stop="toggleItem(item)"
          >
            {{ item }}
          </li>
        </ElTooltip>
      </ul>
    </div>
    <template #reference>
      <DrToolbarFilterButton
        :label="field.label"
        :counter="selectedValues?.length ?? 0"
        :is-active="isActive"
        @clear="clearFilter"
      />
    </template>
  </ElPopover>
</template>

<script lang="ts">
import { pull } from "lodash-es";
import { defineComponent } from "vue";
import { DrToolbarFilterButton } from "@shared/ui/dr-toolbar";

import { FieldItemTypes } from "@drVue/api-service/client-dashboard";

import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { CustomView } from "@setups/types";
import type { PropType } from "vue";

export default defineComponent({
  name: "SelectField",
  components: { DrToolbarFilterButton },
  props: {
    field: { required: true, type: Object as PropType<FieldItem> },
  },
  computed: {
    isMultiSelect(): any {
      return this.field.field_type === FieldItemTypes.MultiSelect;
    },
    isActive(): any {
      return !!(this.selectedValues && this.selectedValues.length);
    },
    selectedValues: {
      get: function (): string[] | undefined {
        const current: CustomView =
          this.$store.state.common.customViews.current;
        return current.filters.fields[this.field.key]?.value;
      },
      set: function (value: string[] | undefined) {
        this.$store.commit("common/customViews/setCurrentFilterValue", {
          field: this.field.key,
          value: value,
          op: this.isMultiSelect ? "ALL" : "ANY",
        });
      },
    },
  },
  methods: {
    isSelected(item: string) {
      return this.selectedValues ? this.selectedValues.includes(item) : false;
    },
    toggleItem(item: string) {
      const values = [...(this.selectedValues || [])];
      if (this.isSelected(item)) {
        pull(values, item);
      } else {
        values.push(item);
      }
      this.selectedValues = values;
    },
    clearFilter() {
      this.selectedValues = undefined;
    },
  },
});
</script>
