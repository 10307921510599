<template>
  <DrToolbarFilterSearch v-model="tasksFilterService.filters.searchText" />

  <div :class="$style.filters">
    <div :class="$style.filtersList">
      <DrToolbarFilterSelect
        v-model="tasksFilterService.filters.statuses"
        label="Status"
        :options="statusesOptions"
      >
        <template #icon="{ option }">
          <DrIconStatus :color="(option as SelectStatusOptionItem).color" />
        </template>
      </DrToolbarFilterSelect>

      <DrToolbarFilterSelect
        v-if="access.priorities"
        v-model="tasksFilterService.filters.priorities"
        label="Priority"
        :options="prioritiesOptions"
      >
        <template #icon="{ option }">
          <DrIconPriority :priority="option.id as PriorityValue" />
        </template>
      </DrToolbarFilterSelect>

      <DrToolbarFilterDatepicker
        v-if="access.dueDateRange"
        v-model="tasksFilterService.filters.dueDateRange"
        label="Due date"
      />

      <DrToolbarFilterTree
        v-if="access.assignees"
        v-model="assigneesModelValue"
        label="Assignee"
        :tree="usersTree"
        :favorites="assigneesFavorites"
        :is-pending="!isMembersAndGroupsLoaded"
      >
        <template #tree-item-prefix="{ item, className }">
          <DrAvatar
            :name="item.name"
            :identifier="(item as UsersTreeItem).email"
            :url="(item as UsersTreeItem).avatar"
            :class="className"
          />
        </template>
      </DrToolbarFilterTree>

      <DrToolbarFilterTree
        v-if="access.reviewers"
        v-model="reviewersModelValue"
        label="Reviewer"
        :tree="usersTree"
        :favorites="reviewersFavorites"
        :is-pending="!isMembersAndGroupsLoaded"
      >
        <template #tree-item-prefix="{ item, className }">
          <DrAvatar
            :name="item.name"
            :identifier="(item as UsersTreeItem).email"
            :url="(item as UsersTreeItem).avatar"
            :class="className"
          />
        </template>
      </DrToolbarFilterTree>
    </div>
  </div>

  <slot name="append" />
</template>
<script setup lang="ts">
import { capitalize } from "lodash-es";
import { computed, watch } from "vue";
import { DrAvatar } from "@shared/ui/dr-avatar";
import DrIconPriority, {
  type PriorityValue,
} from "@shared/ui/dr-icon-priority";
import DrIconStatus from "@shared/ui/dr-icon-status";
import {
  DrToolbarFilterDatepicker,
  DrToolbarFilterSearch,
  DrToolbarFilterSelect,
  DrToolbarFilterTree,
} from "@shared/ui/dr-toolbar";

import { TaskFieldAccessType } from "@setups/enums";
import { insightTrack, RoomTasksFiltersEvent } from "@app/insight";
import { TasksFilterServiceProxy } from "@app/ng/serviceProxies";
import { ROOM_DATA, ROOM_MEMBER_DATA, USER_DATA } from "@app/setups/data";
import DrStore from "@drVue/store";
import { TASK_PRIORITY } from "@drVue/store/pinia/room/tasks";

import type { Group } from "@drVue/store/modules/room/groups/GroupsApiService";
import type { RoomMember } from "@drVue/store/modules/room/members/RoomMembersApiService";
import type { TaskStatus } from "@drVue/store/modules/room/tasks-statuses/types";
import type { StatusColor } from "@shared/ui/dr-icon-status";
import type { SelectOptionItem } from "@shared/ui/dr-popups";
import type { TreeItem } from "@shared/ui/dr-tree";

type SelectStatusOptionItem = SelectOptionItem & { color: StatusColor };
const MEMBER_ID_PREFIX = "member_";
type UserOptionItemId = `${typeof MEMBER_ID_PREFIX}${number}` | "unassigned";

const UNASSIGNED_USER_ID = "unassigned";
const tasksFilterService = new TasksFilterServiceProxy();
const canManageTasks = ROOM_DATA.userPermissions.canManageTasks;

const accessStartDueDatesFields =
  (ROOM_MEMBER_DATA.group.task_start_and_due_dates_access ||
    TaskFieldAccessType.NoAccess) !== TaskFieldAccessType.NoAccess;

const access: Record<string, boolean> = {
  assignees: canManageTasks,
  dueDateRange: accessStartDueDatesFields,
  priorities: canManageTasks,
  reviewers: canManageTasks,
};

const statusesOptions = computed<SelectStatusOptionItem[]>(() => {
  return DrStore.state.room.tasksStatuses.list.map((status: TaskStatus) => ({
    id: status.id,
    color: status.color,
    name: status.name,
    isSelected: tasksFilterService.filters.statuses.includes(status.id),
  }));
});
const prioritiesOptions = computed<SelectOptionItem[]>(() => {
  return TASK_PRIORITY.map((item) => ({
    id: item,
    name: capitalize(item),
    isSelected: tasksFilterService.filters.priorities.includes(item),
  }));
});

const isMembersAndGroupsLoaded = computed(() => {
  const m = DrStore.state.room.members;
  const g = DrStore.state.room.groups;

  return (
    m.isLoading === false &&
    m.isError === false &&
    g.isLoading === false &&
    g.isError === false
  );
});
const groupsList = computed(() => DrStore.state.room.groups.pgroupsList);

const getUsersOfGroup = (group: Group) => {
  return DrStore.state.room.members.membersList.reduce(
    (acc: RoomMember[], m: RoomMember) => {
      const isActiveUser = !m.pending;
      const isMemberOfTheGroup = m.pgroup.id === group.id;
      const canTheGroupManageTasks = group.can_task_manage;

      if (isActiveUser && isMemberOfTheGroup && canTheGroupManageTasks) {
        acc.push(m);
      }

      return acc;
    },
    [],
  );
};

type UsersTreeGroup = TreeItem<
  void,
  {
    avatar: string;
    email: string;
  }
>;
type UsersTreeItem = TreeItem<{
  id: UserOptionItemId;
  avatar: string;
  email: string;
}>;
const usersTree = computed<UsersTreeGroup[]>(() => {
  return groupsList.value.reduce((acc: UsersTreeGroup[], g: Group) => {
    const groupMembers = getUsersOfGroup(g).map((u) => {
      return {
        id: `member_${u.id}`,
        name: u.name,
        email: u.email,
        avatar: u.avatar.reduced || "",
      };
    });

    if (!groupMembers.length) return acc;

    const group = {
      id: `group_${g.id}`,
      name: g.name,
      children: groupMembers,
    };

    acc.push(group);

    return acc;
  }, []);
});
const assigneesFavorites = computed<UsersTreeItem[]>(() => {
  return [
    {
      id: `${MEMBER_ID_PREFIX}${USER_DATA.id}`,
      name: "Assigned to me",
      avatar: USER_DATA.profile.avatar.thumbnail || "",
      email: USER_DATA.email,
    },
    {
      id: UNASSIGNED_USER_ID,
      name: "Show unassigned",
      avatar: "",
      email: "",
    },
  ];
});
const reviewersFavorites = computed<UsersTreeItem[]>(() => {
  return [
    {
      id: `${MEMBER_ID_PREFIX}${USER_DATA.id}`,
      name: "Need my review",
      avatar: USER_DATA.profile.avatar.thumbnail || "",
      email: USER_DATA.email,
    },
  ];
});
const assigneesModelValue = computed({
  get(): UserOptionItemId[] {
    const members = tasksFilterService.filters.assignees.map(
      (assigneeId: number) => `${MEMBER_ID_PREFIX}${assigneeId}`,
    );
    if (tasksFilterService.filters.showUnassigned) {
      members.push(UNASSIGNED_USER_ID);
    }
    return members;
  },
  set(checkedIds: UserOptionItemId[]) {
    const showUnassigned = checkedIds.includes(UNASSIGNED_USER_ID);
    const parsedMembersIds = checkedIds
      .filter((id) => id !== UNASSIGNED_USER_ID)
      .map((memberId) => Number(memberId.replace(MEMBER_ID_PREFIX, "")));

    tasksFilterService.filters.showUnassigned = showUnassigned;
    tasksFilterService.filters.assignees = parsedMembersIds;
  },
});
const reviewersModelValue = computed({
  get() {
    return tasksFilterService.filters.reviewers.map(
      (reviewerId: number) => `${MEMBER_ID_PREFIX}${reviewerId}`,
    );
  },
  set(checkedIds: UserOptionItemId[]) {
    const parsedMembersIds = checkedIds.map((memberId: string) =>
      Number(memberId.replace(MEMBER_ID_PREFIX, "")),
    );

    tasksFilterService.filters.reviewers = parsedMembersIds;
  },
});

watch(
  () => tasksFilterService.filters.assignees,
  (membersIds: number[]) => {
    if (!membersIds.length) return;

    insightTrack(RoomTasksFiltersEvent.Filtered, {
      filtered_by: "assignees",
    });
  },
);

watch(
  () => tasksFilterService.filters.showUnassigned,
  (showUnassigned: boolean) => {
    if (!showUnassigned) return;

    insightTrack(RoomTasksFiltersEvent.Filtered, {
      filtered_by: "show unassigned",
    });
  },
);

watch(
  () => tasksFilterService.filters.reviewers,
  (membersIds: number[]) => {
    if (!membersIds.length) return;

    insightTrack(RoomTasksFiltersEvent.Filtered, {
      filtered_by: "reviewers",
    });
  },
);

watch(
  () => tasksFilterService.filters.statuses,
  (checkedIds: TaskStatus["id"][]) => {
    if (!checkedIds.length) return;

    insightTrack(RoomTasksFiltersEvent.Filtered, {
      filtered_by: "status",
    });
  },
);

watch(
  () => tasksFilterService.filters.priorities,
  (priorities: (typeof TASK_PRIORITY)[]) => {
    if (!priorities.length) return;

    insightTrack(RoomTasksFiltersEvent.Filtered, {
      filtered_by: "priority",
    });
  },
);

watch(
  () => tasksFilterService.filters.dueDateRange,
  (range: [Date, Date] | []) => {
    if (!range.length) return;

    insightTrack(RoomTasksFiltersEvent.Filtered, {
      filtered_by: "due_date",
    });
  },
);

watch(
  () => tasksFilterService.filters.labels,
  (labels: string[]) => {
    if (!labels.length) return;

    insightTrack(RoomTasksFiltersEvent.Filtered, {
      filtered_by: "labels",
    });
  },
);
</script>

<style lang="scss" module>
@use "@app/styles/scss/spacing";

.filters {
  position: relative;
  height: 36px;
  padding: 2px;
  overflow: hidden;
}

.filtersList {
  display: flex;
  flex-wrap: wrap;
  gap: spacing.$xs;
}
</style>
