<template>
  <DrLayoutContentCenteredSection
    title="Require Two-Factor Authentication (2FA)"
  >
    <template #description>
      When this is enabled all users are required to use 2FA to log in.
    </template>

    <ElButton
      plain
      type="primary"
      :loading="isSubmitting"
      @click="toggleRequire2FA"
    >
      {{ settings.require_2fa ? "Make 2FA optional" : "Require 2FA" }}
    </ElButton>
  </DrLayoutContentCenteredSection>

  <DrLayoutContentCenteredSection
    v-if="showSsoField"
    title="Require single sign-on (SSO)"
  >
    <template #description>
      Require single sign-on authentication for all {{ clientDomain }} users.
    </template>

    <ElButton
      plain
      type="primary"
      :loading="isSubmitting"
      @click="toggleRequireSSO"
    >
      {{ settings.require_sso ? "Make SSO optional" : "Require SSO" }}
    </ElButton>
  </DrLayoutContentCenteredSection>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrForm from "@shared/ui/dr-form";
import { DrLayoutContentCenteredSection } from "@shared/ui/dr-layouts";

import { ORG_MEMBER_DATA } from "@setups/data";
import DrStore from "@drVue/store";

const confirmationMessages: { [key: string]: any } = {
  require_2fa: {
    true: {
      title: "Require Two-Factor Authentication",
      body: "Existing members without two-factor authentication will be forced to turn it on. Do you want to continue?",
    },
    false: {
      title: "Make Two-Factor Optional",
      body: "Are you sure you want to allow users to access your rooms without having two-factor authentication enabled?",
    },
  },
  bg_color: {},
  require_sso: {
    true: {
      title: "Require SSO",
      body: `All users of ${ORG_MEMBER_DATA.client.sso_allowed_domain} will have to use SSO. Users who are not logged in using SSO will have to re-login. Do you want to continue?`,
    },
    false: {
      title: "Make SSO Optional",
      body: "Are you sure you want to allow users of your organization to login with any available method, including email and password?",
    },
  },
};

interface Data {
  settings: any;
}

export default defineComponent({
  name: "SettingsAuthGeneral",
  components: {
    DrLayoutContentCenteredSection,
  },
  extends: DrForm,
  data(): Data {
    return {
      settings: DrStore.state.clientDashboard.settings,
    };
  },
  computed: {
    clientDomain(): any {
      return ORG_MEMBER_DATA.client.sso_allowed_domain;
    },
    showSsoField(): any {
      return (
        this.settings.require_sso ||
        ORG_MEMBER_DATA.client.enabled_openid_providers.length
      );
    },
  },
  methods: {
    toggleRequire2FA() {
      if (this.isSubmitting) return;
      return this.confirmAndSubmit("require_2fa", !this.settings.require_2fa);
    },
    toggleRequireSSO() {
      if (this.isSubmitting) return;
      return this.confirmAndSubmit(
        "require_sso",
        !this.settings.require_sso,
      ).then(() => {
        if (this.settings.require_sso) {
          window.location.reload();
        }
      });
    },
    confirmAndSubmit(option: string, value: any) {
      const data = { [option]: value };
      const confirm = confirmationMessages[option][value];
      if (!confirm) {
        return this.updateSettings(data);
      }
      return this.$confirm(confirm.body, confirm.title, {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
      })
        .then(() => {
          return this.updateSettings(data);
        })
        .catch(() => {});
    },
    updateSettings(data: { [key: string]: any }) {
      return this.submitAction(
        "clientDashboard/settings/update",
        data,
        "Failed to update client settings",
      );
    },
  },
});
</script>
