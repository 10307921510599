import * as d3 from "d3";

import Animation from "./animation";

angular
  .module("dealroom.analytics.linechart.lines.paths", [
    "dealroom.analytics.linechart.service.config",
  ])
  .service("AnalyticsLinechartPathFactory", PathFactory);

PathFactory.$inject = ["AnalyticsLinechartConfigService"];
function PathFactory(AnalyticsLinechartConfigService) {
  const STROKE_WIDTH = AnalyticsLinechartConfigService.STROKE_WIDTH;
  const MIN_OPACTITY = 0.2;

  return function (HIGHT, R) {
    const flatY = HIGHT + STROKE_WIDTH / 2;

    return {
      remove,
      updateYs,
      update,
    };

    function remove(parent, onEnd) {
      flat(parent, { opacity: MIN_OPACTITY }, _remove);

      function _remove() {
        d3.select(this).remove();
        onEnd();
      }
    }

    function updateYs(parent, beforeUpdate = Function.prototype) {
      const data = getData(parent);
      const selection = getSelection(parent);
      const updated = selection.call(beforeUpdate, data).data(data);
      updated.exit().remove();
      return create(updated).call(raise);
    }

    function update(parent, [x2, x1]) {
      flat(parent, { x1, x2 }, _createAndRaise);

      function _createAndRaise() {
        updateYs(parent, updateXs);
      }

      function updateXs(selection, newData) {
        return selection.attr("d", function (d, i) {
          const newPoints = newData[i] && newData[i].points;
          if (newPoints === undefined) return d3.select(this).attr("d");
          const line = d3
            .line()
            .x((d) => d.x)
            .y(flatY);
          return line(newPoints);
        });
      }
    }

    ////////
    function getData(parent) {
      const { points, color } = parent.datum();
      const line = d3
        .line()
        .x((d) => d.x)
        .y((d) => (d.isUnreachedYet ? flatY : d.y));
      const data = {
        color,
        points,
        d: line(points),
      };
      return [data];
    }

    function getSelection(parent) {
      return parent.selectAll(".path");
    }

    function setFlatD(selection, { x1, x2 } = {}) {
      if (selection.empty()) return selection;
      return selection.attr("d", function ({ points }) {
        const line = d3.line().x(getX).y(flatY);
        return line(points);

        function getX(d, i) {
          if (x1 === undefined || x2 === undefined) return d.x;

          if (i === 0) return x1;
          if (i === points.length - 1) return x2;
          return d.x;
        }
      });
    }

    function flat(parent, params, onEnd) {
      const selection = getSelection(parent);
      params.opacity = angular.isNumber(params.opacity)
        ? params.opacity
        : MIN_OPACTITY;

      return Animation.flat(selection, params, onEnd).call(setFlatD, params);
    }

    function raise(selection) {
      Animation.raise(selection).attr("d", (d) => d.d);
    }

    function create(selection) {
      return selection
        .enter()
        .append("path")
        .attr("fill", "none")
        .attr("stroke", (d) => d.color)
        .attr("class", "path")
        .attr("stroke-width", STROKE_WIDTH)
        .attr("opacity", 1)
        .call(setFlatD)
        .merge(selection);
    }
  };
}
