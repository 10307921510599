<template>
  <div ref="containerRef" :class="$style.toolbar">
    <div v-if="selectedItems.length === 0" :class="$style.toolbarRight">
      <DrButtonMore>
        <ElDropdownItem
          v-if="ROOM_DATA.userPermissions.canCreateTasks"
          type="text"
          @click="createNewRequest"
        >
          New Request
        </ElDropdownItem>

        <ElDropdownItem
          v-if="parentFolder?.edit && items.length"
          type="text"
          @click="showMenuEditIndex"
        >
          Edit Index
        </ElDropdownItem>

        <ElDropdownItem
          v-if="parentFolder"
          type="text"
          @click="exportIndexesPdf"
        >
          Export to PDF
        </ElDropdownItem>

        <ElDropdownItem
          v-if="parentFolder"
          type="text"
          @click="exportIndexesXlsx"
        >
          Export to Excel
        </ElDropdownItem>
      </DrButtonMore>

      <DrTooltip v-if="parentFolder?.edit" content="Add folder">
        <ElButton :class="$style.iconBtn" @click="showMenuNewFolder">
          <DrIcon name="folder-plus" />
        </ElButton>
      </DrTooltip>

      <DocumentsTableOptionsButton />
    </div>

    <DrToolbarBulkActions v-else :actions="bulkActions" slot-on-right>
      <DrToolbarFilterButton
        :label="`${selectedItems.length} ${selectedText} selected`"
        is-active
        @clear="clearSelected"
      />
    </DrToolbarBulkActions>
  </div>
</template>

<script lang="ts" setup>
import { ElButton } from "element-plus";
import { ElDropdownItem } from "element-plus";
import { computed, markRaw, ref } from "vue";

import { insightTrack, RoomDataroomBulkEvent } from "@app/insight";
import { ROOM_DATA } from "@app/setups";
import { ROOM_MEMBER_DATA } from "@app/setups/data";
import { AiAccess } from "@app/setups/enums";
import { DrStore } from "@app/vue";
import DrButtonMore from "@app/vue/shared/ui/dr-button/DrButtonMore.vue";
import DrIcon from "@app/vue/shared/ui/dr-icon/DrIcon.vue";
import DrToolbarBulkActions from "@app/vue/shared/ui/dr-toolbar/DrToolbarBulkActions.vue";
import DrToolbarFilterButton from "@app/vue/shared/ui/dr-toolbar/DrToolbarFilterButton.vue";
import DrTooltip from "@app/vue/shared/ui/dr-tooltip/DrTooltip.vue";
import {
  type Document,
  type Folder,
  isDocument,
  isFolder,
} from "@app/vue/store/modules/room/documents/DocumentsApiService";
import FolderMenuAiReport from "./components/FolderMenuAiReport.vue";
import FolderMenuChangeIndex from "./components/FolderMenuChangeIndex.vue";
import FolderMenuCopy from "./components/FolderMenuCopy.vue";
import FolderMenuMove from "./components/FolderMenuMove.vue";
import FolderMenuNewFolder from "./components/FolderMenuNewFolder.vue";
import FolderMenuNewRequest from "./components/FolderMenuNewRequest.vue";
import DocumentsTableOptionsButton from "./DocumentsTableOptionsButton.vue";
import IconAiAnalysis from "./svg/IconAiAnalysis.vue";
import { useFolderMenu } from "./use/useFolderMenu";
import {
  useFolderBulkMenuBus,
  useFolderMenuBus,
  useFolderMenuPersistedBus,
} from "./use/useFolderMenuBus";
import { type FolderMenuParams, getFolderMenuEntityTitle } from "./utils";

import type { ToolbarBulkActionsItem } from "@app/vue/shared/ui/dr-toolbar/types";

type TableItem = (Folder | Document) & { _isChecked?: boolean };

interface Props {
  parentFolder?: Folder;
  items: TableItem[];
  isBookmarks?: boolean;
  isArchive?: boolean;
}

interface Events {
  (e: "update-items-list"): void;
  (e: "checkbox-all"): void;
}

const props = withDefaults(defineProps<Props>(), {
  items: () => [],
});

const emit = defineEmits<Events>();

const bulkActions = computed<ToolbarBulkActionsItem[]>(() => {
  const isOnlyDocumentsSelected =
    selectedItemsViewable.value.length === selectedItems.value.length;

  return [
    {
      id: "bookmark",
      name: "Bookmark",
      icon: "bookmark",
      getIsVisible: () => !props.isBookmarks && !props.isArchive,
      action: () => bookmarkItems(true),
    },
    {
      id: "unbookmark",
      name: "Unbookmark",
      icon: "bookmark",
      getIsVisible: () => props.isBookmarks && !props.isArchive,
      action: () => bookmarkItems(false),
    },
    {
      id: "new_request",
      name: "New Request",
      icon: "calendar-plus",
      getIsVisible: () =>
        !props.isArchive && ROOM_DATA.userPermissions.canCreateTasks,
      action: (event) => createNewRequest(event),
    },
    {
      id: "move",
      name: "Move",
      icon: "arrows",
      getIsVisible: () =>
        !props.isBookmarks &&
        !props.isArchive &&
        selectedItemsEditable.value.length !== 0,
      action: (event) => showMenuMove(event),
    },
    {
      id: "copy",
      name: "Copy",
      icon: "clone",
      getIsVisible: () =>
        !props.isArchive && selectedItemsEditable.value.length !== 0,
      action: (event) => showMenuCopy(event),
    },
    {
      id: "delete",
      name: "Delete",
      icon: "archive",
      getIsVisible: () =>
        !props.isBookmarks &&
        !props.isArchive &&
        selectedItemsEditable.value.length !== 0,
      action: () => removeItems(),
    },
    {
      id: "restore",
      name: "Restore",
      icon: "undo",
      getIsVisible: () => props.isArchive,
      action: () => restoreItems(),
    },
    {
      id: "restore",
      name: "Delete Permanently",
      icon: "archive",
      getIsVisible: () => props.isArchive,
      action: () => deletePermanentlyItems(),
    },
    {
      id: "ai_report",
      name: "Analyze with AI",
      iconComponent: markRaw(IconAiAnalysis),
      getIsVisible: () =>
        !props.isArchive &&
        ROOM_DATA.isAiEnabled &&
        ROOM_MEMBER_DATA.group.ai_access === AiAccess.BASIC,
      action: (event) => showMenuAiReport(event),
      tooltip: !isOnlyDocumentsSelected
        ? "AI report can be applied only on documents"
        : undefined,
      disabled: !isOnlyDocumentsSelected,
    },
  ];
});

const containerRef = ref<HTMLDivElement | undefined>();

const selectedItems = computed(() =>
  props.items.filter((item) => item._isChecked),
);

const selectedItemsDownload = computed(() =>
  selectedItems.value.filter((item) => item.download),
);

const selectedItemsViewable = computed(
  () =>
    selectedItems.value.filter(
      (item) => isDocument(item) && item.processing?.is_viewable,
    ) as Document[],
);

const selectedItemsEditable = computed(() =>
  selectedItems.value.filter((item) => item.edit),
);

const selectedText = computed(() => {
  return getFolderMenuEntityTitle({
    folders: selectedItems.value.filter(isFolder),
    documents: selectedItems.value.filter(isDocument),
  });
});

const folderMenu = useFolderMenu({
  updateItemsList: () => emit("update-items-list"),
});
const folderMenuBus = useFolderMenuBus();
const folderMenuPersistedBus = useFolderMenuPersistedBus();
const folderBulkMenuBus = useFolderBulkMenuBus();

const showMenuNewFolder = (event: MouseEvent) => {
  if (!(event.target instanceof HTMLElement) || !props.parentFolder) return;

  insightTrack(RoomDataroomBulkEvent.NewFolder);

  folderMenuBus.emit("menu:show", {
    reference: event.target,
    params: {
      folders: [props.parentFolder],
      documents: [],
    },
    placement: "bottom",
    menuComponent: markRaw(FolderMenuNewFolder),
  });
};

const showMenuEditIndex = (event: MouseEvent) => {
  if (!(event.target instanceof HTMLElement) || !props.items[0]) return;

  insightTrack(RoomDataroomBulkEvent.IndexChange);

  folderMenuBus.emit("menu:show", {
    reference: event.target,
    params: {
      folders: [],
      documents: [],
      [isFolder(props.items[0]) ? "folders" : "documents"]: [props.items[0]],
    },
    menuComponent: markRaw(FolderMenuChangeIndex),
  });
};

const showMenuMove = (event?: MouseEvent) => {
  const reference = event?.target ?? containerRef.value;

  if (!(reference instanceof HTMLElement)) return;

  insightTrack(RoomDataroomBulkEvent.Move);

  folderMenuBus.emit("menu:show", {
    reference,
    params: {
      folders: selectedItemsEditable.value.filter(isFolder),
      documents: selectedItemsEditable.value.filter(isDocument),
    },
    placement: "bottom",
    menuComponent: markRaw(FolderMenuMove),
  });
};

const showMenuCopy = (event?: MouseEvent) => {
  const reference = event?.target ?? containerRef.value;

  if (!(reference instanceof HTMLElement)) return;

  insightTrack(RoomDataroomBulkEvent.Copy);

  folderMenuBus.emit("menu:show", {
    reference,
    params: {
      folders: selectedItemsDownload.value.filter(isFolder),
      documents: selectedItemsDownload.value.filter(isDocument),
    },
    placement: "bottom",
    menuComponent: markRaw(FolderMenuCopy),
  });
};

const showMenuAiReport = (event?: MouseEvent) => {
  const reference = event?.target ?? containerRef.value;

  if (!(reference instanceof HTMLElement)) return;

  insightTrack(RoomDataroomBulkEvent.AiReportOpen);

  folderMenuBus.emit("menu:show", {
    reference,
    params: {
      folders: [],
      documents: selectedItemsViewable.value,
    },
    placement: "bottom",
    menuComponent: markRaw(FolderMenuAiReport),
  });
};

const bookmarkItems = async (set_bookmarked: boolean) => {
  insightTrack(RoomDataroomBulkEvent.Bookmark);

  await DrStore.dispatch("room/documents/bookmarkItems", {
    folder_ids: selectedItems.value.filter(isFolder).map((item) => item.id),
    document_ids: selectedItems.value.filter(isDocument).map((item) => item.id),
    set_bookmarked,
  });

  emit("update-items-list");
};

const createNewRequest = (event?: MouseEvent) => {
  const reference = event?.target ?? containerRef.value;

  if (!(reference instanceof HTMLElement)) return;

  insightTrack(RoomDataroomBulkEvent.NewRequest);

  folderMenuBus.emit("menu:show", {
    reference,
    params: {
      folders: selectedItems.value.filter(isFolder),
      documents: selectedItems.value.filter(isDocument),
    },
    menuComponent: markRaw(FolderMenuNewRequest),
  });
};

const removeItems = () => {
  folderMenuPersistedBus.emit("menu:persisted", true);

  insightTrack(RoomDataroomBulkEvent.Remove);

  DrStore.dispatch("room/documents/archiveItems", {
    folders: selectedItems.value.filter(isFolder),
    documents: selectedItems.value.filter(isDocument),
  } satisfies FolderMenuParams).finally(() => {
    folderMenuPersistedBus.emit("menu:persisted", false);
  });
};

const restoreItems = () => {
  folderMenuPersistedBus.emit("menu:persisted", true);

  insightTrack(RoomDataroomBulkEvent.Restore);

  folderMenu
    .restoreItems({
      folders: selectedItems.value.filter(isFolder),
      documents: selectedItems.value.filter(isDocument),
    })
    .finally(() => {
      folderMenuPersistedBus.emit("menu:persisted", false);
    });
};

const deletePermanentlyItems = () => {
  folderMenuPersistedBus.emit("menu:persisted", true);

  insightTrack(RoomDataroomBulkEvent.DeletePermanently);

  folderMenu
    .deleteItems({
      folders: selectedItems.value.filter(isFolder),
      documents: selectedItems.value.filter(isDocument),
    })
    .finally(() => {
      folderMenuPersistedBus.emit("menu:persisted", false);
    });
};

const clearSelected = () => {
  props.items.forEach((item) => {
    item._isChecked = false;
  });

  emit("checkbox-all");

  folderBulkMenuBus.emit("bulk:clear-selected");
};

const exportIndexesPdf = () => {
  if (!props.parentFolder) return;

  insightTrack(RoomDataroomBulkEvent.ExportIndexPdf);

  folderMenu.exportIndexesPdf(props.parentFolder);
};

const exportIndexesXlsx = () => {
  if (!props.parentFolder) return;

  insightTrack(RoomDataroomBulkEvent.ExportIndexXlsx);

  folderMenu.exportIndexesXlsx(props.parentFolder);
};
</script>

<style lang="scss" module>
$toolbar-space: 8px;

.iconCopy {
  font-size: 12px;
}

.iconBtn:global(.el-button) {
  width: 32px;
  margin-left: 0;
}

.toolbar {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: $toolbar-space;
}

.toolbarRight {
  display: grid;
  grid-auto-flow: column;
  gap: $toolbar-space;
  justify-self: flex-end;
}
</style>
