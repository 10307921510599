import { ApiService } from "@drVue/api-service";
import { NoteObjectType } from "./types";

import type { DealNote, Note, UserNote } from "./types";

export abstract class NoteApiService<
  NoteType extends Note,
  RelatedObjectIdType extends string | number,
> extends ApiService {
  private readonly type: NoteObjectType;

  protected constructor(type: NoteObjectType) {
    super();
    this.type = type;
  }

  public loadNotes(objectId: number | string): Promise<Note[]> {
    const url = this._getListUrl(objectId);
    return this.get<NoteType[]>(url).then((notes) =>
      notes.map(this._deserialize),
    );
  }

  public createNote(
    objectId: number | string,
    note: Partial<NoteType>,
  ): Promise<NoteType> {
    const url = this._getListUrl(objectId);
    return this.post<NoteType, Partial<NoteType>>(url, note).then(
      this._deserialize,
    );
  }

  public updateNote(note: NoteType): Promise<Note> {
    const url = this._getDetailUrl(note);
    return this.put<NoteType, NoteType>(url, note).then(this._deserialize);
  }

  public deleteNote(note: NoteType) {
    const url = this._getDetailUrl(note);
    return this.delete<any>(url);
  }

  protected abstract getObjectId(note: Partial<NoteType>): RelatedObjectIdType;

  private _getDetailUrl(note: NoteType): string {
    const urlPrefix = `api:client-dashboard:${this.type}-notes`;
    const objectId = this.getObjectId(note);
    return this.Urls[`${urlPrefix}-detail`](objectId, note.id);
  }

  private _getListUrl(objectId: number | string): string {
    const urlPrefix = `api:client-dashboard:${this.type}-notes`;
    return this.Urls[`${urlPrefix}-list`](objectId);
  }

  private _deserialize(note: NoteType): NoteType {
    note.date_added = new Date(note.date_added);
    note.date_updated = new Date(note.date_updated);
    return note;
  }
}

export class DealNoteApiService extends NoteApiService<DealNote, number> {
  constructor() {
    super(NoteObjectType.Deal);
  }
  protected getObjectId(note: Partial<DealNote>): number {
    return note.deal_id as number;
  }
}

export class UserNoteApiService extends NoteApiService<UserNote, string> {
  constructor() {
    super(NoteObjectType.OrgUser);
  }
  protected getObjectId(note: Partial<UserNote>): string {
    return note.user_id as string;
  }
}
