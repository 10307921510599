import { Document } from "flexsearch";

import type { Deal, DealDialogState, Room, RoomGroup } from "./types";

export class DealsState {
  public items: Deal[] = [];
  public index: Document<Deal> = new Document<Deal>({
    document: { id: "uid", index: [] },
  });

  public isLoading: boolean = false;
  public isError: boolean = false;

  public detailsDialog = {
    visible: false,
    tab: "notes",
    dealUid: undefined,
  } as DealDialogState;

  public groups: { [id: string]: RoomGroup } = {};

  // used in angularjs invite modal
  public clientRooms: Room[] = [];
}
