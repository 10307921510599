import { keyBy } from "lodash-es";
import { h } from "vue";
import { TableInlineEditor } from "@shared/ui/table-inline-editor";

import { ROOM_DATA } from "@setups/data";
import {
  insightTrack,
  RoomFindingsCustomFieldsTrackingEvent,
} from "@app/insight";
import { serializeCustomData } from "@drVue/api-service/parse";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import AssigneesCell from "@drVue/components/room/findings/cells/AssigneesCell.vue";
import { drUserTime } from "@drVue/filters/drUserTime";
import { pinia } from "@drVue/store/pinia";
import { useFindingsStore } from "@drVue/store/pinia/room/findings";
import { TableColumnsBase } from "@drVue/TableColumnsBase";
import AddedByCell from "../cells/AddedByCell.vue";
import DragCell from "../cells/DragCell.vue";
import EditorCell from "../cells/EditorCell.vue";
import LikelihoodCell from "../cells/LikelihoodCell.vue";
import SeverityCell from "../cells/SeverityCell.vue";
import { StatusCellEdit, StatusCellView } from "../cells/StatusCell";
import TiesToCell from "../cells/TiesToCell.vue";
import TypeCell from "../cells/TypeCell.vue";

import type { FindingsTableField } from "../types";
import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { DrVxeTableColumn } from "@drVue/components/types";
import type {
  Finding,
  FindingUpdatePayload,
} from "@drVue/store/pinia/room/findings";
import type { CustomViewColumn } from "@setups/types";
import type { Ref } from "vue";

export interface TFindingsTableColumn<D> extends DrVxeTableColumn<D> {
  field: FindingsTableField | "_drag";
}

export default class TableColumns extends TableColumnsBase<Finding> {
  constructor(
    private readonly viewColumns: Ref<CustomViewColumn[]>,
    private readonly customFields: Ref<FieldItem[]>,
    private readonly inArchive: Ref<boolean>,
  ) {
    super();
  }

  public findingsStore = useFindingsStore(pinia);

  private get allowShowCustomFields() {
    return ROOM_DATA.enableFindingCustomFields;
  }

  private get allowEditCustomFields() {
    return ROOM_DATA.enableFindingCustomFields;
  }

  inlineEditor = new TableInlineEditor(
    (changes: FindingUpdatePayload, finding: Finding) => {
      if (changes.custom_data) {
        serializeCustomData(changes.custom_data, this.customFields.value);

        const customFieldKey = Object.keys(changes.custom_data)[0];
        const customFieldInfo = this.customFields.value.find(
          (field) => field.key === customFieldKey,
        );

        if (customFieldInfo) {
          insightTrack(
            RoomFindingsCustomFieldsTrackingEvent.FieldValueChanged,
            {
              source: "table",
              type: customFieldInfo.field_type,
              name: customFieldInfo.label,
            },
          );
        }
      }

      return this.findingsStore.update(finding.id, changes);
    },
    "id",
  );

  getUserColumns(): Record<string, CustomViewColumn> {
    const columns = this.viewColumns.value;
    return columns ? keyBy(columns, "field") : {};
  }

  getTableColumns(): DrVxeTableColumn<Finding>[] {
    const customFieldsColumns = this.allowShowCustomFields
      ? this.customFields.value.map((field) =>
          this.customFieldColumn(
            field,
            !this.allowEditCustomFields || this.inArchive.value,
            true,
          ),
        )
      : [];

    return [
      this.dragColumn(),
      this.keyColumn(),
      this.typeColumn(),
      this.titleColumn(),
      this.statusColumn(),
      this.severityColumn(),
      this.likelihoodColumn(),
      this.tiesToColumn(),
      this.assigneesColumn(),
      this.dateAddedColumn(),
      this.addedByColumn(),
      this.dateResolvedColumn(),
      this.descriptionColumn(),
      this.mitigationPlanColumn(),
      this.actualMitigationColumn(),
      ...(customFieldsColumns as TFindingsTableColumn<Finding>[]),
    ];
  }

  private dragColumn(): DrVxeTableColumn<Finding> {
    return {
      field: "_drag",
      title: "",
      fixed: "left",
      resizable: false,
      width: 25,
      showHeaderOverflow: false,
      className: "dr-vxe-column--centered",
      headerClassName: "dr-vxe-column--centered",
      slots: {
        default: ({ $table }) => {
          const isSorted = $table.getSortColumns().length > 0;
          return h(DragCell, { isHidden: false, isDisabled: isSorted });
        },
      },
    };
  }

  private keyColumn(): TFindingsTableColumn<Finding> {
    return {
      field: "key",
      title: "ID",
      minWidth: 45,
      width: 60,
      sortable: true,
    };
  }

  private titleColumn(): TFindingsTableColumn<Finding> {
    return {
      field: "title",
      title: "Title",
      width: 400,
      sortable: true,
    };
  }

  private statusColumn(): TFindingsTableColumn<Finding> {
    return {
      field: "status_id",
      title: "Status",
      minWidth: 45,
      width: 115,
      sortable: true,
      slots: {
        default: (params) => {
          return this.inlineEditor.renderInlineField(
            {
              isReadOnly: params.row.is_archived,
              type: FieldSchemaType.Custom,
              prop: "status_id",
              label: "Status",
              optimistic: true,
              editComponent: StatusCellEdit,
              viewComponent: StatusCellView,
            },
            params,
          );
        },
      },
    };
  }

  private typeColumn(): TFindingsTableColumn<Finding> {
    return {
      field: "type_id",
      title: "Type",
      minWidth: 45,
      width: 115,
      sortable: true,
      slots: {
        default: ({ row }) => h(TypeCell, { typeUid: row.type_id }),
      },
    };
  }

  private severityColumn(): TFindingsTableColumn<Finding> {
    return {
      field: "severity",
      title: "Severity",
      minWidth: 45,
      width: 115,
      sortable: true,
      slots: {
        default: ({ row }) => h(SeverityCell, { severity: row.severity }),
      },
    };
  }

  private likelihoodColumn(): TFindingsTableColumn<Finding> {
    return {
      field: "likelihood",
      title: "Likelihood",
      minWidth: 45,
      width: 115,
      sortable: true,
      slots: {
        default: ({ row }) => h(LikelihoodCell, { likelihood: row.likelihood }),
      },
    };
  }

  private tiesToColumn(): TFindingsTableColumn<Finding> {
    return {
      field: "ties_to",
      title: "Related items",
      minWidth: 5,
      width: 260,
      sortable: true,
      slots: {
        default: ({ row }) =>
          h(TiesToCell, {
            categoryUids: row.categories,
            taskUids: row.tasks,
          }),
      },
    };
  }

  private descriptionColumn(): TFindingsTableColumn<Finding> {
    return {
      field: "description",
      title: "Description",
      width: 220,
      sortable: true,
      slots: {
        default: ({ row }) => h(EditorCell, { body: row.description }),
      },
    };
  }

  private mitigationPlanColumn(): TFindingsTableColumn<Finding> {
    return {
      field: "mitigation_plan",
      title: "Mitigation plan",
      width: 220,
      sortable: true,
      slots: {
        default: ({ row }) => h(EditorCell, { body: row.mitigation_plan }),
      },
    };
  }

  private actualMitigationColumn(): TFindingsTableColumn<Finding> {
    return {
      field: "actual_mitigation",
      title: "Actual mitigation",
      width: 220,
      sortable: true,
      slots: {
        default: ({ row }) => h(EditorCell, { body: row.actual_mitigation }),
      },
    };
  }

  private dateAddedColumn(): TFindingsTableColumn<Finding> {
    return {
      field: "date_added",
      title: "Date identified",
      width: 120,
      sortable: true,
      slots: {
        default: ({ row }) => drUserTime(row.date_added, "short-date") ?? "",
      },
    };
  }

  private dateResolvedColumn(): TFindingsTableColumn<Finding> {
    return {
      field: "date_resolved",
      title: "Date resolved",
      width: 120,
      sortable: true,
      slots: {
        default: ({ row }) => drUserTime(row.date_resolved, "short-date") ?? "",
      },
    };
  }

  private addedByColumn(): TFindingsTableColumn<Finding> {
    return {
      field: "added_by_id",
      title: "Identified by",
      width: 120,
      sortable: true,
      showOverflow: "ellipsis",
      slots: {
        default: ({ row }) => h(AddedByCell, { userUid: row.added_by_id }),
      },
    };
  }

  private assigneesColumn(): TFindingsTableColumn<Finding> {
    return {
      field: "assignees",
      title: "Assignees",
      width: 156,
      sortable: true,
      showOverflow: "ellipsis",
      slots: {
        default: ({ row }) => h(AssigneesCell, { assigneeUids: row.assignees }),
      },
    };
  }
}
