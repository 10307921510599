<template>
  <div class="dash-content-panel__close" @click="$emit('close')">
    <div class="dash-content-panel__close-dimmer" />
    <button class="dash-content-panel__close-button">
      <i class="fa fa-remove" />
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ContentPanelClose",
  emits: ["close"],
});
</script>
