<template>
  <DrMainMenu
    :user="menuUser"
    :items="menuItems"
    :rooms="menuRooms"
    :organizations="menuOrganizations"
    :billing-info="billingInfo"
    :website-logo="websiteLogo"
    :website-name="websiteName"
    :is-expanded="isMenuExpanded"
    :allow-room-create="allowCreateRoom"
    @select-item="handleSelectMenu"
    @select-room="handleSelectRoom"
    @select-organization="handleSelectOrganization"
    @billing-activate="handleBillingActivate"
    @expand="handleExpandMenu(true)"
    @collapse="handleExpandMenu(false)"
    @add-room="handleAddRoom"
  />

  <AccountSettings ref="refUserSettingsPanel" />
  <DrHelpModal ref="refHelpModal" />
  <RoomCreateModal v-if="allowCreateRoom" ref="refRoomCreateModal" />
</template>

<script setup lang="ts">
import Cookies from "js-cookie";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import DrHelpModal from "@shared/ui/dr-help";
import DrMainMenu from "@shared/ui/dr-main-menu";
import { useBrowserLocation } from "@vueuse/core";

import {
  APP_SETTINGS,
  CLIENT_DASHBOARD_DATA,
  ORG_MEMBER_DATA,
  ROOM_DATA,
  USER_DATA,
} from "@setups/data";
import { Urls } from "@setups/urls";
import { http } from "@drVue/api-service/http";
import { isBillingVisible } from "@drVue/components/client-dashboard/billing/utils";
import RoomCreateModal from "@drVue/components/client-dashboard/rooms/RoomCreateModal.vue";
import AccountSettings from "@drVue/components/client-dashboard/users/AccountSettings/AccountSettings.vue";
import { Tabs } from "@drVue/components/client-dashboard/users/AccountSettings/types";
import { useOrgStore } from "@drVue/store/modules/common/organization/store";
import { FeedbackApiService } from "../../api-service/common/feedback";
import {
  IS_PIPELINE_ENABLED,
  IS_PIPELINE_OPEN,
  NON_ROOM_LOCATIONS,
  useIsRoomLocation,
} from "./utils";

import type {
  MainMenuBillingInfo,
  MainMenuItem,
  MainMenuOrganization,
  MainMenuRoom,
  MainMenuUser,
} from "@shared/ui/dr-main-menu/types";

const props = withDefaults(
  defineProps<{
    roomsRestricted?: boolean;
  }>(),
  {
    roomsRestricted: false,
  },
);

const orgStore = useOrgStore();

const MAIN_MENU_EXPANDED_STATE_KEY = "dr:is_main_menu_expanded";
const IS_ROOM_OPEN = ROOM_DATA.id !== undefined;
const IS_DEALROOM = APP_SETTINGS.WEBSITE.IS_DEALROOM;

const $router = IS_PIPELINE_OPEN ? useRouter() : null;
const $route = IS_PIPELINE_OPEN ? useRoute() : null;
const $location = useBrowserLocation();
const isMenuExpanded = Cookies.get(MAIN_MENU_EXPANDED_STATE_KEY) === "true";

const currentClient = ORG_MEMBER_DATA?.client.id || ROOM_DATA?.client_id;
const allowCreateRoom =
  !IS_PIPELINE_ENABLED && ORG_MEMBER_DATA?.group?.can_create_rooms;

// template refs
const refUserSettingsPanel = ref<InstanceType<typeof AccountSettings> | null>(
  null,
);
const refHelpModal = ref<InstanceType<typeof DrHelpModal> | null>(null);
const refRoomCreateModal = ref<InstanceType<typeof RoomCreateModal> | null>(
  null,
);

// data
const websiteLogo = APP_SETTINGS.WEBSITE.LOGO_SVG;
const websiteName = APP_SETTINGS.WEBSITE.NAME;
const menuUser: MainMenuUser = {
  id: USER_DATA.username,
  name: USER_DATA.profile.name,
  avatar: USER_DATA.profile.avatar.thumbnail || "",
};
const billingInfo: MainMenuBillingInfo = {
  isAccessible: IS_ROOM_OPEN
    ? ROOM_DATA.is_paid
    : IS_PIPELINE_OPEN
      ? CLIENT_DASHBOARD_DATA.is_paid
      : true,
  isRoomBilling: IS_ROOM_OPEN,
  billingObjectId: IS_ROOM_OPEN
    ? ROOM_DATA.id
    : ORG_MEMBER_DATA
      ? ORG_MEMBER_DATA.client.id
      : null,
  hasBillingAccess: !!ORG_MEMBER_DATA?.has_billing_access,
  supportEmail: APP_SETTINGS.CONTACT_EMAIL,
  subscriptionWarning:
    ROOM_DATA?.subscriptionWarning ||
    CLIENT_DASHBOARD_DATA?.subscriptionWarning ||
    null,
};
const menuOrganizations: MainMenuOrganization[] = USER_DATA.organizations.map(
  (org) => ({
    id: `${org.id}`,
    name: org.public_name,
    isActive: org.id === currentClient,
    href: org.url,
  }),
);

// computed
const menuItems = computed(() => {
  const currentPath = $route
    ? $route.path
    : $location.value.hash?.slice(1) || "";
  const items: MainMenuItem[] = [];

  if (IS_PIPELINE_ENABLED) {
    items.push({
      id: "deals",
      name: "Pipeline",
      icon: "layer-group",
      place: "top",
      isActive: currentPath.startsWith("/deals"),
      isBeta: false,
      href: `${ORG_MEMBER_DATA.client.url}#/deals`,
    });
  }

  if (IS_DEALROOM && (IS_ROOM_OPEN || IS_PIPELINE_OPEN)) {
    if (IS_PIPELINE_ENABLED) {
      items.push({
        id: "your-work",
        name: "Your work",
        icon: "list",
        place: "top",
        isActive: currentPath.startsWith("/your-work"),
        isBeta: false,
        href: `${ORG_MEMBER_DATA.client.url}#/your-work`,
      });
    } else {
      items.push({
        id: "your-work",
        name: "Your work",
        icon: "list",
        place: "top",
        isActive: currentPath.startsWith("/your-work"),
        isBeta: false,
      });
    }
  }

  if (IS_PIPELINE_ENABLED) {
    items.push({
      id: "people",
      name: "People",
      icon: "users",
      place: "top",
      isActive: currentPath.startsWith("/people"),
      isBeta: false,
      href: `${ORG_MEMBER_DATA.client.url}#/people`,
    });

    items.push({
      id: "reports",
      name: "Reports",
      icon: "poll",
      place: "top",
      isActive: currentPath.startsWith("/reports"),
      isBeta: false,
      href: `${ORG_MEMBER_DATA.client.url}#/reports`,
    });
  }
  if (
    ORG_MEMBER_DATA?.group?.is_administrator ||
    (IS_PIPELINE_ENABLED && isBillingVisible())
  ) {
    const roomOrgSettingsPath = "/org-settings";
    const pipelineSettingsPath = "/settings";

    const itemId = IS_PIPELINE_ENABLED ? "settings" : "org-settings";
    const isActive = IS_PIPELINE_ENABLED
      ? currentPath.startsWith(pipelineSettingsPath) && !IS_ROOM_OPEN
      : currentPath.startsWith(roomOrgSettingsPath);
    const href = IS_PIPELINE_ENABLED
      ? `${ORG_MEMBER_DATA.client.url}#${pipelineSettingsPath}`
      : `#${roomOrgSettingsPath}`;
    items.push({
      id: itemId,
      name: "Settings",
      icon: "cogwheel",
      place: "top",
      isActive: isActive,
      isBeta: false,
      href: href,
    });
  }

  items.push({
    id: "notifications",
    name: "Notifications",
    icon: "bell",
    place: "top",
    isActive: currentPath.startsWith("/notifications"),
    isBeta: false,
  });

  if (USER_DATA.is_staff) {
    items.push({
      id: "management",
      name: "Management",
      icon: "sliders-h",
      place: "bottom",
      isActive: false,
      isBeta: false,
    });
  }

  items.push({
    id: "help",
    name: "Help",
    icon: "info-circle",
    place: "bottom",
    isActive: false,
    isBeta: false,
  });

  items.push({
    id: "account_settings",
    name: "User settings",
    icon: "user",
    place: "account",
    isActive: false,
    isBeta: false,
  });

  items.push({
    id: "email_notifications",
    name: "Email notifications",
    icon: "envelope",
    place: "account",
    isActive: false,
    isBeta: false,
  });

  if (showPrivateAPIDoc) {
    items.push({
      id: "private_api_doc",
      name: "Private API doc",
      icon: "layer-group",
      place: "account",
      isActive: false,
      isBeta: false,
    });
  }

  items.push({
    id: "logout",
    name: "Log out",
    icon: "sign-out-alt",
    place: "account",
    isActive: false,
    isBeta: false,
  });

  return items;
});

const showPrivateAPIDoc = APP_SETTINGS.DEBUG;

const handleExpandMenu = (isExpanded: boolean) => {
  Cookies.set(MAIN_MENU_EXPANDED_STATE_KEY, `${isExpanded}`, {
    domain: APP_SETTINGS.WEBSITE.HOST,
    expires: 365,
  });
};

const routes = [
  "your-work",
  "deals",
  "people",
  "reports",
  "settings",
  "notifications",
  "org-settings",
];
const handleSelectMenu = (itemId: string, newTab: boolean) => {
  if (routes.includes(itemId)) {
    if ($router) {
      newTab ? window.open(`#/${itemId}`) : $router.push(`/${itemId}`);
    } else {
      if (NON_ROOM_LOCATIONS.includes(itemId)) {
        newTab ? window.open(`#/${itemId}`) : (document.location.hash = itemId);
        return;
      }
      window.open(
        `${ORG_MEMBER_DATA.client.url}#/${itemId}`,
        newTab ? "_blank" : "_self",
      );
    }
  }

  if (itemId === "logout") {
    http.post(Urls["api:auth:logout"]()).then(() => window.location.reload());
  }

  if (itemId === "help") {
    refHelpModal.value!.open();
  }

  if (itemId === "account_settings") {
    refUserSettingsPanel.value!.open();
  }

  if (itemId === "email_notifications") {
    refUserSettingsPanel.value!.open(Tabs.EmailNotifications);
  }

  if (itemId === "management") {
    window.open(Urls["management"](), "_blank");
  }

  if (itemId === "private_api_doc") {
    window.open(`${location.origin}/schema/private-api/swagger-ui/`, "_blank");
  }
};

const handleSelectRoom = (roomId: string) => {
  const targetRoom = menuRooms.value.find((room) => room.id === roomId);
  if (targetRoom) {
    window.open(targetRoom.url, "_self");
  }
};
const handleSelectOrganization = (organizationId: string) => {
  const targetOrganization = menuOrganizations.find(
    (org) => org.id === organizationId,
  );
  if (targetOrganization) {
    window.open(targetOrganization.href, "_self");
  }
};

const handleBillingActivate = () => {
  if (billingInfo.hasBillingAccess) {
    location.hash = "#/settings/billing";
  } else {
    new FeedbackApiService().activate();
  }
};

const handleAddRoom = () => {
  refRoomCreateModal.value!.open();
};

const orgRooms = computed(() => orgStore.rooms);

const { isRoomLocation } = useIsRoomLocation();
const menuRooms = computed<MainMenuRoom[]>(() => {
  return orgRooms.value.map((room) => ({
    id: `${room.id}`,
    name: room.title,
    logo: room.logo || "",
    isActive: room.id === ROOM_DATA.id && isRoomLocation.value,
    url: `${room.url}/`,
  }));
});

// hooks, fetch data,...
if (!props.roomsRestricted) {
  orgStore.getRooms();
}
</script>
