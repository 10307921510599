import tasksHtml from "./index.html?raw";

angular
  .module("dealroom.analytics.datagrid-table.tasks.activity", [])
  .component("drTasksActivityDatagrid", {
    bindings: { cls: "@" },
    template: tasksHtml,
    controller: RequestsActivityDatagrid,
  });

const CELLS = [
  {
    label: "Time (min)",
    class: "datagrid-table__column--data-timespent",
    verbs: ["task_viewed"],
    src: "time",
    getDisplayedValue: Math.round,
  },
  {
    label: "Users Viewed",
    class: "datagrid-table__column--data",
    verbs: ["task_viewed"],
    src: "uniq:user",
  },
  {
    label: "Requests Views",
    class: "datagrid-table__column--data-lg",
    verbs: ["task_viewed"],
    src: "total",
  },
];

function RequestsActivityDatagrid() {
  const $ctrl = this;

  $ctrl.cells = CELLS;

  $ctrl.nestByArray = ["category", "task"];
  $ctrl.tableUid = "RequestsActivity";

  $ctrl.verbsForFilter = true;
  $ctrl.subParentFieldName = "categories";
}
