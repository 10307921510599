<template>
  <CreateRequestPanel ref="createRequestPanelRef" />
  <ElDropdown
    trigger="click"
    @command="(command) => handleMenuActions(command)"
  >
    <ElLink type="primary" :underline="false">
      Link to request
      <ElIcon class="el-icon--right">
        <i class="fas fa-plus-circle" />
      </ElIcon>
    </ElLink>
    <template #dropdown>
      <ElDropdownMenu>
        <ElDropdownItem command="attachToTask">
          Link to existing request
        </ElDropdownItem>
        <ElDropdownItem v-if="canCreateTasks" command="createNewTask">
          Link to new request
        </ElDropdownItem>
      </ElDropdownMenu>
    </template>
  </ElDropdown>
</template>

<script setup lang="ts">
import { ref } from "vue";

import { TasksServiceProxy } from "@app/ng/serviceProxies";
import { ROOM_DATA } from "@app/setups";
import CreateRequestPanel from "@drVue/components/room/tasks/TaskOverviewPage/CreateRequestPanel.vue";

const canCreateTasks = !!ROOM_DATA?.userPermissions?.canCreateTasks;

const props = defineProps<{ documentId: number }>();

const createRequestPanelRef = ref<InstanceType<
  typeof CreateRequestPanel
> | null>(null);

const tasksService = new TasksServiceProxy();

function createNewTask() {
  createRequestPanelRef.value?.open({
    attachDocumentIds: [props.documentId],
  });
}

function attachToTask() {
  tasksService.showAttachToTaskDialog(undefined, props.documentId);
}

function handleMenuActions(command: string) {
  if (command === "createNewTask") {
    createNewTask();
  } else if (command === "attachToTask") {
    attachToTask();
  }
}
</script>
