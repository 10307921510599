<template>
  <DrLayoutContentCenteredSection title="Storage Usage">
    <ElButton plain type="primary" icon-right @click="showModal">
      View storage usage
      <template #icon>
        <DrIcon size="xs" name="eye" />
      </template>
    </ElButton>
    <ElDialog
      v-model="isModalVisible"
      width="800px"
      append-to-body
      @close="closeModal"
    >
      <template #header>
        <h4>Storage Usage</h4>
        <span>Storage information is updated once a day. </span>
      </template>
      <DrVxeGrid
        outlined
        :data="tableData"
        :columns="columns"
        :loading="isLoading"
        :sort-config="{ trigger: 'cell', defaultSort }"
        :footer-method="footerData"
        show-footer
        empty-text="No data"
        :max-height="500"
      />
      <br />
      <div class="text-right">
        <ElButton type="primary" @click="exportData"> Export </ElButton>
      </div>
    </ElDialog>
  </DrLayoutContentCenteredSection>
</template>

<script lang="ts" setup>
import { sumBy } from "lodash-es";
import { ref } from "vue";
import DrIcon from "@shared/ui/dr-icon";
import { DrLayoutContentCenteredSection } from "@shared/ui/dr-layouts";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import { formatBytes } from "@app/common/formatters";
import { ClientSettingsApiService } from "@drVue/api-service/client-dashboard/settings";
import { $notifyDanger } from "@drVue/common";
import { ExcelReport, exportToExcel } from "@drVue/store/modules/reporting";

import type { RoomStorageUsage } from "@drVue/api-service/client-dashboard/settings";
import type { DrVxeTableColumn } from "@drVue/components/types";
import type { Column, ReportItem } from "@drVue/store/modules/reporting";
import type { VxeTablePropTypes } from "vxe-table";

const isLoading = ref(false);
const isModalVisible = ref(false);
const tableData = ref<RoomStorageUsage[]>([]);

const defaultSort: VxeTablePropTypes.SortConfigDefaultSort = {
  field: "total_storage",
  order: "desc",
};

const formatRoomName = (roomName: string | null) =>
  roomName === null ? "[Unknown]" : roomName;

const formatIsArchived = (isArchived: boolean | null) => {
  if (isArchived === null) {
    return "-";
  }
  return isArchived ? "Yes" : "No";
};

const columns: DrVxeTableColumn<RoomStorageUsage>[] = [
  {
    sortable: true,
    field: "room_name",
    title: "Room",
    showOverflow: "tooltip",
    formatter: ({ cellValue }: { cellValue: string }) =>
      formatRoomName(cellValue),
  },
  {
    sortable: true,
    field: "room_is_archived",
    title: "Is Archived",
    showOverflow: "tooltip",
    width: 100,
    formatter: ({ cellValue }: { cellValue: boolean }) =>
      formatIsArchived(cellValue),
  },
  {
    sortable: true,
    field: "active_storage",
    sortBy: "active_storage",
    title: "Active data",
    width: 150,
    formatter: ({ cellValue }: { cellValue: number }) =>
      formatBytes(cellValue, 2),
  },
  {
    sortable: true,
    field: "archived_storage",
    sortBy: "archived_storage",
    title: "Archived data",
    width: 150,
    formatter: ({ cellValue }: { cellValue: number }) =>
      formatBytes(cellValue, 2),
  },
  {
    sortable: true,
    field: "total_storage",
    sortBy: "total_storage",
    title: "Total data",
    width: 150,
    formatter: ({ cellValue }: { cellValue: number }) =>
      formatBytes(cellValue, 2),
  },
];

const exportData = () => {
  const report = new StorageExcelReport();
  exportToExcel(report);
};

const footerData: VxeTablePropTypes.FooterMethod<RoomStorageUsage> = ({
  data,
}) => {
  const archivedCount = sumBy(data, "room_is_archived") + " archived";
  const totalActive = sumBy(data, "active_storage");
  const totalArchived = sumBy(data, "archived_storage");
  const total = sumBy(data, "total_storage");
  return [
    [
      "Total",
      archivedCount,
      formatBytes(totalActive, 2),
      formatBytes(totalArchived, 2),
      formatBytes(total, 2),
    ],
  ];
};

class StorageExcelReport extends ExcelReport {
  constructor() {
    super("storage_usage.xlsx", "Rooms storage usage");
  }
  private get_column_title(col: DrVxeTableColumn<RoomStorageUsage>): string {
    return (col.field as string)!.endsWith("_storage")
      ? `${col.title} (bytes)`
      : (col.title as string);
  }

  get columns(): Column[] {
    return columns.map((col) => ({
      title: this.get_column_title(col),
      data_index: col.field,
      width: 24,
    }));
  }

  get items(): ReportItem[] {
    return tableData.value.map((item) => ({
      ...item,
      room_name: formatRoomName(item.room_name),
      room_is_archived: formatIsArchived(item.room_is_archived),
    }));
  }
}

const loadData = () => {
  if (isLoading.value) {
    return;
  }
  isLoading.value = true;
  const api = new ClientSettingsApiService();
  api
    .getStorageUsage()
    .then((storageUsage) => {
      tableData.value = storageUsage;
    })
    .catch(() => {
      $notifyDanger("Failed to load storage usage");
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const showModal = () => {
  isModalVisible.value = true;
  loadData();
};
const closeModal = () => {
  isModalVisible.value = false;
};
</script>
