<template>
  <ElSelect
    ref="selectRef"
    :model-value="editProps.value"
    :size="size!"
    :class="$style.selectContainer"
    @visible-change="handleVisibleChange"
    @change="handleSubmit"
  >
    <template #prefix>
      <DrIconPriority size="sm" :priority="editProps.value" />
    </template>
    <ElOption
      v-for="priority in PriorityValues"
      :key="priority"
      :value="priority"
      :class="$style.optionContainer"
    >
      <DrIconPriority size="sm" :priority="priority" />
      <span :class="$style.optionLabel">{{ capitalize(priority) }}</span>
    </ElOption>
  </ElSelect>
</template>

<script setup lang="ts">
import { ElOption, ElSelect } from "element-plus"; // In timeline el-components are required to be explicitly imported
import { capitalize } from "lodash-es";
import { nextTick, onMounted, ref } from "vue";
import DrIconPriority, { PriorityValues } from "@shared/ui/dr-icon-priority";

import { $notifyDanger } from "@drVue/common";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { Task } from "@drVue/store/pinia/room/tasks";
import type { PriorityValue } from "@shared/ui/dr-icon-priority";

interface Props {
  editProps: EditFieldProps<PriorityValue, Task>;
  size?: "default" | "small" | "large";
}

const props = withDefaults(defineProps<Props>(), { size: "default" });

interface Emits {
  (e: "change"): void;
}

const emit = defineEmits<Emits>();

const handleVisibleChange = (visible: boolean): void => {
  if (visible) return;
  props.editProps.quitEditMode();
};

// value is a string since it is capitalized
const handleSubmit = (value: string): void => {
  try {
    props.editProps.veeField.onChange(value);
    props.editProps.submitField();

    emit("change");
  } catch {
    $notifyDanger("Something went wrong while modifying priority");
  }
};

const selectRef = ref<InstanceType<typeof ElSelect> | null>(null);
onMounted(() => {
  selectRef.value?.toggleMenu();

  nextTick(() => {
    // Un-focusing inner input, so text overflow style apply
    selectRef.value?.$el.querySelector("input").blur();
  });
});
</script>

<style module lang="scss">
@use "@app/styles/scss/typography";

.selectContainer {
  width: 100%;

  :global(.el-input__prefix-inner > :last-child) {
    margin-right: 6px;
  }

  :global(.el-input__wrapper) {
    padding: 1px 7px;
  }

  :global(.el-input__inner) {
    text-overflow: ellipsis;
  }
}

.optionContainer {
  align-items: center;
  display: flex;
  gap: 8px;
}

.optionLabel {
  font-weight: normal; // Select options are bold by default
}
</style>
