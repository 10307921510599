<template>
  <div
    :class="{
      [$style.panel]: true,
      [$style.panel_hasRight]: !!$slots.right,
    }"
  >
    <ElScrollbar
      max-height="100%"
      :wrap-class="$style.left"
      :view-class="$style.content"
    >
      <slot name="left" />
    </ElScrollbar>

    <ElScrollbar
      v-if="!!$slots.right"
      view-style="height: 100%"
      max-height="100%"
      :view-class="$style.content"
    >
      <slot name="right" />
    </ElScrollbar>
  </div>
</template>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;

$left-width-min: 758px;
$left-width-max: 950px;
$right-width: 300px;

.panel {
  height: 100%;
  min-width: $left-width-min;
  width: 75vw;
  max-width: $left-width-max;
  display: grid;
  grid-template-columns: 1fr;
  align-items: stretch;
  overflow: hidden;
  background-color: #fff;
}

.content {
  padding: spacing.$m;
}

.panel_hasRight {
  min-width: $left-width-min + $right-width;
  max-width: $left-width-max + $right-width;
  grid-template-columns: 1fr $right-width;
}

.left .content {
  padding: spacing.$m spacing.$xl;
}

.panel_hasRight .left {
  border-right: 1px solid colors.$pr-150;
}
</style>
