import { debounce } from "lodash-es";
import { orderBy } from "lodash-es";
import { computed, ref, watch } from "vue";

import { pinia } from "@drVue/store/pinia";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import { TasksApiService } from "@drVue/store/pinia/room/tasks/api";

import type {
  TaskActivity,
  TaskComment,
} from "@drVue/store/modules/room/types";
import type { ComputedRef } from "vue";

type TaskCommentAttachments = Pick<TaskComment, "folders" | "documents">;

type TaskCommentCreatePayload = Pick<
  TaskComment,
  "body" | "viewers" | "is_public"
> &
  Partial<TaskCommentAttachments>;

type TaskCommentUpdatePayload = Pick<
  TaskComment,
  "body" | "viewers" | "is_public"
> &
  Partial<TaskCommentAttachments>;

export type {
  TaskActivity,
  TaskComment,
  TaskCommentCreatePayload,
  TaskCommentUpdatePayload,
};

const tasksApi = new TasksApiService();

export const useTaskComments = (
  taskId: ComputedRef<TaskComment["task_id"]>,
) => {
  const tasksStore = useTasksStore(pinia);

  const isActivityShown = ref(false);

  const listComments = ref<TaskComment[]>([]);
  const isCommentsLoading = ref<boolean>(false);
  const isCommentsLoadError = ref<boolean>(false);

  const listActivities = ref<TaskActivity[]>([]);
  const isActivitiesLoading = ref<boolean>(false);
  const isActivitiesLoadError = ref<boolean>(false);

  const load = async () => {
    isCommentsLoading.value = true;
    isCommentsLoadError.value = false;

    try {
      listComments.value = await tasksApi.loadComments(taskId.value);

      if (isActivityShown.value) {
        await loadActivity();
      } else if (listActivities.value.length) {
        // сlearing the activity data loaded for the previous taskId value
        listActivities.value = [];
      }

      isCommentsLoading.value = false;
      return listComments.value;
    } catch (err) {
      isCommentsLoading.value = false;
      isCommentsLoadError.value = true;
      throw err;
    }
  };

  const showActivity = (show: boolean) => {
    isActivityShown.value = show;
  };

  const loadActivity = async () => {
    isActivitiesLoading.value = true;
    isActivitiesLoadError.value = false;

    try {
      listActivities.value = await tasksApi.loadActivity(taskId.value);
      isActivitiesLoading.value = false;
      return listActivities.value;
    } catch (err) {
      isActivitiesLoading.value = false;
      isActivitiesLoadError.value = true;
      throw err;
    }
  };

  const debouncedLoadActivity = debounce(loadActivity, 300);

  const localUpdate = (
    id: TaskComment["id"],
    payload: Partial<TaskComment>,
  ) => {
    const i = listComments.value.findIndex((item) => item.id === id);
    if (i > -1)
      listComments.value.splice(i, 1, { ...listComments.value[i], ...payload });
  };

  const create = async (payload: TaskCommentCreatePayload) => {
    const createdItem = await tasksStore.addComment(taskId.value, {
      task_id: taskId.value, // @todo check if this is still needed
      ...payload,
    });
    listComments.value.push(createdItem);

    /** Update task's comments_count, followers, etc. */
    await tasksStore.reloadTaskById(taskId.value);

    return createdItem;
  };

  const approve = async (
    id: TaskComment["id"],
    userId: NonNullable<TaskComment["approved_by"]>,
  ) => {
    return tasksApi
      .approveComment(id, taskId.value, userId)
      .then((approvedItem) => {
        localUpdate(id, approvedItem);
        return approvedItem;
      });
  };

  const update = async (
    id: TaskComment["id"],
    payload: TaskCommentUpdatePayload,
  ) => {
    const updatedItem = await tasksApi.updateComment(id, taskId.value, {
      task_id: taskId.value, // @todo check if this is still needed
      ...payload,
    });

    localUpdate(id, updatedItem);

    /** Update task's comments_count, followers, etc. */
    await tasksStore.reloadTaskById(taskId.value);

    return updatedItem;
  };

  const remove = async (id: TaskComment["id"]) => {
    await tasksApi.deleteComment(id, taskId.value);
    const i = listComments.value.findIndex((item) => item.id === id);
    if (i > -1) listComments.value.splice(i, 1);

    /** Update task's comments_count, followers, etc. */
    await tasksStore.reloadTaskById(taskId.value);
  };

  watch(taskId, load, { immediate: true });
  watch(
    () => isActivityShown.value,
    (value) => {
      if (value && !listActivities.value.length) loadActivity();
    },
    { immediate: true },
  );

  const setActivityRefreshTrigger = (trigger: ComputedRef<any>) =>
    watch(
      () => trigger.value,
      (value) => {
        // any truly value is allowed
        if (value) debouncedLoadActivity();
      },
      { deep: true },
    );

  const list = computed<(TaskComment | TaskActivity)[]>(() => {
    if (isActivityShown.value) {
      return orderBy(
        [...listComments.value, ...listActivities.value],
        [
          (item) => {
            if ("timestamp" in item) {
              return Number(item.timestamp);
            }

            return Number(item.date_added);
          },
        ],
        ["desc"],
      );
    }

    return listComments.value;
  });

  const isLoading = computed(
    () => isCommentsLoading.value || isActivitiesLoading.value,
  );
  const isLoadError = computed(
    () => isCommentsLoadError.value || isActivitiesLoadError.value,
  );

  return {
    list,
    isLoading,
    isLoadError,
    load,
    loadActivity,
    create,
    approve,
    update,
    remove,
    showActivity,
    isCommentsLoadError,
    isActivitiesLoadError,
    setActivityRefreshTrigger,
  };
};
