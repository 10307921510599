import { Urls } from "@setups/urls";
import { postAndGetFile } from "@drVue/common";
import { FieldItemTypes } from "@drVue/store/modules/client-dashboard/fields/types";

import type { FieldExtraByType } from "@drVue/api-service/client-dashboard/types";
import type { FieldItem } from "@drVue/store/modules/client-dashboard/fields/types";

export interface Column {
  title: string;
  data_index: string;
  width: number;
  number_format?: FieldExtraByType[FieldItemTypes.Number];
}

export type ReportItem = { [data_index: string]: any };

export type IDict = { [key: string]: any };

export function exportFieldValueToCell(field: FieldItem, value: any) {
  value = value || null;
  if (value && field.field_type === FieldItemTypes.MultiSelect) {
    value = value.join("\n");
  }
  if (value && field.field_type === FieldItemTypes.Date) {
    value = { type: "date", value };
  }
  return value;
}

export abstract class ExcelReport {
  filename: string;
  sheetname: string;

  protected constructor(filename: string, sheetname: string) {
    this.filename = filename;
    this.sheetname = sheetname;
  }

  abstract get columns(): Column[];
  abstract get items(): ReportItem[];

  protected makeCustomColumns(fields: FieldItem[]): Column[] {
    return fields.map((f) => ({
      data_index: `extra-${f.key}`,
      title: f.label,
      width: f.field_type === FieldItemTypes.Text ? 24 : 16,
    }));
  }

  protected formatCustomData(fields: FieldItem[], customData: IDict): IDict {
    return fields.reduce((bucket: ReportItem, f: FieldItem) => {
      const value = customData && customData[f.key];
      bucket[`extra-${f.key}`] = exportFieldValueToCell(f, value);

      return bucket;
    }, {});
  }
}

export function exportToExcel(report: ExcelReport) {
  const url = Urls["api:export_xlsx"]();
  postAndGetFile(url, {
    filename: report.filename,
    sheetname: report.sheetname,
    columns: report.columns,
    items: report.items,
  });
}
