import { tasksStatusesActions } from "@drVue/store/modules/room/tasks-statuses/TasksStatusesActions";
import { tasksStatusesGetters } from "./TasksStatusesGetters";
import { TasksStatusesState } from "./TasksStatusesState";
import { tasksStatusesMutations } from "./TaskStatusesMutations";

import type { RootState } from "@drVue/store/state";
import type { Module } from "vuex";

export { TasksStatusesState } from "./TasksStatusesState";

export const TasksStatusesModule: Module<TasksStatusesState, RootState> = {
  namespaced: true,
  state: new TasksStatusesState(),

  actions: tasksStatusesActions,
  getters: tasksStatusesGetters,
  mutations: tasksStatusesMutations,
};
