<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1875 3.9646H8.9375L7.1875 1.9646H2.8125C2.4644 1.9646 2.13056 2.12263 1.88442 2.40394C1.63828 2.68524 1.5 3.06677 1.5 3.4646L1.5 12.4646C1.5 12.8624 1.63828 13.244 1.88442 13.5253C2.13056 13.8066 2.4644 13.9646 2.8125 13.9646H14.1875C14.5356 13.9646 14.8694 13.8066 15.1156 13.5253C15.3617 13.244 15.5 12.8624 15.5 12.4646V5.4646C15.5 5.06677 15.3617 4.68524 15.1156 4.40394C14.8694 4.12263 14.5356 3.9646 14.1875 3.9646ZM11.1575 9.9646H9.375V11.4646C9.375 11.5972 9.32891 11.7244 9.24686 11.8182C9.16481 11.9119 9.05353 11.9646 8.9375 11.9646H8.0625C7.94647 11.9646 7.83519 11.9119 7.75314 11.8182C7.67109 11.7244 7.625 11.5972 7.625 11.4646V9.9646H5.84301C5.75643 9.96449 5.67183 9.93503 5.59991 9.87995C5.52799 9.82486 5.47199 9.74663 5.439 9.65515C5.40601 9.56368 5.3975 9.46307 5.41456 9.36607C5.43162 9.26906 5.47348 9.18003 5.53484 9.11022L8.17188 6.12085C8.25918 6.022 8.3771 5.96654 8.5 5.96654C8.6229 5.96654 8.74082 6.022 8.82812 6.12085L11.4646 9.11147C11.5255 9.18127 11.567 9.27008 11.5839 9.36676C11.6008 9.46343 11.5923 9.56365 11.5595 9.65482C11.5267 9.74599 11.471 9.82404 11.3995 9.87916C11.328 9.93428 11.2438 9.964 11.1575 9.9646V9.9646Z"
      fill="#60738C"
    />
  </svg>
</template>
