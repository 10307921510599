<template>
  <div :class="$style.container">
    <DrTruncatedTextTooltip :content="label">
      <span :class="$style.label">{{ label }}</span>
    </DrTruncatedTextTooltip>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import { FindingSeverityDict } from "@drVue/api-service/modules/findings/types";

import type { FindingSeverityValue } from "@drVue/api-service/modules/findings/types";

interface Props {
  severity: FindingSeverityValue;
}
const props = defineProps<Props>();

const severity = computed(() =>
  props.severity ? FindingSeverityDict[props.severity] : null,
);

const label = computed(() => severity.value?.label ?? "");

const color = computed(() => severity.value?.color ?? "transparent");
</script>

<style module lang="scss">
.container {
  background: v-bind(color);
  padding: 4px 6px;
  border-radius: 6px;
}

.label {
  line-height: 24px;
}
</style>
