<template>
  <div :class="$style.button">
    <DrColorPicker
      ref="inputRef"
      :color="editProps.value"
      :is-submitting="editProps.schema.isReadOnly"
      :predefine="predefineColors"
      v-bind="editProps.veeField"
    />
  </div>
</template>

<script setup lang="ts">
import DrColorPicker from "@shared/ui/dr-color-picker/DrColorPicker.vue";

import type { FieldProps } from "../types";

interface Props {
  editProps: FieldProps;
}

const props = defineProps<Props>();

const predefineColors = [
  "#eb5a46",
  "#f2a620",
  "#f2d600",
  "#4e9840",
  "#51e898",
  "#00c2e0",
  "#a6b2bc",
  "#7037e2",
  "#c377e0",
  "#ff80ce",
];
</script>

<style lang="scss" module>
.button {
  width: 150px;
}
</style>
