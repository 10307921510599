angular
  .module("dealroom.analytics.linechart.hoverable", [])
  .directive("drAnalyticsLinechartHoverable", () => ({
    scope: {
      drAnalyticsLinechartHoverable: "<",
    },
    restrict: "A",
    controller: Controller,
  }));

const MIN_OPACITY = 0.1;

Controller.$inject = ["$scope", "AnalyticsLinechartConfigService"];
function Controller($scope, AnalyticsLinechartConfigService) {
  const dispatcher = $scope.drAnalyticsLinechartHoverable;
  const TIMEOUT = AnalyticsLinechartConfigService.HOVER_TIMEOUT;
  const pk = "drAnalyticsLinechartHoverable-" + $scope.$id;

  const nodes = {};
  let _inProgress = true;

  dispatcher.on("addHover." + pk, bind);
  dispatcher.on("removeHover." + pk, unbind);
  dispatcher.on("update." + pk, setInPtogress);

  function setInPtogress({ inProgress }) {
    _inProgress = inProgress;
  }

  function unbind(node) {
    const selectedKey = getKey(node);
    node.on("mouseenter", null);
    node.on("mouseleave", null);
    nodes[selectedKey] = nodes[selectedKey].filter(function () {
      return !isSameNode(node, this);
    });
  }

  function bind(node) {
    const selectedKey = getKey(node);
    if (nodes[selectedKey] === undefined) {
      nodes[selectedKey] = d3.selectAll();
    }

    nodes[selectedKey]._groups[0].push(node.node());

    node.on("mouseenter", mouseenter);
    node.on("mouseleave", mouseleave);

    function mouseenter() {
      if (_inProgress) return;
      const node = d3.select(this);
      const selectedKey = getKey(node);
      callAfterTimeout(node, unselectOther, { isSelection: true });

      function unselectOther() {
        d3.entries(nodes).forEach(function ({ key, value }) {
          if (selectedKey === key) resetState(value);
          else hideSelection(value);
        });
      }
    }

    function mouseleave() {
      const node = d3.select(this);
      callAfterTimeout(node, resetStates);

      function resetStates() {
        d3.values(nodes).forEach(resetState);
      }
    }

    function resetState(selection) {
      selection.transition().style("opacity", 1);
    }

    function hideSelection(selection) {
      selection
        .transition()
        .style("opacity", MIN_OPACITY)
        .each(function () {
          if (this.tagName === "g") {
            d3.select(this).lower();
          }
        });
    }
  }

  function getKey(node) {
    const { key } = node.datum();
    console.assert(
      angular.isNumber(key) || angular.isString(key),
      "key is empty",
      key,
    );

    return key.toString();
  }

  function isSameNode(node, element) {
    return element.isSameNode(node.node());
  }

  function callAfterTimeout(node, fn, isSelection) {
    const selectedKey = getKey(node);

    let [timerToClear, timerToSet] = ["unselect-timer", "select-timer"];
    if (!isSelection) [timerToClear, timerToSet] = [timerToSet, timerToClear];

    d3.values(nodes).forEach((selection) => {
      selection
        .attr(timerToClear, clearTimer)
        .attr(timerToSet, setOrClearTimer);
    });

    function clearTimer() {
      return _clear(this);
    }

    function setOrClearTimer() {
      if (isSameNode(node, this)) return _set();
      return _clear(this, timerToSet);
    }

    function _set() {
      if (node.attr(timerToSet)) return node.attr(timerToSet);
      const setId = setTimeout(function () {
        node.attr(timerToSet, null);
        fn();
      }, TIMEOUT);
      return setId;
    }

    function _clear(e, timerName = timerToClear) {
      const clearId = d3.select(e).attr(timerName);
      clearInterval(clearId);
      return null;
    }
  }
}
