import { isOnline } from "@shared/ui/dr-offline/DrOffline";

import { ORG_MEMBER_DATA, ROOM_DATA } from "@setups";
import { isAuthenticationErrorResponse } from "@setups/urls";
import { DrStore } from "@drVue";
import { pinia } from "@drVue/store/pinia";
import { useFindingsStatusesStore } from "@drVue/store/pinia/pipeline/findings-statuses";
import { useFindingsTypesStore } from "@drVue/store/pinia/pipeline/findings-types";
import { useFindingsStore } from "@drVue/store/pinia/room/findings";

(function () {
  "use strict";
  angular
    .module("dealroom.room-data", [
      "dealroom.documents",
      "dealroom.task",
      "dealroom.members",
    ])
    .service("RoomDataService", RoomDataService);

  RoomDataService.$inject = [
    "$rootScope",
    "$timeout",
    "$q",
    "$http",
    "URLS",
    "RoomConfig",
    "AlertService",
    "AppDebug",
    "DocumentsService",
    "TasksService",
    "CategoriesService",
    "drLabelKey",
  ];
  function RoomDataService(
    $rootScope,
    $timeout,
    $q,
    $http,
    URLS,
    RoomConfig,
    AlertService,
    AppDebug,
    DocumentsService,
    TasksService,
    CategoriesService,
    drLabelKey,
  ) {
    $rootScope.$on("track-activity:become-active", () => {
      if (isOnline()) {
        updateData();
      }
    });

    var initialLoad = true;
    function setupInitialRoomData() {
      if (ROOM_DATA.userPermissions.isFindingsAccessible) {
        const findingsStatusesStore = useFindingsStatusesStore(pinia);
        findingsStatusesStore.fillList(ROOM_DATA.findingStatuses);
        const findingsTypesStore = useFindingsTypesStore(pinia);
        findingsTypesStore.fillList(ROOM_DATA.findingTypes);
      }

      if (ROOM_DATA.custom_fields) {
        DrStore.commit(`clientDashboard/customFields/setItems`, {
          items: ROOM_DATA.custom_fields,
        });
      }
      if (ROOM_DATA.custom_views) {
        DrStore.commit(`common/customViews/set`, ROOM_DATA.custom_views);
      }

      if (ORG_MEMBER_DATA.client) {
        DrStore.commit(`clientDashboard/settings/update`, {
          data: ORG_MEMBER_DATA.client,
        });
      }

      if (ORG_MEMBER_DATA.client.custom_fields) {
        DrStore.commit(`clientDashboard/customFields/setItems`, {
          items: ORG_MEMBER_DATA.client.custom_fields,
        });
      }
    }

    function updateData() {
      var updateLoadStart = new Date().getTime();
      const syncRequests = [
        DocumentsService.syncTree(true),
        DrStore.dispatch("room/members/load", true),
        DrStore.dispatch("room/groups/load", true),
      ];
      if (RoomConfig.userPermissions.isFindingsAccessible) {
        const findingsStore = useFindingsStore(pinia);
        findingsStore.load();
        if (!initialLoad) {
          const findingsStatusesStore = useFindingsStatusesStore(pinia);
          syncRequests.push(findingsStatusesStore.load());
          const findingsTypesStore = useFindingsTypesStore(pinia);
          syncRequests.push(findingsTypesStore.load());
        }
      }
      if (RoomConfig.userPermissions.viewTasks) {
        var tasksMetaSync = initialLoad
          ? $q.when(true)
          : $q.all([
              drLabelKey.syncAll(),
              DrStore.dispatch("room/tasksStatuses/syncAll"),
            ]);
        const tasksSync = tasksMetaSync.then(function () {
          return $q.all([
            $q.when(TasksService.loadTasks(true)),
            $q.when(CategoriesService.loadCategories(true)),
          ]);
        });
        syncRequests.push(tasksSync);
      }

      $q.all(syncRequests)
        .then(
          function () {
            AppDebug.increaseDataUpdateCount();
            if (initialLoad) {
              measureLoad(updateLoadStart);
            }
          },
          function (error) {
            const errorResp = error?.response?.status ? error.response : error;
            if (isAuthenticationErrorResponse(errorResp)) {
              return; // ignore, page will be reloaded
            }
            var errorText = "Failed to update room data.";
            if (errorResp?.status === -1) {
              // request timeout on client side
              errorText += " Please check your Internet connection.";
            }
            AlertService.danger(errorText);
          },
        )
        .finally(function () {
          initialLoad = false;
        });
    }

    function measureLoad(updateLoadStart) {
      if (!initialLoad) {
        return;
      }
      var roomDataLoaded = new Date().getTime();
      var perfData = window.performance.timing;

      function sendMeasure() {
        var data = {
          pageResponseEnd: perfData.responseEnd - perfData.navigationStart,
          domLoadingComplete: perfData.domComplete - perfData.responseEnd,
          roomDataLoadingComplete: roomDataLoaded - updateLoadStart,
          roomLoadingTotal: new Date().getTime() - perfData.navigationStart,
        };
        $timeout(function () {
          var url = URLS["api:room:measure-load"]();
          $http.post(url, data);
        });
      }

      if (perfData.domComplete) {
        sendMeasure();
      } else {
        // wait until full page load to make sure that domComplete
        window.addEventListener("load", function () {
          sendMeasure();
        });
      }
    }

    return {
      updateData: updateData,
      setupInitialRoomData: setupInitialRoomData,
    };
  }
})();
