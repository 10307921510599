import { sortBy } from "lodash-es";
import { values } from "lodash-es";

import documents_documentsSearchCompletionBoxHtml from "../../../templates/documents/documents-search-completion-box.html?raw";

export default function documentsSearchCompletionBox() {
  return {
    template: documents_documentsSearchCompletionBoxHtml,
    controller: [
      "$state",
      "$stateParams",
      "DocumentsService",
      "drSearchContext",
      function ($state, $stateParams, DocumentsService, drSearchContext) {
        var $ctrl = this;
        $ctrl.drSearchContext = drSearchContext;

        $ctrl.onItemSelected = onItemSelected;
        $ctrl.suggestItems = suggestItems;
        $ctrl.goToSearchResults = goToSearchResults;
        $ctrl.searchQuery = $stateParams.query;

        $ctrl.DOCS_LIMIT = 6;

        function goToSearchResults(query) {
          $state.go($state.$current.name, { query: query }, { reload: false });
        }

        function onItemSelected($item, $event) {
          if ($item.type === "folder") {
            // reset query cause search is finished
            $state.go(
              "documents.folder",
              { folderId: $item.id, query: undefined },
              { reload: "documents.folder" },
            );
          }
          if ($item.type === "file") {
            DocumentsService.viewDocument($item);
            //prevent selection of file name in search box
            $state.reload();
          }
          $event.stopPropagation();
        }

        function suggestItems(query) {
          var items;
          if (!query) {
            //take all viewed files
            items = values(DocumentsService.Files).filter(function (i) {
              return !!i.lastViewDate;
            });
          } else {
            //take top `DOCS_LIMIT` matched with query, will resort by lastViewDate
            items = DocumentsService.findItems(query).splice(
              0,
              $ctrl.DOCS_LIMIT,
            );
          }

          var now = new Date();
          // do stable sorting to pop up recent objects
          items = sortBy(items, function (i) {
            return now - (i.lastViewDate || 0);
          }).splice(0, $ctrl.DOCS_LIMIT);

          return items;
        }
      },
    ],
  };
}
