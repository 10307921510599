<template>
  <DrLayoutPage page-title="People" page-icon="users">
    <DrLayoutContent>
      <template v-if="hasDeals" #toolbar>
        <SearchInput v-model="searchQuery" style="z-index: 2000" />
        <UsersFilter />
        <div :class="$style.counter">
          <span :class="$style.divider">|</span>
          {{ `${usersCount} ${usersCount === 1 ? "record" : "records"}` }}
        </div>
      </template>

      <template v-if="hasDeals" #toolbar-right>
        <ElButton @click="exportToExcel">Export</ElButton>
        <ElButton type="primary" plain @click="openPostAnnouncementModal">
          Create announcement
        </ElButton>
        <ElButton
          type="primary"
          :disabled="isLoading"
          @click="openAddUserModal"
        >
          New person
        </ElButton>
      </template>

      <template v-if="hasDeals">
        <UsersTable
          ref="table"
          :search-query="searchQuery"
          @counted="setUsersCount"
        />
        <PostAnnouncement ref="postAnnouncementModal" />
        <AddUserModal ref="addUserModal" />
      </template>

      <div v-if="!hasDeals && !isLoading" :class="$style.noUsers">
        <h3>No active deals found</h3>
        <span v-if="canCreateRooms">Try creating a new deal</span>
      </div>
    </DrLayoutContent>

    <template #root>
      <DetailModal @closed="detailsClosed" />
    </template>
  </DrLayoutPage>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DrLayoutContent, DrLayoutPage } from "@shared/ui/dr-layouts";
import SearchInput from "@shared/ui/search-input";

import { ORG_MEMBER_DATA } from "@setups/data";
import DetailModal from "@drVue/components/client-dashboard/deals/DetailModal/DetailModal.vue";
import PostAnnouncement from "@drVue/components/client-dashboard/notifications/PostAnnouncement";
import AddUserModal from "@drVue/components/client-dashboard/users/AddUserModal/modal.vue";
import UsersFilter from "@drVue/components/client-dashboard/users/Filter.vue";
import UsersTable from "@drVue/components/client-dashboard/users/UsersTable";
import DrStore from "@drVue/store";
import { ExcelReport, exportToExcel } from "@drVue/store/modules/reporting";

import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { UserViewModel } from "@drVue/components/client-dashboard/users/UsersTable/types";
import type { Column, ReportItem } from "@drVue/store/modules/reporting";

class UsersExcelReport extends ExcelReport {
  private usersVm: UserViewModel[];
  private fields: FieldItem[];

  constructor(users: UserViewModel[], fields: FieldItem[]) {
    super("users.xlsx", "Users");
    this.usersVm = users;
    this.fields = fields;
  }

  get columns(): Column[] {
    return [
      { title: "Name", data_index: "name", width: 20 },
      { title: "Rooms", data_index: "rooms", width: 24 },
      { title: "Company", data_index: "company", width: 18 },
      { title: "Title", data_index: "title", width: 22 },
      { title: "Email", data_index: "email", width: 22 },
      { title: "Phone", data_index: "office_number", width: 12 },
      ...this.makeCustomColumns(this.fields),
    ];
  }

  get items(): ReportItem[] {
    return this.usersVm
      .filter((vm) => !vm.user.pending)
      .map(this.userVmToItem.bind(this));
  }

  private userVmToItem(userVm: UserViewModel): ReportItem {
    const item = {
      ...userVm.user,
      ...this.formatCustomData(this.fields, userVm.user.custom_data),
    } as ReportItem;
    item.rooms = userVm.membershipsInfo
      .map((mi) => `${mi.dealTitle} - ${mi.roomGroupName}`)
      .join("\n");
    return item;
  }
}

interface Data {
  canCreateRooms: any;
  searchQuery: string;
  usersCount: number;
}

export default defineComponent({
  name: "UsersTab",
  components: {
    AddUserModal,
    UsersTable,
    SearchInput,
    UsersFilter,
    PostAnnouncement,
    DetailModal,
    DrLayoutContent,
    DrLayoutPage,
  },
  data(): Data {
    return {
      canCreateRooms: ORG_MEMBER_DATA.group.can_create_rooms,
      searchQuery: "",
      usersCount: 0,
    };
  },
  computed: {
    isLoading(): any {
      return (
        DrStore.state.clientDashboard.orgUsers.isLoading ||
        DrStore.state.clientDashboard.deals.isLoading
      );
    },
    hasDeals(): any {
      return DrStore.state.clientDashboard.deals.items.length > 0;
    },
  },
  methods: {
    setUsersCount(usersCount: number) {
      this.usersCount = usersCount;
    },
    exportToExcel() {
      const table = this.$refs.table as any;
      const usersVm = table.filteredUsers;
      const fields = table.customFields;
      const report = new UsersExcelReport(usersVm, fields);
      exportToExcel(report);
    },
    openPostAnnouncementModal() {
      (this.$refs.postAnnouncementModal as any).open();
    },
    openAddUserModal() {
      (this.$refs.addUserModal as any).open();
    },
    detailsClosed() {
      DrStore.commit("clientDashboard/deals/detailsDialog", {
        visible: false,
        dealUid: undefined,
      });
    },
  },
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

.toolbar {
  display: grid;
  gap: 10px;
  align-items: center;
  grid-template-columns: 200px max-content max-content 1fr;
}

.ops {
  justify-self: end;
}

.alignCenter {
  display: flex;
  align-items: center;
  height: 100%;
}

.counter {
  margin-top: 1px;
  color: colors.$pr-500;
}

.divider {
  display: inline-block;
  margin: 0 7px 0 5px;
  color: colors.$pr-500;
}

.noUsers {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: center;
}
</style>
