<template>
  <div>
    <ElInput
      v-model="contactsQuery"
      class="dr-search-input"
      placeholder="Type to search..."
      clearable
    >
      <template #prefix>
        <i class="far fa-search" />
      </template>
    </ElInput>

    <div class="contacts-existing">
      <DrVxeGrid
        ref="contactsTable"
        :data="filteredContacts"
        :columns="tableColumns.columns"
        :style="contactsTableStyles"
        @checkbox-all="onGridCheckAll"
        @checkbox-change="onGridCheck"
      >
        <template #empty>No available contacts found</template>
      </DrVxeGrid>
    </div>

    <div class="text-right">
      <ElButton
        type="primary"
        size="large"
        :disabled="isSubmitting"
        :loading="isSubmitting"
        @click="addSelectedContacts"
      >
        Add selected ({{ checkedCount }})
      </ElButton>
    </div>
  </div>
</template>

<script lang="ts">
import { Document } from "flexsearch";
import { keyBy } from "lodash-es";
import { sortBy } from "lodash-es";
import { defineComponent } from "vue";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import { flexSearchAllResultsArray } from "@app/flex";
import { $notifySuccess } from "@drVue/common";
import TableColumns from "./tableColumns";

import type { UpdateContactsPayload } from "@drVue/store/modules/client-dashboard/deals/DealsActions";
import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { Dictionary } from "@drVue/types";

interface Data {
  tableColumns: any;
  selected: Dictionary<boolean>;
  contactsQuery: string;
  isSubmitting: boolean;
  flexIndex: Document<OrgUser>;
}

export default defineComponent({
  name: "ExistingContactsTable",
  components: {
    DrVxeGrid,
  },
  inject: ["dealId"],
  emits: ["contacts-list-updated"],
  data(): Data {
    return {
      tableColumns: new TableColumns(),
      selected: {},
      contactsQuery: "",
      isSubmitting: false,
      flexIndex: new Document<OrgUser>({ document: { id: "id", index: [] } }),
    };
  },
  computed: {
    contactsTableStyles(): object {
      return {
        maxHeight: "calc(100vh - 300px)", // 300px - height of the panel content and indents excluding table
        height: this.filteredContacts.length * 40 + 50 + "px", // 40 - line height, 50 - height of the table header + horizontal scrollbar
      };
    },
    dealContactsIds(): string[] {
      return this.$store.getters["clientDashboard/deals/getContactsOfDeal"](
        (this as any).dealId, // injected
      );
    },
    availableContactsArray(): OrgUser[] {
      const users = this.$store.state.clientDashboard.orgUsers.items.filter(
        (u: OrgUser) => this.dealContactsIds.indexOf(u.id) === -1,
      );

      return sortBy(users, ["pending", "email"]);
    },
    availableContactsDictionary(): Dictionary<OrgUser> {
      return keyBy(this.availableContactsArray, "id");
    },
    filteredContacts(): OrgUser[] {
      const query = this.contactsQuery;
      if (query) {
        const foundSortedIds = flexSearchAllResultsArray(this.flexIndex, query);
        return foundSortedIds.map((id) => this.availableContactsDictionary[id]);
      }

      return this.availableContactsArray;
    },
    usersIdsToAdd(): string[] {
      return Object.keys(this.selected).filter((k) => this.selected[k]);
    },
    checkedCount(): any {
      return this.usersIdsToAdd.length;
    },
  },
  watch: {
    availableContactsArray: {
      immediate: true,
      handler(users: OrgUser[]) {
        this.updateFlexIndex(users);
      },
    },
  },
  mounted() {
    this.updateFlexIndex(this.availableContactsArray);
  },
  methods: {
    updateFlexIndex(users: OrgUser[]) {
      this.flexIndex = new Document<OrgUser>({
        charset: "latin:advanced",
        tokenize: "full",
        document: {
          id: "id",
          index: ["name", "company", "email", "title", "office_number"],
        },
      });

      users.forEach((u) => this.flexIndex.add(u));
    },
    onGridCheck({ row, checked }: { row: OrgUser; checked: boolean }) {
      this.selected[row.id] = checked;
    },
    onGridCheckAll({ records }: { records: OrgUser[] }) {
      this.selected = {};
      records.forEach((r) => (this.selected[r.id] = true));
    },
    addSelectedContacts: function () {
      this.isSubmitting = true;
      this.$store
        .dispatch("clientDashboard/deals/updateContacts", {
          dealId: (this as any).dealId, // injected
          usersIdsToAdd: this.usersIdsToAdd,
          usersIdsToRemove: [],
        } as UpdateContactsPayload)
        .then(() => {
          this.$emit("contacts-list-updated");
          $notifySuccess("The contact list was successfully updated");
        })
        .finally(() => (this.isSubmitting = false));
    },
  },
});
</script>
