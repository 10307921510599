export default {
  green: {
    normal: "#3ec175",
  },

  grey: {
    light: "#dadcdc",
    normal: "#bbbdbf",
    dark: "#767676",
  },

  blue: {
    light: "#d2e2f5",
    normal: "#0077db",
  },

  orange: {
    normal: "#f2a620",
  },

  red: {
    normal: "#c63a3c",
  },
};
