<template>
  <div class="client-settings-panel">
    <ElForm
      ref="integrationForm"
      :model="integrationModel"
      :disabled="isSubmitting"
      :rules="rules"
      :label-position="'top'"
      size="small"
      @submit.prevent="formSubmit"
    >
      <ElFormItem label="Title" prop="title">
        <ElInput v-model="integrationModel.title" autofocus />
      </ElFormItem>

      <ElFormItem label="">
        <ElCheckbox
          v-model="integrationModel.allow_update_user_on_login"
          text-color="inherit"
        >
          Always Update Users Through SAML Attributes On Login
        </ElCheckbox>
      </ElFormItem>

      <hr />
      <strong>Identity provider settings</strong>
      <br /><br />

      <ElFormItem label="Entity ID" prop="entity_id">
        <ElInput v-model="integrationModel.entity_id" />
      </ElFormItem>
      <ElFormItem label="Single Sign On URL" prop="sso_url">
        <ElInput v-model="integrationModel.sso_url" />
      </ElFormItem>
      <ElFormItem label="Single Log Out URL" prop="slo_url">
        <ElInput v-model="integrationModel.slo_url" />
      </ElFormItem>
      <ElFormItem label="x509 public certificate" prop="x509cert">
        <ElInput v-model="integrationModel.x509cert" />
      </ElFormItem>

      <hr />
      <strong>Attribute mapping</strong>
      <br /><br />

      <ElFormItem label="Identifier" prop="mapping.ident">
        <ElInput v-model="integrationModel.mapping.ident" />
      </ElFormItem>
      <ElFormItem label="Email" prop="mapping.email">
        <ElInput v-model="integrationModel.mapping.email" />
      </ElFormItem>
      <ElFormItem label="First Name" prop="mapping.first_name">
        <ElInput v-model="integrationModel.mapping.first_name" />
      </ElFormItem>
      <ElFormItem label="Last Name" prop="mapping.last_name">
        <ElInput v-model="integrationModel.mapping.last_name" />
      </ElFormItem>
      <ElFormItem label="Title" prop="mapping.title">
        <ElInput v-model="integrationModel.mapping.title" />
      </ElFormItem>
      <ElFormItem label="Phone" prop="mapping.office_number">
        <ElInput v-model="integrationModel.mapping.office_number" />
      </ElFormItem>

      <ElFormItem>
        <ElButton
          type="primary"
          :disabled="!isChanged"
          :loading="isSubmitting"
          @click="formSubmit"
        >
          {{ isCreateNew ? "Create" : "Update" }}
        </ElButton>
        <ElButton class="close-btn" @click="close(undefined)">
          Cancel
        </ElButton>
        <ElButton
          v-if="!isCreateNew"
          type="danger"
          icon="el-icon-delete"
          size="small"
          class="pull-right"
          @click="deleteIntegration"
        />
      </ElFormItem>
    </ElForm>
  </div>
</template>

<script lang="ts">
import { cloneDeep } from "lodash-es";
import { defineComponent } from "vue";
import DrForm from "@shared/ui/dr-form";

import { OpenIDIntegrationApiService } from "@drVue/api-service/client-dashboard";

import type { OpenIDProvider } from "@drVue/api-service/client-dashboard";
import type { Dictionary } from "@drVue/types";
import type { PropType } from "vue";

interface Data {
  integrationModel: Dictionary<any>;
  api: OpenIDIntegrationApiService;
  isCreateNew: boolean;
  rules: any;
}

export default defineComponent({
  name: "EditForm",
  extends: DrForm,
  props: {
    integrationData: {
      required: true,
      type: Object as PropType<Dictionary<any>>,
    },
    provider: { required: true, type: Object as PropType<OpenIDProvider> },
  },
  emits: ["close"],
  data(): Data {
    return {
      integrationModel: cloneDeep(this.integrationData),
      api: new OpenIDIntegrationApiService(this.provider.id),
      isCreateNew: !this.integrationData.entity_id,
      rules: {
        title: [
          { required: true, message: "Please input title", trigger: "blur" },
        ],
        entity_id: [
          {
            required: true,
            message: "Please input entity ID",
            trigger: "blur",
          },
        ],
        sso_url: [
          {
            required: true,
            message: "Please input Single Sign On URL",
            trigger: "blur",
          },
        ],
        slo_url: [
          {
            required: true,
            message: "Please input Single Logout URL",
            trigger: "blur",
          },
        ],
        x509cert: [
          {
            required: true,
            message: "Please input x509 public certificate",
            trigger: "blur",
          },
        ],
        "mapping.ident": [
          {
            required: true,
            message: "Please input identifier field name",
            trigger: "blur",
          },
        ],
        "mapping.email": [
          {
            required: true,
            message: "Please input email field name",
            trigger: "blur",
          },
        ],
        "mapping.first_name": [
          {
            required: true,
            message: "Please input first name field name",
            trigger: "blur",
          },
        ],
        "mapping.last_name": [
          {
            required: true,
            message: "Please input last name field name",
            trigger: "blur",
          },
        ],
        "mapping.title": [{ required: false }],
        "mapping.office_number": [{ required: false }],
      },
    };
  },
  watch: {
    integrationModel: {
      deep: true,
      handler() {
        this.isChanged = true;
      },
    },
  },
  methods: {
    close(payload: { [key: string]: any } | undefined) {
      this.$emit("close", payload);
    },
    formSubmit() {
      (this.$refs["integrationForm"] as any).validate((valid: boolean) => {
        if (valid) {
          const method = this.isCreateNew ? "create" : "update";
          const promise = this.api[method](this.integrationModel!);

          return this.submitPromise(
            promise,
            `Failed to ${method} ${this.provider.name} integration`,
          ).then((data) => this.close(data));
        } else {
          return false;
        }
      });
    },
    deleteIntegration() {
      this.$confirm(
        `${this.provider.name} integration will be removed`,
        `Remove integration`,
        {
          confirmButtonText: "Remove",
          cancelButtonText: "Cancel",
          type: "warning",
        },
      ).then(() => {
        const promise = this.api.destroy();

        return this.submitPromise(
          promise,
          `Failed to remove ${this.provider.name} integration`,
        ).then(() => this.close({}));
      });
    },
  },
});
</script>
