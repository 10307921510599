import { cloneDeep } from "lodash-es";

import type {
  Deal,
  DealPhaseInfo,
} from "@drVue/store/modules/client-dashboard/deals/types";

export function getOrCreatePhaseInfo(
  deal: Deal,
  phaseId: number,
): DealPhaseInfo {
  // try to find existing phase information
  const selectedPhaseInfo = deal.phases_info[phaseId];
  if (selectedPhaseInfo) {
    // avoid editing item directly to prevent unexpected changes on deal
    return cloneDeep(selectedPhaseInfo);
  }
  return {
    deal_id: deal.id,
    phase_id: phaseId,
    planned_start_date: null,
    actual_start_date: new Date(),
    comment: "",
  };
}
