angular
  .module("dealroom.nda", [
    "ui.router",
    "dealroom.sentry",
    "dealroom.config",
    "dealroom.room",
    "dealroom.common",
  ])
  .controller("NdaController", NdaController);

NdaController.$inject = ["$scope", "URLS", "$http", "NextPageService"];

function NdaController($scope, URLS, $http, NextPageService) {
  const submitUrl = URLS["api:room:accept_nda"]();
  $scope.submitNda = function () {
    $http.post(submitUrl, { accept: true }).then(function () {
      // try to use redirect page param
      if (!NextPageService.redirectToNextPage()) {
        // since we have the right ui-routing after login we need only reload the page
        // but in this time middleware get us to the room itself
        window.location.reload();
      }
    });
  };
}
