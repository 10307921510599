<template>
  <Transition>
    <a v-if="roomButton?.type === 'link'" :href="roomButton.href">
      <ElButton icon-right :class="$style.button">
        {{ roomButton.text }}
        <template #icon>
          <DrIcon :name="roomButton.icon" />
        </template>
      </ElButton>
    </a>

    <ElButton
      v-else-if="roomButton?.type === 'button'"
      icon-right
      :class="$style.button"
      :disabled="!roomButton.action"
      @click="roomButton.action"
    >
      {{ roomButton.text }}
      <template v-if="roomButton?.icon" #icon>
        <DrIcon :name="roomButton.icon" />
      </template>
    </ElButton>

    <ElDropdown
      v-else-if="roomButton?.type === 'splitButton'"
      split-button
      trigger="click"
      :teleported="false"
      :class="{
        [$style.dropdown]: true,
        [$style.dropdown__partialDisabled]: !roomButton.action,
      }"
      @click="roomButton.action"
      @command="handleMenuClick"
    >
      {{ roomButton.text }}
      <template #dropdown>
        <ElDropdownMenu>
          <ElDropdownItem
            v-for="item in roomButton.menu"
            :command="item.command"
            :key="item.command"
            :divided="item.divided"
          >
            {{ item.label }}
          </ElDropdownItem>
        </ElDropdownMenu>
      </template>
    </ElDropdown>
  </Transition>
</template>

<script lang="ts" setup>
import { ElMessageBox } from "element-plus";
import { computed, h } from "vue";
import DrIcon from "@shared/ui/dr-icon";

import { ORG_MEMBER_DATA } from "@setups/data";
import { DrStore } from "@app/vue";
import { $notifyDanger, $notifySuccess } from "@drVue/common";
import DeleteRestoreDealConfirmMessage from "@drVue/components/client-dashboard/settings/DealsArchive/DeleteRestoreDealConfirmMessage.vue";
import DeleteRestoreRoomConfirmMessage from "./DeleteRestoreRoomConfirmMessage.vue";

import type { IconName } from "@drVue/shared/ui/dr-icon";
import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";
import type { ElMessageBoxOptions } from "element-plus/es";

interface Props {
  deal: Deal;
}

const props = defineProps<Props>();

const emit = defineEmits(["no-access", "create-room", "deleted"]);

type CommandItem = {
  command: "delete-room" | "reopen-room" | "delete-deal";
  label: string;
  divided?: boolean;
};

type RoomButton =
  | {
      type: "link";
      text: string;
      href: string;
      icon: IconName;
    }
  | {
      type: "button";
      text: string;
      icon?: IconName;
      action?: () => void;
    }
  | {
      type: "splitButton";
      text: string;
      action?: () => void;
      menu: CommandItem[];
    };

type ConfirmParams = {
  title: string;
  message: ElMessageBoxOptions["message"];
  type?: "warning" | "error";
  confirmButtonText: string;
  action: () => Promise<any>;
  postAction?: () => void;
  successMessage?: string;
  errorMessage?: string;
};

const COMMAND__DELETE_DEAL: CommandItem = {
  command: "delete-deal",
  label: "Remove deal from pipeline",
};
const COMMAND__REOPEN_ROOM: CommandItem = {
  command: "reopen-room",
  label: "Reopen room",
};
const COMMAND__DELETE_ROOM: CommandItem = {
  command: "delete-room",
  label: "Delete room data",
};

const hasAccessToRoom = computed(
  () => props.deal.room?.access.administrator || props.deal.room?.access.member,
);

const canEditDeal = computed(() => ORG_MEMBER_DATA.group.can_edit_deals);
const canEditRoom = computed(() => {
  return (
    ORG_MEMBER_DATA.group.is_administrator ||
    !!props.deal.room?.access.administrator
  );
});

const canCreateRoom = computed(() => {
  const { deals_access_type } = ORG_MEMBER_DATA.group;
  return (
    (["scope_criteria", "all"].includes(deals_access_type) &&
      ORG_MEMBER_DATA.group.can_create_rooms) ||
    ORG_MEMBER_DATA.group.is_administrator
  );
});

const handleNoAcceesToOpenRoom = () => {
  if (ORG_MEMBER_DATA.group.is_administrator) {
    emit("no-access");
  } else {
    ElMessageBox({
      type: "info",
      title: "Room Access",
      message:
        "You are not a member of this room. Contact room administrators to get access.",
    });
  }
};

const showConfirm = (params: ConfirmParams) => {
  ElMessageBox.confirm(params.message, params.title, {
    type: params.type ?? "warning",
    confirmButtonText: params.confirmButtonText,
    confirmButtonClass: params.type === "error" ? "el-button--danger" : "",
    cancelButtonText: "No",
    beforeClose: (action, instance, done) => {
      if (action !== "confirm") {
        done();
        return;
      }

      instance.showCancelButton = false;
      instance.confirmButtonLoading = true;

      params
        .action()
        .then(
          () => {
            if (params.postAction) params.postAction();
            if (params.successMessage) $notifySuccess(params.successMessage);
          },
          () => {
            if (params.errorMessage) $notifyDanger(params.errorMessage);
          },
        )
        .finally(done);
    },
  }).catch(() => {});
};

const reopenRoom = (deal: Deal) => {
  showConfirm({
    title: "Reopen room",
    message: h(DeleteRestoreRoomConfirmMessage, {
      type: "restore",
      room: deal.room!,
    }),
    confirmButtonText: "Reopen",
    action: () => DrStore.dispatch("clientDashboard/deals/restoreRoom", deal),
    successMessage: `Room "${deal.room!.title}" has been reopened`,
    errorMessage: "Failed to reopen the room, please try again",
  });
};

const deleteRoom = (deal: Deal) => {
  showConfirm({
    title: "Delete room",
    message: h(DeleteRestoreRoomConfirmMessage, {
      type: "delete",
      room: deal.room!,
    }),
    confirmButtonText: "Delete",
    type: "error",
    action: () => DrStore.dispatch("clientDashboard/deals/deleteRoom", deal),
    successMessage: `Room "${deal.room!.title}" has been deleted`,
    errorMessage: "Failed to delete the room, please try again",
  });
};

const deleteDeal = (deal: Deal) => {
  showConfirm({
    title: "Remove deal from pipeline",
    message: h(DeleteRestoreDealConfirmMessage, { type: "delete", deal }),
    confirmButtonText: "Delete",
    type: "error",
    action: () => DrStore.dispatch("clientDashboard/deals/deleteDeal", deal.id),
    postAction: () => emit("deleted"),
    successMessage: "Deal is deleted",
    errorMessage: "Failed to delete the deal, please try again",
  });
};

const handleMenuClick = (command: CommandItem["command"]) => {
  const { deal } = props;

  switch (command) {
    case "reopen-room":
      reopenRoom(deal);
      break;
    case "delete-room":
      deleteRoom(deal);
      break;
    case "delete-deal":
      deleteDeal(deal);
      break;
  }
};

const roomButton = computed<RoomButton | null>(() => {
  const { room } = props.deal;

  if (!room) {
    const menu: CommandItem[] = canEditDeal.value ? [COMMAND__DELETE_DEAL] : [];

    if (canCreateRoom.value) {
      return menu.length
        ? {
            type: "splitButton",
            text: "Create room",
            action: () => emit("create-room"),
            menu,
          }
        : {
            type: "button",
            text: "Create room",
            action: () => emit("create-room"),
          };
    } else {
      return menu.length
        ? {
            type: "button",
            text: menu[0].label,
            action: () => handleMenuClick(menu[0].command),
          }
        : null;
    }
  } else if (room.is_archived || room.is_removed) {
    if (room.is_removed) {
      const menu: CommandItem[] = canEditDeal.value
        ? [COMMAND__DELETE_DEAL]
        : [];

      return menu.length
        ? {
            type: "splitButton",
            text: "Room is deleted",
            action: undefined,
            menu,
          }
        : {
            type: "button",
            text: "Room is deleted",
            action: undefined,
          };
    } else {
      const menu = canEditRoom.value
        ? [COMMAND__REOPEN_ROOM, COMMAND__DELETE_ROOM]
        : [];

      if (canEditDeal.value) {
        menu.push(
          menu.length
            ? { ...COMMAND__DELETE_DEAL, divided: true }
            : COMMAND__DELETE_DEAL,
        );
      }

      return menu.length
        ? {
            type: "splitButton",
            text: "Room is closed",
            action: undefined,
            menu,
          }
        : {
            type: "button",
            text: "Room is closed",
            action: undefined,
          };
    }
  } else {
    return hasAccessToRoom.value
      ? {
          type: "link",
          text: "Open room",
          href: `${room.permalink}/`,
          icon: "sign-in",
        }
      : {
          type: "button",
          text: "Open room",
          icon: "sign-in",
          action: handleNoAcceesToOpenRoom,
        };
  }
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.button:global(.el-button) {
  font: typography.$body_regular;
}

.dropdown :global {
  .el-button {
    font: typography.$body_regular;
  }

  .el-button:focus {
    outline: none !important;
  }

  .el-button-group > .el-button:active,
  .el-button-group > .el-button:hover {
    border-color: colors.$sc-600;

    &:before {
      background: colors.$sc-600;
      opacity: 1;
    }
  }

  .el-dropdown-menu__item {
    font: typography.$body_regular;
    color: colors.$pr-900;
  }
}

.dropdown__partialDisabled :global {
  .el-button-group > .el-button:not(.el-dropdown__caret-button) {
    cursor: text;
    border-color: colors.$pr-300;
    background: colors.$pr-100;
  }

  .el-button-group > .el-button,
  .el-button-group > .el-button:active,
  .el-button-group > .el-button:hover {
    color: #485668;
  }
}
</style>
