import { isDate, isValid, parseISO } from "date-fns";
import { toDate, toZonedTime } from "date-fns-tz";
import fuzzaldrinPlus from "fuzzaldrin-plus";
import { capitalize as _capitalize, escape, map } from "lodash-es";
import VueNgDatepicker from "@shared/ui/dr-datepicker/NgDatepicker.vue";
import VueDrHelpModalContent from "@shared/ui/dr-help/content.vue";

import { memberUrl } from "@setups/room-urls";
import VueCustomFieldsEditForm from "@drVue/components/client-dashboard/common/CustomFieldsEditForm.vue";
import parseEmailLine from "@drVue/components/client-dashboard/users/AddUserModal/emails-parser";
import VueDynamicDateIndicatorIcon from "@drVue/components/room/tasks/shared/DynamicDateIndicatorIcon.vue";
import { drUserTime as _drUserTime } from "@drVue/filters/drUserTime";
import { DrStore } from "@drVue/index";
import getIconClass from "./common/mimetype";
import { ROOM_DATA, Urls, USER_DATA } from "./setups";
import alertMessage_alertMessageHtml from "./templates/components/alert-message/alert-message.html?raw";
import datepicker_datepickerHtml from "./templates/components/datepicker/datepicker.html?raw";
import icons_checkIcoHtml from "./templates/components/icons/check-ico.html?raw";
import icons_checkboxHtml from "./templates/components/icons/checkbox.html?raw";
import icons_followIcoHtml from "./templates/components/icons/follow-ico.html?raw";
import icons_newFolderIcoHtml from "./templates/components/icons/new-folder-ico.html?raw";
import icons_plusIcoHtml from "./templates/components/icons/plus-ico.html?raw";
import icons_priorityIcoHtml from "./templates/components/icons/priority-ico.html?raw";
import icons_relatedRequestsIcoHtml from "./templates/components/icons/related-requests-ico.html?raw";
import icons_reviewIcoHtml from "./templates/components/icons/review-ico.html?raw";
import icons_statusIcoHtml from "./templates/components/icons/status-ico.html?raw";
import icons_usersIcoHtml from "./templates/components/icons/users-ico.html?raw";
import room_logoHtml from "./templates/components/room/logo.html?raw";
import searchField_searchCompletionBoxHtml from "./templates/components/search-field/search-completion-box.html?raw";
import searchField_searchFieldHtml from "./templates/components/search-field/search-field.html?raw";
import users_userAvatarHtml from "./templates/components/users/user-avatar.html?raw";
import users_userInformationHtml from "./templates/components/users/user-information.html?raw";
import help_helpModalHtml from "./templates/help/help-modal.html?raw";
import help_trainingVideoHtml from "./templates/help/training-video.html?raw";
import help_trainingVideosHtml from "./templates/help/training-videos.html?raw";
import members_inviteConfirmationHtml from "./templates/members/invite-confirmation.html?raw";
import TrainingVideosService from "./TrainingVideosService";
import { OpenIDProvidersService } from "./vue/api-service/client-dashboard";
import { capitalize, copyToClipboard, postAndGetFile } from "./vue/common";
import { isISODateString } from "./vue/filters/drUserTime";

(function () {
  "use strict";
  tooltipConfig.$inject = ["$uibTooltipProvider"];
  drFitDropdown.$inject = ["$timeout"];
  drTooltip.$inject = ["$uibTooltip"];
  drTimeSpent.$inject = ["$filter"];
  drHighlightMatch.$inject = ["$sce"];
  SearchQueryService.$inject = ["URLS", "$http", "drSearchContext"];
  FeedbackService.$inject = ["URLS", "AlertService", "$http"];
  AlertService.$inject = ["$sce"];
  drFlexTable.$inject = ["$timeout"];
  drCopyToClipboard.$inject = ["AlertService", "drSafeApply"];
  drSearchField.$inject = ["$state", "$compile"];
  downloadDialog.$inject = ["$location", "$timeout", "ContactEmail"];
  ignoreClickIf.$inject = ["$parse", "$rootScope"];
  drAutoFocus.$inject = ["$timeout"];
  NextPageService.$inject = ["$window"];
  ManageMembersService.$inject = ["$q", "$sce", "$uibModal", "AlertService"];
  drAsyncFuncWrapper.$inject = ["$q", "drAsyncStatus"];
  drSafeApply.$inject = ["$rootScope"];

  angular.module("dealroom.config", []).provider("URLS", function () {
    this.$get = function () {
      return Urls;
    };
  });

  var Icons = getIcons();

  angular
    .module("dealroom.common", [
      "ui.bootstrap",
      "angular-svg-round-progressbar",
      "ngAnimate",
      "dealroom.date-fns",

      "dealroom.config",
    ])
    .factory("drSafeApply", drSafeApply)
    .factory("drAsyncFuncWrapper", drAsyncFuncWrapper)
    .factory("drMimetype", drMimetype)
    .service("ManageMembersService", ManageMembersService)
    .service("NextPageService", NextPageService)
    .service("NavKeyHandler", NavKeyHandler)
    .directive("drEmailList", drEmailList)
    .directive("drAutoFocus", drAutoFocus)
    .directive("drDropdown", drDropdown)
    .directive("ignoreClickIf", ignoreClickIf)
    .directive("downloadDialog", downloadDialog)
    .directive("drLoader", drLoader)
    .directive("drServerError", drServerError)
    .directive("compareTo", compareTo)
    .directive("onCtrlEnter", onCtrlEnter)
    .directive("drOnDragStartDisabled", drOnDragStartDisabled)
    .component("drInRowMenu", drInRowMenu())
    .factory("TrainingVideosService", () => TrainingVideosService)
    .component("drTrainingVideo", drTrainingVideo())
    .component("drTrainingVideos", drTrainingVideos())
    .directive("drSearchField", drSearchField)
    .directive("drCopySelected", drCopySelected)
    .directive("drCopyToClipboard", drCopyToClipboard)
    .directive("drFlexTable", drFlexTable)
    .component("drAlert", drAlert())
    .component("drHelpModal", drHelpModal())
    .component("drRoomLogo", drRoomLogo())
    .component("drStoragedCheckbox", drStoragedCheckbox())
    .component("drPlusIco", Icons.plusIco)
    .component("drUsersIco", Icons.usersIco)
    .component("drCheckIco", Icons.checkIco)
    .component("drPriorityIco", Icons.priorityIco)
    .component("drStatusIco", Icons.statusIco)
    .component("drFollowIco", Icons.followIco)
    .component("drReviewIco", Icons.reviewIco)
    .component("drRelatedTasksIco", Icons.relatedTasks)
    .component("drNewFolderIco", Icons.newFolder)
    .component("drCheckbox", Icons.checkbox)
    .component("drSearchCompletionBox", drSearchCompletionBox())
    .component("drUserAvatar", drUserAvatar())
    .component("drQrCode", drQrCode())
    .component("drUserInformation", drUserInformation())
    .component("drDatepicker", drDatepicker())
    .service("DownloadFile", DownloadFile)
    .service("RaiseServerValidationErrors", RaiseServerValidationErrors)
    .service("AlertService", AlertService)
    .service("FeedbackService", FeedbackService)
    .service("OpenIDProvidersService", OpenIDProvidersService)
    .constant("drAsyncStatus", {
      Pending: 1,
      Success: 2,
      Error: 3,
    })
    .service("SearchQueryService", SearchQueryService)
    .constant("drSearchContext", {
      DocumentsOverview: "documents_overview",
    })
    .filter("drHighlightMatch", drHighlightMatch)
    .filter("capitalize", capitalizeFilter)
    .filter("drUserTime", drUserTime)
    .filter("bToMb", bToMb)
    .filter("drTimestamp", drTimestamp)
    .filter("drTimeSpent", drTimeSpent)
    .directive("drTooltipPopup", drTooltipPopup)
    .directive("drTooltip", drTooltip)
    .directive("drFitDropdown", drFitDropdown)
    .config(tooltipConfig)
    .value("postAndGetFile", postAndGetFile)
    .value("VueDrHelpModalContent", VueDrHelpModalContent)
    .value("VueCustomFieldsEditForm", VueCustomFieldsEditForm)
    .value("VueNgDatepicker", VueNgDatepicker)
    .value("VueDynamicDateIndicatorIcon", VueDynamicDateIndicatorIcon)
    .run(function () {
      applyFindPolyfill();
      applyEndsWithPolyfill();
      applyEntriesPolyfill();
    });

  function applyEntriesPolyfill() {
    if (!Object.entries) {
      Object.entries = function (obj) {
        var ownProps = Object.keys(obj),
          i = ownProps.length,
          resArray = new Array(i); // preallocate the Array
        while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

        return resArray;
      };
    }
  }

  function applyFindPolyfill() {
    if (!Array.prototype.find) {
      Object.defineProperty(Array.prototype, "find", {
        value: function (predicate) {
          if (this === null) {
            throw new TypeError(
              "Array.prototype.find called on null or undefined",
            );
          }
          if (typeof predicate !== "function") {
            throw new TypeError("predicate must be a function");
          }
          var list = Object(this);
          var length = list.length >>> 0;
          var thisArg = arguments[1];
          var value;

          for (var i = 0; i < length; i++) {
            value = list[i];
            if (predicate.call(thisArg, value, i, list)) {
              return value;
            }
          }
          return undefined;
        },
      });
    }
  }

  function applyEndsWithPolyfill() {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/endsWith
    if (!String.prototype.endsWith) {
      String.prototype.endsWith = function (searchStr, Position) {
        // This works much better than >= because
        // it compensates for NaN:
        if (!(Position < this.length)) Position = this.length;
        else Position |= 0; // round position
        return (
          this.substr(Position - searchStr.length, searchStr.length) ===
          searchStr
        );
      };
    }
  }

  function drAsyncFuncWrapper($q, drAsyncStatus) {
    /*
     NOTE: obsolete, do not use it
     Create wrapper of async function.
     Wrapper object have status and exec method,
     only last method execute is resolved.
     */
    var service = {
      wrap: wrap,
      AsyncStatus: drAsyncStatus,
    };

    var Wrapper = function (fn) {
      this.status = undefined;
      this.lastPromise = undefined;
      this.fn = fn;
    };

    Wrapper.prototype.exec = function () {
      var self = this;
      var deferred = (self.lastDeferred = $q.defer());
      self.status = drAsyncStatus.Pending;
      self.fn.apply(self.fn, arguments).then(
        function (result) {
          if (deferred === self.lastDeferred) {
            self.status = drAsyncStatus.Success;
            deferred.resolve(result);
          }
          return result;
        },
        function (error) {
          if (deferred === self.lastDeferred) {
            self.status = drAsyncStatus.Error;
            deferred.reject(error);
          }
          return error;
        },
      );

      self.lastPromise = deferred.promise;
      return self.lastPromise;
    };

    function wrap(fn, lastOnly) {
      if (fn === undefined) {
        throw new Error("Wrap function is undefined");
      }
      return new Wrapper(fn, lastOnly);
    }

    return service;
  }

  function NextPageService($window) {
    // browser does not provide #-hash in request url, backend can only handle host/path of url
    // so backend on redirect (for unauthenticated user e.g.) adds host/path to "redirect" var in url search
    // browser store url hash on redirect, and finally auth app receives full initial location
    //
    // so everything looks like this:
    // foo.dealroom.net/#/tasks/3 <- unauthenticated user click this link
    // dealroom.net/account/?redirect=foo.dealroom.net#/tasks/3 <- user is redirected to this page

    const redirectSearch =
      $window.location.search.match(/[?&]redirect=([^&]+)/);
    const hostPath = redirectSearch && decodeURIComponent(redirectSearch[1]);
    //<host>[<path>][#fragment] without proto scheme
    const hostRe = new RegExp(
      "^([-0-9a-zA-Z]+\\.)?" + // must start with subdomain or nothing
        $window.location.host.replace(/\./g, "\\.") + // host matches current host
        "(/.*)?$",
      "gm",
    );

    let redirect;
    if (hostPath) {
      // validate hostPath
      const hostMatch = hostPath.match(hostRe);
      if (hostMatch && hostMatch.length === 1) {
        redirect = hostPath + $window.location.hash;
      }
    }
    return {
      redirect: redirect,
      redirectToNextPage: function () {
        if (!redirect) return false;
        $window.location = "//" + redirect;
        return true;
      },
    };
  }

  function drSafeApply($rootScope) {
    return {
      apply: apply,
    };

    function apply(fn) {
      var phase = $rootScope.$$phase;
      if (phase == "$apply" || phase == "$digest") {
        if (fn && typeof fn === "function") {
          fn();
        }
      } else {
        $rootScope.$apply(fn);
      }
    }
  }

  function drEmailList() {
    /*
     email list validator
     */

    return {
      restrict: "A",
      require: "ngModel",
      scope: {},
      link: function (scope, elem, attrs, ctrl) {
        ctrl.$parsers.push(function (viewValue) {
          ctrl.$setValidity("emails", true);
          if (angular.isUndefined(viewValue)) {
            ctrl.$setValidity("emails", false);
            return undefined;
          }
          // split by newlines
          var values = viewValue.split(/\n+/);

          var parsedEmails = [];
          for (var i = 0; i < values.length; i++) {
            // remove trailing comma
            var line = values[i].replace(/,\s*$/, "");
            var emails = parseEmailLine(line);
            if (!emails) {
              ctrl.$setValidity("emails", false);
              return undefined;
            }
            parsedEmails = parsedEmails.concat(emails);
          }
          return parsedEmails;
        });
      },
    };
  }

  function drAutoFocus($timeout) {
    // set focus on directive render
    // by default focus on element itself, to focus on child element pass in params
    // like <div dr-auto-focus="input.control" >
    return {
      restrict: "A",
      link: function (scope, element, attrs) {
        // apply in next digest cycle, then element present in DOM
        $timeout(function () {
          if (attrs.drAutoFocus) {
            // focus on child element
            element = element.find(attrs.drAutoFocus);
          }
          var autoSelectValue = attrs["drAutoSelect"];
          if (autoSelectValue !== undefined) {
            var el = element[0];
            if (autoSelectValue === "file") {
              el.setSelectionRange(0, el.value.lastIndexOf("."));
            } else {
              el.setSelectionRange(0, el.value.length);
            }
          }
          element.focus();
        });
      },
    };
  }

  function drDropdown() {
    // not as powerfull as ui-dropdown, but with better render speed
    // should be used only if ui-dropdown is too slow
    // dropdown close on click only supported
    // .dropdown .dropdown-toggle and .dropdown-menu classes have to be added manually
    // provide isDropdownOpen attr and toogleDropdown method to scope
    // click handler on .dropdown-toggle have to be added manually
    // isDropdownOpen attr can be used to reduce watched by hiding menu

    return {
      restrict: "A",
      controller: [
        "$scope",
        "$element",
        "$attrs",
        "$parse",
        "$document",
        "$timeout",
        function ($scope, $element, $attrs, $parse, $document, $timeout) {
          $scope.isDropdownOpen = false;
          var toggleElement = $element.find(".dropdown-toggle");

          function onDocumentClick(evt) {
            if (!$scope.isDropdownOpen) {
              return;
            }

            if (evt && toggleElement && toggleElement[0].contains(evt.target)) {
              return;
            }
            closeDropdown();
          }

          function preventDefault(evt) {
            return evt.preventDefault();
          }

          function closeDropdown() {
            if (!$scope.isDropdownOpen) return;
            $element.removeClass("open");
            $document.unbind("mouseup", onDocumentClick);
            $document.unbind("contextmenu", preventDefault);
            $scope.isDropdownOpen = false;
            // it is here not in toggleDropdown because closeDropdown will ba call after onDocumentClick as well
            $scope.onToggle($scope.isDropdownOpen);
          }

          function openDropdown() {
            if ($scope.isDropdownOpen) return;
            $element.addClass("open");
            $document.bind("mouseup", onDocumentClick);
            $document.bind("contextmenu", preventDefault);
            $scope.isDropdownOpen = true;
            $scope.onToggle($scope.isDropdownOpen);
          }

          $scope.toggleDropdown = function () {
            $scope.isDropdownOpen ? closeDropdown() : openDropdown();
          };

          var onToggleInvoke = $attrs.onToggle
            ? $parse($attrs.onToggle)
            : angular.noop;
          $scope.onToggle = function (val) {
            $timeout(() => {
              onToggleInvoke($scope, { isDropdownOpen: val });
            });
          };
        },
      ],
    };
  }

  function downloadDialog($location, $timeout, ContactEmail) {
    return {
      restrict: "E",
      template:
        '<div ng-if="start">' +
        '<div ng-show="status == status_list.Pending || status == status_list.Processing">' +
        "<p>{{pend_msg}}</p>" +
        "<dr-loader></dr-loader>" +
        "</div>" +
        '<div ng-show="status == status_list.Success">' +
        '<p style="margin-bottom: 10px;">{{msg}}</p>' +
        '<div class="text-right"><a class="btn btn-primary" ng-href="{{ downloadUrl }}" target="_blank">Download</a></div>' +
        "</div>" +
        '<div ng-show="status == status_list.Fail">' +
        "<p>Something went wrong! Please try again, or contact " +
        '<a ng-href="mailto:{{ContactEmail}}" target="_blank">{{ ContactEmail }}</a></p>' +
        "</div>" +
        "</div>",
      scope: {
        start: "=start",
        stop: "=stop",
        msg: "=msg",
        pend_msg: "=pending",
        service: "=service",
        onSuccess: "=",
        onProgress: "=",
      },
      link: function (scope, elem, attrs) {
        var checkTimer,
          response_data,
          firstStateCheckTimeout = 1000, // ms
          exportStateCheckTimeout = 2000, // ms
          failedCheckMaxRetries = 3;

        scope.baseUrl = $location.protocol() + "://" + $location.host();
        scope.pend_msg = scope.pend_msg || "Packaging files...";
        scope.ContactEmail = ContactEmail;

        scope.status_list = {
          Pending: "pending",
          Processing: "processing",
          Success: "success",
          Fail: "fail",
        };
        scope.status = scope.status_list.Pending;

        function checkExportState() {
          if (scope.stop) {
            return;
          }
          var failedChecks = 0;
          scope.service.check(response_data).then(
            function successCallback(response) {
              var status = response.data.status;
              scope.status = status;

              if (status == scope.status_list.Fail) {
                return;
              }

              if (status == scope.status_list.Pending) {
                checkTimer = $timeout(
                  checkExportState,
                  exportStateCheckTimeout,
                );

                return;
              }

              if (status == scope.status_list.Processing) {
                if (scope.onProgress) scope.onProgress(response.data.progress);
                checkTimer = $timeout(
                  checkExportState,
                  exportStateCheckTimeout,
                );

                return;
              }
              if (status == scope.status_list.Success) {
                if (scope.onSuccess) scope.onSuccess(response.data.log);
              }
            },
            function errorCallback() {
              failedChecks += 1;
              if (failedChecks > failedCheckMaxRetries) {
                scope.status = scope.status_list.Fail;
              }
              // retry with extra delay on every fail
              checkTimer = $timeout(
                checkExportState,
                failedChecks * exportStateCheckTimeout,
              );
            },
          );
        }

        function startRequest() {
          scope.service.request().then(
            function successCallback(response) {
              response_data = response.data;
              scope.downloadUrl = scope.service.download_url(response_data);

              $timeout(() => {
                checkExportState();
                DrStore.dispatch("room/downloads/getDownloads");
              }, firstStateCheckTimeout);
            },
            function errorCallback() {
              scope.status = scope.status_list.Fail;
            },
          );
        }

        function cancel() {
          if (checkTimer) {
            $timeout.cancel(checkTimer);
          }

          if (
            response_data &&
            scope.status != scope.status_list.Success &&
            scope.service.cancel
          ) {
            scope.service.cancel(response_data);
          }
        }

        scope.$watch("start", function (start) {
          if (!start) return;
          startRequest();
        });

        scope.$watch("stop", function (stop) {
          if (!stop) return;
          cancel();
        });

        scope.$on("$destroy", function () {
          scope.stop = true;
        });
      },
    };
  }

  function ManageMembersService($q, $sce, $uibModal, AlertService) {
    function pluralize(emailsList, singular, plural) {
      return emailsList.length > 1 ? plural : singular;
    }
    function emailsListString(emailsList) {
      return emailsList
        .map(function (email) {
          return "<strong>" + email + "</strong>";
        })
        .join(", ");
    }

    function showInviteSuccessUpdates(result) {
      function getString(emailsList, verb) {
        return $sce.trustAsHtml(
          pluralize(emailsList, "User", "Users") +
            " " +
            emailsListString(emailsList) +
            " " +
            pluralize(emailsList, "was", "were") +
            " " +
            verb +
            ".",
        );
      }

      if (result.invited.length) {
        AlertService.success(getString(result.invited, "invited"));
      }

      if (result.moved.length) {
        AlertService.success(getString(result.moved, "moved"));
      }

      if (result.exists.length) {
        AlertService.warning(getString(result.exists, "cannot be moved"));
      }
    }

    function showInviteFailureError(result) {
      function getString(emailsList, verb) {
        return $sce.trustAsHtml(
          `Failed to ${verb} ` +
            pluralize(emailsList, "user", "users") +
            " " +
            emailsListString(emailsList) +
            ".",
        );
      }
      if (result.invited.length) {
        AlertService.danger(getString(result.invited, "invite"));
      }
      if (result.moved.length) {
        AlertService.danger(getString(result.moved, "move"));
      }
    }

    function confirmMembersManage(syncMethod, data, isInvite) {
      /* data: {
          members: [
            {email: ...},
            ...
            {email: ..., profile: {
              first_name: ...,
              last_name: ...,
              office_number: ...,
              title: ...,
              company: ...,
              // all of profile fields are optional
            }]}
            ,...
          ],
          message: ...
        } */
      return syncMethod(data, false).then(
        function (checkData) {
          var confirmationModal = $uibModal.open({
            template: members_inviteConfirmationHtml,
            controller: [
              "$scope",
              function ($scope) {
                $scope.result = checkData;
                $scope.isInvite = isInvite && $scope.result.invited.length;
                $scope.confirm = function () {
                  $scope.blockBtns = true;
                  return syncMethod(data, true).then(
                    function inviteConfirmSuccess(confirmData) {
                      showInviteSuccessUpdates($scope.result);
                      confirmationModal.close(confirmData);
                    },
                    function InviteConfirmFailure() {
                      showInviteFailureError($scope.result);
                      confirmationModal.dismiss();
                    },
                  );
                };
              },
            ],
          });
          return confirmationModal.result;
        },
        function checkUpdateFailed(error) {
          // this function is used for moving and inviting users
          // so set a failed message depends on the initial purpose
          var msg = "Invite request failed";
          if (!isInvite) {
            msg = "User" + (data.length > 1 ? "s" : "") + " moving failed";
          }
          AlertService.danger(msg);
          return $q.reject(error);
        },
      );
    }

    return {
      manage: confirmMembersManage,
    };
  }

  /* MIMETYPE identifier
   * @desc : identify the content type of the
   *         file and returns the css class name
   *         that sets the file icon.
   */
  function drMimetype() {
    return {
      getIconClass: getIconClass,
    };
  }

  function ignoreClickIf($parse, $rootScope) {
    // http://stackoverflow.com/questions/25600071/how-to-achieve-that-ui-sref-be-conditionally-executed
    return {
      // this ensure eatClickIf be compiled before ngClick
      priority: 100,
      restrict: "A",
      compile: function ($element, attr) {
        var fn = $parse(attr.ignoreClickIf);
        return {
          pre: function link(scope, element) {
            var eventName = "click";
            element.on(eventName, function (event) {
              var callback = function () {
                if (fn(scope, { $event: event })) {
                  // prevents ng-click to be executed
                  event.stopImmediatePropagation();
                  // prevents href
                  event.preventDefault();
                  return false;
                }
              };
              if ($rootScope.$$phase) {
                scope.$evalAsync(callback);
              } else {
                scope.$apply(callback);
              }
            });
          },
          post: function () {},
        };
      },
    };
  }

  function DownloadFile() {
    // see https://github.com/PixelsCommander/Download-File-JS
    var isChrome = navigator.userAgent.toLowerCase().indexOf("chrome") > -1;
    var isSafari = navigator.userAgent.toLowerCase().indexOf("safari") > -1;

    return function (fileUrl, fileName) {
      //iOS devices do not support downloading. We have to inform user about this.
      if (/(iP)/g.test(navigator.userAgent)) {
        window.open(fileUrl);
      }

      //If in Chrome or Safari - download via virtual link click
      if (isChrome || isSafari) {
        //Creating new link node.
        var link = document.createElement("a");
        link.href = fileUrl;

        if (link.download !== undefined) {
          //Set HTML5 download attribute. This will prevent file from opening if supported.
          link.download = fileName
            ? fileName
            : fileUrl.substring(fileUrl.lastIndexOf("/") + 1, fileUrl.length);
        }

        //Dispatching click event.
        if (document.createEvent) {
          var e = document.createEvent("MouseEvents");
          e.initEvent("click", true, true);
          link.dispatchEvent(e);
          return true;
        }
      }

      window.open(fileUrl, "_self");
      return true;
    };
  }

  function drServerError() {
    return {
      restrict: "A",
      require: "ngModel",
      link: function (scope, elem, attr, ctrl) {
        ctrl.setServerError = function (value) {
          ctrl.$setValidity("server", !value);
          ctrl.drServerError = value;
        };
        ctrl.$parsers.unshift(function (value) {
          // remove server non field error
          if (
            ctrl.$$parentForm.non_field_errors &&
            ctrl.$$parentForm.non_field_errors.$error.server
          ) {
            ctrl.$$parentForm.non_field_errors.$setValidity("server", true);
          }
          if (ctrl.drServerError === undefined) {
            return value;
          }
          var errorValue = ctrl.drServerError;
          if (errorValue !== value) {
            ctrl.$setValidity("server", true);
            ctrl.drServerError = undefined;
          }
          return value;
        });
      },
    };
  }

  function compareTo() {
    return {
      require: "ngModel",
      scope: {
        otherModelValue: "=compareTo",
      },
      link: function (scope, element, attributes, ngModel) {
        ngModel.$validators.compareTo = function (modelValue) {
          return modelValue == scope.otherModelValue;
        };

        scope.$watch("otherModelValue", function () {
          ngModel.$validate();
        });
      },
    };
  }

  function RaiseServerValidationErrors() {
    return function (data, form) {
      let setError = false;
      angular.forEach(form, function (field, key) {
        if (
          typeof field === "object" &&
          Object.prototype.hasOwnProperty.call(field, "$modelValue") &&
          Object.prototype.hasOwnProperty.call(field, "setServerError")
        ) {
          field.setServerError(data[key]);
          setError = true;
        }
      });
      return setError;
    };
  }

  function capitalizeFilter() {
    return function (input, scope) {
      // capitalize first letters in all words
      if (input != null) return capitalize(input);
    };
  }

  function drUserTime() {
    return function (value) {
      if (value === undefined) return null;
      if (value === null) return null;
      if (value === "") return null;
      if (isDate(value) && !isValid(value)) return null;

      const tz = USER_DATA.profile.timezone;
      const date = isDate(value)
        ? value
        : isISODateString(value)
          ? toDate(value, { timeZone: tz })
          : typeof value === "number"
            ? new Date(value)
            : parseISO(value);

      if (!isValid(date)) return null;

      return toZonedTime(date, tz);
    };
  }

  function bToMb() {
    return function (size) {
      // convert size in bytes to mb
      if (size != null) {
        var mb = size / 1024 / 1024;
        // show 2 decimal
        return Math.round(mb * 100) / 100;
      }
    };
  }

  function drTimestamp() {
    return function (time) {
      const formatted = _drUserTime(time, "MMM dd • h:mma");
      if (formatted === null) return "";

      return formatted;
    };
  }

  function drTimeSpent($filter) {
    var numberFilter = $filter("number");
    return function (dt) {
      return numberFilter(dt, 0);
    };
  }

  function drLoader() {
    return {
      restrict: "E",
      template: '<div class="loader-holder"><i class="loader"><i/></div>',
    };
  }

  function AlertService($sce) {
    var service = {
      alerts: [],
      closeAlert: closeAlert,
      success: addSuccess,
      info: addInfo,
      warning: addWarning,
      danger: addDanger,
      dismissOnTimeout: 4000,
    };
    return service;

    function closeAlert(alert) {
      var index = service.alerts.indexOf(alert);
      service.alerts.splice(index, 1);
    }

    function add(msg, msgType, duration) {
      // msg: string | undefined | $sce.trustAsHtml's trusted value;
      //      if msg is string it'll be html-escaped
      //      if msg is trusted html, it'll be used directly
      // if duration is 0, alert will not be dismissed automatically
      let alertMsg;
      if (msg) {
        // trusted $sce.trustAsHtml's value
        if (msg.$$unwrapTrustedValue) {
          alertMsg = msg.toString();
        } else {
          alertMsg = escape(msg);
        }
        alertMsg = _capitalize(alertMsg);
      } else {
        alertMsg = "Request is failed";
      }

      const alert = {
        msg: $sce.trustAsHtml(alertMsg),
        class: "alert-message__item--" + msgType,
        duration: angular.isDefined(duration)
          ? duration
          : service.dismissOnTimeout,
      };
      service.alerts.push(alert);
    }

    function addSuccess(msg, duration) {
      add(msg, "success", duration);
    }

    function addInfo(msg, duration) {
      add(msg, "info", duration);
    }

    function addWarning(msg, duration) {
      add(msg, "warning", duration);
    }

    function addDanger(msg, duration) {
      add(msg, "danger", duration);
    }
  }

  function drAlert() {
    return {
      template: alertMessage_alertMessageHtml,
      controller: [
        "AlertService",
        "drSafeApply",
        function (AlertService, drSafeApply) {
          var $ctrl = this;
          $ctrl.AlertService = AlertService;
          $ctrl.checkClose = function (alert) {
            var check = function (prcnt) {
              if (prcnt == 100) {
                drSafeApply.apply(function () {
                  AlertService.closeAlert(alert);
                });
              }
            };
            return check;
          };
        },
      ],
    };
  }

  function onCtrlEnter() {
    return {
      restrict: "A",
      link: function (scope, elem, attrs) {
        function handleCtrlEnter(e) {
          if (e.ctrlKey && e.key === "Enter") {
            scope.$apply(attrs.onCtrlEnter);
          }
        }

        // if it is a modal without input - focus will be on .modal
        if (elem.hasClass("modal-body")) elem = elem.closest(".modal");

        elem.on("keydown", handleCtrlEnter);

        scope.$on("$destroy", function () {
          elem.off("keydown", handleCtrlEnter);
        });
      },
    };
  }

  function NavKeyHandler() {
    const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
    const modKey = isMac ? "alt" : "ctrl";
    const modKeyAttr = `${modKey}Key`;
    const arrowLeft = 37;
    const arrowRight = 39;
    return {
      handle: function (element, onNext, onPrev) {
        const skipEvOnElements = ["TEXTAREA", "INPUT"];
        const handlerKeyDown = function (ev) {
          const target = ev.target;
          if (
            skipEvOnElements.includes(target.tagName) ||
            (target.tagName === "DIV" &&
              target.className.includes("ProseMirror"))
          ) {
            return;
          }
          if (ev[modKeyAttr]) {
            if (ev.keyCode === arrowRight) {
              onNext(ev);
            }
            if (ev.keyCode === arrowLeft) {
              onPrev(ev);
            }
          }
        };
        element.on("keydown", handlerKeyDown);
        return {
          modKey,
          unbind: () => element.unbind("keydown", handlerKeyDown),
        };
      },
    };
  }

  function drInRowMenu() {
    return {
      bindings: {
        getMenu: "=menu",
        item: "=",
        class: "@",
      },
      template:
        '<div class="dropdown context-menu dr-row-menu" ng-class="::$ctrl.class"' +
        ' context-menu="$ctrl.getMenu($ctrl.item)" context-menu-on="click">' +
        '<a href="" class="dropdown-toggle">' +
        '<i class="fas fa-ellipsis-h"></i>' +
        "</a>" +
        "</div>",
    };
  }

  function drOnDragStartDisabled() {
    return {
      restrict: "A",
      link: function (scope, elem, attrs) {
        elem[0].ondragstart = function () {
          return false;
        };
      },
    };
  }

  function drTrainingVideo() {
    return {
      bindings: {
        video: "<",
      },
      template: help_trainingVideoHtml,
      controller: [
        "$scope",
        "$sce",
        function ($scope, $sce) {
          const $ctrl = this;
          $ctrl.embedCode = "";
          $scope.$watch("$ctrl.video", function (video) {
            $ctrl.embedCode = $sce.trustAsHtml(video.embedCode);
          });
        },
      ],
    };
  }

  function drTrainingVideos() {
    return {
      bindings: {
        selectedVideo: "<",
      },
      template: help_trainingVideosHtml,
      controller: [
        "TrainingVideosService",
        function (TrainingVideosService) {
          const $ctrl = this;
          $ctrl.videos = TrainingVideosService.getViewableVideosModels();
          $ctrl.$onInit = function () {
            if (!$ctrl.selectedVideo) $ctrl.selectedVideo = $ctrl.videos[0];
          };
        },
      ],
    };
  }

  function drSearchField($state, $compile) {
    return {
      restrict: "E",
      template: searchField_searchFieldHtml,
      scope: {
        ngModel: "=",
        ngChange: "=?",
        ngSubmit: "=?",
      },
      controller: [
        "$scope",
        function ($scope) {
          $scope.clear = function () {
            $scope.ngModel = "";
            if ($scope.ngChange) {
              $scope.ngChange();
            }
          };
        },
      ],
    };
  }

  function drCopySelected() {
    return {
      link: function ($scope, element, attrs) {
        if (document.execCommand) {
          element.bind("click", function () {
            // window.getSelection() works only if element has a focus
            $(attrs.drCopySelected).focus();
            // document.execCommand works only after event raised
            var success = document.execCommand("copy");
            var msg = success ? "Copied" : "Failed to copy";
            element.text(msg);
          });
        } else {
          element.hide();
        }
      },
    };
  }

  function drCopyToClipboard(AlertService, drSafeApply) {
    return {
      link: function ($scope, element, attrs) {
        if (document.execCommand) {
          element.bind("click", function () {
            var text = attrs.drCopyToClipboard;
            var success = copyToClipboard(text);
            drSafeApply.apply(function () {
              if (success) {
                AlertService.success(
                  attrs.drCopyMsg || "The value has been copied.",
                );
              } else {
                AlertService.danger("Failed to copy text to clipboard.");
              }
            });
          });
        } else {
          element.hide();
        }
      },
    };
  }

  function drHighlightMatch($sce) {
    var highlight_open_tag = "<b>";
    var highlight_close_tag = "</b>";
    return function (str, query) {
      str = escape(str);
      if (!query) {
        return $sce.trustAsHtml(str);
      }
      var matchPositions = fuzzaldrinPlus.match(str, query);
      if (!matchPositions || matchPositions.length === 0) return str;
      var output = "";
      var matchIndex = -1;
      var strPos = 0;
      while (++matchIndex < matchPositions.length) {
        var matchPos = matchPositions[matchIndex];
        // Get text before the current match position
        if (matchPos > strPos) {
          output += str.substring(strPos, matchPos);
          strPos = matchPos;
        }
        // Get consecutive positions from the array
        while (++matchIndex < matchPositions.length) {
          if (matchPositions[matchIndex] == matchPos + 1) {
            matchPos++;
          } else {
            matchIndex--;
            break;
          }
        }
        //Get text inside the match, including current
        matchPos++;
        if (matchPos > strPos) {
          output += highlight_open_tag;
          output += str.substring(strPos, matchPos);
          output += highlight_close_tag;
          strPos = matchPos;
        }
      }
      output += str.substring(strPos);
      return $sce.trustAsHtml(output);
    };
  }

  function FeedbackService(URLS, AlertService, $http) {
    function send(data, msg) {
      return $http.post(URLS["feedback"](), data).then(
        function successCallback() {
          AlertService.success(msg);
        },
        function errorCallback() {
          AlertService.danger("Failed to send a message.");
        },
      );
    }

    return {
      activate: function () {
        var data = { type_name: "activate" };
        var msg =
          "Thank you for your message. Account executives will contact you shortly.";
        return send(data, msg);
      },
      requestNewRoom: function () {
        var data = { type_name: "request-room" };
        var msg =
          "Your request has been sent!. Account executives will contact you shortly.";
        return send(data, msg);
      },
      send: function (data) {
        var msg =
          "Thank you for your feedback. Support will contact you shortly.";
        return send(data, msg);
      },
    };
  }

  function SearchQueryService(URLS, $http, drSearchContext) {
    var self = {
      getRecentQueries: getRecentQueries,
      saveQuery: saveQuery,
    };

    const searchUrl = URLS["api:room:search"]();
    var QUERY_COMPLETION_TIMEOUT = 300;

    function getRecentQueries(query, context, limit) {
      console.assert(Object.values(drSearchContext).includes(context));

      return $http
        .get(searchUrl, {
          params: {
            context: context,
            query: query,
            limit: limit,
          },
          timeout: QUERY_COMPLETION_TIMEOUT,
        })
        .then(function (response) {
          var queries = response.data;
          return map(queries, "text");
        })
        .catch(function () {
          return [];
        });
    }

    function saveQuery(query, context) {
      console.assert(Object.values(drSearchContext).includes(context));
      console.assert(query);
      return $http.post(searchUrl, { text: query, context: context });
    }

    return self;
  }

  function drTooltipPopup() {
    // copy-paste from uibTooltipPopup to work with drTooltip
    return {
      restrict: "A",
      scope: { content: "@" },
      templateUrl: "uib/template/tooltip/tooltip-popup.html",
    };
  }

  function drTooltip($uibTooltip) {
    // works like uibTooltip, but show tooltip only if element is truncated
    var prefix = "tooltip";
    var tooltipDirective = $uibTooltip("drTooltip", prefix, "mouseenter");
    var originalLink = tooltipDirective.compile();
    var enableAttr = prefix + "Enable";
    return {
      link: function (scope, element, attrs, tooltipCtrl) {
        // overwrite tooltipEnable check
        var originalEnable = attrs[enableAttr];
        attrs[enableAttr] = function () {
          // check original tooltip enable function if exists
          if (originalEnable && !scope.$eval(originalEnable)) return false;
          // check that element is truncated
          var el = element[0];
          return el.offsetWidth < el.scrollWidth;
        };
        // call uibTooltip link function
        return originalLink(scope, element, attrs, tooltipCtrl);
      },
    };
  }

  function drFitDropdown($timeout) {
    return {
      scope: {
        isOpen: "<",
        bottomOffset: "<",
        drFitDropdownRegister: "<",
      },
      priority: -10,
      link: function ($scope, element) {
        function handleOpen() {
          if ($scope.isOpen) {
            const windowHeight = $(window).height();
            var dropUpBorder = windowHeight / 2;
            var elemTop = element[0].getBoundingClientRect().top;
            var menu = $(element).find(".dropdown-menu");
            if (elemTop > dropUpBorder) {
              // wait while dropdown is open
              $timeout(function () {
                var elemHeight = menu.height() || 340; // in case dropdown-menu didnt get time to render
                // be sure we have enough space at the top for dropdown-menu
                const overflowWindow =
                  !$scope.bottomOffset ||
                  windowHeight < $scope.bottomOffset + elemTop + elemHeight;
                if (elemTop > elemHeight && overflowWindow) {
                  element.addClass("dropup");
                }
              });
            }
          } else {
            //on close
            element.removeClass("dropup");
          }
        }
        if ($scope.drFitDropdownRegister) {
          $scope.drFitDropdownRegister(handleOpen);
        }
        $scope.$watch("isOpen", function () {
          handleOpen();
        });
      },
    };
  }

  function tooltipConfig($uibTooltipProvider) {
    $uibTooltipProvider.options({
      appendToBody: true,
      popupDelay: 500,
    });
  } /* End tooltipConfig */

  function drUserInformation() {
    return {
      template: users_userInformationHtml,
      bindings: {
        userId: "<",
        showAvatar: "<?", // true by default
        showName: "<?", // true by default
        showTooltip: "<?", // true by default
        isLink: "<",
        openInNewTab: "<?", // true by default
        email: "<",
        size: "@",
        showHeadline: "<",
        mlName: "<",
      },
      controller: [
        "$scope",
        "$state",
        "MembersService",
        function ($scope, $state, MembersService) {
          var $ctrl = this;
          var anonymousUser = { name: "Anonymous", isAnonymous: true };
          $ctrl.Members = MembersService;

          $ctrl.$onInit = function () {
            $ctrl.showAvatar = angular.isDefined($ctrl.showAvatar)
              ? $ctrl.showAvatar
              : true;
            $ctrl.showName = angular.isDefined($ctrl.showName)
              ? $ctrl.showName
              : true;
            $ctrl.showTooltip = angular.isDefined($ctrl.showTooltip)
              ? $ctrl.showTooltip
              : true;
            $ctrl.openInNewTab = angular.isDefined($ctrl.openInNewTab)
              ? $ctrl.openInNewTab
              : true;

            if ($ctrl.email) {
              // for invites
              $ctrl.user = { name: $ctrl.email };
            } else {
              var watch = $scope.$watch("$ctrl.Members.loading", function () {
                if (!MembersService.isLoaded()) return;

                watch(); // unwatch

                var user = MembersService.members[$ctrl.userId];
                if (user) {
                  $ctrl.user = user;
                  $ctrl.link = $ctrl.isLink
                    ? memberUrl(ROOM_DATA.url, $ctrl.userId)
                    : "";
                } else {
                  $ctrl.user = anonymousUser;
                }
              });
            }
          };
        },
      ],
    };
  }

  function drUserAvatar() {
    return {
      template: users_userAvatarHtml,
      bindings: {
        avatarUrl: "<",
        initials: "<",
        size: "<?",
        isAnonymous: "<",
        isInvite: "<",
        isStatic: "=?", // True by default
      },
      controller: function () {
        var $ctrl = this;
        var maxFZ = 16,
          prcnt = 0.3;
        $ctrl.$onInit = function () {
          $ctrl.isStatic = angular.isDefined($ctrl.isStatic)
            ? $ctrl.isStatic
            : true;
          $ctrl.size = $ctrl.size ? $ctrl.size : "25px";
          $ctrl.fontSize = Math.min(
            maxFZ,
            Math.ceil(parseInt($ctrl.size) * prcnt),
          );

          if ($ctrl.isStatic) {
            $ctrl.avatarUrl = $ctrl.avatarUrl || "";
            $ctrl.isAnonymous = !!$ctrl.isAnonymous;
            $ctrl.isInvite = !!$ctrl.isInvite;
          }
        };
      },
    };
  }

  function drQrCode() {
    // render QrCode from 2d array
    return {
      bindings: {
        code: "<",
      },
      template: `<table><tbody>
        <tr ng-repeat="row in $ctrl.code track by $index">
          <td ng-repeat="cell in row  track by $index" class="{{ ::(cell? 'black': 'white') }}"></td>
        </tr>
      </tbody></table>`,
    };
  }

  function drDatepicker() {
    return {
      template: datepicker_datepickerHtml,
      bindings: {
        dt: "<",
        update: "&",
        showClearAlways: "<",
      },
      controller: function () {
        const $ctrl = this;
        let prevDt;

        $ctrl.$onInit = function () {
          prevDt = $ctrl.dt;
        };

        $ctrl.updateIfChanged = function () {
          if (prevDt !== $ctrl.dt) {
            $ctrl.update({ dt: $ctrl.dt });
            prevDt = $ctrl.dt;
          }
        };
      },
    };
  }

  function drFlexTable($timeout) {
    return {
      restrict: "A",
      priority: -10,
      link: function ($scope, element) {
        // header is not a part of table, so it does not have scroll
        // and if table have scroll add some offset to header to alight columns in table and header
        var bodySelector = "> .flex-table_body";
        var headerSelector = "> .flex-table__row--header";

        var tableBody = element.find(bodySelector)[0];
        var tableHeader = element.find(headerSelector)[0];
        var marginRight = 0;
        $scope.$watch(function () {
          // run after $digest, so getting values will not cause rerender from the browser
          $timeout(
            function () {
              var newMarginValue =
                tableBody.scrollHeight > tableBody.clientHeight;
              if (newMarginValue !== marginRight) {
                marginRight = newMarginValue;
                tableHeader.style.marginRight =
                  tableBody.offsetWidth - tableBody.clientWidth + "px";
              }
            },
            0,
            false,
          );

          return true;
        });
      },
    };
  }

  function drSearchCompletionBox() {
    return {
      template: searchField_searchCompletionBoxHtml,
      bindings: {
        onItemSelected: "&",
        onGoToSearchResults: "&",
        suggestItems: "&",
        searchQuery: "=",
        searchContext: "<",
        maxDisplayedItems: "<",
      },

      controller: [
        "SearchQueryService",
        function (SearchQueryService) {
          var QUERY_LIMIT = 3;

          var $ctrl = this;

          $ctrl.handleItemSelected = handleItemSelected;
          $ctrl.suggestQueriesAndItems = suggestQueriesAndItems;
          $ctrl.submitSearchQuery = submitSearchQuery;
          $ctrl.resetSearchResults = resetSearchResults;

          $ctrl.$onInit = function () {
            $ctrl.isInSearchMode = !!$ctrl.searchQuery;
          };

          function handleItemSelected($item, $event) {
            if ($item.type == "query") {
              goToSearchResults($item.text);
              $event.stopPropagation();
              return;
            }
            $ctrl.onItemSelected({ $item: $item, $event: $event });
          }

          function suggestQueriesAndItems(query) {
            return SearchQueryService.getRecentQueries(
              query,
              $ctrl.searchContext,
              QUERY_LIMIT,
            ).then(function (recentQueries) {
              recentQueries = recentQueries.map(function (tq) {
                return { text: tq, type: "query" };
              });
              var suggestedItems = $ctrl
                .suggestItems({ $query: query })
                .splice(0, $ctrl.maxDisplayedItems);
              var allSuggestedItems = recentQueries.concat(suggestedItems);
              return allSuggestedItems;
            });
          }

          function submitSearchQuery(query) {
            if (query) {
              goToSearchResults(query);
            } else {
              resetSearchResults();
            }
          }

          function goToSearchResults(query) {
            $ctrl.isInSearchMode = true;
            SearchQueryService.saveQuery(query, $ctrl.searchContext);
            $ctrl.onGoToSearchResults({ $query: query });
          }

          function resetSearchResults() {
            $ctrl.onGoToSearchResults();
            $ctrl.isInSearchMode = false;
          }
        },
      ],
    };
  }

  function drHelpModal() {
    return {
      template:
        '<a class="room-havbar__item-link room-havbar--icon" href="" rel="modal" role="button" ng-click="$ctrl.openModal()">' +
        '<i class="fa fa-question-circle"></i>' +
        "</a>",
      controller: [
        "$uibModal",
        function ($uibModal) {
          this.openModal = function () {
            $uibModal.open({
              template: help_helpModalHtml,
              windowClass: "help-modal modal--wide",
            });
          };
        },
      ],
    };
  }

  function drRoomLogo() {
    return {
      template: room_logoHtml,
      controller: [
        "RoomConfig",
        function (RoomConfig) {
          this.RoomConfig = RoomConfig;
        },
      ],
    };
  }

  function drStoragedCheckbox() {
    return {
      template: `<span>
                    <span ng-click="$ctrl.updateState()">{{ :: $ctrl.label }}</span>
                    <dr-checkbox
                        checked="$ctrl.checked"
                        click="$ctrl.updateState()"
                        >
                    </dr-checkbox>
                </span>`,
      bindings: {
        click: "<",
        label: "@",
        uid: "@",
      },
      controller: function () {
        const $ctrl = this;
        let uid;
        $ctrl.$onInit = function () {
          uid = ("drStoragedCheckbox-" + $ctrl.uid + "-" + $ctrl.label).replace(
            / /g,
            "-",
          );

          $ctrl.checked = !!sessionStorage[uid];
          $ctrl.click($ctrl.checked);
        };

        $ctrl.updateState = () => {
          $ctrl.checked = !$ctrl.checked;
          sessionStorage[uid] = $ctrl.checked ? "checked" : "";
          $ctrl.click($ctrl.checked);
        };
      },
    };
  }

  function getIcons() {
    return {
      plusIco: {
        template: icons_plusIcoHtml,
        bindings: {
          disabled: "=",
        },
      },
      usersIco: {
        template: icons_usersIcoHtml,
        bindings: {
          disabled: "=",
          active: "=",
        },
      },
      checkIco: {
        template: icons_checkIcoHtml,
        bindings: {
          check: "=",
          icoColor: "@",
        },
      },
      priorityIco: {
        template: icons_priorityIcoHtml,
        bindings: {
          level: "=",
        },
      },
      statusIco: {
        template: icons_statusIcoHtml,
        bindings: {
          statusId: "=",
        },
      },
      followIco: {
        template: icons_followIcoHtml,
        bindings: {
          isFollower: "=",
        },
      },
      reviewIco: {
        template: icons_reviewIcoHtml,
        bindings: {
          isReviewed: "=",
        },
      },
      relatedTasks: {
        template: icons_relatedRequestsIcoHtml,
        bindings: {
          isHoverable: "=",
          isPrimary: "=",
        },
      },
      newFolder: {
        template: icons_newFolderIcoHtml,
      },
      checkbox: {
        template: icons_checkboxHtml,
        bindings: {
          click: "&",
          checked: "=",
          indeterminate: "<",
          disabled: "<",
        },
        controller: function () {
          function guid() {
            function s4() {
              return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
            }

            return (
              s4() +
              s4() +
              "-" +
              s4() +
              "-" +
              s4() +
              "-" +
              s4() +
              "-" +
              s4() +
              s4() +
              s4()
            );
          }

          var uid;
          var $ctrl = this;
          while (angular.isUndefined($ctrl.prefix)) {
            uid = guid();
            if (!document.getElementById(uid)) {
              $ctrl.prefix = uid;
            }
          }
        },
      },
    };
  }

  // fixes https://github.com/angular-ui-tree/angular-ui-tree/issues/836
  angular
    .module("ui-tree-helpers", [])
    // https://github.com/marklagendijk/angular-recursion
    .factory("RecursionHelper", [
      "$compile",
      function ($compile) {
        return {
          /**
           * Manually compiles the element, fixing the recursion loop.
           * @param element
           * @param [link] A post-link function, or an object with function(s) registered via pre and post properties.
           * @returns An object containing the linking functions.
           */
          compile: function (element, link) {
            // Normalize the link parameter
            if (angular.isFunction(link)) {
              link = { post: link };
            }

            // Break the recursion loop by removing the contents
            var contents = element.contents().remove();
            var compiledContents;
            return {
              pre: link && link.pre ? link.pre : null,
              /**
               * Compiles and re-adds the contents
               */
              post: function (scope, element) {
                // Compile the contents
                if (!compiledContents) {
                  compiledContents = $compile(contents);
                }
                // Re-add the compiled contents to the element
                compiledContents(scope, function (clone) {
                  element.append(clone);
                });

                // Call the post-linking function, if any
                if (link && link.post) {
                  link.post.apply(null, arguments);
                }
              },
            };
          },
        };
      },
    ])

    // https://github.com/angular-ui-tree/angular-ui-tree/pull/841
    .directive("uiTreeHelpersNodesRenderer", [
      "RecursionHelper",
      "$templateCache",
      function (RecursionHelper, $templateCache) {
        return {
          scope: true,
          restrict: "A",
          template: function (tElem, tAttrs) {
            return $templateCache.get(tAttrs.nodeRenderTemplate);
          },
          compile: function (element) {
            return RecursionHelper.compile(
              element,
              function (scope, iElement, iAttrs, controller) {
                scope.currentNode = scope.$eval(iAttrs.currentNode);
              },
            );
          },
        };
      },
    ]);
})();
