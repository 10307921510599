import { defineStore } from "pinia";
import { reactive, ref } from "vue";

import { RoomApiService } from "@drVue/api-service/common/rooms";

import type { OrgRecentRoom } from "@drVue/api-service/common/rooms";

const $apiRooms = new RoomApiService();

/**
 * Current user organization data
 */
export const useOrgStore = defineStore("organization", () => {
  const initialized = ref(false);

  const rooms = ref<OrgRecentRoom[]>([]);
  const roomsRequest = reactive({
    isPending: false,
    error: "",
  });

  const getRooms = async (force = false) => {
    if (!force && initialized.value) {
      return;
    }

    if (roomsRequest.isPending) {
      return;
    }

    roomsRequest.isPending = true;
    roomsRequest.error = "";

    try {
      rooms.value = await $apiRooms.orgRecentRooms();
    } catch (error) {
      if (error instanceof Error) {
        roomsRequest.error = error.message;
      } else {
        roomsRequest.error = String(error);
      }
    }

    roomsRequest.isPending = false;
    initialized.value = true;
  };

  const getRoomById = (id: OrgRecentRoom["id"]) => {
    return rooms.value.find((room) => room.id === id) || null;
  };

  const getRoomByUid = (uid: OrgRecentRoom["uid"]) => {
    return rooms.value.find((room) => room.uid === uid) || null;
  };

  return { rooms, getRooms, getRoomById, getRoomByUid };
});
