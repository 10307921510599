<template>
  <div :class="$style.container">
    <div :class="$style.roomInfo">
      <RoomHeaderNavTitle is-title-link />
    </div>
    <div :class="$style.docPath">
      <a
        class="truncate"
        :class="$style.linkReset"
        target="_blank"
        :href="`${ROOM_DATA.url}/#/documents/folder/${documentInfo.folder.id}`"
      >
        <DrIcon name="folder" />
        <span :class="$style.pathElement">
          {{ documentInfo.folder.tree_index }} {{ documentInfo.folder.name }}
        </span>
      </a>
      <span :class="$style.pathDelimiter">/</span>
      <DrPopup v-if="siblings.length" paddingless>
        <template #reference>
          <Filename :name="documentInfo.name" :mimetype="documentInfo.mimetype">
            <template #suffix>
              <DrIcon :class="$style.siblingsButton" name="caret-down" />
            </template>
          </Filename>
        </template>
        <template #default>
          <SiblingsList :siblings="siblings" />
        </template>
      </DrPopup>
      <Filename
        v-else
        :name="documentInfo.name"
        :mimetype="documentInfo.mimetype"
      />
    </div>
    <div :class="$style.actions">
      <ElInput
        v-model="searchTerm"
        placeholder="Type to search"
        :class="$style.inputSearch"
        @keydown.enter="emitSearch"
      >
        <template #prefix>
          <DrIcon name="search" />
        </template>
      </ElInput>
      <DrTooltip :content="bookmarked ? 'Unbookmark' : 'Bookmark'">
        <ElButton
          :class="$style.bookmark"
          :type="bookmarked ? 'info' : ''"
          @click="toggleBookmarked()"
        >
          <DrIcon name="bookmark" />
        </ElButton>
      </DrTooltip>
      <ElButton
        @click="model === 'info' ? (model = null) : (model = 'info')"
        :type="model === 'info' ? 'primary' : 'default'"
        :plain="model === 'info'"
      >
        <template #icon><DrIcon name="info-circle" /></template>
        Info
      </ElButton>
      <ElButton
        v-if="isAiEnabled"
        @click="model === 'ai' ? (model = null) : (model = 'ai')"
        :type="model === 'ai' ? 'primary' : 'default'"
        :plain="model === 'ai'"
      >
        <template #icon><IconAiAnalysis /></template> AI Analysis
      </ElButton>
      <DownloadDocumentButton
        :download-original="documentInfo.permission.download_original"
        :document-id="documentInfo.id"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { debounce } from "lodash-es";
import { ref, watch } from "vue";
import DrIcon from "@shared/ui/dr-icon";
import { DrPopup } from "@shared/ui/dr-popups";

import { ROOM_DATA, ROOM_MEMBER_DATA } from "@setups/data";
import { AiAccess } from "@app/setups/enums";
import { DrStore } from "@app/vue";
import DrTooltip from "@app/vue/shared/ui/dr-tooltip/DrTooltip.vue";
import RoomHeaderNavTitle from "@drVue/components/room/common/RoomHeaderNavTitle.vue";
import IconAiAnalysis from "../documents/svg/IconAiAnalysis.vue";
import DownloadDocumentButton from "./DownloadDocumentButton.vue";
import Filename from "./Filename.vue";
import SiblingsList from "./SiblingsList.vue";

import type { DocumentSibling, DocumentViewerInfo } from "./types";
import type { BookmarkItemsPayload } from "@app/vue/store/modules/room/documents/DocumentsApiService";

const props = defineProps<{
  siblings: DocumentSibling[];
  documentInfo: DocumentViewerInfo;
}>();

const emit = defineEmits<{
  (e: "search", term: string): void;
  (e: "bookmark"): void;
  (e: "download"): void;
}>();

const model = defineModel<null | "ai" | "info">();

const searchTerm = ref("");

const isAiEnabled =
  ROOM_DATA.isAiEnabled && ROOM_MEMBER_DATA.group.ai_access === AiAccess.BASIC;

const emitSearch = debounce(() => {
  emit("search", searchTerm.value);
}, 500);

watch(searchTerm, () => emitSearch());

const bookmarked = ref(props.documentInfo.bookmarked);
const toggleBookmarked = () => {
  const set_bookmarked = !bookmarked.value;
  DrStore.dispatch("room/documents/bookmarkItems", {
    document_ids: [props.documentInfo.id],
    folder_ids: [],
    set_bookmarked,
  } satisfies BookmarkItemsPayload).then(() => {
    bookmarked.value = set_bookmarked;
  });
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography.scss";
@use "./vars";

.container {
  background: white;
  grid-template-columns: fit-content(25%) auto min-content;
  align-items: center;
  display: grid;
  height: vars.$topbar-height;
  color: colors.$pr-400;
  font: typography.$body_regular;

  // z-index is temporary required to work with old COOL viewer styles
  z-index: 1;
  position: relative;
}

.roomInfo {
  height: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid colors.$pr-200;
  padding: 0 16px;
  color: colors.$pr-800;

  span {
    margin-left: 8px;
  }
}

.docPath {
  height: 100%;
  padding-left: 23px;
  display: flex;
  align-items: center;
  overflow-x: hidden;
}

.pathElement {
  margin: 0 10px;
  color: colors.$pr-800;

  &:hover {
    color: colors.$pr-900;
  }
}

.pathDelimiter {
  margin: 0 8px 0 0;
}

.siblingsButton {
  color: colors.$pr-350;

  &:hover {
    color: colors.$pr-600;
  }
}

.actions {
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  // We don't want Element Plus to interfere with `gap` of .actions
  :global(.el-button + .el-button) {
    margin-left: unset;
  }
}

.inputSearch {
  width: 180px !important; // Has to override element's 100%
}

.linkReset {
  color: unset;

  &:hover,
  &:focus {
    color: unset;
    text-decoration: unset;
  }
}

.bookmark {
  width: 32px;
}
</style>
