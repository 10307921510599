<template>
  <DrVxeGrid
    outlined
    :checkbox-config="{
      highlight: true,
      range: true,
      checkField: '_isChecked',
    }"
    :columns="tableColumns.columns"
    :data="items"
    :loading="loading"
    :row-class-name="getRowClassName"
    :scroll-y="{ enabled: !isInSearchMode, gt: 0 }"
    :sort-config="{ trigger: 'cell', defaultSort: defaultSort }"
    @checkbox-all="emitCheckboxAll"
    @checkbox-change="emitCheckbox"
    @resizable-change="onColumnResized"
  >
    <template #empty>No documents found</template>
  </DrVxeGrid>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import DocumentsFilterService from "@app/ng/documents/services/DocumentsFilterService";
import { saveColumnsConfig } from "@drVue/components/room/documents/utils";
import { DOC_ITEM_TYPES } from "@drVue/store/modules/room/documents/DocumentsApiService";
import TableColumns from "./tableColumns";

import type { DocsItem } from "@drVue/store/modules/room/documents/DocumentsApiService";
import type { CustomViewColumn } from "@setups/types";
import type { PropType } from "vue";
import type { VxeTableDefines, VxeTablePropTypes } from "vxe-table";

interface Data {
  tableColumns: any;
  defaultSort: VxeTablePropTypes.SortConfigDefaultSort;
}

export default defineComponent({
  name: "DocumentsTable",
  components: {
    DrVxeGrid,
  },
  props: {
    loading: { required: true, type: Boolean as PropType<boolean> },
    items: { required: true, type: Object as PropType<DocsItem[]> },
    tasksService: { required: true, type: Object as PropType<any> },
    categoriesService: { required: true, type: Object as PropType<any> },
    allowBulkDownload: { required: true, type: Boolean as PropType<boolean> },
  },
  emits: ["checkbox", "checkbox-all"],
  data(): Data {
    return {
      tableColumns: new TableColumns(this.$store, ["_bookmark", "_menu"]),
      defaultSort: {
        field: "treeIndex",
        order: "asc",
      },
    };
  },
  computed: {
    isLoading(): any {
      return false;
    },
    isInSearchMode(): any {
      const query = DocumentsFilterService.searchQuery;
      return !!query;
    },
  },
  beforeMount() {
    this.tableColumns.tasksService = this.tasksService;
    this.tableColumns.categoriesService = this.categoriesService;
  },
  methods: {
    getRowClassName({ row }: { row: DocsItem }): string {
      if (row.type === DOC_ITEM_TYPES.Folder) {
        return `dr-row-folder dr-row-folder-id-${row.id}`;
      }

      return "";
    },
    emitCheckbox(params: any) {
      this.$emit("checkbox", params.row, params.$event);
    },
    emitCheckboxAll() {
      this.$emit("checkbox-all");
    },
    onColumnResized({ column }: { column: VxeTableDefines.ColumnInfo }) {
      const update: CustomViewColumn = {
        field: column.property,
        width: column.resizeWidth,
      };

      saveColumnsConfig(this.$store, update);
    },
  },
});
</script>
