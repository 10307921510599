<template>
  <DrLayoutPage
    page-title="Your work"
    page-icon="layer-group"
    :nav-items="yourWorkPages"
  >
    <DrTransitionPage>
      <YourWorkTasks />
    </DrTransitionPage>
  </DrLayoutPage>
</template>

<script lang="ts" setup>
import { DrLayoutPage } from "@shared/ui/dr-layouts";
import { DrTransitionPage } from "@shared/ui/dr-transitions";

import YourWorkTasks from "./tasks/YourWorkTasks.vue";
// emulate YourWork page inside pipeline
const yourWorkPages = [
  {
    id: "tasks",
    name: "Tasks",
    isActive: true,
  },
];
</script>
