<template>
  <DefaultViewFieldLayout :view-props="viewProps">
    <template #label>
      {{ viewProps.schema.label }}
    </template>
    <template #control>
      <DrTruncatedTextTooltip v-if="text" :content="text" has-shaved-text>
        <div v-html="linkifiedTextHtml" class="shave" :class="$style.text" />
      </DrTruncatedTextTooltip>
      <div v-else>-</div>
    </template>
  </DefaultViewFieldLayout>
</template>

<script setup lang="ts">
import linkifyStr from "linkify-string";
import { computed } from "vue";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import DefaultViewFieldLayout from "./DefaultViewFieldLayout.vue";

import type { ViewFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";

interface Props {
  viewProps: ViewFieldProps;
}

const props = defineProps<Props>();

const text = computed(() => {
  if (!props.viewProps.entityValue) return "";
  return props.viewProps.entityValue.toString();
});

const linkifiedTextHtml = computed(() => {
  if (!props.viewProps.entityValue) return "";

  return linkifyStr(props.viewProps.entityValue.toString(), {
    attributes: {
      target: "_blank",
    },
  });
});
</script>

<style module lang="scss">
.text {
  --line-clamp: 4;
  word-break: break-word;
}
</style>
