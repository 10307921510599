import "./axis.directive.js";
import "./cells.directive.js";
import "./legend.directive.js";
import "./config.service.js";

import HeatmapController from "./controller.js";
import analyticsHeatmapHtml from "./index.html?raw";

angular
  .module("dealroom.analytics.heatmap", [
    "dealroom.analytics.heatmap.axis",
    "dealroom.analytics.heatmap.cells",
    "dealroom.analytics.heatmap.legend",
    "dealroom.analytics.heatmap.service.config",

    "dealroom.analytics.svg",
  ])
  .component("drAnalyticsHeatmap", {
    bindings: {
      dispatcher: "<",
    },
    template: analyticsHeatmapHtml,
    controller: HeatmapController,
  });
