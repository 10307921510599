<template>
  <ElDropdown
    ref="refDropdown"
    trigger="click"
    :disabled="disabled"
    :max-height="600"
    :popper-options="popperOptions"
    :popper-class="$style.popper_isHidden"
    placement="right"
    @visible-change="handleVisibilityChange"
  >
    <DrIcon name="ellipsis-h" />

    <template #dropdown>
      <ElDropdownMenu>
        <template v-for="(menuItems, index) in folderMenu" :key="index">
          <template
            v-for="(menuItem, menuItemIndex) in menuItems"
            :key="menuItemIndex"
          >
            <ElDropdownItem
              v-if="
                folder &&
                (menuItem.action || menuItem.menuComponent) &&
                menuItem.getIsVisible(folder)
              "
              :divided="menuItemIndex === 0 && index !== 0"
              @click="handleMenuAction(menuItem)"
            >
              {{ menuItem.name }}
            </ElDropdownItem>
          </template>
        </template>
      </ElDropdownMenu>
    </template>
  </ElDropdown>
</template>

<script lang="ts" setup>
import {
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  type Options,
} from "element-plus";
import { computed, nextTick, ref, watch } from "vue";

import { DrIcon } from "@drVue/shared/ui/dr-icon";
import { type FolderMenuItem, useFolderMenu } from "../use/useFolderMenu";
import {
  useFolderMenuActiveBus,
  useFolderMenuBus,
} from "../use/useFolderMenuBus";

import type { Folder } from "@app/vue/store/modules/room/documents/DocumentsApiService";
import type { ComponentInstance } from "vue";

interface Props {
  folder?: Folder;
  disabled?: boolean;
}

interface Emits {
  (e: "expand-folder"): void;
  (e: "collapse-folder"): void;
  (e: "update:active", value: boolean): void;
  (e: "update-items-list"): void;

  (e: "menu-open-folder", params: Folder): void;
  (e: "menu-download", params: Folder): void;
  (e: "menu-edit-custom-data", params: Folder): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const refDropdown = ref<ComponentInstance<typeof ElDropdown> | undefined>();

const updateDropdown = async () => {
  await nextTick();
  refDropdown.value?.$forceUpdate();
};

const popperOptions: Partial<Options> = {
  modifiers: [
    {
      name: "flip",
      options: { fallbackPlacements: ["right", "right-start"] },
    },
    {
      name: "offset",
      options: { offset: [-20, 10] },
    },
    {
      name: "arrow",
      options: { padding: 20 },
    },
  ],
};

const isDropdownVisible = ref(false);

const isMenuPlacerActive = ref(false);
const isMenuActive = computed<boolean>(
  () => isDropdownVisible.value || isMenuPlacerActive.value,
);

const handleVisibilityChange = (isVisible: boolean) => {
  if (isVisible) closeMenu();

  isDropdownVisible.value = isVisible;
  updateDropdown();
};

const closeMenu = (): void => {
  if (props.disabled) return;
};

const { folderMenu } = useFolderMenu({
  expandFolder: () => emit("expand-folder"),
  collapseFolder: () => emit("collapse-folder"),

  open: (folder) => emit("menu-open-folder", folder),
  download: (folder) => emit("menu-download", folder as Folder),
  editCustomData: (folder) => emit("menu-edit-custom-data", folder as Folder),
  updateItemsList: () => emit("update-items-list"),
});

const folderMenuBus = useFolderMenuBus();
const folderMenuActiveBus = useFolderMenuActiveBus();

folderMenuActiveBus.on((event, payload) => {
  if (!payload) isMenuPlacerActive.value = false;
});

const handleMenuAction = (item: FolderMenuItem): void => {
  if (!props.folder) return;

  item.track?.(props.folder);

  if (item.menuComponent && refDropdown.value) {
    folderMenuBus.emit("menu:show", {
      reference: refDropdown.value.$el,
      params: { folders: [props.folder], documents: [] },
      menuComponent: item.menuComponent,
    });

    isMenuPlacerActive.value = true;
  } else {
    item.action?.(props.folder as never);
  }
};

watch(isMenuActive, (value) => {
  emit("update:active", value);
});
</script>

<style lang="scss" module>
.popper {
  padding: 0 !important;
  width: unset !important;
}

.popper_isHidden:global {
  &.el-popper[data-popper-reference-hidden="true"] {
    display: none;
  }
}
</style>
