<template>
  <div
    class="alert-message dont-break-out col-sm-10 col-sm-offset-1 col-lg-8 col-lg-offset-2"
  >
    <AlertMessage v-for="item in toasts" :key="item.id" :item="item" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import AlertMessage from "./AlertMessage";
import { Toasts } from "./service";

interface Data {
  toasts: any;
}

export default defineComponent({
  name: "Notification",
  components: {
    AlertMessage,
  },
  data(): Data {
    return {
      toasts: Toasts.items,
    };
  },
  mounted() {
    // https://vuejs.org/guide/essentials/reactivity-fundamentals.html#script-setup
    // In Vue 2 returning Toasts service from the data method made it reactive.
    // In Vue 3 things changed. Now we have a wrapper for data's object.
    // Therefore, we can't use the magic anymore and need to pass the wrapped
    // value by hand.
    Toasts.setItems(this.toasts);
  },
});
</script>
