<template>
  <DrPopup ref="popupRef" paddingless>
    <template #reference>
      <ElInput
        :class="{ [$style.dateInput]: true, [$style.dateInput_isSmall]: small }"
        :model-value="value ?? ''"
      >
        <template #prefix>
          <ElIcon>
            <Calendar />
          </ElIcon>
        </template>
        <template #suffix>
          <ElIcon :class="$style.clearDateBtn" @click="$emit('delete')">
            <CircleClose />
          </ElIcon>
        </template>
      </ElInput>
    </template>
    <template #default>
      <slot>
        <DrDatePickerInline
          :model-value="dateValue"
          @click.stop
          @update:model-value="$emit('submit', $event)"
        />
      </slot>
      <div v-if="$slots['action']" :class="$style.actionContainer">
        <slot name="action" />
      </div>
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { ElIcon, ElInput } from "element-plus";
import { onMounted, ref } from "vue";
import { Calendar, CircleClose } from "@element-plus/icons-vue";
import { DrDatePickerInline } from "@shared/ui/dr-datepicker";
import { DrPopup } from "@shared/ui/dr-popups";

interface Props {
  value: string | null;
  dateValue?: string | Date;
  small?: boolean;
}

withDefaults(defineProps<Props>(), {
  dateValue: "",
  small: false,
});

interface Events {
  (e: "submit", payload: Date): void;
  (e: "delete"): void;
}

defineEmits<Events>();

const popupRef = ref<InstanceType<typeof DrPopup> | null>(null);

onMounted(() => {
  popupRef.value?.show();
});
</script>

<style module lang="scss">
.dateInput {
  &:hover {
    .clearDateBtn {
      opacity: 1;
    }
  }
}

.dateInput_isSmall {
  --el-input-border-radius: 6px;
  --el-input-height: 24px;
}

.clearDateBtn {
  transition: opacity 0.1s;
  opacity: 0;
}

.actionContainer {
  margin: 0 10px 10px 10px;
}
</style>
