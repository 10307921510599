<template>
  <PriorityEdit :edit-props="editProps" @change="handleChange" />
</template>

<script setup lang="ts">
import { insightTrack, TasksTableTrackingEvent } from "@app/insight";
import { PriorityEdit } from "@drVue/components/room/tasks/shared/widgets/priority";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { Task, TaskPriority } from "@drVue/store/pinia/room/tasks";

interface Props {
  editProps: EditFieldProps<TaskPriority, Task>;
}
defineProps<Props>();

function handleChange() {
  insightTrack(TasksTableTrackingEvent.PriorityChanged);
}
</script>

<style scoped lang="scss"></style>
