angular
  .module("dealroom.analytics.service.filter.tasks", [
    "dealroom.analytics.service.data",
    "dealroom.analytics.service.labels",
  ])
  .service("ActivityFilterTasksService", ActivityFilterTasksService);

ActivityFilterTasksService.$inject = [
  "ActivityLabelService",
  "ActivityVisibleCategoriesService",
];
function ActivityFilterTasksService(
  ActivityLabelService,
  ActivityVisibleCategoriesService,
) {
  const service = {
    filter: filterTasksRelated,
    isTaskVisible,
  };

  return service;

  function isTaskVisible(d, includeArchived) {
    const taskId = ActivityLabelService.itemId(d, "task");
    return taskId
      ? ActivityVisibleCategoriesService.isTaskVisible(taskId, includeArchived)
      : null;
  }

  function filterTasksRelated(d, includeArchived) {
    if (!d.target) return false;

    if (/^task/.test(d.verb)) {
      return isTaskVisible(d, includeArchived);
    }

    if (d.target.type === "document") {
      return ActivityVisibleCategoriesService.isDocIdInAttachments(d.target.id);
    }
    return false;
  }
}
