<template>
  <NodeViewWrapper class="vue-component">
    <span contenteditable="false">
      <MentionItemContent
        class="dr-mention"
        :class="{ 'dr-mention--hoverable': item }"
        :item="item"
        :type="type"
        :url="item ? item.url : undefined"
      />
    </span>
  </NodeViewWrapper>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { type NodeViewProps, NodeViewWrapper } from "@tiptap/vue-3";

import MentionItemContent from "./MentionItemContent.vue";

import type { MentionProvider } from "./providers";

// import type { NodeViewProps } from "@tiptap/vue-3";
// const props = defineProps<NodeViewProps>();
//
// But for some reason `build-storybook` script fails with this import.
// So, we use local `Props` and `as Component<NodeViewProps>` syntax in `mentionsExt.ts`.
// Official examples use `nodeViewProps` object (from `@tiptap/vue-3`), and `defineComponent` syntax.
// Again, for some reason `typeof noveViewProps` object is not the same as `NodeViewProps`.
// `Props` are hand-mand copy of `typeof nodeViewProps` type.
interface Props {
  editor: NodeViewProps["editor"];
  node: NodeViewProps["node"];
  decorations: NodeViewProps["decorations"];
  selected: NodeViewProps["selected"];
  extension: NodeViewProps["extension"];
  getPos: NodeViewProps["getPos"];
  updateAttributes: NodeViewProps["updateAttributes"];
  deleteNode: NodeViewProps["deleteNode"];
}
const props = defineProps<Props>();

const item = computed(() => {
  const provider = props.extension?.options.mentionsProvider as MentionProvider;
  if (!provider) return undefined;

  return provider.getItem(props.node?.attrs.type, props.node?.attrs.id);
});

const type = computed(() => props.node?.attrs.type);
</script>
