import { keyBy } from "lodash-es";
import { sortBy } from "lodash-es";

import { CustomViewObjectTypes } from "@setups/types";
import {
  DEFAULT_DATAROOM_VIEW,
  DEFAULT_DEALS_VIEWS,
  DEFAULT_FINDING_VIEW,
  DEFAULT_SYNERGY_VIEW,
  DEFAULT_TASK_TABLE_VIEW,
} from "./default";

import type { State } from "./state";
import type { CustomView } from "./types";
import type { RootState } from "@drVue/store/state";
import type { GetterTree } from "vuex";

export interface DealsGetterTree extends GetterTree<State, RootState> {
  bySlug(state: State): (slug: string) => CustomView | undefined;
  byObjectType(state: State): (type: CustomViewObjectTypes) => CustomView[];
  dealsPinnedViews(
    state: State,
    localGetters: any,
    rootState: RootState,
  ): CustomView[];
  defaultView(
    state: State,
    localGetters: any,
    rootState: RootState,
  ): (type: CustomViewObjectTypes) => CustomView;
}

const DEFAULT_DEALS_VIEWS_MAP = keyBy(DEFAULT_DEALS_VIEWS, "slug");

export const getters: DealsGetterTree = {
  bySlug: (state) => (slug) => {
    if (DEFAULT_DEALS_VIEWS_MAP[slug]) {
      return DEFAULT_DEALS_VIEWS_MAP[slug];
    }

    return state.items.find((v) => slug === v.slug);
  },

  byObjectType: (state) => (type: CustomViewObjectTypes) => {
    return state.items.filter((v) => type === v.object_type);
  },

  defaultView:
    (state, localGetters, rootState) => (type: CustomViewObjectTypes) => {
      switch (type) {
        case CustomViewObjectTypes.Deal: {
          const defaultViewSlug =
            rootState.clientDashboard.preferences.default_deals_view;

          const view = localGetters.bySlug(defaultViewSlug);
          if (view) {
            return view;
          }

          return DEFAULT_DEALS_VIEWS[0];
        }

        case CustomViewObjectTypes.DataRoom: {
          const views = localGetters["byObjectType"](type);
          if (views.length === 0) {
            return DEFAULT_DATAROOM_VIEW;
          }

          if (views.length > 1) {
            console.warn("Found more than one views for 'data_room' type.");
          }

          return views[0];
        }
        case CustomViewObjectTypes.Task: {
          const views = localGetters["byObjectType"](type);
          if (views.length === 0) {
            return DEFAULT_TASK_TABLE_VIEW;
          }
          // return personal view if exists
          return sortBy(views, "is_personal")[views.length - 1];
        }
        case CustomViewObjectTypes.Finding: {
          const views = localGetters["byObjectType"](type);
          if (views.length === 0) {
            return DEFAULT_FINDING_VIEW;
          }

          if (views.length > 1) {
            console.warn("Found more than one views for 'finding' type.");
          }

          return views[0];
        }
        case CustomViewObjectTypes.Synergy: {
          const views = localGetters["byObjectType"](type);
          if (views.length === 0) {
            return DEFAULT_SYNERGY_VIEW;
          }

          if (views.length > 1) {
            console.warn("Found more than one views for 'synergy' type.");
          }

          return views[0];
        }
        default: {
          throw `Default view are not supported by ${type}`;
        }
      }
    },

  dealsPinnedViews: (state, localGetters, rootState) => {
    // filters unavailable pinned custom-views
    return rootState.clientDashboard.preferences.pinned_deals_views
      .map((slug: string) => localGetters.bySlug(slug))
      .filter((dv?: CustomView) => !!dv);
  },
};
