<template>
  <DrPopup
    ref="popupRef"
    paddingless
    @show="handlePopupShow"
    @hide="handlePopupHide"
  >
    <template #default="{ hide }">
      <DrPanelWrapper :height="384" :width="354" title="Set labels">
        <template #subtitle>
          <ElInput
            ref="searchFieldRef"
            v-model="searchFieldText"
            clearable
            placeholder="Search for a label..."
            @keyup.enter="createAndAddLabel"
          >
            <template #prefix>
              <DrIcon size="sm" name="search" />
            </template>
          </ElInput>
        </template>

        <template #default>
          <div
            :class="{
              [$style.container]: true,
              [$style.container_isBusy]: isProcessing,
            }"
          >
            <DrNewLoader v-if="isProcessing" overlay />

            <ElButton
              v-if="filterText && !exactMatchedLabel"
              :class="$style.addButton"
              :loading="isProcessing"
              :disabled="isProcessing"
              type="primary"
              plain
              size="small"
              @click="createAndAddLabel"
            >
              Add '{{ searchFieldText }}' label
            </ElButton>

            <template v-if="filteredLabels.length">
              <LabelsEditControl
                v-for="label in filteredLabels"
                :key="label.id"
                :label="label"
                :is-selected="modelValue.includes(label.id)"
                @click="toggleLabel(label.id)"
                @busy="onProcessing"
                @update="onLabelUpdate"
                @delete="onLabelDelete"
              />
            </template>
            <div v-else :class="$style.notFound">No labels found</div>
          </div>
        </template>

        <template #footer-left>
          <ElCheckbox v-model="overwriteCheckbox">
            Overwrite existing labels
          </ElCheckbox>
        </template>

        <template #footer-right>
          <ElButton size="small" @click="hide">Cancel</ElButton>
          <ElButton
            size="small"
            type="primary"
            :disabled="isProcessing || !isValueChanged"
            @click="submitModelValue(hide)"
          >
            Apply
          </ElButton>
        </template>
      </DrPanelWrapper>
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrNewLoader } from "@shared/ui/dr-loader";
import { DrPanelWrapper } from "@shared/ui/dr-panels";
import { DrPopup } from "@shared/ui/dr-popups";

import { insightTrack, RoomRequestsBulkEvent } from "@app/insight";
import { pinia } from "@drVue/store/pinia";
import {
  LABEL_BG_COLOR,
  LABEL_COLOR,
} from "@drVue/store/pinia/room/tasksLabels/constants";
import {
  type TaskLabel,
  useTasksLabelsStore,
} from "@drVue/store/pinia/room/tasksLabels/tasksLabels";
import LabelsEditControl from "./widgets/labels/LabelsEditControl.vue";

export interface BulkUpdateLabelsPayload {
  value: TaskLabel["uid"][];
  overwrite: boolean;
}

interface Emits {
  (event: "submit", payload: BulkUpdateLabelsPayload): void;
  (e: "hide"): void;
}

const emit = defineEmits<Emits>();

const tasksLabelsStore = useTasksLabelsStore(pinia);

const popupRef = ref<InstanceType<typeof DrPopup>>();

const searchFieldRef = ref<HTMLInputElement | null>(null);
const searchFieldText = ref("");
const overwriteCheckbox = ref(false);
const modelValue = ref<TaskLabel["id"][]>([]);
const isProcessing = ref(false);

const onProcessing = (value: boolean) => {
  isProcessing.value = value;
};

const labelsList = computed(() => tasksLabelsStore.list);

const filterText = computed(() => searchFieldText.value.trim().toLowerCase());

const filteredLabels = computed(() => {
  if (!filterText.value) {
    return labelsList.value;
  }

  return labelsList.value.filter((item) => {
    return (
      modelValue.value.includes(item.id) ||
      item.name.toLowerCase().includes(filterText.value)
    );
  });
});

const exactMatchedLabel = computed((): TaskLabel | null => {
  if (filterText.value === "") return null;

  return (
    labelsList.value.find(
      (item) => item.name.toLowerCase() === filterText.value,
    ) ?? null
  );
});

const isValueChanged = computed(
  () => modelValue.value.length || overwriteCheckbox.value,
);

const handlePopupShow = () => {
  searchFieldRef.value?.focus();
};

const handlePopupHide = () => {
  modelValue.value = [];
  emit("hide");
};

const handleEndProcessing = () => {
  isProcessing.value = false;
  searchFieldRef.value?.focus();
};

const toggleLabel = (id: TaskLabel["id"]) => {
  const i = modelValue.value.indexOf(id);
  const isSelected = i > -1;
  if (isSelected) {
    modelValue.value.splice(i, 1);
  } else {
    modelValue.value.push(id);
  }
};

const createAndAddLabel = () => {
  if (!filterText.value) return;

  if (exactMatchedLabel.value !== null) {
    toggleLabel(exactMatchedLabel.value.id);
    return;
  }

  isProcessing.value = true;
  tasksLabelsStore
    .create({
      name: searchFieldText.value.trim(),
      label_color: LABEL_BG_COLOR,
      bg_color: LABEL_COLOR,
    })
    .then((createdLabel) => {
      searchFieldText.value = "";
      toggleLabel(createdLabel.id);
    })
    .finally(handleEndProcessing);
};

const onLabelUpdate = (
  label: TaskLabel,
  onDone: (isEditing: boolean) => void,
) => {
  isProcessing.value = true;

  tasksLabelsStore
    .update(label)
    .then(() => {
      onDone(false);
    })
    .finally(handleEndProcessing);
};

const onLabelDelete = (label: TaskLabel) => {
  isProcessing.value = true;

  tasksLabelsStore
    .delete(label.id)
    .then(() => {
      const i = modelValue.value.indexOf(label.id);
      if (i > -1) {
        modelValue.value.splice(i, 1);
      }
    })
    .finally(handleEndProcessing);
};

const submitModelValue = (hidePopup?: () => void) => {
  emit("submit", {
    value: modelValue.value.map((id) => tasksLabelsStore.dict[id].uid),
    overwrite: overwriteCheckbox.value,
  });
  insightTrack(RoomRequestsBulkEvent.LabelsUpdate, {
    overwrite: overwriteCheckbox.value ? "yes" : "no",
    number: String(modelValue.value.length),
  });
  hidePopup?.();
};

const show = (reference: HTMLElement) => {
  popupRef.value?.show(reference);
};

defineExpose({ show });
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography" as typo;

.container_isBusy {
  pointer-events: none;
}

.addButton {
  width: 100%;
  margin-bottom: 12px;
}

.notFound {
  color: colors.$pr-500;
  font: typo.$caption_regular;
}
</style>
