<template>
  <div :class="$style.wrapper" v-loading="aiReportsStore.isLoading">
    <h4>Create report</h4>
    <DrDynamicFormClassic
      ref="form"
      :entity="model"
      :schema="labelSchema"
      :errors="formErrors"
      :disabled="isFormSubmitting"
      @update="model[$event.field as keyof Model] = $event.value"
      @submit="submit"
    />
    <div :class="$style.actions">
      <ElButton type="primary" @click="submit" :disabled="isFormSubmitting">
        Create AI report
      </ElButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ElButton } from "element-plus";
import { type ComponentInstance, computed, reactive, ref } from "vue";

import { $notifySuccess } from "@app/vue/common";
import { FieldSchemaType } from "@app/vue/components/client-dashboard/dynamic-form/types";
import { DrDynamicFormClassic } from "@app/vue/shared/ui/dr-dynamic-form";
import { validateString } from "@app/vue/shared/ui/dr-dynamic-form/utils";
import { useFormHelper } from "@app/vue/shared/ui/dr-form";
import { pinia } from "@drVue/store/pinia";
import { useAiReportsStore } from "../AiReportsStore";

import type { FormSchema } from "@app/vue/shared/ui/dr-dynamic-form/types";

const aiReportsStore = useAiReportsStore(pinia);

const form = ref<ComponentInstance<typeof DrDynamicFormClassic> | undefined>();

const model = reactive({
  label: "",
});
type Model = typeof model;

const { formErrors, hookFormSubmitPromise, isFormSubmitting } =
  useFormHelper<Model>();

const labelSchema = computed(
  (): FormSchema => [
    {
      type: FieldSchemaType.Text,
      required: true,
      isReadOnly: isFormSubmitting.value,
      prop: "label",
      label: "Label",
      placeholder: "My super AI report",
      rules: validateString().required("This field may not be blank"),
    },
  ],
);

const submit = async () => {
  if (isFormSubmitting.value) return;

  const result = await form.value?.validate().catch((error) => error);
  if (result !== true) return;

  hookFormSubmitPromise(
    aiReportsStore.create({ label: model.label }),
    "Failed to create report, see console for details",
  ).then(
    () => {
      $notifySuccess("Report created");

      model.label = "";

      form.value?.reset({
        label: "",
      });
    },
    (r) => {
      console.error(r);
    },
  );
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.wrapper {
  border: 1px solid colors.$pr-150;
  border-radius: 4px;
  margin: spacing.$xs spacing.$s;
  padding: spacing.$s spacing.$s;
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: spacing.$s;
}
</style>
