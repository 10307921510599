<template>
  <FindingStatusesTable
    :items="findingsStatusesStore.filledList"
    :is-loading="isLoading"
    :columns="tableColumns.columns"
  />

  <FindingStatusEditOrCreatePanel
    v-model:shown="showEditModal"
    :status-id="editingStatusId"
  />
</template>

<script setup lang="ts">
import { ElMessageBox } from "element-plus";
import { computed, ref } from "vue";

import { $notifyDanger, $notifySuccess } from "@drVue/common";
import { pinia } from "@drVue/store/pinia";
import { useFindingsStatusesStore } from "@drVue/store/pinia/pipeline/findings-statuses";
import FindingStatusEditOrCreatePanel from "./FindingStatusEditOrCreatePanel.vue";
import FindingStatusesTable from "./findingStatusesTable/findingStatusesTable.vue";
import TableColumns from "./findingStatusesTable/findingStatusesTableColumns";

const findingsStatusesStore = useFindingsStatusesStore(pinia);

const showEditModal = ref(false);
const editingStatusId = ref<string | null>(null);

const isLoading = computed(() => findingsStatusesStore.isLoading);

const showWarningLastOfType = (type: string) => {
  ElMessageBox.confirm(
    `This status can not be deleted because there must be at least one "${type}" status type.`,
    "Unacceptable action",
    {
      confirmButtonText: "Ok",
      showCancelButton: false,
      showClose: false,
    },
  );
};

const onEdit = (id: string) => {
  editingStatusId.value = id;
  showEditModal.value = true;
};

const onRemove = (id: string) => {
  const status = findingsStatusesStore.dict[id];
  const statusTypeCount = findingsStatusesStore.listByType[status.type].length;
  const statusTypeLabel = findingsStatusesStore.typeLabel[status.type];

  if (statusTypeCount < 2) {
    showWarningLastOfType(statusTypeLabel);
    return;
  }

  ElMessageBox({
    type: "warning",
    title: `Remove finding status "${status.name}"`,
    message: `Type of all findings with this status will be changed to another status of the same type "${statusTypeLabel}".
    Are you sure want to remove status "${status.name}"?`,
    confirmButtonText: "Remove",
    showCancelButton: true,
    showClose: true,
    cancelButtonText: "No",
    beforeClose: (action, instance, done) => {
      if (action != "confirm") {
        done();
        return;
      }
      instance.confirmButtonLoading = true;

      findingsStatusesStore
        .remove(status.id)
        .then(
          () => {
            $notifySuccess(`Finding status '${status.name}' was removed.`);
            done();
          },
          (err) => {
            let msg = "Failed to remove selected status.";
            const backendRespError = err.response?.data?.non_field_errors;

            if (backendRespError) {
              msg = backendRespError.join(" ");
            }
            $notifyDanger(msg);
          },
        )
        .finally(() => {
          instance.confirmButtonLoading = false;
        });
    },
  }).catch(() => {});
};

const tableColumns = new TableColumns({ onEdit, onRemove });

const addNewStatus = () => {
  editingStatusId.value = null;
  showEditModal.value = true;
};

findingsStatusesStore.load();

defineExpose({
  addNewStatus,
});
</script>
