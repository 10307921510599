<template>
  <FolderMenuDropdown
    :reference="reference"
    :placement="placement"
    :width="POPUP_SIZES.tree.width"
    @close="$emit('close')"
  >
    <FolderMenuWrapper
      :loading="isFormSubmitting"
      :disabled="selected === null"
      submin-text="Apply"
      @click:cancel="$emit('close')"
      @click:submit="submit"
    >
      <div :class="$style.container">
        <DrPopupSelectOptions
          :items="options"
          type="checkboxRight"
          hide-check-hover
          @select="selected = $event"
        />
      </div>
    </FolderMenuWrapper>
  </FolderMenuDropdown>
</template>

<script setup lang="ts">
import { type ComponentInstance, computed, onMounted, ref } from "vue";

import { insightTrack, RoomDataroomBulkEvent } from "@app/insight";
import { $notifyDanger, $notifySuccess } from "@app/vue/common";
import { POPUP_SIZES } from "@app/vue/shared/ui/constants";
import DrPopupSelectOptions from "@app/vue/shared/ui/dr-popups/DrPopupSelectOptions.vue";
import { DocumentsApiService } from "@app/vue/store/modules/room/documents/DocumentsApiService";
import { pinia } from "@app/vue/store/pinia";
import { useAiReportsStore } from "../../ai/AiReportsStore";
import { type FolderMenuParams, getFolderMenuSelectedText } from "../utils";
import FolderMenuDropdown from "./FolderMenuDropdown.vue";
import FolderMenuWrapper from "./FolderMenuWrapper.vue";

import type { AiReport } from "../../ai/types";
import type { SelectOptionItem } from "@app/vue/shared/ui/dr-popups";

interface Props {
  params: FolderMenuParams;
  reference: HTMLElement;
  placement?: ComponentInstance<typeof FolderMenuDropdown>["placement"];
}

interface Emits {
  (e: "close"): void;
  (e: "update:persisted", value: boolean): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const selected = ref<AiReport["id"] | null>(null);
const isFormSubmitting = ref(false);

const aiReportsStore = useAiReportsStore(pinia);

const options = computed(() =>
  aiReportsStore.list.map(
    (item) =>
      ({
        id: item.id,
        name: item.label,
        isSelected: selected.value === item.id,
      }) satisfies SelectOptionItem,
  ),
);

const api = new DocumentsApiService();

const submit = async () => {
  if (isFormSubmitting.value || selected.value === null) return;

  isFormSubmitting.value = true;

  const selectedText = getFolderMenuSelectedText(props.params);

  emit("update:persisted", true);

  insightTrack(RoomDataroomBulkEvent.AiReportApply, {
    documents_count: `${props.params.documents.length}`,
    report_label: aiReportsStore.dict[selected.value]?.label ?? "",
  });

  api
    .bulkApplyAiReport(
      props.params.documents.map((item) => item.uid),
      selected.value,
    )
    .then(() => {
      $notifySuccess(`The summary has been applied on ${selectedText}.`);

      emit("close");
    })
    .catch((error) => {
      $notifyDanger(
        error.response.data?.join("\n") ??
          `Failed to apply summary on ${selectedText}.`,
      );
    })
    .finally(() => {
      isFormSubmitting.value = false;

      emit("update:persisted", false);
    });
};

onMounted(() => {
  aiReportsStore.load();
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/spacing";

.container {
  padding: spacing.$s;
}
</style>
