import { ApiService } from "../index";

import type { UrlsGetters } from "../index";
import type { Order } from "./types";

export class ClientDashboardApiService<
  I extends Order = Order,
> extends ApiService {
  constructor(apiRoutePrefix: string) {
    super();

    this.Urls = this.processUrls(this.Urls, apiRoutePrefix);
  }

  public processUrls(Urls: UrlsGetters, apiRoutePrefix: string) {
    const base = "api:client-dashboard:" + apiRoutePrefix;

    return {
      detail: Urls[`${base}-detail`],
      list: Urls[`${base}-list`],
      reorder: Urls[`${base}-orders`],
    };
  }

  public addItem(item: I) {
    const url = this.Urls.list();

    return this.post<I, I>(url, item);
  }

  public editItem(item: I) {
    const url = this.Urls.detail(item.id);

    return this.put<I, I>(url, item);
  }

  public deleteItem(item: I) {
    const url = this.Urls.detail(item.id);

    return this.delete<I>(url);
  }

  public reorder(ordered: Order[]) {
    const url = this.Urls.reorder();

    return this.post<Order[], Order[]>(url, ordered);
  }
}
