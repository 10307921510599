import "./row";

import * as d3 from "d3";

import CLASSES from "./classes";

angular
  .module("dealroom.analytics.datagrid-table.table.body", [
    "dealroom.analytics.datagrid-table.table.body.row",
  ])
  .component("drAnalyticsDatagridTableBody", {
    bindings: {
      dispatcher: "<",
      limitTo: "<",
    },
    controller: AnalyticsDatagridTableBodyController,
  });

AnalyticsDatagridTableBodyController.$inject = [
  "$element",
  "AnalyticsDatagridTableBodyRowFactory",
];
function AnalyticsDatagridTableBodyController(
  $element,
  AnalyticsDatagridTableBodyRowFactory,
) {
  const $ctrl = this;
  const rootElement = d3.select($element[0]);
  const EXPANDED_STORAGE = {};
  const selector = `.${CLASSES.rowContainer}`;
  const keyFn = (d) => d.itemId;

  let sortBy = { fn: null };
  let createTimeoutId;

  $ctrl.$onInit = function () {
    $ctrl.dispatcher.on("update", onUpdate);
    $ctrl.dispatcher.on("sortBy", onSortBy);
  };

  function onUpdate(data) {
    createRows(data);
  }

  function onSortBy(_sortBy) {
    sortBy = _sortBy;
    updateContainersData();
  }

  function createRows(data) {
    if (data !== undefined && +$ctrl.limitTo !== 0) {
      data = data.sort(sortBy.fn).slice(0, $ctrl.limitTo);
    }
    const containers = updateContainersData(data);
    containers.exit().each(AnalyticsDatagridTableBodyRowFactory.remove);
    containers
      .enter()
      .append("div")
      .each(AnalyticsDatagridTableBodyRowFactory.create)
      .merge(containers)
      .each(update);

    function update(d) {
      d3.select(this).call(
        AnalyticsDatagridTableBodyRowFactory.update,
        EXPANDED_STORAGE[d.itemId],
        onExpand,
      );
    }
  }

  function onExpand(itemId) {
    EXPANDED_STORAGE[itemId] = !EXPANDED_STORAGE[itemId];
    createRows();
    return EXPANDED_STORAGE[itemId];
  }

  function updateContainersData(data) {
    let contaiers = rootElement.selectAll(selector);
    if (data === undefined) {
      data = contaiers.filter((d) => d.paddingLevel === 1).data();
    }

    data = _pushExpandedChildToData(data);

    contaiers = contaiers.data(data, keyFn);

    return contaiers.order();

    function _pushExpandedChildToData(data) {
      return data.sort(sortBy.fn).reduce((sorted, d) => {
        sorted.push(d);
        if (EXPANDED_STORAGE[d.itemId] && d.getSubRows instanceof Function) {
          const childs = d.getSubRows();
          sorted = sorted.concat(_pushExpandedChildToData(childs));
        }
        return sorted;
      }, []);
    }
  }
}
