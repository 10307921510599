import type { HEXColor } from "@drVue/utils/color";

export enum FindingStatusType {
  Open = "open",
  InProgress = "inprogress",
  Closed = "closed",
}
export const FindingStatusTypesArray: FindingStatusType[] = [
  FindingStatusType.Open,
  FindingStatusType.InProgress,
  FindingStatusType.Closed,
];

export interface FindingStatus {
  readonly id: string; // uid
  name: string;
  color: HEXColor;
  type: FindingStatusType;
}

export type FindingStatusCreateOrUpdatePayload = Pick<
  FindingStatus,
  "name" | "color" | "type"
>;
