<template>
  <TextViewContent :value="value" @click.stop="viewProps.enterEditMode" />
</template>

<script setup lang="ts">
import { computed } from "vue";

import { numberFormat } from "@app/vue/utils/number";
import TextViewContent from "./TextViewContent.vue";

import type { ViewFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";

interface Props {
  viewProps: ViewFieldProps;
}

const props = defineProps<Props>();

const value = computed(() => {
  if (typeof props.viewProps.entityValue !== "number") return "-";

  return numberFormat(
    props.viewProps.entityValue,
    props.viewProps.schema.extra.symbol,
    props.viewProps.schema.extra.digit_grouping,
  );
});
</script>
