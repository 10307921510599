<template>
  <div :class="$style.email">
    <div :class="$style.subjectRow">
      <div>{{ email.rawSubject }}</div>
      <div>
        <ElButton
          v-if="editable"
          text
          :class="$style.deleteButton"
          @click="deleteEmail(email.id)"
        >
          <template #icon>
            <i class="fa fa-times" />
          </template>
        </ElButton>
      </div>
    </div>

    <div :class="$style.content">
      <div :class="$style.fromRow">
        <b>From:</b>
        <div :class="$style.emailsRow">
          <EmailUsers :email-id="email.id" :email-users="[email.from]" />
        </div>
        <span :class="$style.date">{{ fromDate }}</span>
      </div>
      <div :class="$style.contentRow">
        <b>To:</b>
        <div :class="$style.emailsRow">
          <EmailUsers :email-id="email.id" :email-users="email.to" />
        </div>
      </div>
      <div v-if="email.cc.length > 0" :class="$style.contentRow">
        <b>Cc:</b>
        <div :class="$style.emailsRow">
          <EmailUsers :email-id="email.id" :email-users="email.cc" />
        </div>
      </div>
      <div v-if="email.attachments.length > 0" :class="$style.attachmentsBlock">
        <b>Attachments:</b>
        <div>
          <EmailAttachment
            v-for="attachment in email.attachments"
            :key="attachment.file.id"
            :attachment="attachment"
            :email-id="email.id"
          />
        </div>
      </div>

      <div :class="$style.body">
        <div
          ref="emailBodyText"
          :class="$style.bodyText"
          :style="{ 'max-height': isExpanded ? '' : MAX_TEXT_HEIGHT + 'px' }"
        >
          >
          {{ email.bodyText }}
        </div>
        <br />
        <ElLink
          v-if="showExpandButton"
          type="primary"
          @click="isExpanded = !isExpanded"
        >
          Show {{ isExpanded ? "less" : "more" }}
        </ElLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ElMessageBox } from "element-plus";
import { computed, inject, ref } from "vue";
import { RawEmailsInjectionKey } from "@shared/ui/emails-list/keys";

import { $notifyDanger, $notifySuccess } from "@drVue/common";
import { drUserTime } from "@drVue/filters/drUserTime";
import { emailsApi } from "@drVue/store/modules/room/emails/EmailsApiService";
import EmailAttachment from "./EmailAttachment.vue";
import EmailUsers from "./EmailUsers.vue";

import type { EmailMessage } from "@drVue/store/modules/room/emails/EmailsApiService";
import type { EmailModel } from "@shared/ui/emails-list/EmailModel";
import type { Ref } from "vue";

const rawEmails = inject<Ref<EmailMessage[]>>(RawEmailsInjectionKey);

interface Props {
  email: EmailModel;
  editable?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  editable: false,
});

interface Emits {
  (e: "email-deleted", emailId: string): void;
}

const emit = defineEmits<Emits>();

const fromDate = computed(() => drUserTime(props.email.origDate, "full-dt"));

const MAX_TEXT_HEIGHT = ref(500);
const isExpanded = ref(false);
const emailBodyText = ref<InstanceType<typeof HTMLDivElement> | null>(null);
const showExpandButton = computed(() => {
  if (!emailBodyText.value) {
    return false;
  }
  return emailBodyText.value!.scrollHeight > emailBodyText.value!.clientHeight;
});

const deleteEmail = (emailId: string) => {
  ElMessageBox.confirm(
    "Are you sure you want to delete this email?",
    "Warning",
    {
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    },
  ).then(() =>
    emailsApi.deleteEmail(emailId).then(
      () => {
        if (rawEmails) {
          rawEmails.value = rawEmails.value.filter((e) => e.id !== emailId);
        }
        $notifySuccess("Email deleted");
        emit("email-deleted", emailId);
      },
      () => $notifyDanger("Failed to delete the email, please try again"),
    ),
  );
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/_colors.scss" as colors;
@use "@app/styles/scss/typography.scss" as typo;

.email {
  background-color: #fff;
  margin-bottom: 20px;
  border: 1px solid colors.$pr-200;
  border-radius: 4px;
}

.subjectRow {
  border-bottom: 1px solid colors.$pr-200;
  padding: 16px 16px 16px 18px;
  font-size: typo.$font-size-title;
  font-weight: typo.$font-weight-medium;
  line-height: typo.$line-height-title;
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
}

.content {
  margin: 0 18px;
}

.fromRow {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 45px 1fr max-content;
}

.contentRow {
  margin: 8px 0;
  display: grid;
  grid-template-columns: 45px 1fr;

  &:first-of-type {
    margin-top: 16px;
  }
}

.attachmentsBlock {
  margin: 8px 0;
  display: flex;
  align-items: center;
}

.emailsRow {
  display: flex;
  flex-wrap: wrap;
}

.date {
  justify-self: flex-end;
}

.body {
  margin-bottom: 16px;
  white-space: pre-line;
  word-break: break-all;
}

.bodyText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.deleteButton {
  width: 34px;
}
</style>
