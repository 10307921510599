import { orderBy } from "lodash-es";

import { FieldItemTypes } from "./api-service/client-dashboard";
import { mapCustomFieldToSchema } from "./components/client-dashboard/dynamic-form/utils";
import { getSortValue } from "./store/modules/client-dashboard/deals/utils";

import type { FieldItem } from "./api-service/client-dashboard";
import type { DrVxeTableColumn } from "@drVue/components/types";
import type { Dictionary } from "@drVue/types";
import type { CustomViewColumn } from "@setups/types";
import type { TableInlineEditor } from "@shared/ui/table-inline-editor";

const LEFT_GAP = 100;
const moveToLeft = (index: number) => index - LEFT_GAP;

export const MENU_COLUMN_FIELD = "_menu";

export abstract class TableColumnsBase<D = unknown> {
  abstract getTableColumns(): DrVxeTableColumn<D>[];

  abstract getUserColumns(): Record<string, CustomViewColumn>;

  protected abstract readonly inlineEditor: TableInlineEditor;

  get columns(): DrVxeTableColumn<D>[] {
    const tableColumns = this.getTableColumns().filter(
      (c) => !c.isInaccessible,
    );
    const userColumns = this.getUserColumns();

    const fixedColumnsOrder: Dictionary<number | undefined> = {};
    tableColumns.forEach((tableCol, index) => {
      const userCol = userColumns[tableCol.field];

      // If column is fixed in table, then it has priority over user settings
      if (tableCol.fixed) {
        fixedColumnsOrder[tableCol.field] = moveToLeft(index);
      } else if (userCol && userCol.fixed) {
        fixedColumnsOrder[tableCol.field] = userCol.order;
        tableCol.fixed = userCol.fixed;
      }

      if (tableCol.resizable === undefined || tableCol.resizable) {
        tableCol.width = userCol?.width || tableCol.width;
      }

      tableCol.visible = !userCol?.hidden;
    });

    return orderBy(
      tableColumns,
      [
        (col) => {
          if (fixedColumnsOrder[col.field]) {
            return fixedColumnsOrder[col.field];
          }

          if (userColumns[col.field]?.order !== undefined) {
            return userColumns[col.field].order;
          }
          return col.field === MENU_COLUMN_FIELD // column should be always last
            ? Number.MAX_SAFE_INTEGER
            : Number.MAX_SAFE_INTEGER - 1;
        },
      ],
      ["asc"],
    );
  }

  protected customFieldColumn(
    field: FieldItem,
    isReadOnly?: boolean,
    isSortable?: boolean,
  ): DrVxeTableColumn<D> {
    return {
      field: `custom_data.${field.key}`,
      title: field.label,
      sortable: isSortable,
      sortBy: ({ row }) => getSortValue(field, row.custom_data[field.key]),
      width: 140,
      minWidth: field.field_type === FieldItemTypes.MultiSelect ? 200 : 20,
      slots: {
        default: (params) => {
          const schema = mapCustomFieldToSchema(field);
          schema.isReadOnly = isReadOnly;
          return this.inlineEditor.renderInlineField(schema, params);
        },
      },
    };
  }
}
