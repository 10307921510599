import { ApiService } from "../index";

export interface GKField {
  id: number;
  type: string;
}

export enum ActionVerbs {
  DealAdd = "deal_add",
  DealPhase = "deal_phase",
  DealCustomData = "deal_custom_data",
}

export interface Action {
  id: number;
  action: ActionVerbs;
  dt: Date;
  target?: GKField;
  object?: GKField;
  content: { [key: string]: any };
}

export class ActivityApiService extends ApiService {
  public list() {
    const url: string = this.Urls["api:client-dashboard:activity-list"]();

    return this.get<Action[]>(url);
  }
}
