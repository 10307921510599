import { ROOM_DATA } from "@setups/data";
import { ApiService } from "@drVue/api-service";

import type { CustomView } from "./types";

const URL_PREFIX = ROOM_DATA?.uid
  ? "api:room:custom-view"
  : "api:client-dashboard:custom-view";

export default class CustomViewApiService extends ApiService {
  public list() {
    const url: string = this.Urls[`${URL_PREFIX}-list`]();
    return this.get<CustomView[]>(url);
  }

  public create(customView: Partial<CustomView>) {
    const url: string = this.Urls[`${URL_PREFIX}-list`]();
    return this.post<CustomView>(url, customView);
  }

  public update(customView: CustomView) {
    const url: string = this.Urls[`${URL_PREFIX}-detail`](customView.slug);
    return this.put<CustomView>(url, customView);
  }

  public destroy(customView: CustomView) {
    const url: string = this.Urls[`${URL_PREFIX}-detail`](customView.slug);
    return this.delete<any>(url);
  }
}
