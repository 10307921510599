<template>
  <ElSelect
    ref="selectRef"
    filterable
    reserve-keyword
    :teleported="false"
    :class="$style.select"
    :model-value="editProps.value"
    :placeholder="editProps.schema.placeholder"
    :filter-method="updateFilterText"
  >
    <DrTree
      check-strictly
      check-sign-on-right
      check-on-click-node
      :expand-on-click-node="false"
      :default-expanded-keys="expandedKeys"
      :filter-text="filterText"
      :data="tree"
      :model-value="localValue"
      @update:model-value="handleSelection"
      @reset-filter="filterText = ''"
    />
    <div style="display: none">
      <ElOption
        v-for="item in treeList"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </div>
  </ElSelect>
</template>

<script setup lang="ts">
import { ElSelect } from "element-plus";
import { difference } from "lodash-es";
import { computed, ref } from "vue";
import { DrTree } from "@shared/ui/dr-tree";

import type { Category } from "@drVue/store/pinia/room/categories";
import type { FieldProps } from "@shared/ui/dr-dynamic-form/types";
import type { Tree } from "@shared/ui/dr-tree";

interface Props {
  editProps: FieldProps;
}

const props = defineProps<Props>();

const selectRef = ref<InstanceType<typeof ElSelect> | null>(null);

const expandedKeys = computed(() => {
  if (!props.editProps.value) return [];

  const cats = props.editProps.schema.extra.categoriesList as Category[];
  const currentCat = cats.find((c) => c.id === props.editProps.value);
  if (!currentCat) return [];

  return currentCat.parents?.map((c) => c.id) || [];
});

const tree = computed(() => {
  return props.editProps.schema.extra.categories as Tree;
});
const treeList = computed(() => {
  return (props.editProps.schema.extra.categoriesList as Category[]).map(
    (cat) => ({
      label: cat.name,
      value: cat.id,
    }),
  );
});

const filterText = ref("");
const updateFilterText = (query: string) => {
  filterText.value = query.trim();
};

const localValue = ref<Category["id"][]>([props.editProps.value]);
const handleSelection = (value: (string | number)[]) => {
  if (!value.length) return;

  if (value.length === 1) {
    localValue.value = value as Category["id"][];
  } else {
    localValue.value = difference(value as Category["id"][], localValue.value);
  }

  props.editProps.veeField.onInput(localValue.value[0]);
  selectRef.value?.toggleMenu();
};
</script>

<style module lang="scss">
.select {
  width: 100%;

  :global(.el-select-dropdown__wrap) {
    max-height: 305px;
  }
}
</style>
