<template>
  <div :class="$style.container">
    <div :class="$style.title"><slot /></div>
    <div :class="$style.description"><slot name="description" /></div>
  </div>
</template>

<style module lang="scss">
@use "@app/styles/scss/_colors.scss";

.container {
  background-color: colors.$pr-50;
  padding: 20px 24px 24px;
  border-bottom: 1px solid colors.$pr-200;
}

.title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
}

.description {
  color: colors.$pr-600;
  font-size: 14px;
}
</style>
