import type { OrgUsersState } from "@drVue/store/modules/client-dashboard/org-users/OrgUsersState";
import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { MutationTree } from "vuex";

interface OrgUsersMutationTree extends MutationTree<OrgUsersState> {
  setOrgUsers(state: OrgUsersState, orgUsers: OrgUser[]): void;
  setIsLoading(state: OrgUsersState, isLoading: boolean): void;
  setIsError(state: OrgUsersState, isError: boolean): void;
}

export const orgUsersMutations = {
  setOrgUsers(state: OrgUsersState, orgUsers: OrgUser[]) {
    state.items = orgUsers;
  },

  update(state: OrgUsersState, data: Partial<OrgUser>) {
    const user = state.items.find((u) => u.id === data.id);
    if (!user) return;

    Object.assign(user, data);
  },

  remove(state: OrgUsersState, orgUserId: string) {
    const index = state.items.findIndex((u) => u.id === orgUserId);
    if (index === -1) return;

    state.items.splice(index, 1);
  },

  setIsLoading(state: OrgUsersState, isLoading: boolean): void {
    state.isLoading = isLoading;
  },

  setIsError(state: OrgUsersState, isError: boolean): void {
    state.isError = isError;
  },
} as OrgUsersMutationTree;
