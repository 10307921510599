import { ClientDashboardApiService } from "./base";

import type { PhaseItem } from "./types";

export const apiRoutePrefix: string = "phases";

export class PhasesApiService extends ClientDashboardApiService<PhaseItem> {
  constructor() {
    super(apiRoutePrefix);
  }
}
