import axios from "axios";

import { APP_SETTINGS } from "@setups/data";
import { ApiService } from "@drVue/api-service";
import { getTimezone } from "@drVue/common";

import type { OpenIDIntegration } from "@drVue/api-service/client-dashboard/open-id-providers";
import type { AxiosResponse } from "axios";

const http = axios.create({
  baseURL: location.origin,
  withCredentials: true,
  xsrfHeaderName: "X-CSRFToken",
  xsrfCookieName: APP_SETTINGS.CSRF_COOKIE_NAME,
});

export interface LoginRequest {
  username: string;
  password: string;
  remember: boolean;
}

export interface MFAInterface {
  interface: string;
  name: string;
  description: string;
  is_backup_interface: boolean;
  requires_activation: boolean;
}

export interface LoginResponse {
  mfa_required: boolean;
  interfaces: MFAInterface[];
}

export interface ConfirmMFARequest {
  remember: boolean;
  challenge: any;
  interface: string;
}

interface SignInSsoByEmail {
  redirect_url: string;
}

export class AuthApiService extends ApiService {
  home_url: string = `${location.origin}${this.Urls["account"]()}`;

  public login(data: LoginRequest): Promise<AxiosResponse<LoginResponse>> {
    const url = this.Urls["api:auth:login"]();

    const params = new URLSearchParams();
    params.append("username", data.username);
    params.append("password", data.password);
    params.append("remember", data.remember.toString());

    return http.post<LoginResponse>(url, params, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
      },
    });
  }

  public activate_mfa(mfaInterfaceKey: string) {
    const url = this.Urls["api:auth:activate_mfa"]();
    return http.post(url, {
      interface: mfaInterfaceKey,
    });
  }

  public confirm_mfa(request: ConfirmMFARequest) {
    const url = this.Urls["api:auth:confirm_mfa"]();

    const params = new URLSearchParams();
    params.append("interface", request.interface);
    params.append("challenge", request.challenge.toString());
    params.append("remember", request.remember.toString());

    return http.post(url, params, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
      },
    });
  }

  public reset(data: any) {
    const url = this.Urls["api:auth:password_reset"]();
    return http.post(url, data);
  }

  public password_set(data: any) {
    const url = this.Urls["api:auth:password_set"]();
    return http.post(url, data);
  }

  public sign_in_with(integration: OpenIDIntegration, redirect: string) {
    const url = this.Urls["api:auth:sign-in-with"]();
    return http.post(url, {
      redirect: redirect,
      provider: integration.provider_id,
      integration: integration.id,
      timezone: getTimezone(),
    });
  }

  public sign_in_sso_by_email(data: { email: string }) {
    const url = this.Urls["api:auth:sign-in-by-email"]();
    return http.post<SignInSsoByEmail>(url, {
      email: data.email,
      timezone: getTimezone(),
    });
  }

  public register(data: any) {
    const url = this.Urls["api:users:root"]();
    return http.post(url, data);
  }
}
