<template>
  <div :class="$style.permissions" v-if="row.has_access">
    <DrIcon
      name="eye"
      :class="{
        [$style.icon]: true,
        [$style.icon_isActive]: perms.can_view,
      }"
      @click="emit(row.group_id, 'can_view', !perms.can_view)"
    />
    <DrIcon
      name="arrow-down"
      :class="{
        [$style.icon]: true,
        [$style.icon_isActive]: perms.can_download_watermaked,
      }"
      @click="
        emit(
          row.group_id,
          'can_download_watermaked',
          !perms.can_download_watermaked,
        )
      "
    />
    <DrIcon
      name="download"
      :class="{
        [$style.icon]: true,
        [$style.icon_isActive]: perms.can_download_original,
      }"
      @click="
        emit(
          row.group_id,
          'can_download_original',
          !perms.can_download_original,
        )
      "
    />
    <DrIcon
      name="pen"
      :class="{
        [$style.icon]: true,
        [$style.icon_isActive]: perms.can_edit,
      }"
      @click="emit(row.group_id, 'can_edit', !perms.can_edit)"
    />
  </div>

  <DrPopup v-else placement="left" trigger="click" teleported width="300">
    Group doesn't have access to the folder where files will be uploaded. Access
    can be granted later.
    <template #reference>
      <span :class="$style.noAccess">
        <DrIcon name="info-circle" size="sm" />
        No access
      </span>
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { computed } from "vue";
import DrIcon from "@shared/ui/dr-icon";
import { DrPopup } from "@shared/ui/dr-popups";

import { useUploadPermissionsBus } from "../usePermissionsBus";

import type { PermissionTableRow, RowPermissionsKey } from "../../types";

interface Props {
  row: PermissionTableRow;
}

const props = defineProps<Props>();
const uploadPermissionsBus = useUploadPermissionsBus();

const perms = computed(() => props.row.permissions);
const emit = (
  groupId: number,
  permission: RowPermissionsKey,
  value: boolean,
) => {
  if (!props.row.has_access) return;

  uploadPermissionsBus.emit(undefined, {
    groupId,
    permission,
    value,
  });
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

.icon {
  color: colors.$pr-350;
}

.icon_isActive {
  color: colors.$sc-600;
}

.noAccess {
  color: colors.$pr-400;
}

.permissions {
  height: 20px;
  display: flex;
  gap: 14px;
  align-items: center;
}
</style>
