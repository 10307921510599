import { ORG_MEMBER_DATA } from "@setups/data";
import { OrganizationMemberLookerAccess } from "@setups/enums";

export function isLookerAccessible(): boolean {
  return (
    ORG_MEMBER_DATA?.client?.enable_looker &&
    ORG_MEMBER_DATA?.group?.is_administrator &&
    ORG_MEMBER_DATA?.looker_access !== OrganizationMemberLookerAccess.Disabled
  );
}
