<template>
  <span>
    <span
      class="phase-info__circle"
      :style="{ 'background-color': phase.color }"
    />{{ phase.name }}
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { PhaseItem } from "@drVue/api-service/client-dashboard";
import type { PropType } from "vue";

export default defineComponent({
  name: "PhaseCell",
  props: {
    phase: { required: true, type: Object as PropType<PhaseItem> },
  },
});
</script>
