export enum NoteObjectType {
  Deal = "deal",
  OrgUser = "user",
}

export interface Note {
  id: number;
  sender_id: number;
  date_added: Date;
  date_updated: Date;
  body: object | null;
  body_text: string; // plain text of body, //TODO: generate of frontend
}

export interface UserNote extends Note {
  user_id: string;
}

export interface DealNote extends Note {
  deal_id: number;
}
