<template>
  <div
    :class="{
      [$style.container]: true,
      [$style.container_isHoverable]: !viewProps.schema.isReadOnly,
    }"
  >
    <Status :status-id="viewProps.entityValue" @click.stop="enterEditMode" />
  </div>
</template>

<script lang="ts" setup>
import Status from "../../components/FindingStatus.vue";

import type { ViewFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";

interface Props {
  viewProps: ViewFieldProps;
}

const props = defineProps<Props>();

const enterEditMode = () => {
  if (!props.viewProps.schema.isReadOnly) {
    props.viewProps.enterEditMode();
  }
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

.container {
  border-radius: 6px;
  transition: all 0.2s ease-out;
  border: 1px solid transparent;
  padding: 2px 6px;
  height: 28px;
  line-height: 22px;
  user-select: none;
  width: 100%;
}

.container_isHoverable:hover {
  border-color: colors.$sc-400;
}
</style>
