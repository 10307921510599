import type { State } from "./state";
import type { Item, ItemPayload, Order, OrderStorage } from "./types";
import type { MutationTree } from "vuex";

export const mutations: MutationTree<State> = {
  setItems(state: State, { items }: { items: Item[] }): void {
    state.items = items.sort(sortByOrder);
  },

  reorder(state: State, { data }: { data: Order[] }): void {
    const newOrders: OrderStorage = data.reduce(
      (bucket: OrderStorage, item) => {
        bucket[item.id] = item.order;

        return bucket;
      },
      {},
    );
    for (const item of state.items) {
      item.order =
        newOrders[item.id] !== undefined ? newOrders[item.id] : item.order;
    }
    state.items = state.items.sort(sortByOrder);
  },

  editItem(state: State, { item }: ItemPayload): void {
    const idx: number = findItemIdx(state.items, item.id);
    if (idx > -1) {
      state.items[idx] = item;
    }
  },

  addItem(state: State, { item }: ItemPayload): void {
    state.items.push(item);
  },

  deleteItem(state: State, { id }: { id: number }): void {
    const idx: number = findItemIdx(state.items, id);
    if (idx > -1) {
      state.items.splice(idx, 1);
    }
  },
};

function sortByOrder(a: Item, b: Item): number {
  return a.order > b.order ? 1 : -1;
}

export function findItemIdx(items: Item[], itemId: number): number {
  return items.findIndex(({ id }: Item): boolean => id === itemId);
}
