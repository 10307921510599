<template>
  <div class="dash-rooms-table-customize__row">
    <i class="dash-rooms-table-customize__row-drag fas fa-grip-vertical" />

    <ElCheckbox v-model="isChecked" border :disabled="isDisabled">
      {{ column.title }}
    </ElCheckbox>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { DrVxeTableColumn } from "@drVue/components/types";
import type { PropType } from "vue";

export default defineComponent({
  name: "ColumnCustomization",
  props: {
    column: { required: true, type: Object as PropType<DrVxeTableColumn> },
    isDisabled: { required: true, type: Boolean as PropType<boolean> },
  },
  emits: ["update-visible"],
  computed: {
    isChecked: {
      get() {
        return this.column.visible;
      },
      set() {
        this.updateVisible(!this.column.visible);
      },
    },
  },
  methods: {
    updateVisible(value: boolean) {
      this.$emit("update-visible", value);
    },
  },
});
</script>
