<template>
  <ElDropdown trigger="click" :disabled="disabled">
    <div :class="$style.toggle">
      <span
        :class="$style.phaseCircle"
        :style="{ color: currentPhase.color }"
      />
      <span
        :class="$style.phaseName"
        class="truncate"
        data-testid="deal-phase-name"
      >
        {{ currentPhase.name }}
      </span>
      <DrIcon size="xs" name="caret-down" :class="$style.caretIcon" />
    </div>

    <template #dropdown>
      <ElDropdownMenu>
        <ElDropdownItem
          v-for="phase in allPhases"
          :key="phase.id"
          :disabled="phase.isDisabled"
          @click="moveDeal(phase.id)"
        >
          <div
            :class="{
              [$style.phaseItem]: true,
              [$style.phaseItem_isDisabled]: phase.isDisabled,
            }"
          >
            <span :class="$style.phaseCircle" :style="{ color: phase.color }" />
            <span :class="$style.phaseName">
              {{ phase.name }}
            </span>
          </div>
        </ElDropdownItem>
      </ElDropdownMenu>
    </template>
  </ElDropdown>
  <MoveDealToPhaseDialog ref="moveDealToPhaseDialogRef" />
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { DrIcon } from "@shared/ui/dr-icon";

import { DrStore } from "@app/vue";
import MoveDealToPhaseDialog from "@drVue/components/client-dashboard/deals/MoveDealToPhaseDialog/MoveDealToPhaseDialog.vue";
import { getOrCreatePhaseInfo } from "@drVue/components/client-dashboard/deals/MoveDealToPhaseDialog/utils";
import { markDisabledPhases } from "@drVue/components/client-dashboard/deals/utils";

import type { PhaseItem } from "@drVue/api-service/client-dashboard";

interface Props {
  dealId: number;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
});

const deal = computed(() =>
  DrStore.getters["clientDashboard/deals/getDealById"](props.dealId),
);

const currentPhase = computed(() =>
  DrStore.getters["clientDashboard/phases/getPhaseById"](deal.value.phase),
);

const allPhases = computed(() => {
  return markDisabledPhases(DrStore.state.clientDashboard.phases.items).filter(
    (phase: PhaseItem) => phase.id !== deal.value.phase,
  );
});

const moveDealToPhaseDialogRef = ref<InstanceType<
  typeof MoveDealToPhaseDialog
> | null>(null);

function moveDeal(phaseId: number) {
  const selectedPhaseInfo = getOrCreatePhaseInfo(deal.value, phaseId);
  moveDealToPhaseDialogRef.value!.open(selectedPhaseInfo);
}
</script>

<style scoped lang="scss" module>
@use "@app/styles/scss/_colors.scss";
@use "@app/styles/scss/typography.scss" as typo;

.toggle {
  width: 100%;
  height: 32px;
  padding: 4px 8px;
  border-radius: 8px;
  display: grid;
  gap: 6px;
  align-items: center;
  grid-template-columns: min-content 1fr min-content;

  &:hover {
    background-color: colors.$pr-100;

    .caretIcon {
      opacity: 1;
    }
  }
}

.phaseName {
  max-width: 240px;
  font-weight: typo.$font-weight-medium;
  color: colors.$pr-500;
}

.phaseCircle {
  $container-size: 12px;
  $circle-size: 8px;

  display: inline-flex;
  height: $container-size;
  width: $container-size;
  align-items: center;
  justify-content: center;

  &::after {
    content: "";
    border-radius: $circle-size;
    display: inline-block;
    height: $circle-size;
    width: $circle-size;
    background-color: currentColor;
  }
}

.caretIcon {
  opacity: 0;
  color: colors.$pr-400;
}

.phaseItem {
  display: grid;
  gap: 6px;
  align-items: center;
  grid-template-columns: min-content 1fr;
}

.phaseItem_isDisabled {
  opacity: 50%;
  filter: grayscale(100%);
}
</style>
