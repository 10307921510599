import tooltipHtml from "./index.html?raw";

Controller.$inject = ["$element"];
import DrStore from "@drVue/store";

angular
  .module("dealroom.analytics.card.burndown.tooltip", ["dealroom.task"])
  .component("drAnalyticsTasksBurndownCardTooltipValue", {
    bindings: {
      counter: "<",
      total: "<",
      opened: "<",
    },
    template: tooltipHtml,
    controller: Controller,
  })
  .service("AnalyticsBurndownTickTooltip", AnalyticsBurndownTickTooltip);

const TEMPLATE = `
  <dr-analytics-tasks-burndown-card-tooltip-value
        counter="::$ctrl.counter"
        total="::$ctrl.total"
        opened="::$ctrl.opened"
        />`;

AnalyticsBurndownTickTooltip.$inject = [];
function AnalyticsBurndownTickTooltip() {
  return {
    get: function (opened, total, counter) {
      const ctrl = { opened, total, counter };
      return { template: TEMPLATE, ctrl };
    },
  };
}

function Controller($element) {
  const $ctrl = this;

  const statusIds = DrStore.state.room.tasksStatuses.list.reduce(
    (bucket, s) => {
      bucket[s.type] = s.id;
      return bucket;
    },
    {},
  );

  $ctrl.$onInit = function () {
    $ctrl.statuses = [
      {
        title: "Created",
        statusId: statusIds["open"],
        value: $ctrl.counter.created,
      },
      {
        title: "Reopened",
        statusId: statusIds["reopened"],
        value: $ctrl.counter.fromResolved,
      },
      {
        title: "Resolved",
        statusId: statusIds["resolved"],
        value: $ctrl.counter.toResolved,
      },
    ];

    $ctrl.resolved = $ctrl.total - $ctrl.opened;

    $ctrl.barValues = [
      {
        tooltip: { body: "Requests Total" },
        value: $ctrl.total,
        isTotal: true,
      },
      {
        tooltip: { body: "Requests Resolved" },
        value: $ctrl.resolved,
        percantageColorize: true,
      },
    ];
  };
}
