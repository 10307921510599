import "./base";
import "./documents";
import "./tasks";
import "./users";

angular.module("dealroom.analytics.datagrid-table", [
  "dealroom.analytics.datagrid-table.base",

  "dealroom.analytics.datagrid-table.documents",
  "dealroom.analytics.datagrid-table.users",
  "dealroom.analytics.datagrid-table.tasks",
]);
