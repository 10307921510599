<template>
  <div>
    <ElRow v-for="i of interfaces" :key="i.interface" :class="$style.row">
      <Component
        :is="COMPONENTS[i.interface]"
        :ref="`${i.interface}Refs`"
        :interface="i"
        @confirmed="onConfirmed"
        @removed="getMfaList"
      />
    </ElRow>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from "vue";

import { $notifyDanger, createDictionary } from "@app/vue/common";
import { MfaApiService } from "@drVue/components/client-dashboard/users/AccountSettings/MfaApiService";
import RecoveryCodes from "./RecoveryCodes.vue";
import SmsInterface from "./SmsInterface.vue";
import TotpInterface from "./TotpInterface.vue";

import type { MfaInterface } from "@drVue/components/client-dashboard/users/AccountSettings/MfaApiService";

interface Emits {
  (e: "confirmed"): void;
}

const emit = defineEmits<Emits>();

type Component =
  | typeof TotpInterface
  | typeof SmsInterface
  | typeof RecoveryCodes;

const COMPONENTS = createDictionary<Component>({
  totp: TotpInterface,
  sms: SmsInterface,
  recovery: RecoveryCodes,
});

const totpRefs = ref<InstanceType<typeof TotpInterface>[]>();
const smsRefs = ref<InstanceType<typeof SmsInterface>[]>();
const recoveryRefs = ref<InstanceType<typeof RecoveryCodes>[]>();

const api = new MfaApiService();

const interfaces = ref<MfaInterface[]>([]);

const getMfaList = () => {
  api.getMfaList().then(
    (r) => {
      interfaces.value = r.data;
    },
    () => {
      $notifyDanger("Failed to load two-factor authentication interfaces");
    },
  );
};

const onConfirmed = (codes: string[]) => {
  getMfaList();

  if (!recoveryRefs.value || !recoveryRefs.value.length) return;

  recoveryRefs.value[0].showCodes(codes).then(() => emit("confirmed"));
};

onBeforeMount(() => {
  getMfaList();
});
</script>

<style module lang="scss">
.row {
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
