import { nullPhaseData } from "./types";

import type { PhaseItem, RootState, State } from "./types";
import type { GetterTree } from "vuex";

type IPhaseId = number | null;
type IPhasePartial = Partial<PhaseItem>;
interface ById {
  [key: number]: IPhasePartial;
}

export const phasesGetters: GetterTree<State, RootState> = {
  // (state: S, tasksGetters: any, rootState: R, rootGetters: any) => any;
  phasesWithDeals(state, localGetters, rootState, rootGetters): PhaseItem[] {
    const hasDealsWithNullPhase =
      rootGetters["clientDashboard/deals/getDealsByPhase"](null).length > 0;
    const nullPhase = nullPhaseData;
    const phases = state.items;
    // inject nullPhase to show rooms with non-defined phase

    return hasDealsWithNullPhase
      ? [...phases, nullPhase as unknown as PhaseItem]
      : phases;
  },

  getPhaseById(state): (phaseId: IPhaseId) => IPhasePartial {
    const byIds = state.items.reduce(
      (bucket, { id, name, order, color }: PhaseItem) => {
        bucket[id] = { name, order, color };

        return bucket;
      },
      {} as ById,
    );
    const nullPhase = nullPhaseData as unknown as IPhasePartial;

    return (phaseId: number | null) =>
      typeof phaseId === "number" ? byIds[phaseId] || nullPhase : nullPhase;
  },
};
