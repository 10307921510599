<template>
  <DrVxeGrid outlined :columns="tableColumns.columns" :data="clients">
    <template #empty> <h3>No clients found</h3></template>
  </DrVxeGrid>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import TableColumns from "./tableColumns";

import type { ClientRow } from "./types";

interface Props {
  clients: ClientRow[];
}

const props = defineProps<Props>();

const tableColumns = computed(() => {
  return new TableColumns();
});
</script>
