<template>
  <div>
    <div class="row">
      <div v-if="isInitialized" class="col-md-10 col-md-offset-1">
        <AngularComponent :component="billingComponent" :app-element="$el" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { AngularComponent } from "@shared/ui/angular";

interface Data {
  angularApp: any;
  isInitialized: boolean;
  billingComponent: any;
}

export default defineComponent({
  name: "Billing",
  components: {
    AngularComponent,
  },
  data(): Data {
    return {
      angularApp: null,
      isInitialized: false,
      billingComponent: {
        template: "<billing-subscription></billing-subscription>",
        bindings: {},
      },
    };
  },
  mounted() {
    angular.element(() => {
      this.angularApp = angular.bootstrap(this.$el, ["dealroom.billing"]);
      this.isInitialized = true;
    });
  },
  beforeUnmount() {
    this.angularApp.get("$rootScope").$destroy();
    this.angularApp = null;
  },
});
</script>
