PrioritiesDropdownController.$inject = [
  "$element",
  "AnalyticsDropdownPromisedItemsService",
  "ActivityVisibleCategoriesService",
];
angular
  .module("dealroom.analytics.dropdown.priorities", [
    "dealroom.analytics.dropdown",

    "dealroom.analytics.service.data",
  ])
  .component("drAnalyticsPriorityDropdown", {
    controller: PrioritiesDropdownController,
  });

const PRIORITIES = [
  { label: "High", id: "high" },
  { label: "Medium", id: "medium" },
  { label: "Low", id: "low" },
];

function PrioritiesDropdownController(
  $element,
  AnalyticsDropdownPromisedItemsService,
  ActivityVisibleCategoriesService,
) {
  const promise =
    ActivityVisibleCategoriesService.loadingPromise.then(createScope);
  AnalyticsDropdownPromisedItemsService.render(promise);

  function createScope() {
    const ctrl = {
      label: "Priority",
      uid: "priorities",
      multiselect: true,
      items: PRIORITIES,
      handleSelect,
    };
    return { ctrl, $element };

    function handleSelect(selected = []) {
      ActivityVisibleCategoriesService.setPriorities(selected.map((s) => s.id));
    }
  }
}
