import { pinia } from "@drVue/store/pinia";
import { useTasksLabelsStore } from "@drVue/store/pinia/room/tasksLabels/tasksLabels";

export default function drLabelKeyProvider() {
  const tasksLabels = useTasksLabelsStore(pinia);

  // setLabels used in task.js to initialize the list from RoomConfig.labelKeys
  this.setLabels = function (labelsList) {
    tasksLabels.list = [...labelsList].sort((a, b) => a.id - b.id);
  };

  const safeApplyAsync = () => {
    setTimeout(() => {
      const drSafeApply = angular
        .element(document.body)
        .injector()
        .get("drSafeApply");

      drSafeApply.apply();
    });
  };

  this.$get = function () {
    const service = {
      get: (labelId) => tasksLabels.dict[labelId],
      syncAll: () => tasksLabels.load().then(safeApplyAsync),
      update: (label) => tasksLabels.update(label).then(safeApplyAsync),
      add: (label) => tasksLabels.create(label).then(safeApplyAsync),
      delete: (label) => tasksLabels.delete(label.id).then(safeApplyAsync),
    };

    Object.defineProperty(service, "list", {
      get: () => tasksLabels.list,
    });

    return service;
  };
}
