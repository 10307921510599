RequestsListsDropdownController.$inject = [
  "$element",
  "AnalyticsDropdownPromisedItemsService",
  "ActivityVisibleCategoriesService",
  "ActivityCategoriesService",
];
angular
  .module("dealroom.analytics.dropdown.requests-lists", [
    "dealroom.analytics.dropdown",

    "dealroom.analytics.service.data",

    "dealroom.task",
  ])
  .component("drAnalyticsRequestsListsDropdown", {
    controller: RequestsListsDropdownController,
  });

function RequestsListsDropdownController(
  $element,
  AnalyticsDropdownPromisedItemsService,
  ActivityVisibleCategoriesService,
  ActivityCategoriesService,
) {
  const promise =
    ActivityVisibleCategoriesService.loadingPromise.then(createScope);
  AnalyticsDropdownPromisedItemsService.render(promise);

  function createScope() {
    const ctrl = {
      label: "Lists",
      uid: "lists",
      multiselect: true,
      items: getItems(),
      handleSelect,
    };
    return { ctrl, $element };

    function handleSelect(selected = []) {
      ActivityVisibleCategoriesService.set(selected.map((d) => d.id));
    }

    function getItems() {
      return ActivityCategoriesService.rootCategoriesIds
        .map((id) => ({
          label: ActivityCategoriesService.categories[id].name,
          order: ActivityCategoriesService.categories[id].order,
          id: id,
        }))
        .sort((a, b) => d3.ascending(a.order, b.order));
    }
  }
}
