<template>
  <SynergiesSettingsHero v-if="!ROOM_DATA.enableClientSynergies" />
  <div v-else :class="$style.container">
    <div :class="$style.switch">
      <div>Enable synergy tracking for this room</div>
      <ElSwitch v-model="formModel.enable" />
    </div>
    <div v-if="formModel.enable">
      <div :class="$style.interval">
        <div>Measurement interval:</div>
        <ElRadioGroup
          :model-value="formModel.interval"
          @change="showWarningAboutInterval"
        >
          <ElRadio label="quarter">Quarters</ElRadio>
          <ElRadio label="month">Months</ElRadio>
        </ElRadioGroup>
      </div>
      <div v-if="formModel.interval === 'month'" :class="$style.control">
        <div>Months range:</div>
        <ElFormItem :error="monthError">
          <DrDatePicker
            v-model="monthRange"
            type="monthrange"
            format="MMM YYYY"
            range-separator="To"
            start-placeholder="Start month"
            end-placeholder="End month"
            unlink-panels
          />
        </ElFormItem>
      </div>
      <div v-if="formModel.interval === 'quarter'" :class="$style.control">
        <div>Quarters range:</div>
        <ElFormItem :error="quartersError">
          <ElSelect v-model="qFrom" placeholder="" style="width: 80px">
            <ElOption
              v-for="item in fromQuarterOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </ElSelect>
          <DrDatePicker v-model="yearFrom" type="year" style="width: 100px" />
          <div :class="$style.to">To</div>
          <ElSelect v-model="qTo" placeholder="" style="width: 80px">
            <ElOption
              v-for="item in toQuarterOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </ElSelect>
          <DrDatePicker v-model="yearTo" type="year" style="width: 100px" />
        </ElFormItem>
      </div>
    </div>
    <div :class="$style.actions">
      <ElButton type="primary" :disabled="isReloadPending" @click="saveChanges">
        {{ saveChangesButtonText }}
      </ElButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ElMessageBox } from "element-plus";
import { computed, reactive, ref, watch } from "vue";
import DrDatePicker, {
  convertBtzToPtz,
} from "@shared/ui/dr-datepicker/DrDatepicker.vue";

import { ROOM_DATA } from "@app/setups";
import { DateType, deserializeDrDate } from "@drVue/api-service/parse";
import { $notifyDanger, $notifySuccess } from "@drVue/common";
import SynergiesSettingsHero from "@drVue/components/room/synergies/SynergiesSettingsHero.vue";
import { drUserTime } from "@drVue/filters/drUserTime";
import { synergiesApi } from "@drVue/store/modules/room/synergies/SynergiesApiService";

import type { SynergySettings } from "@setups/data";

const quarterOptions = [];
for (let i = 1; i <= 4; i++) {
  quarterOptions.push({
    value: i,
    label: `Q${i}`,
  });
}

const fromQuarterOptions = ref([...quarterOptions]);
const toQuarterOptions = ref([...quarterOptions]);

const now = new Date();

const isReloadPending = ref(false);
const saveChangesButtonText = ref("Save changes");

const fromDate = deserializeDrDate(
  DateType.Date,
  ROOM_DATA.synergySettings?.from_date ?? `${now.getFullYear()}-01-01`,
);
const toDate = deserializeDrDate(
  DateType.Date,
  ROOM_DATA.synergySettings?.to_date ?? `${now.getFullYear()}-10-01`,
);

const monthError = ref("");
const monthRange = ref<[Date, Date]>([fromDate, toDate]);
watch(
  monthRange,
  ([from, to]) => {
    const fromDateTime = from.getTime();
    const toDateTime = to.getTime();

    if (fromDateTime === toDateTime) {
      monthError.value = "You should select more than one month.";
    } else if (fromDateTime > toDateTime) {
      monthError.value = "From month should be less than to month.";
    } else {
      monthError.value = "";
    }
  },
  {
    immediate: true,
  },
);

// Quarters cascading
const qFrom = ref(Math.ceil((fromDate.getMonth() + 1) / 3));
const yearFrom = ref(fromDate);
const qTo = ref(Math.ceil((toDate.getMonth() + 1) / 3));
const yearTo = ref(toDate);
const qFromDate = computed(() => {
  const year = yearFrom.value.getFullYear();
  return convertBtzToPtz(new Date(year, (qFrom.value - 1) * 3, 1));
});
const qToDate = computed(() => {
  const year = yearTo.value.getFullYear();
  return convertBtzToPtz(new Date(year, (qTo.value - 1) * 3, 1));
});

const quartersError = ref("");
watch(
  [qFromDate, qToDate],
  () => {
    const qFromDateTime = qFromDate.value.getTime();
    const qToDateTime = qToDate.value.getTime();

    if (qFromDateTime === qToDateTime) {
      quartersError.value = "You should select more than one quarter.";
    } else if (qFromDateTime > qToDateTime) {
      quartersError.value = "From date should be less than to date.";
    } else {
      quartersError.value = "";
    }
  },
  {
    immediate: true,
  },
);

const showWarningAboutInterval = (
  interval: string | number | boolean | undefined,
) => {
  if (interval === undefined) return;

  ElMessageBox({
    type: "warning",
    title: "Confirm switching intervals",
    message: `All the data within the current interval will be deleted. Would you like to proceed?`,
    confirmButtonText: "Confirm",
    showCancelButton: true,
    showClose: true,
    closeOnHashChange: false,
  }).then(() => {
    formModel.interval = interval as SynergySettings["interval"];
  });
};

const saveChanges = () => {
  const interval = formModel.interval;

  if (
    (interval === "month" && monthError.value) ||
    (interval === "quarter" && quartersError.value)
  ) {
    return;
  }

  if (
    ROOM_DATA.synergySettings?.interval &&
    ROOM_DATA.synergySettings.interval === formModel.interval
  ) {
    const interval = formModel.interval;
    const from = interval === "quarter" ? qFromDate.value : monthRange.value[0];
    const to = interval === "quarter" ? qToDate.value : monthRange.value[1];

    const isNewStartDateAfterOldStartDate = from.getTime() > fromDate.getTime();
    const isNewEndDateBeforeOldEndDate = to.getTime() < toDate.getTime();

    if (isNewStartDateAfterOldStartDate || isNewEndDateBeforeOldEndDate) {
      showWarningAboutAdjusting();
      return;
    }
  }

  _saveChanges();
};

const showWarningAboutAdjusting = () => {
  ElMessageBox({
    type: "warning",
    title: "Confirm adjusting intervals",
    message: `All the data outside the current period will be deleted. Would you like to proceed?`,
    confirmButtonText: "Confirm",
    showCancelButton: true,
    showClose: true,
    closeOnHashChange: false,
  }).then(_saveChanges);
};

const _saveChanges = () => {
  const interval = formModel.interval;

  const fromDateString = drUserTime(
    interval === "quarter" ? qFromDate.value : monthRange.value[0],
    "iso-date",
  );
  const toDateString = drUserTime(
    interval === "quarter" ? qToDate.value : monthRange.value[1],
    "iso-date",
  );

  if (!fromDateString || !toDateString) {
    return;
  }

  const payload: SynergySettings = {
    enable: formModel.enable,
    interval: formModel.interval,
    from_date: fromDateString,
    to_date: toDateString,
  };

  synergiesApi.saveSettings(payload).then(
    () => {
      $notifySuccess("Synergies settings saved");
      isReloadPending.value = true;

      let counter = 4;
      saveChangesButtonText.value = `Saved!`;
      const interval = setInterval(() => {
        counter--;
        if (counter > 0) {
          saveChangesButtonText.value = `Reload in ${counter}...`;
        } else {
          saveChangesButtonText.value = "Reloading";
          clearInterval(interval);
          location.reload();
        }
      }, 1000);
    },
    () => $notifyDanger("Failed to save synergies settings"),
  );
};

interface SynergySettingsFormModel {
  enable: SynergySettings["enable"];
  interval: SynergySettings["interval"];
}

const formModel = reactive<SynergySettingsFormModel>({
  enable: ROOM_DATA.synergySettings?.enable ?? false,
  interval: ROOM_DATA.synergySettings?.interval ?? "quarter",
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.container {
  width: 514px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.interval {
  margin-bottom: 10px;
}

.control {
  margin-bottom: 20px;
}
.to {
  margin: 0 10px;
}

.switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid colors.$pr-150;
  padding-bottom: 7px;
  margin-bottom: 24px;
}

.actions {
  display: flex;
  justify-content: flex-end;
}
</style>
