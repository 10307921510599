<template>
  <ItemEdit
    :item="phase"
    :item-type="itemType"
    :label-field="labelField"
    :action-path="actionPath"
    :rules="rules"
    @close="$emit('close')"
  >
    <template #default="slotProps">
      <ElFormItem
        label="Phase Name"
        prop="name"
        :error="slotProps.getErrors('name')"
      >
        <ElInput
          v-model="slotProps.form.name"
          placeholder="Phase Name"
          autofocus
          @change="slotProps.resetErrors('name')"
        />
      </ElFormItem>
      <ElFormItem
        label="Phase Color"
        prop="color"
        :error="slotProps.getErrors('color')"
      >
        <ElColorPicker
          v-model="slotProps.form.color"
          :predefine="predefineColors"
          size="default"
          @change="slotProps.resetErrors('color')"
        />
      </ElFormItem>
    </template>
  </ItemEdit>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ItemEdit from "../../../BaseItemRow/EditForm";

import type { PhaseItem } from "@drVue/api-service/client-dashboard";
import type { PropType } from "vue";

interface Data {
  itemType: any;
  labelField: any;
  actionPath: any;
  rules: any;
  predefineColors: any;
}

export default defineComponent({
  name: "EditForm",
  components: {
    ItemEdit,
  },
  props: {
    phase: { required: true, type: Object as PropType<PhaseItem> },
  },
  emits: ["close"],
  data(): Data {
    return {
      itemType: "phase",
      labelField: "name",
      actionPath: "clientDashboard/phases/",
      rules: {
        name: [
          { required: true, message: "Please enter name", trigger: "blur" },
        ],
        color: [
          { required: true, message: "Please select color", trigger: "change" },
        ],
      },
      predefineColors: [
        "#eb5a46",
        "#f2a620",
        "#f2d600",
        "#4e9840",
        "#51e898",
        "#00c2e0",
        "#a6b2bc",
        "#7037e2",
        "#c377e0",
        "#ff80ce",
      ],
    };
  },
});
</script>
