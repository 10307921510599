import { ApiService } from "@drVue/api-service";

import type { OrganizationGroupData } from "@setups/data";

const DealsAccessType = ["all", "room_admin"] as const;
export type DealsAccessTypeEnum = (typeof DealsAccessType)[number];

export interface OrganizationGroup extends OrganizationGroupData {}

export type OrganizationGroupCreatePayload = Omit<
  OrganizationGroup,
  "id" | "uid"
>;

export type OrganizationGroupUpdatePayload = Partial<
  Omit<OrganizationGroup, "id" | "uid">
>;

export class OrganizationGroupsApiService extends ApiService {
  public async load(): Promise<OrganizationGroup[]> {
    const url: string = this.Urls["api:client-dashboard:org-groups-list"]();
    return this.get<OrganizationGroup[]>(url);
  }

  /**
   * Creates new organization group
   * @param data
   * @returns
   */
  public async create(
    data: OrganizationGroupCreatePayload,
  ): Promise<OrganizationGroup> {
    const url = this.Urls["api:client-dashboard:org-groups-list"]();
    return this.post(url, data);
  }

  /**
   * Updates existing organization group partially
   * @param group_id
   * @param changes
   * @returns
   */
  public async update(
    group_id: OrganizationGroup["id"],
    changes: OrganizationGroupUpdatePayload,
  ): Promise<OrganizationGroup> {
    const url = this.Urls["api:client-dashboard:org-groups-detail"](group_id);
    return this.patch(url, changes);
  }

  /**
   * Deletes organization group
   * @param group_id
   * @returns
   */
  public async remove(group_id: OrganizationGroup["id"]): Promise<void> {
    const url = this.Urls["api:client-dashboard:org-groups-detail"](group_id);
    return this.delete(url).then(() => undefined);
  }
}
