<template>
  <div :class="$style.wrapper" v-loading="aiReportsStore.isLoading">
    <div
      v-for="report in aiReportsStore.list"
      :key="report.id"
      :class="$style.report"
    >
      <h4>
        {{ report.label }}
        <ElPopconfirm
          :title="`Are you sure to delete ${report.label} report?`"
          :hide-icon="true"
          :width="200"
          :confirm-button-type="'warning'"
          @confirm="onReportDelete(report)"
        >
          <template #reference>
            <ElButton text size="small">
              <i class="fas fa-trash" />
            </ElButton>
          </template>
        </ElPopconfirm>
      </h4>
      <ul>
        <li v-for="field in report.fields" :key="field.id">
          <b>
            {{ field.label }}
            <ElPopconfirm
              :title="`Are you sure to delete ${field.label} field?`"
              :hide-icon="true"
              :width="200"
              :confirm-button-type="'warning'"
              @confirm="onFieldDelete(report, field)"
            >
              <template #reference>
                <ElButton text size="small">
                  <i class="fas fa-trash" />
                </ElButton>
              </template>
            </ElPopconfirm>
          </b>
          <p><i>Prompt:</i> {{ field.prompt_template }}</p>
          <p v-if="field.prompt_context?.answer_definition">
            <i>Format: </i>{{ field.prompt_context.answer_definition }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { $notifyDanger } from "@drVue/common";
import { AiReportsApiService } from "@drVue/components/room/ai/api";
import { pinia } from "@drVue/store/pinia";
import { useAiReportsStore } from "../AiReportsStore";

import type { AiReport, AiReportField } from "../types";
const api = new AiReportsApiService();
const aiReportsStore = useAiReportsStore(pinia);

const onReportDelete = (report: AiReport) => {
  aiReportsStore.remove(report.id);
};
const onFieldDelete = (report: AiReport, field: AiReportField) => {
  api.deleteReportField(report.id, field.id).then(
    () => {
      aiReportsStore.load();
    },
    () => {
      $notifyDanger("Failed to delete report field");
    },
  );
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: spacing.$m;
}

.report {
  padding: 20px;
  border: 1px solid colors.$pr-150;
  border-radius: 4px;
  margin: spacing.$xs spacing.$s;
}
</style>
