<template>
  <DrDrawer
    fixed-footer
    size="large"
    title="New deal"
    :shown="isDrawerVisible"
    @close="handleClose"
  >
    <template #sub-header>
      <ElTabs v-model="drawerActiveTab" :class="$style.tabsContainer">
        <ElTabPane :name="DealFormTabs.DEAL" label="Deal details" />
        <ElTabPane
          v-if="isRoomTabVisible"
          :name="DealFormTabs.ROOM"
          label="Room"
        />
      </ElTabs>
    </template>
    <template #default>
      <div
        v-loading="isFormSubmitting"
        :class="{
          [$style.tabsContentContainer]: true,
          [$style.tabsContentContainer_isSubmitting]: isFormSubmitting,
        }"
      >
        <Transition :name="$style.fadeIn">
          <div v-if="drawerActiveTab === DealFormTabs.DEAL">
            <DealForm
              :deal="dealData"
              :form-errors="dealFormErrors"
              @update="handleDealUpdate"
              @submit="handleSubmit"
            />
          </div>
        </Transition>
        <Transition :name="$style.fadeIn">
          <div v-if="drawerActiveTab === DealFormTabs.ROOM">
            <div
              :class="{
                [$style.switchContainer]: true,
                [$style.switchContainer_isSwitchOn]: isRoomCreateDialogVisible,
              }"
            >
              <ElTooltip
                :disabled="!isForcedToCreateRoom"
                content="Deal creation requires room creation for your role."
              >
                <ElSwitch
                  v-model="isRoomCreateDialogVisible"
                  active-text="Setup a room for this deal"
                  :class="$style.visibilitySwitch"
                  :disabled="isForcedToCreateRoom"
                />
              </ElTooltip>
            </div>
            <Transition :name="$style.fadeIn">
              <div v-if="isRoomCreateDialogVisible">
                <RoomForm
                  :room-data="roomData"
                  :form-errors="dealFormErrors"
                  :reset-error-fn="resetErrors"
                  @update="handleRoomUpdate"
                />
              </div>
            </Transition>
          </div>
        </Transition>
      </div>
    </template>
    <template #footer>
      <ElButton :disabled="isFormSubmitting" @click="handleClose">
        Cancel
      </ElButton>
      <ElButton
        type="primary"
        :disabled="isFormSubmitting"
        @click="handleSubmit"
      >
        {{ drawerSubmitBtnText }}
      </ElButton>
    </template>
  </DrDrawer>
</template>

<script setup lang="ts">
import axios from "axios";
import { cloneDeep } from "lodash-es";
import { set } from "lodash-es";
import { computed, ref, watch } from "vue";
import { DrDrawer } from "@shared/ui/dr-drawer";
import { useFormHelper } from "@shared/ui/dr-form";

import { OrganizationGroupDealAccessType } from "@setups/enums";
import { ORG_MEMBER_DATA } from "@app/setups";
import { DrStore } from "@app/vue";
import { serializeCustomData } from "@drVue/api-service/parse";
import { $notifyDanger, $notifySuccess } from "@drVue/common";
import { DealFormTabs } from "@drVue/components/client-dashboard/deals/CreateDeal/types";
import { DealsApiService } from "@drVue/store/modules/client-dashboard/deals/DealsApiService";
import RoomForm from "../CreateRoom/RoomForm.vue";
import DealForm from "./DealForm.vue";

import type { DealFormTab } from "./types";
import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { RoomCreateData } from "@drVue/api-service/common/rooms";
import type {
  Deal,
  IDealCreateData,
} from "@drVue/store/modules/client-dashboard/deals/types";

const isForcedToCreateRoom =
  ORG_MEMBER_DATA.group.deals_access_type ===
  OrganizationGroupDealAccessType.RoomAdmin;

const isDrawerVisible = ref(false);
const isRoomCreateDialogVisible = ref(isForcedToCreateRoom);
const drawerSubmitBtnText = computed(() =>
  isRoomCreateDialogVisible.value ? "Create deal & room" : "Create deal",
);
const drawerActiveTab = ref<DealFormTab>(DealFormTabs.DEAL);

const isRoomTabVisible = ORG_MEMBER_DATA.group.can_create_rooms;

const dealDataTemplate = {
  title: "",
  phase: DrStore.state.clientDashboard.phases.items[0].id,
  custom_data: {},
  start_date: new Date(),
};

const dealData = ref<IDealCreateData>(cloneDeep(dealDataTemplate));

const roomDataTemplate: RoomCreateData = {
  title: "",
  subdomain: "",
  client: ORG_MEMBER_DATA.client.id,
  // `copy_options` must be undefined, otherwise backend will complain there's no source_room
  copy_options: undefined!,
};

const roomData = ref<RoomCreateData>(cloneDeep(roomDataTemplate));

const {
  formErrors: dealFormErrors,
  hookFormSubmitPromise,
  isFormSubmitting,
  resetErrors,
  resetError,
} = useFormHelper<Deal>();

watch(
  () => dealFormErrors.value,
  () => {
    // Deal form errors are displayed along with deal's set of inputs
    // Except for subdomain and copy options errors, which is displayed along with room form
    if (
      "subdomain" in dealFormErrors.value ||
      "copy_options" in dealFormErrors.value
    ) {
      drawerActiveTab.value = DealFormTabs.ROOM;
    } else {
      drawerActiveTab.value = DealFormTabs.DEAL;
    }
  },
);

watch(
  () => dealFormErrors.value,
  () => {
    if (dealFormErrors.value.non_field_errors) {
      if (Array.isArray(dealFormErrors.value.non_field_errors)) {
        dealFormErrors.value.non_field_errors.forEach((error) => {
          $notifyDanger(error);
        });
      } else {
        $notifyDanger(dealFormErrors.value.non_field_errors);
      }
    }
  },
);

const dealsService = new DealsApiService();

function handleRoomUpdate(payload: RoomCreateData) {
  roomData.value = payload;
}

function handleDealUpdate({ field, value }: { field: string; value: any }) {
  resetError(field);
  set(dealData.value, field, value);
}

async function handleSubmit() {
  if (isFormSubmitting.value) return;

  resetErrors();

  try {
    const dealCustomFields = DrStore.getters[
      "clientDashboard/customFields/byObjectType"
    ]("deal") as FieldItem[];

    const deal = cloneDeep(dealData.value);

    if (isRoomCreateDialogVisible.value) {
      deal.new_room_options = roomData.value;
    }

    serializeCustomData(deal.custom_data, dealCustomFields);

    await hookFormSubmitPromise(dealsService.createDeal(deal));
    await DrStore.dispatch("clientDashboard/deals/load");

    $notifySuccess("Deal created successfully");

    reset();
    close();
  } catch (e: unknown) {
    // We want to notify only about server errors
    if (axios.isAxiosError(e) && e.response?.status! >= 500) {
      $notifyDanger("Something went wrong while creating a deal");
    }
  }
}

function handleClose() {
  if (isFormSubmitting.value) return;

  reset();
  close();
}

function reset() {
  resetErrors();

  isRoomCreateDialogVisible.value = isForcedToCreateRoom;

  dealData.value = cloneDeep(dealDataTemplate);
  roomData.value = cloneDeep(roomDataTemplate);
}

function open() {
  drawerActiveTab.value = DealFormTabs.DEAL;
  isDrawerVisible.value = true;
}

function close() {
  isDrawerVisible.value = false;
}

defineExpose({ open, close });
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.tabsContainer {
  margin-bottom: -30px;
}

.tabsContentContainer {
  padding: 0 8px;
}

.tabsContentContainer_isSubmitting {
  // This only needed until DrDrawer's block-loader is fixed
  overflow: hidden;
  height: calc(100vh - 197px);
  width: 496px;
}

.switchContainer {
  border-bottom: 1px solid colors.$pr-150;
}
.switchContainer_isSwitchOn {
  border-bottom: unset;
}

.visibilitySwitch {
  margin: 0 0 16px 0;
}

.fadeIn {
  &:global(-enter-active) {
    transition: 0.3s;
  }
  &:global(-enter-from),
  &:global(-leave-to) {
    opacity: 0;
  }
}
</style>
