<template>
  <ElDialog
    v-model="isModalVisible"
    width="688px"
    title="Add token key"
    :append-to-body="true"
    :close-on-click-modal="false"
    :show-close="false"
    :close-on-press-escape="false"
    top="60px"
  >
    <DrLoader v-if="isCreating" />

    <div v-if="!isCreating">
      <div class="alert alert-danger">
        This is the only time that the token key can be viewed. You cannot
        recover it later. However, you can create new token keys at any time.
      </div>
      <pre>{{ key }}</pre>
    </div>

    <template #footer>
      <div class="text-right">
        <ElButton type="primary" :disabled="isCreating" @click="close"
          >Close
        </ElButton>
      </div>
    </template>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrLoader from "@shared/ui/dr-loader";

import { TokensApiService } from "@drVue/store/modules/client-dashboard/intergrations/TokensApiService";

import type { PropType } from "vue";

interface Data {
  tokensApi: TokensApiService;
  isCreating: boolean;
  key: string;
  errors: any;
  isModalVisible: boolean;
}

export default defineComponent({
  name: "AddTokenModal",
  components: {
    DrLoader,
  },
  props: {
    integrationId: {
      required: false,
      default: undefined,
      type: Number as PropType<number | undefined>,
    },
  },
  emits: ["created"],
  data(): Data {
    return {
      tokensApi: new TokensApiService(),
      isCreating: false,
      key: "",
      errors: {},
      isModalVisible: false,
    };
  },
  methods: {
    open() {
      this.isModalVisible = true;
      this.isCreating = true;

      this.tokensApi.create(this.integrationId).then((r) => {
        if (r.key) this.key = r.key;
        this.isCreating = false;

        this.$emit("created", r);
      });
    },
    close() {
      this.isModalVisible = false;
    },
  },
});
</script>
