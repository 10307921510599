import { downloadsActions } from "./DownloadsActions";
import { downloadsMutations } from "./DownloadsMutations";
import { DownloadsState } from "./DownloadsState";

import type { RootState } from "@drVue/store/state";
import type { Module } from "vuex";

export const DownloadsModule: Module<DownloadsState, RootState> = {
  actions: downloadsActions,
  mutations: downloadsMutations,
  namespaced: true,
  state: new DownloadsState(),
};
