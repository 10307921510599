import type { TasksRelatedState } from "./TasksRelatedState";
import type { DocumentId, FolderId, Task } from "@drVue/store/pinia/room/tasks";
import type { RootState } from "@drVue/store/state";
import type { Dictionary } from "@drVue/types";
import type { ActionTree } from "vuex";

export const tasksRelatedActions: ActionTree<TasksRelatedState, RootState> = {
  update({ state, commit, dispatch }, tasks: Task[]) {
    // use temporary objects to create simple set of IDs initially
    const tempDocTask = {};
    const tempFolderTask = {};
    const tempTaskDoc = {};
    const tempTaskFolder = {};

    function _addToCollection(
      tempCollection: Dictionary<any>,
      itemId: any,
      relatedId: any,
    ) {
      if (!tempCollection[itemId]) {
        tempCollection[itemId] = new Set();
      }
      tempCollection[itemId].add(relatedId);
    }

    tasks.forEach((task: Task) => {
      const documents = task.documents as DocumentId[];
      for (const doc of documents.concat(task.comments_documents || [])) {
        _addToCollection(tempTaskDoc, task.id, doc.document_id);
        _addToCollection(tempDocTask, doc.document_id, task.id);
      }

      const folders = task.folders as FolderId[];
      for (const folder of folders.concat(task.comments_folders || [])) {
        _addToCollection(tempTaskFolder, task.id, folder.folder_id);
        _addToCollection(tempFolderTask, folder.folder_id, task.id);
      }
    });

    // Converts Set of ids to objects of { [relatedKey]: relatedId }.
    function fillCollection(
      collection: any,
      tempCollection: any,
      relatedKey: any,
    ) {
      const keys = Object.keys(tempCollection);
      for (const itemId of keys) {
        const values: any[] = [];
        tempCollection[itemId].forEach((relatedId: any) => {
          values.push({ [relatedKey]: relatedId });
        });
        collection[itemId] = values;
      }
    }

    const documentTasks = {};
    const folderTasks = {};
    const taskDocs = {};
    const taskFolders = {};

    fillCollection(documentTasks, tempDocTask, "task_id");
    fillCollection(folderTasks, tempFolderTask, "task_id");
    fillCollection(taskDocs, tempTaskDoc, "document_id");
    fillCollection(taskFolders, tempTaskFolder, "folder_id");

    commit("setState", { documentTasks, folderTasks, taskDocs, taskFolders });
    commit("setIsReady", true);
  },
};
