import { roomMembersActions } from "./RoomMembersActions";
import { roomMembersGetters } from "./RoomMembersGetters";
import { roomMembersMutations } from "./RoomMembersMutations";
import { RoomMembersState } from "./RoomMembersState";

import type { RootState } from "@drVue/store/state";
import type { Module } from "vuex";

export { RoomMembersState } from "./RoomMembersState";

export const RoomMembersModule: Module<RoomMembersState, RootState> = {
  actions: roomMembersActions,
  mutations: roomMembersMutations,
  getters: roomMembersGetters,
  namespaced: true,
  state: new RoomMembersState(),
};
