<template>
  <div
    data-testid="attachment-list-item"
    :class="[
      $style.container,
      {
        [$style.active]: !!uploadStatus && uploadStatus !== 'ready',
        [$style.success]: uploadStatus === 'success',
        [$style.uploading]: uploadStatus === 'uploading',
      },
    ]"
  >
    <i class="mimetypes" :class="[$style.fileIcon, getIconClass(mimetype)]" />

    <DrTruncatedTextTooltip :content="name">
      <span :class="$style.fileName">{{ name }}</span>
    </DrTruncatedTextTooltip>

    <div v-if="!props.uploadStatus" :class="$style.actions">
      <i
        :class="$style.actionItem"
        class="fas fa-download"
        data-testid="attachment-download-icon"
        @click="onDownload"
      />
      <i
        v-if="canRemove"
        :class="$style.actionItem"
        class="fas fa-trash"
        data-testid="attachment-remove-icon"
        @click="onRemove"
      />
    </div>
    <CancelIcon
      v-else-if="props.uploadStatus === 'uploading'"
      :class="$style.cancelIcon"
      @click="onCancel"
    />

    <div v-if="statusDescription" :class="$style.status">
      <SpinnerIcon :status="uploadStatus!" />
      <span
        :class="[
          $style.description,
          {
            [$style.success]: uploadStatus === 'success',
            [$style.uploading]: uploadStatus === 'uploading',
          },
        ]"
      >
        {{ statusDescription }}
      </span>
    </div>
    <div v-else :class="$style.date">
      {{ dateString }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { format } from "date-fns";
import { computed } from "vue";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import getIconClass from "@app/common/mimetype";
import CancelIcon from "./svg/CancelIcon.vue";
import SpinnerIcon from "./svg/SpinnerIcon.vue";

import type { UploadStatus } from "element-plus";

interface Props {
  id: string;
  name: string;
  mimetype: string;
  canRemove: boolean;
  uploadStatus?: UploadStatus;
  date: Date;
}

const props = withDefaults(defineProps<Props>(), {
  uploadStatus: undefined,
});

const emit = defineEmits<{
  (e: "download", id: string, name: string): void;
  (e: "remove", id: string): void;
  (e: "cancel", id: string): void;
}>();

const DATE_FORMAT = "yyyy-MM-dd";

const statusDescription = computed(() => {
  switch (props.uploadStatus) {
    case "uploading":
      return "Uploading...";
    case "success":
      return "Uploaded successfully";
    default:
      return null;
  }
});

const dateString = computed(() => format(props.date, DATE_FORMAT));

const onDownload = () => {
  emit("download", props.id, props.name);
};

const onRemove = () => {
  emit("remove", props.id);
};

const onCancel = () => {
  emit("cancel", props.id);
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.container {
  display: grid;
  grid-template-columns: 20px 1fr min-content;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 4px;
  padding: 6px 8px 4px;
  border-radius: 6px;
  transition: background-color 150ms ease-out;

  &:hover {
    background: colors.$pr-50;
    cursor: pointer;
  }

  &:last-child {
    margin-bottom: unset;
  }
}

.actions {
  display: grid;
  align-items: center;
  justify-content: center;
  height: 16px;
  line-height: 16px;
  margin-right: -16px;
  opacity: 0;
  transition-duration: 150ms;
  transition-timing-function: ease-out;
  transition-property: margin-right, opacity;
  will-change: margin-right, opacity;

  .container:hover & {
    opacity: 1;
    margin-right: 0;
  }
}

.actionItem {
  grid-column: 1/1;
  grid-row: 1/1;
  width: 16px;
  z-index: -1;
  color: colors.$pr-400;
  transition: margin-left 150ms ease-out;
  cursor: pointer;
  will-change: margin-left;

  .container:hover & {
    grid-column: auto;
    margin-left: 8px;
    z-index: 1;
  }

  &:hover {
    color: colors.$pr-800;
  }
}

.active {
  background: colors.$pr-50;
}

.uploading {
  background: colors.$sc-100;
  color: colors.$sc-600;

  &:hover {
    background: colors.$sc-100;
  }
}

.success {
  background: #f4fcf3;

  .status {
    color: colors.$ad-3;
  }

  &:hover {
    background: #f4fcf3;
  }
}

.fileName {
  font-size: typography.$font-size-body;
  color: colors.$pr-900;

  &:hover {
    text-decoration: underline;
  }
}

.fileIcon {
  width: 14px;
  height: 18px;
  place-self: center;
}

.cancelIcon:hover {
  color: colors.$pr-800;
}

.status {
  grid-column-start: 2;
  display: grid;
  grid-template-columns: 10px auto;
  align-items: center;
  align-self: self-start;
  gap: 8px;
}

.date {
  grid-column-start: 2;
  font: typography.$helper_regular;
  color: colors.$pr-500;
}
</style>
