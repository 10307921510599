<template>
  <div>
    <div v-if="editable" class="notes-list__container">
      <div class="notes-list__content notes-list__content--new-note">
        <EditForm
          :note="newNoteData"
          :notes-store-prefix="notesStorePrefix"
          :object-id="objectId"
        />
      </div>
    </div>
    <DrLoader v-if="isLoading" />
    <div v-if="!isLoading">
      <NoteRow
        v-for="note in notes"
        :key="note.id"
        :note="note"
        :notes-store-prefix="notesStorePrefix"
        :object-id="objectId"
        :editable="editable"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrLoader from "@shared/ui/dr-loader";

import DrStore from "@drVue/store";
import { NoteObjectType } from "@drVue/store/modules/client-dashboard/notes/types";
import EditForm from "./EditForm";
import NoteRow from "./NoteRow";

import type { Note } from "@drVue/store/modules/client-dashboard/notes/types";
import type { PropType } from "vue";

interface Data {
  newNoteData: Note;
  isLoading: boolean;
}

export default defineComponent({
  name: "NotesList",
  components: {
    DrLoader,
    EditForm,
    NoteRow,
  },
  props: {
    objectType: { required: true, type: String as PropType<NoteObjectType> },
    objectId: {
      required: true,
      type: [Number, String] as PropType<number | string>,
    },
    searchQuery: { required: true, type: String as PropType<string> },
    editable: { type: Boolean as PropType<boolean>, default: false },
  },
  data(): Data {
    return {
      newNoteData: {
        body: null,
      } as Note,
      isLoading: true,
    };
  },
  computed: {
    notesStorePrefix(): string {
      const type =
        this.objectType === NoteObjectType.OrgUser
          ? "orgUser"
          : this.objectType;
      return `clientDashboard/${type}s/notes`;
    },
    notes(): Note[] {
      const query = (this.searchQuery || "").toLowerCase().trim();
      const notes: Note[] = DrStore.getters[
        `${this.notesStorePrefix}/byObjectId`
      ](this.objectId);
      if (!this.searchQuery) {
        return notes;
      }
      return notes.filter((note) => {
        const sender = DrStore.getters["clientDashboard/orgUsers/byLegacyId"](
          note.sender_id,
        );

        return (
          (sender && sender.name.toLowerCase().includes(query)) ||
          note.body_text.toLowerCase().includes(query)
        );
      });
    },
  },
  mounted() {
    DrStore.dispatch(`${this.notesStorePrefix}/loadNotes`, this.objectId).then(
      () => {
        this.isLoading = false;
      },
    );
  },
});
</script>
