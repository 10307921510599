<template>
  <DrTooltip
    placement="top"
    :disabled="disableTooltip"
    :content="tooltip"
    :show-after="300"
  >
    <div :class="$style.container">
      <DrAvatarStack :avatars="avatars">
        <div :class="$style.icon">
          <slot />
        </div>
      </DrAvatarStack>
      <span v-if="!disableLabel && label" :class="$style.label">
        {{ label }}
      </span>
    </div>
  </DrTooltip>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DrAvatarStack } from "@shared/ui/dr-avatar";
import { DrTooltip } from "@shared/ui/dr-tooltip";

import type { User } from "./types";

interface Props {
  users: User[];
  disableLabel?: boolean;
  disableTooltip?: boolean;
}

const props = defineProps<Props>();

const avatars = computed(() =>
  props.users.map((u) => ({ name: u.name, url: u.avatar.thumbnail })),
);

const label = computed(() => {
  if (props.users.length === 1) {
    const user = props.users.find(
      (user) => user.first_name.length || user.name.length,
    );
    if (user) {
      return user.first_name.length
        ? `${user.first_name[0]}. ${user.last_name}`
        : user.name;
    }
  }

  return null;
});

const tooltip = computed(() => props.users.map(({ name }) => name).join(", "));
</script>

<style module lang="scss">
.container {
  width: 100%;
  display: inline-flex;
  align-items: center;
}

.label {
  margin-left: 5px;
}

.icon {
  margin: 0 -10px -10px 0;
}
</style>
