<template>
  <DrTooltip
    v-if="!comment.is_public"
    :content="viewersText"
    class="task-comment-viewers"
  >
    <ElTag
      :class="$style.tag"
      size="small"
      :type="isMyGroupComment ? 'warning' : 'info'"
      effect="plain"
    >
      <i class="fas fa-lock task-comment-viewers__icon" aria-hidden="true" />
      {{ viewersText }}
    </ElTag>
  </DrTooltip>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DrTooltip } from "@shared/ui/dr-tooltip";

import { ROOM_DATA } from "@setups/data";

import type { Group } from "@drVue/store/modules/room/groups/GroupsApiService";
import type { TaskComment } from "@drVue/store/modules/room/types";
import type { Dictionary } from "@drVue/types";
import type { PropType } from "vue";

export const MY_GROUP_LABEL = "My group";
export const MY_GROUP_ONLY_LABEL = `${MY_GROUP_LABEL} only`;

interface Data {
  UNKNOWN_GROUP_NAME: any;
}

export default defineComponent({
  name: "CommentViewersTag",
  components: { DrTooltip },
  props: {
    comment: { required: true, type: Object as PropType<TaskComment> },
    groups: {
      required: true,
      type: Object as PropType<Dictionary<Group>>,
    },
  },
  data(): Data {
    return {
      UNKNOWN_GROUP_NAME: "Anonymous",
    };
  },
  computed: {
    isMyGroupComment(): any {
      return (
        !this.comment.is_public &&
        this.comment.viewers.length === 1 &&
        this.comment.viewers[0] === ROOM_DATA.userPermissions.id
      );
    },
    viewersText(): any {
      if (this.isMyGroupComment) {
        return MY_GROUP_ONLY_LABEL;
      }
      const fromMyGroup =
        this.comment.group_id === ROOM_DATA.userPermissions.id;

      const viewers = this.comment.viewers
        .filter((groupId) => {
          return groupId !== this.comment.group_id;
        })
        .map((groupId) => this.getGroupName(groupId));
      viewers.sort();

      let senderLabel = "";
      if (!fromMyGroup) {
        senderLabel = "from " + this.getGroupName(this.comment.group_id) + " ";
      }
      return senderLabel + "to " + viewers.join(", ");
    },
  },
  methods: {
    getGroupName(groupId: number) {
      if (groupId === ROOM_DATA.userPermissions.id) {
        return MY_GROUP_LABEL;
      }
      const group = this.groups[groupId];
      return group ? group.name : this.UNKNOWN_GROUP_NAME;
    },
  },
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.tag {
  border: unset !important;
  :global {
    --el-color-info: #{colors.$pr-500};
  }
}
</style>
