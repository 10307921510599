import { h } from "vue";

import { formatBytes } from "@app/common/formatters";
import BaseMgmtTableColumns from "@drVue/components/management/baseTable/baseMgmtTableColumns";
import MgmtLinkTableCell from "../baseTable/MgmtLinkTableCell.vue";

import type { BasicRoomRow } from "./types";
import type { DrVxeTableColumn } from "@drVue/components/types";

export default class TableColumns extends BaseMgmtTableColumns<BasicRoomRow> {
  getBaseColumns() {
    return [
      this.title(),
      this.simpleTextColumn("Domain", "domain"),
      this.simpleTextColumn("Slug", "slug"),
      this.simpleTextColumn("Payment", "payment_type"),
    ];
  }

  getExtraColumn(column: string): DrVxeTableColumn<BasicRoomRow> {
    switch (column) {
      case "owner":
        return this.simpleTextColumn("Owner", "owner_email");

      case "storage.active":
        return this.simpleTextColumn("Storage Active", "storage.active", {
          formatter: ({ cellValue }: { cellValue: number }) =>
            formatBytes(cellValue, 2),
        });
      case "storage.archived":
        return this.simpleTextColumn("Storage Archived", "storage.archived", {
          formatter: ({ cellValue }: { cellValue: number }) =>
            formatBytes(cellValue, 2),
        });
      default:
        return this.simpleTextColumn(`Unknown ${column}`, "");
    }
  }

  private title(): DrVxeTableColumn<BasicRoomRow> {
    return {
      sortable: true,
      field: "title",
      title: "Title",
      minWidth: 170,
      slots: {
        default: ({ row }) => {
          const prefix = row.is_removed
            ? "Deleted: "
            : row.is_archived
              ? "Archived: "
              : "";
          const label = `${prefix} ${row.title}`;
          const link = `#/rooms/${row.id}`;
          return [h(MgmtLinkTableCell, { label, link })];
        },
      },
    };
  }
}
