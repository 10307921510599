<template>
  <div
    :class="{
      [$style.logo]: true,
      [$style.xs]: size === SIZES.xs,
      [$style.sm]: size === SIZES.sm,
      [$style.lg]: size === SIZES.lg,
      [$style.xl]: size === SIZES.xl,
      [$style.xxl]: size === SIZES.xxl,
    }"
    :style="backgroundStyle"
  >
    <img
      v-if="!url"
      :class="$style.placeholder"
      :src="placeholderSvgUrl"
      alt="Logo"
    />
  </div>
</template>

<script setup lang="ts">
/**
 * The component for displaying the logo of some company, room, etc.
 * in a unified form - a rounded square.
 * The image by `url` is displayed in priority, if it exists.
 * Otherwise, the placeholder icon is displayed.
 * The name is used to select the background color of the placeholder.
 */

import { computed } from "vue";

import { SIZES } from "../constants";
import { getColorByString } from "../utils";
import placeholderSvgUrl from "./placeholder.svg";

interface Props {
  /**
   * Logo image file's url.
   */
  url?: string;
  /**
   * Used to set the background color.
   */
  name?: string;
  /**
   * Avatar's size
   */
  size?: SIZES;
}
const props = withDefaults(defineProps<Props>(), {
  url: "",
  name: "",
  size: SIZES.md,
});

const backgroundStyle = computed(() => {
  if (props.url) {
    return {
      backgroundColor: "transparent",
      backgroundImage: `url(${props.url})`,
    };
  }

  if (props.name) {
    return {
      backgroundColor: getColorByString(props.name),
    };
  }

  return {};
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

$xs: 14px;
$sm: 18px;
$md: 24px;
$lg: 30px;
$xl: 38px;
$xxl: 48px;
$xs_radius: 4px;
$sm_radius: 5px;
$md_radius: 6px;
$lg_radius: 6px;
$xl_radius: 8px;
$xxl_radius: 8px;
$xs_icon: 8px;
$sm_icon: 10px;
$md_icon: 14px;
$lg_icon: 18px;
$xl_icon: 22px;
$xxl_icon: 28px;

.logo {
  --size: #{$md};
  --border-radius: #{$md_radius};
  --size-icon: #{$md_icon};

  position: relative;
  display: inline-grid;
  align-items: center;
  justify-content: center;
  height: var(--size);
  width: var(--size);
  border-radius: var(--border-radius);
  overflow: hidden;
  background-color: colors.$pr-400;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.placeholder {
  height: var(--size-icon);
  width: var(--size-icon);
}

.xs {
  --size: #{$xs};
  --border-radius: #{$xs_radius};
  --size-icon: #{$xs_icon};
}
.sm {
  --size: #{$sm};
  --border-radius: #{$sm_radius};
  --size-icon: #{$sm_icon};
}
.lg {
  --size: #{$lg};
  --border-radius: #{$lg_radius};
  --size-icon: #{$lg_icon};
}
.xl {
  --size: #{$xl};
  --border-radius: #{$xl_radius};
  --size-icon: #{$xl_icon};
}
.xxl {
  --size: #{$xxl};
  --border-radius: #{$xxl_radius};
  --size-icon: #{$xxl_icon};
}
</style>
