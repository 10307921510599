import type { Dictionary } from "@drVue/types";

export function documentViewUrl(
  roomUrl: string,
  documentId: number,
  documentVersion?: number,
  documentPage?: number,
) {
  const baseUrl = `${roomUrl}/documents/${documentId}/view/`;
  const extraParams: Dictionary<string> = {};
  if (documentVersion) {
    extraParams["version"] = documentVersion.toString();
  }
  if (documentPage) {
    extraParams["page"] = documentPage.toString();
  }
  const searchParams = new URLSearchParams(extraParams);
  return (
    baseUrl + (searchParams.toString() ? `?${searchParams.toString()}` : "")
  );
}

export function folderUrl(roomUrl: string, folderId: number) {
  return `${roomUrl}/#/documents/folder/${folderId}`;
}

export function taskUrl(roomUrl: string, taskKey: string) {
  return `${roomUrl}/#/tasks/${taskKey}`;
}

export function categoryUrl(roomUrl: string, categoryId: number) {
  return `${roomUrl}/#/tasks/list/${categoryId}`;
}

export function memberUrl(roomUrl: string, userId: number) {
  return `${roomUrl}/#/members/${userId}`;
}

export function groupUrl(roomUrl: string, groupId: number) {
  return `${roomUrl}/#/members/list?pgroupId=${groupId}`;
}

export function tasksArchiveUrl(roomUrl: string) {
  return `${roomUrl}/#/tasks/archived/overview`;
}

export function findingDetailsUrl(roomUrl: string, findingKey: string) {
  return `${roomUrl}/#/findings/details/${findingKey}`;
}

export function findingArchiveDetailsUrl(roomUrl: string, findingKey: string) {
  return `${roomUrl}/#/findings/archive/${findingKey}`;
}

export function findingsUrl(roomUrl: string, listId?: string) {
  return `${roomUrl}/#/findings${listId ? `/list/${listId}` : ""}`;
}

export function findingsArchiveUrl(roomUrl: string) {
  return `${roomUrl}/#/findings/archive`;
}
