import { computed, ref } from "vue";

import { $notifyDanger } from "@drVue/common";

import type { Dictionary } from "@drVue/types";
import type { AxiosError } from "axios";
import type { ComputedRef, Ref } from "vue";

export type ServerErrors = {
  [key: string]: string[] | Dictionary<string[]>;
  non_field_errors: string[];
  custom_data: Dictionary<string[]>;
};

export type FlatServerErrors = {
  [key: string]: string | Dictionary<string>;
  non_field_errors: string;
  custom_data: Dictionary<string>;
};

export type FormErrors<T> = {
  [P in keyof T | "non_field_errors" | "custom_data"]?: string;
};

const validationErrorCodes = [400];

export function useFormHelper<TFormModel>() {
  const isFormSubmitting: Ref<boolean> = ref(false);
  const serverErrors: Ref<ServerErrors | null> = ref(null);

  const formErrors: ComputedRef<FormErrors<TFormModel>> = computed(() => {
    if (serverErrors.value === null) return {};

    const sE = serverErrors.value;
    const flattedErrors = Object.keys(sE).reduce(
      (flatErrors, key: keyof ServerErrors) => {
        if (key === "custom_data") {
          const customData = sE[key];
          flatErrors.custom_data = Object.keys(customData).reduce(
            (customErrors, customKey) => {
              customErrors[customKey] = customData[customKey].join("\n");
              return customErrors;
            },
            Object.create(null) as Dictionary<string>,
          );
        } else {
          flatErrors[key] = (sE[key] as string[]).join("\n");
        }

        return flatErrors;
      },
      Object.create(null) as FlatServerErrors,
    );

    return flattedErrors as FormErrors<TFormModel>;
  });

  const resetErrors = () => (serverErrors.value = null);
  const resetError = (key: string) => {
    if (serverErrors.value) delete serverErrors.value[key];
  };

  return {
    isFormSubmitting,
    formErrors,
    resetErrors,
    resetError,
    hookFormSubmitPromise: <T>(
      promise: Promise<T>,
      errorMessage: string = "Failed to submit changes.",
    ): Promise<T> => {
      isFormSubmitting.value = true;

      serverErrors.value = null;
      return promise
        .catch((e: AxiosError<ServerErrors>) => {
          if (e.response && validationErrorCodes.includes(e.response.status)) {
            serverErrors.value = e.response.data;
          } else {
            $notifyDanger(errorMessage);
          }

          return Promise.reject(e);
        })
        .finally(() => {
          setTimeout(() => {
            isFormSubmitting.value = false;
          }, 500);
        });
    },
  };
}
