<template>
  <div
    :class="{
      [$style.grid]: true,
      [$style.grid_hasAction]: slots.action,
    }"
  >
    <div :class="$style.title">{{ title }}</div>
    <div :class="$style.action" v-if="slots.action">
      <slot name="action" />
    </div>
    <div :class="$style.content">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSlots } from "vue";

defineProps<{
  title: string;
}>();

const slots = useSlots();
</script>

<style module lang="scss">
@use "@app/styles/scss/_colors";
@use "@app/styles/scss/typography" as typo;

.grid {
  display: grid;
  grid-template: max-content max-content / auto;
  grid-template-areas: "title" "content";
  border-bottom: 1px solid colors.$pr-150;
  padding-bottom: 24px;
}

.grid_hasAction {
  grid-template: max-content max-content / 1fr max-content;
  grid-template-areas: "title action" "content content";
}

.title {
  grid-area: title;
  padding: 16px 0 8px 20px;
  font: typo.$title_semibold;
}

.action {
  grid-area: action;
  padding: 20px 20px 0 0;
}

.content {
  grid-area: content;
  padding: 0 20px;
}
</style>
