<template>
  <div
    :class="{
      [$style.layout]: true,
      [$style.layout_hasToolbar]:
        !!$slots['toolbar'] || !!$slots['toolbar-right'],
      [$style.layout_hasPanel]: !!$slots['panel'],
    }"
  >
    <div :class="$style.nav">
      <slot name="nav" />
    </div>

    <div v-if="!!$slots['panel']" :class="$style.panel">
      <slot name="panel" />
    </div>

    <div v-if="!!$slots['toolbar-over']" :class="$style.toolbar">
      <slot name="toolbar-over" />
    </div>

    <div
      v-else-if="!!$slots['toolbar'] || !!$slots['toolbar-right']"
      :class="$style.toolbar"
      data-testid="board-filter-toolbar"
    >
      <div :class="$style.toolbarSection">
        <slot name="toolbar" />
      </div>
      <div :class="$style.toolbarSection">
        <slot name="toolbar-right" />
      </div>
    </div>

    <div :class="{ [$style.body]: true, [$style.body_shownAside]: showAside }">
      <div
        :class="{
          [$style.content]: true,
          [$style.content_isIndented]: indented,
        }"
      >
        <slot name="default" />
      </div>
      <div :class="$style.aside">
        <slot v-if="allowMountAside" name="aside" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
  showAside?: boolean;
  destroyAsideOnClose?: boolean;
  indented?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  showAside: false,
  destroyAsideOnClose: false,
  indented: false,
});

/**
 * The ``destroyAsideOnClose`` property allows us to control the mounting and destructing of the component inside the `aside` slot.
 * Which is extremely useful if we need:
 * - to load the data only on the first display,
 * - or if we need to update it every time the user accesses this block.
 *
 * This means that the component (inside aside slot) uses the corresponding call within onMounted.
 */
const allowMountAside = computed(
  () => !props.destroyAsideOnClose || props.showAside,
);
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;

$toolbar-height: 32px;
$aside-width: 320px;
$panel-width: 264px;
$animate-duration: 300ms;
$animate-function: ease-out;

$toolbar-space: 8px;
$content-radius: 7px;

.layout {
  display: grid;
  grid-template-areas:
    "nav"
    "body";
  grid-template-rows: min-content 1fr;
  row-gap: spacing.$m;
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
}

.layout_hasToolbar {
  grid-template-areas:
    "nav"
    "toolbar"
    "body";
  grid-template-rows: min-content min-content 1fr;
}

.layout_hasPanel {
  grid-template-areas:
    "nav nav"
    "panel body";
  grid-template-columns: $panel-width 1fr;
}

.layout_hasPanel.layout_hasToolbar {
  grid-template-areas:
    "nav nav"
    "panel toolbar"
    "panel body";
}

.layout_hasPanel.layout_hasToolbar {
  grid-template-areas:
    "nav nav"
    "panel toolbar"
    "panel body";
}

.nav {
  grid-area: nav;
}

.panel {
  grid-area: panel;
  border-right: solid 1px colors.$pr-150;
  display: flex;
  flex-direction: column;
  margin-top: -(spacing.$m);
  position: relative;
}

.toolbar {
  grid-area: toolbar;
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  gap: spacing.$xs;
  min-height: calc($toolbar-height + spacing.$m);
  margin: -(spacing.$xs) 0;
  padding: 0 spacing.$m;
}

.toolbarSection {
  position: relative;
  height: 100%;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: $toolbar-space;
  justify-content: start;
  overflow-x: hidden;
  white-space: nowrap;
  // to display the outline correctly when the buttons have focus
  margin-left: -8px;
  padding-left: 8px;

  :global {
    .el-button + .el-button {
      margin-left: 0;
    }
  }

  & + & {
    padding-left: spacing.$xs;
    // to display the outline correctly when the buttons have focus
    margin-right: -8px;
    padding-right: 8px;
  }
}

.body {
  grid-area: body;
  position: relative;
  padding: 0 spacing.$m spacing.$m;
  overflow: hidden;
}

.content {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 0;
  overflow: auto;
  transition-timing-function: $animate-function;
  transition-duration: $animate-duration;
  will-change: width;
  border: 1px solid colors.$pr-150;
  border-radius: $content-radius;
}

.content_isIndented {
  padding: 20px 20px 10px 20px;
}

.aside {
  position: absolute;
  top: 0;
  right: 0;
  bottom: spacing.$m;
  z-index: 1;
  width: $aside-width;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  border: 1px solid colors.$pr-150;
  border-right: none;
  border-radius: $content-radius 0 0 $content-radius;
  transition-property: transform, opacity;
  transition-timing-function: $animate-function;
  transition-duration: $animate-duration;
  transform: translateX(100%);
  will-change: transform;
}

.body_shownAside {
  .content {
    width: calc(100% - $aside-width);
  }

  .aside {
    transform: translateX(0);
  }
}
</style>
