import axios from "axios";

import { $notifyDanger, $notifySuccess } from "@drVue/common";
import { RoomMembersApiService } from "./RoomMembersApiService";

import type { AddMembersData, RoomMember } from "./RoomMembersApiService";
import type { RoomMembersState } from "./RoomMembersState";
import type { SetStatePayload } from "@drVue/store/modules/room/members/RoomMembersMutations";
import type { RootState } from "@drVue/store/state";
import type { AxiosErrorJSON } from "@drVue/types";
import type { ActionContext, ActionTree } from "vuex";

export type Context = ActionContext<RoomMembersState, RootState>;

const api = new RoomMembersApiService();

export interface RoomMembersActions
  extends ActionTree<RoomMembersState, RootState> {
  addMembers(
    context: Context,
    payload: {
      pgroupId: number;
      data: AddMembersData;
    },
  ): Promise<any>;
  addMembersCheck(
    context: Context,
    payload: {
      pgroupId: number;
      data: AddMembersData;
    },
  ): Promise<any>;
  removeMembers(context: Context, members: RoomMember[]): Promise<any>;
  resendInvite(context: Context, payload: { invite: any }): Promise<any>;
  load(context: Context, skipErrorAlert: boolean): Promise<any>;
}

export const roomMembersActions: RoomMembersActions = {
  async addMembers({ state, commit, dispatch }, payload) {
    return await api.addMembers(payload.pgroupId, payload.data);
  },

  async addMembersCheck({ state, commit, dispatch }, payload) {
    return await api.addMembersCheck(payload.pgroupId, payload.data);
  },

  async removeMembers({ state, commit, dispatch }, members) {
    // TODO?: Create removeMembers backend method.
    const promises = members.map((m) =>
      api.removeMember(m.pgroup.id, m.email).then(
        () => {
          const message = m.pending
            ? `Invite for ${m.email} was canceled.`
            : `Member ${m.email} was removed.`;

          $notifySuccess(message);
        },
        () => $notifyDanger(`Failed to remove member ${m.email}.`),
      ),
    );

    await Promise.allSettled(promises);
    await dispatch("load");
  },

  async resendInvite({ state, commit, dispatch }, { invite }) {
    try {
      commit("setInviteResending", { id: invite.id, value: true });

      const r: any = await api.resendInvite(invite.pgroup.id, invite.id);

      invite.id = r.id;
      invite.invite_date = r.invite_date;

      $notifySuccess(`Invitation for ${invite.email} has been resent`);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const json = e.toJSON() as AxiosErrorJSON;
        if (json.status === 404) {
          await dispatch("load");
        }
      }

      $notifyDanger(`Failed to resent an invitation for ${invite.email}`);
    } finally {
      commit("setInviteResending", { id: invite.id, value: false });
    }
  },

  load({ state, rootState, commit }, skipErrorAlert) {
    if (state.isLoading && state.updatePromise !== null) {
      return state.updatePromise;
    }

    commit("setIsError", false);
    commit("setIsLoading", true);

    const promise = api.getMembers().then(
      (allMembers) => {
        // JFYI: Content of "member" and "invites" dictionaries are not equal to the list's content.
        const members: { [k: string]: RoomMember } = {};
        const membersByUid: { [k: string]: RoomMember } = {};
        const invites: { [k: string]: RoomMember } = {};
        allMembers.forEach(function (member) {
          if (member.pending) {
            invites[member.id] = member;
          } else {
            members[member.id] = member;
            membersByUid[member.uid] = member;
          }
        });

        const activeMembersList: RoomMember[] = [];
        const membersList: RoomMember[] = [];
        const invitesList: RoomMember[] = [];
        allMembers.forEach(function (member) {
          if (
            !member.is_archived &&
            // if user isn't admin all viewable members have group == none
            (!member.pgroup || (member.pgroup && !member.pgroup.is_archived))
          ) {
            activeMembersList.push(member);

            if (member.pending) {
              invitesList.push(member);
            } else {
              membersList.push(member);
            }
          }
        });

        commit("setState", {
          members,
          membersByUid,
          membersList,
          invites,
          invitesList,
          activeMembersList,
        } as SetStatePayload);
        commit("setIsLoading", false);

        return allMembers;
      },
      (error) => {
        if (!skipErrorAlert) {
          $notifyDanger("Failed to load members list.");
        }

        commit("setIsError", true);
        commit("setIsLoading", false);

        return Promise.reject(error);
      },
    );

    commit("setPromise", promise);

    return promise;
  },
};
