<template>
  <DrLayoutContentCenteredSection title="Requests table default view">
    <template #description>
      Adjust a table view that will be visible by default for all members of the
      room. This doesn't affect data access or permissions – users can still
      adjust their personal "Requests" view if needed.
    </template>

    <ElButton plain type="primary" @click="openModal">
      Adjust default view
    </ElButton>
    <TableAdjustDefaultViewDrawer v-if="isVisible" @close="closeModal" />
  </DrLayoutContentCenteredSection>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { DrLayoutContentCenteredSection } from "@shared/ui/dr-layouts";

import TableAdjustDefaultViewDrawer from "./TableAdjustDefaultViewDrawer.vue";

const isVisible = ref(false);

const openModal = () => {
  isVisible.value = true;
};

const closeModal = () => {
  isVisible.value = false;
};
</script>

<style module lang="scss">
.content {
  min-height: 225px;
}
</style>
