<template>
  <DrItemList :items="taskCategoryGroups" :class="$style.container">
    <template #header>
      <TasksTreeBrowser
        :model-value="dependencyUids"
        :filter-method="filterMethod"
        only-task-value
        @update:model-value="(tasks: Task['uid'][]) => $emit('change', tasks)"
      >
        <template #reference="{ show }">
          <DrItemListHeader
            :title="title"
            :light-weight="!taskIds.length"
            :allow-action="allowChange"
            @action="show"
          />
        </template>
      </TasksTreeBrowser>
    </template>
    <template #item="{ item }">
      <TaskItem
        :item="item"
        :disallow-remove="!allowRemove"
        @click="(task) => $emit('click', task)"
        @remove="(task) => $emit('remove', task)"
      />
    </template>
  </DrItemList>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DrItemList, DrItemListHeader } from "@shared/ui/dr-item-list";

import { TasksTreeBrowser } from "@app/vue/components/room/tasks/shared/tasks-tree-browser";
import { groupBy } from "@drVue/common";
import { pinia } from "@drVue/store/pinia";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";
import { useCategoriesArchivedStore } from "@drVue/store/pinia/room/categoriesArchived";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import { useTasksArchivedStore } from "@drVue/store/pinia/room/tasksArchived/tasksArchived";
import TaskItem from "./TaskItem.vue";

import type { TaskDependency } from "@drVue/components/room/tasks/TaskDetails/lists/types";
import type { Task } from "@drVue/store/pinia/room/tasks";

interface Props {
  title: string;
  taskIds: Task["id"][];
  allowChange?: boolean;
  allowRemove?: boolean;
  filterMethod?: (task: Task) => boolean;
}

interface Emits {
  (e: "click", task: Task): void;
  (e: "change", task: Task["uid"][]): void;
  (e: "remove", task: Task): void;
}

const props = withDefaults(defineProps<Props>(), {
  allowChange: false,
  allowRemove: false,
  filterMethod: undefined,
});

defineEmits<Emits>();

const tasksStore = useTasksStore(pinia);
const tasksArchivedStore = useTasksArchivedStore(pinia);
const categoriesStore = useCategoriesStore(pinia);
const categoriesArchivedStore = useCategoriesArchivedStore(pinia);

const tasks = computed(() => {
  if (tasksStore.isLoading || tasksArchivedStore.isLoading) return [];

  return props.taskIds.reduce<Task[]>((acc, id) => {
    const task = tasksStore.tasks[id] ?? tasksArchivedStore.tasks[id];
    if (task) acc.push(task);
    return acc;
  }, []);
});

const dependencyUids = computed(() => tasks.value.map(({ uid }) => uid));

const taskCategoryGroups = computed<TaskDependency[]>(() => {
  const map = groupBy(tasks.value, "category_id");

  return Array.from(map.entries()).map(([categoryId, tasks]) => ({
    id: categoryId,
    category:
      categoriesStore.categories[categoryId] ??
      categoriesArchivedStore.categories[categoryId],
    tasks,
  }));
});
</script>

<style lang="scss" module>
.container {
  gap: 12px;
}
</style>
