<template>
  <a
    :class="[$style.button, active ? $style.button_isActive : '']"
    :href="tab.url"
    @click="onClick"
  >
    <span :class="$style.label">{{ tab.label }}</span>
  </a>
</template>

<script setup lang="ts">
import type { RoomNavTab } from "./utils";

const props = defineProps<{
  tab: RoomNavTab;
  active: boolean;
}>();

const onClick = function (event: Event) {
  if (props.active) {
    event.stopPropagation();
    event.preventDefault();
  }
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/_colors";
@use "@app/styles/scss/typography" as typo;
.button {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 6px;
  height: 100%;
  max-width: 168px;
  padding: 0 2px;
  border: none;
  border-radius: 0;
  font: typo.$body_regular;
  background-color: transparent;
  color: colors.$pr-500;
  border-bottom: solid 2px transparent;
  transition: all ease-out 200ms;

  &:focus,
  &:hover {
    color: colors.$pr-900;
    text-decoration: none;
  }
}

.button_isActive {
  font: typo.$body_semibold;

  &,
  &:focus,
  &:hover {
    color: colors.$pr-900;
    border-bottom-color: colors.$sc-600;
  }
}

.label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
