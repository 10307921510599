import { $notifyDanger } from "@drVue/common";
import PreferencesApiService from "./api";

import type { RootState, State } from "./types";
import type { ActionContext, ActionTree } from "vuex";

type IContext = ActionContext<State, RootState>;

const api = new PreferencesApiService();

function save(state: State) {
  return api.save(state).catch((error) => {
    $notifyDanger("Failed to save preferences");
    throw error;
  });
}

export interface PreferencesActionTree extends ActionTree<State, RootState> {
  setDefaultDealsView(context: IContext, viewSlug: string): Promise<void>;
  setPinnedDealsView(context: IContext, views: string[]): Promise<void>;
}

export const actions: PreferencesActionTree = {
  async setDefaultDealsView({ state, commit }, viewSlug) {
    commit("setDefaultDealsView", viewSlug);
    await save(state);
  },
  async setPinnedDealsView({ state, commit }, views) {
    commit("setPinnedDealsView", views);
    await save(state);
  },
};
