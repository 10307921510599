<template>
  <ElDropdown
    trigger="click"
    split-button
    :type="type"
    :size="size"
    @click="handleClick"
    @command="handleCommand"
  >
    {{ t("shared.upload") }}
    <template #dropdown>
      <ElDropdownMenu>
        <ElDropdownItem>{{ t("shared.upload_folder") }}</ElDropdownItem>
      </ElDropdownMenu>
    </template>
  </ElDropdown>
</template>

<script setup lang="ts">
import { ElDropdown } from "element-plus";
import { useI18n } from "vue-i18n";

import { openFilesDialog } from "./utils";

const { t } = useI18n();

interface Props {
  size?: "" | "large" | "default" | "small";
  type?: "" | "default" | "primary";
}

interface Emits {
  /**
   * Fires when files are selected.
   * @param {"change"} event Event name.
   * @param {File[]} files Files to upload.
   */
  (event: "selected", files: File[]): void;
}

withDefaults(defineProps<Props>(), {
  size: "default",
});

const emit = defineEmits<Emits>();

const handleClick = async () => {
  openFilesDialog().then(
    (files) => emit("selected", files),
    () => {},
  );
};

const handleCommand = () => {
  openFilesDialog(true).then(
    (files) => emit("selected", files),
    () => {},
  );
};
</script>

<style lang="scss" module></style>
