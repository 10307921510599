<template>
  <ElScrollbar :max-height="maxHeight">
    <div :class="$style.list">
      <DrSorterRow v-for="item in itemsFixed" :key="item.id" v-bind="item" />
    </div>

    <Draggable
      v-model="itemsDraggable"
      item-key="id"
      handle="[drag]"
      :class="$style.list"
    >
      <template #item="{ element: item }">
        <DrSorterRow
          v-bind="item"
          @update-visible="(value) => changeVisible(item, value)"
        />
      </template>
    </Draggable>
  </ElScrollbar>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import Draggable from "vuedraggable";

import DrSorterRow from "./DrSorterRow.vue";

import type { SorterItem } from "./types";

interface Props {
  items: SorterItem[];
  height?: number; // in px
}

const props = withDefaults(defineProps<Props>(), {
  height: 0,
});
const emit = defineEmits<{
  (event: "update-order", changedItems: SorterItem[]): void;
  (event: "update-visible", changedItems: SorterItem[]): void;
}>();

/** Fixed items always on top */
const itemsFixed = computed(() =>
  props.items.filter((item) => item.fixed).sort((a, b) => a.order - b.order),
);

const itemsDraggable = computed({
  get() {
    return props.items
      .filter((col) => !col.fixed)
      .sort((a, b) => a.order - b.order);
  },
  set(items: SorterItem[]) {
    const topItemsCount = itemsFixed.value.length;
    const resortedItems = items.map((item, index) => ({
      ...item,
      order: index + topItemsCount,
    }));

    emit("update-order", resortedItems);
  },
});

const changeVisible = (item: SorterItem, isVisible: boolean) => {
  emit("update-visible", [{ ...item, visible: isVisible }]);
};

const maxHeight = computed(() => {
  if (props.height) {
    return `${props.height}px`;
  }
  return "100%";
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/spacing";

.list {
  display: flex;
  flex-direction: column;
  gap: spacing.$xs;

  & + & {
    margin-top: spacing.$xs;
  }
}
</style>
