<template>
  <div
    :class="{
      [$style.composer]: true,
      [$style.composer_isActivated]: isActivated,
      [$style.composer_isFocused]: isFocused,
    }"
  >
    <DrEditor
      ref="editorRef"
      v-model="commentDraft.body"
      :class="$style.editor"
      :options="{
        showMenuOnFocus: true,
        placeholder: 'Write a comment...',
      }"
      :mentions="mentions"
      @focus="handleFocus"
      @blur="handleBlur"
    />

    <div v-show="isActivated" :class="$style.actions">
      <div v-if="$slots['extra-actions']" :class="$style.actionsExtra">
        <slot name="extra-actions" />
      </div>
      <DrCommentsViewersSelector
        v-if="canManagePermissions"
        :viewers-groups="viewersGroups"
        :viewers="commentDraft.viewers"
        :user-group-id="userGroupId"
        :is-public="commentDraft.is_public"
        @change="handleUpdatePermissions"
      />

      <ElButton v-if="comment" size="small" @click="handleCancelEdit">
        Cancel
      </ElButton>
      <ElButton size="small" type="primary" @click="handleSubmitComment">
        {{ submitBtnText }}
      </ElButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { cloneDeep } from "lodash-es";
import { computed, onMounted, reactive, ref, unref, watch } from "vue";

import DrEditor from "../editor";
import DrCommentsViewersSelector from "./DrCommentsViewersSelector.vue";

import type {
  Comment,
  CommentDraft,
  CommentMentions,
  CommentPermissions,
  CommentUpdate,
  CommentViewersGroup,
} from "./types";

interface Props {
  autofocus?: boolean;
  mentions?: CommentMentions;
  canManagePermissions?: boolean;
  viewersGroups: CommentViewersGroup[];
  defaultViewersGroups?: CommentViewersGroup["id"][];
  userGroupId: CommentViewersGroup["id"];
  comment?: Comment;
}

interface Emits {
  (event: "add", comment: CommentDraft): void;
  (event: "edit", comment: CommentUpdate): void;
  (event: "cancel-edit"): void;
}

const props = withDefaults(defineProps<Props>(), {
  autofocus: false,
  canManagePermissions: false,
  mentions: undefined,
  comment: undefined,
  defaultViewersGroups: () => [],
});
const emit = defineEmits<Emits>();

const isActivated = ref(false);
const isFocused = ref(false);
const editorRef = ref();

const handleFocus = () => {
  isFocused.value = true;
  if (!isActivated.value) {
    isActivated.value = true;
  }
};

const handleBlur = () => {
  isFocused.value = false;
};

const getDefaultFormState = () => {
  const is_public = !props.defaultViewersGroups.length;
  const viewers = props.defaultViewersGroups.slice();

  return {
    body: null,
    viewers,
    is_public,
  };
};

const resetForm = () => {
  const { body, viewers, is_public } = getDefaultFormState();
  commentDraft.body = body;
  commentDraft.viewers = viewers;
  commentDraft.is_public = is_public;
};

const submitBtnText = computed(() =>
  props.comment ? "Update comment" : "Comment",
);

const commentDraft = reactive<CommentDraft>(getDefaultFormState());

watch(
  () => props.comment,
  (comment) => {
    if (comment) {
      commentDraft.is_public = !!comment.is_public;
      commentDraft.body = comment.body;
      commentDraft.viewers = (comment.viewers || []).slice();
    } else {
      resetForm();
    }
  },
  {
    immediate: true,
  },
);

const handleSubmitComment = () => {
  const payload = cloneDeep(commentDraft);
  /** @note user group is always must be in viewers */
  if (!payload.viewers.includes(props.userGroupId)) {
    payload.viewers.push(props.userGroupId);
  }

  if (props.comment) {
    emit("edit", {
      id: props.comment.id,
      ...payload,
    });
  } else {
    emit("add", payload);
  }

  resetForm();
};

const handleCancelEdit = () => {
  emit("cancel-edit");
};

const handleUpdatePermissions = (perms: CommentPermissions) => {
  commentDraft.is_public = perms.is_public;
  commentDraft.viewers = perms.viewers;
};

const focus = () => {
  unref(editorRef)?.focus();
};

onMounted(() => {
  if (props.autofocus) {
    focus();
  }
});

watch(
  () => props.autofocus,
  (value) => {
    if (value) {
      focus();
    }
  },
);

watch(
  () => props.comment,
  (value) => {
    if (value) {
      focus();
    }
  },
);

defineExpose({
  focus,
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";
@use "@app/styles/scss/values";

.composer {
  min-height: values.$base-input-height;
  border: 1px solid colors.$pr-300;
  border-radius: values.$base-border-radius;

  :global {
    .dr-editor__wrapper {
      border: none;
      border-radius: values.$base-border-radius;
    }

    .dr-editor__content > .ProseMirror {
      min-height: 24px;
    }

    .dr-editor__placeholder:first-child::before {
      color: colors.$pr-400;
      font: typography.$body_regular;
    }
  }
}

.composer:not(.composer_isActivated) {
  :global(.dr-editor__wrapper) {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.composer_isFocused {
  border-color: colors.$sc-500;
  box-shadow: 0 0 0 3px rgba(colors.$sc-200-rgb, 0.2);
}

.editor {
  :global(.user-information__icon) {
    display: none;
  }

  :global(.user-information__name::before) {
    content: "@";
    font-size: 14px;
  }
}

.actions {
  padding: 0 spacing.$xs spacing.$xs;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: spacing.$xs;

  :global(.el-button + .el-button) {
    margin-left: 0;
  }
}

.actionsExtra {
  flex: 1;
  min-width: 0;
}
</style>
