angular
  .module("dealroom.analytics.service.data.activity", [
    "dealroom.analytics.service.data.dispatcher",
    "dealroom.analytics.service.filter",
  ])
  .service("AnalyticsActivityDataService", AnalyticsActivityDataService);

AnalyticsActivityDataService.$inject = [
  "AnalyticsDataDispatcher",
  "ActivityFilterDatesService",
  "ActivityFilterService",
  "ActivityAnalyticsServiceLoadedPromise",
];
function AnalyticsActivityDataService(
  AnalyticsDataDispatcher,
  ActivityFilterDatesService,
  ActivityFilterService,
  ActivityAnalyticsServiceLoadedPromise,
) {
  const pk = "AnalyticsActivityDataService";
  const service = {
    alldata: [],
    isLoaded: false,

    add: loadData,
    filter,

    filterUnreached,
    isStartDateReached: false,
  };

  let isStartDateReached, startDt;

  AnalyticsDataDispatcher.on("batchIsLoaded." + pk, loadData);
  AnalyticsDataDispatcher.on("filtersChanged." + pk, checkStartDate);

  let _startDt = null;

  return service;

  function loadData(batch, isLast) {
    service.alldata = service.alldata.concat(batch);
    service.isLoaded = isLast;

    if (!service.isStartDateReached) {
      if (isLast) {
        service.isStartDateReached = true;
        _startDt = true;
      } else {
        _startDt = batch[batch.length - 1].dt;
      }
      ActivityAnalyticsServiceLoadedPromise.then(function () {
        AnalyticsDataDispatcher.call("filtersChanged");
      });
    }
    batch = null;
  }

  function filterUnreached({ dt }) {
    if (service.isStartDateReached) return true;
    return d3.timeDay.count(_startDt, dt) >= 0;
  }

  function checkStartDate() {
    service.isStartDateReached =
      _startDt === true
        ? true
        : _startDt === null
          ? false
          : ActivityFilterDatesService.isStartDateReached(_startDt);
  }

  function filter(filterBy, includeVerbs) {
    let batch = service.alldata;
    if (includeVerbs instanceof Array) {
      batch = batch.filter((d) => includeVerbs.indexOf(d.verb) > -1);
    }
    return ActivityFilterService.filter(batch, filterBy);
  }
}
