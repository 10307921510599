import { cloneDeep } from "lodash-es";
import { includes } from "lodash-es";

import { ORG_MEMBER_DATA } from "@app/setups";
import { FieldItemTypes } from "@drVue/api-service/client-dashboard";

import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { PhaseItem } from "@drVue/api-service/client-dashboard";
import type { SelectFieldOption } from "@drVue/api-service/client-dashboard/types";

export function isAccessiblePhase(phase: PhaseItem) {
  const accessiblePhaseUids =
    ORG_MEMBER_DATA?.group?.deals_scope_criteria?.operands.find(
      (operand) => operand.field_key === "phase",
    )?.values ?? [];
  return accessiblePhaseUids.length
    ? accessiblePhaseUids.includes(phase.uid)
    : true;
}

export function isAccessibleCustomFieldOption(
  fieldKey: string,
  option: SelectFieldOption,
) {
  const operand = ORG_MEMBER_DATA?.group?.deals_scope_criteria?.operands.find(
    ({ field_key }) => field_key === fieldKey,
  );
  if (!operand) return true; // If operand doesn't exist, that mean a custom field with all it's options is available
  return operand.values.includes(option.label);
}

export function markDisabledCustomFieldOptions(
  fields: FieldItem[],
): FieldItem[] {
  return cloneDeep(fields).map((field) => {
    if (
      field.field_type === FieldItemTypes.Select &&
      "select_options" in field.extra
    ) {
      const allOptions = field.extra.select_options;
      const accessibleOptions = allOptions.filter((option) =>
        isAccessibleCustomFieldOption(field.key, option),
      );
      field.extra.select_options = allOptions.map((option) => ({
        ...option,
        isDisabled: !includes(accessibleOptions, option),
      }));
    }
    return field;
  });
}

export function markDisabledPhases(
  phases: PhaseItem[],
): Array<PhaseItem & { isDisabled: boolean }> {
  return cloneDeep(phases).map((phase) => {
    return {
      ...phase,
      isDisabled: !isAccessiblePhase(phase),
    };
  });
}

export function getCustomFieldWithoutDisabledOptions(field: FieldItem) {
  if (
    field.field_type === FieldItemTypes.Select &&
    "select_options" in field.extra
  ) {
    const accessibleOptions = field.extra.select_options.filter(
      (option) => !(option as unknown as { isDisabled: boolean }).isDisabled,
    );
    return {
      ...field,
      extra: {
        select_options: accessibleOptions,
        options: accessibleOptions.map(({ label }) => label),
      },
    };
  }
  return field;
}
