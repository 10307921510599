angular
  .module("dealroom.analytics.service.data.dispatcher", [
    "dealroom.analytics.factory.dispatcher",
  ])
  .service("AnalyticsDataDispatcher", AnalyticsDataDispatcher);

AnalyticsDataDispatcher.$inject = ["AnalyticsDispatcherFactory"];
function AnalyticsDataDispatcher(AnalyticsDispatcherFactory) {
  return AnalyticsDispatcherFactory("filtersChanged");
}
