<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <div>
    <div
      v-if="selectFieldTypes.includes(fieldType)"
      class="el-form-item el-form-item--default is-required asterisk-left el-form-item--label-top"
    >
      <label class="el-form-item__label">Options</label>

      <div>
        <Draggable
          v-if="fieldType === 'multiselect'"
          v-model="extra.options"
          item-key="index"
        >
          <template #item="{ index }">
            <ElFormItem
              :key="index"
              :prop="'extra.options.' + index"
              :rules="rules.selectOption"
              class="el-form-item--no-spacing"
            >
              <ElInput v-model="extra.options[index]">
                <template #append>
                  <ElButton @click.prevent="removeOption(index)">
                    <template #icon>
                      <DrIcon name="trash" />
                    </template>
                  </ElButton>
                </template>
              </ElInput>
            </ElFormItem>
          </template>
        </Draggable>
        <Draggable
          v-if="fieldType === 'select'"
          v-model="extra.select_options"
          item-key="index"
        >
          <template #item="{ index }">
            <ElFormItem
              :key="index"
              :prop="'extra.select_options.' + index"
              :rules="rules.selectOption"
              class="el-form-item--no-spacing"
            >
              <ElInput
                v-model="extra.select_options[index].label"
                :class="$style.inputWithColorPicker"
              >
                <template #prepend>
                  <ElColorPicker
                    v-model="extra.select_options[index].color"
                    size="small"
                  />
                </template>
                <template #append>
                  <ElButton @click.prevent="removeOption(index)">
                    <template #icon>
                      <DrIcon name="trash" />
                    </template>
                  </ElButton>
                </template>
              </ElInput>
            </ElFormItem>
          </template>
        </Draggable>
      </div>

      <ElButton type="text" class="el-button--top-indent" @click="addOption()">
        <template #icon>
          <i class="fas fa-plus-circle" />
        </template>
        Add Option
      </ElButton>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable vue/no-mutating-props */

import { defineComponent } from "vue";
import Draggable from "vuedraggable";
import DrIcon from "@shared/ui/dr-icon";

import { FieldItemTypes } from "@drVue/api-service/client-dashboard";

import type { SelectFieldOption } from "@drVue/api-service/client-dashboard/types";
import type { PropType } from "vue";

interface Data {
  selectFieldTypes: any;
  rules: any;
}

export default defineComponent({
  name: "EditOptions",
  components: {
    DrIcon,
    Draggable,
  },
  props: {
    fieldType: {
      required: false,
      type: String as PropType<FieldItemTypes | null>,
      default: null,
    },
    extra: { required: true, type: Object as PropType<any> },
  },
  data(): Data {
    const thany = this as any;

    return {
      selectFieldTypes: [FieldItemTypes.Select, FieldItemTypes.MultiSelect],
      rules: {
        selectOption: {
          trigger: "blur",
          validator: thany.validateOptionUnique,
        },
      },
    };
  },
  computed: {
    extraGetterName() {
      switch (this.fieldType) {
        case FieldItemTypes.Select:
          return "select_options";
        case FieldItemTypes.MultiSelect:
          return "options";
        default:
          throw new Error("Invalid field type");
      }
    },
  },
  watch: {
    fieldType() {
      if (
        this.selectFieldTypes.includes(this.fieldType) &&
        !this.extra[this.extraGetterName]
      ) {
        this.extra[this.extraGetterName] = [this.stubOptionObject()];
      }
    },
  },
  methods: {
    stubOptionObject() {
      switch (this.fieldType) {
        case FieldItemTypes.Select:
          return { label: "", color: null };
        case FieldItemTypes.MultiSelect:
          return "";
        default:
          throw new Error("Invalid field type");
      }
    },
    removeOption(index: number) {
      this.extra[this.extraGetterName].splice(index, 1);
    },
    addOption() {
      if (!this.extra[this.extraGetterName]) {
        this.extra[this.extraGetterName] = [];
      }

      this.extra[this.extraGetterName].push(this.stubOptionObject());
    },
    validateOptionUnique(rule: any, value: any, callback: any) {
      // TODO: refactor applying `extraGetterName` to `extra` object
      function raiseUniquenessError(callback: (err: Error) => never) {
        callback(new Error("Values must be unique."));
      }
      if (value === "") {
        callback(new Error("Please input the value"));
      }
      if (
        "options" in this.extra &&
        this.extra.options.filter((option: string) => option === value).length >
          1
      ) {
        raiseUniquenessError(callback);
      } else if (
        "select_options" in this.extra &&
        this.extra.select_options.filter(
          (option: SelectFieldOption) => option.label === value,
        ).length > 1
      ) {
        raiseUniquenessError(callback);
      } else {
        callback();
      }
    },
  },
});
</script>

<style module lang="scss">
.inputWithColorPicker {
  :global(.el-input-group__prepend) {
    padding: 5px 5px 0 5px;
    width: 30px;
  }
  :global(.el-color-picker__trigger) {
    border: unset;
    width: 20px !important;
    height: 20px !important;
    padding: unset;
  }
}
</style>
