<template>
  <div
    :class="{
      [$style.field]: true,
      [$style.field_isHoverable]: !viewProps.schema.isReadOnly,
    }"
    data-testid="dform-value"
    @click.stop="enterEditMode"
  >
    <div v-if="noTextData" :class="$style.placeholder">
      {{ viewProps.schema.placeholder ?? "Click to write" }}
    </div>

    <template v-else>
      <div
        ref="containerRef"
        :class="{
          [$style.container]: true,
          [$style.container_hasMore]:
            containerTextState.hasExpander && !isExpanded,
        }"
        :style="containerStyle"
      >
        <div :ref="(ref) => setTextRef(ref)">
          <DrEditor :editable="false" :model-value="viewProps.entityValue" />
        </div>
      </div>

      <div
        v-if="containerTextState.hasExpander"
        :class="$style.expander"
        @click.stop.prevent="isExpanded = !isExpanded"
      >
        <span>Show {{ isExpanded ? "less" : "more" }}</span>
        <DrIcon size="sm" :name="isExpanded ? 'caret-up' : 'caret-down'" />
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { isEqual } from "lodash-es";
import { computed, ref } from "vue";
import { DrIcon } from "@shared/ui/dr-icon";
import DrEditor from "@shared/ui/editor/DrEditor.vue";
import { useIntersectionObserver } from "@vueuse/core";

import { getRichTextEmptyData } from "../utils";

import type { ViewFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { Node as ProsemirrorNode } from "@tiptap/pm/model";
import type { ComponentPublicInstance, Ref } from "vue";

const TEXT_ROW_HEIGHT = 24;
const TEXT_MIN_ROWS = 5;
const TEXT_TB_PADDINGS = 10;

interface Props {
  viewProps: ViewFieldProps<ProsemirrorNode, any, { rows: number }>;
}

type IntersectionState = {
  hasExpander: boolean;
  stop?: () => void;
};

const props = defineProps<Props>();

const noTextData = computed(() => {
  const value = props.viewProps.entityValue;
  return !value || isEqual(value, getRichTextEmptyData());
});

const isExpanded = ref(false);
const containerRef = ref<InstanceType<typeof HTMLDivElement> | null>(null);
const containerMaxHeight = computed(
  () =>
    TEXT_ROW_HEIGHT * (props.viewProps.schema.extra?.rows ?? TEXT_MIN_ROWS) +
    TEXT_TB_PADDINGS,
);
const containerStyle = computed(() => ({
  maxHeight: isExpanded.value ? undefined : `${containerMaxHeight.value}px`,
}));

const containerTextState = ref<IntersectionState>({
  hasExpander: false,
  stop: undefined,
});

const intersectionObserverOptions = {
  root: containerRef,
  threshold: 1,
};
const setTextRef = (el: Element | ComponentPublicInstance | null) => {
  if (el) {
    if (containerTextState.value.stop) {
      return;
    }

    const textRef = ref(el) as Ref<HTMLElement>;
    const { stop } = useIntersectionObserver(
      textRef,
      () => {
        if (containerTextState.value.stop) {
          // take into account the padding of the container
          const textClientHeight = textRef.value!.clientHeight + 8;
          const containerClientHeight = containerRef.value!.clientHeight;
          const containerScrollHeight = containerRef.value!.scrollHeight;

          containerTextState.value.hasExpander = isExpanded.value
            ? textClientHeight === containerScrollHeight
            : containerScrollHeight > containerClientHeight;
        } else {
          stop();
        }
      },
      intersectionObserverOptions,
    );

    containerTextState.value.stop = stop;
  } else if (containerTextState.value.stop) {
    containerTextState.value.stop();
    delete containerTextState.value.stop;
  }
};

const enterEditMode = (e: MouseEvent) => {
  if (
    props.viewProps.schema.isReadOnly ||
    e.target instanceof HTMLAnchorElement
  ) {
    return;
  }

  props.viewProps.enterEditMode();
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.field {
  width: 100%;

  :global(.dr-editor__wrapper--read-only) {
    color: colors.$pr-900;
    font: typography.$body_regular;
  }
}

.container {
  position: relative;
  overflow: hidden;
  padding: 4px 6px;
  margin-left: -6px;
  margin-right: -2px;
  color: colors.$pr-900;
  font: typography.$body_regular;
  border-radius: 6px;
  transition: background-color 100ms ease-out;
  border: solid 1px transparent;
}

.container_hasMore:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 22px;
  background: linear-gradient(
    0deg,
    rgb(255, 255, 255) 0%,
    rgba(255, 255, 255, 0.5) 100%
  );
}

.field_isHoverable .container:hover {
  cursor: pointer;
  background-color: colors.$pr-100;
}

.placeholder {
  color: colors.$pr-400;
  font: typography.$body_regular;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: colors.$pr-100;
    outline: solid 4px colors.$pr-100;
  }
}

.expander {
  display: inline-block;
  margin-top: spacing.$xs;
  font: typography.$caption_regular;
  color: colors.$sc-600;
  cursor: pointer;
}
</style>
