import { loadFromLocalStorage } from "@drVue/common";
import documents_uploadConfirmationHtml from "../../../templates/documents/upload-confirmation.html?raw";

RoomDocumentsController.$inject = [
  "$state",
  "$q",
  "$uibModal",
  "RoomConfig",
  "DocumentsService",
  "FileUploadService",
];
import {
  insightTrack,
  RoomDataroomBulkEvent,
  RoomDataroomMenuEvent,
} from "@app/insight";
import DocumentsFilterService from "../services/DocumentsFilterService";
import { LeftSidebarDocumentsTree } from "../services/DocumentsTreeService.ts";
import UploadConfirmationController from "./UploadConfirmationController";

export default function RoomDocumentsController(
  $state,
  $q,
  $uibModal,
  RoomConfig,
  DocumentsService,
  FileUploadService,
) {
  var self = this;

  self.userPermissions = RoomConfig.userPermissions;
  self.RoomConfig = RoomConfig;
  self.selectedFolderId = undefined;
  self.Documents = DocumentsService;
  self.FileUploadService = FileUploadService;
  self.DocsFilter = DocumentsFilterService;
  self.allowBulkDownload =
    RoomConfig.enableBulkDownload || RoomConfig.userPermissions.administrator;

  self.selectFolder = selectFolder;
  self.goToRecent = goToRecent;
  self.goToBookmarks = goToBookmarks;
  self.goToArchive = goToArchive;
  self.goToAiReports = goToAiReports;
  self.resetSearch = resetSearch;
  self.notifyDrag = notifyDrag;
  self.uploadConfirmationDialog = uploadConfirmationDialog;
  self.onItemDragAndDrop = onItemDragAndDrop;
  self.trackUploadFolders = trackUploadFolders;
  self.trackUploadFiles = trackUploadFiles;

  self.downloadItem = downloadItem;
  self.editCustomData = editCustomData;
  self.showActivity = showActivity;

  function _checkSelectedFolder() {
    if ($state.current.name === "documents") {
      // open and expand selected folder or open root if nothing selected
      self.selectFolder(self.Documents.rootFolder, true);
    }
  }

  if (self.Documents.rootFolder) {
    _checkSelectedFolder();
  } else {
    $q.when(DocumentsService.loadingPromise).then(function () {
      _checkSelectedFolder();
    });
  }

  function selectFolder(folder, expandTree) {
    self.selectedFolderId = folder.id;

    if (expandTree) {
      LeftSidebarDocumentsTree.expandFolder(folder);
    }

    // reload folder without reloading documents controller
    $state.go(
      "documents.folder",
      { folderId: folder.id, query: self.DocsFilter.searchQuery },
      { reload: "documents.folder" },
    );
  }

  function goToRecent() {
    $state.go("documents.recent", { query: self.DocsFilter.searchQuery });
  }

  function goToBookmarks() {
    $state.go("documents.bookmarks", { query: self.DocsFilter.searchQuery });
  }

  function goToAiReports() {
    $state.go("documents.reports", {});
  }

  function goToArchive() {
    $state.go("documents.archive", { query: self.DocsFilter.searchQuery });
  }

  function resetSearch() {
    $state.go($state.$current.name, { query: "" }, { reload: false });
  }

  function downloadItem(item) {
    if (item.type === "file") {
      insightTrack(RoomDataroomMenuEvent.Download, { item: "file" });

      DocumentsService.documentDownload(item.id);
    } else if (item.type === "folder") {
      insightTrack(RoomDataroomMenuEvent.Download, { item: "folder" });

      DocumentsService.bulkDownload([item.id], []);
    }
  }

  function trackUploadFolders() {
    insightTrack(RoomDataroomBulkEvent.Upload, { item: "folder" });
  }

  function trackUploadFiles() {
    insightTrack(RoomDataroomBulkEvent.Upload, { item: "file" });
  }

  function editCustomData(item) {
    DocumentsService.editCustomDataModal(item);
  }

  function showActivity(item) {
    DocumentsService.showDocumentActivity(item);
  }

  function notifyDrag(folder, isDragging) {
    const currentFolder =
      folder ?? DocumentsService.Folders[self.selectedFolderId];
    const label = currentFolder?.parent_id
      ? `the folder ${currentFolder.treeIndex} ${currentFolder.name}`
      : "your room";

    FileUploadService.notifyDrag(label, isDragging);
  }

  function uploadConfirmationDialog(folder, items) {
    const id = folder?.id ?? self.selectedFolderId;

    if (!items || !items.length) return;
    FileUploadService.dragLabel = undefined;
    $uibModal.open({
      template: documents_uploadConfirmationHtml,
      resolve: {
        items: $q.when(items),
        destinationFolderId: id,
      },
      animation: false,
      controllerAs: "$ctrl",
      controller: UploadConfirmationController,
    });
  }

  function onItemDragAndDrop(destFolder, items) {
    // we can not really disable drag to file (prolly because of ngf upload)
    // so double check it here
    if (destFolder.type !== "folder" || !destFolder.edit) {
      return false; // cancel drag and drop
    }
    const docs = [],
      folders = [];
    items.forEach((item) => {
      (item.type === "folder" ? folders : docs).push(item.id);
    });
    DocumentsService.moveOrCopyItems(folders, docs, false, destFolder.id);
    return true;
  }

  // New Upload Dialog related code
  self.isNewUploadDialogEnabled =
    !!loadFromLocalStorage("dr:enable_new_upload_dialog") ||
    RoomConfig.enableNewUploadDialog;

  self.folderToUploadId = null;
  self.itemsToUpload = null;

  self.setItemsToUpload = (folder, files) => {
    if (Array.isArray(folder)) {
      files = folder;
      folder = null;
    }

    self.folderToUploadId = folder?.id ?? self.selectedFolderId;

    const isUploadingFolder = files.some((f) => !!f.webkitRelativePath);
    if (isUploadingFolder) {
      trackUploadFolders();
    } else {
      trackUploadFiles();
    }

    self.itemsToUpload = files;
  };

  self.clearItemsToUpload = () => {
    self.folderToUploadId = null;
    self.itemsToUpload = null;
  };

  self.selectUploadDialg = (folder, files) => {
    FileUploadService.dragLabel = undefined;

    if (self.isNewUploadDialogEnabled) {
      self.setItemsToUpload(folder, files);
    } else {
      self.uploadConfirmationDialog(folder, files);
    }
  };
}
