<template>
  <div :class="$style.container">
    <Section title="General">
      <InformationTable
        :uploaded-at="uploadedAt"
        :number-of-pages="numberOfPages"
        :last-modified="lastModified"
        :size="size"
      />
    </Section>

    <Section
      title="Related requests"
      v-if="ROOM_DATA.userPermissions.viewTasks"
    >
      <template #action>
        <LinkToRequestAction :document-id="documentInfo.id" />
      </template>

      <RequestsList :document-id="documentInfo.id" />
    </Section>

    <div v-if="documentInfo.permission.edit">
      <!-- .versions are provided only if the user has edit permissions for the document -->
      <Section title="Versions" v-if="documentInfo.versions?.length">
        <VersionsList
          :versions="documentInfo.versions"
          :showing-version-id="documentInfo.version_id"
        />
      </Section>

      <!-- .version_id is provided only if the user has edit permissions for the document -->
      <Section
        title="Redaction"
        v-if="documentInfo.version_id && !!redactionManager"
      >
        <RedactionForm
          :version-id="documentInfo.version_id"
          :document-id="documentInfo.id"
          :session-id="redactionManager?.getOriginalSessionId()"
          @preview-generated="
            (sessionId) =>
              redactionManager?.replaceSessionWithRedaction(sessionId)
          "
          @redaction-cancelled="
            redactionManager?.replaceSessionWithRedaction(
              redactionManager?.getOriginalSessionId(),
            )
          "
        />
      </Section>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { ROOM_DATA } from "@setups/data";
import { formatBytes } from "@app/common/formatters";
import { drUserTime } from "@drVue/filters/drUserTime";
import RedactionForm from "./RedactionForm.vue";
import InformationTable from "./sidebar/InformationTable.vue";
import LinkToRequestAction from "./sidebar/LinkToRequestAction.vue";
import RequestsList from "./sidebar/RequestsList.vue";
import Section from "./sidebar/Section.vue";
import VersionsList from "./sidebar/VersionsList.vue";

import type { BaseViewerManager } from "./managers/base";
import type PrizmdocViewerManager from "./managers/PrizmdocViewerManager";
import type { SidebarSection } from "./types";
import type { DocumentViewerInfo } from "./types";

const props = defineProps<{
  currentSection: SidebarSection;
  documentInfo: DocumentViewerInfo;
  manager: BaseViewerManager;
}>();

const emit = defineEmits<{
  (e: "expand-section", section: SidebarSection): void;
}>();

const handleExpand = (section: SidebarSection) => {
  emit("expand-section", section);
};

const numberOfPages = computed(() => props.manager.state.totalPages!);
const size = computed(() => formatBytes(props.documentInfo.size));

const lastModified = computed(() => {
  if (!props.documentInfo.versions?.length) return;
  const result = drUserTime(
    props.documentInfo.versions[0].date_created,
    "full-date",
  );
  if (result === null) return;
  return result;
});

const versions = computed(() => {
  return props.documentInfo.versions?.map(({ date_created }) => {
    const result = drUserTime(date_created, "short-dt");
    if (result === null) return;
    return result;
  });
});

const uploadedAt = computed(() => {
  if (versions.value?.length) return versions.value[0];
  return "";
});

const redactionManager = computed((): PrizmdocViewerManager | null => {
  return props.manager.isRedactionSupported
    ? (props.manager as PrizmdocViewerManager)
    : null;
});
</script>

<style module lang="scss">
@use "@app/styles/scss/_colors";
@use "./vars";

.container {
  background: colors.$pr-0;
  height: 100%;
  border-top: 1px solid colors.$pr-200;
}
</style>
