<template>
  <!-- @vue-ignore -->
  <button
    type="button"
    :title="btn.title"
    class="dr-editor__menu-button"
    :class="{ 'is-active': btn.methods.isActive() }"
    @click="btn.methods.toggle()"
  >
    <slot>
      <i :class="btn.icon" />
      <template v-if="showTitle">{{ btn.title }}</template>
    </slot>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { MenuButton } from "./types";
import type { PropType } from "vue";

export default defineComponent({
  name: "DrEditorMenuButton",
  props: {
    btn: { required: true, type: Object as PropType<MenuButton> },
    showTitle: { default: false, type: Boolean as PropType<boolean> },
  },
});
</script>
