import { computed } from "vue";
import { useBrowserLocation } from "@vueuse/core";

import { CLIENT_DASHBOARD_DATA, ORG_MEMBER_DATA } from "@setups/data";

export const NON_ROOM_LOCATIONS = [
  "notifications",
  "org-settings",
  "your-work",
];
export const useIsRoomLocation = () => {
  const $location = useBrowserLocation();

  const isRoomLocation = computed<boolean>(() => {
    const currentPath = $location.value.hash?.slice(1) || "";
    return !NON_ROOM_LOCATIONS.some((path) =>
      currentPath.startsWith(`/${path}`),
    );
  });
  return {
    isRoomLocation,
  };
};

export const IS_PIPELINE_OPEN = CLIENT_DASHBOARD_DATA.is_paid !== undefined;
export const IS_PIPELINE_ENABLED = ORG_MEMBER_DATA?.client.enable_dashboard;
