import { unref } from "vue";

import { extractFiles } from "@app/vue/utils/extractFiles";

import type { UploadItem } from "@app/vue/utils/extractFiles";
import type { Ref } from "vue";
import type { VxeGridInstance } from "vxe-table";

const hasNoFiles = (e: DragEvent) =>
  e.dataTransfer === null ||
  e.dataTransfer.items.length === 0 ||
  Array.from(e.dataTransfer.items).every((i) => i.kind !== "file");

export const useVxeGridDragNDrop = <Item>(
  gridRef: Ref<VxeGridInstance | undefined>,
  rowIdPrefix: string,
  onDropped: (item: Item, entries: UploadItem[]) => void,
  onDroppedDefault: (entries: UploadItem[]) => void,
  onDraggedOver: (item?: Item) => void,
  onDraggedLeave: (item?: Item) => void,
) => {
  const onDragOver = (e: DragEvent) => {
    if (hasNoFiles(e)) return;

    const grid = unref(gridRef);

    const target = e.target as HTMLElement;
    const rowEl = target.closest(".vxe-body--row") as HTMLElement | null;
    const rowId = rowEl?.getAttribute("rowid") || null;
    if (grid && rowEl && rowId && rowId.startsWith(rowIdPrefix)) {
      const row = grid.getRowById(rowId);

      grid.setCurrentRow(row);
      onDraggedOver(row);
    } else {
      grid?.clearCurrentRow();
      onDraggedOver();
    }
  };

  const onDragLeave = (e: DragEvent) => {
    onDraggedLeave();

    if (hasNoFiles(e)) return;

    unref(gridRef)?.clearCurrentRow();
  };

  const onDrop = (e: DragEvent) => {
    if (hasNoFiles(e)) return;

    const grid = unref(gridRef);
    grid?.clearCurrentRow();

    const target = e.target as HTMLElement;
    const rowEl = target.closest(".vxe-body--row") as HTMLElement | null;
    const rowId = rowEl?.getAttribute("rowid") || null;
    if (grid && rowEl && rowId && rowId.startsWith(rowIdPrefix)) {
      extractFiles(e).then((entries) =>
        onDropped(grid.getRowById(rowId), entries.flat()),
      );
    } else {
      extractFiles(e).then((entries) => onDroppedDefault(entries.flat()));
    }
  };

  return {
    onDragOver,
    onDragLeave,
    onDrop,
  };
};
