import { ApiService } from "../index";

import type { SubscriptionWarning } from "@setups/data";
import type { PaymentTypes, SubscriptionPlanTypes } from "@setups/enums";

interface PlanInformation {
  price_month: number;
  features: string[];
}

export const PaymentPlanIntervals: string[] = ["month", "quarter", "year"];

export interface Plan {
  title: string;
  information: PlanInformation;
  interval: (typeof PaymentPlanIntervals)[number];
  payment_type: PaymentTypes;
}

export class BillingApiService extends ApiService {
  constructor() {
    super();
  }

  public plans() {
    const url = this.Urls["api:billing:plans"]();

    return this.get<Plan[]>(url);
  }
}

abstract class BaseBillingService extends ApiService {
  abstract baseUrlPath: string;

  constructor(private billingObjectId: number) {
    super();
  }

  public updateLimit(plan_type: SubscriptionPlanTypes, limit: number) {
    const url = this.Urls[`${this.baseUrlPath}:warnings`](this.billingObjectId);
    const payload = { type: plan_type, limit };

    return this.put<SubscriptionWarning | null>(url, payload).then(
      (response) => {
        // backend returns {} if there is no warning, but we use null for that
        if (!response?.title) {
          return null;
        }
        return response;
      },
    );
  }
}

export class RoomBillingService extends BaseBillingService {
  baseUrlPath = "api:billing:rooms";
}

export class OrganizationBillingService extends BaseBillingService {
  baseUrlPath = "api:billing:clients";
}
