<template>
  <ElDialog
    v-model="isDialogVisible"
    title="Your time zone has changed"
    width="600px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :show-close="!isWaiting"
    :close-on-press-escape="!isWaiting"
    @close="hideDialog"
  >
    <div class="errors-dialog">
      <ElForm>
        <div>
          <p>
            Would you like to update your time zone to the new detected time
            zone below?
          </p>

          <ElFormItem>
            <ElSelect
              v-model="selectedTimezone"
              class="el-select--full-width"
              placeholder=""
              :disabled="isWaiting"
            >
              <ElOption
                v-for="tz in timezones"
                :key="tz"
                :label="tz"
                :value="tz"
              />
            </ElSelect>
          </ElFormItem>

          <p>
            Time zone setting of your profile:
            <b>{{ newTimezone || savedTimezone }}</b>
          </p>
        </div>
        <div v-if="isWaiting">
          <p>
            <b>
              Your time zone setting was successfully updated. We will reload
              the page to apply the change in {{ countdown }} seconds.
            </b>
          </p>
        </div>

        <ElFormItem class="text-right">
          <ElButton v-if="!isWaiting" :disabled="isWaiting" @click="dismiss">
            Don't update
          </ElButton>
          <ElButton v-if="!isWaiting" type="primary" @click="update">
            Update
          </ElButton>
          <ElButton v-if="isWaiting" type="primary" @click="reload">
            Reload now
          </ElButton>
        </ElFormItem>
      </ElForm>
    </div>
  </ElDialog>
</template>

<script lang="ts">
import Cookies from "js-cookie";
import { defineComponent } from "vue";

import { APP_SETTINGS, USER_DATA } from "@setups/data";
import { Urls } from "@setups/urls";
import { http } from "@drVue/api-service/http";
import {
  $notifyDanger,
  getTimezone,
  loadFromLocalStorage,
} from "@drVue/common";
import { tzdb } from "@drVue/tzdb";

const LS_PREVIOUSLY_SHOWN_TIMEZONE_KEY = "dr:previously_shown_timezone";
const COOKIE_PREVIOUSLY_SHOWN_TIMEZONE = "tz";

interface Data {
  isDialogVisible: boolean;
  selectedTimezone: string;
  isWaiting: boolean;
  countdown: number;
  countdownIntervalId: number | null;
  newTimezone: string | null;
}

export default defineComponent({
  name: "TimezoneDialog",
  data(): Data {
    return {
      isDialogVisible: false,
      selectedTimezone: "",
      isWaiting: false,
      countdown: 15,
      countdownIntervalId: null,
      newTimezone: null,
    };
  },
  computed: {
    currentTimezone(): any {
      return getTimezone();
    },
    savedTimezone(): any {
      return USER_DATA.profile.timezone;
    },
    timezones(): any {
      return tzdb;
    },
  },
  beforeMount() {
    if (window.isTestEnv) return;
    const lsPrevShownTz = loadFromLocalStorage(
      LS_PREVIOUSLY_SHOWN_TIMEZONE_KEY,
    ) as string;
    let cookiePrevShownTz = Cookies.get(COOKIE_PREVIOUSLY_SHOWN_TIMEZONE);

    if (lsPrevShownTz) {
      this.setTimezoneCookie(lsPrevShownTz);
      localStorage.removeItem(LS_PREVIOUSLY_SHOWN_TIMEZONE_KEY);
      cookiePrevShownTz = lsPrevShownTz;
    }

    if (cookiePrevShownTz === this.currentTimezone) return;

    this.selectedTimezone = this.currentTimezone;
    this.isDialogVisible =
      this.currentTimezone !== this.savedTimezone &&
      this.currentTimezone !== cookiePrevShownTz;

    if (this.isDialogVisible) {
      this.setTimezoneCookie();
    }
  },
  methods: {
    setTimezoneCookie(value?: string) {
      Cookies.set(
        COOKIE_PREVIOUSLY_SHOWN_TIMEZONE,
        value || this.currentTimezone,
        {
          domain: APP_SETTINGS.WEBSITE.HOST,
          expires: 3650,
        },
      );
    },
    showDialog() {
      this.isDialogVisible = true;
    },
    hideDialog() {
      this.isDialogVisible = false;
    },
    dismiss() {
      this.setTimezoneCookie();
      this.hideDialog();
    },
    reload() {
      location.reload();
    },
    update() {
      this.isWaiting = true;

      const tick = () => {
        if (this.countdown === 1) {
          if (this.countdownIntervalId) clearInterval(this.countdownIntervalId);
          location.reload();
          return;
        }

        this.countdown--;
      };

      const payload = {
        timezone: this.selectedTimezone,
      };

      http.post(Urls["api:users:user"](USER_DATA.id), payload).then(
        () => {
          this.newTimezone = this.selectedTimezone;
          this.countdownIntervalId = window.setInterval(tick, 1000);
        },
        () => {
          this.isWaiting = false;
          $notifyDanger(
            "Something went wrong, can't save the time zone. Please, try again.",
          );
        },
      );
    },
  },
});
</script>
