export default function treeService(
  roots,
  onTreeChange,
  getKey,
  getChild,
  options,
) {
  var STATES = {
    unselected: 0,
    indeterminate: 1, // only some of child selected
    selected: 2,
  };
  var defaultOptions = {
    defaultSelected: true, // select all by default
    checkParentNode: true, // select parent if all child selected
  };
  options = { ...defaultOptions, ...options };
  var defaultSelectedValue = options.defaultSelected
    ? STATES.selected
    : STATES.unselected;
  var service = {
    STATES: STATES,
    isAllSelected: false,
    selectedChildsList: [], // selected child nodes elements
    displayedSelectedList: [], // selected elements for display in label
  };
  var rootKeys,
    allItems,
    selectedValues,
    itemChildObj,
    itemChildKeys,
    itemParentKey,
    terminalKeys,
    displayedSelected,
    expanded = {};

  function processChild(parent, items, oldSelected) {
    var parentKey = parent && getKey(parent);
    if (parent) {
      itemChildObj[parentKey] = items;
    }
    if (!items || items.length === 0) {
      return [];
    }

    return items.map(function (item) {
      var key = getKey(item);
      allItems[key] = item;
      itemParentKey[key] = parentKey;
      selectedValues[key] =
        key in oldSelected ? oldSelected[key] : defaultSelectedValue;

      var child = getChild(item);
      if (child && child.length > 0) {
        itemChildKeys[key] = processChild(item, child, oldSelected);
      } else {
        itemChildKeys[key] = [];
        terminalKeys[key] = true;
      }
      return key;
    });
  }

  function refresh(newRoots) {
    roots = newRoots;
    var oldSelected = selectedValues || {};
    // reset variables
    allItems = {};
    selectedValues = {};
    itemChildKeys = {};
    itemChildObj = {};
    itemParentKey = {};
    terminalKeys = {};
    displayedSelected = {};
    rootKeys = processChild(null, roots, oldSelected);
    updateSelectedList();
  }

  function updateSelectedList() {
    service.selectedChildsList = [];
    service.displayedSelectedList = [];
    service.isAllSelected = false;
    displayedSelected = {};
    if (rootKeys.length === 0) {
      return;
    }

    function updateSelected(key) {
      const childKeys = itemChildKeys[key];
      if (!childKeys || childKeys.length === 0) {
        return selectedValues[key];
      }

      childKeys.forEach(function (childKey) {
        updateSelected(childKey);
      });
      const selectedChildCount = {};
      for (const childKey of childKeys) {
        const childState = selectedValues[childKey];
        selectedChildCount[childState] =
          (selectedChildCount[childState] || 0) + 1;
      }
      if (
        selectedChildCount[STATES.selected] &&
        selectedChildCount[STATES.selected] === childKeys.length &&
        (options.checkParentNode || selectedValues[key] === STATES.selected)
      ) {
        selectedValues[key] = STATES.selected;
      } else if (
        selectedChildCount[STATES.selected] ||
        selectedChildCount[STATES.indeterminate]
      ) {
        selectedValues[key] = STATES.indeterminate;
        if (selectedChildCount[STATES.selected]) {
          for (const childKey of childKeys) {
            if (selectedValues[childKey] === STATES.selected) {
              const child = allItems[childKey];
              service.displayedSelectedList.push(child);
            }
          }
        }
      } else {
        //nothing selected
        selectedValues[key] = STATES.unselected;
      }
    }

    rootKeys.forEach(function (rootKey) {
      updateSelected(rootKey);
      if (selectedValues[rootKey] === STATES.selected) {
        var rootItem = allItems[rootKey];
        service.displayedSelectedList.push(rootItem);
      }
    });

    // add children to another list just for user-selection
    rootKeys.forEach(function (rootKey) {
      var children = itemChildKeys[rootKey];
      // move all selected children to selectedChildsList
      children.forEach(function (childKey) {
        if (selectedValues[childKey] === STATES.selected) {
          service.selectedChildsList.push(allItems[childKey]);
        }
      });
    });

    service.displayedSelectedList.forEach(function (item) {
      var key = getKey(item);
      displayedSelected[key] = true;
    });

    service.displayedSelectedList.sort(function (aItem, bItem) {
      return aItem.treePosition - bItem.treePosition;
    });

    service.isAllSelected = rootKeys.every(function (rootKey) {
      return selectedValues[rootKey] === STATES.selected;
    });
  }

  function toggleSelected(item) {
    var key = getKey(item);
    setSelected(item, selectedValues[key] !== STATES.selected);
  }

  function setSelected(item, selected, skipOnChange) {
    var value = selected ? STATES.selected : STATES.unselected;
    var key = getKey(item);
    _propagateSetSelection(key, value);
    updateSelectedList();
    if (!skipOnChange) {
      onTreeChange(service);
    }
  }

  function _propagateSetSelection(key, value) {
    selectedValues[key] = value;
    if (itemChildKeys[key]) {
      itemChildKeys[key].forEach(function (childKey) {
        _propagateSetSelection(childKey, value);
      });
    }
  }

  function isSelected(item) {
    var key = getKey(item);
    return selectedValues[key];
  }

  function isTerminal(item) {
    var key = getKey(item);
    return terminalKeys[key];
  }

  function isExpanded(item) {
    var key = getKey(item);
    return expanded[key];
  }

  function toggleExpanded(item) {
    var key = getKey(item);
    expanded[key] = !expanded[key];
  }

  function getChildItems(item) {
    // do not use getChild, because some items can be changed
    // use refresh to update whole tree
    var key = getKey(item);
    return itemChildObj[key];
  }

  function getRoots() {
    return roots;
  }

  function unselectAll() {
    roots.forEach(function (item) {
      setSelected(item, STATES.unselected, true);
    });
  }

  function getItemClass(item) {
    var key = getKey(item);
    var selected = selectedValues[key];
    if (selected === STATES.unselected) {
      return "";
    } else if (selected === STATES.indeterminate) {
      return "tree-service__checkbox--selected-parent";
    } else {
      if (displayedSelected[key]) {
        return "tree-service__checkbox--selected";
      } else {
        return "tree-service__checkbox--selected-child";
      }
    }
  }

  function expandAncestors(item) {
    var itemKey = getKey(item);
    while ((itemKey = itemParentKey[itemKey])) {
      expanded[itemKey] = true;
    }
  }

  function buildFlatSubTree() {
    const flatTree = [];
    function addItem(level, item) {
      flatTree.push({ level, item });
      if (!isExpanded(item)) {
        return;
      }
      const childItems = getChildItems(item);
      if (childItems && childItems.length) {
        for (const child of childItems) addItem(level + 1, child);
      }
    }
    for (const root of getRoots()) {
      addItem(0, root);
    }
    return flatTree;
  }

  service.getItemClass = getItemClass;
  service.toggleSelected = toggleSelected;
  service.setSelected = setSelected;
  service.isSelected = isSelected;
  service.isTerminal = isTerminal;
  service.refresh = refresh;
  service.buildFlatSubTree = buildFlatSubTree;

  service.isExpanded = isExpanded;
  service.toggleExpanded = toggleExpanded;
  service.expandAncestors = expandAncestors;

  service.getChildItems = getChildItems;
  service.getRoots = getRoots;
  service.unselectAll = unselectAll;

  refresh(roots);

  return service;
}
