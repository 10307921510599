const columns = [
  { title: "Category", data_index: "category_fullpath", width: 24 },
  { title: "Name", data_index: "name", width: 36 },
  { title: "Type", data_index: "type", width: 10 },
  {
    title: "Time (min)",
    data_index: "task_viewed:time",
    width: 12,
    float_precision: 0,
  },
  { title: "Users Viewed", data_index: "task_viewed:uniq:user", width: 18 },
  { title: "Requests Views", data_index: "task_viewed:total", width: 18 },
];
export default columns;
