import "angular";
import "angular-messages";
import "angular-animate";
import "angular-sanitize";
import "angular-ui-bootstrap";
import "angular-filter";
import "./ng/date-fns";
import "ng-file-upload";
import "angular-svg-round-progressbar";
import "ui-select";
import "angular-ui-router";
import "angular-vs-repeat";
import "./../vendor/ngVue/src/index";

import * as insight from "./insight";
import {
  // initial data setup (defined in angular.html template)
  APP_SETTINGS,
  getCsrfToken,
  intercept403,
  interceptAuthError,
  NG_APP,
  ORG_MEMBER_DATA,
  ROOM_DATA,
  USER_DATA,
} from "./setups";

function getCurrentNgAppModule() {
  return NG_APP;
}

angular
  .module("dealroom.config")
  .constant("UserData", USER_DATA)
  .constant("RoomConfig", ROOM_DATA)
  .constant("ORG_MEMBER_DATA", ORG_MEMBER_DATA)
  .constant("NG_APP", getCurrentNgAppModule())
  .constant("STRIPE_PUBLIC_KEY", APP_SETTINGS.STRIPE_PUBLIC_KEY)
  .constant("STATIC_URL", APP_SETTINGS.STATIC_URL)
  .constant("ContactEmail", APP_SETTINGS.CONTACT_EMAIL)
  .constant("WebsiteUrl", APP_SETTINGS.WEBSITE.URL) //TODO: user WEBSITE constant
  .constant("WEBSITE", APP_SETTINGS.WEBSITE)
  .constant("PaymentTypes", APP_SETTINGS.PAYMENT_TYPES)
  .constant("APP_SETTINGS", APP_SETTINGS)
  .run([
    "$rootScope",
    "WEBSITE",
    function ($rootScope, WEBSITE) {
      $rootScope.WEBSITE = WEBSITE;
    },
  ])
  .factory("$exceptionHandler", [
    "$log",
    function ($log) {
      return function (exception, cause) {
        // request with status=401 is handled in request interceptors
        // so no need to log error for it, the page will be redirected to auth
        if (
          typeof exception === "string" &&
          exception.indexOf('"status":401,') > -1
        ) {
          return;
        }
        // default angular's exception handling
        // https://docs.angularjs.org/api/ng/service/$exceptionHandler
        $log.error(exception, cause);
      };
    },
  ])
  .config([
    "$httpProvider",
    "$locationProvider",
    function configure($httpProvider, $locationProvider) {
      $locationProvider.hashPrefix("");

      $httpProvider.defaults.withCredentials = true;
      $httpProvider.defaults.headers.common["X-Requested-With"] =
        "XMLHttpRequest";
      $httpProvider.interceptors.push([
        "$q",
        function ($q) {
          return {
            request: function (config) {
              const csrfToken = getCsrfToken();
              // AngularJS 1.5 doesn't support passing xsrf token
              // to allowed cross-domain
              // we do it manually
              // todo: we can use whitelists after upgrade ng to 1.6
              // https://code.angularjs.org/1.6.10/docs/api/ng/provider/$httpProvider#xsrfWhitelistedOrigins
              if (csrfToken) {
                config.headers["X-CSRFToken"] = csrfToken;
              }
              return config;
            },
            responseError: function (response) {
              interceptAuthError(response);
              intercept403(response);
              return $q.reject(response);
            },
          };
        },
      ]);
    },
  ]);

initSentry(null, null);

// if (!SENTRY_OPTIONS.enabled) {
//   // declare empty module, so Angular DI does not fail
//   angular.module("dealroom.sentry", []);
// }

// configure insight
insight.init();

angular.module("dealroom.config").run([
  "$rootScope",
  function configure($rootScope) {
    insight.trackAngularRouterChange($rootScope);
  },
]);

import "./common";
import "./room";
import "./components/shared";

import { initSentry } from "./vue/components/initSentry";
