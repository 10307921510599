<template>
  <ElDialog
    v-model="isModalVisible"
    title="Must be overridden by slot."
    width="688px"
    :append-to-body="true"
    top="60px"
  >
    <template #header>
      <b>Help</b>
    </template>
    <DrHelpModalContent v-if="isModalVisible" @close="close" />
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrHelpModalContent from "@shared/ui/dr-help/content.vue";

interface Data {
  isModalVisible: boolean;
}

export default defineComponent({
  name: "DrHelpModal",
  components: {
    DrHelpModalContent,
  },
  data(): Data {
    return {
      isModalVisible: false,
    };
  },
  methods: {
    open() {
      this.isModalVisible = true;
    },
    close() {
      this.isModalVisible = false;
    },
  },
});
</script>
