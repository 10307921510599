import { ApiService } from "@drVue/api-service";

import type { TaskLabel } from "../tasksLabels/tasksLabelApi";
import type { TaskStatus } from "@drVue/store/modules/room/tasks-statuses/types";
import type {
  ITaskCommentDraft,
  TaskActivity,
  TaskComment,
} from "@drVue/store/modules/room/types";
import type { Category } from "@drVue/store/pinia/room/categories/api";
import type { PartiallyRequired } from "@drVue/types";
import type { Node as ProsemirrorNode } from "@tiptap/pm/model";

export const TASK_PRIORITY = ["low", "medium", "high"] as const;
export type TaskPriority = (typeof TASK_PRIORITY)[number];

export interface UserUid {
  user_id: string;
}

export interface ParticipantUserV1 {
  user_id: number;
}

export interface ParticipantUserV2 {
  /* @deprecated */
  id: number;
  user_id: string;
  name: string;
  avatar: {
    thumbnail: string;
    reduced: string;
  };
}

export interface ParticipantGroup {
  group_id: number;
}

export interface TaskReviewer {
  user_id: number;
  mark_complete: boolean;
}

export interface TaskId {
  task_id: Task["id"];
}

export interface TaskUid {
  task_uid: Task["uid"];
}

export interface DocumentId {
  document_id: number;
}

export interface DocumentIdPaged {
  document_id: number;
  page: number | null;
}

export interface FolderId {
  folder_id: number;
}

export interface DocumentUid {
  document_id: string;
}

export interface FolderUid {
  folder_id: string;
}

export interface TaskFolder extends FolderId {
  date_added: Date;
}

export interface TaskDocument extends DocumentId {
  date_added: Date;
}

export interface BulkAttachemntsRequest {
  create: {
    documents: DocumentIdPaged[];
    folders: FolderId[];
  };
  delete: {
    documents: DocumentIdPaged[];
    folders: FolderId[];
  };
}

export interface BulkAttachemntsResposne {
  documents: TaskDocument[];
  folders: TaskFolder[];
}

export interface TaskDynamicDateConf {
  deal_field_key: string;
  is_deal_start_date_field: boolean;
  delta_days: number;
}

export interface Task {
  assignees?: ParticipantUserV1[];
  body: string;
  category_id: number;
  comments_count: number;
  comments_documents: DocumentId[];
  comments_folders: FolderId[];
  date_added?: Date;
  date_modified: Date;
  dependencies: number[];
  documents: TaskDocument[];
  due_date?: Date | null;
  folders: TaskFolder[];
  followers?: ParticipantUserV1[];
  id: number;
  is_archived: boolean;
  key: string;
  labels: number[];
  last_updated: Date;
  last_visit_date: Date;
  new_comments_count: number;
  order: number;
  priority?: TaskPriority;
  resolved_date?: Date | null;
  reviewers?: TaskReviewer[];
  sender_id?: number;
  start_date?: Date | null;
  status_id: number;
  title: string;
  uid: string;
  unapproved_comments_count: number;
  users_reminded?: ParticipantUserV1[];
  workspace_id: number;
  overdue: boolean;

  is_due_date_dynamic?: boolean;
  dynamic_due_date?: TaskDynamicDateConf;
  is_start_date_dynamic?: boolean;
  dynamic_start_date?: TaskDynamicDateConf;

  custom_data?: { [key: string]: any };

  /** @deprecated Avoid using this field! */
  extra: Record<string, unknown>;

  // Will be set on client-side from ROOM_DATA.taskStatuses by status_id
  /** @deprecated Use status_id as the foreign key of the Statuses store */
  status: TaskStatus;
}

export interface TaskWithChanges extends Omit<Task, "followers"> {
  follower_ids: number[];
}

export interface CreateTaskPayload {
  category_id: number | null;
  status_id: number | null;

  title: string;
  description: ProsemirrorNode;

  priority: TaskPriority;

  start_date: Date | null;
  due_date: Date | null;

  assignees: UserUid[];
  followers: UserUid[];
  reviewers: UserUid[];
  folders: FolderUid[];
  documents: DocumentUid[];

  labels: number[];

  order?: number;

  custom_data?: { [key: string]: any };
  // copy_task_id?: number;
}

export class TasksApiService extends ApiService {
  public loadTasks(): Promise<Task[]> {
    const url = this.Urls["api:room:tasks"]();
    return this.get<Task[]>(url);
  }

  public loadArchivedTasks(): Promise<Task[]> {
    const url = this.Urls["api:room:tasks_archived"]();
    return this.get<Task[]>(url);
  }

  public loadTaskByKey(taskKey: string): Promise<Task> {
    const url = this.Urls["api:room:task_by_key"](taskKey);
    return this.get(url);
  }

  public loadTaskV2(taskId: Task["id"]): Promise<Task> {
    const url = this.Urls["api:room:tasks_v2-detail"](taskId);
    return this.get(url);
  }

  public createTaskV2(task: CreateTaskPayload): Promise<Task> {
    const url = this.Urls["api:room:tasks_v2-list"]();
    return this.post(url, task);
  }

  public patchTaskV2(taskId: string, changes: Partial<Task>): Promise<Task> {
    const url = this.Urls["api:room:tasks_v2-detail"](taskId);
    return this.patch(url, changes, {
      headers: {
        "X-DR-API-Upgrade-Flags": "task_v2_uid_in_routes",
      },
    });
  }

  public patchTaskBulkV2(
    tasks: PartiallyRequired<Task, "id">[],
  ): Promise<Task[]> {
    const url = this.Urls["api:room:tasks_v2-bulk"]();
    return this.patch(url, tasks);
  }

  public deleteTaskV2(taskId: number): Promise<void> {
    const url = this.Urls["api:room:tasks_v2-detail"](taskId);
    return this.delete(url);
  }

  public deleteTasksV2(taskUids: string[]): Promise<void> {
    const url = this.Urls["api:room:tasks_v2_bulk-archive"]();
    return this.post(url, {
      task_ids: taskUids,
    });
  }

  public restoreTasksV2(taskUids: string[]): Promise<Task[]> {
    const url = this.Urls["api:room:tasks_v2_bulk-restore"]();
    return this.post(url, {
      task_ids: taskUids,
    });
  }

  public updateTaskLastVisitDate(
    id: Task["id"],
  ): Promise<Pick<Task, "last_visit_date">> {
    const url = this.Urls["api:room:task_update_last_visit_date"](id);
    return this.post(url);
  }

  public loadComments(taskId: TaskComment["task_id"]): Promise<TaskComment[]> {
    const url = this.Urls["api:room:task_comments"](taskId);
    return this.get(url);
  }

  public addComment(
    taskId: TaskComment["task_id"],
    comment: ITaskCommentDraft,
  ): Promise<TaskComment> {
    const url = this.Urls["api:room:task_comments"](taskId);
    return this.post(url, comment);
  }

  public approveComment(
    id: TaskComment["id"],
    taskId: TaskComment["task_id"],
    userId: NonNullable<TaskComment["approved_by"]>,
  ): Promise<TaskComment> {
    const url = this.Urls["api:room:task_comment_detail"](taskId, id);
    return this.post(url, { approved_by: userId });
  }

  public updateComment(
    id: TaskComment["id"],
    taskId: TaskComment["task_id"],
    payload: ITaskCommentDraft,
  ): Promise<TaskComment> {
    const url = this.Urls["api:room:task_comment_detail"](taskId, id);
    return this.post(url, payload);
  }

  public deleteComment(
    commentId: TaskComment["id"],
    taskId: TaskComment["task_id"],
  ): Promise<TaskComment> {
    const url = this.Urls["api:room:task_comment_detail"](taskId, commentId);
    return this.post(url, { is_archived: true });
  }

  public updateDependencies(
    taskId: number,
    add: { task_id: number }[] = [],
    remove: { task_id: number }[] = [],
  ): Promise<Task> {
    const url = this.Urls["api:room:tasks_v2-detail-dependencies-bulk"](taskId);
    return this.post(url, { add, remove });
  }

  public updateAssignees(
    taskId: number,
    add: { user_id: string }[],
    remove: { user_id: string }[],
  ): Promise<Task> {
    const url = this.Urls["api:room:tasks_v2-detail-assignees-bulk"](taskId);
    return this.post(url, { add, remove });
  }

  public updateReviewers(
    taskId: number,
    add: { user_id: string }[],
    remove: { user_id: string }[],
  ): Promise<Task> {
    const url = this.Urls["api:room:tasks_v2-detail-reviewers-bulk"](taskId);
    return this.post(url, { add, remove });
  }

  public updateFollowers(
    taskId: number,
    add: { user_id: string }[],
    remove: { user_id: string }[],
  ): Promise<Task> {
    const url = this.Urls["api:room:tasks_v2-detail-followers-bulk"](taskId);
    return this.post(url, { add, remove });
  }

  public updateDocuments(
    taskId: number,
    add: { document_id: string }[],
    remove: { document_id: string }[],
  ): Promise<Task> {
    const url = this.Urls["api:room:tasks_v2-detail-documents-bulk"](taskId);
    return this.post(url, { add, remove });
  }

  public updateFolders(
    taskId: number,
    add: { folder_id: string }[],
    remove: { folder_id: string }[],
  ): Promise<Task> {
    const url = this.Urls["api:room:tasks_v2-detail-folders-bulk"](taskId);
    return this.post(url, { add, remove });
  }

  public updateLabels(
    taskId: number,
    add: { label_key_id: number }[],
    remove: { label_key_id: number }[],
  ): Promise<Task> {
    const url = this.Urls["api:room:tasks_v2-detail-labels-bulk"](taskId);
    return this.post(url, { add, remove });
  }

  // FIXME: this method doesn't follow the add/remove pattern, since task api doesn't implement respective method
  public unlinkFinding(taskId: string, findingId: string) {
    const url = this.Urls["api:room:findings-detail-tasks-bulk"](findingId);
    return this.post(url, { remove: [{ task_uid: taskId }] });
  }

  public getDocumentDownloadUrl(documentId: string): string {
    return this.Urls["api:room:document_download"](documentId);
  }

  public setIsReviewed(taskId: number, isReviewed: boolean): Promise<Task> {
    const url =
      this.Urls["api:room:tasks_v2-detail-toggle-is-reviewed"](taskId);
    return this.patch(url, { is_reviewed: isReviewed });
  }

  public loadActivity(taskId: Task["id"]) {
    const url = this.Urls["api:room:task_activity"](taskId);
    return this.get<TaskActivity[]>(url);
  }

  public remindTaskParticipant(taskId: Task["id"], payload: ParticipantUserV1) {
    const url = this.Urls["api:room:task_user_remind"](taskId);
    return this.post<void>(url, payload);
  }

  /**
   * Bulk update assignees for specified tasks.
   * @param task_ids  Task uids list
   * @param user_ids 	List of uids of users which can be assigned to the task
   * @param overwrite_existing
   * @returns
   */
  public bulkUpdateAssignees(
    task_ids: Task["uid"][],
    user_ids: UserUid["user_id"][],
    overwrite_existing = false,
  ): Promise<Task[]> {
    const url = this.Urls["api:room:tasks_v2_bulk-assignees"]();
    return this.post(url, { task_ids, user_ids, overwrite_existing });
  }

  /**
   * Bulk update reviewers for specified tasks.
   * @param task_ids 	Task uids list
   * @param user_ids 	List of uids of users which can be assigned to the task as reviewers
   * @param overwrite_existing
   * @returns
   */
  public bulkUpdateReviewers(
    task_ids: Task["uid"][],
    user_ids: UserUid["user_id"][],
    overwrite_existing = false,
  ): Promise<Task[]> {
    const url = this.Urls["api:room:tasks_v2_bulk-reviewers"]();
    return this.post(url, { task_ids, user_ids, overwrite_existing });
  }

  /**
   * Bulk update followers for specified tasks.
   * @param task_ids 	Task uids list
   * @param user_ids 	List of uids of users which can be assigned to the task as followers
   * @param overwrite_existing
   * @returns
   */
  public bulkUpdateFollowers(
    task_ids: Task["uid"][],
    user_ids: UserUid["user_id"][],
    overwrite_existing = false,
  ): Promise<Task[]> {
    const url = this.Urls["api:room:tasks_v2_bulk-followers"]();
    return this.post(url, { task_ids, user_ids, overwrite_existing });
  }

  /**
   * Bulk remind participant for specified tasks.
   * @param task_ids 	Task uids list
   * @param user_ids 	List of uids of users which need be reminded
   * @returns
   */
  public bulkRemindParticipants(
    task_ids: Task["uid"][],
    user_ids: UserUid["user_id"][],
  ): Promise<Task[]> {
    const url = this.Urls["api:room:tasks_v2_bulk-remind"]();
    return this.post(url, { task_ids, user_ids });
  }

  /**
   * Bulk update priority for specified tasks.
   * @param task_ids 	Task uids list
   * @param priority
   * @returns
   */
  public bulkUpdatePriority(
    task_ids: Task["uid"][],
    priority: TaskPriority,
  ): Promise<Task[]> {
    const url = this.Urls["api:room:tasks_v2_bulk-priority"]();
    return this.post(url, { task_ids, priority });
  }

  /**
   * Bulk update status for specified tasks.
   * @param task_ids 	  Task uids list
   * @param status_id   Selected status id
   * @returns
   */
  public bulkUpdateStatus(
    task_ids: Task["uid"][],
    status_id: Task["status_id"],
  ): Promise<Task[]> {
    const url = this.Urls["api:room:tasks_v2_bulk-status"]();
    return this.post(url, { task_ids, status_id });
  }

  /**
   * Move multiple tasks into specified category.
   *
   * @param task_ids - An array of task IDs to be moved.
   * @param category_id - The ID of the new category to move the tasks to.
   * @returns A promise that resolves to an array of tasks after the move operation.
   */
  public bulkMove(
    task_ids: Task["uid"][],
    category_id: Category["uid"],
  ): Promise<Task[]> {
    const url = this.Urls["api:room:tasks_v2_bulk-move"]();
    return this.post(url, { task_ids, category_id });
  }

  /**
   * Bulk update labels for specified tasks.
   * @param task_ids 	          Task uids list
   * @param labels_ids 	        Task labels uids list
   * @param overwrite_existing  Overwrite existing labels
   * @returns A promise that resolves to an array of updated tasks.
   */
  public bulkUpdateLabels(
    task_ids: Task["uid"][],
    label_ids: TaskLabel["uid"][],
    overwrite_existing = false,
  ): Promise<Task[]> {
    const url = this.Urls["api:room:tasks_v2_bulk-labels"]();
    return this.post(url, { task_ids, label_ids, overwrite_existing });
  }

  /**
   * Copy multiple tasks into specified category.
   *
   * @param task_ids - An array of task IDs to be copied.
   * @param category_id - The ID of the new category to copy the tasks to.
   * @returns A promise that resolves to an array of tasks after the copy operation.
   */
  public bulkCopy(
    task_ids: Task["uid"][],
    category_id: Category["uid"],
  ): Promise<Task[]> {
    const url = this.Urls["api:room:tasks_v2_bulk-copy"]();
    return this.post(url, { task_ids, category_id });
  }

  /**
   * Archives multiple tasks.
   *
   * @param task_ids  An array of task UIDs to be archived.
   * @returns A promise that resolves when the tasks are successfully archived.
   */
  public bulkArchive(task_ids: Task["uid"][]): Promise<void> {
    const url = this.Urls["api:room:tasks_v2_bulk-archive"]();
    return this.post(url, { task_ids });
  }

  /**
   * Restore multiple tasks.
   *
   * @param task_ids  An array of task UIDs to be restored.
   * @returns A promise that resolves to an array of tasks wich successfully restored.
   */
  public bulkRestore(task_ids: Task["uid"][]) {
    const url = this.Urls["api:room:tasks_v2_bulk-restore"]();
    return this.post<Task[]>(url, { task_ids });
  }

  public bulkAttachments(taskId: number, request: BulkAttachemntsRequest) {
    const url = this.Urls["api:room:task_attachments_bulk"](taskId);
    return this.post<BulkAttachemntsResposne>(url, request);
  }

  public bulkExport(task_ids: Task["uid"][], fmt: "pdf" | "xlsx") {
    const url = this.Urls["api:room:tasks_v2_bulk-export"]();
    return this.postAndGetFile(url, {
      task_ids,
      fmt,
    });
  }
}
