<template>
  <DrPopup :visible="visibility">
    <template #reference>
      <Status
        :status-id="editProps.value"
        @click.stop="editProps.quitEditMode"
      />
    </template>
    <template #default>
      <DrPopupSelectOptions
        type="iconsWithCheckbox"
        :items="statuses"
        @select="updateValue"
      >
        <template #icon="{ option }">
          <DrIconStatus :color="option.color" />
        </template>
      </DrPopupSelectOptions>
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { computed } from "vue";
import DrIconStatus from "@shared/ui/dr-icon-status";
import { DrPopup, DrPopupSelectOptions } from "@shared/ui/dr-popups";

import { pinia } from "@drVue/store/pinia";
import { useFindingsStatusesStore } from "@drVue/store/pinia/pipeline/findings-statuses";
import Status from "../../components/FindingStatus.vue";
import { useVisibility } from "../use-visibilty";

import type { FindingsTableRow } from "../../types";
import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";

interface Props {
  editProps: EditFieldProps;
}

const props = defineProps<Props>();

const findingsStatusesStore = useFindingsStatusesStore(pinia);
const visibility = useVisibility();

// eslint-disable-next-line vue/no-setup-props-destructure
const initialValue = props.editProps.value;

const statuses = computed(() =>
  findingsStatusesStore.list.map(({ id, name, color }) => ({
    id,
    name,
    color,
    isSelected: id === props.editProps.value,
  })),
);

const updateValue = (value: FindingsTableRow["status_id"]) => {
  if (initialValue === value) {
    props.editProps.quitEditMode();
    return;
  }

  props.editProps.veeField.onChange(value);
  props.editProps.submitField();
};
</script>
