import "./base";
import "./dates-range";
import "./export";
import "./pgroups";
import "./priorities";
import "./requests-lists";
import "./statuses";

angular
  .module("dealroom.analytics.dropdown", ["dealroom.analytics.dropdown.base"])
  .service(
    "AnalyticsDropdownPromisedItemsService",
    PromisedItemsDropdownService,
  );

const template = `
  <dr-analytics-dropdown
    multiselect="$ctrl.multiselect"
    type="{{::$ctrl.type}}"
    label="{{::$ctrl.label}}"
    uid="{{::$ctrl.uid}}"
    handle-select="::$ctrl.handleSelect"
    items="::$ctrl.items"
    ></dr-analytics-dropdown>
`;

PromisedItemsDropdownService.$inject = ["$compile", "$rootScope"];
function PromisedItemsDropdownService($compile, $rootScope) {
  return {
    render: (promise) => promise.then(render),
  };

  function render({ ctrl, $element }) {
    if (!ctrl.items) {
      return ctrl.handleSelect();
    }
    const scope = $rootScope.$new(true);
    scope.$ctrl = ctrl;
    const el = $compile(template)(scope);
    $element.append(el);
  }
}
