<template>
  <div :class="{ [$style.date]: true, [$style.date_isOverdue]: isOverdue }">
    {{ dueDateString }}
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import { drUserTime } from "@drVue/filters/drUserTime";

interface Props {
  dueDate: Date;
  isOverdue: boolean;
}

const props = defineProps<Props>();

const dueDateString = computed(() => drUserTime(props.dueDate, "full-dt"));
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.date {
  font: typography.$body_regular;
  color: colors.$pr-900;
}

.date_isOverdue {
  color: colors.$ad-error;
}
</style>
