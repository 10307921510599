import { ApiService } from "@drVue/api-service";
import { $notifyDanger, $notifyInfo } from "@drVue/common";

export enum FeedbackTypeName {
  Activate = "activate",
  RequestRoom = "request-room",
  General = "general",
  Suggestion = "suggestion",
  Problem = "problem",
  Question = "question",
}

export interface FeedbackModel {
  type_name: FeedbackTypeName;
  description?: string;
}

export class FeedbackApiService extends ApiService {
  private postFeedback(data: FeedbackModel, successMsg?: string) {
    return this.post(this.Urls["feedback"](), data).then(
      (resp) => {
        if (successMsg) {
          $notifyInfo(successMsg);
        }
        return resp;
      },
      (error) => {
        $notifyDanger("Failed to send a message.");
        return Promise.reject(error);
      },
    );
  }

  public activate(dontNotifyUser?: boolean, description?: string) {
    const data: FeedbackModel = {
      type_name: FeedbackTypeName.Activate,
      description,
    };
    let msg =
      "Thank you for your message. Account executives will contact you shortly";
    if (dontNotifyUser) {
      msg = "";
    }
    return this.postFeedback(data, msg);
  }

  public requestNewRoom() {
    const data = { type_name: FeedbackTypeName.RequestRoom };
    const msg =
      "Your request has been sent!. Account executives will contact you shortly.";
    return this.postFeedback(data, msg);
  }
}
