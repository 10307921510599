import type { State } from "./state";
import type { RootState } from "./types";
import type { GetterTree } from "vuex";

export const getters: GetterTree<State, RootState> = {
  getRoomEndDateFieldKey:
    (state, storeGetters, rootState, rootGetters) => (): string | undefined => {
      const fieldId = state.room_end_field;
      if (!fieldId) {
        return;
      }
      const field = rootGetters["clientDashboard/customFields/byId"](fieldId);

      return field ? field.key : undefined;
    },
};
