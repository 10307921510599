<template>
  <div v-loading="!ready" :class="$style.container">
    <CommentComposer
      ref="composerRef"
      :task-id="taskId"
      :task-category-id="taskCategoryId"
      :can-manage-permissions="hasManagerAccess"
      @submit-success="$emit('refresh-list')"
    />
    <ElScrollbar :max-height="MAX_HEIGHT">
      <ul v-for="date in Object.keys(itemDateGroupsMap)" :key="date">
        <li :class="$style.sticker">
          <span :class="$style.date">
            {{ date }}
          </span>
          <div :class="$style.divider" />
        </li>
        <li v-for="item in itemDateGroupsMap[date]" :key="item.id">
          <CommentItem
            :item="item"
            :last-visit-date="lastVisitDate"
            @approved="$emit('refresh-list')"
            @deleted="$emit('refresh-list')"
          />
        </li>
      </ul>
    </ElScrollbar>
  </div>
</template>

<script setup lang="ts">
import { isToday } from "date-fns";
import { groupBy } from "lodash-es";
import { computed, onUnmounted, ref, unref, watch } from "vue";

import { drUserTime } from "@drVue/filters/drUserTime";
import CommentComposer from "./CommentComposer.vue";
import CommentItem from "./CommentItem.vue";

import type { TaskComment } from "@drVue/store/modules/room/types";

interface Props {
  hasManagerAccess: boolean;
  ready: boolean;
  items: TaskComment[];
  taskId: number;
  taskCategoryId: number;
  lastVisitDate: Date | null;
}

const props = defineProps<Props>();

const emit = defineEmits(["refresh-list", "update-last-visit"]);

const MAX_HEIGHT = 512;

const itemDateGroupsMap = computed(() => {
  const group = groupBy(props.items, (d) => {
    const date = drUserTime(d.date_added, "short-date");
    return isToday(d.date_added) ? `Today - ${date}` : date;
  });
  if (!props.hasManagerAccess) {
    // TODO: probably refactor
    Object.keys(group).forEach((key) => {
      group[key] = group[key].filter(({ need_approve }) => !need_approve);
    });
    // delete groups that become empty after filtering above
    Object.keys(group).forEach((key) => {
      if (!group[key].length) delete group[key];
    });
  }
  return group;
});

onUnmounted(() => {
  emit("update-last-visit");
});

const composerRef = ref<InstanceType<typeof CommentComposer> | null>(null);
// Due to `items` being fetched, they're empty on mount
// So, we're setting a watcher to capture a moment when they're present
// And setting a flag to avoid calling `focus` excessively
const hasComposerBeenFocused = ref(false);
watch(
  () => props.items,
  (value) => {
    if (!Object.keys(value).length && !hasComposerBeenFocused.value) {
      unref(composerRef)?.focus();
    }
  },
);
defineExpose({ composer: composerRef });
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography.scss";

.container {
  padding: 12px;
  width: 550px;
  :global(.el-loading-mask) {
    border-radius: 10px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
}

.composer {
  margin-bottom: 12px;
}

.sticker {
  top: 0;
  position: sticky;
  display: flex;
  background-color: white;
  font: typography.$caption_regular;
  color: colors.$pr-500;
  z-index: 1;
  height: 25px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: colors.$pr-150;
  transform: translateY(10px);
}

.date {
  margin-right: 7px;
  white-space: nowrap;
}
</style>
