<template>
  <CategoryCreateDialog
    ref="dialogRef"
    @added="$emit('added', $event)"
    @hide="$emit('hide')"
  />
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

import CategoryCreateDialog from "@drVue/components/room/tasks/shared/CategoryCreateOrRenameDialog.vue";

import type { Category } from "@drVue/store/pinia/room/categories";

interface Props {
  shown: boolean;
}

interface Emits {
  (event: "added", category: Category): void;
  (event: "hide"): void;
}

const props = defineProps<Props>();
defineEmits<Emits>();

const dialogRef = ref<InstanceType<typeof CategoryCreateDialog>>();

const showDialog = () => {
  const reference = document.querySelector("#createNewCategoryPlusBtn");
  if (reference === null) return;

  dialogRef.value?.show(reference as HTMLElement, {
    type: "create",
  });
};

watch(
  () => props.shown,
  (shown) => {
    if (shown) showDialog();
  },
);
</script>
