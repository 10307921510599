import { isValid } from "date-fns";

import { numberFormat } from "@app/vue/utils/number";
import { FieldItemTypes } from "@drVue/api-service/client-dashboard/enums";
import { DateType, deserializeDrDate } from "@drVue/api-service/parse";
import { isStringContains } from "@drVue/common";
import { drUserTime } from "@drVue/filters/drUserTime";

import type { ExtraFieldFormatted } from "./types";
import type { FieldItem } from "@drVue/api-service/client-dashboard/types";

function isValueEmpty(value: any) {
  // non empty array, or 0 are valid values
  return !(
    (!!value || value === 0 || value === null) &&
    (!Array.isArray(value) || value.length !== 0)
  );
}

export function formatFields(
  allFields: FieldItem[],
  customData: { [key: string]: any },
  ignoreEmptyValues: boolean = false,
): ExtraFieldFormatted[] {
  const emptyValue = ignoreEmptyValues ? null : "-";

  return allFields.reduce((bucket: ExtraFieldFormatted[], field: FieldItem) => {
    const value = formatFieldValue(field, customData[field.key], emptyValue);
    if (ignoreEmptyValues && value === null) {
      return bucket;
    }
    let option;
    if ("extra" in field && "select_options" in field.extra) {
      option = field.extra.select_options.find(({ label }) => label === value);
    }
    const item = {
      key: field.key,
      label: field.label,
      value: value as string,
      color: option?.color ?? null,
    };
    bucket.push(item);

    return bucket;
  }, []);
}

export function formatFieldValue(
  field: FieldItem,
  rawValue: any,
  emptyValue: string | null = "-",
): string | null {
  const parsedValue = parseFieldValue(field, rawValue);
  if (parsedValue === undefined) {
    return emptyValue;
  } else if (field.field_type === FieldItemTypes.Date) {
    return drUserTime(parsedValue, "full-date");
  } else if (field.field_type === FieldItemTypes.MultiSelect) {
    return parsedValue.join(", ");
  } else if (field.field_type === FieldItemTypes.Number) {
    return numberFormat(
      parsedValue,
      field.extra?.symbol ?? "",
      field.extra?.digit_grouping ?? "space",
    );
  }
  return parsedValue;
}

export function hasCustomDataActiveFilter(
  filters: { [key: string]: any },
  allFields: FieldItem[],
): number {
  return allFields.filter((field: FieldItem) => {
    const fieldFilter = filters[field.key];
    return fieldFilter && !isValueEmpty(fieldFilter.value);
  }).length;
}

export function parseFieldValue(
  field: Pick<FieldItem, "field_type">,
  value: any,
) {
  if (isValueEmpty(value)) return undefined;

  const fieldType = field.field_type;
  if (fieldType === "date") {
    value = deserializeDrDate(DateType.Date, value);

    if (!value || !isValid(value)) {
      return undefined;
    }

    return value;
  }

  return value;
}

function validateOp(field: FieldItem, op: string, allowed: string[]) {
  if (!allowed.includes(op)) {
    throw `Invalid op ${op} for field <${field.field_type}> "${field.key}" `;
  }
}

// TODO: all filtering related stuff to ../filters/TasksUtils.ts

export function isFilterValid(fieldFilter: any) {
  if (!fieldFilter) {
    return false;
  }
  if (!fieldFilter.op) {
    return false;
  }
  return !isValueEmpty(fieldFilter.value);
}

export function cleanUpFilters(filters: { [key: string]: any }) {
  // remove field filters with invalid values
  const cleaned: { [key: string]: any } = {};
  Object.keys(filters).forEach((field: string) => {
    const fieldFilter = filters[field];
    if (isFilterValid(fieldFilter)) {
      cleaned[field] = fieldFilter;
    }
  });
  return cleaned;
}

export function endOfPtzDay(d: Date) {
  // the function expects that d is a Date with 00:00:00:000 at PTZ
  d = new Date(d);
  d.setHours(d.getHours() + 23);
  d.setMinutes(d.getMinutes() + 59);
  d.setSeconds(d.getSeconds() + 59);
  d.setMilliseconds(999);
  return d;
}

export function filterCustomFields(
  allFields: FieldItem[],
  filters: { [key: string]: any },
  customData: { [key: string]: any },
) {
  return allFields.every((field) => {
    const fieldFilter = filters[field.key];
    if (!fieldFilter) {
      return true;
    }
    const filterVal = fieldFilter.value;
    const filterOp = fieldFilter.op;
    const value = parseFieldValue(field, customData[field.key]);
    if (value === undefined) {
      return false;
    }

    switch (field.field_type) {
      case FieldItemTypes.Date: {
        validateOp(field, filterOp, ["RANGE"]);
        const from = filterVal[0];
        const to = filterVal[1];
        return value >= from && value <= to;
      }
      case FieldItemTypes.Number: {
        validateOp(field, filterOp, ["EQ"]);
        return value === parseFloat(filterVal);
      }
      case FieldItemTypes.Select: {
        validateOp(field, filterOp, ["ANY"]);
        return filterVal.includes(value);
      }
      case FieldItemTypes.MultiSelect: {
        validateOp(field, filterOp, ["ALL"]);
        return filterVal.every((val: string) => value.includes(val));
      }
      default: {
        validateOp(field, filterOp, ["CONTAINS"]);
        return isStringContains(filterVal, value);
      }
    }
  });
}
