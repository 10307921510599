<template>
  <DrVxeGrid
    :key="currentView.slug"
    :loading="isLoading"
    :data="tableData"
    :columns="tableColumns.columns"
    :sort-config="tableSortConfig"
    :class="$style.dealsTable"
    @cell-click="openDetails"
    @resizable-change="columnResized"
    @sort-change="sortChanged"
  >
    <template #empty>No deals found</template>
  </DrVxeGrid>
</template>

<script setup lang="ts">
import { orderBy } from "lodash-es";
import { computed } from "vue";
import { useRouter } from "vue-router";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import { FieldItemTypes } from "@drVue/api-service/client-dashboard";
import DrStore from "@drVue/store";
import { getSortValue } from "@drVue/store/modules/client-dashboard/deals/utils";
import TableColumns from "./tableColumns";

import type { DealsTableRow } from "./tableColumns";
import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";
import type { ExtraFieldFormatted } from "@drVue/store/modules/client-dashboard/fields/types";
import type { CustomView } from "@setups/types";
import type { VxeTablePropTypes } from "vxe-table";

const router = useRouter();

const tableColumns = computed(() => {
  return new TableColumns(DrStore);
});

const currentView = computed<CustomView>(() => {
  return DrStore.state.common.customViews.current;
});

const customFieldsMap = computed(() =>
  (
    DrStore.getters["clientDashboard/customFields/byObjectType"](
      "deal",
    ) as FieldItem[]
  ).reduce(
    (acc, field) => {
      acc[field.key] = field;
      return acc;
    },
    {} as Record<FieldItem["key"], FieldItem>,
  ),
);

const phaseById = DrStore.getters["clientDashboard/phases/getPhaseById"];

const tableSortConfig = computed<VxeTablePropTypes.SortConfig<DealsTableRow>>(
  () => {
    const savedDefaultSort = currentView.value.settings
      .tableLayoutSort as VxeTablePropTypes.SortConfigDefaultSort;

    return {
      defaultSort: savedDefaultSort || {
        field: "phase",
        order: "asc",
      },
      trigger: "cell",
      sortMethod: ({ data, sortList }) => {
        const sortItem = sortList[0];
        const { field, order } = sortItem;

        if (!order) return data;

        const customField = customFieldsMap.value[field];

        // to sort deal's "string"/"number" field without value always below
        const lastStringValue =
          order === "asc"
            ? "\uFFFF\uFFFF\uFFFF\uFFFF"
            : "\u0000\u0000\u0000\u0000";
        const lastNumberValue =
          order === "asc" ? Number.MAX_VALUE : Number.MIN_VALUE;

        return orderBy(
          data,
          [
            (deal: DealsTableRow) => {
              let value = deal[field as keyof DealsTableRow];
              let isNumeric = false;

              if (field === "phase") {
                const phase = phaseById(deal.phase);
                value = phase.order;
              }

              if (field === "last_note.date_added") {
                value =
                  deal.last_note?.date_added?.getTime() || lastNumberValue;
              }

              if (
                customField &&
                [FieldItemTypes.Number, FieldItemTypes.Date].includes(
                  customField.field_type,
                )
              ) {
                value = getSortValue(customField, deal.custom_data[field]);
                isNumeric = true;
              }

              if (value === undefined || value === "-") {
                return isNumeric ? lastNumberValue : lastStringValue;
              }
              return value;
            },
          ],
          [order],
        );
      },
    };
  },
);

const formatFields =
  DrStore.getters["clientDashboard/customFields/formattedFields"];

const tableData = computed(() => {
  return deals.value.map((deal: Deal) => {
    const dealRow: DealsTableRow & { [key: string]: any } = {
      id: deal.id,
      uid: deal.uid,
      title: deal.title,
      phase: deal.phase,
      start_date: deal.start_date,
      last_note: deal.last_note,
      custom_data: deal.custom_data,
      room: deal.room,
    };
    /** Original custom fields values are required for correct sorting,
     * so we put the formatted values in the root of the dealRow object */
    formatFields("deal", deal.custom_data).forEach(
      (field: ExtraFieldFormatted) => {
        dealRow[field.key] = field.value;
      },
    );
    return dealRow;
  });
});

const deals = computed<Deal[]>(() => {
  const deals = DrStore.state.clientDashboard.deals.items;
  return DrStore.getters["clientDashboard/filters/filterDeals"](deals);
});

const isLoading = computed(() => {
  return DrStore.state.clientDashboard.deals.isLoading;
});

const openDetails = ({ row }: { row: any }) => {
  const deal = DrStore.getters["clientDashboard/deals/getDealById"](row.id);
  if (deal) {
    router.push(`/deals/details/${deal.uid}`);
  }
};

const sortChanged = ({
  property,
  order,
}: {
  property: string;
  order: string;
}) => {
  DrStore.commit("common/customViews/updateCurrentSettings", {
    tableLayoutSort: { field: property, order },
  });
};
const columnResized = ({ column }: any) => {
  DrStore.commit("common/customViews/updateCurrentSettingsColumns", [
    { field: column.property, width: column.resizeWidth },
  ]);
};
</script>

<style lang="scss" module>
.dealsTable {
  :global(.deal-room-link__title) {
    font-weight: 700;
    font-size: 14px;
  }
}
</style>
