import { Urls } from "@setups/urls";
import { BaseViewerManager } from "./base";

export default class CoolViewerManager extends BaseViewerManager {
  static readonly ID = "cool";
  isRotateSupported = false;
  isFitZoomSupported = false;
  isRedactionSupported = false;

  private mapApi: any = undefined;

  initialize() {
    const viewerUrl =
      window.location.protocol + "//" + window.location.host + "/viewer";
    const urlsrc =
      viewerUrl +
      "/browser/dist/cool.html?" +
      "WOPISrc=" +
      encodeURIComponent(this.doc.wopi_storage_url!) +
      "&permission=view&readOnly=true";

    const form =
      '<form id="loleafletform_viewer" name="loleafletform_viewer" target="loleafletframe_viewer" action="' +
      urlsrc +
      '" method="post">' +
      '<input name="access_token" value="' +
      this.doc.wopi_access_token +
      '" type="hidden"/></form>';

    // 40px trick is required to make new layout work with legacy one
    const frame =
      '<iframe id="loleafletframe_viewer_frame" name= "loleafletframe_viewer" style="width: 100%; height: calc(100% + 39px); margin-top: -39px; display: block;""/>';

    const viewerContainer = $(`#${this.viewerContainerId}`);
    viewerContainer.append(form);
    viewerContainer.append(frame);

    $("#loleafletform_viewer").submit();

    window.document.addEventListener(
      "initializationComplete",
      () => {
        this.onInitializationComplete();
      },
      false,
    );

    this.beforeViewerInitializeCb();
  }

  private onInitializationComplete() {
    this.state.isLoaded = true;
    this.mapApi = (
      document.getElementById("loleafletframe_viewer_frame") as any
    ).contentWindow.L.Map.THIS;
    this.initMapHandlers();
    $("#documentViewer").trigger("onDocumentLoaded");
  }

  private initMapHandlers() {
    const updateZoomLevel = () => {
      this.state.zoomLevel = this.mapApi.getZoomScale(
        this.mapApi.getZoom(),
        this.mapApi.options.zoom,
      );
    };

    const updateCurrentPage = () => {
      if (this.mapApi._docLayer._docType === "text") {
        this.state.currentPage = this.mapApi.getCurrentPageNumber();
      } else {
        this.state.currentPage = this.mapApi.getCurrentPartNumber() + 1;
      }
    };
    const updateTotalPages = () => {
      if (this.mapApi._docLayer._docType === "text") {
        this.state.totalPages = this.mapApi.getNumberOfPages();
      }
      this.state.totalPages = this.mapApi.getNumberOfParts();
    };

    this.mapApi.on("updateparts pagenumberchanged", updateCurrentPage);
    this.mapApi.on("zoomend", updateZoomLevel);
    // initialize current state
    updateCurrentPage();
    updateZoomLevel();
    updateTotalPages();
  }

  goToPage(page: number) {
    if (this.mapApi._docLayer._docType === "text") {
      return this.mapApi.goToPage(page);
    }
    return this.mapApi.setPart(page - 1);
  }

  prevPage() {
    const page = Math.max(1, this.state.currentPage! - 1);
    this.goToPage(page);
  }

  nextPage() {
    const page = Math.min(this.state.totalPages!, this.state.currentPage! + 1);
    this.goToPage(page);
  }

  zoomDocument(zoomIn: boolean) {
    if (zoomIn) {
      if (this.mapApi.getZoom() < this.mapApi.getMaxZoom()) {
        this.mapApi.zoomIn(1);
      }
    } else {
      if (this.mapApi.getZoom() > this.mapApi.getMinZoom()) {
        this.mapApi.zoomOut(1);
      }
    }
  }

  fitWidth() {}
  fitHeight() {}
  rotate() {}

  search(term: string) {
    this.mapApi.search(term);
  }

  setTextSelectionMode(enable: boolean) {}
}
