<template>
  <DrDrawer
    fixed-footer
    title="New value driver"
    submit-btn="Create value driver"
    :custom-class="$style.drawer"
    :shown="shown"
    :is-submitting="isFormSubmitting"
    @submit="submit"
    @close="checkAndHandleClose"
    @cancel="cancel"
  >
    <DrDynamicFormClassic
      ref="formRef"
      :schema="valueDriverFormSchema"
      :entity="valueDriverFormEntity"
      :errors="formErrors"
      @update="handleUpdateValueDriverForm"
      @submit="submit"
    >
      <template #tasks="{ editProps, error, dataTestId }">
        <ElFormItem
          :error="error"
          style="grid-column: 1 / -1"
          :data-testid="dataTestId"
        >
          <TasksField :edit-props="editProps" />
        </ElFormItem>
      </template>
    </DrDynamicFormClassic>

    <ElAlert
      v-if="formErrors.non_field_errors"
      type="error"
      :title="formErrors.non_field_errors"
    />
  </DrDrawer>
</template>

<script lang="ts" setup>
import { ElMessageBox } from "element-plus";
import { cloneDeep } from "lodash-es";
import { set } from "lodash-es";
import { computed, reactive, ref, useCssModule } from "vue";
import { DrDrawer } from "@shared/ui/dr-drawer";
import { DrDynamicFormClassic } from "@shared/ui/dr-dynamic-form";
import TasksField from "@shared/ui/dr-dynamic-form/fields/Tasks.vue";
import { validateString } from "@shared/ui/dr-dynamic-form/utils";
import { useFormHelper } from "@shared/ui/dr-form";

import { insightTrack, RoomSynergiesEvent } from "@app/insight";
import { $notifySuccess } from "@drVue/common";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import { useSynergiesStore } from "@drVue/store/modules/room/synergies/synergies";
import { useValueDriversStore } from "@drVue/store/modules/room/synergies/value-drivers";
import { pinia } from "@drVue/store/pinia";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";

import type {
  CreateValueDriverPayload,
  Synergy,
} from "@drVue/store/modules/room/synergies/SynergiesApiService";
import type { FormSchema } from "@shared/ui/dr-dynamic-form/types";

export interface ValueDriverCreatePanelOpenParams {
  synergyId?: Synergy["id"];
}

const shown = ref(false);
const openedSynergyId = ref<Synergy["id"]>("");

const $style = useCssModule();

const tasksStore = useTasksStore();
const synergiesStore = useSynergiesStore(pinia);
const valueDriversStore = useValueDriversStore(pinia);

const formRef = ref<InstanceType<typeof DrDynamicFormClassic> | null>(null);

const {
  formErrors,
  hookFormSubmitPromise,
  isFormSubmitting,
  resetErrors,
  resetError,
} = useFormHelper<unknown>();

const valueDriverFormSchema = computed<FormSchema>(() => {
  return [
    {
      type: FieldSchemaType.Text,
      prop: "title",
      label: "Title",
      placeholder: "Specify title",
      rules: validateString().required("Title is required"),
      required: true,
    },
    {
      type: FieldSchemaType.Select,
      prop: "synergy_id",
      label: "Synergy",
      placeholder: "Select synergy",
      extra: {
        select_options: synergiesStore.list.map((s) => ({
          value: s.id,
          label: s.title,
        })),
      },
      rules: validateString().required("Synergy is required"),
      required: true,
    },
    {
      type: FieldSchemaType.Custom,
      prop: "tasks",
      label: "Enabled by",
      required: false,
    },
  ];
});

interface ValueDriverFormModel {
  title: string;
  synergy_id: string;
  tasks: string[];
}

const valueDriverEmptyEntity: ValueDriverFormModel = {
  title: "",
  synergy_id: "",
  tasks: [],
};

const valueDriverFormEntity = reactive<ValueDriverFormModel>(
  cloneDeep(valueDriverEmptyEntity),
);

const handleUpdateValueDriverForm = ({
  field,
  value,
}: {
  field: string;
  value: any;
}) => {
  resetError(field);

  if (field === "tasks") {
    value = value.map((id: number) => tasksStore.tasks[id].uid);
  }

  set(valueDriverFormEntity, field, value);
};

const checkAndHandleClose = () => {
  if (
    valueDriverFormEntity.title === "" &&
    valueDriverFormEntity.synergy_id === openedSynergyId.value
  ) {
    close();
  } else {
    ElMessageBox.confirm(
      "You have unsaved changes made to the new value driver form. Are you sure you wish to discard these changes?",
      "Unsaved changes",
      {
        confirmButtonText: "Discard",
        cancelButtonText: "Cancel",
        customClass: $style.warnMessageBox,
        showClose: false,
      },
    ).then(cancel);
  }
};

const open = (params?: ValueDriverCreatePanelOpenParams) => {
  if (params && params.synergyId) {
    valueDriverFormEntity.synergy_id = params.synergyId;
    openedSynergyId.value = params.synergyId;
  }

  shown.value = true;
};

const close = () => {
  shown.value = false;
};

const cancel = () => {
  close();

  resetErrors();
  Object.assign(valueDriverFormEntity, valueDriverEmptyEntity);
  openedSynergyId.value = "";
  formRef.value?.reset();
};

const submit = async () => {
  if (!formRef.value) return;

  resetErrors();
  const isValid = await formRef.value.validate();
  if (!isValid) return;

  const payload: CreateValueDriverPayload = {
    ...valueDriverFormEntity,
    tasks: valueDriverFormEntity.tasks.map((uid) => ({
      task_uid: uid,
    })),
  };

  const promise = valueDriversStore.create(payload);
  hookFormSubmitPromise(promise).then(() => {
    $notifySuccess("Value driver created");
    insightTrack(RoomSynergiesEvent.ValueDriverCreated);

    cancel();
  });
};

defineExpose({
  open,
  close,
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.drawer {
  // TODO: delete !important after we migrate to Vite
  width: 600px !important;
}

.warnMessageBox {
  &:global(.el-message-box) {
    max-width: 448px;
    border-left: 2px solid colors.$ad-10;
    padding: spacing.$l spacing.$xl spacing.$xl;
  }

  :global {
    .el-message-box__header {
      padding: 0 0 spacing.$xs;
    }

    .el-message-box__title {
      color: colors.$pr-900;
      font: typography.$title_bold;
      border-top: none;
      border-bottom: none;
    }

    .el-message-box__content {
      padding: 0 0 spacing.$xl;
      color: colors.$pr-900;
      font: typography.$body_regular;
    }

    .el-message-box__btns {
      padding: 0;

      .el-button.el-button--primary {
        --el-button-bg-color: #{colors.$ad-10};
        --el-button-border-color: #{colors.$ad-10};
        --el-button-hover-bg-color: #{colors.$ad-10_5};
        --el-button-hover-border-color: #{colors.$ad-10_5};
        --el-button-active-bg-color: #{colors.$ad-10_5};
        --el-button-active-border-color: #{colors.$ad-10_5};
        --el-button-outline-color: transparent;
      }
    }
  }
}

.tiesToHint {
  margin-top: spacing.$xxs;
  padding-top: spacing.$xs;
  display: flex;
  gap: spacing.$xs;
  border-top: solid 1px colors.$pr-150;
  color: colors.$pr-900;
  font-size: 13px;
  line-height: spacing.$xl;
}

.iconLock {
  color: colors.$pr-500;
}
</style>
