<template>
  <!-- @vue-ignore -->
  <Grid
    :ref="setGridRef"
    v-bind="props"
    :class="{
      [$style.table]: true,
      [$style.table_isOutlined]: props.outlined,
    }"
    :header-cell-class-name="({ column }) => (column.order ? 'is--sorted' : '')"
  >
    <template v-for="(_, slot) of $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </Grid>
</template>

<script lang="ts" setup generic="D">
import { Grid } from "vxe-table";

import type { DrVxeTableColumn } from "@drVue/components/types";
import type { VxeGridInstance, VxeTablePropTypes } from "vxe-table";

interface Props {
  // original
  data: D[];
  columns: DrVxeTableColumn<D>[];
  resizable?: VxeTablePropTypes.Resizable;
  showOverflow?: VxeTablePropTypes.ShowOverflow;
  highlightHoverRow?: VxeTablePropTypes.HighlightHoverRow;
  showHeaderOverflow?: VxeTablePropTypes.ShowHeaderOverflow;
  maxHeight?: VxeTablePropTypes.MaxHeight;
  height?: VxeTablePropTypes.Height;
  border?: VxeTablePropTypes.Border;
  sortConfig?: VxeTablePropTypes.SortConfig<D>;
  checkboxConfig?: VxeTablePropTypes.CheckboxConfig<D>;
  tooltipConfig?: VxeTablePropTypes.TooltipConfig<D>;
  rowConfig?: VxeTablePropTypes.RowConfig;
  menuConfig?: VxeTablePropTypes.MenuConfig;
  scrollY?: VxeTablePropTypes.ScrollY;
  rowClassName?: VxeTablePropTypes.RowClassName<D>;
  editConfig?: VxeTablePropTypes.EditConfig<D>;
  mergeCells?: VxeTablePropTypes.MergeCells<D>;
  spanMethod?: VxeTablePropTypes.SpanMethod<D>;
  footerMethod?: VxeTablePropTypes.FooterMethod<D>;
  cellStyle?: VxeTablePropTypes.CellStyle<D>;
  keepSource?: VxeTablePropTypes.KeepSource;
  loading?: VxeTablePropTypes.Loading;
  showFooter?: VxeTablePropTypes.ShowFooter;
  emptyText?: VxeTablePropTypes.EmptyText;
  // custom
  setGridRef?: (ref: VxeGridInstance) => void;
  outlined?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  resizable: true,
  showOverflow: true,
  highlightHoverRow: true,
  showHeaderOverflow: true,
  maxHeight: "100%",
  height: "100%",
  border: "full",
  sortConfig: () => ({ trigger: "cell" }),
  checkboxConfig: undefined,
  tooltipConfig: undefined,
  rowConfig: undefined,
  menuConfig: undefined,
  scrollY: () => ({
    enabled: true,
    gt: 0,
  }),
  rowClassName: undefined,
  editConfig: undefined,
  mergeCells: undefined,
  spanMethod: undefined,
  footerMethod: undefined,
  cellStyle: undefined,
  keepSource: false,
  loading: false,
  showFooter: false,
  emptyText: undefined,
  setGridRef: (_ref: VxeGridInstance) => {},
  outlined: false,
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography" as typo;

.table {
  height: 100%;

  :global {
    .vxe-table--render-default
      .is--checked.vxe-cell--checkbox
      .vxe-checkbox--icon,
    .vxe-table--render-default
      .is--indeterminate.vxe-cell--checkbox
      .vxe-checkbox--icon,
    .vxe-table--render-default
      .vxe-cell--checkbox:not(.is--disabled):hover
      .vxe-checkbox--icon {
      color: colors.$sc-600;
    }

    .vxe-cell--checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .vxe-table {
      font: typo.$body_regular;
      color: colors.$pr-800;
    }

    .vxe-table,
    .vxe-grid--table-container,
    .vxe-table--render-wrapper,
    .vxe-table--main-wrapper {
      height: 100%;
    }

    .vxe-table--header-border-line {
      border-color: colors.$pr-100 !important;
    }

    .vxe-table .vxe-table--main-wrapper,
    .vxe-table .vxe-table--fixed-wrapper {
      display: flex;
      flex-direction: column;

      .vxe-table--header-wrapper {
        flex: 0 0 min-content;
        background-color: colors.$pr-50;
      }

      .vxe-table--body-wrapper {
        flex-grow: 1;
      }

      .vxe-table--footer-wrapper {
        font: typo.$caption_semibold;
        color: colors.$pr-800;
        background-color: colors.$pr-50;
      }
    }

    .vxe-table .vxe-table--body {
      border-collapse: collapse;
    }

    .vxe-table.border--full .vxe-table--border-line {
      display: none;
    }

    .vxe-table.border--full .vxe-header--column {
      position: relative;
      height: 32px;
      padding: 0;
      font: typo.$caption_regular;
      color: colors.$pr-500;
      background-color: colors.$pr-50;
    }

    .vxe-table.border--full .vxe-header--column .vxe-cell {
      padding-right: 0;
    }

    .vxe-header--column .vxe-resizable.is--line:before {
      height: 100%;
      background-color: colors.$pr-150;
    }

    .vxe-table.border--full .vxe-header--column,
    .vxe-table.border--full .vxe-body--column {
      background-image: linear-gradient(colors.$pr-100, colors.$pr-100),
        linear-gradient(colors.$pr-100, colors.$pr-100);
      background-repeat: no-repeat;
      background-size:
        1px 100%,
        100% 1px;
      background-position:
        right bottom,
        right bottom;

      &:last-child:not(:first-child) {
        background-size:
          1px 0,
          100% 1px;
      }
    }

    .vxe-table.border--full .vxe-footer--column {
      background-image: linear-gradient(colors.$pr-150, colors.$pr-150);
      background-repeat: no-repeat;
      background-size: 1px 100%;
      background-position: right bottom;

      &:last-child:not(:first-child) {
        background-size: 0 0;
      }
    }

    .vxe-table--render-default .vxe-header--column.is--sortable {
      cursor: pointer;
    }

    .vxe-table .vxe-cell--sort-vertical-layout {
      height: 1em;
    }

    .vxe-header--column .vxe-sort--asc-btn.sort--active,
    .vxe-header--column .vxe-sort--desc-btn.sort--active {
      color: colors.$sc-600;
    }

    .vxe-table--render-default
      .vxe-header--column:not(.is--sorted)
      .vxe-sort--desc-btn,
    .vxe-table--render-default
      .vxe-header--column:not(.is--sorted)
      .vxe-sort--asc-btn,
    .vxe-table--render-default
      .vxe-header--column.is--sorted
      .vxe-sort--desc-btn:not(.sort--active),
    .vxe-table--render-default
      .vxe-header--column.is--sorted
      .vxe-sort--asc-btn:not(.sort--active) {
      display: none;
    }

    .vxe-table--render-default .vxe-header--column.is--sorted {
      font: typo.$caption_semibold;
      color: colors.$pr-900;

      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        width: 100%;
        border-top: solid 1px colors.$sc-500;
      }
    }

    .vxe-header--column.dr-vxe-column--centered .vxe-cell,
    .vxe-body--column.dr-vxe-column--centered .vxe-cell {
      padding: 0;
      text-align: center;
    }

    // Required due the inability to disable ellipsizing when v-scroll is enabled
    .dr-vxe-column--no-ellipsis > .vxe-cell {
      text-overflow: unset !important;
    }

    .vxe-table--render-default .vxe-cell {
      padding: 0 8px;
    }

    .vxe-table--render-default .vxe-body--row {
      cursor: pointer;

      .dr-vxe-cell__content-row-hover-visible {
        visibility: hidden;
      }
      .dr-vxe-cell__content-row-hover-display {
        display: none;
      }

      .fa-bookmark-o {
        opacity: 0;
        transition: opacity 100ms ease-out;
      }

      &.row--hover {
        background-color: colors.$pr-50;

        .dr-vxe-cell__content-row-hover-visible {
          visibility: visible;
        }

        .dr-vxe-cell__content-row-hover-display {
          display: block;
        }

        .dropdown-toggle i {
          opacity: 1;
        }

        .fa-bookmark-o {
          opacity: 1;
        }
      }

      .fa-tasks:hover {
        color: colors.$light-blue;
      }

      &.row--checked {
        background-color: colors.$sc-50;

        &.row--hover {
          background-color: colors.$sc-100;
        }
      }

      &.row--current {
        background-color: colors.$sc-100;
      }

      .dropdown-toggle i {
        color: colors.$dark-gray;
        opacity: 0;
        transition: opacity 100ms ease-out;

        &:hover {
          color: colors.$med-blue;
        }
      }

      .document-information__icon,
      .folder-information__icon {
        margin-right: 4px;
        position: relative;
        vertical-align: text-bottom;
      }
    }

    ::-webkit-scrollbar {
      appearance: none;
      height: 12px;
      width: 12px;

      &-thumb {
        background-color: colors.$pr-150;
        border-radius: 6px;
        box-shadow: inset 0 0 0 2px #fff;

        &:hover,
        &:active {
          background-color: colors.$pr-300;
        }
      }
    }
  }
}

:global(.grabbing)
  :local(.table)
  :global(.vxe-table--render-default .vxe-body--row) {
  user-select: none;
}

.table_isOutlined {
  border: solid 1px colors.$pr-150;
  border-radius: 7px;
  overflow: hidden;
}
</style>
