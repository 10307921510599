<template>
  <table class="dr-qr-code">
    <tbody>
      <tr v-for="(row, rowIndex) in code" :key="rowIndex">
        <td
          v-for="(cell, cellIndex) in row"
          :key="cellIndex"
          :class="cell ? 'black' : 'white'"
        />
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { PropType } from "vue";

export default defineComponent({
  name: "QrCode",
  props: {
    code: { required: true, type: Array as PropType<boolean[][]> },
  },
});
</script>
