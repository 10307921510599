import taskDetail_pathCategoriesHtml from "../../../templates/components/task-detail/path-categories.html?raw";

export default function drTaskPathCategories() {
  return {
    template: taskDetail_pathCategoriesHtml,
    bindings: {
      categoryId: "=",
      bold: "=",
    },
    controller: [
      "$scope",
      "CategoriesService",
      function ($scope, CategoriesService) {
        $scope.Categories = CategoriesService;
        var $ctrl = this;
        $ctrl.$onInit = function () {
          $scope.$watchCollection(
            "[Categories.loading, $ctrl.categoryId]",
            function () {
              if (!CategoriesService.isLoaded()) {
                return;
              }

              setAncestors();
            },
          );
        };

        function setAncestors() {
          var parent = CategoriesService.categories[$ctrl.categoryId];
          $ctrl.ancestors = parent.parents.map(function (c) {
            return c.name;
          });
          $ctrl.ancestors.push(parent.name);
        }
      },
    ],
  };
}
