<template>
  <div
    :class="{
      [$style.icon]: true,
      [$style.xs]: size === 'xs',
      [$style.sm]: size === 'sm',
      [$style.lg]: size === 'lg',
      [$style.xl]: size === 'xl',
      [$style.xxl]: size === 'xxl',
      [$style.icon_isOpen]: color === 'open',
      [$style.icon_isInProgress]: color === 'inprogress',
      [$style.icon_isResolved]: color === 'resolved',
    }"
    :style="styleObject"
  />
</template>

<script setup lang="ts">
/**
 * Status dot icon component.
 */

import { computed } from "vue";

import { hexRegexp } from "./types";

import type { Size } from "../constants";
import type { StatusColor } from "./types";

interface Props {
  /**
   * Status icon's color.
   * The color must be a valid hex value or one of predefined status key.
   */
  color?: StatusColor;
  /**
   * Status icon's size
   */
  size?: Size;
}
const props = withDefaults(defineProps<Props>(), {
  color: "open",
  size: "md",
});

const styleObject = computed(() => {
  if (hexRegexp.test(props.color)) {
    return {
      color: props.color,
    };
  }
  return {};
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

$xs: 14px;
$sm: 16px;
$md: 20px;
$lg: 24px;
$xl: 28px;
$xxl: 36px;
$xs_font-size: 10px;
$sm_font-size: 14px;
$md_font-size: 18px;
$lg_font-size: 22px;
$xl_font-size: 24px;
$xxl_font-size: 30px;

.icon {
  --size: #{$md};
  --font-size: #{$md_font-size};

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: var(--size);
  width: var(--size);
  font-size: var(--font-size);
  color: colors.$pr-400;
}

.icon:before,
.icon:after {
  content: "";
  display: block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: currentColor;
}

.icon:before {
  opacity: 0.24;
}

.icon:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transition: transform 0.2s ease;
  transform-origin: 50%;
  transform: translate(-50%, -50%) scale(50%);
  background-color: currentColor;
}

.icon:hover:after {
  transform: translate(-50%, -50%) scale(100%);
}

.xs {
  --size: #{$xs};
  --font-size: #{$xs_font-size};
}
.sm {
  --size: #{$sm};
  --font-size: #{$sm_font-size};
}
.lg {
  --size: #{$lg};
  --font-size: #{$lg_font-size};
}
.xl {
  --size: #{$xl};
  --font-size: #{$xl_font-size};
}
.xxl {
  --size: #{$xxl};
  --font-size: #{$xxl_font-size};
}

.icon_isOpen {
  color: colors.$pr-400;
}

.icon_isInProgress {
  color: colors.$priority-medium;
}

.icon_isResolved {
  color: colors.$ad-3;
}
</style>
