import { groupsActions } from "./GroupsActions";
import { groupsGetters } from "./GroupsGetters";
import { groupsMutations } from "./GroupsMutations";
import { GroupsState } from "./GroupsState";

import type { RootState } from "@drVue/store/state";
import type { Module } from "vuex";

export { GroupsState } from "./GroupsState";

export const GroupsModule: Module<GroupsState, RootState> = {
  actions: groupsActions,
  mutations: groupsMutations,
  getters: groupsGetters,
  namespaced: true,
  state: new GroupsState(),
};
