import CoolViewerManager from "./CoolViewerManager";
import DummyViewer from "./DummyViewer";
import PrizmdocViewerManager from "./PrizmdocViewerManager";

export const MANAGERS = {
  [PrizmdocViewerManager.ID]: PrizmdocViewerManager,
  [CoolViewerManager.ID]: CoolViewerManager,
  // use dummy implementation for viewer for now
  [DummyViewer.ID]: DummyViewer,
};
