export enum CustomViewLayouts {
  Table = "table",
  Timeline = "timeline",
  Board = "board",
}

export enum CustomViewObjectTypes {
  DataRoom = "data_room",
  Task = "task",
  Deal = "deal",
  GroupPermission = "group_permission",
  ValueDriver = "value_driver",
  ValueDriverPeriodData = "value_driver_period_data",
  User = "user",
  Finding = "finding",
  Synergy = "synergy",
}

export enum CustomViewFiltersOp {
  AND = "AND",
}

export interface CustomViewFiltersField<T = any> {
  op: string;
  value: T;
}

export interface CustomViewColumn {
  field: string;
  width?: number;
  order?: number;
  hidden?: boolean;
  fixed?: "left" | "right";
}

export const ZOOM = {
  FitToScreen: "fit",
  Days: "days",
  Weeks: "weeks",
  Months: "months",
  Quarters: "quarters",
  Years: "years",
} as const;
export type ZoomLevel = (typeof ZOOM)[keyof typeof ZOOM];

export type TableLayoutSortOrder = "asc" | "desc";
export interface TableLayoutSort {
  field: string;
  order: TableLayoutSortOrder;
}

export interface CustomViewSettings {
  columns?: CustomViewColumn[];
  tableLayoutSort?: TableLayoutSort;
  timelineZoom?: ZoomLevel;
}

export type CustomViewsFilterFields<T = void, F = void> = T extends object
  ? {
      [P in keyof T]?: CustomViewFiltersField<F>;
    }
  : {
      [key: string]: CustomViewFiltersField;
    };

export interface CustomViewFilters {
  search: string;
  op: CustomViewFiltersOp;
  fields: CustomViewsFilterFields;
}

export interface CustomView {
  readonly slug: string;
  name: string;
  layout: CustomViewLayouts;
  object_type: CustomViewObjectTypes;
  is_personal: boolean;
  filters: CustomViewFilters;
  settings: CustomViewSettings;
  is_default?: boolean;
}
