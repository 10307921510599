<template>
  <div
    :class="{
      [$style.comment]: true,
      [$style.comment_isNew]: item.is_new,
      [$style.comment_isNeedApprove]: item.need_approve,
    }"
  >
    <DrAvatar :name="item.author.name" :url="item.author.avatar" />
    <div :class="$style.main">
      <div :class="$style.header">
        <a
          v-if="item.author.url"
          target="_blank"
          :href="item.author.url"
          :class="$style.author"
        >
          {{ item.author.name }}
        </a>
        <div v-else :class="$style.author">
          {{ item.author.name }}
        </div>

        <span :class="$style.time">{{ time }}</span>

        <template v-if="editedHint">
          <span :class="$style.dotDivider" />
          <DrTooltip :content="editedHint" placement="top">
            <div :class="$style.editedMark">
              <DrIcon name="pencil" size="sm" :class="$style.editedMarkIcon" />
              <span>Edited</span>
            </div>
          </DrTooltip>
        </template>

        <template v-if="!item.is_public && viewersGroups.length">
          <span :class="$style.dotDivider" />
          <DrCommentsViewersTag
            :comment="item"
            :viewers-groups="viewersGroups"
            :user-group-id="userGroupId"
          />
        </template>

        <div :class="$style.menuBlock">
          <ElDropdown
            v-if="menu.length"
            trigger="click"
            @command="handleMenuCommand"
          >
            <div :class="$style.menu">
              <DrIcon name="ellipsis-hs" size="sm" />
            </div>
            <template #dropdown>
              <ElDropdownMenu>
                <ElDropdownItem
                  v-for="menuItem in menu"
                  :key="menuItem.value"
                  :command="menuItem.value"
                >
                  {{ menuItem.name }}
                </ElDropdownItem>
              </ElDropdownMenu>
            </template>
          </ElDropdown>
        </div>
      </div>

      <div :class="$style.text">
        <slot name="body" v-bind="{ item }">
          <DrEditor
            v-if="item.body"
            :model-value="item.body"
            :mentions="mentions"
            :editable="false"
          />
        </slot>
      </div>

      <div v-if="showApprove" :class="$style.actions">
        <div :class="$style.approveNote">
          <DrTruncatedTextTooltip
            content="Approve comment so it be visible for non-admin groups."
          />
        </div>

        <ElButton
          v-if="canRemove"
          size="small"
          title="Delete comment"
          @click="handleRemove"
        >
          <template #icon>
            <DrIcon name="trash" />
          </template>
        </ElButton>

        <ElButton type="primary" size="small" @click="handleApprove">
          Approve
        </ElButton>
      </div>

      <!-- slot for attachments,... etc -->
      <div v-if="$slots.footer" :class="$style.footer">
        <slot name="footer" v-bind="{ item }" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { isEqual, isToday } from "date-fns";
import { computed } from "vue";
import { DrAvatar } from "@shared/ui/dr-avatar";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrTooltip, DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";
import DrEditor from "@shared/ui/editor";

import { drUserTime } from "@drVue/filters/drUserTime";
import DrCommentsViewersTag from "./DrCommentsViewersTag.vue";

import type { Comment, CommentMentions, CommentViewersGroup } from "./types";

interface Props {
  item: Comment;
  userId: Comment["author"]["id"];
  viewersGroups?: CommentViewersGroup[];
  userGroupId?: CommentViewersGroup["id"];
  hasManagerAccess?: boolean;
  mentions?: CommentMentions;
}

interface Emits {
  (event: "approve", id: Comment["id"]): void;
  (event: "remove", id: Comment["id"]): void;
  (event: "edit", id: Comment["id"]): void;
}

const props = withDefaults(defineProps<Props>(), {
  author: () => ({
    id: "",
    name: "Anonymous",
    avatar: "",
  }),
  viewersGroups: () => [],
  userGroupId: "",
  hasManagerAccess: false,
  mentions: undefined,
});

const emit = defineEmits<Emits>();

const fixTimePostfix = (time: string | null) => {
  if (!time) return time;
  return time.replace("PM", " PM").replace("AM", " AM");
};

const time = computed(() =>
  fixTimePostfix(drUserTime(props.item.date_added, "short-time")),
);

const showApprove = computed(
  () => props.item.need_approve && props.hasManagerAccess,
);
const canRemove = computed(
  () => props.userId === props.item.author.id || props.hasManagerAccess,
);
const canEdit = computed(
  () => props.userId === props.item.author.id && !props.item.approved_by,
);

const editedHint = computed(() => {
  const { date_added, date_modified } = props.item;

  if (!date_modified || isEqual(date_added, date_modified)) {
    return "";
  }

  const date = isToday(date_modified)
    ? "Today"
    : drUserTime(date_modified, "short-date-reverse");
  const time = fixTimePostfix(drUserTime(date_modified, "short-time"));

  return `${date} at ${time}`;
});

const handleApprove = () => {
  emit("approve", props.item.id);
};

const handleRemove = async () => {
  emit("remove", props.item.id);
};

const handleEdit = async () => {
  emit("edit", props.item.id);
};

const MENU = {
  edit: {
    name: "Edit",
    value: "edit",
  },
  remove: {
    name: "Delete",
    value: "remove",
  },
} as const;
type Menu = (typeof MENU)[keyof typeof MENU][];

const menu = computed(() => {
  if (props.item.readonly) {
    return [];
  }

  const items: Menu = [];

  if (canEdit.value) {
    items.push(MENU.edit);
  }

  if (canRemove.value) {
    items.push(MENU.remove);
  }

  return items;
});

const handleMenuCommand = (cmd: keyof typeof MENU | "approve") => {
  switch (cmd) {
    case "approve":
      handleApprove();
      break;
    case "edit":
      handleEdit();
      break;
    case "remove":
      handleRemove();
      break;
  }
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";
@use "@app/styles/scss/values";

.comment {
  display: grid;
  grid-template-columns: 32px auto;
  padding: spacing.$xxs;
  border: 1px solid transparent;
  border-radius: values.$base-border-radius;
  overflow: hidden;

  &:hover {
    background-color: colors.$pr-100;
  }
}

.comment_isNew,
.comment_isNeedApprove {
  padding: spacing.$xs;

  & + & {
    margin-top: spacing.$xs;
  }
}

.comment_isNew {
  border-color: colors.$sc-300;
  background-color: colors.$sc-50;
}

.comment_isNeedApprove {
  border-color: colors.$pr-200;
  background-color: colors.$pr-50;
}

.main {
  min-width: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: spacing.$xxs;
  overflow: hidden;
}

.dotDivider {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: spacing.$xs;

  &:after {
    content: "";
    display: block;
    width: spacing.$xxs;
    height: spacing.$xxs;
    border-radius: 50%;
    background-color: colors.$pr-350;
  }
}

.editedMarkIcon {
  color: colors.$pr-500;
}

.editedMark {
  display: flex;
  gap: 2px;
  color: colors.$pr-400;
  cursor: pointer;

  &:hover,
  &:hover .editedMarkIcon {
    color: colors.$pr-900;
  }
}

.author {
  flex: 0;
  font: typography.$caption_semibold;
  color: colors.$pr-800;
  white-space: nowrap;
}

a.author {
  cursor: pointer;

  &:hover {
    color: colors.$sc-600;
  }
}

.time {
  flex: 0;
  font: typography.$caption_regular;
  color: colors.$pr-500;
  padding-left: spacing.$xxs;
  white-space: nowrap;
}

.menuBlock {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
}

.menu {
  cursor: pointer;
  color: colors.$pr-400;
}

.comment:hover .menuBlock {
  opacity: 1;
}

.text {
  :global(.user-information__icon) {
    display: none;
  }

  :global(.user-information__name::before) {
    content: "@";
    font-size: 14px;
  }

  p {
    font: typography.$body_regular;
    word-break: break-word;
    text-align: left;
  }
}

.actions {
  margin-top: spacing.$xs;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: end;
  gap: spacing.$xs;
  overflow: hidden;
  // prevent crop ElButton in focus state (with outlines)
  margin: -4px;
  padding: 4px;

  :global(.el-button + .el-button) {
    margin-left: 0;
  }
}

.approveNote {
  flex: 1;
  font: typography.$caption_regular;
  color: colors.$pr-500;
  overflow: hidden;
}

.footer {
  margin-top: spacing.$xs;
}
</style>
