import { DrStore } from "@drVue";

angular
  .module("dealroom.analytics.service.data.categories.processor", [
    "dealroom.analytics.service.data.categories.tasks-counter",

    "dealroom.analytics.service.data.loader",
    "dealroom.analytics.service.data.dispatcher",
    "dealroom.analytics.service.data.api",

    "dealroom.task",
    "dealroom.members",
  ])
  .service("ActivityCategoriesService", ActivityCategoriesService);

ActivityCategoriesService.$inject = [
  "$q",
  "ActivityCategoriesTaskCounterService",
  "CategoriesService",
  "PermissionsService",
  "TasksService",
  "ActivityRoomServiceLoadedPromise",
];
function ActivityCategoriesService(
  $q,
  ActivityCategoriesTaskCounterService,
  CategoriesService,
  PermissionsService,
  TasksService,
  ActivityRoomServiceLoadedPromise,
) {
  const service = {
    loadingPromise: ActivityRoomServiceLoadedPromise.then(processData),

    categories: {},
    // categoriesList: [],

    rootCategoriesStats: {},
    rootCategoriesIds: [],

    attachments: [],

    isPgroupAllowed,
    getCategoryTasks,
    getDocCategories,

    getEmptyStats,
  };

  const categoryToTasks = {};

  return service;

  function processData({ categories }) {
    if (categories === null) return;
    const categoriesList = categories.data;
    service.rootCategoriesIds = [];
    service.categories = {};

    categoriesList.forEach((category) => {
      const isRoot = !category.parent_id;
      if (isRoot && !category.is_archived) {
        service.rootCategoriesIds.push(+category.id);

        service.rootCategoriesStats[category.id] =
          service.getEmptyStats(category);
      }

      service.categories[category.id] = category;
      categoryToTasks[category.id] = [];
    });

    PermissionsService.pgroupsList.forEach((pgroup) => {
      if (pgroup.is_archived) return;
      pgroup.viewable_categories_ids.forEach((categoryId) => {
        if (service.rootCategoriesStats[categoryId] === undefined) return;

        service.rootCategoriesStats[categoryId].pgroupIds.push(+pgroup.id);

        const activeMembers =
          DrStore.getters["room/members/activeDictionaryByGroupId"][pgroup.id];
        activeMembers
          .filter((m) => !m.pending)
          .forEach(function (m) {
            service.rootCategoriesStats[categoryId].membersIds[m.id] =
              pgroup.id;
          });
      });
    });

    TasksService.tasksList.forEach((task) => {
      if (task.is_archived) return;

      const rootCategoryId = CategoriesService.childIdToRoot[task.category_id];
      const rootCategory = service.rootCategoriesStats[rootCategoryId];
      if (!rootCategory) return;

      rootCategory.tasks.add(task);

      DrStore.getters["room/tasksRelated/getTaskAllDocuments"](
        task.id,
      )?.forEach((docData) => {
        const docId = +docData.document_id;

        if (!service.attachments.includes(docId)) {
          service.attachments.push(docId);
          if (!rootCategory.attachments.includes(docId)) {
            rootCategory.attachments.push(docId);
          }
        }
      });

      if (categoryToTasks[task.category_id]) {
        categoryToTasks[task.category_id].push(+task.id);
      }
    });

    return service;
  }

  function getEmptyStats({ id, name } = {}) {
    return {
      id,
      name,
      attachments: [],
      pgroupIds: [],
      membersIds: {},
      tasks: ActivityCategoriesTaskCounterService.get(),
      // taskIds is used in visible stats calculations
      taskIds: [],
    };
  }

  function isPgroupAllowed(categoryId, pgroupId) {
    const rootCategoryId = CategoriesService.childIdToRoot[categoryId];
    const rootCategory = service.rootCategoriesStats[categoryId];
    if (!rootCategory) return false;

    return rootCategory.pgroupIds.includes(+pgroupId);
  }

  function getCategoryTasks(catId) {
    return categoryToTasks[catId] || [];
  }

  function getDocCategories(docId) {
    return service.rootCategoriesIds.filter((catId) => {
      const cat = service.rootCategoriesStats[catId];
      return cat.attachments.includes(docId);
    });
  }
}
