<template>
  <div :class="$style.container">
    <div v-if="!!$slots.icon" :class="$style.icon">
      <slot name="icon" />
    </div>

    <slot />

    <div v-if="!!$slots.action" :class="$style.action">
      <slot name="action" />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.container {
  background-color: colors.$pr-800;
  color: colors.$pr-0;
  border-radius: 6px;
  height: 40px;
  min-height: 40px;
  align-items: center;
  display: flex;
  padding: 0 12px;
}

.icon {
  margin-right: 8px;
}

.action {
  color: colors.$pr-0 !important;
  margin-left: auto;
}
</style>
