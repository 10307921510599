import { DrStore } from "@drVue";

angular
  .module("dealroom.analytics.assignees-distribution.service.stats", [
    "dealroom.analytics.service.colors-scheme",
    "dealroom.task",
  ])
  .service(
    "AnalyticsTasksAssigneeDistributionStatsService",
    AnalyticsTasksAssigneeDistributionStatsService,
  );

AnalyticsTasksAssigneeDistributionStatsService.$inject = [
  "AnalyticsColorsSchemeService",
];
function AnalyticsTasksAssigneeDistributionStatsService(
  AnalyticsColorsSchemeService,
) {
  const tasksStatuses = DrStore.state.room.tasksStatuses;
  const statuses = tasksStatuses.list.reduce((acc, { type, color }) => {
    acc[type] = color;
    return acc;
  }, {});
  return { getEmptyStats, getTaskType };

  function getEmptyStats() {
    const stats = [
      ["noduedate", AnalyticsColorsSchemeService.blue.light, "No Due Date"],
      ["resolved", statuses.resolved, "Resolved"],
      ["overdue", AnalyticsColorsSchemeService.red.normal, "Overdue"],
      ["inprogress", statuses.inprogress, "In Progress"],
    ];
    return stats.reduce((b, [key, color, title]) => {
      b[key] = {
        color,
        title,
        key,
        value: 0,
      };
      return b;
    }, {});
  }

  function getTaskType(task) {
    if (task.status.type === "resolved") return "resolved";
    const dueDate = task.due_date && new Date(task.due_date);
    if (dueDate && dueDate < new Date()) return "overdue";
    if (task.status.type !== "open") return "inprogress";
    return "noduedate";
  }
}
