<template>
  <ElDrawer
    :model-value="visible"
    destroy-on-close
    :append-to-body="true"
    :with-header="false"
    class="el-drawer--entity"
    size=""
    @close="close"
    @closed="handleClosed"
  >
    <ContentPanelClose @close="close()" />

    <!-- Deal details panel -->
    <div v-if="deal" class="room-details-panel">
      <div class="room-details-panel__col room-details-panel__col--left">
        <div :class="$style.sectionWrapper">
          <ElImage
            v-if="deal.room?.logo"
            class="deal-details-logo"
            fit="scale-down"
            :src="deal.room?.logo"
          />
          <div :class="$style.dealTitle" data-testid="deal-title">
            <DynamicForm
              :entity="deal"
              :schema="dealTitleFormSchema"
              :submit-fn="handleSubmitDealTitle"
            />
          </div>

          <div>
            <DealRoomActions
              :deal="deal"
              @no-access="showSelectRoomGroupModal"
              @create-room="$emit('create-room', deal)"
              @deleted="close()"
            />
          </div>

          <div :class="$style.dealPhase">
            <DealPhase :deal-id="deal.id" :disabled="!allowEdit" />
          </div>
        </div>

        <div class="room-details-panel__divider" />

        <div class="room-details-panel__deal-details-scrollable-container">
          <div class="form-title">
            <span>Deal details</span>
            <ElButton
              v-if="allowEdit"
              icon-right
              link
              @click="openDealFormSide"
            >
              Edit all
              <template #icon>
                <DrIcon name="pencil" size="sm" />
              </template>
            </ElButton>
          </div>
          <DealForm :deal="deal" :editable="allowEdit" />
          <DealFormSide
            v-if="allowEdit"
            v-model:shown="isDealFormSideOpened"
            :deal="deal"
          />
        </div>

        <SelectRoomGroupModal
          v-if="deal.room && !deal.room.is_archived"
          ref="selectRoomGroupModal"
          :deal="deal"
        />
      </div>

      <ElScrollbar
        ref="panelCentralBlock"
        height="100%"
        :class="$style.panelCenterBlock"
        :view-class="$style.panelCenterBlockContent"
      >
        <SimpleTabNav
          :tabs="availableTabs"
          :active-tab="state.tab"
          class="room-details-panel__tabs"
          @tab-click="onTabClick"
        />

        <SearchInput
          v-if="[Tabs.Notes, Tabs.Emails].includes(state.tab)"
          v-model="searchQuery"
          class="room-details-panel__search-field"
        />

        <EmailsContainer
          v-if="state.tab === Tabs.Emails"
          entity-type="Deal"
          :entity-id="deal.id"
          class="room-details-panel__section"
          :search-query="searchQuery"
          :editable="allowEdit"
        />

        <NotesList
          v-show="state.tab === Tabs.Notes"
          class="room-details-panel__section"
          :object-type="NoteObjectType.Deal"
          :object-id="deal.id"
          :search-query="searchQuery"
          :editable="allowEdit"
        />

        <PhasesTable
          v-if="state.tab === Tabs.Phases"
          class="room-details-panel__section"
          :deal="deal"
          :editable="allowEdit"
        />

        <DealsTasks
          v-if="state.tab === Tabs.Tasks"
          :deal-id="deal.id"
          @fill-task-form="handleDealTasksFillForm"
        />
      </ElScrollbar>

      <div class="room-details-panel__col room-details-panel__col--right">
        <div class="room-details-panel__section-wrapper">
          <Contacts :deal-id="deal.id" :editable="allowEdit" />
        </div>
        <div class="room-details-panel__section-wrapper">
          <Attachments :editable="allowEdit" :deal="deal" />
        </div>
      </div>
    </div>
    <!-- / Room details panel -->
  </ElDrawer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { string } from "yup";
import { SearchInput, SimpleTabNav } from "@shared/ui";
import ContentPanelClose from "@shared/ui/content-panel/close.vue";
import drFormMixin from "@shared/ui/dr-form/drFormMixin";
import { DrIcon } from "@shared/ui/dr-icon";
import EmailsContainer from "@shared/ui/emails-list/EmailsContainer.vue";

import { ORG_MEMBER_DATA } from "@setups/data";
import NotesList from "@drVue/components/client-dashboard/common/NotesList";
import DealsTasks from "@drVue/components/client-dashboard/deals/DealsTasks/DealsTasks.vue";
import DynamicForm from "@drVue/components/client-dashboard/dynamic-form/DynamicForm.vue";
import EditText from "@drVue/components/client-dashboard/dynamic-form/Fields/Edit/Text.vue";
import {
  FieldSchemaType,
  type FormSchema,
} from "@drVue/components/client-dashboard/dynamic-form/types";
import { NoteObjectType } from "@drVue/store/modules/client-dashboard/notes/types";
import Attachments from "../Attachments/Attachments.vue";
import Contacts from "../Contacts/Contacts.vue";
import DealForm from "../DealForm/DealForm.vue";
import DealFormSide from "../DealForm/DealFormSide.vue";
import SelectRoomGroupModal from "../DealsBoard/SelectRoomGroupModal.vue";
import PhasesTable from "../PhasesTable/PhasesTable.vue";
import TitleViewField from "./components/TitleViewField.vue";
import DealStartDateField from "./CustomFields/View/DealStartDate.vue";
import DealPhase from "./Sections/DealPhase.vue";
import DealRoomActions from "./Sections/DealRoomActions.vue";

import type {
  Deal,
  DealDialogState,
} from "@drVue/store/modules/client-dashboard/deals/types";
import type { ElScrollbar } from "element-plus";

enum Tabs {
  Emails = "emails",
  Notes = "notes",
  Groups = "groups",
  Phases = "phases",
  Tasks = "tasks",
}

interface Data {
  isEditPanelOpen: boolean;
  searchQuery: string;
  NoteObjectType: any;
  isDealFormSideOpened: boolean;
  visible: boolean;
}

export default defineComponent({
  name: "DetailModal",
  components: {
    DealsTasks,
    DynamicForm,
    Attachments,
    Contacts,
    ContentPanelClose,
    DealForm,
    DealFormSide,
    DealPhase,
    EmailsContainer,
    NotesList,
    PhasesTable,
    DealRoomActions,
    SearchInput,
    SelectRoomGroupModal,
    SimpleTabNav,
    DrIcon,
  },
  mixins: [drFormMixin],
  emits: ["create-room", "closed"],
  data(): Data {
    return {
      isEditPanelOpen: false,
      searchQuery: "",
      NoteObjectType: NoteObjectType,
      isDealFormSideOpened: false,
      visible: false,
    };
  },
  computed: {
    dealTitleFormSchema(): FormSchema {
      return {
        fields: [
          {
            type: FieldSchemaType.Custom,
            prop: "title",
            rules: string().required("Deal title is required.").min(3),
            viewComponent: TitleViewField,
            editComponent: EditText,
            isReadOnly: !this.allowEdit,
          },
        ],
      };
    },
    extraViewFields(): any[] {
      if (!this.state.dealUid) return [];

      return [
        {
          key: "dealStartDate",
          component: DealStartDateField,
        },
      ];
    },
    Tabs(): any {
      return Tabs;
    },
    state(): DealDialogState {
      return this.$store.state.clientDashboard.deals.detailsDialog;
    },
    availableTabs(): Tabs[] {
      const tabs = [Tabs.Notes];

      if (ORG_MEMBER_DATA?.client?.enable_outlook) {
        tabs.push(Tabs.Emails);
      }

      tabs.push(Tabs.Phases);

      if (
        ORG_MEMBER_DATA?.client?.enable_dashboard &&
        ORG_MEMBER_DATA?.client?.enable_deal_tasks &&
        ORG_MEMBER_DATA?.group?.can_edit_deals
      ) {
        tabs.push(Tabs.Tasks);
      }

      return tabs;
    },
    deal(): Deal | undefined {
      return (
        this.state.dealUid &&
        this.$store.getters["clientDashboard/deals/getDealByUid"](
          this.state.dealUid,
        )
      );
    },
    allowEdit(): boolean {
      return ORG_MEMBER_DATA.group.can_edit_deals;
    },
  },
  watch: {
    "deal.id": {
      handler(id: Deal["id"]) {
        if (id) {
          this.$store.dispatch("clientDashboard/deals/refreshById", id);
        }
      },
      immediate: true,
    },
    "state.visible": {
      handler(isVisible) {
        this.visible = isVisible;
        if (!isVisible) {
          this.updateState({ tab: this.availableTabs[0] });
        }
      },
      immediate: true,
    },
  },
  methods: {
    close() {
      this.visible = false;
    },
    handleClosed() {
      this.$emit("closed");
    },
    updateState(payload: Partial<DealDialogState>) {
      return this.$store.commit("clientDashboard/deals/detailsDialog", payload);
    },
    onTabClick(tab: string) {
      this.searchQuery = "";
      this.updateState({ tab });
    },
    showSelectRoomGroupModal() {
      const selectRoomGroupModal = this.$refs
        .selectRoomGroupModal as InstanceType<typeof SelectRoomGroupModal>;

      selectRoomGroupModal.open();
    },
    openDealFormSide() {
      this.isDealFormSideOpened = true;
    },
    handleSubmitDealTitle(updates: { title: string }) {
      const deal = this.deal;
      if (!deal) return Promise.reject();

      return this.submitPromise(
        this.$store.dispatch("clientDashboard/deals/patchDeal", {
          dealId: deal.id,
          updates,
        }),
      );
    },
    handleDealTasksFillForm(_isNew: boolean) {
      const panelCentralBlock = this.$refs.panelCentralBlock as InstanceType<
        typeof ElScrollbar
      >;

      if (panelCentralBlock) {
        panelCentralBlock.setScrollTop(0);
      }
    },
  },
});
</script>

<style scoped lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography" as typography;

$left-col-sides-padding: 25px;

.deal-details-logo {
  max-height: 38px;
  max-width: 100%;

  :deep(img) {
    border-radius: 4px;
    max-width: 100%;
    width: auto;
  }
}

// Room details panel
.room-details-panel {
  height: 100%;
  display: flex;
  align-items: stretch;

  &__section {
    margin-bottom: 12px;
  }

  &__deal-title {
    font-size: typography.$font-size-h6;
    font-weight: typography.$font-weight-semi-bold;
  }

  &__col {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    &--left {
      width: 320px;
      padding: 17px $left-col-sides-padding 15px;
      overflow-x: auto;
    }

    &--center {
      flex: 1;
      padding: 25px 25px 15px;
      overflow-x: hidden;
      overflow-y: auto;
      background: colors.$pr-50;
      border-right: 1px solid colors.$pr-200;
      border-left: 1px solid colors.$pr-200;
    }

    &--right {
      width: 300px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &--right .room-details-panel__section-wrapper {
      height: 50%;
      margin-bottom: unset;
      &:first-child {
        border-bottom: 1px solid colors.$pr-200;
      }
    }
  }

  &__tabs {
    min-height: 40px;
    margin-bottom: 20px;
  }

  &__search-field {
    margin-bottom: 15px;
    width: 200px;
  }

  &__deal-details-scrollable-container {
    width: calc(100% + 50px);
    padding: 14px 25px;
    margin: -17px -25px -15px;
    box-sizing: border-box;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__section-wrapper {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
  }

  &__divider {
    height: 1px;
    margin-bottom: 17px;
    position: relative;

    &::before {
      content: "";
      width: calc(100% + 50px);
      height: 0;
      border-bottom: 1px colors.$pr-200 solid;
      position: absolute;
      inset: 0 auto auto -25px;
    }

    &--bigger-bottom-margin {
      margin-bottom: 27px;
    }
  }
}

.form-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: colors.$pr-800;
  margin-bottom: 22px;

  &:deep(.el-button.is-link) {
    --el-button-text-color: #{colors.$sc-600};
    --el-button-icon-color: #{colors.$sc-600};
    --el-button-hover-link-text-color: #{colors.$sc-600};
    --el-button-outline-color: transparent;
  }
}
</style>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.sectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.dealTitle > * {
  padding-bottom: 0;
}

.dealPhase {
  margin: 0 -25px;
  border-top: solid 1px colors.$pr-200;
  padding: 8px 16px;

  :global(.el-dropdown) {
    width: 100%;
  }
}

.panelCenterBlock {
  flex: 1;
  background: colors.$pr-50;
  border-right: 1px solid colors.$pr-200;
  border-left: 1px solid colors.$pr-200;
}

.panelCenterBlockContent {
  padding: 25px 25px 15px;
}
</style>
