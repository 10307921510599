import { postAndGetFile } from "@app/vue/common";
import { ApiService } from "@drVue/api-service";

import type getIconClass from "@app/common/mimetype";
import type { AiReport } from "@app/vue/components/room/ai/types";

export enum DOC_ITEM_TYPES {
  Folder = "folder",
  Document = "file",
}

export interface DocsItem {
  bookmarked: Date | null;
  date_created: Date;
  download: boolean;
  edit: boolean;
  id: number;
  uid: string;
  index: number | string;
  is_archived: boolean;
  is_removed: boolean;
  name: string;
  tree_index: string;
  user_id: number;

  treeIndex: string;
  treeIndexList: string[];
  treePosition: number;
  type: DOC_ITEM_TYPES;

  rank: number;

  custom_data?: Record<string, any>;

  _isChecked?: boolean;
}

export interface Folder extends DocsItem {
  type: DOC_ITEM_TYPES.Folder;

  dateCreated: Date;
  expanded: boolean;
  files: Document[];
  folders: Folder[];
  items: DocsItem[];

  parent_id: number | null;
  parent?: Folder;
  parents: Folder[];

  aggregates: {
    files: number;
    folders: number;
    all: number;
  };
}

export interface DocumentProcessing {
  error: string | null;
  has_thumbnail: boolean;
  is_viewable: boolean;
}

export interface Document extends DocsItem {
  type: DOC_ITEM_TYPES.Document;

  date_updated: Date;
  download_original: boolean;
  error: null;
  folder_id: number;
  has_thumbnail: boolean;
  is_viewable: boolean;
  last_view_date: Date;
  mimetype: string;
  processing?: DocumentProcessing;
  size: number;
  parent: Folder;

  dateCreated: Date;
  dateUpdated: Date;
  iconClass: ReturnType<typeof getIconClass>;
  lastViewDate: Date;
  processingError: string | null;
  thumbnailLargelUrl?: string;
  thumbnailSmallUrl?: string;
  viewUrl: string;

  snippet: string;
}

export interface GetTreeResponse {
  Files: Document[];
  Folders: Folder[];
}

export interface SearchItem {
  id: number;
  rank: number;
  snippet: null | string;
  type: DOC_ITEM_TYPES;
}

export interface DownloadWatermarkedInitializeData {
  task_id: string;
}

export const enum DOWNLOAD_QUEUE_STATUS {
  Pending = "pending",
  Processing = "processing",
  Success = "success",
  Fail = "fail",
}

export interface DownloadWatermarkedStatusData {
  status: DOWNLOAD_QUEUE_STATUS;
}

export type BookmarkItemsPayload = {
  folder_ids: Folder["id"][];
  document_ids: Document["id"][];
  set_bookmarked: boolean;
};

export type ArchiveItemsPayload = {
  folder_ids: Folder["id"][];
  document_ids: Document["id"][];
};

export interface UploadPermissions {
  default_permissions: Record<number, [boolean, boolean, boolean, boolean]>;
  folder_viewable: Record<number, boolean>;
}

export const isFolder = (
  value: Folder | Document | DocsItem,
): value is Folder => value.type === DOC_ITEM_TYPES.Folder;

export const isDocument = (
  value: Folder | Document | DocsItem,
): value is Document => value.type === DOC_ITEM_TYPES.Document;

export class DocumentsApiService extends ApiService {
  public getTree(): Promise<GetTreeResponse> {
    const promises = [
      this.get<DocsItem[]>(this.Urls["api:room:documents_api"]()),
      this.get<DocsItem[]>(this.Urls["api:room:folders_api"]()),
    ];

    return Promise.all(promises).then((values) => ({
      Files: values[0] as Document[],
      Folders: values[1] as Folder[],
    }));
  }

  public searchDocuments(query: string): Promise<SearchItem[]> {
    return this.get(
      `${this.Urls["api:room:documents_search"]()}?query=${query}`,
    );
  }

  public async createDownloadWatermarkedTask(documentId: number) {
    const url = this.Urls["api:room:document_download_initialize"](documentId);
    return this.post<DownloadWatermarkedInitializeData>(url, {});
  }

  public async getDownloadWatermarkedTaskStatus(
    documentId: number,
    downloadTaskId: string,
  ) {
    const checkUrl = this.Urls["api:room:document_download_watermark_status"](
      documentId,
      downloadTaskId,
    );
    return await this.get<DownloadWatermarkedStatusData>(checkUrl);
  }

  public downloadWatermarkedDocUrl(
    documentId: number,
    watermarkTaskId?: string,
  ): string {
    const baseUrl = this.Urls["api:room:document_download"](documentId);
    return watermarkTaskId ? `${baseUrl}?task_id=${watermarkTaskId}` : baseUrl;
  }

  public createFolder(parentId: number, name: string): Promise<Folder> {
    const url = this.Urls["api:room:folder_create"](parentId);
    return this.post(url, { name });
  }

  public renameFolder(folderId: number, name: string): Promise<void> {
    const url = this.Urls["api:room:folder_rename"](folderId);
    return this.post(url, { name });
  }

  public renameDocument(documentId: number, name: string): Promise<void> {
    const url = this.Urls["api:room:document_detail_rename"](documentId);
    return this.post(url, { name });
  }

  public archiveItems(payload: ArchiveItemsPayload) {
    const url = this.Urls["api:room:documents_archive"]();
    return this.post(url, payload);
  }

  public restoreItems(payload: ArchiveItemsPayload) {
    const url = this.Urls["api:room:documents_restore"]();
    return this.post(url, payload);
  }

  public deleteItems(payload: ArchiveItemsPayload) {
    const url = this.Urls["api:room:documents_remove"]();
    return this.post(url, payload);
  }

  public moveFoldersAndDocuments(
    parent_id: number,
    folder_ids: number[],
    document_ids: number[],
  ) {
    const url = this.Urls["api:room:folders_move"]();
    return this.post(url, { parent_id, folder_ids, document_ids });
  }

  public copyFoldersAndDocuments(
    parent_id: number,
    folder_ids: number[],
    document_ids: number[],
  ) {
    const url = this.Urls["api:room:folders_copy"]();
    return this.post(url, { parent_id, folder_ids, document_ids });
  }

  public bulkEditIndex(
    parentId: number,
    documents: Pick<Document, "id" | "index">[],
    folders: Pick<Folder, "id" | "index">[],
  ) {
    const url = this.Urls["api:room:folder_bulk_index"](parentId);
    return this.post(url, { documents, folders });
  }

  public bookmarkItems(payload: BookmarkItemsPayload) {
    const url = this.Urls["api:room:documents_bookmarks"]();
    return this.post<{ folder_ids: []; document_ids: [] }>(url, payload);
  }

  public bulkApplyAiReport(
    document_ids: string[],
    report_type_template_id: AiReport["id"],
  ) {
    const url = this.Urls["api:room:doc-ver-reports-bulk-generate"]();
    return this.post(url, { document_ids, report_type_template_id });
  }

  public exportIndexes(payload: {
    fmt: "pdf" | "xlsx";
    folder_id: Folder["id"];
  }) {
    const url = this.Urls["api:room:folder_export_indexes"]();
    return postAndGetFile(url, payload);
  }

  public getFolderDefaultPermissions(
    folderId: Folder["id"],
  ): Promise<UploadPermissions> {
    const url = this.Urls["api:room:folder_default_permissions"](folderId);
    return this.get(url);
  }
}
