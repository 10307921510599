<template>
  <div :class="$style.container">
    <!-- @vue-ignore -->
    <LabelsEdit :edit-props="editProps" @change="handleChange" />
  </div>
</template>

<script setup lang="ts">
import { insightTrack, TasksTableTrackingEvent } from "@app/insight";
import { LabelsEdit } from "@drVue/components/room/tasks/shared/widgets/labels";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { Task } from "@drVue/store/pinia/room/tasks";
import type { TaskLabel } from "@drVue/store/pinia/room/tasksLabels/tasksLabelApi";

interface Props {
  editProps: EditFieldProps<TaskLabel[], Task>;
}

defineProps<Props>();

const handleChange = () => {
  insightTrack(TasksTableTrackingEvent.LabelsSet);
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.container {
  height: 32px;
  width: 100%;
  padding: 2px 6px;
  border: 1px solid colors.$sc-400;
  border-radius: 6px;
  background-color: colors.$pr-100;
  align-items: center;
  cursor: pointer;
  display: flex;
  font: typography.$body_regular;
  gap: 6px;
  min-height: 32px;
  margin-left: -6px;
}
</style>
