import taskDetail_taskInformationHtml from "../../../templates/components/task-detail/task-information.html?raw";

export default function drTaskInformation() {
  return {
    template: taskDetail_taskInformationHtml,
    bindings: {
      taskId: "<?",
      task: "<?",
      isLink: "<?",
      isHoverable: "<",
      isArchived: "<",
      showPriority: "<",
    },
    controller: [
      "$scope",
      "$state",
      "$window",
      "TasksService",
      "CategoriesService",
      function ($scope, $state, $window, TasksService, CategoriesService) {
        var $ctrl = this;
        $ctrl.Tasks = TasksService;
        $ctrl.Categories = CategoriesService;
        $ctrl.openTaskDetails = openTaskDetails;
        var watch;
        $ctrl.$onInit = function () {
          if (angular.isUndefined($ctrl.task)) {
            watch = $scope.$watch("$ctrl.Tasks.tasksLoading", function () {
              if (!TasksService.isLoaded()) {
                return;
              }

              watch(); // unwatch

              if (TasksService.tasksLoadError) {
                return;
              }

              $ctrl.task = TasksService.tasks[$ctrl.taskId];
            });
          } else {
            watch = $scope.$watch("$ctrl.Categories.loading", function () {
              if (!CategoriesService.isLoaded()) {
                return;
              }
              watch(); // unwatch

              var parent = CategoriesService.categories[$ctrl.task.category_id];
              if (angular.isUndefined(parent)) {
                $ctrl.isLink = false;
                $ctrl.isHoverable = false;
              }
            });
          }
        };

        function openTaskDetails() {
          if ($ctrl.isLink && $ctrl.task) {
            TasksService.openTaskDetails($ctrl.task.key);
          }
        }
      },
    ],
  };
}
