import { groupBy } from "lodash-es";

import { USER_DATA } from "@setups/data";

import type { RoomMember } from "@drVue/store/modules/room/members/RoomMembersApiService";
import type { RoomMembersState } from "@drVue/store/modules/room/members/RoomMembersState";
import type { RootState } from "@drVue/store/state";
import type { GetterTree } from "vuex";

export const roomMembersGetters: GetterTree<RoomMembersState, RootState> = {
  currentUserRoomMember: (state) => {
    return state.membersList.find((member) => member.user_id === USER_DATA.id);
  },

  byUid: (state) => (uId: string) => {
    return state.membersList.find((member) => member.uid === uId);
  },

  activeByGroupId: (state, getters) => (groupId: number) => {
    return getters.activeDictionaryByGroupId[groupId];
  },

  pendingByGroupId: (state, getters) => (groupId: number) => {
    return getters.pendingDictionaryByGroupId[groupId];
  },

  activeDictionaryByGroupId: (state, getters, rootState) => {
    const active = state.activeMembersList;
    const activeGrouped = groupBy(active, "pgroup.id");
    const groupsIds = Object.getOwnPropertyNames(rootState.room.groups.pgroups);

    const result: { [key: string]: RoomMember[] } = {};
    for (const id of groupsIds) {
      result[id] = activeGrouped[id] ? activeGrouped[id] : [];
    }
    return result;
  },

  pendingDictionaryByGroupId: (state, getters, rootState) => {
    const pending = state.activeMembersList.filter((m) => m.pending);
    const pendingGrouped = groupBy(pending, "pgroup.id");
    const groupsIds = Object.getOwnPropertyNames(rootState.room.groups.pgroups);

    const result: { [key: string]: RoomMember[] } = {};
    for (const id of groupsIds) {
      result[id] = pendingGrouped[id] ? pendingGrouped[id] : [];
    }
    return result;
  },
};
