Controller.$inject = [
  "$scope",
  "$element",
  "ActivityFilterService",
  "AnalyticsActivityDataService",
  "ActivityNestService",
  "AnalyticsBurndownTickFactrory",
  "ActivityVisibleCategoriesService",
  "AnalyticsDataDispatcher",
]; /*
  {
    title, key,
    ticks: [{dt, value}],
  };
*/
import "./tick";
import "./total";

import * as d3 from "d3";

angular
  .module("dealroom.analytics.card.burndown.data", [
    "dealroom.analytics.card.burndown.data.tick",
    "dealroom.analytics.card.burndown.data.total",

    "dealroom.analytics.service.filter",
    "dealroom.analytics.service.data",
    "dealroom.analytics.service.nest",

    "dealroom.task",
  ])
  .directive("drAnalyticsTasksBurndownCardData", () => ({
    scope: {
      dispatcher: "<",
    },
    restrict: "A",
    controller: Controller,
  }));

function Controller(
  $scope,
  $element,
  ActivityFilterService,
  AnalyticsActivityDataService,
  ActivityNestService,
  AnalyticsBurndownTickFactrory,
  ActivityVisibleCategoriesService,
  AnalyticsDataDispatcher,
) {
  $scope.dispatcher.add("mkListNest");
  AnalyticsDataDispatcher.on("filtersChanged", processData, $element, $scope);

  function processData() {
    /* we have to start calculations from the current date
         but user can choose a custom date range in dates filter
         so first calc every day from today and then cut other  */
    const filterParams = { includeArchived: true, ignoreEndDateFilter: true };
    let filtered = ActivityFilterService.filter(
      AnalyticsActivityDataService.alldata,
      "requests",
      filterParams,
    );

    let nested = ActivityNestService.nest(
      filtered,
      "rootCategory",
      rollUp,
    ).reduce(nestedToObject, {});

    const data =
      ActivityVisibleCategoriesService.visibleStats.map(nestedToData);

    nested = null;
    filtered = null;

    $scope.dispatcher.call("nested", data);

    function nestedToData(stat) {
      const data = nested[stat.id] || {};
      return {
        key: stat.id,
        ticks: AnalyticsBurndownTickFactrory.fromData(data, stat),
        title: stat.name,
      };
    }
  }

  function rollUp(data) {
    return ActivityNestService.nest(data, "task");
  }

  function nestedToObject(bucket, { key, value }) {
    // key - rootCategoryId, values - nested by taskId
    bucket[key] = value.reduce((bucket, entry) => {
      if (entry.key === "0") return bucket;

      bucket[entry.key] = entry.values;
      return bucket;
    }, {});
    return bucket;
  }
}
