import { DrStore } from "@app/vue";

import type { FieldItem } from "@drVue/api-service/client-dashboard";

export function computeOverlapSize(
  leftEl: HTMLElement,
  rightEl: HTMLElement,
): number {
  return (
    leftEl.getBoundingClientRect().right - rightEl.getBoundingClientRect().left
  );
}

export function getMilestoneLabel(dealFieldKey: string): string | void {
  const customFields: FieldItem[] =
    DrStore.getters["clientDashboard/customFields/byObjectType"]("deal");
  return customFields.find(({ key }) => key === dealFieldKey)?.label;
}
