import type { DrVxeTableColumn } from "@drVue/components/types";
import type { UserRoomFinding } from "@drVue/store/pinia/your-work/room-findings";
import type { CustomViewsFilterFields } from "@setups/types";

export enum ParticipantsType {
  Assignee = "assignee",
  Follower = "follower",
  Owner = "owner",
}

export const YOUR_WORK_FINDINGS_TABLE_FIELDS = [
  "key",
  "type_id",
  "title",
  "status_id",
  "severity",
  "likelihood",
  "room",
] as const;
export type YourWorkFindingsTableField =
  (typeof YOUR_WORK_FINDINGS_TABLE_FIELDS)[number];

export interface YourWorkFindingsTableColumn
  extends DrVxeTableColumn<YourWorkFindingsTableRow> {
  field: YourWorkFindingsTableField;
}

export type YourWorkFindingsTableRow = UserRoomFinding & {
  room: {
    title: string;
    url: string;
    logo: string;
  };
  roles: ParticipantsType[];
};

export type YourWorkFindingsCustomViewsFilters = CustomViewsFilterFields<
  YourWorkFindingsFilters,
  YourWorkFindingsFilters[keyof YourWorkFindingsFilters]
>;

export type YourWorkFindingsFilters = {
  room_id: UserRoomFinding["room_id"][];
  type_id: UserRoomFinding["type_id"][];
  severity: NonNullable<UserRoomFinding["severity"]>[];
  likelihood: NonNullable<UserRoomFinding["likelihood"]>[];
  status_id: UserRoomFinding["status_id"][];
  roles: ParticipantsType[];
};

export type YourWorkFindingsToolbarFiltersUpdate = {
  field: keyof YourWorkFindingsFilters;
  value: YourWorkFindingsFilters[keyof YourWorkFindingsFilters];
};

/**
 * Value matching function:
 * "eq"  - exact matching
 * "in"  - the value being tested must be in the list of allowed values
 * "bt"  - the value being tested must be within the specified range
 *         (between start and end value)
 *
 * @todo place to "common" | "setups"
 */
type FilterValueMatchingFunction = "eq" | "in" | "bt";

export const mapYourWorkFindingsFieldToMatchingFunction: {
  [k in keyof YourWorkFindingsFilters]?: FilterValueMatchingFunction;
} = {
  room_id: "in",
  type_id: "in",
  severity: "in",
  likelihood: "in",
  status_id: "in",
  roles: "in",
};
