import "./date-range";
import "./members";
import "./tasks";

angular
  .module("dealroom.analytics.service.filter", [
    "dealroom.analytics.service.filter.dates",
    "dealroom.analytics.service.filter.members",
    "dealroom.analytics.service.filter.tasks",
    "dealroom.analytics.service.labels",
    "dealroom.documents",
  ])
  .service("ActivityFilterService", ActivityFilterService);

ActivityFilterService.$inject = [
  "ActivityFilterDatesService",
  "ActivityFilterTasksService",
  "ActivityFilterMembersService",
  "ActivityLabelService",
];
function ActivityFilterService(
  ActivityFilterDatesService,
  ActivityFilterTasksService,
  ActivityFilterMembersService,
  ActivityLabelService,
) {
  const service = {
    filter,
  };
  return service;

  function filter(
    batch,
    fname,
    { includeArchived = false, ignoreEndDateFilter = false } = {},
  ) {
    const args = {
      requests: ActivityFilterTasksService.filter,
      documents: filterDocumentsRelated,
      users: filterGeneralActivity,
    };
    const func = args[fname] ? args[fname] : noFilter;
    return batch.filter(f);

    function f(d) {
      if (!ActivityFilterMembersService.isMemberVisible(d)) {
        return false;
      }

      if (
        ActivityFilterDatesService.filters.dateStart !== null &&
        ActivityFilterDatesService.filters.dateEnd !== null
      ) {
        if (
          ActivityFilterDatesService.isInRange(d, ignoreEndDateFilter) === false
        ) {
          return false;
        }
      }

      const item = ActivityLabelService.item(d);
      if (
        item &&
        (item.is_archived === true || item.is_removed === true) &&
        includeArchived == false
      ) {
        return false;
      }

      return func(d, includeArchived);
    }

    function filterDocumentsRelated(d) {
      return /^(document|folder)$/.test((d.target || {}).type);
    }

    function filterGeneralActivity(d, includeArchived) {
      // isTaskVisible return `null` if it is not a task activity
      // and false for hidden tasks
      if (
        ActivityFilterTasksService.isTaskVisible(d, includeArchived) === false
      )
        return false;

      // unfilter activity about changing index during folder creation
      if (
        d.verb === "folder_index" &&
        angular.isDefined(d.content.old_value) &&
        +d.content.old_value === 0
      )
        return false;

      return true;
    }

    function noFilter(d) {
      return true;
    }
  }
}
