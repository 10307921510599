/* tslint:disable:no-magic-numbers */
/* tslint:disable:no-this-assignment */
import { add, endOfDay } from "date-fns";
import { d3 } from "@shared/ui";

import { ZOOM } from "@setups/types";
import { SCROLLBAR_SIZE, TimelineLayout } from "./layout";
import { TimelineTooltip } from "./tooltip";

import type { ZoomLevel } from "@setups/types";

export interface TimelineData {
  id: number;
  from: Date;
  to: Date | null;
}

export interface DataViewport {
  isVisible: boolean;
  offsetFrom: number;
  offsetTo: number;
  visibleWidth: number;
  rightIconShown: boolean;
}

export interface TimelineConfig {
  axisHeight: number;
  rowHeight: number;
  zoomLevel: ZoomLevel;
  showSidebar: boolean;
  sidebarExpandedWidth: number;
  sidebarCollapsedWidth: number;
  sidebarPaddingSides: number;
}

const BASE_CHART_CONFIG: TimelineConfig = {
  axisHeight: 80,
  rowHeight: 60,
  zoomLevel: ZOOM.FitToScreen,
  showSidebar: true,
  sidebarExpandedWidth: 200,
  sidebarCollapsedWidth: 40,
  sidebarPaddingSides: 13,
};

const enum AXIS_LINES {
  Visible = "visible",
  Partial = "partial",
  Hidden = "hidden",
}

interface TickConfigValue {
  topInterval: d3.TimeInterval;
  topFormat: (date: Date) => string;
  topWidth: number;
  bottomInterval: d3.TimeInterval;
  bottomFormat: (date: Date) => string;
  axisLines: {
    top: AXIS_LINES;
    bottom: AXIS_LINES;
  };
  gridInterval: d3.TimeInterval;
}

const TICKS_CONFIG: { [key: string]: TickConfigValue } = {
  [ZOOM.Days]: {
    topInterval: d3.timeMonth,
    topFormat: d3.timeFormat("%B %y"),
    topWidth: 1500,
    bottomInterval: d3.timeDay,
    bottomFormat: d3.timeFormat("%e"),
    axisLines: {
      top: AXIS_LINES.Visible,
      bottom: AXIS_LINES.Visible,
    },
    gridInterval: d3.timeDay,
  },
  [ZOOM.Weeks]: {
    topInterval: d3.timeMonth,
    topFormat: d3.timeFormat("%B %y"),
    topWidth: 500,
    bottomInterval: d3.timeWeek,
    bottomFormat: d3.timeFormat("%e %b"),
    axisLines: {
      top: AXIS_LINES.Partial,
      bottom: AXIS_LINES.Visible,
    },
    gridInterval: d3.timeWeek,
  },
  [ZOOM.Months]: {
    topInterval: d3.timeMonth,
    topFormat: d3.timeFormat("%b %y"),
    topWidth: 150,
    bottomInterval: d3.timeWeek,
    bottomFormat: d3.timeFormat("%e"),
    axisLines: {
      top: AXIS_LINES.Visible,
      bottom: AXIS_LINES.Hidden,
    },
    gridInterval: d3.timeMonth,
  },
  [ZOOM.Quarters]: {
    topInterval: d3.timeMonth.every(3) as any,
    topFormat: d3.timeFormat("Q%q %Y"),
    topWidth: 200,
    bottomInterval: d3.timeMonth,
    bottomFormat: d3.timeFormat("%b"),
    axisLines: {
      top: AXIS_LINES.Visible,
      bottom: AXIS_LINES.Visible,
    },
    gridInterval: d3.timeMonth.every(3) as any,
  },
  [ZOOM.Years]: {
    topInterval: d3.timeYear,
    topFormat: d3.timeFormat("%Y"),
    topWidth: 200,
    bottomInterval: d3.timeMonth.every(3) as any,
    bottomFormat: d3.timeFormat("Q%q"),
    axisLines: {
      top: AXIS_LINES.Visible,
      bottom: AXIS_LINES.Visible,
    },
    gridInterval: d3.timeYear,
  },
};
export type D3Selection<T extends d3.BaseType> = d3.Selection<
  T,
  unknown,
  null,
  undefined
>;

interface DataPos {
  fromX: number;
  toX: number;
  width: number;
  topY: number;
  bottomY: number;
}

export function truncateTextNode(node: any, maxWidth: number) {
  let textLength = node.getComputedTextLength();
  let text = node.textContent;
  while (textLength > maxWidth && text.length > 0) {
    text = text.slice(0, -1);
    node.textContent = text + "...";
    textLength = node.getComputedTextLength();
  }
}

export function barPath(
  pos: DataPos,
  height: number,
  radius: number,
  roundRight: boolean,
) {
  let barW = pos.width;
  const r = Math.floor(Math.min(barW / 2, radius));
  barW -= roundRight ? r * 2 : r;
  const barH = height - r * 2;
  const d = [
    `M 0 ${r}`, // start below top left corner
    `Q 0 0 ${r} 0`, // render top left rounded corner
    `h ${barW}`, // top horizontal line
    roundRight ? `q ${r} 0 ${r} ${r}` : `h ${r} v ${r}`,
    `v ${barH}`, // right vertical  line
    roundRight ? `q 0 ${r} ${-r} ${r}` : `v ${r} h ${-r}`,
    `h ${-barW}`, // bottom horizontal line
    `q ${-r} 0 ${-r} ${-r}`, // render bottom left rounded corner
    `z`,
  ];

  return d.join(" ");
}

export abstract class BaseTimelineChart<T extends TimelineData> {
  protected dispatcher: d3.Dispatch<EventTarget>;
  protected data: T[];
  // protected container: HTMLElement;
  protected config: TimelineConfig;

  protected dataHeight: number;
  protected dataWidth: number;
  protected sidebarWidth: number;
  protected xScale: d3.ScaleTime<number, number>;
  protected tickConfig: TickConfigValue;
  protected dataPositions: { [key: number]: DataPos };

  protected layout: TimelineLayout;
  protected tooltip: TimelineTooltip;
  protected svg: D3Selection<SVGSVGElement>;
  protected grid: D3Selection<SVGGElement>;
  protected rows: D3Selection<SVGGElement>;
  protected header: D3Selection<SVGGElement>;
  protected sidebarData: D3Selection<SVGGElement>;
  protected sidebarHeader: D3Selection<SVGGElement>;
  protected timelineSelection: d3.Selection<
    SVGGElement,
    T,
    SVGElement,
    unknown
  >;

  constructor(
    data: T[],
    container: HTMLElement,
    config: Partial<TimelineConfig>,
  ) {
    this.dispatcher = d3.dispatch("itemClick", "toggleSidebar");
    this.data = data;
    this.config = { ...BASE_CHART_CONFIG, ...config };
    this.sidebarWidth = this.config.showSidebar
      ? this.config.sidebarExpandedWidth
      : this.config.sidebarCollapsedWidth;
    this.layout = new TimelineLayout(
      container,
      this.config.axisHeight,
      this.sidebarWidth,
    );
    this.tooltip = new TimelineTooltip();

    // calc chart dimensions and setup axises
    this.xScale = this.initXScale();
    this.dataHeight = this.data.length * this.config.rowHeight;
    this.tickConfig = this.initTickConfig();
    this.dataWidth =
      (this.xScale
        .nice(this.tickConfig.topInterval as any)
        .ticks(this.tickConfig.topInterval).length -
        1) *
      this.tickConfig.topWidth;
    this.layout.setDataSize(this.dataHeight, this.dataWidth);
    this.dataWidth = Math.max(
      this.dataWidth,
      this.layout.getVisibleDataArea().width,
    );
    this.xScale
      .range([0, this.dataWidth])
      .nice(this.tickConfig.topInterval as any);
    // calculate bars positions
    this.dataPositions = this.calcDataPositions();

    this.svg = this.layout.gridData
      .append("svg")
      .attr("class", "dash-rooms-timeline__d_svg")
      .attr("height", this.dataHeight)
      .attr("width", this.dataWidth);
    this.grid = this.svg.append("g");
    this.rows = this.svg.append("g");
    this.header = this.layout.gridHeader
      .append("svg")
      .attr("width", this.dataWidth)
      .attr("height", this.config.axisHeight)
      .append("g");
    this.sidebarHeader = this.layout.sidebarHeader
      .append("svg")
      .attr("width", this.sidebarWidth)
      .attr("height", this.config.axisHeight)
      .append("g");
    this.sidebarData = this.layout.sidebarData
      .append("svg")
      .attr("width", this.sidebarWidth)
      .attr("height", this.dataHeight)
      .append("g")
      .attr("class", "dash-rooms-timeline__d_sidebar");

    this.renderHeader();
    this.renderGrid();
    this.renderSidebarGrid();
    this.renderSidebarHeader();
    this.timelineSelection = this.renderDataTimeline();

    this.updateVisibleViewport();
    this.layout.on("onScroll", this.updateVisibleViewport.bind(this));
  }

  public getScrollPosition() {
    return this.layout.getScrollPosition();
  }

  public setScrollPosition(top: number, left: number) {
    return this.layout.setScrollPosition(top, left);
  }

  public destroy() {
    this.layout.destroy();
  }

  public on(typenames: string, callback: any) {
    this.dispatcher.on(typenames, callback);
  }

  protected abstract updateVisibleViewport(): void;

  protected abstract renderSidebarData(): void;

  protected renderSidebarHeader() {
    this.renderSidebarBorder(this.sidebarHeader, 0, this.config.axisHeight);
    if (this.config.showSidebar) {
      // bottom border
      this.sidebarHeader
        .append("line")
        .attr("class", "dr-timeline__sidebar-border-line")
        .attr("x1", 0)
        .attr("x2", this.sidebarWidth)
        .attr("y1", this.config.axisHeight)
        .attr("y2", this.config.axisHeight);
    }
    this.renderSidebarHeaderContent();
  }

  protected abstract renderSidebarHeaderContent(): void;

  protected abstract renderDataTimeline(): d3.Selection<
    SVGGElement,
    T,
    SVGElement,
    unknown
  >;

  protected itemClick(item: T) {
    this.dispatcher.call("itemClick", undefined, item);
  }

  protected toggleSidebar() {
    this.dispatcher.call("toggleSidebar", undefined);
  }

  protected getCurrentViewportData(): { [key: number]: DataViewport } {
    const viewport = this.layout.getVisibleDataArea();
    const isItemVisible = (item: T) => {
      return !(
        this.dataPositions[item.id].fromX >= viewport.XTo ||
        this.dataPositions[item.id].toX <= viewport.XFrom ||
        this.dataPositions[item.id].topY >= viewport.YTo ||
        this.dataPositions[item.id].bottomY <= viewport.YFrom
      );
    };
    const data: { [key: number]: DataViewport } = {};
    this.data.forEach((item: T) => {
      const isVisible = isItemVisible(item);
      if (!isVisible) {
        data[item.id] = {
          isVisible,
          offsetFrom: 0,
          offsetTo: 0,
          visibleWidth: 0,
          rightIconShown: false,
        };

        return;
      }
      // abs - from SVG axis
      const absVisibleFrom = Math.max(
        this.dataPositions[item.id].fromX,
        viewport.XFrom,
      );
      const absVisibleTo = Math.min(
        this.dataPositions[item.id].toX,
        viewport.XTo,
      );
      const visibleWidth = absVisibleTo - absVisibleFrom;
      // offset from bar group axis
      const offsetFrom = absVisibleFrom - this.dataPositions[item.id].fromX;
      const offsetTo = offsetFrom + visibleWidth;
      const absTo = this.dataPositions[item.id].toX;
      const rightIconShown = !item.to || absVisibleTo < absTo;

      data[item.id] = {
        isVisible,
        offsetFrom,
        offsetTo,
        visibleWidth,
        rightIconShown,
      };
    });

    return data;
  }

  private initXScale() {
    const domainMin = d3.min(this.data, (d) => d.from) as Date;
    let domainMax = d3.max(this.data, (d) => d.to) as Date;
    const domainFromMax = d3.max(this.data, (d) => d.from) as Date;

    if (!domainMax || domainFromMax > domainMax) {
      // no items with end date
      domainMax = endOfDay(add(domainFromMax, { months: 1 }));
    }

    return d3.scaleTime().domain([domainMin, domainMax]);
  }

  private initTickConfig() {
    const width = this.layout.getVisibleDataArea().width;
    // header xAxis contains top and bottoms axises
    // basically zoom level configures top axis
    let zoomLevel = this.config.zoomLevel;
    if (zoomLevel === ZOOM.FitToScreen) {
      // select the smallest interval that shows full timeline
      // and fits in chart container width without scrolling
      zoomLevel = ZOOM.Years; // the biggest interval as fallback
      for (const level of [ZOOM.Months, ZOOM.Quarters]) {
        const levelTicks = TICKS_CONFIG[level];
        const ticksCount =
          this.xScale
            .nice(levelTicks.topInterval as any)
            .ticks(levelTicks.topInterval).length - 1;
        const fullWidth = SCROLLBAR_SIZE + ticksCount * levelTicks.topWidth;
        if (fullWidth <= width) {
          zoomLevel = level;
          break;
        }
      }
    }

    return TICKS_CONFIG[zoomLevel];
  }

  private calcDataPositions() {
    const positions: { [key: number]: DataPos } = {};
    this.data.forEach((item: T, index) => {
      const fromX = this.xScale(item.from) as number;
      const toX = item.to ? (this.xScale(item.to) as number) : this.dataWidth;
      const width = toX - fromX;
      const topY = this.config.rowHeight * index;
      const bottomY = topY + this.config.rowHeight;
      positions[item.id] = { fromX, toX, width, topY, bottomY };
    });

    return positions;
  }

  private renderHeader() {
    const self = this;

    if (this.layout.verScrollShown) {
      this.header
        .append("line")
        .attr("class", "dr-timeline__grid-header-border")
        .attr("x1", this.dataWidth)
        .attr("x2", this.dataWidth)
        .attr("y1", 0)
        .attr("y2", this.config.axisHeight);
    }

    function DrawXAxis(
      offsetY: number,
      lines: AXIS_LINES,
      interval: d3.TimeInterval,
      format: any,
    ) {
      const xAxis = d3
        .axisTop(self.xScale)
        .tickSize(1)
        .ticks(interval)
        .tickPadding(self.config.axisHeight / 4)
        .tickFormat(format);
      const labelAxis = self.header
        .append("g")
        .attr("class", "dash-rooms-timeline__d_header_axis")
        .attr("transform", `translate(0, ${offsetY})`)
        .call(xAxis);
      const tickText = labelAxis.selectAll("text");
      const tickLines = labelAxis.selectAll(".tick line");
      const tickLineOffset = lines === AXIS_LINES.Partial ? 7 : 0;
      if (lines === AXIS_LINES.Hidden) {
        tickText.attr("transform", `translate(0, 6)`);
        tickLines.attr("stroke-width", 0);
      } else {
        labelAxis
          .selectAll("text")
          .style("text-anchor", "start")
          .attr("transform", "translate(5, 6)");
        tickLines
          .attr("class", "dash-rooms-timeline__d_grid_line")
          .attr("y1", -tickLineOffset)
          .attr("y2", -self.config.axisHeight / 2 + tickLineOffset);
      }
    }

    DrawXAxis(
      this.config.axisHeight,
      self.tickConfig.axisLines.bottom,
      self.tickConfig.bottomInterval,
      self.tickConfig.bottomFormat,
    );
    DrawXAxis(
      this.config.axisHeight / 2,
      self.tickConfig.axisLines.top,
      this.tickConfig.topInterval,
      this.tickConfig.topFormat,
    );
  }

  private renderSidebarGrid() {
    this.renderSidebarBorder(this.sidebarData, 0, this.dataHeight);
    if (this.config.showSidebar) {
      this.renderDataHorizontalGrid(this.sidebarData, this.sidebarWidth);
      this.renderSidebarData();
    }
  }

  private renderGrid() {
    this.renderVerticalGridLines();
    this.renderDataHorizontalGrid(this.grid, this.dataWidth);
    this.renderTodayLine();
  }

  private renderSidebarBorder(
    elem: D3Selection<SVGGElement>,
    y1: number,
    y2: number,
  ) {
    elem
      .append("line")
      .attr("class", "dr-timeline__sidebar-border-line")
      .attr("x1", this.sidebarWidth)
      .attr("x2", this.sidebarWidth)
      .attr("y1", y1)
      .attr("y2", y2);
    elem
      .append("line")
      .attr("class", "dr-timeline__sidebar-border-line")
      .attr("x1", 0)
      .attr("x2", 0)
      .attr("y1", y1)
      .attr("y2", y2);
  }

  private renderTodayLine() {
    const todayX = this.xScale(new Date()) as number;
    const todayGroup = this.grid
      .append("g")
      .attr("class", "dash-rooms-timeline__d_today");
    todayGroup
      .append("line")
      .attr("x1", todayX)
      .attr("x2", todayX)
      .attr("y1", 0)
      .attr("y2", this.dataHeight);
    todayGroup
      .append("circle")
      .attr("cx", todayX)
      .attr("cy", 0)
      .attr("r", "3px");
  }

  private renderVerticalGridLines() {
    const axis = d3
      .axisTop(this.xScale)
      .ticks(this.tickConfig.gridInterval)
      .tickFormat("" as any);

    const g = this.grid.append("g");

    g.call(axis);

    g.select(".domain").remove();
    g.selectAll(".tick:not(:first-of-type) line")
      .attr("class", "dash-rooms-timeline__d_grid_line")
      .attr("y1", 0)
      .attr("y2", this.dataHeight);
  }

  private renderDataHorizontalGrid(container: any, gridWidth: number) {
    container
      .append("g")
      .selectAll("line")
      .data(this.data)
      .enter()
      .append("line")
      .attr("class", "dash-rooms-timeline__d_grid_line")
      .attr("y2", (item: T) => this.dataPositions[item.id].topY)
      .attr("y1", (item: T) => this.dataPositions[item.id].topY)
      .attr("x1", 0)
      .attr("x2", gridWidth);
  }
}
