import { h } from "vue";

import ActionsCell from "@drVue/components/room/settings/cells/ActionsCell.vue";
import NameCell from "@drVue/components/room/settings/cells/NameCell.vue";
import TypeCell from "@drVue/components/room/settings/cells/TypeCell.vue";

import type { DrVxeTableColumn } from "@drVue/components/types";

export default class TableColumns {
  get columns(): DrVxeTableColumn[] {
    return [this.typeColumn(), this.nameColumn(), this.actionsCell()];
  }

  private typeColumn(): DrVxeTableColumn {
    return {
      field: "type",
      title: "Type",
      minWidth: 200,
      width: 200,
      slots: {
        default: (params) => [
          h(TypeCell, {
            row: params.row,
          }),
        ],
      },
    };
  }

  private nameColumn(): DrVxeTableColumn {
    return {
      field: "name",
      title: "Name",
      minWidth: 200,
      width: 200,
      slots: {
        default: (params) => [
          h(NameCell, {
            row: params.row,
          }),
        ],
      },
    };
  }

  private actionsCell(): DrVxeTableColumn {
    return {
      field: "",
      title: "",
      slots: {
        default: (params) => [
          h(ActionsCell, {
            row: params.row,
            table: params.$table,
          }),
        ],
      },
    };
  }
}
