import "./nodes";

import * as d3 from "d3";

angular
  .module("dealroom.analytics.linechart.lines", [
    "dealroom.analytics.linechart.service.config",
    "dealroom.analytics.linechart.lines.nodes",
  ])
  .directive("drAnalyticsLinechartLines", () => ({
    scope: {
      drAnalyticsLinechartLines: "<",
      shadowId: "@",
    },
    controller: Controller,
  }));

Controller.$inject = ["$scope", "$element", "AnalyticsLinechartNodes"];
function Controller($scope, $element, AnalyticsLinechartNodes) {
  const pk = "drAnalyticsLinechartLines";
  const rootElement = d3.select($element[0]);
  const dispatcher = $scope.drAnalyticsLinechartLines;

  let _visibleKeys,
    _elements,
    _daysExtent = [];

  dispatcher.on("visibleKeys." + pk, update);

  function update(
    visibleKeys,
    { data, maxValue, height, r, daysExtent, inProgress },
  ) {
    if (maxValue === undefined) {
      data = [];
    }
    const nodes = AnalyticsLinechartNodes({
      height,
      r,
      shadowId: $scope.shadowId,
    });

    const graphs = rootElement
      .selectAll(".line")
      .data(data.slice().reverse(), ({ key }, i) => key || i);
    graphs.exit().each(remove);

    const updated = graphs
      .enter()
      .append("g")
      .each(bindHover)
      .attr("class", "line")
      .merge(graphs)
      .order();

    if (data.length === 0) return;

    if (daysExtentIsChanged(daysExtent)) {
      _daysExtent = daysExtent;
      updated.each(updateNodes);
    } else {
      updated.each(updateYs);
    }

    function remove(d) {
      const g = d3.select(this).classed("line", false);
      dispatcher.call("removeHover", null, g);
      nodes.remove(g);
    }

    function updateYs(d) {
      const g = d3.select(this);
      nodes.updateYs(g, visibleKeys, inProgress);
    }

    function updateNodes(d) {
      const g = d3.select(this);
      const xExtent = d3.extent(d.points, ({ x }) => x);
      nodes.updateNodes(g, visibleKeys, xExtent, inProgress);
    }

    function bindHover() {
      dispatcher.call("addHover", null, d3.select(this));
    }
  }

  function daysExtentIsChanged([news, newe]) {
    const [s, e] = _daysExtent;
    return d3.timeDay.count(s, news) !== 0 || d3.timeDay.count(e, newe) !== 0;
  }
}
