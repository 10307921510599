import { useBrowserLocation } from "@vueuse/core";

import { APP_SETTINGS, ROOM_DATA, ROOM_MEMBER_DATA } from "@setups/data";
import { TaskFieldAccessType } from "@setups/enums";

import type { Category } from "@drVue/store/pinia/room/categories";
import type { CategoriesStore } from "@drVue/store/pinia/room/categories/categories";

const location = useBrowserLocation();

export interface RoomNavTab {
  id: string;
  url: string;
  label: string;
}

export function taskListToTab(catList: Category): RoomNavTab {
  return {
    id: `tasks.${catList.id}`,
    url: `#/tasks/list/${catList.id}`,
    label: catList.name,
  };
}

export function isTaskListNavEnabled(): boolean {
  return ROOM_DATA.enableListsTabs;
}

export function getTasksListTabsNav(
  catsStore: CategoriesStore,
): RoomNavTab[] | null {
  if (!(ROOM_DATA.userPermissions.viewTasks && isTaskListNavEnabled())) {
    return null;
  }
  if (!catsStore.rootCategories?.length || !catsStore.categories) {
    return [
      // no categories show, default requests tab
      {
        id: "tasks",
        url: "#/tasks",
        label: "Requests",
      },
    ];
  }
  return catsStore.rootCategories.map((c) => taskListToTab(c));
}

export function getCurrentRootCategoryId(
  catsStore: CategoriesStore,
  categoryId?: number,
): number | null {
  if (!catsStore.rootCategories || !catsStore.categories) {
    return null;
  }

  if (!categoryId) {
    return catsStore.rootCategories.length
      ? catsStore.rootCategories[0].id
      : null;
  }
  // locate root
  let id = categoryId;
  while (id !== catsStore.childIdToRoot![id]) {
    id = catsStore.childIdToRoot![id];
  }

  return catsStore.categories[id]?.id;
}

export function getActiveTabId(catsStore: CategoriesStore): string | null {
  const currentHash = location.value.hash || "";

  if (isTaskListNavEnabled() && currentHash.startsWith("#/tasks/list")) {
    const match = /#\/tasks\/list\/(\d+).*/.exec(currentHash);
    const categoryId = match ? parseInt(match[1]) : undefined;
    const rootCategoryId = getCurrentRootCategoryId(catsStore, categoryId);
    if (!rootCategoryId) {
      return "tasks";
    }
    return `tasks.${rootCategoryId}`;
  }
  const tabs = getBaseRoomNavTabs();
  const active = tabs.find((t) => currentHash.startsWith(t.url));
  return active?.id ?? null;
}

export function getBaseRoomNavTabs(): RoomNavTab[] {
  const tabs: RoomNavTab[] = [];

  if (ROOM_DATA.userPermissions.viewTasks && !isTaskListNavEnabled()) {
    // if lists tabs enabled, separate element is added
    tabs.push({
      id: "tasks",
      url: "#/tasks",
      label: "Requests",
    });
  }
  if (ROOM_DATA.userPermissions.isFindingsAccessible) {
    tabs.push({
      id: "findings",
      url: "#/findings",
      label: "Findings",
    });
  }
  tabs.push({
    id: "documents",
    url: "#/documents",
    label: "Data Room",
  });
  tabs.push({
    id: "members",
    url: "#/members",
    label: ROOM_DATA.userPermissions.administrator ? "Permissions" : "Members",
  });
  if (
    APP_SETTINGS.WEBSITE.IS_DEALROOM &&
    (ROOM_DATA.synergySettings?.enable ?? false) &&
    ROOM_MEMBER_DATA.group.synergies_access !== TaskFieldAccessType.NoAccess
  ) {
    tabs.push({
      id: "synergies",
      url: "#/synergies",
      label: "Synergies",
    });
  }
  if (ROOM_DATA.userPermissions.administrator) {
    tabs.push({
      id: "analytics",
      url: "#/analytics",
      label: "Reports",
    });

    tabs.push({
      id: "settings",
      url: "#/settings",
      label: "Settings",
    });
  }
  return tabs;
}
