<template>
  <div
    :class="{
      [$style.wrapper]: true,
      [$style.wrapper_isExpanded]: isExpanded,
    }"
  >
    <img v-if="websiteLogo" :class="$style.logoImage" :src="websiteLogo" />
    <img v-else :class="$style.logoImage" src="./logo-dealroom.svg" />

    <DrMainMenuItem
      icon="chevron-double-right"
      :class="$style.toggle"
      @select="emit('toggle')"
    />
  </div>
</template>

<script setup lang="ts">
/**
 * Main menu logo.
 */

import DrMainMenuItem from "./DrMainMenuItem.vue";

interface Props {
  websiteLogo?: string; // required, optional for now to make it work in styleguide
  isExpanded?: boolean;
}
withDefaults(defineProps<Props>(), {
  websiteLogo: "",
  isExpanded: false,
});

const emit = defineEmits<{
  (event: "toggle"): void;
}>();
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

@mixin transition {
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
}

$space: 8px;

.wrapper {
  padding: $space;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoImage {
  @include transition;
  flex-shrink: 0;
  width: 0;
  height: 20px;
  opacity: 0;
  transition-property: opacity;
  will-change: opacity;

  .wrapper_isExpanded & {
    width: auto;
    margin-left: 6px;
    opacity: 1;
  }
}

.toggle {
  @include transition;
  transform: rotateY(0deg);
  transition-property: transform;

  .wrapper_isExpanded & {
    transform: rotateY(180deg);
  }
}
</style>
