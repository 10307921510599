<template>
  <ElDrawer
    v-model="isModalVisible"
    class="el-drawer--narrow"
    append-to-body
    :show-close="false"
    :with-header="false"
    @open="resetForm"
  >
    <ContentPanelClose @close="close" />
    <div :class="$style.container">
      <h4>Add person</h4>
      <AddUserModalContent
        v-if="!isMultipleInvitesEnabled"
        ref="form"
        :rooms="rooms"
        :users="users"
        :api="api"
        @close="close"
      />
      <AddUserModalContent2
        v-else
        ref="form"
        :rooms="rooms"
        :users="users"
        @close="close"
      />
    </div>
  </ElDrawer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ContentPanelClose from "@shared/ui/content-panel/close.vue";

import { ORG_MEMBER_DATA } from "@app/setups";
import { loadFromLocalStorage } from "@app/vue/common";
import { UsersApiService } from "@drVue/api-service/client-dashboard";
import AddUserModalContent from "./content.vue";
import AddUserModalContent2 from "./content2.vue";

import type { OrgUser } from "@app/vue/store/modules/client-dashboard/org-users/types";
import type { Room } from "@drVue/store/modules/client-dashboard/deals/types";

export interface Data {
  isModalVisible: boolean;
  usersApiService: UsersApiService;
}

export default defineComponent({
  name: "AddUserModal",
  components: {
    AddUserModalContent,
    AddUserModalContent2,
    ContentPanelClose,
  },
  data(): Data {
    return {
      isModalVisible: false,
      usersApiService: new UsersApiService(),
    };
  },
  computed: {
    rooms(): Room[] {
      return this.$store.getters["clientDashboard/deals/getRooms"].filter(
        (r: Room) =>
          // There may still be groups that the user has access to but cannot invite to the Room.
          !r.is_archived && !!r.groups,
      );
    },
    users(): OrgUser[] {
      return this.$store.state.clientDashboard.orgUsers.items;
    },
    api(): UsersApiService {
      return this.usersApiService;
    },
    isMultipleInvitesEnabled() {
      return (
        !!loadFromLocalStorage("dr:enable_multiple_invites") ||
        ORG_MEMBER_DATA.client.enable_bulk_invites
      );
    },
  },
  methods: {
    resetForm() {
      const formRef = this.$refs["form"] as InstanceType<
        typeof AddUserModalContent
      >;

      formRef.resetForm();
    },
    open() {
      this.isModalVisible = true;
    },
    close() {
      this.$store.dispatch("clientDashboard/orgUsers/load");
      this.$store.dispatch("clientDashboard/deals/load");
      this.isModalVisible = false;
    },
  },
});
</script>

<style lang="scss" module>
.container {
  padding: 24px 24px 20px;
}
</style>
