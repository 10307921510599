<template>
  <div class="client-settings-panel">
    <ElForm
      ref="integrationForm"
      :model="integrationModel"
      :disabled="isSubmitting"
      :rules="rules"
      :label-position="'top'"
      size="small"
      @submit.prevent="formSubmit"
    >
      <ElFormItem label="Title" prop="title">
        <ElInput v-model="integrationModel.title" autofocus />
      </ElFormItem>
      <ElFormItem
        v-if="provider.id !== OpenIDProviders.Google"
        label="Metadata URI"
        prop="openid_configuration_url"
      >
        <ElInput v-model="integrationModel.openid_configuration_url" />
      </ElFormItem>
      <ElFormItem label="Client ID" prop="app_client_id">
        <ElInput v-model="integrationModel.app_client_id" />
      </ElFormItem>
      <ElFormItem label="Client Secret" prop="app_client_secret">
        <ElInput v-model="integrationModel.app_client_secret" />
      </ElFormItem>
      <ElFormItem>
        <ElButton
          type="primary"
          :disabled="!isChanged"
          :loading="isSubmitting"
          @click="formSubmit"
        >
          {{ isCreateNew ? "Create" : "Update" }}
        </ElButton>
        <ElButton class="close-btn" @click="close(undefined)">
          Cancel
        </ElButton>
        <ElButton
          v-if="!isCreateNew"
          type="danger"
          icon="el-icon-delete"
          size="small"
          class="pull-right"
          @click="deleteIntegration"
        />
      </ElFormItem>
    </ElForm>
  </div>
</template>

<script lang="ts">
import { cloneDeep } from "lodash-es";
import { defineComponent } from "vue";
import DrForm from "@shared/ui/dr-form";

import {
  OpenIDIntegrationApiService,
  OpenIDProviders,
} from "@drVue/api-service/client-dashboard";

import type {
  OpenIDIntegration,
  OpenIDProvider,
} from "@drVue/api-service/client-dashboard";
import type { Dictionary } from "@drVue/types";
import type { PropType } from "vue";

interface Data {
  api: OpenIDIntegrationApiService;
  isCreateNew: boolean;
  integrationModel: OpenIDIntegration;
  rules: Dictionary<object[]>;
}

export default defineComponent({
  name: "EditForm",
  extends: DrForm,
  props: {
    integrationData: {
      required: true,
      type: Object as PropType<OpenIDIntegration>,
    },
    provider: { required: true, type: Object as PropType<OpenIDProvider> },
  },
  emits: ["close"],
  data(): Data {
    return {
      api: new OpenIDIntegrationApiService(this.provider.id),
      isCreateNew: !this.integrationData.app_client_id,
      integrationModel: cloneDeep(this.integrationData),
      rules: {
        title: [
          { required: true, message: "Please input title", trigger: "blur" },
        ],
        openid_configuration_url: [
          {
            required: true,
            message: "Please input metadata URI of authorization server",
            trigger: "blur",
          },
        ],
        app_client_id: [
          {
            required: true,
            message: "Please input client ID",
            trigger: "blur",
          },
        ],
        app_client_secret: [
          {
            required: true,
            message: "Please input client secret",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    OpenIDProviders() {
      return OpenIDProviders;
    },
  },
  watch: {
    integrationModel: {
      deep: true,
      handler() {
        this.isChanged = true;
      },
    },
  },
  beforeMount() {
    if (this.provider.id === OpenIDProviders.Google) {
      delete this.rules["openid_configuration_url"];
    }
  },
  methods: {
    close(payload: OpenIDIntegration | undefined) {
      this.$emit("close", payload);
    },
    formSubmit() {
      (this.$refs["integrationForm"] as any).validate((valid: boolean) => {
        if (valid) {
          const method = this.isCreateNew ? "create" : "update";
          const promise = this.api[method](this.integrationModel!);

          return this.submitPromise(
            promise,
            `Failed to ${method} ${this.provider.name} integration`,
          ).then((data: OpenIDIntegration) => this.close(data));
        } else {
          return false;
        }
      });
    },
    deleteIntegration() {
      this.$confirm(
        `${this.provider.name} integration will be removed`,
        `Remove integration`,
        {
          confirmButtonText: "Remove",
          cancelButtonText: "Cancel",
          type: "warning",
        },
      ).then(() => {
        const promise = this.api.destroy();

        return this.submitPromise(
          promise,
          `Failed to remove ${this.provider.name} integration`,
        ).then(() => this.close({} as OpenIDIntegration));
      });
    },
  },
});
</script>
