<template>
  <div
    :class="{
      [$style.item]: true,
      [$style.item_isSingleMode]: singleMode,
    }"
  >
    <div :class="$style.itemBody"><slot /></div>

    <div :class="$style.itemActions">
      <DrTooltip v-for="item in actions" :key="item.id" :content="item.title">
        <div
          :class="{
            [$style.itemAction]: true,
            [$style.itemAction_disabled]: item.disabled,
          }"
          @click.stop="handleAction(item)"
        >
          <DrIcon :name="item.icon" size="sm" />
        </div>
      </DrTooltip>

      <DrTooltip v-if="allowRemove" content="Remove">
        <div :class="$style.itemAction" @click.stop="$emit('remove')">
          <DrIcon name="cross" size="sm" />
        </div>
      </DrTooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { DrIcon } from "@shared/ui/dr-icon";
import { DrTooltip } from "@shared/ui/dr-tooltip";

import type { ActionItem } from "./types";

interface Props {
  allowRemove?: boolean;
  singleMode?: boolean;
  actions?: ActionItem[];
}

interface Emits {
  (e: "remove"): void;
  (e: "action", id: ActionItem["id"]): void;
}

withDefaults(defineProps<Props>(), {
  allowRemove: false,
  singleMode: false,
  actions: () => [],
});

const emit = defineEmits<Emits>();

const handleAction = (item: ActionItem) => {
  if (item.disabled) return;

  emit("action", item.id);
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";

.itemActions {
  flex: 0 1 auto;
  display: grid;
  align-items: center;
  justify-content: center;
  height: 16px;
  line-height: 16px;
  margin-right: -16px;
  opacity: 0;
  transition-duration: 150ms;
  transition-timing-function: ease-out;
  transition-property: margin-right, opacity;
  will-change: margin-right, opacity;

  .item:hover & {
    opacity: 1;
    margin-right: 8px;
  }
}

.itemAction {
  grid-column: 1/1;
  grid-row: 1/1;
  width: 16px;
  z-index: -1;
  color: colors.$pr-500;
  transition: margin-left 150ms ease-out;
  cursor: pointer;
  will-change: margin-left;

  .item:hover & {
    grid-column: auto;
    margin-left: 8px;
    z-index: 1;
  }

  &:hover {
    color: colors.$pr-900;
  }
}

.itemAction_disabled {
  &,
  &:hover {
    cursor: not-allowed;
    color: colors.$pr-300;
  }
}

.itemBody {
  flex: 1;
}

.item {
  border-radius: 8px;
  margin-left: -8px;
  padding-left: 8px;
  margin-right: -8px;
  padding-right: 2px;
  position: relative;
  min-height: 32px;
  display: flex;
  align-items: center;
  transition: background-color 150ms ease-out;

  &:hover {
    background-color: colors.$pr-100;
  }
}

.item_isSingleMode {
  min-height: 28px;
  margin-left: 0;
  margin-right: 0;
  padding-left: 4px;
  padding-right: 4px;
}
</style>
