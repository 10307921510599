<template>
  <ElDrawer
    v-model="isOpen"
    :append-to-body="true"
    :with-header="false"
    destroy-on-close
    class="el-drawer--entity"
    size=""
    @close="close"
  >
    <ContentPanelClose @close="close" />

    <div class="user-details-panel">
      <div class="user-details-panel__col user-details-panel__col--left">
        <div class="user-details-panel__section-wrapper">
          <!-- @vue-ignore -->
          <AvatarNSummary class="user-details-panel__section" :user="user" />
        </div>

        <div class="user-details-panel__divider" />

        <div
          class="user-details-panel__section-wrapper user-details-panel__section-wrapper--scrollable"
        >
          <div style="padding: 0 25px">
            <div class="form-title">User details</div>
            <!-- @vue-ignore -->
            <UserForm :user="user" />
          </div>
        </div>
      </div>
      <div class="user-details-panel__col user-details-panel__col--center">
        <SimpleTabNav
          :tabs="availableTabs"
          :active-tab="tab"
          class="user-details-panel__tabs"
          @tab-click="tab = $event"
        />

        <SearchInput
          v-show="[Tabs.Notes, Tabs.Emails].includes(tab)"
          v-model="searchQuery"
          class="user-details-panel__search-field"
        />

        <!-- @vue-ignore -->
        <EmailsContainer
          v-if="tab === Tabs.Emails"
          entity-type="User"
          :entity-id="user.id"
          class="user-details-panel__section"
          :search-query="searchQuery"
          editable
        />

        <!-- @vue-ignore -->
        <NotesList
          v-if="tab === Tabs.Notes"
          class="user-details-panel__section"
          :object-type="noteType"
          :object-id="user.id"
          :search-query="searchQuery"
          editable
        />

        <!-- @vue-skip -->
        <UserRoomTasksList
          v-if="tab === Tabs.Requests"
          :user="user"
          class="room-details-panel__section"
        />
      </div>
      <div class="user-details-panel__col user-details-panel__col--right">
        <div
          class="user-details-panel__section-wrapper user-details-panel__section-wrapper--half-height"
        >
          <!-- @vue-ignore -->
          <RoomsList :user="user" class="user-details-panel__section" />
        </div>
        <div class="user-details-panel__divider" />
        <div
          class="user-details-panel__section-wrapper user-details-panel__section-wrapper--half-height"
        >
          <!-- @vue-ignore -->
          <DealsList :user="user" class="user-details-panel__section" />
        </div>
      </div>
    </div>
  </ElDrawer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SearchInput, SimpleTabNav } from "@shared/ui";
import ContentPanelClose from "@shared/ui/content-panel/close.vue";
import EmailsContainer from "@shared/ui/emails-list/EmailsContainer.vue";

import { ORG_MEMBER_DATA } from "@setups/data";
import NotesList from "@drVue/components/client-dashboard/common/NotesList";
import ViewTextField from "@drVue/components/client-dashboard/users/UserDetails/CustomFields/View/ViewTextField.vue";
import ViewUserEmailField from "@drVue/components/client-dashboard/users/UserDetails/CustomFields/View/ViewUserEmailField.vue";
import ViewOrEditPhoneField from "@drVue/components/client-dashboard/users/UserDetails/CustomFields/ViewOrEdit/ViewOrEditPhoneField.vue";
import UserForm from "@drVue/components/client-dashboard/users/UserForm.vue";
import { NoteObjectType } from "@drVue/store/modules/client-dashboard/notes/types";
import UserRoomTasksList from "./RoomTasks/UserRoomTasks.vue";
import AvatarNSummary from "./sections/AvatarNSummary.vue";
import DealsList from "./sections/DealsList.vue";
import RoomsList from "./sections/RoomsList.vue";

import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";

enum Tabs {
  Emails = "emails",
  Notes = "notes",
  Requests = "requests",
}

interface Data {
  user: OrgUser | null;
  isOpen: boolean;
  tab: string;
  searchQuery: string;
  noteType: any;
  isEditPanelOpen: boolean;
}

export default defineComponent({
  name: "UserModal",
  components: {
    AvatarNSummary,
    ContentPanelClose,
    DealsList,
    EmailsContainer,
    NotesList,
    RoomsList,
    SearchInput,
    SimpleTabNav,
    UserForm,
    UserRoomTasksList,
    // UserDetails,
  },
  data(): Data {
    return {
      user: null,
      isOpen: false,
      tab: Tabs.Notes,
      searchQuery: "",
      noteType: NoteObjectType.OrgUser,
      isEditPanelOpen: false,
    };
  },
  computed: {
    Tabs(): any {
      return Tabs;
    },
    availableTabs(): Tabs[] {
      const tabs = [Tabs.Notes];
      if (ORG_MEMBER_DATA.client.enable_outlook) {
        tabs.push(Tabs.Emails);
      }
      if (this.user && !this.user.pending) {
        tabs.push(Tabs.Requests);
      }
      return tabs;
    },
    extraViewFields(): any {
      if (!this.user) return;

      // prettier-ignore
      return [
        { key: "email", component: ViewUserEmailField },
        { key: "first_name", component: ViewTextField, meta: { label: "First name", fieldPath: "first_name" } },
        { key: "last_name", component: ViewTextField, meta: { label: "Last name", fieldPath: "last_name"} },
        { key: "company", component: ViewTextField, meta: { label: "Company", fieldPath: "company" } },
        { key: "role", component: ViewTextField, meta: { label: "Role", fieldPath: "title"} },
        { key: "phone", component: ViewOrEditPhoneField, meta: { isEditable: false} },
      ];
    },
  },
  methods: {
    show(user: OrgUser) {
      this.isOpen = true;
      this.user = user;
      this.searchQuery = "";
    },
    close() {
      (this.$refs.info as any)?.cancel();
      this.isOpen = false;
      this.searchQuery = "";
    },
  },
});
</script>

<style scoped lang="scss">
@use "/app/styles/scss/_colors.scss";
@use "/app/styles/scss/typography.scss" as typo;

// User details panel
.user-details-panel {
  height: 100%;
  display: flex;
  align-items: stretch;

  &__col {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    &--left {
      width: 320px;
    }

    &--center {
      flex: 1;
      padding: 15px 0;
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: auto;
      background: colors.$light-gray-blue;
      border-right: 1px solid colors.$pr-200;
      border-left: 1px solid colors.$pr-200;
    }

    &--right {
      width: 300px;
      overflow-x: hidden;
      overflow-y: auto;
      justify-content: flex-start;
    }
  }

  &__tabs {
    min-height: 40px;
    margin: 20px 25px;
    flex-grow: 0;
  }

  &__search-field {
    margin: 15px 25px;
    width: 200px;
  }

  &__section-wrapper {
    padding: 17px 0 25px;

    &--scrollable {
      overflow-x: hidden;
      overflow-y: auto;
    }

    &--half-height {
      max-height: 50%;
    }
  }

  &__section {
    padding: 0 25px;
  }

  &__divider {
    height: 1px;
    position: relative;

    &::before {
      content: "";
      width: 100%;
      height: 0;
      border-bottom: 1px colors.$pr-200 solid;
      position: absolute;
      inset: 0 auto;
    }
  }
}

.form-title {
  padding-top: 4px;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: colors.$pr-800;
  margin-bottom: 22px;
}
</style>
