<template>
  <div
    :class="{
      [$style.icon]: true,
      [$style.xxs]: size === SIZES.xxs,
      [$style.xs]: size === SIZES.xs,
      [$style.sm]: size === SIZES.sm,
      [$style.lg]: size === SIZES.lg,
      [$style.xl]: size === SIZES.xl,
      [$style.xxl]: size === SIZES.xxl,
    }"
  >
    <div :class="faIconClass" />
  </div>
</template>

<script setup lang="ts">
/**
 * Icon component.
 * Allows to display icons from `fontawesome 5` strongly by style guide.
 */

import { computed } from "vue";

import { SIZES } from "../constants";
import { ICONS_NAMES } from "./icons";

import type { IconName } from "./icons";

interface Props {
  /**
   * Icon's name
   */
  name: IconName;
  /**
   * Icon's size
   */
  size?: keyof typeof SIZES;
}
const props = withDefaults(defineProps<Props>(), {
  size: SIZES.md,
});

const faIconClass = computed(() => ICONS_NAMES[props.name]);
</script>

<style lang="scss" module>
$xxs: 12px;
$xs: 14px;
$sm: 16px;
$md: 20px;
$lg: 24px;
$xl: 28px;
$xxl: 36px;
$xxs_font-size: 9px;
$xs_font-size: 11px;
$sm_font-size: 13px;
$md_font-size: 16px;
$lg_font-size: 22px;
$xl_font-size: 24px;
$xxl_font-size: 30px;

.icon {
  --size: #{$md};
  --font-size: #{$md_font-size};

  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: var(--size);
  width: var(--size);
  font-size: var(--font-size);
}

.xxs {
  --size: #{$xxs};
  --font-size: #{$xxs_font-size};
}

.xs {
  --size: #{$xs};
  --font-size: #{$xs_font-size};
}
.sm {
  --size: #{$sm};
  --font-size: #{$sm_font-size};
}
.lg {
  --size: #{$lg};
  --font-size: #{$lg_font-size};
}
.xl {
  --size: #{$xl};
  --font-size: #{$xl_font-size};
}
.xxl {
  --size: #{$xxl};
  --font-size: #{$xxl_font-size};
}
</style>
