UploadConfirmationController.$inject = [
  "items",
  "destinationFolderId",
  "DocumentsService",
  "$uibModalInstance",
  "FileUploadService",
  "FileUploadHelpers",
  "RoomConfig",
  "DocumentsApiService",
  "AlertService",
  "PermissionsService",
  "TasksAttachmentsService",
];
import { insightTrack } from "@app/insight";

export default function UploadConfirmationController(
  items,
  destinationFolderId,
  DocumentsService,
  $uibModalInstance,
  FileUploadService,
  FileUploadHelpers,
  RoomConfig,
  DocumentsApiService,
  AlertService,
  PermissionsService,
  TasksAttachmentsService,
) {
  // TODO: for non admin - without permission confirmation
  var $ctrl = this;
  $ctrl.DocumentsService = DocumentsService;
  $ctrl.isAdministrator = RoomConfig.userPermissions.administrator;
  $ctrl.showTaskSelect =
    RoomConfig.userPermissions.viewTasks &&
    RoomConfig.userPermissions.canManageTasks;
  $ctrl.PermissionsService = PermissionsService;
  $ctrl.STEPS = {
    DestinationSelect: 0,
    Confirm: 10,
  };
  $ctrl.wizardStep = $ctrl.STEPS.DestinationSelect;
  $ctrl.destinationFolderID = parseInt(destinationFolderId);
  $ctrl.selectedTask = undefined;
  $ctrl.batches = FileUploadHelpers.splitToBatches(items);
  $ctrl.permissions = {}; // set perms on upload {pgroupId: perms tuple}
  $ctrl.destionationViewable = {};
  $ctrl.notifyOnUpload = true;
  $ctrl.permGroups = undefined;
  $ctrl.confirmUpload = confirmUpload;
  $ctrl.showConfirmStep = showConfirmStep;
  $ctrl.isPermissionsLoaded = isPermissionsLoaded;
  $ctrl.toggleGroupPermissions = toggleGroupPermissions;
  $ctrl.isConfirmationRequired = isConfirmationRequired;
  $ctrl.confirmed = false;
  $ctrl.nonUniqueNames = undefined;
  $ctrl.checkNamesUnique = checkNamesUnique;
  var defaultPermsLoading = false;

  if ($ctrl.isAdministrator) {
    updatePermGroups();
  }
  checkNamesUnique();

  function toggleGroupPermissions(pgroupId, permIndex) {
    var perms = $ctrl.permissions[pgroupId];
    var i;
    for (i = 0; i < permIndex; i++) {
      perms[i] = true;
    }
    perms[permIndex] = !perms[permIndex];
    for (i = permIndex + 1; i < perms.length; i++) {
      perms[i] = false;
    }
  }
  function isPermissionsLoaded() {
    return $ctrl.permGroups !== undefined && !defaultPermsLoading;
  }

  function updatePermGroups() {
    PermissionsService.updatePromise.then(function () {
      $ctrl.permGroups = PermissionsService.pgroupsList.filter(function (pg) {
        return !pg.is_administrator;
      });
    });
  }

  function isConfirmationRequired() {
    return (
      $ctrl.isAdministrator &&
      ($ctrl.permGroups === undefined || $ctrl.permGroups.length > 0)
    );
  }

  function showConfirmStep() {
    loadPermissions($ctrl.destinationFolderID);
    $ctrl.wizardStep = $ctrl.STEPS.Confirm;
  }

  function loadPermissions(folderId) {
    defaultPermsLoading = true;
    return DocumentsApiService.defaultPermissions(folderId)
      .then(
        function (resp) {
          $ctrl.permissions = resp.data.default_permissions;
          $ctrl.destionationViewable = resp.data.folder_viewable;
        },
        function (reason) {
          AlertService.danger("Failed to load default permissions for files");
        },
      )
      .finally(function () {
        defaultPermsLoading = false;
      });
  }

  function confirmUpload() {
    if ($ctrl.confirmed) {
      return;
    }
    const selectedTask = $ctrl.selectedTask;
    let allItems = [];
    $ctrl.batches.forEach(function (batch) {
      if (batch.name !== batch.originalName) {
        FileUploadHelpers.renameBatch(batch, batch.name);
      }
      allItems = allItems.concat(batch.items);
    });

    insightTrack("room.documents.upload", {
      source: "documents",
      folder_id: $ctrl.destinationFolderID,
      batches: $ctrl.batches.length,
      destination_changed: $ctrl.destinationFolderID !== destinationFolderId,
      notify_on_upload: $ctrl.notifyOnUpload,
      request_selected: !!selectedTask,
    });

    var permissions = $ctrl.isAdministrator ? $ctrl.permissions : undefined;
    FileUploadService.uploadToFolder(
      $ctrl.destinationFolderID,
      allItems,
      permissions,
      $ctrl.notifyOnUpload,
    ).then(function (result) {
      if (selectedTask) {
        TasksAttachmentsService.attachUploadBatchResult(selectedTask, result);
      }
    });
    $ctrl.confirmed = true;
    $uibModalInstance.dismiss();
  }

  function checkNamesUnique() {
    $ctrl.nonUniqueNames = undefined;
    const nonUniqueNames = {};
    const checkedNames = {};
    $ctrl.batches.forEach(function (batch) {
      if (checkedNames[batch.name]) {
        nonUniqueNames[batch.name] = true;
        $ctrl.nonUniqueNames = nonUniqueNames;
      }
      checkedNames[batch.name] = true;
    });
  }
}
