<template>
  <div :class="$style.wrapper" @click.stop>
    <div
      role="button"
      :class="{
        [$style.content]: true,
        [$style.content_isHavingNewComments]: isHavingNewComments,
        [$style.content_isHavingUnapprovedComments]: isHavingUnapprovedComments,
        [$style.content_isEmpty]: isEmpty,
      }"
    >
      <DrIcon size="sm" :class="$style.icon" name="comment" />
      <span v-if="commentsCount" :class="$style.label">
        {{ commentsCount }}
      </span>
      <DrIcon
        v-else
        size="xs"
        name="plus"
        :class="[$style.icon, $style.plusSign]"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import DrIcon from "@shared/ui/dr-icon/DrIcon.vue";

interface Props {
  commentsCount: number;
  newCommentsCount: number;
  unapprovedCommentsCount: number;
}

const props = defineProps<Props>();
const isEmpty = computed(() => props.commentsCount === 0);
const isHavingNewComments = computed(() => props.newCommentsCount > 0);
const isHavingUnapprovedComments = computed(
  () => props.unapprovedCommentsCount > 0,
);
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.wrapper {
  width: 100%;
  height: 100%;
  &:hover {
    .content {
      opacity: 1;
    }
  }
}

.content {
  padding: 4px 6px;
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
  border: 1px solid transparent;
  color: colors.$pr-500;
  display: inline-flex;
  align-items: center;
  min-width: 100%;
  margin: auto;

  &:hover {
    border: 1px solid colors.$sc-400;
    .icon {
      color: colors.$sc-600;
    }
  }
}

.content_isHavingNewComments {
  color: colors.$sc-600;
}

.content_isHavingUnapprovedComments {
  color: #da8607;
  transition: all 0.2s ease-in-out;
  &:hover {
    .icon {
      color: #da8607;
    }
  }
}

.content_isEmpty {
  opacity: 0;
}

.plusSign {
  margin-left: 2px;
}

.label {
  line-height: 14px;
  margin-left: 4px;
  color: colors.$pr-900;
}
</style>
