RoomDocumentsSearchListController.$inject = [
  "$scope",
  "$stateParams",
  "$rootScope",
  "RoomConfig",
  "DocumentsService",
  "TasksService",
  "CategoriesService",
  "FileUploadService",
];
import { startsWith } from "lodash-es";
import { values } from "lodash-es";

import DocumentsFilterService from "../services/DocumentsFilterService";
import RoomDocumentFolderService from "../services/RoomDocumentFolderService";

export default function RoomDocumentsSearchListController(
  $scope,
  $stateParams,
  $rootScope,
  RoomConfig,
  DocumentsService,
  TasksService,
  CategoriesService,
  FileUploadService,
) {
  var self = this;
  var docsCtrl = $scope.docsCtrl;

  docsCtrl.recent = $stateParams.recent ?? false;
  docsCtrl.bookmarks = $stateParams.bookmarks ?? false;
  docsCtrl.archive = $stateParams.archive ?? false;
  docsCtrl.isAiReports = false;

  self.recent = $stateParams.recent;
  self.bookmarks = $stateParams.bookmarks;

  self.Tasks = TasksService;
  self.Categories = CategoriesService;
  self.Documents = DocumentsService;
  self.DocsFilter = DocumentsFilterService;
  self.dataService = undefined;

  self.folderId = parseInt($stateParams.folderId);
  self.folder = undefined;
  self.FileUploadService = FileUploadService;
  self.updateItemsList = updateItemsList;
  self.editFolderIndexes = editFolderIndexes;

  self.uploadAllowedFolders = [];

  self.DocsFilter.setSearchQuery($stateParams.query).then(() =>
    $scope.$root.$applyAsync(),
  );

  self.DocsFilter.filtersEnabled = self.bookmarks || self.recent;

  self.sortConfig = {
    field: self.recent
      ? "date_updated"
      : self.bookmarks
        ? "bookmarked"
        : self.DocsFilter.searchQuery
          ? undefined // do not sort (sorted in search)
          : "treeIndex",
    order: (self.bookmarks ? true : !!self.recent) ? "desc" : "asc",
  };

  self.allowDocVerQA =
    RoomConfig.userPermissions.administrator && RoomConfig.isAiEnabled;

  self.toggleItemSelected = function (item, $event) {
    self.dataService.toggleItemSelected(item, $event);
  };

  self.toggleSelectAllCheckbox = function () {
    self.dataService.update();
  };

  self.bulkUnbookmark = function () {
    self.Documents.bookmarkItems(
      self.dataService.getSelectedFoldersId(),
      self.dataService.getSelectedDocumentsId(),
      false,
    );
  };

  function getDescendants(folder) {
    var folderTreeIndex = folder.parent_id ? folder.treeIndex : "";
    var underTreeIndex = function (i) {
      return (
        startsWith(i.treeIndex, folderTreeIndex) &&
        i.treeIndex != folder.treeIndex
      );
    };

    var files = values(DocumentsService.Files).filter(underTreeIndex);
    var folders = values(DocumentsService.Folders).filter(underTreeIndex);
    return { files: files, folders: folders };
  }

  function editFolderIndexes() {
    if (!(self.folder && self.folder.items.length)) {
      return;
    }
    const item = self.folder.items[0];
    const method =
      item.type === "file"
        ? DocumentsService.editDocumentIndex
        : DocumentsService.editFolderIndex;
    method(item);
  }

  function updateItemsList() {
    var mode;
    if (self.bookmarks) {
      mode = "bookmarks";
    } else if (self.recent) {
      mode = "recent";
    } else {
      mode = "folder";
    }

    docsCtrl.selectedFolderId = undefined;
    var files, folders;
    if (mode === "bookmarks") {
      var bookmarkedStorages = self.Documents.getFlattedBookmarkedStorages();
      files = bookmarkedStorages.files;
      folders = bookmarkedStorages.folders;
    } else if (mode === "recent") {
      files = values(self.Documents.Files);
      folders = [];
    } else if (mode === "folder") {
      self.folder =
        self.Documents.Folders[self.folderId] || self.Documents.rootFolder;
      docsCtrl.selectedFolderId = self.folder.id;
      if (!self.DocsFilter.searchQuery) {
        // normal mode, show only folder children
        files = self.folder.files;
        folders = self.folder.folders;
      } else {
        // search mode
        // show folder nested tree
        const descendants = getDescendants(self.folder);
        files = descendants.files;
        folders = descendants.folders;

        if (self.folder === self.Documents.rootFolder) {
          // include viewable docs under inaccessible paths
          const hiddenDocs = values(self.Documents.Files).filter(
            (f) => !(f.folder_id in self.Documents.Folders),
          );

          files = files.concat(hiddenDocs);
        }
      }
    }

    var items = self.DocsFilter.findItems(files, folders);

    //TODO: keep same service with selected items
    self.dataService = RoomDocumentFolderService.getInstance(items);

    self.uploadAllowedFolders = values(DocumentsService.Folders).filter(
      function (folder) {
        return folder.edit;
      },
    );
  }

  var handleBookmarkUpdate = $rootScope.$on(
    "bookmark:changed",
    updateItemsList,
  );

  $scope.$on("$destroy", function () {
    handleBookmarkUpdate();
  });

  self.loading =
    self.Documents.loading === null ||
    self.Documents.loading === true ||
    self.DocsFilter.loading;

  self.error = self.Documents.error || self.DocsFilter.error;
  // ignore firing the first $watch
  var immediateLoad = !self.loading && !self.error;
  if (immediateLoad) {
    updateItemsList();
  }

  $scope.$watchGroup(
    [
      "ctrl.Documents.loading",
      "ctrl.DocsFilter.searchQuery",
      "ctrl.DocsFilter.loading",
    ],

    function () {
      self.loading =
        self.Documents.loading === null ||
        self.Documents.loading === true ||
        self.DocsFilter.loading;

      self.error = self.Documents.error || self.DocsFilter.error;

      if (!self.loading && !self.error && !immediateLoad) {
        updateItemsList();
      }
      immediateLoad = false;
    },
  );
}
