import analyticsCardAssigneesDistributionHtml from "./index.html?raw";

AnalyticsTasksAssigneeDistributionCard.$inject = [
  "AnalyticsDataDispatcher",
  "AnalyticsTasksAssigneeDistributionStatsService",
  "ActivityGroupPermissionsVisibleService",
  "TasksService",
  "MembersService",
  "ActivityVisibleCategoriesService",
];
import "./assignee-distribution";
import "./assignee-distribution-legend";
import "./service.stats";

import * as d3 from "d3";

angular
  .module("dealroom.analytics.card.assignees-distribution", [
    "dealroom.analytics.assignees-distribution",
    "dealroom.analytics.assignees-distribution-legend",
    "dealroom.analytics.assignees-distribution.service.stats",

    "dealroom.analytics.service.data",
    "dealroom.task",
  ])
  .component("drAnalyticsTasksAssigneeDistributionCard", {
    template: analyticsCardAssigneesDistributionHtml,
    controller: AnalyticsTasksAssigneeDistributionCard,
  });

function AnalyticsTasksAssigneeDistributionCard(
  AnalyticsDataDispatcher,
  AnalyticsTasksAssigneeDistributionStatsService,
  ActivityGroupPermissionsVisibleService,
  TasksService,
  MembersService,
  ActivityVisibleCategoriesService,
) {
  const $ctrl = this;
  const pk = "drAnalyticsTasksAssigneeDistributionCard";
  let _tasksByGroups, _selectedType;

  $ctrl.dispatcher = d3.dispatch("update");
  $ctrl.types = [
    {
      label: "Absolute Numbers",
      isPercentage: false,
    },
    {
      label: "Percentage",
      isPercentage: true,
      default: true,
    },
  ];

  $ctrl.handleSelect = handleSelect;

  AnalyticsDataDispatcher.on("filtersChanged." + pk, mkNest);

  function mkNest() {
    const visibleTasksIds =
      ActivityVisibleCategoriesService.visibleStatsSummary.taskIds;
    _tasksByGroups = visibleTasksIds.reduce(reduceByAssigneeGroup, {});
    update();
  }

  function handleSelect(selected) {
    _selectedType = selected;
    update();
  }

  function update() {
    if (_selectedType === undefined) return;
    if (_tasksByGroups === undefined) return;
    const nested = Object.keys(_tasksByGroups).reduce((bucket, pgroupId) => {
      bucket[pgroupId] = d3.values(_tasksByGroups[pgroupId]);
      return bucket;
    }, {});
    const data =
      ActivityGroupPermissionsVisibleService.visiblePgroupIds.map(
        getPgroupStats,
      );

    const total = data.reduce(getSum, 0);
    $ctrl.dispatcher.call(
      "update",
      null,
      data,
      _selectedType.isPercentage,
      total,
    );

    function getPgroupStats(pgroupId) {
      const value =
        nested[pgroupId] ||
        d3.values(
          AnalyticsTasksAssigneeDistributionStatsService.getEmptyStats(),
        );

      return {
        key: pgroupId,
        value,
      };
    }

    function getSum(count, { value }) {
      const total = value.reduce((c, { value }) => c + value, 0);
      return count + total;
    }
  }

  function reduceByAssigneeGroup(bucket, taskId) {
    const task = TasksService.tasks[taskId];
    task.assignees.forEach(function ({ user_id }) {
      const user = MembersService.members[user_id];
      if (user && user.is_archived !== true) {
        const pgroupId = user.pgroup.id;
        if (bucket[pgroupId] === undefined) {
          bucket[pgroupId] =
            AnalyticsTasksAssigneeDistributionStatsService.getEmptyStats();
        }
        const type =
          AnalyticsTasksAssigneeDistributionStatsService.getTaskType(task);
        bucket[pgroupId][type].value += 1;
      }
    });
    return bucket;
  }
}
