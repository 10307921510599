import { USER_DATA } from "@setups/data";
import { http } from "@drVue/api-service/http";
import { ApiService } from "../index";

import type { AvatarResponse } from "@drVue/components/client-dashboard/users/AccountSettings/ProfileService";
import type { Dictionary } from "@drVue/types";
import type { UserAvatar, UserProfile } from "@setups/data";

export enum NotificationsScheduleOption {
  "immediately" = "Immediately",
  "once a hour" = "Hourly",
  "once a day" = "Daily",
  "every week" = "Weekly",
  "off" = "Disabled",
}

export interface ChangePasswordRequest {
  oldpassword: string;
  password1: string;
  password2: string;
}

export class ProfileApiService extends ApiService {
  get profileUrl() {
    return this.Urls["api:users:user"](USER_DATA.id);
  }

  get avatarUrl() {
    return this.Urls["api:users:profile_avatar"](USER_DATA.id);
  }

  public update(profile: Partial<UserProfile>) {
    return http.post(this.profileUrl, profile);
  }

  public uploadAvatar(avatar: File) {
    const formData = new FormData();
    formData.append("avatar", avatar);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    return http
      .post<AvatarResponse>(this.avatarUrl, formData, config)
      .then((r) => r.data.avatar);
  }

  public deleteAvatar() {
    return http.delete(this.avatarUrl).then(
      (): UserAvatar => ({
        thumbnail: null,
        reduced: null,
      }),
    );
  }

  public changePassword(request: ChangePasswordRequest) {
    const url = this.Urls["api:users:password_change"](USER_DATA.id);
    return http.post(url, request);
  }

  public getScheduleOptions() {
    const url = this.Urls["api:users:notification_schedule"]();
    return http.get<Dictionary<string>>(url);
  }
}
