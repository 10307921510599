import * as d3 from "d3";
window.d3 = d3; // DEBUG

import "./components";
import "./analytics.card.assignees-distribution";
import "./analytics.card.chart.header";
import "./analytics.card.burndown";
import "./analytics.card.heatmap";
import "./analytics.card.linechart";
import "./analytics.dropdown";
import "./analytics.tooltip";
import "./analytics.datagrid-table";
import "./analytics.linechart";
import "./analytics.heatmap";
import "./analytics.svg";
import "./analytics.progressbar";
import "./analytics.factory.dispatcher";
import "./analytics.factory.templates";
import "./analytics.service.export";
import "./analytics.service.colors-scheme";
import "./analytics.service.data";
import "./analytics.service.filter";
import "./analytics.service.labels";
import "./analytics.service.nest";

import { ORG_MEMBER_DATA } from "../setups";

(function () {
  "use strict";

  angular
    .module("dealroom.analytics", [
      "dealroom.analytics.components",

      "dealroom.analytics.card.chart.header",
      "dealroom.analytics.card.assignees-distribution",
      "dealroom.analytics.card.burndown",
      "dealroom.analytics.card.heatmap",
      "dealroom.analytics.card.linechart",

      "dealroom.analytics.datagrid-table",

      "dealroom.analytics.dropdown",
      "dealroom.analytics.dropdown.dates-range",
      "dealroom.analytics.dropdown.export",
      "dealroom.analytics.dropdown.pgroups",
      "dealroom.analytics.dropdown.priorities",
      "dealroom.analytics.dropdown.requests-lists",
      "dealroom.analytics.dropdown.statuses",

      "dealroom.analytics.tooltip",

      "dealroom.analytics.svg",
      "dealroom.analytics.heatmap",
      "dealroom.analytics.linechart",
      "dealroom.analytics.progressbar",

      "dealroom.analytics.factory.dispatcher",
      "dealroom.analytics.factory.templates",

      "dealroom.analytics.service.export",
      "dealroom.analytics.service.colors-scheme",
      "dealroom.analytics.service.data",
      "dealroom.analytics.service.filter",
      "dealroom.analytics.service.labels",
      "dealroom.analytics.service.nest",
    ])
    .controller("DashboardController", DashboardController)
    .controller(
      "DashboardOverviewController",
      DashboardOverviewActivityController,
    )
    .controller(
      "DashboardTasksProgressController",
      DashboardTasksProgressController,
    )
    .controller(
      "DashboardTasksActivityController",
      DashboardTasksActivityController,
    )
    .controller(
      "DashboardIndexActivityController",
      DashboardDocumentsActivityController,
    )
    .controller("DashboardActiveController", DashboardUsersActivityController)
    .controller("DashboardAuditController", DashboardAuditController)
    .service("ActivityServiceDebug", ActivityServiceDebug)
    .run([
      "ActivityServiceDebug",
      function (ActivityServiceDebug) {
        // ActivityServiceDebug.inject();
      },
    ]);

  DashboardController.$inject = [
    "$state",
    "RoomConfig",
    "AnalyticsApiService",
    "AnalyticsActivityDataService",
  ];
  function DashboardController(
    $state,
    RoomConfig,
    AnalyticsApiService,
    AnalyticsActivityDataService,
  ) {
    const analyticsCtrl = this;
    analyticsCtrl.$state = $state;
    analyticsCtrl.showTasksAnalytics =
      $state.get("analytics.overview").permissions;
    analyticsCtrl.isAlpha = RoomConfig.isAlpha;
    analyticsCtrl.dataService = AnalyticsActivityDataService;

    if ($state.current.name === "analytics") {
      let goToState = "analytics.documents";
      if (analyticsCtrl.showTasksAnalytics) {
        goToState = "analytics.overview";
      }
      $state.go(goToState);
    }

    AnalyticsApiService.startLoading();
  }

  DashboardOverviewActivityController.$inject = ["$scope"];
  function DashboardOverviewActivityController($scope) {
    this.mode = "absolute"; // "absolute" | "relative"

    this.modes = [
      {
        default: true,
        label: "Absolute Numbers",
        id: "absolute",
      },
      {
        default: false,
        label: "Percentage",
        id: "relative",
      },
    ];

    this.handleSelect = (selectedMode) =>
      $scope.$applyAsync(() => (this.mode = selectedMode.id));
  }

  DashboardTasksProgressController.$inject = [];
  function DashboardTasksProgressController() {}

  DashboardTasksActivityController.$inject = [];
  function DashboardTasksActivityController() {}

  DashboardDocumentsActivityController.$inject = [];
  function DashboardDocumentsActivityController() {}

  DashboardUsersActivityController.$inject = [];
  function DashboardUsersActivityController() {}

  DashboardAuditController.$inject = [
    "DashboardFilter",
    "AuditLogExportService",
  ];
  function DashboardAuditController(DashboardFilter, AuditLogExportService) {
    const ctrl = this;
    ctrl.filter = DashboardFilter.get();
    ctrl.exportService = AuditLogExportService;

    ctrl.getFilters = function () {
      const auditFilters = ctrl.filter;
      return {
        from_d: auditFilters.fromData,
        to_d: auditFilters.toData,
        action: auditFilters.action !== "all" ? auditFilters.action : undefined,
        group: auditFilters.group,
        user: auditFilters.actors,
      };
    };
  }

  function ActivityServiceDebug(/* ...services */) {
    const services = Array.from(arguments);
    this.inject = () => {
      d3.zip(ActivityServiceDebug.$inject, services).forEach(
        ([name, service]) => {
          window["_" + name] = service;
        },
      );
    };
  }
  ActivityServiceDebug.$inject = [
    "ActivityNestService",
    "ActivityLabelService",
    "DocumentsService",
    "MembersService",
    "PermissionsService",
    "TasksService",
    "CategoriesService",

    "ActivityVisibleCategoriesService",
    "ActivityCategoriesService",
    "ActivityGroupPermissionsVisibleService",
    "ActivityTasksService",
    "AnalyticsDataDispatcher",
    "AnalyticsActivityDataService",
    "ActivityFilterService",
    "ActivityFilterDatesService",
    "ActivityFilterMembersService",
    "ActivityFilterTasksService",
  ];
})();
