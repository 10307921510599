<template>
  <div :class="$style.container" @click.stop>
    <div v-if="isViewing" :class="$style.viewerContainer">
      <div :class="$style.viewerHeader">
        <DynamicDateIndicatorIcon disable-tooltip />
        <span><b>Dynamic Date&nbsp;</b>is set to:</span>
        <b>{{ viewerTitle }}</b>
      </div>
      <ElDivider :class="$style.divider" />
      <div :class="$style.footer">
        <ElButton :loading="isProcessing" @click.stop="$emit('set-static')">
          Set static date
        </ElButton>
        <ElButton :class="$style.editBtn" @click.stop="isEditing = true">
          Edit dynamic date
        </ElButton>
      </div>
    </div>
    <div v-else :class="$style.editorContainer">
      <span>This task will be due:</span>
      <div :class="$style.editorForm">
        <ElInput v-model="formDaysCount">
          <template #suffix>days</template>
        </ElInput>
        <ElSelect v-model="formTimeMarker" :teleported="false">
          <ElOption label="Before" value="before" />
          <ElOption label="After" value="after" />
        </ElSelect>
        <!-- @vue-skip -->
        <ElFormItem :error="milestoneError">
          <ElSelect
            placeholder="Select milestone"
            :teleported="false"
            :model-value="formMilestone"
            @change="handleMilestoneChange"
          >
            <template #prefix>
              <DrIcon name="calendar" />
            </template>
            <ElOption
              v-for="m in milestoneCustomFields"
              :key="m.key"
              :value="m.key"
              :label="m.label"
            />
          </ElSelect>
        </ElFormItem>
      </div>
      <ElDivider :class="$style.divider" />
      <div :class="$style.footer">
        <ElButton @click.stop="handleCancel">Cancel</ElButton>
        <ElButton
          type="primary"
          :loading="isProcessing"
          @click.stop="handleSubmit"
        >
          Set dynamic date
        </ElButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// NOTE: @click.stop is really needed only for angular compatibility
import {
  ElButton,
  ElDivider,
  ElFormItem,
  ElInput,
  ElOption,
  ElSelect,
} from "element-plus";
import { computed, ref, watch } from "vue";
import DrIcon from "@shared/ui/dr-icon/DrIcon.vue";

import { DrStore } from "@app/vue";
import DynamicDateIndicatorIcon from "@drVue/components/room/tasks/shared/DynamicDateIndicatorIcon.vue";
import $utils from "@drVue/utils";

import type { FieldItem } from "@drVue/store/modules/client-dashboard/fields/types";
import type { TaskDynamicDateConf } from "@drVue/store/pinia/room/tasks";

interface Props {
  isActive?: boolean;
  deltaDays?: number;
  dealFieldKey?: string;
  isProcessing?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isActive: false,
  deltaDays: 0,
  dealFieldKey: undefined,
  isProcessing: false,
});

interface Events {
  (e: "submit", payload: TaskDynamicDateConf): void;
  (e: "set-static"): void;
  (e: "cancel"): void;
}

const emit = defineEmits<Events>();

const isEditing = ref(false);
const isViewing = computed(() => !isEditing.value && props.isActive);

const milestoneCustomFields = computed<FieldItem[]>(() =>
  DrStore.getters["clientDashboard/customFields/byObjectType"]("deal").filter(
    ({ field_type }: FieldItem) => field_type === "date",
  ),
);

const formMilestone = ref<string | null>(null);
const milestoneLabel = computed<FieldItem>(() => {
  if (props.dealFieldKey) {
    return DrStore.getters["clientDashboard/customFields/byObjectType"](
      "deal",
    ).find(({ key }: FieldItem) => key === props.dealFieldKey).label;
  }

  if (milestoneCustomFields.value.length) {
    return milestoneCustomFields.value[0].label;
  }

  return "";
});

const formDaysCount = ref(10);
const daysCount = computed(() => {
  if (!props.deltaDays) return 0;
  return Math.abs(props.deltaDays);
});

const formTimeMarker = ref("before");
const timeMarker = computed(() => {
  if (!props.deltaDays) return "before";
  return props.deltaDays > 0 ? "after" : "before";
});

const viewerTitle = computed(() =>
  [
    daysCount.value,
    $utils.text.pluralize("day", daysCount.value),
    timeMarker.value,
    milestoneLabel.value,
  ].join(" "),
);

watch(
  () => props.deltaDays,
  (daysCount) => {
    if (daysCount) {
      formDaysCount.value = Math.abs(daysCount);
      formTimeMarker.value = daysCount > 0 ? "after" : "before";
    }
  },
  { immediate: true },
);

watch(
  () => props.dealFieldKey,
  (key) => {
    if (key) {
      formMilestone.value = key;
    }
  },
  { immediate: true },
);

const milestoneError = ref<string | null>(null);

function handleMilestoneChange(m: string) {
  milestoneError.value = null;
  formMilestone.value = m;
}

async function handleSubmit() {
  if (!formMilestone.value) {
    milestoneError.value = "Please select milestone";
    return;
  }

  const deltaFactor = formTimeMarker.value === "before" ? -1 : 1;
  const delta_days = formDaysCount.value * deltaFactor;
  const deal_field_key = formMilestone.value;

  emit("submit", {
    deal_field_key,
    delta_days,
    is_deal_start_date_field: false,
  });
  isEditing.value = false;
}

function handleCancel() {
  isEditing.value = false;
  if (!props.isActive) {
    emit("cancel");
  }
}
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.container {
  padding: 12px 12px 2px 12px;
  color: colors.$pr-900;
}

.viewerContainer {
  min-width: 312px;
}

.editorContainer {
  min-width: 408px;
}

.viewerHeader {
  display: grid;
  grid-template-areas: "icon line1" "line2 line2";
  grid-template-columns: 14px auto;
  grid-template-rows: auto auto;
  align-items: center;
  gap: 8px;

  :global {
    div {
      grid-area: icon;
    }
    span {
      grid-area: line1;
    }
    b {
      grid-area: line2;
      font-weight: 600;
    }
  }
}

.editorHeader {
  font: typography.$body_regular;
  color: colors.$pr-900;
}

.editorForm {
  display: grid;
  grid-template-columns: 80px 90px 1fr;
  grid-gap: 8px;
  margin-top: 8px;

  :global(.el-input__inner) {
    color: colors.$pr-900;
  }

  :global(.el-form-item) {
    margin-bottom: unset;
  }

  :global(.el-input__inner) {
    --el-input-inner-height: 32px;
  }
}

.divider {
  margin: 14px -13px !important;
  width: calc(100% + 26px) !important;
}

.footer {
  display: flex;
  justify-content: flex-end;
}

.editBtn {
  border-color: colors.$sc-400 !important;
  color: colors.$sc-600 !important;

  &:hover {
    border-color: colors.$sc-500 !important;
    color: colors.$sc-700 !important;
  }
}
</style>
