import { type Document } from "@app/vue/store/modules/room/documents/DocumentsApiService";

import type { ComputedRef, InjectionKey } from "vue";

export const UploadListBatchInjectionKey: InjectionKey<{
  getDuplicatePrimaryButtonText: (
    batch: Batch,
    duplicate: Document | undefined,
  ) => string;
  onBatchAction: ComputedRef<(batch: Batch) => void>;
  onBatchRenamed: ComputedRef<(batch: Batch) => void>;
}> = Symbol("UploadListBatchInjectionKey");

export interface RowPermissions {
  can_view: boolean;
  can_download_watermaked: boolean;
  can_download_original: boolean;
  can_edit: boolean;
}

export type RowPermissionsKey = keyof RowPermissions;

export interface PermissionTableRow {
  group_id: number;
  group_name: string;
  has_access: boolean;
  permissions: RowPermissions;
  notify: boolean;
}

// This type is incomplete, it's missing a lot of keys that are being added
// dynamically in the code after uploadToFolder() is called.
export interface DrUploadFile extends File {
  path: string;
}

export interface DrUploadDirectory {
  type: "directory";
  name: string;
  path: string;
}

export type FileBatchItem = File | DrUploadFile;
export type FolderBatchItem = File | DrUploadFile | DrUploadDirectory;
export type BatchItem = FileBatchItem | FolderBatchItem;

export interface FileBatch {
  id: number;
  name: string;
  originalName: string;
  iconClass: string;
  type: "file";
  items: FileBatchItem[];
}

export interface FolderBatch {
  id: number;
  name: string;
  originalName: string;
  iconClass: string;
  type: "folder";
  items: FolderBatchItem[];
}

export type Batch = FileBatch | FolderBatch;
