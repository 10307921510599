import type { State } from "./state";
import type { Notification } from "@drVue/store/modules/common/notifications/api";
import type { MutationTree } from "vuex";

export const mutations: MutationTree<State> = {
  addNotifications(state, notifications: Notification[]) {
    state.notifications.splice(state.notifications.length, 0, ...notifications);
  },

  setNextUrl(state, nextUrl: string | null) {
    state.nextUrl = nextUrl;
  },

  resetState(state) {
    state.nextUrl = null;
    state.notifications = [];
  },

  setIsLoading(state, value: boolean) {
    state.isLoading = value;
  },
};
