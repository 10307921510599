<template>
  <DrItemList :items="preparedItems">
    <template #header>
      <slot name="header" />
    </template>

    <template #item="{ item }">
      <DrItemListItem
        :allow-remove="!isReadOnly"
        @remove="$emit('remove', item)"
      >
        <span :class="$style.container">
          <DrAvatar :url="item.avatar" :name="item.name ?? anon" />
          <span :class="$style.name">{{ item.name ?? anon }}</span>
        </span>
      </DrItemListItem>
    </template>
  </DrItemList>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { DrAvatar } from "@shared/ui/dr-avatar";
import { DrItemList, DrItemListItem } from "@shared/ui/dr-item-list";

import { DrStore } from "@app/vue";

type Item = { user_id: string }; // actually it is uid

type LocalItem = Item & {
  id: string; // for compatibility with DrItemListItem
  name: string;
  avatar: string;
  email: string;
};

interface Props {
  items: Item[];
  isReadOnly?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isReadOnly: false,
});

defineEmits<{
  (event: "remove", item: LocalItem): void;
}>();

const anon = "Anonymous";

const preparedItems = computed<LocalItem[]>(() => {
  return props.items.reduce((items, item) => {
    const user = DrStore.state.room.members.membersByUid[item.user_id];
    if (user) {
      items.push({
        id: item.user_id,
        user_id: item.user_id,
        name: user.name,
        email: user.email,
        avatar: user.avatar.reduced || "",
      });
    }
    return items;
  }, [] as LocalItem[]);
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.header {
  color: colors.$pr-900;
  font: typography.$body_medium;
  margin-bottom: spacing.$xxs;
}

.container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.name {
  font: typography.$body_regular;
  color: colors.$pr-900;
}
</style>
