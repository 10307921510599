import * as d3 from "d3";

angular
  .module("dealroom.analytics.datagrid-table.table.body.cell", [
    "dealroom.analytics.factory.templates",
  ])
  .service(
    "AnalyticsDatagridTableCellRenderService",
    AnalyticsDatagridTableCellRenderService,
  );

AnalyticsDatagridTableCellRenderService.$inject = ["AnalyticsTemplatesFactory"];
function AnalyticsDatagridTableCellRenderService(AnalyticsTemplatesFactory) {
  return {
    renderCells,
    removeCell,
  };

  function removeCell(d) {
    AnalyticsTemplatesFactory.remove(this);
  }

  function renderCells(cells, isOpen, onExpand) {
    cells.each(render);

    function render(d) {
      const cell = d3.select(this);
      const classes = ["flex-table__column", d.class];
      cell.attr("class", classes.join(" "));

      if (d.key === "title") {
        cell.call(renderTitle, isOpen, onExpand);
      } else {
        cell.call(renderCellTemplate);
      }
    }
  }

  function renderCellTemplate(cell) {
    const d = cell.datum();
    const value = AnalyticsTemplatesFactory.render(cell.node(), d);
    if (["string", "number"].includes(typeof value)) {
      cell.classed("datagrid-table__column--data-stats", d.type === undefined);
      cell.html(value);
    }
  }

  function renderTitle(cell, isOpen, onExpand) {
    const d = cell.datum();
    let content = cell.select(".collapsable-content");
    if (content.empty()) {
      content = createTitleCell();
    }
    content.call(updateExpand, isOpen, onExpand);

    function createTitleCell() {
      content = cell
        .append("div")
        .attr("class", "collapsable-content")
        .style("padding-left", 12 * d.paddingLevel + "px");

      content
        .append("span")
        .attr("class", "collapse-icon")
        .style("display", "none")
        .classed("collapse-icon--bottom", d.alignBottom)
        .append("i")
        .attr("class", "fa");

      content
        .append("div")
        .attr("class", "collapsable-content__inner truncate")
        .call(renderCellTemplate);

      return content;
    }

    function updateExpand(content, isOpen, onExpand) {
      const expand = content.select(".collapse-icon");
      expand.style("display", onExpand === undefined ? "none" : null);
      expand
        .select(".fa")
        .classed("fa-caret-right", !isOpen)
        .classed("fa-caret-down", isOpen);
      expand.on("click", onExpand);
    }
  }
}
