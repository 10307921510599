<template>
  <PriorityEdit :edit-props="editProps" @change="handleChange" />
</template>

<script setup lang="ts">
import { insightTrack, TaskDetailsTrackingEvent } from "@app/insight";
import { PriorityEdit } from "@drVue/components/room/tasks/shared/widgets/priority";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { Task } from "@drVue/store/pinia/room/tasks";
import type { PriorityValue } from "@shared/ui/dr-icon-priority";

interface Props {
  editProps: EditFieldProps<PriorityValue, Task>;
}
defineProps<Props>();

function handleChange() {
  insightTrack(TaskDetailsTrackingEvent.PriorityChanged);
}
</script>
