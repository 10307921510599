<template>
  <div v-if="isRoomLocation" :class="$style.nav">
    <div :class="$style.roomInfo">
      <RoomHeaderNavTitle />
    </div>
    <div :class="$style.separator" />
    <div :class="$style.tabs">
      <RoomTabsNav />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useIsRoomLocation } from "../../navigation/utils";
import RoomHeaderNavTitle from "../common/RoomHeaderNavTitle.vue";
import RoomTabsNav from "./RoomTabsNav.vue";

const { isRoomLocation } = useIsRoomLocation();
</script>

<style lang="scss" module>
@use "@app/styles/scss/_colors";
@use "@app/styles/scss/typography" as typo;
.nav {
  display: flex;
  height: 48px;
  align-items: center;
  background: colors.$pr-50;
  box-shadow: 0 -1px 0 0 colors.$pr-150 inset;
}

.roomInfo {
  flex: 0 0 264px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.separator {
  flex: 0 0 1px;
  display: inline-block;
  height: 16px;
  border-left: 1px solid colors.$pr-150;
}

.tabs {
  flex: 1 1 auto;
  height: 100%;
  padding: 0 20px;
}
</style>
