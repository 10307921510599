<template>
  <div :class="$style.row">
    <DrIcon
      v-if="!fixed"
      name="grip-vertical"
      size="sm"
      :class="$style.drag"
      drag
    />

    <ElCheckbox v-model="isChecked" border :readonly="fixed">
      {{ title }}
    </ElCheckbox>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import DrIcon from "../dr-icon";

import type { SorterRow } from "./types";

/**
 * Due to limitation
 * "the generic argument itself passed to defineProps cannot be an imported type"
 * it is not possible to do just
 * ```const props = defineProps<SorterRow>();```
 */
interface Props {
  fixed: SorterRow["fixed"];
  visible: SorterRow["visible"];
  title: SorterRow["title"];
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: "update-visible", isVisible: boolean): void;
}>();

const isChecked = computed({
  get() {
    return props.visible;
  },
  set() {
    emit("update-visible", !props.visible);
  },
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/values";
@use "@app/styles/scss/typography" as typo;

.row {
  height: values.$base-input-height;
  display: flex;
  align-items: center;
  gap: spacing.$xxs;

  :global {
    .el-checkbox.is-bordered {
      width: 100%;
      margin: 0;
      padding: spacing.$xs;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 8px;
      border-color: colors.$pr-300;

      &[readonly] {
        border-color: colors.$pr-300;
        background: none;

        &,
        .el-checkbox__input {
          cursor: default;
        }
      }

      .el-checkbox__input .el-checkbox__inner {
        border-color: colors.$pr-350;
      }

      &.is-checked[readonly] {
        .el-checkbox__input .el-checkbox__inner {
          border-color: colors.$pr-300;
          background: colors.$pr-300;
        }
      }

      &.is-checked:not([readonly]) {
        border-color: colors.$sc-300;
        background: colors.$sc-50;

        .el-checkbox__input .el-checkbox__inner {
          background: colors.$sc-600;
          border-color: colors.$sc-600;
        }
      }

      &:hover:not([readonly]) {
        border-color: colors.$sc-500;
      }

      &:hover:not(.is-checked, [readonly]) {
        .el-checkbox__input .el-checkbox__inner {
          border-color: colors.$sc-500;
        }
      }

      .el-checkbox__label {
        display: inline;
        color: colors.$pr-900;
        font: typo.$caption_regular;
        padding-left: spacing.$xs;
      }
    }
  }
}

.drag {
  color: colors.$pr-350;
  cursor: grab;
}
</style>
