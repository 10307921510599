import { ApiService } from "@drVue/api-service";

export interface TaskLabel {
  uid: string;
  id: number;
  name: string;
  bg_color: `#${string}`;
  label_color: `#${string}`;
}

export type TaskLabelCreatePayload = Omit<TaskLabel, "id" | "uid">;
export type TaskLabelUpdatePayload = TaskLabel;

export class TasksLabelsApiService {
  constructor(private api: ApiService = new ApiService()) {}

  public load(): Promise<TaskLabel[]> {
    const url = this.api.Urls["api:room:labels-list"]();
    return this.api.get(url);
  }

  public create(label: TaskLabelCreatePayload): Promise<TaskLabel> {
    const url = this.api.Urls["api:room:labels-list"]();
    return this.api.post(url, label);
  }

  public update(
    id: TaskLabel["id"],
    changes: TaskLabelUpdatePayload,
  ): Promise<TaskLabel> {
    const url = this.api.Urls["api:room:labels-detail"](id);
    return this.api.put(url, changes);
  }

  public delete(labelId: TaskLabel["id"]): Promise<void> {
    const url = this.api.Urls["api:room:labels-detail"](labelId);
    return this.api.delete(url);
  }
}
