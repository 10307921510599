<template>
  <div class="notes-list__container">
    <div class="notes-list__header">
      <span>
        <UserInformation :user="author" :is-link="false" size="30px" />
      </span>
      <div class="notes-list__header-spacer" />
      {{ date_added }}
      <div v-if="isEdited(note)" class="notes-list__header-spacer" />
      <span v-if="isEdited(note)">
        Updated:
        {{ date_updated }}
      </span>
      <div v-if="editable" class="notes-list__header-action">
        <ElDropdown trigger="click" size="small">
          <span>
            <i
              class="far fa-ellipsis-v fa-lg"
              data-testid="deals-notes-delete-menu-btn"
            />
          </span>
          <template #dropdown>
            <ElDropdownMenu>
              <ElDropdownItem @click="showEdit = true">
                <i class="fas fa-pencil" />
                Edit
              </ElDropdownItem>
              <ElDropdownItem @click="deleteItem()">
                <i class="fas fa-trash" />
                Delete
              </ElDropdownItem>
            </ElDropdownMenu>
          </template>
        </ElDropdown>
      </div>
    </div>
    <div class="notes-list__content">
      <EditForm
        v-if="showEdit"
        :note="note"
        :expand-editor="true"
        :notes-store-prefix="notesStorePrefix"
        :object-id="objectId"
        @close="showEdit = false"
      />
      <DrEditor v-else :model-value="note.body" :editable="false" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, h } from "vue";
import DrForm from "@shared/ui/dr-form";
import { DrEditor } from "@shared/ui/editor";
import { UserInformation } from "@shared/ui/users";

import { DashboardCardEvent, insightTrack } from "@app/insight";
import { drUserTime } from "@drVue/filters/drUserTime";
import DrStore from "@drVue/store";
import EditForm from "../EditForm";

import type { Note } from "@drVue/store/modules/client-dashboard/notes/types";
import type { PropType } from "vue";

interface Data {
  showEdit: boolean;
}

export default defineComponent({
  name: "NoteRow",
  components: {
    DrEditor,
    EditForm,
    UserInformation,
  },
  extends: DrForm,
  props: {
    notesStorePrefix: { required: true, type: String as PropType<string> },
    objectId: { required: true, type: Number as PropType<number | string> },
    note: { required: true, type: Object as PropType<Note> },
    editable: { type: Boolean as PropType<boolean>, default: false },
  },
  data(): Data {
    return {
      showEdit: false,
    };
  },
  computed: {
    date_added() {
      return drUserTime(this.note.date_added, "full-dt");
    },
    date_updated() {
      return drUserTime(this.note.date_updated, "full-date");
    },
    author(): any {
      return DrStore.getters["clientDashboard/orgUsers/byLegacyId"](
        this.note.sender_id,
      );
    },
  },
  methods: {
    deleteItem() {
      this.$confirm(
        h("div", {}, [
          "Are you sure you want to remove this note?",
          // TODO @dgonchar: remove 'as any'.
          h(DrEditor as any, { value: this.note.body, editable: false }),
        ]) as any,
        `Remove note`,
        {
          confirmButtonText: "Remove",
          cancelButtonText: "Cancel",
          type: "warning",
        },
      ).then(() => {
        return this.submitAction(
          `${this.notesStorePrefix}/deleteNote`,
          this.note,
          "Failed to delete note",
        ).then(() => {
          insightTrack(DashboardCardEvent.Removed);
        });
      });
    },
    isEdited(note: Note) {
      const editTimeOffset = 3000; // 3 sec
      return (
        note.date_updated.getTime() - note.date_added.getTime() > editTimeOffset
      );
    },
  },
});
</script>
