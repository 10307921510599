<template>
  <div class="users-tree">
    <div v-if="tree">
      <ElSelect
        ref="select"
        v-model="selectModel"
        multiple
        filterable
        reserve-keyword
        placeholder="Type to search..."
        class="el-select--full-width"
        :filter-method="filterMethod"
        @change="handleSelectChange"
        @visible-change="handleSelectVisibleChange"
      >
        <ElTree
          ref="tree"
          show-checkbox
          node-key="key"
          :data="tree"
          :filter-node-method="filterNode"
          :default-checked-keys="modelValue"
          :default-expand-all="true"
          :expand-on-click-node="false"
          :check-on-click-node="true"
          @check-change="handleCheckChange"
        />
        <div style="display: none">
          <ElOption
            v-for="m in itemsList"
            :key="m.key"
            :label="m.label"
            :value="m.key"
          />
        </div>
      </ElSelect>
    </div>
    <div v-else>Loading...</div>
  </div>
</template>

<script lang="ts">
import fuzzaldrinPlus from "fuzzaldrin-plus";
import { differenceWith } from "lodash-es";
import { intersectionWith } from "lodash-es";
import { defineComponent } from "vue";

import type { RoomGroup } from "@drVue/store/modules/client-dashboard/deals/types";
import type { RoomMember } from "@drVue/store/modules/room/members/RoomMembersApiService";
import type { Dictionary } from "@drVue/types";
import type { TreeNodeData } from "element-plus/es/components/tree/src/tree.type";
import type { PropType } from "vue";

interface SelectItem {
  id: number;
  key: string;
  label: string;
}

interface TreeGroup {
  id: number;
  key: string;
  label: string;
  children: TreeMember[];
}

interface TreeMember {
  id: number;
  key: string;
  parentKey: string;
  label: string;
  email: string;
}

// ElTree and ElSelect both handle theirs states via keys ("member_${m.id}" or
// "group_${g.id}"). UsersTree returns (ids: number[]) of members.
interface Data {
  handleCheckTimeoutId: number | null;
  selectModel: string[];
  filterText: string;
}

export default defineComponent({
  name: "UsersTree",
  props: {
    modelValue: { required: true, type: Array as PropType<number[]> },
    membersList: { required: true, type: Array as PropType<RoomMember[]> },
    groupsList: { required: true, type: Array as PropType<RoomGroup[]> },
    isLoaded: { required: true, type: Boolean as PropType<boolean> },
    getUsersOfGroup: {
      required: true,
      type: Function as PropType<(group: RoomGroup) => RoomMember[]>,
    },
  },
  emits: ["update:modelValue", "change", "visible-change"],
  data(): Data {
    return {
      handleCheckTimeoutId: null,
      selectModel: [],
      filterText: "",
    };
  },
  computed: {
    itemsList(): SelectItem[] {
      const membersItems = this.membersList.map((m) => ({
        key: `member_${m.id}`,
        id: m.id,
        label: m.name,
      }));

      const groupsItems = this.groupsList.map((g) => ({
        key: `group_${g.id}`,
        id: g.id,
        label: g.name,
      }));

      return membersItems.concat(groupsItems);
    },
    tree(): any {
      if (!this.isLoaded) {
        return null;
      }

      return this.groupsList.reduce((acc: TreeGroup[], g: RoomGroup) => {
        const parentKey = `group_${g.id}`;

        const groupMembers = this.getUsersOfGroup(g).map((u) => {
          return {
            id: u.id,
            key: `member_${u.id}`,
            parentKey: parentKey,
            label: u.name,
            email: u.email,
          };
        });

        if (groupMembers.length === 0) return acc;

        const group = {
          key: parentKey,
          id: g.id,
          label: g.name,
          children: groupMembers,
        };

        (acc as Dictionary<any>)[parentKey] = group;
        acc.push(group);

        return acc;
      }, []);
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(value: number[]) {
        const $tree = this.$refs["tree"] as any;
        if ($tree) {
          $tree.setCheckedKeys(value.map((v) => `member_${v}`));
        }

        this.selectModel = this.getSelectModel(value);
      },
    },
    filterText(filterText: string) {
      const $tree = this.$refs["tree"] as any;
      $tree.filter(filterText);
    },
  },
  mounted() {
    const $tree = this.$refs["tree"] as any;
    if ($tree) $tree.setCheckedKeys(this.modelValue.map((v) => `member_${v}`));
  },
  methods: {
    getSelectModel(input: number[]) {
      if (this.tree === null) return [];

      const tree = this.tree;
      const tags = [];

      for (const child of tree) {
        const difference = differenceWith(
          child.children,
          input,
          (child: TreeMember, id) => child.id === id,
        );

        const intersection = intersectionWith(
          child.children,
          input,
          (child: TreeMember, id) => child.id === id,
        );

        if (difference.length === 0) {
          tags.push(child.key);
        } else if (intersection.length > 0) {
          tags.push(...intersection.map((m) => m.key));
        }
      }

      return tags;
    },
    filterMethod(query: string) {
      this.filterText = query;
    },
    filterNode(query: string, node: TreeNodeData) {
      if (node.children) return false;
      if (!query) return true;

      const score = fuzzaldrinPlus.score(node.label, query);
      return score > 0;
    },
    handleSelectVisibleChange(isVisible: boolean) {
      this.filterText = "";
      this.$emit("visible-change", isVisible);
    },
    handleCheckChange() {
      if (this.handleCheckTimeoutId) clearTimeout(this.handleCheckTimeoutId);
      this.handleCheckTimeoutId = window.setTimeout(
        this.handleCheckChangeDeferred,
      );
    },
    handleCheckChangeDeferred() {
      const $tree = this.$refs["tree"] as any;

      // Checked array contains all the nodes. If you select a group1 with
      // 2 members and a member from group2 then array will be
      // [{ group1 }, { member1-of1 }, { member2-of1 }, { member2-of2 }].
      const checked = $tree.getCheckedNodes();

      const checkedGroups = checked.filter((c: any) =>
        c.key.startsWith("group"),
      );
      const checkedMembers = checked.filter((c: any) =>
        c.key.startsWith("member"),
      );

      // Filter out the Members that were already selected via Groups selection.
      // The previous selection will be converted to [{ group1 }, { member2-of2 }].
      let filteredMembers = checkedMembers;
      for (const group of checkedGroups) {
        filteredMembers = filteredMembers.filter(
          (m: any) => !group.children.find((c: any) => c.key === m.key),
        );
      }

      this.selectModel = checkedGroups
        .concat(filteredMembers)
        .map((i: TreeGroup | TreeMember) => i.key);

      this.$emit(
        "update:modelValue",
        checkedMembers.map((m: TreeMember) => m.id),
      );

      setTimeout(() => {
        const $select = this.$refs["select"] as any;
        if ($select.visible) {
          const $input = $select.$refs["input"] as HTMLInputElement;
          $input.focus();
        }
      });
    },
    handleSelectChange(keys: string[]) {
      const $tree = this.$refs["tree"] as any;

      // It will call this.handleCheckChange -> this.$emit("input", ...)
      $tree.setCheckedKeys(keys);
      this.$emit("change", keys);
    },
  },
});
</script>

<style lang="scss" scoped>
.users-tree {
  width: 100%;
}
</style>
