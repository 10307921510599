<template>
  <div :class="$style.container">
    <DrLabel v-for="label in labelsToRender" :key="label.name">
      {{ label.name }}
    </DrLabel>
    <DrLabel v-if="isCountVisible">{{ countLabel }}</DrLabel>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DrLabel } from "@shared/ui/dr-label";

import { pinia } from "@drVue/store/pinia";
import { useTasksLabelsStore } from "@drVue/store/pinia/room/tasksLabels/tasksLabels";

import type { TaskLabel } from "@drVue/store/pinia/room/tasksLabels/tasksLabelApi";

interface Props {
  ids: number[];
}

const tasksLabels = useTasksLabelsStore(pinia);

const props = defineProps<Props>();

const labels = computed<TaskLabel[]>(() =>
  props.ids
    .slice()
    .sort()
    .reduce<TaskLabel[]>((acc, id) => {
      if (tasksLabels.dict[id]) {
        acc.push(tasksLabels.dict[id]);
      }
      return acc;
    }, []),
);

const labelsToRender = computed(() => labels.value.slice(0, 2));

const countLabel = computed(
  () => `+${labels.value.length - labelsToRender.value.length}`,
);

const isCountVisible = computed(
  () => labels.value.length > labelsToRender.value.length,
);
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.container {
  display: flex;
  align-items: center;
  gap: 6px;

  border-radius: 6px;
  transition: border 0.2s ease-out;
  border: 1px solid transparent;
  height: 28px;
  line-height: 22px;
  user-select: none;
  width: 100%;

  &:hover {
    .action {
      opacity: 1;
    }
  }
}
</style>
