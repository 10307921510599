import { ApiService } from "@drVue/api-service";

export enum AsyncOperationStatuses {
  Pending = "pending",
  Processing = "processing",
  Success = "success",
  Fail = "fail",
}

export enum UserLogLevel {
  Info = "info",
  Warning = "warning",
  Error = "error",
}

export interface UserLogItem {
  timestamp: Date;
  level: UserLogLevel;
  message: string;
}

export interface UserAsyncOperationResult {
  status: AsyncOperationStatuses;
  date_created: Date;
  progress: number;
  log: UserLogItem[];
  result_file: boolean;
}

export class UserAsyncOperationApiService extends ApiService {
  public getDetails(operation_uid: string): Promise<UserAsyncOperationResult> {
    const url = this.Urls["api:operations-detail"](operation_uid);
    return this.get<UserAsyncOperationResult>(url);
  }
}
