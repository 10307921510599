<template>
  <div :class="$style.container">
    <DrVxeGrid
      outlined
      :data="tableData"
      :columns="columns"
      :loading="isLoading"
      :sort-config="{ trigger: 'cell', defaultSort }"
      :row-config="{ height: 100 }"
      @cell-click="handleCellClick"
      empty-text="No data"
    />
  </div>
  <ElAlert
    v-if="isLoadingError"
    type="error"
    :title="isLoadingError"
    :closable="false"
  />
</template>

<script lang="ts" setup>
import { computed, h, ref, watch } from "vue";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import { DrStore } from "@app/vue";
import { useAiReportsStore } from "@drVue/components/room/ai/AiReportsStore";
import { AiReportsApiService } from "@drVue/components/room/ai/api";
import { pinia } from "@drVue/store/pinia";
import AiReportTableAnswerCell from "./AiReportTableAnswerCell.vue";

import type {
  AiDocReport,
  AiDocReportField,
  AiReport,
  AiReportField,
} from "@drVue/components/room/ai/types";
import type { DrVxeTableColumn } from "@drVue/components/types";
import type { VxeTableEvents, VxeTablePropTypes } from "vxe-table";

interface Props {
  reportTypeId: AiReport["id"];
}
interface Emits {
  (event: "select", id: AiDocReport["document_id"]): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const docReports = ref<AiDocReport[] | null>(null);
const aiReportsStore = useAiReportsStore(pinia);

const api = new AiReportsApiService();
const isLoading = ref(false);
const isLoadingError = ref("");

type AiReportFieldId = AiReportField["id"];

export interface DocReportTableRow {
  document_id: number;
  document_name: string;
  document_index: string;
  fields: { [key: AiReportFieldId]: AiDocReportField };
}

const fetchReports = () => {
  isLoading.value = true;
  isLoadingError.value = "";
  api.bulkLoadDocReports(props.reportTypeId).then(
    (data) => {
      docReports.value = data;
      isLoading.value = false;
    },
    (errorResp) => {
      const errorMsg =
        errorResp.response?.data?.non_field_errors?.[0] ||
        "Failed to load reports data";
      isLoadingError.value = errorMsg;
      isLoading.value = false;
    },
  );
};

const defaultSort: VxeTablePropTypes.SortConfigDefaultSort = {
  field: "tree_index",
  order: "desc",
};

const columns = computed(() => {
  const report = aiReportsStore.dict[props.reportTypeId];

  if (!report) return [];

  const fields: DrVxeTableColumn<DocReportTableRow>[] = [
    {
      sortable: true,
      field: "document_index",
      title: "Index",
      showOverflow: "tooltip",
      minWidth: 100,
    },
    {
      sortable: true,
      field: "document_name",
      title: "Title",
      showOverflow: "tooltip",
      minWidth: 250,
    },
  ];
  report.fields.forEach((f) => {
    fields.push({
      sortable: true,
      field: `fields.${f.id}`,
      title: f.label,
      showOverflow: "tooltip",
      minWidth: 200,
      slots: {
        default: (params) => {
          const field = params.row.fields[f.id];
          const value = field?.value || null;
          return h(AiReportTableAnswerCell, { value });
        },
      },
    });
  });
  return fields;
});

watch(
  () => props.reportTypeId,
  () => {
    fetchReports();
  },
  { immediate: true },
);

const tableData = computed((): DocReportTableRow[] => {
  if (!docReports.value) {
    return [];
  }

  return docReports.value.reduce<DocReportTableRow[]>((rows, report) => {
    const doc = DrStore.state.room.documents.files[report.document_id];
    if (!doc) return rows;

    const fields = report.fields.reduce<{
      [key: AiReportFieldId]: AiDocReportField;
    }>((acc, field) => {
      acc[field.report_type_template_field_id] = field;
      return acc;
    }, {});

    rows.push({
      document_id: doc.id,
      document_name: doc.name,
      document_index: doc.treeIndex,
      fields,
    });

    return rows;
  }, []);
});

const handleCellClick: VxeTableEvents.CellClick = ({ row }) => {
  emit("select", row.document_id);
};
</script>

<style module lang="scss">
@use "@app/styles/scss/spacing";

.container {
  display: grid;
  grid-template-rows: 1fr;
  gap: spacing.$m;
  height: 100%;
  max-height: 100%;
}

.actionsBar {
  display: flex;
  justify-content: flex-end;
}
</style>
