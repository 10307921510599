import "tippy.js/dist/tippy.css";
import "tippy.js/themes/translucent.css";

import tippy from "tippy.js";

// for tippy SVG support in IE11
// https://atomiks.github.io/tippyjs/creating-tooltips/#%E2%9A%A0%EF%B8%8F-svg-in-ie11
if (!SVGElement.prototype.contains) {
  SVGElement.prototype.contains = HTMLDivElement.prototype.contains;
}

const TOOLTIP_DELAY = 100;

export class TimelineTooltip {
  public add(el: SVGGraphicsElement, text: string) {
    tippy(el, {
      content: text,
      animation: "fade",
      theme: "translucent",
      arrow: false,
      delay: [TOOLTIP_DELAY, 0],
      offset: [0, 0],
    });
  }
}
