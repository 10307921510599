<template>
  <template v-if="folder.parent">
    <FolderBreadcrumbs :folder="folder.parent" light />
    <DrIcon name="angle-right" :class="$style.icon" />
  </template>
  <span
    :class="{
      [$style.text]: true,
      [$style.text_isLight]: light,
    }"
  >
    {{ text }}
  </span>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import DrIcon from "@app/vue/shared/ui/dr-icon/DrIcon.vue";

import type { Folder } from "@app/vue/store/modules/room/documents/DocumentsApiService";

interface Props {
  folder: Folder;
  light?: boolean;
}

const props = defineProps<Props>();

const text = computed(() => {
  const { index, treeIndex, name } = props.folder;
  return `${index ? treeIndex + ". " : ""}${name}`;
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

.text {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: colors.$pr-900;

  &.text_isLight {
    font-weight: 400;
    color: colors.$pr-500;
  }
}

.icon {
  color: colors.$pr-500;
  font-size: 14px;
}
</style>
