<template>
  <div :class="$style.app">
    <Navigation />

    <div :class="$style.page">
      <div class="dash-container">
        <ElRow>
          <ElCol class="text-center">
            <div v-if="isTrial" class="unpaid_page__title">
              We hope you enjoyed seeing what
              {{ websiteName }} has to offer with your free trial.
            </div>

            <div v-if="hasBillingAccess">
              <div class="unpaid_page__description">
                To continue seamlessly with your deals activate a subscription.
              </div>

              <Billing />
            </div>

            <div v-if="!hasBillingAccess">
              <div class="unpaid_page__description">
                To continue seamlessly using the platform, click on the button
                below and
                <br />
                one of our Account Executives will be in touch shortly.
              </div>
              <p>
                <ElButton
                  type="primary"
                  size="large"
                  :loading="activateSending"
                  :disabled="activateSuccess"
                  class="unpaid_page__active_button"
                  @click="activate"
                >
                  Request support help
                </ElButton>
              </p>

              <ElAlert
                v-if="activateSuccess"
                title="Thank you for your request."
                type="success"
                :closable="false"
                center
                class="unpaid_page__success_alert"
              >
                We will contact you shortly.
              </ElAlert>
              <div v-if="activateSuccess" class="unpaid_page__success_info">
                <span>
                  We do our best to respond to requests the same day received,
                  but please allow up to 24 hours or the next business day for a
                  response. We look forward to working with you,
                  {{ USER_DATA.profile.first_name }}!
                </span>
                <p>Team {{ websiteName }}</p>
              </div>

              <ElAlert
                v-if="activateError"
                title="Failed to send a request."
                type="error"
                show-icon
                center
                :closable="false"
              >
                Please try again or send us a message to
                <a :href="`mailto:${contactEmail}`" target="_blank">{{
                  contactEmail
                }}</a>
              </ElAlert>
            </div>
            <br />
          </ElCol>
        </ElRow>
      </div>

      <DrAlert />
      <TimezoneDialog />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TimezoneDialog from "@shared/ui/timezone-dialog/TimezoneDialog.vue";

import {
  APP_SETTINGS,
  CLIENT_DASHBOARD_DATA,
  ORG_MEMBER_DATA,
  ROOM_DATA,
  USER_DATA,
} from "@setups/data";
import { FeedbackApiService } from "@drVue/api-service/common/feedback";
import Navigation from "@drVue/components/navigation/Navigation.vue";
import { DrAlert } from "../";
import Billing from "./billing";

const IS_ROOM_OPEN = ROOM_DATA.id !== undefined;
const isTrial = IS_ROOM_OPEN
  ? ROOM_DATA.isTrialRoom
  : CLIENT_DASHBOARD_DATA.is_trial;

interface Data {
  USER_DATA: any;
  contactEmail: any;
  websiteName: any;
  hasBillingAccess: boolean;
  isTrial: any;
  activateSending: boolean;
  activateSuccess: boolean;
  activateError: boolean;
}

export default defineComponent({
  name: "UnpaidApp",
  components: {
    DrAlert,
    TimezoneDialog,
    Billing,
    Navigation,
  },
  data(): Data {
    return {
      USER_DATA: USER_DATA,
      contactEmail: APP_SETTINGS.CONTACT_EMAIL,
      websiteName: APP_SETTINGS.WEBSITE.NAME,
      hasBillingAccess: ORG_MEMBER_DATA.has_billing_access,
      isTrial: isTrial,
      activateSending: false,
      activateSuccess: false,
      activateError: false,
    };
  },
  methods: {
    activate() {
      const api = new FeedbackApiService();

      this.activateSuccess = false;
      this.activateError = false;
      this.activateSending = true;

      api.activate().then(
        () => {
          this.activateSuccess = true;
          this.activateSending = false;
        },
        () => {
          this.activateError = true;
          this.activateSending = false;
        },
      );
    },
  },
});
</script>

<style lang="scss" module>
.app {
  display: grid;
  grid-template-columns: min-content 1fr;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.page {
  max-width: 100%;
  overflow: hidden;
}
</style>
