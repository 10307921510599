import * as owasp from "owasp-password-strength-test";

owasp.tests.required.push(function (password) {
  if (password !== password.trim()) {
    return "Please don't begin or end your password with blank space.";
  }
});

owasp.config({
  allowPassphrases: false,
  maxLength: 128,
  minLength: 10,
  minPhraseLength: 20,
  minOptionalTestsToPass: 3,
});

angular
  .module("dealroom.common.password", [])
  .directive("passwordValidator", passwordValidator)
  .factory("PasswordValidator", PasswordValidator);

function PasswordValidator() {
  // PasswordValidator service used for testing the password strength

  var service = {
    getResult: getResult,
    getPopoverMsg: getPopoverMsg,
  };

  return service;

  function getResult(password) {
    var result = owasp.test(password);
    return result;
  }

  function getPopoverMsg() {
    var popoverMsg =
      "Please enter password with " +
      owasp.configs.minLength +
      " or more characters, numbers, lowercase, uppercase, and special characters.";

    return popoverMsg;
  }
}

function passwordValidator(PasswordValidator) {
  return {
    require: "ngModel",
    link: function (scope, element, attrs, ngModel) {
      ngModel.$validators.requirements = function (password) {
        let status = true;
        if (password) {
          const result = PasswordValidator.getResult(password);

          status = result.strong;
          scope.passwordErrors = status ? [] : result.errors;
        }
        return status;
      };
    },
  };
}
