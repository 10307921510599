import { defineStore } from "pinia";
import { computed, ref } from "vue";

import updateTaskAttributes from "@app/ng/tasks/services/helpers/updateTaskAttributes";
import { $notifyDanger, createDictionary } from "@drVue/common";
import { TasksApiService } from "@drVue/store/pinia/room/tasks";

import type { Task } from "../tasks/api";

const api = new TasksApiService();

export const useTasksArchivedStore = defineStore("tasksArchived", () => {
  const loadingPromise = ref<Promise<Task[]> | null>(null);

  const tasksList = ref<Task[]>([]);

  const tasks = computed(() => {
    return Object.freeze(
      tasksList.value.reduce((dict, task) => {
        dict[task.id] = task;
        return dict;
      }, createDictionary<Task>()),
    );
  });

  const tasksByUid = computed(() => {
    return Object.freeze(
      tasksList.value.reduce((dict, task) => {
        dict[task.uid] = task;
        return dict;
      }, createDictionary<Task>()),
    );
  });

  const tasksByCategoryId = computed(() => {
    return Object.freeze(
      tasksList.value.reduce((dict, task) => {
        if (!dict[task.category_id]) dict[task.category_id] = [];
        dict[task.category_id].push(task);
        return dict;
      }, createDictionary<Task[]>()),
    );
  });

  const isLoading = ref<boolean>(false);
  const isError = ref<boolean>(false);

  const load = (skipErrorAlert: boolean = true) => {
    if (isLoading.value) return loadingPromise.value;

    isLoading.value = true;
    isError.value = false;

    loadingPromise.value = api
      .loadArchivedTasks()
      .then(
        (_tasksList) => {
          _tasksList.forEach((task) => updateTaskAttributes(task));

          tasksList.value = _tasksList;

          return _tasksList;
        },
        (error) => {
          isError.value = true;

          if (!skipErrorAlert) {
            $notifyDanger("Failed to update requests.");
          }

          return Promise.reject(error);
        },
      )
      .finally(() => (isLoading.value = false));

    return loadingPromise.value;
  };

  const upsertTask = (changes: Task) => {
    let index = tasksList.value.findIndex((t) => t.id === changes.id);

    if (index === -1) {
      tasksList.value.push(changes);
      index = tasksList.value.length - 1;
    } else {
      tasksList.value[index] = {
        ...tasksList.value[index],
        ...changes,
      };
    }

    updateTaskAttributes(tasksList.value[index]);
  };

  const deleteTask = (taskId: number) => {
    const i = tasksList.value.findIndex((t) => t.id === taskId);
    if (i === -1) return;

    tasksList.value.splice(i, 1);
  };

  return {
    loadingPromise,

    tasks,
    tasksByUid,
    tasksList,
    tasksByCategoryId,

    upsertTask, // for compatibility with angular, do not use in vue
    deleteTask, // for compatibility with angular, do not use in vue

    isLoading,
    isError,

    load,
  };
});
