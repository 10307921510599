PgroupsDropdownController.$inject = [
  "$element",
  "AnalyticsDropdownPromisedItemsService",
  "ActivityGroupPermissionsVisibleService",
  "PermissionsService",
];
angular
  .module("dealroom.analytics.dropdown.pgroups", [
    "dealroom.analytics.dropdown",

    "dealroom.analytics.service.data",

    "dealroom.members",
  ])
  .component("drAnalyticsPgroupsDropdown", {
    controller: PgroupsDropdownController,
  });

function PgroupsDropdownController(
  $element,
  AnalyticsDropdownPromisedItemsService,
  ActivityGroupPermissionsVisibleService,
  PermissionsService,
) {
  const promise =
    ActivityGroupPermissionsVisibleService.loadingPromise.then(createScope);
  AnalyticsDropdownPromisedItemsService.render(promise);

  function createScope() {
    const ctrl = {
      label: "Groups",
      uid: "groups",
      items: getItems(),
      multiselect: true,
      handleSelect,
    };
    return { ctrl, $element };

    function handleSelect(selected = []) {
      ActivityGroupPermissionsVisibleService.set(selected.map((s) => s.id));
    }

    function getItems() {
      return ActivityGroupPermissionsVisibleService.pgroupIds.map((id) => ({
        label: PermissionsService.pgroups[id].name,
        id: id,
      }));
    }
  }
}
