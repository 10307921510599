import { getActions } from "./actions";
import { mutations } from "./mutations";
import { State } from "./state";

import type { RootState } from "./types";
import type { Module } from "vuex";

export type IModule = Module<State, RootState>;
export function getModule(api: any, getters?: any): IModule {
  return {
    actions: getActions(api),
    getters,
    mutations,
    namespaced: true,
    state: new State(),
  };
}

export { State } from "./state";
