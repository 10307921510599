<template>
  <div>{{ type }}</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { PropType } from "vue";

export default defineComponent({
  name: "TypeCell",
  props: {
    row: { required: true, type: Object as PropType<any> },
  },
  computed: {
    type(): any {
      const t = this.row.type;
      return this.$store.state.room.tasksStatuses.TYPES_DEFAULT_LABEL[t];
    },
  },
});
</script>
