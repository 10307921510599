import cardHtml from "./card.html?raw";

CategoriesController.$inject = [
  "CategoriesService",
  "ActivityVisibleCategoriesService",
  "TasksService",
];
angular
  .module("dealroom.analytics.card.heatmap.categories", [
    "dealroom.analytics.service.data",
    "dealroom.task",
  ])
  .component("drAnalyticsRequestsHeatmapCard", {
    template: cardHtml,
    controller: CategoriesController,
  });

function CategoriesController(
  CategoriesService,
  ActivityVisibleCategoriesService,
  TasksService,
) {
  const $ctrl = this;
  /// public
  $ctrl.title = "Requests Activity";
  $ctrl.yLabel = "Lists";
  $ctrl.nestBy = "category";
  $ctrl.filterBy = "requests";
  $ctrl.verb = "task_viewed";

  $ctrl.statsTypes = {
    viewsCoverage: {
      label: "Requests Views (% of requests viewed in a list)",
    },
    viewsNumbers: { label: "Requests Views" },
    timeSpent: { label: "Requests Time Spent (Minutes)" },
  };

  $ctrl.getDKey = getDKey;
  $ctrl.getYKeys = getYKeys;
  $ctrl.getDescendants = getDescendants;
  $ctrl.getTotalValues = getTotalValues;
  $ctrl.getYLabel = getYLabel;

  /// functions
  function filterArchived(category) {
    return category.is_archived === false;
  }

  function getDKey(d) {
    const taskId = d.target.id;
    const task = TasksService.tasks[taskId];
    if (!task || task.is_archived) return null;
    return taskId;
  }

  function getYKeys(categoryId) {
    if (!categoryId) {
      return ActivityVisibleCategoriesService.visibleRootCategoriesIds || [];
    }
    return CategoriesService.categories[categoryId].categories
      .filter(filterArchived)
      .map((c) => +c.id);
  }

  function getDescendants(categoriesIds) {
    return categoriesIds.reduce((descendantsIds, categoryId) => {
      const category = CategoriesService.categories[categoryId];
      const ids = category.descendants;
      return descendantsIds.concat(ids);
    }, []);
  }

  function getTotalValues(descendants) {
    return descendants.reduce((count, categoryId) => {
      const visibleTasks =
        ActivityVisibleCategoriesService.getVisisbleTasks(categoryId);
      return count + visibleTasks.length;
    }, 0);
  }

  function getYLabel(categoryId) {
    const category = CategoriesService.categories[categoryId];
    return {
      label: category ? category.name : "Unknown category",
      order: category ? category.order : 10000,
      hasChilds:
        category && category.categories.filter(filterArchived).length > 0,
      parentsIds: category && category.parents.map((c) => c.id),
      usePrefix: !!category,
    };
  }
}

export default CategoriesController;
