angular
  .module("dealroom.analytics.service.data.api", [])
  .service("AnalyticsApiService", AnalyticsApiService);

AnalyticsApiService.$inject = ["URLS", "$q", "$http", "RoomConfig"];
function AnalyticsApiService(URLS, $q, $http, RoomConfig) {
  const promises = [];
  const viewTasks = RoomConfig.userPermissions.viewTasks;
  return {
    getActivityHistory: getActivityHistory,
    getActivityActual: getActivityActual,

    documentsPromise: getPromise(getDocuments),
    foldersPromise: getPromise(getFolders),
    tasksPromise: getPromise(getTasks),
    categoriesPromise: getPromise(getCategories),

    startLoading,
  };

  function startLoading() {
    promises.forEach((resolve) => resolve());
  }

  function getPromise(loadFn) {
    return $q((resolve, reject) => {
      promises.push(resolve);
    }).then(loadFn);
  }

  function getDocuments() {
    const url = URLS["api:room:activity_documents_info"]();
    return $http.get(url);
  }
  function getFolders() {
    const url = URLS["api:room:activity_folders_info"]();
    return $http.get(url);
  }

  function getTasks() {
    const url = URLS["api:room:activity_tasks_info"]();
    return viewTasks ? $http.get(url) : null;
  }

  function getCategories() {
    const url = URLS["api:room:activity_categories_info"]();
    return viewTasks ? $http.get(url) : null;
  }

  function getActivityHistory() {
    const url = URLS["api:room:activity_history"]();
    return $http.get(url);
  }

  function getActivityActual({
    lastActionTimestamp: lastActionTimestamp,
    nextCursor,
  }) {
    let url = URLS["api:room:activity_actual"]();
    if (nextCursor) {
      console.assert(!lastActionTimestamp);
      console.assert(nextCursor.startsWith(url));
      return $http.get(nextCursor);
    }

    if (lastActionTimestamp) {
      url +=
        "?last_action_timestamp=" + encodeURIComponent(lastActionTimestamp);
    }
    return $http.get(url);
  }
}
