Controller.$inject = [
  "$scope",
  "$element",
  "ActivityFilterDatesService",
  "AnalyticsActivityDataService",
  "AnalyticsDataDispatcher",
];
import * as d3 from "d3";

angular
  .module("dealroom.analytics.card.linechart.base.nest", [
    "dealroom.analytics.service.filter",
    "dealroom.analytics.service.data",
  ])
  .directive("drAnalyticsLinechartCardNestData", () => ({
    scope: {
      dispatcher: "<",
      filterBy: "<",
      types: "<",
      getYList: "<",
    },
    restrict: "A",
    controller: Controller,
  }));

function Controller(
  $scope,
  $element,
  ActivityFilterDatesService,
  AnalyticsActivityDataService,
  AnalyticsDataDispatcher,
) {
  AnalyticsDataDispatcher.on("filtersChanged", mkNest, $element, $scope);

  function mkNest() {
    const EVERYDAY = ActivityFilterDatesService.getEveryDayRange() || [];
    if (EVERYDAY.length === 0) return;

    const filtered = AnalyticsActivityDataService.filter($scope.filterBy);
    const emptyMetricsData = $scope.types.reduce((bucket, { label }) => {
      bucket[label] = getEmptyData();
      return bucket;
    }, {});
    // {label: {yKey: {dt: INT, ..}, ...}, ...}
    const nested = filtered.reduce(nestByMetrics, emptyMetricsData);

    $scope.dispatcher.call("nested", nested);

    function getEmptyData() {
      const yIds = $scope.getYList();
      return d3
        .nest()
        .key(({ key }) => key)
        .rollup(() =>
          d3
            .nest()
            .key((dt) => dt)
            .rollup(() => 0)
            .object(EVERYDAY),
        )
        .object(yIds);
    }
  }

  function nestByMetrics(bucket, d) {
    const metrics = getMetrics(d);
    if (!metrics) return bucket;

    const label = metrics.label;
    const yKey = metrics.getY(d);
    if (bucket[label][yKey] == undefined) return bucket;
    const dt = d3.timeDay(d.dt);
    const value = angular.isFunction(metrics.value) ? metrics.value(d) : 1;
    bucket[label][yKey][dt] += value;
    return bucket;
  }

  function getMetrics(d) {
    const metrics = $scope.types.find((m) => {
      const testFn = angular.isFunction(m.test) ? m.test : getTestFn(m);
      return testFn(d);
    });

    return metrics;

    function getTestFn({ verbs }) {
      return (d) => verbs.includes(d.verb);
    }
  }
}
