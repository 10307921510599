<template>
  <DrToolbarFilterSearch
    :model-value="search"
    @update:model-value="emit('update:search', $event)"
  />

  <DrToolbarFilterSelect
    :model-value="filters.type_id"
    label="Type"
    :options="typeOptions"
    @update:model-value="updateField('type_id', $event)"
  >
    <template #icon="{ option }">
      <DrIcon
        size="sm"
        name="exclamation-triangle"
        :style="{ color: option.color }"
      />
    </template>
  </DrToolbarFilterSelect>

  <DrToolbarFilterSelect
    :model-value="filters.severity"
    label="Severity"
    :options="severityOptions"
    @update:model-value="updateField('severity', $event)"
  />

  <DrToolbarFilterSelect
    :model-value="filters.likelihood"
    label="Likelihood"
    :options="likelihoodOptions"
    @update:model-value="updateField('likelihood', $event)"
  />

  <DrToolbarFilterSelect
    :model-value="filters.status_id"
    label="Status"
    :options="statusOptions"
    @update:model-value="updateField('status_id', $event)"
  >
    <template #icon="{ option }">
      <DrIconStatus :color="option.color" />
    </template>
  </DrToolbarFilterSelect>

  <DrToolbarFilterSelect
    :model-value="filters.roles"
    label="Role"
    :options="rolesOptions"
    @update:model-value="updateField('roles', $event)"
  />

  <slot name="append" />

  <div v-if="count" :class="$style.count">
    {{ countString }}
  </div>
</template>
<script setup lang="ts">
import { capitalize } from "lodash-es";
import { computed } from "vue";
import { DrIcon } from "@shared/ui/dr-icon";
import DrIconStatus from "@shared/ui/dr-icon-status";
import {
  DrToolbarFilterSearch,
  DrToolbarFilterSelect,
} from "@shared/ui/dr-toolbar";

import {
  FindingLikelihoodList,
  FindingSeverityList,
} from "@drVue/api-service/modules/findings/types";
import { pinia } from "@drVue/store/pinia";
import { useFindingsStatusesStore } from "@drVue/store/pinia/pipeline/findings-statuses";
import { useFindingsTypesStore } from "@drVue/store/pinia/pipeline/findings-types";
import $utils from "@drVue/utils";
import { ParticipantsType } from "./types";

import type {
  YourWorkFindingsFilters,
  YourWorkFindingsToolbarFiltersUpdate,
} from "./types";
import type {
  FindingLikelihoodKey,
  FindingSeverityKey,
} from "@drVue/api-service/modules/findings/types";
import type { StatusColor } from "@shared/ui/dr-icon-status";
import type { SelectOptionItem } from "@shared/ui/dr-popups";

type SelectOptionItemWithColor<T = void> = SelectOptionItem<T> & {
  color: StatusColor;
};

interface Props {
  search: string;
  filters: YourWorkFindingsFilters;
  count: number;
}
const props = defineProps<Props>();

const emit = defineEmits<{
  (event: "update:filter", payload: YourWorkFindingsToolbarFiltersUpdate): void;
  (event: "update:search", payload: string): void;
}>();

const findingsStatusesStore = useFindingsStatusesStore(pinia);
const findingsTypesStore = useFindingsTypesStore(pinia);

const countString = computed(() => {
  if (!props.count) {
    return "";
  }

  return `${props.count} ${$utils.text.pluralize("finding", props.count)}`;
});

const typeOptions = computed<SelectOptionItemWithColor<string>[]>(() => {
  return findingsTypesStore.list.map(({ id, name, color }) => ({
    name,
    id,
    color,
    isSelected: props.filters.type_id.includes(id),
  }));
});

const severityOptions = computed<SelectOptionItem<FindingSeverityKey>[]>(() => {
  return FindingSeverityList.map((severity) => ({
    id: severity.value,
    name: severity.label,
    isSelected: props.filters.severity.includes(severity.value),
  }));
});

const likelihoodOptions = computed<SelectOptionItem<FindingLikelihoodKey>[]>(
  () => {
    return FindingLikelihoodList.map((likelihood) => ({
      id: likelihood.value,
      name: likelihood.label,
      isSelected: props.filters.likelihood.includes(likelihood.value),
    }));
  },
);

const statusOptions = computed<SelectOptionItemWithColor<string>[]>(() => {
  return findingsStatusesStore.list.map(({ id, name, color }) => ({
    id,
    name,
    color,
    isSelected: props.filters.status_id.includes(id),
  }));
});

const rolesOptions = computed<SelectOptionItem<ParticipantsType>[]>(() => {
  return Object.values(ParticipantsType).map((item) => ({
    id: item,
    name: capitalize(item),
    isSelected: props.filters.roles.includes(item),
  }));
});

const updateField = (
  field: YourWorkFindingsToolbarFiltersUpdate["field"],
  value: YourWorkFindingsToolbarFiltersUpdate["value"],
) => {
  emit("update:filter", {
    field,
    value,
  });
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography" as typo;

.count {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 16px;
  font: typo.$body_regular;
  color: colors.$pr-500;
  white-space: nowrap;

  &:before {
    content: "";
    display: inline-block;
    height: 14px;
    border-left: solid 1px colors.$pr-300;
  }
}
</style>
