import * as d3 from "d3";

import Animation from "./animation";

angular
  .module("dealroom.analytics.linechart.lines.points", [
    "dealroom.analytics.linechart.service.config",
    "dealroom.analytics.factory.templates",
  ])
  .service("AnalyticsLinechartPointsFactory", Factory);

Factory.$inject = [
  "AnalyticsTemplatesFactory",
  "AnalyticsLinechartConfigService",
];
function Factory(AnalyticsTemplatesFactory, AnalyticsLinechartConfigService) {
  const STROKE_WIDTH = AnalyticsLinechartConfigService.STROKE_WIDTH;
  const MIN_STROKE_WIDTH = 0.1;
  // const MIN_OPACTITY = 0;
  const MIN_OPACTITY = 0.2;

  return function (HIGHT, R, SHADOW_ID) {
    const flatY = HIGHT + STROKE_WIDTH / 2;

    return {
      remove,
      updateYs,
      update,
    };

    function remove(parent, onEnd) {
      flat(parent, { opacity: 0 }, _remove);

      function _remove() {
        const node = d3.select(this);
        if (node.property("tagName") === "circle" && node.classed("ng-scope")) {
          removeScope(this);
        }
        node.remove();
        onEnd();
      }
    }

    function removeScope(node) {
      const circleScope = angular.element(node).scope();
      circleScope && circleScope.$destroy();
    }

    function updateYs(parent, visibleKeys, beforeUpdate = Function.prototype) {
      const data = getData(parent, visibleKeys);
      const selection = getSelection(parent);
      const updated = selection.call(beforeUpdate, data).data(data);

      updated.exit().remove();
      return create(updated).call(raise);
    }

    function update(parent, visibleKeys, [x2, x1]) {
      const size = getSize(parent);
      flat(parent, { x1, x2 }, _createAndRaise);

      function _createAndRaise(d, i) {
        if (size === 0 || i === size - 1) {
          updateYs(parent, visibleKeys, updateXs);
        }
      }

      function updateXs(selection, newData) {
        return selection.attr("cx", function (d, i) {
          const newCx = newData[i] && newData[i].x;
          if (newCx === undefined) return d3.select(this).attr("cx");
          return newCx;
        });
      }
    }

    //////
    function getSelection(parent) {
      return parent.selectAll(".node");
    }

    function getData(parent, visibleKeys) {
      const PARENT_DATA = parent.datum();
      const points = PARENT_DATA.points;
      const total = points.length - 1;
      return points.filter(isInVisibleKeys);
      function isInVisibleKeys(d, i) {
        if (i === 0 || i === total) return true;
        if (i === 1 && total > 30) return false;
        if (i === total - 1 && total > 30) return false;
        return visibleKeys.includes(d.key);
      }
    }

    function getSize(parent) {
      if (!parent) return 0;
      return getSelection(parent).size();
    }

    function flat(parent, params, onEnd) {
      const selection = getSelection(parent);
      params.opacity =
        params.opacity === undefined ? MIN_OPACTITY : params.opacity;
      return Animation.flat(selection, params, onEnd)
        .call(setFlatAttrs, true)
        .call(setCX);

      function setCX(selection) {
        const maxI = selection.size() - 1;
        if (params.x1 === undefined || params.x2 === undefined) {
          return selection;
        }
        return selection.attr("cx", function (d, i) {
          if (i === 0) return params.x1;
          if (i === maxI) return params.x2;
          return d.x;
        });
      }
    }

    function raise(selection) {
      const r = R - STROKE_WIDTH;
      Animation.raise(selection)
        .attr("r", r)
        .attr("fill", "white")
        .attr("stroke-width", STROKE_WIDTH)
        .attr("cy", getY)
        .on("end", function (d, i) {
          // if (AnalyticsActivityDataService.isStartDateReached) {
          window.setTimeout(() => {
            d3.select(this).call(updateTooltip);
          }, 10);
          // }
        });

      function getY({ y, isUnreachedYet }) {
        return isUnreachedYet ? flatY : y + STROKE_WIDTH / 2;
      }
    }

    function create(selection) {
      return selection
        .enter()
        .append("circle")
        .attr("class", "node")
        .attr("opacity", (d) => (d.isUnreachedYet ? 1 : 0))
        .attr("cx", ({ x }) => x)
        .call(setFlatAttrs)
        .property("dispatcher", createTooltip)
        .merge(selection)
        .call(setColor)
        .raise();
    }

    function setColor(selection) {
      if (selection.empty()) return selection;
      const { color } = getParentData(selection);
      return selection.attr("stroke", color);
    }

    function setFlatAttrs(selection) {
      if (selection.empty()) return selection;
      const { color } = getParentData(selection);
      const attrs = [
        ["r", STROKE_WIDTH / 2],
        ["stroke-width", MIN_STROKE_WIDTH],
        ["stroke", color],
        ["fill", color],
        ["cy", flatY],
      ];

      attrs.forEach(([name, value]) => {
        selection = selection.attr(name, value);
      });
      return selection;
    }

    function getParentData(selection) {
      if (selection.empty()) return {};
      const parentNode = selection.node().parentNode || {};
      return parentNode.__data__ || {};
    }

    function createTooltip() {
      const circle = d3.select(this);
      const d = {
        type: "tooltip",
        shadowId: SHADOW_ID,
        timeout: AnalyticsLinechartConfigService.HOVER_TIMEOUT,
      };
      const { dispatcher } = AnalyticsTemplatesFactory.render(this, d);
      return dispatcher;
    }

    function updateTooltip(circle) {
      if (circle.empty()) return;
      const { key, value, tooltip, y, isUnreachedYet } = circle.datum();
      if (isUnreachedYet === true) return; // do not create scope for not reached dts

      const PARENT_DATA = getParentData(circle);
      if (!PARENT_DATA) {
        removeScope(circle.node());
        return;
      }

      const dispatcher = circle.property("dispatcher");
      const tooltipData = {
        color: PARENT_DATA.color,
        title: PARENT_DATA.title,
        body: d3.timeFormat("%B %d, %Y")(new Date(key)),
        value: tooltip ? tooltip : value,
      };
      dispatcher.call("update", null, tooltipData);
    }
  };
}
