import * as d3 from "d3";

export default dataToRows;

function dataToRows(data, EMPTY_COLOR) {
  const PER_ROW = 2;
  const EMPTY_DATA = {
    title: "N/A",
    label: "Insufficient data",
    color: EMPTY_COLOR,
  };
  return d3.range(0, data.length, PER_ROW).map(createRow);

  function createRow(startRowIndex) {
    const endRowIndex = startRowIndex + PER_ROW;
    const indexes = d3.range(startRowIndex, endRowIndex);
    const columns = indexes.reduce((columns, index) => {
      const d = data[index];
      if (d) {
        const isEmpty = angular.isUndefined(d.title);
        if (isEmpty) {
          columns.push(EMPTY_DATA);
        } else {
          columns.push({
            color: d.color, // || colorScheme(index),
            title: `${index + 1}. ${d.title}`,
            label: d.label,
            key: d.key,
          });
        }
      }
      return columns;
    }, []);
    return columns;
  }
}
