import type { DrVxeTableColumn } from "@drVue/components/types";
import type { DealTask } from "@drVue/store/pinia/your-work/deal-tasks/";
import type { CustomViewsFilterFields } from "@setups/types";

export const YOUR_WORK_DEAL_TASKS_TABLE_FIELDS = [
  "due_datetime",
  "title",
  "deal_uid",
  "date_resolved",
  "is_resolved",
] as const;
export type YourWorkDealTasksTableField =
  (typeof YOUR_WORK_DEAL_TASKS_TABLE_FIELDS)[number];

export interface YourWorkDealTasksTableColumn
  extends DrVxeTableColumn<YourWorkDealTasksTableRow> {
  field: YourWorkDealTasksTableField;
}

export type YourWorkDealTasksTableRow = DealTask & {
  deal: {
    title: string;
    url: string;
  };
};

export type YourWorkDealTasksCustomViewsFilters = CustomViewsFilterFields<
  YourWorkDealTasksTableRow,
  (number | string | boolean)[]
>;

export type YourWorkDealTasksFilters = {
  due_datetime: [Date, Date] | [];
};

export type YourWorkDealTasksToolbarFiltersUpdate = {
  field: keyof YourWorkDealTasksFilters;
  value: YourWorkDealTasksFilters[keyof YourWorkDealTasksFilters];
};

/**
 * Value matching function:
 * "eq"  - exact matching
 * "in"  - the value being tested must be in the list of allowed values
 * "bt"  - the value being tested must be within the specified range
 *         (between start and end value)
 *
 * @todo place to "common" | "setups"
 */
type FilterValueMatchingFunction = "eq" | "in" | "bt";

export const mapYourWorkDealTasksFieldToMatchingFunction: {
  [k in keyof YourWorkDealTasksTableRow]?: FilterValueMatchingFunction;
} = {
  due_datetime: "bt",
};
