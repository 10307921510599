export default {
  category: `<dr-category-information
        category="::$ctrl.category"
        is-link="::$ctrl.isLink"
        is-archived="::$ctrl.isArchived"
        > </dr-category-information>`,

  task: `<div>
      <dr-task-path-categories
         category-id="::$ctrl.categoryId"
         ng-if="::$ctrl.categoryId"
         ></dr-task-path-categories>

      <dr-task-information
        task="::$ctrl.task"
        show-priority="::true"
        is-link="::$ctrl.isLink"
        is-archived="::$ctrl.isArchived"
        > </dr-task-information>
    </div>`,

  user: `<dr-user-information
        user-id="::$ctrl.userId"
        show-avatar="::true"
        is-link="::true"
        show-headline="::true"
        > </dr-user-information>`,

  folder: `<dr-folder-information
        folder="::$ctrl.folder"
        show-index="::true"
        is-link="::$ctrl.isLink"
        > </dr-folder-information>`,

  document: `<dr-document-information
        document="::$ctrl.document"
        show-index="::true"
        is-link="::$ctrl.isLink"
        hide-thumbnail="::true"
        > </dr-document-information>`,

  progressbar: `<dr-analytics-progressbar
        values="::$ctrl.value"
        show-percentage="::$ctrl.showPercentage"
        dispatcher="::$ctrl.dispatcher"
        ></dr-analytics-progressbar>`,

  value: `<div
        class="truncate"
        dr-tooltip="{{ ::$ctrl.value }}"
        tooltip-placement="top-left"
        tooltip-class="tooltip-lg">
          {{ ::$ctrl.value }}
      </div>`,
};
