import { differenceInDays, startOfDay } from "date-fns";
import { toDate } from "date-fns-tz";

import { USER_DATA } from "@setups/data";

export default function dueDaysLeft(dueDateRaw: string | null) {
  if (dueDateRaw === null) return NaN;

  const today = startOfDay(new Date());
  const dueDate = toDate(dueDateRaw, { timeZone: USER_DATA.profile.timezone });

  return differenceInDays(today, dueDate);
}
