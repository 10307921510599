import EditDateField from "./Fields/Edit/Date.vue";
import EditDateTimeField from "./Fields/Edit/DateTime.vue";
import EditMultiselectField from "./Fields/Edit/MultiSelect.vue";
import NotImplementedEdit from "./Fields/Edit/NotImplementedEdit.vue";
import EditNumberField from "./Fields/Edit/Number.vue";
import EditSelectField from "./Fields/Edit/Select.vue";
import EditTextField from "./Fields/Edit/Text.vue";
import EditTextareaField from "./Fields/Edit/Textarea.vue";
import ViewAsDate from "./Fields/View/Date.vue";
import ViewAsEmailField from "./Fields/View/Email.vue";
import ViewAsLinkifiedText from "./Fields/View/LinkifiedText.vue";
import NotImplementedView from "./Fields/View/NotImplementedView.vue";
import ViewAsNumber from "./Fields/View/Number.vue";
import ViewAsPhoneField from "./Fields/View/Phone.vue";
import ViewAsSelect from "./Fields/View/Select.vue";
import ViewAsText from "./Fields/View/Text.vue";
import { FieldSchemaType } from "./types";

import type {
  EditFieldsTypes,
  FieldSchemaTypeDefaultComponents,
  ViewFieldsTypes,
} from "./types";

export const viewFieldComponents: FieldSchemaTypeDefaultComponents<ViewFieldsTypes> =
  {
    [FieldSchemaType.Date]: ViewAsDate,
    [FieldSchemaType.DateTime]: ViewAsDate,
    [FieldSchemaType.Email]: ViewAsEmailField,
    [FieldSchemaType.MultiSelect]: ViewAsSelect,
    [FieldSchemaType.Number]: ViewAsNumber,
    [FieldSchemaType.Phone]: ViewAsPhoneField,
    [FieldSchemaType.Richtext]: ViewAsText,
    [FieldSchemaType.Select]: ViewAsSelect,
    [FieldSchemaType.Text]: ViewAsText,
    [FieldSchemaType.Textarea]: ViewAsText,
    [FieldSchemaType.LinkifiedText]: ViewAsLinkifiedText,
    [FieldSchemaType.Switch]: ViewAsText,
    [FieldSchemaType.Color]: NotImplementedView,
  };

export const editFieldComponents: FieldSchemaTypeDefaultComponents<EditFieldsTypes> =
  {
    [FieldSchemaType.Date]: EditDateField,
    [FieldSchemaType.DateTime]: EditDateTimeField,
    [FieldSchemaType.Email]: EditTextField,
    [FieldSchemaType.MultiSelect]: EditMultiselectField,
    [FieldSchemaType.Number]: EditNumberField,
    [FieldSchemaType.Phone]: EditTextField,
    [FieldSchemaType.Richtext]: EditTextField,
    [FieldSchemaType.Select]: EditSelectField,
    [FieldSchemaType.Text]: EditTextField,
    [FieldSchemaType.Textarea]: EditTextareaField,
    [FieldSchemaType.LinkifiedText]: EditTextareaField,
    [FieldSchemaType.Switch]: EditTextField,
    [FieldSchemaType.Color]: NotImplementedEdit,
  };
