<template>
  <DrLayoutContent>
    <template #toolbar>
      <DrToolbarFilterSearch v-model="membersSearch" />
    </template>

    <template #toolbar-right>
      <ElButton type="primary" @click="inviteMember"> Invite member </ElButton>
    </template>

    <template #panel>
      <DrNavTree
        title="Permission set"
        root-title="All"
        add-tooltip="Create permission set"
        :tree="groupsList"
        :current-id="currentGroupId || 'root'"
        :is-processing="isLoading || isPermissionSetDeleting"
        no-data-text=""
        @select="handleSelectPermissionSet"
        @add="handleAddPermissionSet"
      >
        <template #item-action="{ item, setActive }">
          <ElDropdown
            trigger="click"
            @command="(cmd) => handlePermissionSetMenu(cmd, item.id)"
            @visible-change="setActive(item.id, $event)"
          >
            <DrIcon name="ellipsis-h" size="sm" />
            <template #dropdown>
              <ElDropdownMenu>
                <ElDropdownItem command="edit">Edit</ElDropdownItem>
                <ElDropdownItem
                  v-if="checkGroupIsEdiatable(item)"
                  command="delete"
                >
                  Delete
                </ElDropdownItem>
              </ElDropdownMenu>
            </template>
          </ElDropdown>
        </template>
      </DrNavTree>
    </template>

    <OrgMembersTable
      :is-loading="isLoading"
      :data="membersFiltered"
      @select="handleSelectMember"
    />

    <OrgGroupPanel
      v-model:shown="isOrgGroupPanelShown"
      :group-id="editableOrgGroupId"
    />

    <OrgMemberPanel
      v-model:shown="isOrgMemberPanelShown"
      :member-id="editableOrgMemberId"
      :group-id="currentGroupId"
      @removed="handleMemberRemoved"
    />
  </DrLayoutContent>
</template>

<script lang="ts" setup>
import { ElMessageBox } from "element-plus";
import { computed, onMounted, ref } from "vue";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrLayoutContent } from "@shared/ui/dr-layouts";
import { DrNavTree } from "@shared/ui/dr-nav";
import { DrToolbarFilterSearch } from "@shared/ui/dr-toolbar";

import { $notifyDanger, $notifyWarning, matchText } from "@drVue/common";
import { pinia } from "@drVue/store/pinia";
import { useOrganizationGroups } from "@drVue/store/pinia/pipeline/org-groups";
import { useOrganizationMembers } from "@drVue/store/pinia/pipeline/org-members";
import OrgGroupPanel from "./OrgGroupPanel.vue";
import OrgMemberPanel from "./OrgMemberPanel.vue";
import OrgMembersTable from "./OrgMembersTable";

import type { TableDataRow } from "./OrgMembersTable/tableColumns";
import type { OrganizationGroup } from "@drVue/store/pinia/pipeline/org-groups";
import type { OrganizationMember } from "@drVue/store/pinia/pipeline/org-members";
import type { NavTreeItem } from "@shared/ui/dr-nav";

const orgGroupsStore = useOrganizationGroups(pinia);
const orgMembersStore = useOrganizationMembers(pinia);
const isLoading = computed(
  () => orgGroupsStore.isLoading || orgMembersStore.isLoading,
);

const membersSearch = ref("");
const currentGroupId = ref<OrganizationGroup["id"] | null>(null);

const isOrgGroupPanelShown = ref(false);
const editableOrgGroupId = ref<OrganizationGroup["id"] | null>(null);
const isOrgMemberPanelShown = ref(false);
const editableOrgMemberId = ref<OrganizationMember["id"] | null>(null);

const checkGroupIsEdiatable = (group: NavTreeItem) => {
  return group.name !== "Administrator";
};

const groupsList = computed<NavTreeItem[]>(() =>
  orgGroupsStore.list.map((group) => ({
    id: group.id,
    name: group.name,
  })),
);

const membersFiltered = computed<TableDataRow[]>(() => {
  let members = orgMembersStore.list;

  if (currentGroupId.value) {
    members = members.filter(
      (member) => member.group_id === currentGroupId.value,
    );
  }

  if (membersSearch.value) {
    members = members.filter((member) =>
      filterMember(membersSearch.value, member),
    );
  }

  return (
    members
      .map((member) => ({
        member: member,
        group: orgGroupsStore.dict[member.group_id],
      }))
      // filter removed groups' members
      .filter((item) => item.group)
  );
});

const filterMember = (query: string, member: OrganizationMember): boolean => {
  return matchText(query, member.user, [
    "name",
    "email",
    "title",
    "company",
    "office_number",
  ]);
};

const handleSelectMember = (id: OrganizationMember["id"]) => {
  editableOrgMemberId.value = id;
  isOrgMemberPanelShown.value = true;
};

const inviteMember = () => {
  editableOrgMemberId.value = null;
  isOrgMemberPanelShown.value = true;
};

const handleMemberRemoved = () => {
  editableOrgMemberId.value = null;
};

const handleSelectPermissionSet = (setId: NavTreeItem["id"] | "root") => {
  if (setId === "root") {
    currentGroupId.value = null;
    return;
  }
  currentGroupId.value = setId as OrganizationGroup["id"];
};

const handleAddPermissionSet = () => {
  editableOrgGroupId.value = null;
  isOrgGroupPanelShown.value = true;
};

const isPermissionSetDeleting = ref(false);
const handleDeletePermissionSet = async (permissionSet: OrganizationGroup) => {
  const messageList = [
    `Are you sure you want to remove the permission set "${permissionSet.name}"?`,
  ];

  const membersInSet = orgMembersStore.list.filter(
    (member) => member.group_id === permissionSet.id,
  );

  if (membersInSet.length) {
    messageList.push(
      `Please note that ${membersInSet.length} permission set's members will lose their access.`,
    );
  }

  try {
    await ElMessageBox({
      type: "warning",
      title: "Delete permission set",
      message: messageList.join(" "),
      confirmButtonText: "Delete",
      confirmButtonClass: "el-button--warning",
      showCancelButton: true,
    });
  } catch {
    return;
  }

  isPermissionSetDeleting.value = true;

  try {
    await orgGroupsStore.remove(permissionSet.id);
    await orgMembersStore.load();

    if (currentGroupId.value === permissionSet.id) {
      currentGroupId.value = null;
    }
  } catch (error) {
    let errMessage = `Failed to delete organization permission set "${permissionSet.name}".`;

    if (typeof error === "string") {
      errMessage = error;
    }
    $notifyDanger(errMessage);
  } finally {
    isPermissionSetDeleting.value = false;
  }
};

const handlePermissionSetMenu = (cmd: string, itemId: NavTreeItem["id"]) => {
  const permissionSet = orgGroupsStore.dict[itemId];
  if (!permissionSet) {
    $notifyWarning("Unexpected error: unable to get permission set data.");
    return;
  }

  editableOrgGroupId.value = itemId as OrganizationGroup["id"];

  switch (cmd) {
    case "edit":
      isOrgGroupPanelShown.value = true;
      break;
    case "delete":
      handleDeletePermissionSet(permissionSet);
      break;
  }
};

onMounted(() => {
  orgGroupsStore
    .load()
    .catch(() => $notifyDanger("Failed to load organization permission sets."));
  orgMembersStore
    .load()
    .catch(() => $notifyDanger("Failed to load organization members."));
});
</script>
