import { CustomViewObjectTypes } from "@setups/types";
import { CustomViewFiltersOp, CustomViewLayouts } from "./types";

import type { CustomView } from "./types";

const _emptyFilters = {
  search: "",
  op: CustomViewFiltersOp.AND,
  fields: {},
};

export const DEFAULT_DEALS_VIEWS: CustomView[] = [
  {
    slug: "board",
    name: "Board",
    layout: CustomViewLayouts.Board,
    object_type: CustomViewObjectTypes.Deal,
    is_personal: false,
    filters: _emptyFilters,
    settings: {},
    is_default: true,
  },
  {
    slug: "table",
    name: "Table",
    layout: CustomViewLayouts.Table,
    object_type: CustomViewObjectTypes.Deal,
    is_personal: false,
    filters: _emptyFilters,
    settings: {},
    is_default: true,
  },
  {
    slug: "timeline",
    name: "Timeline",
    layout: CustomViewLayouts.Timeline,
    object_type: CustomViewObjectTypes.Deal,
    is_personal: false,
    filters: _emptyFilters,
    settings: {},
    is_default: true,
  },
];

export const DEFAULT_DATAROOM_VIEW: CustomView = {
  settings: {
    columns: [],
  },
  name: "Default",
  object_type: CustomViewObjectTypes.DataRoom,
  filters: {
    fields: {},
    search: "",
    op: CustomViewFiltersOp.AND,
  },
  is_default: true,
  is_personal: true,
  layout: CustomViewLayouts.Table,
  slug: "",
};

export const DEFAULT_TASK_TABLE_VIEW: CustomView = {
  settings: {
    columns: [
      {
        field: "key",
        fixed: "left",
        order: 0,
      },
      {
        field: "title",
        fixed: "left",
        order: 1,
      },
    ],
  },
  name: "Default",
  object_type: CustomViewObjectTypes.Task,
  filters: {
    fields: {},
    search: "",
    op: CustomViewFiltersOp.AND,
  },
  is_default: true,
  is_personal: true,
  layout: CustomViewLayouts.Table,
  slug: "",
};

export const DEFAULT_TASK_GANTT_VIEW: CustomView = {
  settings: {
    columns: [
      {
        field: "_text",
        hidden: false,
        order: 0,
      },
      {
        field: "_status",
        hidden: false,
        order: 1,
      },
      {
        field: "_start_date",
        hidden: false,
        order: 2,
      },
      {
        field: "_due_date",
        hidden: false,
        order: 3,
      },
      {
        field: "_assignees",
        hidden: false,
        order: 4,
      },
      {
        field: "_priority",
        hidden: false,
        order: 5,
      },
    ],
  },
  name: "Default",
  object_type: CustomViewObjectTypes.Task,
  filters: {
    fields: {},
    search: "",
    op: CustomViewFiltersOp.AND,
  },
  is_default: true,
  is_personal: true,
  layout: CustomViewLayouts.Timeline,
  slug: "",
};

export const DEFAULT_FINDING_VIEW: CustomView = {
  settings: {
    columns: [
      {
        field: "key",
        hidden: false,
        order: 0,
      },
      {
        field: "type_id",
        hidden: false,
        order: 1,
      },
      {
        field: "title",
        hidden: false,
        order: 2,
      },
      {
        field: "status_id",
        hidden: false,
        order: 3,
      },
      {
        field: "severity",
        hidden: false,
        order: 4,
      },
      {
        field: "likelihood",
        hidden: false,
        order: 5,
      },
      {
        field: "ties_to",
        hidden: false,
        order: 6,
      },
      {
        field: "assignees",
        hidden: false,
        order: 7,
      },
      {
        field: "date_added",
        hidden: true,
        order: 8,
      },
      {
        field: "date_added",
        hidden: true,
        order: 9,
      },
      {
        field: "added_by_id",
        hidden: true,
        order: 10,
      },
      {
        field: "date_resolved",
        hidden: true,
        order: 11,
      },
      {
        field: "description",
        hidden: true,
        order: 12,
      },
      {
        field: "mitigation_plan",
        hidden: true,
        order: 13,
      },
      {
        field: "actual_mitigation",
        hidden: true,
        order: 14,
      },
    ],
  },
  name: "Default",
  object_type: CustomViewObjectTypes.Finding,
  filters: {
    fields: {},
    search: "",
    op: CustomViewFiltersOp.AND,
  },
  is_default: true,
  is_personal: true,
  layout: CustomViewLayouts.Table,
  slug: "",
};

export const DEFAULT_SYNERGY_VIEW: CustomView = {
  settings: {
    columns: [
      {
        field: "title",
        hidden: false,
        order: 0,
      },
      {
        field: "enabled_by",
        hidden: false,
        order: 1,
      },
    ],
  },
  name: "Default",
  object_type: CustomViewObjectTypes.Synergy,
  filters: {
    fields: {},
    search: "",
    op: CustomViewFiltersOp.AND,
  },
  is_default: true,
  is_personal: true,
  layout: CustomViewLayouts.Table,
  slug: "",
};
