<template>
  <DrDrawer
    size="large"
    fixed-footer
    title="New summary"
    submit-btn="Create and customize"
    :shown="isOpen"
    :is-submitting="isFormSubmitting"
    @submit="submit"
    @close="resetAndClose"
    @cancel="resetAndClose"
    @closed="$emit('close')"
  >
    <DrDynamicFormClassic
      ref="form"
      :entity="model"
      :schema="labelSchema"
      :errors="formErrors"
      :disabled="isFormSubmitting"
      @update="model[$event.field as keyof Model] = $event.value"
      @submit="submit"
    />

    <div :class="$style.description">
      Please use the relevant name based on the document type (e.g., “Employee
      contracts summary”). That way, we can suggest proper fields for your
      summary.
    </div>

    <ElAlert
      v-if="formErrors.non_field_errors"
      type="error"
      :title="formErrors.non_field_errors"
    />
  </DrDrawer>
</template>

<script lang="ts" setup>
import { type ComponentInstance, computed, reactive, ref } from "vue";
import { DrDrawer } from "@shared/ui/dr-drawer";
import { DrDynamicFormClassic } from "@shared/ui/dr-dynamic-form";
import { validateString } from "@shared/ui/dr-dynamic-form/utils";
import { useFormHelper } from "@shared/ui/dr-form";

import { AiReportsEvent, insightTrack } from "@app/insight";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import { pinia } from "@drVue/store/pinia";
import { useAiReportsStore } from "../AiReportsStore";

import type { AiReport } from "../types";
import type { FormSchema } from "@shared/ui/dr-dynamic-form/types";

interface Emits {
  (event: "close"): void;
  (event: "created", report: AiReport): void;
}

const emit = defineEmits<Emits>();

const aiReportsStore = useAiReportsStore(pinia);

const isOpen = ref(true);
const form = ref<ComponentInstance<typeof DrDynamicFormClassic> | undefined>();

const model = reactive({
  label: "",
});
type Model = typeof model;

const { formErrors, hookFormSubmitPromise, isFormSubmitting, resetErrors } =
  useFormHelper<Model>();

const labelSchema = computed(
  (): FormSchema => [
    {
      type: FieldSchemaType.Text,
      required: true,
      isReadOnly: isFormSubmitting.value,
      prop: "label",
      label: "Summary name",
      rules: validateString().required("This field may not be blank"),
      extra: {
        autofocus: 200,
      },
    },
  ],
);

const submit = async () => {
  if (isFormSubmitting.value) return;

  resetErrors();

  const result = await form.value?.validate().catch((error) => error);
  if (result !== true) return;

  hookFormSubmitPromise(
    aiReportsStore.create({ label: model.label }),
    "Failed to create report",
  ).then((data) => {
    insightTrack(AiReportsEvent.ReportCreated, { label: model.label });

    emit("created", data);
    resetAndClose();
  });
};

const resetForms = () => {
  resetErrors();
  model.label = "";

  form.value?.reset({
    label: "",
  });
};

const handleClose = () => {
  isOpen.value = false;
};

const resetAndClose = () => {
  resetForms();
  handleClose();
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: colors.$pr-600;
  margin-top: 14px;
}
</style>
