import type { CustomData } from "@app/ng/tasks/services/ts/types/Filters";
import type { CustomDataType } from "@app/ng/tasks/services/ts/types/Filters";
import type { Category } from "@drVue/store/pinia/room/categories/api";
import type {
  Finding,
  FindingUpdatePayload,
} from "@drVue/store/pinia/room/findings";
import type { CustomViewsFilterFields } from "@setups/types";
import type { FieldProps } from "@shared/ui/dr-dynamic-form/types";

export type FindingDetails = Omit<Finding, "tasks" | "categories"> & {
  ties_to: Pick<Finding, "tasks" | "categories">;
};
export type { FindingUpdatePayload };

export const FINDINGS_TABLE_FIELDS: ReadonlyArray<keyof FindingDetails> = [
  "id",
  "key",
  "title",
  "type_id",
  "severity",
  "likelihood",
  "ties_to",
  "description",
  "mitigation_plan",
  "actual_mitigation",
  "date_added",
  "date_resolved",
  "added_by_id",
  "assignees",
] as const;
export type FindingsTableField = (typeof FINDINGS_TABLE_FIELDS)[number];
export type FindingsTableRow = Finding;

export type FindingsFilters = CustomViewsFilterFields<
  FindingsTableRow,
  (number | string | boolean)[]
>;

export type FindingsToolbarFiltersModel = {
  type_id: FindingsTableRow["type_id"][];
  status_id: FindingsTableRow["status_id"][];
  severity: NonNullable<FindingsTableRow["severity"]>[];
  likelihood: NonNullable<FindingsTableRow["likelihood"]>[];
  assignees: FindingsTableRow["assignees"][number]["user_id"][];
};

export type FindingsToolbarFiltersUpdate = {
  field: keyof FindingsToolbarFiltersModel;
  value: FindingsToolbarFiltersModel[keyof FindingsToolbarFiltersModel];
};

export type FindingsCustomDataFiltersModel = CustomData;

export type FindingsCustomDataFiltersUpdate = {
  field: string;
  value: CustomDataType;
};

/**
 * Value matching function:
 * "eq"  - exact matching
 * "in"  - the value being tested must be in the list of allowed values
 * "bt"  - the value being tested must be within the specified range
 *         (between start and end value)
 *
 * @todo place to "common" | "setups"
 */
type FilterValueMatchingFunction = "eq" | "in" | "bt";

export const mapFindingsFieldToMatchingFunction: {
  [k in keyof FindingsTableRow]?: FilterValueMatchingFunction;
} = {
  type_id: "eq",
  severity: "eq",
  likelihood: "eq",
  status_id: "eq",
  assignees: "eq",
};

export type TiesToControlProps = {
  editProps: FieldProps<Pick<Finding, "tasks" | "categories">>;
};
export type ParticipantsControlProps = {
  editProps: FieldProps<Finding["assignees"]>;
};

export const FINDING_UNTIED_CATEGORY_KEY = "untied";
export const FINDING_ROOT_CATEGORY_KEY = "all";

export type FindingCategoryId =
  | Category["id"]
  | typeof FINDING_UNTIED_CATEGORY_KEY
  | typeof FINDING_ROOT_CATEGORY_KEY;
