<template>
  <span :class="$style.action"><slot /></span>
</template>

<script setup lang="ts"></script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.action {
  color: colors.$pr-0;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    color: colors.$sc-400;
  }
}
</style>
