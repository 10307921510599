<template>
  <DrLayoutContentCenteredSection title="Finding statuses">
    <template #description>
      Set finding statuses to organize your team's workflow in rooms of your
      organization.
    </template>
    <template #title-right>
      <ElButton type="primary" @click="showCreateModal">
        <span>New status</span>
        <DrIcon name="plus-r" size="sm" />
      </ElButton>
    </template>
    <div :class="$style.content">
      <FindingStatusesForm ref="FindingStatusesFormRef" />
    </div>
  </DrLayoutContentCenteredSection>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrLayoutContentCenteredSection } from "@shared/ui/dr-layouts";

import FindingStatusesForm from "@drVue/components/room/findings/settings/FindingStatusesForm.vue";

const FindingStatusesFormRef = ref<InstanceType<typeof FindingStatusesForm>>();

const showCreateModal = () => {
  FindingStatusesFormRef.value?.addNewStatus();
};
</script>

<style module lang="scss">
.content {
  min-height: 225px;
}
</style>
