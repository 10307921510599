<template>
  <DrIconStatus size="sm" :color="status?.color" />
  <span v-if="status" class="truncate">
    {{ status.name }}
  </span>
  <span v-else>...</span>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import DrIconStatus from "@shared/ui/dr-icon-status";

import DrStore from "@drVue/store";

import type { ViewFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { TaskStatus } from "@drVue/store/modules/room/tasks-statuses/types";
import type { Task } from "@drVue/store/pinia/room/tasks";

interface Props {
  viewProps: ViewFieldProps<number, Task>;
}

const props = defineProps<Props>();

const status = computed<TaskStatus>(() =>
  DrStore.getters["room/tasksStatuses/byId"](props.viewProps.entityValue),
);
</script>
