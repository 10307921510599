import type { RoomMembersState } from "./RoomMembersState";
import type { RoomMember } from "@drVue/store/modules/room/members/RoomMembersApiService";
import type { Dictionary } from "@drVue/types";
import type { MutationTree } from "vuex";

export interface SetStatePayload {
  members: Dictionary<RoomMember>;
  membersByUid: Dictionary<RoomMember>;
  membersList: RoomMember[];

  invites: Dictionary<RoomMember>;
  invitesList: RoomMember[];

  activeMembersList: RoomMember[];
}

export const roomMembersMutations: MutationTree<RoomMembersState> = {
  setPromise(state: RoomMembersState, promise: Promise<RoomMember[]>) {
    state.updatePromise = promise;
  },

  setState(state: RoomMembersState, payload: SetStatePayload) {
    state.members = payload.members;
    state.membersByUid = payload.membersByUid;
    state.membersList = payload.membersList;
    state.invites = payload.invites;
    state.invitesList = payload.invitesList;

    state.activeMembersList = payload.activeMembersList;
  },

  setIsLoading(state: RoomMembersState, isLoading: boolean) {
    state.isLoading = isLoading;
  },

  setIsError(state: RoomMembersState, isError: boolean) {
    state.isError = isError;
  },

  setInviteResending(
    state: RoomMembersState,
    { id, value }: { id: number; value: boolean },
  ) {
    state.inviteResending[id] = value;
  },
};
