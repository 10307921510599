import baseHtml from "./index.html?raw";

Controller.$inject = ["AnalyticsDispatcherFactory"];
import "./nest";

angular
  .module("dealroom.analytics.card.linechart.base", [
    "dealroom.analytics.card.linechart.base.nest",
    "dealroom.analytics.card.chart.header",
    "dealroom.analytics.factory.dispatcher",
  ])
  .component("drAnalyticsLinechartCard", {
    bindings: {
      name: "<",
      filterBy: "<",
      metricsTypes: "<",
      displayCount: "<",
      getYList: "<",
    },
    template: baseHtml,
    controller: Controller,
  });

function Controller(AnalyticsDispatcherFactory) {
  const $ctrl = this;

  let selectedKey, nested;

  $ctrl.dispatcher = AnalyticsDispatcherFactory("update", "nested");
  $ctrl.dispatcher.on("nested.base", handleNested);

  $ctrl.handleSelect = handleSelect;

  function handleNested(n) {
    nested = n;
    mkStats();
  }

  function handleSelect({ label, totalLabels }) {
    selectedKey = { label, totalLabels };
    mkStats();
  }

  function mkStats() {
    if (!nested) return;
    if (!selectedKey) return;

    const nestedMetrics = nested[selectedKey.label]; // {yKey: {dt: int, ..}, ...}
    const data = $ctrl.getYList().map(({ key, title }) => {
      const ticks = d3.entries(nestedMetrics[key]).map(({ key, value }) => ({
        dt: new Date(key),
        value: Math.round(value),
      }));
      const total = ticks.reduce((count, tick) => count + tick.value, 0);
      return {
        title,
        key,
        ticks,
        total,
        label: getLabel(total),
      };

      function getLabel(total) {
        const labels = selectedKey.totalLabels;
        let label;
        if (angular.isString(labels)) label = labels;
        if (angular.isArray(labels)) {
          label = total === 1 ? labels[0] : labels[1];
        }
        return label ? `${total} ${label}` : total;
      }
    });

    $ctrl.dispatcher.call("update", data, { displayCount: $ctrl.displayCount });
  }
}
