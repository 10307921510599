<template>
  <div class="task-participants-list__row">
    <UserInformation :user="user" />
    <i
      class="fa fa-times task-participants-list__remove-icon"
      @click="$emit('remove', value)"
    />
    <ParticipantUserRemind
      v-if="showRemindButton"
      class="task-participants-list__remind-btn"
      :user-id="value.user_id"
      :task="task"
      @remind="$emit('remind')"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UserInformation from "@shared/ui/users/user-information/index.vue";

import ParticipantUserRemind from "./ParticipantUserRemind.vue";

import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { ParticipantUserV1, Task } from "@drVue/store/pinia/room/tasks";
import type { PropType } from "vue";

export default defineComponent({
  name: "ParticipantUserRow",
  components: { UserInformation, ParticipantUserRemind },
  props: {
    value: { required: true, type: Object as PropType<ParticipantUserV1> },
    task: { required: true, type: Object as PropType<Task> },
    showRemindButton: {
      required: false,
      default: false,
      type: Boolean as PropType<boolean>,
    },
  },
  emits: ["remove", "remind"],
  computed: {
    user(): OrgUser {
      const members = this.$store.state.room.members.members;
      return members[this.value.user_id] as unknown as OrgUser;
    },
  },
});
</script>
