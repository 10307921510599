<template>
  <div>
    <a style="margin-right: 10px" @click="edit">Edit</a>
    <a @click="deleteFinding">Delete</a>
  </div>
</template>

<script setup lang="ts">
import {
  findingTypeTableEventNames,
  useFindingTypesTableEventBus,
} from "@drVue/components/room/findings/settings/findingsTypesTable/eventBus";

interface Props {
  findingTypeId: string;
}

const props = defineProps<Props>();
const eventBus = useFindingTypesTableEventBus();

const edit = () => {
  eventBus.emit(findingTypeTableEventNames.edit, { id: props.findingTypeId });
};
const deleteFinding = () => {
  eventBus.emit(findingTypeTableEventNames.delete, { id: props.findingTypeId });
};
</script>
