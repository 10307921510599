<template>
  <div v-show="false" ref="editorWrapperRef">
    <DrEditor :editable="false" :model-value="body" />
  </div>
  {{ text }}
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref } from "vue";
import { watch } from "vue";
import DrEditor from "@shared/ui/editor";

import type { Node as ProsemirrorNode } from "@tiptap/pm/model";

interface Props {
  body: ProsemirrorNode | null;
}

const props = defineProps<Props>();

const editorWrapperRef = ref<InstanceType<typeof HTMLDivElement> | null>(null);

const text = ref("");

const extractTextContent = () => {
  nextTick(() => {
    text.value = editorWrapperRef.value!.textContent ?? "";
  });
};

onMounted(() => {
  extractTextContent();
});

watch(() => props.body, extractTextContent);
</script>
