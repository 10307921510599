import templates_confirmDialogHtml from "../../../templates/confirm-dialog.html?raw";

RoomDocumentsArchiveListController.$inject = [
  "$scope",
  "$uibModal",
  "$stateParams",
  "drMimetype",
  "RoomConfig",
  "AlertService",
  "DocumentsApiService",
  "DocumentsService",
  "TasksService",
  "CategoriesService",
];
import { insightTrack, RoomDataroomBulkEvent } from "@app/insight";
import RoomDocumentFolderService from "../services/RoomDocumentFolderService";

export default function RoomDocumentsArchiveListController(
  $scope,
  $uibModal,
  $stateParams,
  drMimetype,
  RoomConfig,
  AlertService,
  DocumentsApiService,
  DocumentsService,
  TasksService,
  CategoriesService,
) {
  var self = this;
  self.Documents = DocumentsService;

  var docsCtrl = $scope.docsCtrl;
  docsCtrl.selectedFolderId = undefined;
  docsCtrl.recent = false;
  docsCtrl.bookmarks = false;
  docsCtrl.archive = $stateParams.archive ?? false;

  self.updateItems = updateItems;
  self.loadArchived = loadArchived;
  self.resetSearchQuery = resetSearchQuery;
  self.loading = true;
  self.error = false;
  self.dataService = undefined;
  self.searchQuery = undefined;
  self.allItems = undefined;

  self.Tasks = TasksService;
  self.Categories = CategoriesService;

  self.toggleItemSelected = function (item, $event) {
    self.dataService.toggleItemSelected(item, $event);
  };

  self.toggleSelectAllCheckbox = function () {
    self.dataService.update();
  };
  // VXE Table related
  //
  self.sortConfig = {
    field: "treeIndex",
    order: "asc",
  };

  loadArchived();
  // ng-change update model after callback fire, use this workaround for now
  $scope.$watch("ctrl.searchQuery", updateItems);

  function treePosition(tree_index) {
    return tree_index.split(".").reduce((acc, val) => {
      return acc * 10 + parseInt(val);
    }, 0);
  }

  function loadArchived() {
    self.loading = true;
    return DocumentsApiService.listArchive().then(
      function (resp) {
        resp.data.forEach(function (item) {
          item.iconClass =
            item.version && drMimetype.getIconClass(item.version.mimetype);
          item.treePosition = treePosition(item.tree_index);
          item.treeIndex = item.tree_index;
        });
        self.allItems = resp.data;
        self.loading = false;
        self.error = false;
        self.updateItems();
      },
      function () {
        self.error = true;
        self.loading = false;
      },
    );
  }

  function updateItems() {
    if (self.loading) {
      return;
    }
    if (self.searchQuery) {
      var files = {},
        folders = {};
      self.allItems.forEach(function (item) {
        var storage = item.type === "file" ? files : folders;
        storage[item.id] = item;
      });
      var filteredItems = DocumentsService.findItems(
        self.searchQuery,
        files,
        folders,
      );

      self.dataService = RoomDocumentFolderService.getInstance(filteredItems);
    } else {
      self.dataService = RoomDocumentFolderService.getInstance(self.allItems);
    }
  }

  function resetSearchQuery() {
    self.searchQuery = undefined;
  }
}
