<template>
  <div
    :class="{
      [$style.wrapper]: true,
      [$style.wrapper_isReadonly]: editProps.schema.isReadOnly,
      [$style.wrapper_isActivated]: isActivated,
      [$style.wrapper_isFocused]: isFocused,
    }"
  >
    <DrEditor
      ref="editorRef"
      :model-value="editProps.value"
      :options="{
        showMenuOnFocus: true,
        placeholder: editProps.schema.placeholder || 'Write...',
      }"
      :editable="!editProps.schema.isReadOnly"
      @focus="handleFocus"
      @blur="handleBlur"
      @update:model-value="editProps.veeField.onInput"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, unref } from "vue";
import DrEditor from "@shared/ui/editor";

import type { FieldProps } from "../types";
import type { Node as ProsemirrorNode } from "@tiptap/pm/model";

interface Props {
  editProps: FieldProps<ProsemirrorNode | null>;
}

const props = defineProps<Props>();

const isActivated = ref(false);
const isFocused = ref(false);
const editorRef = ref();

const handleFocus = () => {
  isFocused.value = true;
  if (!isActivated.value) {
    isActivated.value = true;
  }
};

const handleBlur = (event: FocusEvent) => {
  isFocused.value = false;
  props.editProps.veeField.onBlur(event);
};
const focus = () => {
  unref(editorRef)?.focus();
};

defineExpose({ focus });
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";
@use "@app/styles/scss/values";

.wrapper {
  min-height: values.$base-input-height;
  border: 1px solid colors.$pr-300;
  border-radius: values.$base-border-radius;

  :global {
    .dr-editor__wrapper {
      border: none;
      border-radius: values.$base-border-radius;
    }

    .dr-editor__content > .ProseMirror {
      min-height: 24px;
    }

    .dr-editor__placeholder:first-child::before {
      color: colors.$pr-400;
      font: typography.$body_regular;
    }
  }

  &:hover:not(.wrapper_isFocused, .wrapper_isReadonly) {
    border-color: colors.$pr-400;
  }
}

.wrapper:not(.wrapper_isActivated) {
  :global(.dr-editor__wrapper) {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.wrapper_isFocused {
  border-color: colors.$sc-500;
  box-shadow: 0 0 0 3px rgba(colors.$sc-200-rgb, 0.2);
}

.wrapper_isReadonly {
  :global(.dr-editor__wrapper) {
    padding: 6px 12px;
    color: colors.$pr-400;
  }
}
</style>
