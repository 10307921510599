<template>
  <p v-if="type === 'delete'" :class="$style.text">
    Are you sure you want to permanently delete the deal
    <b>"{{ deal.title }}"</b> from pipeline?
    <template v-if="hasNotRemovedRoom">
      <br />
      <br />
      <span class="text-danger">
        <b>
          Deleting of this deal will permanently delete all assoiciated data.
        </b>
      </span>
    </template>
  </p>

  <p v-if="type === 'restore'" :class="$style.text">
    Are you sure you want to restore the deal
    <b>"{{ deal.title }}"</b> from trash?
    <template v-if="noPhaseCheck">
      <br />
      <br />
      <span class="text-danger">
        <b>
          The initial phase of the deal was removed. It will be restored to the
          "{{ firstPhaseName }}" stage of your pipeline.
        </b>
      </span>
    </template>
  </p>
</template>

<script setup lang="ts">
import { computed } from "vue";

import DrStore from "@drVue/store";

import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";

interface Props {
  type: "restore" | "delete";
  deal: Deal;
}

const props = defineProps<Props>();

const noPhaseCheck = computed(
  () =>
    !DrStore.state.clientDashboard.phases.items.find(
      (phase) => phase.id === props.deal.phase,
    ),
);

const firstPhaseName = computed(
  () => DrStore.state.clientDashboard.phases.items[0].name,
);

/**
 * @important This doesn't match the types,
 * but in fact the value ``is_room_removed`` is not only a boolean,
 * but also may be a null. */
const hasNotRemovedRoom = computed(() => props.deal.is_room_removed === false);
</script>

<style module lang="scss">
@use "@app/styles/scss/spacing";

.text {
  margin-bottom: spacing.$s !important;
}
</style>
