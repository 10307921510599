import { isStringContains } from "@drVue/common";
import { filterCustomFields, formatFields } from "./utils";

import type { ExtraFieldFormatted, FieldItem, RootState, State } from "./types";
import type { CustomViewObjectTypes } from "@setups/types";
import type { GetterTree } from "vuex";

export const getters: GetterTree<State, RootState> = {
  byObjectType:
    (state) =>
    (objectType: CustomViewObjectTypes | string): FieldItem[] => {
      return state.items.filter(
        (item: FieldItem) => item.object_type === objectType,
      );
    },

  formattedFields:
    (state, localGetters) =>
    (objectType: string, customData: any): ExtraFieldFormatted[] => {
      const allFields = localGetters.byObjectType(objectType);
      return formatFields(allFields, customData);
    },

  filterFields:
    (state, localGetters) =>
    (query: string, objectType: string, customData: any): boolean => {
      const formattedFields: ExtraFieldFormatted[] =
        localGetters.formattedFields(objectType, customData);
      return formattedFields.some((field) => {
        return isStringContains(query, field.value);
      });
    },

  filterCustomFields:
    (state, localGetters) =>
    (
      objectType: string,
      customDataFilters: { [key: string]: any },
      customDataValues: { [key: string]: any },
    ): boolean => {
      const allFields = localGetters.byObjectType(objectType);
      return filterCustomFields(allFields, customDataFilters, customDataValues);
    },

  byId:
    (state) =>
    (id: number): FieldItem | undefined => {
      return state.items.find((item: FieldItem) => item.id === id);
    },

  byKey:
    (state) =>
    (key: string): FieldItem | undefined => {
      return state.items.find((item: FieldItem) => item.key === key);
    },
};
