import { createDictionary } from "@drVue/common";

import type { Dictionary } from "@drVue/types";
import type { ZoomLevel } from "@setups/types";

const AUTO_SCHEDULING_KEY = "dr:gantt_auto_scheduling_enabled";
const CRITICAL_PATH_KEY = "dr:gantt_critical_path_enabled";
const COLLAPSED_CATS_KEY = "dr:gantt_collapsed_categories";
const CATS_STATE_KEY = "dr:gantt_categories_state";
const ARE_COLUMNS_HIDDEN_KEY = "dr:gantt_are_columns_hidden";
const GRID_SIZE_KEY = "dr:gantt_grid_size";
const GRID_WIDTH_KEY = "dr:gantt_grid_width";
const ZOOM_LEVEL_KEY = "dr:gantt_zoom_level";

// TODO: remove both calls to `removeItem` in the future.
localStorage.removeItem(GRID_SIZE_KEY);
localStorage.removeItem(COLLAPSED_CATS_KEY);

export enum GanttCategoryState {
  Closed = 0,
  Open = 1,
}

export const getCategoriesState = () => {
  let state: Dictionary<number>;

  const stateStr = localStorage.getItem(CATS_STATE_KEY);
  const date = new Date();
  if (stateStr === null) {
    state = createDictionary({ date: date.getTime() });
  } else {
    try {
      state = JSON.parse(stateStr);
    } catch {
      state = createDictionary({ date: date.getTime() });
    }
  }

  return state;
};

export const saveCategoryState = (id: string, catState: GanttCategoryState) => {
  const state = getCategoriesState();
  state[id] = catState;
  localStorage.setItem(CATS_STATE_KEY, JSON.stringify(state));
};

export const saveGridWidth = (width: number) => {
  localStorage.setItem(GRID_WIDTH_KEY, `${width}`);
};

export const getGridWidth = () => {
  const sizeStr = localStorage.getItem(GRID_WIDTH_KEY);
  if (sizeStr === null) return null;

  return Number(sizeStr);
};

export const saveZoomLevel = (zoom: ZoomLevel) => {
  localStorage.setItem(ZOOM_LEVEL_KEY, zoom);
};

export const getZoomLevel = (): ZoomLevel | null => {
  const zoomStr = localStorage.getItem(ZOOM_LEVEL_KEY);
  if (zoomStr === null) return null;

  return zoomStr as ZoomLevel;
};

export const saveIsAutoSchedulingEnabled = (isEnabled: boolean) => {
  localStorage.setItem(AUTO_SCHEDULING_KEY, `${isEnabled}`);
};

export const getIsAutoSchedulingEnabled = () => {
  const isEnabledStr = localStorage.getItem(AUTO_SCHEDULING_KEY);
  if (isEnabledStr === null) return false;

  return isEnabledStr === "true";
};

export const getIsCriticalPathEnabled = () => {
  const isEnabledStr = localStorage.getItem(CRITICAL_PATH_KEY);
  if (isEnabledStr === null) return false;

  return isEnabledStr === "true";
};

export const saveIsCriticalPathEnabled = (isEnabled: boolean) => {
  localStorage.setItem(CRITICAL_PATH_KEY, `${isEnabled}`);
};
