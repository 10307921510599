import { DrStore } from "@drVue";

angular
  .module("dealroom.analytics.service.data.categories.visible", [
    "dealroom.analytics.service.data.categories.tasks-counter",
    "dealroom.analytics.service.data.dispatcher",
    "dealroom.analytics.service.labels",
  ])
  .service(
    "ActivityVisibleCategoriesService",
    ActivityVisibleCategoriesService,
  );

ActivityVisibleCategoriesService.$inject = [
  "ActivityCategoriesService",
  "ActivityCategoriesTaskCounterService",
  "AnalyticsDataDispatcher",
  "ActivityLabelService",
  "ActivityTasksService",
  "CategoriesService",
];
function ActivityVisibleCategoriesService(
  ActivityCategoriesService,
  ActivityCategoriesTaskCounterService,
  AnalyticsDataDispatcher,
  ActivityLabelService,
  ActivityTasksService,
  CategoriesService,
) {
  const service = {
    visibleStats: [],
    visibleStatsSummary: ActivityCategoriesService.getEmptyStats(),

    visibleRootCategoriesIds: [],
    visibleStatuses: [],
    visiblePriorities: [],

    // SET
    set: setVisisbleLists,
    setStatuses: setVisisbleStatuses,
    setPriorities: setVisisblePriorities,

    // GET
    isVisible,
    isTaskVisible,
    isStatusVisible,
    isPriorityVisible,

    isDocIdInAttachments,

    getSelectedListsRangeAsString,
    getSelectedPriorityRangeAsString,
    getSelectedStatusesRangeAsString,

    getVisisbleTasks,

    loadingPromise: ActivityCategoriesService.loadingPromise.then(mkStats),
  };

  return service;

  function mkStats() {
    setVisisbleStatuses(service.visibleStatuses);
    setVisisblePriorities(service.visiblePriorities);
    setVisisbleLists(service.visibleRootCategoriesIds);
  }

  function getSelectedListsRangeAsString() {
    return service.visibleRootCategoriesIds.length ===
      ActivityCategoriesService.rootCategoriesIds.length
      ? "All"
      : service.visibleRootCategoriesIds
          .map((listId) => {
            return ActivityCategoriesService.categories[listId].name;
          })
          .join(", ");
  }
  function getSelectedPriorityRangeAsString() {
    return service.visiblePriorities.length ===
      ActivityCategoriesTaskCounterService.PRIORITIES.length
      ? "All"
      : service.visiblePriorities.join(", ");
  }
  function getSelectedStatusesRangeAsString() {
    return service.visibleStatuses.length ===
      ActivityCategoriesTaskCounterService.STATUSES.length
      ? "All"
      : service.visibleStatuses
          .map(
            (statusId) =>
              DrStore.getters["room/tasksStatuses/byId"](statusId).name,
          )
          .join(", ");
  }

  // SETS
  function setVisisbleLists(ids = ActivityCategoriesService.rootCategoriesIds) {
    // use numbers! && copy
    service.visibleRootCategoriesIds = ids.map((i) => +i);
    _setVisibleStats();
  }

  function setVisisbleStatuses(
    statusesIds = ActivityCategoriesTaskCounterService.STATUSES,
  ) {
    // use numbers! && copy
    service.visibleStatuses = statusesIds.map((i) => +i);
    _setVisibleStats();
  }

  function setVisisblePriorities(
    prioritiesNames = ActivityCategoriesTaskCounterService.PRIORITIES,
  ) {
    service.visiblePriorities = prioritiesNames.slice(); // copy
    _setVisibleStats();
  }

  function _setVisibleStats() {
    if (!ActivityCategoriesService.rootCategoriesStats) return;

    service.visibleStats = service.visibleRootCategoriesIds.map(_processStats);

    service.visibleStatsSummary = service.visibleStats.reduce(
      _addStats,
      ActivityCategoriesService.getEmptyStats(),
    );

    AnalyticsDataDispatcher.call("filtersChanged");

    ////
    function _processStats(catId) {
      const catStats = angular.copy(
        ActivityCategoriesService.rootCategoriesStats[catId],
      );
      catStats.tasks = catStats.tasks.copy({
        statusesIds: service.visibleStatuses,
        prioritiesNames: service.visiblePriorities,
      });
      catStats.taskIds = catStats.tasks.get().map((task) => +task.id);
      return catStats;
    }

    function _addStats(summStats, catStats) {
      catStats.attachments.forEach((itemId) => {
        if (summStats.attachments.indexOf(itemId) === -1) {
          summStats.attachments.push(itemId);
        }
      });
      catStats.pgroupIds.forEach((itemId) => {
        if (summStats.pgroupIds.indexOf(itemId) === -1) {
          summStats.pgroupIds.push(itemId);
        }
      });
      Object.keys(catStats.membersIds).forEach((memberId) => {
        summStats.membersIds[memberId] = catStats.membersIds[memberId];
      });
      summStats.taskIds = summStats.taskIds.concat(catStats.taskIds);
      return summStats;
    }
  }
  // GETS
  function isVisible(catId) {
    return (service.visibleRootCategoriesIds || []).includes(+catId);
  }

  function isTaskVisible(taskId, includeArchive) {
    const task = ActivityTasksService.tasks[taskId];
    if (task.is_archive && !includeArchive) return false;
    const rootCategoriesId = CategoriesService.childIdToRoot[task.category_id];
    if (!isVisible(rootCategoriesId)) return false;
    if (!isStatusVisible(task.status_id)) return false;
    if (!isPriorityVisible(task.priority)) return false;
    return true;
  }

  function isStatusVisible(statusId) {
    return service.visibleStatuses === null
      ? true
      : service.visibleStatuses.includes(+statusId);
  }

  function isPriorityVisible(priority) {
    return service.visiblePriorities === null
      ? true
      : service.visiblePriorities.includes(priority);
  }

  function isDocIdInAttachments(docId) {
    return service.visibleStatsSummary.attachments.includes(+docId);
  }

  function getVisisbleTasks(catId, taskTypes) {
    const tasksIds = ActivityCategoriesService.getCategoryTasks(catId) || [];
    return tasksIds.filter(isTaskVisible);
  }
}
