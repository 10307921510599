import { Activity } from "./activity";
import { DealsModule } from "./deals/DealsModule";
import { CustomFields } from "./fields";
import { Filters } from "./filters";
import { getters } from "./getters";
import { OrgUsersModule } from "./org-users/OrgUsersModule";
import { PhasesModule } from "./phases/PhasesModule";
import { Preferences } from "./preferences";
import { Settings } from "./settings";

import type { State } from "./state";
import type { RootState } from "@drVue/store/state";
import type { Module, ModuleTree } from "vuex";

export type { State } from "./state";

const modules: ModuleTree<RootState> = {
  activity: Activity,
  customFields: CustomFields,
  deals: DealsModule,
  filters: Filters,
  orgUsers: OrgUsersModule,
  phases: PhasesModule,
  preferences: Preferences,
  settings: Settings,
};

export const clientDashboard: Module<State, RootState> = {
  namespaced: true,
  getters,
  modules,
};
