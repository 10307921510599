<template>
  <DrUserBox :users="users" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DrUserBox } from "@shared/ui/dr-user-box";

import { DrStore } from "@app/vue";

import type { FindingParticipantUser } from "@drVue/api-service/modules/findings/types";
import type { User } from "@shared/ui/dr-user-box/types";

interface Props {
  assigneeUids: FindingParticipantUser[];
}

const props = defineProps<Props>();

const users = computed(() =>
  props.assigneeUids.reduce((acc, { user_id }) => {
    const user = DrStore.state.room.members.membersByUid[user_id];
    if (user) {
      acc.push(user);
    }
    return acc;
  }, [] as User[]),
);
</script>
