<template>
  <div :class="$style.title">This will allow the application to:</div>
  <div class="wrapper">
    <div v-for="scopeKey in scopeKeys" :key="scopeKey" :class="$style.scope">
      <i :class="$style.point" />
      <div>
        {{ scopes[scopeKey] }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import type { ScopesDictionary } from "@drVue/components/oauth/OAuth2AuthorizePageData";
import type { PropType } from "vue";

const props = defineProps({
  scopes: {
    type: Object as PropType<ScopesDictionary>,
    required: true,
  },
});

const scopeKeys = computed(() => {
  return Object.keys(props.scopes).sort();
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/_colors" as colors;
@use "@app/styles/scss/typography.scss" as typo;
.title {
  font-size: typo.$font-size-title;
  font-weight: typo.$font-weight-medium;
}
.scope {
  display: flex;
  font-size: typo.$font-size-base;
  align-items: center;
  gap: 8px;
  margin: 3px 0;
}
.point {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: colors.$sc-600;
  display: inline-block;
}
</style>
