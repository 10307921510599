<template>
  <div>
    <ElPopover
      ref="selectPopover"
      :visible="isSelectPopoverVisible"
      :popper-options="{
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top', 'bottom', 'left', 'right'],
            },
          },
        ],
      }"
      width="350"
      trigger="click"
      popper-class="el-popover--no-padding"
      placement="left"
    >
      <TaskParticipantsSelect
        v-if="isSelectPopoverVisible"
        :value="selectedValues"
        :task-category-id="task.category_id"
        @change="updateSelectedValue"
        @update-content="updatePopperPosition"
      />
      <template #reference>
        <div
          class="task-detail-infoitem cursor-pointer"
          @click="isSelectPopoverVisible = !isSelectPopoverVisible"
        >
          <div class="task-detail-infoitem__btn">
            <span class="dr-plus-ico">
              <i class="fas fa-plus-circle dr-plus-ico__active" />
              <i class="far fa-plus-circle dr-plus-ico__normal" />
            </span>
          </div>
          <div class="task-detail-infoitem__text">{{ title }}</div>
        </div>
      </template>
    </ElPopover>
    <div class="task-participants-list">
      <div>
        <div v-for="groupInfo in participantGroups" :key="groupInfo.group_id">
          <ParticipantGroupRow
            :value="groupInfo"
            @remove="removeParticipantGroup"
          />
        </div>
      </div>
      <div>
        <div v-for="userInfo in participantUsers" :key="userInfo.user_id">
          <ParticipantUserRow
            :value="userInfo"
            :show-remind-button="
              Boolean(task.id && type !== ParticipantsType.Follower)
            "
            :task="task"
            @remove="removeParticipantUser"
            @remind="$emit('remind', userInfo.user_id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { cloneDeep } from "lodash-es";
import { keyBy } from "lodash-es";
import { defineComponent } from "vue";

import { capitalize } from "@drVue/common";
import ParticipantGroupRow from "./ParticipantGroupRow.vue";
import ParticipantUserRow from "./ParticipantUserRow.vue";
import TaskParticipantsSelect from "./TaskParticipantsSelect.vue";

import type { SelectedParticipants } from "./TaskParticipantsSelect.vue";
import type {
  ParticipantGroup,
  ParticipantUserV1,
  Task,
} from "@drVue/store/pinia/room/tasks";
import type { PropType } from "vue";

interface SelectedState {
  [key: number]: boolean;
}

export enum ParticipantsType {
  Assignee = "assignee",
  Follower = "follower",
  Reviewer = "reviewer",
}

interface Data {
  ParticipantsType: any;
  isSelectPopoverVisible: boolean;
}

export default defineComponent({
  name: "TaskParticipantsList",
  components: {
    TaskParticipantsSelect,
    ParticipantGroupRow,
    ParticipantUserRow,
  },
  props: {
    type: { required: true, type: String as PropType<ParticipantsType> },
    task: { required: true, type: Object as PropType<Task> },
  },
  emits: ["change", "remind"],
  data(): Data {
    return {
      ParticipantsType: ParticipantsType,
      isSelectPopoverVisible: false,
    };
  },
  computed: {
    title(): any {
      return capitalize(this.type) + "s";
    },
    participantGroups(): ParticipantGroup[] {
      const attr = `${this.type}_groups` as keyof Task;
      return this.task[attr] as unknown as ParticipantGroup[];
    },
    participantUsers(): ParticipantUserV1[] {
      const attr = `${this.type}s` as keyof Task;
      return this.task[attr] as unknown as ParticipantUserV1[];
    },
    selectedValues(): SelectedParticipants {
      return {
        groups: this.participantGroups.map((v) => v.group_id),
        users: this.participantUsers.map((v) => v.user_id),
      };
    },
  },
  methods: {
    updatePopperPosition() {
      // updates popper position (it's needed because dimensions of the popper changes on tab switch)
      (this.$refs.selectPopover as any).popperRef.$parent.updatePopper();
    },
    updateSelectedValue(value: SelectedParticipants) {
      this.isSelectPopoverVisible = false;
      const currentGroups = keyBy(this.participantGroups, "group_id");
      const groups: ParticipantGroup[] = value.groups.map((groupId) => {
        return currentGroups[groupId] || { group_id: groupId };
      });

      const currentUsers = keyBy(this.participantUsers, "user_id");
      const users: ParticipantUserV1[] = value.users.map((userId) => {
        return currentUsers[userId] || { user_id: userId };
      });
      this.$emit("change", { groups, users });
    },
    removeParticipantGroup(group: ParticipantGroup) {
      const selected = cloneDeep(this.selectedValues);
      selected.groups = selected.groups.filter((gId) => gId !== group.group_id);
      this.updateSelectedValue(selected);
    },
    removeParticipantUser(user: ParticipantUserV1) {
      const selected = cloneDeep(this.selectedValues);
      selected.users = selected.users.filter(
        (userId) => userId !== user.user_id,
      );
      this.updateSelectedValue(selected);
    },
  },
});
</script>
