<template>
  <DrLayoutContentCentered>
    <OrgName v-if="sectionsVisibility.organization" />
    <Phases v-if="sectionsVisibility.phases" />
    <TaskStatusesWrapper v-if="sectionsVisibility.taskStatuses" />
    <TasksAdjustDefaultView v-if="sectionsVisibility.tasksAdjustViews" />

    <DrLayoutContentCenteredSection
      v-if="sectionsVisibility.listsAsTabs && enableListsTabs"
      title="Request lists as tabs"
    >
      <template #description>
        Move the request lists you have on the left hand side to the top menu
        bar as an additional new.
      </template>

      <ElButton plain type="primary" @click="toggleTabsFeature">
        {{ enableListsTabs ? "Disable" : "Enable" }} Lists as tabs feature
      </ElButton>
    </DrLayoutContentCenteredSection>

    <FindingTypesWrapper v-if="sectionsVisibility.findingTypes" />

    <FindingStatusesWrapper v-if="sectionsVisibility.findingStatuses" />

    <DrLayoutContentCenteredSection
      v-if="sectionsVisibility.loginColor"
      title="Login page color"
    >
      <template #description>
        Customize the login and registration screen background color for all
        users.
      </template>

      <DrColorPicker
        v-if="!isFormSubmitting"
        :color="bgColor"
        :is-submitting="isFormSubmitting"
        @change="updateBgColor"
      />
    </DrLayoutContentCenteredSection>

    <TimelineFields v-if="sectionsVisibility.timelineFields" />

    <AiToggle v-if="sectionsVisibility.aiToggle" />

    <StorageUsage v-if="isAdmin && sectionsVisibility.storage" />

    <SyncLookerModel v-if="showSyncLooker && sectionsVisibility.syncLooker" />
  </DrLayoutContentCentered>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import DrColorPicker from "@shared/ui/dr-color-picker";
import { useFormHelper } from "@shared/ui/dr-form";
import {
  DrLayoutContentCentered,
  DrLayoutContentCenteredSection,
} from "@shared/ui/dr-layouts";

import { ORG_MEMBER_DATA } from "@setups/data";
import { $notifyDanger } from "@drVue/common";
import { isLookerAccessible } from "@drVue/components/client-dashboard/looker/utils";
import {
  getPipelineSettingsAccess,
  PIPELINE_SETTINGS_ACCESS_TYPES,
} from "@drVue/components/client-dashboard/utils";
import DrStore from "@drVue/store";
import AiToggle from "./AiToggle.vue";
import FindingStatusesWrapper from "./FindingStatusesWrapper.vue";
import FindingTypesWrapper from "./FindingTypesWrapper.vue";
import OrgName from "./OrgName.vue";
import Phases from "./Phases";
import StorageUsage from "./StorageUsage.vue";
import SyncLookerModel from "./SyncLookerModel.vue";
import TasksAdjustDefaultView from "./TasksAdjustDefaultView.vue";
import TaskStatusesWrapper from "./TaskStatusesWrapper.vue";
import TimelineFields from "./TimelineFields.vue";

const settingsAccess = getPipelineSettingsAccess(DrStore.state);

const sectionsVisibility: Record<string, boolean> = {
  organization: !!settingsAccess,
  phases: !!settingsAccess,
  taskStatuses:
    settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.PipelineOnly ||
    settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.ExecutionSuit,
  listsAsTabs: settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.ExecutionSuit,
  tasksAdjustViews:
    settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.ExecutionSuit,
  findingTypes:
    settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.ExecutionSuit &&
    ORG_MEMBER_DATA.client.enable_findings,
  findingStatuses:
    settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.ExecutionSuit &&
    ORG_MEMBER_DATA.client.enable_findings,
  loginColor: !!settingsAccess,
  timelineFields:
    settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.PipelineOnly ||
    settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.ExecutionSuit,
  storage: !!settingsAccess,
  syncLooker:
    settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.PipelineOnly ||
    settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.ExecutionSuit,
  aiToggle:
    settingsAccess === PIPELINE_SETTINGS_ACCESS_TYPES.ExecutionSuit &&
    ORG_MEMBER_DATA.client.enable_ai,
};

const bgColor = computed(() => DrStore.state.clientDashboard.settings.bg_color);
const enableListsTabs = computed(
  () => DrStore.state.clientDashboard.settings.enable_lists_tabs,
);
const isAdmin = computed(() => ORG_MEMBER_DATA.group.is_administrator);
const showSyncLooker = computed(() => isLookerAccessible());

const { hookFormSubmitPromise, isFormSubmitting } = useFormHelper<{
  color: string | null;
}>();

const updateClientSettings = (payload: Record<string, unknown>) => {
  return hookFormSubmitPromise(
    DrStore.dispatch("clientDashboard/settings/update", payload),
  ).catch(() => {
    $notifyDanger("Failed to update client settings");
  });
};

const updateBgColor = (newColor: string | null) => {
  updateClientSettings({ bg_color: newColor }).then(() =>
    window.location.reload(),
  );
};
const toggleTabsFeature = () => {
  updateClientSettings({ enable_lists_tabs: !enableListsTabs.value });
};
</script>
