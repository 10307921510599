import { onBeforeUnmount, onMounted, ref } from "vue";

export const useVisibility = () => {
  const visibility = ref(false);

  onMounted(() => {
    visibility.value = true;
  });

  onBeforeUnmount(() => {
    visibility.value = false;
  });

  return visibility;
};
