<template>
  <div>
    <Draggable
      v-model="fieldsList"
      item-key="id"
      filter=".client-settings-panel-no-drag"
      :prevent-on-filter="false"
    >
      <template #item="{ element: field }">
        <FieldRow
          :field="field"
          is-draggable
          @updated="emit('updated', $event)"
        />
      </template>
    </Draggable>

    <div v-if="isAddFormShown" class="client-settings-panel">
      <!-- @vue-ignore -->
      <FieldEditForm
        :field="{ extra: {}, object_type: objectType }"
        @close="isAddFormShown = false"
        @added="emit('added', $event)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, provide, ref } from "vue";
import Draggable from "vuedraggable";

import DrStore from "@drVue/store";
import FieldEditForm from "./EditForm";
import FieldRow from "./Row";

import type { FieldItem, Order } from "@drVue/api-service/client-dashboard";

interface SortOrder {
  id: number;
  order: number;
}

interface Props {
  objectType: FieldItem["object_type"];
}

interface Emits {
  (e: "deleted", key: string): void;
  (e: "added", field: FieldItem): void;
  (e: "updated", field: FieldItem): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const isAddFormShown = ref(false);

const fieldsList = computed({
  get: function (): FieldItem[] {
    return DrStore.getters["clientDashboard/customFields/byObjectType"](
      props.objectType,
    );
  },
  set: function (value: FieldItem[]) {
    const data = setOrderByIndex(value);
    DrStore.dispatch("clientDashboard/customFields/reorder", {
      data: data,
    });
  },
});

const setOrderByIndex = (items: FieldItem[]): SortOrder[] => {
  return items.map(({ id }: Order, index: number) => {
    return { id, order: index + 1 };
  });
};

const showAddForm = () => {
  isAddFormShown.value = true;
};

provide("deletedCallback", (key: string) => emit("deleted", key));
defineExpose({ showAddForm });
</script>
