<template>
  <span class="truncate" :class="$style.wrapper">
    <span class="truncate" :class="$style.dealTitle">
      {{ deal.title }}
    </span>
    <RoomLinkBtn
      v-if="showRoomLinkButton"
      :room="deal.room"
      class="dr-vxe-cell__content-row-hover-display"
    />
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import RoomLinkBtn from "@drVue/components/client-dashboard/deals/Common/RoomLinkBtn.vue";

import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";
import type { PropType } from "vue";

export default defineComponent({
  name: "TitleCell",
  components: { RoomLinkBtn },
  props: {
    deal: {
      required: true,
      type: Object as PropType<Deal>,
    },
  },
  computed: {
    showRoomLinkButton(): boolean {
      return !!(
        this.deal.is_room_member &&
        this.deal.room &&
        !this.deal.room.is_archived
      );
    },
  },
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/_colors" as colors;
@use "@app/styles/scss/typography.scss" as typo;
.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dealTitle {
  font-size: typo.$font-size-title;
  font-weight: typo.$font-weight-medium;
}
</style>
