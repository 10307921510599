<template>
  <div class="dash-rooms-board__custom-field">
    <div v-for="item in values" :key="item.key" :class="$style.fieldRow">
      <span v-if="isVisible" class="dash-rooms-board__custom-field-label">
        {{ item.label }}
      </span>
      <span
        v-if="isVisible"
        class="dash-rooms-board__custom-field-value"
        :title="item.value"
        :class="$style.coloredFieldOptionValue"
        :style="{
          ...(item.color && {
            backgroundColor: item.color,
            color: isDarkColor(hexToRgb(item.color)) ? 'white' : 'black',
          }),
        }"
      >
        {{ item.value }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useStore } from "vuex";

import { CustomViewObjectTypes } from "@setups/types";
import { formatFields } from "@drVue/store/modules/client-dashboard/fields/utils";
import { hexToRgb, isDarkColor } from "@drVue/utils/color";

import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";
import type { ExtraFieldFormatted } from "@drVue/store/modules/client-dashboard/fields/types";

interface Props {
  deal: Deal;
  isVisible: boolean;
}

const $store = useStore();
const props = defineProps<Props>();

const customFields = computed(() => {
  return $store.getters["clientDashboard/customFields/byObjectType"](
    CustomViewObjectTypes.Deal,
  );
});
const values = computed<ExtraFieldFormatted[]>(() => {
  return [
    ...formatFields(
      customFields.value.slice(0, 4),
      props.deal.custom_data,
      true,
    ),
  ];
});
</script>

<style module lang="scss">
.fieldRow {
  height: 24px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.coloredFieldOptionValue {
  border-radius: 6px;
  padding: 2px 6px !important;
  margin-left: 12px;
}
</style>
