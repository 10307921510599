import "./api.js";

import * as d3 from "d3";

angular
  .module("dealroom.analytics.service.data.loader", [
    "dealroom.task",
    "dealroom.documents",
    "dealroom.members",

    "dealroom.analytics.service.data.api",
    "dealroom.analytics.service.data.categories",
    "dealroom.analytics.service.data.dispatcher",
  ])

  .service(
    "ActivityAnalyticsServiceLoadedPromise",
    ActivityAnalyticsServiceLoadedPromise,
  )
  .service("ActivityRoomServiceLoadedPromise", ActivityRoomServiceLoadedPromise)
  .run(loadRoomActivity);

ActivityAnalyticsServiceLoadedPromise.$inject = [
  "$q",
  "ActivityDocumentsService",
  "ActivityGroupPermissionsVisibleService",
  "ActivityTasksService",
  "ActivityCategoriesService",
];
function ActivityAnalyticsServiceLoadedPromise(
  $q,
  ActivityDocumentsService,
  ActivityGroupPermissionsVisibleService,
  ActivityTasksService,
  ActivityCategoriesService,
) {
  const dataPromise = $q.all([
    ActivityDocumentsService.loadingPromise,
    ActivityGroupPermissionsVisibleService.loadingPromise,
    ActivityTasksService.loadingPromise,
    ActivityCategoriesService.loadingPromise,
  ]);
  return dataPromise;
}

ActivityRoomServiceLoadedPromise.$inject = [
  "$q",
  "AnalyticsApiService",
  "RoomConfig",
  "MembersService",
  "PermissionsService",
  "TasksService",
  "CategoriesService",
  "DocumentsService",
];
function ActivityRoomServiceLoadedPromise(
  $q,
  AnalyticsApiService,
  RoomConfig,
  MembersService,
  PermissionsService,
  TasksService,
  CategoriesService,
  DocumentsService,
) {
  const timeout = 200;
  const viewTasks = RoomConfig.userPermissions.viewTasks;
  // TODO: watch for services refresh
  const roomServicesPromises = [
    MembersService.updateMembersList(),
    PermissionsService.updateGroupsList(),
    DocumentsService.syncTree(),
  ];
  if (viewTasks) {
    roomServicesPromises.push(TasksService.loadTasks());
    roomServicesPromises.push(CategoriesService.loadCategories());
  }
  const timeoutPromise = $q((resolve, reject) => {
    $q.all(roomServicesPromises).then((r) => {
      // give services time to make all their dark magic
      window.setTimeout(() => {
        resolve(r);
      }, timeout);
    });
  });
  const promises = {
    room: timeoutPromise,
    documents: AnalyticsApiService.documentsPromise,
    folders: AnalyticsApiService.foldersPromise,
    tasks: AnalyticsApiService.tasksPromise,
    categories: AnalyticsApiService.categoriesPromise,
  };
  return $q.all(promises);
}

loadRoomActivity.$inject = [
  "$filter",
  "MembersService",
  "ActivityRoomServiceLoadedPromise",
  "AnalyticsApiService",
  "AnalyticsDataDispatcher",
];
function loadRoomActivity(
  $filter,
  MembersService,
  ActivityRoomServiceLoadedPromise,
  AnalyticsApiService,
  AnalyticsDataDispatcher,
) {
  const loadStart = performance.now();
  AnalyticsDataDispatcher.add("batchIsLoaded");

  // all services will be loaded only on analytics page
  // so - wait for them to be sure that we dont load
  // data before user opened analytics
  ActivityRoomServiceLoadedPromise.then(loadActivity);

  function loadActivity() {
    let actions;

    function handleBatch(response) {
      const { next, results } = response.data;
      actions = actions.concat(results);
      if (next) {
        AnalyticsApiService.getActivityActual({ nextCursor: next }).then(
          handleBatch,
        );
      } else {
        processActivity(actions);
      }
    }

    AnalyticsApiService.getActivityHistory().then((response) => {
      actions = response.data;
      let lastActionTimestamp = null;
      if (actions.length > 0)
        lastActionTimestamp = actions[actions.length - 1].dt;
      return AnalyticsApiService.getActivityActual({
        lastActionTimestamp: lastActionTimestamp,
      }).then(handleBatch);
    });
  }

  let tzConversions = 0;

  function processActivity(actions) {
    const processStart = performance.now();
    const processed = actions
      .filter(filterUselessActivity)
      .map(deserializeActivity);
    const processEnd = performance.now();
    AnalyticsDataDispatcher.call("batchIsLoaded", processed, true);
    const loadEnd = performance.now();
  }

  const userTimeZoneFilter = $filter("drUserTime");

  let dateKey;
  let dateKeyInTz;

  function fastUserTimezoneFilter(date) {
    // userTimeZoneFilter() is too expensive to be called on thousands of actions
    // so we reuse the same casted date for the same minute
    // and apply it to actions ordered by timestamp
    // the `minute` period is taken due to the fact that DST switching
    // won't be triggered during a minute;
    // in contrast, the `hour` period does not have such guarantees
    const newDateKey = d3.timeMinute(date);
    if (!dateKey || newDateKey.getTime() !== dateKey.getTime()) {
      dateKey = newDateKey;
      dateKeyInTz = d3.timeMillisecond(userTimeZoneFilter(dateKey));
      tzConversions++;
    }
    const resultDate = new Date(dateKeyInTz.valueOf());
    resultDate.setSeconds(date.getSeconds());
    resultDate.setMilliseconds(date.getMilliseconds());
    return resultDate;
  }

  function deserializeActivity(d) {
    /* Datetimes, timezones, etc */

    const dtInUserTz = fastUserTimezoneFilter(new Date(d.dt));

    d.dt = d3.timeMillisecond(dtInUserTz);
    d.date = d3.timeDay(dtInUserTz);
    /*
        some time ago some `d` items had `verb` and some - `action`;
        now they all have only `action`,
        but thats happened frontend use `verb` field;
      */
    d.verb = d.action;
    delete d.action;

    return d;
  }

  function filterUselessActivity(d) {
    const user = MembersService.members[d.user];
    if (user === undefined) return false;

    if (user.is_archived === true) return false;

    if (user.pgroup.is_archived === true) return false;

    return true;
  }
}
