<template>
  <div>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-html="notice" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { NotificationsHtmlHelper } from "@drVue/components/client-dashboard/notifications/Notification/html-helper";
import getIconClass from "../../../../../common/mimetype";

import type { PropType } from "vue";

interface Data {
  showSimilarClass: string;
  htmlHelper: any;
  boundToggleRead: any;
  boundToggleShow: any;
}

export default defineComponent({
  name: "Notification",
  props: {
    notice: { default: "", type: String as PropType<string | undefined> },
  },
  data(): Data {
    return {
      showSimilarClass: "notification-stacked-list__expand-btn",
      htmlHelper: new NotificationsHtmlHelper(),
      boundToggleRead: () => {},
      boundToggleShow: () => {},
    };
  },
  beforeMount() {
    this.boundToggleRead = this.toggleRead.bind(this);
    this.boundToggleShow = this.toggleShow.bind(this);
  },
  mounted() {
    this.$el
      .querySelectorAll("[notice-id]")
      .forEach((e: HTMLElement) =>
        e.addEventListener("click", this.boundToggleRead),
      );

    this.$el
      .querySelector(`.${this.showSimilarClass}`)
      ?.addEventListener("click", this.boundToggleShow);

    this.$el
      .querySelectorAll("[data-mimetype]")
      .forEach((icon: HTMLElement) => {
        const mimetype = icon.getAttribute("data-mimetype");
        const iconClass = getIconClass(mimetype);

        icon.classList.add("mimetypes");
        icon.classList.add(iconClass);
      });
  },
  beforeUnmount() {
    this.$el
      .querySelectorAll("[notice-id]")
      .forEach((e: HTMLElement) =>
        e.removeEventListener("click", this.boundToggleRead),
      );

    this.$el
      .querySelector(`.${this.showSimilarClass}`)
      ?.removeEventListener("click", this.boundToggleShow);
  },
  methods: {
    toggleShow() {
      this.htmlHelper.toggleWrapping(this.$el);
    },
    toggleRead(e: MouseEvent) {
      const notice = e.currentTarget as HTMLElement;
      const noticeId = notice.getAttribute("notice-id");

      const isViewed = this.htmlHelper.isNoticeViewed(notice);
      const action = isViewed ? "markAsUnread" : "markAsRead";

      this.$store
        .dispatch(`common/notifications/${action}`, { noticeId })
        .then(() => this.htmlHelper.toggleRead(notice));
    },
  },
});
</script>
