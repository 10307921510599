import { sortBy } from "lodash-es";

import { CustomViewObjectTypes } from "@setups/types";
import { getSortValue } from "@drVue/store/modules/client-dashboard/deals/utils";

import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";
import type { RootState } from "@drVue/store/state";
import type { Dictionary } from "@drVue/types";
import type { TableLayoutSort } from "@setups/types";
import type { Store } from "vuex";

type DealSortMethod = (deal: Deal) => any;

interface SortField {
  field: string;
  label: string;
  sortBy: DealSortMethod;
}

export const DEFAULT_BOARDS_ORDER: TableLayoutSort = {
  field: "title",
  order: "asc",
};

export class DealsBoardSortHelper {
  public sortFields: { field: string; label: string }[];
  private getSortValue: Dictionary<DealSortMethod>;

  constructor(private $store: Store<RootState>) {
    const supportedFields = [
      ...this.sortableBasicFields(),
      ...this.sortableCustomFields(),
    ];
    this.sortFields = [];
    this.getSortValue = {};
    for (const field of supportedFields) {
      this.sortFields.push({ field: field.field, label: field.label });
      this.getSortValue[field.field] = field.sortBy;
    }
  }

  public sortDeals(deals: Deal[], sort: TableLayoutSort) {
    const getValueMethod = this.getSortValue[sort.field];
    deals = sortBy(deals, getValueMethod);
    if (sort.order === "desc") {
      deals.reverse();
    }
    return deals;
  }

  private sortableBasicFields(): SortField[] {
    const phaseById =
      this.$store.getters["clientDashboard/phases/getPhaseById"];
    return [
      {
        field: "title",
        label: "Title",
        sortBy: (deal) => deal.title,
      },
      {
        field: "start_date",
        label: "Start Date",
        sortBy: (deal) => deal.start_date?.getTime(),
      },
    ];
  }

  private sortableCustomFields(): SortField[] {
    const customColumns = this.$store.getters[
      "clientDashboard/customFields/byObjectType"
    ](CustomViewObjectTypes.Deal);
    return customColumns.map((field: FieldItem): SortField => {
      return {
        field: field.key,
        label: field.label,
        sortBy: (deal) => getSortValue(field, deal.custom_data[field.key]),
      };
    });
  }
}
