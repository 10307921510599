DropdownSelectedLabelController.$inject = ["$element", "$timeout"];
import * as d3 from "d3";

const labelsCls = "analytics-select__selected-labels";
const template = `
  <div class="analytics-select__selected-labels-container "
       ng-class="::$ctrl.classes.container"
       >
    <div class="${labelsCls}">-</div>
  </div>
`;

angular
  .module("dealroom.analytics.dropdown.base.selected-label", [])
  .component("drAnalyticsDropdownSelectedLabel", {
    bindings: {
      dispatcher: "<",
      items: "<",
      multiselect: "<",
      classes: "<",
    },
    controller: DropdownSelectedLabelController,
    template: template,
  });

function DropdownSelectedLabelController($element, $timeout) {
  const rootElement = d3.select($element[0]).select("div");
  const $ctrl = this;
  let uniqSelect = true;
  let labels = [];

  $ctrl.$onInit = function () {
    $ctrl.dispatcher.on("handleSelect.selectedLabel", handleSelect);
    const names = $ctrl.items
      .map((i) => i.label)
      .concat($ctrl.multiselect ? ["All", "None"] : []);
    labels = rootElement.selectAll("." + labelsCls).data(names);
    labels = labels
      .enter()
      .append("div")
      .attr("class", labelsCls)
      .merge(labels)
      .classed(
        "analytics-select__selected-labels--multiselect",
        $ctrl.multiselect,
      )
      .text((name) => name);
    uniqSelect = $ctrl.items.length === 1 || !$ctrl.multiselect;
  };

  function handleSelect(selectedLabels) {
    const isAllChecked = selectedLabels.length === $ctrl.items.length;
    if (isAllChecked) selectedLabels = ["All"];
    else if (selectedLabels.length === 0) selectedLabels = ["None"];
    else selectedLabels = selectedLabels.slice(0, 2);

    labels.each(setLabelVisible);

    function setLabelVisible(name) {
      const label = d3.select(this);
      const idx = selectedLabels.indexOf(name);
      const visible = idx > -1;
      const isLast = idx === selectedLabels.length - 1;

      label.classed("analytics-select__selected-labels--visible", visible);
      label.classed(
        "analytics-select__selected-labels--visible--multiselect",
        visible && $ctrl.multiselect,
      );

      if (isLast) label.style("margin-right", "0px");

      label.text(visible && !isLast ? name + "," : name);
      const maxWidth = visible && uniqSelect ? "100%" : null;
      label.style("max-width", maxWidth);
    }
  }
}
