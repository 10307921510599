import Sortable from "sortablejs";
import { onBeforeUnmount, ref, unref, watchEffect } from "vue";

import { isCategory } from "@app/ng/tasks/services/helpers/getItemType";

import type { Ref } from "vue";
import type { TableReactData, VxeGridInstance } from "vxe-table";

const disableHover = (grid: VxeGridInstance) => {
  // setTimeout to pass the VXE. It's going to setHoverRow() right now.
  setTimeout(() => {
    const table = unref(grid.getRefMaps().refTable);
    table.clearHoverRow();

    // In VXE internals if Date.now() < lastScrollTime, then hover event is
    // being fired. We offset the lastScrollTime value to the future to disable
    // the hovering.
    table.reactData.lastScrollTime = Date.now() + 1000 * 60 * 60;
  });
};

const enableHover = (grid: VxeGridInstance) => {
  // VXE is going to setHoverRow() again, so we keep the hovering disabled for
  // a while (100 ms).
  setTimeout(() => {
    const table = unref(grid.getRefMaps().refTable);
    table.reactData.lastScrollTime = 0;
  }, 100);
};

export const useTasksReorder = (
  gridRef: Ref<VxeGridInstance | null>,
  onEnd: (
    taskToMoveId: number,
    prevItemId: number,
    isPrevCategory: boolean,
  ) => void,
) => {
  let sortable: Sortable | null;
  let sortableInitTimeoutId: number | undefined;
  const isActive = ref<Boolean>(true);

  const initSortableJS = (selector: string) => {
    destroySortableJS();

    const grid = unref(gridRef);
    if (!grid) {
      return;
    }

    const tableElement = grid.$el.querySelector(selector);
    sortable = Sortable.create(tableElement, {
      animation: 100,
      // We explicitly set forceFallback to true, as HTML5 D&D is not working
      // properly in our case with VXE table.
      forceFallback: true,

      // See <DragCell /> component for details.
      handle: ".drag-handle",

      removeCloneOnHide: true,
      onStart: (e) => {
        document.body.classList.add("grabbing");

        disableHover(grid);
      },
      onMove: (e) => {
        disableHover(grid);

        // We can't drag over the first Category in the table. Therefore, we
        // are putting .the-first class in getRowClassname and handle it here.
        //
        // We must return false if we want to prevent the drag over the row.
        return e.related.classList.contains("the-first") ? 1 : true;
      },
      onEnd: (e) => {
        document.body.classList.remove("grabbing");

        if (e.oldIndex === e.newIndex) {
          enableHover(grid);
          return;
        }

        const taskRowId = e.item.getAttribute("rowid");
        if (!taskRowId) {
          enableHover(grid);
          return;
        }

        const taskToMove = grid.getRowById(taskRowId);

        const prevItemEl = e.item.previousSibling! as HTMLElement;
        const prevItemRowId = prevItemEl.getAttribute("rowid");
        if (!prevItemRowId) return;
        const prevItem = grid.getRowById(prevItemRowId);

        // e.item is cloned <tr>...</tr> that was created by VXE. We don't
        // need it as we want to pass all the DOM manipulations to VXE.
        e.item.remove();

        enableHover(grid);

        onEnd(taskToMove.id, prevItem.id, isCategory(prevItem));
      },
    });
  };

  const destroySortableJS = () => {
    clearTimeout(sortableInitTimeoutId);

    if (sortable) {
      sortable.destroy();
      sortable = null;
    }
  };

  const activateSortable = () => {
    if (!gridRef.value) {
      // grid is not initialized
      return;
    }

    const table = unref(gridRef.value.getRefMaps().refTable);
    if (!table) {
      // data is not loaded
      return;
    }

    const reactData: TableReactData = table.reactData;
    if (reactData === null) return;

    const selector =
      reactData.overflowX && reactData.hasFixedColumn
        ? ".vxe-table--fixed-wrapper tbody"
        : ".vxe-table--main-wrapper tbody";

    clearTimeout(sortableInitTimeoutId);
    sortableInitTimeoutId = window.setTimeout(() => initSortableJS(selector));
  };

  watchEffect(() => {
    if (isActive.value) {
      activateSortable(); // NB. deps of activate method will also trigger watchEffect
    } else {
      destroySortableJS();
    }
  });

  onBeforeUnmount(destroySortableJS);

  const enable = () => {
    isActive.value = true;
  };

  const disable = () => {
    isActive.value = false;
  };

  return { enable, disable };
};
