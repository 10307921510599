<template>
  <EditFieldContainer>
    <ElSelect
      ref="selectRef"
      clearable
      :model-value="currentValue"
      popper-class="dr-popper--inline-control"
      @change="updateValue"
      @visible-change="submitIfClosedAndChanged"
    >
      <ElOption
        v-for="option in FindingLikelihoodList"
        :key="option.value"
        :value="option.value"
        :label="option.label"
      />
    </ElSelect>
  </EditFieldContainer>
</template>

<script setup lang="ts">
import { ElSelect } from "element-plus";
import { onMounted, ref } from "vue";

import { FindingLikelihoodList } from "@drVue/api-service/modules/findings/types";
import EditFieldContainer from "@drVue/components/client-dashboard/dynamic-form/Fields/Edit/EditFieldContainer.vue";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";

interface Props {
  editProps: EditFieldProps;
}
const props = defineProps<Props>();

/* eslint-disable vue/no-setup-props-destructure */
const initialValue = props.editProps.value;
let currentValue = props.editProps.value;
/* eslint-enable vue/no-setup-props-destructure */

// @change event is fired before @visible-change, so we can update the current
// selected value and then decide what to do next.
const updateValue = (value: string) => {
  props.editProps.veeField.onChange(value);
  currentValue = value;
};

const submitIfClosedAndChanged = (visible: boolean) => {
  const hasBeenClosed = !visible;

  if (hasBeenClosed) {
    const hasValueChanged = currentValue !== initialValue;

    if (hasValueChanged) props.editProps.submitField();
    else props.editProps.quitEditModeConfirm();
  }
};

const selectRef = ref<InstanceType<typeof ElSelect> | null>(null);
onMounted(() => {
  if (!selectRef.value) return;
  selectRef.value.states.hoveringIndex = 0;
  selectRef.value.toggleMenu();
});
</script>
