<template>
  <div :class="$style.wrapper">
    <div :class="$style.nav">
      <DrNav :items="items" @select="emit('select', $event)">
        <template #item-postfix="{ item, isActive }">
          <!-- @vue-skip -->
          <DrChip
            v-if="item.counter"
            big
            :text="item.counter"
            :color="isActive ? 'blue' : 'gray'"
          />
        </template>
      </DrNav>

      <template v-if="$slots['all-views-form']">
        <div :class="$style.divider" />
        <ElPopover
          v-model:visible="isAllViewsPopupVisible"
          placement="bottom"
          trigger="click"
          width="auto"
        >
          <slot name="all-views-form" v-bind="{ close: closeAllViewsPopup }" />

          <template #reference>
            <button
              :class="{
                [$style.viewsBtn]: true,
                [$style.viewsBtn_isActive]: isAllViewsPopupVisible,
              }"
            >
              <span>All views</span>
              <DrIcon name="caret-down" size="sm" :class="$style.icon" />
            </button>
          </template>
        </ElPopover>
      </template>
    </div>

    <template v-if="$slots['save-view-form']">
      <ElPopover
        v-model:visible="isSaveViewPopupVisible"
        placement="bottom"
        trigger="click"
        width="auto"
      >
        <slot name="save-view-form" v-bind="{ close: closeSaveViewPopup }" />

        <template #reference>
          <ElButton icon-right :class="$style.saveBtn">
            Save view
            <template #icon>
              <DrIcon name="save" size="sm" />
            </template>
          </ElButton>
        </template>
      </ElPopover>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import DrChip from "@shared/ui/dr-chip";
import DrIcon from "@shared/ui/dr-icon";
import { DrNav } from "@shared/ui/dr-nav";

import type { ViewbarItem } from "./types";

interface Props {
  items: ViewbarItem[];
}

withDefaults(defineProps<Props>(), {
  items: () => [],
});

const emit = defineEmits<{
  (event: "select", id: ViewbarItem["id"]): void;
}>();

const isAllViewsPopupVisible = ref(false);
const isSaveViewPopupVisible = ref(false);

const closeAllViewsPopup = () => {
  isAllViewsPopupVisible.value = false;
};
const closeSaveViewPopup = () => {
  isSaveViewPopupVisible.value = false;
};

defineExpose({
  closeAllViewsPopup,
  closeSaveViewPopup,
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography" as typo;
@use "@app/styles/scss/spacing";

$viewbar-height: 48px;
$space: 8px;
$space-lg: 12px;

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: $viewbar-height;
  box-shadow: 0 -1px 0 0 colors.$pr-150 inset;
  padding: 0 spacing.$m;
}

.nav {
  height: 100%;
  display: flex;
  justify-content: flex-start;
}

.divider {
  display: flex;
  align-items: center;
  padding: 0 20px;

  &:before {
    content: "";
    display: inline-block;
    height: 16px;
    border-left: solid 1px colors.$pr-150;
  }
}

.viewsBtn {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 6px;
  width: auto;
  height: 100%;
  padding: 0 2px;
  border: none;
  border-radius: 0;
  font: typo.$body_regular;
  white-space: nowrap;
  background-color: transparent;
  color: colors.$pr-500;
  transition: color ease 150ms;
  cursor: pointer;

  .icon {
    color: colors.$pr-400;
  }

  &:focus,
  &:hover,
  &:hover .icon {
    color: colors.$pr-800;
  }
}

.viewsBtn_isActive {
  &,
  &:focus,
  &:hover,
  .icon {
    color: colors.$pr-900;
  }
}

.saveBtn {
  justify-self: end;
}
</style>
