<template>
  <span
    :class="$style.container"
    :style="{
      backgroundImage: logoUrl ? `url(${logoUrl})` : '',
      backgroundColor: logoUrl ? '' : bgColor,
    }"
  >
    <img
      v-if="!logoUrl"
      :class="$style.image"
      :src="placeholderSvgUrl"
      :alt="`${title} Logo`"
    />
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getColorByString } from "@shared/ui/utils";

import placeholderSvgUrl from "./placeholder.svg";

import type { PropType } from "vue";

export default defineComponent({
  name: "RoomLogo",
  props: {
    logoUrl: {
      required: false,
      default: null,
      type: String as PropType<string>,
    },
    title: {
      required: true,
      type: String as PropType<string>,
    },
  },
  computed: {
    bgColor() {
      return getColorByString(this.title);
    },
    placeholderSvgUrl() {
      return placeholderSvgUrl;
    },
  },
});
</script>
<style lang="scss" module>
.container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  overflow: hidden;
  height: 24px;
  width: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 3px;
  color: #fff;
}

.image {
  width: 100%;
  margin: 4px;
  fill: currentColor;
}
</style>
