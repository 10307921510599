import type { RouteLocationNormalized } from "vue-router";

export function getRedirect(
  $route: RouteLocationNormalized,
  fallbackValue: string,
) {
  const query = $route.query;
  const hash = $route.hash;

  // A normal case. One query-parameter and it is a string or null.
  let redirect = query.redirect || fallbackValue;

  // Edge case. We SHOULD NOT be here!
  // ?redirect=<url>&redirect=<url>&redirect=<url>
  if (Array.isArray(redirect)) {
    // If there are several redirect query params we use the first one that is truthy.
    redirect = redirect.find((r) => !!r) || fallbackValue;
  }

  // Remove http:// or https:// from the URL.
  redirect = redirect.replace(`${location.protocol}//`, "");

  // Attach the hash value.
  redirect = `${redirect}${hash}`;

  return redirect;
}
