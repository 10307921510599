<template>
  <div>
    <div class="dr-editor__menubar" :class="{ 'is-focused': editor.isFocused }">
      <ElDropdown>
        <button type="button" class="dr-editor__menu-button el-dropdown-link">
          <i class="fal fa-font-case" />
        </button>
        <template #dropdown>
          <ElDropdownMenu>
            <ElDropdownItem v-for="btn in TextSizeMenuActions" :key="btn.key">
              <MenuButtonComponent :btn="btn" :show-title="true" />
            </ElDropdownItem>
          </ElDropdownMenu>
        </template>
      </ElDropdown>

      <MenuButtonComponent
        v-for="btn in TextMenuButtons"
        :key="btn.key"
        :btn="btn"
      />
      <ElDropdown trigger="click" placement="bottom-start" size="small">
        <button type="button" class="dr-editor__menu-button el-dropdown-link">
          <i class="fal fa-ellipsis-h" />
        </button>
        <template #dropdown>
          <ElDropdownMenu>
            <ElDropdownItem v-for="btn in ExtraTextMenuActions" :key="btn.key">
              <MenuButtonComponent :btn="btn" :show-title="true" />
            </ElDropdownItem>
          </ElDropdownMenu>
        </template>
      </ElDropdown>

      <MenuSpacer />
      <MenuButtonComponent
        v-for="btn in ListsMenuButtons"
        :key="btn.key"
        :btn="btn"
      />

      <MenuSpacer v-if="mentionMenuButtons.length" />
      <MenuButtonComponent
        v-for="btn in mentionMenuButtons"
        :key="btn.key"
        :btn="btn"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MenuButtonComponent from "@shared/ui/editor/menu/DrEditorMenuButton.vue";
import MenuSpacer from "@shared/ui/editor/menu/DrEditorMenuSpacer.vue";

import type { MenuButton } from "./types";
import type { MenuButtonMethods } from "./types";
import type { Editor, UnionCommands } from "@tiptap/vue-3";
import type { PropType } from "vue";

interface EditorOptions {
  placeholder: string;
  showMenuOnFocus: boolean;
}

const defaultOptions: EditorOptions = {
  placeholder: "",
  showMenuOnFocus: false,
};

export interface Data {
  TextSizeMenuActions: MenuButton[];
  TextMenuButtons: MenuButton[];
  ExtraTextMenuActions: MenuButton[];
  ListsMenuButtons: MenuButton[];
}

export default defineComponent({
  name: "DrEditorMenu",
  components: {
    MenuButtonComponent,
    MenuSpacer,
  },
  props: {
    editor: { required: true, type: Object as PropType<Editor> },
    mentionMenuButtons: {
      default: () => [],
      type: Array as PropType<MenuButton[]>,
    },
  },
  data(): Data {
    return {
      TextSizeMenuActions: [
        {
          key: "text-normal",
          title: "Normal",
          icon: "fal fa-paragraph",
          methods: this.getMenuMethods("paragraph", {}, "setParagraph"),
        },
        {
          key: "text-h1",
          title: "Heading 1",
          icon: "fal fa-h1",
          methods: this.getMenuMethods(
            "heading",
            { level: 1 },
            "toggleHeading",
          ),
        },
        {
          key: "text-h2",
          title: "Heading 2",
          icon: "fal fa-h2",
          methods: this.getMenuMethods(
            "heading",
            { level: 2 },
            "toggleHeading",
          ),
        },
        {
          key: "text-h3",
          title: "Heading 3",
          icon: "fal fa-h3",
          methods: this.getMenuMethods(
            "heading",
            { level: 3 },
            "toggleHeading",
          ),
        },
      ],
      TextMenuButtons: [
        {
          key: "text-bold",
          title: "Bold",
          icon: "fal fa-bold",
          methods: this.getMenuMethods("bold", {}, "toggleBold"),
        },
        {
          key: "text-italic",
          title: "Italic",
          icon: "fal fa-italic",
          methods: this.getMenuMethods("italic", {}, "toggleItalic"),
        },
      ],
      ExtraTextMenuActions: [
        {
          key: "text-strike",
          title: "Strikethrough",
          icon: "fal fa-strikethrough",
          methods: this.getMenuMethods("strike", {}, "toggleStrike"),
        },
        {
          key: "text-underline",
          title: "Underline",
          icon: "fal fa-underline",
          methods: this.getMenuMethods("underline", {}, "toggleUnderline"),
        },
        {
          key: "code-block",
          title: "Block",
          icon: "fal fa-code",
          methods: this.getMenuMethods("codeBlock", {}, "toggleCodeBlock"),
        },
      ],
      ListsMenuButtons: [
        {
          key: "list-ul",
          title: "Bullet list",
          icon: "fal fa-list-ul",
          methods: this.getMenuMethods("bulletList", {}, "toggleBulletList"),
        },
        {
          key: "list-ol",
          title: "Numbered list",
          icon: "fal fa-list-ol",
          methods: this.getMenuMethods("orderedList", {}, "toggleOrderedList"),
        },
      ],
    };
  },
  methods: {
    getMenuMethods(
      type: string,
      attrs: object,
      command: keyof UnionCommands,
    ): MenuButtonMethods {
      const thany = this as any;

      return {
        isActive: () => this.editor.isActive(type, attrs),
        toggle: () => {
          return thany.editor.chain().focus()[command](attrs).run();
        },
      };
    },
  },
});
</script>
