import { isEqual } from "lodash-es";
import { isPlainObject } from "lodash-es";
import { transform } from "lodash-es";

/**
 * Deep diff between two object, using lodash
 * @param  object Object compared
 * @param  base   Object to compare with
 * @return        Return a new object who represent the diff
 */
const diff = (
  object: Record<string, unknown>,
  base: Record<string, unknown>,
) => {
  function changes(
    object: Record<string, unknown>,
    base: Record<string, unknown>,
  ) {
    return transform(
      object,
      function (result: Record<string, unknown>, value: unknown, key: string) {
        if (!isEqual(value, base[key])) {
          result[key] =
            isPlainObject(value) && isPlainObject(base[key])
              ? changes(
                  value as Record<string, unknown>,
                  base[key] as Record<string, unknown>,
                )
              : value;
        }
      },
    );
  }
  return changes(object, base);
};

/**
 * Find first value by key deeply in object (next match will be ignored)
 * @param source  target object or array of objects
 * @param key     name of the node we looking for
 * @returns
 */
const findValueByKey = (
  source: Record<string, unknown> | Array<unknown>,
  key: string,
): any => {
  let result: any = undefined;

  if (Array.isArray(source)) {
    for (const item of source) {
      if (item instanceof Object) {
        // objects and arrays, except null
        result = findValueByKey(
          item as Record<string, unknown> | Array<unknown>,
          key,
        );

        if (result !== undefined) {
          break;
        }
      }
    }
  } else {
    if (key in source) {
      result = source[key];
    } else {
      for (const prop in source) {
        if (source[prop] instanceof Object) {
          result = findValueByKey(
            source[prop] as Record<string, unknown> | Array<unknown>,
            key,
          );

          if (result !== undefined) {
            break;
          }
        }
      }
    }
  }

  return result;
};

/** Utilities for processing objects */
export default {
  diff,
  findValueByKey,
};
