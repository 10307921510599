<template>
  <ItemEdit
    :item="field"
    :item-type="itemType"
    :label-field="labelField"
    :action-path="actionPath"
    :rules="rules"
    @close="$emit('close')"
    @added="$emit('added', $event)"
    @updated="$emit('updated', $event)"
  >
    <template #default="slotProps">
      <ElFormItem
        label="Field Label"
        prop="label"
        :error="slotProps.getErrors('label')"
        required
      >
        <ElInput
          v-model="slotProps.form.label"
          placeholder="Field Label"
          autofocus
          @change="slotProps.resetErrors('label')"
        />
      </ElFormItem>

      <ElFormItem
        v-if="showTypeEdit"
        label="Field Type"
        prop="field_type"
        :error="slotProps.getErrors('field_type')"
        required
      >
        <ElSelect
          v-model="slotProps.form.field_type"
          placeholder="Field Type"
          autofocus
          class="el-select--full-width"
          @change="slotProps.resetErrors('field_type')"
        >
          <ElOption
            v-for="(value, key) in fieldTypes"
            :key="value"
            :label="key"
            :value="value"
          />
        </ElSelect>
      </ElFormItem>

      <EditOption
        v-if="
          slotProps.form.field_type === FieldItemTypes.Select ||
          slotProps.form.field_type === FieldItemTypes.MultiSelect
        "
        :field-type="slotProps.form.field_type"
        :extra="slotProps.form.extra"
      />

      <EditNumber
        v-if="slotProps.form.field_type === FieldItemTypes.Number"
        :extra="slotProps.form.extra"
        :error-symbol="slotProps.getErrors('extra.symbol')"
        @update:extra="
          slotProps.updateItem({
            ...slotProps.form,
            extra: $event,
          });
          slotProps.resetErrors('extra');
        "
      />

      <FormAlert :text="slotProps.getErrors('non_field_errors')" />
    </template>
  </ItemEdit>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { FormAlert } from "@shared/ui";

import { FieldItemTypes } from "@drVue/api-service/client-dashboard";
import ItemEdit from "../../../BaseItemRow/EditForm";
import EditNumber from "../EditNumber/EditNumber.vue";
import EditOption from "../EditOptions";

import type { FieldItem } from "@drVue/api-service/client-dashboard";

interface Props {
  field: FieldItem;
}

interface Emits {
  (e: "close"): void;
  (e: "added", field: FieldItem): void;
  (e: "updated", field: FieldItem): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const itemType = "field";
const labelField = "label";
const actionPath = "clientDashboard/customFields/";

const rules = {
  label: [{ required: true, message: "Please enter label", trigger: "blur" }],
};

const fieldTypes = {
  Text: FieldItemTypes.Text,
  Number: FieldItemTypes.Number,
  Date: FieldItemTypes.Date,
  Select: FieldItemTypes.Select,
  "Multi Select": FieldItemTypes.MultiSelect,
};

const showTypeEdit = computed(() => !props.field.id);
</script>
