import { CustomViewObjectTypes } from "@setups/types";

import type {
  Document,
  Folder,
} from "@app/vue/store/modules/room/documents/DocumentsApiService";
import type { RootState } from "@drVue/store/state";
import type { CustomViewColumn } from "@setups/types";
import type { Store } from "vuex";

function updateColumns(columns: CustomViewColumn[], update: CustomViewColumn) {
  const col = columns.find((c) => c.field === update.field);
  if (col) {
    const i = columns.indexOf(col);
    columns.splice(i, 1);
  }
  columns.push({
    ...(col || {}),
    ...update,
  });
}

function handleUpdates(
  columns: CustomViewColumn[],
  updates: CustomViewColumn | CustomViewColumn[],
) {
  if (Array.isArray(updates)) {
    updates.forEach((u) => updateColumns(columns, u));
  } else {
    updateColumns(columns, updates);
  }

  return columns;
}

export function saveColumnsConfig(
  $store: Store<RootState>,
  updates: CustomViewColumn | CustomViewColumn[],
) {
  const view = $store.getters["common/customViews/defaultView"](
    CustomViewObjectTypes.DataRoom,
  );

  handleUpdates(view.settings.columns, updates);
  const method = view.is_default ? "create" : "update";
  $store.dispatch(`common/customViews/${method}`, view);
}

export type FolderMenuParams = { folders: Folder[]; documents: Document[] };

export const getFolderMenuEntityTitle = (params: FolderMenuParams) => {
  if (params.folders.length && params.documents.length === 0) {
    return `folder${params.folders.length === 1 ? "" : "s"}`;
  }

  if (params.documents.length && params.folders.length === 0) {
    return `document${params.documents.length === 1 ? "" : "s"}`;
  }

  return "folders and documents";
};

export const getFolderMenuSelectedText = (params: FolderMenuParams) => {
  if (params.folders.length && params.documents.length === 0) {
    return params.folders.length === 1
      ? `folder ${params.folders[0].name}`
      : "selected folders";
  }

  if (params.documents.length && params.folders.length === 0) {
    return params.documents.length === 1
      ? `document ${params.documents[0].name}`
      : "selected documents";
  }

  return "selected folders and documents";
};
