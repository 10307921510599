<template>
  <div class="mfa-interface">
    <div
      class="mfa-interface__icon"
      :class="{
        'mfa-interface__icon--enabled': interface.is_enrolled,
        'mfa-interface__icon--disabled': !interface.is_enrolled,
      }"
    >
      <i class="fa fa-list-alt" />
    </div>

    <div>
      <div class="mfa-interface__header">{{ interface.name }}</div>
      <p class="mfa-interface__description">{{ interface.description }}</p>

      <ElButton
        v-if="interface.is_enrolled && !isPending"
        type="primary"
        @click="requestPassword('view')"
      >
        View codes
      </ElButton>

      <ElButton
        v-if="interface.is_enrolled && !isPending"
        type="primary"
        @click="requestPassword('regenerate')"
      >
        Regenerate codes
      </ElButton>

      <div v-if="interface.is_enrolled && isPending">
        <ElForm>
          <ElFormItem label="Current password">
            <ElInput
              v-model="password"
              type="password"
              autocomplete="current-password"
            />
          </ElFormItem>
        </ElForm>
        <ElButton @click="doAction">Confirm</ElButton>
      </div>
    </div>

    <ElDialog
      ref="dialog"
      v-model="isViewCodesDialogVisible"
      title="Unused recovery codes"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="30%"
      @closed="resolvePromise"
    >
      <p>
        Make sure to keep a copy of these codes to recover your account if you
        lose your authenticator.
      </p>
      <pre><span v-for="c of codes" :key="c">{{ c }}<br/></span></pre>
      <template #footer>
        <span class="dialog-footer">
          <ElButton type="primary" @click="isViewCodesDialogVisible = false"
            >Close</ElButton
          >
        </span>
      </template>
    </ElDialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  Interface,
  MfaApiService,
} from "@drVue/components/client-dashboard/users/AccountSettings/MfaApiService";

import type { MfaInterface } from "@drVue/components/client-dashboard/users/AccountSettings/MfaApiService";
import type { PropType } from "vue";

type Action = "view" | "regenerate";

interface Data {
  api: MfaApiService;
  action: Action;
  isPending: boolean;
  password: string;
  codes: string[];
  isViewCodesDialogVisible: boolean;
  resolvePromise: () => void;
}

export default defineComponent({
  name: "RecoveryCodes",
  props: {
    interface: { required: true, type: Object as PropType<MfaInterface> },
  },
  data(): Data {
    return {
      api: new MfaApiService(),
      action: "view",
      isPending: false,
      password: "",
      codes: [],
      isViewCodesDialogVisible: false,
      resolvePromise: () => {},
    };
  },
  methods: {
    requestPassword(action: Action) {
      this.action = action;
      this.isPending = true;
    },
    doAction() {
      switch (this.action) {
        case "view":
          this.viewCodes();
          break;
        case "regenerate":
          this.regenerateCodes();
          break;
      }
    },
    viewCodes() {
      return new Promise<void>((resolve, reject) => {
        this.api
          .getInterfaceDetails(Interface.recovery, this.password)
          .then((r) => {
            this.resolvePromise = resolve;

            this.codes = r.data.codes;
            this.isViewCodesDialogVisible = true;
            this.isPending = false;
          }, reject);
      });
    },
    regenerateCodes() {
      return new Promise<void>((resolve, reject) => {
        this.api
          .updateInterface(Interface.recovery, this.password)
          .then((r) => {
            this.resolvePromise = resolve;

            this.codes = r.data.codes;
            this.isViewCodesDialogVisible = true;
            this.isPending = false;
          }, reject);
      });
    },
    showCodes(codes: string[]) {
      return new Promise<void>((resolve, reject) => {
        this.resolvePromise = resolve;

        this.codes = codes;
        this.isViewCodesDialogVisible = true;
        this.isPending = false;
      });
    },
  },
});
</script>
