<template>
  <MgmtUsersTable v-loading="isLoading" :users="users">
    <template #empty>
      <span v-if="!searchText && !lastPromise">Please type a search query</span>
      <span v-if="searchText && lastPromise">Performing search...</span>
      <span v-if="searchText && !lastPromise">No users found</span>
    </template>
  </MgmtUsersTable>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useDebounceFn } from "@vueuse/core";

import { ManagementApiService } from "@drVue/api-service/management";
import { $notifyDanger } from "@drVue/common";
import MgmtUsersTable from "@drVue/components/management/UsersTable/MgmtUsersTable.vue";

import type { UserRow } from "@drVue/components/management/UsersTable/types";

const props = defineProps<{
  searchText: string;
  extraColumns?: string[];
}>();

const isLoading = ref<boolean>(false);
const lastPromise = ref<Promise<UserRow[]> | null>(null);

const api = new ManagementApiService();
const users = ref<UserRow[]>([]);

const debouncedSearchFn = useDebounceFn(() => {
  if (props.searchText) {
    isLoading.value = true;
    const promise = (lastPromise.value = api.searchUser(props.searchText));
    promise
      .then((data) => {
        if (lastPromise.value === promise) {
          users.value = data!;
        }
      })
      .catch(() => {
        $notifyDanger("Error while fetching users");
      })
      .finally(() => {
        if (lastPromise.value === promise) {
          isLoading.value = false;
        }
        lastPromise.value = null;
      });
  } else {
    users.value = [];
  }
}, 400);

watch(() => props.searchText, debouncedSearchFn);
</script>
