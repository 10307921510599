import { DealsNotesModule } from "@drVue/store/modules/client-dashboard/notes";
import { dealsActions } from "./DealsActions";
import { dealsGetters } from "./DealsGetters";
import { dealsMutations } from "./DealsMutations";
import { DealsState } from "./DealsState";

import type { RootState } from "@drVue/store/state";
import type { Module, ModuleTree } from "vuex";

export { DealsState };

const modules: ModuleTree<RootState> = {
  notes: DealsNotesModule,
};

export const DealsModule: Module<DealsState, RootState> = {
  namespaced: true,

  actions: dealsActions,
  getters: dealsGetters,
  modules,
  mutations: dealsMutations,

  state: new DealsState(),
};
