<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M7.92208 1L8.12245 1.02597L8.23377 1.15584L8.41187 1.57143L8.76809 2.53247L9.07978 3.36364L9.45826 4.37662L9.63636 4.79221L9.79221 5.1039L9.99258 5.38961L10.282 5.72727L10.6605 6.03896L11.0167 6.24675L11.9295 6.71429L12.5974 7.05195L13.3098 7.41558L13.8442 7.7013L13.9555 7.80519L14 7.93506L13.9777 8.11688L13.8664 8.27273L13.6883 8.37662L12.7755 8.84416L11.0835 9.7013L10.7273 9.90909L10.4156 10.1429L10.1039 10.4545L10.0816 10.5065H10.0371L9.99258 10.6104L9.79221 10.8961L9.65863 11.1299L9.41373 11.7273L9.1243 12.5065L8.72356 13.5714L8.43414 14.3506L8.23377 14.8442L8.10019 14.974L7.96661 15L7.83302 14.9221L7.74397 14.7922L7.47681 14.1169L6.74212 12.1169L6.49722 11.4675L6.29685 11.026L6.07421 10.6883L5.87384 10.4286L5.53989 10.1169L5.11688 9.83117L4.3154 9.41558L3.4026 8.94805L2.51206 8.48052L2.15584 8.2987L2.02226 8.16883L2 7.90909L2.06679 7.77922L2.17811 7.67532L4.04824 6.71429L4.87199 6.2987L5.25046 6.09091L5.58442 5.83117L5.87384 5.54545L6.141 5.20779L6.36364 4.79221L6.54174 4.37662L6.98701 3.18182L7.2987 2.35065L7.67718 1.33766L7.76623 1.12987L7.87755 1.02597L7.92208 1Z"
      :fill="active ? `url(#${gradientId})` : 'currentColor'"
    />
    <defs>
      <linearGradient
        :id="gradientId"
        x1="2"
        y1="8"
        x2="13.4868"
        y2="7.88979"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2B49E8" />
        <stop offset="1" stop-color="#795AD2" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script setup lang="ts">
defineProps<{
  active?: boolean;
}>();

const gradientId = "paint0_linear_22146_37939";
</script>
