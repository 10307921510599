import { defineStore } from "pinia";

import { generateList } from "@drVue/store/pinia/helpers/generators";
import { OrganizationGroupsApiService } from "./api";

import type {
  OrganizationGroup,
  OrganizationGroupCreatePayload,
  OrganizationGroupUpdatePayload,
} from "./api";

const api = new OrganizationGroupsApiService();

export const useOrganizationGroups = defineStore(
  "pipelineOrganizationGroups",
  () => {
    const { list, dict, load, create, update, remove, isLoading, isLoadError } =
      generateList<
        OrganizationGroup,
        OrganizationGroupCreatePayload,
        OrganizationGroupUpdatePayload
      >({
        name: "Pipeline organization groups",
        load: api.load.bind(api),
        create: api.create.bind(api),
        update: api.update.bind(api),
        remove: api.remove.bind(api),
      });

    return {
      list,
      dict,
      isLoading,
      isLoadError,
      load,
      create,
      update,
      remove,
    };
  },
);
