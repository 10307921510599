<template>
  <ElButton
    :type="row.date_revoked ? 'default' : 'danger'"
    size="small"
    title="Remove"
    :disabled="!!row.date_revoked"
    @click="revoke()"
  >
    Revoke
  </ElButton>
</template>

<script setup lang="ts">
import { ElMessageBox } from "element-plus";

import { useRevokeCellBus } from "./useRevokeCellBus";

import type { ApiToken } from "@app/vue/store/modules/client-dashboard/intergrations/TokensApiService";

interface Props {
  row: ApiToken;
}

const props = defineProps<Props>();

const removeCellBus = useRevokeCellBus();

const revoke = () => {
  ElMessageBox.confirm(
    "Are you sure you want to revoke the token?",
    "Revoke token",
    {
      customClass: "el-message-box--warning",
      confirmButtonText: "Revoke",
    },
  ).then(() => {
    removeCellBus.emit(undefined, props.row.id);
  });
};
</script>
