<template>
  <DrToolbarButtonCustomize
    :columns="tableColumns.columns"
    @update="updateConfig"
  />
</template>

<script setup lang="ts">
import { DrStore } from "@app/vue";
import DrToolbarButtonCustomize from "@app/vue/shared/ui/dr-toolbar/DrToolbarButtonCustomize.vue";
import TableColumns from "./tableColumns";
import { saveColumnsConfig } from "./utils";

import type { CustomViewColumn } from "@setups/types";

const tableColumns = new TableColumns(DrStore, ["_bookmark", "_menu", "name"]);

const updateConfig = (updates: CustomViewColumn[]) => {
  saveColumnsConfig(DrStore, updates);
};
</script>
