<template>
  <div class="notifications-list__wrapper">
    <div v-for="item in notifications" :key="item.date">
      <div class="notifications-list__date">
        {{ getFormattedDate(item.date) }}
      </div>
      <!-- notice is a pure html string -->
      <NotificationComponent
        v-for="notice in item.notifications"
        :key="notice"
        :notice="notice"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { drUserTime } from "@drVue/filters/drUserTime";
import NotificationComponent from "./Notification/Notification.vue";

import type { Notification } from "@drVue/store/modules/common/notifications/api";
import type { PropType } from "vue";

export default defineComponent({
  name: "NotificationsList",
  components: {
    NotificationComponent,
  },
  props: {
    notifications: { required: true, type: Array as PropType<Notification[]> },
  },
  methods: {
    getFormattedDate(date: string) {
      return drUserTime(date, "MMM dd, yyyy");
    },
  },
});
</script>
