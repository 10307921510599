<template>
  <DrVxeGrid
    outlined
    :data="tableData"
    :columns="tableColumns"
    :loading="isLoading"
  />
</template>

<script lang="ts" setup>
import { h, onBeforeMount, ref } from "vue";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import { ManagementApiService } from "@drVue/api-service/management";
import { $notifyDanger } from "@drVue/common";

import type { SubscriptionOverUsageInfo } from "@drVue/api-service/management";
import type { DrVxeTableColumn } from "@drVue/components/types";
import type { Ref } from "vue";

interface TableData extends SubscriptionOverUsageInfo {
  over_usage: number;
}
const api = new ManagementApiService();
const isLoading = ref(false);
const tableData: Ref<TableData[]> = ref([]);
onBeforeMount(() => loadData());

const tableColumns: DrVxeTableColumn<SubscriptionOverUsageInfo>[] = [
  {
    field: "client_name",
    title: "Client",
    width: 200,
    sortable: true,
    slots: {
      default: ({ row }) => {
        return [
          h(
            "a",
            { href: `#/clients/${row.client_id}`, target: "_blank" },
            row.client_name,
          ),
        ];
      },
    },
  },
  {
    field: "subscription_status",
    title: "Subscription Status",
    width: 170,
    sortable: true,
    slots: {
      default: ({ row }) => {
        return [
          h(
            "a",
            {
              href: `https://dashboard.stripe.com/subscriptions/${row.subscription_id}`,
              target: "_blank",
            },
            row.subscription_status,
          ),
        ];
      },
    },
  },
  {
    field: "billing_type",
    title: "Billing Type",
    width: 170,
    sortable: true,
  },
  {
    field: "current_usage",
    title: "Current Usage",
    width: 170,
    sortable: true,
  },
  {
    field: "included_usage",
    title: "Included Usage",
    width: 170,
    sortable: true,
  },
  {
    field: "over_usage",
    title: "Over Usage",
    sortable: true,
  },
];

const loadData = () => {
  if (isLoading.value) {
    return;
  }
  isLoading.value = true;
  api
    .getSubsWithOverUsage()
    .then(
      (data) => {
        tableData.value = data.map((r) => {
          return {
            ...r,
            over_usage: r.current_usage - r.included_usage,
          };
        });
      },
      () => $notifyDanger("Failed to load over usage information."),
    )
    .finally(() => {
      isLoading.value = false;
    });
};
</script>
