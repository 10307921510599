<template>
  <DrLayoutContentCentered>
    <div :class="$style.tableWrapper">
      <DrVxeGrid outlined :data="tableData" :columns="tableColumns.columns">
        <template #empty>No deleted deals found</template>
      </DrVxeGrid>
    </div>
  </DrLayoutContentCentered>
</template>

<script setup lang="ts">
import { ElMessageBox } from "element-plus";
import { computed, h, ref } from "vue";
import { DrLayoutContentCentered } from "@shared/ui/dr-layouts";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import { $notifyDanger } from "@drVue/common";
import DrStore from "@drVue/store";
import { DealsApiService } from "@drVue/store/modules/client-dashboard/deals/DealsApiService";
import DeleteRestoreDealConfirmMessage from "./DeleteRestoreDealConfirmMessage.vue";
import TableColumns from "./tableColumns";

import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";
import type { ExtraFieldFormatted } from "@drVue/store/modules/client-dashboard/fields/types";
import type { Dictionary } from "@drVue/types";

const api = new DealsApiService();
const deals = ref<Deal[]>([]);

const tableColumns = computed<TableColumns>(
  () =>
    new TableColumns({
      onRestore,
      onDelete,
    }),
);

const tableData = computed(() => {
  const formatFields =
    DrStore.getters["clientDashboard/customFields/formattedFields"];

  return deals.value.map((deal) => {
    const columns: Dictionary<any> = {
      id: deal.id,
      title: deal.title,
      phase: deal.phase,
      custom_data: deal.custom_data,
      is_room_removed: deal.is_room_removed,
    };

    formatFields("deal", deal.custom_data).forEach(
      (field: ExtraFieldFormatted) => {
        columns[field.key] = field.value;
      },
    );

    return columns;
  });
});

const updateTrashDealsList = () =>
  api.getTrash().then((data: Deal[]) => (deals.value = data));

const onRestore = (deal: Deal) => {
  ElMessageBox.confirm(
    h(DeleteRestoreDealConfirmMessage, { type: "restore", deal: deal }),
    "Restore the deal",
    {
      type: "warning",
      confirmButtonText: "Restore",
      showCancelButton: true,
      showClose: true,
      cancelButtonText: "No",
      closeOnHashChange: false,
      closeOnPressEscape: false,
      beforeClose: (action, instance, done) => {
        if (action === "confirm") {
          instance.showCancelButton = false;
          instance.confirmButtonLoading = true;

          DrStore.dispatch("clientDashboard/deals/restoreDeal", deal.id).then(
            async () => {
              await updateTrashDealsList();
              done();
            },
            (err) => {
              instance.showCancelButton = true;
              instance.confirmButtonLoading = false;
              $notifyDanger(
                err instanceof Error
                  ? err.message
                  : "Failed to restore the deal.",
              );
            },
          );
        } else {
          done();
        }
      },
    },
  ).catch(() => {});
};

const onDelete = (deal: Deal) => {
  ElMessageBox.confirm(
    h(DeleteRestoreDealConfirmMessage, { type: "delete", deal: deal }),
    "Permanently delete the deal from pipeline",
    {
      type: "warning",
      confirmButtonText: "Delete",
      confirmButtonClass: "el-button--danger",
      showCancelButton: true,
      showClose: true,
      cancelButtonText: "No",
      closeOnHashChange: false,
      closeOnPressEscape: false,
      beforeClose: (action, instance, done) => {
        if (action === "confirm") {
          instance.showCancelButton = false;
          instance.confirmButtonLoading = true;

          api.permanentlyDeleteDeal(deal.id).then(
            async () => {
              await updateTrashDealsList();
              done();
            },
            (err) => {
              instance.showCancelButton = true;
              instance.confirmButtonLoading = false;
              $notifyDanger(
                err instanceof Error
                  ? err.message
                  : "Failed to permanently delete the deal.",
              );
            },
          );
        } else {
          done();
        }
      },
    },
  ).catch(() => {});
};

updateTrashDealsList();
</script>

<style lang="scss" module>
.tableWrapper {
  position: relative;
  height: calc(100vh - 96px);
}
</style>
