<template>
  <DrVxeGrid :data="types" :columns="tableColumns.columns" />
</template>

<script setup lang="ts">
import DrVxeGrid from "@shared/ui/dr-vxe-grid/DrVxeGrid.vue";

import {
  findingTypeTableEventNames,
  useFindingTypesTableEventBus,
} from "./eventBus";
import TableColumns from "./findingTypesTableColumns";

import type { FindingType } from "@drVue/api-service/modules/findings-types/types";

interface Props {
  types: FindingType[];
}
interface Emits {
  (event: "edit", id: string): void;
  (event: "delete", id: string): void;
}

defineProps<Props>();
const emit = defineEmits<Emits>();
const tableColumns = new TableColumns();

const eventBus = useFindingTypesTableEventBus();
eventBus.on((event, payload) => {
  if (event === findingTypeTableEventNames.edit) {
    emit("edit", payload!.id);
  } else if (event == findingTypeTableEventNames.delete) {
    emit("delete", payload!.id);
  }
});
</script>
