import { DOC_ITEM_TYPES } from "@drVue/store/modules/room/documents/DocumentsApiService";

import type {
  DocsItem,
  Document,
  Folder,
} from "@drVue/store/modules/room/documents/DocumentsApiService";
import type { Dictionary } from "@drVue/types";

export default function getMaxNestedPathsLengths(
  rootFolder: Folder,
  roomTitleLength: number,
) {
  const lengths: Dictionary<number> = {};

  function getItemLength(parentLength: number, item: DocsItem) {
    if (
      item.type === DOC_ITEM_TYPES.Folder &&
      (item as Folder).parent_id === null
    ) {
      // root folder
      return roomTitleLength;
    }
    // +2 for / in path and whitespace between treeIndex and name
    return 2 + parentLength + item.treeIndex.length + item.name.length;
  }

  function calculatePathLength(parentLength: number, folder: Folder) {
    const folderLength = getItemLength(parentLength, folder);
    folder.folders.forEach((child: any) =>
      calculatePathLength(folderLength, child),
    );
    // calc max doc name inside this folder
    let maxChildLength = folderLength;
    folder.files.forEach((doc: Document) => {
      maxChildLength = Math.max(
        maxChildLength,
        getItemLength(folderLength, doc),
      );
    });
    lengths[folder.id] = maxChildLength;
  }

  calculatePathLength(0, rootFolder);
  return lengths;
}
