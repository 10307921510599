import { isLocalStorageEnabled } from "@drVue/common";

function getTaskCommentInputKey(taskId: number) {
  return `dr_taskcomment_${taskId}`;
}

export const storeTaskCommentInput = (taskId: number, text: object | null) => {
  if (!isLocalStorageEnabled) {
    return;
  }
  const key = getTaskCommentInputKey(taskId);
  if (text) {
    localStorage.setItem(key, JSON.stringify(text));
  } else {
    localStorage.removeItem(key);
  }
};

export const loadTaskCommentInput = (taskId: number): object | null => {
  if (!isLocalStorageEnabled) {
    return null;
  }
  const key = getTaskCommentInputKey(taskId);
  const rawValue = localStorage.getItem(key);
  if (rawValue) {
    try {
      return JSON.parse(rawValue);
    } catch (e) {
      return null;
    }
  }
  return null;
};
