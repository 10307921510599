import * as d3 from "d3";

import { MIN_WIDTH } from "../analytics.progressbar/service.data";

(function () {
  "use strict";
  AnalyticsRequestsByListsOverallStats.$inject = [
    "$scope",
    "$element",
    "AnalyticsDataDispatcher",
    "ActivityVisibleCategoriesService",
    "ActivityCategoriesService",
    "ActivityTasksService",
  ];
  AnalyticsRequestsAllOverallStats.$inject = [
    "$scope",
    "$element",
    "AnalyticsDataDispatcher",
    "ActivityVisibleCategoriesService",
    "ActivityTasksService",
  ];
  RequestTimePeriodGroupActivity.$inject = [
    "$element",
    "$scope",
    "AnalyticsActivityDataService",
    "AnalyticsDataDispatcher",
    "PermissionsService",
    "ActivityVisibleCategoriesService",
    "ActivityGroupPermissionsVisibleService",
  ];
  RequestTimePeriodUsersActivity.$inject = [
    "$element",
    "$scope",
    "AnalyticsDataDispatcher",
    "ActivityGroupPermissionsVisibleService",
    "MembersService",
    "AnalyticsActivityDataService",
    "ActivityVisibleCategoriesService",
  ];

  const AnalyticsColorsScheme = {
    green: "#3ec175",
    grey: "#bbbdbf",
    darkGrey: "#767676",
    blue: "#0077db",
    orange: "#f2a620",
    red: "#c63a3c",
  };

  angular
    .module("dealroom.analytics.components", [
      "dealroom.analytics.service.data",
      "dealroom.analytics.service.filter",
      "dealroom.members",
      "dealroom.documents",
    ])

    // barcharts
    .component("drAnalyticsRequestTimePeriodUsersActivity", {
      templateUrl: "drAnalyticsRequestTimePeriodUsersActivity.tmpl.html",
      controller: RequestTimePeriodUsersActivity,
    })
    .component("drAnalyticsRequestTimePeriodGroupActivity", {
      templateUrl: "drAnalyticsRequestTimePeriodGroupActivity.tmpl.html",
      controller: RequestTimePeriodGroupActivity,
    })
    // progressbar
    .component("drAnalyticsRequestsAllOverallStats", {
      templateUrl: "drAnalyticsRequestsAllOverallStats.tmpl.html",
      controller: AnalyticsRequestsAllOverallStats,
      bindings: {
        mode: "<",
      },
    })
    .component("drAnalyticsRequestsByListsOverallStats", {
      templateUrl: "drAnalyticsRequestsByListsOverallStats.tmpl.html",
      controller: AnalyticsRequestsByListsOverallStats,
      bindings: {
        mode: "<",
      },
    });

  // barcharts

  function RequestTimePeriodGroupActivity(
    $element,
    $scope,
    AnalyticsActivityDataService,
    AnalyticsDataDispatcher,
    PermissionsService,
    ActivityVisibleCategoriesService,
    ActivityGroupPermissionsVisibleService,
  ) {
    var $ctrl = this;
    var rootElement = d3.select($element[0]);
    var barLegends = {
      height: 16,
      marginTop: 15,
    };
    var barChart = new BarsChartStat(
      rootElement.select("[bars-chart-parent]"),
      {
        height: 118 + 45 + barLegends.height + barLegends.marginTop,
        align: true,
        legend: barLegends,
        elementWidth: 12,
        groupInnerPadding: 3,
      },
    );

    mkStats();
    AnalyticsDataDispatcher.on("filtersChanged", mkStats, $element, $scope);
    function getFiltered() {
      return AnalyticsActivityDataService.filter("requests");
    }

    function keyFn(d) {
      const dataOverAll = ActivityVisibleCategoriesService.visibleStatsSummary;
      const pgroupId = dataOverAll.membersIds[d.user];
      return pgroupId;
    }
    function getTitle(pgroupId) {
      return PermissionsService.pgroups[pgroupId].name;
    }
    function mkStats() {
      if ($scope.$$destroyed) return;
      const dataOverAll = ActivityVisibleCategoriesService.visibleStatsSummary;
      const dataPeriod = getFiltered();
      const isReady =
        dataOverAll &&
        ActivityGroupPermissionsVisibleService.visiblePgroupIds.length > 0;
      const current = isReady ? dataPeriod : null;
      const allelements = isReady
        ? ActivityGroupPermissionsVisibleService.visiblePgroupIds
        : null;
      let stats = null;
      const data = nestDataForStats(current, keyFn, getTitle, allelements);
      if (data) {
        stats = {
          first: data.shift(),
          last:
            data.length > 0
              ? data.pop()
              : { activities: new UserDisplayedActivity(), title: "-" },
        };
      }
      setActivityStats(stats);
      setBarChart(stats);
    }

    function setActivityStats(stats) {
      rootElement.selectAll("[activity-stats-parent]").each(function () {
        var parent = d3.select(this);
        var type = parent.attr("activity-stats-parent");
        var data = [
          stats
            ? stats[type]
            : { activities: new UserDisplayedActivity(), title: "-" },
        ];

        ActivityGroupStats(parent, type, data);
      });
    }

    function setBarChart(stats) {
      // {activities: UserDisplayedActivity, title: str}
      function getLabel(labels) {
        return labels[1].replace(/(?:^|\s)\S/g, function (a) {
          return a.toUpperCase();
        });
      }
      var bars;
      if (!stats) {
        bars = new UserDisplayedActivity().stats.map(function (stat) {
          return {
            bars: d3.range(2).map(function () {
              return { value: 0, color: AnalyticsColorsScheme.grey };
            }),
            label: getLabel(stat.label),
          };
        });
        barChart.update(bars);
        return;
      }

      var colors = [AnalyticsColorsScheme.blue, AnalyticsColorsScheme.red];
      var pgroups = [stats.first, stats.last];
      bars = pgroups.reduce(function (bucket, group, i) {
        if (group.title) {
          var color = colors[i];
          group.activities.stats.forEach(function (activity, j) {
            var label = getLabel(activity.label);
            if (bucket[j] === undefined) {
              bucket[j] = { bars: [], label: label };
            }
            bucket[j].bars[i] = {
              tooltip: { body: label, title: group.title },
              value: activity.count,
              color: color,
            };
          });
        }
        return bucket;
      }, []);
      barChart.update(bars);
    }
  }

  function RequestTimePeriodUsersActivity(
    $element,
    $scope,
    AnalyticsDataDispatcher,
    ActivityGroupPermissionsVisibleService,
    MembersService,
    AnalyticsActivityDataService,
    ActivityVisibleCategoriesService,
  ) {
    var $ctrl = this;
    var rootElement = d3.select($element[0]);
    var parent = rootElement.select("[activity-stats-parent]");
    var displayItemsCount = 4;

    mkStats();
    AnalyticsDataDispatcher.on("filtersChanged", mkStats, $element, $scope);
    function getFiltered() {
      return AnalyticsActivityDataService.filter("requests");
    }

    function keyFn(d) {
      return d.user;
    }
    function getTitle(userId) {
      return MembersService.members[userId].name;
    }
    function mkStats() {
      if ($scope.$$destroyed) return;
      const dataOverAll = ActivityVisibleCategoriesService.visibleStatsSummary;
      const dataPeriod = getFiltered();
      const isReady =
        dataOverAll &&
        ActivityGroupPermissionsVisibleService.visibleUsersIds.length > 0;
      const current = isReady ? dataPeriod : null;
      const allelements = isReady
        ? ActivityGroupPermissionsVisibleService.visibleUsersIds
        : d3.range(displayItemsCount);
      const data = nestDataForStats(current, keyFn, getTitle, allelements);
      ActivityGroupStats(
        parent,
        "first",
        data.slice(0, displayItemsCount),
        true,
      );
    }
  }

  ////

  function BarsChartStat(rootElement, params) {
    var width = rootElement.node().offsetWidth;
    var height = params.height;
    var elementWidth = params.elementWidth;
    var ticksCount = 4;

    var cleanDuration = 1000;
    var updateDuration = 1000;
    var cleanTransition = d3
      .transition()
      .duration(cleanDuration)
      .ease(d3.easeQuadOut);
    var updateTransition = d3
      .transition()
      .delay(cleanDuration)
      .duration(updateDuration)
      .ease(d3.easeQuadIn);

    var svg = createSvg(rootElement, width, height, params);
    var yMin = svg.height;
    var minH = yMin - 2;
    var yScale = d3.scaleLinear().rangeRound([yMin, 0]);
    var getY = function (value) {
      if (value === 0) return minH;
      return Math.min(yScale(value), minH - 2);
    };
    var yAxisG = svg.main
      .append("g")
      .attr(
        "class",
        "analytics-axis analytics-axis--no-labels analytics-axis--y",
      );

    var xScale = d3.scaleBand().rangeRound([0, width]);
    var xAxisG = svg.main
      .append("g")
      .attr("class", "analytics-axis analytics-axis--x");

    var tooltips = [];
    this.update = update;
    update();

    function createYAxis() {
      var yMax = yScale.domain()[1];
      var tickStep = yMax / ticksCount;
      var tickValues = d3.range(0, yMax + tickStep, tickStep);
      var yAxis = d3
        .axisLeft(yScale)
        .tickSizeInner(-width)
        .tickSizeOuter(0)
        .tickValues(tickValues);

      yAxisG.selectAll("*").remove();
      yAxisG.call(yAxis);
    }

    function createXAxis(marginLeft) {
      if (!params.legend) {
        return;
      }
      var xAxis = d3
        .axisBottom(xScale)
        .tickSizeInner(0)
        .tickSizeOuter(params.legend.sizeOuter || 0);

      var step = xScale(xScale.domain()[0]);
      marginLeft =
        marginLeft -
        (xScale.bandwidth() - 1) / 2 +
        elementWidth +
        (params.groupInnerPadding || 0) / 2;
      var marginTop = svg.height + params.legend.marginTop;

      xAxisG.selectAll("*").remove();
      xAxisG
        .attr("transform", "translate(" + marginLeft + "," + marginTop + ")")
        .call(function (g) {
          g.call(xAxis);
          g.selectAll(".tick text").each(function (label) {
            var text = d3.select(this);
            var words = label.split(" ");
            text.text("");
            text
              .selectAll("tspan")
              .data(words)
              .enter()
              .append("tspan")
              .attr("x", 0)
              .attr("y", function (s, i) {
                return i * 17 - 3;
              })
              .text(function (word) {
                return word;
              });
          });
        });
    }

    function update(data) {
      // [{bars: [{tooltip: {tooltip}, value: value, color}, ..], label: ''}]
      clean();

      data = data || [];
      tooltips = [];

      var valueMax =
        d3.max(data, function (group) {
          return d3.max(group.bars, function (bar) {
            return bar.value;
          });
        }) || 1;
      yScale.domain([0, valueMax]);
      xScale.domain(
        data.map(function (d, i) {
          return d.label || i;
        }),
      );

      createYAxis();

      var parent = svg.main.append("g").attr("class", "parent-g");

      var groups = parent.selectAll("g").data(data).enter().append("g");

      var parentWidth = 0;
      var bars = groups
        .selectAll("rect")
        .data(function (d, i) {
          var x = xScale(d.label || i);
          var margin = (params.groupInnerPadding || 0) + elementWidth;
          return d.bars.map(function (bar, j) {
            bar.x = x + margin * j;
            bar.y = getY(bar.value);
            bar.height = yMin - bar.y + 1;
            // get the right x of the last bar
            parentWidth = bar.x + elementWidth;
            return bar;
          });
        })
        .enter()
        .append("rect")
        .attr("width", elementWidth)
        .attr("height", 0)
        .attr("y", svg.height)
        .attr("x", function (d, i) {
          return d.x;
        })
        .attr("fill", function (d) {
          return d.color;
        })
        .attr("opacity", 0)
        .each(function (d) {
          if (d.tooltip) {
            var tooltip = AnalyticsTooltip(d3.select(this), {
              color: d.color,
              value: d.value,
              content: d.tooltip,
              filterId: svg.dropshadowFilterId,
            });
            tooltips.push(tooltip);
          }
        })
        .transition(updateTransition)
        .attr("opacity", 1)
        .delay(50)
        .attr("height", function (d) {
          return d.height;
        })
        .attr("y", function (d, i) {
          return d.y;
        });
      var margin = params.align ? (width - parentWidth) / 2 : 0;
      parent.attr("transform", "translate(" + margin + ",0)");
      createXAxis(margin);
    }

    function clean() {
      if (svg) {
        svg
          .selectAll("rect")
          .interrupt()
          .transition(cleanTransition)
          .attr("height", 0)
          .attr("y", svg.height);

        svg
          .selectAll(".parent-g")
          .interrupt()
          .transition(cleanTransition)
          .delay(100)
          .attr("opacity", 0)
          .remove();
        // svg.selectAll('.analytics-axis')
        //   .interrupt()
        //   .transition(cleanTransition)
        //     .attr('opacity', 0)
        //   .remove();
      }
      while (tooltips.length > 1) {
        tooltips.pop().remove();
      }
    }
  }

  // progressbar

  function AnalyticsRequestsAllOverallStats(
    $scope,
    $element,
    AnalyticsDataDispatcher,
    ActivityVisibleCategoriesService,
    ActivityTasksService,
  ) {
    const rootElement$ = d3.select($element[0]);

    const params = {
      showPercent: true,
      alignLast: true,
      mode: "absolute",
    };
    const progressParent$ = rootElement$.select("[progress-parent]");
    const progressLayout = new ResolvedToTotalTasksLayout(
      progressParent$,
      params,
    );

    progressLayout.update([{ title: "" }]);
    AnalyticsDataDispatcher.on("filtersChanged", update, $element, $scope);

    function update() {
      const stats = ActivityVisibleCategoriesService.visibleStatsSummary;
      const category = {
        title: "",
        tasks: ActivityTasksService.taskIdsToTypes(stats.taskIds),
      };
      progressLayout.update([category]);
    }

    this.$onChanges = function (changes) {
      progressLayout.setParams({
        mode: changes.mode.currentValue,
        showPercent: true,
        alignLast: true,
      });

      update();
    };
  }

  function AnalyticsRequestsByListsOverallStats(
    $scope,
    $element,
    AnalyticsDataDispatcher,
    ActivityVisibleCategoriesService,
    ActivityCategoriesService,
    ActivityTasksService,
  ) {
    const root$ = d3.select($element[0]);
    root$.style("display", "none");

    const progress$ = root$.select("[progress-parent]");
    const params = { mode: "absolute" };
    const progressLayout = new ResolvedToTotalTasksLayout(progress$, params);
    AnalyticsDataDispatcher.on("filtersChanged", update, $element, $scope);

    function update() {
      const stats = ActivityVisibleCategoriesService.visibleStats;
      const categories = stats.map(_processStats);
      root$.style("display", categories.length > 0 ? "block" : "none");
      progressLayout.update(categories);

      function _processStats(stats) {
        return {
          title: ActivityCategoriesService.categories[stats.id].name,
          tasks: ActivityTasksService.taskIdsToTypes(stats.taskIds),
        };
      }
    }

    this.$onChanges = function (changes) {
      progressLayout.setParams({ mode: changes.mode.currentValue });
      update();
    };
  }

  ///////

  function ResolvedToTotalTasksLayout(rootElement$, params) {
    params = params || {};

    const classes = {
      row: "analytics-card__row",
      column: "analytics-card__column",
      columnMd: "analytics-card__column--md analytics-card__column--marged",
    };

    this.setParams = function (_params) {
      params = _params;
    };

    this.update = function update(categories) {
      const categoriesCount = categories ? categories.length : 0;
      const columnsCount = 2;
      const data = d3.range(0, categoriesCount, columnsCount).map(function (i) {
        const columns = d3.range(
          i,
          Math.min(i + columnsCount, categoriesCount),
        );

        return {
          columns: columns.map((i) => {
            const { title, tasks } = categories[i];
            let cls = classes.columnMd;
            if (params.alignLast && columns.length === 1) {
              cls = "";
            }
            return {
              class: cls,
              title: title,
              mode: params.mode,
              stats: mkStats(tasks),
            };
          }),
        };
      });

      const rows = rootElement$.selectAll("." + classes.row).data(data);
      rows.exit().remove();

      const updatedRows = rows
        .enter()
        .append("div")
        .attr("class", classes.row)
        .merge(rows);

      const columns = updatedRows
        .selectAll("." + classes.column)
        .data(function (row) {
          return row.columns;
        });

      columns.exit().remove();

      const updatedColumns = columns
        .enter()
        .append("div")
        .attr("class", classes.column + " analytics-card__column--padded")
        .each(function (d) {
          if (d.title) {
            d3.select(this)
              .append("h4")
              .attr("class", "analytics-card__subtitle");
          }

          const progressBar = d3
            .select(this)
            .append("div")
            .attr("progress-parent", "");

          d3.select(this).property(
            "bar",
            new ProgressBarStats(progressBar, params.mode),
          );
        })
        .merge(columns);

      updatedColumns.each(function (d) {
        d3.select(this).classed(d.class, true);
        d3.select(this).select("h4").text(d.title);

        const progressBar = d3.select(this).property("bar");
        progressBar.setMode(params.mode);
        progressBar.update(d.stats.title, d.stats.bars);
      });
    };

    function mkStats(tasks) {
      if (!tasks) return { title: null, bars: null };

      const percent =
        tasks.total === 0
          ? 0
          : Math.round((tasks.resolved / tasks.total) * 100);

      let title = `${tasks.resolved}/${tasks.total}`;
      if (params.showPercent) title = `${title} (${percent}%)`;

      const bars = [
        {
          tooltip: { body: "Resolved" },
          value: tasks.resolved,
          color: "green",
        },
        {
          tooltip: { body: "Reopened" },
          value: tasks.reopened,
          color: "violet",
        },
        {
          tooltip: { body: "In Progress" },
          value: tasks.inprogress,
          color: "orange",
        },
        {
          tooltip: { body: "Open" },
          value: tasks.open,
          color: "grey",
        },
      ];

      return { title: title, bars: bars };
    }
  }

  class ProgressBarStats {
    static cleanDuration = 1000;

    static updateDuration = 1000;

    static cleanTransition = d3
      .transition()
      .duration(ProgressBarStats.cleanDuration)
      .ease(d3.easeQuadOut);

    static updateTransition = d3
      .transition()
      .delay(ProgressBarStats.cleanDuration)
      .duration(ProgressBarStats.updateDuration)
      .ease(d3.easeQuadIn);

    _mode = ""; // "absolute" | "relative"

    root$ = null;

    title$ = null;

    progressBar$ = null;

    tooltips$ = null;

    constructor(root$, mode = "absolute") {
      this.root$ = root$;
      this.setMode(mode);
    }

    setMode(mode) {
      if (mode === "absolute" || mode === "relative") {
        this._mode = mode;
        return;
      }

      throw new Error(
        `Unsupported mode value [${mode}]. Must be either "absolute" or "relative".`,
      );
    }

    clean() {
      if (this.tooltips$) {
        this.tooltips$.forEach((tt) => tt.remove());
        this.tooltips$ = null;
      }

      if (this.title$) {
        this.title$.remove();
        this.title$ = null;
      }

      if (this.progressBar$) {
        this.progressBar$
          .selectAll(".analytics-stats-progress-bar__bar")
          .interrupt()
          .transition(ProgressBarStats.cleanTransition)
          .style("width", 0)
          .style("left", 0)
          .remove();
        this.progressBar$.remove();
        this.progressBar$ = null;
      }
    }

    update(titleText, data) {
      this.clean();

      data = data || [
        { value: 1, color: "darkGrey" },
        { value: 100, color: "grey", total: true },
      ];

      titleText = titleText || "N/A";

      this.tooltips$ = [];
      this.title$ = this.root$
        .append("div")
        .attr("class", "analytics-card__metrics")
        .text(titleText);
      this.progressBar$ = this.root$
        .append("div")
        .attr("class", "analytics-stats-progress-bar");

      const dataSorted = data.sort((a, b) => (a.total ? -1 : b.total ? 1 : 0));

      const hasTotal = dataSorted[0].total === true;
      const total = hasTotal
        ? dataSorted[0].value
        : d3.sum(data, (d) => d.value);

      const width = this.progressBar$.node().offsetWidth;
      const scale = d3
        .scaleLinear()
        .domain([0, total || data.length])
        .range([0, width]);

      const that = this;

      dataSorted.forEach((d, i) => {
        if (that._mode === "absolute") d.label = d.value;
        if (that._mode === "relative") {
          d.label =
            total === 0 ? "0%" : `${Math.round((d.value * 100) / total)}%`;
        }

        if (d.total) {
          d.zIndex = 1;
          d.left = 0;
          d.width = width;
        } else {
          d.zIndex = 2;

          if (total === 0) {
            d.width = scale(1);
          } else {
            d.width = scale(d.value);
          }

          if (hasTotal) {
            if (i === 1) {
              // When hasTotal is [true] we put the "total" bar to the bottom.
              // That is why we start counting the offsets from scratch.
              d.left = 0;
            } else {
              d.left = data.reduce(
                // idx > 0 to skip the "total" bar.
                // idx < i to skip the "current" bar.
                (acc, cur, idx) => (idx > 0 && idx < i ? acc + cur.width : acc),
                0,
              );
            }
          } else {
            if (i === 0) {
              // When hasTotal is [false] we put the "current" bar to the left
              // and start counting the offsets.
              d.left = 0;
            } else {
              d.left = d.left = data.reduce(
                // idx < i to skip the "current" bar.
                (acc, cur, idx) => (idx < i ? acc + cur.width : acc),
                0,
              );
            }
          }
        }
      });

      const div = this.progressBar$
        .selectAll("div")
        .data(dataSorted)
        .enter()
        .append("div");

      div
        // Do not append a label for bars that are too short.
        .filter((d) => d.width > MIN_WIDTH)
        .append("div")
        .classed("analytics-stats-progress-bar__label", true)
        .text((d) => {
          if (that._mode === "absolute") return d.value;
          if (that._mode === "relative") {
            return total === 0
              ? "0%"
              : `${Math.round((d.value * 100) / total)}%`;
          }
        });

      div
        .attr(
          "class",
          (d) =>
            "analytics-stats-progress-bar__bar " +
            `analytics-stats-progress-bar__bar--${d.color}`,
        )
        .style("width", 0)
        .style("left", 0)
        .each(function (d) {
          if (d.tooltip) {
            const tooltip$ = AnalyticsTooltip(d3.select(this), {
              color: d.color,
              value: d.label,
              content: d.tooltip,
            });

            that.tooltips$.push(tooltip$);
          }
        })
        .transition(ProgressBarStats.updateTransition)
        .style("width", (d) => d.width + "px")
        .style("left", (d) => d.left + "px");
    }
  }

  /// Other
  function AnalyticsTooltip(parent, params) {
    var timer;
    var timeout = params.timeout || 100;
    var margin = { left: 20, top: -30, right: 5 };
    var content = params.content;

    parent.classed("analytics-tooltip-parent", true);
    var showClass = params.svg
      ? "analytics-tooltip-parent--svg"
      : "analytics-tooltip-parent--show";

    var tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "analytics-tooltip-content");

    if (params.color) {
      var color = hexToRgba(params.color, 0.5);
      tooltip.style("box-shadow", "0 0 4px " + params.color);
    }

    if (content.title) {
      tooltip
        .append("div")
        .attr("class", "analytics-tooltip-content__title")
        .attr("style", "color: " + params.color + "!important")
        .text(content.title);
    }

    tooltip
      .append("div")
      .attr("class", "analytics-tooltip-content__subtitle")
      .text(content.body);

    tooltip
      .append("div")
      .attr("class", "analytics-tooltip-content__metrics")
      .text(params.value);

    function toggleTooltip(close) {
      tooltip.classed("analytics-tooltip-content--show", !close);
      if (params.filterId) {
        parent.style("filter", !close ? "url(#" + params.filterId + ")" : "");
      } else {
        parent.classed("analytics-tooltip-parent--show", !close);
      }
    }

    function setPos(event) {
      if (event) {
        var lx = event.clientX + margin.left;
        var width = tooltip.node().offsetWidth;
        if (lx + width + margin.right > window.innerWidth) {
          lx = event.clientX - margin.left - width;
        }
        tooltip
          .style("left", lx + "px")
          .style("top", event.clientY + margin.top + "px");
      }
    }

    parent
      .on("mousemove", function () {
        if (timer === undefined) timer = setInterval(toggleTooltip, timeout);
        setPos(d3.event);
        params.mousemove && params.mousemove();
      })
      .on("mouseleave", function () {
        if (timer) {
          clearInterval(timer);
          timer = undefined;
        }
        toggleTooltip(true);
        params.mouseleave && params.mouseleave();
      });

    return tooltip;
  }

  function ActivityGroupStats(rootElement, type, data, multicolumns) {
    rootElement.selectAll("*").remove();

    var titleCls = "analytics-time-period-group-stats__title--" + type;
    var statCls = "analytics-time-period-group-stats__stat--" + type;
    // [{title: str, stats: [{}, ..]}, ..]
    var sections = rootElement
      .selectAll(".analytics-time-period-group-stats")
      .data(data)
      .enter()
      .append("div")
      .attr("class", "analytics-time-period-group-stats");

    var titles = sections
      .append("div")
      .attr("class", "analytics-time-period-group-stats__title " + titleCls)
      .text(function (d) {
        return d.title || "N/A";
      });

    var rows = sections
      .selectAll(".analytics-card__row")
      .data(function (d) {
        var columnsCount = multicolumns ? 2 : 1;
        var l = d.activities.length;
        var step = multicolumns ? l / columnsCount : 1;
        var data = d3.range(0, l, columnsCount).map(function (i) {
          var columns = d3.range(i, i + step).map(function (idx) {
            var stat = d.activities.stats[idx];
            return {
              label: stat.count === 1 ? stat.label[0] : stat.label[1],
              value: d.title ? stat.count : "-",
            };
          });
          return { columns: columns };
        });
        return data;
      })
      .enter()
      .append("div")
      .attr(
        "class",
        "analytics-card__row analytics-time-period-group-stats__content",
      );

    var columns = rows
      .selectAll(".analytics-card__column")
      .data(function (d) {
        return d.columns;
      })
      .enter()
      .append("div")
      .attr(
        "class",
        "analytics-card__column analytics-time-period-group-stats__stat " +
          statCls,
      )
      .classed("analytics-card__column--md", multicolumns);
    columns.append("strong").text(function (d) {
      return d.value;
    });
    columns.append("span").text(function (d) {
      return " " + d.label;
    });
  }

  function hexToRgba(hex, alpha) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (!result) return null;
    var colors = [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16),
      alpha,
    ];

    return "rgba(" + colors.join(",") + ")";
  }

  function createSvg(rootElement, width, height, params) {
    const shadow = { top: 3, left: 3, dev: 3, op: 0.5 };
    const margin = { top: 0, bottom: 0, left: 10 };
    if (!params.align) {
      margin.bottom += shadow.top + 1;
      margin.left += shadow.left + 1;
    }
    if (params.legend) {
      margin.bottom += params.legend.height + params.legend.marginTop;
    }
    function createShadowFilter(defs) {
      var n = d3.selectAll("filter.dropshadow").size() + 1;
      var id = "dropshadow" + n;
      var filter = defs
        .append("filter")
        .attr("class", "dropshadow")
        .attr("id", id)
        .attr("x", "-40%")
        .attr("y", "-40%")
        .attr("width", "180%")
        .attr("height", "180%")
        .attr("filterUnits", "userSpaceOnUse");

      filter
        .append("feGaussianBlur")
        .attr("in", "SourceAlpha")
        .attr("stdDeviation", shadow.dev); // stdDeviation is how much to blur

      filter
        .append("feOffset")
        .attr("dx", shadow.left)
        .attr("dy", shadow.top)
        .attr("result", "offsetblur");

      filter
        .append("feOffset")
        .attr("dx", -shadow.left)
        .attr("dy", -shadow.top)
        .attr("result", "offsetblur");

      filter
        .append("feComponentTransfer")
        .html('<feFuncA type="linear" slope="' + shadow.op + '"/>');

      filter
        .append("feMerge")
        .html(
          [
            "<feMergeNode/>",
            '<feMergeNode in="SourceGraphic"/>',
            '<feMergeNode in="SourceGraphic"/>',
          ].join("\n"),
        );

      return id;
    }
    function createLinearGradient(defs, params) {
      var n = d3.selectAll("filter.lineargradient").size() + 1;
      var id = "lineargradient" + n;
      var linearGradient = defs
        .append("linearGradient")
        .attr("id", id)
        .attr("class", "lineargradient")
        .attr("x2", (params.horizontal ? 100 : 0) + "%")
        .attr("x1", 0 + "%")
        .attr("y2", 0 + "%")
        .attr("y1", (params.horizontal ? 0 : 100) + "%");
      var step = 100 / (params.colors.length - 1);
      params.colors.forEach(function (color, i) {
        linearGradient
          .append("stop")
          .attr("offset", i * step + "%")
          .style("stop-color", color)
          .style("stop-opacity", 1);
      });
      return id;
    }
    const svg = rootElement
      .append("svg")
      .attr("width", width + margin.left)
      .attr("overflow", "visible");
    svg.style("margin-left", -margin.left);
    const defs = svg.append("defs");
    svg.dropshadowFilterId = createShadowFilter(defs);
    if (params.lineargradient) {
      svg.lineargradientFilterId = createLinearGradient(
        defs,
        params.lineargradient,
      );
    }
    svg.main = svg.append("g");

    svg.update = ({ t, h }) => {
      margin.top = (t || params.marginTop || 0) + shadow.top + 1;
      svg.main.attr(
        "transform",
        "translate(" + margin.left + ", " + margin.top + ")",
      );

      if (angular.isDefined(h)) {
        svg.attr("height", h);
      }
      svg.height = +svg.attr("height") - margin.top - margin.bottom;
      svg.style("margin-top", -margin.top);
    };
    svg.update({ t: params.marginTop, h: Math.max(height, 100) });
    return svg;
  }

  function nestDataForStats(data, keyFn, getTitle, allelements) {
    if (!allelements) {
      return null;
    }
    var nested = [];
    if (data) {
      nested = d3
        .nest()
        .key(keyFn)
        .rollup(function (values) {
          return new UserDisplayedActivity(values);
        })
        .entries(data);
    }
    var mapped = d3.map(nested, function (d) {
      return d.key;
    });
    var padded = allelements.map(function (key) {
      return {
        title: data && getTitle(key),
        activities: mapped.has(key)
          ? mapped.get(key).value
          : new UserDisplayedActivity(),
      };
    });
    return padded.sort(function (a, b) {
      return d3.descending(a.activities.score, b.activities.score);
    });
  }

  function UserDisplayedActivity(values) {
    values = values || [];
    var templates = [
      {
        label: ["file uploaded", "files uploaded"],
        score: 2,
        count: 0,
        verbs: ["document_upload", "document_overwrite"],
      },
      {
        label: ["file downloaded", "files downloaded"],
        score: 2,
        count: 0,
        verbs: ["document_download", "document_bulk_download"],
      },
      {
        label: ["file viewed", "files viewed"],
        score: 1,
        count: 0,
        verbs: ["document_viewed"],
      },
      {
        label: ["comment made", "comments made"],
        score: 3,
        count: 0,
        verbs: ["task_comment_add"],
      },
    ];

    this.stats = values.reduce(function (stats, d) {
      var s = stats.find(function (s) {
        return s.verbs.indexOf(d.verb) > -1;
      });
      if (s) {
        s.count += 1;
      }
      return stats;
    }, templates);
    this.score = this.stats.reduce(function (score, s) {
      return s.count * s.score + score;
    }, 0);
    this.length = this.stats.length;
  }
})();
