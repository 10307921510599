<template>
  <DrVxeGrid outlined :columns="tableColumns.columns" :data="tableData">
    <template #empty>
      <slot name="empty" />
    </template>
  </DrVxeGrid>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import TableColumns from "./tableColumns";

import type { BasicRoomRow } from "./types";
import type { PaymentTypes } from "@setups/enums";
import type { PropType } from "vue";

export interface Filters {
  activeStatus: "" | "active" | "archived" | "deleted";
  paymentType: "" | PaymentTypes;
}

interface Data {
  tableColumns: any;
}

export default defineComponent({
  name: "RoomsTable",
  components: {
    DrVxeGrid,
  },
  props: {
    rooms: { type: Array as PropType<BasicRoomRow[]>, default: () => [] },
    filters: { type: Object as PropType<Filters>, required: true },
    searchText: { type: String as PropType<string>, default: "" },
    extraColumns: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  emits: ["row-click"],

  setup(props, context) {
    const tableColumns = computed(() => {
      return new TableColumns(props.extraColumns);
    });

    const tableData = computed(() => {
      let rooms: BasicRoomRow[] = props.rooms;
      // Filters rooms list
      if (props.filters.activeStatus || props.filters.paymentType) {
        rooms = rooms.filter(filterRooms);
      }

      // Search through rooms
      if (props.searchText) {
        rooms = searchInRooms(rooms, props.searchText);
      }

      return rooms;
    });

    function searchInRooms(rooms: BasicRoomRow[], searchText: string) {
      searchText = searchText.toLowerCase();

      return rooms.filter(
        (room) =>
          room.domain?.toLowerCase().includes(searchText) ||
          room.title.toLowerCase().includes(searchText),
      );
    }

    function filterRooms(room: BasicRoomRow) {
      if (
        props.filters.activeStatus === "active" &&
        (room.is_archived || room.is_removed)
      ) {
        return false;
      }
      if (
        props.filters.activeStatus === "archived" &&
        (room.is_removed || !room.is_archived)
      ) {
        return false;
      }
      if (props.filters.activeStatus === "deleted" && !room.is_removed) {
        return false;
      }

      if (
        props.filters.paymentType &&
        room.payment_type != props.filters.paymentType
      ) {
        return false;
      }
      return true;
    }

    return { tableColumns, tableData };
  },
});
</script>
