<template>
  <VeeForm
    ref="VeeFormRef"
    :validation-schema="validationSchema"
    :initial-values="initialValues"
    keep-values
    as=""
  >
    <DrFormWrapper
      :model="entity"
      grid-template-columns="repeat(12, 1fr)"
      :get-form-ref="getElFormRef"
      @submit="emit('submit')"
    >
      <template v-for="schemaField in schema" :key="schemaField.prop">
        <VeeField
          ref="veeFieldRef"
          v-slot="{
            errorMessage,
            handleChange,
            handleBlur,
            value: veeFieldValue,
          }"
          :name="schemaField.prop"
          :rules="schemaField.rules"
        >
          <slot
            :name="schemaField.prop"
            v-bind="{
              editProps: {
                schema: {
                  ...schemaField,
                  isReadOnly: disabled || schemaField.isReadOnly || false,
                },
                veeField: {
                  onInput: (val: unknown) => {
                    handleChange(val, !!errorMessage);
                    updateEntityValue(schemaField.prop, val);
                  },
                  onChange: (val: unknown) => {
                    handleChange(val);
                    updateEntityValue(schemaField.prop, val);
                  },
                  onBlur: handleBlur,
                },
                value: veeFieldValue,
              },
              error: get(errors, schemaField.prop) || errorMessage,
              dataTestId: schemaField.prop,
            }"
          >
            <ElFormItem
              :prop="schemaField.prop"
              :label="schemaField.label"
              :error="get(errors, schemaField.prop) || errorMessage"
              :required="schemaField.required"
              :data-testid="schemaField.prop"
              :style="{
                gridColumn: schemaField.gridColumn
                  ? schemaField.gridColumn
                  : '1 / -1',
              }"
            >
              <Component
                :is="
                  schemaField.editComponent ??
                  COMPONENT_BY_TYPE[schemaField.type]
                "
                :edit-props="{
                  schema: {
                    ...schemaField,
                    isReadOnly: disabled || schemaField.isReadOnly || false,
                  },
                  veeField: {
                    onInput: (value: unknown) => {
                      handleChange(value, !!errorMessage),
                        updateEntityValue(schemaField.prop, value);
                    },
                    onChange: (value: unknown) => {
                      handleChange(value),
                        updateEntityValue(schemaField.prop, value);
                    },
                    onBlur: handleBlur,
                  },
                  value: veeFieldValue,
                }"
              />
            </ElFormItem>
          </slot>
        </VeeField>
      </template>
    </DrFormWrapper>
  </VeeForm>
</template>

<script setup lang="ts">
import { cloneDeep } from "lodash-es";
import { get } from "lodash-es";
import { isEqual } from "lodash-es";
import { Field as VeeField, Form as VeeForm } from "vee-validate";
import { computed, ref, watch } from "vue";

import DrFormWrapper from "../dr-form/DrFormWrapper.vue";
import { COMPONENT_BY_TYPE } from "./fields/constants";

import type { FormSchema, RuleExpression, UpdatePayload } from "./types";
import type { Dictionary } from "@drVue/types";
import type { ElForm } from "element-plus";

interface Props {
  schema: FormSchema;
  entity: Record<string, any>;
  errors?: Record<string, string>;
  disabled?: boolean;
}

type ValidationSchema = Dictionary<RuleExpression<unknown>>;

const props = withDefaults(defineProps<Props>(), {
  errors: () => ({}),
  disabled: false,
});

const emit = defineEmits<{
  (event: "update", payload: UpdatePayload): void;
  (event: "submit"): void;
}>();

const initialValues = cloneDeep(props.entity);

const validationSchema = computed<ValidationSchema>(() => {
  return props.schema.reduce((rulesMap, field) => {
    if (field.rules) {
      rulesMap[field.prop] = field.rules;
    }
    return rulesMap;
  }, {} as ValidationSchema);
});

const VeeFormRef = ref<InstanceType<typeof VeeForm>>();
const resetForm = (values?: Record<string, any>) => {
  VeeFormRef.value?.resetForm(
    { values: values || initialValues },
    { force: true },
  );
};
const validateForm = () => VeeFormRef.value?.validate();

watch(
  () => props.schema,
  () => {
    // save form values on schema update
    resetForm(props.entity);
  },
);

const ElFormRef = ref<InstanceType<typeof ElForm>>();
const getElFormRef = (form: InstanceType<typeof ElForm>) =>
  (ElFormRef.value = form);

const updateEntityValue = async (field: string, value: any) => {
  // We prevent duplication of updating the field value by the "onChange" event,
  // if this value has already been set by the "onInput" event.
  const oldValue = get(props.entity, field);
  if (isEqual(oldValue, value)) return;

  emit("update", { field, value });
};

const validate = async (): Promise<boolean> => {
  const result = await validateForm();

  if (!result?.valid) {
    const firstFieldWithError = Object.keys(result?.errors || {})[0];
    if (firstFieldWithError) {
      ElFormRef.value?.scrollToField(firstFieldWithError);
    }
  }

  return result?.valid || false;
};

defineExpose({
  validate,
  reset: resetForm,
});
</script>
