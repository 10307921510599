<template>
  <div :class="$style.container">
    <DrIcon
      v-for="(findingTypeColor, findingTypeId) in findingTypesColors"
      :key="findingTypeId"
      :style="{ color: findingTypeColor }"
      name="exclamation-triangle"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import DrIcon from "@shared/ui/dr-icon";

import { pinia } from "@drVue/store/pinia";
import { useFindingsTypesStore } from "@drVue/store/pinia/pipeline/findings-types";

import type { FindingType } from "@drVue/store/pinia/pipeline/findings-types";
import type { Finding } from "@drVue/store/pinia/room/findings";

interface Props {
  findings: Finding[];
}

type FindingTypesRecord = Record<
  FindingType["id"],
  FindingType["color"] | undefined
>;

const props = defineProps<Props>();

const findingsTypeStore = useFindingsTypesStore(pinia);

const findingTypesColors = computed<FindingTypesRecord>(() => {
  return props.findings.reduce((acc, item) => {
    if (!acc[item.type_id]) {
      acc[item.type_id] = findingsTypeStore.dict[item.type_id]?.color;
    }

    return acc;
  }, {} as FindingTypesRecord);
});
</script>

<style module lang="scss">
@use "@app/styles/scss/spacing";

.container {
  display: inline-flex;
  align-items: center;
  gap: spacing.$xxs;
}
</style>
