angular
  .module("dealroom.analytics.service.export.pdf", ["dealroom.common"])
  .directive("drExportPdfSource", DrExportPdfSourceDirective)
  .service("AnalyticsExportPdfService", AnalyticsExportPdfService);

AnalyticsExportPdfService.$inject = ["postAndGetFile", "URLS"];
function AnalyticsExportPdfService(postAndGetFile, URLS) {
  let pdfSources;

  const service = {
    setSource: setSource,
    export: exportData,
  };

  return service;

  function setSource(elem) {
    pdfSources = elem;
  }

  function exportData() {
    if (!angular.isElement(pdfSources)) return;
    const clones = [];
    const styles = Array.prototype.slice.call(
      document.getElementsByTagName("style"),
    );
    styles.forEach((node) => {
      const clone = node.cloneNode(true);
      clones.push(clone);
      pdfSources.appendChild(clone);
    });
    const reportData = { source_html: pdfSources.outerHTML };
    clones.forEach((node) => $(node).remove());
    postAndGetFile(URLS["api:export_pdf"](), reportData);
  }
}

DrExportPdfSourceDirective.$inject = ["AnalyticsExportPdfService"];
function DrExportPdfSourceDirective(AnalyticsExportPdfService) {
  return {
    scope: {},
    link: function (scope, elem) {
      AnalyticsExportPdfService.setSource(elem[0]);
    },
  };
}
