<template>
  <div :class="$style.container">
    <slot name="default" />
  </div>
</template>

<style lang="scss" module>
.container {
  width: 100%;
}
</style>
