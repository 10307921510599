<template>
  <AiReportsExpansion
    :label="report.label"
    :init-is-open="isGenerating || hasGeneratedFields"
    has-border
  >
    <template #actions="{ buttonIconClass }">
      <DrButtonMore>
        <ElDropdownItem type="text" @click="$emit('edit-report')">
          Edit summary
        </ElDropdownItem>

        <ElDropdownItem
          v-if="hasGeneratedFields"
          type="text"
          @click="revokeReportWithConfirm"
        >
          Revoke summary from document
        </ElDropdownItem>
      </DrButtonMore>

      <ElButton
        v-if="showGenerateButton"
        :type="hasGeneratedFields ? 'default' : 'primary'"
        :loading="isLoadingGenerateReport || isGenerating"
        :disabled="isLoadingRevokeReport"
        @click.stop="generateReport"
      >
        <template v-if="isGenerating">Generating summary...</template>

        <template v-else-if="hasGeneratedFields">
          <span>Regenerate</span>
          <DrIcon name="rotate-right" size="sm" :class="buttonIconClass" />
        </template>

        <template v-else>Apply</template>
      </ElButton>
    </template>

    <template #default>
      <div>
        <div v-if="isEmptyReport" :class="$style.emptyStub">
          <i>This summary is currently empty.</i>
        </div>

        <AiReportRowField
          v-for="field in report.fields"
          :key="field.id"
          :doc-id="docId"
          :doc-uid="docUid"
          :report="report"
          :report-field="field"
          :report-doc="reportDoc"
          :report-doc-field="docFieldMap[field.id]"
          @update:report-doc-field="updateReportDocField"
        />
      </div>
    </template>
  </AiReportsExpansion>
</template>

<script lang="ts" setup>
import { ElMessageBox } from "element-plus";
import { computed, ref } from "vue";

import { AiReportsEvent, insightTrack } from "@app/insight";
import { $notifyDanger } from "@app/vue/common";
import DrButtonMore from "@app/vue/shared/ui/dr-button/DrButtonMore.vue";
import DrIcon from "@app/vue/shared/ui/dr-icon/DrIcon.vue";
import { AsyncOperationStatuses } from "@app/vue/store/modules/room/user-async-operation/AsyncOperationApiService";
import AiReportRowField from "./AiReportRowField.vue";
import AiReportsExpansion from "./AiReportsExpansion.vue";
import { AiReportsApiService } from "./api";

import type {
  AiDocReport,
  AiDocReportField,
  AiReport,
  AiReportField,
} from "./types";

interface Props {
  docId: number;
  docUid: string;
  report: AiReport;
  reportDoc?: AiDocReport;
}

interface Emit {
  (e: "reload-reports"): void;
  (e: "update:report-doc", value: AiDocReport): void;
  (e: "edit-report"): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emit>();

const api = new AiReportsApiService();
const isLoadingRevokeReport = ref(false);
const isLoadingGenerateReport = ref(false);

const docFieldMap = computed<Record<string, AiDocReportField>>(() => {
  if (!props.reportDoc) return {};

  return props.reportDoc.fields.reduce<Record<string, AiDocReportField>>(
    (result, item) => {
      result[item.report_type_template_field_id] = item;
      return result;
    },
    {},
  );
});

const isEmptyReport = computed(() => !props.report.fields.length);

const showGenerateButton = computed(() => {
  return !isEmptyReport.value && needsRegenerate.value;
});

const hasGeneratedFields = computed(() =>
  props.report.fields.some((field) => docFieldMap.value[field.id]),
);

const isGenerating = computed(() =>
  props.report.fields.some(
    (field) =>
      docFieldMap.value[field.id] &&
      (docFieldMap.value[field.id].status ===
        AsyncOperationStatuses.Processing ||
        docFieldMap.value[field.id].status === AsyncOperationStatuses.Pending),
  ),
);

const isFieldNeedRegenerate = (reportField: AiReportField) => {
  const docField = docFieldMap.value[reportField.id];
  if (!docField) return true; // Field is not generated yet
  if (docField.status === AsyncOperationStatuses.Fail) return true; // Field generation failed
  if (docField.prompt_template_hash !== reportField.prompt_template_hash) {
    return true; // Outdated prompt template
  }
  return false;
};

const needsRegenerate = computed(() =>
  props.report.fields.some(isFieldNeedRegenerate),
);

const revokeReport = () => {
  if (isLoadingRevokeReport.value || !props.reportDoc) return;

  isLoadingRevokeReport.value = true;

  api
    .revokeReport(props.docUid, props.reportDoc.id)
    .then(() => {
      insightTrack(AiReportsEvent.ReportRevoked);

      emit("reload-reports");
    })
    .catch(() => {
      $notifyDanger("Failed to revoke report");
    })
    .finally(() => {
      isLoadingRevokeReport.value = false;
    });
};

const revokeReportWithConfirm = () => {
  ElMessageBox({
    title: "Revoke summary",
    message: `Are you sure you want to revoke "${props.report.label}" from the document?`,
    confirmButtonText: "Revoke",
    customClass: "el-message-box--warning",
    showCancelButton: true,
    icon: undefined,
    callback: (value: string) => {
      if (value === "confirm") revokeReport();
    },
  });
};

const generateReport = () => {
  if (isLoadingGenerateReport.value) return;

  isLoadingGenerateReport.value = true;

  api
    .generateDocReport(props.docUid, props.report.id)
    .then(() => {
      insightTrack(
        hasGeneratedFields.value
          ? AiReportsEvent.ReportRegenerated
          : AiReportsEvent.ReportGenerated,
      );

      emit("reload-reports");
    })
    .catch(() => {
      $notifyDanger("Failed to generate report");
    })
    .finally(() => {
      isLoadingGenerateReport.value = false;
    });
};

const updateReportDocField = (field: AiDocReportField) => {
  if (!props.reportDoc) return;

  const index = props.reportDoc.fields.findIndex(
    (item) => item.id === field.id,
  );

  if (index === -1) return;

  const fields = props.reportDoc.fields.slice();

  fields.splice(index, 1, field);

  emit("update:report-doc", { ...props.reportDoc, fields });
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.emptyStub {
  padding: spacing.$s;
}
</style>
