<template>
  <ElTooltip :enterable="false" :show-after="200">
    <template #content>
      <div class="dash-rooms-table__last-note-details">
        <DrEditor :model-value="note.body" :editable="false" />
      </div>
    </template>
    <div class="truncate">{{ note.body_text }}</div>
  </ElTooltip>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DrEditor } from "@shared/ui/editor";

import type { DealNote } from "@drVue/store/modules/client-dashboard/notes/types";
import type { PropType } from "vue";

export default defineComponent({
  name: "LastNoteCell",
  components: { DrEditor },
  props: {
    note: { required: true, type: Object as PropType<DealNote> },
  },
});
</script>
