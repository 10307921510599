<template>
  <div
    class="alert-message__item"
    :class="{
      [`alert-message__item--${item.type}`]: true,
    }"
  >
    <div class="alert-message__container">
      <div
        class="alert-message__content"
        :class="{
          'alert-message__content--clickable': hasOnClickHandler,
        }"
        @click="handleClick"
      >
        <!-- eslint-disable-next-line -->
        <span class="alert-message__text" v-html="item.message" />
        <DrIcon
          v-if="hasOnClickHandler"
          class="alert-message__external-link"
          name="external-link"
        />
      </div>

      <div class="alert-message__close">
        <ElProgress
          type="circle"
          color="white"
          :width="progressWidth"
          :show-text="false"
          :percentage="progress"
          :stroke-width="progressStroke"
        />

        <i
          class="fa fa-times alert-message__close-ico"
          aria-hidden="true"
          @click.stop="clearAlert(false)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrIcon from "@shared/ui/dr-icon";

import { Toasts } from "../service";

import type { Toast } from "../service";
import type { PropType } from "vue";

const DEFAULT_DURATION = 4000;
const CLEAR_ANIMATION_DURATION = 600; // el-progress has .6s transition

interface Data {
  timerId: number | undefined;
  progress: any;
  progressWidth: any;
  progressStroke: any;
}

export default defineComponent({
  name: "AlertMessage",
  components: { DrIcon },
  props: {
    item: { required: true, type: Object as PropType<Toast> },
  },
  data(): Data {
    return {
      timerId: undefined,
      progress: 0,
      progressWidth: 24,
      progressStroke: 1,
    };
  },
  computed: {
    hasOnClickHandler() {
      return !!this.item.options?.onClick;
    },
  },
  created() {
    const duration: number = this.item.options?.duration
      ? this.item.options.duration
      : DEFAULT_DURATION;

    if (duration > 0) {
      const start = Date.now();

      this.timerId = window.setInterval(() => {
        const d = Date.now() - start;

        this.progress = Math.min(100, Math.round((d * 100) / duration));
        if (this.progress == 100) {
          this.clearAlert(true);
        }
      }, 20);
    }
  },
  beforeUnmount() {
    this.clearTimeout();
  },
  methods: {
    handleClick() {
      if (this.item.options?.onClick) {
        this.item.options.onClick();
      }

      this.clearAlert(false);
    },
    clearAlert(withDelay: boolean) {
      this.clearTimeout();

      const delay = withDelay ? CLEAR_ANIMATION_DURATION : 0;
      setTimeout(() => {
        Toasts.remove(this.item.id);
      }, delay);
    },
    clearTimeout() {
      clearInterval(this.timerId);
      this.timerId = undefined;
    },
  },
});
</script>
