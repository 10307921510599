export default function drListKeyCheck() {
  return {
    require: "ngModel",
    scope: {
      disallowedKeys: "<?",
    },
    restrict: "A",
    link: function (scope, elm, attrs, $ctrl) {
      const MIN_LENGTH = 2;
      const MAX_LENGTH = 6;
      const LIST_KEY_PATTERN = new RegExp(
        "^[A-Z]{" + MIN_LENGTH + "," + MAX_LENGTH + "}$",
      );
      $ctrl.$validators.minlength = function (modelValue) {
        return $ctrl.$isEmpty(modelValue) || modelValue.length >= MIN_LENGTH;
      };
      $ctrl.$validators.maxlength = function (modelValue) {
        return $ctrl.$isEmpty(modelValue) || modelValue.length <= MAX_LENGTH;
      };

      $ctrl.$validators.invalid = function (modelValue) {
        return $ctrl.$isEmpty(modelValue) || LIST_KEY_PATTERN.test(modelValue);
      };
      $ctrl.$validators.disallowed = function (modelValue) {
        if (
          $ctrl.$isEmpty(modelValue) ||
          $ctrl.$isEmpty(scope.disallowedKeys)
        ) {
          return true;
        }
        return scope.disallowedKeys.indexOf(modelValue) === -1;
      };
      $ctrl.$parsers.push(function (value) {
        if (value) {
          return value.toUpperCase();
        }
      });
    },
  };
}
