import type { RoomCopyOptions } from "@drVue/api-service/common/rooms";

export const Fields = ["copy_options"];
export const DEFAULT_COPY_OPTIONS: RoomCopyOptions = {
  source_room: null,
  copy_groups: [],
  copy_folders: true,
  copy_documents: true,
  copy_labels: true,
  copy_tasks: {
    copy_categories: true,
    copy_tasks: true,
    keep_labels: true,
    keep_statuses: true,
    keep_assignees: true,
    keep_reviewers: true,
    keep_attachments: true,
    keep_dependencies: true,
    keep_dates: true,
    keep_custom_fields: true,
  },
};

export { default } from "./index.vue";
