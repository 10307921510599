<template>
  <ElDialog
    v-model="isModalVisible"
    :title="dialogTitle"
    :class="$style.dialog"
    :append-to-body="true"
    @close="close"
  >
    <RoomCreate
      v-if="isModalVisible"
      :deal="deal"
      :can-create-deal="canCreateDeal"
      @created="close"
    />
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { IS_PIPELINE_ENABLED } from "@drVue/components/navigation/utils";
import RoomCreate from "@drVue/components/user-dashboard/room-create/index.vue";

import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";

interface Data {
  isModalVisible: boolean;
  deal?: Deal;
}

export default defineComponent({
  name: "RoomCreateModal",
  components: { RoomCreate },
  data(): Data {
    return {
      isModalVisible: false,
      deal: undefined,
    };
  },
  computed: {
    canCreateDeal() {
      return !this.deal && IS_PIPELINE_ENABLED;
    },
    dialogTitle(): string {
      if (this.deal) {
        return `Create a new room for the '${this.deal.title}' deal`;
      }
      if (this.canCreateDeal) {
        return "Create a new deal";
      }
      return "Create a new room";
    },
  },
  methods: {
    open(deal?: Deal) {
      this.deal = deal;
      this.isModalVisible = true;
    },
    close() {
      this.isModalVisible = false;
    },
  },
});
</script>

<style lang="scss" module>
.dialog:global(.el-dialog) {
  // let RoomCreate component control width of dialog
  width: fit-content;
}
</style>
