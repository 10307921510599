<template>
  <div :class="$style.fieldWrapper" v-loading="isLoading">
    <ElButton
      size="small"
      :text="true"
      @click="add"
      :disabled="isLoading"
      :class="$style.button"
    >
      <DrIcon name="plus" size="sm" />
    </ElButton>
    <div :class="$style.label">
      {{ label }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { DrIcon } from "@shared/ui/dr-icon";

import { AiReportsEvent, insightTrack } from "@app/insight";
import { $notifyDanger } from "@drVue/common";
import { pinia } from "@drVue/store/pinia";
import { useAiReportsStore } from "../AiReportsStore";

import type { AiReport } from "../types";

interface Props {
  reportId: AiReport["id"];
  label: string;
}

const props = defineProps<Props>();

const isLoading = ref(false);
const aiReportsStore = useAiReportsStore(pinia);

const add = () => {
  isLoading.value = true;
  aiReportsStore
    .generateAndCreateNewField(props.reportId, props.label)
    .then(() => {
      insightTrack(AiReportsEvent.FieldAdded, { source: "suggested" });

      isLoading.value = false;
    })
    .catch(() => {
      $notifyDanger("Failed to add field");
      isLoading.value = false;
    });
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

$button_height: 24px;

.fieldWrapper {
  display: grid;
  grid-template-columns: $button_height 1fr;
  gap: spacing.$xs;
  align-items: center;
  font-size: typography.$font-size-body;
  background: white;
}

.button {
  height: $button_height;
}

.label {
  width: 100%;
  padding: 6px 0;

  .fieldWrapper:not(:last-child) & {
    border-bottom: 1px solid colors.$pr-150;
  }
}
</style>
