<template>
  <div :class="$style.container">
    <template v-if="manager.state.isTextSelectionSupported">
      <div :class="$style.section">
        <DrIcon
          name="cursor"
          :class="{
            [$style.action]: true,
            [$style.actionActive]: manager.state.textSelectionMode,
          }"
          @click="() => manager.setTextSelectionMode(true)"
        />
        <DrIcon
          name="hand"
          :class="{
            [$style.action]: true,
            [$style.actionActive]: !manager.state.textSelectionMode,
          }"
          @click="() => manager.setTextSelectionMode(false)"
        />
      </div>
      <div :class="$style.divider" />
    </template>

    <template v-if="manager.isRotateSupported || manager.isFitZoomSupported">
      <div :class="$style.section">
        <DrIcon
          v-if="manager.isRotateSupported"
          name="undo"
          :class="[$style.action, $style.flip]"
          @click="() => manager.rotate()"
        />
        <DrIcon
          v-if="manager.isFitZoomSupported"
          name="arrows-h"
          :class="$style.action"
          @click="() => manager.fitWidth()"
        />
        <DrIcon
          v-if="manager.isFitZoomSupported"
          name="arrows-v"
          :class="$style.action"
          @click="() => manager.fitHeight()"
        />
      </div>
      <div :class="$style.divider" />
    </template>

    <div :class="$style.section">
      <DrIcon
        name="minus"
        :class="$style.action"
        @click="() => manager.zoomDocument(false)"
      />
      <span>{{ zoomLevel }}%</span>
      <DrIcon
        name="plus"
        :class="$style.action"
        @click="() => manager.zoomDocument(true)"
      />
    </div>
    <div :class="$style.divider" />
    <div :class="$style.section">
      <DrIcon
        name="caret-up"
        :class="$style.action"
        :disabled="manager.state.currentPage === 1"
        @click="() => manager.prevPage()"
      />
      <span>
        {{ manager.state.currentPage }}/{{ manager.state.totalPages }}
      </span>
      <DrIcon
        name="caret-down"
        :class="$style.action"
        :disabled="manager.state.currentPage === manager.state.totalPages"
        @click="() => manager.nextPage()"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import DrIcon from "@shared/ui/dr-icon/DrIcon.vue";

import type { BaseViewerManager } from "@drVue/components/room/viewer/managers/base";

const props = defineProps<{
  manager: BaseViewerManager;
}>();

const zoomLevel = computed(() => {
  if (props.manager.state.zoomLevel === null) return 0;
  return Math.floor(props.manager.state.zoomLevel * 100);
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";
@use "vars";

.container {
  height: 40px;
  background: colors.$pr-800;
  color: colors.$pr-400;
  border-radius: 8px;
  display: inline-flex;
  span {
    color: white;
    font: typography.$body_semibold;
  }
}

.section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px;
}

.divider {
  width: 1px;
  height: 18px;
  background: colors.$pr-400;
  place-self: center;
}

.action {
  margin: 0 6px;
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
}

.actionActive {
  color: white;
}

.flip {
  transform: scale(-1, 1);
}
</style>
