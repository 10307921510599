import { defineStore } from "pinia";
import { computed, reactive, toRef } from "vue";

import { createDictionary } from "@drVue/common";
import { generateList } from "@drVue/store/pinia/helpers/generators";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import { TasksLabelsApiService } from "./tasksLabelApi";

import type {
  TaskLabel,
  TaskLabelCreatePayload,
  TaskLabelUpdatePayload,
} from "./tasksLabelApi";

export type { TaskLabel };

const api = new TasksLabelsApiService();

export const useTasksLabelsStore = defineStore("tasksLabels", () => {
  const tasksStore = useTasksStore();

  const { list, dict, load, create, update, remove, isLoading, isLoadError } =
    generateList<TaskLabel, TaskLabelCreatePayload, TaskLabelUpdatePayload>({
      name: "Room tasks labels",
      load: api.load.bind(api),
      create: api.create.bind(api),
      update: api.update.bind(api),
      remove: api.delete.bind(api),
    });

  /**
   * @note This wrapper to ensure compatibility with the existing angular code
   *       frontend/app/ng/tasks/providers/drLabelKeyProvider.js
   */
  const updateLabel = (label: TaskLabel) => update(label.id, label);

  const deleteLabel = (id: TaskLabel["id"]) => {
    return remove(id).then(() => tasksStore.deleteTasksLabel(id));
  };

  return {
    list,
    dict,
    load,
    create,
    update: updateLabel,
    delete: deleteLabel,
    isLoading,
    isLoadError,
  };
});
