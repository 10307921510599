<template>
  <a href="" @click.prevent="deleteCategory()">Delete</a>
</template>

<script setup lang="ts">
import { ElMessageBox } from "element-plus";

import { insightTrack, RoomTasksCategoriesEvent } from "@app/insight";
import { pinia } from "@drVue/store/pinia";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";

import type { Category } from "@drVue/store/pinia/room/categories";

interface Props {
  category: Category;
}

interface Emits {
  (event: "deleted", category: Category): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const categoriesStore = useCategoriesStore(pinia);

const deleteCategory = () => {
  const { category } = props;
  const title = category.parent ? "Delete category" : "Delete worklist";
  const message = category.parent
    ? "Are you sure you want to delete this category, including all nested sub-categories and requests?"
    : "Are you sure you want to delete this worklist, including all nested categories and requests?";

  ElMessageBox.confirm(message, title, {
    customClass: "el-message-box--warning",
    confirmButtonText: "Delete",
  })
    .then(() => {
      categoriesStore.archiveCategory(category).then(() => {
        insightTrack(RoomTasksCategoriesEvent.Deleted, {
          id: `${category.id}`,
          parent: category.parent?.name ?? "",
          name: category.name,
        });

        emit("deleted", category);
      });
    })
    .catch(() => {});
};
</script>
