import { keyBy } from "lodash-es";

import type { State } from "./state";
import type { DealNote, Note, UserNote } from "./types";
import type { MutationTree } from "vuex";

export interface NotesMutationTree<NoteType extends Note>
  extends MutationTree<State<NoteType>> {
  addNotes(state: State<NoteType>, notes: NoteType[]): void;
  updateNote(state: State<NoteType>, note: NoteType): void;
  deleteNote(state: State<NoteType>, note: NoteType): void;
}

function makeMutationsTree<
  NoteType extends Note,
>(): NotesMutationTree<NoteType> {
  return {
    addNotes(state, notes: NoteType[]) {
      // merge existing `states.items` with new `notes` by id
      const notesById = keyBy(notes, "id");

      const existingNotes: { [noteId: number]: NoteType } = {};
      state.items.forEach((n) => {
        if (n.id in notesById) {
          Object.assign(n, notesById[n.id]);
          existingNotes[n.id] = n;
        }
      });
      const newNotes: NoteType[] = [];
      notes.forEach((n) => {
        if (!(n.id in existingNotes)) {
          newNotes.push(n);
        }
      });
      state.items.push(...newNotes);
    },

    updateNote(state, note: NoteType) {
      const existing = state.items.find((n) => n.id === note.id);
      if (!existing) return;
      Object.assign(existing, note);
    },

    deleteNote(state, note: NoteType) {
      const existingIdx = state.items.findIndex((n) => n.id === note.id);
      if (existingIdx === -1) return;
      state.items.splice(existingIdx, 1);
    },
  };
}

export const dealsNotesMutations = makeMutationsTree<DealNote>();
export const usersNotesMutations = makeMutationsTree<UserNote>();
