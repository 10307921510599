import { ClientDashboardApiService } from "./base";

import type { FieldItem } from "./types";

export const apiRoutePrefix: string = "custom-fields";

export class FieldsApiService extends ClientDashboardApiService<FieldItem> {
  constructor() {
    super(apiRoutePrefix);
  }
}
