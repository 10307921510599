Controller.$inject = ["$scope", "$element"];
import * as d3 from "d3";

angular
  .module("dealroom.analytics.svg.shadow", [])
  .directive("drAnalyticsFilterShadow", () => ({
    scope: {
      drAnalyticsFilterShadow: "<",
    },
    controller: Controller,
    restrict: "A",
    controllerAs: "$ctrl",
  }));

function Controller($scope, $element) {
  const params = $scope.drAnalyticsFilterShadow;
  const defs = d3.select($element[0]);

  createShadow(params, defs);
}

function createShadow({ id, dev, left, top, op, w }, defs) {
  const [tag, cls] = ["filter", "shadow"];
  const filter = defs
    .append(tag)
    .attr("class", cls)
    .attr("id", id)
    .attr("x", "-40%")
    .attr("y", "-40%")
    .attr("width", w + "%")
    .attr("height", w + "%")
    .attr("filterUnits", "userSpaceOnUse");

  filter
    .append("feGaussianBlur")
    .attr("in", "SourceAlpha")
    .attr("stdDeviation", dev);

  filter
    .append("feOffset")
    .attr("dx", left)
    .attr("dy", top)
    .attr("result", "offsetblur");

  filter
    .append("feOffset")
    .attr("dx", -left)
    .attr("dy", -top)
    .attr("result", "offsetblur");

  filter.append("feFlood").attr("flood-color", `rgba(0, 0, 0, ${op})`);
  filter.append("feComposite").attr("in2", "offsetblur").attr("operator", "in");

  const merge = filter.append("feMerge");
  merge.append("feMergeNode");
  merge.append("feMergeNode").attr("in", "SourceGraphic");
}
