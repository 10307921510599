<template>
  <div>
    <div class="dash-content-panel-info__item-label">Start Date</div>
    <div class="dash-content-panel-info__item-value">
      <span>{{ dealStartDate }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { drUserTime } from "@drVue/filters/drUserTime";

import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";
import type { PropType } from "vue";

export default defineComponent({
  name: "DealStartDateField",
  props: {
    item: { required: true, type: Object as PropType<Deal> },
  },
  computed: {
    dealStartDate(): any {
      return drUserTime(this.item.start_date, "full-date");
    },
  },
});
</script>
