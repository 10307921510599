<template>
  <div class="items">
    <template v-if="items.length">
      <button
        v-for="(row, index) in items"
        :key="index"
        class="item"
        :class="{ 'is-selected': index === selectedIndex }"
        @click="selectItem(index)"
      >
        <MentionItemContent :type="row.type" :item="row.item" />
      </button>
    </template>
    <div v-else class="item">No result</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import MentionItemContent from "./MentionItemContent.vue";
import { MentionTypes } from "./providers";

import type { PropType } from "vue";

interface KeyDownEvent {
  event: {
    key: "ArrowUp" | "ArrowDown" | "Enter";
  };
}

export default defineComponent({
  name: "MentionsList",

  components: {
    MentionItemContent,
  },

  props: {
    items: {
      type: Array as PropType<any>,
      required: true,
    },
    command: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      selectedIndex: 0,
      MentionTypes: MentionTypes,
    };
  },

  watch: {
    items() {
      this.selectedIndex = 0;
    },
  },

  methods: {
    onKeyDown({ event }: KeyDownEvent) {
      if (event.key === "ArrowUp") {
        this.upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        this.downHandler();
        return true;
      }

      if (event.key === "Enter") {
        this.enterHandler();
        return true;
      }

      return false;
    },

    upHandler() {
      this.selectedIndex =
        (this.selectedIndex + this.items.length - 1) % this.items.length;
    },

    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
    },

    enterHandler() {
      this.selectItem(this.selectedIndex);
    },

    selectItem(index: number) {
      const item = this.items[index];
      if (item) this.command({ id: item.item.id, type: item.type });
    },
  },
});
</script>

<style lang="scss" scoped>
.items {
  padding: 0.2rem;
  position: relative;
  border-radius: 0.5rem;
  background: #fff;
  color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  font-size: 13px;
}

.item {
  display: block;
  margin: 0;
  width: 100%;
  text-align: left;
  background: transparent;
  border-radius: 0.4rem;
  border: 1px solid transparent;
  padding: 0.2rem 0.4rem;

  &.is-selected {
    border-color: #999;
  }
}
</style>
