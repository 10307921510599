import * as d3 from "d3";

angular
  .module("dealroom.analytics.datagrid-table.service.nest.rows", [
    "dealroom.analytics.service.labels",
  ])
  .service(
    "AnalyticsDatagridNestRowsService",
    AnalyticsDatagridNestRowsService,
  );

AnalyticsDatagridNestRowsService.$inject = ["ActivityLabelService"];
function AnalyticsDatagridNestRowsService(ActivityLabelService) {
  return function ({ activityRowCells, cells }) {
    const flatCells = _flatCells(cells);
    return {
      cellsToRow,
      getActionRow,
    };

    function _flatCells(cells) {
      return cells.reduce((bucket, cell) => {
        putToBucket(cell);
        return bucket;

        function putToBucket(cell) {
          if (cell.childs !== undefined) {
            cell.childs.forEach(putToBucket);
          } else {
            bucket.push(cell);
          }
        }
      }, []);
    }

    function cellsToRow(
      { type, id, showPath, paddingLevel, alignBottom, dt },
      cellsValues = {},
    ) {
      const titleCell = {
        type,
        id,
        showPath,
        paddingLevel,
        alignBottom,
        dt,
        visible: true,
        key: "title",
        class: "datagrid-table__column--title",
      };
      const cells = flatCells.map(getCellValue);
      cells.unshift(titleCell);
      return cells;

      function getCellValue(cell) {
        let value = cellsValues[cell.key];
        if (cell.src.split(":")[0] === "uniq") {
          value = (value || []).length;
        } else if (value === undefined) {
          value = 0;
        }

        return {
          value,
          key: cell.key,
          class: cell.class,
          getDisplayedValue: cell.getDisplayedValue,
          visible: cell.visible === false ? false : true, // undefined as true
        };
      }
    }

    function getActionRow(d, order) {
      const gridtime = d3.timeFormat("%-I:%M %p");
      const item = ActivityLabelService.item(d) || {};
      const targetCls =
        activityRowCells.length > 3
          ? "datagrid-table__column--action-target"
          : "datagrid-table__column--action-target-lg";
      return {
        itemId: "d-" + d.id,
        id: d.id,
        order,
        cells: activityRowCells.map(getActionCell),
      };

      function getActionCell(name) {
        const actionsCells = {
          timestamp: {
            type: "timestamp",
            value: gridtime(d.dt),
            class: "datagrid-table__column--timestamp",
            visible: true,
          },
          verb: {
            type: "verb",
            value: ActivityLabelService.label(d),
            class: "datagrid-table__column--action",
            visible: true,
          },
          user: {
            type: "user",
            id: d.user,
            class: targetCls,
            visible: true,
          },
          group: {
            type: "group",
            userId: d.user,
            class: targetCls,
            visible: true,
          },
          target: {
            type: item.type,
            id: item.id,
            class: targetCls,
            visible: true,
          },
        };

        const cell = actionsCells[name];
        cell.key = name;
        return cell;
      }
    }
  };
}

function dateAsc(key) {
  return (a, b) => d3.ascending(new Date(a[key]), new Date(b[key]));
}
function dateDesc(key) {
  return (a, b) => d3.descending(new Date(a[key]), new Date(b[key]));
}
