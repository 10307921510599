import { BaseViewerManager } from "./base";

export default class DummyViewer extends BaseViewerManager {
  static readonly ID = "dummy";
  isRotateSupported = false;
  isFitZoomSupported = false;
  isRedactionSupported = false;

  initialize() {
    const initializationDelay = Math.random() * 2 + 0.5;
    this.beforeViewerInitializeCb();
    setTimeout(() => {
      this.state.isLoaded = true;
      this.state.currentPage = 1;
      this.state.zoomLevel = 0.89;
      this.state.totalPages = 9;
      this.state.isTextSelectionSupported = true;

      const container = document.getElementById(this.viewerContainerId);
      const h1 = document.createElement("h1");
      h1.textContent = "Dummy Viewer Content";
      container!.appendChild(h1);
      const info = document.createElement("div");
      info.textContent = JSON.stringify(this.doc);
      container!.appendChild(info);
      if (this.initialPage) {
        this.goToPage(this.initialPage);
      }
    }, initializationDelay * 1000);
  }
  goToPage(page: number) {
    this.state.currentPage = Math.max(
      1,
      Math.min(this.state.totalPages!, page),
    );
  }
  prevPage() {
    this.state.currentPage = Math.max(1, this.state.currentPage! - 1);
  }
  nextPage() {
    this.state.currentPage = Math.min(
      this.state.totalPages!,
      this.state.currentPage! + 1,
    );
  }
  zoomDocument(zoomIn: boolean) {
    this.state.zoomLevel! = Math.max(
      0.1,
      this.state.zoomLevel! + (zoomIn ? 0.3 : -0.3),
    );
  }

  fitWidth() {
    console.log("fit width");
  }

  fitHeight() {
    console.log("fit width");
  }
  rotate() {
    console.log("rotate");
  }

  search(term: string) {
    console.log(term, " term been searched");
  }

  setTextSelectionMode(enable: boolean) {
    this.state.textSelectionMode = enable;
  }
}
