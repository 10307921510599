<template>
  <div v-if="status" :class="$style.status">
    <DrIconStatus size="sm" :color="status.color" />
    <DrTruncatedTextTooltip :content="status.name">
      {{ status.name }}
    </DrTruncatedTextTooltip>
  </div>
  <div v-else>
    <slot name="empty" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import DrIconStatus from "@shared/ui/dr-icon-status";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import { pinia } from "@drVue/store/pinia";
import { useFindingsStatusesStore } from "@drVue/store/pinia/pipeline/findings-statuses";

import type { FindingsTableRow } from "../types";

interface Props {
  statusId: FindingsTableRow["status_id"];
}

const props = defineProps<Props>();

const findingsStatusesStore = useFindingsStatusesStore(pinia);

const status = computed(() => findingsStatusesStore.dict[props.statusId]);
</script>

<style lang="scss" module>
.status {
  width: 100%;
  display: inline-flex;
  gap: 6px;
  align-items: center;
}
</style>
