<template>
  <ElForm
    ref="formRef"
    size="default"
    label-position="top"
    :class="$style.form"
    :model="model"
    :rules="rules"
    :disabled="disabled"
    :style="formStyle"
    :scroll-into-view-options="{
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    }"
    @keypress.enter.prevent="$emit('submit')"
  >
    <slot name="default" />
  </ElForm>
</template>

<script setup lang="ts">
/**
 * Wrapper component for displaying the form in a unified way
 * (according style guide).
 */
import { computed, ref, watch } from "vue";

import type { FormInstance, FormRules } from "element-plus";

interface Props {
  width?: number;
  model?: Record<string, any>;
  rules?: FormRules;
  getFormRef?: (form: FormInstance) => void;
  disabled?: boolean;
  gridTemplateColumns?: string;
}

const props = withDefaults(defineProps<Props>(), {
  width: 0,
  model: undefined,
  rules: undefined,
  getFormRef: undefined,
  disabled: false,
  gridTemplateColumns: undefined,
});

defineEmits(["submit"]);

const formRef = ref<FormInstance>();

const formWidth = computed(() => (props.width ? `${props.width}px` : "100%"));

const formStyle = computed(() =>
  props.gridTemplateColumns
    ? { gridTemplateColumns: props.gridTemplateColumns }
    : {},
);

watch(
  () => formRef.value,
  (form) => {
    if (form && props.getFormRef) props.getFormRef(form);
  },
);
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;

.form {
  width: v-bind(formWidth);
  min-height: 0;
  display: grid;
  grid-auto-flow: row;
  gap: spacing.$l;

  :global {
    .el-form-item {
      margin: 0;
    }

    // set default view for content as full width
    .el-form-item__content > * {
      width: 100%;
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__wrapper {
      width: 100%;
    }

    .el-form-item__label {
      font: typo.$body_regular;
      color: colors.$pr-900;
      margin-bottom: spacing.$xxs !important;
    }

    .el-form-item[hide-label] .el-form-item__label {
      display: none;
    }

    .el-checkbox:not(.is-bordered) {
      height: spacing.$xl;
    }

    .el-select {
      width: 100%;
    }

    .el-radio {
      height: spacing.$xl;
      margin-bottom: 0;
    }
  }
}
</style>
