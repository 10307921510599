<template>
  <div v-if="disabled">
    <slot v-bind="{ gridRefSetter }" />
  </div>

  <div
    v-else
    @dragenter.prevent
    @dragover.prevent="onDragOver"
    @dragleave.prevent="onDragLeave"
    @drop="onDrop"
  >
    <slot v-bind="{ gridRefSetter }" />
  </div>
</template>

<script setup lang="ts" generic="Item">
import { ref } from "vue";

import { useVxeGridDragNDrop } from "./useVxeGridDragNDrop";

import type { UploadItem } from "@app/vue/utils/extractFiles";
import type { VxeGridInstance } from "vxe-table";

interface Props {
  rowIdPrefix: string;
  disabled?: boolean;
}

interface Emits {
  (e: "drop", item: Item, files: UploadItem[]): void;
  (e: "drop-default", files: UploadItem[]): void;
  (e: "drag-over", item: Item | undefined): void;
  (e: "drag-leave"): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const gridRef = ref<VxeGridInstance>();

const gridRefSetter = (value: VxeGridInstance) => {
  gridRef.value = value;
};

const { onDrop, onDragLeave, onDragOver } = useVxeGridDragNDrop(
  gridRef,
  props.rowIdPrefix,
  (item: Item, files) => emit("drop", item, files),
  (files) => emit("drop-default", files),
  (item?: Item) => emit("drag-over", item),
  () => emit("drag-leave"),
);
</script>
