import { Urls } from "@setups/urls";
import { ApiService } from "@drVue/api-service";

export type RawEmailItem = string;

export type RawEmailItemList = string; // comma-separated list of RawEmailItem

export interface EmailLinkedUser {
  user_id: string; // IOrgUser’s id, user_[a-zA-Z0-9]{22}
}

export interface OrgFile {
  id: string; // file_[a-zA-Z0-9]{22}
  name: string;
  mimetype: string; // e.g. "image/jpeg"
  size: number; // in bytes
  date_created: Date;
}

export interface EmailAttachment {
  email_id: string; // email_[a-zA-Z0-9]{22}
  file: OrgFile;
}

export interface EmailMessage {
  id: string; // email_[a-zA-Z0-9]{22}

  linked_deal_id: string | null; // deal_[a-zA-Z0-9]{22} (or null if not linked)

  linked_users: EmailLinkedUser[]; // read-only, may be empty

  raw_subject: string; // RE: FW: Initial subject
  raw_from: RawEmailItem;
  raw_to: RawEmailItemList;
  raw_cc: RawEmailItemList | "";
  raw_bcc: RawEmailItemList | "";
  attachments: EmailAttachment[];
  body_text: string | ""; // body as plain (non-html) text
  orig_date: Date; // origination date

  external_message_id: string; // provider-specific unique message id;
  external_thread_id: string; // provider-specific unique thread id;
}

export function attachmentDownloadUrl(
  emailId: string,
  attachmentId: string,
): string {
  return Urls["api:client-dashboard:emails-detail-attachments-download"](
    emailId,
    attachmentId,
  );
}

export class EmailsApiService extends ApiService {
  public getByUser(userId: string | number): Promise<EmailMessage[]> {
    const url: string =
      this.Urls["api:client-dashboard:emails-by-user"](userId);
    return this.get<EmailMessage[]>(url);
  }
  public getByDeal(dealId: string | number): Promise<EmailMessage[]> {
    const url: string =
      this.Urls["api:client-dashboard:emails-by-deal"](dealId);
    return this.get<EmailMessage[]>(url);
  }
  public linkUsersToEmail(
    emailId: number | string,
    usersIds: string[],
  ): Promise<EmailMessage[]> {
    const url: string =
      this.Urls["api:client-dashboard:emails-detail-linked_users-bulk"](
        emailId,
      );

    return this.post(url, {
      add: usersIds.map((ui): EmailLinkedUser => ({ user_id: ui })),
      remove: [],
    });
  }
  public unlinkUsersFromEmail(
    emailId: number | string,
    usersIds: string[],
  ): Promise<EmailMessage[]> {
    const url: string =
      this.Urls["api:client-dashboard:emails-detail-linked_users-bulk"](
        emailId,
      );

    return this.post(url, {
      add: [],
      remove: usersIds.map((ui): EmailLinkedUser => ({ user_id: ui })),
    });
  }
  public deleteEmail(emailId: number | string) {
    const url: string =
      this.Urls["api:client-dashboard:emails-detail"](emailId);
    return this.delete(url);
  }
}

export const emailsApi = new EmailsApiService();
