<template>
  <div class="rooms-section">
    <!-- Header -->
    <div class="header rooms-section__header">
      <div class="header__title">Rooms {{ `(${roomsData.length})` }}</div>

      <ElButton
        v-if="false"
        class="header__invite-button"
        type="text"
        size="default"
        @click="() => {}"
      >
        <template #icon>
          <i class="fas fa-user-plus" />
        </template>
        Invite to room
      </ElButton>
    </div>
    <!-- / Header -->

    <div class="rooms-section__rooms-list-wrapper">
      <div class="rooms-section__rooms-list">
        <div v-if="roomsData.length === 0" class="no-rooms-text">
          This user hasn't been added to any room
        </div>
        <!-- Room -->
        <a
          v-for="data in roomsData"
          :key="data.room.id"
          class="room"
          :href="data.room.permalink"
          target="_blank"
        >
          <ElImage
            v-if="data.room.logo"
            class="room__logo"
            fit="contain"
            :src="data.room.logo"
          />

          <span v-else class="room__logo room__logo--placeholder">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="512"
              height="512"
              viewBox="0 0 512 512"
            >
              <path
                d="M0,512V0H512V512H0ZM223.368,67.1H67.1V444.9H223.368V67.1Zm221.53,0H288.632V186.6H444.9V67.1Zm0,184.762H288.632V444.9H444.9V251.864Z"
              />
            </svg>
          </span>

          <span class="room__name-n-group">
            <span class="room__name">
              {{ data.room.title }}
            </span>
            <span class="room__group">Group: {{ data.group.name }}</span>
          </span>
        </a>
        <!-- / Room -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type {
  Room,
  RoomGroup,
} from "@drVue/store/modules/client-dashboard/deals/types";
import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { PropType } from "vue";

interface ListData {
  group: RoomGroup;
  room: Room;
}

export default defineComponent({
  name: "RoomsList",
  props: {
    user: { required: true, type: Object as PropType<OrgUser> },
    searchQuery: { default: "", type: String as PropType<string> },
  },
  computed: {
    roomsData(): ListData[] {
      const getRoomsOfOrgUser =
        this.$store.getters["clientDashboard/deals/getRoomsOfOrgUser"];

      const getRoomGroupOfOrgUser =
        this.$store.getters["clientDashboard/deals/getRoomGroupOfOrgUser"];

      const rooms = getRoomsOfOrgUser(this.user.id);
      const data: ListData[] = rooms.map((room: Room) => {
        const group = getRoomGroupOfOrgUser(room.id, this.user.id);
        return {
          group,
          room,
        };
      });

      if (!this.searchQuery) return data;

      return data.filter(
        (rd) =>
          rd.group.name.toLowerCase().includes(this.searchQuery) ||
          rd.room.title.toLowerCase().includes(this.searchQuery),
      );
    },
  },
});
</script>

<style scoped lang="scss">
@import "/app/styles/scss/_colors.scss";
@import "/app/styles/scss/typography.scss";

.no-rooms-text {
  padding: 7px 25px 8px;
  color: $pr-500;
}

// Room
.room {
  margin: 0 -25px 0 0;
  padding: 7px 25px 8px;
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  user-select: none;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    background: rgba($pr-50, 0);
    transition: background-color 0.15s ease;
  }

  &:hover {
    background: rgba($pr-50, 1);
  }

  &__logo {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    border-radius: 3px;
    position: relative;
    overflow: hidden;

    &--placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $pr-300;

      svg {
        width: 50%;
        height: 50%;
        fill: #fff;
      }
    }
  }

  &__name-n-group {
    display: flex;
    flex-direction: column;
  }

  &__name {
    margin-bottom: 2px;
    font-weight: 600 !important;
    font-size: 14px;
    line-height: 19px;
    color: $pr-800;
  }
  &__group {
    font-size: 13px;
    line-height: 18px;
    color: $pr-500;
  }
}

// Header
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: $pr-800;
  }

  &__invite-button {
    align-self: center;
  }
}

// Rooms section
.rooms-section {
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  &__header {
    padding-top: 8px;
    margin-bottom: 18px;
  }

  &__rooms-list-wrapper {
    width: calc(100% + 50px);
    margin: 0 -25px;
  }
  &__rooms-list {
    max-height: calc(50vh - 60px);
    box-sizing: border-box;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

// Override EL UI for .rooms-section
.rooms-section.user-details-panel__section {
  padding-right: unset;
}
</style>
