<template>
  <div :class="$style.container">
    <DealsScopeCriteriaItem
      v-for="(item, i) in criteriaForm"
      :key="item?.id"
      v-model="criteriaForm[i]"
      :key-options="options"
      :value-options="Object.values(optionsTree[item?.id]?.children || [])"
      :value-error="valueErrors.get(i)"
      @update:model-value="handleUpdate"
    >
      <template #inner-op-name>Any of</template>
      <template v-if="i < criteriaForm.length - 1" #outer-op-name>And</template>
      <template v-if="criteriaForm.length > 1" #action>
        <DrIcon
          :class="$style.removeBtn"
          name="cross"
          size="xs"
          @click="handleRemoveOperand(item.id)"
        />
      </template>
    </DealsScopeCriteriaItem>
    <div :class="$style.addBtn">
      <span role="button" @click="handleCreateOperand">+ Add criteria</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { differenceBy } from "lodash-es";
import { computed, ref, watch } from "vue";
import DrIcon from "@shared/ui/dr-icon";

import { DrStore } from "@app/vue";
import DealsScopeCriteriaItem from "./DealsScopeCriteriaItem.vue";
import {
  fieldToSelectOption,
  isSelectField,
  makeEmptySelectOption,
  phaseToSelectOption,
} from "./utils";

import type { SelectOption, SelectOptionChildrenDictionary } from "./types";
import type { Dictionary } from "@drVue/types";
import type { DealScopeCriteria } from "@setups/data";

interface Props {
  modelValue?: DealScopeCriteria;
  valueErrors: Map<number, string>;
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: () => ({
    op_type: "AND",
    operands: [
      {
        op_type: "FIELD_ANY_OF",
        field_key: null!,
        values: [],
      },
    ],
  }),
});

interface Emits {
  (e: "update:modelValue", criteria: DealScopeCriteria): void;
}
const emit = defineEmits<Emits>();

const optionsTree: Dictionary<SelectOptionChildrenDictionary> = DrStore.getters[
  "clientDashboard/customFields/byObjectType"
]("deal")
  .filter(isSelectField)
  .map(fieldToSelectOption)
  .reduce(
    (acc: Dictionary<SelectOption>, el: SelectOption) => ({
      ...acc,
      [el.id]: el,
    }),
    {
      phase: {
        id: "phase",
        label: "Phase",
        children: DrStore.state.clientDashboard.phases.items
          .map(phaseToSelectOption)
          .reduce((acc, el) => ({ ...acc, [el.id]: el }), {}),
      },
    },
  );

const options = computed<SelectOption[]>(() =>
  // We're not allowed to use option as operand more than once
  differenceBy(
    // TODO: teach TS that dict's values are of type value[]
    Object.values(optionsTree) as unknown as SelectOption[],
    criteriaForm.value,
    "id",
  ),
);

const selectedOptionsKeys = computed(() =>
  criteriaForm.value.map((el) => el.id),
);

const criteriaForm = ref<SelectOption[]>([]);

function handleCreateOperand() {
  criteriaForm.value.push(makeEmptySelectOption());
}

function handleRemoveOperand(id: string) {
  criteriaForm.value = criteriaForm.value.filter((el) => el.id !== id);
  handleUpdate();
}

function handleUpdate() {
  emit("update:modelValue", {
    op_type: "AND",
    operands: criteriaForm.value.map((el) => ({
      op_type: "FIELD_ANY_OF",
      field_key: el.id,
      values: el.children?.map((el) => el.id) ?? [],
    })),
  });
}

watch(
  () => props.modelValue,
  ({ operands }) => {
    operands.forEach(({ field_key, values }) => {
      if (selectedOptionsKeys.value.includes(field_key)) {
        // It's cooler to pluck operand's values and map them to criteriaForm
        // children, however, it doesn't make a lot of sense, since there
        // won't be real-time updates (from backend) of the criteria.
        return;
      } else if (field_key === null) {
        criteriaForm.value.push(makeEmptySelectOption());
      } else {
        criteriaForm.value.push({
          id: field_key,
          label: optionsTree[field_key].label,
          children: values.map((id) => optionsTree[field_key].children![id]),
        });
      }
    });
  },
  { immediate: true },
);
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.container {
  padding: 8px 12px 12px 34px;
  background: colors.$pr-50;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-top: -6px;
}

.addBtn {
  margin-top: 8px;

  span {
    transition: color 0.1s ease-in-out;
    color: colors.$sc-600;
    font: typography.$caption_regular;
    cursor: pointer;

    &:hover {
      color: colors.$sc-700;
    }
  }
}

.removeBtn {
  cursor: pointer;
  transition: color 0.1s ease-in-out;
  &:hover {
    color: colors.$pr-500;
  }
}
</style>
