import tableHtml from "../table.html?raw";

TasksDatagrid.$inject = [
  "ActivityVisibleCategoriesService",
  "CategoriesService",
  "TasksService",
];
import "./activity";
import "./progress";

import * as d3 from "d3";

angular
  .module("dealroom.analytics.datagrid-table.tasks", [
    "dealroom.analytics.datagrid-table",
    "dealroom.analytics.datagrid-table.tasks.activity",
    "dealroom.analytics.datagrid-table.tasks.progress",
    "dealroom.analytics.service.data",
    "dealroom.task",
  ])
  .component("drTasksDatagrid", {
    bindings: {
      cells: "<",
      tableUid: "<",
      cls: "<",
      nestByArray: "<",
      getRootItems: "<",
      subParentFieldName: "<",
      limitTo: "<",
      verbsForFilter: "<",
      showPath: "<",
    },
    template: tableHtml,
    controller: TasksDatagrid,
  });

function TasksDatagrid(
  ActivityVisibleCategoriesService,
  CategoriesService,
  TasksService,
) {
  const $ctrl = this;

  // $ctrl.cells are set in Activity/Progress parent controller
  $ctrl.activityRowCells = ["timestamp", "user", "group"];
  $ctrl.filterBy = "requests";

  $ctrl.getRowFromItem = getRowFromItem;
  $ctrl.$onInit = function () {
    $ctrl.getRootItems = $ctrl.getRootItems || getRootItems;
  };

  function sortItems(a, b) {
    return d3.descending(a.order, b.order);
  }

  function getRootItems() {
    if (!CategoriesService.isLoaded()) return [];
    return ActivityVisibleCategoriesService.visibleRootCategoriesIds
      .map((i) => CategoriesService.categories[i])
      .filter((c) => c.is_archived === false)
      .sort(sortItems);
  }

  function getRowFromItem(item) {
    if (item.category_id !== undefined) return taskRow(item);
    if (item.categories instanceof Array) return categoryRow(item);
    return console.error("GetRowFromItem: Unknown type", item);

    function taskRow(task) {
      const row = {
        type: "task",
        id: task.id,
        sortName: task.title,
        // parentId: task.category_id,
        showPath: $ctrl.showPath,
        alignBottom: $ctrl.showPath,
        class: $ctrl.showPath ? "datagrid-table__row--task-with-path" : "",
      };
      return row;
    }

    function categoryRow(category) {
      const row = {
        type: "category",
        id: category.id,
        class: "datagrid-table__row--top",
        sortName: category.name,
      };
      if (hasChilds() === true) {
        row.getOrderedChilds = getOrderedChilds;
      }
      return row;

      function getOrderedChilds() {
        const { taskIds, categories } = getChilds();
        // items that have to be placed in the end of a table have to have lowest order
        // this why we place tasks first
        const tasks = taskIds
          .map((taskId) => TasksService.tasks[taskId])
          .sort(sortItems);
        return tasks.concat(categories.sort(sortItems));
      }

      function getChilds() {
        const taskIds = ActivityVisibleCategoriesService.getVisisbleTasks(
          category.id,
        );

        const categories = category.categories || [];
        return { taskIds, categories };
      }

      function hasChilds() {
        const { taskIds, categories } = getChilds();
        return taskIds.length > 0 || categories.length > 0;
      }
    }
  }
}
