import $ from "jquery";

import { Urls } from "@setups/urls";
import { BaseViewerManager } from "./base";

const IFRAME_ID = "prizmdoc_iframe";

const enum MOUSE_TOOLS {
  selectText = "AccusoftSelectText",
  pan = "AccusoftPanAndEdit",
}

export default class PrizmdocViewerManager extends BaseViewerManager {
  static readonly ID = "prizm";
  private viewerControl?: any;
  private extraViewerMethods?: any;
  private viewerSessionId?: any;
  private loadingEl?: any;

  readonly isRotateSupported = true;
  readonly isFitZoomSupported = true;
  readonly isRedactionSupported = true;

  initialize() {
    const viewerContainer = $(`#${this.viewerContainerId}`);
    this.loadingEl = $(
      '<dr-loader class="dr-loader--absolute-position" style="top: 85px">' +
        '<div class="loader-holder"><i class="loader"><i/></div>' +
        "</dr-loader>",
    );
    viewerContainer.prepend(this.loadingEl);

    const iframeSrc =
      window.location.protocol +
      "//" +
      window.location.host +
      Urls["api:room:document_detail_view_prizmdoc"](this.doc.id) +
      `?version=${this.doc.version_id}`;
    const iframeEl = `<iframe id="${IFRAME_ID}" src="${iframeSrc}"  allow="clipboard-write" style="width: 100%; height: 100%; display: block;"/>`;

    viewerContainer.append(iframeEl);

    window.document.addEventListener(
      "iframeLoaded",
      () => {
        this.removeLoaderElement();
      },
      false,
    );
    window.document.addEventListener(
      "initializationComplete",
      () => {
        this.onInitializationComplete();
      },
      false,
    );

    this.beforeViewerInitializeCb();
  }

  private removeLoaderElement() {
    this.loadingEl?.remove();
    this.loadingEl = undefined;
  }

  private onInitializationComplete() {
    this.removeLoaderElement();

    this.state.isLoaded = true;
    this.viewerControl = (
      document.getElementById(IFRAME_ID) as any
    ).contentWindow.viewerControl;
    this.extraViewerMethods = (
      document.getElementById(IFRAME_ID) as any
    ).contentWindow.extraViewerMethods;
    this.viewerSessionId = (
      document.getElementById(IFRAME_ID) as any
    ).contentWindow.sessionID;

    this.initViewerEventHandlers();
  }

  private initViewerEventHandlers() {
    const updateZoomLevel = () => {
      this.state.zoomLevel = this.viewerControl.scaleFactor;
    };

    const updatePageInfo = () => {
      this.state.currentPage = this.viewerControl.pageNumber;
      this.state.totalPages = this.viewerControl.pageCount;
    };

    const updateMouseTool = () => {
      this.state.textSelectionMode =
        this.viewerControl.getCurrentMouseTool() === MOUSE_TOOLS.selectText;
    };

    // see events https://help.accusoft.com/PrizmDoc/latest/HTML/PCCViewer.html#.EventType
    this.viewerControl.on("PageChanged", () => updatePageInfo());
    this.viewerControl.on("PageCountReady", () => updatePageInfo());
    this.viewerControl.on("EstimatedPageCountReady", () => updatePageInfo());
    this.viewerControl.on("ScaleChanged", () => updateZoomLevel());
    this.viewerControl.on("MouseToolChanged", () => updateMouseTool());
    updatePageInfo();
    updateZoomLevel();
    updateMouseTool();

    this.viewerControl.documentHasText().then((hasText: boolean) => {
      this.state.isTextSelectionSupported =
        hasText && this.doc.permission.download_original;
      this.setTextSelectionMode(this.state.isTextSelectionSupported);
      if (this.initialPage) {
        this.goToPage(this.initialPage);
      }
    });
  }

  goToPage(page: number) {
    this.viewerControl.pageNumber = page;
  }

  prevPage() {
    this.viewerControl.changeToPrevPage();
  }

  nextPage() {
    this.viewerControl.changeToNextPage();
  }

  zoomDocument(zoomIn: boolean) {
    const zoomFactor = 1.25; // x1.25
    if (zoomIn) {
      this.viewerControl.zoomIn(zoomFactor);
    } else {
      this.viewerControl.zoomOut(zoomFactor);
    }
  }

  fitWidth() {
    return this.viewerControl.fitContent("FullWidth");
  }

  fitHeight() {
    return this.viewerControl.fitContent("FullHeight");
  }
  rotate() {
    return this.viewerControl.rotateDocument(90);
  }

  search(term: string) {
    this.extraViewerMethods.viewerSearch(term);
  }

  setTextSelectionMode(enable: boolean) {
    const mouseTool =
      enable && this.state.isTextSelectionSupported
        ? MOUSE_TOOLS.selectText
        : MOUSE_TOOLS.pan;
    this.extraViewerMethods.setMouseTool(mouseTool);
  }

  // PrizmDoc specific methods
  getOriginalSessionId() {
    return this.viewerSessionId;
  }
  replaceSessionWithRedaction(redactionSessionId: string) {
    this.extraViewerMethods.replaceSessionWithRedaction(redactionSessionId);
  }
}
