<template>
  <DrPopup :teleported="false">
    <template #reference>
      <ElButton> Remind later </ElButton>
    </template>
    <template #default>
      {{ descriptionText }}
      <br />
      <ElInputNumber v-model="limit" :min="currentLimit" />
      {{ valueLabel }}
      <div :class="$style.spacer" />
      <ElButton type="primary" :loading="isSaving" @click="confirm">
        Confirm
      </ElButton>
      <div :class="$style.spacer" />
      <div :class="$style.spacer" />
      <p :class="$style.footer">
        Please note, reminder will not be shown again until the selected usage
        amount is reached
      </p>
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { DrPopup } from "@shared/ui/dr-popups";

import { SubscriptionPlanTypes } from "@setups/enums";
import {
  OrganizationBillingService,
  RoomBillingService,
} from "@drVue/api-service/common/billing";
import { $notifyDanger } from "@drVue/common";

interface Props {
  currentLimit: number;
  planType: SubscriptionPlanTypes;
  isRoomBilling: boolean;
  billingObjectId: number;
}
const props = withDefaults(defineProps<Props>(), {});
const typeLabels: { [key in SubscriptionPlanTypes]?: string } = {
  [SubscriptionPlanTypes.Storage]: "storage",
};
const valueLabels: { [key in SubscriptionPlanTypes]?: string } = {
  [SubscriptionPlanTypes.Storage]: "GB",
  [SubscriptionPlanTypes.LookerEditor]: "BI Editors",
  [SubscriptionPlanTypes.LookerViewer]: "BI Viewers",
};

const isSaving = ref<boolean>(false);

const limit = ref<number>(0);
watch(
  () => props.currentLimit,
  (newLimit) => {
    limit.value = newLimit;
  },
  { immediate: true },
);

const descriptionText = computed(() =>
  typeLabels[props.planType]
    ? `Hide warning until storage ${typeLabels[props.planType]} is:`
    : `Hide warning until usage is:`,
);
const valueLabel = computed(() =>
  valueLabels[props.planType] ? valueLabels[props.planType] : "",
);

const confirm = () => {
  if (isSaving.value) {
    return;
  }
  isSaving.value = true;
  const ApiClass = props.isRoomBilling
    ? RoomBillingService
    : OrganizationBillingService;
  const api = new ApiClass(props.billingObjectId);
  api
    .updateLimit(props.planType, limit.value)
    .then(() => {
      // update itis tricky since it's very deeply nested property
      // simply reload for now
      window.location.reload();
    })
    .catch(() => {
      $notifyDanger("Failed to load storage usage");
    })
    .finally(() => {
      isSaving.value = false;
    });
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.spacer {
  margin-bottom: 10px;
}

.footer {
  font-size: typography.$font-size-caption;
  word-break: normal;
}
</style>
