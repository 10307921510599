import { APP_SETTINGS, USER_DATA } from "@app/setups";

export function initCrisp() {
  if (!APP_SETTINGS.CRISP_WEBSITE_ID) return;

  if (!window.$crisp) {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = APP_SETTINGS.CRISP_WEBSITE_ID;

    const script = document.createElement("script");
    script.src = "https://client.crisp.chat/l.js";

    document.querySelector("head")?.appendChild(script);
  }

  if (USER_DATA.email && USER_DATA.crisp_verify_signature) {
    window.$crisp.push([
      "set",
      "user:email",
      [USER_DATA.email, USER_DATA.crisp_verify_signature],
    ]);
  }

  // It is safe to call this method multiple times. Only one callback will be registered.
  window.$crisp.push([
    "on",
    "chat:closed",
    () => {
      // We want to hide the Chat button when the chat is closed.
      window.$crisp.push(["do", "chat:hide"]);
    },
  ]);

  window.$crisp.push(["do", "chat:show"]);
  window.$crisp.push(["do", "chat:open"]);
}

window.DR.initCrisp = initCrisp;
