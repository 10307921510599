import treeFilter_treeFilterHtml from "../../templates/components/tree-filter/tree-filter.html?raw";
import treeFilter_treeFilterDisplayHtml from "../../templates/components/tree-filter/tree-filter-display.html?raw";
import treeFilter_treeFilterSelectorHtml from "../../templates/components/tree-filter/tree-filter-selector.html?raw";
import treeService from "./service";

(function (angular) {
  "use strict";

  angular
    .module("dealroom.tree-filter.directive", [
      "ui.bootstrap",
      "dealroom.date-fns",
      "dealroom.config",
      "dealroom.common",
    ])
    .service("TreeFilterSelectedService", TreeFilterSelectedService)
    .directive("treeFilter", TreeFilter)
    .directive("treeFilterDisplay", TreeFilterDisplay)
    .directive("treeFilterSelector", TreeFilterSelector);

  function TreeFilterSelectedService() {
    return treeService;
  }

  function TreeFilter() {
    return {
      restrict: "E",
      scope: true,
      bindToController: {
        titleField: "@",
        tree: "<",
        placeholder: "<",
      },
      template: treeFilter_treeFilterHtml,
      controllerAs: "ctrl",
      controller: [
        "$scope",
        function TreeFilterDisplayController($scope) {
          var self = this;

          self.clickOnInput = function () {
            self.menuIsOpen = !self.menuIsOpen;
          };

          angular.element("body").bind("mouseup", function ($event) {
            if (!self.menuIsOpen || !$event.target) {
              return;
            }

            if (
              angular.element($event.target).parents(".tree-filter__selector")
                .length < 1 &&
              angular.element($event.target).parents(".tree-filter__display")
                .length < 1
            ) {
              self.menuIsOpen = false;
              $scope.$apply();
            }
          });
        },
      ],
    };
  }

  function TreeFilterDisplay() {
    return {
      restrict: "E",
      replace: true,
      scope: true,
      bindToController: {
        click: "&",
        selected: "<",
        titleField: "<",
        tree: "<",
        placeholder: "<",
      },
      template: treeFilter_treeFilterDisplayHtml,
      controllerAs: "ctrl",
      controller: function TreeFilterDisplayController() {
        var self = this;
        self.placeholder = self.placeholder || "Select items";

        self.removeSelected = function ($event, item) {
          $event.preventDefault();
          $event.stopPropagation();
          self.tree.setSelected(item, false);
        };
      },
    };
  }

  function TreeFilterSelector() {
    return {
      restrict: "E",
      replace: true,
      scope: true,
      bindToController: {
        tree: "<",
        items: "<",
        titleField: "<",
      },
      template: treeFilter_treeFilterSelectorHtml,
      controllerAs: "ctrl",
      controller: function TreeFilterSelectorController() {},
    };
  }
})(angular);
