<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <div class="phase-cell">
    <div class="phase-cell__data">
      <DrDatePicker
        v-model="row[field]"
        type="date"
        size="large"
        clearable
        style="width: 100%"
      />
    </div>
    <div class="phase-cell__actions">
      <i class="fas fa-check" @click="saveRowEvent()" />
      <i class="fas fa-times" @click="cancelRowEvent()" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrDatePicker from "@shared/ui/dr-datepicker/DrDatepicker.vue";

import type { PropType } from "vue";

export default defineComponent({
  name: "DateCell",
  components: { DrDatePicker },
  props: {
    field: { required: true, type: String as PropType<string> },
    row: { required: true, type: Object as PropType<any> },
    table: { required: true, type: Object as PropType<any> },
  },
  methods: {
    saveRowEvent() {
      this.table.clearActived();
    },
    cancelRowEvent() {
      this.table.revertData(this.row);
      this.table.clearActived();
    },
  },
});
</script>
