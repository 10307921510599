import type { TaskStatus } from "./types";
import type { Dictionary } from "@drVue/types";

export enum TaskStatusType {
  Open = "open",
  InProgress = "inprogress",
  Reopened = "reopened",
  Resolved = "resolved",
}

export class TasksStatusesState {
  public TYPES_DEFAULT_LABEL: Dictionary<string> = {
    [TaskStatusType.Open]: "Open",
    [TaskStatusType.InProgress]: "In Progress",
    [TaskStatusType.Reopened]: "Reopened",
    [TaskStatusType.Resolved]: "Resolved",
  };

  public TYPES_ORDER: Dictionary<number> = {
    [TaskStatusType.Open]: 0,
    [TaskStatusType.InProgress]: 1,
    [TaskStatusType.Reopened]: 2,
    [TaskStatusType.Resolved]: 3,
  };

  public list: TaskStatus[] = [];
  public byTypes: Record<TaskStatusType, number[]> = {
    [TaskStatusType.InProgress]: [],
    [TaskStatusType.Open]: [],
    [TaskStatusType.Reopened]: [],
    [TaskStatusType.Resolved]: [],
  };

  public styleElement: HTMLStyleElement | null = null;

  public isLoading: boolean | null = null;
  public isError: boolean | null = null;
}
