import { cloneDeep } from "lodash-es";

import {
  deserializeCustomData,
  serializeCustomData,
} from "@drVue/api-service/parse";
import { $notifyDanger } from "@drVue/common";
import { DealsApiService } from "./DealsApiService";

import type { DealPatchRequestData } from "./DealsApiService";
import type { DealsState } from "./DealsState";
import type { Deal, Room, RoomGroup } from "./types";
import type { SetContactsOfDealPayload } from "@drVue/store/modules/client-dashboard/deals/DealsMutations";
import type { RootState } from "@drVue/store/state";
import type { ActionContext, ActionTree } from "vuex";

export type IContext = ActionContext<DealsState, RootState>;

interface OrgFile {
  id: string;
}

export interface PostDealAttachmentsPayload {
  dealId: number;
  file: File;
  controller: AbortController;
  fileUploadedHook?: (file: OrgFile) => void;
  fileAttachedHook?: (file: OrgFile) => void;
}

export interface UpdateContactsPayload {
  dealId: number;
  usersIdsToAdd: string[];
  usersIdsToRemove: string[];
}

export interface DealPatchPayload {
  dealId: number;
  updates: DealPatchRequestData;
}

interface DealsActionTree extends ActionTree<DealsState, RootState> {
  load(context: IContext): Promise<void>;
  refreshById(context: IContext, id: number): Promise<void>;
  updateDeal(context: IContext, deal: Deal): Promise<void>;
  patchDeal(context: IContext, payload: DealPatchPayload): Promise<void>;
  deleteDeal(context: IContext, dealId: number): Promise<void>;
  restoreRoom(context: IContext, deal: Deal): Promise<void>;
  deleteRoom(context: IContext, deal: Deal): Promise<void>;
  updateContacts(
    context: IContext,
    payload: UpdateContactsPayload,
  ): Promise<void>;
}

const api = new DealsApiService();

export const dealsActions = {
  async load({ commit, rootGetters }: IContext) {
    commit("setIsError", false);
    commit("setIsLoading", true);

    try {
      const deals = await api.list();
      const customFields =
        rootGetters["clientDashboard/customFields/byObjectType"]("deal");

      deals.forEach((d) => deserializeCustomData(d.custom_data, customFields));

      commit("setDeals", deals);
      commit("buildIndex", customFields);

      const allRooms: Room[] = deals.filter((d) => d.room).map((d) => d.room!);

      const clientRooms = allRooms.filter((room) => room.access.administrator);
      commit("setClientRooms", clientRooms);

      const allGroups = allRooms.reduce((allGroups, room) => {
        if (!room.groups) {
          return allGroups;
        }
        return allGroups.concat(room.groups);
      }, [] as RoomGroup[]);
      commit("setGroups", allGroups);
    } catch (error) {
      $notifyDanger("Failed to retrieve deals");
      commit("setIsError", true);
      throw error;
    } finally {
      commit("setIsLoading", false);
    }
  },

  async refreshById({ commit, dispatch, rootGetters }: IContext, id: number) {
    try {
      const deal = await api.getById(id);
      const customFields =
        rootGetters["clientDashboard/customFields/byObjectType"]("deal");

      deserializeCustomData(deal.custom_data, customFields);

      commit("updateDeal", deal);
      commit("updateIndex", { customFields, deal });

      if (deal.room && Array.isArray(deal.room.groups)) {
        for (const group of deal.room.groups) {
          commit("updateGroup", group);
        }
      }
    } catch (error) {
      $notifyDanger("Failed to retrieve deal");
      throw error;
    }
  },

  async updateDeal({ commit, dispatch, rootGetters }: IContext, deal: Deal) {
    try {
      deal = cloneDeep(deal);
      const customFields =
        rootGetters["clientDashboard/customFields/byObjectType"]("deal");

      serializeCustomData(deal.custom_data, customFields);
      const updatedDeal = await api.updateDeal(deal);
      deserializeCustomData(updatedDeal.custom_data, customFields);

      commit("saveDeal", updatedDeal);
      commit("updateIndex", { customFields, deal: updatedDeal });
    } catch (err) {
      $notifyDanger("Failed to update deal");
      throw err;
    }
  },

  async patchDeal(
    { commit, dispatch, rootGetters }: IContext,
    { dealId, updates },
  ) {
    try {
      updates = cloneDeep(updates);
      const customFields =
        rootGetters["clientDashboard/customFields/byObjectType"]("deal");

      if (updates.custom_data) {
        serializeCustomData(updates.custom_data, customFields);
      }
      const updatedDeal = await api.patchDeal(dealId, updates);
      deserializeCustomData(updatedDeal.custom_data, customFields);

      commit("saveDeal", updatedDeal);
      commit("updateIndex", { customFields, deal: updatedDeal });
    } catch (err) {
      $notifyDanger("Failed to update deal");
      throw err;
    }
  },

  async deleteDeal(
    { commit, dispatch, rootGetters }: IContext,
    dealId: number,
  ) {
    try {
      await api.deleteDeal(dealId);
      dispatch("load");
      dispatch("clientDashboard/orgUsers/load", null, { root: true });
    } catch (err) {
      $notifyDanger("Failed to delete deal");
      throw err;
    }
  },

  // fixme: add to iface + cover with tests
  async restoreDeal(
    { commit, dispatch, rootGetters }: IContext,
    dealId: number,
  ) {
    try {
      await api.restoreDeal(dealId);
      dispatch("load");
      dispatch("clientDashboard/orgUsers/load", null, { root: true });
    } catch (err) {
      $notifyDanger("Failed to delete deal");
      throw err;
    }
  },

  async restoreRoom({ dispatch }: IContext, deal: Deal) {
    try {
      await api.restoreRoom(deal.id);
      dispatch("load");
    } catch (err) {
      $notifyDanger("Failed to reopen room");
      throw err;
    }
  },

  async deleteRoom({ dispatch }: IContext, deal: Deal) {
    try {
      await api.deleteRoom(deal.id);
      dispatch("load");
    } catch (err) {
      $notifyDanger("Failed to delete room");
      throw err;
    }
  },

  async updateContacts(
    { commit }: IContext,
    { dealId, usersIdsToAdd, usersIdsToRemove }: UpdateContactsPayload,
  ) {
    try {
      const updatedContactsIds = await api.bulkUpdateContacts(
        dealId,
        usersIdsToAdd,
        usersIdsToRemove,
      );

      commit("setContactsOfDeal", {
        dealId,
        contactsIds: updatedContactsIds,
      } as SetContactsOfDealPayload);
    } catch (err) {
      $notifyDanger("Failed to update contacts list");
      throw err;
    }
  },

  async postDealAttachment(
    { dispatch },
    {
      dealId,
      file,
      controller,
      fileUploadedHook,
      fileAttachedHook,
    }: PostDealAttachmentsPayload,
  ) {
    try {
      const orgFile = await api.uploadFile(file, {
        signal: controller.signal,
      });
      fileUploadedHook?.(orgFile);

      await api.attachFiles(dealId, { file_id: orgFile.id });
      fileAttachedHook?.(orgFile);

      await dispatch("refreshById", dealId);
      return orgFile;
    } catch (e: any) {
      $notifyDanger(`Failed to upload attachments (${e.message})`);
      throw e;
    }
  },

  async removeDealAttachment({ dispatch }, { dealId, fileId }) {
    try {
      await api.deleteFile(dealId, fileId);
      await dispatch("refreshById", dealId);
    } catch (e) {
      $notifyDanger("Failed to delete attachment");
      throw e;
    }
  },

  getAttachmentDownloadPath(_, { dealId, fileId }) {
    try {
      return api.retrieveFilePath(dealId, fileId);
    } catch (e) {
      $notifyDanger("Failed to download attachment");
      throw e;
    }
  },
} as DealsActionTree;
