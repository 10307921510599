import "./documents.js";
import "./categories.js";

import HeatmapCardController from "./index.ctrl.js";
import analyticsCardHeatmapHtml from "./index.html?raw";

angular
  .module("dealroom.analytics.card.heatmap", [
    "dealroom.analytics.card.heatmap.documents",
    "dealroom.analytics.card.heatmap.categories",

    "dealroom.analytics.heatmap",
    "dealroom.analytics.card.chart.header",

    "dealroom.analytics.factory.dispatcher",
    "dealroom.analytics.service.data",

    "dealroom.members",
  ])
  .component("drAnalyticsHeatmapCard", {
    template: analyticsCardHeatmapHtml,
    bindings: {
      name: "@",
      yLabel: "@",
      nestBy: "@",
      verb: "@",
      statsTypes: "<",
      getYKeys: "<",
      getDKey: "<",
      getDescendants: "<",
      getTotalValues: "<",
      getYLabel: "<",
      filterBy: "@",
    },
    controller: HeatmapCardController,
  });
