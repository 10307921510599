<template>
  <div class="groups-control">
    <ElSelect
      ref="select"
      :model-value="selectModel"
      :disabled="selectAll"
      multiple
      reserve-keyword
      clearable
      popper-class="el-select-dropdown--groups"
      :placeholder="placeholder"
      :no-data-text="noDataText"
      class="el-select--full-width"
      @remove-tag="(groupId) => toggleGroup(groupId, false)"
      @clear="areSomeGroupsSelected = false"
    >
      <div class="groups-control__item">
        <ElCheckbox
          v-model="areSomeGroupsSelected"
          class="groups-control__checkbox"
          :indeterminate="isIndeterminate"
        >
          All groups ({{ groups.length }})
        </ElCheckbox>
      </div>

      <Group
        v-for="g of groups"
        :key="g.id"
        :group="g"
        :state="groupsState[g.id]"
        :members="groupsMembers[g.id]"
        class="groups-control__item"
        @toggle-group="(val) => toggleGroup(g.id, val)"
        @update-members="(members) => updateGroupMembers(g.id, members)"
      />

      <ElOption
        v-for="o in selectOptions"
        :key="o.key"
        :label="o.label"
        :value="o.id"
        style="display: none"
      />
    </ElSelect>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Group from "@drVue/components/client-dashboard/deals/GroupsToCopy/Group.vue";

import type { GroupCopyState } from "./types";
import type { RoomGroup } from "@drVue/store/modules/client-dashboard/deals/types";
import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { Dictionary } from "@drVue/types";
import type { PropType } from "vue";

interface MemberToCopy {
  user_id: string;
}

interface GroupToCopy {
  group_id: number;
  members: MemberToCopy[];
}

export default defineComponent({
  name: "GroupsToCopy",
  components: { Group },
  props: {
    modelValue: { required: true, type: Array as PropType<GroupToCopy[]> },
    roomId: {
      required: false,
      type: Number as PropType<number | null>,
      default: null,
    },
    selectAll: {
      required: false,
      default: false,
      type: Boolean as PropType<boolean>,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    groupsState(): Dictionary<GroupCopyState> {
      const state: Dictionary<GroupCopyState> = {};
      for (const group of this.groups) {
        state[group.id] = {
          selected: false,
          members: [],
        };
      }
      for (const groupData of this.modelValue) {
        state[groupData.group_id] = {
          selected: true,
          members: groupData.members.map(
            (userData: MemberToCopy) => userData.user_id,
          ),
        };
      }
      return state;
    },
    groups(): RoomGroup[] {
      const dictionary = this.$store.state.clientDashboard.deals.groups;
      return Object.keys(dictionary)
        .map((k) => dictionary[k])
        .filter((g) => g.room_id === this.roomId);
    },
    groupsMembers(): Dictionary<OrgUser[]> {
      const members: Dictionary<OrgUser[]> = {};
      for (const group of this.groups) {
        members[group.id] = this.$store.getters[
          "clientDashboard/deals/getUsersOfGroup"
        ](group.id).filter((u: OrgUser) => !u.pending);
      }
      return members;
    },
    noDataText(): any {
      if (this.roomId) {
        return "No groups found";
      } else {
        return "Select a room first";
      }
    },
    placeholder(): any {
      if (this.roomId) {
        return "Select groups";
      } else {
        return "Select a room first";
      }
    },
    selectModel(): number[] {
      return this.modelValue.map((d) => d.group_id);
    },
    selectOptions(): any {
      return this.groups.map((g) => {
        const state = this.groupsState[g.id];
        let tag = "";
        if (state.members.length) {
          tag += ` (${state.members.length} member${
            state.members.length > 1 ? "s" : ""
          })`;
        }
        return {
          id: g.id,
          key: `group_${g.id}`,
          label: `${g.name}${tag}`,
        };
      });
    },
    areSomeGroupsSelected: {
      get: function (): boolean {
        const selectedGroups = this.groups.filter(
          (g) => this.groupsState[g.id].selected,
        );

        return selectedGroups.length > 0;
      },
      set: function (val: boolean | null) {
        const isAllSelected = !!val;

        for (const group of this.groups) {
          this.toggleGroup(group.id, isAllSelected);
        }
      },
    },
    isIndeterminate: function (): boolean {
      const selectedGroups = this.groups.filter(
        (g) => this.groupsState[g.id].selected,
      );

      return !(
        selectedGroups.length === 0 ||
        selectedGroups.length === this.groups.length
      );
    },
  },
  watch: {
    selectAll: {
      immediate: true,
      handler(value: boolean) {
        if (!value) {
          return;
        }
        this.selectAllGroupsAndMembers();
      },
    },
    roomId(value: null | number) {
      if (value === null) {
        return;
      }

      if (this.selectAll) {
        this.selectAllGroupsAndMembers();
      }
    },
  },
  methods: {
    syncStateToValue() {
      const newValue: GroupToCopy[] = [];
      for (const [groupIdStr, groupState] of Object.entries(this.groupsState)) {
        if (!groupState.selected) {
          continue;
        }
        newValue.push({
          group_id: parseInt(groupIdStr),
          members: groupState.members.map((userId) => ({ user_id: userId })),
        });
      }
      this.$emit("update:modelValue", newValue);
    },
    toggleGroup(groupId: number, isSelected: boolean) {
      const state = this.groupsState[groupId];
      state.selected = isSelected;
      state.members = isSelected
        ? this.groupsMembers[groupId].map((m) => m.id)
        : [];
      this.syncStateToValue();
    },
    updateGroupMembers(groupId: number, members: string[]) {
      const state = this.groupsState[groupId];
      state.members = members;
      this.syncStateToValue();
    },
    selectAllGroupsAndMembers() {
      for (const group of this.groups) {
        this.toggleGroup(group.id, true);
      }
    },
  },
});
</script>
