<template>
  <div :class="$style.wrapper">
    <DrDatepicker
      ref="datePicker"
      :model-value="[modelValue[0] || '', modelValue[1] || '']"
      :type="type"
      @update:model-value="handleDateChanged"
    />
    <DrToolbarFilterButton
      :label="label"
      :is-active="isActive"
      @click="openDatePicker"
      @clear="clearDate"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";

import { DrDatepicker } from "../dr-datepicker";
import DrToolbarFilterButton from "./DrToolbarFilterButton.vue";

interface Props {
  modelValue: [Date, Date] | [];
  label: string;
  /** @note preparation for the future */
  type?: "daterange";
}
const props = withDefaults(defineProps<Props>(), {
  type: "daterange",
});

const emit = defineEmits<{
  (event: "update:modelValue", value: Props["modelValue"]): void;
}>();

const isActive = computed(() => !!props.modelValue[0] && !!props.modelValue[1]);

/**
 * @todo using InstanceType<typeof DrDatePicker> not work :(
 *       using InstanceType<typeof ElDatePicker> also not work:
 *       throw ts error `Property 'handleOpen' does not exist on type....`
 */
const datePicker = ref<{ handleOpen: () => void } | null>(null);
const openDatePicker = () => {
  datePicker.value?.handleOpen();
};
const handleDateChanged = (value: [Date, Date]) => {
  emit("update:modelValue", value);
};
const clearDate = () => {
  emit("update:modelValue", []);
};
</script>

<style lang="scss" module>
.wrapper {
  position: relative;

  :global(.el-date-editor.el-range-editor) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    opacity: 0;
  }
}
</style>
