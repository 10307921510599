<template>
  <div>
    <ElPopover
      :teleported="false"
      :width="330"
      placement="auto"
      trigger="click"
    >
      <template #reference>
        <div :class="$style.toggle">
          <DrIcon size="sm" :name="isPublic ? 'lock-open' : 'lock'" />
          <DrTruncatedTextTooltip :content="selectedViewersText">
            {{ selectedViewersText }}
          </DrTruncatedTextTooltip>
          <DrIcon size="sm" name="caret-down" :class="$style.toggleCaret" />
        </div>
      </template>

      <div :class="$style.menu">
        <div :class="$style.menuTitle">Select who can view this comment:</div>

        <ElSwitch
          :model-value="isPublic"
          active-text="Open: all groups with access"
          @change="togglePublic"
        />
        <ElSwitch
          :model-value="isMyGroupOnly"
          active-text="My group only"
          @change="toggleMyGroup"
        />

        <template v-if="viewersGroups.length">
          <div :class="$style.groupsTitle">
            Send private comment to other groups:
          </div>
          <ElCheckbox
            v-for="group in groupsExceptMy"
            :key="group.id"
            :model-value="isGroupSelected(group.id)"
            @change="toggleGroup(group.id)"
          >
            {{ group.name }}
          </ElCheckbox>
        </template>
      </div>
    </ElPopover>
  </div>
</template>

<script setup lang="ts">
import { pull } from "lodash-es";
import { computed, watch } from "vue";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import { MY_GROUP_ONLY_LABEL } from "./constants";

import type { CommentPermissions, CommentViewersGroup } from "./types";

interface Props {
  viewersGroups: CommentViewersGroup[];
  viewers: CommentViewersGroup["id"][];
  userGroupId: CommentViewersGroup["id"];
  isPublic?: boolean;
}

interface Emits {
  (event: "change", payload: CommentPermissions): void;
}

const props = withDefaults(defineProps<Props>(), {
  isPublic: false,
});

const emit = defineEmits<Emits>();

const isMyGroupOnly = computed(
  () =>
    !props.isPublic &&
    props.viewers.length === 1 &&
    props.viewers[0] === props.userGroupId,
);

const groupsExceptMy = computed(() =>
  props.viewersGroups.filter(({ id }) => id !== props.userGroupId),
);

const groupsSelectedExceptMy = computed(() =>
  props.viewersGroups.filter(({ id }) => {
    return id !== props.userGroupId && props.viewers.includes(id);
  }),
);

const selectedViewersText = computed(() => {
  if (props.isPublic) {
    return "Open";
  }

  if (isMyGroupOnly.value) {
    return MY_GROUP_ONLY_LABEL;
  }

  const viewers = groupsSelectedExceptMy.value.map(({ name }) => name).sort();
  return `to ${viewers.join(", ")}`;
});

const updateViewers = (is_public: boolean, viewers: Props["viewers"]) => {
  // user group is always must be in selected groups (viewers)
  if (!viewers.includes(props.userGroupId)) {
    viewers.push(props.userGroupId);
  }
  emit("change", { is_public, viewers });
};

const isGroupSelected = (id: CommentViewersGroup["id"]) =>
  !props.isPublic && props.viewers.includes(id);

const togglePublic = () => {
  if (props.isPublic) {
    updateViewers(false, [props.userGroupId]);
  } else {
    updateViewers(true, []);
  }
};

const toggleGroup = (id: CommentViewersGroup["id"]) => {
  const viewers = [...props.viewers];

  if (isGroupSelected(id)) {
    pull(viewers, id);
  } else {
    viewers.push(id);
  }

  updateViewers(false, viewers);
};

const toggleMyGroup = () => {
  if (isMyGroupOnly.value) {
    updateViewers(true, []);
  } else {
    updateViewers(false, [props.userGroupId]);
  }
};

const checkViewers = () => {
  const groupIds = props.viewersGroups.map(({ id }) => id);
  const notAllowedViewers = props.viewers.filter(
    (id) => !groupIds.includes(id),
  );

  if (notAllowedViewers.length) {
    updateViewers(
      props.isPublic,
      props.viewers.filter((id) => !notAllowedViewers.includes(id)),
    );
  }
};

watch(
  () => props.viewersGroups,
  (groups) => {
    if (groups.length) checkViewers();
  },
  {
    immediate: true,
  },
);
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.toggle {
  display: inline-flex;
  align-items: center;
  gap: spacing.$xxs;
  height: 28px;
  padding: 5px;
  overflow: hidden;
  max-width: 220px; // !important; // important to overwrite .truncate
  cursor: pointer;
  border: solid 1px colors.$pr-300;
  border-radius: 8px;
  color: colors.$pr-500;
}

.toggleCaret {
  color: colors.$pr-350;
}

.menu {
  display: flex;
  flex-direction: column;

  :global(.el-checkbox) {
    height: 28px;
  }
}

.menuTitle {
  padding-bottom: spacing.$s;
  margin-bottom: spacing.$s;
  border-bottom: 1px solid colors.$pr-200;
}

.groupsTitle {
  padding-top: spacing.$s;
  font: typography.$caption_regular;
  color: colors.$pr-500;
}
</style>
