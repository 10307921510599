import type { Category } from "@drVue/store/pinia/room/categories";
import type { Task } from "@drVue/store/pinia/room/tasks";

export const getItemType = (item: Task | Category) =>
  "status_id" in item ? "task" : "category";

export const isTask = (
  item: Task | Category | null | undefined,
): item is Task => !!item && getItemType(item) === "task";

export const isCategory = (
  item: Task | Category | null | undefined,
): item is Category => !!item && getItemType(item) === "category";
