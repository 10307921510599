import type { GroupsState } from "./GroupsState";
import type { RootState } from "@drVue/store/state";
import type { GetterTree } from "vuex";

export const groupsGetters: GetterTree<GroupsState, RootState> = {
  groupsWithCategoryAccess: (state, getters) => (categortId: number) => {
    return state.pgroupsList.filter((pg) =>
      pg.viewable_categories_ids.includes(categortId),
    );
  },
};
