<template>
  <DefaultViewFieldLayout :view-props="viewProps">
    <template #label>
      {{ viewProps.schema.label }}
    </template>
    <template #control>
      <div v-if="text">{{ text }}</div>
      <div v-else>-</div>
    </template>
  </DefaultViewFieldLayout>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import { drUserTime } from "@drVue/filters/drUserTime";
import DefaultViewFieldLayout from "./DefaultViewFieldLayout.vue";

import type { ViewFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";

interface Props {
  viewProps: ViewFieldProps;
}

const props = defineProps<Props>();
const text = computed(() => {
  const type = props.viewProps.schema.type;
  if (type !== FieldSchemaType.Date && type !== FieldSchemaType.DateTime) {
    throw new Error(`Invalid type ${type} for Date View Field`);
  }

  if (type === FieldSchemaType.Date) {
    return drUserTime(props.viewProps.entityValue, "full-date");
  } else {
    return drUserTime(props.viewProps.entityValue, "short-dt");
  }
});
</script>
