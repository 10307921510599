import { ApiService } from "../../index";

import type {
  DealTask,
  DealTaskCreatePayload,
  DealTaskUpdatePayload,
} from "./types";

type DealId = number;

export class DealTasksApiService extends ApiService {
  public load(dealId: DealId) {
    const url =
      this.Urls["api:client-dashboard:deals-detail-tasks-list"](dealId);
    return this.get<DealTask[]>(url);
  }

  public create(dealId: DealId, payload: DealTaskCreatePayload) {
    const url =
      this.Urls["api:client-dashboard:deals-detail-tasks-list"](dealId);
    return this.post<DealTask>(url, payload);
  }

  public update(
    dealId: DealId,
    taskId: DealTask["id"],
    payload: DealTaskUpdatePayload,
  ) {
    const url = this.Urls["api:client-dashboard:deals-detail-tasks-detail"](
      dealId,
      taskId,
    );
    return this.patch<DealTask>(url, payload);
  }

  public remove(dealId: DealId, taskId: DealTask["id"]): Promise<void> {
    const url = this.Urls["api:client-dashboard:deals-detail-tasks-detail"](
      dealId,
      taskId,
    );
    return this.delete(url);
  }

  /**
   * List of selected organization's deal tasks the current user is assigned to.
   * @returns
   */
  public loadAllDeals() {
    const url = this.Urls["api:client-dashboard:your-work-deal-tasks"]();
    return this.get<DealTask[]>(url);
  }
}
