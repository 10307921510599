<template>
  <div>
    <ElButton size="small" @click="showDialog">MFA Recovery</ElButton>
    <ElDialog
      v-model="isDialogVisible"
      title="MFA Recovery Codes"
      @close="hideDialog"
    >
      <SimpleTabNav
        :tabs="tabsList"
        :active-tab="activeTab"
        @tab-click="activeTab = $event"
      />
      <br />
      <MFARecoveryRequestConfirmation
        v-if="activeTab === Tabs.Confirm"
        :user-id="userId"
      />
      <MFARecoveryLoadCodes v-if="activeTab === Tabs.Load" :user-id="userId" />
    </ElDialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SimpleTabNav } from "@shared/ui";

import MFARecoveryLoadCodes from "./MFARecoveryLoadCodes.vue";
import MFARecoveryRequestConfirmation from "./MFARecoveryRequestConfirmation.vue";

import type { PropType } from "vue";

enum Tabs {
  Confirm = "confirm",
  Load = "load",
}

interface Data {
  isDialogVisible: boolean;
  activeTab: string;
  tabsList: string[];
  Tabs: any;
}

export default defineComponent({
  name: "MfaRecoveryCodesDialog",
  components: {
    SimpleTabNav,
    MFARecoveryLoadCodes,
    MFARecoveryRequestConfirmation,
  },
  props: {
    userId: { required: true, type: Number as PropType<number> },
  },
  data(): Data {
    return {
      isDialogVisible: false,
      activeTab: Tabs.Confirm,
      tabsList: [Tabs.Confirm, Tabs.Load],
      Tabs: Tabs,
    };
  },
  methods: {
    showDialog() {
      this.isDialogVisible = true;
    },
    hideDialog() {
      this.isDialogVisible = false;
    },
  },
});
</script>
