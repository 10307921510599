<template>
  <ElPopover
    trigger="click"
    :persistent="false"
    :popper-class="$style.roomsPopover"
    :popper-options="{
      modifiers: [
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['top', 'bottom', 'left', 'right'],
          },
        },
      ],
    }"
    :width="363"
  >
    <ElTabs class="el-tabs--dropdown">
      <ElTabPane :label="`Rooms (${memberships.length})`">
        <div :class="$style.scroller">
          <div :class="$style.description">
            <span v-if="memberships.length === 0">
              There are no rooms where the person is a member
            </span>
            <span v-else>Rooms where the person is a member</span>
          </div>

          <span
            v-for="m in memberships"
            :key="m.key"
            :class="$style.row"
            href=""
            @click.prevent="openDealDetails(m.dealId)"
          >
            <RoomLogo :logo-url="m.dealLogo" :title="m.dealTitle" />
            {{ m.dealTitle }}
          </span>
        </div>
      </ElTabPane>
      <ElTabPane :label="`Deals (${contacts.length})`">
        <div :class="$style.scroller">
          <div :class="$style.description">
            <span v-if="contacts.length === 0">
              There are no deals where the person is a contact
            </span>
            <span v-else>Deals where the person is a contact</span>
          </div>

          <span
            v-for="c in contacts"
            :key="c.key"
            :class="$style.row"
            href=""
            @click.prevent="openDealDetails(c.dealId)"
          >
            <RoomLogo :logo-url="c.dealLogo" :title="c.dealTitle" />
            {{ c.dealTitle }}
          </span>
        </div>
      </ElTabPane>
    </ElTabs>

    <template #reference>
      <span :class="$style.roomsRef">
        <span>{{ roomsLabel }}</span>
        <i class="fas fa-caret-down" :class="$style.caretIcon" />
      </span>
    </template>
  </ElPopover>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import RoomLogo from "@drVue/components/client-dashboard/rooms/RoomLogo.vue";
import DrStore from "@drVue/store";

import type {
  ContactInfo,
  MembershipInfo,
} from "@drVue/components/client-dashboard/users/UsersTable/types";
import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";
import type { PropType } from "vue";

export default defineComponent({
  components: {
    RoomLogo,
  },
  props: {
    memberships: {
      required: true,
      type: Array as PropType<MembershipInfo[]>,
    },
    contacts: { required: true, type: Array as PropType<ContactInfo[]> },
  },
  computed: {
    dealsUids(): string[] {
      const set = new Set() as Set<string>;
      this.memberships.forEach((m) => set.add(m.dealUid));
      this.contacts.forEach((c) => set.add(c.dealUid));
      return Array.from(set.values());
    },
    roomsLabel(): string {
      if (this.dealsUids.length === 0) return "No deals";
      const dealMap = this.$store.getters[
        "clientDashboard/deals/getDealsMap"
      ] as Map<string, Deal>;
      const dealUid =
        this.dealsUids[(this.dealsUids.length * Math.random()) | 0];

      const deal = dealMap.get(dealUid)!;
      return this.dealsUids.length === 1
        ? `${deal.title}`
        : `${deal.title} & ${this.dealsUids.length - 1} more`;
    },
  },
  methods: {
    openDealDetails(dealId: number) {
      const deal = DrStore.getters["clientDashboard/deals/getDealById"](dealId);
      if (deal) {
        DrStore.commit("clientDashboard/deals/detailsDialog", {
          visible: true,
          dealUid: deal.uid,
        });
      }
    },
  },
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

.roomsPopover {
  max-height: 350px;
  overflow-y: auto;
  padding: 0 !important;
}

.roomsRef {
  :global(.fa-caret-down) {
    display: inline-block;
    color: colors.$pr-400;
    margin-left: 5px;
  }

  &:hover {
    text-decoration: underline;
    :global(.fa-caret-down) {
      color: colors.$pr-600;
    }
  }
}

.caretIcon {
  display: inline-block;
  margin-left: 5px;
}

.row {
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 10px;
  align-items: center;
  padding: 6px 20px;
  color: colors.$pr-800;

  &:last-of-type {
    margin-bottom: 15px;
  }

  &:hover {
    background-color: colors.$pr-100;
    cursor: pointer;
  }
}

.description {
  color: colors.$pr-500;
  word-break: keep-all;
  padding: 0;
  margin: 15px 20px 10px 20px;
  text-align: left;
}

.scroller {
  height: 100%;
  overflow-y: auto;
  max-height: 301px;
}
</style>
