<template>
  <DrLayoutContent destroy-aside-on-close :class="$style.container">
    <template #panel>
      <DrNavTree
        title="Synergies"
        root-title="All synergies"
        add-tooltip="Create synergy"
        :tree="synergiesNav"
        :current-id="currentSynergyId"
        :draggable="false"
        :disallow-add="!isAbleToEditSynergyOrValueDriver"
        @select="handleSynergySelect"
        @add="handleSynergyAdd"
      >
        <template
          v-if="isAbleToEditSynergyOrValueDriver"
          #item-action="{ item, setActive }"
        >
          <ElDropdown
            trigger="click"
            @command="(cmd) => handleItemMenu(cmd, item.id)"
            @visible-change="setActive(item.id, $event)"
          >
            <DrIcon name="ellipsis-h" size="sm" />
            <template #dropdown>
              <ElDropdownMenu>
                <ElDropdownItem
                  v-for="menuItem in menu"
                  :key="menuItem.id"
                  :command="menuItem.id"
                  :divided="menuItem.divided"
                >
                  {{ menuItem.label }}
                </ElDropdownItem>
              </ElDropdownMenu>
            </template>
          </ElDropdown>
        </template>
      </DrNavTree>
    </template>
    <template #toolbar-right>
      <ElButton
        type="primary"
        @click="openCreateValueDriverPanel"
        v-if="isAbleToEditSynergyOrValueDriver"
      >
        New value driver
      </ElButton>
    </template>
    <template #default>
      <DrVxeGrid
        :data="tableStore.rows"
        :span-method="colspanMethod"
        :columns="tableColumns.columns"
        :sort-config="{ remote: true, trigger: 'cell' }"
        :row-config="{ height: 38 }"
        :row-class-name="getRowClassName"
        @sort-change="handleSortChange"
        @cell-click="handleCellClick"
        @resizable-change="onColumnResized"
      />
    </template>
  </DrLayoutContent>
  <CreateEditSynergyDialog ref="createEditSynergyDialogRef" />
  <ValueDriverCreatePanel ref="createValueDriverPanelRef" />
  <ValueDriverDetailsPanel ref="valueDriverDetailsPanelRef" />
</template>

<script lang="ts" setup>
import { toDate } from "date-fns-tz";
import { ElMessageBox } from "element-plus";
import { computed, onMounted, ref, useCssModule } from "vue";
import DrIcon from "@shared/ui/dr-icon";
import { DrLayoutContent } from "@shared/ui/dr-layouts";
import { DrNavTree, type NavTreeItem } from "@shared/ui/dr-nav";
import DrVxeGrid from "@shared/ui/dr-vxe-grid/DrVxeGrid.vue";

import { ROOM_DATA, ROOM_MEMBER_DATA } from "@setups/data";
import { TaskFieldAccessType } from "@setups/enums";
import { type CustomViewColumn, CustomViewObjectTypes } from "@setups/types";
import { insightTrack, RoomSynergiesEvent } from "@app/insight";
import { DrStore } from "@app/vue";
import { $notifyDanger, $notifySuccess } from "@drVue/common";
import ValueDriverDetailsPanel from "@drVue/components/room/synergies/ValueDriverDetailsPanel.vue";
import { saveColumnsConfig } from "@drVue/components/room/tasks/TaskOverviewPage/viewUtils";
import { useSynergiesStore } from "@drVue/store/modules/room/synergies/synergies";
import {
  type SynergyRow,
  useSynergiesTableStore,
} from "@drVue/store/modules/room/synergies/table";
import { useValueDriversStore } from "@drVue/store/modules/room/synergies/value-drivers";
import { pinia } from "@drVue/store/pinia";
import CreateEditSynergyDialog from "./CreateEditSynergyDialog.vue";
import TableColumns from "./SynergiesTable/tableColumns";
import ValueDriverCreatePanel from "./ValueDriverCreatePanel.vue";

import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { Synergy } from "@drVue/store/modules/room/synergies/SynergiesApiService";
import type { SynergyTableRow } from "@drVue/store/modules/room/synergies/table";
import type { VxeTableEvents, VxeTablePropTypes } from "vxe-table";

const createEditSynergyDialogRef = ref<InstanceType<
  typeof CreateEditSynergyDialog
> | null>(null);
const createValueDriverPanelRef = ref<InstanceType<
  typeof ValueDriverCreatePanel
> | null>(null);
const valueDriverDetailsPanelRef = ref<InstanceType<
  typeof ValueDriverDetailsPanel
> | null>(null);

const synergiesStore = useSynergiesStore(pinia);
const valueDriversStore = useValueDriversStore(pinia);
const tableStore = useSynergiesTableStore(pinia);

const currentSynergyId = ref<string>();

const synergiesNav = computed((): NavTreeItem[] =>
  synergiesStore.list.map((s) => ({
    id: s.id,
    name: s.title,
  })),
);

const isAbleToEditSynergyOrValueDriver = computed(
  () => ROOM_MEMBER_DATA.group.synergies_access === TaskFieldAccessType.Edit,
);

const viewColumns = computed((): CustomViewColumn[] => {
  const view = DrStore.getters["common/customViews/defaultView"](
    CustomViewObjectTypes.Synergy,
  );

  return view.settings.columns;
});

type MenuItem = {
  id: string;
  label: string;
  divided: boolean;
  shown: boolean;
};

const menu: MenuItem[] = [
  {
    id: "rename",
    label: "Rename",
    divided: false,
    shown: true,
  },
  {
    id: "delete",
    label: "Delete",
    divided: false,
    shown: true,
  },
];

const handleSortChange: VxeTableEvents.SortChange<SynergyTableRow> = ({
  field,
  order,
}) => {
  tableStore.setSortConfig({
    field,
    order,
  });
};

const handleSynergySelect = (id: string | number) => {
  if (id === "root") {
    tableStore.unsetActiveSynergyId();
    currentSynergyId.value = undefined;
  } else {
    tableStore.setActiveSynergyId(id as Synergy["id"]);
    currentSynergyId.value = id as Synergy["id"];
  }
};

const handleSynergyAdd = () => {
  createEditSynergyDialogRef.value?.open();
};

const handleCellClick: VxeTableEvents.CellClick<SynergyTableRow> = ({
  row,
}) => {
  if (row.type === "synergy") return;

  valueDriverDetailsPanelRef.value?.open(row.id);
};

const driverCustomFields = DrStore.getters[
  "clientDashboard/customFields/byObjectType"
]("value_driver") as FieldItem[];

const dataCustomFields = DrStore.getters[
  "clientDashboard/customFields/byObjectType"
]("value_driver_period_data") as FieldItem[];

const tableColumns = new TableColumns(
  viewColumns,
  valueDriversStore,
  driverCustomFields,
  dataCustomFields,
);

const getIntervalsCount = () => {
  if (!ROOM_DATA.synergySettings) {
    throw new Error("Synergy settings are not defined");
  }

  const INTERVAL = ROOM_DATA.synergySettings.interval;
  const SYNERGIES_FROM = toDate(ROOM_DATA.synergySettings.from_date);
  const SYNERGIES_TO = toDate(ROOM_DATA.synergySettings.to_date);
  const interval = INTERVAL === "month" ? 1 : 3;

  let intervalsCount = 0;
  for (
    let i = SYNERGIES_FROM;
    i <= SYNERGIES_TO;
    i.setMonth(i.getMonth() + interval)
  ) {
    intervalsCount++;
  }

  return intervalsCount;
};

// 2 is `Title` and `Enabled by` default columns.
const colspan =
  2 + driverCustomFields.length + dataCustomFields.length * getIntervalsCount();

const colspanMethod: VxeTablePropTypes.SpanMethod<any> = (params) => {
  // $columnIndex is global index, columnIndex is the local index.
  const { $columnIndex, row } = params;

  if (row.type === "synergy") {
    if ($columnIndex === 0) {
      return {
        rowspan: 1,
        colspan,
      };
    } else {
      return {
        rowspan: 1,
        colspan: 0,
      };
    }
  }

  return { rowspan: 1, colspan: 1 };
};

const handleItemMenu = (cmd: string, id: string | number) => {
  switch (cmd) {
    case "rename": {
      createEditSynergyDialogRef.value?.open(id as Synergy["id"]);
      break;
    }
    case "delete": {
      const synergy = synergiesStore.dict[id as Synergy["id"]];

      ElMessageBox({
        type: "warning",
        title: "Delete synergy",
        message: `Are you sure you want to delete the synergy "${synergy.title}"?`,
        confirmButtonText: "Delete",
        showCancelButton: true,
        showClose: true,
        cancelButtonText: "No",
        closeOnHashChange: false,
        closeOnPressEscape: false,
      })
        .then(() => {
          synergiesStore.remove(id as Synergy["id"]).then(
            () => {
              $notifySuccess("Synergy has been deleted");
              insightTrack(RoomSynergiesEvent.Deleted);
            },
            () => $notifyDanger("Failed to delete synergy"),
          );
        })
        .catch(() => {});

      break;
    }
  }
};

const openCreateValueDriverPanel = () => {
  createValueDriverPanelRef.value?.open({
    synergyId: currentSynergyId.value,
  });
};

const $styles = useCssModule();
const getRowClassName: VxeTablePropTypes.RowClassName<SynergyTableRow> = ({
  row,
}) => (row.type === "synergy" ? $styles.synergy : "");

const onColumnResized: VxeTableEvents.ResizableChange<SynergyRow> = ({
  column,
}) => {
  const update: CustomViewColumn = {
    field: column.field,
    width: column.resizeWidth,
  };

  saveColumnsConfig(DrStore, [update], CustomViewObjectTypes.Synergy);
};

onMounted(() => {
  synergiesStore.load();
  valueDriversStore.load();
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/values";

.container {
  height: calc(100vh - #{values.$header-height});

  :global(.vxe-table.border--full .vxe-header--column) {
    background-color: white !important;
  }
}

.synergy {
  background-color: colors.$pr-50;
}
</style>
