<template>
  <!-- @vue-skip -->
  <span v-if="!item">
    {{ unknownLabels[type] || "Unknown item" }}
  </span>

  <MentionItemContentWrapper
    v-else-if="type === MentionTypes.Task || type === MentionTypes.User"
    :url="url"
  >
    <span v-if="type === MentionTypes.Task" class="dr-mention__task-info">
      <span :class="item.status.colorClass" :title="item.status.name">
        #{{ item.key }}
      </span>
      {{ item.title }}
    </span>

    <UserInformation
      v-else
      :user="item"
      :is-link="false"
      :show-tooltip="true"
      size="16px"
      avatar-font-size="7px"
    />
  </MentionItemContentWrapper>

  <span v-else>Invalid item</span>
</template>

<script setup lang="ts">
import { UserInformation } from "@shared/ui/users";

import MentionItemContentWrapper from "./MentionItemContentWrapper.vue";
import { MentionTypes } from "./providers";

import type { IMentionItem } from "./providers";

interface Props {
  item?: IMentionItem;
  type: MentionTypes;
  url?: string;
}

withDefaults(defineProps<Props>(), {
  item: undefined,
  url: undefined,
});

const unknownLabels = {
  [MentionTypes.Task]: "Unknown Request",
  [MentionTypes.User]: "Anonymous",
};
</script>
