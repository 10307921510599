angular
  .module("dealroom.analytics.service.filter.members", [
    "dealroom.analytics.service.data",
    "dealroom.members",
  ])
  .service("ActivityFilterMembersService", ActivityFilterMembersService);

ActivityFilterMembersService.$inject = [
  "MembersService",
  "ActivityGroupPermissionsVisibleService",
];
function ActivityFilterMembersService(
  MembersService,
  ActivityGroupPermissionsVisibleService,
) {
  const service = {
    isMemberVisible,
  };

  return service;

  function isMemberVisible(d) {
    const member = MembersService.members[d.user];
    if (member.is_archived === true) return false;

    const pgroupId = member.pgroup.id;
    const isPgroupVisible =
      ActivityGroupPermissionsVisibleService.isVisible(pgroupId);
    return isPgroupVisible;
  }
}
