<template>
  <ElForm ref="form" label-position="top" :rules="rules" :model="formModel">
    <ElAlert
      v-if="getBackendError('non_field_errors')"
      class="auth-form__alert"
      :title="getBackendError('non_field_errors')"
      type="error"
      show-icon
      :closable="false"
    />

    <ElFormItem
      label="Current password"
      prop="oldpassword"
      :error="getBackendError('oldpassword')"
    >
      <ElInput v-model="formModel.oldpassword" type="password" />
    </ElFormItem>

    <ElFormItem
      label="New password"
      prop="password1"
      :error="getBackendError('password1')"
    >
      <ElInput v-model="formModel.password1" type="password" />
    </ElFormItem>

    <ElFormItem
      label="New password confirmation"
      prop="password2"
      :error="getBackendError('password2')"
    >
      <ElInput v-model="formModel.password2" type="password" />
    </ElFormItem>

    <ElFormItem>
      <div class="text-right">
        <ElButton type="primary" @click="changePassword"
          >Change password
        </ElButton>
      </div>
    </ElFormItem>
  </ElForm>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrForm from "@shared/ui/dr-form";

import PasswordService from "@app/common/password-service";
import { ProfileApiService } from "@drVue/api-service/client-dashboard/profile";
import { $notifyDanger, $notifySuccess } from "@drVue/common";
import { ProfileService } from "@drVue/components/client-dashboard/users/AccountSettings/ProfileService";

import type { ElForm } from "element-plus";

const api = new ProfileApiService();

interface FormModel {
  oldpassword: string;
  password1: string;
  password2: string;
}

export interface Data {
  rules: any;
  formModel: FormModel;
  profileService: ProfileService;
}

export default defineComponent({
  name: "ChangePassword",
  extends: DrForm,
  data(): Data {
    const thany = this as any;

    return {
      rules: {
        oldpassword: [
          {
            required: true,
            trigger: "blur",
            message: "This field may not be blank.",
          },
        ],
        password1: [
          {
            required: true,
            trigger: "blur",
            message: "This field may not be blank.",
          },
          {
            validator: (
              rule: any,
              value: string,
              callback: (error?: Error | string) => void,
            ) => {
              // Was validated by 'required' rule above.
              if (!value || value.length === 0) callback();

              const result = PasswordService.test(value);
              if (result.errors.length > 0) {
                callback(result.errors.join("\n"));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
        password2: [
          {
            required: true,
            trigger: "blur",
            message: "This field may not be blank.",
          },
          {
            validator: (
              rule: any,
              value: string,
              callback: (error?: Error | string) => void,
            ) => {
              if (!value || value.length === 0) callback();

              if (thany.formModel.password1 === thany.formModel.password2) {
                callback();
              } else {
                callback("Passwords must match.");
              }
            },
            trigger: ["blur", "change"],
          },
        ],
      },
      formModel: {
        oldpassword: "",
        password1: "",
        password2: "",
      },
      profileService: new ProfileService(api),
    };
  },
  methods: {
    getForm() {
      return this.$refs.form as typeof ElForm;
    },
    changePassword() {
      this.getForm()
        .validate()
        .then(
          (isValid: boolean) => {
            if (!isValid) return;

            this.profileService.changePassword(this.formModel).then(
              () => {
                $notifySuccess("Your password has been successfully changed");

                this.formModel.oldpassword = "";
                this.formModel.password1 = "";
                this.formModel.password2 = "";
              },
              () =>
                $notifyDanger(
                  "Failed to change your password, please try again",
                ),
            );
          },
          () => {},
        );
    },
  },
});
</script>
