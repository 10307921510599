import { USER_DATA } from "@setups/data";

export enum INITIAL_DATA_TYPES {
  Blank = "blank",
  Template = "template",
  CopyRoom = "copy_room",
}

export function getCreateAllowedClients() {
  return USER_DATA.org_memberships
    .filter((uc) => uc.group.can_create_deals)
    .map((uc) => uc.client);
}
