<template>
  <div class="auth-form auth-form--set">
    <div class="auth-form__title text-center">Create new password</div>

    <div v-if="hasCheckFailed">
      <ElAlert
        class="auth-form__alert"
        type="warning"
        show-icon
        :closable="false"
      >
        Your password reset link has expired
      </ElAlert>
    </div>

    <ElForm
      v-if="!hasCheckFailed"
      ref="form"
      :rules="rules"
      :model="model"
      @submit.prevent="set"
    >
      <ElAlert
        v-if="getBackendError('non_field_errors')"
        class="auth-form__alert"
        :title="getBackendError('non_field_errors')"
        type="error"
        show-icon
        :closable="false"
      />

      <ElFormItem
        class="el-form-item--password el-form-item--error-message-align-top"
        prop="password1"
        :error="getBackendError('password1')"
      >
        <ElInput
          v-model="model.password1"
          :disabled="isSubmitting"
          placeholder="Enter password"
          size="large"
          show-password
          autocomplete="new-password"
        />
      </ElFormItem>

      <ElFormItem
        class="el-form-item--password el-form-item--tall el-form-item--error-message-align-top"
        prop="password2"
        :error="getBackendError('password2')"
      >
        <ElInput
          v-model="model.password2"
          :disabled="isSubmitting"
          placeholder="Re-enter password"
          size="large"
          show-password
          autocomplete="new-password"
        />
      </ElFormItem>

      <ElButton
        type="primary"
        native-type="submit"
        class="auth-form__submit"
        size="large"
        :disabled="isSubmitting"
      >
        Set new password
      </ElButton>
    </ElForm>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrForm from "@shared/ui/dr-form";

import PasswordService from "@app/common/password-service";
import { AuthApiService } from "@drVue/components/auth/AuthApiService";

import type { ElForm } from "element-plus";

interface FormModel {
  password1: string;
  password2: string;
}

interface Data {
  api: AuthApiService;
  rules: any;
  hasCheckFailed: boolean;
  model: FormModel;
}

export default defineComponent({
  name: "Reset",
  extends: DrForm,
  data(): Data {
    const thany = this as any;

    return {
      api: new AuthApiService(),
      rules: {
        password1: [
          {
            required: true,
            message: "This field may not be blank.",
            trigger: "blur",
          },
          {
            validator: (
              rule: any,
              value: string,
              callback: (error?: Error | string) => void,
            ) => {
              // Treat it as valid if there is no value.
              if (!value || value.length === 0) callback();

              const result = PasswordService.test(value);
              if (result.errors.length > 0) {
                callback(result.errors.join("\n"));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
        password2: [
          {
            required: true,
            message: "Please re-enter password.",
            trigger: "blur",
          },
          {
            validator: (
              rule: any,
              value: string,
              callback: (error?: Error | string) => void,
            ) => {
              if (!value || value.length === 0) callback();

              if (thany.model.password1 === thany.model.password2) {
                callback();
              } else {
                callback("Passwords must match.");
              }
            },
            trigger: ["blur", "change"],
          },
        ],
      },
      hasCheckFailed: false,
      model: {
        password1: "",
        password2: "",
      },
    };
  },
  beforeMount() {
    const check = {
      ...this.$route.params, // { uidb36: number, key: string }
      key_check_only: true,
    };

    const setPromise = this.api.password_set(check);
    this.submitPromise(setPromise).catch(() => (this.hasCheckFailed = true));
  },
  methods: {
    getForm(): typeof ElForm {
      return this.$refs["form"] as typeof ElForm;
    },
    set() {
      const request = {
        ...this.$route.params, // { uidb36: number, key: string }
        ...this.model,
      };

      this.getForm()
        .validate()
        .then(() => {
          const setPromise = this.api.password_set(request);
          this.submitPromise(setPromise).then(() => {
            this.isSubmitting = true;
            location.href = this.api.home_url;
          });
        });
    },
  },
});
</script>
