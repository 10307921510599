<template>
  <ElButton size="small" @click="handleOpenDialog">Add Admin to Org</ElButton>
  <ElDialog v-model="isDialogVisible" title="Add Admin to Org">
    <SimpleTabNav
      :active-tab="activeTab"
      :tabs="['request', 'confirm']"
      @tab-click="handleSwitchTab($event)"
    />
    <ElForm
      v-if="activeTab === 'request'"
      ref="requestFormRef"
      v-loading="isFormSubmitting"
      label-position="top"
      :class="$style.container"
      :model="formModel"
    >
      <ElFormItem required label="Email" prop="email">
        <ElInput v-model="formModel.email" placeholder="admin@example.com" />
      </ElFormItem>
      <div v-if="token" :class="$style.token">
        <code>{{ token }}</code>
        <ElButton text size="small" @click="() => copyToClipboard(token)">
          <template #icon>
            <DrIcon name="copy" />
          </template>
        </ElButton>
      </div>
    </ElForm>
    <ElForm
      v-if="activeTab === 'confirm'"
      ref="confirmFormRef"
      v-loading="isFormSubmitting"
      label-position="top"
      :class="$style.container"
      :model="formModel"
    >
      <ElFormItem required label="Email" prop="email">
        <ElInput v-model="formModel.email" placeholder="admin@example.com" />
      </ElFormItem>
      <ElFormItem required label="Token" prop="token">
        <ElInput v-model="formModel.token" placeholder="abc123" />
      </ElFormItem>
      <ElAlert
        v-if="formErrors.non_field_errors"
        :title="formErrors.non_field_errors"
        type="error"
        show-icon
        :closable="false"
      />
    </ElForm>
    <ElFormItem>
      <ElButton type="primary" @click="submit">Submit</ElButton>
      <ElButton @click="handleCloseDialog">Cancel</ElButton>
    </ElFormItem>
  </ElDialog>
</template>

<script setup lang="ts">
import { ElForm } from "element-plus";
import { ref } from "vue";
import { useFormHelper } from "@shared/ui/dr-form";
import DrIcon from "@shared/ui/dr-icon/DrIcon.vue";
import SimpleTabNav from "@shared/ui/simple-tabnav/index.vue";

import { ManagementApiService } from "@drVue/api-service/management";
import { $notifyDanger, $notifySuccess, copyToClipboard } from "@drVue/common";

type Tab = "request" | "confirm";

interface Props {
  clientId: number;
}
const props = defineProps<Props>();
const emit = defineEmits(["change"]);

const { formErrors, hookFormSubmitPromise, isFormSubmitting } = useFormHelper();
const requestFormRef = ref<InstanceType<typeof ElForm> | null>(null);
const confirmFormRef = ref<InstanceType<typeof ElForm> | null>(null);
const handleReset = () => {
  requestFormRef.value?.resetFields();
  confirmFormRef.value?.resetFields();
};

const activeTab = ref<Tab>("request");
const handleSwitchTab = (tab: Tab) => {
  activeTab.value = tab;
  handleReset();
};

const api = new ManagementApiService();

const isDialogVisible = ref(false);
const handleOpenDialog = () => {
  isDialogVisible.value = true;
};
const handleCloseDialog = () => {
  isDialogVisible.value = false;
};

const formModel = ref({ email: "", token: "" });
const token = ref("");

const submit = async () => {
  switch (activeTab.value) {
    case "request":
      await submitRequestToAdd(formModel.value.email);
      handleReset();
      break;
    case "confirm":
      await submitConfirm(formModel.value.email, formModel.value.token);
      handleReset();
      break;
    default:
      throw new Error("Unknown tab");
  }
  emit("change");
};
const submitRequestToAdd = async (email: string) => {
  try {
    const resp = await hookFormSubmitPromise(
      api.confirmAddOrgAdmin(props.clientId, { email }),
    );
    token.value = resp.token;
    $notifySuccess("Request to add new admin has been sent");
  } catch {
    $notifyDanger("Failed to request to add new admin");
  }
};
const submitConfirm = async (email: string, token: string) => {
  try {
    await hookFormSubmitPromise(
      api.addOrgAdmin(props.clientId, { email, token }),
    );
    handleCloseDialog();
    $notifySuccess("New admin has been confirmed");
  } catch {
    $notifyDanger("Failed to confirm new admin");
  }
};
</script>

<style module lang="scss">
.container {
  padding: 20px 0;
}

.token {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
}
</style>
