import { ElMessageBox } from "element-plus";

import { ROOM_DATA } from "@setups/data";
import { DocumentsTreeService } from "@app/ng/documents/services/DocumentsTreeService";
import getMaxNestedPathsLengths from "@app/ng/documents/services/helpers/getMaxNestedPathsLengths";
import processDocuments from "@app/ng/documents/services/helpers/processDocuments";
import restoreExpandedFoldersState from "@app/ng/documents/services/helpers/restoreExpandedFoldersState";
import sortFolderChildren from "@app/ng/documents/services/helpers/sortFolderChildren";
import {
  type FolderMenuParams,
  getFolderMenuEntityTitle,
  getFolderMenuSelectedText,
} from "@app/vue/components/room/documents/utils";
import { $notifyDanger, $notifySuccess } from "@drVue/common";
import { DocumentsApiService, isFolder } from "./DocumentsApiService";

import type {
  ArchiveItemsPayload,
  BookmarkItemsPayload,
  Document,
  Folder,
} from "./DocumentsApiService";
import type { DocumentsState } from "./DocumentsState";
import type { RootState } from "@drVue/store/state";
import type { ActionContext, ActionTree } from "vuex";

export type IContext = ActionContext<DocumentsState, RootState>;

const api = new DocumentsApiService();

let loadingPromise: Promise<void>;

export const documentsActions: ActionTree<DocumentsState, RootState> = {
  syncTree(
    { state, commit, dispatch }: IContext,
    skipErrorAlert: boolean,
  ): Promise<void> {
    if (state.isLoading) return loadingPromise;

    commit("setIsLoading", true);
    commit("setIsError", false);

    loadingPromise = api.getTree().then(
      (tree) => {
        const data = processDocuments(tree.Folders, tree.Files);

        sortFolderChildren(data.rootFolder);
        restoreExpandedFoldersState(data.folders, state.folders);

        commit("setState", {
          rootFolder: data.rootFolder,

          folders: data.folders,
          files: data.files,
          foldersByUid: data.foldersByUid,
          filesByUid: data.filesByUid,

          foldersList: tree.Folders,
          filesList: tree.Files,
        });

        DocumentsTreeService.setRootFolder(data.rootFolder);
        dispatch("updateMaxNestedPathsLengths");

        commit("setIsLoading", false);
        commit("setIsError", false);
      },
      (error) => {
        commit("setIsLoading", false);
        commit("setIsError", true);
        if (!skipErrorAlert) {
          $notifyDanger("Failed to update documents.");
        }
        return Promise.reject(error);
      },
    );

    return loadingPromise;
  },
  updateMaxNestedPathsLengths({ state, commit }: IContext) {
    if (state.rootFolder === null) return;

    const pathLengths = getMaxNestedPathsLengths(
      state.rootFolder,
      ROOM_DATA.title.length,
    );
    commit("updateMaxNestedPathsLengths", pathLengths);
  },

  bookmarkItems({ state }: IContext, payload: BookmarkItemsPayload) {
    return api
      .bookmarkItems(payload)
      .then((response) => {
        const bookmarkValue = payload.set_bookmarked ? new Date() : null;

        response.folder_ids.forEach((folderId) => {
          const folder = state.folders[folderId];
          folder.bookmarked = bookmarkValue;
        });

        response.document_ids.forEach((documentId) => {
          const doc = state.files[documentId];
          doc.bookmarked = bookmarkValue;
        });
      })
      .catch(() => {
        $notifyDanger(
          `Failed to ${payload.set_bookmarked ? "bookmark" : "unbookmark"} the selected items.`,
        );
      });
  },

  toggleBookmark({ dispatch }: IContext, item: Folder | Document) {
    const payload: BookmarkItemsPayload = {
      folder_ids: [],
      document_ids: [],
      set_bookmarked: !item.bookmarked,
    };

    if (isFolder(item)) {
      payload.folder_ids.push(item.id);
    } else {
      payload.document_ids.push(item.id);
    }

    return dispatch("bookmarkItems", payload);
  },

  archiveItems({ dispatch }: IContext, params: FolderMenuParams) {
    const selectedText = getFolderMenuSelectedText(params);
    const pluralisedAction =
      params.documents.length + params.folders.length === 1 ? "has" : "have";

    return new Promise<void>((resolve) => {
      ElMessageBox({
        title: `Delete ${getFolderMenuEntityTitle(params)}`,
        message: `Are you sure you want to delete ${selectedText}?`,
        confirmButtonText: "Delete",
        customClass: "el-message-box--warning",
        showCancelButton: true,
        icon: undefined,
        callback: async (action: string) => {
          if (action === "confirm") {
            await api
              .archiveItems({
                document_ids: params.documents.map((item) => item.id),
                folder_ids: params.folders.map((item) => item.id),
              } satisfies ArchiveItemsPayload)
              .then((response) => {
                dispatch("syncTree");

                $notifySuccess(
                  `The ${selectedText} ${pluralisedAction} been deleted.`,
                );

                return response;
              })
              .catch(() => {
                $notifyDanger(`Failed to delete ${selectedText}.`);
              });
          }

          resolve();
        },
      });
    });
  },
};
