import { $notifyDanger } from "@drVue/common";
import { NotificationsApiService } from "@drVue/store/modules/common/notifications/api";

import type { State } from "./state";
import type { RootState } from "@drVue/store/state";
import type { ActionContext, ActionTree } from "vuex";

export type IContext = ActionContext<State, RootState>;

const api = new NotificationsApiService();

interface NotificationsActionTree extends ActionTree<State, RootState> {
  loadNotifications(
    { state, commit, dispatch }: IContext,
    payload: {
      reloadAll: boolean;
      roomId?: number;
    },
  ): Promise<void>;
  markAllAsRead({ dispatch }: IContext): Promise<void>;
  markAsRead(
    { commit, dispatch }: IContext,
    data: { noticeId: number },
  ): Promise<void>;
  markAsUnread(
    { commit, dispatch }: IContext,
    payload: { noticeId: number },
  ): Promise<void>;
}

export const actions: NotificationsActionTree = {
  async loadNotifications(
    { state, commit, dispatch }: IContext,
    payload: {
      reloadAll: boolean;
      roomId?: number;
    },
  ) {
    if (payload.reloadAll) commit("resetState");

    commit("setIsLoading", true);

    try {
      const r = await api.loadNotifications(state.nextUrl, payload.roomId);

      commit("addNotifications", r.results);
      commit("setNextUrl", r.next);
    } catch (error) {
      await $notifyDanger("Failed to load notifications from server");
      throw error;
    } finally {
      commit("setIsLoading", false);
    }
  },

  async markAllAsRead({ dispatch }: IContext) {
    try {
      await api.markAllAsRead();
    } catch (error) {
      await $notifyDanger("Failed to mark all notifications as read");
      throw error;
    }
  },

  async markAsRead({ commit, dispatch }: IContext, data: { noticeId: number }) {
    commit("setIsLoading", true);

    try {
      await api.markAsRead(data.noticeId);
    } catch (error) {
      await $notifyDanger("Failed to mark a notification as read");
      throw error;
    } finally {
      commit("setIsLoading", false);
    }
  },

  async markAsUnread(
    { commit, dispatch }: IContext,
    payload: { noticeId: number },
  ) {
    commit("setIsLoading", true);

    try {
      await api.markAsUnread(payload.noticeId);
    } catch (error) {
      await $notifyDanger("Failed to mark a notification as unread");
      throw error;
    } finally {
      commit("setIsLoading", false);
    }
  },
};
