import "./groups";
import "./tasks";
import "./base";

angular.module("dealroom.analytics.card.linechart", [
  "dealroom.analytics.card.linechart.groups",
  "dealroom.analytics.card.linechart.tasks",

  "dealroom.analytics.card.linechart.base",
]);
