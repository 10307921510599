import { format, isDate, isValid, parseISO } from "date-fns";
import { toDate, toZonedTime } from "date-fns-tz";

import { USER_DATA } from "@setups/data";

import type { Dictionary } from "@drVue/types";

const PREDEFINED_FORMATS: Dictionary<string> = {
  "full-dt": "MMM dd yyyy, h:mma",
  "short-dt": "MMM dd, h:mma",
  "pipe-dt": "MMM dd '|' h:mma",
  "full-date": "MMM dd, yyyy",
  "short-date": "MMM d",
  "short-date-reverse": "d MMM",
  "iso-date": "yyyy-MM-dd",
  "short-time": "h:mma",
};

export function isISODateString(val: any) {
  return typeof val === "string" && /\d{4}-\d{2}-\d{2}/.test(val);
}

export function drUserTime(
  value: any,
  formatString: string = "short-date",
): string | null {
  if (PREDEFINED_FORMATS[formatString]) {
    formatString = PREDEFINED_FORMATS[formatString];
  }

  if (value === undefined) return null;
  if (value === null) return null;
  if (value === "") return null;
  if (isDate(value) && !isValid(value)) return null;

  const tz = USER_DATA.profile.timezone;
  const date = isDate(value)
    ? value
    : isISODateString(value)
      ? toDate(value, { timeZone: tz })
      : typeof value === "number"
        ? new Date(value)
        : parseISO(value);

  if (!isValid(date)) return null;

  const zonedTime = toZonedTime(date, tz);
  return format(zonedTime, formatString);
}
