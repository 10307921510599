export enum OrganizationMemberLookerAccess {
  Disabled = "disabled",
  Viewer = "viewer",
  Editor = "editor",
}

export enum PaymentTypes {
  Finance = "finance",
  Free = "free",
  StripeClient = "stripe_client",
  StripeRoom = "stripe_room",
  PipelineOnly = "pipeline_only",
}

export enum SubscriptionPlanTypes {
  Fixed = "fixed",
  Storage = "storage",
  LookerViewer = "looker_viewer",
  LookerEditor = "looker_editor",
}

export enum OrganizationLookerAccess {
  Disabled = "disabled",
  Basic = "basic",
  Advanced = "advanced",
}

export enum OrganizationGroupDealAccessType {
  All = "all",
  RoomAdmin = "room_admin",
  ScopeCriteria = "scope_criteria",
}

export enum TaskFieldAccessType {
  NoAccess = "no_access",
  View = "view",
  Edit = "edit",
}

export enum AiAccess {
  NO_ACCESS = "no_access",
  BASIC = "basic",
}
