<template>
  <div v-if="isError" :class="$style.placeholder">
    <span :class="$style.placeholderText">ERROR</span>
    <ElButton text>
      <span>Try again</span>
      <template #icon>
        <DrIcon size="xs" name="rotate-right" />
      </template>
    </ElButton>
  </div>
  <CommentsList
    v-else
    ref="commentsListRef"
    :ready="isReady"
    :items="comments"
    :task-id="taskId"
    :task-category-id="categoryId"
    :last-visit-date="lastVisitDate"
    :has-manager-access="hasManagerAccess"
    @refresh-list="handleRefreshList"
    @update-last-visit="$emit('update-last-visit')"
  />
</template>

<script setup lang="ts">
import { ref } from "vue";
import { DrIcon } from "@shared/ui/dr-icon";

import CommentsList from "../../../comments/CommentList.vue";

import type { TaskComment } from "@drVue/store/modules/room/types";

interface Props {
  categoryId: number;
  comments: TaskComment[];
  hasManagerAccess: boolean;
  isError: boolean;
  isReady: boolean;
  lastVisitDate: Date | null;
  taskId: number;
}
const props = defineProps<Props>();
const emit = defineEmits(["refresh-list", "update-last-visit"]);
const handleRefreshList = () => {
  emit("refresh-list");
};
const commentsListRef = ref();
defineExpose({ list: commentsListRef });
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography.scss";

.placeholder {
  color: colors.$sc-600;
  font: typography.$caption_medium;
  width: 548px;
  height: 512px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.placeholderText {
  margin-bottom: 10px;
}
</style>
