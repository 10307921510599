<template>
  <DrPopup :visible="visibility" paddingless>
    <template #reference>
      <Comments
        :unapproved-comments-count="editProps.entity.unapproved_comments_count"
        :comments-count="editProps.entity.comments_count"
        :new-comments-count="editProps.entity.new_comments_count"
        @click.stop="editProps.quitEditMode"
      />
    </template>
    <template #default>
      <CommentsEditContent
        ref="commentsEditRef"
        :last-visit-date="editProps.entity.last_visit_date"
        :has-manager-access="editProps.extra.hasManagerAccess"
        :task-id="editProps.entity.id"
        :category-id="editProps.entity.category_id"
        :comments="comments"
        :is-error="isError"
        :is-ready="isReady"
        @refresh-list="loadComments"
        @update-last-visit="handleUpdateLastVisit"
      />
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { ref, unref, watch } from "vue";
import { DrPopup } from "@shared/ui/dr-popups";

import { insightTrack, TasksTableTrackingEvent } from "@app/insight";
import { pinia } from "@app/vue/store/pinia";
import { $notifyDanger } from "@drVue/common";
import Comments from "@drVue/components/room/tasks/TasksTable/cells/comments/Comments.vue";
import { useVisibility } from "@drVue/components/room/tasks/TasksTable/cells/useVisibilty";
import { TasksApiService, useTasksStore } from "@drVue/store/pinia/room/tasks";
import CommentsEditContent from "./CommentsEditContent.vue";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { TaskComment } from "@drVue/store/modules/room/types";

interface Props {
  editProps: EditFieldProps;
}

const props = defineProps<Props>();

const isError = ref(false);
const isReady = ref(false);

const tasksStore = useTasksStore(pinia);

const api = new TasksApiService();
const comments = ref<TaskComment[]>([]);
const loadComments = async () => {
  try {
    comments.value = await api.loadComments(props.editProps.entity.id);
    isReady.value = true;
  } catch {
    isError.value = true;
    $notifyDanger("Something went wrong while fetching the comments");
  }
};
const handleUpdateLastVisit = () => {
  insightTrack(TasksTableTrackingEvent.CommentModalOpened);
  return tasksStore.updateTaskLastVisitDate(props.editProps.entity.id);
};

const visibility = useVisibility();
watch(visibility, (isVisible) => {
  if (isVisible) {
    loadComments();
    setTimeout(() => {
      // Child component will be ready on the next tick
      unref(commentsEditRef)?.list.composer.focus();
    });
  }
});

const commentsEditRef = ref<InstanceType<typeof CommentsEditContent> | null>(
  null,
);
</script>
