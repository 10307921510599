<template>
  <ElSelect
    ref="selectRef"
    clearable
    multiple
    style="width: 100%"
    :persistent="false"
    :model-value="editProps.value"
    :disabled="editProps.schema.isReadOnly"
    :placeholder="editProps.schema.placeholder"
    v-bind="editProps.veeField"
  >
    <template v-if="hasValidOptions">
      <ElOption
        v-for="opt in editProps.schema.extra.options"
        :key="opt"
        :label="opt"
        :value="opt"
      />
    </template>
  </ElSelect>
</template>

<script setup lang="ts">
import { ElSelect } from "element-plus";
import { computed, ref } from "vue";

import type { FieldProps } from "../types";

interface Props {
  editProps: FieldProps;
}

const props = defineProps<Props>();

const selectRef = ref<InstanceType<typeof ElSelect> | null>(null);

const hasValidOptions = computed(() => {
  const extra = props.editProps.schema.extra;
  return (
    extra &&
    Array.isArray(extra.options) &&
    typeof extra.options[0] === "string"
  );
});
</script>
