<template>
  <div v-if="status" :class="$style.container">
    <DrIconStatus :color="status.type" />
    <span>{{ status.name }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import DrIconStatus from "@shared/ui/dr-icon-status";

import {
  type ITableRow,
  TableRowTypes,
} from "@drVue/components/client-dashboard/users/UserDetails/RoomTasks/types";
import DrStore from "@drVue/store";

import type { TableCategoryRow } from "@drVue/components/client-dashboard/users/UserDetails/RoomTasks/types";

interface Props {
  row: ITableRow;
}

const props = defineProps<Props>();

const isCategoryRow = (row: ITableRow): row is TableCategoryRow =>
  row.type === TableRowTypes.Task;

const status = computed(() => {
  if (isCategoryRow(props.row)) return null;

  return DrStore.getters["room/tasksStatuses/byId"](props.row.task.status_id);
});
</script>

<style module lang="scss">
.container {
  display: inline-flex;
  gap: 6px;
  align-items: center;
}
</style>
