<template>
  <div>
    <div v-if="REDIRECT_ROOM.LOGO" class="auth-form__room-logo">
      <img :src="REDIRECT_ROOM.LOGO" :alt="`${REDIRECT_ROOM.TITLE} Logo`" />
    </div>
    <div class="auth-form__title text-center">
      <span v-if="isConfirmMFAStep">Two-factor authentication</span>
      <span v-else>
        {{ REDIRECT_ROOM.TITLE || "Login to your account" }}
      </span>
    </div>

    <div v-if="isEnterCredentialsStep" class="auth-form">
      <ElForm :disabled="isSubmitting" @submit.prevent="login">
        <ElFormItem :error="getBackendError('username')">
          <ElInput
            v-model="username"
            autocomplete="username"
            placeholder="Email"
            size="large"
          >
            <template #prepend>
              <i class="fal fa-envelope" />
            </template>
          </ElInput>
        </ElFormItem>

        <ElFormItem
          class="el-form-item--short"
          :error="getBackendError('password')"
        >
          <ElInput
            v-model="password"
            type="password"
            autocomplete="current-password"
            placeholder="Password"
            size="large"
          >
            <template #prepend>
              <i class="fal fa-lock" />
            </template>
          </ElInput>
        </ElFormItem>

        <ElAlert
          v-if="getBackendError('non_field_errors')"
          class="auth-form__alert"
          :title="getBackendError('non_field_errors')"
          type="error"
          show-icon
          :closable="false"
        />

        <ElRow>
          <ElCol :span="12">
            <ElFormItem class="el-form-item--short">
              <ElCheckbox v-model="remember">Keep me logged in</ElCheckbox>
            </ElFormItem>
          </ElCol>
          <ElCol :span="12" class="text-right">
            <RouterLink
              v-if="!isSubmitting"
              class="auth-form__forgot"
              to="/reset"
            >
              <u>Forgot password?</u>
            </RouterLink>
          </ElCol>
        </ElRow>

        <ElButton
          type="primary"
          native-type="submit"
          class="auth-form__submit"
          size="large"
          :loading="isSubmitting"
          :disabled="isSubmitting"
        >
          Log in
        </ElButton>
      </ElForm>

      <div v-if="OPENID_INTEGRATIONS.length" class="sign-in-with">
        <div class="sign-in-with__or-text">
          <span>or log in with</span>
        </div>

        <div v-for="integration in OPENID_INTEGRATIONS" :key="integration.id">
          <ElButton
            class="sign-in-with__provider"
            :loading="isSubmitting"
            :disabled="isSubmitting"
            @click="signInWith(integration)"
          >
            <img
              v-if="integration.provider.logo"
              :src="integration.provider.logo"
              :alt="`${integration.title} Logo`"
            />
            <span>{{ integration.title }}</span>
          </ElButton>
        </div>
      </div>
      <div v-else class="text-center">
        <RouterLink
          v-if="!isSubmitting"
          class="auth-form__sign-in-with-email"
          to="/sso"
        >
          <u>Sign in with Single Sign On</u>
        </RouterLink>
      </div>
    </div>

    <div v-if="isConfirmMFAStep" class="auth-form">
      <p class="text-center">
        To continue you need to provide the access code from your authenticator
        or a recovery code if you have one.
      </p>

      <ElAlert
        v-if="getBackendError('non_field_errors')"
        class="auth-form__alert"
        :title="getBackendError('non_field_errors')"
        type="error"
        show-icon
        :closable="false"
      />

      <ElForm
        :disabled="isSubmitting"
        label-position="top"
        @submit.prevent="submitMFA"
      >
        <ElFormItem :error="getBackendError('interface')">
          <SimpleTabNav
            :tabs="tabs"
            :active-tab="activeTab"
            class="dash-content-panel-nav__grow"
            @tab-click="activeTab = $event"
          />
        </ElFormItem>

        <ElFormItem
          label="Verification code"
          class="el-form-item"
          :error="getBackendError('challenge')"
        >
          <!-- @vue-ignore -->
          <ElInput
            v-model="mfaConfirmRequest.challenge"
            autocomplete="one-time-code"
            placeholder="Enter code"
            size="large"
          />
        </ElFormItem>

        <div v-if="mfaActivationMessage">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="mfaActivationMessage" />
        </div>
        <br />

        <ElButton
          type="primary"
          native-type="submit"
          class="auth-form__submit"
          size="large"
          :loading="isSubmitting"
          :disabled="isSubmitting"
        >
          Log in
        </ElButton>
      </ElForm>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SimpleTabNav } from "@shared/ui";
import DrForm from "@shared/ui/dr-form";

import { REDIRECT_ROOM } from "@setups/data";
import { getCurrentClientSsoIntegrations } from "@drVue/api-service/client-dashboard";
import { AuthApiService } from "@drVue/components/auth/AuthApiService";
import { getRedirect } from "@drVue/components/auth/utils";

import type { OpenIDIntegration } from "@drVue/api-service/client-dashboard/open-id-providers";
import type {
  ConfirmMFARequest,
  MFAInterface,
} from "@drVue/components/auth/AuthApiService";
import type { RedirectRoom } from "@setups/data";

interface Data {
  REDIRECT_ROOM: RedirectRoom;
  api: AuthApiService;
  OPENID_INTEGRATIONS: OpenIDIntegration[];
  username: string;
  password: string;
  remember: boolean;
  isMFARequired: boolean;
  mfaInterfaces: MFAInterface[];
  mfaActivationMessage: string;
  mfaConfirmRequest: ConfirmMFARequest | null;
  validationErrorCodes: number[];
  tabs: string[];
  activeTab: string;
}

export default defineComponent({
  components: {
    SimpleTabNav,
  },
  extends: DrForm,
  data(): Data {
    return {
      REDIRECT_ROOM: REDIRECT_ROOM,
      api: new AuthApiService(),
      OPENID_INTEGRATIONS: getCurrentClientSsoIntegrations(),
      username: "",
      password: "",
      remember: false,
      isMFARequired: false,
      mfaInterfaces: [],
      mfaActivationMessage: "",
      mfaConfirmRequest: null,
      validationErrorCodes: [400, 403],
      tabs: [],
      activeTab: "",
    };
  },
  computed: {
    isEnterCredentialsStep(): boolean {
      return !this.mfaConfirmRequest;
    },
    isConfirmMFAStep(): boolean {
      return !!this.mfaConfirmRequest;
    },
    selectedMfaConfirmIface(): string {
      const interfaceKey = this.mfaConfirmRequest?.interface;
      if (!interfaceKey) {
        return "";
      }
      const mfaInterface = this.mfaInterfaces.find(
        (iface) => iface.interface === interfaceKey,
      );
      return mfaInterface ? mfaInterface.name : "";
    },
  },
  watch: {
    activeTab(activeTab: string) {
      const mfaInterface = this.mfaInterfaces.find((i) => i.name === activeTab);
      if (mfaInterface && this.mfaConfirmRequest) {
        this.mfaConfirmRequest.interface = mfaInterface.interface;
      }
    },
    "mfaConfirmRequest.interface"(mfaInterfaceKey: string) {
      const mfaInterface = this.mfaInterfaces.find(
        (i) => i.interface === mfaInterfaceKey,
      );

      this.mfaActivationMessage = "";
      if (this.mfaConfirmRequest) {
        this.mfaConfirmRequest.challenge = "";
      }
      this.setBackendErrors({});

      if (!mfaInterface) return;

      if (mfaInterface.requires_activation) {
        const activatePromise = this.api.activate_mfa(mfaInterfaceKey);
        this.submitPromise(activatePromise).then(
          (r) => (this.mfaActivationMessage = r.data.message),
          (r) => {
            // The MFA session has expired, the request is outdated.
            if (r.response.status === 401) {
              this.mfaConfirmRequest = null;
            }

            this.setBackendErrors(r.response.data);
          },
        );
      }
    },
  },
  beforeMount() {
    const _sign_in_with_error = this.$route.query._sign_in_with_error;
    if (!_sign_in_with_error) return;

    let ssoError: string = "";
    if (typeof _sign_in_with_error === "string") {
      ssoError = _sign_in_with_error;
    } else if (Array.isArray(_sign_in_with_error)) {
      const e = _sign_in_with_error.find((v) => !!v);
      ssoError = typeof e === "string" ? e : "";
    }

    ssoError = ssoError.trim();
    if (ssoError) {
      this.setBackendErrors({
        non_field_errors: [ssoError],
      });

      // Remove _sign_in_with_error query parameter.
      this.$router.replace({
        replace: true,
        query: {
          ...this.$route.query,
          _sign_in_with_error: undefined,
        },
      });
    }
  },
  methods: {
    signInWith(integration: OpenIDIntegration) {
      const redirectUrl = getRedirect(this.$route, this.api.home_url);

      const signInWithPromise = this.api.sign_in_with(integration, redirectUrl);
      this.submitPromise(signInWithPromise).then((r) => {
        this.isSubmitting = true;
        location.href = r.data.redirect_url;
      });
    },
    login() {
      this.setBackendErrors({});

      const loginPromise = this.api.login({
        username: this.username,
        password: this.password,
        remember: this.remember,
      });

      this.submitPromise(loginPromise).then(
        (r) => {
          if (r.data.mfa_required) {
            this.isMFARequired = true;
            this.mfaInterfaces = r.data.interfaces as MFAInterface[];

            this.tabs = this.mfaInterfaces.map((i) => i.name);
            this.activeTab = this.tabs[0];

            this.mfaConfirmRequest = {
              remember: this.remember,
              challenge: "",
              interface: this.mfaInterfaces[0].interface,
            };
          } else {
            this.isSubmitting = true;
            // allow backend to validate redirect and find next correct location
            location.reload();
          }
        },
        () => {},
      );
    },
    submitMFA() {
      if (this.mfaConfirmRequest === null) return;

      const confirmPromise = this.api.confirm_mfa(this.mfaConfirmRequest);
      this.submitPromise(confirmPromise).then(
        () => {
          this.isSubmitting = true;
          // allow backend to validate redirect and find next correct location
          location.reload();
        },
        (r) => {
          this.setBackendErrors(r.response.data);

          // The MFA session has expired, the request is outdated.
          if (r.response.status === 401) {
            this.mfaConfirmRequest = null;
          }
        },
      );
    },
  },
});
</script>
