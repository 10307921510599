<template>
  <ElPopover
    ref="popoverRef"
    trigger="click"
    placement="right"
    :offset="15"
    :popper-class="$style.panel"
  >
    <template #reference>
      <slot />
    </template>
    <template #default>
      <div :class="$style.panelBody">
        <div :class="$style.title">
          {{ title }}
        </div>

        <div :class="$style.description">
          {{ description }}
        </div>

        <div :class="$style.action">
          <ElButton type="primary" @click="activate">
            {{ activateBtnText }}
          </ElButton>
        </div>

        <div :class="$style.help">
          If you want to learn more about the product or need help, please reach
          out
          <a :href="`mailto:${billingInfo.supportEmail}`" target="_blank">
            {{ billingInfo.supportEmail }}
          </a>
        </div>
        <hr v-if="firstOverUsageItem" />
        <!-- @vue-skip -->
        <OverUsageLimitButton
          v-if="firstOverUsageItem"
          :plan-type="firstOverUsageItem.planType"
          :current-limit="firstOverUsageItem.currentLimit"
          :is-room-billing="billingInfo.isRoomBilling"
          :billing-object-id="billingInfo.billingObjectId"
        />
      </div>
    </template>
  </ElPopover>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import OverUsageLimitButton from "./OverUsageLimitButton.vue";

import type { MainMenuBillingInfo } from "./types";

type PopoverMock = {
  hide: () => void;
};

interface Props {
  billingInfo: MainMenuBillingInfo;
  websiteName: string;
}
const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["activate"]);

const popoverRef = ref<PopoverMock | null>(null);

const title = computed(() => {
  const info = props.billingInfo;
  if (info.subscriptionWarning) {
    return info.subscriptionWarning.title;
  }
  if (!info.isAccessible) {
    return "Your access is limited";
  }
  return "Billing Problem";
});

const description = computed(() => {
  const info = props.billingInfo;
  if (info.subscriptionWarning) {
    return info.subscriptionWarning.message;
  }
  if (info.hasBillingAccess) {
    return `Activate your subscription now to maintain your ${props.websiteName} access`;
  } else {
    return "Contact the owner to activate a subscription";
  }
});
const activateBtnText = computed(() => {
  const info = props.billingInfo;
  if (info.hasBillingAccess) {
    if (
      info.subscriptionWarning &&
      info.subscriptionWarning.action === "open_billing"
    ) {
      return "Open billing";
    }
    return "Activate subscription";
  } else {
    return "Request support help";
  }
});

const firstOverUsageItem = computed(() => {
  const info = props.billingInfo;
  const context = info.subscriptionWarning?.context;
  if (!context || !info.billingObjectId) {
    return null;
  }
  const planType = Object.keys(context)[0] as keyof typeof context;
  if (!planType) {
    return null;
  }
  return {
    planType,
    currentLimit: context[planType].quantity,
  };
});

const activate = () => {
  popoverRef.value?.hide();
  emit("activate");
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors" as colors;
@use "@app/styles/scss/typography" as typo;

.panel {
  &:global(.el-popper.el-popover) {
    --el-border-color-light: #{colors.$pr-900};
    --el-bg-color-overlay: #{colors.$pr-900};

    min-width: 400px;
    padding: 0;
    box-shadow: 0px 2px 38px rgba(0, 0, 0, 0.14);
  }
}

.panelBody {
  padding: 12px 16px 16px;
}

.title {
  font: typo.$title_semibold;
  color: colors.$pr-0;
  padding-bottom: 8px;
}

.description {
  font: typo.$body_regular;
  color: colors.$pr-350;
  word-break: normal;
  padding-bottom: 12px;
}

.action {
  padding-bottom: 12px;
}

.help {
  word-break: normal;
  color: colors.$pr-350;
}
</style>
