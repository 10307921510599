<template>
  <a :href="`mailto:${email}`" class="truncate" target="_blank">
    <i v-if="isIconShowed" class="fa fa-envelope" :class="$style.icon" />
    <span class="truncate">{{ email }}</span>
  </a>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { PropType } from "vue";

export default defineComponent({
  name: "UserEmail",
  props: {
    email: { required: true, type: String as PropType<string> },
    isIconShowed: { type: Boolean, default: true },
  },
});
</script>

<style module lang="scss">
.icon {
  margin-right: 5px;
}
</style>
