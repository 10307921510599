<template>
  <TasksTreeBrowser
    :model-value="modelValue"
    :expand-on-click-node="false"
    :check-strictly="true"
    :only-task-value="false"
    @update:model-value="onUpdateModelValue"
  >
    <template #reference="{ show }">
      <slot name="reference" :show="show" />
    </template>
  </TasksTreeBrowser>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import { TasksTreeBrowser } from "@drVue/components/room/tasks/shared/tasks-tree-browser";

import type { Category } from "@drVue/store/pinia/room/categories";
import type { Task } from "@drVue/store/pinia/room/tasks";
import type { FieldProps } from "@shared/ui/dr-dynamic-form/types";

interface TaskAndCategoryIds {
  tasks: { task_uid: Task["uid"] }[];
  categories: { category_uid: Category["uid"] }[];
}

type TaskOrCategoryId = Task["uid"] | Category["uid"];

interface Props {
  editProps: FieldProps<TaskAndCategoryIds>;
}

const props = defineProps<Props>();

const T_PREFIX = "task_";
const C_PREFIX = "cat_";

const modelValue = computed(() => {
  const value = props.editProps.value;

  return [
    ...value.tasks.map((task) => task.task_uid),
    ...value.categories.map((cat) => cat.category_uid),
  ];
});

const onUpdateModelValue = (value: TaskOrCategoryId[]) => {
  const result = value.reduce<TaskAndCategoryIds>(
    (acc, id) => {
      if (id.startsWith(T_PREFIX)) {
        acc.tasks.push({ task_uid: id });
      } else if (id.startsWith(C_PREFIX)) {
        acc.categories.push({ category_uid: id });
      }

      return acc;
    },
    { tasks: [], categories: [] },
  );

  props.editProps.veeField.onChange(result);
};
</script>
