import { createDictionary } from "@drVue/common";

import type { FindingStatus } from "../findings-statuses/types";
import type { FindingType } from "../findings-types/types";
import type { HEXColor } from "@drVue/utils/color";
import type { Node as ProsemirrorNode } from "@tiptap/pm/model";

export const LabelColor: Record<string, HEXColor> = {
  GREEN: "#E6F5E4",
  YELLOW: "#F9EACD",
  RED: "#FFDDDD",
};

export const FindingSeverityList = [
  {
    label: "Low",
    color: LabelColor.GREEN,
    value: 1,
  },
  {
    label: "Moderate",
    color: LabelColor.YELLOW,
    value: 2,
  },
  {
    label: "High",
    color: LabelColor.RED,
    value: 3,
  },
] as const;
export type FindingSeverityKey = (typeof FindingSeverityList)[number]["value"];
export type FindingSeverityValue = FindingSeverityKey | null;

export const FindingSeverityDict = FindingSeverityList.reduce((acc, item) => {
  acc[item.value] = item;
  return acc;
}, createDictionary<(typeof FindingSeverityList)[number]>());

export const FindingLikelihoodList = [
  {
    label: "Unlikely",
    color: LabelColor.GREEN,
    value: 1,
  },
  {
    label: "Likely",
    color: LabelColor.YELLOW,
    value: 2,
  },
  {
    label: "Very likely",
    color: LabelColor.RED,
    value: 3,
  },
] as const;
export type FindingLikelihoodKey =
  (typeof FindingLikelihoodList)[number]["value"];
export type FindingLikelihoodValue = FindingLikelihoodKey | null;

export const FindingLikelihoodDict = FindingLikelihoodList.reduce(
  (acc, item) => {
    acc[item.value] = item;
    return acc;
  },
  createDictionary<(typeof FindingLikelihoodList)[number]>(),
);

export type FindingParticipantUser = {
  user_id: string; // actually it is uid
};

export type FindingCategoryItem = { category_uid: string };

export type FindingTaskItem = { task_uid: string };

export type FindingDocument = {
  document_id: string;
};

export type FindingFolders = {
  folder_id: string;
};

export interface Finding {
  readonly id: string;
  readonly key: `${number}`;
  readonly added_by_id: string; // uid
  readonly date_added: Date;
  readonly resolved_by_id: null | string; // uid
  readonly date_resolved: null | Date;
  is_archived: boolean;
  title: string;
  type_id: FindingType["id"];
  status_id: FindingStatus["id"]; // uid
  tasks: FindingTaskItem[];
  categories: FindingCategoryItem[];
  severity: FindingSeverityValue;
  likelihood: FindingLikelihoodValue;
  description: ProsemirrorNode;
  mitigation_plan: ProsemirrorNode;
  actual_mitigation: ProsemirrorNode;
  assignees: FindingParticipantUser[];
  followers: FindingParticipantUser[];
  folders: FindingFolders[];
  documents: FindingDocument[];
  order: number;

  custom_data?: Record<string, any>;
}

export type FindingCreatePayload = Pick<
  Finding,
  | "title"
  | "type_id"
  | "status_id"
  | "description"
  | "mitigation_plan"
  | "actual_mitigation"
  | "custom_data"
> &
  Partial<Pick<Finding, "tasks" | "categories" | "severity" | "likelihood">>;

export type FindingUpdatePayload = Partial<
  Pick<
    Finding,
    | "type_id"
    | "status_id"
    | "tasks"
    | "categories"
    | "title"
    | "severity"
    | "likelihood"
    | "description"
    | "mitigation_plan"
    | "actual_mitigation"
    | "custom_data"
  >
>;

export type FindingAssigneesPayload = {
  add: FindingParticipantUser[];
  remove: FindingParticipantUser[];
};
export type FindingFollowersPayload = FindingAssigneesPayload;

export type FindingCategoriesPayload = {
  add: FindingCategoryItem[];
  remove: FindingCategoryItem[];
};

export type FindingTasksPayload = {
  add: FindingTaskItem[];
  remove: FindingTaskItem[];
};

export type FindingDocumentsPayload = {
  add: FindingDocument[];
  remove: FindingDocument[];
};

export type FindingFoldersPayload = {
  add: FindingFolders[];
  remove: FindingFolders[];
};

/** Findings` comments */
export type FindingComment = {
  readonly id: string;
  readonly finding_id: Finding["id"];
  readonly sender_id: Finding["added_by_id"]; // uid string
  readonly date_created: Date; // "string" parsed with deserializeDrDate
  readonly date_updated: Date; // "string" parsed with deserializeDrDate
  body: ProsemirrorNode;
};

export type FindingCommentCreatePayload = Pick<FindingComment, "body">;

export type FindingCommentUpdatePayload = Pick<FindingComment, "body">;

export type FindingReorderPayload = {
  direction: "insert_after" | "insert_before";
  reference: Finding["id"];
};
