import taskDetail_taskSelectHtml from "../../../templates/components/task-detail/task-select.html?raw";

export default function drTaskSelect() {
  return {
    template: taskDetail_taskSelectHtml,
    bindings: {
      selectedTask: "=",
    },
    controller: [
      "TasksService",
      "CategoriesService",
      function (TasksService, CategoriesService) {
        var $ctrl = this;
        $ctrl.Tasks = TasksService;

        $ctrl.taskGroupBy = function (task) {
          return CategoriesService.categories[task.category_id].full_path;
        };

        $ctrl.taskCategoriesOrder = function (groups) {
          groups.sort(function (group1, group2) {
            var cat1 =
              CategoriesService.categories[group1.items[0].category_id];
            var cat2 =
              CategoriesService.categories[group2.items[0].category_id];
            var cat1index = CategoriesService.categoriesList.indexOf(cat1);
            var cat2index = CategoriesService.categoriesList.indexOf(cat2);
            return cat1index - cat2index;
          });
          return groups;
        };

        $ctrl.selectedTaskCategoryPath = function () {
          if (!$ctrl.selectedTask) return;
          var category =
            CategoriesService.categories[$ctrl.selectedTask.category_id];
          return category.full_path;
        };
      },
    ],
  };
}
