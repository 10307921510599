TasksAttachmentsService.$inject = ["$injector", "$http", "URLS"];
import { differenceWith } from "lodash-es";

import { DrStore } from "@drVue";

export default function TasksAttachmentsService($injector, $http, URLS) {
  const service = {
    createAttachments: createAttachments,
    updateAttachments: updateAttachments,
    attachUploadBatchResult: attachUploadBatchResult,
  };

  function attachUploadBatchResult(task, result) {
    const documents = [];
    const folders = [];
    if (result.info.folders.length) {
      folders.push({ folder_id: result.info.folders[0].id });
    } else {
      if (!result.files[0]) {
        // file upload is failed/cancelled
        return;
      }
      documents.push({ document_id: result.files[0].data.id, page: null });
    }

    return this.createAttachments(task, folders, documents);
  }

  function request(task, createDocs, createFolders, deleteDocs, deleteFolders) {
    const request = {
      create: {
        documents: createDocs.map((i) => ({
          document_id: i.document_id,
          page: i.page || null,
        })),
        folders: createFolders.map((i) => ({ folder_id: i.folder_id })),
      },
      delete: {
        documents: deleteDocs.map((i) => ({
          document_id: i.document_id,
          page: i.page || null,
        })),
        folders: deleteFolders.map((i) => ({ folder_id: i.folder_id })),
      },
    };

    const url = URLS["api:room:task_attachments_bulk"](task.id);
    return $http.post(url, request).then((r) => {
      task.folders = r.data.folders;
      task.documents = r.data.documents;

      const TasksService = $injector.get("TasksService");
      DrStore.dispatch("room/tasksRelated/update", TasksService.tasksList);
    });
  }

  function createAttachments(task, folders, documents) {
    return request(task, documents, folders, [], []);
  }

  function updateAttachments(task, folders, documents) {
    const origDocs = task.documents;
    const origFolders = task.folders;

    const docsDiff = (a, b) => Number(a.document_id) === Number(b.document_id);
    const foldersDiff = (a, b) => Number(a.folder_id) === Number(b.folder_id);

    const createDocs = differenceWith(documents, origDocs, docsDiff);
    const deleteDocs = differenceWith(origDocs, documents, docsDiff);

    const createFolders = differenceWith(folders, origFolders, foldersDiff);
    const deleteFolders = differenceWith(origFolders, folders, foldersDiff);

    return request(task, createDocs, createFolders, deleteDocs, deleteFolders);
  }

  return service;
}
