import { parseDjDataJson } from "@setups/data";

import type { Dictionary } from "@drVue/types";

// scope -> human-readable scope description
export type ScopesDictionary = Dictionary<string>;
export type AuthorizeAppMember = {
  dr_org_user_id: string; // user_XXXX
  client_name: string;
};
interface OAuth2Application {
  name: string;
}

export interface OAuth2AuthorizeFatalError {
  // oauth2/custom code like “invalid_dealroom_account”
  error: string;
  description: string;
}

export interface OAuth2AuthorizeContext {
  fatal_error?: OAuth2AuthorizeFatalError;

  // undefined if oauth client_id is invalid
  application?: OAuth2Application;

  // allowed orgs; undefined if there is no one
  // (and the fatal_error will be set to indicate that)
  org_memberships?: AuthorizeAppMember[];

  // authorize form errors in dj format;
  // undefined if the fatal_error is defined
  form_errors?: { [field: string]: string[] };

  // scope -> human-readable scope description
  // undefined if the fatal_error is defined
  scopes?: ScopesDictionary;

  // a param set that must be passed to authorize endpoint
  // undefined if the fatal_error is defined
  authorize_endpoint_params?: Dictionary<string>;
}

export const OAUTH2_AUTHORIZATION_DATA: OAuth2AuthorizeContext =
  parseDjDataJson("oauth2-authorize-context", true);
