<template>
  <FindingsTypesTable
    :types="findingsTypeStore.list"
    @delete="onDelete"
    @edit="onEdit"
  />
  <FindingTypeEditOrCreatePanel
    v-model:shown="showEditModal"
    :finding-id="editingFindingId"
  />
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";

import { $notifyDanger, $notifySuccess } from "@drVue/common";
import { pinia } from "@drVue/store/pinia";
import { useFindingsTypesStore } from "@drVue/store/pinia/pipeline/findings-types";
import FindingsTypesTable from "./findingsTypesTable/findingsTypesTable.vue";
const findingsTypeStore = useFindingsTypesStore(pinia);
import { ElMessageBox } from "element-plus";

import FindingTypeEditOrCreatePanel from "@drVue/components/room/findings/settings/FindingTypeEditOrCreatePanel.vue";

onMounted(() => {
  findingsTypeStore.load();
});

const showEditModal = ref(false);
const editingFindingId = ref<string | null>(null);

const onEdit = (id: string) => {
  editingFindingId.value = id;
  showEditModal.value = true;
};

const onDelete = (id: string) => {
  const type = findingsTypeStore.dict[id];
  ElMessageBox({
    type: "warning",
    title: `Remove type "${type.name}"`,
    message: `Type of all findings with this type will be changed to another type. Are you sure want to remove type "${type.name}"?`,
    confirmButtonText: "Remove",
    showCancelButton: true,
    showClose: true,
    cancelButtonText: "No",
    beforeClose: (action, instance, done) => {
      if (action != "confirm") {
        done();
        return;
      }
      instance.confirmButtonLoading = true;
      findingsTypeStore
        .remove(type.id)
        .then(
          () => {
            $notifySuccess(`Finding type '${type.name}' was removed`);
            done();
          },
          (err) => {
            let msg = "Failed to remove selected type.";
            const backendRespError = err.response?.data?.non_field_errors;
            if (backendRespError) {
              msg = backendRespError.join(" ");
            }
            $notifyDanger(msg);
          },
        )
        .finally(() => {
          instance.confirmButtonLoading = false;
        });
    },
  }).catch(() => {});
};
</script>
