import {
  APP_SETTINGS,
  ORG_MEMBER_DATA,
  ROOM_DATA,
  USER_DATA,
} from "@setups/data";

import type { Dictionary } from "@drVue/types";
import type { Router } from "vue-router";

const isPendoEnabled = typeof pendo !== "undefined";

function getBasePage() {
  // https://www.notion.so/mascience/App-pages-structure-e7e996ddf3a04efaa6aae6fdb833e1d3
  return document.body.getAttribute("t-app") || "unknown";
}
const PAGE_BASE_APP = getBasePage();

function getPendoUrl() {
  // https://agent.pendo.io/advanced/location#pendolocationseturl
  const l = location;
  return `${l.origin + l.pathname}#/${PAGE_BASE_APP}${l.hash.slice(1)}`;
}

interface RouterContext {
  router: "init" | "ng" | "vue";
  route: string;
  href: string;
}

let routerContext: RouterContext = {
  router: "init",
  route: "init",
  href: getHref(),
};

function getHref() {
  return location.href.replace("https://", "").replace("http://", "");
}

function setupPendo() {
  const visitorId = USER_DATA?.uid;
  const accountId =
    ROOM_DATA.client?.uid ?? ORG_MEMBER_DATA.client?.uid ?? null;
  const name =
    ROOM_DATA.client?.public_name ??
    ORG_MEMBER_DATA.client?.public_name ??
    null;

  const options: Dictionary<any> = {
    visitor: {
      id: visitorId,
      site: APP_SETTINGS.WEBSITE.HOST,
      email: USER_DATA?.email,
      full_name: USER_DATA?.name,
      profile_job_title: USER_DATA?.profile?.title,
    },

    account: {
      id: accountId,
      site: APP_SETTINGS.WEBSITE.HOST,
      name: name,
    },

    location: {
      setUrl: getPendoUrl,
    },

    guides: {
      // Docs at https://agent.pendo.io/events#guide-events are outdated.
      // There are no "guideSeen" or "guideAdvanced" or any other Guide events.
      // That is why we use Guide Runtime https://agent.pendo.io/advanced/guide-runtime
      globalScripts: [
        {
          script: function (step: any, guide: any) {
            const thany = this as any; // this is a Guide Runtime

            thany.on("dismissed", () => {
              if (visibleGuides.has(guide.id)) {
                visibleGuides.get(guide.id)?.resolve(true);
                visibleGuides.delete(guide.id);
              }
            });
          },
        },
      ],
    },

    disablePersistence: true,
  };

  if (ROOM_DATA?.userPermissions?.administrator) {
    options.visitor.is_room_admin = true;
  }

  if (ORG_MEMBER_DATA?.group?.is_administrator) {
    options.visitor.is_pipeline_admin = true;
  }

  pendo.initialize(options);
}

export function init() {
  if (!isPendoEnabled) return;

  const isInManagement = location.pathname
    .toLowerCase()
    .startsWith("/management");

  if (isInManagement) return;

  setupPendo();
}

export function insightTrack(
  eventName: string,
  metadata: Record<string, string> = {},
) {
  if (!isPendoEnabled) return;

  metadata = {
    ...metadata,
    ...routerContext,

    app: PAGE_BASE_APP,
  };

  pendo.track(eventName, metadata);
}

export enum Guides {
  FR_Cancel_Subscription_Button = "Oxu0w5iIvPgrwKkPDvdZxQG5FJE",
}

const visibleGuides = new Map<
  string,
  {
    resolve: (value: boolean | PromiseLike<boolean>) => void;
    reject: (reason?: any) => void;
  }
>();

/***
 * @param id - id of the guide to show
 * @returns {Promise<boolean>} resolves immediately with false if the guide was
 * not shown, disabled, etc. otherwise resolves with true when the guide is dismissed
 */
export function showPendoGuideById(id: Guides): Promise<boolean> {
  if (!isPendoEnabled) return Promise.resolve(false);

  return new Promise((resolve, reject) => {
    if (!pendo.showGuideById(id, "API")) {
      resolve(false);
      return;
    }

    visibleGuides.set(id, { resolve, reject });
  });
}

export function trackVueRouterChange(router: Router) {
  router.afterEach(function (to, from, failure) {
    const lastMatched = to.matched.at(-1);

    routerContext = {
      router: "vue",
      route: lastMatched ? lastMatched.path : to.path,
      href: getHref(),
    };
  });
}

export function trackAngularRouterChange($rootScope: any) {
  $rootScope.$on(
    "$stateChangeSuccess",
    function (event: any, toState: any, toParams: any) {
      setTimeout(function () {
        if (toState.insightIgnore) return;

        routerContext = {
          router: "ng",
          route: toState.name,
          href: getHref(),
        };
      });
    },
  );
}
