<template>
  <div :class="$style.wrapper" v-loading="aiReportsStore.isLoading">
    <h4>Create report field</h4>
    <DrDynamicFormClassic
      ref="form"
      :entity="model"
      :schema="reportFieldSchema"
      :errors="formErrors"
      :disabled="isFormSubmitting"
      @update="model[$event.field as keyof Model] = $event.value"
      @submit="submit"
    />
    <div :class="$style.actions">
      <ElButton type="primary" @click="submit" :disabled="isFormSubmitting">
        Create AI report field
      </ElButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ElButton } from "element-plus";
import { type ComponentInstance, computed, reactive, ref } from "vue";

import { $notifySuccess } from "@app/vue/common";
import { FieldSchemaType } from "@app/vue/components/client-dashboard/dynamic-form/types";
import { DrDynamicFormClassic } from "@app/vue/shared/ui/dr-dynamic-form";
import { validateString } from "@app/vue/shared/ui/dr-dynamic-form/utils";
import { useFormHelper } from "@app/vue/shared/ui/dr-form";
import { pinia } from "@drVue/store/pinia";
import { useAiReportsStore } from "../AiReportsStore";
import { AiReportsApiService } from "../api";

import type { FormSchema } from "@app/vue/shared/ui/dr-dynamic-form/types";

const api = new AiReportsApiService();
const aiReportsStore = useAiReportsStore(pinia);

const form = ref<ComponentInstance<typeof DrDynamicFormClassic> | undefined>();

const model = reactive({
  report_id: "",
  label: "",
  answer_definition: "",
  prompt_template: "",
});
type Model = typeof model;

const { formErrors, hookFormSubmitPromise, isFormSubmitting } =
  useFormHelper<Model>();

const reportFieldSchema = computed(
  (): FormSchema => [
    {
      type: FieldSchemaType.Select,
      required: true,
      isReadOnly: isFormSubmitting.value,
      prop: "report_id",
      label: "AI report",
      extra: {
        select_options: aiReportsStore.list.map((r) => ({
          value: r.id,
          label: r.label,
        })),
      },
    },
    {
      type: FieldSchemaType.Text,
      required: true,
      isReadOnly: isFormSubmitting.value,
      prop: "label",
      label: "Label",
      placeholder: "",
      rules: validateString().required("This field may not be blank"),
    },
    {
      type: FieldSchemaType.Textarea,
      isReadOnly: isFormSubmitting.value,
      prop: "answer_definition",
      label: "Answer format",
      placeholder: "",
    },
    {
      type: FieldSchemaType.Textarea,
      required: true,
      isReadOnly: isFormSubmitting.value,
      prop: "prompt_template",
      label: "User prompt",
      placeholder: "",
      rules: validateString().required("This field may not be blank"),
    },
  ],
);

const submit = async () => {
  if (isFormSubmitting.value) return;

  const result = await form.value?.validate().catch((error) => error);
  if (result !== true) return;
  const prompt_context = model.answer_definition
    ? { answer_definition: model.answer_definition }
    : undefined;

  hookFormSubmitPromise(
    api.createReportField(model.report_id, {
      label: model.label,
      prompt_template: model.prompt_template,
      prompt_context,
    }),
    "Failed to create report field, see console for details",
  ).then(
    () => {
      $notifySuccess("Report field created");

      model.label = "";
      model.prompt_template = "";

      form.value?.reset({
        label: "",
        prompt_template: "",
      });

      aiReportsStore.load();
    },
    (r) => {
      console.error(r);
    },
  );
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.wrapper {
  border: 1px solid colors.$pr-150;
  border-radius: 4px;
  margin: spacing.$xs spacing.$s;
  padding: spacing.$s spacing.$s;
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: spacing.$s;
}
</style>
