import { useEventBus } from "@vueuse/core";

import type { RowPermissionsKey } from "../types";

const UploadPermissionsKey = Symbol("upload-permissions");

export const useUploadPermissionsBus = () => {
  return useEventBus<
    never,
    { groupId: number; permission: RowPermissionsKey; value: boolean }
  >(UploadPermissionsKey);
};
