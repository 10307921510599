<template>
  <DrDrawer
    size="large"
    title="Manage summaries"
    no-footer
    :shown="isOpen"
    @close="handleClose"
    @cancel="handleClose"
    @closed="$emit('close')"
  >
    <AiReportsWrapper
      v-loading="aiReportsStore.isLoading"
      :class="$style.wrapper"
    >
      <template #actions="{ buttonIconClass }">
        <div :class="$style.header">
          <AiReportsTitle
            v-if="aiReportsStore.list.length"
            title="Your summaries"
            description="Created summaries will show up here."
          />
          <div v-else :class="$style.emptyText">
            No reports have been created.
          </div>
        </div>

        <ElButton @click="isOpenCreateModal = true">
          <span>New summary</span>
          <DrIcon name="plus" :class="buttonIconClass" size="sm" />
        </ElButton>
      </template>

      <template #list>
        <AiReportRowEdit
          v-for="report in aiReportsStore.list"
          :key="report.id"
          :report="report"
          @edit="$emit('edit-report', report)"
        />
      </template>

      <template #footer>
        <AiReportsSuggestion @open:report="$emit('edit-report', $event)" />
      </template>
    </AiReportsWrapper>
  </DrDrawer>

  <CreateReportPanel
    v-if="isOpenCreateModal"
    @created="$emit('edit-report', $event)"
    @close="isOpenCreateModal = false"
  />
</template>

<script lang="ts" setup>
import { ref } from "vue";

import DrDrawer from "@app/vue/shared/ui/dr-drawer/DrDrawer.vue";
import DrIcon from "@app/vue/shared/ui/dr-icon/DrIcon.vue";
import { pinia } from "@drVue/store/pinia";
import AiReportRowEdit from "./AiReportRowEdit.vue";
import { useAiReportsStore } from "./AiReportsStore";
import AiReportsSuggestion from "./AiReportsSuggestion.vue";
import AiReportsTitle from "./AiReportsTitle.vue";
import AiReportsWrapper from "./AiReportsWrapper.vue";
import CreateReportPanel from "./forms/CreateReportPanel.vue";

import type { AiReport } from "./types";

interface Emits {
  (event: "close"): void;
  (event: "edit-report", report: AiReport): void;
}

const emit = defineEmits<Emits>();

const aiReportsStore = useAiReportsStore(pinia);

const isOpen = ref(true);
const isOpenCreateModal = ref(false);

const handleClose = (): void => {
  if (isOpenCreateModal.value) return;

  isOpen.value = false;
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.wrapper {
  padding-bottom: spacing.$l;
}

.header {
  margin-right: auto;
}

.emptyText {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: colors.$pr-500;
}
</style>
