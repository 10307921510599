import menuHtml from "./index.html?raw";

DropdownMenuController.$inject = ["$element"];
import "./select-all-checkbox";

import * as d3 from "d3";

angular
  .module("dealroom.analytics.dropdown.base.menu", [
    "dealroom.analytics.dropdown.base.select-all-checkbox",
  ])
  .component("drAnalyticsDropdownMenu", {
    bindings: {
      dispatcher: "<",
      items: "<",
      multiselect: "<",
      alignment: "@",
    },
    controller: DropdownMenuController,
    template: menuHtml,
  });

function DropdownMenuController($element) {
  const rootElement = d3.select($element[0]);
  const menu = rootElement.select(".dropdown-menu");
  const $ctrl = this;
  let options = [];
  const optionCls = "analytics-select__item";

  $ctrl.$onInit = function () {
    $ctrl.cls = "pull-" + ($ctrl.alignment || "left");
    $ctrl.dispatcher.on("handleSelect.menu", handleSelect);
    $ctrl.dispatcher.on("handleOpen.menu", handleOpen);

    options = menu
      .selectAll("." + optionCls)
      .data($ctrl.items)
      .enter()
      .append("li")
      .attr("class", optionCls)
      .each(createOption);
  };

  function handleSelect(selected) {
    options.each(function (d) {
      const isSelected = selected.includes(d.label);
      d3.select(this)
        .select('[type="checkbox"]')
        .property("checked", isSelected);
    });
  }

  function handleOpen(toClose) {
    toClose = angular.isDefined(toClose)
      ? toClose
      : rootElement.classed("open");
    rootElement.classed("open", !toClose);
  }

  function createOption(d, i) {
    const li = d3.select(this);
    li.on("click", (d) => {
      d3.event.preventDefault();
      d3.event.stopPropagation();

      $ctrl.dispatcher.call("toggleSelect", null, d);
    });
    li.append("a").attr("href", "").append("div").each(createLabel);

    function createLabel(d) {
      const inner = d3.select(this).attr("class", "analytics-select__inner");
      if ($ctrl.multiselect) {
        inner
          .append("input")
          .attr("type", "checkbox")
          .attr("class", "analytics-select__checkbox")
          .on("click", (d) => {
            d3.event.stopPropagation();
            $ctrl.dispatcher.call("toggleSelect", null, d);
          });
      }
      inner.append("span").classed("truncate", $ctrl.multiselect).text(d.label);
    }
  }
}
