import { ROOM_DATA } from "@app/setups";
import { diff } from "./helpers/diff";

import type { TaskComparator } from "../types/TaskComparator";

export const key: TaskComparator = (a, b, isReversed = false) => {
  if (ROOM_DATA.enableTaskListKey) {
    return diff(
      parseInt(a.key.split("-")[1]),
      parseInt(b.key.split("-")[1]),
      isReversed,
    );
  }

  return diff(parseInt(a.key), parseInt(b.key), isReversed);
};
