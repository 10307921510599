import { DealNoteApiService, UserNoteApiService } from "./api";

import type { NoteApiService } from "./api";
import type { State } from "./state";
import type { DealNote, Note, UserNote } from "./types";
import type { RootState } from "@drVue/store/state";
import type { ActionContext, ActionTree } from "vuex";

export type IContext = ActionContext<State<Note>, RootState>;

export interface NotesActionTree<NoteType extends Note>
  extends ActionTree<State<NoteType>, RootState> {
  loadNotes(context: IContext, objectId: number | string): Promise<void>;
  createNote(
    context: IContext,
    payload: { objectId: number | string; note: Partial<NoteType> },
  ): Promise<void>;
  updateNote(context: IContext, note: NoteType): Promise<void>;
  deleteNote(context: IContext, note: NoteType): Promise<void>;
}

class NotesActionTreeFactory<
  NoteType extends Note,
  RelatedObjectIdType extends string | number,
> {
  protected readonly noteApi: NoteApiService<NoteType, RelatedObjectIdType>;
  constructor(noteApi: NoteApiService<NoteType, RelatedObjectIdType>) {
    this.noteApi = noteApi;
  }

  asActionTree(): NotesActionTree<NoteType> {
    const self = this;
    return {
      loadNotes: self.loadNotes.bind(self),
      createNote: self.createNote.bind(self),
      updateNote: self.updateNote.bind(self),
      deleteNote: self.deleteNote.bind(self),
    };
  }

  loadNotes(context: IContext, objectId: number | string): Promise<void> {
    return this.noteApi
      .loadNotes(objectId)
      .then((notes) => context.commit("addNotes", notes));
  }
  createNote(
    context: IContext,
    payload: { objectId: number | string; note: Partial<NoteType> },
  ): Promise<void> {
    return this.noteApi
      .createNote(payload.objectId, payload.note)
      .then((newNote) => {
        context.commit("addNotes", [newNote]);
      });
  }
  updateNote(context: IContext, note: NoteType): Promise<void> {
    return this.noteApi.updateNote(note).then((updatedNote) => {
      context.commit("updateNote", updatedNote);
    });
  }
  deleteNote(context: IContext, note: NoteType): Promise<void> {
    return this.noteApi.deleteNote(note).then(() => {
      context.commit("deleteNote", note);
    });
  }
}

class UserNotesActionTreeFactory extends NotesActionTreeFactory<
  UserNote,
  string
> {}

class DealNotesActionTreeFactory extends NotesActionTreeFactory<
  DealNote,
  number
> {
  createNote(
    context: IContext,
    payload: { objectId: number; note: Partial<DealNote> },
  ): Promise<void> {
    return super.createNote(context, payload).then(() => {
      this._updateDealNotesInfo(payload.objectId, context);
    });
  }

  updateNote(context: IContext, note: DealNote): Promise<void> {
    return super.updateNote(context, note).then(() => {
      this._updateDealNotesInfo(note.deal_id, context);
    });
  }

  deleteNote(context: IContext, note: DealNote): Promise<void> {
    return super.deleteNote(context, note).then(() => {
      this._updateDealNotesInfo(note.deal_id, context);
    });
  }

  _updateDealNotesInfo(dealId: number, { rootState, getters }: IContext) {
    const deal = rootState.clientDashboard.deals.items.find(
      (d) => d.id === dealId,
    );
    if (!deal) return;
    //NB: dealNotes ordered by -date_added
    const dealNotes: DealNote[] = getters.byObjectId(dealId);
    deal.notes_count = dealNotes.length;
    deal.last_note = dealNotes.length ? dealNotes[0] : null;
  }
}

export const dealsNotesActions: NotesActionTree<DealNote> =
  new DealNotesActionTreeFactory(new DealNoteApiService()).asActionTree();
export const usersNotesActions: NotesActionTree<UserNote> =
  new UserNotesActionTreeFactory(new UserNoteApiService()).asActionTree();
