import Idle from "./idle";
import { generateUUID } from "./vue/common";

(function () {
  //https://github.com/shawnmclean/Idle.js
  // TODO: move to separate file after JS build support
  "use strict";

  angular
    .module("dealroom.time-tracking", ["dealroom.config"])
    .service("AppVersionService", AppVersionService)
    .provider("TrackActivity", TrackActivityProvider)
    .provider("AppDebug", AppDebugProvider);

  function AppVersionService() {
    var lastVersion,
      versionChanged = false;

    function setVersion(newVersion) {
      if (newVersion !== lastVersion && lastVersion) {
        versionChanged = true;
      }
      lastVersion = newVersion;
    }

    function isVersionChanged() {
      return versionChanged;
    }

    return {
      setVersion: setVersion,
      isVersionChanged: isVersionChanged,
    };
  }

  function AppDebugProvider() {
    var self = this;

    // https://chromium.googlesource.com/external/Webkit/+/master/Source/WebCore/page/MemoryInfo.cpp#64
    // chrome allows to get updated memory info only every 20minutes, check every 21 for sanity
    // so do need to send debug information very often
    var updateRate = 21 * 60;
    var lastUpdated = 0;
    var tabOpenedAt = new Date().getTime();
    var dataUpdateCount = 0;
    var tabUUID = generateUUID();

    function openDuration() {
      return Math.round((new Date().getTime() - tabOpenedAt) / 1000);
    }

    function bytesToMb(val) {
      return Math.round(val / 1024 / 1024);
    }

    function memoryUsage() {
      if (window.performance && window.performance.memory) {
        return {
          totalJSHeapSize: bytesToMb(window.performance.memory.totalJSHeapSize),
          usedJSHeapSize: bytesToMb(window.performance.memory.usedJSHeapSize),
        };
      }
    }

    function needUpdate() {
      if (openDuration() < 5) return false;
      return (new Date().getTime() - 0) / 1000 > updateRate;
    }

    self.$get = function () {
      function getDebugData() {
        if (!needUpdate()) {
          return;
        }
        lastUpdated = new Date().getTime();
        return {
          tab_uuid: tabUUID,
          tab_opened_duration: openDuration(), // seconds
          data_updated_count: dataUpdateCount, // Mb
          memory: memoryUsage(),
        };
      }
      function increaseDataUpdateCount() {
        dataUpdateCount += 1;
      }
      return {
        getDebugData: getDebugData,
        increaseDataUpdateCount: increaseDataUpdateCount,
      };
    };
  }

  function TrackActivityProvider() {
    var self = this;

    self.object = undefined; // can be like {"type": "task", "id": 777},
    self.trackingInterval = 15; // send ping request every X seconds
    self.inactivityTimeout = 30; // stop sending pings after X seconds inactivity
    self.setObject = function (object) {
      self.object = object;
    };

    var idle = new Idle({
      awayTimeout: self.inactivityTimeout * 1000,
    }).start();

    var tracker;

    self.$get = [
      "$interval",
      "$http",
      "$rootScope",
      "URLS",
      "AppVersionService",
      "AppDebug",
      function (
        $interval,
        $http,
        $rootScope,
        URLS,
        AppVersionService,
        AppDebug,
      ) {
        function onAwayBack() {
          startTracking();
          $rootScope.$emit("track-activity:become-active");
        }

        function ping() {
          var data = {
            debug: AppDebug.getDebugData(),
          };
          if (self.object) {
            data["object"] = self.object;
          }
          $http
            .post(URLS["api:room:activity_ping"](), data)
            .then(function (resp) {
              AppVersionService.setVersion(resp.data["app_version"]);
            });
        }
        function stopTracking() {
          if (tracker) {
            $interval.cancel(tracker);
            tracker = undefined;
          }
          Idle.onAwayBack.unsubscribe(onAwayBack);
        }
        function startTracking() {
          stopTracking();
          ping();
          // start pings every trackingInterval
          tracker = $interval(
            function () {
              idle.checkAway();
              if (Idle.isAway) {
                // user is inactive, stop sending pings
                // and wait until it comes back;
                stopTracking();
                Idle.onAwayBack.subscribe(onAwayBack);
              } else {
                //user is active
                ping();
              }
            },
            self.trackingInterval * 1000,
            0,
            false,
          );
        }
        function setNewObject(object) {
          self.setObject(object);
          if (tracker) {
            ping();
          }
        }
        return {
          isActive: function () {
            return !Idle.isAway;
          },
          setObject: setNewObject,
          stopTracking: stopTracking,
          startTracking: startTracking,
        };
      },
    ];
  }
})();
