<template>
  <div ref="containerRef" :class="$style.labelContainer">
    <ElInput
      v-if="isEditing"
      ref="inputRef"
      v-model="editValue"
      :class="$style.input"
      type="text"
      :maxlength="LABEL_MAX_LENGTH"
      @click.stop
      @keydown.stop="(e) => saveLabelOnEnter(e as KeyboardEvent, label)"
    />

    <div v-if="!isEditing" :class="$style.labelInner">
      <DrLabel :class="$style.label">{{ label.name }}</DrLabel>
      <DrIcon
        v-if="isSelected"
        name="check"
        size="xs"
        :class="$style.iconCheck"
      />
    </div>

    <!-- Actions -->
    <span v-if="isEditing">
      <DrIcon
        v-if="isEditing"
        name="check"
        :class="[$style.icon, $style.iconSave]"
        @click.stop="saveLabel(label)"
      />
      <DrIcon
        name="cross"
        :class="[$style.icon, $style.iconCancel]"
        @click.stop="isEditing = false"
      />
    </span>
    <span v-else :class="$style.icons">
      <DrIcon
        name="pencil"
        :class="[$style.icon, $style.iconEdit]"
        @click.stop="editLabel(label)"
      />
      <DrIcon
        name="trash"
        :class="[$style.icon, $style.iconDelete]"
        @click.stop="deleteLabel(label)"
      />
    </span>
  </div>
</template>

<script setup lang="ts">
import { ElInput, ElMessageBox } from "element-plus";
import { nextTick, ref } from "vue";
import DrIcon from "@shared/ui/dr-icon";
import { DrLabel } from "@shared/ui/dr-label";

import { LABEL_MAX_LENGTH } from "@drVue/store/pinia/room/tasksLabels/constants";

import type { TaskLabel } from "@drVue/store/pinia/room/tasksLabels/tasksLabelApi";

interface Props {
  label: TaskLabel;
  isSelected: boolean;
}

const containerRef = ref<HTMLElement>();
const inputRef = ref<InstanceType<typeof ElInput> | null>(null);
const isEditing = ref(false);
const editValue = ref("");

defineProps<Props>();
const emit = defineEmits<{
  (e: "busy", isBusy: boolean): void;
  (
    e: "update",
    label: TaskLabel,
    onUpdated: (isEditing: boolean) => void,
  ): void;
  (e: "delete", deletedLabel: TaskLabel): void;
}>();

const deleteLabel = (l: TaskLabel) => {
  ElMessageBox({
    type: "warning",
    title: "Delete label",
    message: `Are you sure you want to delete the label "${l.name}"? The label will be deleted from this room and from all requests. This action can not be undone.`,
    confirmButtonText: "Delete",
    confirmButtonClass: "el-button--danger",
    showCancelButton: true,
    showClose: true,
    cancelButtonText: "No",
    closeOnHashChange: false,
    closeOnPressEscape: false,
    appendTo: containerRef.value,
    beforeClose: (action, _instance, done) => {
      if (action === "confirm") emit("delete", l);

      done();
    },
  }).catch(() => {});
};

const editLabel = (l: TaskLabel) => {
  editValue.value = l.name;
  isEditing.value = true;

  nextTick(() => {
    inputRef.value?.focus();
  });
};

const saveLabel = (l: TaskLabel) => {
  if (editValue.value.trim() === l.name.trim()) {
    isEditing.value = false;
    return;
  }

  if (editValue.value.trim() === "") {
    editValue.value = l.name;
    isEditing.value = false;
    return;
  }

  const label = {
    uid: l.uid,
    id: l.id,
    bg_color: l.bg_color,
    label_color: l.label_color,
    name: editValue.value,
  };

  emit(
    "update",
    label,
    (_isEditing: boolean) => (isEditing.value = _isEditing),
  );
};

const saveLabelOnEnter = (e: KeyboardEvent, l: TaskLabel) => {
  if (e.key === "Enter") {
    saveLabel(l);

    e.preventDefault();
  }
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

.labelContainer {
  width: 100%;
  padding: 5px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  transition: background-color 0.2s ease-out;
  justify-content: space-between;

  &:hover {
    background-color: colors.$pr-200;
  }
}

.labelInner {
  display: flex;
  align-items: center;
}

.label {
  line-height: 1;
  background-color: colors.$pr-100 !important;
}

.input {
  margin-right: 10px;
}

.icons {
  display: grid;
  grid-template-columns: repeat(2, 10px);
  gap: 12px;
  margin-right: 10px;
}

.icon {
  color: colors.$pr-300;
}

.icon:hover {
  transition: color 0.2s ease-out;
  color: colors.$sc-600;
}

.iconSave {
  font-size: 14px;
}
.iconCancel {
  font-size: 16px;
}
.iconEdit {
  font-size: 14px;
}
.iconDelete {
  font-size: 14px;
}

.iconCheck {
  margin-left: 6px;
  color: colors.$sc-600;
}
</style>
