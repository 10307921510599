<template>
  <ElForm label-position="top" :class="$style.form">
    <ElRow justify="space-between" :class="$style.header">
      <ElButton @click="$emit('hide')"> Close </ElButton>
      <ElButton type="primary" :disabled="!hasFilters" @click="clearFilters()">
        Reset all
      </ElButton>
    </ElRow>

    <ElFormItem label="Phase:" prop="name">
      <ElSelect
        v-model="selectedPhases"
        clearable
        multiple
        placeholder="Select phase"
        class="el-select--full-width"
        :popper-options="popperOptions"
      >
        <ElOption
          v-for="phase in phasesList"
          :key="phase.id"
          :label="phase.name"
          :value="phase.id"
          class="dash-rooms-filter-phase"
        >
          <!-- @vue-ignore: color -->
          <PhaseSelectItemComponent
            :name="phase.name"
            :color="phase.color"
            :deals-count="phase.dealsCount"
          />
        </ElOption>
      </ElSelect>
    </ElFormItem>

    <ElFormItem
      v-for="field in fieldsList"
      :key="field.id"
      :label="field.label"
    >
      <DrDatePicker
        v-if="field.field_type === FieldItemTypes.Date"
        type="daterange"
        start-placeholder="From"
        end-placeholder="To"
        align="center"
        :model-value="getFieldValue(field.key)"
        @update:model-value="
          (value) => setFieldValue(field.key, value, 'RANGE')
        "
      />

      <ElSelect
        v-else-if="
          field.field_type === FieldItemTypes.Select ||
          field.field_type === FieldItemTypes.MultiSelect
        "
        clearable
        multiple
        placeholder=""
        class="el-select--full-width"
        :model-value="getFieldValue(field.key)"
        :popper-options="popperOptions"
        @change="
          (value) =>
            setFieldValue(
              field.key,
              value,
              field.field_type === FieldItemTypes.Select ? 'ANY' : 'ALL',
            )
        "
      >
        <ElTooltip
          v-for="item in field.extra.options"
          :key="item"
          :content="item"
          :show-after="400"
        >
          <ElOption :class="$style.dropdownItem" :label="item" :value="item" />
        </ElTooltip>
      </ElSelect>

      <ElInput
        v-else-if="field.field_type === FieldItemTypes.Number"
        clearable
        type="number"
        :model-value="getFieldValue(field.key)"
        @input="(value) => setFieldValue(field.key, value, 'EQ')"
        @wheel.prevent
      />

      <ElAutocomplete
        v-else
        clearable
        class="el-autocomplete--full-width"
        :popper-options="popperOptions"
        :label="field.key"
        :fetch-suggestions="
          (query, cb) => textFieldAutoComplete(query, cb, field.key)
        "
        :trigger-on-focus="false"
        :debounce="0"
        :model-value="getFieldValue(field.key)"
        @update:model-value="
          (value) => setFieldValue(field.key, value, 'CONTAINS')
        "
      >
        <template #default="{ item }">
          <ElTooltip :show-after="400" :content="item.value">
            <div class="truncate" :class="$style.dropdownItem">
              {{ item.value }}
            </div>
          </ElTooltip>
        </template>
      </ElAutocomplete>
    </ElFormItem>
  </ElForm>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrDatePicker from "@shared/ui/dr-datepicker/DrDatepicker.vue";

import { FieldItemTypes } from "@drVue/api-service/client-dashboard";
import {
  getCustomFieldWithoutDisabledOptions,
  markDisabledCustomFieldOptions,
} from "@drVue/components/client-dashboard/deals/utils";
import PhaseSelectItemComponent from "./PhaseSelectItem.vue";
import {
  convertPhasesFilterValueToStore,
  convertPhasesStoreToFilterValue,
  getPhasesSelectList,
  getTextFieldAutoComplete,
} from "./utils";

import type { PhasesValue } from "./utils";
import type { Options as PopperOptions } from "@popperjs/core";

interface Data {
  FieldItemTypes: any;
  textFieldAutoComplete: any;
  popperOptions: PopperOptions;
}

export default defineComponent({
  name: "FiltersForm",
  components: { PhaseSelectItemComponent, DrDatePicker },
  emits: ["hide"],

  data(): Data {
    return {
      FieldItemTypes: FieldItemTypes,
      textFieldAutoComplete: getTextFieldAutoComplete(this.$store),
      popperOptions: {
        strategy: "absolute",
        placement: "bottom",
        modifiers: [
          {
            name: "flip",
            options: {
              fallbackPlacements: ["top", "bottom"],
            },
          },
        ],
      },
    };
  },

  computed: {
    selectedPhases: {
      get: function (): PhasesValue {
        return convertPhasesStoreToFilterValue(this.getFieldValue("phase"));
      },
      set: function (values: PhasesValue) {
        const converted = convertPhasesFilterValueToStore(values);
        this.setFieldValue("phase", converted, "ANY");
      },
    },
    phasesList() {
      return getPhasesSelectList(this.$store);
    },
    fieldsList(): any {
      return markDisabledCustomFieldOptions(
        this.$store.getters["clientDashboard/customFields/byObjectType"](
          "deal",
        ),
      ).map(getCustomFieldWithoutDisabledOptions);
    },
    hasFilters(): any {
      return this.$store.getters["clientDashboard/filters/dealsFilterActive"];
    },
    fieldFilters(): any {
      return this.$store.state.common.customViews.current.filters.fields;
    },
    customData(): any {
      return JSON.stringify(this.fieldFilters.fields);
    },
  },

  methods: {
    setFieldValue(field: string, value: any, op: string = "EQ") {
      this.$store.commit("common/customViews/setCurrentFilterValue", {
        field,
        value,
        op,
      });
    },
    getFieldValue(field: string) {
      return this.fieldFilters[field]?.value;
    },
    clearFilters() {
      this.$store.commit("common/customViews/clearCurrentFiltersFields");
    },
  },
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

.form {
  padding: 20px;

  .header {
    position: sticky;
    top: 0;
    z-index: 100;
    margin: -20px -20px 10px;
    padding: 20px 20px;
    background-color: #fff;
    border-bottom: solid 1px colors.$pr-100;
  }
}

.dropdownItem {
  width: 279px;
}
</style>
