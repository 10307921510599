import { dealsNotesActions, usersNotesActions } from "./actions";
import { dealsNotesGetters, usersNotesGetters } from "./getters";
import { dealsNotesMutations, usersNotesMutations } from "./mutations";
import { DealsNotesState, UsersNotesState } from "./state";

import type { RootState } from "@drVue/store/state";
import type { Module } from "vuex";

export const DealsNotesModule: Module<DealsNotesState, RootState> = {
  actions: dealsNotesActions,
  getters: dealsNotesGetters,
  mutations: dealsNotesMutations,
  namespaced: true,
  state: new DealsNotesState(),
};

export const UsersNotesModule: Module<UsersNotesState, RootState> = {
  actions: usersNotesActions,
  getters: usersNotesGetters,
  mutations: usersNotesMutations,
  namespaced: true,
  state: new UsersNotesState(),
};
