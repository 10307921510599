import { DrStore } from "@drVue";
import VueTaskListFilter from "@drVue/components/room/TaskListFilter.vue";
import VueCommentViewersSelector from "@drVue/components/room/tasks/comments/CommentViewersSelector.vue";
import VueCommentViewersTag from "@drVue/components/room/tasks/comments/CommentViewersTag.vue";
import VueTaskParticipantsList from "@drVue/components/room/tasks/participants/TaskParticipantsList.vue";
import VueTasksCategoriesTree from "@drVue/components/room/tasks/TaskCategoriesTree.vue";
import VueTaskDetails from "@drVue/components/room/tasks/TaskDetails/TaskDetails.vue";
import VueTasksOverviewContent from "@drVue/components/room/tasks/TaskOverviewPage/TasksOverviewContent.vue";
import VueTasksTimelineContent from "@drVue/components/room/tasks/TaskTimelinePage/TasksTimelineContent.vue";
import drCategoryInformation from "./ng/tasks/components/drCategoryInformation";
import drTaskInformation from "./ng/tasks/components/drTaskInformation";
import drTaskPathCategories from "./ng/tasks/components/drTaskPathCategories";
import drTaskSelect from "./ng/tasks/components/drTaskSelect";
import RoomTaskController from "./ng/tasks/controllers/RoomTaskController";
import TaskArchivedListController from "./ng/tasks/controllers/TaskArchivedListController";
import TaskDetailsPageController from "./ng/tasks/controllers/TaskDetailsPageController";
import TaskListController from "./ng/tasks/controllers/TaskListController";
import drListKeyCheck from "./ng/tasks/directives/drListKeyCheck";
import drLabelKeyProvider from "./ng/tasks/providers/drLabelKeyProvider";
import ArchivedCategoriesService from "./ng/tasks/services/ArchivedCategoriesService";
import CategoriesService from "./ng/tasks/services/CategoriesService";
import TasksAttachmentsService from "./ng/tasks/services/TasksAttachmentsService";
import TasksFilterService from "./ng/tasks/services/TasksFilterService";
import TasksService from "./ng/tasks/services/TasksService";

angular
  .module("dealroom.task", [
    "ui.router",
    "ui.bootstrap",
    "ui.indeterminate",
    "ui.tree",
    "monospaced.elastic",
    "dealroom.date-fns",
    "ngFileUpload",
    "vs-repeat",
    "ui.bootstrap.contextMenu",
    "dealroom.table-sort.directive",

    "dealroom.members",
    "dealroom.components.editor",
  ])
  .provider("drLabelKey", drLabelKeyProvider)
  .factory("CategoriesService", CategoriesService)
  .factory("ArchivedCategoriesService", ArchivedCategoriesService)
  .factory("TasksService", TasksService)
  .factory("TasksAttachmentsService", TasksAttachmentsService)
  .factory("TasksFilterService", TasksFilterService)
  .controller("RoomTaskController", RoomTaskController)
  .controller("TaskDetailsPageController", TaskDetailsPageController)
  .controller("TaskListController", TaskListController)
  .controller("TaskArchivedListController", TaskArchivedListController)
  .component("drTaskInformation", drTaskInformation())
  .component("drCategoryInformation", drCategoryInformation())
  .component("drTaskPathCategories", drTaskPathCategories())
  .component("drTaskSelect", drTaskSelect())
  .directive("drListKeyCheck", drListKeyCheck)
  .value("VueTasksTimelineContent", VueTasksTimelineContent)
  .value("VueCommentViewersTag", VueCommentViewersTag)
  .value("VueCommentViewersSelector", VueCommentViewersSelector)
  .value("VueTaskListFilter", VueTaskListFilter)
  .value("VueTasksCategoriesTree", VueTasksCategoriesTree)
  .value("VueTaskParticipantsList", VueTaskParticipantsList)
  .value("VueTasksOverviewContent", VueTasksOverviewContent)
  .value("VueTaskDetails", VueTaskDetails)
  .config([
    "drLabelKeyProvider",
    "RoomConfig",
    function (drLabelKeyProvider, RoomConfig) {
      drLabelKeyProvider.setLabels(RoomConfig.labelKeys);
      DrStore.dispatch("room/tasksStatuses/init", RoomConfig.taskStatuses);
    },
  ]);
