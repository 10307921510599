import toggleHtml from "./index.html?raw";

DropdownToggleController.$inject = ["$element"];
import "./selected-label";

import * as d3 from "d3";

angular
  .module("dealroom.analytics.dropdown.base.toggle", [
    "dealroom.analytics.dropdown.base.selected-label",
  ])
  .component("drAnalyticsDropdownToggle", {
    bindings: {
      dispatcher: "<",
      items: "<",
      multiselect: "<",
      classes: "<",
    },
    controller: DropdownToggleController,
    template: toggleHtml,
  });

function DropdownToggleController($element) {
  const rootElement = d3.select($element[0]);
  const $ctrl = this;

  rootElement.on("click", function () {
    d3.event.preventDefault();
    d3.event.stopPropagation();
    // close all dropdowns
    d3.selectAll("dr-analytics-dropdown-toggle").classed("open", false);
    $ctrl.dispatcher.call("toggleOpen");
  });
}
