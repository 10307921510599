import type { TasksStatusesState } from "./TasksStatusesState";
import type { TaskStatus } from "./types";
import type { RootState } from "@drVue/store/state";
import type { GetterTree } from "vuex";

export const tasksStatusesGetters: GetterTree<TasksStatusesState, RootState> = {
  byId(state) {
    return (statusId: number): TaskStatus | undefined => {
      return state.list.find((s) => s.id === statusId);
    };
  },
};
