<template>
  <ElDialog
    v-model="isCreateOrEditModalVisible"
    width="600px"
    :title="formModel.id ? 'Edit request status' : 'New request status'"
    @close="closeModal"
  >
    <ElForm label-position="top">
      <ElFormItem label="Name" prop="name" :error="getBackendError('name')">
        <ElInput v-model="formModel.name" maxlength="12" />
      </ElFormItem>

      <ElFormItem
        v-if="!formModel.id"
        label="Type"
        prop="type"
        :error="getBackendError('type')"
      >
        <ElSelect v-model="formModel.type" placeholder="Select">
          <ElOption
            v-for="(label, type) in TYPES_DEFAULT_LABEL"
            :key="type"
            :label="label"
            :value="type"
          />
        </ElSelect>
      </ElFormItem>

      <ElFormItem label="Color" prop="color" :error="getBackendError('color')">
        <ElColorPicker
          v-model="formModel.color"
          :error="getBackendError('color')"
          :predefine="COLORS"
          size="default"
          @change="resetBackendError('color')"
        />
      </ElFormItem>

      <ElFormItem>
        <div class="text-right">
          <ElButton @click="closeModal">Close</ElButton>
          <ElButton type="primary" @click="submitCreateOrEdit">
            {{ formModel.id ? "Save" : "Create" }}
          </ElButton>
        </div>
      </ElFormItem>
    </ElForm>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrForm from "@shared/ui/dr-form";

import { TaskStatusType } from "@drVue/store/modules/room/tasks-statuses/TasksStatusesState";

const COLORS = [
  "#a6b2bc",
  "#4d4d4d",
  "#51e898",
  "#4e9840",
  "#00c2e0",
  "#7037e2",
  "#ff80ce",
  "#eb5a46",
  "#f2a620",
  "#f2d600",
];

interface FormModel {
  id?: null | number;
  name: string;
  type: string;
  color: string;
}

interface Data {
  isCreateOrEditModalVisible: boolean;
  COLORS: any;
  formModel: FormModel;
}

export default defineComponent({
  name: "TaskStatuses",
  extends: DrForm,
  data(): Data {
    return {
      isCreateOrEditModalVisible: false,
      COLORS: COLORS,
      formModel: {
        id: null,
        name: "",
        type: "",
        color: COLORS[0],
      },
    };
  },
  computed: {
    TYPES_DEFAULT_LABEL(): any {
      return this.$store.state.room.tasksStatuses.TYPES_DEFAULT_LABEL;
    },
  },
  methods: {
    openCreateModal() {
      delete this.formModel.id;

      this.formModel.name = "";
      this.formModel.type = TaskStatusType.Open;
      (this.formModel.color = COLORS[0]), this.setBackendErrors({});

      this.isCreateOrEditModalVisible = true;
    },
    openEditModal(row: any) {
      this.formModel.id = row.id;
      this.formModel.name = row.name;
      this.formModel.type = row.type;
      this.formModel.color = row.color;
      this.setBackendErrors({});

      this.isCreateOrEditModalVisible = true;
    },
    submitCreateOrEdit() {
      const submitAction = this.formModel.id
        ? "room/tasksStatuses/update"
        : "room/tasksStatuses/add";

      if (this.formModel.id === null) delete this.formModel.id;

      this.setBackendErrors({});
      this.submitAction(submitAction, this.formModel).then(this.closeModal);
    },
    closeModal() {
      this.isCreateOrEditModalVisible = false;
    },
  },
});
</script>
