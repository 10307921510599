<template>
  <div :class="$style.container">
    <div :class="$style.info">
      <div :class="$style.infoLabel">{{ label }}</div>
      <h1 :class="$style.infoCallToAction">
        <slot name="call-to-action" />
      </h1>
      <p :class="$style.infoDescription">
        <slot name="description" />
      </p>
      <ElButton type="primary" @click="requestAccess">Request access</ElButton>
    </div>
    <div :class="$style.preview" v-if="src">
      <img :src="src" alt="feature preview" />
    </div>
  </div>
</template>

<script lang="ts" setup>
/** @todo move to common app params */
const SUPPORT_EMAIL = "support@dealroom.net";

interface Props {
  /**
   * Label of the feature (shown in the top left corner of the banner)
   */
  label: string;

  /**
   * Email subject for the "Request access" button
   */
  subject: string;

  /**
   * URL of the preview image
   */
  src?: string;
}

const props = defineProps<Props>();

const requestAccess = () => {
  const uri = `mailto:${SUPPORT_EMAIL}?subject=${encodeURIComponent(props.subject)}`;
  window.open(uri, "_blank");
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";

.container {
  display: flex;
  align-items: center;
  border: 1px solid colors.$pr-150;
  border-radius: 12px;
  background-color: colors.$pr-50;
}

.info {
  padding: spacing.$xxl spacing.$xs spacing.$xxl spacing.$xxl;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
}

.infoLabel {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: colors.$sc-600;
  text-transform: uppercase;
}

.infoCallToAction {
  margin: 0;
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
  color: colors.$pr-900;
}

.infoDescription {
  color: colors.$pr-900;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 6px;
}

.preview {
  flex: 0 0 332px;
  padding: spacing.$s;

  & > img {
    width: 100%;
  }
}
</style>
