<template>
  <div v-if="isError" :class="$style.error">
    <span>ERROR</span>
    <ElButton type="primary" @click="emit('retry')">
      Try again
      <template #icon>
        <DrIcon size="xs" name="rotate-right" />
      </template>
    </ElButton>
  </div>

  <div
    v-else
    v-loading="isPending"
    :class="{
      [$style.container]: true,
      [$style.container_isReadOnly]: isReadOnly,
      [$style.container_isCondensed]: condensed,
    }"
  >
    <DrCommentsComposer
      v-if="!isReadOnly"
      :autofocus="autofocus && !isPending"
      :can-manage-permissions="canManagePermissions"
      :mentions="mentions"
      :viewers-groups="viewersGroups"
      :user-group-id="userGroupId"
      :default-viewers-groups="defaultViewersGroups"
      :comment="editedComment"
      @add="$emit('add', $event)"
      @edit="submitEdit"
      @cancel-edit="cancelEdit"
    >
      <template v-if="$slots['composer-extra-actions']" #extra-actions>
        <slot name="composer-extra-actions" />
      </template>
    </DrCommentsComposer>

    <div :class="$style.body">
      <ElScrollbar max-height="100%">
        <div v-if="!itemsByDate.length" :class="$style.emptyMessage">
          <DrIcon size="sm" name="comment" :class="$style.commentIcon" />
          No comments here yet
        </div>

        <div
          v-for="group in itemsByDate"
          :key="group.key"
          :class="$style.group"
        >
          <div :class="$style.groupTitle">
            <span :class="$style.groupTitleValue">
              {{ group.title }}
            </span>
          </div>

          <template v-for="item in group.items" :key="item.id">
            <slot name="item" v-bind="{ item, startEdit }">
              <DrCommentsItem
                :item="item"
                :user-id="userId"
                :viewers-groups="viewersGroups"
                :user-group-id="userGroupId"
                :has-manager-access="hasManagerAccess"
                :mentions="mentions"
                @approve="$emit('approve', $event)"
                @remove="$emit('remove', $event)"
                @edit="startEdit"
              />
            </slot>
          </template>
        </div>
      </ElScrollbar>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T extends Comment">
import { isThisYear, isToday } from "date-fns";
import { groupBy } from "lodash-es";
import { computed, onBeforeUnmount, ref } from "vue";

import { drUserTime } from "@drVue/filters/drUserTime/";
import { DrIcon } from "../dr-icon";
import DrCommentsComposer from "./DrCommentsComposer.vue";
import DrCommentsItem from "./DrCommentsItem.vue";

import type {
  Comment,
  CommentDraft,
  CommentMentions,
  CommentUpdate,
  CommentViewersGroup,
} from "./types";

interface Props {
  items: T[];
  lastVisitDate?: Date;
  userId: Comment["author"]["id"];
  viewersGroups?: CommentViewersGroup[];
  userGroupId?: CommentViewersGroup["id"];
  defaultViewersGroups?: CommentViewersGroup["id"][];
  hasManagerAccess?: boolean;
  mentions?: CommentMentions;
  isPending?: boolean;
  isError?: boolean;
  autofocus?: boolean;
  isReadOnly?: boolean;
  /** Customize widget height, 250px min. */
  height?: number;
  /** No height limit */
  freeHeight?: boolean;
  /** No min height */
  condensed?: boolean;
}

interface Emits {
  (event: "retry"): void;
  (event: "update-last-visit"): void;
  (event: "add", comment: CommentDraft): void;
  (event: "approve", id: T["id"]): void;
  (event: "remove", id: T["id"]): void;
  (event: "edit", comment: CommentUpdate): void;
}

const props = withDefaults(defineProps<Props>(), {
  lastVisitDate: undefined,
  viewersGroups: () => [],
  userGroupId: "",
  defaultViewersGroups: () => [],
  hasManagerAccess: false,
  mentions: undefined,
  isPending: false,
  isError: false,
  autofocus: false,
  isReadOnly: false,
  height: 0,
  freeHeight: false,
  condensed: false,
});

const emit = defineEmits<Emits>();

const DEFAULT_HEIGHT = 512;
const MIN_HEIGHT = 220;

const widgetMaxHeight = computed(() => {
  if (props.freeHeight) {
    return "auto";
  }

  return props.height
    ? `${Math.max(MIN_HEIGHT, props.height)}px`
    : `${DEFAULT_HEIGHT}px`;
});

const canManagePermissions = computed(
  () =>
    props.hasManagerAccess &&
    !!props.viewersGroups.length &&
    !!props.userGroupId,
);

const itemsByDate = computed(() => {
  let filteredIems = props.hasManagerAccess
    ? [...props.items]
    : props.items.filter(
        ({ need_approve, author }) =>
          !need_approve || author.id === props.userId,
      );

  filteredIems = filteredIems
    .sort((itemA, itemB) => {
      return itemB.date_added.getTime() - itemA.date_added.getTime();
    })
    .map((item) => {
      let is_new = false;
      if (props.lastVisitDate && item.author.id !== props.userId) {
        if (item.date_added > props.lastVisitDate) {
          is_new = true;
        }
      }

      return {
        ...item,
        is_new,
        readonly: (props.isReadOnly || item.readonly) ?? false,
      };
    });

  const grouppedItems = groupBy(filteredIems, (d) =>
    drUserTime(d.date_added, "full-date"),
  );

  return Object.keys(grouppedItems).map((dateValue) => {
    const groupDate = new Date(grouppedItems[dateValue][0].date_added);
    const showFullDate = !isThisYear(groupDate);
    let title = drUserTime(
      groupDate,
      showFullDate ? "full-date" : "short-date",
    );

    if (!showFullDate && isToday(groupDate)) {
      title = `Today - ${title}`;
    }

    return {
      key: dateValue,
      title,
      items: grouppedItems[dateValue],
    };
  });
});

const editedComment = ref<Comment | undefined>(undefined);

const startEdit = (id: T["id"]) => {
  const comment = props.items.find((item) => item.id === id);
  editedComment.value = comment;
};

const submitEdit = (comment: CommentUpdate) => {
  emit("edit", comment);
  editedComment.value = undefined;
};

const cancelEdit = () => {
  editedComment.value = undefined;
};

onBeforeUnmount(() => {
  emit("update-last-visit");
});

defineExpose({
  startEdit,
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.error {
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: spacing.$s;
  color: colors.$ad-error;
  font: typography.$caption_medium;
}

.container {
  width: 100%;
  min-height: 220px;
  max-height: v-bind(widgetMaxHeight);
  display: grid;
  grid-template-rows: min-content 1fr;
  overflow: hidden;
  gap: spacing.$xs;
}

.container_isReadOnly {
  grid-template-rows: 1fr;
}

.container_isCondensed {
  min-height: unset;
}

.body {
  flex: 1;
  position: relative;
  min-height: 0;
  max-height: 100%;
  overflow: hidden;

  :global(.el-scrollbar__bar.is-vertical) {
    right: 0px;
  }
}

.emptyMessage {
  display: flex;
  gap: spacing.$xs;
  align-items: center;
  font: typography.$body_regular;
  color: colors.$pr-400;
  padding-left: spacing.$s;
}

.commentIcon {
  color: colors.$pr-350;
}

.group + .group {
  padding-top: 20px;
}

.groupTitle {
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: spacing.$xs;
  height: 20px;
  margin-bottom: 10px;
  background-color: white;
  font: typography.$caption_regular;
  color: colors.$pr-500;

  &:after {
    content: "";
    display: block;
    flex: 1;
    height: 1px;
    background-color: colors.$pr-150;
  }
}

.groupTitleValue {
  white-space: nowrap;
}
</style>
