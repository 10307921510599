// similar to https://raw.githubusercontent.com/vueuse/vueuse/v10.7.0/packages/core/useDropZone/index.ts
// - patched to handle folders upload like ngFileUpload library does https://github.com/danialfarid/ng-file-upload
//    , so it returns folder in addition to files, and adds path property to files
// - also, it returns Promise<UploadItem[]> instead of File[] in callback

import { ref, unref } from "vue";
import { useEventListener } from "@vueuse/core";
import { isClient } from "@vueuse/shared";

import type { MaybeRef, Ref } from "vue";
// some types inlined since they are not exported
const notNullish = <T = any>(val?: T | null | undefined): val is T =>
  val != null;
type MaybeRefOrGetter<T> = MaybeRef<T> | (() => T);

export type UploadFolder = { type: "directory"; path: string; name: string };
export type UploadFile = File & { path: string };
export type UploadItem = UploadFile | UploadFolder;

export interface UseDropZoneReturn {
  isOverDropZone: Ref<boolean>;
}

export interface UseDropZoneOptions {
  /**
   * Allowed data types, if not set, all data types are allowed.
   * Also can be a function to check the data types.
   */
  dataTypes?: MaybeRef<string[]> | ((types: readonly string[]) => boolean);
  onDrop?: (files: Promise<UploadItem[]>, event: DragEvent) => void;
  onEnter?: (files: Promise<UploadItem[]>, event: DragEvent) => void;
  onLeave?: (files: Promise<UploadItem[]>, event: DragEvent) => void;
  onOver?: (files: Promise<UploadItem[]>, event: DragEvent) => void;
}

export async function extractFilesFromEvent(
  e: ClipboardEvent | DragEvent,
): Promise<UploadItem[]> {
  // return list of files in the same format as the ngFileUpload library, see ng-file-upload/src/drop.js:extractFiles
  const items: UploadItem[] = [];

  const scanFiles = async (
    entry: any,
    item: DataTransferItem,
    path: string,
  ) => {
    if (entry.isDirectory) {
      const folder: UploadFolder = {
        type: "directory",
        name: entry.name,
        path: path + entry.name,
      };
      items.push(folder);

      const directoryReader = entry.createReader();
      await new Promise<void>((resolve, reject) => {
        directoryReader.readEntries(async (entries: any) => {
          for (const entry of entries) {
            await scanFiles(entry, item, folder.path + "/");
          }

          resolve();
        });
      });
    } else if (entry) {
      const itemFile = item.getAsFile(); // * Safari can't handle entry.file with pasting
      const file =
        entry instanceof File
          ? entry
          : entry instanceof DataTransferItem
            ? entry.getAsFile()
            : await new Promise((resolve, reject) =>
                entry.file(resolve, (err: any) => resolve(itemFile)),
              );

      if (!file) return;
      const uploadFile = file as unknown as any;
      if (path) {
        uploadFile.path = path ? path + uploadFile.name : ""; // ngFileUpload library adds full path to files inside folders
      }
      items.push(uploadFile as UploadFile);
    }
  };

  if (
    e instanceof DragEvent &&
    e.dataTransfer!.files &&
    !e.dataTransfer!.items
  ) {
    for (let i = 0; i < e.dataTransfer!.files.length; i++) {
      const file = e.dataTransfer!.files[i] as unknown as any;
      file.path = file.name;
      items.push(file as UploadFile);
    }
  } else {
    const items = ((e as DragEvent).dataTransfer ||
      (e as ClipboardEvent).clipboardData)!.items;

    const promises: Promise<void>[] = [];
    for (let i = 0; i < items.length; ++i) {
      const item: DataTransferItem = items[i];
      if (item.kind === "file") {
        const entry = item.webkitGetAsEntry() || item.getAsFile();
        promises.push(scanFiles(entry, item, ""));
      }
    }

    await Promise.all(promises);
  }

  return items;
}

export function useDropZone(
  target: MaybeRefOrGetter<HTMLElement | null | undefined>,
  options: UseDropZoneOptions | UseDropZoneOptions["onDrop"] = {},
): UseDropZoneReturn {
  const isOverDropZone = ref(false);
  let counter = 0;
  let isDataTypeIncluded = true;
  if (isClient) {
    const _options =
      typeof options === "function" ? { onDrop: options } : options;
    const getFiles = async (event: DragEvent) => {
      return await extractFilesFromEvent(event);
    };

    useEventListener<DragEvent>(target, "dragenter", (event) => {
      const types = Array.from(event?.dataTransfer?.items || [])
        .map((i) => (i.kind === "file" ? i.type : null))
        .filter(notNullish);

      if (_options.dataTypes && event.dataTransfer) {
        const dataTypes = unref(_options.dataTypes);
        isDataTypeIncluded =
          typeof dataTypes === "function"
            ? dataTypes(types)
            : dataTypes
              ? dataTypes.some((item) => types.includes(item))
              : true;
        if (!isDataTypeIncluded) return;
      }
      event.preventDefault();
      counter += 1;
      isOverDropZone.value = true;
      _options.onEnter?.(getFiles(event), event);
    });
    useEventListener<DragEvent>(target, "dragover", (event) => {
      if (!isDataTypeIncluded) return;
      event.preventDefault();
      _options.onOver?.(getFiles(event), event);
    });
    useEventListener<DragEvent>(target, "dragleave", (event) => {
      if (!isDataTypeIncluded) return;
      event.preventDefault();
      counter -= 1;
      if (counter === 0) isOverDropZone.value = false;
      _options.onLeave?.(getFiles(event), event);
    });
    useEventListener<DragEvent>(target, "drop", (event) => {
      event.preventDefault();
      counter = 0;
      isOverDropZone.value = false;
      _options.onDrop?.(getFiles(event), event);
    });
  }

  return {
    isOverDropZone,
  };
}
