import { h } from "vue";

import BaseMgmtTableColumns from "@drVue/components/management/baseTable/baseMgmtTableColumns";
import MgmtLinkTableCell from "../baseTable/MgmtLinkTableCell.vue";

import type { ClientRow } from "./types";
import type { DrVxeTableColumn } from "@drVue/components/types";

export default class TableColumns extends BaseMgmtTableColumns<ClientRow> {
  getBaseColumns(): DrVxeTableColumn<ClientRow>[] {
    return [
      this.name(),
      this.simpleTextColumn("Public Name", "public_name"),
      this.simpleTextColumn("Payment", "payment_type"),
    ];
  }

  getExtraColumn(column: string): DrVxeTableColumn<ClientRow> {
    return this.simpleTextColumn(`Unknown ${column}`, "");
  }

  private name(): DrVxeTableColumn<ClientRow> {
    return {
      sortable: true,
      field: "private_name",
      title: "Private Name",
      fixed: "left",
      minWidth: 170,
      slots: {
        default: ({ row }) => {
          const link = `#/clients/${row.id}`;
          const label = row.private_name;
          return [h(MgmtLinkTableCell, { label, link })];
        },
      },
    };
  }
}
