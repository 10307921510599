import { ApiService } from "@drVue/api-service";

import type { TaskStatus } from "@drVue/store/modules/room/tasks-statuses/types";

export class TasksStatusesApiService extends ApiService {
  public list(): Promise<TaskStatus[]> {
    const url = this.Urls["api:client-dashboard:statuses-list"]();
    return this.get(url);
  }

  public upd(status: TaskStatus): Promise<TaskStatus> {
    const url = this.Urls["api:client-dashboard:statuses-detail"](status.id);
    return this.put(url, status);
  }

  public add(status: TaskStatus): Promise<TaskStatus> {
    const url = this.Urls["api:client-dashboard:statuses-list"]();
    return this.post(url, status);
  }

  public del(statusId: number): Promise<void> {
    const url = this.Urls["api:client-dashboard:statuses-detail"](statusId);
    return this.delete<string>(url).then((r: string) => undefined);
  }
}
