<template>
  <ElDialog
    v-model="isDialogVisible"
    title="Processing Errors"
    width="600px"
    :append-to-body="true"
    @close="hideDialog"
  >
    <div class="errors-dialog">
      <div v-if="!taskErrors">Can't find the task... Please, try again.</div>
      <div v-else>
        <p>
          There were errors during the bulk processing. You can still download
          your archive via <a :href="getDownloadUrl(taskId)">this link</a> but
          these files will not be included:
        </p>
        <div class="errors-dialog__errors">
          <div
            v-for="e in taskErrors"
            :key="e"
            class="errors-dialog__errors-item"
          >
            {{ e }}
          </div>
        </div>
      </div>
    </div>
  </ElDialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { Urls } from "@setups/urls";

import type { Download } from "@drVue/store/modules/room/downloads/DownloadsApiService";

interface Data {
  taskId: string;
  isDialogVisible: boolean;
}

export default defineComponent({
  name: "RoomDownloadDialog",
  data(): Data {
    return {
      taskId: "",
      isDialogVisible: false,
    };
  },
  computed: {
    taskErrors(): any {
      const task = this.$store.state.room.downloads.downloads.find(
        (d: Download) => d.task_id === this.taskId,
      );

      return task ? task.log : null;
    },
  },
  methods: {
    getDownloadUrl(taskId: string) {
      return Urls["api:room:documents_download_bulk_archive"](taskId);
    },
    showDialog(taskId: string) {
      this.taskId = taskId;
      this.isDialogVisible = true;
    },
    hideDialog() {
      this.taskId = "";
      this.isDialogVisible = false;
    },
  },
});
</script>
