/**
 * Here are constants and types common to all "dr-common" components.
 * All in accordance with the style guide.
 */

/** Unified size grid according to the style guide */
export enum SIZES {
  xxs = "xxs",
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  xxl = "xxl",
}

export type Size = keyof typeof SIZES;

/** A set of colors for contrasting highlighting different elements/variants of the same entity */
export const LEGEND_COLORS = [
  "#447AEB",
  "#6955E3",
  "#75D266",
  "#E5BB23",
  "#A523D2",
  "#ED3AD0",
  "#3CC3ED",
  "#28CFB1",
  "#7CAE54",
  "#D9742B",
  "#E75333",
  "#F03578",
];

/** Unified popups' size grid according to the style guide */
export const POPUP_SIZES = {
  form: {
    width: 280,
  },
  tree: {
    width: 356,
    height: 508,
  },
  wideTree: {
    width: 516,
    height: 508,
  },
  folderMenu: {
    width: 440,
    height: 508,
  },
} as const;
