TasksFilterService.$inject = ["$rootScope"];
import { watch } from "vue";

import tasksFilterService from "@app/ng/tasks/services/ts/TasksFilterService";

export default function TasksFilterService($rootScope) {
  watch(tasksFilterService.filters, () => $rootScope.$applyAsync());

  return tasksFilterService;
}
