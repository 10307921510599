angular
  .module("dealroom.analytics.service.nest", [
    "dealroom.analytics.service.labels",
    "dealroom.analytics.service.data",

    "dealroom.task",
    "dealroom.documents",
    "dealroom.members",
  ])
  .service("ActivityNestService", ActivityNestService);

ActivityNestService.$inject = [
  "ActivityCategoriesService",
  "ActivityTasksService",
  "ActivityDocumentsService",
  "ActivityLabelService",
  "DocumentsService",
  "MembersService",
  "TasksService",
  "CategoriesService",
];
function ActivityNestService(
  ActivityCategoriesService,
  ActivityTasksService,
  ActivityDocumentsService,
  ActivityLabelService,
  DocumentsService,
  MembersService,
  TasksService,
  CategoriesService,
) {
  const service = {
    nest: mkNest,
    NO_GROUP: "0",
  };

  const dformat = d3.timeFormat("%a %B %d, %Y");

  const nestByFuncs = {
    dt: (d) => {
      var dt = new Date(d.dt);
      return dformat(dt);
    },
  };

  nestByFuncs.document = (d) => {
    const docId = ActivityLabelService.itemId(d, "document");
    // const doc = DocumentsService.Files[docId];
    // if (!isValidItem(doc)) return 0;
    return docId || service.NO_GROUP;
  };

  nestByFuncs.folder = (d) => {
    const item = ActivityLabelService.item(d);
    // if (!isValidItem(item)) return 0;

    if (item && item.type === "folder") {
      return item.id;
    } else if (item && item.type === "document") {
      const doc = DocumentsService.Files[item.id];
      // if (!isValidItem(doc)) return 0;
      if (!doc) return service.NO_GROUP;
      // if (doc.folder_id === DocumentsService.rootFolder.id) return doc.id;
      // const folder = DocumentsService.Folders[doc.folder_id];
      // if (!folder || folder.is_archived || folder.is_removed) return 0;
      return doc.folder_id;
    }
    return service.NO_GROUP;
  };

  nestByFuncs.rootFolder = (d) => {
    const item = ActivityLabelService.item(d);
    if (!item || ["document", "folder"].indexOf(item.type) === -1) return 0;
    const key = item.type === "document" ? "docId" : "folderId";
    return DocumentsService.itemIdToRootFolderId({ [key]: item.id }) || 0;
  };

  nestByFuncs.task = (d) => {
    const taskId = ActivityLabelService.itemId(d, "task");
    // const task = TasksService.tasks[taskId];
    // if (!isValidItem(task)) return 0;
    return taskId || service.NO_GROUP;
  };

  nestByFuncs.category = (d) => {
    const taskId = ActivityLabelService.itemId(d, "task");
    const task = TasksService.tasks[taskId];
    // if (!isValidItem(task)) return 0;
    return task ? task.category_id : service.NO_GROUP;
  };

  nestByFuncs.rootCategory = (d) => {
    if (!d.target) return service.NO_GROUP;

    const rootCategoriesIds = ActivityCategoriesService.rootCategoriesIds;
    if (!rootCategoriesIds) return service.NO_GROUP;

    if (/^task/.test(d.verb)) {
      const taskId = ActivityLabelService.itemId(d, "task");
      const task = ActivityTasksService.tasks[taskId];
      if (!task) return service.NO_GROUP;
      return CategoriesService.childIdToRoot[task.category_id];
    } else if (/^document$/.test(d.target.type)) {
      const docId = +d.target.id;
      const rootCategoryIds = ActivityCategoriesService.getDocCategories(docId);
      // TODO: what to do if a doc attached to several lists?
      return rootCategoryIds.length > 0 ? rootCategoryIds[0] : service.NO_GROUP;
    }
    return service.NO_GROUP;
  };

  nestByFuncs.group = function (d) {
    var member = MembersService.members[d.user];
    return member.pgroup.id;
    // return (member && member.is_archived !== true) ? member.pgroup.id : 0;
  };

  nestByFuncs.user = function (d) {
    return d.user || service.NO_GROUP;
  };

  return service;

  function mkNest(batch, fname, rollup) {
    const nestByFunc = nestByFuncs[fname] || nestByFuncs.dt;
    const nest = d3.nest().key(nestByFunc).rollup(rollup).entries(batch);

    return nest;
  }
}
