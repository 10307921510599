const CELLS = [
  {
    label: "Time (min)",
    class: "datagrid-table__column--data-timespent",
    verbs: ["document_viewed"],
    src: "time",
    getDisplayedValue: Math.round,
  },
  {
    label: "Documents Views",
    class: "",
    childs: [
      {
        label: "Users",
        class: "datagrid-table__column--data-uniq",
        verbs: ["document_viewed"],
        src: "uniq:user",
      },
      {
        label: "Total",
        class: "datagrid-table__column--data-total",
        verbs: ["document_viewed"],
        src: "total",
      },
    ],
  },
  {
    label: "Documents Uploaded",
    class: "",
    childs: [
      {
        label: "Users",
        class: "datagrid-table__column--data-uniq",
        verbs: ["document_upload", "document_overwrite"],
        src: "uniq:user",
      },
      {
        label: "Total",
        class: "datagrid-table__column--data-total",
        verbs: ["document_upload", "document_overwrite"],
        src: "total",
      },
    ],
  },
  {
    label: "Documents Downloaded",
    class: "",
    childs: [
      {
        label: "Users",
        class: "datagrid-table__column--data-uniq",
        verbs: ["document_download", "document_bulk_download"],
        src: "uniq:user",
      },
      {
        label: "Total",
        class: "datagrid-table__column--data-total",
        verbs: ["document_download", "document_bulk_download"],
        src: "total",
      },
    ],
  },
];
export default CELLS;
