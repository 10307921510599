import axios from "axios";

import { isAuthenticationErrorResponse } from "@setups/urls";
import { $notifyDanger } from "@drVue/common";
import {
  DownloadsApiService,
  DownloadStatus,
} from "@drVue/store/modules/room/downloads/DownloadsApiService";

import type { DownloadsState } from "./DownloadsState";
import type { Download } from "@drVue/store/modules/room/downloads/DownloadsApiService";
import type { RootState } from "@drVue/store/state";
import type { ActionContext, ActionTree } from "vuex";

export type IContext = ActionContext<DownloadsState, RootState>;

export interface SetNeedEmailPayload {
  taskId: string;
  needEmail: boolean;
}

const api = new DownloadsApiService();

interface DownloadsActionTree {
  getDownloads(context: IContext, keepTaskId?: string): Promise<void>;
  setNeedEmail(context: IContext, payload: SetNeedEmailPayload): Promise<void>;
}

let needRefreshDownloadsOnComplete: boolean = false;

export const downloadsActions: ActionTree<DownloadsState, RootState> &
  DownloadsActionTree = {
  async getDownloads({ state, commit, dispatch }, keepTaskId?) {
    if (state.isLoading) {
      needRefreshDownloadsOnComplete = true;
      return;
    }

    commit("setIsLoading", true);
    try {
      let downloads = await api.getDownloads();
      downloads = downloads.filter((d) => {
        d.date_created = new Date(d.date_created);

        if (d.status === DownloadStatus.Success) {
          if (keepTaskId === d.task_id) {
            return true;
          }
          return state.downloads.some((_d) => _d.task_id === d.task_id);
        }

        return true;
      });

      downloads.sort(
        (a: Download, b: Download) =>
          a.date_created.getTime() - b.date_created.getTime(),
      );

      commit("setDownloads", downloads);
    } catch (error) {
      let skipErrorAlert = false;

      if (axios.isAxiosError(error)) {
        // no need to show error alert, user will be redirected to login page
        skipErrorAlert =
          !!error.response && isAuthenticationErrorResponse(error.response);
      }

      if (!skipErrorAlert) {
        $notifyDanger("Failed to load bulk downloads information", {
          tag: "failed_to_load_bulk_downloads",
        });
      }
    } finally {
      commit("setIsLoading", false);

      if (needRefreshDownloadsOnComplete) {
        needRefreshDownloadsOnComplete = false;
        dispatch("getDownloads");
      }
    }
  },

  async setNeedEmail(
    { state, commit, dispatch }: IContext,
    payload: SetNeedEmailPayload,
  ) {
    try {
      const download = await api.setNeedEmail(
        payload.taskId,
        payload.needEmail,
      );

      commit(
        "setDownloads",
        state.downloads.map((d) =>
          d.task_id === payload.taskId ? download : d,
        ),
      );
    } catch (error) {
      $notifyDanger(
        `Failed to ${
          payload.needEmail ? "enable" : "disable"
        } download notification email`,
      );

      throw error;
    }
  },
};
