import { defineStore } from "pinia";

import { generateList } from "@drVue/store/pinia/helpers/generators";
import { AiReportsApiService } from "./api";

import type { AiReport, AiReportField } from "./types";

const api = new AiReportsApiService();

export const useAiReportsStore = defineStore("roomAiReports", () => {
  const {
    list,
    dict,
    load,
    create,
    remove,
    update,
    isLoading,
    isLoadError,
    applyChanges,
  } = generateList<AiReport, Pick<AiReport, "label">>({
    name: "Room ai reports",
    load: api.loadReports.bind(api),
    create: api.createReport.bind(api),
    remove: api.deleteReport.bind(api),
    update: api.updateReport.bind(api),
  });

  const deleteReportField = async (
    reportId: AiReport["id"],
    fieldId: AiReportField["id"],
  ) => {
    const report = dict.value[reportId];
    if (!report) {
      console.error(`Report with id ${reportId} not found`);
      return;
    }
    return api.deleteReportField(reportId, fieldId).then(() => {
      const currenReport = dict.value[reportId];
      const fields = currenReport.fields.filter(
        (field) => field.id !== fieldId,
      );
      applyChanges({ id: reportId, fields });
    });
  };

  const generateAndCreateNewField = async (
    reportId: AiReport["id"],
    fieldLabel: AiReportField["label"],
  ) => {
    const report = dict.value[reportId];
    if (!report) {
      console.error(`Report with id ${reportId} not found`);
      return;
    }
    const payload = {
      label: fieldLabel,
    };
    return api.generateAndCreateReportField(reportId, payload).then((field) => {
      const currenReport = dict.value[reportId];
      const fields = [...currenReport.fields, field];
      applyChanges({ id: reportId, fields });
    });
  };

  return {
    list,
    dict,
    isLoading,
    isLoadError,
    load,
    create,
    remove,
    update,
    applyChanges,
    deleteReportField,
    generateAndCreateNewField,
  };
});
