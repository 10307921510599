<template>
  <div>
    <DrLoader v-if="isLoading" />
    <div v-else>
      <div v-if="emails.length">
        <Email
          v-for="email in searchedEmails"
          :key="email.id"
          :email="email"
          :editable="editable"
          @email-deleted="$emit('email-deleted', $event)"
        />
      </div>

      <div v-else-if="emails.length === 0" :class="$style.empty">
        <i class="fas fa-inbox" :class="$style.inboxIcon" />
        <div :class="$style.title">
          This {{ readableType }} doesn't have any linked emails
        </div>
        <div :class="$style.description">
          Use our
          <a
            href="https://support.dealroom.net/en/article/how-do-i-setup-and-use-the-outlook-plugin-1ume56u/"
            target="_blank"
          >
            Outlook Add-In
          </a>
          to log emails to DealRoom.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import DrLoader from "@shared/ui/dr-loader";

import { Document, flexSearchAllResultsArray } from "@app/flex";
import Email from "./Email.vue";

import type { EmailModel } from "@shared/ui/emails-list/EmailModel";

interface Props {
  isLoading: boolean;
  emails: EmailModel[];
  entityType: "Deal" | "User";
  editable?: boolean;
  searchQuery: string;
}

const props = withDefaults(defineProps<Props>(), {
  editable: false,
});

interface Emits {
  (e: "email-deleted", emailId: string): void;
}

defineEmits<Emits>();

const readableType = computed(() => {
  switch (props.entityType) {
    case "Deal":
      return "deal";
    case "User":
    default:
      return "person";
  }
});

const emailsMap = computed(() =>
  props.emails.reduce(
    (acc, email) => {
      acc[email.id] = email;
      return acc;
    },
    {} as Record<EmailModel["id"], EmailModel>,
  ),
);

const searchedEmails = computed(() => {
  const searchValue = props.searchQuery.toLowerCase().trim();
  if (searchValue.length) {
    const flexIndex = new Document({
      document: {
        id: "id",
        index: [
          {
            field: "rawSubject",
            charset: "latin:advanced",
            tokenize: "full",
          },
          {
            field: "bodyText",
            charset: "latin:advanced",
            tokenize: "full",
          },
          {
            field: "from:email",
            charset: "latin:advanced",
            tokenize: "full",
          },
          {
            field: "to[]:email",
            charset: "latin:advanced",
            tokenize: "full",
          },
        ],
      },
    });

    props.emails.forEach((email) => flexIndex.add(email));
    const foundIds = flexSearchAllResultsArray(flexIndex, searchValue);

    return foundIds.map((id) => emailsMap.value[id]);
  }

  return props.emails;
});
</script>

<style module lang="scss">
@use "/app/styles/scss/_colors.scss";

.empty {
  height: 100px;
  max-width: 356px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: 75px auto 0;
}

.inboxIcon {
  font-size: 40px;
  color: colors.$pr-800;
  margin-bottom: 30px;
}

.title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
  text-align: center;
}

.description {
  text-align: center;
}
</style>
