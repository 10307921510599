import { cloneDeep } from "lodash-es";

import { CustomViewObjectTypes } from "@setups/types";
import {
  DEFAULT_DATAROOM_VIEW,
  DEFAULT_FINDING_VIEW,
  DEFAULT_SYNERGY_VIEW,
  DEFAULT_TASK_TABLE_VIEW,
} from "@app/vue/store/modules/common/custom-views/default";

import type { RootState } from "@drVue/store/state";
import type { CustomView, CustomViewColumn } from "@setups/types";
import type { Store } from "vuex";

// TODO: refactor, mostly copy pasted from frontend/app/vue/components/room/documents/utils.ts:5

function updateColumns(columns: CustomViewColumn[], update: CustomViewColumn) {
  // applyPathToColumns
  const col = columns.find((c) => c.field === update.field);
  if (col) {
    const i = columns.indexOf(col);
    columns.splice(i, 1);
  }
  columns.push({
    ...(col || {}),
    ...update,
  });
}

export function handleUpdates(
  columns: CustomViewColumn[],
  updates: CustomViewColumn | CustomViewColumn[],
) {
  // patch "columns" array by applying changes from "updates"
  if (Array.isArray(updates)) {
    updates.forEach((u) => updateColumns(columns, u));
  }

  return columns;
}

export function saveView($store: Store<RootState>, view: CustomView) {
  const toSaveView = cloneDeep(view);
  const method = view.slug && view.is_personal ? "update" : "create";
  toSaveView.is_personal = true;
  $store.dispatch(`common/customViews/${method}`, toSaveView);
}

export function saveColumnsConfig(
  $store: Store<RootState>,
  updates: CustomViewColumn[],
  objectType: CustomViewObjectTypes,
) {
  const view: CustomView =
    $store.getters["common/customViews/defaultView"](objectType);

  handleUpdates(view.settings.columns!, updates);

  saveView($store, view);
}

function getOrgDefaultView(
  $store: Store<RootState>,
  type: CustomViewObjectTypes,
): CustomView {
  const views: CustomView[] =
    $store.getters["common/customViews/byObjectType"](type);

  const orgView = views.filter((v) => !v.is_personal)[0];
  if (orgView) return orgView;

  switch (type) {
    case CustomViewObjectTypes.DataRoom:
      return DEFAULT_DATAROOM_VIEW;
    case CustomViewObjectTypes.Finding:
      return DEFAULT_FINDING_VIEW;
    case CustomViewObjectTypes.Synergy:
      return DEFAULT_SYNERGY_VIEW;
    case CustomViewObjectTypes.Task:
      return DEFAULT_TASK_TABLE_VIEW;
    case CustomViewObjectTypes.Deal:
    case CustomViewObjectTypes.GroupPermission:
    case CustomViewObjectTypes.User:
    case CustomViewObjectTypes.ValueDriver:
    case CustomViewObjectTypes.ValueDriverPeriodData:
    default:
      throw new Error(`No default view for ${type}`);
  }
}

export function resetColumnsConfig(
  $store: Store<RootState>,
  type: CustomViewObjectTypes,
) {
  const currentView: CustomView =
    $store.getters["common/customViews/defaultView"](type);

  const defaultView = getOrgDefaultView($store, type);

  currentView.settings.columns!.splice(0, currentView.settings.columns!.length);
  defaultView.settings.columns!.forEach((c) =>
    currentView.settings.columns!.push(c),
  );

  saveView($store, currentView);
}
