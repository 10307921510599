import { participantsContains } from "./helpers/participantsContains";

import type { TaskPredicate } from "@app/ng/tasks/services/ts/types/TaskPredicate";

export const assignees: TaskPredicate = (task, filters) => {
  if (!task.assignees) return true;

  if (filters.assignees.length === 0 && !filters.showUnassigned) return true;
  if (task.assignees.length === 0) return filters.showUnassigned;

  return participantsContains(task.assignees, filters.assignees);
};
