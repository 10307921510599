<template>
  <div
    :class="$style.wrapper"
    :style="{
      width: panelWidth,
      height: panelHeight,
    }"
  >
    <div
      v-if="
        !!title ||
        $slots['title'] ||
        $slots['title-action'] ||
        $slots['subtitle']
      "
      :class="{
        [$style.header]: true,
        [$style.header_isHighlighted]: highlightHeader,
      }"
    >
      <template v-if="!!title || $slots['title'] || $slots['title-action']">
        <div :class="$style.title">
          <slot name="title">{{ title }}</slot>
        </div>
        <div :class="$style.titleAction">
          <slot name="title-action" />
        </div>
      </template>

      <div v-if="$slots['subtitle']" :class="$style.subtitle">
        <slot name="subtitle" />
      </div>

      <div v-if="$slots['favorites']" :class="$style.favorites">
        <slot name="favorites" />
      </div>
    </div>

    <div :class="$style.body">
      <ElScrollbar
        ref="scrollbar"
        max-height="100%"
        :view-class="$style.bodyInner"
        @scroll="handleScroll"
      >
        <slot />
      </ElScrollbar>
    </div>

    <div
      v-if="$slots['footer-left'] || $slots['footer-right']"
      :class="$style.footer"
    >
      <div :class="$style.footerSection">
        <slot name="footer-left" />
      </div>
      <div :class="$style.footerSection">
        <slot name="footer-right" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
/**
 * This component provides a unified "layout" of panel with controls.
 *
 * Designed to take up the entire height and width of the parent block.
 * The main area (body) has a custom scrollbar style.
 * It has an optional "header" block, which is highlighted with a shadow
 * when scrolling content in the main area (body).
 */

import { ElScrollbar } from "element-plus"; // In timeline el-components are required to be explicitly imported
import { computed, ref } from "vue";

interface Props {
  title?: string;
  width?: number;
  height?: number;
}

const props = withDefaults(defineProps<Props>(), {
  title: "",
  width: 0,
  height: 0,
});

/** Set 320px as min width  */
const panelWidth = computed(() => `${Math.max(props.width || 0, 320)}px`);
const panelHeight = computed(() =>
  props.height ? `${props.height}px` : "100%",
);

const scroll = ref(0);
const handleScroll = ({ scrollTop }: { scrollTop: number }) => {
  scroll.value = scrollTop;
};
const highlightHeader = computed(() => {
  return scroll.value > 0;
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.header {
  display: grid;
  grid-template-columns: min-content min-content;
  justify-content: space-between;
  align-items: center;
  gap: 12px 10px;
  z-index: 100;
  padding: spacing.$s;
  background-color: #fff;
  border-bottom: solid 1px colors.$pr-150;
  border-top-left-radius: var(--el-popover-border-radius);
  border-top-right-radius: var(--el-popover-border-radius);
}

.header_isHighlighted {
  box-shadow: 0px 2px 10px rgba(9, 25, 56, 0.04);
}

.title {
  font: typo.$body_semibold;
  color: colors.$pr-900;
  white-space: nowrap;
}

.titleAction {
  color: inherit;
}

.subtitle {
  grid-column-start: 1;
  grid-column-end: 3;
}

.favorites {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 3;
  padding: spacing.$xs 0;
  margin-bottom: -(spacing.$s);

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -(spacing.$s);
    right: -(spacing.$s);
    border-top: solid 1px colors.$pr-150;
  }
}

.body {
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
}

.bodyInner {
  padding: spacing.$s;
}

.footer {
  display: grid;
  grid-template-columns: min-content min-content;
  justify-content: space-between;
  align-items: center;
  gap: spacing.$s;
  padding: spacing.$s;
  border-top: solid 1px colors.$pr-150;
}

.footerSection {
  flex: 0 0 auto;
  white-space: nowrap;
}
</style>
