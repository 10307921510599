<template>
  <ElDrawer
    v-model="isVisible"
    class="el-drawer--narrow"
    append-to-body
    :with-header="false"
    :show-close="false"
  >
    <ContentPanelClose @close="close" />
    <ContentPanelHeader>
      Create announcement
      <template #description>
        Create and send a message to selected groups. They will receive a
        notification.
      </template>
    </ContentPanelHeader>
    <div :class="$style.container">
      <ElForm
        ref="form"
        v-loading="isSubmitting"
        :model="formData"
        label-position="top"
        :rules="validationRules"
      >
        <ElFormItem label="Room" prop="room">
          <ElInput
            v-if="insideRoom"
            disabled
            :model-value="selectedRoom?.title"
          />
          <template v-else>
            <!-- @vue-ignore -->
            <ElSelect
              v-model="formData.room"
              placeholder="Select Room"
              clearable
              :append-to-body="'body'"
              value-key="id"
              class="el-select--full-width"
              filterable
            >
              <ElOption
                v-for="room in rooms"
                :key="room.id"
                :label="room.title"
                :value="room"
              />
            </ElSelect>
          </template>
        </ElFormItem>
        <ElFormItem label="Recipients" prop="groups">
          <!-- @vue-ignore -->
          <ElSelect
            v-model="formData.groups"
            multiple
            value-key="id"
            placeholder="Select Groups"
            class="el-select--full-width"
            :disabled="!selectedRoom"
            filterable
          >
            <ElOption
              v-for="group in roomGroups"
              :key="group.id"
              :label="group.name"
              :value="group"
            />
          </ElSelect>
        </ElFormItem>
        <ElFormItem label="Message" prop="message">
          <DrEditor v-model="formData.message" />
        </ElFormItem>
        <ElFormItem class="el-form-item--pull-right">
          <ElButton @click="close">Cancel</ElButton>
          <ElButton type="primary" @click="postAnnouncement()">
            Send message
          </ElButton>
        </ElFormItem>
      </ElForm>
    </div>
  </ElDrawer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ContentPanelClose from "@shared/ui/content-panel/close.vue";
import ContentPanelHeader from "@shared/ui/content-panel/header.vue";
import DrForm from "@shared/ui/dr-form";
import { DrEditor } from "@shared/ui/editor";

import { ROOM_DATA } from "@setups/data";
import { $notifyInfo } from "@drVue/common";
import { NotificationsApiService } from "@drVue/store/modules/common/notifications/api";

import type {
  Room,
  RoomGroup,
} from "@drVue/store/modules/client-dashboard/deals/types";
import type {
  PostAnnouncementApiData,
  ProseMirrorDocument,
} from "@drVue/store/modules/common/notifications/api";
import type { RoomData } from "@setups/data";
import type { ElForm, FormRules } from "element-plus";

interface PostAnnouncementFormData {
  room: Room | null;
  groups: RoomGroup[] | null;
  message: ProseMirrorDocument | null;
}

interface Data {
  formData: PostAnnouncementFormData;
  isVisible: boolean;
  api: NotificationsApiService;
}

export default defineComponent({
  name: "PostAnnouncement",
  components: {
    ContentPanelClose,
    ContentPanelHeader,
    DrEditor,
  },
  extends: DrForm,
  emits: ["announcement-posted"],
  data(): Data {
    return {
      formData: {
        message: null,
        groups: null,
        room: null,
      },
      isVisible: false,
      api: new NotificationsApiService(),
    };
  },
  computed: {
    insideRoom(): boolean {
      return ROOM_DATA.id !== undefined;
    },
    validationRules(): FormRules {
      return {
        room: [
          {
            required: !this.insideRoom,
            trigger: "blur",
            message: "Please select a room",
          },
        ],
        groups: [
          {
            required: true,
            trigger: "blur",
            message: "Please select groups",
          },
        ],
        message: [
          {
            required: true,
            trigger: "blur",
            message: "Message is required",
          },
        ],
      };
    },
    selectedRoom(): Room | RoomData | null {
      if (this.insideRoom) {
        return ROOM_DATA;
      }
      return this.formData.room;
    },
    selectedGroups(): RoomGroup[] | null {
      return this.formData.groups;
    },
    rooms(): Room[] {
      return this.$store.getters["clientDashboard/deals/getRooms"].filter(
        (r: Room) => r.access.administrator,
      );
    },
    roomGroups(): any {
      if (this.insideRoom) {
        return this.$store.state.room.groups.pgroupsList;
      } else {
        if (!this.selectedRoom) {
          return [];
        } else {
          return (this.selectedRoom as Room).groups;
        }
      }
    },
  },
  methods: {
    getForm() {
      return this.$refs.form as typeof ElForm;
    },
    open() {
      this.formData = {
        message: null,
        groups: null,
        room: null,
      };

      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    },
    postAnnouncement() {
      if (this.isSubmitting) return;

      this.getForm()
        .validate()
        .then(() => {
          const requestData = {
            room_id: this.selectedRoom!.id,
            recipients_groups: this.selectedGroups!.map((g) => g.id),
            message: this.formData.message,
          } as PostAnnouncementApiData;

          const postPromise = this.api.postAnnouncement(requestData);
          this.submitPromise(postPromise, "Failed to post announcement.").then(
            () => {
              $notifyInfo("Announcement has been posted.");
              this.$emit("announcement-posted");
              this.close();
            },
          );
        });
    },
  },
});
</script>

<style lang="scss" module>
.container {
  padding: 20px;
}
</style>
