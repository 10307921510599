import "./tree";
import "./rows";
import "./filter";

angular
  .module("dealroom.analytics.datagrid-table.service.nest", [
    "dealroom.analytics.datagrid-table.service.nest.tree",
    "dealroom.analytics.datagrid-table.service.nest.rows",
    "dealroom.analytics.datagrid-table.service.nest.rows",
    "dealroom.analytics.datagrid-table.service.filter",
  ])
  .service("AnalyticsDatagridNestService", AnalyticsDatagridNestService);

AnalyticsDatagridNestService.$inject = [
  "AnalyticsDatagridNestRowsService",
  "AnalyticsDatagridNestTreeService",
];
function AnalyticsDatagridNestService(
  AnalyticsDatagridNestRowsService,
  AnalyticsDatagridNestTreeService,
) {
  return { nest };

  function nest($ctrl) {
    const ROW_FACTORY = AnalyticsDatagridNestRowsService($ctrl);
    const TREE = AnalyticsDatagridNestTreeService.create($ctrl);

    const LAST_TYPE = $ctrl.nestByArray[$ctrl.nestByArray.length - 1];
    const LIMIT_TO = +$ctrl.limitTo === 0 ? undefined : $ctrl.limitTo;
    const GET_ROW_FROM_ITEM = $ctrl.getRowFromItem;

    return itemsToRow($ctrl.getRootItems());

    function itemsToRow(items, paddingLevel = 1) {
      return items.reduce((bucket, item, i) => {
        const row = getRow(item, i);
        if (row !== null) bucket.push(row);
        return bucket;
      }, []);

      function getRow(item, order) {
        const row = _getRow(item, order);
        if (row === null) return null;

        console.assert(row.type !== undefined, row, item);

        row.itemId = `${row.type === "dt" ? row.parentId + "-" : ""}${
          row.type
        }-${row.id}`;
        row.order = order;
        row.paddingLevel = paddingLevel;
        row.cells = ROW_FACTORY.cellsToRow(
          row,
          row.type !== "dt" ? TREE[row.type][row.id] : item.cellsValues,
        );

        if (row.type === "dt") {
          row.getSubRows = getActivityRows(item.activity);
        } else {
          let getOrderedChilds;
          if (row.type === LAST_TYPE && TREE["dt"][row.id] !== undefined) {
            getOrderedChilds = getOrderedDays(row.id, paddingLevel + 1);
          } else {
            getOrderedChilds = row.getOrderedChilds;
          }
          row.getSubRows = getSubRows(item, getOrderedChilds, paddingLevel + 1);
        }

        delete row.getOrderedChilds;

        return row;

        function _getRow(item, order) {
          if (item.type === "dt") {
            return {
              dt: item.key,
              id: item.key,
              type: "dt",
              parentId: item.parentId,
            };
          } else {
            const row = GET_ROW_FROM_ITEM(item);
            if (
              LIMIT_TO !== undefined &&
              order >= LIMIT_TO &&
              TREE[row.type][row.id] === undefined
            ) {
              return null;
            }
            return row;
          }
        }

        function getSubRows(item, getOrderedChilds, paddingLevel) {
          if (getOrderedChilds === undefined) return undefined;
          return function () {
            const childs = getOrderedChilds(item);
            return itemsToRow(childs, paddingLevel);
          };
        }

        function getActivityRows(activity) {
          if (activity.length === 0) return undefined;
          return function () {
            return activity.sort(dateAsc).map(ROW_FACTORY.getActionRow);

            function dateAsc(a, b) {
              return (a, b) => d3.ascending(a.dt, b.dt);
            }
          };
        }

        function getOrderedDays(parentId) {
          return function () {
            return TREE["dt"][parentId].sort(dateDesc);

            function dateDesc() {
              return (a, b) => d3.descending(new Date(a.key), new Date(b.key));
            }
          };
        }
      }
    }
  }
}
