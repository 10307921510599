<template>
  <div class="billing-interval-switch__wrapper">
    <div class="billing-interval-switch__container">
      <div
        v-for="interval in intervals"
        :key="interval"
        class="billing-interval-switch__value"
        :class="{ active: value === interval }"
        @click="$emit('change', interval)"
      >
        Billed {{ intervalLabel(interval) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { intervalLabel } from "./utils";

import type { PropType } from "vue";

export default defineComponent({
  name: "PaymentIntervalSwitch",
  props: {
    value: { required: true, default: "", type: String as PropType<string> },
    intervals: {
      required: true,
      type: Array as PropType<string[]>,
    },
  },
  emits: ["change"],
  methods: {
    intervalLabel(inverval: string) {
      return intervalLabel(inverval);
    },
  },
});
</script>
