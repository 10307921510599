<template>
  <ElPopover width="350" trigger="click" popper-class="el-popover--no-margin">
    <ElInput
      :model-value="fieldValue"
      clearable
      type="number"
      @input="(value) => (fieldValue = value)"
      @wheel.prevent
    />

    <template #reference>
      <DrToolbarFilterButton
        :label="field.label"
        :is-active="isActive"
        @clear="fieldValue = undefined"
      />
    </template>
  </ElPopover>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DrToolbarFilterButton } from "@shared/ui/dr-toolbar";

import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { CustomView } from "@setups/types";
import type { PropType } from "vue";

export default defineComponent({
  name: "NumberField",
  components: { DrToolbarFilterButton },
  props: {
    field: { required: true, type: Object as PropType<FieldItem> },
  },
  computed: {
    isActive(): any {
      return !!this.fieldValue;
    },
    fieldValue: {
      get: function (): any {
        const current: CustomView =
          this.$store.state.common.customViews.current;
        return current.filters.fields[this.field.key]?.value;
      },
      set: function (value: any) {
        this.$store.commit("common/customViews/setCurrentFilterValue", {
          field: this.field.key,
          value,
          op: "EQ",
        });
      },
    },
  },
});
</script>
