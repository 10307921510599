import { sortUploadItems, type UploadItem } from "./common/file-upload-helpers";
import getIconClass from "./common/mimetype";

import type {
  Batch,
  DrUploadDirectory,
  DrUploadFile,
} from "./vue/shared/ui/dr-upload-dialog/types";

interface FileInfo {
  file: File;
  path: string;
  name: string;
  size: number;
}

export class FileUploadHelper {
  #pathSep = "/";

  #dissalowFilenames = /(\.|\.DS_Store|\.empty|Thumbs\.db|desktop\.ini)$/i;

  isDrUploadDirectory(value: unknown): value is DrUploadDirectory {
    if (!value) return false;

    return (
      typeof value === "object" &&
      "type" in value &&
      "path" in value &&
      value.type === "directory" &&
      typeof value.path === "string"
    );
  }

  #getDirectoryPath(filepath: string) {
    return (
      filepath
        // Replace backslashes with forward slashes
        .replace(/\\/g, "/")
        // Replace last slash and everything after it with an empty string.
        // This will remove the file name.
        .replace(/\/[^/]*$/, "")
    );
  }

  #getFilePath(file: File | DrUploadFile) {
    if ("path" in file) return file.path;

    return file.webkitRelativePath;
  }

  #setFilePath(file: any, path: string) {
    if (this.isDrUploadDirectory(file)) return;

    file.path = path;

    Object.defineProperties(file, {
      webkitRelativePath: {
        value: path,
      },
    });
  }

  #getFileDirectoryPath(file: File | DrUploadFile) {
    const path = this.#getFilePath(file);

    let dirPath = this.#getDirectoryPath(path);

    // TODO: it looks like we don't need to remove them, there are no such cases.
    // remove leading and trailing slash
    dirPath = dirPath.replace(/^\//g, "").replace(/\/$/g, "");

    return dirPath;
  }

  #getParentPaths(folders: UploadItem[]) {
    // return obj there each path associated with parent path
    // NB. add missed parts of tree, like in case of paths=['1', '1/2/3'] add '1/2' as well
    const pathsTree: Record<string, string> = {}; //path: parent path

    const addPath = (path: string) => {
      if (path.length === 0) return;

      const parent = path.split(this.#pathSep).slice(0, -1).join(this.#pathSep);
      if (!Object.prototype.hasOwnProperty.call(pathsTree, parent)) {
        addPath(parent);
      }

      pathsTree[path] = parent;
    };

    folders.forEach((f) => addPath(f.path));

    return pathsTree;
  }

  #getRootPaths(pathParents: Record<string, string>) {
    return Object.keys(pathParents).filter((path) => pathParents[path] === "");
  }

  #getChildrenPaths(pathParents: Record<string, string>) {
    const children: Record<string, string[]> = {};

    Object.keys(pathParents).forEach((child) => {
      const parent = pathParents[child];
      if (parent === "") return;

      children[parent] = (children[parent] || []).concat([child]);
    });

    return children;
  }

  #replaceRootName(item: any, newRootName: string) {
    const path = this.isDrUploadDirectory(item)
      ? item.path
      : this.#getFilePath(item);

    const remainingPath = path
      .split(this.#pathSep)
      .slice(1)
      .join(this.#pathSep);

    const newPath = remainingPath
      ? newRootName + this.#pathSep + remainingPath
      : newRootName;

    if (this.isDrUploadDirectory(item)) {
      item.path = newPath;
      item.name = newPath;
    } else {
      this.#setFilePath(item, newPath);
    }
  }

  hasValidFilename(file: { name: string }) {
    return !this.#dissalowFilenames.test(file.name);
  }

  splitItemsToFoldersAndFiles(
    items: File[] | (DrUploadFile | DrUploadDirectory)[],
  ) {
    const directoriesPaths = items.reduce<string[]>((paths, item) => {
      // We have DrUploadDirectory only if files are uploaded via drag & drop.
      if (this.isDrUploadDirectory(item)) paths.push(item.path);
      return paths;
    }, []);

    // We are trying our best to determine if we have directories in the list of items.
    //
    // If there are no directories, then we have only files and we are going to
    // get the directory path from the file paths.
    //
    // We are here from:
    // 1. User clicked on 'Upload files' button
    // 2. User clicked on 'Upload folder' button and selected a folder, even if it has subfolders
    // 3. User dragged and dropped files from one level of hierarchy
    if (!directoriesPaths.length) {
      items.forEach((file) => {
        if (this.isDrUploadDirectory(file)) return;

        const dirPath = this.#getFileDirectoryPath(file);
        if (dirPath && directoriesPaths.indexOf(dirPath) === -1) {
          directoriesPaths.push(dirPath);
        }
      });
    }

    // We are creating a list of FileInfo objects that seems to be a bit redundant.
    // They are used only for the sorting purpose in sortUploadItems.
    const filesInfo = items.reduce<FileInfo[]>((acc, file) => {
      if (!this.isDrUploadDirectory(file) && this.hasValidFilename(file)) {
        // TODO: it looks like we don't need to wrap files into FileInfo.
        // See the structure:
        // name, size, path, and file!
        acc.push({
          file: file,
          path: this.#getFileDirectoryPath(file),
          name: file.name,
          size: file.size,
        });
      }

      return acc;
    }, []);

    const folders = directoriesPaths.map((path) => ({
      path,
    }));

    return {
      folders: sortUploadItems(folders),
      documents: sortUploadItems(filesInfo),
    };
  }

  // File[] when files are uploaded via an input element, including directories.
  // Even if the user clicks on the "Upload folder" button, we receive a list of files.
  //
  // (DrUploadFile | DrUploadDirectory)[] when files are uploaded via drag & drop.
  // When files existing in one folder (one level of hierarchy) are dropped,
  // we receive a DrUploadFile[], and the path property is an empty string.
  // For us that means that we actually have patched File[] and this patch is
  // unnecessary, but we have to keep the types consistent.
  splitToBatches(
    files: File[] | (DrUploadFile | DrUploadDirectory)[],
  ): Batch[] {
    let batchId = 1;

    const batches: Batch[] = [];
    const uploadData = this.splitItemsToFoldersAndFiles(files);

    const pathParents = this.#getParentPaths(uploadData.folders);
    const pathChildren = this.#getChildrenPaths(pathParents);

    const rootFolders = this.#getRootPaths(pathParents);
    const docsInfo = uploadData.documents;
    const rootFiles = docsInfo.filter((doc) => doc.path === "");

    // For each of the root files, create a batch with only one item.
    // A batch is a row in the upload dialog.
    rootFiles.forEach((docInfo) => {
      batches.push({
        id: batchId++,
        type: "file",
        name: docInfo.name,
        originalName: docInfo.name,
        items: [docInfo.file],
        iconClass: getIconClass(docInfo.file.type),
      });
    });

    function getNestedPaths(path: string) {
      let paths = [path];

      (pathChildren[path] || []).forEach((child) => {
        paths = paths.concat(getNestedPaths(child));
      });

      return paths;
    }

    // For each of the root folder create a batch and put its files in it.
    rootFolders.forEach((rootFolderPath) => {
      const batchPaths = getNestedPaths(rootFolderPath);
      const fakeDirs = batchPaths.map<DrUploadDirectory>((path) => ({
        type: "directory",
        path: path,
        name: path,
      }));

      const batchItems = docsInfo.reduce<
        (File | DrUploadFile | DrUploadDirectory)[]
      >((acc, docInfo) => {
        if (batchPaths.indexOf(docInfo.path) > -1) acc.push(docInfo.file);
        return acc;
      }, []);

      batches.push({
        id: batchId++,
        type: "folder",
        name: rootFolderPath,
        originalName: rootFolderPath,
        items: batchItems.concat(fakeDirs),
        iconClass: "", // Types are broken here...
      });
    });

    return batches;
  }

  renameBatch(batch: Batch, newName: string) {
    // rename root in batch and folders/files relative path in case of folder batch
    batch.originalName = newName;
    batch.name = newName;

    if (batch.type === "file") {
      const rootDocument = batch.items[0];
      // name property is readonly, this hack to overwrite
      Object.defineProperties(rootDocument, {
        name: {
          value: newName,
        },
      });
    } else {
      batch.name = newName;
      batch.items.forEach((item) => {
        this.#replaceRootName(item, newName);
      });
    }
  }
}
