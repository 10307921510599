<template>
  <div class="avatar-n-summary">
    <UserAvatar class="avatar-n-summary__avatar" :user="user" size="48px" />

    <div class="avatar-n-summary__name">
      {{ user.name }}
    </div>

    <div class="avatar-n-summary__position">
      {{ user.title }}
    </div>

    <UserEmail :email="user.email" :is-icon-showed="false" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { UserAvatar } from "@shared/ui/users";
import UserEmail from "@shared/ui/users/user-email";

import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { PropType } from "vue";

interface Data {}

export default defineComponent({
  components: {
    UserAvatar,
    UserEmail,
  },

  props: {
    user: { type: Object as PropType<OrgUser>, required: true },
  },

  data(): Data {
    return {};
  },
});
</script>

<style scoped lang="scss">
@use "@app/styles/scss/_colors.scss";
@use "@app/styles/scss/typography.scss";

// Avatar & Summary
.avatar-n-summary {
  &__avatar {
    margin-bottom: 22px;
  }

  &__name {
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 25px;
    font-weight: typography.$font-weight-medium;
  }

  &__position {
    margin-bottom: 7px;
    font-size: 13px;
    line-height: 20px;
    color: colors.$pr-500;
  }
}
</style>
