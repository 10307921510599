import { documentsActions } from "./DocumentsActions";
import { documentsMutations } from "./DocumentsMutations";
import { DocumentsState } from "./DocumentsState";

import type { RootState } from "@drVue/store/state";
import type { Module } from "vuex";

export { DocumentsState } from "./DocumentsState";

export const DocumentsModule: Module<DocumentsState, RootState> = {
  actions: documentsActions,
  mutations: documentsMutations,
  state: new DocumentsState(),
  namespaced: true,
};
