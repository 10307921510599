<template>
  <TreeBrowser
    :selected-node-ids="modelValue ? [modelValue] : []"
    :tree="tree"
    :is-loading="loading || isFormSubmitting"
    :disabled="disabled"
    :submit-text="submitText"
    :autofocus="autofocus"
    :default-expanded-keys="tree.map((item) => item.id)"
    disallow-reset
    @save="$emit('update:model-value', $event[0] as number)"
    @cancel="$emit('cancel')"
    @create-node="createFolder"
  >
    <template #tree-item="{ item, node, localValue }">
      <div
        :class="{
          [$style.title]: true,
          [$style.title_isDisabled]: item.disabled,
          [$style.title_isSelected]: localValue.includes(item.id),
        }"
      >
        <DrIcon
          :name="
            node.expanded && item.children?.length !== 0
              ? 'folder-open'
              : 'folder'
          "
          :class="{
            [$style.iconFolder]: true,
          }"
        />
        {{ item.name }}
      </div>
    </template>
  </TreeBrowser>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import { DrStore } from "@app/vue";
import { $notifyDanger, $notifySuccess } from "@app/vue/common";
import { useFormHelper } from "@app/vue/shared/ui/dr-form";
import {
  DocumentsApiService,
  type Folder,
} from "@app/vue/store/modules/room/documents/DocumentsApiService";
import { DrIcon } from "@drVue/shared/ui/dr-icon";
import { type Tree, type TreeItem } from "@drVue/shared/ui/dr-tree";
import TreeBrowser from "../../tasks/shared/tree-browser/TreeBrowser.vue";

import type { NodeId } from "../../tasks/shared/tree-browser/types";

interface Props {
  modelValue?: number | undefined;
  disabled?: boolean;
  loading?: boolean;
  disabledNodeKeys?: number[];
  submitText?: string;
  autofocus?: boolean | number;
}

interface Emits {
  (e: "update:model-value", value: number | undefined): void;
  (e: "cancel"): void;
  (e: "update:persisted", value: boolean): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const api = new DocumentsApiService();

const mapFolderToNode = (
  folder: Folder,
  disabled?: boolean,
): TreeItem<{ canParent: boolean; id: string | number; name: string }> => {
  if (!disabled) disabled = props.disabledNodeKeys?.includes(folder.id);

  return {
    id: folder.id,
    name: folder.name,
    canParent: !disabled,
    disabled: disabled || undefined,
    children: folder.folders.map((item) => mapFolderToNode(item, disabled)),
  };
};

const tree = computed<
  Tree<{ canParent: boolean; id: string | number; name: string }>
>(() => {
  const rootFolder = DrStore.state.room.documents.rootFolder;
  if (!rootFolder) return [];

  return [mapFolderToNode(rootFolder)];
});

const { formErrors, hookFormSubmitPromise, isFormSubmitting } = useFormHelper<{
  name: string;
}>();

const createFolder = (value: { parentId: NodeId; name: string }): void => {
  if (isFormSubmitting.value || typeof value.parentId !== "number") return;

  emit("update:persisted", true);

  hookFormSubmitPromise(
    api.createFolder(value.parentId, value.name),
    "Failed to create new folder.",
  )
    .then(() => {
      DrStore.dispatch("room/documents/syncTree");

      $notifySuccess("New folder has been created.");
    })
    .catch(() => {
      if (formErrors.value) {
        $notifyDanger(formErrors.value.name ?? "Failed to create new folder.");
      }
    })
    .finally(() => {
      emit("update:persisted", false);
    });
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.title_isDisabled {
    color: colors.$pr-400;
    cursor: not-allowed;

    .iconFolder {
      color: colors.$pr-300;
    }
  }

  &.title_isSelected {
    color: colors.$sc-600;
    font-weight: 500;

    .iconFolder {
      color: colors.$sc-600;
    }
  }
}

.iconFolder {
  color: colors.$pr-400;
}
</style>
