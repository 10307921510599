import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { setupOfflineAlert } from "@shared/ui/dr-offline/DrOffline";

import Login from "@drVue/components/auth/Login.vue";
import { initSentry } from "@drVue/components/initSentry";
import AccessDeniedApp from "./AccessDeniedApp.vue";

import type { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [{ path: "", component: Login }];

export function createAccessDeniedApp() {
  const app = createApp(AccessDeniedApp);
  const router = createRouter({
    history: createWebHistory("/"),
    routes,
  });

  initSentry(app, router);

  app.use(router);

  setupOfflineAlert();

  return app;
}
