HeatmapLegendController.$inject = [
  "$scope",
  "$element",
  "AnalyticsHeatmapConfigService",
];
import * as d3 from "d3";

angular
  .module("dealroom.analytics.heatmap.legend", [
    "dealroom.analytics.heatmap.service.config",
  ])
  .directive("drAnalyticsHeatmapLegend", () => ({
    scope: {
      dispatcher: "<",
      elementWidth: "@",
      gradientId: "@",
    },
    controller: HeatmapLegendController,
    restrict: "A",
  }));

const labelsMargin = 5;

function HeatmapLegendController(
  $scope,
  $element,
  AnalyticsHeatmapConfigService,
) {
  const dispatcher = $scope.dispatcher;
  const elementWidth = $scope.elementWidth;
  const gradientId = $scope.gradientId;

  const left =
    elementWidth - AnalyticsHeatmapConfigService.LEGEND.width - labelsMargin;
  const rootElement = d3
    .select($element[0])
    .style("display", "none")
    .attr("transform", `translate(${left}, 0)`)
    .classed("lineargradient", true);

  const rect = rootElement
    .append("rect")
    .attr("class", "lineargradient-rect")
    .attr("height", AnalyticsHeatmapConfigService.LEGEND.height)
    .attr("width", AnalyticsHeatmapConfigService.LEGEND.width)
    .attr("fill", `url(#${gradientId})`);
  const minLabel = rootElement
    .append("text")
    .attr("dy", -2)
    .attr("y", AnalyticsHeatmapConfigService.LEGEND.height);
  const maxLabel = rootElement
    .append("text")
    .attr("dy", -2)
    .attr("y", AnalyticsHeatmapConfigService.LEGEND.height)
    .attr("x", AnalyticsHeatmapConfigService.LEGEND.width + labelsMargin);

  const updateUid = "updateHeatmap.HeatmapLegendController";
  dispatcher.on(updateUid, update, $element, $scope);

  function update({ valuesExtent }) {
    const [min, max] = valuesExtent;
    rootElement.style("display", max != 0 ? "block" : "none");
    minLabel.text(min);
    maxLabel.text(max);

    const maxLabelBbox = maxLabel.node().getBBox();
    const minLabelBbox = minLabel.node().getBBox();

    rootElement.attr(
      "transform",
      `translate(${left - maxLabelBbox.width}, ${0})`,
    );

    minLabel.attr("x", -(labelsMargin + minLabelBbox.width));
  }
}
