import { ROOM_DATA } from "@setups/data";
import { documentViewUrl } from "@setups/room-urls";
import { Urls } from "@setups/urls";
import { DOC_ITEM_TYPES } from "@drVue/store/modules/room/documents/DocumentsApiService";
import getIconClass from "../../../../common/mimetype";

import type {
  DocsItem,
  Document,
  Folder,
} from "@drVue/store/modules/room/documents/DocumentsApiService";
import type { Dictionary } from "@drVue/types";

function fileThumbnailUrl(file: Document, size: "small" | "large") {
  if (file.processing && file.processing.has_thumbnail) {
    return Urls["api:room:document_detail_view_thumbnail"](file.id, size);
  }
}

export default function processDocuments(folders: Folder[], files: Document[]) {
  function processIndexInfo(obj: DocsItem) {
    if (typeof obj.index === "string") {
      obj.index = parseInt(obj.index, 10);
    }
    obj.treeIndex = obj.tree_index ? obj.tree_index : "–";
    obj.treeIndexList = obj.tree_index ? obj.treeIndex.split(".") : [];
  }

  // aggregate number of child in folders
  function aggregateFolderChild(folder: Folder) {
    let files = folder.files.length;
    let folders = folder.folders.length;

    folder.folders.forEach((nested: Folder) => {
      aggregateFolderChild(nested);
      files += nested.aggregates.files;
      folders += nested.aggregates.folders;
    });

    folder.aggregates = {
      files: files,
      folders: folders,
      all: files + folders,
    };
  }

  let rootFolder;

  // First pass, check proper object structure and index
  const Folders = folders.reduce(
    (bucket: Dictionary<Folder>, folder: Folder) => {
      folder.type = DOC_ITEM_TYPES.Folder;
      folder.dateCreated = new Date(folder.date_created);
      folder.parents = [];
      folder.items = []; // child files + child folders
      folder.folders = [];
      folder.files = [];
      processIndexInfo(folder);

      bucket[folder.id] = folder;
      return bucket;
    },
    {},
  );

  const foldersByUid = Object.keys(Folders).reduce((acc, folderId) => {
    const folderUid = Folders[folderId].uid;
    acc[folderUid] = Folders[folderId];
    return acc;
  }, {} as Dictionary<Folder>);

  const Files = files.reduce((bucket: Dictionary<Document>, file: Document) => {
    file.type = DOC_ITEM_TYPES.Document;
    file.iconClass = getIconClass(file.mimetype);
    file.viewUrl = documentViewUrl(ROOM_DATA.url, file.id);
    file.thumbnailSmallUrl = fileThumbnailUrl(file, "small");
    file.thumbnailLargelUrl = fileThumbnailUrl(file, "large");
    file.dateCreated = new Date(file.date_created);
    file.dateUpdated = new Date(file.date_updated);
    file.lastViewDate = new Date(file.last_view_date);
    file.processingError = file.processing?.error ?? null;
    processIndexInfo(file);

    bucket[file.id] = file;
    return bucket;
  }, {});

  const filesByUid = Object.keys(Files).reduce((acc, fileId) => {
    const fileUid = Files[fileId].uid;
    acc[fileUid] = Files[fileId];
    return acc;
  }, {} as Dictionary<Document>);

  // set ids for child elements on Folders, normalize data coming out of API, build indexes
  // set children for folders
  folders.forEach((folder: Folder) => {
    if (folder.parent_id === null) {
      // kind of lame, catch root folders
      folder.name = "Home";
      folder.parents = [];
      rootFolder = folder;
      return;
    }

    const parent = Folders[folder.parent_id];
    parent.folders.push(folder);
    parent.items.push(folder);
    folder.parent = parent;
    folder.parents = parent.parents.concat([parent]);
  });

  files.forEach((file: Document) => {
    const parent = Folders[file.folder_id];
    if (parent === undefined) {
      return;
    }

    parent.items.push(file);
    parent.files.push(file);
    file.parent = parent;
  });

  // add treePosition for sorting items by treeIndex (for example in search)
  const filesItems = files as DocsItem[];
  const foldersItems = folders as DocsItem[];
  const allItems = filesItems.concat(foldersItems);
  allItems.sort((a: DocsItem, b: DocsItem) => {
    // sort items by treeIndex, 1.2.3 before 1.3 for example
    const indexA = a.treeIndexList;
    const indexB = b.treeIndexList;

    for (let i = 0; i < Math.min(indexA.length, indexB.length); ++i) {
      if (indexA[i] !== indexB[i]) {
        return parseInt(indexA[i]) - parseInt(indexB[i]);
      }
    }

    return indexA.length - indexB.length;
  });

  for (let pos = 0; pos < allItems.length; pos++) {
    allItems[pos].treePosition = pos;
  }

  // sort folders items list, so we will not have to sort items in ng-repeats
  folders.forEach((folder: Folder) =>
    folder.items.sort(
      (a: DocsItem, b: DocsItem) => a.treePosition - b.treePosition,
    ),
  );

  if (rootFolder) {
    aggregateFolderChild(rootFolder);
  }

  return {
    folders: Folders,
    foldersByUid,
    rootFolder: rootFolder,
    files: Files,
    filesByUid,
  };
}
