import * as Sentry from "@sentry/browser";

import { APP_SETTINGS, ORG_MEMBER_DATA, ROOM_DATA } from "@setups/data";

import type { GetUrlFn, UrlsGetters } from "@drVue/api-service/types";

export const Urls: UrlsGetters = (() => {
  const WebsiteUrl: string = APP_SETTINGS.WEBSITE.URL;
  const isInRoom: boolean = ROOM_DATA && !!ROOM_DATA.id;
  const loadedUrls: UrlsGetters | undefined = getLoadedUrls();
  if (loadedUrls === undefined) {
    throw new Error("jsReverse is not loaded!");
  }

  const patchedUrls: UrlsGetters = {};

  const drRootUrlNames = ["management", "dashboard", "oauth2-client-callback"];
  drRootUrlNames.forEach((urlName: string) => {
    patchedUrls[urlName] = patchUrl(WebsiteUrl, loadedUrls[urlName], urlName);
  });

  const clientRootUrlNames = ["saml_metadata", "saml_acs", "saml_sls"];

  if (ORG_MEMBER_DATA?.client) {
    const clientRootDomain = ORG_MEMBER_DATA.client.enable_domain
      ? ORG_MEMBER_DATA.client.domain
      : APP_SETTINGS.WEBSITE.HOST;

    const clientRootUrl = `${location.protocol}//${clientRootDomain}`;

    clientRootUrlNames.forEach((urlName: string) => {
      patchedUrls[urlName] = patchUrl(
        clientRootUrl,
        loadedUrls[urlName],
        urlName,
      );
    });
  } else {
    clientRootUrlNames.forEach((urlName: string) => {
      patchedUrls[urlName] = invalidContextUrl(urlName);
    });
  }

  Object.keys(loadedUrls).forEach((urlName: string) => {
    if (patchedUrls[urlName]) {
      return;
    }

    const f: GetUrlFn = loadedUrls[urlName];

    // FIXME: extract CLIENT_DATA, use it instead of ORG_MEMBER_DATA.client;
    const clientId = ORG_MEMBER_DATA.client?.id || ROOM_DATA.client_id;
    if (urlName.startsWith("api:client-dashboard:") && clientId) {
      patchedUrls[urlName] = patchUrl("", f, urlName, clientId);
    } else if (urlName.startsWith("api:room:") && isInRoom) {
      patchedUrls[urlName] = patchUrl("", f, urlName, ROOM_DATA.uid);
    } else if (urlName.startsWith("root-api:")) {
      patchedUrls[urlName] = patchUrl(WebsiteUrl, f, urlName);
    } else {
      patchedUrls[urlName] = f;
    }
  });

  return patchedUrls;

  function handleInvalidUrl(
    message: string,
    prefix: string,
    urlName: string,
    contextArgs: any[],
    args: any[],
  ) {
    Sentry.captureEvent({
      message: message,
      extra: { prefix, urlName, contextArgs, args },
      level: "error",
    });

    if (!Sentry.getClient()?.getOptions().enabled) {
      throw Error(
        message +
          JSON.stringify({
            prefix,
            urlName,
            contextArgs,
            args,
          }),
      );
    }
  }

  function invalidContextUrl(urlName: string) {
    return (...args: any[]) => {
      handleInvalidUrl(
        "This URL must not be used in this context",
        "",
        urlName,
        [],
        args,
      );
      return `bad-${urlName}`;
    };
  }

  function patchUrl(
    prefix: string,
    f: GetUrlFn,
    urlName: string,
    ...contextArgs: any[]
  ) {
    if (!contextArgs) {
      contextArgs = [];
    }

    return (...args: any[]) => {
      const relUrl = f.apply(f, contextArgs.concat([...args]));
      if (!relUrl) {
        handleInvalidUrl(
          "Invalid JsReverse URL",
          prefix,
          urlName,
          contextArgs,
          args,
        );
      }

      return prefix + relUrl;
    };
  }
})();

function getLoadedUrls(): UrlsGetters {
  const loadedUrls: UrlsGetters | undefined = (window as any)
    ._jsGlobalReverseUrls;
  if (loadedUrls === undefined) {
    throw new Error("URLS are not loaded.");
  }

  return loadedUrls as UrlsGetters;
}

export function isAuthenticationErrorResponse(response: {
  status: number;
  data: any;
}): boolean {
  if (
    response.status === 401 &&
    response.data?.non_field_errors?.[0] === "User is not authenticated"
  ) {
    return true;
  }
  if (
    response.status === 403 &&
    response.data?.detail === "Authentication credentials were not provided."
  ) {
    return true;
  }
  return false;
}

export function interceptAuthError(response: any) {
  if (isAuthenticationErrorResponse(response)) {
    const redirectUrl =
      window.location.protocol +
      "//" +
      APP_SETTINGS.WEBSITE.HOST +
      Urls["auth"]() +
      "?redirect=" +
      window.location.host +
      window.location.pathname +
      window.location.hash;
    window.location.href = redirectUrl;
  }
}

export function intercept403(response: any) {
  const forbiddenStatusCode = 403;
  if (
    response.status === forbiddenStatusCode &&
    response.data.non_field_errors &&
    (response.data.non_field_errors[0] === "NDA is not accepted" ||
      response.data.non_field_errors[0] === "2FA is required")
  ) {
    window.location.reload();
  }
}
