<template>
  <a class="user-information truncate">
    <div v-if="showAvatar" class="user-information__icon">
      <div class="avatar-holder">
        <span>
          <i class="fas fa-lg fa-user-friends" />
        </span>
      </div>
    </div>

    <div class="user-information__detail truncate">
      <div class="user-information__name truncate">
        {{ group.name }}
      </div>
    </div>
  </a>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { Group } from "@drVue/store/modules/room/groups/GroupsApiService";
import type { PropType } from "vue";

export default defineComponent({
  name: "GroupInformation",
  props: {
    group: { required: true, type: Object as PropType<Group> },
    showAvatar: {
      required: false,
      default: true,
      type: Boolean as PropType<boolean>,
    },
  },
});
</script>
