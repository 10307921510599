<template>
  <ElTooltip
    ref="tooltipRef"
    v-model:visible="isPopoverVisible"
    placement="bottom"
    effect="light"
    trigger="click"
    transition="no-transition"
    :persistent="false"
    :show-arrow="false"
    :popper-class="$style.popper"
    :teleported="false"
    width="100%"
  >
    <ElInput
      ref="inputRef"
      :placeholder="placeholder"
      :model-value="inputText"
      @input="onInput"
      @click="onClick"
      @focus="onFocus"
      @blur="onBlur"
      :class="$style.blueColor"
    >
      <template #prefix v-if="model">
        <DrIcon name="folder" :class="$style.blueColor" />
      </template>
    </ElInput>

    <template #content>
      <div :class="$style.tree" data-tree-container>
        <DrTree
          :model-value="model"
          :filter-text="filterText"
          :default-expanded-keys="selectedFolderParentIds"
          :data="tree"
          check-strictly
          check-on-click-node
          check-sign-on-right
          @update:model-value="updateFolderId"
          @reset-filter="onResetFilter"
        >
          <template #item="{ item, node }">
            <DrFolderTreeItem
              :item="item"
              :node="node"
              :is-disabled="false"
              :is-selected="model.includes(item.id)"
            />
          </template>
        </DrTree>
      </div>
    </template>
  </ElTooltip>
</template>

<script setup lang="ts">
import { difference } from "lodash-es";
import { computed, nextTick, onMounted, ref } from "vue";
import { DrTree } from "@shared/ui/dr-tree";
import DrFolderTreeItem from "@shared/ui/dr-tree/items/DrFoldersTreeItem.vue";

import { DrStore } from "@app/vue";
import { useFoldersTreeData } from "@app/vue/components/room/documents/use/useFoldersTreeData";
import DrIcon from "@drVue/shared/ui/dr-icon";

import type { ElInput, ElTooltip } from "element-plus";

const model = defineModel<(string | number)[]>({
  required: true,
  default: () => [],
});
const tooltipRef = ref<InstanceType<typeof ElTooltip> | null>(null);
const inputRef = ref<InstanceType<typeof ElInput> | null>(null);

const inputText = ref<string>("");
const filterText = ref<string>("");
const isPopoverVisible = ref(false);

const selectedFolder = computed(() => {
  if (!model.value || model.value.length === 0) return undefined;

  return DrStore.state.room.documents.folders[model.value[0]];
});

const selectedFolderName = computed(() => selectedFolder.value?.name ?? "");

const selectedFolderParentIds = computed(() => {
  const res = [];

  let cursor = selectedFolder.value;
  while (cursor) {
    res.push(cursor.id);
    cursor = cursor.parent;
  }

  return res;
});

const placeholder = computed(
  () => selectedFolderName.value || "Type to search...",
);

const updateFolderId = (folderIds: (string | number)[]) => {
  isPopoverVisible.value = false;

  if (folderIds.length === 0) {
    model.value = [];
  } else if (folderIds.length === 1) {
    model.value = folderIds;
  } else {
    model.value = difference(folderIds, model.value);
  }

  nextTick(() => {
    inputText.value = selectedFolderName.value;
  });

  setTimeout(() => {
    filterText.value = "";
  }, 300);
};

const tree = useFoldersTreeData();

const onInput = (e: string) => {
  inputText.value = e;
  filterText.value = e;

  isPopoverVisible.value = true;
};

const onFocus = (e: FocusEvent) => {
  // We are in `focus`, `relatedTarget` is the element that is losing focus.
  const relatedTarget = e.relatedTarget as HTMLElement;
  if (relatedTarget && !relatedTarget.closest("[data-tree-container]")) {
    inputText.value = "";
    filterText.value = "";
  }
};

const onClick = () => {
  inputText.value = "";
  filterText.value = "";
};

const onBlur = (e: FocusEvent) => {
  // We are in `blur`, `relatedTarget` is the element that is gaining focus.
  const relatedTarget = e.relatedTarget as HTMLElement;
  if (!relatedTarget || !relatedTarget.closest("[data-tree-container]")) {
    if (!model.value) {
      inputText.value = "";
    } else {
      inputText.value = selectedFolderName.value;
    }

    isPopoverVisible.value = false;
  }
};

const onResetFilter = () => {
  filterText.value = "";
  inputText.value = "";
  inputRef.value?.focus();
};

onMounted(() => {
  filterText.value = selectedFolderName.value;
  inputText.value = selectedFolderName.value;
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

.popper {
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.tree {
  max-height: 300px;
  overflow-y: auto;
}

.blueColor {
  color: colors.$sc-600;

  :global {
    .el-input__inner {
      color: colors.$sc-600;
    }
  }
}
</style>
