import { computed, ref, watch } from "vue";

import { FindingsApiService } from "@drVue/api-service/modules/findings";
import { createDictionary } from "@drVue/common";
import { pinia } from "@drVue/store/pinia";
import { useFindingsStore } from "@drVue/store/pinia/room/findings";

import type {
  FindingComment as FindingCommentBackend,
  FindingCommentCreatePayload,
  FindingCommentUpdatePayload,
} from "@drVue/api-service/modules/findings/types";
import type { ComputedRef } from "vue";

type FindingComment = Omit<FindingCommentBackend, "id"> & {
  id: string;
};

export type {
  FindingComment,
  FindingCommentCreatePayload,
  FindingCommentUpdatePayload,
};

const $api = new FindingsApiService();

export const useFindingComments = (
  findingId: ComputedRef<FindingComment["id"]>,
) => {
  const findingsStore = useFindingsStore(pinia);

  const list = ref<FindingComment[]>([]);
  const dict = computed(() =>
    list.value.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, createDictionary<FindingComment>()),
  );

  const isLoading = ref<boolean>(false);
  const isLoadError = ref<boolean>(false);

  const load = async () => {
    isLoading.value = true;
    isLoadError.value = false;

    try {
      const commentsRaw = await $api.loadComments(findingId.value);
      list.value = commentsRaw.map((item) => ({
        ...item,
        id: String(item.id),
      }));
      isLoading.value = false;
      return list.value;
    } catch (err) {
      isLoading.value = false;
      isLoadError.value = true;
      throw err;
    }
  };

  const create = async (payload: FindingCommentCreatePayload) => {
    const createdItem = await $api.createComment(findingId.value, payload);
    const formattedItem = {
      ...createdItem,
      id: String(createdItem.id),
    };
    list.value.push(formattedItem);

    /** Update finding followers if user mentioned in comment */
    await findingsStore.reloadItemById(findingId.value);

    return formattedItem;
  };

  const update = async (
    commentId: FindingComment["id"],
    payload: FindingCommentCreatePayload,
  ) => {
    const updatedItem = await $api.updateComment(
      findingId.value,
      commentId,
      payload,
    );

    const i = list.value.findIndex((item) => item.id === commentId);
    if (i > -1) list.value.splice(i, 1, { ...updatedItem, id: commentId });

    /** Update finding followers if user mentioned in comment */
    await findingsStore.reloadItemById(findingId.value);

    return list.value[i];
  };

  const remove = async (commentId: FindingComment["id"]) => {
    await $api.removeComment(findingId.value, commentId);
    const i = list.value.findIndex((item) => item.id === commentId);
    if (i > -1) list.value.splice(i, 1);
  };

  watch(findingId, load, { immediate: true });

  return {
    list,
    dict,
    isLoading,
    isLoadError,
    load,
    create,
    update,
    remove,
  };
};
