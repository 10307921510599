<template>
  <DrIcon name="list" :class="$style.listIcon" />
  <span :class="$style.countText">{{ countText }}</span>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DrIcon } from "@shared/ui/dr-icon";

import { default as text } from "@drVue/utils/text";

import type { ValueDriverRow } from "@drVue/store/modules/room/synergies/table";

const props = defineProps<{
  row: ValueDriverRow;
}>();

const countText = computed(() => {
  const count = props.row.tasks.length;
  const requests = text.pluralize("Request", count);
  return `${count} ${requests}`;
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

.listIcon {
  color: colors.$pr-400;
}

.countText {
  margin-left: 4px;
  color: colors.$pr-900;
}
</style>
