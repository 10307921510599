<template>
  <ViewFieldWrapper
    :is-read-only="!!viewProps.schema.isReadOnly"
    :label="viewProps.schema.label"
    @edit="viewProps.enterEditMode"
  >
    <div v-if="hasLabels" :class="$style.labelsContainer">
      <DrLabel v-for="label in labelsToRender" :key="label.name">
        {{ label.name }}
      </DrLabel>
      <DrLabel v-if="isCountVisible">{{ countLabel }}</DrLabel>
    </div>
    <span v-else>-</span>
  </ViewFieldWrapper>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DrLabel } from "@shared/ui/dr-label";

import { pinia } from "@drVue/store/pinia";
import { useTasksLabelsStore } from "@drVue/store/pinia/room/tasksLabels/tasksLabels";
import ViewFieldWrapper from "../ViewFieldWrapper.vue";

import type { ViewFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { Task } from "@drVue/store/pinia/room/tasks";
import type { TaskLabel } from "@drVue/store/pinia/room/tasksLabels/tasksLabelApi";

interface Props {
  viewProps: ViewFieldProps<number[], Task>;
}

const props = defineProps<Props>();

const tasksLabels = useTasksLabelsStore(pinia);

const hasLabels = computed(() => props.viewProps.entityValue.length > 0);

const labels = computed<TaskLabel[]>(() =>
  props.viewProps.entityValue
    .slice()
    .sort()
    .reduce<TaskLabel[]>((acc, id) => {
      if (tasksLabels.dict[id]) {
        acc.push(tasksLabels.dict[id]);
      }
      return acc;
    }, []),
);

const labelsToRender = computed(() => labels.value.slice(0, 2));

const countLabel = computed(
  () => `+${labels.value.length - labelsToRender.value.length}`,
);

const isCountVisible = computed(
  () => labels.value.length > labelsToRender.value.length,
);
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.labelsContainer {
  align-items: center;
  display: flex;
  gap: 6px;
  line-height: 22px;
  user-select: none;
}

.label {
  font: typography.$caption_regular;
  color: colors.$pr-600;
}
</style>
