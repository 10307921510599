RoomTaskController.$inject = ["$scope", "$timeout", "$state", "TasksService"];
import { isTask } from "@app/ng/tasks/services/helpers/getItemType";
import tasksFilterService from "@app/ng/tasks/services/ts/TasksFilterService";
import { pinia } from "@drVue/store/pinia";
import { useTasksTableStore } from "@drVue/store/pinia/room/tasksTable/tasksTable";

const tasksTableStore = useTasksTableStore(pinia);

export default function RoomTaskController(
  $scope,
  $timeout,
  $state,
  TasksService,
) {
  var self = this;

  self.$state = $state;
  self.TasksService = TasksService;

  tasksFilterService.setOrder({ by: "order", reversed: false });

  tasksTableStore.isActive = true;

  self.openTaskDetails = (row) => {
    if (isTask(row)) self.TasksService.openTaskDetails(row.key);
  };

  self.navigate = ({ listId }) => {
    if ($state.$current.name === "tasks.archived.overview") {
      $state.go("tasks.list.overview", {
        listId,
      });
    } else {
      $state.go($state.$current.name, { listId });
    }
  };

  $timeout(function () {
    if ($state.$current.name === "tasks") {
      $state.go("tasks.list");
    }
  });

  /** @todo: move into vue store */
  function reOrderTask(taskId, prevObjId, isPrevObjCategory) {
    var task = TasksService.tasks[taskId];
    var newCategoryId = isPrevObjCategory
      ? prevObjId
      : TasksService.tasks[prevObjId].category_id;
    var categoryChanged = newCategoryId !== task.category_id;

    var newOrder = isPrevObjCategory ? 0 : TasksService.tasks[prevObjId].order;
    if (!isPrevObjCategory && (categoryChanged || newOrder < task.order)) {
      // for example items in order a, b, c:
      // order increased like b, c, a -> decrease b and c index and use prev item index
      // order decreased like a, c, b -> increase b index and use prev item index + _1_
      newOrder += 1;
    }

    if (!categoryChanged && task.order === newOrder) return;

    var siblings = TasksService.tasksList.filter(function (sib) {
      return sib.category_id === newCategoryId;
    });
    siblings.sort(function (a, b) {
      return a.order - b.order;
    }); //smaller index first

    if (categoryChanged) {
      // tasks is moved, add some space for new task in category
      siblings.forEach(function (sib) {
        if (sib.order >= newOrder) sib.order += 1;
      });
    } else {
      // task position changed in same category
      if (newOrder > task.order) {
        siblings.forEach(function (sib) {
          if (sib.order > task.order && sib.order <= newOrder) sib.order -= 1;
        });
      } else {
        siblings.forEach(function (sib) {
          if (sib.order >= newOrder && sib.order < task.order) sib.order += 1;
        });
      }
    }

    var data = { order: newOrder };
    if (categoryChanged) {
      data.category_id = newCategoryId;
    }
    TasksService.patchTaskV2(task.uid, data);
    task.order = newOrder;
    task.category_id = newCategoryId;
  }

  $scope.vxe = {
    onVxeTaskReordered: reOrderTask,
    onVxeLinkFile: function (sourceTask) {
      $scope.tasksCtrl.TasksService.updateTaskAttachments({ sourceTask });
    },
  };
}
