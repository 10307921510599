import { createDictionary } from "@drVue/common";

import type { ParticipantUserV1 } from "@drVue/store/pinia/room/tasks";

type UserId = number;

export const participantsContains = (
  participants: ParticipantUserV1[],
  ids: UserId[],
) => {
  const dict = participants.reduce((dict, user) => {
    dict[user.user_id] = true;
    return dict;
  }, createDictionary<boolean>());

  return ids.some((id) => dict[id]);
};
