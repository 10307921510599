import { sortBy } from "lodash-es";

import type { Category } from "@drVue/store/pinia/room/categories";
import type { Dictionary } from "@drVue/types";

export default function processCategories(categories: Category[]) {
  // build flat ordered list of categories and add indent to "get_name" attr
  // add ids list of all child categories with category itself to "descendants" attribute
  const cat_dict: Dictionary<Category> = {};
  const cat_child: Dictionary<Category[]> = {};

  categories.forEach((cat) => {
    cat_dict[cat.id] = cat;

    const parent = cat.parent_id ? cat_dict[cat.parent_id] : undefined;

    cat.parent = parent;
    cat.parents = parent ? parent.parents.concat([parent]) : [];

    const indent = new Array(cat.parents.length + 1).join("-");
    cat.get_name = indent + cat.name;
    cat.full_path = parent ? parent.full_path + " \\ " + cat.name : cat.name;
    cat_child[cat.id] = [];

    if (parent) {
      cat_child[parent.id].push(cat);
    }
  });

  for (let i = categories.length; i--; ) {
    const cat = categories[i];
    cat.categories = sortBy(cat_child[cat.id] || [], "order");

    cat.descendants = [cat.id];
    cat.categories.forEach(
      (child) => (cat.descendants = cat.descendants.concat(child.descendants)),
    );
  }

  let nextCategoryPosition = 1;
  function setCategoriesTreePosition(categories: Category[]) {
    categories.forEach(function (category) {
      category.treePosition = nextCategoryPosition;
      nextCategoryPosition += 1;
      setCategoriesTreePosition(category.categories);
    });
  }

  const rootCategories = sortBy(
    categories.filter((cat) => !cat.parent_id),
    "order",
  );
  setCategoriesTreePosition(rootCategories);

  return sortBy(categories, "treePosition");
}
