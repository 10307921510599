import type EditDate from "./Fields/Edit/Date.vue";
import type EditDateTime from "./Fields/Edit/DateTime.vue";
import type EditMultiSelect from "./Fields/Edit/MultiSelect.vue";
import type EditNumber from "./Fields/Edit/Number.vue";
import type EditSelect from "./Fields/Edit/Select.vue";
import type EditText from "./Fields/Edit/Text.vue";
import type EditTextArea from "./Fields/Edit/Textarea.vue";
import type { EditFieldProps, ViewFieldProps } from "./Fields/types";
import type ViewAsEmail from "./Fields/View/Email.vue";
import type ViewAsLinkifiedText from "./Fields/View/LinkifiedText.vue";
import type ViewAsPhone from "./Fields/View/Phone.vue";
import type ViewAsText from "./Fields/View/Text.vue";
import type { GenericValidateFunction } from "vee-validate";

export type FieldSchemaTypeDefault = Exclude<
  FieldSchemaType,
  FieldSchemaType.Custom
>;

export type FieldSchemaTypeDefaultComponents<ComponentsTypes> = Record<
  FieldSchemaTypeDefault,
  ComponentsTypes
>;

export type EditFieldsTypes =
  | typeof EditText
  | typeof EditDate
  | typeof EditDateTime
  | typeof EditNumber
  | typeof EditSelect
  | typeof EditMultiSelect
  | typeof EditTextArea;

export type ViewFieldsTypes =
  | typeof ViewAsEmail
  | typeof ViewAsPhone
  | typeof ViewAsText
  | typeof ViewAsLinkifiedText;

// The type was copy-pasted from vee-validate sources as it is not exported.
export type YupValidator<TValue = any> = {
  validate(value: TValue, options: Record<string, any>): Promise<TValue>;
};

// The type was copy-pasted from vee-validate sources as it is not exported.
export type RuleExpression<TValue> =
  | string
  | Record<string, unknown>
  | GenericValidateFunction<TValue>
  | GenericValidateFunction<TValue>[]
  | YupValidator<TValue>
  | undefined;

export enum FieldSchemaType {
  Date = "date",
  DateTime = "datetime",
  Email = "email",
  MultiSelect = "multiselect",
  Number = "number",
  Phone = "phone",
  Richtext = "richtext",
  Select = "select",
  Text = "text",
  LinkifiedText = "linkified_text",
  Textarea = "textarea",
  Switch = "switch",
  Color = "color",

  Custom = "custom",
}

export interface Props<TExtra = any> {
  prop: string;

  extra?: TExtra;
  isReadOnly?: boolean;
  label?: string;
  optimistic?: boolean;
  placeholder?: string;
  rules?: RuleExpression<unknown>;
}

export interface TypedProps {
  type: FieldSchemaTypeDefault;
}

export interface CustomProps {
  type: FieldSchemaType.Custom;
  editComponent: any;
  viewComponent: any;
}

export type TypedFieldSchema<TExtra> = Props<TExtra> & TypedProps;
export type CustomFieldSchema<TExtra> = Props<TExtra> & CustomProps;

export type FieldSchema<TExtra = any> = Props<TExtra> &
  (TypedProps | CustomProps);

export interface ViewSlotProps {
  viewProps: ViewFieldProps;
}

export interface EditSlotProps {
  editProps: EditFieldProps;
}

export interface FormSchema<TExtra = any> {
  fields: FieldSchema<TExtra>[];
}
