AttachToRequestModalController.$inject = [
  "itemPath",
  "onUploadComplete",
  "files",
  "$uibModalInstance",
  "DocumentsService",
  "FileUploadHelpers",
  "FileUploadService",
];
import fuzzaldrinPlus from "fuzzaldrin-plus";

import { suggestUploadFolder } from "@app/ng/tasks/services/suggestUploadFolder";

export default function AttachToRequestModalController(
  itemPath,
  onUploadComplete,
  files,
  $uibModalInstance,
  DocumentsService,
  FileUploadHelpers,
  FileUploadService,
) {
  var $ctrl = this;
  $ctrl.Documents = DocumentsService;
  $ctrl.uploadBatches = [];
  $ctrl.currentBatch = 0;

  $ctrl.destinationFolderID = suggestUploadFolder(
    itemPath,
    DocumentsService.rootFolder,
    DocumentsService.Folders,
  );

  const initialDestinationFolderId = $ctrl.destinationFolderID;

  $ctrl.expandedFolders = {};

  $ctrl.uploadFiles = uploadFiles;
  $ctrl.isBatchConfirmationShown = isBatchConfirmationShown;
  $ctrl.skipCurrentBatch = skipCurrentBatch;
  $ctrl.uploadCurrentBatch = uploadCurrentBatch;
  $ctrl.linkExistingDocument = linkExistingDocument;

  if (files && files.length > 0) {
    $ctrl.uploadFiles(files);
  }

  function uploadFiles(files) {
    if (!files || files.length === 0) return;
    const batches = FileUploadHelpers.splitToBatches(files);
    $ctrl.uploadBatches = $ctrl.uploadBatches.concat(batches);
    updateDuplicateDocuments();
  }

  function isBatchConfirmationShown() {
    return $ctrl.currentBatch < $ctrl.uploadBatches.length;
  }

  function openNextBatch() {
    $ctrl.currentBatch += 1;
    if (!isBatchConfirmationShown()) {
      $uibModalInstance.close();
    }
  }

  function skipCurrentBatch() {
    openNextBatch();
  }

  function uploadCurrentBatch() {
    var batch = $ctrl.uploadBatches[$ctrl.currentBatch];
    var folderId = $ctrl.destinationFolderID;
    if (batch.name !== batch.originalName) {
      FileUploadHelpers.renameBatch(batch, batch.name);
    }

    FileUploadService.uploadToFolder(folderId, batch.items).then(
      callbackBatchUploadResult,
    );

    openNextBatch();
  }

  function updateDuplicateDocuments() {
    // match existing document with new document upload by name and size
    // file size have to match exactly, fuzzy matching is used for names
    $ctrl.duplicateDocs = $ctrl.uploadBatches.map(function (batch) {
      if (batch.type !== "file") {
        return;
      }
      var file = batch.items[0];
      var matchingDocuments = []; // [score, doc]
      Object.keys(DocumentsService.Files).forEach(function (docId) {
        var doc = DocumentsService.Files[docId];
        if (doc.size === file.size) {
          var score = fuzzaldrinPlus.score(doc.name, file.name);
          if (score) {
            matchingDocuments.push([score, doc]);
          }
        }
      });
      if (!matchingDocuments.length) {
        return;
      }
      matchingDocuments.sort(function (a, b) {
        return a[0] > b[0] ? -1 : 1;
      });
      return matchingDocuments[0][1];
    });
  }

  function callbackBatchUploadResult(result) {
    const documents = [];
    const folders = [];

    if (result.info.folders.length) {
      folders.push({
        folder_id: result.info.folders[0].id,
        folder_uid: result.info.folders[0].uid,
      });
    } else {
      if (!result.files[0]) {
        // file upload is failed/cancelled
        return;
      }

      documents.push({
        document_id: result.files[0].data.id,
        document_uid: result.files[0].data.uid,
        page: null,
      });
    }

    const hasDestinationFolderChanged =
      initialDestinationFolderId !== $ctrl.destinationFolderID;

    onUploadComplete(folders, documents, hasDestinationFolderChanged);
  }

  function linkExistingDocument(document) {
    const hasDestinationFolderChanged =
      initialDestinationFolderId !== $ctrl.destinationFolderID;

    onUploadComplete(
      [],
      [{ document_id: document.id, document_uid: document.uid }],
      hasDestinationFolderChanged,
    );

    skipCurrentBatch();
  }
}
