<template>
  <div
    :class="{
      [$style.container]: true,
      [$style.container_withBorder]: hasBorder,
      [openedClass ?? '']: isOpen,
      [$style.container_hoverable]: !disabled && hoverable,
    }"
  >
    <component
      :is="disabled ? 'div' : 'button'"
      :class="$style.toggleButton"
      @click="toggle"
    >
      <div :class="$style.toggleButtonTitle">
        <DrIcon
          v-if="hasCaret"
          name="caret-down"
          size="sm"
          :class="{
            [$style.iconCaret]: true,
            [$style.iconCaret__opened]: isOpen,
            [$style.iconCaret__disabled]: disabled,
          }"
        />

        <div :class="$style.label">
          <slot name="label"> {{ label }}</slot>
        </div>

        <div :class="$style.actions" @click.stop>
          <slot
            name="actions"
            v-bind="{
              buttonHiddenClass: $style.buttonHidden,
              buttonIconClass: $style.buttonIcon,
              isOpen,
            }"
          />
        </div>
      </div>

      <div v-if="$slots.subtitle" :class="$style.toggleButtonSubtitle">
        <slot name="subtitle" />
      </div>
    </component>

    <div
      v-if="isOpen"
      :class="{
        [$style.inner]: true,
        [$style.inner_withCaret]: hasCaret,
        [$style.inner_withoutCaret]: !hasCaret,
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

import DrIcon from "@app/vue/shared/ui/dr-icon/DrIcon.vue";

interface Props {
  label?: string;
  hasBorder?: boolean;
  hasCaret?: boolean;
  disabled?: boolean;
  openedClass?: string;
  hoverable?: boolean;
  initIsOpen?: boolean;
}

const props = defineProps<Props>();

const isOpen = ref(props.initIsOpen);

const toggle = () => {
  if (props.disabled) return;

  isOpen.value = !isOpen.value;
};

watch(
  () => props.disabled,
  (value) => {
    if (value) isOpen.value = false;
  },
);

watch(
  () => props.initIsOpen,
  (value) => {
    if (value) isOpen.value = value;
  },
);
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";

.container {
  display: grid;
  grid-template-columns: auto 1fr auto;
  overflow: hidden;
}

.container_withBorder {
  border-radius: spacing.$xs;
  border: 1px solid colors.$pr-200;
  background: white;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);

  &.container_hoverable:hover {
    border-color: colors.$pr-400;

    .iconCaret {
      color: colors.$pr-900;
    }
  }
}

.buttonHidden {
  opacity: 0;
}

.toggleButton {
  padding: 10px;
  display: grid;
  grid-template-columns: subgrid;
  grid-column: span 3;
  border: none;
  background: none;
  text-align: start;

  &:hover {
    .buttonHidden {
      opacity: 1;
    }
  }
}

.toggleButtonTitle {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: span 3;
}

.toggleButtonSubtitle {
  display: grid;
  grid-column: 2 / span 2;
  margin-top: spacing.$xxs;
}

.buttonIcon {
  margin-left: 4px;
}

.label {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: colors.$pr-900;
  align-self: center;
  grid-column: 2;

  &:first-child {
    margin-left: spacing.$xxs;
  }
}

.actions {
  display: flex;
  gap: spacing.$xs;
}

.inner {
  display: grid;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: colors.$pr-900;
}

.inner_withCaret {
  grid-column: 2 / span 2;
}

.inner_withoutCaret {
  grid-column: 1 / span 3;
}

.iconCaret {
  align-self: center;
  transform: rotate(-90deg);
  transition: transform 0.1s ease-out;
  color: colors.$pr-350;
  margin-right: spacing.$xs;
}

.iconCaret__opened {
  transform: rotate(0deg);
  color: colors.$pr-900;
}

.iconCaret.iconCaret__disabled {
  color: colors.$pr-200;
}
</style>
