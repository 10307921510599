import * as Sentry from "@sentry/browser";

export class DrEvent {
  private subscribers: ((...args: any[]) => void)[] = [];

  subscribe(fn: () => void) {
    if (this.subscribers.indexOf(fn) > -1) return;
    this.subscribers.push(fn);
  }

  unsubscribe(fn: () => void) {
    const i = this.subscribers.indexOf(fn);
    if (i > -1) {
      this.subscribers.splice(i, 1);
    }
  }

  unsubscribeAll() {
    this.subscribers = [];
  }

  fire(...args: any[]) {
    for (const subscriber of this.subscribers) {
      try {
        subscriber(...args);
      } catch (e) {
        console.error(e);
        Sentry.captureException(e);
      }
    }
  }
}
