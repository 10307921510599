import { orderBy } from "lodash-es";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { convertPtzToBtz } from "@drVue/common";
import { getPeriodKey } from "@drVue/components/room/synergies/utils";
import { useSynergiesStore } from "@drVue/store/modules/room/synergies/synergies";
import { useValueDriversStore } from "@drVue/store/modules/room/synergies/value-drivers";
import { pinia } from "@drVue/store/pinia";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";

import type { CustomDataType } from "@drVue/store/modules/client-dashboard/fields/types";
import type {
  Synergy,
  ValueDriver,
} from "@drVue/store/modules/room/synergies/SynergiesApiService";
import type { Task } from "@drVue/store/pinia/room/tasks";
import type { VxeTablePropTypes } from "vxe-table";

export interface SynergyRow {
  type: "synergy";
  id: Synergy["id"];
  title: Synergy["title"];
}

export interface ValueDriverRow {
  type: "value-driver";
  id: ValueDriver["id"];
  title: ValueDriver["title"];
  tasks: Task[];
  custom_data: CustomDataType;
  synergy_id: Synergy["id"];
  periods_data: Record<string, Record<string, number>>;
}

export type SynergyTableRow = SynergyRow | ValueDriverRow;

export const useSynergiesTableStore = defineStore("synergies-table", () => {
  const tasksStore = useTasksStore(pinia);
  const synergiesStore = useSynergiesStore(pinia);
  const valueDriversStore = useValueDriversStore(pinia);

  const activeSynergyId = ref<string>();
  const sortConfig = ref<VxeTablePropTypes.SortConfigDefaultSort>({
    field: "",
    order: "",
  });

  const rows = computed<SynergyTableRow[]>(() => {
    const list = activeSynergyId.value
      ? synergiesStore.list.filter((s) => s.id === activeSynergyId.value)
      : synergiesStore.list;

    return list.reduce<SynergyTableRow[]>((arr, syn) => {
      arr.push({
        type: "synergy",
        id: syn.id,
        title: syn.title,
      });

      const valueDrivers = valueDriversStore.dictBySynergyId[syn.id];
      if (!valueDrivers) return arr;

      let rows: SynergyTableRow[] = valueDrivers.map((drv) => {
        const periods_data = drv.periods_data
          ? drv.periods_data.reduce<Record<string, Record<string, number>>>(
              (acc, pd) => {
                const btzDate = convertPtzToBtz(pd.start_date);
                const key = getPeriodKey(btzDate);

                acc[key] = pd.custom_data;
                return acc;
              },
              {},
            )
          : {};

        return {
          type: "value-driver",
          id: drv.id,
          synergy_id: drv.synergy_id,
          title: drv.title,
          tasks: drv.tasks.map(
            ({ task_uid }) => tasksStore.tasksByUid[task_uid],
          ),
          custom_data: drv.custom_data,
          periods_data,
        };
      });

      if (sortConfig.value.field && sortConfig.value.order) {
        rows = orderBy(
          rows,
          [sortConfig.value.field],
          [sortConfig.value.order],
        );
      }

      arr = arr.concat(rows);

      return arr;
    }, []);
  });

  const setActiveSynergyId = (id: string) => {
    activeSynergyId.value = id;
  };

  const unsetActiveSynergyId = () => {
    activeSynergyId.value = undefined;
  };

  const setSortConfig = (config: VxeTablePropTypes.SortConfigDefaultSort) => {
    sortConfig.value = config;
  };

  return {
    rows,
    setSortConfig,
    setActiveSynergyId,
    unsetActiveSynergyId,
  };
});
