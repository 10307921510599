import headerCellHtml from "./index.html?raw";

AnalyticsDatagridTableHeaderCellController.$inject = ["$element", "$scope"];
import * as d3 from "d3";

angular
  .module("dealroom.analytics.datagrid-table.table.header-cell", [])
  .component("drAnalyticsDatagridTableHeaderCell", {
    bindings: {
      cell: "<",
      dispatcher: "<",
    },
    controller: AnalyticsDatagridTableHeaderCellController,
    template: headerCellHtml,
  });

const CLASSES = {
  icon: {
    asc: "fa-caret-up",
    desc: "fa-caret-down",
  },
  cell: {
    active: "dr-tablesort-sortable--active",
  },
};

function AnalyticsDatagridTableHeaderCellController($element, $scope) {
  const $ctrl = this;
  const rootElement = d3.select($element[0]);
  const pk = "AnalyticsDatagridTableHeaderCellController-" + $scope.$id;
  $ctrl.$onInit = function () {
    if (!$ctrl.cell.childs) {
      rootElement
        .classed("flex-table__column flex-table__column--header", true)
        .classed("dr-tablesort-sortable", true)
        .classed($ctrl.cell.class, true);
      rootElement.on("click", handleClick);

      $ctrl.dispatcher.on("sortBy." + pk, onSortBy);
    }
  };

  function handleClick() {
    $ctrl.dispatcher.call("setSortBy", null, $ctrl.cell);
  }

  function onSortBy(sortBy, sorting) {
    CLASSES.icon.all = sorting.map((n) => CLASSES.icon[n]).join(" ");
    CLASSES.icon.default = CLASSES.icon[sorting[0]];

    const icon = rootElement.select(".dr-tablesort-direction");
    const isActive = sortBy.by === $ctrl.cell.key;
    rootElement.classed(CLASSES.cell.active, isActive);
    if (isActive) {
      icon.classed(CLASSES.icon.default, false);
      icon.classed(CLASSES.icon[sortBy.order], true);
    } else {
      icon.classed(CLASSES.all, false);
      icon.classed(CLASSES.default, true);
    }
  }
}
