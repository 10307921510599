DocumentsApiService.$inject = ["$http", "$q", "URLS"];
export default function DocumentsApiService($http, $q, URLS) {
  return {
    getTree: getTree,
    searchDocuments: searchDocuments,
    getDocumentActivity: getDocumentActivity,
    listArchive: listArchive,
    defaultPermissions: defaultPermissions,
  };

  function getTree() {
    return $q.all({
      files: $http.get(URLS["api:room:documents_api"]()),
      folders: $http.get(URLS["api:room:folders_api"]()),
    });
  }

  function searchDocuments(query) {
    return $http.get(URLS["api:room:documents_search"]() + `?query=${query}`);
  }

  function getDocumentActivity(documentId) {
    return $http.get(URLS["api:room:document_detail_activity"](documentId));
  }

  function listArchive() {
    return $http.get(URLS["api:room:documents_archive"]());
  }

  function defaultPermissions(folderId) {
    var url = URLS["api:room:folder_default_permissions"](folderId);
    return $http.get(url);
  }
}
