import { defineStore } from "pinia";

import { DealTasksApiService } from "@drVue/api-service/modules/deal-tasks";
import { generateList } from "@drVue/store/pinia/helpers/generators";

import type { DealTask } from "@drVue/api-service/modules/deal-tasks/types";

export type { DealTask };

const api = new DealTasksApiService();

export const useYourWorkDealTasksStore = defineStore(
  "yourWorkDealTasks",
  () => {
    const { list, dict, load, isLoading, isLoadError } = generateList<DealTask>(
      {
        name: "Your work deal tasks",
        load: api.loadAllDeals.bind(api),
      },
    );

    return {
      list,
      dict,
      isLoading,
      isLoadError,
      load,
    };
  },
);
