<template>
  <div
    ref="elementRef"
    class="dash-rooms-board__item"
    @click.stop="openDetails"
    @mouseenter="toggleMouseOver"
    @mouseleave="toggleMouseOver"
  >
    <DealTitleRow
      :deal="deal"
      logo-position="right"
      :show-room-link-button="isMouseOver"
      :is-visible="isVisible"
    />
    <DealsBoardItemFields :deal="deal" :is-visible="isVisible" />
    <DealsBoardItemFooter :show-room-button="false" :deal="deal" />
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import DealsBoardItemFields from "./DealsBoardItemFields.vue";
import DealsBoardItemFooter from "./DealsBoardItemFooter.vue";
import DealTitleRow from "./DealTitleRow.vue";
import { useBoardItemIntersection } from "./useDealBoardItemObserver";

import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";

interface Props {
  deal: Deal;
}

const props = defineProps<Props>();

const $router = useRouter();

const elementRef = ref<Element>();
const isVisible = ref(false);
const { addBoardItem, removeBoardItem } = useBoardItemIntersection();
onMounted(() => {
  addBoardItem(elementRef.value!, (isVisibleVal) => {
    isVisible.value = isVisibleVal;
  });
});
onBeforeUnmount(() => {
  removeBoardItem(elementRef.value!);
});

const isMouseOver = ref(false);
const openDetails = () => {
  $router.push(`/deals/details/${props.deal.uid}`);
};

const toggleMouseOver = () => {
  isMouseOver.value = !isMouseOver.value;
};
</script>
