import { defineStore } from "pinia";

import { YourWorkApiService } from "@drVue/api-service/client-dashboard/your_work";
import { generateList } from "@drVue/store/pinia/helpers/generators";

import type { UserRoomTask } from "./types";

export type { UserRoomTask };

const api = new YourWorkApiService();

export const useYourWorkRoomTasksStore = defineStore(
  "yourWorkRoomTasks",
  () => {
    const { list, dict, load, isLoading, isLoadError } =
      generateList<UserRoomTask>({
        name: "Your work room tasks",
        load: api.getTasks.bind(api),
      });

    const exportToExcel = (ids: UserRoomTask["id"][]) => {
      return api.exportTasks(ids);
    };

    return {
      list,
      dict,
      isLoading,
      isLoadError,
      load,
      exportToExcel,
    };
  },
);
