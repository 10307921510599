import { ClientSettingsApiService } from "@drVue/api-service/client-dashboard";

import type { State } from "./state";
import type { ClientSettings, RootState } from "./types";
import type { ActionContext, ActionTree } from "vuex";

type IContext = ActionContext<State, RootState>;

const api = new ClientSettingsApiService();

export const actions: ActionTree<State, RootState> = {
  update({ commit }: IContext, data: ClientSettings) {
    return api.update(data).then((updated: ClientSettings) =>
      commit("update", {
        data: updated,
      }),
    );
  },
};
