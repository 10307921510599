import type {
  DrUploadDirectory,
  DrUploadFile,
} from "@shared/ui/dr-upload-dialog/types";

export type UploadItem = DrUploadFile | DrUploadDirectory;

// webkitRelativePath is avaliable only when <input type="file" webkitdirectory>
// is used, so we need to read the directory structure manually.

function readEntryContents(entry: FileSystemEntry) {
  return new Promise<UploadItem[]>((resolve) => {
    let reading = 0;

    const contents: UploadItem[] = [];

    readEntry(entry, "");

    function readEntry(entry: FileSystemEntry, path: string) {
      if (entry.isFile) {
        const fileEntry = entry as FileSystemFileEntry;

        reading++;

        fileEntry.file((file) => {
          // file.webkitRelativePath is empty all the time!

          const drUploadFile = file as DrUploadFile;
          if (path) drUploadFile.path = `${path}${fileEntry.name}`;

          reading--;

          contents.push(drUploadFile);

          if (reading === 0) resolve(contents);
        });
      } else if (entry.isDirectory) {
        const dirEntry = entry as FileSystemDirectoryEntry;

        path = `${path}${dirEntry.name}/`;
        contents.push({
          path,
          name: dirEntry.name,
          type: "directory",
        });

        readReaderContent(dirEntry.createReader(), path);
      }
    }

    function readReaderContent(
      reader: FileSystemDirectoryReader,
      path: string,
    ) {
      reading++;

      reader.readEntries(function (entries) {
        reading--;

        for (const entry of entries) readEntry(entry, path);

        if (reading === 0) resolve(contents);
      });
    }
  });
}

export const extractFiles = (e: DragEvent): Promise<UploadItem[][]> => {
  e.preventDefault();

  if (e.dataTransfer === null) return Promise.resolve([]);
  const promises: Promise<UploadItem[]>[] = [];

  const items = e.dataTransfer.items;
  for (let i = 0; i < items.length; i++) {
    const item = items[i].webkitGetAsEntry();
    if (item) promises.push(readEntryContents(item));
  }

  return Promise.all(promises);
};
