import documents_docsMultiSelectHtml from "../../templates/components/documents/docs-multi-select.html?raw";

(function () {
  "use strict";
  angular
    .module("dealroom.docs-multi-select", ["dealroom.tree-filter.directive"])
    .component("drDocsMultiSelect", drDocsMultiSelect());

  function drDocsMultiSelect() {
    return {
      bindings: {
        selectedFoldersIds: "<",
        selectedDocsIds: "<",
        onChange: "<",
        allowHomeSelect: "<?",
        checkParentNode: "<?",
      },
      template: documents_docsMultiSelectHtml,
      controller: [
        "$scope",
        "TreeFilterSelectedService",
        "DocumentsService",
        function ($scope, TreeFilterSelectedService, DocumentsService) {
          var $ctrl = this;
          var selectedItems = {};
          $ctrl.onTreeDropdownToggle = onTreeDropdownToggle;
          $ctrl.saveSelected = saveSelected;
          $ctrl.isTreeDropdownOpen = false;

          $ctrl.$onInit = function () {
            var roots = getRoots();
            $ctrl.tree = new TreeFilterSelectedService(
              roots,
              onTreeChange,
              getKey,
              getChild,
              {
                defaultSelected: false,
                checkParentNode: $ctrl.checkParentNode,
              },
            );

            if (roots.length === 1) {
              $ctrl.tree.expandAncestors(roots[0]);
            }

            selectedItems.foldersIds = $ctrl.selectedFoldersIds;
            selectedItems.docIds = $ctrl.selectedDocsIds;
            updateTreeSelected();

            $scope.$watch("Documents.loading", function () {
              if (!DocumentsService.isLoaded()) {
                return;
              }
              updateTreeData();
            });
          };

          function saveSelected() {
            var foldersIds = $ctrl.tree.displayedSelectedList
              .filter(function (item) {
                return item.type === "folder";
              })
              .map(function (item) {
                return item.id;
              });
            var docIds = $ctrl.tree.displayedSelectedList
              .filter(function (item) {
                return item.type === "file";
              })
              .map(function (item) {
                return item.id;
              });
            selectedItems.foldersIds = foldersIds;
            selectedItems.docIds = docIds;
            $ctrl.onChange(foldersIds, docIds);
          }

          function updateTreeSelected() {
            // update tree state to current saved state
            // var s = performance.now();
            $ctrl.tree.unselectAll();
            selectedItems.foldersIds.forEach(function (fId) {
              var folder = DocumentsService.Folders[fId];
              $ctrl.tree.setSelected(folder, true, true);
              $ctrl.tree.expandAncestors(folder);
            });
            selectedItems.docIds.forEach(function (dId) {
              var doc = DocumentsService.Files[dId];
              $ctrl.tree.setSelected(doc, true, true);
              if (Object.keys(DocumentsService.Files).length < 1000) {
                // ignore large rooms for now
                // angular fails to render large expanded trees in select dropdown
                $ctrl.tree.expandAncestors(doc);
              }
            });
          }

          function onTreeChange() {
            if (!$ctrl.isTreeDropdownOpen) {
              // remove btn is clicked in selected display list
              saveSelected();
            }
          }

          function onTreeDropdownToggle(isOpen) {
            if (!isOpen) {
              // reset to last saved state after close
              updateTreeSelected();
            }
          }

          function updateTreeData() {
            $ctrl.tree.refresh(getRoots());
          }

          function getRoots() {
            if ($ctrl.allowHomeSelect) {
              return [DocumentsService.rootFolder];
            } else {
              return DocumentsService.rootFolder.items;
            }
          }

          function getKey(item) {
            return item.type + "-" + item.id;
          }

          function getChild(item) {
            if (item.type !== "folder") {
              return;
            }
            return item.items;
          }
        },
      ],
    };
  }
})();
