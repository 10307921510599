import "./activity";
import "./api";
import "./categories";
import "./dispatcher";
import "./documents";
// import './folders.js';
import "./grouppermissions";
import "./load";
import "./tasks";

angular.module("dealroom.analytics.service.data", [
  "dealroom.analytics.service.data.activity",
  "dealroom.analytics.service.data.api",
  "dealroom.analytics.service.data.categories",
  "dealroom.analytics.service.data.dispatcher",
  "dealroom.analytics.service.data.documents",
  // 'dealroom.analytics.service.data.folders',
  "dealroom.analytics.service.data.grouppermissions",
  "dealroom.analytics.service.data.loader",
  "dealroom.analytics.service.data.tasks",
]);
