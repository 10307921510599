import angular from "angular";
import { createApp } from "vue";
import { h } from "vue";
import { createI18n } from "vue-i18n";
import { getLangCookie } from "@app/vue/common";

import enUS from "@app/locales/en-US.json";
import frFR from "@app/locales/fr-FR.json";
import { installElementUi } from "@drVue/plugin-element-ui";
import { installVxeTable } from "@drVue/plugin-vxe-table";
import DrStore from "@drVue/store";
import { pinia } from "@drVue/store/pinia";
import getVueComponent from "../components/getVueComponent";
import evalPropEvents from "../components/props/evaluateEvents";
import evalValues from "../components/props/evaluateValues";
import extractSpecialAttributes from "../components/props/extractSpecialAttributes";
import getPropExprs from "../components/props/getExpressions";
import watchPropExprs from "../components/props/watchExpressions";
import watchSpecialAttributes from "../components/props/watchSpecialAttributes";
// import evaluateDirectives from "../directives/evaluateDirectives";
import { deleteState, getState } from "../state";

export function ngVueLinker(
  componentName,
  jqElement,
  elAttributes,
  scope,
  $injector,
) {
  if (!jqElement.parent().length)
    throw new Error(
      "ngVue components must have a parent tag or they will not render",
    );
  const e = jqElement[0];
  if (!e.id) {
    console.error(e);
    throw new Error("ngVue components must have a unique id");
  }
  const $ngVue = $injector.has("$ngVue") ? $injector.get("$ngVue") : null;

  const dataExprsMap = getPropExprs(elAttributes);
  const Component = getVueComponent(componentName, $injector);
  // const directives = evaluateDirectives(elAttributes, scope) || [];
  const reactiveData = {
    _v: {
      props: evalValues(dataExprsMap.props || dataExprsMap.data, scope) || {},
      attrs: evalValues(dataExprsMap.htmlAttributes, scope) || {},
      special: extractSpecialAttributes(elAttributes),
    },
  };
  const on = evalPropEvents(dataExprsMap, scope) || {};
  if (on["onUpdateModelValue"]) {
    on["onUpdate:ModelValue"] = on["onUpdateModelValue"];
    on["onUpdate:model-value"] = on["onUpdateModelValue"];
  }

  const inQuirkMode = $ngVue ? $ngVue.inQuirkMode() : false;
  const rootProps = $ngVue ? $ngVue.getRootProps() : {};

  const mounted = rootProps.mounted;
  const props = Object.assign({}, rootProps);
  props.mounted = function () {
    const element = jqElement[0];
    if (element.innerHTML.trim()) {
      let html;
      if (element.children.length === 0) {
        const span = document.createElement("span");
        span.innerHTML = element.innerHTML.trim();
        html = span;
      } else {
        html = element.children[0];
      }
      const slot = this.$refs.__slot__;
      slot.parentNode.replaceChild(html, slot);
    }
    if (angular.isFunction(mounted)) {
      mounted.apply(this, arguments);
    }
  };

  const watchOptions = {
    depth: elAttributes.watchDepth,
    quirk: inQuirkMode,
  };
  watchPropExprs(dataExprsMap, reactiveData, watchOptions, scope, "props");
  watchPropExprs(dataExprsMap, reactiveData, watchOptions, scope, "attrs");
  watchSpecialAttributes(reactiveData, jqElement, scope);

  const state = getState(dataExprsMap.htmlAttributes.id);
  state.props.value = reactiveData._v.props;
  const rProps = state.props;
  let app = createApp({
    name: componentName,
    render() {
      return h(Component, { ...rProps.value, ...on });
    },
  });

  installElementUi(app);
  installVxeTable(app);
  app.use(DrStore);
  app.use(pinia);

  const i18n = createI18n({
    legacy: false,
    locale: getLangCookie() ?? "en-US",
    fallbackLocale: "en-US",
    messages: {
      "en-US": enUS,
      "fr-FR": frFR,
    },
  });

  app.use(i18n);

  app.mount(jqElement[0]);

  scope.$on("$destroy", () => {
    app.unmount();
    app = null;

    deleteState(dataExprsMap.htmlAttributes.id);
  });
}
