<template>
  <DrPopup ref="popupRef" hide-arrow paddingless>
    <template #reference>
      <div
        v-if="!isReadOnly"
        :class="$style.wrapper"
        @click.capture.stop="dispatchLinkFileEventWhenEmpty"
      >
        <div
          :class="{
            [$style.content]: true,
            [$style.content_isEmpty]: !attachments.length,
          }"
        >
          <DrIcon size="sm" :class="$style.icon" name="paperclip" />
          <span v-if="attachments.length" :class="$style.label">
            {{ attachments.length }}
          </span>
          <DrIcon
            v-else
            size="xs"
            name="plus"
            :class="[$style.icon, $style.plusSign]"
          />
        </div>
      </div>

      <div v-else :class="$style.wrapper" />
    </template>
    <template #default>
      <AttachmentsPopupContent
        :items="attachments"
        :allow-attach-new-files="allowAttachNewFiles"
        :disabled="isReadOnly"
        @link-file="dispatchLinkFileEvent"
      />
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { computed, ref, unref } from "vue";
import { useStore } from "vuex";
import DrIcon from "@shared/ui/dr-icon";
import { DrPopup } from "@shared/ui/dr-popups";

import AttachmentsPopupContent from "@drVue/components/room/tasks/Attachments/AttachmentsPopupContent.vue";

import type { RequestsItem } from "@drVue/components/room/tasks/TasksTable/types";
import type { DocsItem } from "@drVue/store/modules/room/documents/DocumentsApiService";
import type { RootState } from "@drVue/store/state";
import type { VxeTableConstructor, VxeTablePrivateMethods } from "vxe-table";

interface Props {
  allowAttachNewFiles: boolean;
  table: VxeTableConstructor<RequestsItem> &
    VxeTablePrivateMethods<RequestsItem>;
  taskId: number;
  isReadOnly?: boolean;
}

const props = defineProps<Props>();

const store = useStore<RootState>();

const popupRef = ref<InstanceType<typeof DrPopup> | null>(null);
const dispatchLinkFileEvent = ($event: Event | null) => {
  unref(popupRef)?.hide();

  props.table.dispatchEvent(
    "cell-click",
    { customEvent: "link-file", taskId: props.taskId },
    $event,
  );
};
const dispatchLinkFileEventWhenEmpty = ($event: Event) => {
  if (attachments.value.length) {
    unref(popupRef)?.show();
  } else {
    dispatchLinkFileEvent($event);
  }
};

const attachments = computed(() => {
  const docs = store.state.room.documents;
  const rel = store.state.room.tasksRelated;

  const filesIds = rel.taskDocs[props.taskId] || [];
  const foldersIds = rel.taskFolders[props.taskId] || [];

  const files = filesIds.map<DocsItem>(
    ({ document_id }) => docs.files[document_id],
  );
  const folders = foldersIds.map<DocsItem>(
    ({ folder_id }) => docs.folders[folder_id],
  );

  return files.concat(folders).sort((a, b) => a.treePosition - b.treePosition);
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.wrapper {
  width: 100%;
  height: 100%;
  &:hover {
    .content {
      opacity: 1;
    }
  }
}

.content {
  color: colors.$pr-500;
  padding: 4px 6px;
  border-radius: 6px;
  transition: all 0.2s ease-in-out;
  border: 1px solid transparent;
  display: inline-flex;
  align-items: center;
  min-width: 100%;
  margin: auto;

  &:hover {
    border: 1px solid colors.$sc-400;
    .icon {
      color: colors.$sc-600;
    }
  }
}

.content_isEmpty {
  opacity: 0;
}

.plusSign {
  margin-left: 2px;
}

.label {
  line-height: 14px;
  margin-left: 4px;
  color: colors.$pr-900;
}
</style>
