<template>
  <div
    class="deal-create"
    :class="isOnlyBlankOptionShown ? 'deal-create--blank-only' : ''"
  >
    <RoomCreateForm
      v-model:create-room="isCreatingRoom"
      :class="$style.roomCreateForm"
      :playbook="selectedPlaybook!"
      :deal="deal"
      :data-type="createBy"
      @created="onRoomCreate"
    >
      <div v-if="!isOnlyBlankOptionShown && isCreatingRoom">
        <h2 class="deal-create__header">Populate your room</h2>
        <div class="deal-create__options">
          <div
            v-for="item in switchDataTypeButtons"
            :key="item.type"
            class="el-button deal-create__options-item"
            :class="
              createBy === item.type
                ? 'el-button--primary'
                : 'el-button--plain el-button--default'
            "
            @click="setCreateBy(item.type)"
          >
            <i class="fas" :class="item.icon" />
            {{ item.label }}
          </div>
        </div>
      </div>
      <Playbooks
        v-if="
          createBy === INITIAL_DATA_TYPES.Template &&
          !selectedPlaybook &&
          isCreatingRoom
        "
        @select="(p) => (selectedPlaybook = p)"
      />
      <div class="deal-create__create-wrapper">
        <div
          v-if="INITIAL_DATA_TYPES.Template && selectedPlaybook"
          class="deal-create__create-wrapper-column"
        >
          <PlaybooksView
            :playbook="selectedPlaybook!"
            :show-select-button="false"
            @back="setCreateBy(INITIAL_DATA_TYPES.Template)"
          />
        </div>
      </div>
    </RoomCreateForm>
  </div>
</template>

<script lang="ts">
import { isEmpty } from "lodash-es";
import { defineComponent } from "vue";

import {
  APP_SETTINGS,
  CLIENT_DASHBOARD_DATA,
  USER_DASHBOARD_DATA,
} from "@setups/data";
import { PaymentTypes } from "@setups/enums";
import { IS_PIPELINE_OPEN } from "@drVue/components/navigation/utils";
import playbooks from "@drVue/components/user-dashboard/playbooks/data";
import Playbooks from "@drVue/components/user-dashboard/playbooks/Playbooks.vue";
import PlaybooksView from "@drVue/components/user-dashboard/playbooks/PlaybooksView.vue";
import { useOrgStore } from "@drVue/store/modules/common/organization/store";
import RoomCreateForm from "./form.vue";
import { INITIAL_DATA_TYPES } from "./utils";

import type { RoomCreateResp } from "@drVue/api-service/common/rooms";
import type { Playbook } from "@drVue/components/user-dashboard/playbooks/types";
import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";
import type { PropType } from "vue";

const blankButton = {
  id: "blank",
  type: INITIAL_DATA_TYPES.Blank,
  label: "Keep blank",
  icon: "fa-plus",
};

const copyButton = {
  id: "copy",
  type: INITIAL_DATA_TYPES.CopyRoom,
  label: "Copy room",
  icon: "fa-clone",
};

const templateButton = {
  id: "template",
  type: INITIAL_DATA_TYPES.Template,
  label: "Use playbook",
  icon: "fa-book",
};

interface Data {
  selectedPlaybook: Playbook | null;
  isCreatingRoom: boolean;
  createBy: INITIAL_DATA_TYPES;
}

const isPipelineOnly =
  CLIENT_DASHBOARD_DATA.payment_type &&
  CLIENT_DASHBOARD_DATA.payment_type == PaymentTypes.PipelineOnly;

export default defineComponent({
  name: "RoomCreate",
  components: { PlaybooksView, Playbooks, RoomCreateForm },
  props: {
    deal: {
      required: false,
      default: undefined,
      type: Object as PropType<Deal>,
    },
    canCreateDeal: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  emits: ["created"],
  data(): Data {
    return {
      selectedPlaybook: null,
      isCreatingRoom: !this.canCreateDeal,
      createBy:
        APP_SETTINGS.WEBSITE.IS_DEALROOM && !isPipelineOnly
          ? templateButton.type
          : blankButton.type,
    };
  },
  computed: {
    isOnlyBlankOptionShown(): boolean {
      return this.switchDataTypeButtons.length === 1;
    },
    switchDataTypeButtons(): any {
      const buttons = [blankButton];

      if (isPipelineOnly) {
        return buttons;
      }

      if (APP_SETTINGS.WEBSITE.IS_DEALROOM) {
        buttons.unshift(templateButton);
      }

      if (isEmpty(USER_DASHBOARD_DATA) && this.deals.length > 0) {
        buttons.push(copyButton);
      }

      return buttons;
    },
    INITIAL_DATA_TYPES(): any {
      return INITIAL_DATA_TYPES;
    },
    playbooks(): any {
      return playbooks;
    },
    deals(): any {
      return this.$store.state.clientDashboard.deals.items;
    },
  },
  methods: {
    setCreateBy(value: INITIAL_DATA_TYPES) {
      this.createBy = value;
      this.selectedPlaybook = null;
    },
    onRoomCreate(roomData: RoomCreateResp) {
      if (this.isCreatingRoom) {
        if (!isPipelineOnly && roomData?.url) {
          location.href = roomData.url;
          return;
        }
      }
      if (IS_PIPELINE_OPEN) {
        this.$store.dispatch("clientDashboard/deals/load");
      }
      useOrgStore().getRooms(true);
      this.$emit("created", roomData);
    },
  },
});
</script>

<style module lang="scss">
.roomCreateForm {
  margin: 20px 0;
}
</style>
