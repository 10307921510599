<template>
  <ElInput
    ref="inputRef"
    type="number"
    :validate-event="false"
    :model-value="editProps.value"
    :readonly="editProps.schema.isReadOnly"
    :placeholder="editProps.schema.placeholder"
    v-bind="editProps.veeField"
    @wheel.prevent
  >
    <template v-if="editProps.schema.extra?.symbol" #prepend>
      {{ editProps.schema.extra.symbol }}
    </template>
  </ElInput>
</template>

<script setup lang="ts">
import { ElInput } from "element-plus";
import { get } from "lodash-es";
import { onMounted, ref } from "vue";

import { delayedCall } from "@drVue/common";

import type { FieldProps } from "../types";

interface Props {
  editProps: FieldProps;
}

const props = defineProps<Props>();

const inputRef = ref<InstanceType<typeof ElInput> | null>(null);

onMounted(() => {
  const delay = get(props.editProps.schema, "extra.autofocus") ?? false;
  delayedCall(() => inputRef.value?.focus(), delay);
});
</script>
