import type { DownloadsState } from "./DownloadsState";
import type { MutationTree } from "vuex";

export const downloadsMutations: MutationTree<DownloadsState> = {
  setIsLoading(state, isLoading: boolean) {
    state.isLoading = isLoading;
  },

  setDownloads(state, downloads: any[]) {
    state.downloads = downloads;
  },

  showDialog(state) {
    state.isDialogVisible = true;
  },

  hideDialog(state) {
    state.isDialogVisible = false;
  },
};
