<template>
  <div class="playbook">
    <div class="playbook__back">
      <ElButton type="text" @click="back">
        <i class="fas fa-angle-left" /> Back to gallery
      </ElButton>
    </div>

    <div>
      <h2 class="playbook__title">{{ playbook.title }}</h2>
      <p class="playbook__description">{{ playbook.description }}</p>
    </div>

    <h5 class="playbook__subtitle">
      This playbook includes requests for the following categories:
    </h5>
    <ul class="playbook__list">
      <li v-for="req in playbook.requests" :key="req">{{ req }}</li>
    </ul>

    <h5 v-if="playbook.resources.length" class="playbook__subtitle">
      Useful resources:
    </h5>
    <ul class="playbook__list">
      <li v-for="res in playbook.resources" :key="res.title">
        <a :href="res.url" target="_blank">{{ res.title }}</a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { Playbook } from "@drVue/components/user-dashboard/playbooks/types";
import type { PropType } from "vue";

export default defineComponent({
  name: "PlaybooksView",
  props: {
    playbook: { required: true, type: Object as PropType<Playbook> },
  },
  emits: ["back", "use"],
  methods: {
    back() {
      this.$emit("back");
    },
    use() {
      this.$emit("use", this.playbook);
    },
  },
});
</script>
