import DrStore from "@drVue/store";

angular
  .module("dealroom.analytics.service.data.categories.tasks-counter", [
    "dealroom.task",
  ])
  .service(
    "ActivityCategoriesTaskCounterService",
    ActivityCategoriesTaskCounterService,
  );

ActivityCategoriesTaskCounterService.$inject = [];
function ActivityCategoriesTaskCounterService() {
  const service = { get: getCounter };
  service.STATUSES = Object.freeze(
    DrStore.state.room.tasksStatuses.list.map((s) => s.id),
  );
  service.PRIORITIES = Object.freeze(["low", "medium", "high"]);

  return service;

  function getCounter() {
    return new TasksCounter();

    function TasksCounter(_tasks = []) {
      const tasks = _tasks.slice();

      this.add = add;
      this.get = get;
      // this.includes = includes;
      this.copy = getVisibleCopy;

      function add(task) {
        tasks.push(task);
      }
      // function includes(taskId) {
      //   return !!tasks.find(task => +task.id === +taskId);
      // }

      function get({
        statusesIds = service.STATUSES,
        prioritiesNames = service.PRIORITIES,
        statusType,
      } = {}) {
        if (statusType) {
          statusesIds = DrStore.state.room.tasksStatuses.byTypes[statusType];
        }

        return tasks.filter(
          (task) =>
            statusesIds.includes(+task.status.id) &&
            prioritiesNames.includes(task.priority),
        );
      }
      function getVisibleCopy({
        statusesIds = service.STATUSES,
        prioritiesNames = service.PRIORITIES,
      } = {}) {
        return new TasksCounter(get({ statusesIds, prioritiesNames }));
      }
    }
  }
}
