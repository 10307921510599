import { sub } from "date-fns";
import { cloneDeep } from "lodash-es";

import { drUserTime } from "@drVue/filters/drUserTime";
import { ActionVerbs } from "../types";

import type { Action } from "../types";
import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";
import type { CustomDataType } from "@drVue/store/modules/client-dashboard/fields/types";
import type { Dictionary } from "@drVue/types";

export function dateToKey(dt: Date): string {
  const key = drUserTime(dt, "iso-date");
  if (key === null) throw new Error("dateToKey");

  return key;
}

function getDealId(action: Action): number {
  if (action.action === ActionVerbs.DealAdd) {
    return (action.object && action.object.id) as number;
  }
  return (action.target && action.target.id) as number;
}

export interface DealHistoryData {
  phase: null | number;
  custom_data: CustomDataType;
}

export type IDealsDailyData = Dictionary<DealHistoryData>;
export type IDealsHistoryData = Dictionary<IDealsDailyData>;

type IHistoryInitialized = { [key in keyof DealHistoryData]: boolean };

function overwriteDataHistory(
  history: IDealsHistoryData,
  ignoreDate: string | null,
  dealId: number,
  values: Partial<DealHistoryData>,
) {
  // if some data was set on deal create (for example using import)
  // we need to restore it's values from first change action or current state
  for (const date of Object.keys(history)) {
    if (ignoreDate !== null && date === ignoreDate) {
      continue;
    }
    const dealData = history[date][dealId];
    if (dealData) {
      history[date][dealId] = { ...dealData, ...values };
    }
  }
}

export function dealsHistory(
  deals: Deal[],
  actions: Action[],
): IDealsHistoryData {
  const currentData: IDealsDailyData = {};
  const dataHistory: IDealsHistoryData = {};
  const isInitialized: Dictionary<IHistoryInitialized> = {};

  let currentDate = "";
  for (const action of actions) {
    const actionDateKey = dateToKey(action.dt);
    if (actionDateKey !== currentDate && currentDate) {
      // action on a new day, remember current data
      dataHistory[currentDate] = cloneDeep(currentData);
    }

    currentDate = actionDateKey;
    const dealId = getDealId(action);

    // initialize with empty data if not provided
    if (!currentData[dealId]) {
      currentData[dealId] = {
        phase: null,
        custom_data: {},
      };
      isInitialized[dealId] = {
        phase: false,
        custom_data: false,
      };
    }

    switch (action.action) {
      case ActionVerbs.DealCustomData:
        currentData[dealId].custom_data = action.content.new_value;
        if (!isInitialized[dealId].custom_data) {
          const oldValue = action.content["old_value"] || {};
          overwriteDataHistory(dataHistory, currentDate, dealId, {
            custom_data: oldValue,
          });
          isInitialized[dealId].custom_data = true;
        }
        break;
      case ActionVerbs.DealPhase:
        if (!isInitialized[dealId].phase) {
          const oldValue = action.content["old_value"] || null;
          overwriteDataHistory(dataHistory, currentDate, dealId, {
            phase: oldValue,
          });
          isInitialized[dealId].phase = true;
        }
        currentData[dealId].phase = action.content.new_value;
    }
  }
  dataHistory[currentDate] = currentData;
  // check if some deals do not have change actions and set values from current state
  for (const deal of deals) {
    if (!isInitialized[deal.id].phase) {
      overwriteDataHistory(dataHistory, null, deal.id, { phase: deal.phase });
    }
    if (!isInitialized[deal.id].custom_data) {
      overwriteDataHistory(dataHistory, null, deal.id, {
        custom_data: deal.custom_data,
      });
    }
  }
  return dataHistory;
}

export function getHistoryState(
  history: IDealsHistoryData,
  date: Date,
): IDealsDailyData | undefined {
  const _20years = 20 * 365;
  for (let i = 0; i <= _20years; i++) {
    const key = drUserTime(date, "iso-date");
    if (key === null) throw new Error("getHistoryState");

    if (history[key]) {
      return history[key];
    }

    date = sub(date, { days: 1 });
  }

  // nothing found in 20 years before passed day
  // date should be validated to be in history, so this is should not happen
  return undefined;
}
