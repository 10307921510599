export interface Resource {
  url: string;
  title: string;
}

export interface Playbook {
  id: string;
  bannerFilename?: string;
  title: string;
  description: string;
  requests: string[];
  tags: string[];
  resources: Resource[];
}

export enum Tags {
  DueDiligence = "Due Diligence",
  IPO = "IPO",
  Integration = "Integration",
  PrivateEquity = "Private Equity",
  CapitalRaise = "Capital Raise",
}
