<template>
  <div :class="$style.app">
    <Navigation />
    <div :class="$style.page">
      <RouterView
        :class="{
          'dash-container': hasOldLayout,
          'dash-container--no-padding-sides': hasOldLayout && noPaddingSides,
          'dash-container--no-padding': hasOldLayout && noPadding,
        }"
      />
      <DrAlert />
      <TimezoneDialog />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TimezoneDialog from "@shared/ui/timezone-dialog/TimezoneDialog.vue";

import Navigation from "@drVue/components/navigation/Navigation.vue";
import { DrAlert } from "../";

export default defineComponent({
  name: "App",
  components: {
    DrAlert,
    Navigation,
    TimezoneDialog,
  },
  computed: {
    noPaddingSides(): any {
      return this.$route.matched.some((r) => r?.meta?.noPaddingSides);
    },
    noPadding(): any {
      return this.$route.matched.some((r) => r?.meta?.noPadding);
    },
    /**
     * For the dashboard deals' page, the layout was refactored
     * and a separate component DrLayoutPage was used.
     * Therefore, it does not require the old classes.
     */
    hasOldLayout(): boolean {
      return !["/your-work", "/deals", "/reports", "/people", "/settings"].some(
        (path) => this.$route.path.startsWith(path),
      );
    },
  },
});
</script>

<style lang="scss" module>
.app {
  display: grid;
  grid-template-columns: min-content 1fr;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.page {
  max-width: 100%;
  overflow: hidden;
}
</style>
