export function buildQueryParams(obj: { [key: string]: any }): string {
  let params: URLSearchParams | string = new URLSearchParams();

  for (const k of Object.keys(obj)) {
    if (obj[k]) params.append(k, obj[k].toString());
  }

  params = params.toString();
  return params ? `?${params}` : "";
}
