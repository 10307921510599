// patched https://raw.githubusercontent.com/ueberdosis/tiptap/cf97cdf6f024d1159a527b49b7089d563093ca58/packages/extension-mention/src/mention.ts
import { mergeAttributes, Node, type NodeViewProps } from "@tiptap/core";
import { PluginKey } from "@tiptap/pm/state";
import { VueNodeViewRenderer } from "@tiptap/vue-3";

import MentionNodeView from "./MentionNodeView.vue";
import { MentionProvider } from "./providers";
import { Suggestion } from "./suggestionExt";

import type { SuggestionOptions } from "./suggestionExt";
import type { Node as ProseMirrorNode } from "@tiptap/pm/model";
import type { Component } from "vue";

export type MentionOptions = {
  mentionsProvider: MentionProvider;
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: {
    options: MentionOptions;
    node: ProseMirrorNode;
  }) => string;
  suggestion: Omit<SuggestionOptions, "editor" | "provider">;
};

export const MentionPluginKey = new PluginKey("dr_mention");
const MentionIdAttr = "data-dr-mention-id";
const MentionTypeAttr = "data-dr-mention-type";

export const Mention = Node.create<MentionOptions>({
  name: "dr_mention",

  addOptions() {
    return {
      mentionsProvider: new MentionProvider(),
      HTMLAttributes: {},
      renderLabel({ options, node }) {
        return this.mentionsProvider.getLabel(node.attrs.type, node.attrs.id);
      },
      suggestion: {
        pluginKey: MentionPluginKey,
        command: ({ editor, range, props }) => {
          // increase range.to by one when the next node is of type "text"
          // and starts with a space character
          const nodeAfter = editor.view.state.selection.$to.nodeAfter;
          const overrideSpace = nodeAfter?.text?.startsWith(" ");

          if (overrideSpace) {
            range.to += 1;
          }

          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: "dr_mention",
                attrs: props,
              },
              {
                type: "text",
                text: " ",
              },
            ])
            .run();
        },
        allow: ({ editor, range }) => {
          return editor.can().insertContentAt(range, { type: "dr_mention" });
        },
      },
    };
  },

  group: "inline",

  inline: true,

  selectable: false,

  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute(MentionIdAttr),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }

          return {
            [MentionIdAttr]: attributes.id,
          };
        },
      },

      type: {
        default: null,
        parseHTML: (element) => element.getAttribute(MentionTypeAttr),
        renderHTML: (attributes) => {
          if (!attributes.type) {
            return {};
          }

          return {
            [MentionTypeAttr]: attributes.type,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `span[${MentionTypeAttr}]`,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return ["vue-component", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return VueNodeViewRenderer(MentionNodeView as Component<NodeViewProps>, {
      stopEvent({ event }) {
        return false;
      },
    });
  },

  renderText({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node,
    });
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isMention = false;
          const { selection } = state;
          const { empty, anchor } = selection;

          if (!empty) {
            return false;
          }

          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === this.name) {
              isMention = true;
              const trigger = this.options.mentionsProvider.getTypeTrigger(
                node.attrs.type,
              );
              tr.insertText(trigger || "", pos, pos + node.nodeSize);

              return false;
            }
          });

          return isMention;
        }),
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        provider: this.options.mentionsProvider,
        ...this.options.suggestion,
      }),
    ];
  },
});
