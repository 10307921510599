import { useEventBus } from "@vueuse/core";

import type { FolderMenuParams } from "../utils";
import type { ElPopover } from "element-plus";
import type { Component, ComponentInstance, ComputedOptions, Raw } from "vue";

export type MenuComponent = Component<
  { params?: FolderMenuParams },
  unknown,
  unknown,
  ComputedOptions,
  { close: () => void },
  {
    close(e: "close"): void;
    updatePersisted(e: "update:persisted", value: boolean): void;
  }
>;

export type FolderMenuPayload = {
  reference?: HTMLElement;
  params: FolderMenuParams;
  menuComponent: Raw<MenuComponent>;
  placement?: ComponentInstance<typeof ElPopover>["placement"];
};

export const useFolderMenuBus = () => {
  return useEventBus<"menu:show", FolderMenuPayload>("folder-menu");
};

export const useFolderMenuPersistedBus = () => {
  return useEventBus<"menu:persisted", boolean>("folder-menu-persisted");
};

export const useFolderMenuActiveBus = () => {
  return useEventBus<"menu:active", boolean>("folder-menu-active");
};

export const useFolderBulkMenuBus = () => {
  return useEventBus<"bulk:clear-selected", void>("folder-bulk-clear-selected");
};
