<template>
  <div>
    <span>
      <span>
        <a class="mgmt-sub-info__info-btn" @click="isExpanded = !isExpanded">
          <i class="fas fa-info" />
          {{ sub.title }} <b>{{ sub.status }}</b>
        </a>
      </span>
      <a
        :href="sub.stripe_url"
        target="_blank"
        class="mgmt-sub-info__stripe-link"
      >
        <i class="fas fa-external-link" />
        <small> view in stripe </small>
      </a>
    </span>
    <div v-if="isExpanded">
      <div v-for="row in details" :key="row.key">
        <b>{{ row.key }}:</b> {{ row.value }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { drUserTime } from "@drVue/filters/drUserTime";

import type { PropType } from "vue";

interface SubInfo {
  title: string;
  created: Date;
  days_until_due: number;
  status: string;
  trial_end: Date | null;
  current_period_end: Date;
  cancel_at_period_end: boolean;
  billing: string;
  stripe_url: string;
}

type ISubInfoKeys = keyof SubInfo;

interface Data {
  isExpanded: boolean;
}

export default defineComponent({
  name: "SubscriptionInfo",
  props: {
    sub: { required: true, type: Object as PropType<SubInfo> },
  },
  data(): Data {
    return {
      isExpanded: false,
    };
  },
  computed: {
    details(): any {
      return Object.keys(this.sub)
        .filter((key) => key != "stripe_url")
        .map((key: string) => {
          let value = this.sub[key as keyof SubInfo];
          if (["created", "trial_end", "current_period_end"].includes(key)) {
            value = drUserTime(value, "full-dt");
          }
          return { key, value };
        });
    },
  },
});
</script>
