import { toDate } from "date-fns-tz";
import { keyBy } from "lodash-es";
import { h } from "vue";
import { TableInlineEditor } from "@shared/ui/table-inline-editor";

import { ROOM_MEMBER_DATA } from "@setups/data";
import { TaskFieldAccessType } from "@setups/enums";
import { ROOM_DATA } from "@app/setups";
import { serializeCustomData } from "@drVue/api-service/parse";
import { mapCustomFieldToSchema } from "@drVue/components/client-dashboard/dynamic-form/utils";
import { getPeriodKey } from "@drVue/components/room/synergies/utils";
import { getSortValue } from "@drVue/store/modules/client-dashboard/deals/utils";
import { TableColumnsBase } from "@drVue/TableColumnsBase";
import EnabledBy from "./EnabledBy.vue";

import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { DrVxeTableColumn } from "@drVue/components/types";
import type { PeriodData } from "@drVue/store/modules/room/synergies/SynergiesApiService";
import type { SynergyTableRow } from "@drVue/store/modules/room/synergies/table";
import type { useValueDriversStore } from "@drVue/store/modules/room/synergies/value-drivers";
import type { CustomViewColumn } from "@setups/types";
import type { Ref } from "vue";

export default class TableColumns extends TableColumnsBase<SynergyTableRow> {
  constructor(
    private viewColumns: Ref<CustomViewColumn[]>,
    private valueDriversStore: ReturnType<typeof useValueDriversStore>,
    private driverCustomFields: FieldItem[],
    private dataCustomFields: FieldItem[],
  ) {
    super();
  }

  getUserColumns(): Record<string, CustomViewColumn> {
    const columns = this.viewColumns.value;
    return columns ? keyBy(columns, "field") : {};
  }

  inlineEditor = new TableInlineEditor((changes: any, row: any) => {
    if (typeof changes.custom_data === "object") {
      return this.valueDriversStore.patch(row.id, {
        custom_data: serializeCustomData(
          changes.custom_data,
          this.driverCustomFields,
        ),
      });
    }

    const promises: Promise<PeriodData>[] = [];
    const keys = Object.keys(changes);
    for (const key of keys) {
      const [_periods_data, period, cf] = key.split(".");

      const value = changes[key];
      const data = serializeCustomData(
        {
          ...row.periods_data[period],
          [cf]: value,
        },
        this.dataCustomFields,
      );

      // `period` is in format "_2024_01_01"
      const [_empty, y, m, d] = period.split("_");

      promises.push(
        this.valueDriversStore.patchPeriod(row.id, `${y}-${m}-${d}`, data),
      );
    }

    return Promise.all(promises);
  }, "id");

  getTableColumns(): DrVxeTableColumn<SynergyTableRow>[] {
    return [
      this.title(),
      this.enabledBy(),
      ...this.driverCustomFields.map((field) =>
        this.driverCustomFieldColumn(field),
      ),
      ...this.getPeriodsColumns(),
    ];
  }

  private getPeriodsColumns(): DrVxeTableColumn<SynergyTableRow>[] {
    const columns: DrVxeTableColumn<SynergyTableRow>[] = [];

    const accessLevel = ROOM_MEMBER_DATA.group.synergy_tracking_fields_access;
    if (
      !ROOM_DATA.synergySettings ||
      accessLevel === TaskFieldAccessType.NoAccess
    ) {
      return columns;
    }

    const INTERVAL = ROOM_DATA.synergySettings.interval;
    const SYNERGIES_FROM = toDate(ROOM_DATA.synergySettings.from_date);
    const SYNERGIES_TO = toDate(ROOM_DATA.synergySettings.to_date);

    const interval = INTERVAL === "month" ? 1 : 3;
    for (
      const cursor = SYNERGIES_FROM;
      cursor <= SYNERGIES_TO;
      cursor.setMonth(cursor.getMonth() + interval)
    ) {
      const periodStr =
        INTERVAL === "month"
          ? cursor.toLocaleString("default", { month: "short" })
          : `Q${Math.ceil((cursor.getMonth() + 1) / 3)}`;
      const yearStr = `${cursor.getFullYear()}`.slice(-2); // 2024 -> 24

      columns.push({
        sortable: false,
        title: `${periodStr} - ${yearStr}`,
        field: "",
        children: this.dataCustomFields.map((f) => {
          const field = `periods_data.${getPeriodKey(cursor)}.${f.key}`;
          return {
            sortable: true,
            title: f.label,
            minWidth: 80,
            width: 132,
            field: field,
            slots: {
              default: (params: any) => {
                const schema = mapCustomFieldToSchema(f);
                schema.prop = field;

                if (
                  accessLevel !== TaskFieldAccessType.View &&
                  accessLevel !== TaskFieldAccessType.Edit
                ) {
                  // We already bailed out if `accessLevel` is `NoAccess` above.
                  // Therefore, we can't reach this code path.
                  throw new Error(
                    `[Invariant Violation] Unexpected 'synergy_tracking_fields_access' value: ${accessLevel}`,
                  );
                }
                schema.isReadOnly = accessLevel === TaskFieldAccessType.View;

                return this.inlineEditor.renderInlineField(schema, params);
              },
            },
          };
        }),
      });
    }

    return columns;
  }

  private title(): DrVxeTableColumn<SynergyTableRow> {
    return {
      sortable: true,
      field: "title",
      title: "Value driver",
      fixed: "left",
      minWidth: 170,
      width: 270,
      slots: {
        default: ({ row }) => {
          return row.type === "synergy"
            ? h(`b`, { style: "font-weight: 600" }, row.title)
            : row.title;
        },
      },
    };
  }

  private enabledBy(): DrVxeTableColumn<SynergyTableRow> {
    return {
      sortable: false,
      field: "enabled_by",
      title: "Enabled by",
      fixed: "left",
      minWidth: 150,
      width: 250,
      slots: {
        default: ({ row }) => {
          if (row.type === "synergy") {
            return "";
          } else {
            return h(EnabledBy as any, { row });
          }
        },
      },
    };
  }

  private driverCustomFieldColumn(
    field: FieldItem,
  ): DrVxeTableColumn<SynergyTableRow> {
    return {
      sortable: true,
      field: `custom_data.${field.key}`,
      title: field.label,
      width: 132,
      sortBy: ({ row }) => getSortValue(field, row.custom_data[field.key]),
      slots: {
        default: (params) => {
          const schema = mapCustomFieldToSchema(field);

          const accessLevel = ROOM_MEMBER_DATA.group.synergies_access;
          if (
            accessLevel !== TaskFieldAccessType.View &&
            accessLevel !== TaskFieldAccessType.Edit
          ) {
            // We can't open the Synergies page if `accessLevel` is `NoAccess`.
            // Therefore, we can't reach this code path.
            //
            // We should throw an error here to make sure that we will notice if
            // this code path is reached, and block the user from opening the
            // Synergies page.
            //
            // See route that uses `<VueSynergiesPage>` component in `app.js`.
            throw new Error(
              `[Invariant Violation] Unexpected 'synergies_access' value: ${accessLevel}`,
            );
          }

          schema.isReadOnly = accessLevel === TaskFieldAccessType.View;

          return this.inlineEditor.renderInlineField(schema, params);
        },
      },
    };
  }
}
