<template>
  <DrLayoutContentCenteredSection title="Organization">
    <template #description>
      Set the name you would like displayed for all users when they access your
      organizations account with DealRoom.
    </template>

    <ElForm
      ref="orgFormRef"
      label-position="top"
      require-asterisk-position="right"
      :model="orgFormModel"
      :rules="formRules"
      :disabled="isFormSubmitting"
    >
      <ElFormItem
        :error="formErrors.public_name"
        label="Оrganization name"
        prop="public_name"
        required
      >
        <ElInput
          v-model="orgFormModel.public_name"
          placeholder="Please specify organization name"
        />
      </ElFormItem>

      <div class="text-right">
        <ElButton
          type="primary"
          :loading="isFormSubmitting"
          @click="updateOrg(orgFormRef)"
        >
          Update
        </ElButton>
      </div>

      <ElAlert
        v-if="formErrors.non_field_errors"
        :title="formErrors.non_field_errors"
      />
    </ElForm>
  </DrLayoutContentCenteredSection>
</template>

<script lang="ts" setup>
import { reactive, ref } from "vue";
import { useStore } from "vuex";
import { useFormHelper } from "@shared/ui/dr-form";
import { DrLayoutContentCenteredSection } from "@shared/ui/dr-layouts";

import { $notifyDanger } from "@drVue/common";

import type { FormInstance, FormRules } from "element-plus";

const $store = useStore();

interface OrgFormModel {
  public_name: string;
}
const orgFormRef = ref<FormInstance>();
const orgFormModel = reactive<OrgFormModel>({
  public_name: $store.state.clientDashboard.settings.public_name,
});
const formRules = reactive<FormRules>({
  public_name: [
    {
      required: true,
      message: "Оrganization public name is required.",
    },
  ],
});

const { formErrors, hookFormSubmitPromise, isFormSubmitting } =
  useFormHelper<OrgFormModel>();

const updateOrg = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid) => {
    if (valid) {
      hookFormSubmitPromise(
        $store.dispatch("clientDashboard/settings/update", {
          public_name: orgFormModel.public_name,
        }),
      ).then(
        () => {
          window.location.reload();
        },
        () => $notifyDanger(`Failed to update organization.`),
      );
    }
  });
};
</script>
