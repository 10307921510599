import { computed, ref, watch } from "vue";

import { DealTasksApiService } from "@drVue/api-service/modules/deal-tasks";
import { createDictionary } from "@drVue/common";

import type {
  DealTask,
  DealTaskCreatePayload,
  DealTaskUpdatePayload,
} from "@drVue/api-service/modules/deal-tasks/types";
import type { ComputedRef } from "vue";

export type { DealTask, DealTaskCreatePayload, DealTaskUpdatePayload };

type DealId = number;

const $api = new DealTasksApiService();

export const useDealTasks = (dealId: ComputedRef<DealId>) => {
  const list = ref<DealTask[]>([]);
  const isLoading = ref(false);
  const isLoadError = ref(false);

  const dict = computed(() =>
    list.value.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, createDictionary<DealTask>()),
  );

  const load = async () => {
    if (!dealId.value) {
      list.value = [];
      return [];
    }

    isLoading.value = true;
    isLoadError.value = false;

    try {
      const tasksRaw = await $api.load(dealId.value);
      list.value = tasksRaw.sort(
        (a, b) => b.date_added.getTime() - a.date_added.getTime(),
      );

      isLoading.value = false;
      return list.value;
    } catch (err) {
      isLoading.value = false;
      isLoadError.value = true;
      throw err;
    }
  };

  const create = async (payload: DealTaskCreatePayload) => {
    const createdItem = await $api.create(dealId.value, payload);
    list.value.unshift(createdItem);
    return createdItem;
  };

  const update = async (
    taskId: DealTask["id"],
    payload: DealTaskUpdatePayload,
  ) => {
    const updatedItem = await $api.update(dealId.value, taskId, payload);
    const i = list.value.findIndex(({ id }) => id === taskId);
    if (i > -1) list.value.splice(i, 1, updatedItem);

    return list.value[i];
  };

  const remove = async (taskId: string) => {
    await $api.remove(dealId.value, taskId);
    const i = list.value.findIndex(({ id }) => id === taskId);
    if (i > -1) list.value.splice(i, 1);
  };

  watch(dealId, load, { immediate: true });

  return {
    list,
    dict,
    isLoading,
    isLoadError,
    load,
    create,
    update,
    remove,
  };
};
