import * as d3 from "d3";

angular
  .module("dealroom.analytics.factory.dispatcher", [])
  .factory("AnalyticsDispatcherFactory", AnalyticsDispatcher);

function AnalyticsDispatcher() {
  return create;

  function create(...events) {
    const dispatcher = d3.dispatch(...events);
    const timers = {};
    function getHandler(fn, uid, $scope, $element) {
      let timeoutId;
      return {
        on: function (...args) {
          // broke call stack
          clearTimeout(timeoutId);
          timeoutId = window.setTimeout(function () {
            if (!$scope.$$destroyed) {
              fn(...args);
            }
          }, 10);
        },
        off: function () {
          clearTimeout(timeoutId);
          d3.select($element[0]).selectAll("*").remove();
          dispatcher.on(uid, null);
        },
      };
    }

    function on(event, fn, $element, $scope) {
      if (!$element) {
        dispatcher.on(event, fn);
      } else {
        const uid = event + "." + $element[0].tagName + "-" + $scope.$id;
        const handler = getHandler(fn, uid, $scope, $element);

        dispatcher.on(uid, handler.on);

        $scope.$on("$destroy", handler.off);
        $scope.$on("$stateChangeStart", handler.off);
      }
    }

    return { on, call, add, debug: dispatcher, timerCall };

    function timerCall(event) {}

    function call(event, ...args) {
      window.clearTimeout(timers[event]);
      if (args.length === 0) {
        timers[event] = window.setTimeout(function () {
          dispatcher.call(event, null, ...args);
        }, 10);
      } else {
        dispatcher.call(event, null, ...args);
      }
    }

    function add(...events) {
      events.forEach((event) => {
        dispatcher._[event] = [];
      });
    }
  }
}
