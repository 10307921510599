<template>
  <div>
    <ElButton type="primary" @click="restoreDeal">Restore</ElButton>
    <ElButton type="danger" @click="deleteDeal">Delete</ElButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { Dictionary } from "@drVue/types";
import type { PropType } from "vue";

export default defineComponent({
  name: "MenuDealCell",
  props: {
    deal: { required: true, type: Object as PropType<Dictionary<any>> },
  },
  emits: ["restore", "delete"],
  methods: {
    restoreDeal() {
      this.$emit("restore", this.deal);
    },
    deleteDeal() {
      this.$emit("delete", this.deal);
    },
  },
});
</script>
