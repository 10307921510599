<template>
  <PanelDatePick type="date" :parsed-value="parsedValue" @pick="handlePick" />
</template>

<script setup lang="ts">
import { ROOT_PICKER_INJECTION_KEY } from "element-plus/es/components/date-picker/src/constants";
import PanelDatePick from "element-plus/es/components/date-picker/src/date-picker-com/panel-date-pick";
import { timePickerDefaultProps } from "element-plus/es/components/time-picker/src/common/props";
import { parseDate } from "element-plus/es/components/time-picker/src/utils";
import { TOOLTIP_INJECTION_KEY } from "element-plus/es/components/tooltip/src/constants";
import { useLocale } from "element-plus/es/hooks/use-locale/index.mjs";
import { provide } from "vue";

const props = defineProps(timePickerDefaultProps);

const { lang } = useLocale();
const parsedValue = parseDate(
  props.modelValue as Date,
  props.valueFormat,
  lang.value,
);

// Element UI doesn't implement an inline date picker,
// so we had to pluck the picker panel from Element UI's lib.
// However, it's not working just as is. After investigating the source code,
// we found that the panel is expecting some props to be passed through Vue Context API.
provide("EP_PICKER_BASE", { props });
provide(TOOLTIP_INJECTION_KEY, undefined!);
provide(ROOT_PICKER_INJECTION_KEY, {
  slots: {},
  pickerNs: undefined!,
});

const emit = defineEmits(["update:modelValue"]);

// While a click on a date cell has been handled, Element UI fires a focus event
// on the 'parent' input element to make it active. However, since we don't have
// any input element, focus is being fired on the table element. Which causes
// another handlePick call. To prevent this, we need to blur the active element.
function handlePick({ $d }: { $d: Date }) {
  if (document.activeElement instanceof HTMLElement) {
    document.activeElement.blur();
  }
  emit("update:modelValue", $d);
}
</script>
