import type { Deal } from "./deals/types";
import type { State } from "./state";
import type { RootState } from "@drVue/store/state";
import type { GetterTree } from "vuex";

export const getters: GetterTree<State, RootState> = {
  filteredDealsData(state, localGetters): Deal[] {
    return localGetters["filters/filterDeals"](state.deals.items);
  },
};
