import { DocumentsModule } from "./documents/DocumentsModule";
import { DownloadsModule } from "./downloads/DownloadsModule";
import { GroupsModule } from "./groups/GroupsModule";
import { RoomMembersModule } from "./members/RoomMembersModule";
import { TasksRelatedModule } from "./tasks-related/TasksRelatedModule";
import { TasksStatusesModule } from "./tasks-statuses/TasksStatusesModule";

import type { State } from "./state";
import type { RootState } from "@drVue/store/state";
import type { Module, ModuleTree } from "vuex";

export type { State } from "./state";

const modules: ModuleTree<RootState> = {
  documents: DocumentsModule,
  downloads: DownloadsModule,
  groups: GroupsModule,
  members: RoomMembersModule,
  tasksRelated: TasksRelatedModule,
  tasksStatuses: TasksStatusesModule,
};

export const room: Module<State, RootState> = {
  modules,
  namespaced: true,
};
