<template>
  <div :class="$style.likelihoodContainer">
    <DrTruncatedTextTooltip :content="label">
      <span :class="$style.label">{{ label }}</span>
    </DrTruncatedTextTooltip>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import { FindingLikelihoodDict } from "@drVue/api-service/modules/findings/types";

import type { FindingLikelihoodValue } from "@drVue/api-service/modules/findings/types";

interface Props {
  likelihood: FindingLikelihoodValue;
}
const props = defineProps<Props>();

const likelihood = computed(() =>
  props.likelihood ? FindingLikelihoodDict[props.likelihood] : null,
);

const label = computed(() => likelihood.value?.label ?? "");

const color = computed(() => likelihood.value?.color ?? "transparent");
</script>

<style module lang="scss">
.likelihoodContainer {
  background: v-bind(color);
  padding: 4px 6px;
  border-radius: 6px;
}

.label {
  line-height: 24px;
}
</style>
