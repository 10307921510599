import { find } from "lodash-es";

import activityFilter_activityFilterHtml from "../../templates/components/activity-filter/activity-filter.html?raw";

(function () {
  "use strict";
  var moduleActivityFilterDirective = angular
    .module("dealroom.activity-filter.directive", [
      "ui.bootstrap",
      "dealroom.date-fns",
      "dealroom.config",
      "dealroom.common",
    ])
    .controller("ActivityFilterController", ActivityFilterController)
    .directive("activityFilter", function () {
      return {
        restrict: "E",
        replace: true,
        scope: true,
        bindToController: {
          action: "@",
          for: "@",
          widgetType: "@",
          callback: "&",
        },
        template: activityFilter_activityFilterHtml,
        controller: "ActivityFilterController",
        controllerAs: "ctrl",
      };
    });

  function ActivityFilterController($scope, $q, VerbsService) {
    var self = this;

    self.$onInit = function () {
      init();
    };

    self.selectActivity = function (data) {
      if (self.callback) {
        self.callback({ activity: self.selectedActivity });
      }
    };

    function init() {
      self.activities = [
        {
          id: "all",
          title: "All activities",
        },
      ];
      var activitiesList = VerbsService.getShortList(self.widgetType);
      for (var i in activitiesList) {
        self.activities.push({
          id: i,
          title: activitiesList[i].label,
          chartTitle: activitiesList[i].chartTitle,
        });
      }
      self.selectedActivity = find(self.activities, {
        id: self.action || "all",
      });
      self.callback({ activity: self.selectedActivity });
    }
  }

  ActivityFilterController.$inject = ["$scope", "$q", "VerbsService"];
})();
