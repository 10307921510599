<template>
  <div :class="$style.wrapper">
    <DrTooltip
      v-if="!!item.category"
      :content="categoryFullPathTooltip"
      placement="top"
    >
      <a :href="categoryFullPathUrl" target="_blank" :class="$style.path">
        {{ categoryFullPathTitle }}
      </a>
    </DrTooltip>

    <DrItemListItem
      v-for="t in item.tasks"
      :key="t.id"
      :allow-remove="!disallowRemove"
      :single-mode="singleMode"
      @click="$emit('click', t)"
      @remove="$emit('remove', t)"
    >
      <component
        :is="asLink ? 'a' : 'div'"
        :class="$style.container"
        v-bind="getCompoentProps(t)"
      >
        <span
          :class="$style.key"
          :style="{ color: getStatus(t.status_id).color }"
        >
          #{{ t.key }}
        </span>
        <span class="truncate" :class="$style.title">{{ t.title }}</span>
        <DrUserBox
          v-if="!!t.assignees?.length"
          :users="getAssignees(t.assignees)"
          disable-label
        />
      </component>
    </DrItemListItem>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DrItemListItem } from "@shared/ui/dr-item-list";
import { DrTooltip } from "@shared/ui/dr-tooltip";
import { DrUserBox } from "@shared/ui/dr-user-box";

import { ROOM_DATA } from "@setups/data";
import { categoryUrl, taskUrl } from "@setups/room-urls";
import DrStore from "@drVue/store";

import type { Category } from "@drVue/store/pinia/room/categories";
import type { Task } from "@drVue/store/pinia/room/tasks";

interface Props {
  item: {
    category?: Category;
    tasks: Task[];
  };
  disallowRemove?: boolean;
  singleMode?: boolean;
  asLink?: boolean;
}

interface Emits {
  (e: "click", task: Task): void;
  (e: "remove", task: Task): void;
}

const props = withDefaults(defineProps<Props>(), {
  disallowRemove: false,
  singleMode: false,
});

defineEmits<Emits>();

const getStatus = (statusId: number) =>
  DrStore.getters["room/tasksStatuses/byId"](statusId);

const getAssignees = (assigneeIds: { user_id: number }[]) => {
  return assigneeIds.map(
    ({ user_id }) => DrStore.state.room.members.members[user_id],
  );
};

const getCompoentProps = (task: Task) => {
  if (props.asLink) {
    return {
      href: taskUrl(ROOM_DATA.url, task.key),
      target: "_blank",
    };
  }
  return {};
};

const categoryFullPath = computed(() => {
  if (!props.item.category) return [];

  return props.item.category.full_path.split(" \\ ");
});

const categoryFullPathTitle = computed(
  () => categoryFullPath.value[categoryFullPath.value.length - 1],
);

const categoryFullPathTooltip = computed(() =>
  categoryFullPath.value.join(" / "),
);

const categoryFullPathUrl = computed(() => {
  if (!props.item.category) return "";

  return categoryUrl(ROOM_DATA.url, props.item.category.id);
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.wrapper {
  & + & {
    margin-top: 4px;
  }
}
.container {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;

  &:hover {
    cursor: pointer;
  }
}

.container:is(a) {
  text-decoration: none;

  &:hover {
    .key,
    .title {
      color: colors.$sc-600;
      text-decoration: underline;
    }
  }
}

.key {
  font: typography.$body_regular;
}

.path {
  display: inline-block;
  padding: 1px 0;
  font: typography.$caption_regular;
  line-height: 1;
  color: colors.$pr-600;
  border-bottom: solid 1px transparent;
  cursor: pointer;

  &:hover {
    color: colors.$pr-900;
    border-bottom-color: colors.$pr-900;
  }
}

.title {
  font: typography.$body_regular;
  color: colors.$pr-900;
  margin-left: 4px;
  margin-right: 8px;
}
</style>
