<template>
  <div
    :class="{
      [$style.filter]: true,
      [$style.filter_inOneLine]: inOneLine,
    }"
  >
    <DrToolbarFilterSearch
      v-model="tasksFilters.search"
      @update:model-value="updateField('search')"
    />

    <div :class="$style.selectsContainer">
      <span v-if="!inOneLine">Filter by</span>

      <DrToolbarFilterSelect
        v-model="tasksFilters.rooms"
        label="Room"
        :options="roomsOptions"
        @update:model-value="updateField('rooms')"
      />

      <DrToolbarFilterSelect
        v-model="tasksFilters.statuses"
        label="Status"
        :options="statusesOptions"
        @update:model-value="updateField('statuses')"
      >
        <template #icon="{ option }">
          <DrIconStatus :color="(option as SelectStatusOptionItem).color" />
        </template>
      </DrToolbarFilterSelect>

      <DrToolbarFilterSelect
        v-model="tasksFilters.roles"
        label="Role"
        :options="rolesOptions"
        @update:model-value="updateField('roles')"
      />

      <DrToolbarFilterSelect
        v-model="tasksFilters.priorities"
        label="Priority"
        :options="prioritiesOptions"
        @update:model-value="updateField('priorities')"
      >
        <template #icon="{ option }">
          <DrIconPriority :priority="option.id as PriorityValue" />
        </template>
      </DrToolbarFilterSelect>

      <DrToolbarFilterDatepicker
        v-if="inOneLine"
        v-model="tasksFilters.due_date"
        label="Due date"
        @update:model-value="updateField('due_date')"
      />
    </div>

    <span>{{ filteredTasksCount }}</span>
  </div>
</template>

<script lang="ts" setup>
import { capitalize } from "lodash-es";
import { cloneDeep } from "lodash-es";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import DrIconPriority, {
  type PriorityValue,
} from "@shared/ui/dr-icon-priority";
import DrIconStatus from "@shared/ui/dr-icon-status";
import {
  DrToolbarFilterDatepicker,
  DrToolbarFilterSearch,
  DrToolbarFilterSelect,
} from "@shared/ui/dr-toolbar";

import { ParticipantsType } from "@drVue/components/room/tasks/participants/TaskParticipantsList.vue";
import { useOrgStore } from "@drVue/store/modules/common/organization/store";
import { TASK_PRIORITY } from "@drVue/store/pinia/room/tasks";
import $utils from "@drVue/utils";

import type { UserTasksFilters } from "@drVue/components/client-dashboard/users/UserDetails/RoomTasks/UserRoomTasks.vue";
import type { TaskStatus } from "@drVue/store/modules/room/tasks-statuses/types";
import type { StatusColor } from "@shared/ui/dr-icon-status";
import type { SelectOptionItem } from "@shared/ui/dr-popups";

type SelectStatusOptionItem = SelectOptionItem & { color: StatusColor };

const $store = useStore();

interface Props {
  modelValue: UserTasksFilters;
  numberOfTasks: number;
  inOneLine?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  inOneLine: false,
});

const emit = defineEmits([
  "update:modelValue",
  "update:filter",
  "update:search",
]);

const tasksFilters = ref<UserTasksFilters>(cloneDeep(props.modelValue));

const filteredTasksCount = computed(() => {
  return `${props.numberOfTasks} ${$utils.text.pluralize(
    "request",
    props.numberOfTasks,
  )}`;
});

const roomsOptions = computed<SelectOptionItem[]>(() => {
  return useOrgStore().rooms.map((room) => ({
    id: room.id,
    name: room.title,
    isSelected: tasksFilters.value.rooms.includes(room.id),
  }));
});
const statusesOptions = computed<SelectStatusOptionItem[]>(() => {
  return $store.state.room.tasksStatuses.list.map((status: TaskStatus) => ({
    id: status.id,
    color: status.color,
    name: status.name,
    isSelected: tasksFilters.value.statuses.includes(status.id),
  }));
});
const prioritiesOptions = computed<SelectOptionItem[]>(() => {
  return TASK_PRIORITY.map((item) => ({
    id: item,
    name: capitalize(item),
    isSelected: tasksFilters.value.priorities.includes(item),
  }));
});
const rolesOptions = computed<SelectOptionItem[]>(() => {
  return Object.values(ParticipantsType).map((item) => ({
    id: item,
    name: capitalize(item),
    isSelected: tasksFilters.value.roles.includes(item),
  }));
});

const updateField = (field: keyof UserTasksFilters) => {
  if (field === "search") {
    emit("update:search", tasksFilters.value.search);
  } else {
    emit("update:filter", {
      field,
      value: cloneDeep(tasksFilters.value[field]).sort((a, b) => {
        if (a instanceof Date && b instanceof Date) {
          return a.getTime() - b.getTime();
        } else if (typeof a === "number" && typeof b === "number") {
          return a - b;
        } else if (typeof a === "string" && typeof b === "string") {
          return a.localeCompare(b);
        }
        return 0;
      }),
    });
  }
  emit("update:modelValue", cloneDeep(tasksFilters.value));
};

watch(
  () => props.modelValue,
  (value: UserTasksFilters) => {
    tasksFilters.value = cloneDeep(value);
  },
  {
    deep: true,
    immediate: true,
  },
);
</script>

<style lang="scss" module>
@use "@app/styles/scss/spacing";

.filter {
  display: flex;
  flex-direction: column;
  gap: spacing.$s;
  margin-bottom: spacing.$l;
}

.selectsContainer {
  display: flex;
  align-items: center;
  gap: spacing.$s;

  & :global(.el-button + .el-button) {
    margin-left: 0;
  }
}

.filter_inOneLine {
  flex-direction: row;
  align-items: center;
  margin-bottom: 0;
}
</style>
