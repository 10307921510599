<template>
  <div class="dash-filter">
    <ElDropdown ref="dropdown" trigger="click" :hide-on-click="false">
      <ElButton link>
        <template #icon>
          <i class="fal fa-filter" :class="$style.filterIcon" />
        </template>
        Filters
      </ElButton>

      <template #dropdown>
        <ElDropdownMenu class="dash-filter__menu">
          <div class="dash-filter__content">
            <ElForm label-position="top">
              <ElFormItem label="Room:" prop="title">
                <ElSelect
                  v-model="selectedRoom"
                  placeholder="Select Room"
                  clearable
                  append-to-body
                  value-key="id"
                  class="el-select--full-width"
                >
                  <ElOption
                    v-for="room in rooms"
                    :key="room.id"
                    :label="room.title"
                    :value="room"
                  />
                </ElSelect>
              </ElFormItem>

              <ElFormItem label="Group:" prop="name">
                <ElSelect
                  v-model="selectedGroup"
                  placeholder="Select Group"
                  clearable
                  :append-to-body="'body'"
                  value-key="id"
                  :disabled="!selectedRoom"
                  class="el-select--full-width"
                >
                  <ElOption
                    v-for="group in selectedRoomGroups"
                    :key="group.id"
                    :label="group.name"
                    :value="group"
                  />
                </ElSelect>
              </ElFormItem>

              <div class="text-right">
                <ElButton
                  type="primary"
                  :disabled="!hasFilters"
                  @click="clearFilters()"
                >
                  Clear all
                </ElButton>
              </div>
            </ElForm>
          </div>
        </ElDropdownMenu>
      </template>
    </ElDropdown>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import DrStore from "@drVue/store";

import type {
  Room,
  RoomGroup,
} from "@drVue/store/modules/client-dashboard/deals/types";
import type { UsersFilters } from "@drVue/store/modules/client-dashboard/filters/types";

interface Data {
  store: any;
}

export default defineComponent({
  name: "UsersFilter",
  data(): Data {
    return {
      store: DrStore,
    };
  },
  computed: {
    selectedRoom: {
      get: function (): any {
        return this.store.state.clientDashboard.filters.users.room;
      },
      set: function (room: Room | null) {
        if ((room as any) === "" || room === undefined)
          // el-select uses empty string on clear, but since 2.7.0 it may be undefined
          room = null;
        this.applyFilters({ room: room });

        (this.$refs.dropdown as any).handleOpen();
      },
    },
    selectedGroup: {
      get: function (): any {
        return this.store.state.clientDashboard.filters.users.group;
      },
      set: function (group: RoomGroup | null) {
        if ((group as any) === "" || group === undefined)
          // el-select uses empty string on clear, but since 2.7.0 it may be undefined
          group = null;
        this.applyFilters({ group: group });
      },
    },
    rooms(): any {
      return this.store.getters["clientDashboard/deals/getRooms"];
    },
    selectedRoomGroups(): RoomGroup[] {
      if (!this.selectedRoom) return [];
      return this.selectedRoom.groups;
    },
    hasFilters(): any {
      return this.selectedRoom !== null;
    },
  },
  methods: {
    clearFilters() {
      this.applyFilters({ room: null, group: null });
    },
    applyFilters(filters: Partial<UsersFilters>) {
      this.store.commit("clientDashboard/filters/setUsersFilters", filters);
    },
  },
});
</script>

<style lang="scss" module>
.filterIcon {
  margin-left: 5px;
}
</style>
