<template>
  <CreateRequestPanel ref="createRequestPanelRef" @closed="$emit('close')" />
</template>

<script setup lang="ts">
import { type ComponentInstance, onMounted, ref } from "vue";

import CreateRequestPanel from "@drVue/components/room/tasks/TaskOverviewPage/CreateRequestPanel.vue";

import type { FolderMenuParams } from "../utils";

interface Props {
  params: FolderMenuParams;
}

interface Emits {
  (e: "close"): void;
  (e: "update:persisted", value: boolean): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const createRequestPanelRef = ref<
  ComponentInstance<typeof CreateRequestPanel> | undefined
>();

const close = () => {
  createRequestPanelRef.value?.close();
};

onMounted(() => {
  createRequestPanelRef.value?.open({
    attachFolderIds: props.params.folders.map((item) => item.id),
    attachDocumentIds: props.params.documents.map((item) => item.id),
  });
});

defineExpose({
  close,
});
</script>
