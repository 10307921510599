<template>
  <DrLayoutPage page-title="Settings" page-icon="cogwheel">
    <DrLayoutContentCentered>
      <OrgName />

      <DrLayoutContentCenteredSection title="Login page color">
        <template #description>
          Customize the login and registration screen background color for all
          users.
        </template>

        <DrColorPicker :color="bgColor" @change="updateBgColor" />
      </DrLayoutContentCenteredSection>
    </DrLayoutContentCentered>
  </DrLayoutPage>
</template>

<script lang="ts" setup>
import { computed, reactive, ref } from "vue";
import { useStore } from "vuex";
import DrColorPicker from "@shared/ui/dr-color-picker";
import { useFormHelper } from "@shared/ui/dr-form";
import {
  DrLayoutContentCentered,
  DrLayoutContentCenteredSection,
  DrLayoutPage,
} from "@shared/ui/dr-layouts";

import { $notifyDanger } from "@drVue/common";
import OrgName from "./General/OrgName.vue";

import type { FormInstance, FormRules } from "element-plus";

const $store = useStore();

const bgColor = computed(() => {
  return $store.state.clientDashboard.settings.bg_color;
});
const updateBgColor = (newColor: string | null) => {
  $store
    .dispatch("clientDashboard/settings/update", { bg_color: newColor })
    .then(() => {
      window.location.reload();
    })
    .catch(() => {
      $notifyDanger("Failed to update bg color");
    });
};

interface OrgFormModel {
  public_name: string;
}
const orgFormRef = ref<FormInstance>();
const orgFormModel = reactive<OrgFormModel>({
  public_name: $store.state.clientDashboard.settings.public_name,
});
const formRules = reactive<FormRules>({
  public_name: [
    {
      required: true,
      message: "Оrganization public name is required.",
    },
  ],
});

const { formErrors, hookFormSubmitPromise, isFormSubmitting } =
  useFormHelper<OrgFormModel>();

const updateOrg = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid) => {
    if (valid) {
      hookFormSubmitPromise(
        $store.dispatch("clientDashboard/settings/update", {
          public_name: orgFormModel.public_name,
        }),
      ).then(
        () => {
          window.location.reload();
        },
        () => $notifyDanger(`Failed to update organization.`),
      );
    }
  });
};
</script>
