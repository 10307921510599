<template>
  <div :class="$style.app">
    <Navigation rooms-restricted />

    <div :class="$style.wrapper">
      <div v-if="!isClientSpecified">
        <div v-if="allowNewTrialClient" class="user-dash__trial-text">
          Start your {{ newTrialDays }}-days free trial
        </div>
        <div v-else class="user-dash__no-rooms-text">
          You have no active rooms
        </div>
      </div>

      <div v-if="allowRoomCreate">
        <div class="user-dash__room-create-wrapper">
          <RoomCreate />
        </div>
      </div>
    </div>

    <DrAlert />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { default as DrAlert } from "@shared/ui/dr-alert";

import { USER_DASHBOARD_DATA } from "@setups/data";
import Navigation from "@drVue/components/navigation/Navigation.vue";
import RoomCreate from "./room-create/index.vue";
import { getCreateAllowedClients } from "./room-create/utils";

interface Data {
  allowNewTrialClient: any;
  allowRoomCreate: any;
  newTrialDays: any;
  isClientSpecified: any;
}

export default defineComponent({
  name: "App",
  components: {
    DrAlert,
    RoomCreate,
    Navigation,
  },
  data(): Data {
    const allowNewTrialClient =
      USER_DASHBOARD_DATA.allow_create_new_trial_client;

    return {
      allowNewTrialClient: allowNewTrialClient,
      allowRoomCreate: getCreateAllowedClients().length || allowNewTrialClient,
      newTrialDays: USER_DASHBOARD_DATA.new_trial_days,
      isClientSpecified: USER_DASHBOARD_DATA.is_client_specified,
    };
  },
});
</script>
<style lang="scss" module>
.app {
  display: grid;
  grid-template-columns: min-content 1fr;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.wrapper {
  display: grid;
  justify-content: center;
  padding: 50px 20px 20px;
  overflow-y: auto;
}
</style>
