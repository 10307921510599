import "angular-bootstrap-contextmenu";
import "angular-ui-tree";
import "angular-tablesort";
import "angular-ui-indeterminate";
import "angular-elastic";
import "./ng/date-fns";
// app
import "./components/tree-filter/tree-filter.directive.js";
import "./components/docs-multi-select/docs-multi-select.js";
import "./file-upload.js";
import "./activity.js";
import "./task.js";
import "./documents.js";
import "./members.js";
import "./room-data.js";
import "./time-tracking.js";
import "./../vendor/ngVue/src/index";

import { parseDjDataJson } from "@setups";
import VueRoomDocumentViewer from "@drVue/components/room/viewer/RoomDocumentViewer.vue";

angular
  .module("dealroom.viewer", [
    "dealroom.sentry",
    "ui.router",
    "ui.select",
    "ngMessages",
    "ngVue",

    "angular.filter",
    "dealroom.common",
    "dealroom.config",
    "dealroom.activity",
    "dealroom.time-tracking",
    "dealroom.task",
    "dealroom.documents",
    "dealroom.members",
    "dealroom.room-data",
  ])
  .value("VueRoomDocumentViewer", VueRoomDocumentViewer)
  .component("drRoomDocumentViewer", drRoomDocumentViewer());

const DOCUMENT_INFO = parseDjDataJson("document-info", true);

export default function drRoomDocumentViewer() {
  return {
    template: `<vue-component 
      id="root_VueRoomDocumentViewer"
      name="VueRoomDocumentViewer"
      v-on-before-initialize="$ctrl.beforeInitialize"
    ></vue-component>`,
    controller: [
      "TrackActivity",
      "RoomDataService",
      function (TrackActivity, RoomDataService) {
        const $ctrl = this;

        $ctrl.beforeInitialize = function beforeInitialize() {
          RoomDataService.setupInitialRoomData();
          RoomDataService.updateData();
          TrackActivity.setObject({ type: "document", id: DOCUMENT_INFO.id });
          TrackActivity.startTracking();
        };
      },
    ],
  };
}
