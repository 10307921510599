<template>
  <div v-loading="isLoading">
    <ElAlert
      v-if="loadingErrorMessage"
      type="error"
      :title="loadingErrorMessage"
      :closable="false"
    />

    <div v-if="shownFields.length" :class="$style.list">
      <SuggestedReportField
        v-for="label in shownFields"
        :key="label"
        :report-id="report.id"
        :label="label"
      />
    </div>

    <div v-else-if="!isLoading" :class="$style.noFieldsText">
      No additional fields are available for suggestion.
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";

import { AiReportsApiService } from "@drVue/components/room/ai/api";
import SuggestedReportField from "./SuggestedReportField.vue";

import type { AiReport } from "../types";

interface Props {
  report: AiReport;
}

const props = defineProps<Props>();

const api = new AiReportsApiService();
const isLoading = ref(false);
const loadingErrorMessage = ref("");
const fieldLabels = ref<string[]>([]);

const shownFields = computed(() => {
  const existingFields = props.report.fields.map((field) => field.label);
  return fieldLabels.value.filter((label) => !existingFields.includes(label));
});

const fetchFields = () => {
  isLoading.value = true;
  api.loadSuggestedFields(props.report.id).then(
    (data) => {
      fieldLabels.value = data;
      isLoading.value = false;
    },
    (errorResp) => {
      const errorMsg =
        errorResp.response?.data?.non_field_errors?.[0] ||
        "Failed to load AI suggested fields";
      loadingErrorMessage.value = errorMsg;
      isLoading.value = false;
    },
  );
};

watch(() => props.report.id, fetchFields, { immediate: true });
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.noFieldsText {
  color: colors.$pr-500;
}

.list {
  border-bottom: 1px solid colors.$pr-150;
  border-top: 1px solid colors.$pr-150;
}
</style>
