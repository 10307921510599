import emailAddresses from "email-addresses";
import { uniqBy } from "lodash-es";

export interface ParseResult {
  parsed: string[];
  errors: string[];
}

// regex is taken from http://stackoverflow.com/a/46181
const EMAIL_REX =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

export default function parse(str: string): ParseResult {
  const result: ParseResult = { parsed: [], errors: [] };

  str = str.trim();
  if (str.length === 0) return result;

  const lines: string[] = str
    .split(/\n+/)
    .map((s) => s.split(",").map((s) => s.trim()))
    .flat()
    .filter((s) => s);

  for (let line of lines) {
    // remove trailing comma
    line = line.replace(/,\s*$/, "");

    const emails = parseEmailLine(line);
    if (emails) {
      result.parsed = result.parsed.concat(emails);
    } else {
      result.errors.push(line);
    }
  }

  result.parsed = uniqBy(result.parsed, (val) => val.toLowerCase());

  return result;
}

export function parseEmailLine(value: string) {
  // return array of emails
  // return null in case of invalid email

  value = value.trim();
  // try to extract from  mailto:foo@example.com?subject=bar format
  // eslint-disable-next-line no-useless-escape
  const mailTo = value.split(/^mailto:([^\?]*)/i);
  if (mailTo.length > 1) {
    value = mailTo[1];
  }

  const addrs = emailAddresses.parseAddressList(value);
  if (!addrs) return addrs;

  // double check because parseAddressList allow emails like foo@domain
  // it is fine by RFC, but better to not to allow for us
  const emails = addrs.map((addr) => (addr as any).address);
  const haveInvalidEmail = emails.some((email) => !EMAIL_REX.test(email));
  if (haveInvalidEmail) return null;

  return emails;
}
