<template>
  <div v-if="isTaskRow(row)" :class="$style.container">
    <DrIconPriority :priority="row.task.priority as PriorityValue" />
    <span>{{ capitalize(row.task.priority) }}</span>
  </div>
</template>

<script setup lang="ts">
import { capitalize } from "lodash-es";
import DrIconPriority, {
  type PriorityValue,
} from "@shared/ui/dr-icon-priority";

import { TableRowTypes } from "../types";

import type { ITableRow, TableTaskRow } from "../types";

const isTaskRow = (row: ITableRow): row is TableTaskRow =>
  row.type === TableRowTypes.Task;

interface Props {
  row: ITableRow;
}

defineProps<Props>();
</script>

<style module lang="scss">
.container {
  display: inline-flex;
  gap: 6px;
  align-items: center;
}
</style>
