<template>
  <ElTreeSelect
    ref="treeRef"
    :data="tree"
    check-on-click-node
    check-strictly
    clearable
    filterable
    highlight-current
    multiple
    node-key="id"
    :auto-expand-parent="false"
    :disabled="editProps.schema.isReadOnly"
    :expand-on-click-node="false"
    :filter-node-method="filterMethod"
    :model-value="modelValue"
    :placeholder="editProps.schema.placeholder"
    @change="triggerVeeOnChange"
    @clear="clear"
  >
    <template #default="{ data: { label } }">
      <div :class="$style.ellipsis" :title="label">
        {{ label }}
      </div>
    </template>
  </ElTreeSelect>
</template>

<script setup lang="ts">
import { ElTreeSelect } from "element-plus";
import { computed, ref } from "vue";

import { DrStore } from "@app/vue";

import type { Folder } from "@drVue/store/modules/room/documents/DocumentsApiService";
import type { DocumentUid } from "@drVue/store/pinia/room/tasks";
import type { FolderUid } from "@drVue/store/pinia/room/tasks";
import type { FieldProps } from "@shared/ui/dr-dynamic-form/types";

interface Props {
  editProps: FieldProps<{
    folders: FolderUid[];
    documents: DocumentUid[];
  } | null>;
}

interface Node {
  id: string;
  label: string;
  children: Node[];
}

const props = defineProps<Props>();

const clear = () => {
  props.editProps.veeField.onChange(null);
};

const triggerVeeOnChange = (ids: string[]) => {
  props.editProps.veeField.onChange({
    folders: ids
      .filter((id) => id.startsWith("fldr_"))
      .map((id) => ({ folder_id: id })),

    documents: ids
      .filter((id) => id.startsWith("doc_"))
      .map((id) => ({ document_id: id })),
  });
};

const treeRef = ref<InstanceType<typeof ElTreeSelect> | null>(null);

const modelValue = computed(() => {
  const value: string[] = [];

  props.editProps.value?.folders.forEach((f) => {
    value.push(f.folder_id);
  });

  props.editProps.value?.documents.forEach((d) => {
    value.push(d.document_id);
  });

  return value;
});

const filterMethod = (value: string, node: Node) => {
  if (value.trim() === "") return true;
  return node.label.toLowerCase().includes(value.toLowerCase());
};

const mapFolderToNode = (folder: Folder): Node => {
  const children: Node[] = [
    ...folder.folders.map((subFolder) => mapFolderToNode(subFolder)),
    ...folder.files.map((file) => ({
      id: file.uid,
      label: file.name,
      children: [],
    })),
  ];

  return {
    id: folder.uid,
    label: folder.name,
    children,
  };
};

const tree = computed(() => {
  const rootFolder = DrStore.state.room.documents.rootFolder;
  if (!rootFolder) return [];

  return [...mapFolderToNode(rootFolder).children];
});
</script>

<style module lang="scss">
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 300px;
}
</style>
