<template>
  <div
    :class="{
      [$style.list]: true,
      [$style.list_isExpanded]: isExpanded,
    }"
  >
    <div v-if="header" :class="$style.header">
      <div :class="$style.headerTitle">{{ header }}</div>
      <div v-if="showAddBtn" :class="$style.headerBtn" @click="emit('add')">
        <DrIcon size="sm" name="plus" :class="$style.icon" />
      </div>
    </div>
    <slot name="default" />
    <div v-if="$slots.action" :class="$style.action">
      <div :class="$style.divider" />
      <slot name="action" />
    </div>
  </div>
</template>

<script setup lang="ts">
/**
 * Main menu items wrapper.
 */

import DrIcon from "../dr-icon";

interface Props {
  header?: string;
  isExpanded?: boolean;
  showAddBtn?: boolean;
}
withDefaults(defineProps<Props>(), {
  header: "",
  isExpanded: false,
  showAddBtn: false,
});

const emit = defineEmits<{
  (event: "add"): void;
}>();
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography" as typo;

$space: 8px;
$space-sm: 4px;
$space-inverse: -8px;
$transition-function: ease-out;
$transition-duration: 150ms;

.list {
  display: grid;
  grid-auto-flow: row;
  gap: $space-sm;
  max-width: 100%;
  padding: $space $space 0;
  overflow: hidden;
}

.icon {
  transform-origin: 50%;
  transform: scale(0.8);
}

.header {
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  padding: 0 $space $space;
  overflow: hidden;
  pointer-events: none;

  .list_isExpanded & {
    pointer-events: all;
  }
}

.headerTitle {
  padding-right: $space;
  overflow: hidden;
  white-space: nowrap;
  font: typo.$body_regular;
  color: colors.$pr-500;
  text-overflow: ellipsis;
  opacity: 0;
  transform: translateX(3px);
  transition-timing-function: $transition-function;
  transition-duration: $transition-duration;
  transition-property: transform, opacity;

  .list_isExpanded & {
    transform: translateX(0);
    opacity: 1;
  }
}

.headerBtn {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid colors.$pr-700;
  border-radius: 4px;
  color: colors.$pr-500;
  cursor: pointer;
  opacity: 0;
  transition-timing-function: $transition-function;
  transition-duration: $transition-duration;
  transition-property: background-color, opacity;

  &:hover {
    color: colors.$pr-900;
    background-color: colors.$pr-0;
  }

  .list_isExpanded & {
    opacity: 1;
  }
}

.action {
  visibility: hidden;

  .list_isExpanded & {
    visibility: visible;
  }
}

.divider {
  margin: $space $space-inverse;
  border-bottom: solid 1px rgb(colors.$sc-50, 0%);
  transition-timing-function: $transition-function;
  transition-duration: $transition-duration;
  transition-property: border-bottom-color;

  .list_isExpanded & {
    border-bottom-color: rgb(colors.$sc-50, 12%);
  }
}
</style>
