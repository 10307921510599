import type { PreferencesState } from "./state";
import type { MutationTree } from "vuex";

export const mutations: MutationTree<PreferencesState> = {
  setDefaultDealsView(state, viewSlug: string) {
    if (!state.pinned_deals_views.includes(viewSlug)) {
      // only pinned view can be set as default
      return;
    }
    state.default_deals_view = viewSlug;
    // always show default view as the first pinned view
    const views = [...state.pinned_deals_views].filter((v) => v !== viewSlug);
    views.unshift(viewSlug);
    state.pinned_deals_views = views;
  },
  setPinnedDealsView(state, views: string[]) {
    state.pinned_deals_views = views;
    if (
      state.default_deals_view &&
      state.pinned_deals_views.includes(state.default_deals_view)
    ) {
      return;
    }
    state.default_deals_view = state.pinned_deals_views[0] || null;
  },
};
