<template>
  <div :class="$style.app">
    <Navigation rooms-restricted />
    <div :class="$style.page">
      <ElRow type="flex" justify="center" style="padding-top: 30px">
        <ElCol :xs="22" :sm="16">
          <ElAlert
            v-if="getBackendError('non_field_errors')"
            class="auth-form__alert"
            :title="getBackendError('non_field_errors')"
            type="error"
            show-icon
            :closable="false"
          />

          <div v-if="reason === '2fa_required'">
            <ElRow type="flex" justify="center">
              <ElCol :xs="24" :md="16">
                <h3 class="text-center" data-testid="header">
                  To continue, administrators require all members to setup
                  two-factor authentication
                </h3>
                <hr class="spacer spacer--2x" />

                <Interfaces @confirmed="redirectToHome" />
              </ElCol>
            </ElRow>
          </div>

          <div v-if="reason === 'sso_required'">
            <h3 class="text-center" data-testid="header">
              To continue, administrators require you to use SSO
            </h3>
            <hr class="spacer spacer--2x" />
            <h4 class="text-center">Please relogin with:</h4>

            <ElRow type="flex" justify="center">
              <ElCol :xs="24" :sm="10">
                <div v-if="OPENID_INTEGRATIONS.length" class="sign-in-with">
                  <div
                    v-for="integration in OPENID_INTEGRATIONS"
                    :key="integration.id"
                  >
                    <ElButton
                      class="sign-in-with__provider"
                      :disabled="isSubmitting"
                      @click="signInWith(integration)"
                    >
                      <img
                        v-if="integration.provider.logo"
                        :src="integration.provider.logo"
                        :alt="`${integration.title} Logo`"
                      />
                      <span>{{ integration.title }}</span>
                    </ElButton>
                  </div>
                </div>
                <div v-else>
                  There is no any Single Sign On integrations, please contact
                  your admins.
                </div>
              </ElCol>
            </ElRow>
          </div>

          <DrAlert />
          <TimezoneDialog />
        </ElCol>
      </ElRow>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrForm from "@shared/ui/dr-form";
import TimezoneDialog from "@shared/ui/timezone-dialog/TimezoneDialog.vue";

import { parseDjDataJson } from "@setups/data";
import { getCurrentClientSsoIntegrations } from "@drVue/api-service/client-dashboard";
import { AuthApiService } from "@drVue/components/auth/AuthApiService";
import { getRedirect } from "@drVue/components/auth/utils";
import Interfaces from "@drVue/components/client-dashboard/users/AccountSettings/tabs/interfaces/Interfaces.vue";
import { DrAlert } from "../";
import Navigation from "../navigation/Navigation.vue";

import type { OpenIDIntegration } from "@drVue/api-service/client-dashboard/open-id-providers";

interface AccessDeniedData {
  reason: string;
}

type AccessDeniedReason = "sso_required" | "2fa_required";

interface Data {
  reason: AccessDeniedReason | null;
  api: AuthApiService;
  OPENID_INTEGRATIONS: any;
}

export default defineComponent({
  name: "App",
  components: {
    Interfaces,
    DrAlert,
    TimezoneDialog,
    Navigation,
  },
  extends: DrForm,
  data(): Data {
    return {
      reason: null,
      api: new AuthApiService(),
      OPENID_INTEGRATIONS: getCurrentClientSsoIntegrations(),
    };
  },
  beforeMount() {
    const data = parseDjDataJson("access-denied-data") as AccessDeniedData;
    this.reason = data.reason as AccessDeniedReason;
  },
  methods: {
    signInWith(integration: OpenIDIntegration) {
      const redirectUrl = getRedirect(this.$route, this.api.home_url);

      const signInWithPromise = this.api.sign_in_with(integration, redirectUrl);
      this.submitPromise(signInWithPromise).then((r) => {
        this.isSubmitting = true;
        location.href = r.data.redirect_url;
      });
    },
    redirectToHome() {
      location.href = this.api.home_url;
    },
  },
});
</script>
<style lang="scss" module>
.app {
  display: grid;
  grid-template-columns: min-content 1fr;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.page {
  max-width: 100%;
  overflow-x: auto;
}
</style>
