import { ApiService } from "@drVue/api-service";

import type { FindingType, FindingTypeCreateOrUpdatePayload } from "./types";

export class FindingsTypesApiService extends ApiService {
  public async load() {
    const url: string = this.Urls["api:client-dashboard:fd-types-list"]();
    return this.get<FindingType[]>(url);
  }
  public async create(payload: FindingTypeCreateOrUpdatePayload) {
    const url: string = this.Urls["api:client-dashboard:fd-types-list"]();
    return this.post<FindingType, FindingTypeCreateOrUpdatePayload>(
      url,
      payload,
    );
  }
  public async update(id: string, payload: FindingTypeCreateOrUpdatePayload) {
    const url: string = this.Urls["api:client-dashboard:fd-types-detail"](id);
    return this.put<FindingType, FindingTypeCreateOrUpdatePayload>(
      url,
      payload,
    );
  }
  public async remove(id: string) {
    const url: string = this.Urls["api:client-dashboard:fd-types-detail"](id);
    return this.delete<void>(url);
  }
}
