import { cloneDeep } from "lodash-es";
import { defineStore } from "pinia";
import { computed } from "vue";

import { FindingsApiService } from "@drVue/api-service/modules/findings";
import { createDictionary, dowloadFile } from "@drVue/common";
import { generateList } from "@drVue/store/pinia/helpers/generators";
import { useFindingsStatusesStore } from "../../pipeline/findings-statuses";

import type {
  Finding,
  FindingAssigneesPayload,
  FindingCategoriesPayload,
  FindingCreatePayload,
  FindingDocumentsPayload,
  FindingFoldersPayload,
  FindingFollowersPayload,
  FindingTasksPayload,
  FindingUpdatePayload,
} from "@drVue/api-service/modules/findings/types";

export type { Finding, FindingCreatePayload, FindingUpdatePayload };

const api = new FindingsApiService();

export const useFindingsStore = defineStore("roomFindings", () => {
  const {
    list,
    dict,
    load,
    create,
    update,
    remove,
    isLoading,
    isLoadError,
    applyChanges,
  } = generateList<Finding, FindingCreatePayload, FindingUpdatePayload>({
    name: "Room findings",
    load: api.load.bind(api),
    create: api.create.bind(api),
    update: api.update.bind(api),
    remove: api.remove.bind(api),
  });

  const updateAssignees = async (
    id: Finding["id"],
    assignees: Finding["assignees"],
  ) => {
    const itemAssignees = cloneDeep(dict.value[id].assignees);

    const existIds = itemAssignees.map((a) => a.user_id);
    const updatedIds = assignees.map((a) => a.user_id);

    const changes: FindingAssigneesPayload = {
      add: assignees.filter((a) => !existIds.includes(a.user_id)),
      remove: itemAssignees.filter((a) => !updatedIds.includes(a.user_id)),
    };

    if (!changes.add.length && !changes.remove.length) {
      return dict.value[id];
    }

    await api.assignees(id, changes);
    applyChanges({ id, assignees });

    return dict.value[id];
  };

  const updateFollowers = async (
    id: Finding["id"],
    followers: Finding["followers"],
  ) => {
    const itemFollowers = cloneDeep(dict.value[id].followers);

    const existIds = itemFollowers.map((a) => a.user_id);
    const updatedIds = followers.map((a) => a.user_id);

    const changes: FindingFollowersPayload = {
      add: followers.filter((a) => !existIds.includes(a.user_id)),
      remove: itemFollowers.filter((a) => !updatedIds.includes(a.user_id)),
    };

    if (!changes.add.length && !changes.remove.length) {
      return dict.value[id];
    }

    await api.followers(id, changes);
    applyChanges({ id, followers });

    return dict.value[id];
  };

  const updateTiesTo = async (
    id: Finding["id"],
    tiesTo: {
      tasks?: Finding["tasks"];
      categories?: Finding["categories"];
    },
  ) => {
    const item = dict.value[id];

    if ("tasks" in tiesTo && Array.isArray(tiesTo.tasks)) {
      const existIds = item.tasks.map((t) => t.task_uid);
      const updatedIds = tiesTo.tasks.map((t) => t.task_uid);

      const changesTasks: FindingTasksPayload = {
        add: tiesTo.tasks.filter((t) => !existIds.includes(t.task_uid)),
        remove: item.tasks.filter((t) => !updatedIds.includes(t.task_uid)),
      };

      if (changesTasks.add.length || changesTasks.remove.length) {
        await api.tasks(id, changesTasks);
        applyChanges({ id, tasks: tiesTo.tasks });
      }
    }

    if ("categories" in tiesTo && Array.isArray(tiesTo.categories)) {
      const existIds = item.categories.map((c) => c.category_uid);
      const updatedIds = tiesTo.categories.map((c) => c.category_uid);

      const changesCategories: FindingCategoriesPayload = {
        add: tiesTo.categories.filter(
          (c) => !existIds.includes(c.category_uid),
        ),
        remove: item.categories.filter(
          (c) => !updatedIds.includes(c.category_uid),
        ),
      };

      if (changesCategories.add.length || changesCategories.remove.length) {
        await api.categories(id, changesCategories);
        applyChanges({ id, categories: tiesTo.categories });
      }
    }

    return dict.value[id];
  };

  const addDocuments = async (
    id: Finding["id"],
    documents: Finding["documents"],
  ) => {
    const changes: FindingDocumentsPayload = {
      add: documents,
      remove: [],
    };
    await api.documents(id, changes);

    const itemDocuments = cloneDeep(dict.value[id].documents);
    applyChanges({ id, documents: itemDocuments.concat(documents) });

    return dict.value[id];
  };

  const updateDocuments = async (
    id: Finding["id"],
    documents: Finding["documents"],
  ) => {
    const itemDocuments = cloneDeep(dict.value[id].documents);

    const existIds = itemDocuments.map((d) => d.document_id);
    const updatedIds = documents.map((d) => d.document_id);

    const changes: FindingDocumentsPayload = {
      add: documents.filter((d) => !existIds.includes(d.document_id)),
      remove: itemDocuments.filter((d) => !updatedIds.includes(d.document_id)),
    };

    if (!changes.add.length && !changes.remove.length) {
      return dict.value[id];
    }

    await api.documents(id, changes);
    applyChanges({ id, documents });

    return dict.value[id];
  };

  const addFolders = async (id: Finding["id"], folders: Finding["folders"]) => {
    const changes: FindingFoldersPayload = {
      add: folders,
      remove: [],
    };
    await api.folders(id, changes);

    const itemFolders = cloneDeep(dict.value[id].folders);
    applyChanges({ id, folders: itemFolders.concat(folders) });

    return dict.value[id];
  };

  const updateFolders = async (
    id: Finding["id"],
    folders: Finding["folders"],
  ) => {
    const itemFolders = cloneDeep(dict.value[id].folders);

    const existIds = itemFolders.map((f) => f.folder_id);
    const updatedIds = folders.map((f) => f.folder_id);

    const changes: FindingFoldersPayload = {
      add: folders.filter((f) => !existIds.includes(f.folder_id)),
      remove: itemFolders.filter((f) => !updatedIds.includes(f.folder_id)),
    };

    if (!changes.add.length && !changes.remove.length) {
      return dict.value[id];
    }

    await api.folders(id, changes);
    applyChanges({ id, folders });

    return dict.value[id];
  };

  const exportFindings = async (ids: Finding["id"][], _format: "xlsx") => {
    const { file, filename } = await api.exportBulkExcel(ids);
    dowloadFile(file, filename);
  };

  const statusesStore = useFindingsStatusesStore();

  const filledList = computed(() =>
    list.value
      .map((item) => {
        const status = statusesStore.dict[item.status_id];
        const isResolved = status ? status.type === "closed" : false;
        return {
          ...item,
          isResolved,
        };
      })
      .sort((a, b) => a.order - b.order),
  );

  const listByTaskUid = computed(() => {
    return list.value.reduce(
      (acc, finding) => {
        for (const task of finding.tasks) {
          if (!acc[task.task_uid]) {
            acc[task.task_uid] = [finding];
          } else {
            acc[task.task_uid].push(finding);
          }
        }
        return acc;
      },
      {} as Record<Finding["tasks"][number]["task_uid"], Finding[]>,
    );
  });

  const filledDict = computed(() =>
    filledList.value.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, createDictionary<Finding & { isResolved: boolean }>()),
  );

  const reloadItemById = async (id: Finding["id"]) => {
    const item = dict.value[id];
    if (item) {
      const reloadedItem = await api.loadByKey(item.key);
      applyChanges({ ...reloadedItem });
    }
  };

  const reorderFinding = async (
    moveId: Finding["id"],
    direction: "insert_after" | "insert_before",
    reference: Finding["id"],
  ) => {
    await api.reorderFinding(moveId, {
      direction,
      reference,
    });
    await load();
  };

  return {
    list: filledList,
    dict: filledDict,
    isLoading,
    isLoadError,
    load,
    create,
    update,
    remove,
    updateAssignees,
    updateFollowers,
    updateTiesTo,
    addDocuments,
    updateDocuments,
    addFolders,
    updateFolders,
    listByTaskUid,
    reloadItemById,
    applyChanges,
    exportFindings,
    reorderFinding,
  };
});
