import * as d3 from "d3";

angular
  .module("dealroom.analytics.heatmap.axis", [
    "dealroom.analytics.heatmap.service.config",
  ])
  .directive("drAnalyticsHeatmapAxis", () => ({
    scope: {
      type: "@",
      dispatcher: "<",
    },
    controller: HeatmapAxisController,
    restrict: "A",
  }));

const PREFIX = ">";
// const ARROWS_UNICODE = {right: '&#xf0da;', down: '&#xf0d7;'};
const ARROWS_UNICODE = { right: "", down: "" };

HeatmapAxisController.$inject = [
  "$scope",
  "$element",
  "AnalyticsHeatmapConfigService",
];
function HeatmapAxisController(
  $scope,
  $element,
  AnalyticsHeatmapConfigService,
) {
  const type = $scope.type;
  const dispatcher = $scope.dispatcher;

  const axisFn = type === "x" ? d3.axisBottom : d3.axisLeft;
  const axisParams =
    type === "x"
      ? AnalyticsHeatmapConfigService.XAXIS
      : AnalyticsHeatmapConfigService.YAXIS;

  const rootElement = d3
    .select($element[0])
    .classed("analytics-axis", true)
    .classed(`analytics-axis--${type}`, true);

  // create child nodes
  const textGroup = rootElement.append("g");
  const textNode = textGroup
    .append("text")
    .attr("text-anchor", "middle")
    .attr("font-size", axisParams.name.height / 1.3)
    .attr("transform", `rotate(${type === "y" ? -90 : 0})`);
  const ticksGroup = rootElement.append("g");

  const updateUid = "updateHeatmap.HeatmapAxisController-" + type;
  dispatcher.on(updateUid, update, $element, $scope);

  function update(params) {
    const left =
      type === "x" ? axisParams.left : axisParams.full - axisParams.right;
    const top = type === "x" ? params.height : 0;
    rootElement.attr(
      "transform",
      `translate(${left}, ${top + AnalyticsHeatmapConfigService.YAXIS.top})`,
    );

    setName(params[type]);
    createTicks(params[type]);
  }

  function setName({ scale, name }) {
    const g = rootElement.select("g.name");
    textNode.text(name);
    const height = (textNode.node().getBoundingClientRect().height * 2) / 3;
    const left =
      type === "x"
        ? scale.range()[1] / 2
        : -(axisParams.width + axisParams.name.top);
    const top = type === "x" ? axisParams.full - height : scale.range()[0] / 2;
    textGroup.attr("transform", `translate(${left}, ${top})`);
  }

  function createTicks({ scale, values }) {
    const labels = d3.map(values, (v) => v.key);
    const bandwidth = scale.bandwidth();
    const maxTextSpace =
      0.9 *
      (type === "x"
        ? Math.max(bandwidth, axisParams.height)
        : axisParams.width);
    const isRotated = type === "x" && maxTextSpace < axisParams.height;
    const height = type === "y" || isRotated ? bandwidth : axisParams.height;
    const linesCount = Math.floor(height / 12);
    const axis = axisFn(scale).tickSizeInner(0).tickSizeOuter(0);
    ticksGroup.selectAll("*").remove();
    ticksGroup
      .call(axis)
      .selectAll(".tick")
      .each(function (key, i) {
        const label = labels.get(key);
        const tick = d3.select(this);
        const text = tick.select("text").attr("dy", 0).attr("dx", 0);
        if (angular.isString(label.cls)) text.classed(label.cls, true);

        const prefix = PREFIX.repeat((label.lvl || 0) + 1);
        const words =
          linesCount === 1
            ? [label.label]
            : label.label.split(" ").slice(0, linesCount);
        text
          .text("")
          .selectAll("tspan")
          .data(words)
          .enter()
          .append("tspan")
          .attr("x", 0)
          .attr("y", (word, i) => (i + 1) * 9)
          .each(function (word, i) {
            if (i === 0 && label.usePrefix) word = `${prefix} ${word}`;
            let cropLength = word.length;
            const getWidth = (cropLength) => {
              const crop = cropLength === word.length ? "" : "...";
              d3.select(this).text(word.substring(0, cropLength) + crop);
              return d3.select(this).node().getBoundingClientRect().width;
            };
            while (getWidth(cropLength) > maxTextSpace && cropLength > 1) {
              cropLength -= 1;
            }
          });
        if (type === "y") {
          const height = tick.node().getBoundingClientRect().height;
          const top = scale(key) + bandwidth / 2 - height / 2;
          tick.attr("transform", `translate(${0},${top})`);

          text.attr("transform", `translate(${0},${-0.5})`);

          if (label.hasChilds) {
            const isOpen = label.isOpen;
            tick.style("cursor", "pointer").on("click", toggleLabelExpand);
            const collapse = tick
              .insert("g", "text")
              .attr("class", "collapse-ico");
            const ico = collapse
              .append("text")
              .attr("class", "fa")
              .style("font-size", axisParams.collapse.fontSize)
              .text(isOpen ? ARROWS_UNICODE.down : ARROWS_UNICODE.right);

            const bbox = ico.node().getBoundingClientRect();
            ico.attr("dy", bbox.height / 2 + axisParams.collapse.width / 3);
            ico.attr("dx", bbox.width / 2 + axisParams.collapse.width / 2);
          }
        } else if (isRotated) {
          const height = tick.node().getBoundingClientRect().height;
          const left = scale(key) + bandwidth / 2 + height / 2;
          const top = axisParams.top;
          text.attr("text-anchor", "start");
          text.attr("transform", "rotate(90)");
          tick.attr("transform", `translate(${left},${top})`);
        } else if (type === "x") {
          const left = scale(key) + bandwidth / 2;
          const top = axisParams.top;
          tick.attr("transform", `translate(${left},${top})`);
        }
      });
  }

  function toggleLabelExpand(key) {
    dispatcher.call("expand", key);
  }
}
