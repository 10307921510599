<template>
  <span class="dr-editor__menu-spacer" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DrEditorMenuSpacer",
});
</script>
