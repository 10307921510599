<template>
  <DrToolbarBulkActions
    slot-on-right
    :actions="bulkActions"
    :active-action="activeAction"
    @action="handleAction"
  >
    <DrToolbarFilterButton
      :label="selectedTasksTitle"
      is-active
      @clear="handleClear"
    />
  </DrToolbarBulkActions>

  <BulkDynamicStartDatePicker
    ref="bulkDynamicStartDatePickerRef"
    @update="handleSubmitBulkDynamicStartDatePicker"
    @hide="resetActiveAction('start_date')"
  />

  <BulkDynamicDueDatePicker
    ref="bulkDynamicDueDatePickerRef"
    @update="handleSubmitBulkDynamicDueDatePicker"
    @hide="resetActiveAction('due_date')"
  />

  <BulkUpdateAssigneesPicker
    ref="bulkUpdateAssigneesPickerRef"
    :tree="usersTree"
    :is-pending="!isMembersAndGroupsLoaded"
    @submit="handleSubmitAssigneesPicker($event)"
    @hide="resetActiveAction('assign')"
  />

  <BulkRemindAssigneesPicker
    ref="bulkRemindAssigneesPickerRef"
    :assignees="selectedTasksAssignees"
    :reviewers="selectedTasksReviewers"
    :is-pending="!isMembersAndGroupsLoaded"
    @submit="handleSubmitRemindAssigneesPicker($event)"
    @hide="resetActiveAction('remind')"
  />

  <BulkUpdatePriorityPicker
    ref="bulkUpdatePriorityPickerRef"
    :priorities="taskPriorities"
    @submit="handleSubmitUpdatePriorityPicker($event)"
    @hide="resetActiveAction('priority')"
  />

  <BulkUpdateStatusPicker
    ref="bulkUpdateStatusPickerRef"
    :statuses="taskStatuses"
    :selected="taskStatusesSelected"
    @submit="handleSubmitUpdateStatusPicker($event)"
    @hide="resetActiveAction('status')"
  />

  <BulkMoveOrCopyPicker
    ref="bulkMoveIntoCategoryPickerRef"
    :selected="taskCategoriesSelected"
    @submit="handleSubmitMoveIntoCategoryPicker($event)"
    @hide="resetActiveAction('move')"
  />

  <BulkUpdateLabelsPicker
    ref="bulkUpdateLabelsPickerRef"
    @submit="handleSubmitUpdateLabelsPicker($event)"
    @hide="resetActiveAction('labels')"
  />

  <BulkMoveOrCopyPicker
    ref="bulkCopyRequestsPickerRef"
    :selected="taskCategoriesSelected"
    @submit="handleSubmitCopyRequestPicker($event)"
    @hide="resetActiveAction('copy')"
  />
</template>
<script setup lang="ts">
import { ElMessageBox } from "element-plus";
import { computed, ref, unref } from "vue";
import { convertBtzToPtz } from "@shared/ui/dr-datepicker/DrDatepicker.vue";
import {
  DrToolbarBulkActions,
  DrToolbarFilterButton,
} from "@shared/ui/dr-toolbar";
import { type ToolbarBulkActionsItem } from "@shared/ui/dr-toolbar";
import { useBrowserLocation } from "@vueuse/core";

import { ROOM_DATA } from "@setups/data";
import {
  insightTrack,
  RoomRequestsBulkEvent,
  RoomTasksImportExportEvent,
} from "@app/insight";
import { DrStore } from "@app/vue";
import { $notifyDanger, $notifySuccess } from "@drVue/common";
import { pinia } from "@drVue/store/pinia";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import { useTasksTableStore } from "@drVue/store/pinia/room/tasksTable/tasksTable";
import $utils from "@drVue/utils";
import BulkDynamicDueDatePicker from "../shared/BulkDynamicDueDatePicker.vue";
import BulkDynamicStartDatePicker from "../shared/BulkDynamicStartDatePicker.vue";
import BulkMoveOrCopyPicker from "../shared/BulkMoveOrCopyPicker.vue";
import BulkRemindAssigneesPicker from "../shared/BulkRemindAssigneesPicker.vue";
import BulkUpdateAssigneesPicker, {
  type BulkUpdateAssigneesPayload,
} from "../shared/BulkUpdateAssigneesPicker.vue";
import BulkUpdateLabelsPicker, {
  type BulkUpdateLabelsPayload,
} from "../shared/BulkUpdateLabelsPicker.vue";
import BulkUpdatePriorityPicker from "../shared/BulkUpdatePriorityPicker.vue";
import BulkUpdateStatusPicker from "../shared/BulkUpdateStatusPicker.vue";

import type { Group } from "@drVue/store/modules/room/groups/GroupsApiService";
import type { RoomMember } from "@drVue/store/modules/room/members/RoomMembersApiService";
import type { TaskStatus } from "@drVue/store/modules/room/tasks-statuses/types";
import type { Task, TaskPriority } from "@drVue/store/pinia/room/tasks";
import type { TreeItem } from "@shared/ui/dr-tree";

type UserInfo = {
  id: string;
  name: string;
  email: string;
  avatar: string;
};

type UsersTreeGroup = TreeItem<
  {
    id: string;
  },
  UserInfo
>;

interface Props {
  /** Selected tasks count */
  selectedCount: number;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: "copy", taskId: string): void;
  (e: "archive", uids: Task["uid"][]): void;
  (e: "restore", uids: Task["uid"][]): void;
}>();

const M_PREFIX = "member_";
const G_PREFIX = "group_";

const isTrialRoom = ROOM_DATA.isTrialRoom;

const location = useBrowserLocation();
const tasksStore = useTasksStore();
const tasksTableStore = useTasksTableStore(pinia);

const inTheArchive = computed(() => {
  return !!unref(location).hash?.match(/archived/);
});

const canManageTasks = !!ROOM_DATA?.userPermissions?.canManageTasks;
const canCreateTasks = !!ROOM_DATA?.userPermissions?.canCreateTasks;
const canDeleteTasks = !!ROOM_DATA?.userPermissions?.canDeleteTasks;

const activeAction = ref<string>();

let bulkActions: ToolbarBulkActionsItem[] = [
  {
    id: "start_date",
    name: "Start Date",
    icon: "calendar",
  },
  {
    id: "due_date",
    name: "Due Date",
    icon: "calendar",
  },
  {
    id: "assign",
    name: "Assign",
    icon: "user-plus",
  },
  {
    id: "remind",
    name: "Remind",
    icon: "bell",
  },
  {
    id: "status",
    name: "Status",
    icon: "dot-circle",
  },
  {
    id: "priority",
    name: "Priority",
    icon: "flag",
  },
  {
    id: "labels",
    name: "Labels",
    icon: "tags",
  },
  {
    id: "move",
    name: "Move",
    icon: "arrows",
  },
];

bulkActions = bulkActions.filter(
  (i) => canManageTasks && (!inTheArchive.value || i.id === "restore"),
);

if (canCreateTasks) {
  bulkActions.push({
    id: "copy",
    name: "Copy",
    icon: "copy",
  });
}

if (canDeleteTasks) {
  bulkActions.push(
    inTheArchive.value
      ? { id: "restore", name: "Restore", icon: "inbox-out" }
      : { id: "delete", name: "Delete", icon: "trash" },
  );
}

if (canManageTasks && !inTheArchive.value) {
  bulkActions.push({
    id: "unlink_dynamic_dates",
    name: "Unlink Dynamic Dates",
    icon: "link",
  });
}

if (!isTrialRoom) {
  bulkActions.push({
    id: "export_pdf",
    name: "Export to PDF",
    icon: "inbox-out",
  });
  bulkActions.push({
    id: "export_excel",
    name: "Export to Excel",
    icon: "inbox-out",
  });
}

const selectedTasksTitle = computed(() => {
  const count = props.selectedCount;
  return `${count} ${$utils.text.pluralize("request", count)} selected`;
});

const isMembersAndGroupsLoaded = computed(() => {
  const { groups, members } = DrStore.state.room;

  return (
    members.isLoading === false &&
    members.isError === false &&
    groups.isLoading === false &&
    groups.isError === false
  );
});

const groupsList = computed(() => {
  return DrStore.state.room.groups.pgroupsList;
});

const getUsersOfGroup = (group: Group) => {
  return DrStore.state.room.members.membersList.filter((member: RoomMember) => {
    const isActiveUser = !member.pending;
    const isMemberOfTheGroup = member.pgroup.id === group.id;
    return isActiveUser && isMemberOfTheGroup;
  });
};

const usersTree = computed<UsersTreeGroup[]>(() => {
  return groupsList.value.reduce((acc: UsersTreeGroup[], g: Group) => {
    const groupMembers = getUsersOfGroup(g).map((u) => {
      return {
        id: `${M_PREFIX}${u.uid}`,
        name: u.name,
        email: u.email,
        avatar: u.avatar.reduced || "",
      };
    });

    if (!groupMembers.length) return acc;

    const group = {
      id: `${G_PREFIX}${g.id}`,
      name: g.name,
      children: groupMembers,
    };

    acc.push(group);

    return acc;
  }, []);
});

const getUsersInfo = (usersIds: RoomMember["id"][]) => {
  return usersIds.reduce((acc, id) => {
    const user = DrStore.state.room.members.members[id];
    if (user) {
      acc.push({
        id: user.uid,
        name: user.name,
        email: user.email,
        avatar: user.avatar.reduced,
      });
    }
    return acc;
  }, [] as UserInfo[]);
};

const selectedTasksAssignees = computed(() => {
  const usersSet = new Set<RoomMember["user_id"]>();

  tasksTableStore.selectedTasks.forEach((task) =>
    task.assignees?.forEach((item) => usersSet.add(item.user_id)),
  );

  return getUsersInfo([...usersSet]);
});

const selectedTasksReviewers = computed(() => {
  const usersSet = new Set<RoomMember["user_id"]>();

  tasksTableStore.selectedTasks.forEach((task) =>
    (task.reviewers || []).forEach((item) => usersSet.add(item.user_id)),
  );

  return getUsersInfo([...usersSet]);
});

const handleExportMenu = (action: string, taskUids: string[]) => {
  if (!taskUids.length) return;

  switch (action) {
    case "export_pdf":
      tasksStore.exportTasks(taskUids, "pdf").then(() => {
        insightTrack(RoomTasksImportExportEvent.Exported, {
          format: "Pdf",
          tasks: "selected",
        });
      });
      break;
    case "export_excel":
      tasksStore.exportTasks(taskUids, "xlsx").then(() => {
        insightTrack(RoomTasksImportExportEvent.Exported, {
          format: "Excel",
          tasks: "selected",
        });
      });
      break;
  }
};

const bulkDynamicStartDatePickerRef =
  ref<InstanceType<typeof BulkDynamicStartDatePicker>>();
const bulkDynamicDueDatePickerRef =
  ref<InstanceType<typeof BulkDynamicDueDatePicker>>();
const bulkMoveIntoCategoryPickerRef =
  ref<InstanceType<typeof BulkMoveOrCopyPicker>>();
const bulkRemindAssigneesPickerRef =
  ref<InstanceType<typeof BulkRemindAssigneesPicker>>();
const bulkUpdateAssigneesPickerRef =
  ref<InstanceType<typeof BulkUpdateAssigneesPicker>>();
const bulkUpdatePriorityPickerRef =
  ref<InstanceType<typeof BulkUpdatePriorityPicker>>();
const bulkUpdateStatusPickerRef =
  ref<InstanceType<typeof BulkUpdateStatusPicker>>();
const bulkUpdateLabelsPickerRef =
  ref<InstanceType<typeof BulkUpdateLabelsPicker>>();
const bulkCopyRequestsPickerRef =
  ref<InstanceType<typeof BulkMoveOrCopyPicker>>();

const taskSelectedUIDs = computed(() =>
  tasksTableStore.selectedTasks.map((t) => t.uid),
);
const taskSelectedIDs = computed(() =>
  tasksTableStore.selectedTasks.map((t) => t.id),
);

const resetActiveAction = (oldAction: ToolbarBulkActionsItem["id"]) => {
  if (oldAction === activeAction.value) {
    activeAction.value = undefined;
  }
};

const handleAction = (
  actionId: ToolbarBulkActionsItem["id"],
  reference: HTMLElement,
) => {
  switch (actionId) {
    case "start_date":
      bulkDynamicStartDatePickerRef.value?.show(reference);
      activeAction.value = actionId;
      break;
    case "due_date":
      bulkDynamicDueDatePickerRef.value?.show(reference);
      activeAction.value = actionId;
      break;
    case "move":
      bulkMoveIntoCategoryPickerRef.value?.show(reference, "move");
      activeAction.value = actionId;
      break;
    case "remind":
      bulkRemindAssigneesPickerRef.value?.show(reference);
      activeAction.value = actionId;
      break;
    case "assign":
      bulkUpdateAssigneesPickerRef.value?.show(reference);
      activeAction.value = actionId;
      break;
    case "priority":
      bulkUpdatePriorityPickerRef.value?.show(reference);
      activeAction.value = actionId;
      break;
    case "status":
      bulkUpdateStatusPickerRef.value?.show(reference);
      activeAction.value = actionId;
      break;

    case "labels":
      bulkUpdateLabelsPickerRef.value?.show(reference);
      activeAction.value = actionId;
      break;

    case "copy": {
      const taskUids = taskSelectedUIDs.value;
      if (taskUids.length === 1) {
        emit("copy", taskUids[0]);
        tasksTableStore.clearSelection();
      } else {
        bulkCopyRequestsPickerRef.value?.show(reference, "copy");
        activeAction.value = actionId;
      }
      break;
    }

    case "delete":
      emit("archive", taskSelectedUIDs.value);
      break;
    case "restore":
      emit("restore", taskSelectedUIDs.value);
      break;

    case "export_pdf":
      handleExportMenu("export_pdf", taskSelectedUIDs.value);
      break;
    case "export_excel":
      handleExportMenu("export_excel", taskSelectedUIDs.value);
      break;
    case "unlink_dynamic_dates":
      tasksStore
        .patchTaskBulkV2(
          taskSelectedIDs.value.map((id) => ({
            id,
            dynamic_due_date: null!,
            is_due_date_dynamic: false,
            dynamic_start_date: null!,
            is_start_date_dynamic: false,
          })),
        )
        .then(
          () => {
            tasksTableStore.clearSelection();
            insightTrack(RoomRequestsBulkEvent.DynamicDatesDelete);
          },
          (error) => $notifyDanger(error.message),
        );
      break;
  }
};

const getBulkUpdateAssigneesMethod = (
  type: BulkUpdateAssigneesPayload["type"],
) => {
  switch (type) {
    case "assignees":
      return tasksStore.bulkUpdateAssignees;
    case "reviewers":
      return tasksStore.bulkUpdateReviewers;
    case "followers":
      return tasksStore.bulkUpdateFollowers;
  }
};

const taskPriorities = computed(() =>
  tasksTableStore.selectedTasks.map((t) => t.priority),
);

const taskStatuses = computed<TaskStatus[]>(
  () => DrStore.state.room.tasksStatuses.list,
);
const taskStatusesSelected = computed<TaskStatus["id"] | undefined>(() => {
  const allSelectedStatuses = new Set(
    tasksTableStore.selectedTasks.map((t) => t.status_id),
  );

  if (allSelectedStatuses.size === 1) {
    return allSelectedStatuses.values().next().value;
  }

  return undefined;
});

const handleSubmitBulkDynamicStartDatePicker = (payload?: Partial<Task>) => {
  if (payload!.start_date) {
    payload!.start_date = convertBtzToPtz(payload!.start_date);
  }
  tasksStore
    .patchTaskBulkV2(taskSelectedIDs.value.map((id) => ({ id, ...payload })))
    .then(
      () => tasksTableStore.clearSelection(),
      (err) => $notifyDanger(err.message),
    );
};

const handleSubmitBulkDynamicDueDatePicker = (payload?: Partial<Task>) => {
  if (payload!.due_date) {
    payload!.due_date = convertBtzToPtz(payload!.due_date);
  }
  tasksStore
    .patchTaskBulkV2(taskSelectedIDs.value.map((id) => ({ id, ...payload })))
    .then(
      () => tasksTableStore.clearSelection(),
      (err) => $notifyDanger(err.message),
    );
};

const handleSubmitAssigneesPicker = ({
  type,
  value,
  rewrite,
}: BulkUpdateAssigneesPayload) => {
  const userIds = value.map((memberId) =>
    (memberId as string).replace(M_PREFIX, ""),
  );
  const method = getBulkUpdateAssigneesMethod(type);

  method(taskSelectedUIDs.value, userIds, rewrite).then(
    () => tasksTableStore.clearSelection(),
    (err) => {
      /** @todo process the error response object (err.response.data)
       *  to retrieve the personalized message */
      $notifyDanger(err.message);
    },
  );
};

const handleSubmitRemindAssigneesPicker = (userIds: string[]) => {
  tasksStore.bulkRemindParticipants(taskSelectedUIDs.value, userIds).then(
    () => {
      tasksTableStore.clearSelection();
      $notifySuccess("Users are reminded.");
    },
    () => $notifyDanger("Failed to bulk remind participants."),
  );
};

const handleSubmitUpdatePriorityPicker = (priority: TaskPriority) => {
  tasksStore.bulkUpdatePriority(taskSelectedUIDs.value, priority).then(
    () => {
      tasksTableStore.clearSelection();
      $notifySuccess("Bulk update priority done.");
    },
    () => $notifyDanger("Failed to bulk update priority."),
  );
};

const handleSubmitUpdateStatusPicker = (status: TaskStatus) => {
  tasksStore.bulkUpdateStatus(taskSelectedUIDs.value, status.id).then(
    () => {
      tasksTableStore.clearSelection();
      $notifySuccess("Bulk update status done.");
    },
    () => $notifyDanger("Failed to bulk update status."),
  );
};

const taskCategoriesSelected = computed(() => {
  const allSelectedCategories = new Set(
    tasksTableStore.selectedTasks.map((t) => t.category_id),
  );

  if (allSelectedCategories.size === 1) {
    return allSelectedCategories.values().next().value;
  }

  return undefined;
});

const handleSubmitMoveIntoCategoryPicker = (categoryId: string) => {
  tasksStore.bulkMove(taskSelectedUIDs.value, categoryId).then(
    () => {
      tasksTableStore.clearSelection();
      $notifySuccess("Bulk move requests done.");
    },
    () => $notifyDanger("Failed to bulk move requests."),
  );
};

const handleSubmitCopyRequestPicker = (categoryId: string) => {
  tasksStore.bulkCopy(taskSelectedUIDs.value, categoryId).then(
    () => {
      tasksTableStore.clearSelection();
      $notifySuccess("Bulk copy requests done.");
    },
    () => $notifyDanger("Failed to bulk copy requests."),
  );
};

const handleSubmitUpdateLabelsPicker = async ({
  value,
  overwrite,
}: BulkUpdateLabelsPayload) => {
  if (!value.length && overwrite) {
    try {
      await ElMessageBox.alert(
        "Are you sure you want to remove all labels from the selected requests?",
        "Remove all labels.",
        {
          confirmButtonText: "Remove",
          confirmButtonClass: "el-button--danger",
          showCancelButton: true,
          showClose: true,
          cancelButtonText: "No",
        },
      );
    } catch {
      return;
    }
  }

  tasksStore.bulkUpdateLabels(taskSelectedUIDs.value, value, overwrite).then(
    () => {
      tasksTableStore.clearSelection();
      $notifySuccess("Bulk update labels for selected requests done.");
    },
    () => $notifyDanger("Failed to bulk updates labels for selected requests."),
  );
};

const handleClear = () => {
  tasksTableStore.clearSelection();
};
</script>
