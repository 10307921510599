<template>
  <div class="billing-plan-selection__wrapper">
    <div v-if="isPlansLoaded" class="billing-plan-selection__data">
      <PaymentIntervalSwitch
        :intervals="intervals"
        :value="selectedInterval"
        @change="selectInterval"
      />
      <div class="billing-plan-selection__spacer" />
      <div class="billing-plan-info__wrapper">
        <div v-for="plan in plans" :key="plan.title">
          <PlanInfo
            :plan="plan"
            :active="plan.title === selectedPlan"
            @click="selectPlan(plan)"
          />
        </div>
      </div>
    </div>
    <div v-else>Loading</div>
    <h4 v-if="errorMsg" class="color-red">
      <br />
      {{ errorMsg }}
    </h4>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  BillingApiService,
  PaymentPlanIntervals,
} from "@drVue/api-service/common/billing";
import { default as PaymentIntervalSwitch } from "./PaymentIntervalSwitch.vue";
import { default as PlanInfo } from "./PlanInfo.vue";

import type { Plan } from "@drVue/api-service/common/billing";
import type { PropType } from "vue";

interface Data {
  selectedInterval: any;
  selectedPlan: any;
  allPlans: Plan[];
  isPlansLoaded: boolean;
  errorMsg: any;
  billingApi: BillingApiService;
}

export default defineComponent({
  name: "SubscriptionSelect",
  components: {
    PaymentIntervalSwitch,
    PlanInfo,
  },
  props: {
    paymentTypes: {
      required: false,
      default: null,
      type: Array as PropType<string[] | null>,
    },
  },
  emits: ["change"],
  data(): Data {
    return {
      selectedInterval: "month",
      selectedPlan: "",
      allPlans: [],
      isPlansLoaded: false,
      errorMsg: "",
      billingApi: new BillingApiService(),
    };
  },
  computed: {
    plans(): Plan[] {
      return this.allPlans.filter(
        (plan) =>
          plan.interval === this.selectedInterval &&
          (!this.paymentTypes || this.paymentTypes.includes(plan.payment_type)),
      );
    },
    intervals(): string[] {
      const plansIntervals = this.allPlans.map((plan) => plan.interval);
      // keep order of intervals
      return PaymentPlanIntervals.filter((interval) =>
        plansIntervals.includes(interval),
      );
    },
  },
  mounted() {
    this.billingApi.plans().then(
      (plans: Plan[]) => {
        this.allPlans = plans;
        this.setDefaultSelectedPlan();
        this.isPlansLoaded = true;
      },
      (errorResp) => {
        // simply use error string for now
        // DrAlert does not work in user-dashboard
        this.errorMsg = "Failed to load plans information";
      },
    );
  },
  methods: {
    selectInterval(interval: string) {
      this.selectedInterval = interval;
      this.setDefaultSelectedPlan();
    },
    selectPlan(plan: Plan) {
      this.selectedPlan = plan.title;
      this.$emit("change", plan);
    },
    setDefaultSelectedPlan() {
      if (this.plans.length === 1) {
        this.selectPlan(this.plans[0]);
      }
    },
  },
});
</script>
