import { DateType, deserializeDrDate } from "@drVue/api-service/parse";
import parse from "@drVue/components/client-dashboard/users/AddUserModal/emails-parser";
import DrStore from "@drVue/store";

import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";
import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type {
  EmailAttachment,
  EmailMessage,
} from "@drVue/store/modules/room/emails/EmailsApiService";
import type { Dictionary } from "@drVue/types";

export interface EmailUser {
  email: string;
  user: OrgUser | null;
  isUserLinked: boolean;
}

export interface EmailModel {
  id: string; // email_[a-zA-Z0-9]{22}

  linkedDeal: Deal; // deal_[a-zA-Z0-9]{22} (or null if not linked)

  rawSubject: string; // RE: FW: Initial subject
  from: EmailUser;
  to: EmailUser[];
  cc: EmailUser[];
  bcc: EmailUser[];
  attachments: EmailAttachment[];
  bodyText: string | ""; // body as plain (non-html) text
  origDate: Date; // origination date
  externalMessageId: string; // provider-specific unique message id;
  externalThreadId: string; // provider-specific unique thread id;
}

const mapUsers = (
  emails: string,
  userByEmail: Dictionary<OrgUser>,
  isUserLinkedById: Dictionary<boolean>,
) => {
  const result = parse(emails);

  const parsed = result.parsed.map((parsedEmail): EmailUser => {
    const user = userByEmail[parsedEmail] || null;
    const isUserLinked = user && isUserLinkedById[user.id];

    return {
      user,
      isUserLinked,
      email: parsedEmail,
    };
  });

  const errors = result.errors.map(
    (erroredEmail): EmailUser => ({
      email: erroredEmail,
      user: null,
      isUserLinked: false,
    }),
  );

  return parsed.concat(errors);
};

const mapUser = (
  emails: string,
  users: Dictionary<OrgUser>,
  isUserLinkedByEmail: Dictionary<boolean>,
) => {
  return mapUsers(emails, users, isUserLinkedByEmail)[0];
};

export const mapEmail = (email: EmailMessage) => {
  const userByEmail: Dictionary<OrgUser> =
    DrStore.getters["clientDashboard/orgUsers/mapByEmail"];

  const isUserLinkedById = email.linked_users.reduce(
    (dict, lu) => {
      dict[lu.user_id] = true;
      return dict;
    },
    Object.create(null) as Dictionary<boolean>,
  );

  const emailModel: EmailModel = {
    id: email.id,

    linkedDeal: DrStore.getters["clientDashboard/deals/getDealById"](
      email.linked_deal_id,
    ),

    from: mapUser(email.raw_from, userByEmail, isUserLinkedById),
    to: mapUsers(email.raw_to, userByEmail, isUserLinkedById),
    cc: mapUsers(email.raw_cc, userByEmail, isUserLinkedById),
    bcc: mapUsers(email.raw_bcc, userByEmail, isUserLinkedById),
    attachments: email.attachments,

    rawSubject: email.raw_subject,
    bodyText: email.body_text,

    externalMessageId: email.external_message_id,
    externalThreadId: email.external_thread_id,

    origDate: deserializeDrDate(DateType.DateTime, email.orig_date),
  };

  return emailModel;
};
