import cardHtml from "./card.html?raw";

DocumentsController.$inject = ["DocumentsService", "ActivityDocumentsService"];
angular
  .module("dealroom.analytics.card.heatmap.documents", [
    "dealroom.analytics.service.data",
    "dealroom.documents",
  ])
  .component("drAnalyticsDocumentsHeatmapCard", {
    template: cardHtml,
    controller: DocumentsController,
  });

function DocumentsController(DocumentsService, ActivityDocumentsService) {
  const $ctrl = this;
  //// public
  $ctrl.title = "Documents Activity";
  $ctrl.yLabel = "Folders";
  $ctrl.nestBy = "folder";
  $ctrl.filterBy = "documents";
  $ctrl.verb = "document_viewed";

  $ctrl.statsTypes = {
    viewsCoverage: {
      label: "Documents Views (% of documents viewed in a folder)",
    },
    viewsNumbers: { label: "Documents Views" },
    timeSpent: { label: "Documents Time Spent (Minutes)" },
  };

  $ctrl.getDKey = getDKey;
  $ctrl.getYKeys = getYKeys;
  $ctrl.getDescendants = getDescendants;
  $ctrl.getTotalValues = getTotalValues;
  $ctrl.getYLabel = getYLabel;

  //// functions

  function getYLabel(folderId) {
    const rootId = DocumentsService.rootFolder.id;
    const isRoot = +folderId === +rootId;
    const folder = ActivityDocumentsService.Folders[folderId];
    return {
      label: isRoot
        ? folder.name
        : folder
          ? // `${folder.name}` : 'Unknown folder',
            `${folder.treeIndex} ${folder.name}`
          : "Unknown folder",
      order: isRoot ? 0 : folder ? folder.index : 10000,
      hasChilds:
        !isRoot && folder && folder.folders.filter(filterArchived).length > 0,
      parentsIds:
        folder && folder.parents.map((f) => f.id).filter((id) => id != rootId),
      usePrefix: folder && !isRoot,
    };
  }

  function getDKey(d) {
    const docId = d.target.id;
    const doc = DocumentsService.Files[docId];
    if (!doc || doc.is_archived || doc.is_removed || doc.processingError)
      return null;
    return docId;
  }

  function filterArchived(folder) {
    return folder.is_archived === false && folder.is_removed === false;
  }

  function getYKeys(folderId, includeRoot = true) {
    if (!folderId) {
      if (Object.keys(DocumentsService.Files).length === 0) return [];
      return Object.keys(ActivityDocumentsService.stats || {}).filter(
        (folderId) => includeRoot || folderId != DocumentsService.rootFolder.id,
      );
    }
    return DocumentsService.Folders[folderId].folders
      .filter(filterArchived)
      .map((f) => +f.id);
  }

  function getDescendants(folderIds) {
    return folderIds.reduce((descendantsIds, folderId) => {
      const ids = DocumentsService.getFolderDescendants(folderId);
      return descendantsIds.concat(ids);
    }, []);
  }

  function getTotalValues(descendants) {
    return descendants.reduce((count, folderId) => {
      const viewableDocs = DocumentsService.Folders[folderId].files.filter(
        (doc) => !doc.processingError,
      );

      return count + viewableDocs.length;
    }, 0);
  }
}

export default DocumentsController;
