<template>
  <div>
    <div class="help-actions">
      <div class="help-actions__row" v-if="isCrispChatEnabled">
        <a class="help-actions__item" @click="openCrispChat">
          <div>
            <p class="help-actions__item__title">START LIVE CHAT</p>
            <p
              class="help-actions__item__description help-actions__item__description--main"
            >
              Recommended, 24/7 Support
            </p>
          </div>
          <img
            :src="svgUrls.charSvgUrl"
            class="help-actions__item__icon"
            alt="Live Chat"
          />
        </a>
      </div>
      <div class="help-actions__row">
        <a
          class="help-actions__item"
          :href="
            isDealRoom
              ? 'https://support.dealroom.net/'
              : 'https://support.firmroom.com/'
          "
          target="_blank"
        >
          <div>
            <p class="help-actions__item__title">Help center</p>
            <p class="help-actions__item__description">
              Learn how to use and configure your {{ websiteName }}
            </p>
          </div>

          <img
            :src="svgUrls.feedbackSvgUrl"
            class="help-actions__item__icon"
            alt="Feedback"
          />
        </a>
        <a
          v-if="isDealRoom"
          class="help-actions__item"
          href="https://dealroom.net/ma-resource-library"
          target="_blank"
        >
          <div>
            <p class="help-actions__item__title">M&A Resource Library</p>
            <p class="help-actions__item__description">
              Additional material and general resources
            </p>
          </div>
          <img
            :src="svgUrls.librarySvgUrl"
            class="help-actions__item__icon"
            alt="M&A Resource Library"
          />
        </a>
        <a
          v-if="!isDealRoom"
          class="help-actions__item"
          href="https://firmroom.com/blog/firmroom-help-videos"
          target="_blank"
        >
          <div>
            <p class="help-actions__item__title">Help Guide Videos</p>
            <p class="help-actions__item__description">
              Videos to Quickly Learn and Navigate the VDR
            </p>
          </div>
          <img
            :src="svgUrls.librarySvgUrl"
            class="help-actions__item__icon"
            alt="Help Guide Videos"
          />
        </a>
      </div>
      <div
        v-if="trainingVideosService.hasViewableVideos()"
        class="help-actions__row"
      >
        <a
          v-if="trainingVideosService.hasViewableVideos()"
          class="help-actions__item"
          :class="{ 'help-actions__item--active': isTutorialVisible }"
          @click="toggleShowTutorial"
        >
          <div>
            <p class="help-actions__item__title">Tutorial</p>
            <p class="help-actions__item__description">
              Watch quick training materials
            </p>
          </div>
          <img
            :src="svgUrls.videoSvgUrl"
            class="help-actions__item__icon"
            alt="Tutorial"
          />
        </a>

        <a
          v-if="isDealRoom"
          class="help-actions__item"
          href="https://www.youtube.com/dealroom"
          target="_blank"
        >
          <div>
            <p class="help-actions__item__title">YouTube Channel</p>
            <p class="help-actions__item__description">
              Additional {{ websiteName }} User videos
            </p>
          </div>
          <img
            :src="svgUrls.youtubeSvgUrl"
            class="help-actions__item__icon"
            alt="YouTube Channel"
          />
        </a>
      </div>
    </div>

    <div v-if="isTutorialVisible && trainingVideosService.hasViewableVideos()">
      <hr />
      <div class="training-videos">
        <ElDropdown
          placement="bottom-start"
          size="small"
          trigger="click"
          class="training-videos__dropdown"
          @command="selectVideo"
        >
          <span class="el-dropdown-link">
            <b>{{ selectedVideo.title }}</b>
            <i class="el-icon-arrow-down el-icon--right" />
          </span>

          <template #dropdown>
            <ElDropdownMenu>
              <ElDropdownItem
                v-for="v in trainingVideosService.getViewableVideosModels()"
                :key="v.key"
                class="training-videos__dropdown-item"
                :command="v.key"
              >
                <div class="_title">{{ v.title }}</div>
                <div class="_duration">{{ v.duration }}</div>
              </ElDropdownItem>
            </ElDropdownMenu>
          </template>
        </ElDropdown>

        <div class="training-video">
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="training-video__player"
            v-html="selectedVideo.embedCode"
          />
        </div>
      </div>
      <hr />
    </div>

    <div class="help-faq">
      <b class="help-faq__header">Frequently asked questions</b>

      <ElCollapse>
        <ElCollapseItem name="1">
          <template #title>
            I am getting too many/not enough emails from
            {{ websiteName }} about my transaction.
          </template>
          <p>
            Every user can dictate their preferred timetable for email
            notifications from the {{ websiteName }} system.
          </p>
          <p>
            You can do so by selecting the <b>Settings</b> tab from navigation
            bar across the top of the screen, and then
            <b>Email</b> Notifications from the menu on the left.
          </p>
          <p>
            Here you will find a variety of options that will allow you to
            customize when and what emails you receive from us. Don’t forget to
            hit <b>SAVE</b> when you’re done!
          </p>
        </ElCollapseItem>
        <ElCollapseItem v-if="canViewTasks" name="2">
          <template #title>
            Where can I find the template to make sure my diligence spreadsheet
            is in the correct format?
          </template>
          <p>
            First you will have to be granted access to the Requests tab by an
            administrator of the room. You will need to contact them directly to
            do so.
          </p>
          <p>
            If you already have access, you can find this by selecting the
            “Import” button and you will notice a “Download Template” button
            next to the confirmation of Import If you need further assistance
            please feel free to reach out to us at
            <a :href="`mailto:${contactEmail}`" target="_blank">{{
              contactEmail
            }}</a>
          </p>
          <p>
            Remember where this is, as when you’re done you can import your
            properly formatted template here too!
          </p>
        </ElCollapseItem>
        <ElCollapseItem name="3">
          <template #title>
            I can’t see any documents, or a user has notified me that s/he can’t
            see any documents.
          </template>
          <p>
            Please contact the person who invited you to the room and notify
            them of this, it’s usually a simple permissioning change.
          </p>
          <p>
            If you are an admin, you can make the necessary changes via the
            <b>Groups</b> tab at the top of the page. Select the group you wish
            to modify from the menu on the left and “File Permissions” from the
            menu at the top of the screen.
          </p>
          <p>
            Here you can change permissions for that user group using the legend
            at the top of the page. Don’t forget to hit save!
          </p>
        </ElCollapseItem>
        <ElCollapseItem name="4">
          <template #title>
            I just bookmarked a page, and it’s not showing up in “My Bookmarks.”
          </template>
          <p>
            This is usually just a prompt for a simple refresh. Try refreshing
            your page, if it is not accounted for after this, please reach out
            to
            <a :href="`mailto:${contactEmail}`" target="_blank">{{
              contactEmail
            }}</a>
          </p>
        </ElCollapseItem>
        <ElCollapseItem name="5">
          <template #title> Moving a document</template>
          <p>
            Simply select the document(s) you would like to move and then the
            “+” icon at the top of the page. This will open a pop-up that will
            prompt you to select the new (sub)/folder for the document(s) you
            have selected.
          </p>
        </ElCollapseItem>
        <ElCollapseItem name="6">
          <template #title>
            I uploaded my documents/folder structure, but I need to change the
            order of the folders.
          </template>
          <p>
            Simply select the document(s) you would like to move and then the
            “+” icon at the top of the page. This will open a pop-up that will
            prompt you to select the new (sub)/folder for the document(s) you
            have selected.
          </p>
        </ElCollapseItem>
        <ElCollapseItem name="7">
          <template #title>
            I have moved things around and I need my index numbers to reflect
            these changes
          </template>
          <p>
            From the top menu start by selecting the <b>Settings</b> tab, now
            from the ribbon on the left please select “Settings” again beneath
            the header “Room.”
          </p>
          <p>
            Scroll down and you will see a section entitled “Reset documents and
            files indexes,” hit the big blue “Reset” button and follow through
            on the pop-out confirmation of this change.
          </p>
        </ElCollapseItem>
        <ElCollapseItem v-if="canViewTasks" name="8">
          <template #title>
            I need to assign Requests to a party in the {{ websiteName }} to
            complete, how can I do this most effectively?
          </template>
          <p>
            1. Select the box(es) to the left of the requests that you wish to
            assign.
          </p>
          <p>2. Find the Assign button at the top of the page.</p>
          <p>
            3. Select the user(s) who will be responsible for completing the
            requests.
          </p>
          <p>4. Click the blue Assign</p>
        </ElCollapseItem>
      </ElCollapse>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { APP_SETTINGS, ROOM_DATA, USER_DATA } from "@setups/data";
import { initCrisp } from "@drVue/crisp/crisp";
import TrainingVideosService from "../../../../TrainingVideosService";
import charSvgUrl from "./images/chat.svg";
import feedbackSvgUrl from "./images/feedback.svg";
import librarySvgUrl from "./images/library.svg";
import videoSvgUrl from "./images/video.svg";
import youtubeSvgUrl from "./images/youtube.svg";

interface Data {
  contactEmail: any;
  websiteName: any;
  isTutorialVisible: boolean;
  selectedVideo: any;
  trainingVideosService: any;

  svgUrls: {
    charSvgUrl: string;
    feedbackSvgUrl: string;
    librarySvgUrl: string;
    videoSvgUrl: string;
    youtubeSvgUrl: string;
  };
}

export default defineComponent({
  name: "DrHelpModalContent",
  data(): Data {
    return {
      contactEmail: APP_SETTINGS.CONTACT_EMAIL,
      websiteName: APP_SETTINGS.WEBSITE.NAME,
      isTutorialVisible: false,
      selectedVideo: {},
      trainingVideosService: TrainingVideosService,
      svgUrls: {
        charSvgUrl: charSvgUrl,
        feedbackSvgUrl: feedbackSvgUrl,
        librarySvgUrl: librarySvgUrl,
        videoSvgUrl: videoSvgUrl,
        youtubeSvgUrl: youtubeSvgUrl,
      },
    };
  },
  computed: {
    isDealRoom(): any {
      return APP_SETTINGS.WEBSITE.IS_DEALROOM;
    },
    canViewTasks(): any {
      if (ROOM_DATA.userPermissions) {
        return ROOM_DATA.userPermissions.viewTasks;
      }

      return APP_SETTINGS.WEBSITE.IS_DEALROOM;
    },
    isCrispChatEnabled(): boolean {
      return !!APP_SETTINGS.CRISP_WEBSITE_ID;
    },
  },
  beforeMount() {
    this.selectedVideo =
      this.trainingVideosService.getViewableVideosModels()[0];
  },
  methods: {
    selectVideo(key: string) {
      this.selectedVideo = this.trainingVideosService
        .getViewableVideosModels()
        .find((v: any) => v.key === key);
    },
    openCrispChat() {
      initCrisp();
    },
    toggleShowTutorial() {
      this.isTutorialVisible = !this.isTutorialVisible;
    },
  },
});
</script>
