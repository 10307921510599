<template>
  <Emails
    :is-loading="isLoading"
    :emails="emails"
    :entity-type="entityType"
    :editable="editable"
    :search-query="searchQuery"
    @email-deleted="handleEmailDeletion"
  />
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, provide, ref, watch } from "vue";
import { mapEmail } from "@shared/ui/emails-list/EmailModel";
import { RawEmailsInjectionKey } from "@shared/ui/emails-list/keys";

import { DrStore } from "@app/vue";
import { emailsApi } from "@drVue/store/modules/room/emails/EmailsApiService";
import Emails from "./Emails.vue";

import type { EmailMessage } from "@drVue/store/modules/room/emails/EmailsApiService";
import type { EmailModel } from "@shared/ui/emails-list/EmailModel";
import type { ComputedRef, Ref } from "vue";

const isLoading: Ref<boolean> = ref(true);
const lastLoadingPromise = ref<Promise<EmailMessage[]>>();

const rawEmails: Ref<EmailMessage[]> = ref([]);
const emails: ComputedRef<EmailModel[]> = computed(() =>
  rawEmails.value
    .map(mapEmail)
    .sort((a, b) => b.origDate.getTime() - a.origDate.getTime()),
);

provide(RawEmailsInjectionKey, rawEmails);

interface Props {
  entityType: "Deal" | "User";
  entityId: number | string;
  editable?: boolean;
  searchQuery: string;
}

const props = withDefaults(defineProps<Props>(), {
  editable: false,
});

watch(
  () => props.entityId,
  () => {
    updateEmailsList();
  },
);

function updateEmailsList() {
  const promise = (lastLoadingPromise.value = emailsApi[
    `getBy${props.entityType}`
  ](props.entityId));
  promise
    .then((r: EmailMessage[]) => {
      if (lastLoadingPromise.value === promise) {
        rawEmails.value = r;
      }
    })
    .finally(() => {
      if (lastLoadingPromise.value === promise) {
        isLoading.value = false;
      }
    });
}

function handleEmailDeletion(emailId: string) {
  if (props.entityType === "Deal") {
    const dealId = props.entityId;
    DrStore.commit("clientDashboard/deals/deleteEmailAttachments", {
      emailId,
      dealId,
    });
  }
}

onBeforeMount(() => updateEmailsList());
</script>
