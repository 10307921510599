<template>
  <DrTruncatedTextTooltip :content="title">
    <RouterLink v-if="url" v-slot="{ href }" :to="url" custom>
      <a
        :href="href"
        :class="$style.title"
        target="_blank"
        @click.stop.prevent="handleHavigate(href)"
      >
        {{ title }}
      </a>
    </RouterLink>
    <span v-else :class="$style.title"> {{ title }}</span>
  </DrTruncatedTextTooltip>
</template>

<script lang="ts" setup>
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import { DealTasksEvent, insightTrack } from "@app/insight";

interface Props {
  title: string;
  url: string;
}

defineProps<Props>();

const handleHavigate = (href: string) => {
  insightTrack(DealTasksEvent.TaskOpening);
  window.open(href, "_blank");
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.title {
  font: typography.$body_regular;
  color: colors.$pr-900;

  &:link {
    &:focus {
      color: colors.$pr-800;
      text-decoration: none;
    }

    &:hover {
      color: colors.$sc-600;
      text-decoration: underline;
    }
  }
}
</style>
