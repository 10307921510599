import { keyBy } from "lodash-es";
import { orderBy } from "lodash-es";
import { h } from "vue";
import { TableInlineEditor } from "@shared/ui/table-inline-editor";

import { ORG_MEMBER_DATA } from "@setups/data";
import { FieldItemTypes } from "@drVue/api-service/client-dashboard";
import { mapCustomFieldToSchema } from "@drVue/components/client-dashboard/dynamic-form/utils";
import { drUserTime } from "@drVue/filters/drUserTime";
import { exportFieldValueToCell } from "@drVue/store/modules/reporting";
import LastNoteCell from "./cells/LastNoteCell.vue";
import PhaseCell from "./cells/PhaseCell.vue";
import TitleCell from "./cells/TitleCell.vue";

import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { DrVxeTableColumnExportable } from "@drVue/components/types";
import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";
import type { RootState } from "@drVue/store/state";
import type { CustomViewSettings } from "@setups/types";
import type { Store } from "vuex";

export type DealsTableRow = Pick<
  Deal,
  | "id"
  | "uid"
  | "title"
  | "phase"
  | "start_date"
  | "last_note"
  | "custom_data"
  | "room"
>;

export default class TableColumns {
  private viewSettings: CustomViewSettings;

  constructor(
    private $store: Store<RootState>,
    private showPermalink?: boolean,
  ) {
    this.viewSettings =
      $store.state.common?.customViews?.current?.settings || {};
  }

  private inlineEditor: TableInlineEditor = new TableInlineEditor(
    (value, row) => {
      return this.$store.dispatch("clientDashboard/deals/patchDeal", {
        dealId: row.id,
        updates: value,
      });
    },
    "id",
  );

  get customFields(): FieldItem[] {
    /**
     * @todo It's not clear why, but deployment tests fail with an error pointing to this place:
     ```FAIL app/vue/store/modules/client-dashboard/deals/__tests__/reporting.test.ts (6.508 s)
        ● exportToExcel
        TypeError: Cannot read properties of undefined (reading 'can_edit_deals')
     ```
     */
    const isReadOnly = !ORG_MEMBER_DATA.group?.can_edit_deals;

    return this.$store.getters["clientDashboard/customFields/byObjectType"](
      "deal",
    ).map((field: FieldItem) => ({ ...field, isReadOnly }));
  }

  get columns(): DrVxeTableColumnExportable<DealsTableRow>[] {
    const columnsConfig = keyBy(this.viewSettings.columns || [], "field");

    const columns: DrVxeTableColumnExportable<DealsTableRow>[] = [
      this.titleColumn(),
      this.phaseColumn(),
      this.startDateColumn(),
      this.lastNoteTextColumn(),
      this.lastNoteDateAddedColumn(),
      ...this.customFields.map((field) => this.customFieldColumn(field)),
    ];
    if (this.showPermalink) {
      columns.splice(1, 0, this.permalinkColumn());
    }
    // make all columns sortable and restore saved config
    columns.forEach((column) => {
      const columnConfig = columnsConfig[column.field];
      column.sortable = true;
      column.width = columnConfig?.width || column.width;
      column.visible = !columnConfig?.hidden;
    });
    // order column by configs
    return orderBy(
      columns,
      [
        "fixed", // keep fixed element always first
        (column) => {
          return columnsConfig[column.field]?.order;
        },
      ],
      ["asc", "asc"],
    );
  }

  private titleColumn(): DrVxeTableColumnExportable<DealsTableRow> {
    return {
      field: "title",
      title: "Title",
      fixed: "left",
      minWidth: 150,
      width: 285,
      slots: {
        default: ({ row }: any) => {
          return [h(TitleCell, { deal: row })];
        },
      },
      exportToExcel: (deal: Deal) => {
        return deal.title;
      },
      exportWidth: 24,
    };
  }

  private phaseColumn(): DrVxeTableColumnExportable<DealsTableRow> {
    const phaseById =
      this.$store.getters["clientDashboard/phases/getPhaseById"];
    return {
      field: "phase",
      title: "Phase",
      width: 140,
      slots: {
        default: ({ row }: any) => {
          const phase = phaseById(row.phase);
          return [h(PhaseCell, { phase })];
        },
      },
      exportToExcel: (deal: Deal) => {
        const phase = phaseById(deal.phase);
        return phase.name;
      },
      exportWidth: 16,
    };
  }

  private startDateColumn(): DrVxeTableColumnExportable<DealsTableRow> {
    return {
      field: "start_date",
      title: "Start Date",
      width: 140,
      slots: {
        default: ({ row }: any) => {
          const text = drUserTime(row.start_date, "full-date") as string;
          return [text];
        },
      },
      exportToExcel: (deal: Deal) => {
        if (deal.start_date) {
          return {
            type: "date",
            value: drUserTime(deal.start_date, "yyyy-MM-dd"),
          };
        }
        return "";
      },
      exportWidth: 12,
    };
  }

  private lastNoteTextColumn(): DrVxeTableColumnExportable<DealsTableRow> {
    return {
      field: "last_note.body_text",
      title: "Last Note",
      width: 280,
      slots: {
        default: ({ row }: any) => {
          if (!row.last_note) {
            return ["-"];
          }

          return [h(LastNoteCell, { note: row.last_note })];
        },
      },
      exportToExcel: (deal: Deal) => {
        return deal.last_note?.body_text || "";
      },
      exportWidth: 24,
    };
  }

  private lastNoteDateAddedColumn(): DrVxeTableColumnExportable<DealsTableRow> {
    return {
      field: "last_note.date_added",
      title: "Last Note Date",
      width: 170,
      sortBy: ({ row }) => row.last_note?.date_added?.getTime(),
      slots: {
        default: ({ row }: any) => {
          const text = row.last_note
            ? drUserTime(row.last_note.date_added, "short-dt")!
            : "-";
          return [text];
        },
      },
      exportToExcel: (deal: Deal) => {
        if (deal.last_note?.date_added) {
          return {
            type: "datetime",
            value: deal.last_note.date_added.toISOString(),
          };
        }
        return "";
      },
      exportWidth: 16,
    };
  }

  private customFieldColumn(
    field: FieldItem,
  ): DrVxeTableColumnExportable<DealsTableRow> {
    return {
      field: field.key,
      title: field.label,
      width: 140,
      minWidth: field.field_type === FieldItemTypes.MultiSelect ? 200 : 20,
      exportToExcel: (deal: Deal) => {
        const value = deal.custom_data[field.key];
        return exportFieldValueToCell(field, value);
      },
      exportWidth: field.field_type === FieldItemTypes.Text ? 24 : 16,
      slots: {
        default: (params) => {
          const schema = mapCustomFieldToSchema(field);
          return this.inlineEditor.renderInlineField(schema, params);
        },
      },
    };
  }

  private permalinkColumn(): DrVxeTableColumnExportable<DealsTableRow> {
    return {
      field: "room.permalink",
      title: "Link",
      width: 40,
      exportToExcel: (deal: Deal) => {
        return !deal.room || deal.room.is_archived ? "" : deal.room.permalink;
      },
      exportWidth: 40,
    };
  }
}
