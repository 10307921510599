<template>
  <DrPanelWrapper
    v-loading="isLoading"
    :width="width"
    :height="height"
    :title="title"
  >
    <template #subtitle>
      <SearchBar
        v-model="filterText"
        :disabled="viewMode === 'create'"
        :autofocus="autofocus"
      />
      <div v-if="$slots.default" :class="$style.slotContainer">
        <slot />
      </div>
    </template>
    <template #default>
      <NodeForm
        v-if="viewMode === 'create'"
        v-model:name="newNodeName"
        v-model:parent-id="newNodeParentId"
        :tree="parentsTree"
      >
        <template #item="{ item, node }">
          <slot
            name="tree-item"
            v-bind="{ item, node, localValue: [newNodeParentId] }"
          >
            {{ item.name }}
          </slot>
        </template>
      </NodeForm>

      <DrTree
        v-if="viewMode === 'browse'"
        check-strictly
        check-sign-on-right
        :check-on-click-node="true"
        :expand-on-click-node="false"
        :show-checkbox="isMultiple"
        :default-expanded-keys="expandedKeys"
        :filter-text="filterText"
        :data="tree"
        :model-value="localValue"
        @update:model-value="handleTreeModelValueUpdate"
        @reset-filter="filterText = ''"
      >
        <template #item="{ item, node }">
          <TreeItem
            :is-create-visible="item.canParent"
            :is-check-visible="!isMultiple"
            :is-active="localValue.includes(item.id)"
            @create-node="handleEnterCreateMode(item.id)"
          >
            <slot name="tree-item" v-bind="{ item, node, localValue }">
              {{ item.name }}
            </slot>
          </TreeItem>
        </template>
      </DrTree>
    </template>
    <template #footer-left>
      <ElButton
        v-if="!disallowReset"
        v-show="isResetButtonVisible"
        size="small"
        :class="$style.contextButton"
        @click="handlePurgeLocalValue"
      >
        Clear selection ({{ localValue.length }})
        <template #icon>
          <DrIcon name="redo" />
        </template>
      </ElButton>
      <ElButton
        v-show="isBackButtonVisible"
        size="small"
        :class="$style.contextButton"
        @click="newNodeParentId = 0"
      >
        Back
        <template #icon>
          <DrIcon name="chevron-left" />
        </template>
      </ElButton>
    </template>
    <template #footer-right>
      <ElButton size="small" @click="handleCancelDialog">Cancel</ElButton>
      <ElButton
        v-show="isSaveButtonVisible"
        type="primary"
        size="small"
        :disabled="!isDirty"
        :class="$style.dialogButton"
        @click="handleSave"
      >
        {{ submitText }}
      </ElButton>
      <ElButton
        v-show="isCreateButtonVisible"
        type="primary"
        size="small"
        :disabled="!newNodeName || !newNodeParentId"
        :class="$style.dialogButton"
        @click.stop="handleCreateNode"
      >
        Create
      </ElButton>
    </template>
  </DrPanelWrapper>
</template>

<script setup lang="ts">
import { difference } from "lodash-es";
import { xor } from "lodash-es";
import { computed, ref } from "vue";
import { POPUP_SIZES } from "@shared/ui/constants";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrPanelWrapper } from "@shared/ui/dr-panels";
import { DrTree } from "@shared/ui/dr-tree";

import { filterTree } from "@drVue/components/room/tasks/TaskDetails/lists/attachments/utils";
import NodeForm from "./components/NodeForm.vue";
import SearchBar from "./components/SearchBar.vue";
import TreeItem from "./components/TreeItem.vue";

import type { NodeId } from "@drVue/components/room/tasks/shared/tree-browser/types";
import type { Tree } from "@shared/ui/dr-tree";

type TreeProp = Tree<{ canParent: boolean; id: string | number; name: string }>;

interface Props {
  tree: TreeProp;
  selectedNodeIds: NodeId[];
  isMultiple?: boolean;
  width?: number;
  height?: number;
  isLoading?: boolean;
  disallowReset?: boolean;
  disallowEmpty?: boolean;
  submitText?: string;
  autofocus?: boolean | number;
  defaultExpandedKeys?: TreeProp[number]["id"][];
  title?: string;
}

const props = withDefaults(defineProps<Props>(), {
  isMultiple: false,
  width: POPUP_SIZES.tree.width,
  height: POPUP_SIZES.tree.height,
  isLoading: false,
  disallowReset: false,
  disallowEmpty: false,
  submitText: "Save",
  defaultExpandedKeys: () => [],
  title: "",
});

interface Emits {
  (e: "update:selected-node-ids", value: NodeId[]): void;
  (e: "create-node", newNode: { parentId: NodeId; name: string }): void;
  (e: "save", value: (string | number)[]): void;
  (e: "cancel"): void;
}

const emit = defineEmits<Emits>();

const parentsTree = computed(() => {
  return props.tree.map((node) =>
    filterTree<{ canParent: boolean }>(node, (item) => item.canParent),
  );
});

const localValue = ref<NodeId[]>(props.selectedNodeIds);

const expandedKeys = ref([...localValue.value, ...props.defaultExpandedKeys]);

const filterText = ref<string>("");

const newNodeParentId = ref<number>(0);
const newNodeName = ref<string>("");

const viewMode = computed<"browse" | "create">(() =>
  newNodeParentId.value ? "create" : "browse",
);
const isCreateButtonVisible = computed<boolean>(
  () => viewMode.value === "create",
);
const isSaveButtonVisible = computed<boolean>(
  () => viewMode.value === "browse",
);
const isBackButtonVisible = computed<boolean>(
  () => viewMode.value === "create",
);
const isResetButtonVisible = computed<boolean>(
  () => viewMode.value === "browse" && !!localValue.value.length,
);

const isDirty = computed(
  () => xor(localValue.value, props.selectedNodeIds).length > 0,
);

const handleTreeModelValueUpdate = (value: NodeId[]) => {
  if (props.disallowEmpty && !value.length) return;

  if (props.isMultiple) {
    localValue.value = value;
  } else {
    if (value.length === 1) {
      localValue.value = value;
    } else {
      localValue.value = difference(value, localValue.value);
    }
  }
};

const handlePurgeLocalValue = () => {
  localValue.value = [];
};

const handleCreateNode = () => {
  emit("create-node", {
    parentId: newNodeParentId.value,
    name: newNodeName.value,
  });
  expandedKeys.value.push(newNodeParentId.value);
  newNodeParentId.value = 0;
  newNodeName.value = "";
};

const handleCancelDialog = () => {
  emit("cancel");
  newNodeParentId.value = 0;
  newNodeName.value = "";
};

const handleSave = () => {
  emit("save", localValue.value);
};

const handleEnterCreateMode = (id: NodeId) => {
  filterText.value = "";
  newNodeParentId.value = id as number;
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.contextButton {
  margin-left: unset !important;

  &:global(.el-button) {
    --el-button-bg-color: transparent;
    --el-button-border-color: transparent;
    --el-button-disabled-bg-color: transparent;
    --el-button-disabled-border-color: transparent;
    font: typography.$body_regular;
  }
}

.dialogButton {
  width: 65px;
}

.slotContainer {
  margin-top: 8px;
}
</style>
