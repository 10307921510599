<template>
  <AiReportsExpansion
    :class="{
      [$style.row]: true,
    }"
    :opened-class="$style.row_isExpanded"
    :disabled="!reportDocField?.value.excerpts?.length"
    has-caret
  >
    <template #label>
      <div :class="$style.rowLabel">
        {{ reportField.label }}
      </div>
    </template>

    <template v-if="reportDocField" #subtitle>
      <div
        v-if="
          reportDocField.status === AsyncOperationStatuses.Processing ||
          reportDocField.status === AsyncOperationStatuses.Pending
        "
        :class="$style.valuePending"
      >
        <span>Generating</span>
      </div>

      <div
        v-else-if="reportDocField.status === AsyncOperationStatuses.Fail"
        :class="$style.valueFailed"
      >
        <DrIcon
          name="exclamation-triangle"
          size="sm"
          :class="$style.valueFailedIcon"
        />
        Failed to generate
      </div>

      <div
        v-else-if="reportDocField.status === AsyncOperationStatuses.Success"
        :class="$style.rowAnswerText"
      >
        {{ reportDocField.value.shortAnswer }}
      </div>
    </template>

    <template
      v-if="reportDocField?.value.excerpts?.length"
      #actions="{ buttonHiddenClass }"
    >
      <div
        :class="{
          [buttonHiddenClass]: reportDocField.review_status === null,
          [$style.reviewButtons]: true,
        }"
      >
        <ElButton
          text
          size="small"
          :type="reportDocField.review_status === 'LIKE' ? 'success' : ''"
          :class="$style.rowValueAction"
          :disabled="isSubmitting"
          @click.prevent.stop="setReviewStatus('LIKE')"
        >
          <DrIcon name="thumbs-up" size="sm" :class="$style.iconLike" />
        </ElButton>

        <ElButton
          text
          size="small"
          :type="reportDocField.review_status === 'DISLIKE' ? 'danger' : ''"
          :class="$style.rowValueAction"
          :disabled="isSubmitting"
          @click.prevent.stop="setReviewStatus('DISLIKE')"
        >
          <DrIcon name="thumbs-down" size="sm" :class="$style.iconDislike" />
        </ElButton>
      </div>
    </template>

    <template v-if="reportDocField?.value.excerpts?.length" #default>
      <div :class="$style.rowExcerpts">
        <div :class="$style.rowExcerptsTitle">Excerpts</div>
        <ul :class="$style.rowExcerptsText">
          <li
            v-for="(excerpt, index) in reportDocField.value.excerpts"
            :key="index"
          >
            <div>
              {{ excerpt.snippet }}
              <a
                v-if="excerpt.page"
                :href="getExcerptLink(excerpt.page)"
                @click="(e) => emitPageNavigation(e, excerpt.page)"
                >Page {{ excerpt.page }}</a
              >
            </div>
          </li>
        </ul>
      </div>
    </template>
  </AiReportsExpansion>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { DrIcon } from "@shared/ui/dr-icon";

import { documentViewUrl } from "@setups/room-urls";
import { AiReportsEvent, insightTrack } from "@app/insight";
import { ROOM_DATA } from "@app/setups";
import { $notifyDanger } from "@app/vue/common";
import { AsyncOperationStatuses } from "@app/vue/store/modules/room/user-async-operation/AsyncOperationApiService";
import AiReportsExpansion from "./AiReportsExpansion.vue";
import { AiReportsApiService } from "./api";
import { usePageNavigationBus } from "./usePageNavigationBus";

import type {
  AiDocReport,
  AiDocReportField,
  AiReport,
  AiReportField,
} from "./types";

interface Props {
  docId: number;
  docUid: string;
  report: AiReport;
  reportField: AiReportField;
  reportDoc?: AiDocReport;
  reportDocField?: AiDocReportField;
}

interface Emit {
  (e: "like"): void;
  (e: "dislike"): void;
  (e: "update:report-doc-field", value: AiDocReportField): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emit>();

const isSubmitting = ref(false);

const api = new AiReportsApiService();
const pageNavigationBus = usePageNavigationBus();

const getExcerptLink = (page: number) =>
  documentViewUrl(ROOM_DATA.url, props.docId, undefined, page);

const emitPageNavigation = (e: MouseEvent, page: number) => {
  if ((e.metaKey || e.ctrlKey) && e.button === 0) return;
  if (e.button !== 0) return;

  e.preventDefault();

  pageNavigationBus.emit(undefined as never, {
    page,
    docId: props.docId,
  });
};

const setReviewStatus = (review_status: AiDocReportField["review_status"]) => {
  if (
    !props.reportDocField ||
    !props.reportDoc ||
    isSubmitting.value ||
    props.reportDocField.review_status === review_status
  )
    return;

  isSubmitting.value = true;

  api
    .updateReportDocField(
      props.docUid,
      props.reportDoc.id,
      props.reportDocField.id,
      { review_status },
    )
    .then((response) => {
      emit("update:report-doc-field", response);

      insightTrack(
        review_status === "LIKE"
          ? AiReportsEvent.FieldReviewSetLike
          : AiReportsEvent.FieldReviewSetDislike,
        {
          field: props.reportField.label,
          shortAnswer: response.value.shortAnswer ?? "",
        },
      );
    })
    .catch(() => {
      $notifyDanger("Failed to send feedback");
    })
    .finally(() => {
      isSubmitting.value = false;
    });
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.row {
  border-top: 1px solid colors.$pr-150;
}

.row_hoverable:hover,
.row_isExpanded {
  background-color: colors.$pr-50;
}

.rowLabel {
  font: typography.$body_semibold;
}

.rowValue {
  display: flex;
  align-items: flex-start;
}

.rowValueActions {
  opacity: 0;

  .row:hover & {
    opacity: 1;
  }
}

.reviewButtons {
  display: flex;
}

.rowValueAction:global(.el-button) {
  margin: 0;
  padding: 2px spacing.$xxs;

  &:hover {
    &:not(.is-disabled) {
      background: none;
    }

    .iconLike div::before {
      color: colors.$ad-3;
    }

    .iconDislike div::before {
      color: colors.$ad-12;
    }
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dots {
  0% {
    content: "";
  }
  20% {
    content: ".";
  }
  50% {
    content: "..";
  }
  80% {
    content: "...";
  }
}

.valuePending {
  display: flex;
  gap: 6px;
  align-items: center;
  color: colors.$pr-400;

  &:before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 2px solid colors.$pr-400;
    border-bottom-color: transparent;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotation 800ms linear infinite;
  }

  span:after {
    content: "";
    display: inline;
    animation: dots 1.4s steps(3, end) infinite;
  }
}

.valueFailed {
  color: colors.$pr-500;
}

.valueFailedIcon {
  color: colors.$ad-10_5;
}

.rowAnswerText {
  white-space: pre-wrap;
  cursor: pointer;
}

$excerptsPadding: 12px;

.rowExcerpts {
  padding: $excerptsPadding spacing.$s;
  margin: 0 $excerptsPadding $excerptsPadding #{-$excerptsPadding};
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid colors.$pr-200;
  color: colors.$pr-900;
}

.rowExcerptsTitle {
  font: typography.$body_semibold;
  padding-bottom: spacing.$xxs;
}

.rowExcerptsText {
  padding-left: 20px;
  list-style-type: disc;
}
</style>
