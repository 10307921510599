import { createDictionary } from "@drVue/common";

import type { TaskReviewer } from "@drVue/store/pinia/room/tasks";

type UserId = number;

export const reviewersContains = (
  reviewers: TaskReviewer[],
  ids: UserId[],
  needReviewOnly: boolean,
) => {
  const dict = reviewers.reduce((dict, reviewer) => {
    dict[reviewer.user_id] = reviewer;
    return dict;
  }, createDictionary<TaskReviewer>());

  return ids.some((id) => {
    const isReviewer = !!dict[id];
    if (!isReviewer) return false;

    if (needReviewOnly) {
      return !dict[id].mark_complete;
    }

    return true;
  });
};
