<template>
  <div class="billing-plan-info__container" :class="{ active: active }">
    <div class="billing-plan-info__header">
      <div class="billing-plan-info__title">
        {{ plan.title }}
      </div>
      <div class="billing-plan-info__price-value">
        ${{ plan.information.price_month }}
      </div>
      <div class="billing-plan-info__price-label">per month</div>
      <div class="billing-plan-info__interval">
        Billed {{ intervalLabel(plan.interval) }}
      </div>
    </div>
    <div class="billing-plan-info__features">
      <div
        v-for="(feature, index) in plan.information.features"
        :key="index"
        class="billing-plan-info__feature"
      >
        {{ feature }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { intervalLabel } from "./utils";

import type { Plan } from "@drVue/api-service/common/billing";
import type { PropType } from "vue";

export default defineComponent({
  name: "PlanInfo",
  props: {
    plan: { required: true, type: Object as PropType<Plan> },
    active: { required: true, type: Boolean as PropType<boolean> },
  },
  methods: {
    intervalLabel(inverval: string) {
      return intervalLabel(inverval);
    },
  },
});
</script>
