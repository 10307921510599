import documents_downloadDisallowedInfoHtml from "../../../templates/documents/download-disallowed-info.html?raw";

export default function drDownloadDisallowedInfo() {
  return {
    bindings: {
      btnText: "@",
      type: "@",
    },
    controller: function () {
      const $ctrl = this;
      $ctrl.errorMessages = {
        disabled: "Bulk download has been disabled by room administrator.",
        bulkDisallowed:
          "Please contact a room administrator to download documents or folders from this folder.",
        disallowed:
          "Please contact a room administrator to download selected documents or folders.",
      };
    },
    template: documents_downloadDisallowedInfoHtml,
  };
}
