<template>
  <a href="" class="dropdown-toggle" @click="openContextMenu($event)"
    ><i class="fas fa-ellipsis-h"
  /></a>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { PropType } from "vue";

export default defineComponent({
  name: "MenuCell",
  props: {
    table: { required: true, type: Object as PropType<any> },
    row: { required: true, type: Object as PropType<any> },
  },
  methods: {
    openContextMenu(e: MouseEvent) {
      this.table.handleGlobalContextmenuEvent(e);
    },
  },
});
</script>
