<template>
  <ElDrawer
    v-model="isVisible"
    append-to-body
    :with-header="false"
    class="el-drawer--close"
    size=""
    @close="handleClose()"
  >
    <DrDrawerClose @close="isVisible = false" />
    <DrLayout2ColumnPanel>
      <template #left>
        <div :class="$style.left">
          <div :class="$style.header">
            <DrCopy :text="taskUrl(ROOM_DATA.url, localTask.key)">
              ID {{ localTask.key }}
            </DrCopy>
            <TaskPath
              :category-id="localTask.category_id"
              @change-category="onSubmit"
            />
            <ContextMenu
              :is-following="isFollowing"
              :is-archived="isTaskArchived"
              @follow="handleFollow"
              @unfollow="handleUnfollow"
              @nav-forward="handleNavForward"
              @nav-back="handleNavBack"
              @delete="deleteRequest"
              @restore="restoreRequest"
            />
          </div>
          <ElDivider :class="$style.dividerEmphasized" />
          <DrBalloon v-if="isTaskArchived" :class="$style.archivedBalloon">
            <template #icon>
              <DrIcon name="archive" />
            </template>
            <span>Request is archived</span>
            <template v-if="canRestoreRequest" #action>
              <DrBalloonAction @click="restoreRequest">Restore</DrBalloonAction>
            </template>
          </DrBalloon>
          <FindingsIndicators
            v-if="isFindingsEnabled && hasFindings && !isTaskArchived"
          />

          <div :class="$style.title">
            <DynamicForm
              :entity="localTask"
              :schema="taskSchemaTitle"
              :submit-fn="onSubmit"
            />
          </div>

          <div ref="dropZoneWrapperRef" :class="$style.dropZone">
            <div v-show="isOverDropZone" :class="$style.dropZoneOverlay">
              <DrIcon name="download" size="lg" />
              <span>Drop your files here to attach them to this request.</span>
            </div>

            <div :class="{ [$style.dropZoneContent_isHidden]: isOverDropZone }">
              <DynamicForm
                :entity="localTask"
                :schema="taskSchemaDescription"
                :submit-fn="onSubmit"
              />
              <ElDivider :class="$style.dividerLarge" />

              <AttachmentsList
                :file-ids="localTask.documents"
                :folder-ids="localTask.folders"
                :task-id="localTask.id"
                :view-only="isTaskArchived || !canManageTasks"
                @upload="(files) => (uploadItems = files)"
              />

              <ElDivider v-if="isFindingsEnabled" :class="$style.divider" />
              <FindingList
                v-if="isFindingsEnabled"
                :task-uid="localTask.uid"
                :view-only="isTaskArchived"
              />

              <ElDivider v-if="isSynergiesEnabled" :class="$style.divider" />
              <ValueDriversList
                v-if="isSynergiesEnabled"
                :task-uid="localTask.uid"
                :view-only="isTaskArchived"
              />

              <ElDivider :class="$style.divider" />
              <TaskList
                title="Blocked by"
                :task-ids="localTask.dependencies"
                :filter-method="(t: Task) => t.id !== localTask.id"
                :allow-change="!isTaskArchived && canManageTasks"
                :allow-remove="!isTaskArchived && canManageTasks"
                @click="(task) => handleDepClick(task, 'blocked_by')"
                @change="handleDependenciesChange"
                @remove="
                  (dep) => handleDependencyRemove(localTask, dep, 'blocked_by')
                "
              />

              <ElDivider :class="$style.divider" />
              <TaskList
                title="Blocking"
                :task-ids="dependants"
                :allow-remove="!isTaskArchived && canManageTasks"
                @click="(task) => handleDepClick(task, 'blocking')"
                @remove="
                  (dep) => handleDependencyRemove(dep, localTask, 'blocking')
                "
              />
            </div>
          </div>

          <ElDivider :class="$style.dividerLarge" />
          <TaskComments :task-id="localTask.id" :view-only="isTaskArchived" />
        </div>
      </template>

      <template v-if="canManageTasks" #right>
        <ReviewAction
          v-if="isCurrentUserReviewer"
          :reviewed="isReviewedByCurrentUser"
          :disabled="isReviewButtonDisabled"
          @click="handleMarkReviewed"
        />
        <ElDivider
          v-if="isCurrentUserReviewer"
          :class="$style.dividerEmphasized"
        />
        <div
          v-if="
            localTask.assignees || localTask.reviewers || localTask.followers
          "
          :class="$style.participants"
        >
          <AssigneesList
            v-if="localTask.assignees"
            :view-only="isTaskArchived"
            :assignee-ids="localTask.assignees"
            :reminded-ids="localTask.users_reminded"
            :task-id="localTask.id"
            @remind="remindParticipant"
          />
          <ReviewersList
            v-if="localTask.reviewers"
            :view-only="isTaskArchived"
            :reviewers="localTask.reviewers"
            :reminded-ids="localTask.users_reminded"
            :task-id="localTask.id"
            @remind="remindParticipant"
          />
          <FollowersList
            v-if="localTask.followers"
            :view-only="isTaskArchived"
            :follower-ids="localTask.followers"
            :task-id="localTask.id"
          />
        </div>
        <ElDivider :class="$style.divider" />
        <TaskMetadataForm :task="localTask" :submit-fn="onSubmit" />
      </template>
    </DrLayout2ColumnPanel>

    <DrUploadDialog
      v-if="uploadItems?.length"
      :items="uploadItems"
      :attach-to-request-id="localTask.id"
      @closed="clearUploadItems"
    />
  </ElDrawer>
</template>

<script lang="ts" setup>
import { ElMessageBox } from "element-plus";
import { difference } from "lodash-es";
import { differenceBy } from "lodash-es";
import { computed, onBeforeUnmount, onMounted, ref, watchEffect } from "vue";
import { DrBalloon, DrBalloonAction } from "@shared/ui/dr-balloon";
import { DrCopy } from "@shared/ui/dr-copy";
import { DrDrawerClose } from "@shared/ui/dr-drawer-close";
import { validateString } from "@shared/ui/dr-dynamic-form/utils";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrLayout2ColumnPanel } from "@shared/ui/dr-layouts";
import DrUploadDialog from "@shared/ui/dr-upload-dialog/DrUploadDialog.vue";

import {
  APP_SETTINGS,
  ROOM_DATA,
  ROOM_MEMBER_DATA,
  USER_DATA,
} from "@setups/data";
import { TaskFieldAccessType } from "@setups/enums";
import { taskUrl } from "@setups/room-urls";
import {
  insightTrack,
  OrgSettingsCustomFieldsTrackingEvent,
  TaskDetailsTrackingEvent,
} from "@app/insight";
import { isCategory } from "@app/ng/tasks/services/helpers/getItemType";
import { CustomViewObjectTypes } from "@app/setups/types";
import { DrStore } from "@app/vue";
import { serializeCustomData } from "@drVue/api-service/parse";
import { $notifyDanger, $notifySuccess } from "@drVue/common";
import DynamicForm from "@drVue/components/client-dashboard/dynamic-form/DynamicForm.vue";
import EditTextField from "@drVue/components/client-dashboard/dynamic-form/Fields/Edit/Text.vue";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import RichTextEditField from "@drVue/components/room/findings/components/RichTextEditField.vue";
import RichTextViewField from "@drVue/components/room/findings/components/RichTextViewField.vue";
import { pinia } from "@drVue/store/pinia";
import { useFindingsStatusesStore } from "@drVue/store/pinia/pipeline/findings-statuses";
import { useFindingsStore } from "@drVue/store/pinia/room/findings";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import { useTasksArchivedStore } from "@drVue/store/pinia/room/tasksArchived/tasksArchived";
import { useTasksTableStore } from "@drVue/store/pinia/room/tasksTable/tasksTable";
import { useDropZone } from "@drVue/utils/useDropZone";
import { TitleView } from "./fields/title";
import TaskMetadataForm from "./forms/TaskMetadataForm.vue";
import AssigneesList from "./lists/assignees/AssigneesList.vue";
import AttachmentsList from "./lists/attachments/AttachmentList.vue";
import FindingList from "./lists/findings/FindingList.vue";
import FollowersList from "./lists/followers/FollowersList.vue";
import ReviewersList from "./lists/reviewers/ReviewersList.vue";
import TaskList from "./lists/tasks/TaskList.vue";
import ValueDriversList from "./lists/valueDrivers/ValueDriversList.vue";
import ContextMenu from "./widgets/ContextMenu.vue";
import FindingsIndicators from "./widgets/FindingsIndicators.vue";
import ReviewAction from "./widgets/ReviewAction.vue";
import TaskComments from "./widgets/TaskComments.vue";
import TaskPath from "./widgets/TaskPath.vue";

import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { FormSchema } from "@drVue/components/client-dashboard/dynamic-form/types";
import type { CustomDataType } from "@drVue/store/modules/client-dashboard/fields/types";
import type { RoomMember } from "@drVue/store/modules/room/members/RoomMembersApiService";
import type { Finding } from "@drVue/store/pinia/room/findings";
import type { ParticipantUserV1, Task } from "@drVue/store/pinia/room/tasks";
import type { UploadItem } from "@drVue/utils/useDropZone";

interface Props {
  task: Task;
}

const props = defineProps<Props>();

interface Emits {
  (e: "close"): void;
}

const emit = defineEmits<Emits>();

const isFindingsEnabled = ROOM_DATA.userPermissions.isFindingsAccessible;
const isSynergiesEnabled =
  APP_SETTINGS.WEBSITE.IS_DEALROOM &&
  (ROOM_DATA.synergySettings?.enable ?? false) &&
  ROOM_MEMBER_DATA.group.synergies_access !== TaskFieldAccessType.NoAccess;

const dropZoneWrapperRef = ref<HTMLDivElement | null>(null);

const { isOverDropZone } = useDropZone(dropZoneWrapperRef, {
  onDrop: (promise) => {
    promise.then((items: UploadItem[]) => {
      uploadItems.value = items;
      insightTrack(TaskDetailsTrackingEvent.AttachmentDrop);
    });
  },
});

const tasksStore = useTasksStore(pinia);
const tasksArchivedStore = useTasksArchivedStore(pinia);
const tasksTableStore = useTasksTableStore(pinia);
const findingsStore = useFindingsStore(pinia);
const findingsStatusesStore = useFindingsStatusesStore(pinia);

// When you set it to `false`, <ElDrawer> will be closed and `handleClose()` will be called.
const isVisible = ref(true);

insightTrack(TaskDetailsTrackingEvent.OpenModal);

watchEffect(() => {
  window.DR.isNewRequestDetailsOpened = isVisible.value;
});

// We use props.task because it is already loaded on the Angular's side, which
// allows the modal to be displayed more quickly.
//
// When tasksStore or tasksArchivedStore have finished loading, we will replace
// props.task with the corresponding item from the store.
//
// This is because tasksTableStore is a combination of both stores.
const externalTask = ref<Task>(props.task);
const localTask = computed(
  (): Task =>
    (tasksTableStore.itemsDict[`task_${externalTask.value.id}`] as Task) ??
    externalTask.value,
);

const uploadItems = ref<any[] | null>(null);
const clearUploadItems = () => {
  uploadItems.value = null;
};

const isTaskArchived = computed(() => localTask.value.is_archived);
const isReviewButtonDisabled = ref(false);

const canManageTasks = computed(() => ROOM_DATA.userPermissions.canManageTasks);

const canRestoreRequest = computed(
  () =>
    ROOM_DATA.userPermissions.administrator &&
    // `canDeleteTasks` will be true if `userPermissions.administrator` is true,
    // but I want to add it anyway to make it clearer.
    ROOM_DATA.userPermissions.canDeleteTasks &&
    isTaskArchived.value,
);

const isFollowing = computed(
  () =>
    !!localTask.value.followers?.find(
      ({ user_id }) => user_id === USER_DATA.id,
    ),
);

const findings = computed<Finding[]>(
  () => findingsStore.listByTaskUid[localTask.value.uid] ?? [],
);

const hasFindings = computed<boolean>(() =>
  findings.value.some(
    ({ status_id }) => findingsStatusesStore.dict[status_id].type !== "closed",
  ),
);

const isCurrentUserReviewer = computed<boolean>(
  () =>
    !isTaskArchived.value &&
    !!localTask.value.reviewers?.find(
      ({ user_id }) => user_id === USER_DATA.id,
    ),
);

const isReviewedByCurrentUser = computed<boolean>(
  () =>
    !!localTask.value.reviewers?.find(({ user_id }) => user_id === USER_DATA.id)
      ?.mark_complete,
);

const dependencies = computed(() =>
  localTask.value.dependencies.map((id) => tasksStore.tasks[id]),
);

const dependants = computed(
  () => tasksStore.dependantsMap[localTask.value.id]?.map((t) => t.id) ?? [],
);

type DependencyEventSource = "blocked_by" | "blocking";

const handleDepClick = (task: Task, source: DependencyEventSource) => {
  insightTrack(TaskDetailsTrackingEvent.DependencyOpened, { source });

  externalTask.value = task;
  window.location.hash = `#/tasks/${task.key}`;
};

const handleDependenciesChange = async (payload: Task["uid"][]) => {
  // TODO: Remove this unnecessary conversion between `uid` and `id`.
  const toAdd = payload.map((uid) => tasksStore.tasksByUid[uid]);
  const toRemove = differenceBy(dependencies.value, toAdd, "uid");

  await tasksStore.updateDependencies(
    localTask.value.id,
    toAdd.map(({ id }) => ({ task_id: id })),
    toRemove.map(({ id }) => ({ task_id: id })),
  );

  if (toAdd.length)
    insightTrack(TaskDetailsTrackingEvent.DependencyAdded, {
      count: toAdd.length.toString(),
      source: "blocked_by",
    });
  if (toRemove.length)
    insightTrack(TaskDetailsTrackingEvent.DependencyRemoved, {
      count: toRemove.length.toString(),
      source: "blocked_by",
    });
};

const handleDependencyRemove = async (
  parent: Task,
  child: Task,
  source: DependencyEventSource,
) => {
  await tasksStore.updateDependencies(parent.id, [], [{ task_id: child.id }]);

  insightTrack(TaskDetailsTrackingEvent.DependencyRemoved, {
    count: "1",
    source,
  });
};

const handleNavBack = () => {
  const taskUid = localTask.value.uid;
  const taskCount = tasksTableStore.items.length;
  const currIndex = tasksTableStore.items.findIndex(
    ({ uid }) => uid === taskUid,
  );

  let prevIndex = currIndex - 1 === 0 ? taskCount - 1 : currIndex - 1;
  while (isCategory(tasksTableStore.items[prevIndex])) prevIndex--;

  externalTask.value = tasksTableStore.items[prevIndex] as Task;
  history.pushState(null, "", `#/tasks/${localTask.value.key}`);
  insightTrack(TaskDetailsTrackingEvent.NavBack);
};

const handleNavForward = () => {
  const taskUid = localTask.value.uid;
  const taskCount = tasksTableStore.items.length;
  const currIndex = tasksTableStore.items.findIndex(
    ({ uid }) => uid === taskUid,
  );

  let nextIndex = currIndex + 1 === taskCount ? 0 : currIndex + 1;
  while (isCategory(tasksTableStore.items[nextIndex])) nextIndex++;

  externalTask.value = tasksTableStore.items[nextIndex] as Task;
  history.pushState(null, "", `#/tasks/${localTask.value.key}`);
  insightTrack(TaskDetailsTrackingEvent.NavForward);
};

const handleFollow = async () => {
  const currentUserRoomMember = DrStore.getters[
    "room/members/currentUserRoomMember"
  ] as RoomMember;

  await tasksStore.updateFollowers(
    localTask.value.id,
    [{ user_id: currentUserRoomMember.uid }],
    [],
  );
  insightTrack(TaskDetailsTrackingEvent.Follow);
};

const handleUnfollow = async () => {
  const currentUserRoomMember = DrStore.getters[
    "room/members/currentUserRoomMember"
  ] as RoomMember;

  await tasksStore.updateFollowers(
    localTask.value.id,
    [],
    [{ user_id: currentUserRoomMember.uid }],
  );
  insightTrack(TaskDetailsTrackingEvent.Unfollow);
};

const deleteRequest = async () => {
  ElMessageBox.alert(
    "Are you sure you want to archive this request?",
    "Archive request",
    {
      type: "warning",
      confirmButtonClass: "el-button--danger",
      confirmButtonText: "Archive",
      showCancelButton: true,
      showClose: false,
      cancelButtonText: "No",
      closeOnHashChange: false,
      closeOnPressEscape: true,
      beforeClose: async (action, instance, done) => {
        if (action === "confirm") {
          instance.showCancelButton = false;
          instance.confirmButtonLoading = true;

          const taskId = localTask.value.id;
          tasksStore
            .deleteTasksV2([localTask.value.uid])
            .then(() => {
              insightTrack(TaskDetailsTrackingEvent.Archived);

              // We need to close the modal if a user doesn't have rights to view
              // Archived tasks (only administrators have such rights).
              if (!ROOM_DATA.userPermissions.administrator) {
                externalTask.value.is_archived = true;
                isVisible.value = false;

                return;
              }

              // Now we know that the user is administrator and has rights to view
              // Archived tasks.
              //
              // We CAN update the `task` value via `tasksStore.tasks[taskId]`.
              externalTask.value = tasksArchivedStore.tasks[taskId];
            })
            .then(
              () => $notifySuccess("Request has been archived"),
              () => $notifyDanger("Failed to archive request"),
            )
            .finally(() => {
              done();
            });
        } else {
          done();
        }
      },
    },
  ).catch(() => {});
};

const restoreRequest = () => {
  const taskId = localTask.value.id;
  tasksStore
    .restoreTasksV2([localTask.value.uid])
    .then(
      () => {
        $notifySuccess("Request has been restored");
        insightTrack(TaskDetailsTrackingEvent.Restored);
      },
      () => $notifyDanger("Failed to restore request"),
    )
    .then(() => {
      externalTask.value = tasksStore.tasks[taskId];
    });
};

const handleMarkReviewed = async () => {
  try {
    isReviewButtonDisabled.value = true;
    await tasksStore.setIsReviewed(
      localTask.value.id,
      !isReviewedByCurrentUser.value,
    );
  } catch {
    $notifyDanger("Failed to mark task as reviewed");
  } finally {
    isReviewButtonDisabled.value = false;
  }
};

// TODO: Remove this workaround after migrating to Vue.
//
// When `isVisible` is set to false, the modal is closed on the Vue side.
// However, the underlying Angular component remains active.
// To ensure a complete closure, we also need to deactivate the Angular component.
// This is achieved by emitting a `close` event.
//
// A delay is necessary to allow for the Vue component's closure actions to
// complete before deactivating the Angular component.
const handleClose = () => {
  window.DR.isNewRequestDetailsOpened = false;

  // Even though we think that onBeforeUnmount() should be called, it is better
  // to be safe than sorry.
  window.removeEventListener("popstate", handlePopState);

  // Hide the modal in Vue.
  isVisible.value = false;

  tasksStore.updateTaskLastVisitDate(localTask.value.id);

  // Set a timeout to allow the Vue component to fully process the closure.
  setTimeout(() => {
    // emit("close") ->
    // TaskDetailVueCtrl.onClose() ->
    // TaskDetailVueCtrl.$scope.$close() ($scope.$close === modalInstance.$close()) ->
    // [TasksService.modalInstance.result.resolve()]
    // TasksService.modalInstance.result.finally()
    emit("close");
  }, 300);
};

const taskSchemaTitle = computed(
  (): FormSchema => ({
    fields: [
      {
        type: FieldSchemaType.Custom,
        isReadOnly: isTaskArchived.value || !canManageTasks.value,
        prop: "title",
        placeholder: "Specify title",
        rules: validateString()
          .required(`"Title" is required`)
          .min(8, `"Title" must be at least 8 characters`),
        viewComponent: TitleView,
        editComponent: EditTextField,
      },
    ],
  }),
);

const taskSchemaDescription = computed(
  (): FormSchema => ({
    fields: [
      {
        type: FieldSchemaType.Custom,
        isReadOnly: isTaskArchived.value || !canManageTasks.value,
        prop: "description",
        placeholder: "Add description",
        viewComponent: RichTextViewField,
        editComponent: RichTextEditField,
        extra: {
          rows: 14,
        },
      },
    ],
  }),
);

const taskCustomFields = computed((): FieldItem[] => {
  return DrStore.getters["clientDashboard/customFields/byObjectType"](
    CustomViewObjectTypes.Task,
  );
});

const onSubmit = async (changes: Partial<Task>) => {
  if (Array.isArray(changes.labels)) {
    const labelsToAdd = difference(changes.labels, localTask.value.labels).map(
      (id) => ({ label_key_id: id }),
    );
    const labelsToRemove = difference(
      localTask.value.labels,
      changes.labels,
    ).map((id) => ({ label_key_id: id }));

    await tasksStore.updateLabels(
      localTask.value.id,
      labelsToAdd,
      labelsToRemove,
    );
    externalTask.value = tasksStore.tasks[localTask.value.id];

    if (labelsToAdd.length) {
      insightTrack(TaskDetailsTrackingEvent.LabelsAdded);
    }
    if (labelsToRemove.length) {
      insightTrack(TaskDetailsTrackingEvent.LabelsRemoved);
    }

    return;
  }

  const isChangingCustomData = "custom_data" in changes;
  if (isChangingCustomData) {
    serializeCustomData(
      changes.custom_data as CustomDataType,
      taskCustomFields.value,
    );

    const customFieldKey = Object.keys(
      changes.custom_data as CustomDataType,
    )[0];
    const customFieldInfo = taskCustomFields.value.find(
      (field) => field.key === customFieldKey,
    );

    if (customFieldInfo) {
      insightTrack(OrgSettingsCustomFieldsTrackingEvent.FieldValueChanged, {
        source: "details",
        object_type: customFieldInfo.object_type,
        type: customFieldInfo.field_type,
        name: customFieldInfo.label,
      });
    }
  }

  await tasksStore.patchTaskV2(localTask.value.uid, changes);
  externalTask.value = tasksStore.tasks[localTask.value.id];

  if ("category_id" in changes) {
    insightTrack(TaskDetailsTrackingEvent.CategoryChanged);
  }
};

const handlePopState = () => {
  const hash = location.hash;
  if (!hash.startsWith("#/tasks/")) {
    // Somehow, the hash has changed to something else.
    // Close the modal.
    // We still can be on '#/tasks/list/overview' route.
    handleClose();
    return;
  }

  /**
   * The regex matches hash fragments in the format of "#/tasks/[Prefix-][Digits]".
   * `(?:[A-Za-z]+-)?` is a non-capturing group that matches an optional "Prefix-" part.
   *   - `[A-Za-z]+` matches one or more alphabetical characters (the prefix).
   *   - The prefix, if present, is followed by a dash `-`.
   *   - The entire prefix part is optional due to the `?` quantifier.
   * `(\d+)` is a capturing group that matches one or more digits, representing the task key.
   */
  const match = hash.match(/#\/tasks\/(?:[A-Za-z]+-)?(\d+)/);
  if (match === null) {
    // We haven't found a task key in the hash.
    // We probably on '#/tasks/list/overview' route or on '#/tasks/<whatever>' route.
    isVisible.value = false;
    return;
  }

  const urlTaskKey = match[1];

  const task = tasksStore.tasksList.find(
    (item) => !isCategory(item) && item.key === urlTaskKey,
  ) as Task | undefined;
  if (task) {
    externalTask.value = task;
    return;
  }

  const archivedTask = tasksArchivedStore.tasksList.find(
    (item) => !isCategory(item) && item.key === urlTaskKey,
  ) as Task | undefined;
  if (archivedTask) externalTask.value = archivedTask;
};

const remindParticipant = (participant: ParticipantUserV1) => {
  tasksStore.remindTaskParticipant(localTask.value.id, participant);
};

onMounted(() => {
  window.addEventListener("popstate", handlePopState);
});

onBeforeUnmount(() => {
  window.removeEventListener("popstate", handlePopState);
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";
@use "@app/styles/scss/spacing";

.left {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.loader {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

.header {
  display: grid;
  grid-template-columns: 62px auto 96px;
  gap: spacing.$xs;
  align-items: center;
  margin: -2px 0 0 -8px;
}

.participants {
  display: flex;
  flex-direction: column;
  gap: spacing.$s;
}

.divider:global(.el-divider--horizontal) {
  border-color: colors.$pr-100;
  margin: 8px 0;
}

.dividerLarge:global(.el-divider--horizontal) {
  border-color: colors.$pr-100;
  width: calc(100% + 48px);
  margin: 12px -24px;
}

.dividerEmphasized:global(.el-divider--horizontal) {
  border-color: colors.$pr-150;
  margin: 12px 0;
}

.dropZone {
  position: relative;
}

.dropZoneContent_isHidden {
  opacity: 0;
  height: 604px;
}

.dropZoneOverlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background: colors.$sc-50;
  border-radius: 8px;
  border: 1px dashed colors.$sc-400;
  color: colors.$sc-600;
  font: typography.$body_medium;

  span {
    text-align: center;
    width: 243px;
  }
}

.archivedBalloon {
  margin-bottom: 16px;
}
</style>
