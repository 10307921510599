import { nextTick } from "vue";
import { VxeLoading, VxeMenu, VxeTooltip, VxeUI } from "vxe-pc-ui";
import { VxeColgroup, VxeColumn, VxeGrid, VxeTable } from "vxe-table";
import enUS from "vxe-table/lib/locale/lang/en-US";

import type { App } from "vue";
import type { VxeTableDefines } from "vxe-table";

VxeUI.setI18n("en-US", enUS);
VxeUI.setLanguage("en-US");

VxeUI.hooks.add("$setupGrid", {
  setupGrid($grid) {
    const loadColumn = $grid.loadColumn;
    $grid.loadColumn = (columns) => {
      // remember sort state
      const sortColumns = $grid.getSortColumns();

      let beforeColumnUpdateSortState: VxeTableDefines.SortConfs[] | undefined =
        undefined;
      if (sortColumns && sortColumns.length) {
        beforeColumnUpdateSortState = sortColumns.map((scol) => ({
          field: scol.property,
          order: scol.order,
        }));
      }

      // update columns
      const result = loadColumn.call(this, columns);
      // restore columns sort state
      nextTick(() => {
        if (beforeColumnUpdateSortState) {
          $grid.sort(beforeColumnUpdateSortState).then();
        }
      }).then();

      return result;
    };
  },
});

export function installVxeTable(app: App) {
  app.use(VxeColgroup);
  app.use(VxeColumn);
  app.use(VxeGrid);
  app.use(VxeTable);

  app.use(VxeLoading);
  app.use(VxeMenu);
  app.use(VxeTooltip);
}
