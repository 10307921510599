<template>
  <div :class="$style.nav">
    <!-- @vue-skip -->
    <RoomTasksTabsNav v-if="isTaskListNavEnabled()" :active-tab="activeTab" />
    <RoomHeaderButton
      v-for="tab in tabs"
      :key="tab.url"
      :active="tab.id === activeTab"
      :tab="tab"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";

import RoomHeaderButton from "./RoomHeaderButton.vue";
import RoomTasksTabsNav from "./RoomTasksTabsNav.vue";
import {
  getActiveTabId,
  getBaseRoomNavTabs,
  isTaskListNavEnabled,
} from "./utils";

const store = useStore();

const tabs = computed(() => {
  return getBaseRoomNavTabs();
});
const activeTab = computed(() => {
  // @ts-ignore
  return getActiveTabId(store);
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/_colors";
@use "@app/styles/scss/typography" as typo;

.nav {
  display: flex;
  gap: 20px;
  height: 100%;
  align-items: center;
}
</style>
