<template>
  <div v-loading="loading" :class="$style.container">
    <AiReportsTitle
      title="Summary templates"
      description="Use pre-built templates to create a summary, which you can edit later."
    />

    <div :class="$style.reportList">
      <div v-if="errorMessage">Failed to load report templates.</div>

      <div v-else-if="data.length === 0">
        No report templates are available.
      </div>

      <template v-else>
        <AiReportsSuggestionCard
          v-for="report in data"
          :key="report.key"
          :report="report"
          @open:report="$emit('open:report', $event)"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from "vue";

import AiReportsSuggestionCard from "./AiReportsSuggestionCard.vue";
import AiReportsTitle from "./AiReportsTitle.vue";
import { AiReportsApiService } from "./api";

import type { AiReport, AiReportSuggestion } from "./types";

interface Emits {
  (e: "open:report", value: AiReport): void;
}

defineEmits<Emits>();

const api = new AiReportsApiService();

const loading = ref(false);
const errorMessage = ref<string | null>(null);
const data = ref<AiReportSuggestion[]>([]);

const load = () => {
  loading.value = true;
  errorMessage.value = null;

  api
    .loadReportsSuggestion()
    .then((response) => {
      data.value = response;
    })
    .catch((error) => {
      errorMessage.value =
        error.response?.data?.non_field_errors?.[0] ||
        "Failed to load AI suggested summary templates.";
    })
    .finally(() => {
      loading.value = false;
    });
};

onBeforeMount(() => {
  load();
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.container {
  margin-top: 22px;
  padding-top: 18px;
  border-top: 1px solid colors.$pr-200;
}

.reportList {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
}
</style>
