<template>
  <ElPopover
    v-model="isOpen"
    width="350"
    trigger="click"
    popper-class="el-popover--no-padding el-popover--no-margin"
  >
    <div class="el-select-dropdown is-multiple is-inline">
      <ul
        class="el-scrollbar__view el-select-dropdown__list"
        data-testid="deal-dashboard-toolbar-filter-phase-dropdown"
      >
        <li
          v-for="phase in phasesList"
          :key="phase.id"
          :label="phase.name"
          :value="phase.id"
          class="el-select-dropdown__item"
          :class="{
            selected: isSelected(phase.id),
            [$style.phaseDropdownItem]: true,
          }"
          @click.stop="togglePhase(phase.id)"
        >
          <!-- @vue-ignore: color -->
          <PhaseSelectItem
            :color="phase.color"
            :name="phase.name"
            :deals-count="phase.dealsCount"
          />
        </li>
      </ul>
    </div>
    <template #reference>
      <DrToolbarFilterButton
        label="Phase"
        :counter="selectedPhases?.length ?? 0"
        :is-active="isActive"
        @clear="clearFilter"
      />
    </template>
  </ElPopover>
</template>

<script lang="ts">
import { pull } from "lodash-es";
import { defineComponent } from "vue";
import { DrToolbarFilterButton } from "@shared/ui/dr-toolbar";

import PhaseSelectItem from "../PhaseSelectItem.vue";
import {
  convertPhasesFilterValueToStore,
  convertPhasesStoreToFilterValue,
  getPhasesSelectList,
} from "../utils";

import type { PhasesValue } from "../utils";
import type { CustomView } from "@setups/types";

interface Data {
  isOpen: boolean;
}

export default defineComponent({
  name: "PhaseField",
  components: { PhaseSelectItem, DrToolbarFilterButton },
  data(): Data {
    return {
      isOpen: false,
    };
  },
  computed: {
    isActive(): any {
      return !!(this.selectedPhases && this.selectedPhases.length);
    },
    phasesList() {
      return getPhasesSelectList(this.$store);
    },
    selectedPhases: {
      get: function (): PhasesValue {
        const current: CustomView =
          this.$store.state.common.customViews.current;
        const values = current.filters.fields["phase"]?.value;
        return convertPhasesStoreToFilterValue(values);
      },
      set: function (values: PhasesValue) {
        this.$store.commit("common/customViews/setCurrentFilterValue", {
          field: "phase",
          value: convertPhasesFilterValueToStore(values),
          op: "ANY",
        });
      },
    },
  },
  methods: {
    isSelected(id: number) {
      return this.selectedPhases ? this.selectedPhases.includes(id) : false;
    },
    togglePhase(id: number) {
      const values = [...(this.selectedPhases || [])];
      if (this.isSelected(id)) {
        pull(values, id);
      } else {
        values.push(id);
      }
      this.selectedPhases = values;
    },
    clearFilter() {
      this.selectedPhases = undefined;
    },
  },
});
</script>
<style lang="scss" module>
@use "@app/styles/scss/colors";

.phaseDropdownItem:after {
  display: none;
}
.phaseDropdownItem_isDisabled {
  cursor: not-allowed !important;
  &:hover {
    color: colors.$pr-800 !important;
    background-color: unset !important;
  }
}
</style>
