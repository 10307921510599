<template>
  <ElPopover
    v-model:visible="isVisible"
    placement="bottom"
    :width="692"
    trigger="click"
  >
    <ElRow :gutter="20">
      <ElCol :span="6">
        <div class="all-deals-views-container__header">Default views</div>
        <AllDealsViewsRow
          v-for="view in DEFAULT_DEALS_VIEWS"
          :key="view.slug"
          :view="view"
          @select="isVisible = false"
        />
      </ElCol>
      <ElCol :class="$style.leftBorderedColumn" :span="9">
        <div class="all-deals-views-container__header">Personal views</div>
        <AllDealsViewsRow
          v-for="view in personalViews"
          :key="view.slug"
          :view="view"
          @select="isVisible = false"
        />
      </ElCol>
      <ElCol :class="$style.leftBorderedColumn" :span="9">
        <div class="all-deals-views-container__header">Shared views</div>
        <AllDealsViewsRow
          v-for="view in publicViews"
          :key="view.slug"
          :view="view"
          @select="isVisible = false"
        />
      </ElCol>
    </ElRow>

    <template #reference>
      <button :class="$style.button">
        <span>All views</span>
        <DrIcon name="caret-down" size="sm" :class="$style.icon" />
      </button>
    </template>
  </ElPopover>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import DrIcon from "@shared/ui/dr-icon";

import { CustomViewObjectTypes } from "@setups/types";
import { DEFAULT_DEALS_VIEWS } from "@drVue/store/modules/common/custom-views/default";
import AllDealsViewsRow from "./AllDealsViewsRow.vue";

import type { RootState } from "@drVue/store/state";
import type { CustomView } from "@setups/types";

const store = useStore<RootState>();

const isVisible = ref(false);
const dealViews = computed<CustomView[]>(() =>
  store.getters["common/customViews/byObjectType"](CustomViewObjectTypes.Deal),
);

const personalViews = computed<CustomView[]>(() => {
  return dealViews.value.filter((v: CustomView) => v.is_personal);
});
const publicViews = computed<CustomView[]>(() => {
  return dealViews.value.filter((v: CustomView) => !v.is_personal);
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography" as typo;

.icon {
  color: colors.$pr-400;
}

.button {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 6px;
  height: 100%;
  padding: 0 2px;
  border: none;
  border-radius: 0;
  font: typo.$body_medium;
  background-color: transparent;
  border-bottom: solid 2px transparent;
  transition: all ease-out 200ms;
  outline: none;

  &,
  &:focus,
  &:hover {
    color: colors.$pr-800;
  }
}

.leftBorderedColumn {
  border-left: 1px solid colors.$pr-200;
  padding-left: 28px !important;
}
</style>
