<template>
  <div v-if="row.type === 'task'" class="id-cell">
    {{ row.task.key }}
  </div>
  <div v-else class="id-cell category">
    <b>{{ row.category.deal.title }}</b>
    <i class="fa fa-caret-right" />
    <span v-for="(cat, index) in row.category.path" :key="cat.id">
      <span v-if="index" class="separator">/</span>
      <a href="#" @click.stop="openCategory(cat.id)">{{ cat.name }}</a>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { categoryUrl } from "@setups/room-urls";

import type { ITableRow } from "../types";
import type { PropType } from "vue";

export default defineComponent({
  name: "IdCell",
  props: {
    row: {
      required: true,
      type: Object as PropType<ITableRow>,
    },
  },
  methods: {
    openCategory(categoryId: number) {
      if (
        this.row.type !== "category" ||
        !this.row.category.deal.room!.permalink
      ) {
        return;
      }
      const url = categoryUrl(
        this.row.category.deal.room!.permalink,
        categoryId,
      );
      window.open(url, "_blank");
    },
  },
});
</script>
