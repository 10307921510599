<template>
  <TextViewContent :value="value" @click.stop="viewProps.enterEditMode" />
</template>

<script setup lang="ts">
import { computed } from "vue";

import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import { drUserTime } from "@drVue/filters/drUserTime";
import TextViewContent from "./TextViewContent.vue";

import type { ViewFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";

interface Props {
  viewProps: ViewFieldProps;
}

const props = defineProps<Props>();

const value = computed(() => {
  const type = props.viewProps.schema.type;
  if (type !== FieldSchemaType.Date && type !== FieldSchemaType.DateTime) {
    throw new Error(`Invalid type ${type} for Date View Field`);
  }

  const value = props.viewProps.entityValue;
  if (!value) return "-";

  return type === FieldSchemaType.Date
    ? drUserTime(value, "full-date")
    : drUserTime(value, "short-dt");
});
</script>
