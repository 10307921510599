<template>
  <div
    :class="{
      [$style.tag]: true,
      [$style.tag_isMyGroup]: isMyGroupComment,
    }"
  >
    <DrIcon size="sm" name="lock" />
    <DrTruncatedTextTooltip :content="viewersText">
      {{ viewersText }}
    </DrTruncatedTextTooltip>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import {
  MY_GROUP_LABEL,
  MY_GROUP_ONLY_LABEL,
  UNKNOWN_GROUP_NAME,
} from "./constants";

import type { Comment, CommentViewersGroup } from "./types";

interface Props {
  comment: Comment;
  viewersGroups: CommentViewersGroup[];
  userGroupId: CommentViewersGroup["id"];
}

const props = defineProps<Props>();

const isMyGroupComment = computed(
  () =>
    props.comment.viewers?.length === 1 &&
    props.comment.viewers[0] === props.userGroupId,
);

const viewersText = computed(() => {
  if (isMyGroupComment.value) {
    return MY_GROUP_ONLY_LABEL;
  }

  const group_id = props.comment.author.group_id;
  const fromMyGroup = group_id === props.userGroupId;

  const viewersNames = (props.comment.viewers ?? [])
    .filter((id) => id !== group_id)
    .map((id) => getGroupName(id))
    .sort();

  let senderLabel = "";
  if (!fromMyGroup) {
    senderLabel = `from ${getGroupName(group_id)} `;
  }

  return `${senderLabel}to ${viewersNames.join(", ")}`;
});

const getGroupName = (id?: CommentViewersGroup["id"]) => {
  if (id === props.userGroupId) {
    return MY_GROUP_LABEL;
  }

  return (
    props.viewersGroups.find((group) => group.id === id)?.name ??
    UNKNOWN_GROUP_NAME
  );
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";
@use "@app/styles/scss/spacing";

.tag {
  display: inline-flex;
  align-items: center;
  gap: spacing.$xxs;
  max-width: 100%;
  overflow: hidden;
  font: typography.$caption_regular;
  color: colors.$pr-500;
}

.tag_isMyGroup {
  color: colors.$ad-10;
}
</style>
