<template>
  <div :class="$style.container">
    <div :class="$style.body">
      <i :class="$style.icon" class="fas fa-download" />
      <p :class="$style.description">
        Drop your files here to attach them to deal.
      </p>
    </div>
  </div>
</template>

<style module lang="scss">
@use "@app/styles/scss/colors.scss";

.container {
  background: colors.$sc-50;
  height: calc(100% - 16px);
  margin: 0 4px 0 4px;
  padding: 4px;
  border: 1px dashed colors.$sc-400;
  border-radius: 8px;
}

.body {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  text-align: center;
  color: colors.$sc-600;
}

.description {
  font-size: 14px;
  line-height: 24px;
}

.icon {
  font-size: 28px;
  margin-bottom: 12px;
}
</style>
