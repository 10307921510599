import * as d3 from "d3";

angular
  .module("dealroom.analytics.card.burndown.data.total", [
    "dealroom.analytics.card.burndown.data.tick",
    "dealroom.analytics.service.filter",

    "dealroom.task",
  ])
  .service(
    "AnalyticsBurndownDataTotalService",
    AnalyticsBurndownDataTotalService,
  );

AnalyticsBurndownDataTotalService.$inject = [
  "AnalyticsBurndownTickFactrory",
  "AnalyticsColorsSchemeService",
];
function AnalyticsBurndownDataTotalService(
  AnalyticsBurndownTickFactrory,
  AnalyticsColorsSchemeService,
) {
  return {
    median: getData(d3.median),
    total: getData(d3.sum),
  };

  function getData(aggrFn) {
    return (nested) => ({
      key: "total",
      title: "Total",
      color: AnalyticsColorsSchemeService.blue.normal,
      ticks: AnalyticsBurndownTickFactrory.fromTicks(nested, aggrFn),
    });
  }
}
