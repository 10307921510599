<template>
  <AiReportsPagePromotionBanner v-if="!isAiEnabled" :class="$style.banner" />

  <div v-else :class="$style.wrapper">
    <div :class="$style.panel">
      <div :class="$style.activeReportSelectTitle">Select summary:</div>
      <div :class="$style.activeReportSelect">
        <ElSelect
          :model-value="activeReportId ?? undefined"
          :disabled="isDisabled"
          placeholder="Select"
          @update:model-value="selectReport"
        >
          <ElOption
            v-for="view in aiReportsStore.list"
            :key="view.id"
            :label="view.label"
            :value="view.id"
          />
        </ElSelect>
      </div>

      <div>
        <ElButton
          :disabled="isDisabled"
          @click="activeSummariesId = activeReportId"
        >
          Summaries overview
        </ElButton>
      </div>

      <AiDocReportsTableExportButton
        :report-type-id="activeReportId"
        :disabled="isDisabled"
      />
    </div>

    <div :class="$style.main">
      <AiDocReportsTable
        v-if="activeReportId"
        :report-type-id="activeReportId"
        @select="openDocAi"
      />

      <AiReportsPageEmpty v-if="isDisabled" :class="$style.emptyContainer" />
    </div>
  </div>

  <DocVerQaDialog
    v-if="aiDocumentId"
    :doc-id="aiDocumentId"
    @close="aiDocumentId = null"
  />

  <AiDocReportsSummaryDialog
    v-if="activeSummariesId"
    :report-type-id="activeSummariesId"
    @close="activeSummariesId = null"
  />
</template>

<script lang="ts" setup>
import { ElButton, ElOption, ElSelect } from "element-plus";
import { computed, onMounted, ref } from "vue";

import { AiReportsEvent, insightTrack } from "@app/insight";
import { ROOM_DATA, ROOM_MEMBER_DATA } from "@app/setups/data";
import { AiAccess } from "@app/setups/enums";
import { documentViewUrl } from "@app/setups/room-urls";
import { useAiReportsStore } from "@drVue/components/room/ai/AiReportsStore";
import DocVerQaDialog from "@drVue/components/room/ai/DocVerQaDialog.vue";
import { pinia } from "@drVue/store/pinia";
import { usePageNavigationBus } from "../usePageNavigationBus";
import AiDocReportsSummaryDialog from "./AiDocReportsSummaryDialog.vue";
import AiDocReportsTable from "./AiDocReportsTable.vue";
import AiDocReportsTableExportButton from "./AiDocReportsTableExportButton.vue";
import AiReportsPageEmpty from "./AiReportsPageEmpty.vue";
import AiReportsPagePromotionBanner from "./AiReportsPagePromotionBanner.vue";

import type { AiDocReport, AiReport } from "@drVue/components/room/ai/types";

const isAiEnabled =
  ROOM_DATA.isAiEnabled && ROOM_MEMBER_DATA.group.ai_access === AiAccess.BASIC;

const aiReportsStore = useAiReportsStore(pinia);
const activeReportId = ref<AiReport["id"] | null>(null);
const activeSummariesId = ref<AiReport["id"] | null>(null);
const aiDocumentId = ref<AiDocReport["document_id"] | null>(null);

const pageNavigationBus = usePageNavigationBus();

pageNavigationBus.on((_event, payload) => {
  if (!payload) throw new Error("Payload is required");

  window.open(
    documentViewUrl(ROOM_DATA.url, payload.docId, undefined, payload.page),
    "_blank",
  );
});

onMounted(() => {
  insightTrack(AiReportsEvent.ReportsPageOpen);

  aiReportsStore.load().then(() => {
    activeReportId.value = aiReportsStore.list[0]?.id || null;
  });
});

const isDisabled = computed(() => aiReportsStore.list.length === 0);

const selectReport = (reportId: AiReport["id"]) => {
  activeReportId.value = reportId;
};

const openDocAi = (documentId: AiDocReport["document_id"]) => {
  aiDocumentId.value = documentId;
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/values";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

$nav-height: 48px;

.banner {
  height: calc(100vh - #{values.$header-height});
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  height: calc(100vh - #{values.$header-height});
  padding: spacing.$m;
  gap: spacing.$m;
}

.panel {
  gap: 8px;
  display: flex;
  white-space: nowrap;
  align-items: center;
}

.activeReportSelect {
  width: 261px;
  margin-right: auto;
  margin-left: 2px;
}

.activeReportSelectTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.view {
  font: typography.$body_regular;
  padding: 4px 12px 4px 8px;
  border-radius: 8px;
  cursor: pointer;
}

.view_isActive,
.view:hover {
  background-color: colors.$pr-50;
}

.main {
  display: grid;
  grid-template-rows: calc(
    100vh - #{$nav-height + values.$header-height + 32px}
  );
  position: relative;
}

.emptyContainer {
  border: 1px solid colors.$pr-150;
  border-radius: 7px;
}
</style>
