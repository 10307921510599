<template>
  <div class="a-section">
    <h4 class="a-section__header" @click="isExpanded = !isExpanded">
      <slot name="header" />
      <i
        class="a-section__caret fa"
        :class="{
          'fa-caret-right': !isExpanded,
          'fa-caret-down': isExpanded,
        }"
      />
    </h4>
    <div v-if="isExpanded">
      <slot name="default" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { PropType } from "vue";

interface Data {
  isExpanded: boolean;
}

export default defineComponent({
  name: "SecuritySection",
  props: {
    isExpandedInitially: {
      required: true,
      default: false,
      type: Boolean as PropType<boolean>,
    },
  },
  data(): Data {
    return {
      isExpanded: false,
    };
  },
  beforeMount() {
    this.isExpanded = this.isExpandedInitially;
  },
});
</script>
