<template>
  <TaskList
    :title="editProps.schema.label"
    :task-ids="editProps.value"
    allow-change
    allow-remove
    @change="handleChange"
    @remove="handleRemove"
    @click="handleClick"
  />
</template>

<script setup lang="ts">
import { taskUrl } from "@setups/room-urls";
import { ROOM_DATA } from "@app/setups";
import TaskList from "@drVue/components/room/tasks/TaskDetails/lists/tasks/TaskList.vue";
import { pinia } from "@drVue/store/pinia";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";

import type { Task } from "@drVue/store/pinia/room/tasks";
import type { FieldProps } from "@shared/ui/dr-dynamic-form/types";

interface Props {
  editProps: FieldProps<Task["id"][]>;
}

const props = defineProps<Props>();

const tasksStore = useTasksStore(pinia);

const handleChange = (ids: Task["uid"][]) => {
  const mapped = ids.map((id) => tasksStore.tasksByUid[id].id);
  props.editProps.veeField.onChange(mapped);
};

const handleRemove = (task: Task) => {
  props.editProps.veeField.onChange(
    props.editProps.value.filter((id) => id !== task.id),
  );
};

const handleClick = (task: Task) => {
  window.open(taskUrl(ROOM_DATA.url, task.key), "_blank");
};
</script>
