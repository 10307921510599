import analyticsTooltipHtml from "./index.html?raw";

AnalyticsTooltip.$inject = ["$element", "$scope", "$compile"];
import * as d3 from "d3";

angular
  .module("dealroom.analytics.tooltip", [])
  .directive("drAnalyticsTooltip", () => ({
    scope: {
      dispatcher: "<drAnalyticsTooltip",
      shadowId: "@",
      color: "@",
      timeout: "@",
    },
    controller: AnalyticsTooltip,
  }));

const TIMEOUT = 100;
const MARGIN = { left: 20, top: -30, right: 5, bottom: 5 };
const TEMPLATE = analyticsTooltipHtml;

const CLASSES = {
  parent: "analytics-tooltip-parent",
  tooltip: {
    main: "analytics-tooltip-content",
    visible: "analytics-tooltip-content--show",
  },
};

function AnalyticsTooltip($element, $scope, $compile) {
  let _TIMER, innerScope;
  const pk = "AnalyticsTooltip" + $scope.$id;
  const timeout = $scope.timeout || TIMEOUT;
  const tooltip = d3
    .select("body")
    .append("div")
    .attr("class", CLASSES.tooltip.main)
    .html(TEMPLATE);

  setContent();

  $scope.dispatcher.on("update." + pk, setContent);

  $scope.$on("$destroy", remove);

  function setContent(content = {}) {
    if (!content.title && !content.body && !content.value) {
      hideTooltip();
      $element.removeClass(CLASSES.parent);
      $element.on("mousemove", null);
      $element.on("mouseleave", null);
      return;
    }

    const color = hexToRgba(content.color || "#000000");
    tooltip.style("box-shadow", "0 0 4px " + color);

    $element.addClass(CLASSES.parent);
    $element.on("mousemove", showTooltip);
    $element.on("mouseleave", hideTooltip);

    _set("title", color).style("color", color);
    _set("body");

    const valueElement = _set("value");
    if (content.value instanceof Object) {
      _render(content.value, valueElement);
    }

    function _set(name, color) {
      const display = [undefined, null, ""].includes(content[name])
        ? "none"
        : "block";
      return tooltip
        .select(`[${name}]`)
        .html(content[name])
        .style("display", display);
    }

    function _render({ template, ctrl }, node) {
      node.html(template);

      if (innerScope === undefined) {
        innerScope = $scope.$new();
      }
      innerScope.$ctrl = ctrl;
      $compile(node.node())(innerScope);
      innerScope.$digest();
    }
  }

  function remove() {
    innerScope && innerScope.$destroy();
    tooltip.remove();
  }

  function toggleTooltip(close) {
    tooltip.classed(CLASSES.tooltip.visible, !close);
    let filter = null,
      boxShadow = "";
    if (!close) {
      filter = `url(#${$scope.shadowId})`;
      boxShadow = tooltip.style("box-shadow");
    }

    if ($scope.shadowId) {
      $element.attr("filter", filter);
    } else {
      $element[0].style["box-shadow"] = boxShadow;
    }
  }

  function showTooltip({ clientX, clientY }) {
    if (_TIMER === undefined) {
      _TIMER = setInterval(toggleTooltip, timeout);
    }

    tooltip.style("display", "block").style("visibility", "hidden");
    const width = tooltip.node().offsetWidth;
    const height = tooltip.node().offsetHeight;
    tooltip.style("display", null).style("visibility", null);

    let lx = clientX + MARGIN.left;
    if (lx + width + MARGIN.right > window.innerWidth) {
      lx = clientX - MARGIN.left - width;
    }
    let ly = clientY + MARGIN.top;
    if (ly + height + MARGIN.bottom > window.innerHeight) {
      ly = clientY - MARGIN.top - height;
    }
    tooltip.style("left", lx + "px").style("top", ly + "px");
  }

  function hideTooltip() {
    if (_TIMER) {
      clearInterval(_TIMER);
      _TIMER = undefined;
    }
    toggleTooltip(true);
  }
}

function hexToRgba(hex = "#333333", alpha = 1) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) return null;
  const colors = [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16),
    alpha,
  ];

  return "rgba(" + colors.join(",") + ")";
}
