<template>
  <div v-if="groupsCount || notesCount" class="dash-rooms-board__footer">
    <div class="dash-rooms-board__footer-row">
      <div v-if="notesCount" class="dash-rooms-board__footer-item">
        <i class="far fa-sticky-note" />
        {{ notesCount }}
      </div>
      <div v-if="groupsCount" class="dash-rooms-board__footer-item">
        <i class="far fa-user-friends" />
        {{ groupsCount }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";
import type { PropType } from "vue";

export default defineComponent({
  name: "DealsBoardItemFooter",
  props: {
    deal: { required: true, type: Object as PropType<Deal> },
  },
  computed: {
    groupsCount(): number | null {
      return this.deal.contacts.length;
    },
    notesCount(): number {
      return this.deal.notes_count;
    },
  },
});
</script>
