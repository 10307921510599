import type { Action } from "@drVue/api-service/client-dashboard/activity";

export class State {
  public actions: Action[];

  public isLoading = false;
  public isError = false;

  constructor() {
    this.actions = [];
  }
}
