<template>
  <DrItemListItem
    :allow-remove="!disallowRemove"
    :actions="actions"
    @remove="$emit('remove', reviewer.user_id)"
    @action="$emit('remind', reviewer)"
  >
    <div :class="$style.container">
      <div :class="$style.avatar">
        <DrAvatar
          :url="reviewer.avatar?.thumbnail"
          :name="reviewer.name ?? anon"
        />
        <div :class="$style.statusIcon">
          <DrSvgIcon :name="statusIconName" />
        </div>
      </div>
      <span :class="$style.name">{{ reviewer.name ?? anon }}</span>
    </div>
  </DrItemListItem>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DrAvatar } from "@shared/ui/dr-avatar";
import { DrItemListItem } from "@shared/ui/dr-item-list";
import { DrSvgIcon, type SvgIconName } from "@shared/ui/dr-svg-icon";

import { USER_DATA } from "@app/setups";

import type { ReviewerItem } from "./types";
import type { ActionItem } from "@shared/ui/dr-item-list";

interface Props {
  reviewer: ReviewerItem;
  disallowRemove: boolean;
  reminded?: boolean;
}

interface Emits {
  (e: "remove", reviewerUid: string): void;
  (e: "remind", reviewer: ReviewerItem): void;
}

const props = withDefaults(defineProps<Props>(), {
  reminded: false,
});

defineEmits<Emits>();

const anon = "Anonymous";

const statusIconName = computed<SvgIconName>(() =>
  props.reviewer.mark_complete ? "square-check" : "triangle-exclamation",
);

const actions = computed(() => {
  const action: ActionItem = {
    id: "remind",
    icon: "bell",
    title: "Remind",
    disabled: undefined,
  };

  if (USER_DATA.id === props.reviewer.id) {
    action.disabled = true;
    action.title = "Cannot create reminder for yourself";
  }

  if (props.reminded) {
    action.disabled = true;
    action.title = "You can remind once in 24h";
  }

  return [action];
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.avatar {
  position: relative;
}

.statusIcon {
  position: absolute;
  right: -6px;
  bottom: -3px;
}

.name {
  font: typography.$body_regular;
  color: colors.$pr-900;
}
</style>
