<template>
  <DrVxeGrid
    :data="tableData"
    :columns="tableColumns.columns"
    :row-class-name="getRowClassName"
    class="dr-vxe-table--phases"
    :class="{
      'dr-vxe-table--phasesReadOnly': !editable,
    }"
    keep-source
    :edit-config="{
      trigger: 'manual',
      mode: 'cell',
      autoClear: false,
      showIcon: false,
      enabled: editable,
    }"
    @edit-actived="onEditActived"
    @edit-closed="onEditClosed"
  >
    <template #empty>No Phases history yet</template>
  </DrVxeGrid>
</template>

<script lang="ts">
import { isEqual } from "lodash-es";
import { defineComponent } from "vue";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import { calculatePhases } from "@drVue/components/client-dashboard/deals/PhasesTable/calculatePhases";
import TableColumns from "@drVue/components/client-dashboard/deals/PhasesTable/tableColumns";
import { markDisabledPhases } from "@drVue/components/client-dashboard/deals/utils";
import DrStore from "@drVue/store";

import type { PhasesTableRow } from "./models";
import type { DealPatchPayload } from "@drVue/store/modules/client-dashboard/deals/DealsActions";
import type {
  Deal,
  DealPhaseInfo,
} from "@drVue/store/modules/client-dashboard/deals/types";
import type { PropType } from "vue";

interface Data {
  activePhase: Partial<DealPhaseInfo> | null;
  tableColumns: any;
}

export default defineComponent({
  name: "PhasesTable",
  components: {
    DrVxeGrid,
  },
  props: {
    deal: { required: true, type: Object as PropType<Deal> },
    editable: { type: Boolean as PropType<boolean>, default: false },
  },
  data(): Data {
    return {
      activePhase: null,
      tableColumns: new TableColumns(),
    };
  },
  computed: {
    tableData(): PhasesTableRow[] {
      return calculatePhases(
        markDisabledPhases(DrStore.state.clientDashboard.phases.items).filter(
          ({ isDisabled }) => !isDisabled,
        ),
        this.deal,
      );
    },
  },

  methods: {
    getRowClassName({ row }: any) {
      if (row.active) return "active";
    },
    onEditActived(params: any) {
      this.activePhase = {
        phase_id: params.row.phase_id,
        deal_id: params.row.deal_id,
        comment: params.row.comment,
        actual_start_date: params.row.actual_start_date,
        planned_start_date: params.row.planned_start_date,
      };
    },
    onEditClosed(params: any) {
      const closedPhase = {
        phase_id: params.row.phase_id,
        deal_id: params.row.deal_id,
        comment: params.row.comment,
        actual_start_date: params.row.actual_start_date,
        planned_start_date: params.row.planned_start_date,
      };

      if (!isEqual(this.activePhase, closedPhase)) {
        const payload: DealPatchPayload = {
          dealId: this.deal.id,
          updates: {
            phases_info: { [closedPhase.phase_id]: closedPhase },
          },
        };

        DrStore.dispatch("clientDashboard/deals/patchDeal", payload);
      }

      this.activePhase = null;
    },
  },
});
</script>

<style lang="scss">
.dr-vxe-table--phasesReadOnly .phase-cell__actions {
  display: none;
}
</style>
