<template>
  <FolderMenuDropdown
    :reference="reference"
    :placement="placement"
    @close="$emit('close')"
  >
    <FolderTree
      :disabled-node-keys="params.folders.map((item) => item.id) ?? []"
      submit-text="Copy"
      :class="$style.folderMenu"
      :autofocus="200"
      :width="POPUP_SIZES.folderMenu.width"
      :height="POPUP_SIZES.folderMenu.height"
      @cancel="$emit('close')"
      @update:model-value="submit"
      @update:persisted="$emit('update:persisted', $event)"
    />
  </FolderMenuDropdown>
</template>

<script lang="ts" setup>
import { type ComponentInstance, ref } from "vue";

import { DrStore } from "@app/vue";
import { $notifyDanger, $notifySuccess } from "@app/vue/common";
import { POPUP_SIZES } from "@app/vue/shared/ui/constants";
import { DocumentsApiService } from "@app/vue/store/modules/room/documents/DocumentsApiService";
import { type FolderMenuParams, getFolderMenuSelectedText } from "../utils";
import FolderMenuDropdown from "./FolderMenuDropdown.vue";
import FolderTree from "./FolderTree.vue";

import type { ElPopover } from "element-plus";

interface Props {
  params: FolderMenuParams;
  reference: HTMLElement;
  placement?: ComponentInstance<typeof ElPopover>["placement"];
}

interface Emits {
  (e: "close"): void;
  (e: "update:persisted", value: boolean): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const loading = ref(false);

const api = new DocumentsApiService();

const submit = (destinationFolderId: number | undefined): void => {
  if (!destinationFolderId || loading.value) return;

  const selectedText = getFolderMenuSelectedText(props.params);
  const pluralisedAction =
    props.params.documents.length + props.params.folders.length === 1
      ? "has"
      : "have";

  loading.value = true;

  emit("update:persisted", true);

  api
    .copyFoldersAndDocuments(
      destinationFolderId,
      props.params.folders.map((item) => item.id),
      props.params.documents.map((item) => item.id),
    )
    .then(() => {
      DrStore.dispatch("room/documents/syncTree");

      $notifySuccess(`The ${selectedText} ${pluralisedAction} been copied.`);

      emit("close");
    })
    .catch((error) => {
      $notifyDanger(
        error.response.data?.join("\n") ?? `Failed to copy ${selectedText}.`,
      );
    })
    .finally(() => {
      loading.value = false;

      emit("update:persisted", false);
    });
};
</script>

<style lang="scss" module>
.folderMenu {
  :global(.el-button + .el-button) {
    margin-left: 8px;
  }
}
</style>
