import * as Yup from "yup";

export const date = () => {
  return Yup.date().transform((value, originalValue, context) => {
    if (value instanceof Date && Number.isNaN(value.getTime())) {
      return undefined;
    }

    return value;
  });
};

export const number = () => {
  return Yup.number().transform((value, originalValue, context) => {
    if (Number.isNaN(value)) {
      return undefined;
    }

    return value;
  });
};
