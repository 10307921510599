<template>
  <MgmtRoomsTable v-loading="isLoading" :filters="filters" :rooms="rooms">
    <template #empty>
      <span v-if="!searchText && !lastPromise">Please type a search query</span>
      <span v-if="searchText && lastPromise">Performing search...</span>
      <span v-if="searchText && !lastPromise">No rooms found</span>
    </template>
  </MgmtRoomsTable>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useDebounceFn } from "@vueuse/core";

import { ManagementApiService } from "@drVue/api-service/management";
import { $notifyDanger } from "@drVue/common";
import MgmtRoomsTable from "./MgmtRoomsTable.vue";

import type { Filters } from "./MgmtRoomsTable.vue";
import type { BasicRoomRow } from "@drVue/components/management/RoomsTable/types";

const props = withDefaults(
  defineProps<{
    filters: Filters;
    searchText?: string;
    extraColumns?: string[];
  }>(),
  {
    searchText: "",
    extraColumns: () => [],
  },
);

const api = new ManagementApiService();
const rooms = ref<BasicRoomRow[]>([]);
const isLoading = ref<boolean>(false);
const lastPromise = ref<Promise<BasicRoomRow[]> | null>(null);

const debouncedSearchFn = useDebounceFn(() => {
  if (props.searchText) {
    isLoading.value = true;
    const promise = (lastPromise.value = api.searchRoom(props.searchText));
    promise
      .then((data) => {
        if (lastPromise.value === promise) {
          rooms.value = data!;
        }
      })
      .catch(() => {
        $notifyDanger("Error while fetching rooms");
      })
      .finally(() => {
        if (lastPromise.value === promise) {
          isLoading.value = false;
        }
        lastPromise.value = null;
      });
  } else {
    rooms.value = [];
  }
}, 400);

watch(() => props.searchText, debouncedSearchFn);
</script>
