import { formatISO } from "date-fns";

angular
  .module("dealroom.analytics.service.filter.dates", [
    "dealroom.analytics.service.data",
  ])
  .service("ActivityFilterDatesService", ActivityFilterDatesService);

ActivityFilterDatesService.$inject = [
  "$filter",
  "AnalyticsDataDispatcher",
  "RoomConfig",
];
function ActivityFilterDatesService(
  $filter,
  AnalyticsDataDispatcher,
  RoomConfig,
) {
  const service = {
    setDatesRange,
    isStartDateReached,

    getEveryDayRange,
    isInRange,
    isLessDateEnd,
    isMoreDateStart,

    getSelectedRangeAsString,

    getExtent,
    filters: {},
  };

  service.filters.dateStart = false;
  service.filters.dateEnd = false;
  service.filters.dateLast = d3.timeDay.ceil(new Date());
  service.filters.dateFirst = d3.timeParse("%Y-%m-%d")(RoomConfig.createdDate);

  return service;

  function getSelectedRangeAsString() {
    if (!service.filters.dateStart && !service.filters.dateEnd) {
      return "Any";
    }

    const start = service.filters.dateStart
      ? formatISO(service.filters.dateStart)
      : "Any";
    const end = service.filters.dateEnd
      ? formatISO(service.filters.dateEnd)
      : "Any";

    return `${start} - ${end}`;
  }

  function isStartDateReached(dt) {
    return lte(service.filters.dateStart, dt);
  }

  function setDatesRange(dateStart, dateEnd) {
    if (lte(dateStart, dateEnd)) {
      dateStart = prev(dateEnd);
    }
    service.filters.dateStart = dateStart;
    service.filters.dateEnd = dateEnd;
    AnalyticsDataDispatcher.call("filtersChanged");
  }

  function isInRange(d, ignoreEndDateFilter = false) {
    return isMoreDateStart(d) && (ignoreEndDateFilter || isLessDateEnd(d));
  }

  function isLessDateEnd({ dt }) {
    const [dateStart, dateEnd] = getExtent();
    return dt <= dateEnd;
  }

  function isMoreDateStart({ dt }) {
    const [dateStart, dateEnd] = getExtent();
    return dt >= dateStart;
  }

  function getEveryDayRange(toLast = false) {
    let [dateStart, dateEnd] = getExtent(toLast);
    if (!dateStart || !dateEnd) return [];

    if (
      toLast &&
      count(d3.timeDay.ceil(dateStart), service.filters.dateEnd) === 0
    ) {
      dateStart = prev(dateStart);
    }

    const minCount = 1;
    const diff = count(dateStart, dateEnd);
    if (diff < minCount) {
      dateStart = prev(dateStart, minCount - diff);
    }
    return d3.timeDay.range(dateStart, dateEnd);
  }

  function getExtent(toLast) {
    const extent = [
      service.filters.dateStart,
      toLast ? service.filters.dateLast : service.filters.dateEnd,
    ];

    return extent[0] && extent[1]
      ? extent
      : [service.filters.dateFirst, service.filters.dateLast];
  }
}

function count(a, b) {
  return d3.timeDay.count(a, b);
}

function lte(a, b) {
  if (!a || !b) return false;
  return count(a, b) <= 0;
}

function prev(a, i = 1) {
  return d3.timeDay.ceil(d3.timeDay.offset(a, -i));
}
