<template>
  <div v-if="values.length" :class="$style.container">
    <DrTooltip raw-content :content="tooltipText" placement="bottom">
      <div :class="$style.wrapper">
        {{ values[0].title }}
      </div>
    </DrTooltip>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DrTooltip } from "@shared/ui/dr-tooltip";

interface ValueItem {
  id: string;
  title: string;
}

interface Props {
  values: ValueItem[];
}

const props = defineProps<Props>();

const tooltipText = computed(() => {
  return props.values
    .map((item, index) => `<div>${index + 1}. ${item.title}</div>`)
    .join("");
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";

.container {
  display: flex;
  align-items: center;
  height: 32px;
  width: 100%;
  max-width: 100%;
  border-radius: 6px;
  border: 1px solid transparent;
  padding: 2px 6px;
  user-select: none;
  transition: border 0.2s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    border: 1px solid colors.$sc-400;
  }
}

.wrapper {
  flex-shrink: 3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
