import type {
  DocumentId,
  FolderId,
  TaskId,
} from "@drVue/store/pinia/room/tasks";
import type { Dictionary } from "@drVue/types";

export class TasksRelatedState {
  public documentTasks: Dictionary<TaskId[]> = {};
  public folderTasks: Dictionary<TaskId[]> = {};

  public taskDocs: Dictionary<DocumentId[]> = {};
  public taskFolders: Dictionary<FolderId[]> = {};

  public isReady: boolean = false;
}
