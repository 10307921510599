<template>
  <div v-for="field in schema.fields" :key="field.prop" :class="$style.field">
    <DynamicField
      ref="fieldRefs"
      :schema="field"
      :entity="entity"
      :submit-fn="submitFn"
      @request-edit="closeEditingFieldThenGrant"
      @toggle-edit="setEditingFieldProp"
    >
      <template #view="{ viewProps }">
        <Component
          :is="field.viewComponent"
          v-if="field.type === FieldSchemaType.Custom"
          :view-props="viewProps"
        />
      </template>
      <template #edit="{ editProps }">
        <Component
          :is="field.editComponent"
          v-if="field.type === FieldSchemaType.Custom"
          :edit-props="editProps"
        />
      </template>
    </DynamicField>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

import DynamicField from "@drVue/components/client-dashboard/dynamic-form/DynamicField.vue";
import {
  FieldSchemaType,
  type FormSchema,
} from "@drVue/components/client-dashboard/dynamic-form/types";

interface Props {
  schema: FormSchema;
  submitFn: (value: any) => Promise<unknown>;
  entity: any;
}

defineProps<Props>();

const fieldRefs = ref<InstanceType<typeof DynamicField>[]>([]);
const editingFieldProp = ref<string | null>(null);

const setEditingFieldProp = (prop: string | null) =>
  (editingFieldProp.value = prop);

const closeEditingFieldThenGrant = (grant: () => void) => {
  if (editingFieldProp.value === null) {
    grant();
  } else {
    const currentEditingField = fieldRefs.value.find(
      (f: InstanceType<typeof DynamicField>) =>
        f.prop === editingFieldProp.value,
    );

    if (!currentEditingField) return;

    currentEditingField
      .quitEditModeConfirm("Do you want to save your changes?")
      .then(grant);
  }
};
</script>

<style lang="scss" module>
.field {
  padding-bottom: 12px;
}
</style>
