<template>
  <ul v-loading="isLoading" :class="$style.list">
    <div v-if="!tasks.length">
      <p>No related requests</p>
    </div>
    <li v-for="task in tasks" :key="task.id" @click="openTaskDetails(task.key)">
      <TaskStatusIcon :status-id="task.status_id" />
      {{ task.key }}
      {{ task.title }}
    </li>
  </ul>
</template>

<script setup lang="ts">
import { compact } from "lodash-es";
import { uniq } from "lodash-es";
import { computed } from "vue";
import { useStore } from "vuex";

import { TasksServiceProxy } from "@app/ng/serviceProxies";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import { pinia } from "../../../../store/pinia";
import TaskStatusIcon from "../../tasks/TaskStatusIcon.vue";

import type { Task, TaskId } from "@drVue/store/pinia/room/tasks";
import type { RootState } from "@drVue/store/state";

const props = defineProps<{ documentId: number }>();

const DrStore = useStore<RootState>();
const tasksStore = useTasksStore(pinia);

const isLoading = computed((): boolean => {
  return !DrStore.getters["room/tasksRelated/getIsReady"];
});

const tasks = computed((): Task[] => {
  if (isLoading.value) return [];

  const relatedTasksInfo: TaskId[] =
    DrStore.getters["room/tasksRelated/getDocumentTasks"](props.documentId) ||
    [];

  const tasksIds = uniq(relatedTasksInfo.map((rt) => rt.task_id));
  return compact(tasksIds.map((taskId) => tasksStore.tasks[taskId]));
});

const tasksService = new TasksServiceProxy();
function openTaskDetails(taskKey: string) {
  tasksService.openTaskDetails(taskKey);
}
</script>

<style module lang="scss">
.list {
  padding: 4px 0;
  font-size: 14px;

  li {
    margin: 0 0 8px 0;
    display: flex;
    gap: 10px;
    cursor: pointer;

    &:last-child {
      margin: unset;
    }
  }
}
</style>
