import { FieldItemTypes } from "@drVue/api-service/client-dashboard";

import type { SelectOption, SelectOptionChildrenDictionary } from "./types";
import type { FieldItem, PhaseItem } from "@drVue/api-service/client-dashboard";

function getFieldSelectOptions(field: FieldItem): string[] {
  switch (field.field_type) {
    case FieldItemTypes.Select:
      if ("select_options" in field.extra) {
        return field.extra.select_options.map(({ label }) => label);
      } else throw new Error();
    case FieldItemTypes.MultiSelect:
      if ("options" in field.extra) {
        return field.extra.options;
      } else throw new Error();
    default:
      throw new Error();
  }
}

function stringToSelectOption(label: string): SelectOption {
  return { id: label, label };
}

export function fieldToSelectOption(
  field: FieldItem,
): SelectOptionChildrenDictionary {
  return {
    id: field.key,
    label: field.label,
    children: getFieldSelectOptions(field)
      .map(stringToSelectOption)
      .reduce((acc, el) => ({ ...acc, [el.id]: el }), {}),
  };
}

export function phaseToSelectOption(phase: PhaseItem) {
  return { id: phase.uid, label: phase.name };
}

export function isSelectField({ field_type }: FieldItem) {
  return FieldItemTypes.Select === field_type;
}

export function makeEmptySelectOption(): SelectOption {
  return {
    id: Math.random().toString(),
    label: null!,
    children: [],
  };
}
