import { get } from "lodash-es";
import { keyBy } from "lodash-es";

import { compareAscending } from "@drVue/common";
import TableColumns from "@drVue/components/client-dashboard/deals/DealsTable/tableColumns";
import { ExcelReport, exportToExcel } from "@drVue/store/modules/reporting";
import { FieldItemTypes } from "../fields/types";

import type { FieldItem } from "@drVue/api-service/client-dashboard/types";
import type { DealsTableRow } from "@drVue/components/client-dashboard/deals/DealsTable/tableColumns";
import type { DrVxeTableColumnExportable } from "@drVue/components/types";
import type { Column, ReportItem } from "@drVue/store/modules/reporting";
import type { RootState } from "@drVue/store/state";
import type { Dictionary } from "@drVue/types";
import type { CustomViewSettings } from "@setups/types";
import type { Store } from "vuex";

interface ExportToExcelParams {
  deals: DealsTableRow[];
  excludeLastNoteInfo?: boolean;
  viewSettings?: CustomViewSettings;
}

export function exportDealsToExcel(
  $store: Store<RootState>,
  params: ExportToExcelParams,
): void {
  const report = new DealsExcelReport($store, params);
  exportToExcel(report);
}

class DealsExcelReport extends ExcelReport {
  private deals: DealsTableRow[];
  private excludeLastNoteInfo?: boolean;
  private tableColumns: TableColumns;
  private tableColumnsMap: Dictionary<
    DrVxeTableColumnExportable<DealsTableRow>
  >;
  private viewSettings?: CustomViewSettings;
  private $store: Store<RootState>;
  private dealCustomFields: Record<string, FieldItem>;

  constructor($store: Store<RootState>, params: ExportToExcelParams) {
    super("deals.xlsx", "Deals");
    this.$store = $store;
    this.deals = params.deals;
    this.excludeLastNoteInfo = params.excludeLastNoteInfo;
    this.viewSettings = params.viewSettings;
    this.tableColumns = new TableColumns($store, true);
    this.tableColumnsMap = keyBy(this.tableColumns.columns || [], "field");

    this.dealCustomFields = keyBy(
      this.$store.getters["clientDashboard/customFields/byObjectType"]("deal"),
      "key",
    );
  }

  phaseById(id: number | null) {
    return this.$store.getters["clientDashboard/phases/getPhaseById"](id);
  }

  get columns(): Column[] {
    return this.tableColumns.columns
      .filter((col) => col.visible)
      .filter(
        (col) =>
          !this.excludeLastNoteInfo || !col.field.startsWith("last_note."),
      )
      .map((col) => {
        const res: Column = {
          title: col.title as string,
          data_index: col.field,
          width: col.exportWidth,
        };

        const customFieldSchema = this.dealCustomFields[col.field];
        if (!customFieldSchema) return res;
        if (customFieldSchema.field_type === FieldItemTypes.Number) {
          res.number_format = customFieldSchema.extra;
        }

        return res;
      });
  }

  getSortMethod(sortField?: string) {
    const phaseSortMethod = (a: DealsTableRow, b: DealsTableRow) => {
      const phaseA = this.phaseById(a.phase);
      const phaseB = this.phaseById(b.phase);
      return compareAscending(phaseA.order, phaseB.order);
    };

    if (!sortField) {
      return phaseSortMethod;
    }

    const column = this.tableColumnsMap[sortField];
    if (!column) {
      return phaseSortMethod;
    }

    if (column.sortBy && typeof column.sortBy === "function") {
      const getFieldValue = column.sortBy as ({
        row,
      }: {
        row: DealsTableRow;
      }) => any;

      return (aRow: DealsTableRow, bRow: DealsTableRow) => {
        const aRowFieldValue = getFieldValue({ row: aRow });
        const bRowFieldValue = getFieldValue({ row: bRow });
        return compareAscending(aRowFieldValue, bRowFieldValue);
      };
    }

    return (a: DealsTableRow, b: DealsTableRow) =>
      compareAscending(get(a, column.field), get(b, column.field));
  }

  get items(): ReportItem[] {
    const deals = [...this.deals];
    const layoutSort = this.viewSettings?.tableLayoutSort;
    /**
     * The null value of `layoutSort.order` means that sorting by
     * `layoutSort.field` is disabled. */
    const sortMethod = this.getSortMethod(
      layoutSort?.order ? layoutSort?.field : "",
    );

    deals.sort(sortMethod);
    if (layoutSort?.order === "desc") {
      deals.reverse();
    }

    return deals.map((d) => this.dealToItem(d));
  }

  private dealToItem(deal: DealsTableRow): ReportItem {
    return this.columns.reduce((res, col) => {
      const column = this.tableColumnsMap[col.data_index];
      res[col.data_index] = column.exportToExcel(deal) || "";
      return res;
    }, {} as Dictionary<string>);
  }
}
