<template>
  <ElDrawer
    :model-value="shown"
    destroy-on-close
    :append-to-body="true"
    :with-header="false"
    :show-close="false"
    :size="panelSize"
    :before-close="handleElDrawerBeforeClose"
    :class="customClass"
    @closed="$emit('closed')"
  >
    <div :class="$style.close" @click="handleClose">
      <div :class="$style.closeDimmer" />
      <button :class="$style.closeButton">
        <DrIcon name="remove" size="sm" />
      </button>
    </div>

    <div
      :class="{
        [$style.panel]: true,
        [$style.panel_hasFixedFooter]: fixedFooter,
        [$style.panel_condensedLayout]: condensed,
        [$style.panel_customLayout]: $slots.custom,
      }"
    >
      <slot name="custom">
        <div
          :class="{
            [$style.header]: true,
            [$style.header__white]: headerWhite,
          }"
        >
          <div :class="$style.title">
            <div v-if="$slots['title-prefix']" :class="$style.titlePrefix">
              <slot name="title-prefix" />
            </div>
            <div :class="$style.titleText">
              <slot name="title">{{ title }}</slot>
            </div>
            <div v-if="$slots['title-right']" :class="$style.titlePrefix">
              <slot name="title-right" />
            </div>
          </div>

          <div v-if="!!$slots.description" :class="$style.description">
            <slot name="description" />
          </div>

          <div v-if="!!$slots['sub-header']" :class="$style.subHeader">
            <slot name="sub-header" />
          </div>
        </div>

        <div :class="$style.body">
          <ElScrollbar
            max-height="100%"
            :view-class="
              noPadding ? $style.bodyInner_noPadding : $style.bodyInner
            "
          >
            <slot name="default" />
          </ElScrollbar>
        </div>

        <div v-if="!noFooter" :class="$style.footer">
          <slot name="controls" />
          <slot name="footer">
            <ElButton @click="emit('cancel')">{{ cancelBtn }}</ElButton>
            <ElButton
              type="primary"
              :disabled="disallowSubmit"
              @click="emit('submit')"
            >
              {{ submitBtn }}
            </ElButton>
          </slot>
        </div>
      </slot>

      <DrLoader v-if="isSubmitting" overlay />
    </div>
  </ElDrawer>
</template>

<script setup lang="ts">
/**
 * The aside panel component with the page overlay.
 *
 * It's based on element-plus component according to the requirements of our style guide.
 * @reference https://element-plus.org/en-US/component/drawer.html
 *
 * @important 1) Only the parent is responsible for the shown/closed state - prop "shown".
 *            2) Responsibility for validating "whether the panel is allowed to close"
 *               is also left only to the parent component.
 */
import { computed } from "vue";

import DrIcon from "../dr-icon";
import { DrNewLoader as DrLoader } from "../dr-loader";
import { DrawerPanelSize } from "./types";

interface Props {
  shown: boolean;
  title: string;
  size?: keyof typeof DrawerPanelSize;
  submitBtn?: string;
  cancelBtn?: string;
  fixedFooter?: boolean;
  disallowSubmit?: boolean;
  isSubmitting?: boolean;
  customClass?: string;
  headerWhite?: boolean;
  noPadding?: boolean;
  noFooter?: boolean;
  condensed?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  size: "default",
  submitBtn: "Submit",
  cancelBtn: "Cancel",
  fixedFooter: false,
  disallowSubmit: false,
  isSubmitting: false,
  customClass: "",
  headerWhite: false,
  noPadding: false,
  noFooter: false,
  condensed: false,
});
const emit = defineEmits<{
  (event: "close"): void;
  (event: "closed"): void;
  (event: "submit"): void;
  (event: "cancel"): void;
}>();

const panelSize = computed(() => DrawerPanelSize[props.size]);

const handleClose = () => {
  emit("close");
};
const handleElDrawerBeforeClose = (hide: (shouldCancel: boolean) => void) => {
  hide(true);
  handleClose();
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;

$buttons-space: 16px;

.close {
  position: fixed;
}

.closeDimmer {
  background: #000;
  height: 100vh;
  margin-left: -100vw;
  opacity: 0;
  position: absolute;
  transition: opacity 100ms ease-out;
  width: 100vw;

  &:hover {
    opacity: 0.1;
  }
}

.closeButton {
  position: absolute;
  inset: 20px 0 0 -60px;
  z-index: 10;
  width: 34px;
  height: 34px;
  color: colors.$pr-800;
  background-color: #fff;
  border: 1px colors.$pr-200 solid;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    color: #fff;
    border-color: colors.$sc-600;
    background-color: colors.$sc-600;
  }
}

.panel {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  background-color: #fff;
  overflow: hidden;
}

.panel_hasFixedFooter {
  height: 100%;
}

.panel_customLayout {
  height: 100%;
  grid-template-rows: 1fr;
}

.header {
  padding: 20px spacing.$xl;
  background-color: colors.$pr-50;
  border-bottom: solid 1px colors.$pr-200;

  .panel_condensedLayout & {
    padding: spacing.$m;
  }
}

.header__white {
  background-color: transparent;

  .panel_condensedLayout & {
    border-bottom: none;
  }
}

.title {
  display: flex;
  align-items: center;
  gap: spacing.$s;
}
.titleText {
  flex-grow: 1;
  font: typo.$title_bold;
  color: colors.$pr-800;
}

.titlePrefix {
  /**
   * Adjust the display of components with a larger size than the title,
   * so that it is positioned in the same way along the central axis,
   * for example, the user's avatar.
   */
  margin-top: -(spacing.$m);
  margin-bottom: -(spacing.$m);
}

.description {
  margin-top: 12px;
  color: colors.$pr-600;
  font: typo.$body_regular;
  color: colors.$pr-500;
}
.subHeader {
  margin-top: 14px;
}

.body {
  position: relative;
  min-height: 0;
  max-height: 100%;
  overflow: hidden;
}

.bodyInner {
  padding: spacing.$xl spacing.$xl spacing.$l;
  height: 100%;

  .panel_condensedLayout & {
    padding: 0 spacing.$m;
  }
}

.bodyInner_noPadding {
  height: 100%;
}

.footer {
  padding: spacing.$l spacing.$xl;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: spacing.$m;

  :global {
    .el-button + .el-button {
      margin-left: 0;
    }
  }

  .panel_hasFixedFooter & {
    border-top: solid 1px colors.$pr-200;
  }
}
</style>
