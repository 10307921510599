<template>
  <Transition
    :enter-active-class="$style.page_enterActive"
    :leave-active-class="$style.page_leaveActive"
    :enter-from-class="$style.page_enterFrom"
    :leave-to-class="$style.page_leaveTo"
  >
    <slot />
  </Transition>
</template>

<style lang="scss" module>
.page_enterActive,
.page_leaveActive {
  transition: opacity 0.3s ease;
}

.page_enterFrom,
.page_leaveTo {
  opacity: 0;
}
</style>
