<template>
  <EditFieldContainerWithButtons
    :is-form-submitting="editProps.isFormSubmitting"
    @save="() => editProps.submitField()"
    @discard="() => editProps.quitEditModeConfirm()"
  >
    <!-- @vue-ignore: v-bind -->
    <ElSelect
      ref="selectRef"
      :model-value="editProps.value"
      :placeholder="editProps.schema.placeholder"
      :persistent="false"
      style="width: 100%"
      clearable
      multiple
      popper-class="dr-popper--inline-control"
      v-bind="editProps.veeField"
    >
      <ElOption
        v-for="opt in editProps.extra.options"
        :key="opt"
        :label="opt"
        :value="opt"
      />
    </ElSelect>
  </EditFieldContainerWithButtons>
</template>

<script setup lang="ts">
import { ElSelect } from "element-plus";
import { onMounted, ref } from "vue";

import EditFieldContainerWithButtons from "./EditFieldContainerWithButtons.vue";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";

interface Props {
  editProps: EditFieldProps;
}

defineProps<Props>();

const selectRef = ref<InstanceType<typeof ElSelect> | null>(null);
onMounted(() => {
  if (!selectRef.value) return;
  selectRef.value.states.hoveringIndex = 0;
  selectRef.value.toggleMenu();
});
</script>
