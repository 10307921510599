import analyticsCardChartHeaderHtml from "./index.html?raw";

Controller.$inject = ["$element"];
import * as d3 from "d3";

angular
  .module("dealroom.analytics.card.chart.header", [
    "dealroom.analytics.dropdown",
    "dealroom.common", // checkbox
  ])
  .component("drAnalyticsCardChartHeader", {
    bindings: {
      name: "<",
      onSelect: "<",
      types: "<",
      count: "<",

      checkboxTitle: "@",
      onCheck: "<",
    },
    template: analyticsCardChartHeaderHtml,
    controller: Controller,
  });

function Controller($element) {
  const $ctrl = this;
  const rootElement$ = d3.select($element[0]);
  const labelElement$ = rootElement$.select("[selected-label]");

  $ctrl.handleSelect = handleSelect;
  $ctrl.$onInit = function () {
    if ($ctrl.types && $ctrl.types.length === 1) {
      handleSelect($ctrl.types);
    }
  };

  function handleSelect([selected]) {
    if (!selected) return;
    setHeader(selected);
    $ctrl.onSelect(selected);
  }

  function setHeader({ title }) {
    labelElement$.text(title);
  }
}
