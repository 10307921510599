<template>
  <ElDropdown v-if="!inArchive" trigger="click" @command="handleExportMenu">
    <ElButton :class="$style.iconBtn">
      <DrIcon name="ellipsis-h" size="lg" />
    </ElButton>
    <template #dropdown>
      <ElDropdownMenu>
        <template v-for="item in menuItems" :key="item.id">
          <ElDropdownItem :command="item.id">
            {{ item.name }}
          </ElDropdownItem>
        </template>
      </ElDropdownMenu>
    </template>
  </ElDropdown>

  <DrToolbarButtonCustomize
    v-if="columns?.length"
    :columns="columns"
    has-fixed
    has-reset
    @update="onTableCustomize"
    @reset="onTableReset"
  />

  <ElButton v-if="!inArchive" type="primary" @click="createFinding">
    New finding
  </ElButton>
</template>

<script setup lang="ts" generic="D">
import { useStore } from "vuex";
import { DrIcon } from "@shared/ui/dr-icon";

import { insightTrack, RoomFindingsOverviewEvent } from "@app/insight";
import DrToolbarButtonCustomize from "@app/vue/shared/ui/dr-toolbar/DrToolbarButtonCustomize.vue";
import { resetColumnsConfig, saveColumnsConfig } from "./utils";

import type { DrVxeTableColumn } from "@drVue/components/types";
import type { CustomViewColumn } from "@setups/types";

const $store = useStore();

interface Props {
  inArchive?: boolean;
  columns?: DrVxeTableColumn<D>[];
}
withDefaults(defineProps<Props>(), {
  inArchive: false,
  columns: undefined,
});

const emit = defineEmits(["create", "import", "export-pdf", "export-xls"]);

const menuItems = [
  // { id: "import", name: "Import" },
  // { id: "export-pdf", name: "Export to PDF" },
  { id: "export-xls", name: "Export to Excel" },
] as const;

const onTableCustomize = (updates: CustomViewColumn[]) => {
  saveColumnsConfig($store, updates);
  insightTrack(RoomFindingsOverviewEvent.LayoutSaved);
};
const createFinding = () => {
  emit("create");
};

const handleExportMenu = (action: (typeof menuItems)[number]["id"]) => {
  emit(action);
};

const onTableReset = () => {
  resetColumnsConfig($store);
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;

.iconBtn:global(.el-button) {
  padding-left: 6px;
  padding-right: 6px;
  width: 32px;
}
</style>
