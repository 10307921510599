<template>
  <ElDrawer
    v-model="isPanelOpened"
    :append-to-body="true"
    :with-header="false"
    class="el-drawer--auto"
    size=""
    @close="close"
  >
    <ContentPanelClose @close="close" />

    <div class="dash-content-panel__container">
      <div class="dash-content-panel-atop">
        <h4 class="dash-content-panel-atop__title">Account Settings</h4>
      </div>
      <section class="dash-content-panel__main">
        <div
          class="dash-content-panel-nav"
          :style="{ 'margin-bottom': activeTab === Tabs.Security ? 0 : '' }"
        >
          <SimpleTabNav
            :tabs="tabs"
            :active-tab="activeTab"
            class="dash-content-panel-nav__grow"
            @tab-click="onTabClick"
          />
        </div>

        <PersonalInfo v-if="activeTab === Tabs.PersonalInfo" />
        <Notifications v-if="activeTab === Tabs.EmailNotifications" />
        <Security v-if="activeTab === Tabs.Security" />
        <ApiTokensList v-if="activeTab === Tabs.PublicApiTokens" />
      </section>
    </div>
  </ElDrawer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ContentPanelClose from "@shared/ui/content-panel/close.vue";
import SimpleTabNav from "@shared/ui/simple-tabnav/index.vue";

import { ORG_MEMBER_DATA } from "@setups/data";
import ApiTokensList from "@drVue/components/client-dashboard/settings/Integrations/ApiTokensList.vue";
import Notifications from "@drVue/components/client-dashboard/users/AccountSettings/tabs/Notifications.vue";
import PersonalInfo from "@drVue/components/client-dashboard/users/AccountSettings/tabs/PersonalInfo.vue";
import Security from "@drVue/components/client-dashboard/users/AccountSettings/tabs/Security.vue";
import { Tabs } from "./types";

import type { PropType } from "vue";

interface Data {
  isPanelOpened: boolean;
  activeTab: Tabs;
}

export default defineComponent({
  name: "AccountSettings",
  components: {
    ApiTokensList,
    Security,
    ContentPanelClose,
    Notifications,
    PersonalInfo,
    SimpleTabNav,
  },
  props: {
    isOpen: {
      required: false,
      default: false,
      type: Boolean as PropType<boolean>,
    },
  },
  emits: ["closed"],
  data(): Data {
    return {
      isPanelOpened: false,
      activeTab: Tabs.PersonalInfo,
    };
  },
  computed: {
    Tabs(): any {
      return Tabs;
    },
    tabs(): Tabs[] {
      const tabs = [Tabs.PersonalInfo, Tabs.EmailNotifications, Tabs.Security];

      if (ORG_MEMBER_DATA.client.enable_public_api) {
        tabs.push(Tabs.PublicApiTokens);
      }

      return tabs;
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(isOpen: boolean) {
        this.isPanelOpened = isOpen;
      },
    },
  },
  methods: {
    onTabClick(tab: Tabs) {
      this.activeTab = tab;
    },
    open(tab: Tabs = Tabs.PersonalInfo) {
      this.activeTab = tab;
      this.isPanelOpened = true;
    },
    close() {
      this.isPanelOpened = false;
      this.$emit("closed");
    },
  },
});
</script>
