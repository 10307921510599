TaskListController.$inject = [
  "$scope",
  "$state",
  "$stateParams",
  "RoomConfig",
  "TasksFilterService",
  "CategoriesService",
  "previousState",
  "resolvedWhenTasksAndCategoriesLoaded",
];
import { watch } from "vue";

import { pinia } from "@drVue/store/pinia";
import { useTasksTableStore } from "@drVue/store/pinia/room/tasksTable/tasksTable";

const tasksTableStore = useTasksTableStore(pinia);
export default function TaskListController(
  $scope,
  $state,
  $stateParams,
  RoomConfig,
  TasksFilterService,
  CategoriesService,
  previousState,
) {
  // open child state if it is not specified
  if ($state.current.name === "tasks.list") {
    // task.list is kinda abstract state, so go to child
    // if user was in progress view, keep it open
    var goToPrevious = previousState === "tasks.list.timeline";
    var nextState = goToPrevious ? previousState : "tasks.list.overview";
    $state.go(nextState, $stateParams);
  }

  if (RoomConfig.enableListsTabs && !$stateParams.listId) {
    $state.go($state.current.name, {
      listId: CategoriesService.rootCategories[0]?.id,
    });
    return;
  }

  tasksTableStore.isActive = true;

  function setCurrentCategory() {
    const currentCategory = $stateParams.listId
      ? CategoriesService.categories[$stateParams.listId]
      : undefined;

    if (currentCategory) CategoriesService.expandTree(currentCategory);

    TasksFilterService.filters.categories = currentCategory
      ? currentCategory.descendants
      : [];
  }

  setCurrentCategory();

  const unwatch = watch(
    () => CategoriesService.loading,
    (isLoading) => {
      if (
        isLoading ||
        CategoriesService.isError ||
        !CategoriesService.categoriesList.length
      ) {
        return;
      }

      setCurrentCategory();
    },
    {
      immediate: true,
    },
  );

  $scope.$on("$destroy", () => unwatch());
}
