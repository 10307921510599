<template>
  <div :class="$style.container" data-testid="title-cell">
    <div :class="$style.containerIcon">
      <DrIcon
        v-if="isFolder(row)"
        name="folder"
        :class="$style.iconFolder"
        size="lg"
      />
      <i v-else-if="isDocument(row)" class="mimetypes" :class="row.iconClass" />
      <i v-else class="mimetypes ico-unknown" />
    </div>

    <ElTooltip
      placement="top"
      :visible="isToolipShown"
      :content="row.name"
      :popper-class="$style.titleTooltip"
    >
      <div ref="titleContainerRef" class="truncate">
        <span
          ref="titleRef"
          @mouseenter="checkAndShowTitleTooltip"
          @mouseleave="hideTitleTooltip"
        >
          {{ row.name }}
        </span>
      </div>
    </ElTooltip>

    <DrTooltip v-if="isAiEnabled" content="Analyze with AI" placement="bottom">
      <ElButton
        size="small"
        plain
        :class="$style.buttonAiReport"
        @click.stop="showMenuAiReport"
        @contextmenu.stop
      >
        <IconAiReport />
      </ElButton>
    </DrTooltip>

    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="textSnippet"
      class="shave"
      :class="[$style.textSnippet]"
      v-html="textSnippet"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, markRaw, ref } from "vue";

import { insightTrack, RoomDataroomMenuEvent } from "@app/insight";
import DocumentsFilterService from "@app/ng/documents/services/DocumentsFilterService";
import { ROOM_DATA } from "@app/setups";
import { ROOM_MEMBER_DATA } from "@app/setups/data";
import { AiAccess } from "@app/setups/enums";
import DrIcon from "@app/vue/shared/ui/dr-icon/DrIcon.vue";
import DrTooltip from "@app/vue/shared/ui/dr-tooltip/DrTooltip.vue";
import {
  type DocsItem,
  isDocument,
  isFolder,
} from "@app/vue/store/modules/room/documents/DocumentsApiService";
import FolderMenuAiDialog from "../components/FolderMenuAiDialog.vue";
import IconAiReport from "../svg/IconAiReport.vue";
import { useFolderMenuBus } from "../use/useFolderMenuBus";

interface Props {
  row: DocsItem;
}

const props = defineProps<Props>();

const folderMenuBus = useFolderMenuBus();

const titleRef = ref<HTMLElement | null>(null);
const titleContainerRef = ref<HTMLElement | null>(null);

const isToolipShown = ref(false);
const textSnippet = computed(() => {
  return DocumentsFilterService.getTextSnippet(props.row);
});

const isAiEnabled = computed(
  () =>
    isDocument(props.row) &&
    props.row.processing?.is_viewable &&
    ROOM_DATA.isAiEnabled &&
    ROOM_MEMBER_DATA.group.ai_access === AiAccess.BASIC,
);

const checkAndShowTitleTooltip = () => {
  if (titleContainerRef.value && titleRef.value) {
    const containerWidth = titleContainerRef.value.clientWidth;
    const titleWidth = titleRef.value.offsetWidth;

    if (containerWidth < titleWidth) {
      isToolipShown.value = true;
    }
  }
};
const hideTitleTooltip = () => {
  isToolipShown.value = false;
};

const showMenuAiReport = () => {
  if (!isDocument(props.row)) return;

  insightTrack(RoomDataroomMenuEvent.AiDialogOpen, { source: "table row" });

  folderMenuBus.emit("menu:show", {
    reference: undefined,
    params: {
      folders: [],
      documents: [props.row],
    },
    menuComponent: markRaw(FolderMenuAiDialog),
  });
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";

.container {
  display: grid;
  grid-template-columns: 24px 1fr auto;
  grid-template-rows: 26px auto;
  align-items: center;
  padding: spacing.$xxs 0;
}

.containerIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: spacing.$xxs;
}

.titleTooltip {
  max-width: 400px;
}

.textSnippet {
  --line-clamp: 4;

  font-size: 12px;
  margin-top: 5px;
  text-wrap: wrap;
  line-height: 1.2;
  color: colors.$pr-500;
  word-break: break-word;
  grid-column: span 3;

  b {
    font-weight: 500;
    color: colors.$pr-900;
    background-color: colors.$ad-7;
    padding: 0 1px;
  }
}

.iconFolder {
  color: colors.$pr-400;
}

.buttonAiReport {
  height: 26px;
  width: 26px;
  color: colors.$pr-400;
  margin-left: spacing.$xs;

  &:global(.el-button:hover) {
    color: colors.$sc-600;
  }

  &:global(.el-button:focus:not(:hover)) {
    color: colors.$pr-400;
  }

  :global(.vxe-body--row:not(.row--hover)) & {
    display: none;

    &.buttonAiReport_applied {
      border: transparent;
      color: colors.$pr-350;
      display: block;
    }
  }
}
</style>
