import * as d3 from "d3";

const FLAT_DURATION = 700;
const FLAT_EASE = d3.easePolyOut.exponent(4);

const RAISE_DURATION = 1500;
const RAISE_EASE = d3.easeBounceOut;

export default {
  flat,
  raise,
};

function flat(selection, params, onEnd) {
  if (selection.empty()) {
    onEnd && onEnd();
    return selection;
  }
  return selection
    .interrupt()
    .transition("flat")
    .duration(FLAT_DURATION)
    .ease(FLAT_EASE)
    .attrTween("opacity", tweenOpacity)
    .on("end", onEnd);

  function tweenOpacity(d, i) {
    const node = d3.select(this);
    const start = node.attr("opacity") === null ? 1 : +node.attr("opacity");

    if (start === 0) return null;

    const end = params.opacity === undefined ? 0 : params.opacity;
    const interpolate = d3.interpolate(start, end);
    const ease = d3.easePolyOut.exponent(0.3);

    return (t) => interpolate(ease(t));
  }
}

function raise(selection) {
  return selection
    .transition("raise")
    .duration(RAISE_DURATION)
    .ease(RAISE_EASE)
    .attrTween("opacity", tweenOpacity)
    .tween("attr.notReached", tweenNotReached);

  function tweenOpacity(d) {
    const node = d3.select(this);
    const start = node.attr("opacity") === null ? 1 : +node.attr("opacity");
    const end = 1;
    if (start === end || d.isUnreachedYet) return null;

    const ease = d3.easePolyOut.exponent(1);
    const interpolate = d3.interpolateNumber(start, end);

    return (t) => interpolate(ease(t));
  }

  function tweenNotReached(d) {
    const node = this;
    const ease = d3.easeCircleOut;

    const start = 1;
    const middle = 0.5;

    const r = node.getAttribute("r");

    const interpolate = d3.interpolateNumber(start, middle);
    return (t) => {
      if (!d.isUnreachedYet) return start;

      const shiftedT = Math.max((t > 0.5 ? 1 - t : t) * 2, 0);
      const easedT = ease(Math.min(shiftedT, 1));

      const value = interpolate(easedT);
      node.setAttribute("stroke-opacity", value);
      node.setAttribute("r", value * r);
    };
  }
}
