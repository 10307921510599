import { computed } from "vue";

import { DrStore } from "@app/vue";

import type { Folder } from "@app/vue/store/modules/room/documents/DocumentsApiService";
import type { Tree, TreeItem } from "@shared/ui/dr-tree";

const mapFolderToNode = (
  folder: Folder,
  disabled: boolean = false,
): TreeItem<{ canParent: boolean; id: string | number; name: string }> => {
  return {
    id: folder.id,
    name: folder.name,
    canParent: !disabled,
    disabled: disabled || undefined,
    children: folder.folders.map((item) => mapFolderToNode(item, false)),
  };
};

export const useFoldersTreeData = () => {
  const tree = computed<
    Tree<{ canParent: boolean; id: string | number; name: string }>
  >(() => {
    const rootFolder = DrStore.state.room.documents.rootFolder;
    if (!rootFolder) return [];

    return [mapFolderToNode(rootFolder)];
  });

  return tree;
};
