import axios from "axios";

import { APP_SETTINGS } from "@setups/data";
import { intercept403, interceptAuthError } from "@setups/urls";
import { deserializeDates, serializeDates } from "@app/vue/api-service/parse";

export const http = axios.create({
  baseURL: location.origin,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: true,
  xsrfHeaderName: "X-CSRFToken",
  xsrfCookieName: APP_SETTINGS.CSRF_COOKIE_NAME,
});

http.interceptors.request.use((config) => {
  serializeDates(config.data);
  return config;
});

http.interceptors.response.use(
  (response) => {
    deserializeDates(response.data);
    return response;
  },
  (error) => {
    if (error.response) {
      interceptAuthError(error.response);
      intercept403(error.response);
    }

    return Promise.reject(error);
  },
);
