<template>
  <div class="dash-timeline-phases">
    <div
      v-for="phase in phases"
      :key="phase.id"
      class="dash-timeline-phases__phase"
    >
      <span
        class="phase-info__circle"
        :style="{ 'background-color': phase.color }"
      />
      {{ phase.name }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import DrStore from "@drVue/store";

export default defineComponent({
  name: "PhasesList",
  computed: {
    phases(): any {
      return DrStore.getters["clientDashboard/phases/phasesWithDeals"];
    },
  },
});
</script>
