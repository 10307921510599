export default function getIconClass(mimetype: string | null | undefined) {
  switch (mimetype) {
    case "application/pdf":
      return "ico-pdf";

    case "image/jpeg":
    case "image/pjpeg":
    case "image/png":
    case "image/gif":
    case "image/x-png":
    case "image/tiff":
    case "image/x-tiff":
    case "image/bmp":
    case "image/vnd.dwg":
      return "ico-image";

    case "image/x-photoshop":
    case "image/vnd.adobe.photoshop":
      return "ico-psd";

    case "application/vnd.oasis.opendocument.graphics":
    case "application/vnd.oasis.opendocument.presentation":
    case "application/mspowerpoint":
    case "application/vnd.ms-powerpoint":
    case "application/powerpoint":
    case "application/x-mspowerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return "ico-presentation";

    case "application/vnd.oasis.opendocument.text":
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "ico-word";

    case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.oasis.opendocument.spreadsheet":
    case "application/vnd.ms-excel":
    case "application/vnd.ms-excel.addin.macroEnabled.12":
    case "application/vnd.ms-excel.sheet.binary.macroenabled.12":
    case "text/csv":
    case "application/excel":
    case "application/x-excel":
    case "application/x-msexcel":
      return "ico-spreadsheet";

    case "multipart/x-zip":
    case "application/zip":
    case "application/x-zip-compressed":
    case "application/x-compressed":
    case "application/x-gtar":
    case "application/x-gzip":
    case "multipart/x-gzip":
      return "ico-zip";

    case "application/octet-stream":
      return "ico-binary";

    case "text/plain":
    case "text/html":
    case "text/xhtml":
    case "text/xml":
    case "text/x-vcard":
    case "application/json":
    case "application/javascript":
    case "application/rtf":
      return "ico-text";

    case "application/postscript":
      return "ico-eps";

    case "video/avi":
    case "video/mpeg":
    case "video/quicktime":
    case "video/x-ms-asf":
    case "video/x-ms-wmv":
    case "video/mp4":
      return "ico-video";

    case "audio/mpeg":
    case "audio/mp3":
    case "audio/mid":
    case "audio/x-wav":
    case "audio/vnd.wav":
    case "audio/x-pn-realaudio":
    case "audio/x-ms-wma":
      return "ico-audio";

    default:
      return "ico-unknown";
  }
}
