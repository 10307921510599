import { h } from "vue";

import DateCell from "@drVue/components/client-dashboard/deals/PhasesTable/cells/DateCell.vue";
import DateEditCell from "@drVue/components/client-dashboard/deals/PhasesTable/cells/DateEditCell.vue";
import PhaseCell from "@drVue/components/client-dashboard/deals/PhasesTable/cells/PhaseCell.vue";

import type { PhasesTableRow } from "@drVue/components/client-dashboard/deals/PhasesTable/models";
import type { DrVxeTableColumn } from "@drVue/components/types";

export default class TableColumns {
  get columns(): DrVxeTableColumn<PhasesTableRow>[] {
    return [this.nameColumn(), this.actualStartColumn()];
  }

  private nameColumn(): DrVxeTableColumn<PhasesTableRow> {
    return {
      field: "phase_name",
      title: "Phase sequence",
      minWidth: 200,
      width: 200,
      slots: {
        default: (params) => [
          h(PhaseCell, {
            row: params.row,
          }),
        ],
      },
    };
  }

  private actualStartColumn(): DrVxeTableColumn<PhasesTableRow> {
    return {
      field: "actual_start_date",
      title: "Start Date",
      treeNode: true,
      minWidth: 250,
      sortBy: ({ row }) => row.actual_start_date?.getTime(),
      editRender: { name: "input", props: { type: "date" } },
      slots: {
        default: (params) => [
          h(DateCell, {
            field: "actual_start_date",
            row: params.row,
            label: "Duration",
            duration: params.row.actual_duration,
            table: params.$table,
          }),
        ],
        edit: (params) => {
          return [
            h(DateEditCell, {
              field: "actual_start_date",
              row: params.row,
              // TODO @dgonchar: is there $table?
              table: (params as any).$table,
            }),
          ];
        },
      },
    };
  }
}
