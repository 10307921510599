import documents_viewErrorDialogHtml from "../../../templates/documents/view-error-dialog.html?raw";

DocumentsService.$inject = [
  "$http",
  "$q",
  "$window",
  "$uibModal",
  "$rootScope",
  "drUserTimeFilter",
  "DownloadFile",
  "DocumentsApiService",
  "RoomConfig",
  "AlertService",
  "URLS",
];
import fuzzaldrinPlus from "fuzzaldrin-plus";
import { defaults } from "lodash-es";

import { insightTrack, RoomDataroomBulkEvent } from "@app/insight";
import { DrStore } from "@drVue";
import {
  deserializeCustomData,
  serializeCustomData,
} from "@drVue/api-service/parse";
import DocumentActivityModalController from "../controllers/DocumentActivityModalController";

export default function DocumentsService(
  $http,
  $q,
  $window,
  $uibModal,
  $rootScope,
  drUserTimeFilter,
  DownloadFile,
  DocumentsApiService,
  RoomConfig,
  AlertService,
  URLS,
) {
  var service = {
    isLoaded: isLoaded,

    //  operations
    syncTree: (skipErrorAlert) =>
      DrStore.dispatch("room/documents/syncTree", skipErrorAlert),

    showDocumentActivity: showDocumentActivityModal,
    bulkDownload: bulkDownloadModal,
    viewDocument: viewDocumentModal,
    documentDownload: documentDownload,
    downloadDialog: downloadModal,
    editCustomDataModal: editCustomDataModal,
    // folder selection is only supported in Chrome via this proprietary attribute for now
    folderUploadSupported:
      document.createElement("input").webkitdirectory !== undefined,
    // eslint-disable-next-line no-useless-escape
    nameReservedCharacter: /^[^\\/:?*"\|<>]*$/i,
    nameReservedCharacterError:
      "A name can't contain any of the following characters: \\ / : * ? \" < > |",
    findItems: findItems,
    searchItemsArray: searchItemsArray,
    getFlattedBookmarkedStorages: getFlattedBookmarkedStorages,
    isDocumentViewable: isDocumentViewable,

    itemIdToRootFolderId: itemIdToRootFolderId,
    getFolderDescendants: getFolderDescendants,
  };

  Object.defineProperties(service, {
    rootFolder: {
      get: () => DrStore.state.room.documents.rootFolder,
    },
    Folders: {
      get: () => DrStore.state.room.documents.folders,
    },
    Files: {
      get: () => DrStore.state.room.documents.files,
    },
    loading: {
      get: () => DrStore.state.room.documents.isLoading,
    },
    error: {
      get: () => DrStore.state.room.documents.isError,
    },
  });

  return service;

  function isLoaded() {
    return service.loading === false && service.error === false;
  }

  function showDocumentActivityModal(document) {
    var modalInstance = $uibModal.open({
      templateUrl: "document-activity.tmpl.html",
      resolve: {
        document: function () {
          return document;
        },
      },
      controllerAs: "ctrl",
      controller: DocumentActivityModalController,
    });
    return modalInstance.result;
  }

  function downloadModal(params) {
    var modalInstance = $uibModal.open({
      templateUrl: "download-dialog.tmpl.html",
      controller: [
        "$scope",
        function ($scope) {
          $scope.close = function () {
            $scope.is_stop = true;
            $scope.$destroy();
          };

          $scope.confirm = function () {
            $scope.is_start = true;
          };

          for (const p in params) {
            if (Object.prototype.hasOwnProperty.call(params, p)) {
              $scope[p] = params[p];
            }
          }
          $scope.is_start = $scope.is_start !== false;
        },
      ],
    });
    return modalInstance.result;
  }

  function editCustomDataModal(item) {
    const modalInstance = $uibModal.open({
      templateUrl: "edit-custom-data.tmpl.html",
      controller: [
        "$scope",
        "$uibModalInstance",
        "RoomConfig",
        function ($scope, $uibModalInstance, RoomConfig) {
          $scope.editItem = {
            ...item.custom_data,
          };
          deserializeCustomData($scope.editItem, RoomConfig.custom_fields);

          $scope.custom_fields = RoomConfig.custom_fields.filter(
            (cf) => cf.object_type === "data_room",
          );
          $scope.itemName = item.name;

          $scope.close = function () {
            $uibModalInstance.dismiss();
          };

          $scope.confirm = function () {
            const saveUrlKey =
              item.type === "folder"
                ? "api:room:folder_detail_custom_data"
                : "api:room:document_detail_custom_data";
            const saveUrl = URLS[saveUrlKey](item.id);

            const req = {
              custom_data: {
                ...$scope.editItem,
              },
            };

            serializeCustomData(req.custom_data, RoomConfig.custom_fields);

            $http.post(saveUrl, req).then(
              (res) => {
                item.custom_data = res.data.custom_data;
                $uibModalInstance.close();
              },
              () => {
                AlertService.danger("Failed to update the custom data!");
              },
            );
          };
        },
      ],
    });

    return modalInstance.result;
  }

  function documentDownload(documentId) {
    const downloadUrl = URLS["api:room:document_download"](documentId),
      initializeUrl = URLS["api:room:document_download_initialize"](documentId);

    var request = $http.post(initializeUrl);

    function onOriginalDownload() {
      DownloadFile(downloadUrl);
    }

    function onWatermarkedDownload(errors) {
      const doc = service.Files[documentId];
      return service.downloadDialog({
        header: "Download watermarked document",
        download_msg: `Your document "${doc.treeIndex} ${doc.name}" is ready to download`,
        pending: "Packaging file...",
        is_start: errors ? false : true,
        errors: errors,
        errorsMsg: "Unable to download file:",
        service: {
          request: function () {
            return request;
          },
          check: function (rdata) {
            var statusCheckUrl = URLS[
              "api:room:document_download_watermark_status"
            ](documentId, rdata.task_id);
            return $http.get(statusCheckUrl);
          },
          download_url: function (rdata) {
            if (!rdata.task_id) return downloadUrl;
            return downloadUrl + "?task_id=" + rdata.task_id;
          },
        },
      });
    }

    request.then(
      function (resp) {
        if (resp.data.task_id) {
          onWatermarkedDownload();
        } else {
          onOriginalDownload();
        }
        return resp;
      },
      function (errorResp) {
        var errors = errorResp.data && errorResp.data.non_field_errors;
        onWatermarkedDownload(errors || ["error"]);
        return errorResp;
      },
    );
  }

  function bulkDownloadModal(folderIds, documentIds, options, sendTrack) {
    if (sendTrack) insightTrack(RoomDataroomBulkEvent.Download);

    options = defaults(options || {}, {
      showSelected: true,
    });
    folderIds = folderIds || [];
    documentIds = documentIds || [];

    if (folderIds.length === 0 && documentIds.length === 1) {
      return documentDownload(documentIds[0]);
    }

    var modalInstance = $uibModal.open({
      templateUrl: "bulk-download-dialog.tmpl.html",
      controller: [
        "$scope",
        function ($scope) {
          $scope.setSelectedItems = function (folderIds, documentIds) {
            $scope.selectedItems = {
              folderIds: folderIds,
              documentIds: documentIds,
            };
          };

          $scope.options = options;
          $scope.is_start = false;
          $scope.settings = {
            needEmail: false,
            bulkWithIndexes: true,
            bulkTruncateLongTitles: true,
            bulkWithFullTree: true,
          };

          $scope.setSelectedItems(folderIds, documentIds);

          $scope.confirm = function () {
            $scope.is_start = true;
            const url = URLS["api:room:documents_download_bulk"]();
            return $http
              .post(url, {
                folder_ids: $scope.selectedItems.folderIds,
                document_ids: $scope.selectedItems.documentIds,
                with_indexes: $scope.settings.bulkWithIndexes,
                with_full_tree: $scope.settings.bulkWithFullTree,
                truncate_long_paths: $scope.settings.bulkTruncateLongTitles,
                need_email: $scope.settings.needEmail,
              })
              .then(
                function (response) {
                  DrStore.dispatch(
                    "room/downloads/getDownloads",
                    response.data.task_id,
                  );

                  modalInstance.close();
                },
                function (error) {
                  AlertService.danger("Failed to initialize bulk download.");
                  $scope.is_start = false;
                },
              );
          };
        },
      ],
    });

    return modalInstance.result;
  }

  function getFlattedBookmarkedStorages() {
    var filesStorage = [];
    var foldersStorage = [];

    Object.keys(service.Files).forEach(function (key) {
      var item = service.Files[key];
      if (item?.bookmarked) {
        filesStorage.push(item);
      }
    });

    Object.keys(service.Folders).forEach(function (key) {
      var item = service.Folders[key];
      if (item?.bookmarked) {
        foldersStorage.push(item);
      }
    });

    return { files: filesStorage, folders: foldersStorage };
  }

  function searchItemsArray(items, query) {
    var biggestScore = 1000000;
    function searchItemScore(item, query) {
      // give founded by index items biggest score
      var itemIndexPos = item.treeIndex.indexOf(query);
      if (itemIndexPos != -1) {
        // if not from begging decrease score
        // scoreIndex('1.2.3', '1.2') > scoreIndex('1.2.3', '2.3')
        return biggestScore - itemIndexPos;
      }

      return fuzzaldrinPlus.score(item.treeIndex + " " + item.name, query);
    }

    if (query) {
      var results = [];
      items.forEach(function (item) {
        var score = searchItemScore(item, query);
        if (score > 0) {
          results.push([item, score]);
        }
      });
      items = results
        .sort(function (a, b) {
          return b[1] - a[1];
        })
        .map(function (res) {
          return res[0];
        });
    }
    return items;
  }

  function findItems(query, filesStorage, foldersStorage) {
    filesStorage = filesStorage || service.Files;
    foldersStorage = foldersStorage || service.Folders;

    function getArray(storage) {
      return Object.keys(storage).map(function (key) {
        return storage[key];
      });
    }

    var files = getArray(filesStorage);
    var folders = getArray(foldersStorage).filter(function (folder) {
      return folder.parent_id; // exclude root folder
    });
    var items = files.concat(folders);
    return searchItemsArray(items, query);
  }

  function isDocumentViewable(document) {
    return document.processing.is_viewable;
  }

  function viewDocumentModal(document) {
    if (!isDocumentViewable(document)) {
      return $uibModal.open({
        template: documents_viewErrorDialogHtml,
        controller: [
          "$scope",
          "$uibModalInstance",
          function ($scope, $uibModalInstance) {
            this.document = document;

            this.download = function () {
              documentDownload(document.id);
              $uibModalInstance.dismiss();
            };
          },
        ],
        controllerAs: "ctrl",
      });
    } else {
      document.lastViewDate = new Date();
      $window.open(document.viewUrl, "_blank");
    }
  }

  function itemIdToRootFolderId({ docId, folderId }) {
    const find = (item) => {
      if (!item) return;
      const parentId = item.folder_id || item.parent_id;
      if (parentId === service.rootFolder.id) {
        if (angular.isDefined(item.folder_id)) return parentId;
        else return item.id;
      } else {
        return find(service.Folders[parentId]);
      }
    };
    return find(
      docId
        ? service.Files[docId]
        : folderId
          ? service.Folders[folderId]
          : null,
    );
  }

  function getFolderDescendants(folderId) {
    const get = (folder) => {
      const childsIds = folder.folders.reduce((bucket, folder) => {
        bucket.push(+folder.id);
        const childsIds = get(folder);
        return bucket.concat(childsIds);
      }, []);
      return childsIds;
    };
    const folder = service.Folders[folderId];
    if (!folder) return [];
    const folderIds = get(folder).concat(+folderId);
    return folderIds;
  }
}
