import { debounce } from "lodash-es";
import * as Sentry from "@sentry/browser";

import { ROOM_DATA } from "@setups/data";
import { documentViewUrl, folderUrl } from "@setups/room-urls";
import { sortUploadItems } from "@app/common/file-upload-helpers";
import uploads_dialogHtml from "./templates/uploads/dialog.html?raw";
import uploads_dropIndicatorHtml from "./templates/uploads/drop-indicator.html?raw";
import { generateUUID } from "./vue/common";

(function () {
  "use strict";
  FileUploadHelpers.$inject = ["drMimetype"];
  FileUploadService.$inject = [
    "$q",
    "$filter",
    "$timeout",
    "$http",
    "$state",
    "URLS",
    "FileUploadHelpers",
    "DocumentsService",
    "Upload",
    "drMimetype",
    "AlertService",
  ];

  angular
    .module("dealroom.uploads", [
      "ngFileUpload",

      "dealroom.config",
      "dealroom.common",
    ])
    .factory("FileUploadService", FileUploadService)
    .factory("FileUploadHelpers", FileUploadHelpers)
    .directive("uploadDropIndicator", uploadDropIndicator)
    .directive("roomUploadDialog", roomUploadDialog);

  function FileUploadService(
    $q,
    $filter,
    $timeout,
    $http,
    $state,
    URLS,
    FileUploadHelpers,
    DocumentsService,
    Upload,
    drMimetype,
    AlertService,
  ) {
    var self = this;
    var helpers = FileUploadHelpers;
    var MAX_CONCURENT_UPLOADS = 5;
    const UPLOAD_CHUNK_SIZE = 5 * 1024 * 1024; // bytes
    const CHUNKED_UPLOAD_THRESHOLD_SIZE = UPLOAD_CHUNK_SIZE * 1.2;
    var FileStates = {
      Pending: "pending",
      Uploading: "uploading",
      Cancelled: "cancelled",
      Resolved: "resolved",
      Rejected: "rejected",
    };

    const syncOnUploadFinishDebounced = debounce(() => {
      DocumentsService.syncTree();
    }, 1000);

    var service = {
      uploadToFolder: uploadToFolder,
      notifyDrag: notifyDrag,
      cancelFileUpload: cancelFileUpload,
      retryFileUpload: retryFileUpload,
      drFileUploadProgressText: drFileUploadProgressText,
      getUploadRejectedFiles: getUploadRejectedFiles,
      dragLabel: undefined, // to show drag indicator
      FileStates: FileStates,
      uploadInitializationRequests: 0,
      uploadingFiles: 0, // files upload in progress
      uploadList: [], // list of items for upload to show in dialog (including folders)
      filesQueue: [], // files waiting for upload
    };

    return service;

    function uploadNextFile() {
      while (
        service.filesQueue.length !== 0 &&
        service.uploadingFiles < MAX_CONCURENT_UPLOADS
      ) {
        var nextFile = service.filesQueue.shift();
        uploadFile(nextFile);
      }
    }

    function isFilesDrag(evt) {
      // check that file objects are dropped
      // https://github.com/danialfarid/ng-file-upload/issues/1477
      if (evt.dataTransfer.types && evt.dataTransfer.items) {
        for (let i = 0; i < evt.dataTransfer.types.length; i++) {
          if (evt.dataTransfer.types[i] === "Files") {
            return true;
          }
        }
      }
      return false;
    }

    function notifyDrag(label, isDragging) {
      if (isDragging) {
        service.dragLabel = label;
      } else {
        service.dragLabel = undefined;
      }
    }

    function handleFileUploadEnd(file, successResp) {
      file.fileUploadEndDefer.resolve(successResp);
      if (successResp) {
        delete file.uploadInfo;
        delete file.fileUploadEndDefer;
      }
    }

    function cancelFileUpload(file) {
      if (file.drState === FileStates.Uploading) {
        file.drState = FileStates.Cancelled;
        return file.upload.abort();
      } else if (file.drState === FileStates.Pending) {
        // remove file from upload queue
        var index = service.filesQueue.indexOf(file);
        service.filesQueue.splice(index, 1);
        file.drState = FileStates.Cancelled;
      }
      handleFileUploadEnd(file);
    }

    function drFileUploadProgressText(file) {
      if (file.drState === FileStates.Uploading) {
        return (
          $filter("byteFmt")(file.drProgressLoaded, 0) +
          " of " +
          $filter("byteFmt")(file.size, 0)
        );
      }
    }

    function retryFileUpload(file) {
      return queueFile(file, file.uploadInfo).then(function () {
        DocumentsService.syncTree();
      });
    }

    function getUploadRejectedFiles() {
      return service.uploadList.filter((file) => {
        return (
          file.type !== "directory" &&
          file.drState === service.FileStates.Rejected
        );
      });
    }

    function uploadFile(file) {
      var uploadUrl = file.uploadInfo.id
        ? URLS["api:room:document_versions"](file.uploadInfo.id)
        : URLS["api:room:folder_documents"](file.uploadInfo.folder_id);
      var data = {
        file: file,
        index: file.uploadInfo.index,
        name: file.uploadInfo.name,
        mimetype: file.uploadInfo.suggested_mimetype,
      };
      if (Array.isArray(file.uploadInfo.notify)) {
        data.group_ids_to_notify = JSON.stringify(file.uploadInfo.notify);
      } else {
        data.notify = file.uploadInfo.notify;
      }
      if (file.uploadInfo.permissions) {
        data.permissions = JSON.stringify(file.uploadInfo.permissions);
      }
      let uploadResumeStart = 0;
      if (file.uploadInfo.uploadConfig) {
        // saved by ng-fileupload in uploadConfig
        uploadResumeStart = file.uploadInfo.uploadConfig._start;
      }
      const uploadConfig = {
        url: uploadUrl,
        data: data,
      };
      if (file.size > CHUNKED_UPLOAD_THRESHOLD_SIZE) {
        data["_chunkFilename"] = file.name;
        data["_chunkUploadID"] = file.uploadInfo.uploadID;
        uploadConfig.resumeChunkSize = UPLOAD_CHUNK_SIZE;
        uploadConfig.resumeSize = function () {
          // support only continue upload on failure
          // not continue after page reload for example
          return $q.when(uploadResumeStart);
        };
      }
      // config is passed as internal to not copy it and remember _start
      var upload = Upload.upload(uploadConfig, true);
      file.uploadInfo.uploadConfig = uploadConfig;
      upload
        .then(
          function onSuccess(resp) {
            var fileInfo = resp.data;

            file.url = documentViewUrl(ROOM_DATA.url, fileInfo.id);
            file.displayIndex = fileInfo.index;
            file.uploadInfo = fileInfo.upload_information;
            file.displayName = fileInfo.name;

            file.drState = FileStates.Resolved;
            handleFileUploadEnd(file, resp);
          },
          function onError(resp) {
            if (file.drState !== FileStates.Cancelled) {
              file.drState = FileStates.Rejected;

              Sentry.captureEvent({
                message: "File upload failed",
                extra: {
                  progress: file.drProgressLoadedPercent,
                  info: file.uploadInfo,
                  loaded: file.drProgressLoaded,
                  name: file.name,
                  displayName: file.displayName,
                  drState: file.drState,
                  data: resp.data,
                },
                level: "error",
              });
            }
            file.fileUploadEndDefer.resolve();
            handleFileUploadEnd(file);
          },
          function onNotify(evt) {
            // ignore payload (folderpath, filename) in request and show only file size
            // payload is small (some kb), so it is not important
            // but showing payload size can lead to changing file size in interface for small files
            var diff = evt.total - file.size;
            var loaded = Math.max(evt.loaded - diff, 0);
            file.drProgressLoaded = loaded;
            file.drProgressLoadedPercent = (loaded / file.size) * 100;
          },
        )
        .finally(function () {
          service.uploadingFiles -= 1;
          delete file.upload;
          uploadNextFile();
        });

      service.uploadingFiles += 1;
      file.upload = upload;
      file.drState = FileStates.Uploading;
    }

    function queueFile(file, info, prior) {
      if (service.uploadList.indexOf(file) === -1) {
        service.uploadList.push(file);
      }

      file.fileUploadEndDefer = $q.defer();
      file.uploadInfo = info;
      file.displayName = file.name; // we can not overwrite original name
      file.drState = FileStates.Pending;
      file.drProgressLoaded = 0;
      file.drProgressLoadedPercent = 0;
      var mimetype = file.type || info["suggested_mimetype"];
      file.drIcon = drMimetype.getIconClass(mimetype);

      if (info.error && info.error.length !== 0) {
        // it's a little sloppy, but I know that we going to abandon max upload size in future
        // and for now I think we can process this error in such way with no additional logic for back end
        file.drState =
          info.error[0].indexOf("File is too big") > -1
            ? FileStates.Cancelled
            : FileStates.Rejected;
        file.errorInfo = info.error;
        file.fileUploadEndDefer.resolve();
        uploadNextFile();
        return file.fileUploadEndDefer.promise;
      }

      if (service.filesQueue.indexOf(file) === -1) {
        if (prior) {
          service.filesQueue.unshift(file);
        } else {
          service.filesQueue.push(file);
        }
      }
      uploadNextFile();
      return file.fileUploadEndDefer.promise;
    }

    function queueFolder(folder) {
      folder.drIcon = "icon_folder";
      folder.type = "directory";
      folder.uploadInfo = folder.upload_information;

      if (folder.error) {
        folder.drState = FileStates.Rejected;
        folder.displayName = folder.path;
        folder.errorInfo = folder.error;
      } else {
        folder.url = folderUrl(ROOM_DATA.url, folder.id);
        folder.displayIndex = folder.index;
        folder.drState = FileStates.Resolved;
        folder.displayName = folder.name;
      }
      service.uploadList.push(folder);
    }

    function initializeUpload(folderId, files, defaultPermissions) {
      var data = helpers.preProcessFiles(files);
      var docsInfo = data.documents.map(function (fileData) {
        return {
          path: fileData.path,
          name: fileData.name,
          size: fileData.size,
        };
      });
      var foldersInfo = data.folders;
      const uploadInitializeUrl =
        URLS["api:room:folder_upload_initialize"](folderId);
      service.uploadInitializationRequests += 1;
      return $http
        .post(uploadInitializeUrl, {
          folders: foldersInfo,
          files: docsInfo,
          permissions: defaultPermissions,
        })
        .then(
          function (resp) {
            return resp.data;
          },
          function (errorResp) {
            var detail = errorResp.data && errorResp.data.detail;
            if (!detail) {
              detail = "";
              if (
                errorResp.data.files &&
                errorResp.data.files.non_field_errors
              ) {
                detail +=
                  " " + errorResp.data.files.non_field_errors.join(", ");
              }
              if (
                errorResp.data.folders &&
                errorResp.data.folders.non_field_errors
              ) {
                detail +=
                  " " + errorResp.data.folders.non_field_errors.join(", ");
              }
            }
            var errorText = "Upload initialization is failed. " + detail;
            AlertService.danger(errorText);
            return $q.reject(errorResp);
          },
        )
        .finally(function () {
          service.uploadInitializationRequests -= 1;
        });
    }

    function uploadToFolder(folderId, files, overridePermissions, notify) {
      service.dragLabel = undefined;
      notify = angular.isUndefined(notify) ? true : notify;

      if (!files || files.length === 0) return;

      return initializeUpload(folderId, files, overridePermissions).then(
        function (info) {
          info.folders.forEach(queueFolder);

          var validFiles = files.filter(function (file) {
            if (helpers.isDirectory(file)) return;
            return helpers.validateFilename(file);
          });

          var uploadPromises = validFiles.map(function (file) {
            var filePath = helpers.getFileRelativePath(file);
            var fileInfo = info.files[filePath][file.name];
            fileInfo.permissions = info.permissions;
            fileInfo.notify = notify;
            fileInfo.uploadID = generateUUID();
            return queueFile(file, fileInfo);
          });

          if (uploadPromises.length === 0) {
            // all files invalid or empty folder selected
            syncOnUploadFinishDebounced();
            return;
          }
          uploadNextFile();
          // promise is resolved even on some file cancel/failure
          return $q.all(uploadPromises).then(function (fileResults) {
            syncOnUploadFinishDebounced();

            return {
              info: info, // initialization information (paths)
              files: fileResults, // files result
            };
          });
        },
      );
    }
  }

  function FileUploadHelpers(drMimetype) {
    var pathSep = "/";
    var dissalowFilenames = /(\.|\.DS_Store|\.empty|Thumbs\.db|desktop\.ini)$/i;

    return {
      preProcessFiles: preProcessFiles,
      getFileRelativePath: getFileRelativePath,
      splitToBatches: splitToBatches,
      isDirectory: isDirectory,
      validateFilename: validateFilename,
      renameBatch: renameBatch,
    };

    function isDirectory(item) {
      // ng-file-upload specific check
      return item.type === "directory";
    }

    function dirname(path) {
      // eslint-disable-next-line no-useless-escape
      return path.replace(/\\/g, "/").replace(/\/[^\/]*$/, "");
    }

    function validateFilename(file) {
      return !dissalowFilenames.test(file.name);
    }

    function getFilePath(file) {
      return file.path || file.webkitRelativePath;
    }

    function setFilePath(file, path) {
      if (!isDirectory(file)) {
        file.path = path;

        Object.defineProperties(file, {
          webkitRelativePath: {
            value: path,
          },
        });
      }
    }

    function getFileRelativePath(file) {
      var relativePath = "";
      if (file.relativePath) {
        // get the folder path
        relativePath = file.relativePath;
      } else if (getFilePath(file)) {
        // file.path can be added by ng-file-upload
        var path = getFilePath(file);
        relativePath = dirname(path);
      }
      // remove leading and trailing slash
      relativePath = relativePath.replace(/^\//g, "").replace(/\/$/g, "");
      return relativePath;
    }

    function preProcessFiles(items) {
      const directories = items.reduce((acc, item) => {
        if (isDirectory(item)) acc.push(item.path);

        return acc;
      }, []);

      if (directories.length === 0) {
        items.forEach(function (file) {
          var relPath = getFileRelativePath(file);
          if (relPath && directories.indexOf(relPath) === -1) {
            directories.push(relPath);
          }
        });
      }

      var folders = directories.map((path) => ({ path: path }));
      var filesInfo = [];
      items.forEach(function (file) {
        if (isDirectory(file) || !validateFilename(file)) return;
        var relPath = getFileRelativePath(file);
        filesInfo.push({
          file: file,
          path: relPath,
          name: file.name,
          size: file.size,
        });
      });
      return {
        folders: sortUploadItems(folders),
        documents: sortUploadItems(filesInfo),
      };
    }

    function splitToBatches(files) {
      // split given list of files/folders to upload into multiple by unique root item
      var id = 1;
      var batches = [];
      var uploadData = preProcessFiles(files);
      var docsInfo = uploadData.documents;
      var pathParents = getParentPaths(uploadData.folders);
      var pathChildren = getPathChildren(pathParents);

      var rootFolders = getRootPaths(pathParents);
      var rootFiles = docsInfo.filter(function (doc) {
        return doc.path === "";
      });

      rootFiles.forEach(function (docInfo) {
        batches.push({
          id: id++,
          type: "file",
          name: docInfo.name,
          originalName: docInfo.name,
          items: [docInfo.file],
          iconClass: drMimetype.getIconClass(docInfo.file.type),
        });
      });

      function getNestedPath(path) {
        var paths = [path];
        (pathChildren[path] || []).forEach(function (child) {
          paths = paths.concat(getNestedPath(child));
        });
        return paths;
      }

      rootFolders.forEach(function (rootPath) {
        var batchPaths = getNestedPath(rootPath);
        var fakeDirs = batchPaths.map(function (path) {
          return {
            type: "directory",
            name: path,
            path: path,
          };
        });
        var batchFiles = [];
        docsInfo.filter(function (docInfo) {
          if (batchPaths.indexOf(docInfo.path) === -1) {
            return;
          }
          batchFiles.push(docInfo.file);
        });

        batches.push({
          id: id++,
          type: "folder",
          name: rootPath,
          originalName: rootPath,
          items: fakeDirs.concat(batchFiles),
          iconClass: "ico-folder",
        });
      });

      return batches;
    }

    function getParentPaths(folders) {
      // return obj there each path associated with parent path
      // NB. add missed parts of tree, like in case of paths=['1', '1/2/3'] add '1/2' as well
      var pathsTree = {}; //path: parent path
      function addPath(path) {
        if (path.length === 0) return;

        var parent = path.split(pathSep).slice(0, -1).join(pathSep);
        if (!Object.prototype.hasOwnProperty.call(pathsTree, parent)) {
          addPath(parent);
        }
        pathsTree[path] = parent;
      }

      folders.forEach(function (folder) {
        addPath(folder.path);
      });

      return pathsTree;
    }

    function getRootPaths(pathParents) {
      return Object.keys(pathParents).filter(function (path) {
        return pathParents[path] === "";
      });
    }

    function getPathChildren(pathParents) {
      var children = {};
      Object.keys(pathParents).forEach(function (child) {
        var parent = pathParents[child];
        if (parent === "") return;
        children[parent] = (children[parent] || []).concat([child]);
      });
      return children;
    }

    function replaceRootName(item, newRootName) {
      var path = isDirectory(item) ? item.path : getFilePath(item);
      var remainingPath = path.split(pathSep).slice(1).join(pathSep);
      var newPath = remainingPath
        ? newRootName + pathSep + remainingPath
        : newRootName;
      if (isDirectory(item)) {
        item.path = newPath;
        item.name = newPath;
      } else {
        setFilePath(item, newPath);
      }
    }

    function renameBatch(batch, newName) {
      // rename root in batch and folders/files relative path in case of folder batch
      batch.originalName = newName;
      batch.name = newName;
      if (batch.type === "file") {
        var rootDocument = batch.items[0];
        // name property is readonly, this hack to overwrite
        Object.defineProperties(rootDocument, {
          name: {
            value: newName,
          },
        });
      } else {
        batch.name = newName;
        batch.items.forEach(function (item) {
          replaceRootName(item, newName);
        });
      }
    }
  }

  function roomUploadDialog() {
    return {
      template: uploads_dialogHtml,
      restrict: "E",
      scope: {},
      controllerAs: "ctrl",
      bindToController: true,
      controller: [
        "$scope",
        "FileUploadService",
        function roomUploadController($scope, FileUploadService) {
          var self = this;
          self.FileUploadService = FileUploadService;
          self.FileStates = FileUploadService.FileStates;
          self.minimized = false;
          self.hidden = true;
          self.failedFilesCount = 0;
          self.uploadDialogVisible = uploadDialogVisible;
          self.allUploadsCompleted = allUploadsCompleted;
          self.retryAllFailedUploads = retryAllFailedUploads;

          $scope.$watch(
            "ctrl.FileUploadService.uploadList.length",
            function (newValue) {
              if (newValue) {
                self.hidden = false;
              }
            },
          );

          $scope.$watch(
            () => {
              if (uploadDialogVisible()) {
                return FileUploadService.getUploadRejectedFiles().length;
              }
              return self.failedFilesCount;
            },
            (failedFilesCount) => {
              self.failedFilesCount = failedFilesCount;
            },
          );

          function retryAllFailedUploads() {
            return FileUploadService.getUploadRejectedFiles().forEach(
              (file) => {
                FileUploadService.retryFileUpload(file);
              },
            );
          }

          function uploadDialogVisible() {
            return !allUploadsCompleted() || !self.hidden;
          }

          function allUploadsCompleted() {
            return (
              FileUploadService.uploadingFiles === 0 &&
              FileUploadService.filesQueue.length === 0 &&
              FileUploadService.uploadInitializationRequests === 0
            );
          }
        },
      ],
    };
  }

  function uploadDropIndicator() {
    return {
      template: uploads_dropIndicatorHtml,
      restrict: "E",
      scope: {},
      controllerAs: "ctrl",
      bindToController: true,
      controller: [
        "FileUploadService",
        function uploadDropController(FileUploadService) {
          this.FileUploadService = FileUploadService;
        },
      ],
    };
  }
})();
