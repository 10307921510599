import { createPinia } from "pinia";

// We have two apps: Vue 3 and Angular. Angular have ngVueLinker that creates a
// Vue app per component. At the moment we do not know will we need this or not
// but probably yes. This is a singleton-shared instance of the Pinia's root
// store that will be used in Vue and ngVueLinker to share all the data across
// all the stores.
//
// You MUST (as in RFC2119) import this instance for Vue.use()
export const pinia = createPinia();
