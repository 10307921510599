<template>
  <DrPopup
    ref="popupRef"
    paddingless
    @show="checkPassedPriorities"
    @hide="$emit('hide')"
  >
    <template #default="{ hide }">
      <DrPanelWrapper title="Set priority" :width="432">
        <template #default>
          <div :class="$style.optionsWrapper">
            <DrPopupSelectOptions
              type="iconsWithCheckbox"
              :items="options"
              @select="togglePriority"
            >
              <template #icon="{ option }">
                <DrIconPriority :priority="option.id as PriorityValue" />
              </template>
            </DrPopupSelectOptions>
          </div>
        </template>

        <template #footer-right>
          <ElButton size="small" @click="hide"> Cancel </ElButton>
          <ElButton size="small" type="primary" @click="submitModelValue(hide)">
            Save
          </ElButton>
        </template>
      </DrPanelWrapper>
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { capitalize, computed, ref } from "vue";
import DrIconPriority, {
  type PriorityValue,
} from "@shared/ui/dr-icon-priority";
import { DrPanelWrapper } from "@shared/ui/dr-panels";
import {
  DrPopup,
  DrPopupSelectOptions,
  type SelectOptionItem,
} from "@shared/ui/dr-popups";

import { insightTrack, RoomRequestsBulkEvent } from "@app/insight";
import {
  TASK_PRIORITY,
  type TaskPriority,
} from "@drVue/store/pinia/room/tasks";

interface Props {
  priorities: (TaskPriority | undefined)[];
}

interface Emits {
  (event: "submit", payload: TaskPriority): void;
  (e: "hide"): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const popupRef = ref<InstanceType<typeof DrPopup>>();

const selectedPriority = ref<TaskPriority>("low");

const checkPassedPriorities = () => {
  if (props.priorities.every((p) => p === "low")) {
    selectedPriority.value = "low";
  } else if (props.priorities.every((p) => p === "medium")) {
    selectedPriority.value = "medium";
  } else if (props.priorities.every((p) => p === "high")) {
    selectedPriority.value = "high";
  } else {
    selectedPriority.value = "low";
  }
};

const options = computed<SelectOptionItem<TaskPriority>[]>(() => {
  return TASK_PRIORITY.map((item) => ({
    id: item,
    name: capitalize(item),
    isSelected: item === selectedPriority.value,
  }));
});

const togglePriority = (option: TaskPriority) => {
  selectedPriority.value = option;
};

const submitModelValue = (hidePopup: () => void) => {
  emit("submit", selectedPriority.value);
  hidePopup();
  insightTrack(RoomRequestsBulkEvent.PriorityUpdate);
};

const show = (reference: HTMLElement) => {
  popupRef.value?.show(reference);
};

defineExpose({ show });
</script>

<style lang="scss" module>
@use "@app/styles/scss/spacing";

.optionsWrapper {
  position: relative;
  width: 408px;
  padding: spacing.$s;
}
</style>
