import { Document } from "flexsearch";
import { uniq } from "lodash-es";

import type { Document as DocumentType, Id, StoreOption } from "flexsearch";
import type { SimpleDocumentSearchResultSetUnit } from "flexsearch";

export { Document };

function flatFlexResultsToArray(
  searchResults: SimpleDocumentSearchResultSetUnit[],
) {
  const ids: Id[] = [];

  for (const searchResult of searchResults) {
    for (const id of searchResult.result) {
      ids.push(id);
    }
  }

  return uniq(ids);
}

export function flexSearchAllResultsArray<T, Store extends StoreOption = false>(
  document: DocumentType<T, Store>,
  query: string,
) {
  // returns array of all results
  // we do not limit the number of results here https://github.com/nextapps-de/flexsearch#limit--offset
  return flatFlexResultsToArray(
    document.search(query, { limit: Number.MAX_SAFE_INTEGER }),
  );
}
