<template>
  <ElSelect
    style="width: 100%"
    :persistent="true"
    :model-value="editProps.value"
    :disabled="editProps.schema.isReadOnly"
    :placeholder="editProps.schema.placeholder"
    v-bind="editProps.veeField"
  >
    <template #prefix>
      <DrIconStatus
        v-if="currentStatus"
        size="sm"
        :color="currentStatus.color"
      />
    </template>

    <ElOption
      v-for="status in statuses"
      :key="status.id"
      :label="status.name"
      :value="status.id"
    >
      <DrIconStatus size="sm" :color="status.color" />
      <span :class="$style.label">{{ status.name }}</span>
    </ElOption>
  </ElSelect>
</template>

<script setup lang="ts">
import { ElSelect } from "element-plus";
import { computed } from "vue";
import DrIconStatus from "@shared/ui/dr-icon-status";

import DrStore from "@drVue/store";

import type { TaskStatus } from "@drVue/store/modules/room/tasks-statuses/types";
import type { FieldProps } from "@shared/ui/dr-dynamic-form/types";

interface Props {
  editProps: FieldProps;
}

const props = defineProps<Props>();

const statuses: TaskStatus[] = DrStore.state.room.tasksStatuses.list;

const currentStatus = computed<TaskStatus>(() => {
  return DrStore.getters["room/tasksStatuses/byId"](props.editProps.value);
});
</script>

<style module lang="scss">
.label {
  margin-left: 10px;
}
</style>
