const columns = [
  {
    title: "",
    columns: [
      { title: "Index", data_index: "index", width: 12 },
      { title: "Name", data_index: "name", width: 35 },
      { title: "Type", data_index: "type", width: 10 },
    ],
  },
  {
    title: "Documents Views",
    columns: [
      {
        title: "Time (min)",
        data_index: "document_viewed:time",
        width: 12,
        float_precision: 0,
      },
      {
        title: "Users Viewed",
        data_index: "document_viewed:uniq:user",
        width: 16,
      },
      {
        title: "Documents Views",
        data_index: "document_viewed:total",
        width: 18,
      },
    ],
  },
  {
    title: "Documents Transferred",
    columns: [
      {
        title: "Down",
        data_index: "document_download,document_bulk_download:total",
        width: 12,
      },
      {
        title: "Up",
        data_index: "document_upload,document_overwrite:total",
        width: 12,
      },
    ],
  },
  {
    title: "",
    columns: [
      { title: "Uploader", data_index: "user", width: 20 },
      { title: "Upload Date", data_index: "date_created", width: 20 },
    ],
  },
];
export default columns;
