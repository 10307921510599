<template>
  <ElButton plain :loading="isSubmitting" :class="$style.container">
    <ElColorPicker
      :model-value="color || undefined"
      :predefine="predefine"
      :class="$style.picker"
      @change="handleColorChange"
    />

    <div v-show="!isSubmitting" :class="$style.preview" />
    <div :class="$style.value">{{ `${color}` }}</div>
  </ElButton>
</template>

<script setup lang="ts">
/**
 * Color picker by style guide.
 * Based on element-plus component
 * https://element-plus.org/en-US/component/color-picker.html
 */
import { ElColorPicker } from "element-plus";
import { computed } from "vue";

interface Props {
  color: string | null;
  predefine?: string[];
  isSubmitting?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  isSubmitting: false,
  predefine: () => [],
});

const emit = defineEmits<{
  (event: "change", color: Props["color"]): void;
}>();

const backgroundColor = computed(() => props.color);

const handleColorChange = (newColor: string | null) => {
  emit("change", newColor);
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

$space: 8px;

.container:global(.el-button) {
  --el-button-text-color: #{colors.$pr-800};

  position: relative;
  padding-left: 6px;

  > span {
    gap: $space;
  }
}

.container {
  :global {
    /**
     * Unfortunately, we cannot fully customize the appearance of the `ElColorPicker` to fit our style.
     * And there is also no way to open the picker from the outside
     * (there is no exposed "open" method or state to open picker dialog).
     * That is why we use our own display component, 
     * and call the color selection dialog from the ElColorPicker.
     */
    .el-color-picker,
    .el-color-picker__trigger {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
}

.preview {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  overflow: hidden;
  border-radius: 4px;
  border: solid 1px #f1f1f1;
  background: linear-gradient(
    135deg,
    #f1f1f1 0%,
    #f1f1f1 15%,
    #ffffff 16%,
    #ffffff 35%,
    #f1f1f1 36%,
    #f1f1f1 55%,
    #ffffff 56%,
    #ffffff 75%,
    #f1f1f1 76%,
    #f1f1f1 95%
  );

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: v-bind("backgroundColor");
  }
}
.value {
  width: 64px;
  text-transform: uppercase;
}
</style>
