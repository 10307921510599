import { h } from "vue";

import RevokeCell from "@app/vue/components/client-dashboard/settings/Integrations/cells/RevokeCell.vue";
import { drUserTime } from "@drVue/filters/drUserTime";

import type { ApiToken } from "@app/vue/store/modules/client-dashboard/intergrations/TokensApiService";
import type { DrVxeTableColumn } from "@drVue/components/types";

export default class TableColumns {
  get columns(): DrVxeTableColumn<ApiToken>[] {
    return [
      this.suffixColumn(),
      this.lastUsedColumn(),
      this.dateCreatedColumn(),
      this.dateRevokedColumn(),
      this.revokeColumn(),
    ];
  }

  private suffixColumn(): DrVxeTableColumn<ApiToken> {
    return {
      field: "key_suffix",
      title: "Suffix",
      minWidth: 100,
      width: 100,
    };
  }

  private lastUsedColumn(): DrVxeTableColumn<ApiToken> {
    return {
      field: "last_used",
      title: "Last Used At",
      slots: {
        default: ({ row }: any) => {
          const text = drUserTime(row.last_used, "full-dt") as string;
          return [text];
        },
      },
    };
  }

  private dateCreatedColumn(): DrVxeTableColumn<ApiToken> {
    return {
      field: "date_created",
      title: "Created At",
      slots: {
        default: ({ row }: any) => {
          const text = drUserTime(row.date_created, "full-dt") as string;
          return [text];
        },
      },
    };
  }

  private dateRevokedColumn(): DrVxeTableColumn<ApiToken> {
    return {
      field: "date_revoked",
      title: "Revoked At",
      slots: {
        default: ({ row }: any) => {
          const text = drUserTime(row.date_revoked, "full-dt") as string;
          return [text];
        },
      },
    };
  }

  private revokeColumn(): DrVxeTableColumn<ApiToken> {
    return {
      field: "id",
      title: "",
      slots: {
        default: ({ row }) => {
          return [h(RevokeCell, { row })];
        },
      },
    };
  }
}
