import { defineComponent } from "vue";
import VueTagsInput from "@sipec/vue3-tags-input";

import parse from "@drVue/components/client-dashboard/users/AddUserModal/emails-parser";

export default defineComponent({
  extends: VueTagsInput,

  methods: {
    addTagsFromPaste(e: ClipboardEvent) {
      const $this: any = this as any;

      if (!$this.addFromPaste) return;

      // VueTagsInput use <input type="text" /> for the tags input. Therefore when user copy-paste something the value
      // that we receive via v-model ($this.newTag) doesn't have newlines. That is why we're hacking it here.
      const text = e.clipboardData && e.clipboardData.getData("text/plain");

      setTimeout(() => {
        // Original version
        // $this.performAddTags($this.newTag);
        //
        // In setTimeout to handle native input events and update $this.newTag value.
        $this.performAddTags(text);
      }, 10);
    },
    createTagTexts(string: string): { text: string }[] {
      const result = parse(string);
      return result.parsed
        .concat(result.errors)
        .map((text: string) => ({ text }));
    },
  },
});
