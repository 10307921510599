<template>
  <div :class="$style.date">
    {{ dateString }}
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import { drUserTime } from "@drVue/filters/drUserTime";

interface Props {
  date: Date | null;
}

const props = defineProps<Props>();

const dateString = computed(() =>
  props.date ? drUserTime(props.date, "short-date") : "",
);
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.date {
  font: typography.$body_regular;
  color: colors.$pr-900;
}
</style>
