<template>
  <DynamicForm :entity="deal" :schema="schema" :submit-fn="onSubmit" />
</template>

<script setup lang="ts">
import { reactive } from "vue";

import { DrStore } from "@app/vue";
import { markDisabledCustomFieldOptions } from "@drVue/components/client-dashboard/deals/utils";
import DynamicForm from "@drVue/components/client-dashboard/dynamic-form/DynamicForm.vue";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import { mapCustomFieldToSchema } from "@drVue/components/client-dashboard/dynamic-form/utils";
import { date } from "@drVue/components/client-dashboard/dynamic-form/yup";

import type { FieldItem } from "@drVue/api-service/client-dashboard/types";
import type { FormSchema } from "@drVue/components/client-dashboard/dynamic-form/types";
import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";

const props = defineProps<{
  deal: Deal;
  editable: boolean;
}>();

const onSubmit = (value: any) => {
  return DrStore.dispatch("clientDashboard/deals/patchDeal", {
    dealId: props.deal.id,
    updates: value,
  });
};

const customFields = reactive<FieldItem[]>(
  markDisabledCustomFieldOptions(
    DrStore.getters["clientDashboard/customFields/byObjectType"]("deal"),
  ),
);

const customFieldsSchemas = customFields.map((customField: FieldItem) => {
  const fieldSchema = mapCustomFieldToSchema(customField);
  fieldSchema.isReadOnly = !props.editable;

  return fieldSchema;
});

const schema: FormSchema = {
  fields: [
    {
      type: FieldSchemaType.Date,
      prop: "start_date",
      label: "Start date",
      rules: date().required("Start date is required"),
      isReadOnly: !props.editable,
    },
    ...customFieldsSchemas,
  ],
};
</script>
