<template>
  <DrMainMenuList v-if="hasBillingIssues">
    <DrMainMenuBillingPopup
      :billing-info="billingInfo"
      :website-name="props.websiteName"
      @activate="emit('billing-activate')"
    >
      <DrMainMenuItem
        :text="menuTitle"
        :icon="menuIcon"
        :type="menuType"
        :is-expanded="isExpanded"
        :class="$style.trialItem"
      />
    </DrMainMenuBillingPopup>
  </DrMainMenuList>
</template>

<script setup lang="ts">
import { computed } from "vue";

import DrMainMenuBillingPopup from "./DrMainMenuBillingPopup.vue";
import DrMainMenuItem from "./DrMainMenuItem.vue";
import DrMainMenuList from "./DrMainMenuList.vue";
import { MainMenuItemStyle } from "./types";

import type { MainMenuBillingInfo } from "./types";

interface Props {
  billingInfo: MainMenuBillingInfo;
  websiteName: string;
  isExpanded: boolean;
}
const props = withDefaults(defineProps<Props>(), {});
const emit = defineEmits(["billing-activate"]);

const menuIcon = computed(() => {
  return props.billingInfo.isAccessible ? "clock" : "exclamation-triangle";
});

const menuType = computed<MainMenuItemStyle>(() => {
  return props.billingInfo.isAccessible
    ? MainMenuItemStyle.Warning
    : MainMenuItemStyle.Error;
});
const menuTitle = computed(() => {
  const info = props.billingInfo;
  if (info.subscriptionWarning) {
    return info.subscriptionWarning.title;
  }
  return "Subscription Expired";
});

const hasBillingIssues = computed(() => {
  const billingInfo = props.billingInfo;
  return !billingInfo.isAccessible || billingInfo.subscriptionWarning !== null;
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography" as typo;

.trialItem {
  margin-bottom: 4px;
}
</style>
