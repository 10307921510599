import { differenceWith } from "lodash-es";

import { DrStore } from "@app/vue";
import { pinia } from "@drVue/store/pinia";
import {
  type BulkAttachemntsRequest,
  type DocumentId,
  type DocumentIdPaged,
  type FolderId,
  type Task,
  TasksApiService,
  useTasksStore,
} from "@drVue/store/pinia/room/tasks";

import type { UploadToFolderResponse } from "@app/ng/serviceProxies";
import type { PartiallyRequired } from "@app/vue/types";

const tasksStore = useTasksStore(pinia);
const api = new TasksApiService();

export default class TasksAttachmentsService {
  attachUploadBatchResult(task: Task, result: UploadToFolderResponse) {
    const folders = result.info.folders.map((f: any) => ({ folder_id: f.id }));
    const documents = result.files.map((file: any) => ({
      document_id: file.data.id,
      page: null,
    }));

    if (!folders.length && !documents.length) return Promise.resolve();

    return this.createAttachments(task, folders, documents);
  }

  request<FId extends FolderId>(
    task: Task,
    createDocs: PartiallyRequired<DocumentIdPaged, "document_id">[],
    createFolders: FId[],
    deleteDocs: PartiallyRequired<DocumentIdPaged, "document_id">[],
    deleteFolders: FId[],
  ) {
    const request: BulkAttachemntsRequest = {
      create: {
        documents: createDocs.map<DocumentIdPaged>((d) => ({
          document_id: d.document_id,
          page: d.page || null,
        })),
        folders: createFolders.map<FolderId>((f) => ({
          folder_id: f.folder_id,
        })),
      },
      delete: {
        documents: deleteDocs.map<DocumentIdPaged>((d) => ({
          document_id: d.document_id,
          page: d.page || null,
        })),
        folders: deleteFolders.map<FolderId>((f) => ({
          folder_id: f.folder_id,
        })),
      },
    };

    return api.bulkAttachments(task.id, request).then((r) => {
      task.folders = r.folders;
      task.documents = r.documents;

      DrStore.dispatch("room/tasksRelated/update", tasksStore.tasksList);
    });
  }

  createAttachments(task: Task, folders: FolderId[], documents: DocumentId[]) {
    return this.request(task, documents, folders, [], []);
  }

  updateAttachments(task: Task, folders: FolderId[], documents: DocumentId[]) {
    const origDocs = task.documents;
    const origFolders = task.folders;

    const docsDiff = (a: DocumentId, b: DocumentId) =>
      Number(a.document_id) === Number(b.document_id);
    const foldersDiff = (a: FolderId, b: FolderId) =>
      Number(a.folder_id) === Number(b.folder_id);

    const createDocs = differenceWith(documents, origDocs, docsDiff);
    const deleteDocs = differenceWith(origDocs, documents, docsDiff);

    const createFolders = differenceWith(folders, origFolders, foldersDiff);
    const deleteFolders = differenceWith(origFolders, folders, foldersDiff);

    return this.request(
      task,
      createDocs,
      createFolders,
      deleteDocs,
      deleteFolders,
    );
  }
}
