import { type EventBusKey, useEventBus } from "@vueuse/core";

import type { Document } from "@drVue/store/modules/room/documents/DocumentsApiService";

export interface PageNavigationPayload {
  docId: Document["id"];
  page: number;
}

const pageNavigationBusKey: EventBusKey<PageNavigationPayload> = Symbol();

export const usePageNavigationBus = () =>
  useEventBus<never, PageNavigationPayload>(pageNavigationBusKey);
