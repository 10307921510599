export function openFilesDialog(allowFoldersOnly: boolean = false) {
  return new Promise<File[]>((resolve, reject) => {
    function onChange() {
      input.removeEventListener("cancel", onCancel);
      resolve(Array.from(input.files ?? []));
    }

    function onCancel() {
      input.removeEventListener("change", onChange);
      reject();
    }

    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute(allowFoldersOnly ? "webkitdirectory" : "multiple", "");

    input.addEventListener("cancel", onCancel, { once: true });
    input.addEventListener("change", onChange, { once: true });

    input.click();
  });
}
