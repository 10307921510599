import { debounce } from "lodash-es";
import { onBeforeUnmount, onMounted } from "vue";

import type { Ref } from "vue";
import type { VxeGridInstance } from "vxe-table";

// The VXE table uses mouseenter and mouseleave events to trigger hover effects.
// When the table is being scrolled, these events are not fired as browsers
// optimize for performance. In this case, we need to manually call
// triggerHoverEvent().
//
// To call this function, we need to know the row (Request or Task) that is
// currently under the mouse cursor. We can obtain this by calling
// document.elementsFromPoint(). However, to do that, we must first determine
// the mouse cursor's position, which can be done by listening to the mousemove
// event.
export const useHoverTracker = (gridRef: Ref<VxeGridInstance | null>) => {
  const position = { x: 0, y: 0 };

  const saveMousePosition = (e: MouseEvent) => {
    position.x = e.clientX;
    position.y = e.clientY;
  };

  onMounted(() => {
    window.addEventListener("mousemove", saveMousePosition);
  });

  onBeforeUnmount(() => {
    window.removeEventListener("mousemove", saveMousePosition);
  });

  return debounce(() => {
    const elems = document.elementsFromPoint(position.x, position.y);

    elems.find((el) => {
      if (el.classList.contains("vxe-body--column")) {
        // document.elementsFromPoint() doesn't return <tr> row itself, that
        // is why we need to get the parent element from the column.
        const rowId = el.parentElement!.getAttribute("rowid");
        if (!rowId) return;

        // rowId is uid of a Request or a Category: `task_` or `cat_`.
        // We trigger hover event for both of them.
        //
        // Actually Category has no hover styles, but we need to trigger hover
        // event for it to make sure that the hover effect for the Request was
        // disabled.
        const row = gridRef.value?.getRowById(rowId);

        gridRef.value
          ?.getRefMaps()
          .refTable.value.triggerHoverEvent(null, { row });

        return true;
      }

      return false;
    });
  }, 100);
};
