import { computed } from "vue";

import { DrStore } from "@app/vue";
import { pinia } from "@app/vue/store/pinia";
import {
  type Category,
  useCategoriesStore,
} from "@app/vue/store/pinia/room/categories";
import { useTasksStore } from "@app/vue/store/pinia/room/tasks";

import type { NavTreeItem } from "@app/vue/shared/ui/dr-nav";
import type { TreeItem } from "@app/vue/shared/ui/dr-tree";
import type { TaskStatus } from "@app/vue/store/modules/room/tasks-statuses/types";

const getStatusById = DrStore.getters["room/tasksStatuses/byId"];

type Ext = {
  uid: string;
  code: string;
  color?: string;
};

type TaskTreeItem = TreeItem<Ext>;

interface Config {
  disableCategories?: boolean;
}

const tasksStore = useTasksStore(pinia);
const categoriesStore = useCategoriesStore(pinia);

const reformatAndFillCategory = (
  acc: TaskTreeItem[],
  cat: NavTreeItem<Pick<Category, "order" | "uid">>,
  config: Config,
): any => {
  // there are no tasks in this category or its subcategories
  if (cat.progress?.total === 0) return acc;

  const children: TaskTreeItem[] = [];
  if (cat.children?.length) {
    children.push(
      ...cat.children.reduce(
        (acc, cat) => reformatAndFillCategory(acc, cat, config),
        [],
      ),
    );
  }

  const categoryTasks = tasksStore.tasksByCategoryId[cat.id];
  if (categoryTasks?.length) {
    const tasks = categoryTasks.reduce<TaskTreeItem[]>((acc, task) => {
      const taskStatus = getStatusById(task.status_id) as TaskStatus;
      acc.push({
        id: task.id,
        uid: task.uid,
        name: task.title,
        code: task.key,
        color: taskStatus?.color,
      });

      return acc;
    }, []);

    children.push(...tasks);
  }

  acc.push({
    ...cat,
    code: "",
    children,
    disabled: config.disableCategories || undefined,
  });

  return acc;
};

export const useTasksTreeData = (config: Config) => {
  return computed<TaskTreeItem[]>(() =>
    categoriesStore.categoriesNavTree.reduce(
      (acc, cat) => reformatAndFillCategory(acc, cat, config),
      [],
    ),
  );
};
