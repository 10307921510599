import VueTimezoneDialog from "@shared/ui/timezone-dialog/TimezoneDialog.vue";

import { DrAlert } from "@drVue/components";
import VueNotificationsPage from "@drVue/components/client-dashboard/notifications/index.vue";
import VueBaseOrgSettings from "@drVue/components/client-dashboard/settings/BaseSettings.vue";
import VueUserSettingsPanel from "@drVue/components/client-dashboard/users/AccountSettings/AccountSettings.vue";
import VueYourWorkRoom from "@drVue/components/client-dashboard/your-work/YourWorkRoom.vue";
import VueNavigation from "@drVue/components/navigation/Navigation.vue";
import VueFindingsOverviewPage from "@drVue/components/room/findings/FindingsOverviewPage.vue";
import VueRoomCopyProgressDialog from "@drVue/components/room/RoomCopyProgressDialog.vue";
import VueRoomDownloadDialog from "@drVue/components/room/RoomDownloadsDialog.vue";
import VueRoomHeaderNav from "@drVue/components/room/RoomHeaderNav/RoomHeaderNav.vue";
import header_headerHtml from "./templates/components/header/header.html?raw";

(function () {
  "use strict";
  RoomTemplateService.$inject = [
    "$window",
    "STATIC_URL",
    "URLS",
    "RoomConfig",
    "Upload",
    "AlertService",
  ];
  RoomApiService.$inject = ["$http", "Upload", "URLS"];
  angular
    .module("dealroom.room", [
      "ngVue",
      "ngVue.plugins",
      "ui.bootstrap",
      "ui.select",
      "ngFileUpload",
      "angular.filter",

      "dealroom.config",
      "dealroom.common",
    ])
    .factory("RoomApiService", RoomApiService)
    .factory("RoomTemplateService", RoomTemplateService)
    .component("drRoomHeader", drRoomHeader())
    .value("VueDrAlert", DrAlert)
    .value("VueRoomDownloadDialog", VueRoomDownloadDialog)
    .value("VueRoomCopyProgressDialog", VueRoomCopyProgressDialog)
    .value("VueTimezoneDialog", VueTimezoneDialog)
    .value("VueNavigation", VueNavigation)
    .value("VueUserSettingsPanel", VueUserSettingsPanel)
    .value("VueRoomHeaderNav", VueRoomHeaderNav)
    .value("VueBaseOrgSettings", VueBaseOrgSettings)
    .value("VueYourWorkRoom", VueYourWorkRoom)
    .value("VueFindingsOverviewPage", VueFindingsOverviewPage)
    .value("VueFindingsOverviewPage", VueFindingsOverviewPage)
    .value("VueNotificationsPage", VueNotificationsPage);

  function RoomApiService($http, Upload, URLS) {
    return {
      getRooms: function () {
        var url = URLS["root-api:rooms:list"]();
        return $http.get(url);
      },
      createNewRoom: function (data) {
        var url = URLS["root-api:rooms:list"]();
        return $http.post(url, data);
      },
      detail: function () {
        var url = URLS["api:room:detail"]();
        return $http.get(url);
      },
      update: function (data) {
        var url = URLS["api:room:detail"]();
        return $http.post(url, data);
      },
      delete: function () {
        var url = URLS["api:room:detail"]();
        return $http.delete(url);
      },
      deleteLogo: function () {
        var url = URLS["api:room:detail-logo"]();
        return $http.delete(url);
      },
      updateLogo: function (logo) {
        var url = URLS["api:room:detail-logo"]();
        var formData = new FormData();
        formData.append("logo", logo);
        return $http({
          method: "POST",
          headers: { "Content-Type": undefined },
          data: formData,
          url,
        });
      },
      reindex: function () {
        var url = URLS["api:room:reindex"]();
        return $http.post(url, {});
      },
      get_storage: function () {
        var url = URLS["api:room:storage"]();
        return $http.get(url);
      },
      getRoomGroups: function (roomUid) {
        const url = URLS["root-api:rooms:groups"](roomUid);
        return $http.get(url);
      },
    };
  }

  function RoomTemplateService(
    $window,
    STATIC_URL,
    URLS,
    RoomConfig,
    Upload,
    AlertService,
  ) {
    var service = {
      ROOM_TEMPLATE_EXAMPLE_URL: URLS["api:room:template-import-example"](),
      importTemplate: importTemplate,
      exportTemplate: exportTemplate,
    };

    return service;

    function importTemplate(file) {
      var url = URLS["api:room:template-import"]();
      return Upload.upload({ url: url, data: { file: file } }).then(
        function successCallback(response) {
          var importResult = response.data;
          return importResult;
        },
        function errorCallback(response) {
          //TODO: add user-friendly message
          AlertService.danger("Import failed");
        },
      );
    }

    function exportTemplate() {
      var url = URLS["api:room:template-export"]();
      $window.open(url, "_blank");
    }
  }

  function drRoomHeader() {
    return {
      template: header_headerHtml,
      controller: function () {},
    };
  }
})();
