AnalyticsTasksAssigneeDistributionLegend.$inject = [
  "$element",
  "AnalyticsTasksAssigneeDistributionStatsService",
];
import * as d3 from "d3";
angular
  .module("dealroom.analytics.assignees-distribution-legend", [
    "dealroom.analytics.assignees-distribution.service.stats",
  ])
  .component("drAnalyticsTasksAssigneeDistributionLegend", {
    bindings: { dispatcher: "<" },
    controller: AnalyticsTasksAssigneeDistributionLegend,
  });

function AnalyticsTasksAssigneeDistributionLegend(
  $element,
  AnalyticsTasksAssigneeDistributionStatsService,
) {
  const $ctrl = this;
  $ctrl.$onInit = function () {
    $ctrl.dispatcher.on("update." + pk, update);
    update();
  };
  const pk = "AnalyticsTasksAssigneeDistributionLegend";
  const rootElement = d3.select($element[0]);

  const stats = d3.values(
    AnalyticsTasksAssigneeDistributionStatsService.getEmptyStats(),
  );

  const rows = rootElement
    .selectAll(".analytics-card__row")
    .data(d3.range(Math.ceil(stats.length)))
    .enter()
    .append("div")
    .attr("class", "analytics-card__row");

  const legend = rows
    .selectAll(".analytics-card__column")
    .data((i) => stats.slice(i * 2, i * 2 + 2))
    .enter()
    .append("div")
    .attr("class", "analytics-card__column analytics-card__column--md")
    .append("div")
    .attr("class", "analytics-chart-legend");

  legend
    .append("span")
    .attr("class", "analytics-chart-legend__ico")
    .style("border-color", (d) => d.color);
  legend
    .append("strong")
    .attr("class", "analytics-chart-legend__value")
    .attr("type", (d) => d.key);
  legend
    .append("strong")
    .attr("class", "analytics-chart-legend__title")
    .text((d) => d.title);

  function update(data = [], isPercentage = false, total = 0) {
    // [{key, value}, ...]
    const stats =
      AnalyticsTasksAssigneeDistributionStatsService.getEmptyStats();
    const totalStats = data.reduce((bucket, { value }) => {
      value.forEach(({ key, value }) => {
        bucket[key].value += value;
      });
      return bucket;
    }, stats);

    d3.values(totalStats).forEach(({ key, value }) => {
      const node = rootElement.select(
        `.analytics-chart-legend__value[type="${key}"]`,
      );

      if (isPercentage) {
        value = total > 0 ? Math.round((value / total) * 100) + "%" : "N/A";
      }
      node.html(`&nbsp;-&nbsp;${data.length > 0 ? value : "N/A"}`);
    });
  }
}
