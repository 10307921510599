<template>
  <DrPopup ref="popupRef" paddingless @hide="handleHide" @show="handleShow">
    <template #default="{ hide }">
      <DrPanelWrapper :width="354">
        <DrDynamicFormClassic
          ref="form"
          :entity="model"
          :schema="nameSchema"
          :errors="formErrors"
          :disabled="isFormSubmitting"
          @update="handleUpdateForm"
          @submit="submit"
        />

        <div :class="$style.footer">
          <ElButton size="small" @click="hide">Cancel</ElButton>
          <ElButton
            size="small"
            type="primary"
            :disabled="isSubmitDisabled"
            :loading="isFormSubmitting"
            @click="submit"
          >
            {{ labels.submitText }}
          </ElButton>
        </div>
      </DrPanelWrapper>
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import { insightTrack, RoomTasksCategoriesEvent } from "@app/insight";
import { $notifySuccess } from "@drVue/common";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import { DrDynamicFormClassic } from "@drVue/shared/ui/dr-dynamic-form";
import { validateString } from "@drVue/shared/ui/dr-dynamic-form/utils";
import { useFormHelper } from "@drVue/shared/ui/dr-form";
import { DrPanelWrapper } from "@drVue/shared/ui/dr-panels";
import { DrPopup } from "@drVue/shared/ui/dr-popups";
import { pinia } from "@drVue/store/pinia";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";

import type { Category } from "@drVue/store/pinia/room/categories";
import type { FormSchema } from "@shared/ui/dr-dynamic-form/types";

type OpenPopupPayload =
  | {
      type: "create";
      parentId?: Category["parent_id"];
      id?: undefined;
      name?: undefined;
    }
  | {
      type: "rename";
      id: Category["id"];
      name: Category["name"];
      parentId?: undefined;
    };

type OpenPopupParams = {
  openedCallback?: () => void;
  closedCallback?: () => void;
};

interface Emits {
  (event: "added", category: Category): void;
  (event: "renamed", category: Category, oldName: string): void;
  (event: "hide"): void;
}

const emit = defineEmits<Emits>();

const categoriesStore = useCategoriesStore(pinia);

const { formErrors, hookFormSubmitPromise, isFormSubmitting, resetError } =
  useFormHelper<{ name: string }>();

const popupRef = ref<InstanceType<typeof DrPopup>>();
const form = ref<InstanceType<typeof DrDynamicFormClassic> | undefined>();

const actionType = ref<OpenPopupPayload["type"]>("create");
const initialModelValue = ref("");
const modelValue = ref("");
const categoryId = ref<Category["id"] | undefined>();
const parentId = ref<Category["parent_id"] | undefined>();
const hideModalCallback = ref<() => void | undefined>();
const showModalCallback = ref<() => void | undefined>();

const model = computed(() => ({
  name: modelValue.value,
}));

const isSubmitDisabled = computed(() => {
  const value = modelValue.value.trim();
  return (
    !value.length ||
    value.length > 64 ||
    value === initialModelValue.value ||
    !!formErrors.value.name
  );
});

const labels = computed(() => {
  if (actionType.value === "create") {
    return {
      title: "Name",
      placeholder: "New category name",
      submitText: "Create",
    };
  }
  return {
    title: "New name",
    placeholder: "Enter new name",
    submitText: "Rename",
  };
});

const nameSchema = computed(
  (): FormSchema => [
    {
      type: FieldSchemaType.Text,
      isReadOnly: isFormSubmitting.value,
      prop: "name",
      label: labels.value.title,
      placeholder: labels.value.placeholder,
      rules: validateString()
        .required("The name may not be blank.")
        .max(64, "The name may not be longer than 64 characters."),
      extra: {
        autofocus: 200,
      },
    },
  ],
);

const handleUpdateForm = ({
  field,
  value,
}: {
  field: string;
  value: string;
}) => {
  resetError(field);
  modelValue.value = value;
};

const submit = async () => {
  if (isFormSubmitting.value) return;

  const result = await form.value?.validate().catch((error) => error);

  if (result !== true) return;

  let promise: Promise<void>;

  if (actionType.value === "create") {
    promise = categoriesStore
      .createCategory(modelValue.value, parentId.value)
      .then((addedCategory) => {
        emit("added", addedCategory);
        $notifySuccess("A new category has been created.");
        insightTrack(RoomTasksCategoriesEvent.Added, {
          id: `${addedCategory.id}`,
          name: addedCategory.name,
          parent: addedCategory.parent?.name ?? "",
          order: `${addedCategory.order}`,
        });
      });
  } else {
    promise = categoriesStore
      .editCategory(categoryId.value!, {
        name: modelValue.value,
      })
      .then((updatedCategory) => {
        emit("renamed", updatedCategory, initialModelValue.value);
        $notifySuccess(
          `${initialModelValue.value} has been renamed to ${updatedCategory.name}.`,
        );
        insightTrack(RoomTasksCategoriesEvent.Renamed, {
          id: `${updatedCategory.id}`,
          name: updatedCategory.name,
          old_name: initialModelValue.value,
        });
      });
  }

  hookFormSubmitPromise(
    promise,
    actionType.value === "create"
      ? "Failed to create a new category."
      : `Failed to rename "${initialModelValue.value}".`,
  ).then(() => popupRef.value?.hide());
};

const show = (
  reference: HTMLElement,
  payload: OpenPopupPayload,
  params?: OpenPopupParams,
) => {
  actionType.value = payload.type;
  initialModelValue.value = payload.name ?? "";
  modelValue.value = payload.name ?? "";
  categoryId.value = payload.id;
  parentId.value = payload.parentId;
  popupRef.value?.show(reference);
  hideModalCallback.value = params?.closedCallback;
  showModalCallback.value = params?.openedCallback;
};

const handleHide = () => {
  emit("hide");
  hideModalCallback.value?.();
};

const handleShow = () => {
  showModalCallback.value?.();
};

defineExpose({ show });
</script>

<style lang="scss" module>
.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;

  :global(.el-button + .el-button) {
    margin-left: 8px;
  }
}
</style>
