<template>
  <DefaultViewFieldLayout :view-props="viewProps">
    <template #label>
      {{ viewProps.schema.label }}
    </template>
    <template #control>
      <div v-if="text">{{ text }}</div>
      <div v-else>-</div>
    </template>
  </DefaultViewFieldLayout>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import DefaultViewFieldLayout from "./DefaultViewFieldLayout.vue";

import type { SelectFieldOption } from "@drVue/api-service/client-dashboard/types";
import type { ViewFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";

interface Props {
  viewProps: ViewFieldProps;
}

const props = defineProps<Props>();
const text = computed(() => {
  const type = props.viewProps.schema.type;
  if (type !== FieldSchemaType.Select && type !== FieldSchemaType.MultiSelect) {
    throw new Error(`Invalid type ${type} for Select View Field`);
  }

  const entityValue = props.viewProps.entityValue;
  if (type === FieldSchemaType.Select) {
    const extra = props.viewProps.schema.extra;
    const options = extra.select_options as SelectFieldOption[];

    // We have two types of `select_options` in the app. In the first case, we
    // use `option.label` as the `value`. When `option.value` is absent, the
    // field's value will be the "label of the option." In some cases, we do
    // have `option.value`. In these instances, the field's value will be "the
    // ID of that value." We then need to determine the actual selected option
    // and use its `label`.
    if ("value" in options[0]) {
      const selectedOption = options.find(
        (option) => option.value === entityValue,
      );

      return selectedOption?.label ?? "-";
    }

    return entityValue ?? "-";
  } else {
    return entityValue && entityValue.length ? entityValue.join(", ") : "-";
  }
});
</script>
