<template>
  <div :class="$style.container">
    <a @click.prevent="edit">Edit</a>
    <a @click.prevent="remove">Delete</a>
  </div>
</template>

<script setup lang="ts">
interface Props {
  findingStatusId: string;
}

interface Emits {
  (event: "edit", id: string): void;
  (event: "remove", id: string): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const edit = () => {
  emit("edit", props.findingStatusId);
};
const remove = () => {
  emit("remove", props.findingStatusId);
};
</script>

<style lang="scss" module>
.container {
  display: inline-flex;
  gap: 10px;
  align-items: center;
}
</style>
