<template>
  <div :class="$style.wrapper">
    <DrLoader v-if="isProcessing" overlay />

    <div v-if="!!favorites.length" :class="$style.favorites">
      <template v-for="fav in favorites" :key="fav.id">
        <div
          :class="{
            [$style.item]: true,
            [$style.item_outsideTree]: true,
            [$style.item_isFocused]: fav.id === itemWithActiveActionId,
            [$style.item_isActive]: fav.id === currentId,
          }"
          @click="selectItem({ id: fav.id })"
          @contextmenu="contextMenuHandler"
        >
          <div :class="$style.itemBody">
            <DrIcon
              v-if="fav.icon"
              :name="fav.icon"
              size="sm"
              :class="$style.icon"
            />
            <component
              v-else-if="fav.iconComponent"
              :is="fav.iconComponent"
              :class="$style.icon"
              :active="fav.id === currentId"
            />
            <div :class="$style.name">
              <span :class="$style.nameText">
                {{ fav.name }}
              </span>

              <div v-if="fav.newBadge" :class="$style.newBadge">NEW</div>

              <span v-if="fav.info" :class="$style.nameInfo">
                <DrTooltip placement="bottom" :content="fav.info">
                  <DrIcon name="info-circle" size="sm" :class="$style.icon" />
                </DrTooltip>
              </span>
            </div>

            <div :class="$style.action">
              <div
                v-if="$slots['fav-action']"
                :class="$style.actionSlot"
                @click.stop
              >
                <slot
                  name="fav-action"
                  v-bind="{ item: fav, setActive: setItemActionActive }"
                />
              </div>
              <span v-if="fav.counter" :class="$style.actionCounter">
                {{ fav.counter }}
              </span>
            </div>
          </div>
        </div>

        <div v-if="fav.divided" :class="$style.divider" />
      </template>
    </div>

    <div :class="$style.treeTitle">
      <span :class="$style.name">
        {{ title }}
      </span>

      <DrPopup v-if="hasProgress" placement="bottom">
        <template #reference>
          <ElButton size="small">
            <template #icon>
              <DrIcon name="tasks-alt" />
            </template>
          </ElButton>
        </template>
        <template #default="{ hide }">
          <div style="width: 176px">
            <DrPopupSelectOptions
              title="Progress"
              :items="progressItems"
              type="checkboxRight"
              @select="handleProgressViewChange($event, hide)"
            />
          </div>
        </template>
      </DrPopup>

      <DrTooltip :content="addTooltip">
        <ElButton
          v-if="!disallowAdd"
          ref="rootAddBtnRef"
          size="small"
          @click="handleAdd"
        >
          <template #icon>
            <DrIcon name="plus" size="sm" :class="$style.icon" />
          </template>
        </ElButton>
      </DrTooltip>
    </div>

    <div
      :class="{
        [$style.tree]: true,
        [$style.tree_isFlat]: isFlatView,
        ...(props.treeClass ? { [props.treeClass]: true } : {}),
      }"
    >
      <ElScrollbar :view-class="$style.treeInner">
        <div v-if="!tree.length" :class="$style.noData">
          {{ noDataText }}
        </div>

        <template v-else>
          <div
            v-if="rootTitle"
            ref="rootItemRef"
            :class="{
              [$style.item]: true,
              [$style.item_outsideTree]: true,
              [$style.item_isFocused]: 'root' === itemWithActiveActionId,
              [$style.item_isActive]: isRootActive,
            }"
            @click="selectItem({ id: 'root' })"
            @contextmenu="contextMenuHandler"
          >
            <div :class="$style.itemBody">
              <slot name="root-prefix" />
              <span :class="$style.name">
                {{ rootTitle }}
              </span>
              <div
                v-if="$slots['root-action']"
                :class="$style.actionSlot"
                @click.stop
              >
                <slot
                  name="root-action"
                  v-bind="{ setActive: setRootActionActive, ref: rootItemRef }"
                />
              </div>
            </div>

            <div
              v-if="hasProgress && rootProgress && rootProgress.total > 0"
              :class="$style.itemFooter"
            >
              <Transition :name="$style.slideFooter">
                <DrProgressBar
                  v-if="isProgressVisible"
                  :progress="rootProgress"
                  :is-view-absolute="isProgressAbsolute"
                />
              </Transition>
            </div>
          </div>

          <ElTree
            ref="treeRef"
            node-key="id"
            :indent="14"
            :data="localTree"
            :props="treeProps"
            :draggable="draggable"
            :allow-drop="allowDrop"
            :allow-drag="allowDrag"
            :current-node-key="currentId"
            :default-expanded-keys="treeDefaultExpanded"
            @node-click="selectItem"
            @node-drop="handleDrop"
            @node-drag-start="handleDragStart"
            @node-drag-over="handleDragOver"
            @node-drag-end="handleDragEnd"
            @node-expand="handleNodeExpand"
            @node-collapse="handleNodeCollapse"
          >
            <template
              #default="{ data, node }: { data: NavTreeItem; node: Node }"
            >
              <div
                :ref="(ref) => setItemRef(data.id, ref)"
                :class="{
                  [$style.item]: true,
                  [$style.item_isFocused]: data.id === itemWithActiveActionId,
                  [$style.item_isActive]: data.id === currentId,
                }"
                @contextmenu="contextMenuHandler"
              >
                <div :class="$style.itemBody">
                  <slot name="item-prefix" v-bind="{ item: data, node }" />

                  <span :class="$style.name">
                    <DrTruncatedTextTooltip :content="data.name">
                      {{ data.name }}
                    </DrTruncatedTextTooltip>
                  </span>

                  <div :class="$style.action">
                    <div
                      v-if="$slots['item-action']"
                      :class="$style.actionSlot"
                      @click.stop
                    >
                      <slot
                        name="item-action"
                        v-bind="{
                          item: data,
                          setActive: setItemActionActive,
                          ref: itemsRefs[data.id],
                        }"
                      />
                    </div>
                    <span
                      v-if="
                        (!hasProgress || progressType === 'none') &&
                        data.counter
                      "
                      :class="$style.actionCounter"
                    >
                      {{ data.counter }}
                    </span>
                  </div>
                </div>

                <div
                  v-if="hasProgress && data.progress && data.progress.total > 0"
                  :class="$style.itemFooter"
                >
                  <Transition :name="$style.slideFooter">
                    <DrProgressBar
                      v-if="isProgressVisible"
                      :progress="data.progress"
                      :is-view-absolute="isProgressAbsolute"
                    />
                  </Transition>
                </div>
              </div>
            </template>
          </ElTree>
        </template>
      </ElScrollbar>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ElTree } from "element-plus";
import { cloneDeep } from "lodash-es";
import { difference } from "lodash-es";
import { union } from "lodash-es";
import { without } from "lodash-es";
import {
  computed,
  nextTick,
  ref,
  unref,
  useCssModule,
  watch,
  watchEffect,
} from "vue";
import { useStorage } from "@vueuse/core";

import { createDictionary } from "@drVue/common";
import DrIcon from "../dr-icon";
import { DrNewLoader as DrLoader } from "../dr-loader";
import { DrPopup, DrPopupSelectOptions } from "../dr-popups";
import { DrProgressBar } from "../dr-progress-bar";
import { DrTooltip, DrTruncatedTextTooltip } from "../dr-tooltip";

import type {
  NavTreeEmitMoveParams,
  NavTreeFavoritesItem,
  NavTreeItem,
  NavTreeNode,
  NavTreePropAllowDrag,
  NavTreePropAllowDrop,
} from "./types";
import type { Dictionary } from "@drVue/types";
import type { Progress } from "@shared/ui/dr-progress-bar/types";
import type Node from "element-plus/es/components/tree/src/model/node";
import type {
  NodeDropType,
  TreeNodeData,
} from "element-plus/es/components/tree/src/tree.type";
import type { ComponentPublicInstance } from "vue";

interface Props {
  title: string;
  rootTitle?: string;
  tree?: NavTreeItem[];
  favorites?: NavTreeFavoritesItem[];
  currentId?: NavTreeItem["id"];
  /** Prevent request adding a new element to the tree */
  addTooltip?: string;
  disallowAdd?: boolean;
  noDataText?: string;
  draggable?: boolean;
  allowDrop?: NavTreePropAllowDrop;
  allowDrag?: NavTreePropAllowDrag;
  isProcessing?: boolean;
  hasProgress?: boolean;
  triggerActionSlotByContextmenu?: boolean;
  /** Auto-expand node with children on "drag-over" */
  expandOnDragOver?: boolean;
  treeClass?: string;
}

const props = withDefaults(defineProps<Props>(), {
  rootTitle: "",
  favorites: () => [],
  tree: () => [],
  currentId: "root",
  disallowAdd: false,
  addTooltip: undefined,
  noDataText: "No worklists here yet",
  draggable: false,
  allowDrop: () => () => true,
  allowDrag: () => () => true,
  isProcessing: false,
  hasProgress: false,
  triggerActionSlotByContextmenu: false,
  expandOnDragOver: false,
});

const emit = defineEmits<{
  (event: "select", id: NavTreeItem["id"]): void;
  (event: "add", reference: HTMLElement): void;
  (event: "move", params: NavTreeEmitMoveParams): void;
}>();

const $style = useCssModule();
const TIME_OVER_ITEM_TO_EXPAND_IT = 1000; // ms

const rootAddBtnRef = ref<HTMLElement>();
const handleAdd = () => {
  if (rootAddBtnRef.value) {
    emit("add", rootAddBtnRef.value);
  }
};

const isRootActive = computed(() => props.currentId === "root");
const itemWithActiveActionId = ref<NavTreeItem["id"]>("");
/** The local version is needed because the
 *  ElTree component mutates the data from the prop "data". */
const localTree = ref<NavTreeItem[]>(cloneDeep(props.tree));
watchEffect(() => (localTree.value = cloneDeep(props.tree)));

const isFlatView = computed(
  () => !props.tree.some((item) => !!item?.children?.length),
);

const treeRef = ref<InstanceType<typeof ElTree> | null>(null);
const treeProps = {
  children: "children",
  label: "name",
  class: (data: TreeNodeData) => {
    // have to use casting to provide "correct" types for `ElTree.props.class`
    if ((data as NavTreeItem).id === props.currentId) {
      return "is-tree-item-current";
    }
    return "";
  },
};
const treeDefaultExpanded = props.currentId === "root" ? [] : [props.currentId];

/** Allows us to save the "expand" state of the nodes when updating the tree data
 * (for example, updates from the backend after the reorder)  */
const localExpandedKeys = ref<NavTreeItem["id"][]>([]);
watch(
  () => treeRef.value,
  (tree: InstanceType<typeof ElTree> | null) => {
    if (tree) {
      for (const node of Object.values(tree.store.nodesMap)) {
        if (node.expanded) {
          localExpandedKeys.value.push(node.data.id);
        }
      }
    }
  },
);

const handleNodeExpand = (nodeData: NavTreeNode["data"]) => {
  localExpandedKeys.value.push(nodeData.id);
};
const handleNodeCollapse = (nodeData: NavTreeNode["data"]) => {
  const index = localExpandedKeys.value.findIndex((id) => id === nodeData.id);
  if (index >= 0) {
    localExpandedKeys.value.splice(index, 1);
  }
};

const rootItemRef = ref<HTMLElement>();

type ItemsRefs = Record<NavTreeItem["id"], HTMLElement>;
const itemsRefs = ref<ItemsRefs>(createDictionary());
const setItemRef = (
  id: NavTreeItem["id"],
  el: Element | ComponentPublicInstance | null,
) => {
  if (!el) {
    return;
  }

  if (el instanceof HTMLElement) {
    itemsRefs.value[id] = el;
  } else {
    itemsRefs.value[id] = unref((el as ComponentPublicInstance).$el);
  }
};

const selectItem = ({ id }: { id: NavTreeItem["id"] }) => {
  if (id !== props.currentId) {
    emit("select", id);
  }
};

const setItemActionActive = (id: NavTreeItem["id"], isActive: boolean) => {
  itemWithActiveActionId.value = isActive ? id : "";
};
const setRootActionActive = (isActive: boolean) => {
  setItemActionActive("root", isActive);
};

const contextMenuHandler = (event: MouseEvent) => {
  if (!props.triggerActionSlotByContextmenu) return;

  event.preventDefault();

  const target = event.target as HTMLDivElement;
  let actionSlotDiv = target.classList.contains($style.actionSlot)
    ? target
    : null;

  if (!actionSlotDiv) {
    let itemDiv: HTMLDivElement | null = null;
    if (target.classList.contains($style.item)) {
      itemDiv = target;
    }

    if (!itemDiv) {
      itemDiv = target.closest(`.${$style.item}`);
    }

    actionSlotDiv = itemDiv && itemDiv.querySelector(`.${$style.actionSlot}`);
  }

  /**
   * @todo perhaps it is worth solving the question architecturally:
   * - for example, remove the slot and move the menu inside the tree element data.
   * - now this hardcoded selector is a necessary necessity.
   */
  const trigger = actionSlotDiv?.querySelector("[aria-haspopup]");
  if (trigger) {
    (trigger as HTMLElement).click();
  }
};

let draggingNodeOriginalData: NavTreeEmitMoveParams["source"] | null = null;
const draggingOverNode: {
  id: NavTreeNode["data"]["id"] | null;
  timeStart: number;
} = {
  id: null,
  timeStart: 0,
};

const getOriginalTreeItemById = (
  id: NavTreeItem["id"],
  items: NavTreeItem[] = props.tree,
): null | NavTreeItem => {
  for (const item of items) {
    if (item.id === id) {
      return cloneDeep(item);
    }

    const childFound =
      item.children?.length && getOriginalTreeItemById(id, item.children);

    if (childFound) return childFound;
  }

  return null;
};

const getAllChildsIds = (childs: NavTreeItem[] = props.tree) => {
  let ids: NavTreeItem["id"][] = [];

  for (const item of childs) {
    ids.push(item.id);

    if (item.children?.length) {
      ids = union(ids, getAllChildsIds(item.children));
    }
  }

  return ids;
};

/**
 * We save the original data of the "draggingNode",
 * since later in the "@node-drop" event this data will already
 * be updated with the new location in the tree.
 *
 * @remark In terms ElTree a node always has an "parent",
 *         but for root nodes (according to props.tree) it will be a special node with id=0
 *         that has an array in its "data" property with all root nodes (from props.tree).
 */
const handleDragStart = (draggingNode: NavTreeNode) => {
  draggingNodeOriginalData = {
    ...cloneDeep(draggingNode.data),
    parent: draggingNode.parent.id
      ? cloneDeep((draggingNode.parent as NavTreeNode).data)
      : null,
  };
};

/**
 * @important During the execution of this handler, the data of tree has already been updated.
 *            That is, the "dropNode.parent" is already include the "draggingNode" on new position.
 *            Therefore, we need to get original (unchanged versions) data.
 */
const handleDrop = (
  _draggingNode: NavTreeNode,
  dropNode: NavTreeNode,
  dropType: NodeDropType,
) => {
  if (dropType === "none" || !draggingNodeOriginalData) return;

  /** Move to root */
  let target: NavTreeItem | null = null;
  let newPositionIndex = 0;

  /** "inner" type is set when the node has no children or is not expanded */
  if (dropType === "inner") {
    target = getOriginalTreeItemById(dropNode.data.id);
    localExpandedKeys.value.push(dropNode.data.id);

    /** Put at the end of collapsed node */
    if (target && target.children?.length) {
      newPositionIndex = target.children.length;
    }
  } else {
    /** Tree node with id=0 is "special root node" that contains all root nodes from `props.tree` */
    target = dropNode.parent.id
      ? getOriginalTreeItemById(dropNode.parent.data.id)
      : null;

    const siblings = (target ? target.children : props.tree)!;
    const isNewParent = draggingNodeOriginalData.parent?.id !== target?.id;

    newPositionIndex = siblings.findIndex(
      (child) => child.id === dropNode.data.id,
    );

    if (newPositionIndex < 0) return;

    if (
      dropType === "after" &&
      (isNewParent || newPositionIndex < siblings.length - 1)
    ) {
      newPositionIndex += 1;
    }
  }

  const moveParams: NavTreeEmitMoveParams = {
    source: {
      ...draggingNodeOriginalData,
    },
    target,
    index: newPositionIndex,
    updatedTree: cloneDeep(localTree.value),
  };

  draggingNodeOriginalData = null;

  emit("move", moveParams);
  /** We reset local data mutations, waiting for changes through props. */
  localTree.value = cloneDeep(props.tree);
};

/**
 * @remark disable expand an element with children if we're trying
 *         to "drag over" draggingNode side by side (top/bottom, not inside).
 */
const handleDragOver = (
  _draggingNode: NavTreeNode,
  dropNode: NavTreeNode,
  event: DragEvent & { toElement: { parentNode: HTMLElement | null } },
) => {
  if (
    event.toElement.parentNode?.classList.contains("is-drop-inner") &&
    props.expandOnDragOver
  ) {
    if (dropNode.data.id !== draggingOverNode.id) {
      draggingOverNode.id = dropNode.data.id;
      draggingOverNode.timeStart = Date.now();
    }

    if (dropNode.childNodes.length && !dropNode.expanded) {
      const timeElapsed = Date.now() - draggingOverNode.timeStart;

      if (timeElapsed > TIME_OVER_ITEM_TO_EXPAND_IT) {
        dropNode.expand();

        localExpandedKeys.value.push(draggingOverNode.id);
        draggingOverNode.id = null;
        draggingOverNode.timeStart = 0;
      }
    }
    return;
  }

  draggingOverNode.id = null;
  draggingOverNode.timeStart = 0;
};

const handleDragEnd = () => {
  draggingOverNode.id = null;
  draggingOverNode.timeStart = 0;
};

watchEffect(() => treeRef.value?.setCurrentKey(props.currentId));
watch(
  () => localTree.value,
  () => {
    nextTick(() => {
      localExpandedKeys.value.forEach((key) => {
        treeRef.value?.getNode(key)?.expand();
      });
    });
  },
);

const rootProgress = computed<Progress>(() => {
  let total = 0;
  const items: Dictionary<Progress["items"][number]> = {};
  for (const cat of localTree.value) {
    total += cat.progress?.total ?? 0;
    for (const item of cat.progress?.items ?? []) {
      if (!items[item.name]) {
        items[item.name] = { count: 0, color: item.color, name: item.name };
      }
      items[item.name].count += item.count;
    }
  }
  return { total, items: Object.values(items) };
});

const localExpandedKeysString = computed(() =>
  localExpandedKeys.value.join(","),
);
watch(
  () => localExpandedKeysString.value,
  (keysString, oldKeysString) => {
    const keys = keysString.split(",");
    const oldKeys = oldKeysString.split(",");

    const expandIds = difference(keys, oldKeys);
    const collapseIds = difference(oldKeys, keys);

    if (expandIds.length || collapseIds.length) {
      for (const id of collapseIds) {
        treeRef.value?.getNode(id)?.collapse();
      }

      for (const id of expandIds) {
        treeRef.value?.getNode(id)?.expand();
      }
    }
  },
);

const expandNode = (id?: NavTreeItem["id"]) => {
  const targetItem = id ? getOriginalTreeItemById(id) : null;
  const expandIds = getAllChildsIds(targetItem?.children);

  if (id) expandIds.push(id);
  localExpandedKeys.value = union(localExpandedKeys.value, expandIds);
};

const collapseNode = (id?: NavTreeItem["id"]) => {
  const targetItem = id ? getOriginalTreeItemById(id) : null;
  const collapseIds = getAllChildsIds(targetItem?.children);

  if (id) collapseIds.push(id);
  localExpandedKeys.value = without(localExpandedKeys.value, ...collapseIds);
};

type ProgressType = "abs" | "%" | "none";
const progressType = useStorage<ProgressType>(
  "dr:category_progress_type",
  "none",
);

const isProgressVisible = computed(() => progressType.value !== "none");
const isProgressAbsolute = computed(() => progressType.value === "abs");

const progressItems = computed(() => [
  { id: "none", name: "None", isSelected: progressType.value === "none" },
  { id: "abs", name: "Absolute", isSelected: progressType.value === "abs" },
  { id: "%", name: "Percentage", isSelected: progressType.value === "%" },
]);

function handleProgressViewChange(type: string, hidePopup: () => void) {
  progressType.value = type as ProgressType;
  hidePopup();
}

defineExpose({
  expandNode,
  collapseNode,
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;
@use "@app/styles/scss/values";

$transition_function: ease;
$transition_duration: 200ms;

.icon {
  flex: 0 0 auto;
  color: colors.$pr-400;
}

.action {
  display: grid;
  height: 100%;
  align-items: center;
  justify-items: end;

  > * {
    grid-area: 1 / 1;
    margin-left: spacing.$xs;
  }
}

.actionCounter {
  font: typo.$caption_regular;
  color: colors.$pr-400;
}

.actionSlot {
  display: flex;
  color: colors.$pr-400;
  opacity: 0;
  cursor: pointer;
  transition-duration: $transition_duration;
  transition-timing-function: $transition_function;
  transition-property: opacity;

  & + .actionCounter {
    transition-duration: $transition_duration;
    transition-timing-function: $transition_function;
    transition-property: opacity;
  }
}

.newBadge {
  padding: 2px 4px;
  margin-left: 12px;
  border-radius: 4px;
  background: colors.$sc-600;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
}

.name {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

.divider {
  margin: 8px;
  border-bottom: 1px solid colors.$pr-150;
}

.item_outsideTree {
  .name {
    display: flex;
    gap: spacing.$xxs;
  }

  .nameText {
    flex: 0 1 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .nameInfo {
    flex: 0;

    .icon {
      margin-right: 0;
    }
  }
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: spacing.$s spacing.$xs;
}

.favorites {
  padding-bottom: spacing.$xs;
}

.treeTitle {
  display: flex;
  flex: 0 0 40px;
  align-items: center;
  justify-content: space-between;
  gap: spacing.$xs;
  padding: 0 spacing.$xs;
  font: typo.$body_semibold;
  color: colors.$pr-900;

  .icon {
    cursor: pointer;
  }

  .name {
    flex: 1;
  }

  :global(.el-button + .el-button) {
    margin-left: 0;
  }
}

.noData {
  padding: 0 spacing.$s 0 spacing.$xs;
  font: typo.$body_regular;
  color: colors.$pr-500;
}

.tree {
  flex: 1 1 auto;
  position: relative;
  overflow: hidden;
  margin-right: calc(spacing.$xs * -1);

  &Inner {
    padding-right: spacing.$xs;
  }

  :global {
    .el-tree {
      --el-tree-node-hover-bg-color: #{colors.$pr-100};
      --el-tree-text-color: #{colors.$pr-900};
      --el-tree-expand-icon-color: #{colors.$pr-400};

      .el-tree-node__content {
        align-items: baseline;
        height: auto;
        max-width: 100%;
        overflow: hidden;
        border-radius: values.$base-border-radius;

        .el-tree-node__label {
          flex-grow: 1;
          display: block;
          min-width: 0;
        }
      }

      .el-tree-node.is-current:not(.is-tree-item-current)
        > .el-tree-node__content {
        background-color: transparent;
      }

      .el-tree-node.is-tree-item-current > .el-tree-node__content {
        background-color: colors.$pr-100;
      }

      & .el-tree-node.is-drop-inner > .el-tree-node__content {
        background-color: colors.$pr-100;
        border: solid 1px colors.$sc-400;

        .el-tree-node__label {
          background-color: transparent;
        }
      }

      .el-tree__drop-indicator {
        height: 4px;
        background-color: colors.$sc-400;
        border-radius: values.$base-border-radius;
      }
    }
  }
}

.tree_isFlat :global(.el-tree-node__expand-icon.is-leaf) {
  width: 0;
  padding: 0 0 0 spacing.$xs;
}

.item {
  padding: spacing.$xxs spacing.$s spacing.$xxs 0;
  flex: 1;
  max-width: 100%;
  overflow: hidden;
}

.itemBody {
  display: flex;
  height: spacing.$xl;
  align-items: center;
  font: typo.$body_regular;
  color: colors.$pr-900;

  .icon {
    margin-right: spacing.$xs;
  }

  .name {
    flex: 1 1 auto;
  }
}

.item_outsideTree {
  padding: spacing.$xxs spacing.$s spacing.$xxs spacing.$xs;
  background-color: transparent;
  border-radius: values.$base-border-radius;
  cursor: pointer;
}

.item_isActive {
  font: typo.$body_medium;
}

.item_outsideTree:hover,
.item_outsideTree.item_isFocused,
.item_outsideTree.item_isActive,
.tree :global(.el-tree-node__content):has(.item_isFocused) {
  background-color: colors.$pr-100;
}

.item:hover,
.item_isFocused,
.item_isActive {
  .icon {
    color: colors.$pr-900;
  }
}

.item:hover,
.item_isFocused {
  .actionSlot {
    opacity: 1;
  }

  .actionSlot + .actionCounter {
    opacity: 0;
    z-index: -1;
  }
}

.itemFooter {
  overflow: hidden;

  > * {
    padding-bottom: 2px;
  }
}

.slideFooter {
  &:global(-enter-active),
  &:global(-leave-active) {
    transition-property: opacity, margin-top;
    transition-duration: $transition_duration;
    transition-timing-function: $transition_function;
  }

  &:global(-enter-from),
  &:global(-leave-to) {
    margin-top: -16px;
    opacity: 0;
  }
}
</style>
