<template>
  <template v-if="currentMetadata">
    <component
      ref="menuComponentRef"
      :is="currentMetadata.menuComponent"
      :params="currentMetadata.params"
      :reference="currentMetadata.reference"
      :placement="currentMetadata.placement"
      @close="resetMenu"
      @update:persisted="folderMenuPersistedBus.emit('menu:persisted', $event)"
    />
  </template>
</template>

<script lang="ts" setup>
import { type ComponentInstance, ref } from "vue";

import {
  type FolderMenuPayload,
  type MenuComponent,
  useFolderMenuActiveBus,
  useFolderMenuBus,
  useFolderMenuPersistedBus,
} from "../use/useFolderMenuBus";

const menuComponentRef = ref<ComponentInstance<MenuComponent> | undefined>();

const currentMetadata = ref<FolderMenuPayload | null>(null);

const folderMenuBus = useFolderMenuBus();
const folderMenuPersistedBus = useFolderMenuPersistedBus();
const folderMenuActiveBus = useFolderMenuActiveBus();

folderMenuBus.on((event, payload) => {
  if (event !== "menu:show") return;

  if (payload) {
    currentMetadata.value = payload;
  } else {
    if (menuComponentRef.value && "close" in menuComponentRef.value) {
      menuComponentRef.value.close();
    } else currentMetadata.value = null;
  }
});

const resetMenu = () => {
  currentMetadata.value = null;

  folderMenuActiveBus.emit("menu:active", false);
  folderMenuPersistedBus.emit("menu:persisted", false);
};
</script>
