import { Urls } from "@setups/urls";
import { http } from "./http";

import type { UrlsGetters } from "./types";
import type { FileHandledServerResponse, ServerResponse } from "./types";
import type { AxiosRequestConfig, AxiosResponse } from "axios";

export type { UrlsGetters } from "./types";

const AttachmentFilenameRegexp = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

export class ApiService {
  public Urls: UrlsGetters = Urls;

  public get<D>(url: string, config?: AxiosRequestConfig): Promise<D> {
    return http.get(url, config).then(({ data }: ServerResponse<D>): D => data);
  }

  public post<D, I = any>(
    url: string,
    item?: I,
    config?: AxiosRequestConfig,
  ): Promise<D> {
    return http
      .post(url, item, config)
      .then(({ data }: ServerResponse<D>): D => data);
  }

  public postAndGetFile<D = ArrayBuffer, I = any>(
    url: string,
    item: I,
    config?: AxiosRequestConfig,
  ): Promise<FileHandledServerResponse> {
    return http
      .post(url, item, {
        responseType: "arraybuffer",
        ...(config ?? {}),
      })
      .then((resp: AxiosResponse<D>) => {
        const disposition = resp.headers["content-disposition"];
        let filename = "unknown_file";

        if (disposition && disposition.indexOf("attachment") !== -1) {
          const matches = AttachmentFilenameRegexp.exec(disposition);

          if (matches !== null && matches[1])
            filename = matches[1].replace(/['"]/g, "");
        }

        const file = new Blob([resp.data as BlobPart], {
          type: resp.headers["content-type"],
        });

        return {
          filename,
          file,
        };
      });
  }

  public getAndGetFile<D = ArrayBuffer, I = any>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<FileHandledServerResponse> {
    return http
      .get(url, {
        responseType: "arraybuffer",
        ...(config ?? {}),
      })
      .then((resp: AxiosResponse<D>) => {
        const disposition = resp.headers["content-disposition"];
        let filename = "unknown_file";

        if (disposition && disposition.indexOf("attachment") !== -1) {
          const matches = AttachmentFilenameRegexp.exec(disposition);

          if (matches !== null && matches[1])
            filename = matches[1].replace(/['"]/g, "");
        }

        const file = new Blob([resp.data as BlobPart], {
          type: resp.headers["content-type"],
        });

        return {
          filename,
          file,
        };
      });
  }

  public put<D, I = any>(
    url: string,
    item: I,
    config?: AxiosRequestConfig,
  ): Promise<D> {
    return http
      .put(url, item, config)
      .then(({ data }: ServerResponse<D>): D => data);
  }

  public patch<D, I = any>(
    url: string,
    item: Partial<I>,
    config?: AxiosRequestConfig,
  ): Promise<D> {
    return http
      .patch(url, item, config)
      .then(({ data }: ServerResponse<D>): D => data);
  }

  public delete<D>(url: string, config?: AxiosRequestConfig): Promise<D> {
    return http
      .delete(url, config)
      .then(({ data }: ServerResponse<D>): D => data);
  }
}
