import { ApiService } from "@drVue/api-service";

import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";

export interface GetByEmailResponse {
  exists?: boolean;
  user?: OrgUser | null;

  email_invalid?: boolean;
}

export interface UserParticipantTaskCategory {
  id: number;
  name: string;
  order: number;
}

export interface UserParticipantTaskData {
  id: number; // task id
  priority: string; // TODO: use enum?
  key: string;
  title: string;
  due_date: Date;

  room_id: number;
  status_id: number;
  categories: UserParticipantTaskCategory[];

  is_assignee_group: boolean;
  is_assignee_user: boolean;
  is_follower_user: boolean;
  is_reviewer_user: boolean;
}

export interface UserTaskCategory {
  id: number;
  name: string;
  order: number;
}
export interface UserTask {
  id: number;
  key: string;
  priority: "low" | "medium" | "high";
  status_id: number;
  title: string;
  due_date: string | null; //$date
  room_id: number;
  categories: UserTaskCategory[];
  is_assignee_group: boolean;
  is_assignee_user: boolean;
  is_reviewer_user: boolean;
  is_follower_user: boolean;
  is_reviewed: boolean | null;
}

export type OrgUserPatchRequestData = Partial<Pick<OrgUser, "custom_data">>;

export class OrgUsersApiService {
  api: ApiService = new ApiService();

  public list() {
    const url: string = this.api.Urls["api:client-dashboard:org-users-list"]();
    return this.api.get<OrgUser[]>(url);
  }

  public create(orgUser: Partial<OrgUser>) {
    const url: string = this.api.Urls["api:client-dashboard:org-users-list"]();
    return this.api.post<OrgUser>(url, orgUser);
  }

  public update(orgUserId: string, orgUser: Partial<OrgUser>) {
    const url: string =
      this.api.Urls["api:client-dashboard:org-users-detail"](orgUserId);
    return this.api.put<Partial<OrgUser>>(url, orgUser);
  }

  public remove(orgUserId: string) {
    const url: string =
      this.api.Urls["api:client-dashboard:org-users-detail"](orgUserId);
    return this.api.delete(url);
  }

  public patch(orgUserId: string, orgUser: OrgUserPatchRequestData) {
    const url: string =
      this.api.Urls["api:client-dashboard:org-users-detail"](orgUserId);
    return this.api.patch<OrgUser>(url, orgUser);
  }

  public getByEmail(email: string) {
    const url: string =
      this.api.Urls["api:client-dashboard:org-users-email-info"](email);
    return this.api.get<GetByEmailResponse>(url);
  }

  public async bulkRevokeAccess(orgUser: OrgUser) {
    // TODO: update users-bulk-remove api endpoint, use orgUser.id  instead of email
    const url: string =
      this.api.Urls["api:client-dashboard:users-bulk-remove"]();
    const data = { email: orgUser.email };

    return await this.api.post<any>(url, data);
  }

  public roomsTasks(orgUserId: string) {
    const url: string =
      this.api.Urls["api:client-dashboard:org-users-tasks"](orgUserId);
    return this.api.get<UserParticipantTaskData[]>(url);
  }

  public yourWorkTasks() {
    const url: string = this.api.Urls["api:client-dashboard:your-work-tasks"]();
    return this.api.get<UserTask[]>(url);
  }
}
