<template>
  <div :class="$style.container">
    <DrIconStatus
      v-if="firstElement.status"
      :class="$style.statusIcon"
      :color="firstElement.status.color"
      size="md"
    />
    <div class="truncate">
      {{ firstElement.title }}
    </div>
    <span v-if="moreItemsCount > 0">, +{{ moreItemsCount }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import DrIconStatus from "@shared/ui/dr-icon-status/DrIconStatus.vue";

import DrStore from "@drVue/store";
import { pinia } from "@drVue/store/pinia";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";

import type { TaskStatus } from "@drVue/store/modules/room/tasks-statuses/types";

interface Props {
  categoryUids: Array<{ category_uid: string }>;
  taskUids: Array<{ task_uid: string }>;
}
const props = defineProps<Props>();

const tasksStore = useTasksStore(pinia);
const categoriesStore = useCategoriesStore(pinia);

const getStatusById = DrStore.getters["room/tasksStatuses/byId"];

const categories = computed(() =>
  props.categoryUids.map(
    ({ category_uid }) => categoriesStore.categoriesByUid[category_uid] || null,
  ),
);

const tasks = computed(
  () =>
    props.taskUids.map(({ task_uid }) => tasksStore.tasksByUid[task_uid]) ||
    null,
);

const firstElement = computed<{ title: string; status?: TaskStatus }>(() => {
  if (categories.value.length && categories.value[0]) {
    return { title: categories.value[0].name };
  } else if (tasks.value.length && tasks.value[0]) {
    const [task] = tasks.value;
    const status = task ? getStatusById(task.status_id) : null;
    return { title: task.title, status: status };
  } else {
    return { title: "-" };
  }
});

const moreItemsCount = computed(() => {
  return categories.value.length + tasks.value.length - 1;
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.container {
  display: inline-flex;
  padding: 7px 0;
}

.statusIcon {
  margin-right: 8px;
}
</style>
