import * as d3 from "d3";

angular
  .module("dealroom.analytics.datagrid-table.service.filter", [
    "dealroom.analytics.service.data",
    "dealroom.analytics.service.filter",
  ])
  .service("AnalyticsDatagridFilterService", AnalyticsDatagridFilterService);

AnalyticsDatagridFilterService.$inject = [
  "AnalyticsDataDispatcher",
  "AnalyticsActivityDataService",
  "ActivityAnalyticsServiceLoadedPromise",
];
function AnalyticsDatagridFilterService(
  AnalyticsDataDispatcher,
  AnalyticsActivityDataService,
  ActivityAnalyticsServiceLoadedPromise,
) {
  return {
    watchFilters,
    filter,
  };

  function watchFilters(dispatcher, $scope, $element) {
    AnalyticsDataDispatcher.on("filtersChanged", mkFiltered, $element, $scope);

    function mkFiltered() {
      ActivityAnalyticsServiceLoadedPromise.then(function () {
        dispatcher.call("filtersChanged");
      });
    }
  }

  function filter({ verbsForFilter, cells }) {
    // $ctrl.verbs >> true | false | array;
    const verbs =
      verbsForFilter === true ? getVisibleVerbs(cells) : verbsForFilter;
    return AnalyticsActivityDataService.filter(filter, verbs);

    function getVisibleVerbs(cells) {
      return cells.reduce((verbs, cell) => {
        putVerbsToBucket(cell);
        return verbs;

        function putVerbsToBucket(cell) {
          if (cell.verbs instanceof Array) {
            verbs = verbs.concat(cell.verbs);
          }
          if (cell.childs instanceof Array) {
            cell.childs.forEach(putVerbsToBucket);
          }
        }
      }, []);
    }
  }
}
