import VueGridLayout from "vue-grid-layout";
import { createI18n } from "vue-i18n";

import enUS from "@app/locales/en-US.json";
import frFR from "@app/locales/fr-FR.json";
import { getLangCookie } from "@app/vue/common";
import { createOAuth2AuthorizationApp } from "@drVue/components/oauth/createOAuth2AuthorizeApp";
import { createRoomUnpaidApp } from "@drVue/components/room/createRoomUnpaidApp";
import { installElementUi } from "@drVue/plugin-element-ui";
import { installVxeTable } from "@drVue/plugin-vxe-table";
import * as DrComponents from "./components";
import { createAccessDeniedApp } from "./components/access-denied/createAccessDeniedApp";
import { createAuthApp } from "./components/auth/createAuthApp";
import { createClientDashboardApp } from "./components/client-dashboard/createClientDashboardApp";
import { createUserDashboardApp } from "./components/user-dashboard/createUserDashboardApp";
import store from "./store";
import { pinia } from "./store/pinia";

import type { App } from "vue";

export { default as DrStore } from "./store";
export { DrComponents };

const elements = new Map([
  ["vue-access-denied-app", createAccessDeniedApp],
  ["vue-auth-app", createAuthApp],
  ["vue-client-app", createClientDashboardApp],
  ["vue-user-app", createUserDashboardApp],
  ["vue-oauth2-authorize-app", createOAuth2AuthorizationApp],
  ["vue-room-unpaid-app", createRoomUnpaidApp],
]);

let id: string = "";
let createAppMethod: (() => App<Element>) | undefined;
for (const [_id, _createAppMethod] of elements.entries()) {
  if (document.getElementById(_id)) {
    id = _id;
    createAppMethod = _createAppMethod;
  }
}

const i18n = createI18n({
  legacy: false,
  locale: getLangCookie() ?? "en-US",
  fallbackLocale: "en-US",
  messages: {
    "en-US": enUS,
    "fr-FR": frFR,
  },
});

window.i18n = i18n.global;

if (createAppMethod) {
  const app = createAppMethod();

  installElementUi(app);
  installVxeTable(app);
  app.use(VueGridLayout);

  app.use(store);
  app.use(pinia);
  app.use(i18n);

  app.mount(`#${id}`);
}
