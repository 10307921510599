import type { State } from "./state";
import type { Action } from "./types";
import type { MutationTree } from "vuex";

export const mutations: MutationTree<State> = {
  set(state: State, actions: Action[]): void {
    actions.forEach(updateActionFields);
    state.actions = actions;
  },

  setIsLoading(state, isLoading: boolean): void {
    state.isLoading = isLoading;
  },

  setIsError(state, isError: boolean): void {
    state.isError = isError;
  },
};

function updateActionFields(action: Action) {
  if (!(action.dt instanceof Date)) {
    action.dt = new Date(action.dt);
  }
}
