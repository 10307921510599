import { h } from "vue";

import TasksTableCellCategory from "./tableCells/TasksTableCellCategory.vue";
import TasksTableCellDueDate from "./tableCells/TasksTableCellDueDate.vue";
import TasksTableCellId from "./tableCells/TasksTableCellId.vue";
import TasksTableCellPriority from "./tableCells/TasksTableCellPriority.vue";
import TasksTableCellRoom from "./tableCells/TasksTableCellRoom.vue";
import TasksTableCellStatus from "./tableCells/TasksTableCellStatus.vue";
import TasksTableCellTitle from "./tableCells/TasksTableCellTitle.vue";

import type { UserTaskInfo, UserTaskTableField } from "./types";
import type { DrVxeTableColumn } from "@drVue/components/types";

export interface UserTaskTableColumn extends DrVxeTableColumn<UserTaskInfo> {
  field: UserTaskTableField;
}
type TasksTableRowType = { row: UserTaskInfo };

const today = new Date();
today.setHours(0, 0, 0, 0);
const todayValue = today.getTime();

export default class TableColumns {
  get columns(): UserTaskTableColumn[] {
    return [
      this.columnKey(),
      this.columnTitle(),
      this.columnPriority(),
      this.columnStatus(),
      this.columnWorkList(),
      this.columnDueDate(),
      this.columnRoom(),
    ];
  }

  private columnKey(): UserTaskTableColumn {
    return {
      sortable: true,
      field: "key",
      title: "ID",
      minWidth: 60,
      width: 60,
      slots: {
        default: ({ row }: TasksTableRowType) => [
          h(TasksTableCellId, {
            id: row.key,
          }),
        ],
      },
    };
  }

  private columnTitle(): UserTaskTableColumn {
    return {
      sortable: true,
      field: "title",
      title: "Title",
      minWidth: 400,
      slots: {
        default: ({ row }: TasksTableRowType) => [
          h(TasksTableCellTitle, {
            title: row.title,
            url: row.url,
          }),
        ],
      },
    };
  }

  private columnPriority(): UserTaskTableColumn {
    return {
      sortable: true,
      field: "priority",
      title: "PR",
      width: 110,
      resizable: false,
      slots: {
        default: ({ row }: TasksTableRowType) => [
          h(TasksTableCellPriority, {
            priority: row.priority,
          }),
        ],
      },
    };
  }

  private columnStatus(): UserTaskTableColumn {
    return {
      sortable: true,
      field: "status",
      title: "Status",
      width: 140,
      resizable: false,
      slots: {
        default: ({ row }: TasksTableRowType) => [
          h(TasksTableCellStatus, {
            name: row.status.name,
            color: row.status.color,
          }),
        ],
      },
    };
  }

  private columnWorkList(): UserTaskTableColumn {
    return {
      sortable: true,
      field: "category",
      title: "Category",
      minWidth: 300,
      width: 300,
      slots: {
        default: ({ row }: TasksTableRowType) => [
          h(TasksTableCellCategory, {
            path: row.category.path,
            url: row.category.url,
          }),
        ],
      },
    };
  }

  private columnDueDate(): UserTaskTableColumn {
    return {
      sortable: true,
      field: "due_date",
      title: "Due date",
      width: 144,
      resizable: false,
      slots: {
        default: ({ row }: TasksTableRowType) => [
          h(TasksTableCellDueDate, {
            dueDate: row.due_date,
            isOverdue: todayValue > (row.due_date?.getTime() || 0),
          }),
        ],
      },
    };
  }

  private columnRoom(): UserTaskTableColumn {
    return {
      sortable: true,
      field: "room",
      title: "Room",
      minWidth: 150,
      slots: {
        default: ({ row }: TasksTableRowType) => {
          return [
            h(TasksTableCellRoom, {
              title: row.room.title,
              logo: row.room.logo,
              url: row.room.url,
            }),
          ];
        },
      },
    };
  }
}
