<template>
  <div class="dr-editor">
    <div
      v-if="editor"
      class="dr-editor__wrapper"
      :class="{ 'dr-editor__wrapper--read-only': !editable }"
    >
      <DrEditorMenu
        v-show="!options.showMenuOnFocus || wasFocused"
        v-if="editable"
        :editor="editor"
        :mention-menu-buttons="mentionMenuButtons"
      />
      <EditorContent
        class="dr-editor__content"
        :class="{ 'dr-editor__content--read-only': !editable }"
        :editor="editor"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { isEqual } from "lodash-es";
import { defineComponent } from "vue";
import {
  MentionProvider,
  MentionTypes,
} from "@shared/ui/editor/EditorMentions/providers";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import CodeBlock from "@tiptap/extension-code-block";
import Document from "@tiptap/extension-document";
import HardBreak from "@tiptap/extension-hard-break";
import History from "@tiptap/extension-history";
import Italic from "@tiptap/extension-italic";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Strike from "@tiptap/extension-strike";
import Text from "@tiptap/extension-text";
import Underline from "@tiptap/extension-underline";
import { Editor, EditorContent } from "@tiptap/vue-3";

import Heading from "./customHeading";
import { Mention } from "./EditorMentions/mentionsExt";
import DrEditorMenu from "./menu/DrEditorMenu.vue";
import { hasVisibleContent } from "./utils";

import type { Dictionary } from "@drVue/types";
import type {
  MenuButton,
  MenuButtonMethods,
} from "@shared/ui/editor/menu/types";
import type { Node as ProsemirrorNode } from "@tiptap/pm/model";
import type { EditorView } from "@tiptap/pm/view";
import type { PropType } from "vue";

interface EditorOptions {
  placeholder: string;
  showMenuOnFocus: boolean;
}

const defaultOptions: EditorOptions = {
  placeholder: "",
  showMenuOnFocus: false,
};

interface Data {
  editor: any | null;
  editorContent: any;
  editorOptions: EditorOptions;
  wasFocused: boolean;
  mentionsProvider: MentionProvider | null;
  mentionMenuButtons: MenuButton[];
}

export default defineComponent({
  name: "DrEditor",
  components: {
    EditorContent,
    DrEditorMenu,
  },
  props: {
    modelValue: {
      required: true,
      type: [Object, null] as PropType<object | null>,
    },
    editable: { default: true, type: Boolean as PropType<boolean> },
    mentions: {
      required: false,
      default: null,
      type: Object as PropType<Dictionary<any> | null>,
    },
    options: { default: () => ({}), type: Object as PropType<EditorOptions> },
  },
  emits: ["update:modelValue", "focus", "blur", "dr-editor"],
  data(): Data {
    return {
      editor: null,
      editorContent: null,
      editorOptions: { ...defaultOptions },
      wasFocused: false,
      mentionsProvider: null,
      mentionMenuButtons: [],
    };
  },
  watch: {
    mentions: {
      immediate: true,
      handler(mentions: Dictionary<any> | null) {
        this.updateMentions(mentions);
      },
    },
    editable: {
      handler(editable: boolean) {
        if (this.editor) {
          this.editor.options.editable = editable;
        }
      },
    },
    modelValue: {
      deep: true,
      handler(value) {
        if (this.editor && !isEqual(value, this.editorContent)) {
          this.editorContent = value;
          this.editor.commands.setContent(value);
        }
      },
    },
  },
  mounted() {
    const thany = this as any;

    this.editorOptions = { ...defaultOptions, ...this.options };
    this.mentionsProvider = new MentionProvider();

    this.editor = new Editor({
      editable: this.editable,
      editorProps: {
        handleClickOn(
          view: EditorView,
          pos: number,
          node: ProsemirrorNode,
          nodePos: number,
          event: MouseEvent,
        ) {
          const provider = thany.mentionsProvider;
          if (!provider) return false;

          if (node.attrs.type && node.attrs.id) {
            const item = provider.getItem(node.attrs.type, node.attrs.id);
            if (!item) return false;
          }

          if (event.button === 0 && node.type.name === "dr_mention") {
            thany.$emit("dr-editor", { ...node.attrs });
          }

          return true;
        },
      },
      extensions: [
        Bold,
        BulletList,
        CodeBlock,
        Document,
        HardBreak,
        Heading,
        History,
        Italic,
        ListItem,
        OrderedList,
        Paragraph,
        Strike,
        Text,
        Underline,
        Mention.configure({
          mentionsProvider: thany.mentionsProvider!,
        }),
        Link.configure({
          openOnClick: true,
          HTMLAttributes: { target: "_blank" },
        }),
        Placeholder.configure({
          emptyNodeClass: "dr-editor__placeholder",
          placeholder: this.editorOptions.placeholder,
          showOnlyWhenEditable: false,
        }),
      ],
      content: this.modelValue,
      onUpdate: () => {
        const doc = this.editor!.state.doc;
        thany.editorContent = hasVisibleContent(doc) ? doc.toJSON() : null;
        thany.$emit("update:modelValue", thany.editorContent);
      },
      onFocus: () => {
        thany.$emit("focus");
        thany.wasFocused = true;
      },
      onBlur: () => thany.$emit("blur"),
    });
    this.updateMentions(this.mentions);
  },
  beforeUnmount() {
    if (this.editor) {
      this.editor.destroy();
    }
  },
  methods: {
    getMentionMethods(type: MentionTypes): MenuButtonMethods {
      const thany = this as any;

      return {
        isActive: () => false,
        toggle: () => {
          const content =
            type === MentionTypes.Task
              ? "#"
              : type === MentionTypes.User
                ? "@"
                : "";

          return thany.editor?.chain().focus().insertContent(content).run();
        },
      };
    },
    updateMentions(mentions: Dictionary<any> | null) {
      const provider = this.mentionsProvider;
      if (!this.editor || !provider) {
        return;
      }

      provider.setItems(MentionTypes.User, mentions?.users);
      provider.setItems(MentionTypes.Task, mentions?.tasks);

      this.mentionMenuButtons = [];

      if (provider.isActive(MentionTypes.User)) {
        this.mentionMenuButtons.push({
          key: "mention-user",
          title: "Mention User",
          icon: "fal fa-at",
          methods: this.getMentionMethods(MentionTypes.User),
        });
      }
      if (provider.isActive(MentionTypes.Task)) {
        this.mentionMenuButtons.push({
          key: "mention-task",
          title: "Mention Request",
          icon: "fal fa-hashtag",
          methods: this.getMentionMethods(MentionTypes.Task),
        });
      }
    },
    focus() {
      // Probably a PM's bug not allowing to focus in the same tick
      setTimeout(() => {
        this.editor.view.focus();
      });
    },
  },
});
</script>
