import { CustomViewsModule } from "@drVue/store/modules/common/custom-views";
import { NotificationsModule } from "@drVue/store/modules/common/notifications";
import { RoutesRedirectsModule } from "@drVue/store/modules/common/routes-redirects";

import type { State as ViewsState } from "@drVue/store/modules/common/custom-views/state";
import type { State as NotificationsState } from "@drVue/store/modules/common/notifications/state";
import type { State as RoutesRedirectsState } from "@drVue/store/modules/common/routes-redirects/state";
import type { RootState } from "@drVue/store/state";
import type { Module, ModuleTree } from "vuex";

export interface CommonState {
  notifications: NotificationsState;
  customViews: ViewsState;
  routesRedirects: RoutesRedirectsState;
}

const modules: ModuleTree<RootState> = {
  notifications: NotificationsModule,
  customViews: CustomViewsModule,
  routesRedirects: RoutesRedirectsModule,
};

export const common: Module<CommonState, RootState> = {
  modules,
  namespaced: true,
};
