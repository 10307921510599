<template>
  <DrTruncatedTextTooltip :content="title">
    <a
      :href="url"
      :class="$style.title"
      target="_blank"
      @click="handleHavigate"
    >
      {{ title }}
    </a>
  </DrTruncatedTextTooltip>
</template>

<script lang="ts" setup>
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

interface Props {
  title: string;
  url: string;
}

defineProps<Props>();

const handleHavigate = () => {
  /** @todo later will be insightTrack here */
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.title {
  font: typography.$body_regular;
  color: colors.$pr-900;

  &:link {
    &:focus {
      color: colors.$pr-800;
      text-decoration: none;
    }

    &:hover {
      color: colors.$sc-600;
      text-decoration: underline;
    }
  }
}
</style>
