<template>
  <Transition>
    <a v-if="room" :href="`${room.permalink}/`" @click.stop="handleClick">
      <ElButton :disabled="room.is_archived">
        <span v-if="room.is_removed"> Room deleted </span>
        <span v-else-if="room.is_archived"> Room closed </span>
        <span v-else>
          Open room
          <DrIcon name="sign-in" />
        </span>
      </ElButton>
    </a>
    <a v-else-if="canCreateRoom">
      <ElDropdown
        split-button
        @click="$emit('create-room')"
        @command="handleMenuClick"
      >
        Create a room
        <template #dropdown>
          <ElDropdownMenu>
            <ElDropdownItem :command="DROPDOWN_COMMANDS.DeleteDeal">
              Delete deal
            </ElDropdownItem>
          </ElDropdownMenu>
        </template>
      </ElDropdown>
    </a>
  </Transition>
</template>

<script lang="ts" setup>
import { ElMessageBox } from "element-plus";
import { computed } from "vue";
import DrIcon from "@shared/ui/dr-icon";

import { ORG_MEMBER_DATA } from "@setups/data";

import type { Room } from "@drVue/store/modules/client-dashboard/deals/types";

interface Props {
  room?: Room;
}

const props = defineProps<Props>();

const emit = defineEmits(["no-access", "create-room", "delete-deal"]);

const DROPDOWN_COMMANDS = {
  DeleteDeal: "delete-deal",
};

const canCreateRoom = computed(() => {
  const { deals_access_type } = ORG_MEMBER_DATA.group;
  return (
    (["scope_criteria", "all"].includes(deals_access_type) &&
      ORG_MEMBER_DATA.group.can_create_rooms) ||
    ORG_MEMBER_DATA.group.is_administrator
  );
});

const handleClick = (ev: MouseEvent) => {
  if (props.room?.is_archived) {
    ev.preventDefault();
    return;
  }

  const hasAccessToRoom =
    props.room?.access.administrator || props.room?.access.member;

  if (!hasAccessToRoom) {
    ev.preventDefault();

    if (ORG_MEMBER_DATA.group.is_administrator) {
      emit("no-access");
    } else {
      ElMessageBox({
        type: "info",
        title: "Room Access",
        message:
          "You are not a member of this room. Contact room administrators to get access.",
      });
    }
  }
};

const handleMenuClick = (cmd: keyof typeof DROPDOWN_COMMANDS) => {
  if (cmd === DROPDOWN_COMMANDS.DeleteDeal) {
    emit("delete-deal");
  }
};
</script>

<style lang="scss" scoped>
.icon {
  margin-left: 8px;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
