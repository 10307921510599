<template>
  <div ref="dropZoneWrapperRef" :class="$style.container">
    <input
      ref="fileInputButtonRef"
      style="display: none"
      type="file"
      multiple
      :disabled="!editable"
      @change="onFileInputChange"
    />
    <div :class="$style.header" data-testid="deal-attachments-dropzone">
      <h4 :class="$style.title">Attachments</h4>
      <ElButton
        v-if="editable"
        plain
        type="primary"
        size="small"
        @click="openNativeWindow"
      >
        Upload&nbsp;
        <i :class="$style.actionIcon" class="fas fa-upload" />
      </ElButton>
    </div>
    <AttachmentsList :show-drop-zone="isShowDropZone">
      <AttachmentsItem
        v-for="{ file, status } in attachments"
        :id="file.id"
        :key="file.id"
        :mimetype="file.mimetype"
        :name="file.name"
        :date="file.date_created"
        :upload-status="status"
        :can-remove="editable"
        @cancel="handleCancel"
        @download="handleDownload"
        @remove="handleRemove"
      />
    </AttachmentsList>
  </div>
</template>

<script lang="ts" setup>
import { ElMessageBox } from "element-plus";
import { computed, ref } from "vue";
import { useStore } from "vuex";

import { generateUUID } from "@drVue/common";
import { useDropZone } from "@drVue/utils/useDropZone";
import AttachmentsItem from "./AttachmentsItem.vue";
import AttachmentsList from "./AttachmentsList.vue";

import type {
  Deal,
  DealAttachment,
} from "@drVue/store/modules/client-dashboard/deals/types";
import type { OrgFile } from "@drVue/store/modules/room/emails/EmailsApiService";
import type { UploadItem } from "@drVue/utils/useDropZone";
import type { UploadStatus } from "element-plus";
import type { Ref } from "vue";

interface UploadingDealAttachment extends DealAttachment {
  status?: UploadStatus;
  controller?: AbortController;
}

interface Props {
  deal: Deal;
  editable?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  editable: false,
});

const fileInputButtonRef = ref<HTMLInputElement | null>(null);
const dropZoneWrapperRef = ref<HTMLDivElement | null>(null);

const store = useStore();
const uploadingAttachments: Ref<UploadingDealAttachment[]> = ref([]);

const justUploadedFilesIds = ref<string[]>([]);

const attachments = computed<UploadingDealAttachment[]>(() => [
  ...(props.deal.attachments || []).filter(
    ({ file }) => !justUploadedFilesIds.value.includes(file.id),
  ),
  ...uploadingAttachments.value,
]);

const openNativeWindow = () => {
  fileInputButtonRef.value?.click();
};

const onDrop = (promise: Promise<UploadItem[]>) => {
  promise.then((items: UploadItem[]) => {
    if (items?.length) {
      // only files can be uploaded to deal
      const files = items.filter((item) => item.type !== "directory") as File[];
      handleUpload(files);
    }
  });
};

const onFileInputChange = ({ currentTarget }: Event) => {
  const files = Array.from((currentTarget as HTMLInputElement)?.files!);
  handleUpload(files);
};

const handleUpload = async (files: File[]) => {
  for (const file of files) {
    const controller = new AbortController();
    const localFileId = generateUUID();

    const promise = store.dispatch("clientDashboard/deals/postDealAttachment", {
      controller,
      dealId: props.deal.id,
      file,
      fileUploadedHook: (uploadedFile: { id: string }) => {
        justUploadedFilesIds.value.push(uploadedFile.id);
      },
      fileAttachedHook: () => {
        uploadingAttachments.value = uploadingAttachments.value.map((item) => {
          if (item.file.id === localFileId) {
            return { ...item, status: "success" };
          }
          return item;
        });
      },
    });

    uploadingAttachments.value.push({
      status: "uploading",
      controller,
      file: {
        id: localFileId,
        name: file.name,
        mimetype: file.type,
        size: file.size,
        date_created: new Date(),
      },
    });

    try {
      const orgFile: OrgFile = await promise;
      // we take ~1+ sec to display the success state
      setTimeout(() => {
        uploadingAttachments.value = uploadingAttachments.value.filter(
          ({ file }) => file.id !== localFileId,
        );
        justUploadedFilesIds.value = justUploadedFilesIds.value.filter(
          (id) => id !== orgFile.id,
        );
      }, 1000);
    } catch {
      uploadingAttachments.value = uploadingAttachments.value.filter(
        ({ file }) => file.id !== localFileId,
      );
    }
  }
};

const handleRemove = async (fileId: string) => {
  try {
    await ElMessageBox({
      type: "warning",
      title: "Delete attachment",
      message:
        "Are you sure you want to delete this attachment? Caution, this action cannot be undone.",
      confirmButtonText: "Delete",
      confirmButtonClass: "el-button--warning",
      showCancelButton: true,
      showClose: true,
      cancelButtonText: "No",
      closeOnHashChange: false,
      closeOnPressEscape: false,
    });
    await store.dispatch("clientDashboard/deals/removeDealAttachment", {
      dealId: props.deal.id,
      fileId,
    });
  } catch {
    // Do nothing: user canceled the action.
  }
};

const handleDownload = async (
  fileId: string,
  fileName: string,
  emailId?: string,
) => {
  const url = await store.dispatch(
    "clientDashboard/deals/getAttachmentDownloadPath",
    {
      dealId: props.deal.id,
      fileId,
    },
  );

  const a = document.createElement("a");
  a.href = url;
  a.setAttribute("download", fileName);
  a.click();
};

const handleCancel = (fileId: string) => {
  const attachment = uploadingAttachments.value.find(
    ({ file }) => file.id === fileId,
  );
  attachment!.controller?.abort();
};

const { isOverDropZone } = useDropZone(dropZoneWrapperRef, onDrop);
const isShowDropZone = computed(() => isOverDropZone.value && props.editable);
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.container {
  height: 100%;
  font-size: typography.$font-size-caption;
}

.header {
  display: grid;
  grid-template: "title button";
  grid-template-columns: auto 92px;
  padding: 16px 12px 12px 20px;

  // This is temporary: will be removed when we work on the style guide update buttons.
  :global {
    .el-button--small {
      border: unset;
      background: unset;
    }

    .el-button:hover {
      color: colors.$sc-700;
      i {
        color: colors.$sc-700;
      }
    }
  }
}

.title {
  grid-area: title;
  align-self: center;
  font-weight: 600;
  font-size: typography.$font-size-title;
  line-height: typography.$line-height-title;
  color: colors.$pr-900;
  margin-bottom: unset;
}

.actionIcon {
  padding-left: 10px;
}
</style>
