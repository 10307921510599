<template>
  <AiReportsExpansion :label="report.label" has-border has-caret hoverable>
    <template #actions="{ buttonHiddenClass, isOpen }">
      <ElButton
        :loading="loading"
        :class="isOpen ? undefined : buttonHiddenClass"
        @click.stop="applyReport"
      >
        Use template
      </ElButton>
    </template>

    <template #default>
      <ul :class="$style.reportFields">
        <li v-for="field in report.fields" :key="field.label">
          {{ field.label }}
        </li>
      </ul>
    </template>
  </AiReportsExpansion>
</template>

<script setup lang="ts">
import { AxiosError } from "axios";
import { ref } from "vue";

import { AiReportsEvent, insightTrack } from "@app/insight";
import { $notifyDanger } from "@app/vue/common";
import { pinia } from "@app/vue/store/pinia";
import AiReportsExpansion from "./AiReportsExpansion.vue";
import { useAiReportsStore } from "./AiReportsStore";
import { AiReportsApiService } from "./api";

import type { AiReport, AiReportSuggestion } from "./types";

interface Props {
  report: AiReportSuggestion;
}

interface Emits {
  (e: "open:report", value: AiReport): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const api = new AiReportsApiService();
const aiReportsStore = useAiReportsStore(pinia);

const loading = ref(false);

const applyReport = () => {
  loading.value = true;

  api
    .importReportSuggested(props.report.key)
    .then((response) => {
      insightTrack(AiReportsEvent.BuiltinReportCreated, {
        key: props.report.key,
      });

      aiReportsStore.list.push(response);

      emit("open:report", response);
    })
    .catch((error) => {
      if (
        error instanceof AxiosError &&
        error.response?.data?.builtin_report_template_key?.length
      ) {
        $notifyDanger(
          error.response.data.builtin_report_template_key.join(", "),
        );
      } else {
        $notifyDanger("Failed to apply report");
      }
    })
    .finally(() => {
      loading.value = false;
    });
};
</script>

<style module lang="scss">
.reportFields {
  list-style: disc;
  list-style-position: inside;
  margin-bottom: 18px;
}
</style>
