<template>
  <div :class="$style.wrapper">
    <DrVxeGrid
      outlined
      :data="data"
      :columns="tableColumns"
      :loading="loading"
    />
    <ElButton :disabled="!hasMore" :loading="loading" @click="loadData">
      Load More
    </ElButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import { ManagementApiService } from "@drVue/api-service/management";
import { $notifyDanger } from "@drVue/common";
import { drUserTime } from "@drVue/filters/drUserTime";

import type { AuditLogData } from "@drVue/api-service/management";

interface Data {
  loading: boolean;
  data: AuditLogData[];
  api: ManagementApiService;
  nextUrl: string | null;
  hasMore: boolean;
  tableColumns: any;
}

export default defineComponent({
  name: "UsersTable",
  components: {
    DrVxeGrid,
  },
  data(): Data {
    return {
      loading: false,
      data: [],
      api: new ManagementApiService(),
      nextUrl: null,
      hasMore: true,
      tableColumns: [
        {
          field: "dt",
          title: "Time",
          width: 150,
          formatter: ({ cellValue }: { cellValue: string }) => {
            return drUserTime(cellValue, "full-dt");
          },
        },
        {
          field: "action_label",
          title: "Action",
          width: 170,
          showOverflow: true,
        },
        {
          field: "user",
          title: "user_email",
          width: 200,
          showOverflow: true,
          formatter: ({ row }: { row: AuditLogData }) => {
            return `${row.user_name} <${row.user_email}>`;
          },
        },
        {
          field: "item_type",
          title: "Item Type",
          width: 80,

          showOverflow: true,
        },
        {
          field: "item_title",
          title: "Item",
          minWidth: 150,
          showOverflow: true,
        },
      ],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (this.loading || !this.hasMore) {
        return;
      }
      this.loading = true;
      const promise = this.nextUrl
        ? this.api.auditLogNext(this.nextUrl)
        : this.api.auditLog();
      promise
        .then(
          (data) => {
            this.data = this.data.concat(data.results);
            this.nextUrl = data.next || null;
            this.hasMore = !!this.nextUrl;
          },
          () => $notifyDanger("Failed to load audit log."),
        )
        .finally(() => {
          this.loading = false;
        });
    },
  },
});
</script>

<style lang="scss" module>
.wrapper {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr min-content;
  gap: 20px;
}
</style>
