import { DrStore } from "@drVue";

angular
  .module("dealroom.analytics.service.data.tasks", [
    "dealroom.analytics.service.data.loader",
    "dealroom.analytics.service.data.dispatcher",
    "dealroom.analytics.service.data.api",

    "dealroom.task",
    "dealroom.members",
  ])
  .service("ActivityTasksService", ActivityTasksService);

ActivityTasksService.$inject = ["ActivityRoomServiceLoadedPromise"];
function ActivityTasksService(ActivityRoomServiceLoadedPromise) {
  const service = {
    loadingPromise: ActivityRoomServiceLoadedPromise.then(processData),
    // tasksList: [],
    tasks: {},

    taskIdsToTypes,
  };

  ActivityRoomServiceLoadedPromise.then(processData);

  return service;

  function processData({ tasks }) {
    if (tasks === null) return;
    const tasksList = tasks.data;
    service.tasks = {};
    tasksList.forEach((task) => {
      task.status = DrStore.getters["room/tasksStatuses/byId"](task.status_id);
      service.tasks[task.id] = task;
    });
  }

  function taskIdsToTypes(taskIds) {
    const keys = Object.keys(
      DrStore.state.room.tasksStatuses.TYPES_DEFAULT_LABEL,
    );

    // Init phase.
    let countByType = [...keys, "total"].reduce((acc, type) => {
      acc[type] = 0;
      return acc;
    }, {});

    // Fill phase.
    countByType = taskIds.reduce((acc, taskId) => {
      const task = service.tasks[taskId];
      acc[task.status.type] += 1;
      acc.total += 1;
      return acc;
    }, countByType);

    return countByType;
  }
}
