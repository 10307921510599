import type { DrVxeTableColumn } from "@drVue/components/types";

export default abstract class BaseMgmtTableColumns<R = unknown> {
  constructor(readonly extraColumns: string[] = []) {}

  protected abstract getBaseColumns(): DrVxeTableColumn<R>[];
  protected abstract getExtraColumn(column: string): DrVxeTableColumn<R>;

  get columns(): DrVxeTableColumn<R>[] {
    const columns = this.getBaseColumns();

    for (const extraColumn of this.extraColumns) {
      columns.push(this.getExtraColumn(extraColumn));
    }
    return columns;
  }

  protected simpleTextColumn(
    label: string,
    field: string,
    options?: Partial<DrVxeTableColumn<R>>,
  ): DrVxeTableColumn<R> {
    let column: DrVxeTableColumn<R> = {
      sortable: true,
      field: field,
      title: label,
      minWidth: 130,
      showOverflow: "tooltip",
    };
    if (options) {
      column = { ...column, ...options };
    }
    return column;
  }
}
