<template>
  <DrLayoutContentCenteredSection title="Deals Timeline Fields">
    <ElForm
      v-loading="isSubmitting"
      label-width="85px"
      size="default"
      label-position="left"
    >
      <ElFormItem label="Close date">
        <ElSelect
          :model-value="settings.room_end_field"
          clearable
          @change="(id) => updateFields('room_end_field', id)"
        >
          <ElOption
            v-for="field in endFieldsList"
            :key="field.id"
            :label="field.label"
            :value="field.id"
          />
        </ElSelect>
      </ElFormItem>
    </ElForm>
  </DrLayoutContentCenteredSection>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrForm from "@shared/ui/dr-form";
import { DrLayoutContentCenteredSection } from "@shared/ui/dr-layouts";

import DrStore from "@drVue/store";
import { FieldItemTypes } from "@drVue/store/modules/client-dashboard/fields/types";

import type { FieldItem } from "@drVue/store/modules/client-dashboard/fields/types";

interface Data {
  settings: any;
}

export default defineComponent({
  name: "TimelineFields",
  components: {
    DrLayoutContentCenteredSection,
  },
  extends: DrForm,
  data(): Data {
    return {
      settings: DrStore.state.clientDashboard.settings,
    };
  },
  computed: {
    endFieldsList(): FieldItem[] {
      const allFields = DrStore.getters[
        "clientDashboard/customFields/byObjectType"
      ]("deal") as FieldItem[];
      return allFields.filter(
        (field) => field.field_type == FieldItemTypes.Date,
      );
    },
  },
  methods: {
    updateFields(field: string, value: null | number) {
      const data = { [field]: value };
      return this.submitAction(
        "clientDashboard/settings/update",
        data,
        "Failed to update client settings",
      );
    },
  },
});
</script>
