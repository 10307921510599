import * as d3 from "d3";

HeatmapController.$inject = [
  "$element",
  "$scope",
  "AnalyticsHeatmapConfigService",
];
function HeatmapController($element, $scope, AnalyticsHeatmapConfigService) {
  const $ctrl = this;
  // private
  const rootElement = d3.select($element[0]);
  const elementWidth = rootElement.select("div").node().offsetWidth;
  const expanded = {};
  const interpolate = d3.interpolateLab(
    ...AnalyticsHeatmapConfigService.COLORS,
  );

  // public
  $ctrl.elementWidth = elementWidth;
  $ctrl.lineGradientId = "lineGradient-HeatmapController" + $scope.$id;
  $ctrl.lineGradientColors = AnalyticsHeatmapConfigService.COLORS;

  $ctrl.$onInit = function () {
    $ctrl.dispatcher.add("expand", "updateHeatmap");
    $ctrl.dispatcher.on("update.HeatmapController", update, $element, $scope);
    $ctrl.dispatcher.on(
      "expand.HeatmapController",
      toggleExpand,
      $element,
      $scope,
    );
  };

  // functions
  let _storagedParams = {};
  let _storagedHeight;
  function update(params = {}, expandedKey = null) {
    if (expandedKey) {
      params = _storagedParams;
    }

    const isValid = [
      params.getData,
      params.x && params.x.values && params.x.values.length > 0,
      params.y && params.y.values && params.y.values.length > 0,
    ].every((d) => !!d);
    if (!isValid) params = AnalyticsHeatmapConfigService.getDefaultData();
    _storagedParams = angular.copy(params);

    params.y.values = getExpandedValues(expanded, params.y.values);

    const minHeight =
      params.y.values.length * AnalyticsHeatmapConfigService.MIN_CELL_HEIGHT;
    params.height = Math.max(
      minHeight,
      AnalyticsHeatmapConfigService.YAXIS.height,
    );

    params.width = elementWidth - AnalyticsHeatmapConfigService.XAXIS.left;

    params.x.scale = d3
      .scaleBand()
      .range([0, params.width])
      .domain(params.x.values.map((v) => v.key));
    params.y.scale = d3
      .scaleBand()
      .range([params.height, 0])
      .domain(params.y.values.map((v) => v.key));

    params.colorScale = d3.scaleSequential(interpolate);
    params.data = d3
      .cross(params.x.values, params.y.values)
      .map(createCellData);
    params.valuesExtent = (() => {
      if (params.showPercentage) return [0, 100];
      let [min = 0, max = 0] = d3.extent(params.data, (d) => d.value);
      if (min === max) min = 0;
      return [min, max];
    })();
    params.colorScale.domain(params.valuesExtent);

    rootElement
      .select("g.graph")
      .attr(
        "translate",
        `transform(0, ${AnalyticsHeatmapConfigService.YAXIS.top})`,
      );

    const svgHeight =
      params.height +
      AnalyticsHeatmapConfigService.YAXIS.bottom +
      AnalyticsHeatmapConfigService.YAXIS.top;
    rootElement
      .select("svg")
      .attr("height", svgHeight)
      .attr("width", elementWidth);

    $ctrl.dispatcher.call("updateHeatmap", params);

    function createCellData([x, y]) {
      const d = params.getData(x.key, y.key, y.lvl);
      d.getFill = (d) => {
        if (d.value === null)
          return AnalyticsHeatmapConfigService.DEFAULT_COLOR;
        if (params.valuesExtent[1] === 0)
          return AnalyticsHeatmapConfigService.COLORS[0];
        return params.colorScale(d.value);
      };

      d.x = params.x.scale(x.key);
      d.y = params.y.scale(y.key);
      d.width = params.x.scale.bandwidth();
      d.height = params.y.scale.bandwidth();

      const isEmpty = !angular.isNumber(d.value);
      d.text = isEmpty
        ? undefined
        : d.value + (params.showPercentage ? "%" : "");

      d.isNew = !expandedKey && !isEmpty;
      // d.isParent = expandedKey === y.key;
      d.isChild =
        expandedKey &&
        y.parentsIds &&
        y.parentsIds[y.parentsIds.length - 1] == expandedKey;

      d.border = 1;
      d.rx = Math.min(4, Math.max(d.width, d.height, 40) * 0.1);
      d.rectWidth = d.width - d.border * 2;
      d.rectHeight = d.height - d.border * 2;

      return d;
    }
  }

  function toggleExpand(key) {
    expanded[key] = !expanded[key];
    update(null, key);
  }
}

export default HeatmapController;

function getExpandedValues(expanded, values) {
  const checkIfExpanded = (values) =>
    values.reduce((bucket, label) => {
      const key = label.key;
      const parentIsClose = (label.parentsIds || []).find(
        (parentKey) => !expanded[parentKey],
      );

      if (parentIsClose) return bucket;

      if (expanded[key] === true) {
        const childsInValues = values.find(
          (ch) => ch.parentsIds && ch.parentsIds[0] === +label.key,
        );

        if (!childsInValues) {
          const childs = label.childs(label.lvl || 0);
          const expandedChilds = checkIfExpanded(childs);
          bucket = bucket.concat(expandedChilds);
        }
      }
      label.isOpen = expanded[key];
      bucket.push(label);
      return bucket;
    }, []);
  return checkIfExpanded(values);
}
