<template>
  <DrOverlayEmpty icon="folder-open" :title="title">
    <template #action>
      <template v-if="searchText || hasFilters">
        <ElButton v-if="searchText" @click="$emit('click:reset-search')">
          Clear search query
        </ElButton>
        <ElButton v-if="hasFilters" @click="$emit('click:reset-filters')">
          Reset filters
        </ElButton>
      </template>

      <slot v-else name="create" />
    </template>
  </DrOverlayEmpty>
</template>

<script setup lang="ts">
import { computed } from "vue";

import DrOverlayEmpty from "@app/vue/shared/ui/dr-overlay/DrOverlayEmpty.vue";

interface Props {
  searchText?: string;
  hasFilters?: boolean;
  isBookmarks?: boolean;
  isRecent?: boolean;
  isArchive?: boolean;
}

interface Emits {
  (e: "click:reset-search"): void;
  (e: "click:reset-filters"): void;
}

const props = defineProps<Props>();

defineEmits<Emits>();

const title = computed<string>(() => {
  if (props.hasFilters) return "No results matching your criteria were found.";

  if (props.searchText) return `No results for "${props.searchText}".`;

  if (props.isBookmarks) return "No documents bookmarked yet";

  if (props.isRecent) return "No recent files to show";

  if (props.isArchive) return "No documents archived";

  return "No documents uploaded";
});
</script>
