import { defineStore } from "pinia";

import { FindingsTypesApiService } from "@drVue/api-service/modules/findings-types";
import { generateList } from "@drVue/store/pinia/helpers/generators";

import type {
  FindingType,
  FindingTypeCreateOrUpdatePayload,
} from "@drVue/api-service/modules/findings-types/types";

export type { FindingType };

const api = new FindingsTypesApiService();

export const useFindingsTypesStore = defineStore(
  "pipelineFindingsTypes",
  () => {
    const { list, dict, load, create, remove, update, isLoading, isLoadError } =
      generateList<
        FindingType,
        FindingTypeCreateOrUpdatePayload,
        FindingTypeCreateOrUpdatePayload
      >({
        name: "Pipeline findings types",
        load: api.load.bind(api),
        remove: api.remove.bind(api),
        update: api.update.bind(api),
        create: api.create.bind(api),
      });

    const fillList = (items: FindingType[]) => {
      list.value = items;
    };

    return {
      list,
      dict,
      isLoading,
      isLoadError,
      load,
      create,
      remove,
      update,
      fillList,
    };
  },
);
