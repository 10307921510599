<template>
  <div data-testid="size-cell">
    <div v-if="row.type === 'file'" class="truncate">
      {{ size }}
    </div>
    <div v-else-if="row.type === 'folder' && docsCountLabel" class="truncate">
      {{ docsCountLabel }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { formatBytes } from "@app/common/formatters";

import type { PropType } from "vue";

export default defineComponent({
  name: "SizeCell",
  props: {
    row: { required: true, type: Object as PropType<any> },
  },
  computed: {
    size(): any {
      const r = this.row;
      return formatBytes(r.version ? r.version.size : r.size ? r.size : 0);
    },
    docsCountLabel(): any {
      const a = this.row.aggregates;
      return a ? `${a.files} ${a.files === 1 ? "doc" : "docs"}` : "";
    },
  },
});
</script>
