import type { Folder } from "@drVue/store/modules/room/documents/DocumentsApiService";
import type { TreeItem } from "@shared/ui/dr-tree";

export const toNode = (
  folder: Folder,
  showFiles?: boolean,
  preventCanParent?: boolean,
): TreeItem<{ label: string; canParent: boolean }> => {
  const children: TreeItem[] = [];

  if ("folders" in folder && folder.folders.length) {
    children.push(
      ...folder.folders.map((folder) =>
        toNode(folder, showFiles, preventCanParent),
      ),
    );
  }

  if (showFiles && "files" in folder && folder.files.length) {
    children.push(
      ...folder.files.map(({ uid, name, mimetype, type }) => ({
        id: uid,
        label: name,
        type,
        name,
        mimetype,
        canParent: false,
      })),
    );
  }

  return {
    ...folder,
    id: folder.uid,
    label: folder.name, // For compatibility with ElTree
    canParent: !preventCanParent,
    children: children as TreeItem<{ label: string; canParent: boolean }>[],
  };
};

export const filterTree = <Ext>(
  root: TreeItem,
  predicate: (node: TreeItem<Ext>) => boolean,
): TreeItem => {
  function dfsFilter(node: TreeItem): TreeItem {
    if (node && node.children && Array.isArray(node.children)) {
      const children = (node.children as TreeItem<Ext>[])
        .filter(predicate)
        .map(dfsFilter);
      return {
        ...node,
        children,
      };
    }
    return node;
  }
  return dfsFilter(root);
};
