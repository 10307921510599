import { ApiService } from "@drVue/api-service";

import type { CustomDataType } from "@drVue/store/modules/client-dashboard/fields/types";
import type { TaskId, TaskUid } from "@drVue/store/pinia/room/tasks";
import type { SynergySettings } from "@setups/data";

export interface Synergy {
  id: string;
  added_by_id: string;
  date_added: Date;
  title: string;
}

export interface CreateSynergyPayload extends Pick<Synergy, "title"> {}

export interface UpdateSynergyPayload extends Pick<Synergy, "title"> {}

export interface ValueDriver {
  id: string;
  added_by_id: string;
  synergy_id: Synergy["id"];
  date_added: Date;
  title: string;
  tasks: TaskUid[];
  custom_data: CustomDataType;
  periods_data?: PeriodData[];
}

export interface CreateValueDriverPayload
  extends Pick<ValueDriver, "synergy_id" | "title"> {
  tasks: TaskUid[];
}

export interface UpdateValueDriverPayload
  extends Partial<Pick<ValueDriver, "title" | "custom_data">> {}

export interface PeriodData {
  start_date: Date;
  custom_data: CustomDataType;

  // Please add this link:
  value_driver_id: ValueDriver["id"];
}

export class SynergiesApiService extends ApiService {
  public async saveSettings(
    payload: SynergySettings,
  ): Promise<SynergySettings> {
    const url: string = this.Urls["api:room:synergy-settings"]();
    return this.put<SynergySettings>(url, payload);
  }

  public async loadSynergies(): Promise<Synergy[]> {
    const url: string = this.Urls["api:room:synergies-list"]();
    return this.get<Synergy[]>(url);
  }

  public async createSynergy(payload: CreateSynergyPayload): Promise<Synergy> {
    const url: string = this.Urls["api:room:synergies-list"]();
    return this.post<Synergy>(url, payload);
  }

  public async updateSynergy(
    id: Synergy["id"],
    payload: UpdateSynergyPayload,
  ): Promise<Synergy> {
    const url: string = this.Urls["api:room:synergies-detail"](id);
    return this.put<Synergy>(url, payload);
  }

  public async patchSynergy(
    id: Synergy["id"],
    title: Synergy["title"],
  ): Promise<Synergy> {
    const url: string = this.Urls["api:room:synergies-detail"](id);
    return this.patch<Synergy>(url, { title });
  }

  public async deleteSynergy(id: Synergy["id"]): Promise<void> {
    const url: string = this.Urls["api:room:synergies-detail"](id);
    return this.delete(url);
  }

  public async loadValueDrivers(): Promise<ValueDriver[]> {
    const url: string = this.Urls["api:room:value-drivers-list"]();
    return this.get(url);
  }

  public async createValueDriver(
    payload: CreateValueDriverPayload,
  ): Promise<ValueDriver> {
    const url: string = this.Urls["api:room:value-drivers-list"]();
    return this.post(url, payload);
  }

  public async updateValueDriver(
    id: ValueDriver["id"],
    payload: UpdateValueDriverPayload,
  ): Promise<ValueDriver> {
    const url: string = this.Urls["api:room:value-drivers-detail"](id);
    return this.put(url, payload);
  }

  public async patchValueDriver(
    id: ValueDriver["id"],
    payload: UpdateValueDriverPayload,
  ): Promise<ValueDriver> {
    const url: string = this.Urls["api:room:value-drivers-detail"](id);
    return this.patch(url, payload);
  }

  public async deleteValueDriver(id: ValueDriver["id"]): Promise<void> {
    const url: string = this.Urls["api:room:value-drivers-detail"](id);
    return this.delete(url);
  }

  public async patchPeriod(
    valueDriverId: ValueDriver["id"],
    date: string,
    data: CustomDataType,
  ): Promise<PeriodData> {
    const url: string = this.Urls["api:room:value-drivers-periods"](
      valueDriverId,
      date,
    );

    return this.patch(url, {
      custom_data: data,
    });
  }

  public async updateValueDriverTasks(
    valueDriverId: ValueDriver["id"],
    add: TaskUid[],
    remove: TaskUid[],
  ): Promise<ValueDriver> {
    const url: string =
      this.Urls["api:room:value-drivers-detail-tasks-update"](valueDriverId);

    return this.post(url, {
      add,
      remove,
    });
  }
}

export const synergiesApi = new SynergiesApiService();
