import {
  isDocument,
  isFolder,
} from "@drVue/store/modules/room/documents/DocumentsApiService";

import type { DocsItem } from "@drVue/store/modules/room/documents/DocumentsApiService";

let lastCheckedIndex: number | null = null;

export class ItemsService {
  items: DocsItem[];

  hasSelected = false;

  hasDownloadable = false;

  allowSelectedEdit = false;

  allowSelectedDownload = false;

  allowSelectedDownloadOriginal = false;

  constructor(items: DocsItem[]) {
    this.items = items;
    this.hasDownloadable =
      items.filter((item: DocsItem) => item.download).length !== 0;

    this.update();
  }

  toggleItemSelected(item: DocsItem, $event: MouseEvent) {
    const value = item._isChecked;
    const newCheckedIndex = this.items.indexOf(item);

    if (lastCheckedIndex !== null && $event.shiftKey) {
      const start = Math.min(lastCheckedIndex, newCheckedIndex);
      const end = Math.max(lastCheckedIndex, newCheckedIndex);

      this.items.forEach((item, index) => {
        if (index <= start || index >= end) return;

        item._isChecked = value;
      });
    }

    lastCheckedIndex = newCheckedIndex;

    this.update();
  }

  isItemSelected(item: DocsItem): boolean {
    return item._isChecked === true;
  }

  updateSelectedPermissions() {
    const selected = this.getSelected();

    this.allowSelectedDownload = selected.every((item) => item.download);
    this.allowSelectedDownloadOriginal = this.allowSelectedDownload;
    this.allowSelectedEdit = selected.every((item) => item.edit);
  }

  update() {
    this.hasSelected = this.getHasSelected();

    this.updateSelectedPermissions();
  }

  isAllSelected() {
    return this.items.every((item) => item._isChecked);
  }

  getHasSelected() {
    return this.items.some((item) => item._isChecked);
  }

  indeterminate() {
    return this.getHasSelected() && !this.isAllSelected();
  }

  isSingleFileSelected() {
    return this.getSelectedDocuments().length === 1;
  }

  getSelectedDocumentsId() {
    return this.getSelectedDocuments().map((item) => item.id);
  }

  getSelectedDocuments() {
    return this.items.filter(
      (item) => isDocument(item) && item._isChecked === true,
    );
  }

  getSelectedFoldersId() {
    return this.getSelectedFolders().map((item) => item.id);
  }

  getSelectedFolders() {
    return this.items.filter(
      (item) => isFolder(item) && item._isChecked === true,
    );
  }

  getSelected() {
    return this.items.filter((item) => item._isChecked === true);
  }

  getAllDownloadableFoldersId() {
    return this.items
      .filter((item) => isFolder(item) && item.download)
      .map((item) => item.id);
  }

  getAllDownloadableDocumentsId() {
    return this.items
      .filter((item) => isDocument(item) && item.download)
      .map((item) => item.id);
  }
}

const RoomDocumentFolderService = {
  getInstance: function (items: DocsItem[]) {
    return new ItemsService(items);
  },
};

export default RoomDocumentFolderService;
