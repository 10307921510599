import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";
import type {
  UserParticipantTaskCategory,
  UserParticipantTaskData,
} from "@drVue/store/modules/client-dashboard/org-users/OrgUsersApiService";

interface CategoryRow {
  deal: Deal;
  path: UserParticipantTaskCategory[];
}

export enum TableRowTypes {
  Task = "task",
  Category = "category",
}

interface TableRowBase {
  type: TableRowTypes;
}

export interface TableTaskRow {
  type: TableRowTypes.Task;
  task: UserParticipantTaskData;
}

export interface TableCategoryRow {
  type: TableRowTypes.Category;
  category: CategoryRow;
}

export type ITableRow = TableRowBase & (TableTaskRow | TableCategoryRow);
