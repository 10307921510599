import { participantsContains } from "./helpers/participantsContains";

import type { TaskPredicate } from "@app/ng/tasks/services/ts/types/TaskPredicate";

export const followers: TaskPredicate = (task, filters) => {
  if (!task.followers) return true;

  const followersIds = filters.followers;
  if (followersIds.length === 0) return true;

  return participantsContains(task.followers, followersIds);
};
