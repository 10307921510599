import * as d3 from "d3";

angular
  .module("dealroom.analytics.heatmap.cells", [
    "dealroom.analytics.heatmap.service.config",
  ])
  .directive("drAnalyticsHeatmapCells", () => ({
    scope: {
      dispatcher: "<",
    },
    controller: HeatmapCellsController,
    restrict: "A",
    controllerAs: "$ctrl",
  }));

const showvaluesAttr = "show-values-target";
const MIN_CELL_WIDTH = 30;

HeatmapCellsController.$inject = [
  "$scope",
  "$element",
  "AnalyticsHeatmapConfigService",
];
function HeatmapCellsController(
  $scope,
  $element,
  AnalyticsHeatmapConfigService,
) {
  const dispatcher = $scope.dispatcher;
  const left = AnalyticsHeatmapConfigService.XAXIS.left;
  const right = AnalyticsHeatmapConfigService.YAXIS.top;
  const rootElement = d3
    .select($element[0])
    .attr("transform", `translate(${left}, ${right})`);

  const updateUid = "updateHeatmap.HeatmapCellsController";
  const toggleValuesUid = "toggleValues.HeatmapCellsController";
  dispatcher.on(updateUid, update, $element, $scope);
  dispatcher.on(toggleValuesUid, toggleValues, $element, $scope);

  let _valuesDisplay = "none";

  function update({ data }) {
    const cells = rootElement.selectAll(".cells").data(data);
    cells.exit().each(removeCell);

    cells
      .enter()
      .append("g")
      .attr("class", "cells")
      .merge(cells)
      .each(createCell);

    toggleValues(null);
  }

  function toggleValues(show) {
    _valuesDisplay =
      show === true ? "block" : show === false ? "none" : _valuesDisplay;
    rootElement
      .selectAll(`[${showvaluesAttr}]`)
      .style("display", _valuesDisplay)
      .attr(showvaluesAttr, _valuesDisplay);
  }

  function createCell(d) {
    const g = d3
      .select(this)
      // .style('cursor', d => d.cursor)
      .attr("transform", `translate(${d.x}, ${d.y})`)
      .on("mouseenter", selectCell)
      .on("mouseleave", unselectCell);

    let wrapper = g.selectAll(".wrapper").data([d]);
    wrapper = wrapper
      .enter()
      .append("g")
      .attr("class", "wrapper")
      .merge(wrapper);

    const rect = wrapper.selectAll(".value").data([d]);
    rect
      .enter()
      .append("rect")
      .attr("class", "value")
      .merge(rect)
      .each(updateRectAttributes);

    const text = wrapper.selectAll(".numbers").data([d]);
    text
      .enter()
      .append("text")
      .attr("class", "numbers")
      .merge(text)
      .each(updateTextAttributes);

    if (d.isChild) g.each(drawChild);
    // else if (d.isParent) g.each(drawParent);
    else if (d.isNew) g.each(drawNew);
  }

  function drawNew(d) {
    const g = d3.select(this);
    const rect = g.select("rect");
    const text = g.select("text");

    const startWidth = d.rectWidth * 0.1;
    const startOpacity = 0;

    const [cleanDuration, updateDuration] = [400, 300];
    const cleanTransition = d3
      .transition()
      .duration(cleanDuration)
      .ease(d3.easeQuadOut);
    const updateTransition = d3
      .transition()
      .delay(cleanDuration)
      .duration(updateDuration)
      .ease(d3.easeQuadIn);

    rect
      // hide
      .interrupt()
      .transition(cleanTransition)
      .attr("width", (d) => startWidth)
      .delay(60)
      .attr("opacity", (d) => startOpacity)
      // show
      .transition(updateTransition)
      .attr("opacity", 1)
      .delay(60)
      .attr("width", (d) => d.rectWidth);

    text
      // hide
      .style("display", "none")
      .interrupt()
      .transition(100)
      .text("")
      .attr("opacity", 0)
      // show
      .transition(updateTransition)
      .text((d) => d.text)
      .delay(updateDuration / 4)
      .attr("opacity", 1);
  }

  function removeCell(d) {
    const g = d3.select(this);
    const rect = g.select("rect");
    // rect.attr('fill', 'red')
    g.remove();
  }

  function drawChild(d) {
    const g = d3.select(this);
    const rect = g.select("rect");
    const text = g.select("text");

    const expandTransition = d3.transition().duration(300).ease(d3.easeExpOut);

    rect
      .interrupt()
      .attr("opacity", 0)
      .attr("y", -d.height)
      .attr("height", 1)
      .transition(expandTransition)
      .attr("opacity", 1)
      .attr("y", d.border)
      .attr("height", (d) => d.rectHeight);
  }

  function updateRectAttributes(d) {
    const rect = d3.select(this);
    rect
      .attr("fill", d.getFill)
      .attr("width", (d) => d.rectWidth)
      .attr("height", (d) => d.rectHeight)
      .attr("x", (d) => d.border)
      .attr("y", (d) => d.border)
      .attr("rx", (d) => d.rx)
      .attr("ry", (d) => d.rx);
  }

  function updateTextAttributes(d) {
    const text = d3.select(this);
    text
      // 2px for aligning in the center
      .attr("dx", (d) => d.width / 2)
      .attr("dy", 5)
      .attr("text-anchor", "middle")
      .attr("fill", "#464d5d")
      .attr("transform", alignText)
      .attr(showvaluesAttr, (d) => (d.text ? "" : null))
      .text((d) => d.text);
  }

  function alignText(d) {
    const isRotated = d.width < MIN_CELL_WIDTH && d.width < d.height;
    if (isRotated) {
      const left = d.height / 2 + d.width / 2;
      const top = d.width / 2 - 2;
      return `translate(${top},${left}), rotate(-90)`;
    } else {
      const left = d.height / 2 - 2;
      const top = 0;
      return `translate(${top},${left})`;
    }
  }

  function selectCell(d) {
    if (d.value === null) return;
    const g = d3.select(this);
    g.select(".numbers").style("display", "block");
    const side = Math.max(d.width, d.height);
    const margin = d.border * 1.5;
    const scale = (side + margin * 2) / side;
    const marginY = (d.height * scale - d.height) / 2;
    const marginX = (d.width * scale - d.width) / 2;
    g.select(".wrapper").attr(
      "transform",
      `translate(${-marginX},${-marginY}), scale(${scale})`,
    );
  }

  function unselectCell(d) {
    if (d.value === null) return;
    const g = d3.select(this);
    const numbers = g.select(".numbers");
    numbers.style("display", numbers.attr(showvaluesAttr) || "none");
    g.select("rect").attr("stroke", null);
    g.select(".wrapper").attr("transform", "");
  }
}
