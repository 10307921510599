<template>
  <DrVxeGrid
    :set-grid-ref="setGridRef"
    :row-config="{
      useKey: true,
      keyField: 'id',
      height: 38,
    }"
    :sort-config="tableSortConfig"
    :columns="columns"
    :loading="isLoading"
    :data="sortedItems"
    @cell-click="handleCellClick"
    @sort-change="handleSortChanged"
    @resizable-change="handleColumnSizeChanged"
  >
    <template #empty>No findings found</template>
  </DrVxeGrid>
</template>

<script setup lang="ts">
import { orderBy } from "lodash-es";
import { computed, ref, watch } from "vue";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import { CustomViewObjectTypes } from "@setups/types";
import { FieldItemTypes } from "@drVue/api-service/client-dashboard";
import { FindingLikelihoodDict } from "@drVue/api-service/modules/findings/types";
import { FindingSeverityDict } from "@drVue/api-service/modules/findings/types";
import DrStore from "@drVue/store";
import { getSortValue } from "@drVue/store/modules/client-dashboard/deals/utils";
import { pinia } from "@drVue/store/pinia";
import { useFindingsStatusesStore } from "@drVue/store/pinia/pipeline/findings-statuses";
import { useFindingsTypesStore } from "@drVue/store/pinia/pipeline/findings-types";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import utils from "@drVue/utils";
import { saveColumnsConfig, saveSortConfig } from "../utils";
import { useFindingsReorder } from "./useFindingsReorder";

import type { FindingsTableField, FindingsTableRow } from "../types";
import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { DrVxeTableColumn } from "@drVue/components/types";
import type { CustomView } from "@setups/types";
import type { CustomViewColumn } from "@setups/types";
import type {
  VxeGridInstance,
  VxeTableEvents,
  VxeTablePropTypes,
} from "vxe-table";

interface Props {
  isLoading: boolean;
  items: FindingsTableRow[];
  columns: DrVxeTableColumn<FindingsTableRow>[];
  customFields: FieldItem[];
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: "select", id: FindingsTableRow["id"]): void;
  (
    event: "reordered",
    moveId: FindingsTableRow["id"],
    beforeId: FindingsTableRow["id"] | null,
    afterId: FindingsTableRow["id"] | null,
    callbackReorderingCompleted: () => void,
  ): void;
}>();

const tasksStore = useTasksStore(pinia);
const categoriesStore = useCategoriesStore(pinia);
const findingsTypeStore = useFindingsTypesStore(pinia);
const findingsStatusesStore = useFindingsStatusesStore(pinia);

const handleCellClick: VxeTableEvents.CellClick = ({ row }) => {
  emit("select", row.id);
};

const handleColumnSizeChanged: VxeTableEvents.ResizableChange = ({
  column,
}) => {
  const update: CustomViewColumn = {
    field: column.field,
    width: column.resizeWidth,
  };

  saveColumnsConfig(DrStore, [update]);
};

const handleSortChanged: VxeTableEvents.SortChange = ({ column }) => {
  const sort = column.order
    ? {
        field: column.field,
        order: column.order,
      }
    : undefined;

  saveSortConfig(DrStore, sort);
};

const view = computed<CustomView>(() =>
  DrStore.getters["common/customViews/defaultView"](
    CustomViewObjectTypes.Finding,
  ),
);

watch(
  () => !!view.value.settings.tableLayoutSort?.field,
  (isReorderDisabled) => {
    isReorderDisabled ? findingsReorder.disable() : findingsReorder.enable();
  },
);

const sortTableData = (
  data: FindingsTableRow[],
  field?: FindingsTableField,
  order?: "asc" | "desc" | null,
) => {
  if (!field || !order) return data;

  let list = [...data];

  if (order === "asc" || order === "desc") {
    // to sort finding "date"|"numeric" field without value always below
    const lastNumberValue = order === "asc" ? Infinity : -Infinity;
    // to sort finding "string" field without value always below
    const lastStringValue =
      order === "asc" ? "\uFFFF\uFFFF\uFFFF\uFFFF" : "\u0000\u0000\u0000\u0000";

    if (field.startsWith("custom_data.")) {
      const customField = props.customFields.find((cField) => {
        return `custom_data.${cField.key}` === field;
      });

      if (!customField) {
        throw `Invalid sorting order ${field}`;
      }

      return orderBy(
        list,
        [
          (finding: FindingsTableRow) => {
            let value = finding.custom_data?.[customField.key];
            let isNumeric = false;

            if (
              [FieldItemTypes.Number, FieldItemTypes.Date].includes(
                customField.field_type,
              )
            ) {
              value = getSortValue(customField, value);
              isNumeric = true;
            }

            if (value === undefined || value === "-") {
              return isNumeric ? lastNumberValue : lastStringValue;
            }

            return value;
          },
        ],
        [order],
      );
    }

    switch (field) {
      case "title":
        list = orderBy(list, [field], [order]);
        break;

      case "key":
        list = orderBy(list, [(finding) => Number(finding.key)], [order]);
        break;

      case "type_id":
        list = orderBy(
          list,
          [
            (finding) =>
              findingsTypeStore.dict[finding.type_id]?.name ?? lastStringValue,
          ],
          [order],
        );
        break;

      case "status_id":
        list = orderBy(
          list,
          [
            (finding) =>
              findingsStatusesStore.dict[finding.status_id]?.name ??
              lastStringValue,
          ],
          [order],
        );
        break;

      case "severity":
        list = orderBy(
          list,
          [
            (finding) =>
              FindingSeverityDict[finding.severity || ""]?.label ??
              lastStringValue,
          ],
          [order],
        );
        break;

      case "likelihood":
        list = orderBy(
          list,
          [
            (finding) =>
              FindingLikelihoodDict[finding.likelihood || ""]?.label ??
              lastStringValue,
          ],
          [order],
        );
        break;

      case "ties_to":
        list = orderBy(
          list,
          [
            (finding) => {
              if (finding.categories.length) {
                return (
                  categoriesStore.categoriesByUid[
                    finding.categories[0].category_uid
                  ]?.name ?? lastStringValue
                );
              } else if (finding.tasks.length) {
                return (
                  tasksStore.tasksByUid[finding.tasks[0].task_uid]?.title ??
                  lastStringValue
                );
              } else {
                return lastStringValue;
              }
            },
          ],
          [order],
        );
        break;

      case "date_added":
      case "date_resolved":
        list = orderBy(
          list,
          [(finding) => finding[field]?.getTime() ?? lastNumberValue],
          [order],
        );
        break;

      case "assignees":
        list = orderBy(
          list,
          [
            (finding) => {
              if (finding.assignees.length) {
                return (
                  DrStore.state.room.members.membersByUid[
                    finding.assignees[0].user_id
                  ]?.name ?? lastStringValue
                );
              }

              return lastStringValue;
            },
          ],
          [order],
        );
        break;

      case "added_by_id":
        list = orderBy(
          list,
          [
            (finding) => {
              return (
                DrStore.state.room.members.membersByUid[finding.added_by_id]
                  ?.name ?? lastStringValue
              );
            },
          ],
          [order],
        );
        break;

      case "description":
      case "mitigation_plan":
      case "actual_mitigation":
        list = orderBy(
          list,
          [
            (finding) => {
              const source = (finding[field] || {}) as unknown as Record<
                string,
                unknown
              >;
              const text = utils.objects.findValueByKey(source, "text");
              return (
                (text && String(text).trim().toLowerCase()) || lastStringValue
              );
            },
          ],
          [order],
        );
        break;
    }
  }

  return list;
};

const sortedItems = computed(() => {
  const field = view.value.settings.tableLayoutSort?.field;
  const order = view.value.settings.tableLayoutSort?.order;

  return sortTableData(props.items, field as FindingsTableField, order);
});

const tableSortConfig: VxeTablePropTypes.SortConfig<FindingsTableRow> = {
  defaultSort: {
    field: view.value.settings.tableLayoutSort?.field ?? "order",
    order: view.value.settings.tableLayoutSort?.order ?? "asc",
  },
  trigger: "cell",
  remote: true,
};

const gridRef = ref<VxeGridInstance | null>(null);
const setGridRef = (ref: VxeGridInstance) => (gridRef.value = ref);

const findingsReorder = useFindingsReorder(
  gridRef,
  (moveId, beforeId, afterId, updateTable) => {
    emit("reordered", moveId, beforeId, afterId, updateTable);
  },
  !view.value.settings.tableLayoutSort?.field,
);

defineExpose({
  sortedItems,
});
</script>
