AnalyticsDatagridTableSorting.$inject = ["$scope", "$timeout"];
import * as d3 from "d3";

const STORAGE_SEPARATOR = ";";
const SORTING = ["desc", "asc"];

angular
  .module("dealroom.analytics.datagrid-table.table.sorting", [])
  .directive("drAnalyticsDatagridTableSorting", () => ({
    scope: {
      dispatcher: "<",
      uid: "<",
      defaultSort: "<",
    },
    restrict: "A",
    controller: AnalyticsDatagridTableSorting,
  }));

function AnalyticsDatagridTableSorting($scope, $timeout) {
  const pk = "AnalyticsDatagridTableSorting-" + $scope.$id;
  const dispatcher = $scope.dispatcher;
  const uid = $scope.uid;
  const defaultSort = $scope.defaultSort;

  dispatcher.on("setSortBy." + pk, setSortBy);

  const [initSortBy, initSortOrder] = fromStorage(uid);
  const sortBy = {
    by: !defaultSort && initSortBy,
    order: (!defaultSort && initSortOrder) || SORTING[0],
    fn: Function.prototype,
  };

  $timeout(function () {
    setSortBy(defaultSort);
  });

  function setSortBy(d) {
    const key = d && d.key;
    if (key) {
      if (sortBy.by == key) {
        const idx = SORTING.indexOf(sortBy.order);
        sortBy.order = SORTING[idx + 1];
        if (sortBy.order === undefined) {
          sortBy.by = undefined;
          sortBy.order = SORTING[0];
        }
      } else {
        sortBy.by = key;
        sortBy.order = SORTING.includes(d && d.order) ? d.order : SORTING[0];
      }
      toStorage(sortBy, uid);
    }
    sortBy.fn = getSortingFn(sortBy);

    dispatcher.call("sortBy", null, sortBy, SORTING);
  }
}

function fromStorage(uid) {
  const value = sessionStorage[uid];
  if (!value) return [];
  return value.split(STORAGE_SEPARATOR);
}

function toStorage(sortBy, uid) {
  let value = "";
  if (sortBy.by) value = [sortBy.by, sortBy.order].join(STORAGE_SEPARATOR);
  sessionStorage[uid] = value;
}

function getSortingFn(sortBy) {
  const compareFn = sortBy.order === "desc" ? d3.descending : d3.ascending;
  const getValue =
    sortBy.by === "title"
      ? _getTitle
      : sortBy.by !== undefined
        ? _getValue
        : _getOrder;

  return function (a, b) {
    if (_isDates(a) || _isActivity(a)) {
      return _compareByOrder(a, b);
    }
    const aValue = getValue(a);
    const bValue = getValue(b);
    if (aValue !== bValue) {
      return compareFn(aValue, bValue);
    } else {
      return _compareByOrder(a, b);
    }
  };

  function _isDates(d) {
    // eslint-disable-next-line no-useless-escape
    return /\d+\-\d+\-day-/g.test(d.itemId);
  }

  function _isActivity(d) {
    return d.itemId === "d-" + d.id;
  }

  function _compareByOrder(a, b) {
    const aOrder = _getOrder(a);
    const bOrder = _getOrder(b);
    return d3.ascending(aOrder, bOrder);
  }

  function _getOrder(d) {
    return d.order;
  }

  function _getTitle(d) {
    return d.sortName || _getOrder(d);
  }

  function _getValue(d) {
    const cell = d.cells.find((c) => c.key === sortBy.by);
    if (!cell) return _getOrder(d);
    return cell.value;
  }
}
