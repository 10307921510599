import baseHtml from "./index.html?raw";

BaseDropdownController.$inject = ["$element", "$timeout"];
import "./toggle";
import "./menu";

import * as d3 from "d3";

angular
  .module("dealroom.analytics.dropdown.base", [
    "dealroom.analytics.dropdown.base.menu",
    "dealroom.analytics.dropdown.base.toggle",
  ])
  .component("drAnalyticsDropdown", {
    bindings: {
      multiselect: "<?",
      label: "@",
      type: "@?",
      handleSelect: "<",
      items: "<",
      alignment: "@",
      uid: "@",

      promise: "<?",
    },
    controller: BaseDropdownController,
    template: baseHtml,
  });

const cookieValuesSeparator = ";";

function BaseDropdownController($element, $timeout) {
  const $ctrl = this;
  const rootElement = d3.select($element[0]);
  let cookieKey = "DrDropdown__BaseDropdownController";
  let isClose = true;
  let selectedStorage;

  $ctrl.dispatcher = d3.dispatch(
    "toggleOpen",
    "handleOpen",
    "toggleSelectAll",
    "toggleSelect",
    "handleSelect",
  );

  $ctrl.dispatcher.on("toggleOpen.base", toggleOpen);
  $ctrl.dispatcher.on("toggleSelectAll.base", toggleSelectAll);
  $ctrl.dispatcher.on("toggleSelect.base", handleSelect);
  $ctrl.$onInit = function () {
    cookieKey = "DrDropdown-" + $ctrl.uid;

    const classes = getClasses();
    $ctrl.classes = classes[$ctrl.type] || {};
    if ($ctrl.type === "") $ctrl.classes = classes.header;
    if ($ctrl.multiselect) {
      $ctrl.classes.root =
        ($ctrl.classes.root || "") + " " + classes.multiselect.root;
    }

    // get selected from storage
    selectedStorage = processItems($ctrl.items, $ctrl.multiselect, cookieKey);

    // wait for child directive've been registered
    $timeout(() => {
      // rootElement.style('display', null);
      handleSelect();
    });
  };

  /// ----METHODS---- ///
  function toggleOpen(forceClose) {
    isClose = forceClose ? forceClose : !isClose;
    $ctrl.dispatcher.call("handleOpen", null, isClose);
    const listenerMethod = isClose ? "removeEventListener" : "addEventListener";
    document[listenerMethod]("click", outsideClick);

    function outsideClick(event) {
      toggleOpen(true);
    }
  }

  function _updateSelectedValues(d) {
    if (!d) return;
    if ($ctrl.multiselect) {
      selectedStorage[d.label] = !selectedStorage[d.label];
    } else {
      $ctrl.items.forEach((item) => {
        selectedStorage[item.label] = d.label === item.label;
      });
    }
  }

  function handleSelect(d) {
    if (!$ctrl.multiselect) toggleOpen(true);

    _updateSelectedValues(d);

    const selectedLabels = labelsArrayFromStorage(
      selectedStorage,
      $ctrl.multiselect,
    );

    sessionStorage[cookieKey] = selectedLabels.join(cookieValuesSeparator);

    const items = $ctrl.items.filter(
      (d) => selectedLabels.indexOf(d.label) > -1,
    );

    $ctrl.handleSelect(items);
    $ctrl.dispatcher.call("handleSelect", null, selectedLabels);
  }

  function toggleSelectAll(isAllChecked) {
    $ctrl.items.forEach(({ label }) => {
      selectedStorage[label] = isAllChecked;
    });
    handleSelect();
  }
}

function getClasses() {
  return {
    header: {
      root: "analytics-select--header",
      label: "analytics-select--header__label",
      toggle: "analytics-select--header__selected",
      arrow: "analytics-select--header__arrow",
      container: "analytics-select__selected-labels-container--header",
    },
    body: {
      root: "analytics-select--body",
      label: "analytics-select--body__label",
      toggle: "analytics-select--body__selected",
      arrow: "analytics-select--body__arrow",
      container: "analytics-select__selected-labels-container--body",
    },
    multiselect: {
      root: "analytics-select--multiselect",
    },
  };
}

function processItems(items, multiselect, cookieKey) {
  let savedSelectedLabels = fromStorage(cookieKey); // null | '' | ['LABEL', ..]
  if (savedSelectedLabels instanceof Array) {
    const labelsAreInArray = savedSelectedLabels.filter(findLabelInItems);
    if (labelsAreInArray.length === 0) savedSelectedLabels = null;
  }
  return items.reduce(process, {});

  function findLabelInItems(label) {
    return items.find((i) => i.label === label);
  }

  function fromStorage(cookieKey) {
    const labels = sessionStorage[cookieKey];
    // new dropdown
    if (labels === undefined) return null;
    // no items are selected in multiselect
    if (labels === "" && multiselect) return "";
    return labels.split(cookieValuesSeparator);
  }

  function process(selected, item) {
    if (savedSelectedLabels === null) {
      if (multiselect) {
        selected[item.label] = true;
      } else if (item.default) {
        selected[item.label] = true;
      }
    } else if (savedSelectedLabels instanceof Array) {
      if (savedSelectedLabels.indexOf(item.label) > -1) {
        selected[item.label] = true;
      } else {
        selected[item.label] = false;
      }
    }
    return selected;
  }
}

function labelsArrayFromStorage(selectedStorage, multiselect) {
  let selectedLabels = d3
    .keys(selectedStorage)
    .filter((label) => selectedStorage[label]);
  if (selectedLabels.length > 1 && !multiselect) {
    selectedLabels = selectedLabels[0];
  }
  return selectedLabels;
}
