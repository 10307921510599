import { keyBy } from "lodash-es";

import { flexSearchAllResultsArray } from "@app/flex";
import { sortDeals } from "@drVue/store/modules/client-dashboard/deals/utils";
import { hasCustomDataActiveFilter } from "@drVue/store/modules/client-dashboard/fields/utils";

import type { State } from "./state";
import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";
import type { RootState } from "@drVue/store/state";
import type { GetterTree } from "vuex";

export const getters: GetterTree<State, RootState> = {
  filterDeals:
    (state, getters, rootState, rootGetters) =>
    (deals: Deal[]): Deal[] => {
      const currentView = rootState.common.customViews.current;

      // Searching
      if (currentView.filters.search) {
        const foundSortedUids = flexSearchAllResultsArray(
          // Index contains all the Deals (!)
          rootState.clientDashboard.deals.index,
          currentView.filters.search,
        );

        // 'deals' array that we received as a parameter may contain fewer deals
        // than we have in the index
        const dealsDict = keyBy(deals, "uid");
        const foundDeals: Deal[] = [];
        for (const uid of foundSortedUids) {
          // We want to push only those deals that are in the 'deals' array and
          // was found by the search
          const deal = dealsDict[uid];
          if (deal) foundDeals.push(deal);
        }
        deals = foundDeals;
      }

      const fieldsFilter = currentView.filters.fields;
      const phaseFilter = fieldsFilter.phase;
      if (phaseFilter && phaseFilter.value) {
        if (phaseFilter.op !== "ANY") {
          throw `Invalid op ${phaseFilter.op} for phase field`;
        }

        deals = deals.filter((deal) => phaseFilter.value.includes(deal.phase));
      }

      // Filtering by Custom Fields
      const filterCustomData =
        rootGetters["clientDashboard/customFields/filterCustomFields"];
      deals = deals.filter((deal) =>
        filterCustomData("deal", fieldsFilter, deal.custom_data),
      );

      // Sorting
      if (currentView.filters.search) return deals; // Were sorted above.

      const allFields =
        rootGetters["clientDashboard/customFields/byObjectType"]("deal");
      const allPhases = rootState.clientDashboard.phases.items;
      const dealsOrder = [
        { field_key: "phase", direction: "asc" },
        { field_key: "title", direction: "asc" },
      ];

      return sortDeals(deals, dealsOrder, allFields, allPhases);
    },
  dealsFilterActive: (state, getters, rootState, rootGetters) => {
    const fieldsFilter = rootState.common.customViews.current.filters.fields;
    const phase = fieldsFilter.phase && fieldsFilter.phase.value;
    const allFields =
      rootGetters["clientDashboard/customFields/byObjectType"]("deal");
    return (
      (phase !== undefined ? 1 : 0) +
      hasCustomDataActiveFilter(fieldsFilter, allFields)
    );
  },
};
