import type { TaskPredicate } from "@app/ng/tasks/services/ts/types/TaskPredicate";

export const dueDateRange: TaskPredicate = (task, filters) => {
  if (!task.due_date) return true;

  const dueDateRange = filters.dueDateRange;
  if (!dueDateRange[0] || !dueDateRange[1]) return true;

  return task.due_date >= dueDateRange[0] && task.due_date <= dueDateRange[1];
};
