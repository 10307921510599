<template>
  <DrVxeGrid outlined :columns="tableColumns.columns" :data="tableData">
    <template #empty>
      <slot name="empty" />
    </template>
  </DrVxeGrid>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import TableColumns from "./tableColumns";

import type { UserRow } from "@drVue/components/management/UsersTable/types";
import type { PropType } from "vue";

const props = defineProps({
  users: { type: Array as PropType<UserRow[]>, default: () => [] },
  searchText: { type: String as PropType<string>, default: "" },
  extraColumns: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
});

const tableColumns = computed(() => {
  return new TableColumns(props.extraColumns);
});

const tableData = computed(() => {
  let users: UserRow[] = props.users;

  // Search through users
  if (props.searchText) {
    users = searchInUsers(users, props.searchText);
  }

  return users;
});

function searchInUsers(rooms: UserRow[], searchText: string) {
  searchText = searchText.toLowerCase();

  return props.users.filter((user: UserRow) => {
    return (
      user.name.toLowerCase().includes(searchText) ||
      user.email.toLowerCase().includes(searchText) ||
      user.office_number?.toLowerCase().includes(searchText)
    );
  });
}
</script>
