<template>
  <a
    class="user-information truncate"
    :href="(isLink && !isInvite && user.link) || undefined"
    :class="{ 'user-information--link': isLink && !isInvite }"
    :target="openInNewTab && !isInvite ? '_blank' : ''"
  >
    <div v-if="showAvatar !== false" class="user-information__icon">
      <!-- @vue-ignore -->
      <UserAvatar :user="user" :size="size" :font-size="avatarFontSize" />
    </div>

    <div v-if="showName" class="user-information__detail truncate">
      <DrTruncatedTextTooltip
        :content="user.name || user.email"
        :disabled="!showTooltip"
      >
        <span class="user-information__name" :class="{ 'truncate-ml': mlName }">
          {{ user.name || user.email }}
        </span>
      </DrTruncatedTextTooltip>
    </div>
  </a>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import UserAvatar from "../user-avatar";

import type { PropType } from "vue";

interface UserInfo {
  name: string | null;
  email: string;
  link: string | null;
  pending: boolean;
}

export default defineComponent({
  name: "UserInformation",
  components: {
    DrTruncatedTextTooltip,
    UserAvatar,
  },
  props: {
    user: { required: true, type: Object as PropType<UserInfo> },
    showName: {
      required: false,
      default: true,
      type: Boolean as PropType<boolean>,
    },
    showTooltip: {
      required: false,
      default: false,
      type: Boolean as PropType<boolean>,
    },
    openInNewTab: {
      required: false,
      default: false,
      type: Boolean as PropType<boolean>,
    },
    isLink: {
      required: false,
      default: false,
      type: Boolean as PropType<boolean>,
    },
    size: {
      required: false,
      default: undefined,
      type: String as PropType<string | undefined>,
    },
    avatarFontSize: {
      required: false,
      default: undefined,
      type: String as PropType<string | undefined>,
    },
    showAvatar: {
      required: false,
      default: null,
      type: Boolean as PropType<boolean | null>,
    },
    mlName: {
      required: false,
      default: false,
      type: Boolean as PropType<boolean>,
    },
  },
  computed: {
    isInvite(): any {
      return this.user?.pending || false;
    },
  },
});
</script>
