<template>
  <div>
    <ElButton size="small" @click="open">Bulk Change Owners</ElButton>
    <ElDialog v-model="isModalVisible" title="Bulk Update Owners">
      <ElForm
        ref="form"
        v-loading="isFormSubmitting"
        label-position="top"
        :model="formData"
        :rules="formRules"
      >
        <ElAlert
          v-if="formErrors.non_field_errors"
          :title="formErrors.non_field_errors"
          type="error"
          show-icon
          :closable="false"
        />
        <ElFormItem
          label="Current owner (user must be staff)"
          prop="source_owner"
          :error="formErrors.source_owner"
        >
          <ElInput
            v-model="formData.source_owner"
            placeholder="Current owner email"
          />
        </ElFormItem>
        <ElFormItem
          label="New owner"
          prop="target_owner"
          :error="formErrors.target_owner"
        >
          <ElInput
            v-model="formData.target_owner"
            placeholder="New owner email"
          />
        </ElFormItem>
        <ElFormItem class="el-form-item--pull-right">
          <ElButton @click="close">Cancel</ElButton>
          <ElPopconfirm title="Are you sure to change owner?" @confirm="update">
            <template #reference>
              <ElButton type="primary">Submit</ElButton>
            </template>
          </ElPopconfirm>
        </ElFormItem>
      </ElForm>
    </ElDialog>
  </div>
</template>

<script lang="ts" setup>
import { ElForm } from "element-plus";
import { reactive, ref } from "vue";
import { useFormHelper } from "@shared/ui/dr-form";

import { ManagementApiService } from "@drVue/api-service/management";
import { $notifyDanger } from "@drVue/common";

import type { ChangeOwnerData } from "@drVue/api-service/management";
import type { FormRules } from "element-plus";

const props = defineProps({
  clientId: {
    type: Number,
    required: true,
  },
});
const emit = defineEmits(["change"]);

const form = ref<InstanceType<typeof ElForm> | null>(null);
const formData = reactive<ChangeOwnerData>({
  source_owner: "",
  target_owner: "",
});

const { formErrors, hookFormSubmitPromise, isFormSubmitting } =
  useFormHelper<ChangeOwnerData>();

const formRules: FormRules = {
  source_owner: [
    {
      type: "email",
      message: "Enter a valid email",
      required: true,
    },
  ],
  target_owner: [
    {
      type: "email",
      message: "Enter a valid email",
      required: true,
    },
  ],
};

const isModalVisible = ref(false);

const open = () => {
  isModalVisible.value = true;
};

const close = () => {
  form.value?.resetFields();
  isModalVisible.value = false;
};

const update = () => {
  form.value?.validate((valid: boolean) => {
    if (!valid) {
      return Promise.reject();
    }

    const api = new ManagementApiService();
    hookFormSubmitPromise(api.bulkUpdateOwner(props.clientId, formData)).then(
      () => {
        close();
        emit("change");
      },
      () => $notifyDanger("Failed to update owners"),
    );
  });
};

defineExpose({
  open,
  close,
});
</script>
