import { parseISO, sub, toDate } from "date-fns";
import { map } from "lodash-es";
import { convertBtzToPtz } from "@shared/ui/dr-datepicker/DrDatepicker.vue";

import { ROOM_DATA } from "@setups/data";
import * as roomUrls from "@setups/room-urls";
import { drUserTime } from "@drVue/filters/drUserTime";

(function () {
  "use strict";
  var moduleDashboardServices = angular
    .module("dealroom.dashboard-services", [
      "ui.bootstrap",
      "dealroom.date-fns",
      "dealroom.config",
      "dealroom.common",
      "dealroom.members",
      "dealroom.table-sort.directive",

      "dealroom.dashboard-services",
      // new directives
      "dealroom.group-filter.directive",
      "dealroom.activity-filter.directive",
      "dealroom.time-filter.directive",
      "dealroom.audit-log.directive",

      "dealroom.analytics.service.data",
    ])
    .service("DashboardFilter", DashboardFilter)
    .service("VerbsService", VerbsService);

  VerbsService.$inject = [
    "$timeout",
    "DocumentsService",
    "TasksService",
    "ActivityDocumentsService",
    "ActivityCategoriesService",
    "ActivityTasksService",
    "MembersService",
    "PermissionsService",
    "CategoriesService",
    "RoomConfig",
  ];
  function VerbsService(
    $timeout,
    DocumentsService,
    TasksService,
    ActivityDocumentsService,
    ActivityCategoriesService,
    ActivityTasksService,
    MembersService,
    PermissionsService,
    CategoriesService,
    RoomConfig,
  ) {
    var verb = {
      category_add: {
        label: "Category is created",
        usersTableLabel: "Created category",
        item: "object",
        permission: RoomConfig.userPermissions.viewTasks,
      },
      category_is_archived: {
        label: "Category is deleted / restored",
        usersTableLabel: "Deleted / restored category",
        item: "target",
        permission: RoomConfig.userPermissions.viewTasks,
      },
      category_name: {
        label: "Category is renamed",
        usersTableLabel: "Renamed category",
        item: "target",
        permission: RoomConfig.userPermissions.viewTasks,
      },
      category_parent: {
        label: "Category is moved",
        usersTableLabel: "Moved category",
        item: "object",
        permission: RoomConfig.userPermissions.viewTasks,
      },
      task_viewed: {
        statistics_id: "tasks_viewed",
        label: "Request is viewed",
        usersTableLabel: "Viewed request",
        item: "target",
        permission: RoomConfig.userPermissions.viewTasks,
        chartLabel: "Number of viewed requests",
      },
      task_add: {
        label: "Request is created",
        usersTableLabel: "Created request",
        item: "object",
        permission: RoomConfig.userPermissions.viewTasks,
      },
      task_is_archived: {
        label: "Request is deleted / restored",
        usersTableLabel: "Deleted / restored request",
        item: "target",
        permission: RoomConfig.userPermissions.viewTasks,
      },
      task_due_date: {
        label: "Request due date is changed",
        usersTableLabel: "Changed request due date",
        item: "target",
        permission: RoomConfig.userPermissions.viewTasks,
      },
      task_start_date: {
        label: "Request start date is changed",
        usersTableLabel: "Changed request start date",
        item: "target",
        permission: RoomConfig.userPermissions.viewTasks,
      },
      task_description: {
        label: "Request description is changed",
        usersTableLabel: "Changed request description",
        item: "target",
        permission: RoomConfig.userPermissions.viewTasks,
      },
      task_priority: {
        label: "Request priority is changed",
        usersTableLabel: "Changed request priority",
        item: "target",
        permission: RoomConfig.userPermissions.viewTasks,
      },
      task_status: {
        label: "Request status is changed",
        usersTableLabel: "Changed request status",
        item: "target",
        permission: RoomConfig.userPermissions.viewTasks,
      },
      task_category: {
        label: "Request is moved",
        usersTableLabel: "Moved request",
        item: "object",
        permission: RoomConfig.userPermissions.viewTasks,
      },
      task_comment_add: {
        label: "Request comment is added",
        usersTableLabel: "Added request comment",
        item: "target",
        permission: RoomConfig.userPermissions.viewTasks,
      },
      task_assignee_add: {
        label: "Request assignee is added",
        item: "target",
        permission: RoomConfig.userPermissions.viewTasks,
      },
      task_assignee_delete: {
        label: "Request assignee is removed",
        item: "target",
        permission: RoomConfig.userPermissions.viewTasks,
      },
      task_reviewer_add: {
        label: "Request reviewer is added",
        item: "target",
        permission: RoomConfig.userPermissions.viewTasks,
      },
      task_reviewer_delete: {
        label: "Request reviewer is removed",
        item: "target",
        permission: RoomConfig.userPermissions.viewTasks,
      },
      folder_add: {
        label: "Folder is created",
        usersTableLabel: "Created folder",
        item: "object",
      },
      folder_name: {
        label: "Folder is renamed",
        usersTableLabel: "Renamed folder",
        item: "target",
      },
      folder_index: {
        label: "Folder index is changed",
        usersTableLabel: "Changed index of folder",
        item: "target",
      },
      folder_parent: {
        label: "Folder is moved",
        usersTableLabel: "Moved folder",
        item: "target",
      },
      folder_is_archived: {
        statistics_id: "folders_archived",
        label: "Folder is deleted / restored",
        usersTableLabel: "Deleted / restored folder",
        item: "target",
      },
      folder_is_removed: {
        statistics_id: "folders_removed",
        label: "Folder is removed",
        usersTableLabel: "Removed folder",
        item: "target",
      },
      document_upload: {
        statistics_id: "documents_uploaded",
        label: "Document is uploaded",
        usersTableLabel: "Uploaded document",
        item: "target",
        chartLabel: "Number of uploaded documents",
      },
      document_overwrite: {
        label: "Document is updated",
        usersTableLabel: "Updated document",
        item: "target",
      },
      document_name: {
        label: "Document is renamed",
        usersTableLabel: "Renamed document",
        item: "target",
      },
      document_index: {
        label: "Document index is changed",
        usersTableLabel: "Changed index of document",
        item: "target",
      },
      document_folder: {
        label: "Document is moved",
        usersTableLabel: "Moved document",
        item: "object",
      },
      document_viewed: {
        statistics_id: "documents_viewed",
        label: "Document is viewed",
        usersTableLabel: "Viewed document",
        item: "target",
        chartLabel: "Number of viewed documents",
      },
      document_is_archived: {
        statistics_id: "documents_archived",
        label: "Document is deleted / restored",
        usersTableLabel: "Deleted / restored document",
        item: "target",
      },
      document_is_removed: {
        statistics_id: "documents_removed",
        label: "Document is removed",
        usersTableLabel: "Removed document",
        item: "target",
      },
      document_download: {
        statistics_id: "documents_downloaded",
        label: "Document is downloaded",
        usersTableLabel: "Downloaded document",
        item: "target",
        chartLabel: "Number of downloaded documents",
      },
      document_bulk_download: {
        label: "Document is bulk downloaded",
        usersTableLabel: "Bulk downloaded document",
        item: "target",
      },

      user_room_session_begin: {
        statistics_id: "sessions",
        label: "Logged in",
        usersTableLabel: "Logged in",
        item: "target",
        chartLabel: "Number of users logins",
      },

      nda_accept: {
        label: "NDA is accepted",
        usersTableLabel: "NDA is accepted",
        item: null,
      },
      group_permission_invite_add: {
        label: "Invite is added",
        usersTableLabel: "Added invited",
        item: "target",
      },
      group_permission_invite_delete: {
        label: "Invite is removed",
        usersTableLabel: "Removed invite",
        item: "target",
      },
      group_permission_invite_group: {
        label: "Invite is moved",
        usersTableLabel: "Moved invite",
        item: "object",
      },
      group_permission_member_add: {
        label: "Member is added",
        usersTableLabel: "Added member",
        item: "object",
      },
      group_permission_member_delete: {
        label: "Member is removed",
        usersTableLabel: "Removed member",
        item: "object",
      },
      group_permission_member_group: {
        label: "Member is moved",
        usersTableLabel: "Moved member",
        item: "object",
      },
    };
    // clear not allowed for this payplan verbs
    for (var v in verb) {
      if (verb[v].permission === false) {
        delete verb[v];
      }
    }

    function getObjectLabel(action) {
      if (!action) {
        return "";
      }
      var data,
        type = action.type,
        id = action.id,
        verbObj;
      if (!type) {
        verbObj = verb[action.action];
        if (!verbObj || !action[verbObj.item]) {
          return undefined;
        }
        type = action[verbObj.item].type;
        id = action[verbObj.item].id;
      }

      if (!type || !id) {
        return undefined;
      }
      if (type === "document") {
        data = ActivityDocumentsService.Files[id];
        if (!data) return "unknown document";
        return data.treeIndex + " " + data.name;
      }
      if (type === "folder") {
        data = ActivityDocumentsService.Folders[id];
        if (!data) return "unknown folder";
        if (data.parent_id) {
          return data.treeIndex + " " + data.name;
        } else {
          return "Home";
        }
      }
      if (type === "task") {
        data = ActivityTasksService.tasks[id];
        if (!data) return "unknown request";
        return data.key + " " + data.title;
      }
      if (type === "category") {
        data = ActivityCategoriesService.categories[id];
        return data ? data.name : "unknown category";
      }
      if (type === "invite") {
        data = MembersService.invites[id];
        return data ? data.email : "unknown user";
      }
      if (type === "user") {
        data = MembersService.members[id];
        return data ? data.email : "unknown user";
      }
      if (type === "group permission") {
        data = PermissionsService.pgroups[id];
        return data ? data.name : "unknown group";
      }
    }

    function getValues(data) {
      var res = {};
      if (
        data.action === "category_parent" ||
        data.action === "task_category"
      ) {
        res.old_value =
          getObjectLabel({ type: "category", id: data.content.old_value }) ||
          "Root";
        res.new_value =
          getObjectLabel({ type: "category", id: data.content.new_value }) ||
          "Root";
      } else if (
        data.action === "task_assignee_add" ||
        data.action === "task_assignee_delete" ||
        data.action === "task_reviewer_add" ||
        data.action === "task_reviewer_delete"
      ) {
        res.new_value = getObjectLabel({
          type: data.object.type,
          id: data.object.id,
        });
      } else if (
        data.action === "folder_parent" ||
        data.action === "document_folder"
      ) {
        res.old_value =
          getObjectLabel({ type: "folder", id: data.content.old_value }) ||
          "Home";
        res.new_value =
          getObjectLabel({ type: "folder", id: data.content.new_value }) ||
          "Home";
      } else if (data.content && data.content.field === "is_archived") {
        res.old_value = data.content.old_value ? "Deleted" : "Restored";
        res.new_value = data.content.new_value ? "Deleted" : "Restored";
      } else if (
        data.action === "folder_is_removed" ||
        data.action === "document_is_removed" ||
        data.action === "task_comment_add"
      ) {
        res.old_value = "";
        res.new_value = "";
      } else if (
        data.action === "document_index" ||
        data.action === "folder_index"
      ) {
        var el =
          data.action === "document_index"
            ? ActivityDocumentsService.Files[data.target.id]
            : ActivityDocumentsService.Folders[data.target.id];
        var treeIndex =
          el && el.treeIndex.lastIndexOf(".") > 0
            ? el.treeIndex.slice(0, el.treeIndex.lastIndexOf(".")) + "."
            : "";
        res.old_value = treeIndex + data.content.old_value;
        res.new_value = treeIndex + data.content.new_value;
      } else if (
        data.action === "group_permission_invite_add" ||
        data.action === "group_permission_invite_delete"
      ) {
        res.new_value = data.content.email;
      } else if (
        data.action === "group_permission_invite_group" ||
        data.action === "group_permission_member_group"
      ) {
        res.old_value = getObjectLabel({
          type: "group permission",
          id: data.content.old_value,
        });
        res.new_value = getObjectLabel({
          type: "group permission",
          id: data.content.new_value,
        });
      } else if (data.action === "group_permission_member_add") {
        res.new_value = getObjectLabel({
          type: "group permission",
          id: data.target.id,
        });
      } else if (data.action === "task_status") {
        res.old_value = data.content.old_value?.name || "";
        res.new_value = data.content.new_value?.name || "";
      } else if (data.action === "task_description") {
        // TODO: render text
        res.old_value = "";
        res.new_value = "";
      } else if (data.content) {
        res.old_value = data.content.old_value;
        res.new_value = data.content.new_value;
      }

      return res;
    }

    function getObjectUrl(action) {
      var data, url, verbObj, type, id, num;
      if (!action.isDocs) {
        verbObj = verb[action.action];
        if (!verbObj) {
          return;
        }
        type = action[verbObj.item] ? action[verbObj.item].type : undefined;
        id = action[verbObj.item] ? action[verbObj.item].id : undefined;
      } else {
        type = action.type;
        id = action.id;
      }

      if (!type || !id) return;
      if (type === "document") {
        data = ActivityDocumentsService.Files[id];
        url = roomUrls.documentViewUrl(ROOM_DATA.url, id);
      }
      if (type === "folder") {
        data = ActivityDocumentsService.Folders[id];
        url = roomUrls.folderUrl(ROOM_DATA.url, id);
      }
      if (type === "task" || type === "request") {
        data = ActivityTasksService.tasks[id];
        num = data ? data.key : id;
        url = roomUrls.taskUrl(ROOM_DATA.url, num);
      }
      if (type === "category") {
        data = ActivityCategoriesService.categories[id];
        url = roomUrls.categoryUrl(ROOM_DATA.url, id);
      }
      if (type === "user") {
        data = MembersService.members[id];
        url = roomUrls.memberUrl(ROOM_DATA.url, id);
      }
      if (type === "group permission") {
        data = PermissionsService.pgroups[id];
        url = roomUrls.groupUrl(ROOM_DATA.url, id);
      }

      if (!data || data.is_archived || data.is_removed) {
        return;
      }
      return url;
    }

    // todo(task_body): remove this verb when we get rid of it on BE completely
    const deprecatedTaskBodyVerb = {
      id: "task_body",
      label: "Request description is changed",
      usersTableLabel: "Changed request description",
    };

    var getLabel = function (id, isUsersTable) {
      if (id === deprecatedTaskBodyVerb.id) {
        return isUsersTable
          ? deprecatedTaskBodyVerb.usersTableLabel
          : deprecatedTaskBodyVerb.label;
      }

      if (isUsersTable) {
        return verb[id] ? verb[id].usersTableLabel : id;
      } else {
        return verb[id] ? verb[id].label : id;
      }
    };

    var getStatisticId = function (id) {
      return verb[id] ? verb[id].statistics_id : id;
    };

    var getAll = function () {
      return verb;
    };

    var getShortList = function (widgetType) {
      var shortVerb = {},
        list = [
          RoomConfig.userPermissions.viewTasks && "task_viewed",
          "document_upload",
          "document_viewed",
          "document_download",
          "user_room_session_begin",
        ];
      if (widgetType === "audit-log") {
        return verb;
      }
      list.map(function (item) {
        if (item) shortVerb[item] = verb[item];
      });
      return shortVerb;
    };

    var getType = function (id) {
      return verb[id] ? verb[id].item : id;
    };

    var getTargetString = function (d) {
      var objs = {
        document: function (id) {
          return {
            obj: ActivityDocumentsService.Files[id],
            template:
              '<dr-document-information document="::obj" is-link="::true"' +
              'show-index="::true" is-hoverable="::true" class="truncate"' +
              "/>",
          };
        },
        folder: function (id, cleanscope) {
          return {
            obj: ActivityDocumentsService.Folders[id],
            template:
              '<dr-folder-information folder="::obj" is-link="::true"' +
              'show-index="::true" is-hoverable="::true" class="truncate"' +
              "/>",
          };
        },
        task: function (id, cleanscope) {
          return {
            obj: ActivityTasksService.tasks[id],
            template:
              '<dr-task-information task="::obj" is-hoverable="::true"' +
              'is-link="::true" class="truncate" />',
          };
        },
        category: function (id, cleanscope) {
          return {
            obj: ActivityCategoriesService.categories[id],
            template:
              '<dr-category-information category="::obj" is-hoverable="::true"' +
              'is-link="::true" class="truncate" />',
          };
        },
      };

      if (d.target && objs[d.target.type] !== undefined) {
        return objs[d.target.type](d.target.id);
      }
    };

    return {
      getLabel: getLabel,
      getObjectLabel: getObjectLabel,
      getObjectUrl: getObjectUrl,
      getStatisticId: getStatisticId,
      getAll: getAll,
      getShortList: getShortList,
      getType: getType,
      getValues: getValues,
      getTargetString: getTargetString,
    };
  }

  DashboardFilter.$inject = ["$stateParams", "RoomConfig"];
  function DashboardFilter($stateParams, RoomConfig) {
    const Filter = function () {
      const fromData = $stateParams.time_from
        ? parseISO($stateParams.time_from)
        : convertBtzToPtz(sub(new Date(), { days: 30 }));

      // dont allow see data before creation
      const maxFrom = Math.max(parseISO(RoomConfig.createdDate), fromData);

      this.fromData = drUserTime(toDate(maxFrom), "yyyy-MM-dd");
      this.toData =
        $stateParams.time_to || drUserTime(new Date(), "yyyy-MM-dd");

      this.action = "all";

      this.actors = undefined;
      this.group = $stateParams.group_id || undefined;

      this.showArchivedGroups = false;
      this.showArchived = false;

      this.order = { by: "name", reversed: false };
    };

    Filter.prototype.loadData = function () {
      // nothing
    };

    Filter.prototype.timeFilterCallback = function (timeInterval) {
      this.fromData = timeInterval.from;
      this.toData = timeInterval.to;
      this.loadData();
    };

    Filter.prototype.activityFilterCallback = function (activity) {
      this.action = activity.id;
      this.loadData();
    };

    Filter.prototype.groupFilter2Callback = function (data) {
      // {selected: [member, ]}
      this.actors =
        data && data.selected && data.selected.length
          ? map(data.selected, "id").join(",")
          : undefined;
      this.loadData();
    };

    Filter.prototype.setOrder = function (order) {
      this.order.by = order.by;
      this.order.reversed = order.reversed;
      this.loadData();
    };

    return {
      get: function () {
        return new Filter();
      },
    };
  }
})();
