import updateStyleElement from "@drVue/store/modules/room/tasks-statuses/helpers/styleElement";
import { TaskStatusType } from "./TasksStatusesState";

import type { TasksStatusesState } from "./TasksStatusesState";
import type { TaskStatus } from "./types";
import type { MutationTree } from "vuex";

const COLOR_CLASS_PREFIX = "dr-task-status-color__";
const BG_CLASS_PREFIX = "dr-task-status-bg__";

export const tasksStatusesMutations: MutationTree<TasksStatusesState> = {
  setStatuses(state, statuses: TaskStatus[]) {
    state.list = statuses.map((s) => ({
      ...s,
      colorClass: `${COLOR_CLASS_PREFIX}${s.id}`,
      bgClass: `${BG_CLASS_PREFIX}${s.id}`,
    }));

    state.list.sort((a, b) => {
      const aIndex = state.TYPES_ORDER[a.type];
      const bIndex = state.TYPES_ORDER[b.type];

      if (aIndex === bIndex) {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      }

      return aIndex > bIndex ? 2 : -2;
    });

    updateStyleElement(state.list);

    state.byTypes = state.list.reduce<Record<TaskStatusType, number[]>>(
      (acc, cur) => {
        acc[cur.type] = acc[cur.type] || [];
        acc[cur.type].push(cur.id);

        return acc;
      },
      {
        [TaskStatusType.Open]: [],
        [TaskStatusType.InProgress]: [],
        [TaskStatusType.Reopened]: [],
        [TaskStatusType.Resolved]: [],
      },
    );
  },
};
