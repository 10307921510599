import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import Color from "./Color.vue";
import DateField from "./Date.vue";
import DateTimeField from "./DateTime.vue";
import EditorField from "./Editor.vue";
import MultiselectField from "./MultiSelect.vue";
import NumberField from "./Number.vue";
import SelectField from "./Select.vue";
import SwitchField from "./Switch.vue";
import TextField from "./Text.vue";
import TextareaField from "./Textarea.vue";

export const COMPONENT_BY_TYPE = {
  [FieldSchemaType.Custom]: TextField,

  [FieldSchemaType.Date]: DateField,
  [FieldSchemaType.DateTime]: DateTimeField,
  [FieldSchemaType.MultiSelect]: MultiselectField,
  [FieldSchemaType.Number]: NumberField,
  [FieldSchemaType.Select]: SelectField,
  [FieldSchemaType.Text]: TextField,
  [FieldSchemaType.LinkifiedText]: TextField,
  [FieldSchemaType.Textarea]: TextareaField,
  [FieldSchemaType.Phone]: TextField,
  [FieldSchemaType.Email]: TextField,
  [FieldSchemaType.Richtext]: EditorField,
  [FieldSchemaType.Switch]: SwitchField,
  [FieldSchemaType.Color]: Color,
} as const;
