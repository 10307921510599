<template>
  <!-- @vue-skip -->
  <span
    v-for="eu in emailUsers"
    :key="eu.email || eu.user.id"
    :class="$style.emailUser"
  >
    <!-- @vue-ignore -->
    <span v-if="eu.user" :class="$style.email">
      <UserAvatar :class="$style.emailAvatar" size="20px" :user="eu.user" />

      <!-- @vue-skip -->
      <DrTruncatedTextTooltip :content="eu.user.name">
        {{ eu.user.name }}
      </DrTruncatedTextTooltip>
    </span>

    <span v-else :class="$style.email">
      <DrTruncatedTextTooltip :content="eu.email">
        {{ eu.email }}
      </DrTruncatedTextTooltip>
    </span>

    <span :class="$style.actions">
      <!-- TODO: remove v-if and implement the Add button -->
      <span v-if="eu.user" :class="$style.divider" />

      <!-- Action: Link & Unlink -->
      <ElButton
        v-if="eu.user && eu.isUserLinked"
        :class="$style.action"
        :disabled="isInProgress"
        :loading="isInProgress"
        text
      >
        <template #icon>
          <ElPopover
            placement="top-start"
            title="Unlink email from person"
            trigger="hover"
            :width="320"
          >
            <template #reference>
              <i class="far fa-unlink" @click="unlinkUser(eu.user.id)" />
            </template>
            This email will be hidden in person's record
          </ElPopover>
        </template>
      </ElButton>
      <ElButton
        v-else-if="eu.user && !eu.isUserLinked"
        :class="$style.action"
        :disabled="isInProgress"
        :loading="isInProgress"
        text
      >
        <template #icon>
          <ElPopover
            placement="top-start"
            title="Link email to person"
            trigger="hover"
            :width="320"
          >
            <template #reference>
              <i class="far fa-link" @click="linkUser(eu.user.id)" />
            </template>
            This email will be visible in person's record
          </ElPopover>
        </template>
      </ElButton>
      <!-- /Action: Link & Unlink -->

      <!-- TODO: implement the Add button -->
      <!-- Action: Add User -->
      <!--<ElButton v-else :class="$style.action" text>-->
      <!--  <i class="far fa-user-plus"></i>-->
      <!--</ElButton>-->
      <!-- /Action: Add User -->
    </span>
  </span>
</template>

<script lang="ts" setup>
import { remove } from "lodash-es";
import { inject, ref } from "vue";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";
import { RawEmailsInjectionKey } from "@shared/ui/emails-list/keys";
import UserAvatar from "@shared/ui/users/user-avatar/index.vue";

import { $notifyDanger } from "@drVue/common";
import { emailsApi } from "@drVue/store/modules/room/emails/EmailsApiService";

import type { EmailMessage } from "@drVue/store/modules/room/emails/EmailsApiService";
import type { EmailUser } from "@shared/ui/emails-list/EmailModel";
import type { Ref } from "vue";

const rawEmails = inject<Ref<EmailMessage[]>>(RawEmailsInjectionKey);

const props = defineProps<{ emailId: string; emailUsers: EmailUser[] }>();
const isInProgress = ref(false);

const getRawEmailById = (emailId: string) => {
  if (!rawEmails) return null;

  const rawEmail = rawEmails.value.find((re) => re.id === emailId);
  if (!rawEmail) return null;

  return rawEmail;
};

const linkUser = (userId: string) => {
  isInProgress.value = true;

  emailsApi
    .linkUsersToEmail(props.emailId, [userId])
    .then(
      () => {
        const rawEmail = getRawEmailById(props.emailId);
        rawEmail?.linked_users.push({ user_id: userId });
      },
      () => $notifyDanger("Failed to link the user, please try again"),
    )
    .finally(() => (isInProgress.value = false));
};

const unlinkUser = (userId: string) => {
  isInProgress.value = true;

  emailsApi
    .unlinkUsersFromEmail(props.emailId, [userId])
    .then(
      () => {
        const rawEmail = getRawEmailById(props.emailId);
        if (!rawEmail) return;

        remove(rawEmail.linked_users, (lu) => lu.user_id === userId);
      },
      () => $notifyDanger("Failed to unlink the user, please try again"),
    )
    .finally(() => (isInProgress.value = false));
};
</script>

<style module lang="scss">
@use "@app/styles/scss/_colors.scss" as colors;

.emailUser {
  display: inline-flex;
  align-items: center;
  margin-right: 6px;
  margin-bottom: 6px;
  background-color: colors.$pr-100;
  border-radius: 4px;
  flex-wrap: nowrap;

  &:first-of-type {
    margin-left: 0;
  }

  &s:last-of-type {
    margin-right: 0;
  }
}

.email {
  display: flex;
  align-items: center;
  margin: 4px;
  max-width: 200px;
}

.emailAvatar {
  margin-left: 2px;
  margin-right: 4px;
  flex: 0 0 20px;
}

.actions {
  display: inline-flex;
  align-items: center;
}

.divider {
  border-left: 1px solid colors.$pr-300;
  margin-left: 4px;
  padding-left: 8px;
  height: 16px;
}

.action {
  // TODO: why our styles are before the styles of Element UI?
  height: 16px !important;
  padding: 0 !important;
  margin-right: 8px;

  :global(.far) {
    font-size: 12px !important;
  }
}
</style>
