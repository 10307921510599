import { ORG_MEMBER_DATA } from "@setups/data";

import type { State } from "./state";
import type { ClientSettings } from "@drVue/api-service/client-dashboard";
import type { MutationTree } from "vuex";

export const mutations: MutationTree<State> = {
  update(state, { data }: { data: Partial<ClientSettings> }): void {
    const properties = [
      "require_2fa",
      "require_sso",
      "bg_color",
      "public_name",
      "room_end_field",
      "enable_lists_tabs",
    ];
    properties.forEach((key) => {
      const value = (data as any)[key];
      if (value !== undefined) {
        (state as any)[key] = value;
        // some old code might use ORG_MEMBER_DATA.client directly
        (ORG_MEMBER_DATA.client as any)[key] = value;
      }
    });
  },
};
