<template>
  <component
    :is="isTitleLink ? 'a' : 'div'"
    v-bind="componentProps"
    :class="$style.wrapper"
  >
    <DrLogo :name="roomTitle" :class="$style.logo" :url="roomLogo" />
    <div
      :class="{
        [$style.title]: true,
        [$style.titleBold]: isTitleBold,
      }"
    >
      {{ roomTitle }}
    </div>
  </component>
</template>

<script setup lang="ts">
import { computed } from "vue";
import DrLogo from "@shared/ui/dr-logo/DrLogo.vue";

import { ROOM_DATA } from "@setups/data";

const props = withDefaults(
  defineProps<{ isTitleBold?: boolean; isTitleLink?: boolean }>(),
  {
    isTitleBold: false,
    isTitleLink: false,
  },
);

const componentProps = computed(() => {
  if (props.isTitleLink) return { href: ROOM_DATA.url };
  return {};
});

const roomTitle = ROOM_DATA.title;
const roomLogo = ROOM_DATA.logo ?? undefined;
</script>

<style lang="scss" module>
@use "@app/styles/scss/_colors";
@use "@app/styles/scss/typography" as typo;

.wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  overflow: hidden;
}

.logo {
  flex-shrink: 0;
}

.title {
  font: typo.$body_regular;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: colors.$pr-800;
}

.titleBold {
  font: typo.$subtitle_semibold;
}
</style>
