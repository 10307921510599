import DrStore from "@drVue/store";

export default function drLongDownloadPathWarning() {
  return {
    bindings: {
      item: "<",
    },
    templateUrl: "dr-long-download-path-warning.tmpl.html",
    controller: [
      "RoomConfig",
      "UserData",
      function (RoomConfig, UserData) {
        var $ctrl = this;
        $ctrl.pathLengthLimit = 240;
        $ctrl.RoomConfig = RoomConfig;
        $ctrl.UserData = UserData;

        const docsStore = DrStore.state.room.documents;

        Object.defineProperties($ctrl, {
          pathLength: {
            get: () =>
              ($ctrl.item &&
                docsStore.folderMaxNestedPathsLengths[$ctrl.item.id]) ||
              0,
          },
        });
      },
    ],
  };
}
