<template>
  <div
    v-if="dueDate"
    :class="{ [$style.cell]: true, [$style.date_isOverdue]: isOverdue }"
  >
    <DrIcon size="sm" name="calendar" :class="$style.icon" />
    <span :class="$style.date">{{ dueDateString }}</span>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import DrIcon from "@shared/ui/dr-icon";

import { drUserTime } from "@drVue/filters/drUserTime";

const props = defineProps<{
  dueDate: Date | null;
  isOverdue: boolean;
}>();

const dueDateString = computed(() => {
  if (!props.dueDate) {
    return "";
  }

  return drUserTime(props.dueDate, "full-date");
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography" as typo;

.cell {
  display: grid;
  max-width: 100%;
  min-width: 0;
  overflow: hidden;
  align-items: center;
  grid-template-columns: min-content 1fr;
  gap: 10px;
}

.icon {
  color: colors.$pr-350;
}

.date {
  font: typo.$body_regular;
  color: colors.$pr-800;
}

.date_isOverdue .icon,
.date_isOverdue .date {
  font: typo.$body_medium;
  color: colors.$ad-error;
}
</style>
