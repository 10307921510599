import { ApiService } from "@drVue/api-service";

export interface EmbedUrl {
  url: string;
}

export class LookerApiService extends ApiService {
  public embedUrl(): Promise<EmbedUrl> {
    const url = this.Urls["api:client-dashboard:looker-embed-url"]();

    return this.get<EmbedUrl>(url);
  }

  public updateModel(): Promise<{}> {
    const url = this.Urls["api:client-dashboard:looker-update-model"]();

    return this.post<{}, {}>(url, {});
  }
}
