<template>
  <ElPopover :visible="isPopoverVisible" width="300" :append-to-body="true">
    <template #reference>
      <ElButton
        type="text"
        style="padding: 0"
        @click="isPopoverVisible = !isPopoverVisible"
      >
        Add from group
      </ElButton>
    </template>
    <ElForm label-position="top">
      <ElFormItem label="Room">
        <ElSelect
          v-model="selectedRoomId"
          placeholder="Select a Room"
          style="width: 100%"
          filterable
          @change="selectedGroupId = undefined"
        >
          <ElOption
            v-for="room in rooms"
            :key="room.id"
            :label="room.title"
            :value="room.id"
          />
        </ElSelect>
      </ElFormItem>
      <ElFormItem label="Permission Group">
        <ElSelect
          v-model="selectedGroupId"
          :placeholder="
            groups ? 'Select a Permission Group' : 'Select a Room first'
          "
          style="width: 100%"
          :disabled="!groups"
          filterable
        >
          <ElOption
            v-for="group in groups"
            :key="group.id"
            :label="group.name"
            :value="group.id"
          />
        </ElSelect>
      </ElFormItem>
      <div class="text-right">
        <ElButton @click="close">Cancel</ElButton>
        <ElButton type="primary" @click="add">Add</ElButton>
      </div>
    </ElForm>
  </ElPopover>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { Urls } from "@setups/urls";
import { ApiService } from "@drVue/api-service";

import type {
  Room,
  RoomGroup,
} from "@drVue/store/modules/client-dashboard/deals/types";
import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { PropType } from "vue";

interface Data {
  api: ApiService;
  selectedRoomId: number | undefined;
  selectedGroupId: number | undefined;
  isPopoverVisible: boolean;
  rooms: Room[];
}

export default defineComponent({
  name: "AddFromGroupLink",
  props: {
    roomId: {
      required: false,
      default: undefined,
      type: Number as PropType<number | undefined>,
    },
  },
  emits: ["add"],
  data(): Data {
    return {
      api: new ApiService(),
      selectedRoomId: undefined,
      selectedGroupId: undefined,
      isPopoverVisible: false,
      rooms: [],
    };
  },
  computed: {
    groups(): RoomGroup[] | null {
      const room = this.rooms.find((r) => r.id === this.selectedRoomId);
      return room ? room.groups : null;
    },
  },
  beforeMount() {
    const url = Urls["api:client-dashboard:rooms-groups-info"]();
    this.api.get<Room[]>(url).then((rooms) => {
      this.rooms = this.roomId
        ? rooms.filter((r) => r.id !== this.roomId)
        : rooms;
    });
  },
  methods: {
    close() {
      this.selectedGroupId = undefined;
      this.selectedRoomId = undefined;
      this.isPopoverVisible = false;
    },
    add() {
      if (!this.selectedGroupId) return;

      const url = Urls["api:client-dashboard:rooms-group-members-info"](
        this.selectedGroupId,
      );

      this.api.get<Partial<OrgUser>[]>(url).then((members) => {
        this.$emit(
          "add",
          members.map((m) => m.email || "").filter((m) => m),
        );
        this.close();
      });
    },
  },
});
</script>
