import { h } from "vue";
import { UserEmail, UserInformation, UserOfficeNumber } from "@shared/ui/users";

import DrStore from "@drVue/store";

import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { DrVxeTableColumn } from "@drVue/components/types";
import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";

export default class TableColumns {
  get customFields(): FieldItem[] {
    return DrStore.getters["clientDashboard/customFields/byObjectType"]("user");
  }

  get columns(): DrVxeTableColumn[] {
    return [
      this.checkboxColumn(),
      this.name(),
      this.company(),
      this.title(),
      this.email(),
      this.officeNumber(),
    ];
  }

  private checkboxColumn(): DrVxeTableColumn {
    return {
      field: "_checkbox",
      type: "checkbox",
      title: "",
      fixed: "left",
      resizable: false,
      width: 35,
      showHeaderOverflow: false,
    };
  }

  private name(): DrVxeTableColumn {
    return {
      sortable: true,
      field: "name",
      title: "Name",
      fixed: "left",
      className: "dr-users-table__user-name-cell",
      minWidth: 180,
      width: 180,
      slots: {
        default: ({ row }: { row: any }) => {
          row = row as OrgUser;
          return [
            h(UserInformation, {
              user: row,
              isLink: true,
              showTooltip: true,
              size: "20px",
            }),
          ];
        },
      },
    };
  }

  private company(): DrVxeTableColumn {
    return {
      sortable: true,
      field: "company",
      title: "Company",
      minWidth: 150,
      width: 150,
    };
  }

  private title(): DrVxeTableColumn {
    return {
      sortable: true,
      field: "title",
      title: "Title",
      minWidth: 150,
      width: 150,
    };
  }

  private email(): DrVxeTableColumn {
    return {
      sortable: true,
      field: "email",
      title: "Email",
      minWidth: 150,
      width: 150,
      slots: {
        default: ({ row }: { row: any }) => {
          row = row as OrgUser;
          return [h(UserEmail, { email: row.email })];
        },
      },
    };
  }

  private officeNumber(): DrVxeTableColumn {
    return {
      sortable: true,
      field: "office_number",
      title: "Phone",
      minWidth: 150,
      width: 150,
      slots: {
        default: ({ row }: { row: any }) => {
          row = row as OrgUser;
          return [
            h(UserOfficeNumber, {
              officeNumber: row.office_number || "",
            }),
          ];
        },
      },
    };
  }
}
