<template>
  <DrTooltip :content="creatorName">
    <div :class="$style.container">
      <DrAvatar :url="creator?.avatar.thumbnail" :name="creator?.name" />
      <span>{{ creatorName }}</span>
    </div>
  </DrTooltip>
</template>

<script setup lang="ts">
import { computed } from "vue";
import DrAvatar from "@shared/ui/dr-avatar/DrAvatar.vue";
import { DrTooltip } from "@shared/ui/dr-tooltip";

import { DrStore } from "@app/vue";

interface Props {
  modelValue: number | undefined;
}

const props = defineProps<Props>();

const creator = computed(() => {
  if (!props.modelValue) return undefined;

  const members = DrStore.state.room.members;
  if (members.isError || !members.membersList.length) return undefined;

  return members.members[props.modelValue];
});

const creatorName = computed(() => {
  if (!creator.value) return "";
  const [firstName, lastName] = creator.value.name.split(" ");
  return `${firstName[0]}. ${lastName}`;
});
</script>

<style module lang="scss">
.container {
  display: grid;
  grid-template-columns: 24px 1fr;
  align-items: center;
  gap: 6px;
  user-select: none;
  overflow: hidden;
}
</style>
