import { ApiService } from "@drVue/api-service";
import { http } from "@drVue/api-service/http";

export enum Interface {
  totp = "totp",
  sms = "sms",
  recovery = "recovery",
}

export interface ConfirmResponse {
  codes: string[];
}

export interface MfaInterface {
  interface: string;
  name: string;
  description: string;
  is_backup_interface: boolean;
  is_enrolled: boolean;
}

export interface SecretData {
  interface: Interface;
  secret: string;
  phone?: string;
  qrcode?: boolean[][];
}

export interface ConfirmRequest {
  challenge: string;
  password: string;
  secret: string;
  phone?: string;
}

export class MfaApiService extends ApiService {
  public getMfaList() {
    const url = this.Urls["api:users:mfa-list"]();
    return http.get<MfaInterface[]>(url);
  }

  public addInterface(interfaceId: Interface, data: any) {
    const url = this.Urls["api:users:mfa-actions"](interfaceId);
    return http.post<SecretData>(url, data);
  }

  public confirmInterface(interfaceId: Interface, data: ConfirmRequest) {
    const url = this.Urls["api:users:mfa-confirm"](interfaceId);
    return http.post<ConfirmResponse>(url, data);
  }

  public getInterfaceDetails(interfaceId: Interface, password: string) {
    const url = this.Urls["api:users:mfa-details"](interfaceId);
    return http.post(url, { password: password });
  }

  public updateInterface(interfaceId: Interface, password: string) {
    const url = this.Urls["api:users:mfa-actions"](interfaceId);
    return http.put(url, { password: password });
  }

  public removeInterface(interfaceId: Interface, password: string) {
    const url = this.Urls["api:users:mfa-delete"](interfaceId);
    return http.post(url, { password: password });
  }
}
