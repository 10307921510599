<template>
  <a v-if="!!officeNumber" :href="`tel:${officeNumber}`" target="_blank">
    <i class="fa fa-phone" /> <span>{{ officeNumber }}</span>
  </a>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { PropType } from "vue";

export default defineComponent({
  name: "UserOfficeNumber",
  props: {
    officeNumber: {
      required: true,
      type: String as PropType<string>,
    },
  },
});
</script>
