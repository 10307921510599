import analyticsLinechartHtml from "./index.html?raw";

Controller.$inject = [
  "$timeout",
  "$scope",
  "$element",
  "AnalyticsActivityDataService",
  "AnalyticsLinechartConfigService",
  "AnalyticsColorsSchemeFabric",
];
import "./axis";
import "./config.service.js";
import "./container";
import "./legend";
import "./lines";
import "./hoverable";

import * as d3 from "d3";

angular
  .module("dealroom.analytics.linechart", [
    "dealroom.analytics.linechart.service.config",
    "dealroom.analytics.linechart.container",
    "dealroom.analytics.linechart.legend",
    "dealroom.analytics.linechart.axis",
    "dealroom.analytics.linechart.lines",
    "dealroom.analytics.linechart.hoverable",

    "dealroom.analytics.service.colors-scheme",
  ])
  .component("drAnalyticsLinechart", {
    bindings: {
      dataDispatcher: "<dispatcher",
    },
    template: analyticsLinechartHtml,
    controller: Controller,
  });

/*
DATA:
[{
  total: Str
  ticks: [{dt, value}, ...],
}]
*/

function Controller(
  $timeout,
  $scope,
  $element,
  AnalyticsActivityDataService,
  AnalyticsLinechartConfigService,
  AnalyticsColorsSchemeFabric,
) {
  // private
  const $ctrl = this;
  const pk = "drAnalyticsLinechart";
  const rootElement = d3.select($element[0]);
  const colorScheme = AnalyticsColorsSchemeFabric();
  const HEIGHT = AnalyticsLinechartConfigService.YAXIS.height;
  const ELEMENT_WIDTH = rootElement.select("div").node().offsetWidth;

  // public
  $ctrl.shadow = angular.copy(AnalyticsLinechartConfigService.SHADOW);
  $ctrl.shadow.id = "shadow-drAnalyticsLinechart" + $scope.$id;

  $ctrl.dispatcher = d3.dispatch(
    "update",
    "visibleKeys",
    "addHover",
    "removeHover",
  );

  $ctrl.$onInit = function () {
    $ctrl.dataDispatcher.on("update." + pk, update, $element, $scope);
    $timeout(update);
  };

  function update(data, { displayCount, yPrefix = "" } = {}) {
    if (!angular.isArray(data) || data.length === 0) {
      data = AnalyticsLinechartConfigService.getDefaultData();
    }
    const params = { yPrefix };

    const days = data[0].ticks.map(({ dt }) => dt);
    const daysExtent = d3.extent(days);
    const valueExtent = getValueExtent(data);

    params.maxValue = valueExtent[1];
    params.daysExtent = daysExtent;
    params.inProgress = !AnalyticsActivityDataService.isStartDateReached;

    params.r = AnalyticsLinechartConfigService.getCircleRadius(days.length);
    params.width = ELEMENT_WIDTH;
    params.height = HEIGHT;

    const xPaddingSides = Math.max(
      AnalyticsLinechartConfigService.XAXIS.padding,
      params.width / (days.length + 1) / 2,
    );

    params.xScale = d3
      .scaleTime()
      .range([
        xPaddingSides + params.r,
        params.width - xPaddingSides - params.r,
      ])
      .domain(daysExtent);

    params.yScale = d3
      .scaleLinear()
      .rangeRound([HEIGHT, 0])
      .domain([valueExtent[0], params.maxValue || 1]);

    params.data = convertTicks(data);

    $ctrl.dispatcher.call("update", null, params);

    function convertTicks(data) {
      return data
        .sort((a, b) => d3.descending(parseInt(a.total), parseInt(b.total)))
        .slice(0, displayCount)
        .map(convert);

      function convert(d, order) {
        const copy = angular.extend({}, d, {
          color: d.color || colorScheme(d.key || order),
          points: d.ticks
            .map(getPoints)
            .sort((a, b) => d3.descending(a.x, b.x)),
        });
        return copy;
      }

      function getPoints({ dt, value, tooltip }) {
        const isUnreachedYet = !AnalyticsActivityDataService.filterUnreached({
          dt,
        });
        return {
          x: params.xScale(dt),
          y: params.yScale(value),
          isUnreachedYet,
          key: dt.toString(),
          value,
          tooltip,
        };
      }
    }

    function getValueExtent(data) {
      const values = data.map(({ ticks }) => ticks.map((t) => t.value));
      const flatted = d3.merge(values);
      const extent = d3.extent(flatted);
      if (extent[0] && extent[1]) {
        const diff = extent[1] - extent[0];
        extent[0] = Math.floor(
          extent[1] - diff * AnalyticsLinechartConfigService.ZERO,
        );
      }
      if (extent[0] === extent[1] || extent[0] < 0) {
        extent[0] = 0;
      }
      return extent;
    }
  }
}
