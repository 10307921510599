<template>
  <div :class="$style.wrapper">
    <div :class="$style.header">
      <DrTruncatedTextTooltip :content="phase.name">
        {{ phase.name }}
      </DrTruncatedTextTooltip>
      <div
        :class="$style.roomsCount"
        :style="{ 'background-color': phase.color }"
      >
        {{ deals.length }}
      </div>
    </div>
    <ElScrollbar :class="$style.dealsListWrapper">
      <Draggable
        :class="$style.dealsListDraggable"
        :list="phaseDeals"
        item-key="id"
        :sort="false"
        group="deals-board-view-column"
        :data-drag-phase-id="phase.id"
        handle=".dash-rooms-board__item"
        @end="onDragEnd"
      >
        <template #item="{ element }">
          <DealsBoardItem :deal="element" :data-drag-deal-id="element.id" />
        </template>
      </Draggable>
    </ElScrollbar>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Draggable from "vuedraggable";
import * as Sentry from "@sentry/browser";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import DealsBoardItem from "./DealsBoardItem.vue";

import type { PhaseItem } from "@drVue/api-service/client-dashboard";
import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";
import type { PropType } from "vue";

interface SortableEndEvent {
  // https://github.com/SortableJS/Sortable#event-object-demo
  to: HTMLElement;
  item: HTMLElement;
}

export default defineComponent({
  name: "DealsBoardPhaseColumn",
  components: { DrTruncatedTextTooltip, DealsBoardItem, Draggable },
  props: {
    phase: { required: true, type: Object as PropType<PhaseItem> },
    deals: { required: true, type: Array as PropType<Deal[]> },
    position: {
      required: true,
      type: Number as PropType<number>,
    },
  },
  emits: ["dealMovedPhase"],
  computed: {
    phaseDeals(): Deal[] {
      // use computed property to avoid direct update on draggable component
      return this.deals;
    },
  },
  methods: {
    onDragEnd(ev: SortableEndEvent) {
      const dealId = parseInt(ev.item.dataset.dragDealId!, 10);
      const phaseId = parseInt(ev.to.dataset.dragPhaseId!, 10);
      if (dealId && phaseId) {
        this.$emit("dealMovedPhase", { dealId, phaseId });
      } else {
        Sentry.captureEvent({
          message: "DealBoard onDragEnd: dealId or phaseId is not defined",
          extra: {
            dealId,
            phaseId,
          },
          level: "error",
        });
      }
    },
  },
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography" as typo;

$phase-header-height: 40px;
$space: 8px;
$space-dbl: 16px;

.wrapper {
  display: grid;
  grid-template-rows: $phase-header-height 1fr;
  min-width: 335px;
  height: 100%;
  overflow: auto;

  & + & {
    border-left: solid 1px colors.$pr-150;
  }
}

.header {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  gap: 10px;
  padding: $space $space-dbl;
  font: typo.$body_medium;
  background-color: colors.$pr-0;
  border-bottom: solid 1px colors.$pr-150;
}

.roomsCount {
  height: 22px;
  padding: 0 5px;
  font: typo.$caption_medium;
  line-height: 22px;
  border-radius: 4px;
  color: #fff;
}

.dealsListWrapper {
  background: rgba(colors.$very-light-gray, 0.6);
  padding: 12px;
  height: 100%;
  overflow: auto;

  :global(.el-scrollbar__bar.is-vertical) {
    right: 0;
  }

  :global(.el-scrollbar__view) {
    height: 100%;
  }
}

.dealsListDraggable {
  height: 100%;
}
</style>
