import { ApiService } from "@drVue/api-service";

import type { OrgUser } from "../../../modules/client-dashboard/org-users/types";
import type { OrganizationMemberLookerAccess } from "@setups/enums";

export interface OrganizationMemberEditableFields {
  group_id: number;
  looker_access: OrganizationMemberLookerAccess;
}

export interface OrganizationMember extends OrganizationMemberEditableFields {
  id: number;
  user: OrgUser;
}

export type NewOrganizationMemberPayload = OrganizationMemberEditableFields & {
  email: string;
};

export type UpdatedOrganizationMemberPayload = OrganizationMemberEditableFields;

export class OrganizationMembersApiService extends ApiService {
  public async load(): Promise<OrganizationMember[]> {
    const url: string = this.Urls["api:client-dashboard:members-list"]();
    return this.get<OrganizationMember[]>(url);
  }

  public async createMember(
    payload: NewOrganizationMemberPayload,
  ): Promise<OrganizationMember> {
    const url: string = this.Urls["api:client-dashboard:members-list"]();
    return this.post<OrganizationMember>(url, payload);
  }

  public async updateMember(
    member_id: OrganizationMember["id"],
    changes: UpdatedOrganizationMemberPayload,
  ): Promise<OrganizationMember> {
    const url: string =
      this.Urls["api:client-dashboard:members-detail"](member_id);
    return this.put<OrganizationMember>(url, changes);
  }

  public async deleteMember(memberId: OrganizationMember["id"]): Promise<void> {
    const url: string =
      this.Urls["api:client-dashboard:members-detail"](memberId);
    return this.delete<void>(url);
  }

  public async resendInvite(memberId: number): Promise<void> {
    const url: string =
      this.Urls["api:client-dashboard:members-detail-resend-invite"](memberId);
    return this.post<void>(url, null);
  }
}
