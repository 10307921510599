<template>
  <ElDialog
    :model-value="shown"
    :append-to-body="true"
    :before-close="handleElDialogBeforeClose"
    :class="$style.modal"
    :close-on-click-modal="!isSubmitting"
    :close-on-press-escape="!isSubmitting"
    :show-close="false"
    :width="448"
    :with-header="false"
    align-center
    destroy-on-close
  >
    <div :class="$style.form">
      <div :class="$style.header">
        <div :class="$style.title">{{ title }}</div>
        <button
          :class="{
            [$style.closeButton]: true,
            [$style.closeButton_disabled]: isSubmitting,
          }"
          :disabled="isSubmitting"
          @click="handleClose"
        >
          <DrIcon name="remove" size="sm" />
        </button>
      </div>

      <div
        v-if="!!$slots.description || subtitle"
        :class="{
          [$style.description]: true,
          [$style.description_noDivider]: !$slots.default,
        }"
      >
        <slot name="description">
          {{ subtitle }}
        </slot>
      </div>

      <ElForm
        v-if="!!$slots.default || !!$slots.additional"
        label-position="top"
        require-asterisk-position="right"
        :disabled="isSubmitting"
        :class="$style.body"
      >
        <slot name="default" />

        <div v-if="!!$slots.additional" :class="$style.additional">
          <slot name="additional" />
        </div>
      </ElForm>

      <div :class="$style.footer">
        <slot name="footer">
          <ElButton :disabled="isSubmitting" @click="emit('cancel')">
            {{ cancelBtnText }}
          </ElButton>
          <ElButton
            type="primary"
            :loading="isSubmitting"
            @click="emit('submit')"
          >
            {{ submitBtnText }}
          </ElButton>
        </slot>
      </div>
    </div>
  </ElDialog>
</template>

<script setup lang="ts">
/**
 * The modal dialog for user input with the page overlay.
 *
 * It's based on element-plus component according to the requirements of our style guide.
 * @reference https://element-plus.org/en-US/component/dialog.html
 *
 * @important 1) Only the parent is responsible for the shown/closed state - prop "shown".
 *            2) Responsibility for validating "whether the modal is allowed to close"
 *               is also left only to the parent component.
 */

import DrIcon from "../dr-icon";

interface Props {
  shown: boolean;
  /** Form title */
  title: string;
  /**
   * Optional descriptive subtitle explains the purpose of the current form or explains something significant.
   * It will appear right below the title.
   * Also may be customized via #description slot.
   */
  subtitle?: string;
  submitBtnText: string;
  cancelBtnText?: string;
  /**
   * Turn on form "submitting state":
   ** constrols inside ElForm disabled,
   ** footer default buttons also disabled.
   * In this state, the modal does not emit events.
   */
  isSubmitting?: boolean;
}
withDefaults(defineProps<Props>(), {
  subtitle: "",
  cancelBtnText: "Cancel",
  isSubmitting: false,
});
const emit = defineEmits<{
  (event: "close"): void;
  (event: "submit"): void;
  (event: "cancel"): void;
}>();

const handleClose = () => {
  emit("close");
};
const handleElDialogBeforeClose = (hide: (shouldCancel: boolean) => void) => {
  hide(true);
  handleClose();
};
</script>

<style lang="scss" module>
@use "sass:math";

@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography" as typo;

$panel-space: 24px;
$buttons-space: 16px;

.modal {
  background: #fff;
  border: 1px solid colors.$pr-300;
  box-shadow: 0px 2px 10px -6px rgba(47, 48, 49, 0.16);
  border-radius: 8px;

  :global {
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
    }
  }
}

.form {
  position: relative;
  width: 100%;
  max-height: 100%;
  padding: 20px $panel-space $panel-space;
  overflow: hidden;
}

.header {
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: $panel-space;
  align-items: center;
}

.title {
  font: typo.$title_bold;
  color: colors.$pr-900;
}

.closeButton {
  width: 20px;
  height: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: colors.$pr-400;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    color: colors.$pr-900;
  }
}

.closeButton_disabled {
  cursor: not-allowed;

  &:hover {
    color: colors.$pr-400;
  }
}

.description {
  margin-top: math.div($panel-space, 2);
  padding-bottom: $panel-space;
  color: colors.$pr-900;
  font: typo.$body_regular;
  border-bottom: solid 1px colors.$pr-200;

  b {
    font: typo.$body_semibold;
  }
}

.description_noDivider {
  padding-bottom: 0;
  border-bottom: none;
}

.body {
  min-height: 0;
  max-height: 70vh;
  padding-top: $panel-space;
  display: grid;
  grid-auto-flow: row;
  gap: $panel-space;
  overflow-y: auto;

  :global {
    .el-form-item {
      margin: 0;
    }
  }
}

.additional {
  padding-top: $panel-space;
  border-top: solid 1px colors.$pr-200;
}

.footer {
  padding-top: 32px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: end;
  gap: $buttons-space;

  :global {
    .el-button + .el-button {
      margin-left: 0;
    }
  }
}
</style>
