<template>
  <DrLayoutPage
    page-title="Your work"
    page-icon="layer-group"
    :nav-items="yourWorkPages"
  >
    <RouterView v-slot="{ Component, route }">
      <DrTransitionPage>
        <component :is="Component" :key="route.path" />
      </DrTransitionPage>
    </RouterView>
  </DrLayoutPage>
</template>

<script lang="ts" setup>
import { DrLayoutPage } from "@shared/ui/dr-layouts";
import { DrTransitionPage } from "@shared/ui/dr-transitions";

import { ORG_MEMBER_DATA } from "@setups/data";

const yourWorkPages = (() => {
  const tabs = [
    {
      id: "tasks",
      name: "Room requests",
      path: "/your-work/tasks",
    },
  ];

  if (
    ORG_MEMBER_DATA?.client?.enable_dashboard &&
    ORG_MEMBER_DATA?.client?.enable_findings
  ) {
    tabs.push({
      id: "findings",
      name: "Findings",
      path: "/your-work/findings",
    });
  }

  if (
    ORG_MEMBER_DATA?.client?.enable_dashboard &&
    ORG_MEMBER_DATA?.client?.enable_deal_tasks
  ) {
    tabs.push({
      id: "deal_tasks",
      name: "Deal tasks",
      path: "/your-work/deal-tasks",
    });
  }
  return tabs;
})();
</script>
