angular
  .module("dealroom.analytics.datagrid-table.service.nest.tree", [
    "dealroom.analytics.service.nest",
    "dealroom.analytics.service.labels",
  ])
  .service(
    "AnalyticsDatagridNestTreeService",
    AnalyticsDatagridNestTreeService,
  );

AnalyticsDatagridNestTreeService.$inject = [
  "AnalyticsDatagridFilterService",
  "ActivityNestService",
  "ActivityLabelService",
];
function AnalyticsDatagridNestTreeService(
  AnalyticsDatagridFilterService,
  ActivityNestService,
  ActivityLabelService,
) {
  return { create };

  function concatCellValues(childValues) {
    return childValues.reduce((bucket, cellsValues) => {
      Object.keys(cellsValues).forEach((key) => {
        const value = cellsValues[key];
        if (value instanceof Array) {
          if (bucket[key] === undefined) bucket[key] = [];
          value.forEach((uid) => {
            if (bucket[key].indexOf(uid) === -1) {
              bucket[key].push(uid);
            }
          });
        } else {
          if (bucket[key] === undefined) bucket[key] = 0;
          bucket[key] += value;
        }
      });
      return bucket;
    }, {});
  }

  function create($ctrl) {
    const TYPES = $ctrl.nestByArray.concat(["dt"]);
    const rollup = getRollupValues();
    const activityCellValue = getActivityCellValue($ctrl);
    const nested = rollup(AnalyticsDatagridFilterService.filter($ctrl));

    const Tree = nested.reduce(createTree, {});
    if (
      $ctrl.subParentFieldName !== undefined &&
      Tree[$ctrl.nestByArray[0]] !== undefined
    ) {
      const rootItems = $ctrl.getRootItems();
      rootItems.forEach(fillTree);
    }
    TYPES.forEach((type) => {
      if (Tree[type] === undefined) Tree[type] = {};
      delete Tree[type][ActivityNestService.NO_GROUP];
    });
    return Tree;

    function fillTree(parent) {
      const type = $ctrl.nestByArray[0];
      let parentCells = Tree[type][parent.id] || {};
      const childs = parent[$ctrl.subParentFieldName];
      if (childs !== undefined) {
        parentCells = childs.reduce((bucket, child) => {
          const cellsValues = fillTree(child);
          return concatCellValues([bucket, cellsValues]);
        }, parentCells);
        Tree[type][parent.id] = parentCells;
      }
      return parentCells;
    }

    function getActivityCellValue({ cells }) {
      return cells.reduce((bucket, cell) => {
        putToBucket(cell);
        return bucket;

        function putToBucket(cell) {
          if (cell.verbs !== undefined) {
            cell.verbs.forEach((verb) => {
              if (bucket[verb] === undefined) bucket[verb] = [];
              bucket[verb].push({
                src: cell.src,
                key: cell.key,
              });
            });
          }

          if (cell.childs !== undefined) {
            cell.childs.forEach(putToBucket);
          }
        }
      }, {});
    }

    function createTree(bucket, row) {
      putToBucket(row);
      return bucket;

      function putToBucket({ key, value }, nestLevel = 0, parentId = "") {
        const type = $ctrl.nestByArray[nestLevel];
        let cellsValues;
        if (type !== undefined) {
          cellsValues = valuesFromChilds(value);
          if (bucket[type] === undefined) bucket[type] = {};
          bucket[type][key] = cellsValues;
        } else {
          if (bucket["dt"] === undefined) bucket["dt"] = {};
          if (bucket["dt"][parentId] === undefined) bucket["dt"][parentId] = [];

          cellsValues = valuesFromActions(value);
          bucket["dt"][parentId].push({
            parentId,
            key,
            cellsValues,
            type: "dt",
            activity: value,
          });
        }
        return cellsValues;

        function valuesFromChilds(childs) {
          return childs.reduce((bucket, row) => {
            const cellsValues = putToBucket(row, nestLevel + 1, key);
            return concatCellValues([bucket, cellsValues]);
          }, {});
        }

        function valuesFromActions(actions) {
          return actions.reduce(getCellValues, {});

          function getCellValues(bucket, d) {
            const cells = activityCellValue[d.verb];
            if (cells === undefined) return bucket;

            cells.forEach((cell) => {
              const [type, keyField] = cell.src.split(":");
              if (type === "uniq") {
                if (bucket[cell.key] === undefined) bucket[cell.key] = [];
                const uid =
                  keyField === undefined
                    ? ActivityLabelService.itemId(d)
                    : d[keyField] || d.id;
                if (bucket[cell.key].indexOf(uid) === -1)
                  bucket[cell.key].push(uid);
              } else {
                if (bucket[cell.key] === undefined) bucket[cell.key] = 0;
                bucket[cell.key] += type === "time" ? d.time_spent || 0 : 1;
              }
              if (bucket[cell.key] === undefined) bucket[cell.key] = [];
            });

            return bucket;
          }
        }
      }
    }

    function getRollupValues(nestLevel = 0) {
      return function (values) {
        const nestBy = TYPES[nestLevel];
        const rollupFn =
          nestBy === "dt" ? rollupDts : getRollupValues(nestLevel + 1);
        return ActivityNestService.nest(values, nestBy, rollupFn);
      };

      function rollupDts(actions) {
        /*
          if d3 nest data with custom rollup fn -> nested entry has {key, value}
          otherwise it has {key, values}, so to keep everything uniformly
        */
        return actions;
      }
    }
  }
}
