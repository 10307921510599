<template>
  <div :class="$style.path">
    <template v-if="pathStart">
      <DrTruncatedTextTooltip :content="fullPath">
        <span :class="$style.text">
          {{ pathStart }}
        </span>
      </DrTruncatedTextTooltip>

      <span>/</span>
    </template>

    <template v-if="isPathLong">
      <span>...</span>
      <span>/</span>
    </template>

    <a
      target="_blank"
      :href="url"
      :title="pathEnd"
      :class="[$style.text, $style.target]"
    >
      {{ pathEnd }}
    </a>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

const props = defineProps<{
  path: string[];
  url: string;
}>();

const pathStart = computed(() => {
  if (props.path.length > 1) {
    return props.path[0];
  }
  return "";
});

const pathEnd = computed(() => props.path[props.path.length - 1] || "");
const fullPath = computed(() => props.path.join(" / "));

const isPathLong = computed(() => props.path.length > 2);
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography" as typo;

.path {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
  overflow: hidden;
  max-width: 100%;
  font: typo.$body_regular;
  color: colors.$pr-500;
}

.text {
  flex-shrink: 10;
  overflow: hidden;
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.target {
  flex-shrink: 0;
  font: typo.$body_medium;
  color: colors.$pr-800;
  text-decoration: none;

  &:focus {
    color: colors.$sc-600;
    text-decoration: none;
  }

  &:hover {
    color: colors.$sc-600;
    text-decoration: underline;
  }
}
</style>
