<template>
  <ElSelect
    v-model="selectedRoomId"
    clearable
    placeholder="All rooms"
    @visible-change="loadRooms"
  >
    <ElOption
      v-for="room in rooms"
      :key="room.id"
      :label="room.title"
      :value="room.id"
    />
    <template #empty>
      <div :class="$style.empty">
        <span v-if="isLoading">Loading...</span>
        <span v-if="isLoaded && rooms.length === 0">No rooms found</span>
      </div>
    </template>
  </ElSelect>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { RoomApiService } from "@drVue/api-service/common/rooms";

import type { RoomListItem } from "@drVue/api-service/common/rooms";
import type { PropType } from "vue";

interface Data {
  selectedRoomId: number | undefined;
  isLoaded: boolean;
  isLoading: boolean;
  isLoadingFailed: boolean;
  api: RoomApiService;
  rooms: RoomListItem[];
}

export default defineComponent({
  name: "RoomSelectSimple",
  props: {
    modelValue: {
      required: false,
      type: Object as PropType<RoomListItem | null>,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  data(): Data {
    return {
      selectedRoomId: undefined,
      isLoaded: false,
      isLoading: false,
      isLoadingFailed: false,
      api: new RoomApiService(),
      rooms: [],
    };
  },
  watch: {
    // <el-select> selects all the items in a list if the value is object-like.
    // To mitigate this we use pure number as a value.
    selectedRoomId(roomId: number) {
      if (!roomId) {
        this.$emit("update:modelValue", null);
        return;
      }

      const room = this.rooms.find((r) => r.id === roomId);
      this.$emit("update:modelValue", room);
    },
  },
  methods: {
    loadRooms() {
      if (this.isLoading || this.isLoaded) {
        return;
      }

      this.isLoading = true;
      this.isLoadingFailed = false;

      this.api
        .list()
        .then(
          (rooms) => {
            this.rooms = rooms;
            this.isLoaded = true;
          },
          () => (this.isLoadingFailed = true),
        )
        .finally(() => (this.isLoading = false));
    },
  },
});
</script>

<style module lang="scss">
.empty {
  font-size: 14px;
  height: 34px;
  line-height: 34px;
  padding: 0 20px;
}
</style>
