import { ApiService } from "@drVue/api-service";

export interface AddMembersData {
  members: { email: string }[];

  message: string;
}

export interface RoomMember {
  id: number;
  name: string;
  initial: string;
  title: string;
  company: string;
  email: string;
  avatar: { thumbnail: string; reduced: string };
  first_name: string;
  headline: string;
  last_name: string;
  office_number: string;
  pgroup: { id: number; name: string; is_archived: boolean };
  user_id: number;
  pending: boolean;
  is_archived?: boolean;
  room_owner?: boolean;
  link: string;
  uid: string;
}

export class RoomMembersApiService extends ApiService {
  public async getMembers(): Promise<RoomMember[]> {
    const url: string = this.Urls["api:room:pgroup_list_members"]();
    return this.get<RoomMember[]>(url);
  }

  public async addMembersCheck(
    pgroupId: number,
    addMembersData: AddMembersData,
  ) {
    const url = this.Urls["api:room:pgroup_members_check_add"](pgroupId);
    return this.post(url, addMembersData);
  }

  public async addMembers(pgroupId: number, addMembersData: AddMembersData) {
    const url = this.Urls["api:room:pgroup_members_add"](pgroupId);
    return this.post(url, addMembersData);
  }

  public async removeMember(permissionId: number, memberEmail: string) {
    const url = this.Urls["api:room:pgroup_members_remove"](
      permissionId,
      memberEmail,
    );
    return this.delete(url);
  }

  public async resendInvite(permissionId: number, inviteId: number) {
    const url = this.Urls["api:room:pgroup_resend_invite"](
      permissionId,
      inviteId,
    );
    return this.post(url, null);
  }
}
