import { ApiService } from "@drVue/api-service";

export enum ApiTokenType {
  IntegrationToken = "integration_token",
  OrgMemberToken = "org_member_token",
}

export interface ApiToken {
  id: number;

  token_type: ApiTokenType;

  // available one time only right after creation
  key?: string;

  // last 4 chars of key, guaranteed to be unique per client
  key_suffix: string;

  last_used: Date;
  date_created: Date;

  // if null then the token is active (not revoked),
  // otherwise it’s the date when the token was revoked
  date_revoked: null | Date;
}

export class TokensApiService extends ApiService {
  public list(integrationId?: number): Promise<ApiToken[]> {
    const url = this.Urls["api:client-dashboard:api-tokens-list"]();
    const query = integrationId ? `?integration=${integrationId}` : "";

    return this.get<ApiToken[]>(`${url}${query}`);
  }

  public create(integrationId?: number): Promise<ApiToken> {
    const url = this.Urls["api:client-dashboard:api-tokens-list"]();
    const type = integrationId
      ? ApiTokenType.IntegrationToken
      : ApiTokenType.OrgMemberToken;

    const req = { token_type: type } as any;
    if (integrationId) req.integration = integrationId;

    return this.post<ApiToken>(url, req);
  }

  public revoke(tokenId: number): Promise<ApiToken> {
    const url =
      this.Urls["api:client-dashboard:api-tokens-detail-revoke"](tokenId);
    return this.post(url, {});
  }
}
