import { getters } from "./getters";
import { mutations } from "./mutations";
import { State } from "./state";

import type { RootState } from "./types";
import type { Module } from "vuex";

export { State } from "./state";

export const Filters: Module<State, RootState> = {
  getters,
  mutations,
  namespaced: true,
  state: new State(),
};
