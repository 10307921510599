<template>
  <div>
    <div v-if="result.room_owner.length">
      {{ result.room_owner[0] }} is the Room Owner and could not be added as a
      new member or moved to non-admin group.
    </div>
    <div v-if="result.exists.length">
      The following
      {{ result.exists.length === 1 ? "user is" : "users are" }} already a part
      of {{ result.group_name }} group: {{ result.exists.join(", ") }}.
    </div>
    <div v-if="result.in_another.length">
      You can not invite {{ result.in_another.join(", ") }} as they are already
      a part of another group.
    </div>
    <div v-if="result.invite_ignored.length">
      The following
      {{ result.invite_ignored.length === 1 ? "user is" : "users are" }}
      ignored: {{ result.invite_ignored.join(", ") }}. Unregistered users can
      not be invited to inactive room.
    </div>
    <div v-if="result.invite_limited.length">
      The following
      {{ result.invite_limited.length === 1 ? "user is" : "users are" }}
      ignored: {{ result.invite_limited.join(", ") }}. You've reached your limit
      of invites. Activate a subscription to remove the limit.
    </div>
    <div v-if="result.moved.length">
      Are you sure want to move {{ result.moved.join(", ") }} to
      {{ result.group_name }} group?
    </div>
    <div v-if="result.invited.length">
      Are you sure want to invite {{ result.invited.join(", ") }} to
      {{ result.group_name }} group?
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { InviteResponse } from "@app/vue/api-service/client-dashboard/users";
import type { PropType } from "vue";

export default defineComponent({
  name: "InviteConfirmationMessage",
  props: {
    result: { required: true, type: Object as PropType<InviteResponse> },
  },
});
</script>
