import { createDictionary } from "@drVue/common";
import { diff } from "./helpers/diff";

import type { TaskComparator } from "../types/TaskComparator";

const TASK_PRIORITY_LEVELS = createDictionary({
  low: 1,
  medium: 2,
  high: 3,
});

export const priority: TaskComparator = (a, b, isReversed = false) => {
  if (!a.priority || !b.priority) return 0;

  return diff(
    TASK_PRIORITY_LEVELS[a.priority],
    TASK_PRIORITY_LEVELS[b.priority],
    isReversed,
  );
};
