import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { $notifyDanger, createDictionary } from "@drVue/common";
import { pinia } from "@drVue/store/pinia";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import { CategoriesApiService } from "../categories/api";

import type { Category } from "../categories/api";
import type { NavTreeItem } from "@shared/ui/dr-nav";

const api = new CategoriesApiService();

const formatCategory = (
  cat: Category,
): NavTreeItem<Pick<Category, "order">> => {
  return {
    id: cat.id,
    name: cat.name,
    order: cat.order,
    children: cat.categories.map(formatCategory),
  };
};

export const useCategoriesArchivedStore = defineStore(
  "categoriesArchivedStore",
  () => {
    const categoriesStore = useCategoriesStore(pinia);
    const tasksStore = useTasksStore(pinia);

    const loadingPromise = ref<Promise<Category[]> | null>(null);

    const categoriesList = ref<Category[]>([]);
    const categories = computed(() => {
      return categoriesList.value.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, createDictionary<Category>());
    });

    const categoriesByUid = computed(() => {
      return categoriesList.value.reduce((acc, cur) => {
        acc[cur.uid] = cur;
        return acc;
      }, createDictionary<Category>());
    });

    const isLoading = ref<boolean>(false);
    const isError = ref<boolean>(false);

    const load = (skipErrorAlert: boolean) => {
      if (isLoading.value) return loadingPromise.value;

      // If someone asked to load archived categories, we need to load the
      // active ones first. Otherwise, we'll have a problem with the parents.
      let first: Promise<unknown>;
      if (categoriesStore.loadingPromise === null) {
        first = categoriesStore.load(true);
      } else if (categoriesStore.isLoading) {
        first = categoriesStore.loadingPromise;
      } else {
        first = Promise.resolve();
      }

      isLoading.value = true;
      isError.value = false;

      loadingPromise.value = first.then(() =>
        api
          .getArchivedCategories()
          .then(
            (cats) => {
              if (!Array.isArray(cats)) return [];

              cats.forEach((cat) => {
                const parent = cat.parent_id
                  ? categoriesStore.categories[cat.parent_id]
                  : undefined;
                cat.parent = parent;
                cat.parents = parent ? parent.parents.concat([parent]) : [];
                cat.descendants = [];
              });

              categoriesList.value = cats;

              return cats;
            },
            (error) => {
              if (!skipErrorAlert) {
                $notifyDanger("Failed to load categories.");
              }

              isError.value = true;
              return Promise.reject(error);
            },
          )
          .finally(() => (isLoading.value = false)),
      );

      return loadingPromise.value;
    };

    const restoreCategory = (id: number) => {
      return api
        .editCategory(id, { is_archived: false })
        .then(() => Promise.all([load(true), categoriesStore.load(true)]))
        .then(() => {
          tasksStore.load(true);
        });
    };

    return {
      loadingPromise,

      categories,
      categoriesByUid,
      categoriesList,

      restoreCategory,

      isLoading,
      isError,

      load,
    };
  },
);

export type CategoriesStore = ReturnType<typeof useCategoriesStore>;
