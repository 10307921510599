<template>
  <div>
    <ElPopover
      placement="bottom"
      trigger="click"
      popper-class="deals-view-options__popover"
      width="auto"
    >
      <ElForm size="default" label-position="top">
        <ElFormItem label="View">
          <ElRadioGroup v-model="currentLayout">
            <ElRadioButton
              v-for="layout in layouts"
              :key="layout.key"
              :label="layout.key"
              >{{ layout.label }}
            </ElRadioButton>
          </ElRadioGroup>
        </ElFormItem>

        <ElFormItem
          v-if="currentView.layout === ViewLayouts.Table"
          label="Columns"
        >
          <TableCustomize
            :columns="tableColumns.columns"
            class="el-form-item__content-inner el-form-item__content-inner--full-width"
            @update="updateColumns"
            @reset="resetColumns"
          />
        </ElFormItem>
        <ElFormItem
          v-if="currentView.layout === ViewLayouts.Timeline"
          label="Zoom"
        >
          <TimelineZoomSelector />
        </ElFormItem>
        <DealsBoardSortSelector
          v-if="currentView.layout === ViewLayouts.Board"
        />
      </ElForm>
      <template #reference>
        <ElButton>
          <span>Adjust view</span>
          <i class="fas fa-columns" />
        </ElButton>
      </template>
    </ElPopover>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TableCustomize from "@shared/ui/table-customize/TableCustomize.vue";

import { CustomViewLayouts } from "@setups/types";
import DealsBoardSortSelector from "@drVue/components/client-dashboard/deals/DealsBoard/DealsBoardSortSelector.vue";
import TableColumns from "@drVue/components/client-dashboard/deals/DealsTable/tableColumns";
import TimelineZoomSelector from "../DealsTimeline/TimelineZoomSelector";

import type { CustomView, CustomViewColumn } from "@setups/types";

interface Data {
  ViewLayouts: any;
  layouts: any;
}

export default defineComponent({
  name: "CurrentDealsViewOptions",
  components: {
    TableCustomize,
    TimelineZoomSelector,
    DealsBoardSortSelector,
  },
  data(): Data {
    return {
      ViewLayouts: CustomViewLayouts,
      layouts: [
        { key: CustomViewLayouts.Table, label: "Table" },
        { key: CustomViewLayouts.Timeline, label: "Timeline" },
        { key: CustomViewLayouts.Board, label: "Board" },
      ],
    };
  },
  computed: {
    currentView(): CustomView {
      return this.$store.state.common.customViews.current;
    },
    tableColumns(): any {
      return new TableColumns(this.$store);
    },
    currentLayout: {
      get: function (): any {
        return this.currentView.layout;
      },
      set: function (layout: CustomViewLayouts) {
        this.$store.commit("common/customViews/updateCurrentLayout", layout);
      },
    },
  },
  methods: {
    updateColumns(updatedColumns: CustomViewColumn[]) {
      this.$store.commit(
        "common/customViews/updateCurrentSettingsColumns",
        updatedColumns,
      );
    },
    resetColumns() {
      this.$store.commit("common/customViews/updateCurrentSettings", {
        columns: [],
      });
    },
  },
});
</script>
