import { orderBy } from "lodash-es";

import { FieldItemTypes } from "@drVue/api-service/client-dashboard";
import { parseFieldValue } from "../fields/utils";

import type { FieldItem, PhaseItem } from "@drVue/api-service/client-dashboard";
import type { BaseOrder } from "@drVue/api-service/client-dashboard/settings";
import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";

type IFieldKeyMap = { [key: string]: FieldItem };
type IPhaseKeyMap = { [key: number]: PhaseItem };

function selectFieldIndex(options: string[], value: any) {
  const index = options.indexOf(value);
  return index > -1 ? index : undefined;
}

function isNaN(value: any) {
  const n = Number(value);
  return n !== n;
}

export function getSortValue(field: FieldItem, rawValue: any) {
  const value = parseFieldValue(field, rawValue);
  if (value == undefined) {
    return;
  }

  if (field.field_type === FieldItemTypes.Number) {
    const val = parseInt(value);
    return isNaN(val) ? Number.MAX_VALUE : val;
  }

  if (field.field_type === FieldItemTypes.Date) {
    return value.getTime();
  }

  if (field.field_type === FieldItemTypes.Select) {
    if ("options" in field.extra) {
      return selectFieldIndex(field.extra.options as string[], value);
    }
  }

  if (field.field_type === FieldItemTypes.MultiSelect) {
    const allIndexes = value
      .map((selVal: string) => {
        if ("options" in field.extra) {
          return selectFieldIndex(field.extra.options as string[], selVal);
        }
      })
      .filter((val: number | undefined) => val !== undefined)
      .sort();
    return allIndexes.length ? allIndexes[0] : undefined;
  }

  return value;
}
const BASIC_SORT_FIELDS = [
  "title",
  "start_date",
  "last_note.date_added",
  "last_note.body_text",
];

export function sortDeals(
  deals: Deal[],
  dealsOrder: BaseOrder[],
  allFields: FieldItem[],
  allPhases: PhaseItem[],
): Deal[] {
  const orderByIteratees: any[] = [];
  const orderByDirection: any[] = [];

  const fields: IFieldKeyMap = allFields.reduce((acc: IFieldKeyMap, field) => {
    acc[field.key] = field;
    return acc;
  }, {});
  const phases: IPhaseKeyMap = allPhases.reduce((acc: IPhaseKeyMap, phase) => {
    acc[phase.id] = phase;
    return acc;
  }, {});

  dealsOrder.forEach((dealOrder) => {
    if (BASIC_SORT_FIELDS.indexOf(dealOrder.field_key) >= 0) {
      orderByIteratees.push(dealOrder.field_key);
    } else if (dealOrder.field_key === "phase") {
      // by phase
      orderByIteratees.push((deal: Deal) => {
        return deal.phase && phases[deal.phase]
          ? phases[deal.phase].order
          : undefined;
      });
    } else if (fields[dealOrder.field_key]) {
      // by custom field
      const field = fields[dealOrder.field_key];
      orderByIteratees.push((deal: Deal) => {
        return getSortValue(field, deal.custom_data[field.key]);
      });
    } else {
      // simply ignore the dealOrder
      return;
    }
    orderByDirection.push(dealOrder.direction);
  });
  return orderBy(deals, orderByIteratees, orderByDirection);
}
