<template>
  <div :class="$style.wrapper">
    <div :class="$style.content">
      <div :class="$style.accountInfo">
        <UserInformation :user="user" size="28px" />
      </div>
      <br />
      <div v-if="OAUTH2_AUTHORIZATION_DATA.fatal_error">
        <AuthorizeError :error="OAUTH2_AUTHORIZATION_DATA.fatal_error" />
      </div>
      <div v-else>
        <AuthorizeDialog :data="OAUTH2_AUTHORIZATION_DATA" />
      </div>
    </div>
    <DrAlert />
  </div>
</template>

<script lang="ts" setup>
import { UserInformation } from "@shared/ui/users";

import { USER_DATA } from "@setups/data";
import { DrAlert } from "@app/vue/components";
import AuthorizeDialog from "./authorize/AuthorizeDialog.vue";
import AuthorizeError from "./AuthroizeError.vue";
import { OAUTH2_AUTHORIZATION_DATA } from "./OAuth2AuthorizePageData";

import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";

const user = USER_DATA.profile as unknown as OrgUser;
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors" as colors;
@use "@app/styles/scss/typography.scss" as typo;

$border-radius: 4px;

.wrapper {
  height: 100vh;
  width: 100%;
  min-width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: colors.$sc-500;
}

.content {
  background: colors.$white;
  border-radius: $border-radius;
  width: 420px;
  padding-bottom: 36px;
}

.accountInfo {
  background: colors.$pr-50;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}
</style>
