import { tasksRelatedActions } from "./TasksRelatedActions";
import { tasksRelatedGetters } from "./TasksRelatedGetters";
import { tasksRelatedMutations } from "./TasksRelatedMutations";
import { TasksRelatedState } from "./TasksRelatedState";

import type { RootState } from "@drVue/store/state";
import type { Module } from "vuex";

export { TasksRelatedState } from "./TasksRelatedState";

export const TasksRelatedModule: Module<TasksRelatedState, RootState> = {
  actions: tasksRelatedActions,
  getters: tasksRelatedGetters,
  mutations: tasksRelatedMutations,
  namespaced: true,
  state: new TasksRelatedState(),
};
