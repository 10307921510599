import { ROOM_DATA } from "@setups/data";
import { folderUrl } from "@setups/room-urls";
import documents_folderInformationHtml from "../../../templates/components/documents/folder-information.html?raw";

export default function drFolderInformation() {
  return {
    template: documents_folderInformationHtml,
    bindings: {
      folderRaw: "<?folder",
      folderId: "<?",
      isLink: "<?",
      isHoverable: "<?",
      isExpanded: "<?",
      isStatic: "<?", // true by default, one time binding data if static
      showIndex: "<?",
    },
    controller: [
      "$scope",
      "$state",
      "DocumentsService",
      function ($scope, $state, DocumentsService) {
        var $ctrl = this;
        $ctrl.Documents = DocumentsService;

        $ctrl.$onInit = function () {
          $ctrl.isStatic = angular.isDefined($ctrl.isStatic)
            ? $ctrl.isStatic
            : true;

          var folderWatcher = $scope.$watchCollection(
            "[$ctrl.Documents.loading, $ctrl.folderRaw, $ctrl.folderId]",
            function () {
              if (!$ctrl.Documents.isLoaded() && !$ctrl.folderRaw) {
                return;
              }
              if ($ctrl.isStatic) {
                folderWatcher(); // unwatch
              }

              $ctrl.folder = $ctrl.folderRaw
                ? $ctrl.folderRaw
                : $ctrl.Documents.Folders[$ctrl.folderId];
              if (!$ctrl.folder) {
                return;
              }

              $ctrl.link = $ctrl.isLink
                ? folderUrl(ROOM_DATA.url, $ctrl.folder.id)
                : "";
            },
          );
        };
      },
    ],
  };
}
