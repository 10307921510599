import { date, number, string } from "yup";

import { CUSTOM_FIELD_TO_SCHEMA_MAP } from "@drVue/components/client-dashboard/dynamic-form/utils";

import type { FieldSchema } from "./types";
import type { FieldItem } from "@drVue/api-service/client-dashboard";

export const mapCustomFieldToSchema = (fieldItem: FieldItem): FieldSchema => {
  return {
    label: fieldItem.label,
    prop: `custom_data.${fieldItem.key}`,
    type: CUSTOM_FIELD_TO_SCHEMA_MAP[fieldItem.field_type],
    extra: fieldItem.extra,
  };
};

export const validateDate = () => {
  return date().transform((value, _originalValue, _context) => {
    if (value instanceof Date && Number.isNaN(value.getTime())) {
      return undefined;
    }

    return value;
  });
};

export const validateNumber = () => {
  return number().transform((value, _originalValue, _context) => {
    if (value === null || Number.isNaN(value)) {
      return undefined;
    }

    return value;
  });
};

export const validateString = string;
