<template>
  <DrListRelatedItems
    :is-clearable="!viewOnly"
    is-link
    :items="preparedItems"
    @remove="$emit('remove', $event)"
  >
    <template #prefix="{ item }">
      <span
        v-if="(item as LocalItem).code"
        :style="{ color: (item as LocalItem).color }"
      >
        #{{ (item as LocalItem).code }}
      </span>
      <DrIcon v-else name="stream" size="sm" />
    </template>

    <template v-if="showEmpty" #empty>
      No worklists or requests have been associated with this finding so far.
    </template>
  </DrListRelatedItems>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrListRelatedItems } from "@shared/ui/dr-list";

import { DrStore } from "@app/vue";
import { pinia } from "@drVue/store/pinia";
import { useCategoriesStore } from "@drVue/store/pinia/room/categories";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";

import type { TaskStatus } from "@drVue/store/modules/room/tasks-statuses/types";

type Item =
  | {
      task_uid: string;
    }
  | {
      category_uid: string;
      name?: string;
    };

type LocalItem = Item & {
  uid: string;
  code: string;
  name: string;
  color?: string;
};

interface Props {
  items: Item[];
  viewOnly?: boolean;
  showEmpty?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  viewOnly: false,
  showEmpty: false,
});

defineEmits<{
  (event: "remove", item: Item): void;
}>();

const categoriesStore = useCategoriesStore(pinia);
const tasksStore = useTasksStore(pinia);
const getStatusById = DrStore.getters["room/tasksStatuses/byId"];

const preparedItems = computed<LocalItem[]>(() => {
  // get correct room path
  const path = location.href.replace(location.hash, "");

  return props.items.map((item) => {
    if ("task_uid" in item) {
      const task = tasksStore.tasksByUid[item.task_uid] || {};
      const taskStatus = getStatusById(task.status_id) as TaskStatus;

      return {
        uid: item.task_uid,
        task_uid: item.task_uid,
        code: task.key || "",
        name: task.title || "unknown",
        color: taskStatus?.color,
        href: task.key ? `${path}#/tasks/${task.key}` : "",
      };
    } else {
      const category = categoriesStore.categoriesByUid[item.category_uid] || {};
      return {
        uid: item.category_uid,
        category_uid: item.category_uid,
        code: "",
        name: item.name || category.name || "unknown",
        color: undefined,
        href: category.id ? `${path}#/tasks/list/${category.id}/overview` : "",
      };
    }
  });
});
</script>
