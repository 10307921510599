import { DrStore } from "@app/vue";
import { diff } from "./helpers/diff";

import type { TaskComparator } from "../types/TaskComparator";
import type { TaskStatus } from "@app/vue/store/modules/room/tasks-statuses/types";

type StatusId = TaskStatus["id"];
type StatusIndex = number;

// TODO: rewrite TasksStatuses store using Pinia and create a computed for this.
let statusIndexById: Map<StatusId, StatusIndex> | null = null;

export const status: TaskComparator = (a, b, isReversed = false) => {
  if (!statusIndexById) {
    const taskStatuses = DrStore.state.room.tasksStatuses.list as TaskStatus[];
    if (!taskStatuses.length) return diff(a.status_id, b.status_id, isReversed);

    statusIndexById = new Map<StatusId, StatusIndex>(
      taskStatuses.map<[StatusId, StatusIndex]>((s, i) => [s.id, i]),
    );
  }

  return diff(
    statusIndexById.get(a.status_id)!,
    statusIndexById.get(b.status_id)!,
    isReversed,
  );
};
