import fuzzaldrinPlus from "fuzzaldrin-plus";

export interface Identifiable {
  id: number;
  url?: string;
}

export interface MentionUser extends Identifiable {
  first_name: string;
  last_name: string;
  email: string;
}

export interface MentionTask extends Identifiable {
  key: string;
  title: string;
}

export enum MentionTypes {
  Task = "task",
  User = "user",
}

export enum MentionTriggers {
  task = "#",
  user = "@",
}

export type IMentionItem = MentionUser | MentionTask;

export interface MentionItemData {
  type: string;
  key: string;
  item: IMentionItem;
}

export class MentionProvider {
  private itemsList: { [key: string]: MentionItemData[] | undefined } = {
    [MentionTypes.Task]: undefined,
    [MentionTypes.User]: undefined,
  };
  private itemsMap: { [key: string]: { [id: number]: IMentionItem } } = {
    [MentionTypes.Task]: {},
    [MentionTypes.User]: {},
  };

  public getTypeTrigger(type: MentionTypes) {
    return MentionTriggers[type];
  }

  public isActive(type: MentionTypes) {
    return this.itemsList[type] !== undefined;
  }

  public setItems(type: MentionTypes, values: IMentionItem[] | undefined) {
    if (values) {
      this.itemsList[type] = values.map((item) => ({
        type,
        item,
        key: this.getItemKey(type, item),
      }));
      this.itemsMap[type] = {};
      values.forEach((item) => (this.itemsMap[type][item.id] = item));
    } else {
      this.itemsList[type] = values;
    }
  }

  public getItem(type: string, id: number) {
    return this.itemsMap[type][id];
  }

  public getText(type: string, item: IMentionItem | undefined) {
    if (type === MentionTypes.Task) {
      return item
        ? `${(item as any).key} ${(item as any).title}`
        : "Unknown Request";
    }

    return item
      ? `${(item as any).first_name} ${(item as any).last_name}`
      : "Anonymous";
  }

  public getLabel(type: string, id: number) {
    const item = this.getItem(type, id);

    return this.getText(type, item);
  }

  private getMatchType(fullText: string) {
    return fullText[0] === MentionTriggers[MentionTypes.Task]
      ? MentionTypes.Task
      : MentionTypes.User;
  }

  public isMatchActive(fullText: string) {
    const type = this.getMatchType(fullText);
    return this.isActive(type);
  }

  public filterItems(query: string, fullText: string) {
    const type = this.getMatchType(fullText);
    const items = this.itemsList[type] || [];
    if (!query) {
      return items;
    }

    const scores: { [key: string]: number } = {};
    const filteredItems = items.filter((fItem) => {
      const content = this.getText(fItem.type, fItem.item);
      const score = fuzzaldrinPlus.score(content, query);
      scores[fItem.key] = score;

      return score > 0;
    });
    filteredItems.sort((mA, mB) => {
      return scores[mB.key] - scores[mA.key];
    });

    return filteredItems;
  }

  private getItemKey(type: string, item: Identifiable) {
    return `${type}-${item.id}`;
  }
}
