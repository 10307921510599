import { ActivityApiService } from "@drVue/api-service/client-dashboard/activity";
import { $notifyDanger } from "@drVue/common";

import type { State } from "./state";
import type { Action, RootState } from "./types";
import type { ActionContext, ActionTree } from "vuex";

type IContext = ActionContext<State, RootState>;

const api = new ActivityApiService();

export const actions: ActionTree<State, RootState> = {
  async list({ dispatch, commit }: IContext) {
    commit("setIsError", false);
    commit("setIsLoading", true);

    await api
      .list()
      .then((items: Action[]) => {
        commit("set", items);
      })
      .catch((error) => {
        commit("setIsError", true);
        $notifyDanger("Failed to load activity");
        throw error;
      })
      .finally(() => commit("setIsLoading", false));
  },
};
