import { convert } from "html-to-text";
import Pluralize from "pluralize";

/** utilities for processing/formatting text values */
export default {
  /**
   * Pluralize and singularize any word.
   * @param word        - string The word to pluralize
   * @param count       - number How many of the word exist
   * @param inclusive   - boolean Whether to prefix with the number (e.g. 3 ducks)
   *
   * @examples
      pluralize('test') //=> "tests"
      pluralize('test', 0) //=> "tests"
      pluralize('test', 1) //=> "test"
      pluralize('test', 5) //=> "tests"
      pluralize('test', 1, true) //=> "1 test"
      pluralize('test', 5, true) //=> "5 tests"
   */
  pluralize(word: string, count: number, inclusive = false): string {
    return Pluralize(word, count, inclusive);
  },

  getSingleLineFromHtml(html: string): string {
    return convert(html, {
      selectors: [
        { selector: "img", format: "skip" },
        { selector: "a", options: { ignoreHref: true } },
      ],
      wordwrap: 1,
      preserveNewlines: true,
    })
      .replace(/\n{2,}/g, "\n")
      .replaceAll("\n", " ");
  },
};
