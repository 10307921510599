<template>
  <DefaultViewFieldLayout :view-props="viewProps">
    <template #label>
      {{ viewProps.schema.label }}
    </template>
    <template #control>
      <DrTruncatedTextTooltip v-if="text" :content="text">
        {{ text }}
      </DrTruncatedTextTooltip>
      <div v-else>-</div>
    </template>
  </DefaultViewFieldLayout>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import { numberFormat } from "@app/vue/utils/number";
import DefaultViewFieldLayout from "./DefaultViewFieldLayout.vue";

import type { ViewFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";

interface Props {
  viewProps: ViewFieldProps;
}

const props = defineProps<Props>();

const text = computed(() => {
  if (typeof props.viewProps.entityValue !== "number") return "";

  return numberFormat(
    props.viewProps.entityValue,
    props.viewProps.schema.extra.symbol,
    props.viewProps.schema.extra.digit_grouping,
  );
});
</script>
