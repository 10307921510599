DocumentActivityModalController.$inject = [
  "$scope",
  "document",
  "DocumentsApiService",
];
export default function DocumentActivityModalController(
  $scope,
  document,
  DocumentsApiService,
) {
  var self = this;
  self.document = document;
  loadActivities();

  function loadActivities() {
    self.activitiesLoading = true;
    self.activitiesLoadError = false;
    DocumentsApiService.getDocumentActivity(document.id).then(
      function (resp) {
        self.activities = resp.data;
        self.activitiesLoading = false;
        self.activitiesLoadError = false;
      },
      function (errorResp) {
        self.activitiesLoading = false;
        self.activitiesLoadError = true;
      },
    );
  }
}
