<template>
  <div>
    <a href="" style="margin-right: 10px" data-action="edit">Edit</a>
    <a href="" data-action="delete">Delete</a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { PropType } from "vue";

export default defineComponent({
  name: "ActionsCell",
  props: {
    row: { required: true, type: Object as PropType<any> },
    table: { required: true, type: Object as PropType<any> },
  },
  computed: {
    type(): any {
      const t = this.row.type;
      return this.$store.state.room.tasksStatuses.TYPES_DEFAULT_LABEL[t];
    },
  },
});
</script>
