import { USER_DATA } from "@setups/data";

import type {
  ChangePasswordRequest,
  ProfileApiService,
} from "@drVue/api-service/client-dashboard/profile";
import type { UserAvatar, UserData, UserProfile } from "@setups/data";
import type { AxiosResponse } from "axios";

export interface AvatarResponse {
  avatar: UserAvatar;
}

export class ProfileService {
  private api: ProfileApiService;

  constructor(api: ProfileApiService) {
    this.api = api;
  }

  static updateUserData(
    r: AxiosResponse<UserData>,
    userData: UserData = USER_DATA,
  ) {
    userData.profile = { ...r.data.profile };

    // to make compatible with IOrgUser
    userData.avatar = { ...userData.profile.avatar };
    userData.initial = r.data.profile.initial;
    userData.name = r.data.name;

    return r;
  }

  static updateUserAvatar(avatar: UserAvatar, userData: UserData = USER_DATA) {
    userData.profile = {
      ...userData.profile,
      avatar: { ...avatar },
    };

    // to make compatible with IOrg User
    userData.avatar = { ...userData.profile.avatar };

    return avatar;
  }

  update(profile: Partial<UserProfile>) {
    return this.api.update(profile).then(ProfileService.updateUserData);
  }

  uploadAvatar(avatar: File) {
    return this.api.uploadAvatar(avatar).then(ProfileService.updateUserAvatar);
  }

  deleteAvatar() {
    return this.api.deleteAvatar().then(ProfileService.updateUserAvatar);
  }

  changePassword(request: ChangePasswordRequest) {
    return this.api.changePassword(request);
  }
}
