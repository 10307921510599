<template>
  <DrLayoutContent :class="$style.container">
    <template #nav><TasksNavBar /></template>

    <template #toolbar>
      <TasksToolbarFilters />
    </template>

    <template #toolbar-right>
      <TasksToolbarMenu
        view="timeline"
        :zoom="zoomLevel"
        @zoom-change="setZoomLevel"
        @scroll-to-now="onScrollToNow"
        @gantt-option-change="onGanttOptionChange"
        @create-request="() => openCreateRequestPanel()"
      />
    </template>

    <DhtmlxGantt
      ref="dhtmlxGanttRef"
      :auto-scheduling="ganttOptions.isGanttAutoSchedulingEnabled"
      :critical-path="ganttOptions.isGanttCriticalPathEnabled"
      :zoom-level="zoomLevel"
      @create-request="(payload) => openCreateRequestPanel(payload)"
    />

    <CreateRequestPanel ref="createRequestPanelRef" />
  </DrLayoutContent>
</template>

<script setup lang="ts">
import { defineAsyncComponent, reactive, ref } from "vue";
import { DrLayoutContent } from "@shared/ui/dr-layouts";

import { ZOOM } from "@setups/types";
import { insightTrack, TasksTimelineTrackingEvent } from "@app/insight";
import {
  getIsAutoSchedulingEnabled,
  getIsCriticalPathEnabled,
  getZoomLevel,
  saveIsAutoSchedulingEnabled,
  saveIsCriticalPathEnabled,
} from "@drVue/components/room/tasks/TaskTimelinePage/ganttState";
import CreateRequestPanel from "../TaskOverviewPage/CreateRequestPanel.vue";
import TasksNavBar from "../TaskOverviewPage/TasksNavBar.vue";
import TasksToolbarFilters from "../TaskOverviewPage/TasksToolbarFilters.vue";
import TasksToolbarMenu from "../TaskOverviewPage/TasksToolbarMenu.vue";

const DhtmlxGantt = defineAsyncComponent(() => import("./DhtmlxGantt.vue"));

import type { OpenParams } from "@drVue/components/room/tasks/TaskOverviewPage/CreateRequestPanel.vue";
import type { GanttOptionId } from "@drVue/components/room/tasks/TaskOverviewPage/types";
import type { ZoomLevel } from "@setups/types";

const dhtmlxGanttRef = ref<InstanceType<typeof DhtmlxGantt> | null>(null);
const createRequestPanelRef = ref<InstanceType<
  typeof CreateRequestPanel
> | null>(null);

const zoomLevel = ref<ZoomLevel>(getZoomLevel() ?? ZOOM.Months);
const setZoomLevel = (zoom: ZoomLevel) => {
  zoomLevel.value = zoom;
};

const ganttOptions = reactive<Record<GanttOptionId, boolean>>({
  isGanttAutoSchedulingEnabled: getIsAutoSchedulingEnabled(),
  isGanttCriticalPathEnabled: getIsCriticalPathEnabled(),
});
const onGanttOptionChange = (optionId: GanttOptionId, isEnabled: boolean) => {
  ganttOptions[optionId] = isEnabled;

  const state = isEnabled ? "enabled" : "disabled";
  switch (optionId) {
    case "isGanttAutoSchedulingEnabled": {
      saveIsAutoSchedulingEnabled(isEnabled);

      insightTrack("room.tasks.gantt.toggled_auto_scheduling", {
        state,
      });

      break;
    }
    case "isGanttCriticalPathEnabled": {
      saveIsCriticalPathEnabled(isEnabled);

      insightTrack("room.tasks.gantt.toggled_critical_path", {
        state,
      });

      break;
    }
  }
};

const onScrollToNow = () => {
  dhtmlxGanttRef.value?.scrollToNow();
  insightTrack(TasksTimelineTrackingEvent.TodayClicked);
};

const openCreateRequestPanel = (params?: OpenParams) => {
  createRequestPanelRef.value?.open(params);
};
</script>

<style module lang="scss">
@use "@app/styles/scss/values";

.container {
  height: calc(100vh - #{values.$header-height});
}
</style>
