<template>
  <ElForm :model="formModel" :rules="rules" label-position="top">
    <UploadAvatar ref="uploadAvatar" style="margin-bottom: 30px" />

    <hr />

    <ElFormItem prop="email" label="Email address">
      <ElInput v-model="formModel.email" disabled />
    </ElFormItem>

    <ElFormItem prop="first_name" label="First Name">
      <ElInput v-model="formModel.first_name" />
    </ElFormItem>

    <ElFormItem prop="last_name" label="Last Name">
      <ElInput v-model="formModel.last_name" />
    </ElFormItem>

    <ElFormItem prop="title" label="Title">
      <ElInput v-model="formModel.title" />
    </ElFormItem>

    <ElFormItem prop="company" label="Company">
      <ElInput v-model="formModel.company" />
    </ElFormItem>

    <ElFormItem prop="office_number" label="Phone Number">
      <ElInput v-model="formModel.office_number" />
    </ElFormItem>

    <ElFormItem>
      <div class="text-right">
        <ElButton type="primary" @click="save">Save changes</ElButton>
      </div>
    </ElFormItem>
  </ElForm>
</template>

<script lang="ts">
import { cloneDeep } from "lodash-es";
import { defineComponent } from "vue";

import { USER_DATA } from "@setups/data";
import { ProfileApiService } from "@drVue/api-service/client-dashboard/profile";
import { $notifyDanger, $notifySuccess } from "@drVue/common";
import { ProfileService } from "@drVue/components/client-dashboard/users/AccountSettings/ProfileService";
import UploadAvatar from "@drVue/components/client-dashboard/users/AccountSettings/UploadAvatar.vue";

import type { UserProfile } from "@setups/data";

const api = new ProfileApiService();

interface Data {
  profileService: ProfileService;
  formModel: UserProfile;
  rules: any;
}

export default defineComponent({
  name: "PersonalInfo",
  components: { UploadAvatar },
  data(): Data {
    return {
      profileService: new ProfileService(api),
      formModel: cloneDeep(USER_DATA.profile),
      rules: {
        email: [
          {
            required: true,
            trigger: "blur",
            message: "This field may not be blank.",
          },
          {
            max: 254,
            trigger: "blur",
            message: "Ensure this field has no more than 254 characters.",
          },
        ],
        first_name: [
          {
            required: true,
            trigger: "blur",
            message: "This field may not be blank.",
          },
          {
            max: 50,
            message: "Ensure this field has no more than 50 characters.",
          },
        ],
        last_name: [
          {
            required: true,
            trigger: "blur",
            message: "This field may not be blank.",
          },
          {
            max: 50,
            message: "Ensure this field has no more than 50 characters.",
          },
        ],
        title: [
          {
            required: true,
            trigger: "blur",
            message: "This field may not be blank.",
          },
          {
            max: 100,
            message: "Ensure this field has no more than 100 characters.",
          },
        ],
        company: [
          {
            required: true,
            trigger: "blur",
            message: "This field may not be blank.",
          },
          {
            max: 100,
            message: "Ensure this field has no more than 100 characters.",
          },
        ],
        office_number: [
          {
            required: true,
            trigger: "blur",
            message: "This field may not be blank.",
          },
          {
            min: 7,
            message: "Ensure this field has at least 7 characters.",
          },
          {
            pattern: /^[0-9-+\s()]+$/,
            message:
              'Please use digits, symbols "+", "-", round brackets and spaces.',
          },
          {
            max: 25,
            message: "Ensure this field has no more than 25 characters.",
          },
        ],
      },
    };
  },
  beforeMount() {
    this.formModel = cloneDeep(USER_DATA.profile);
  },
  methods: {
    save() {
      this.profileService.update(this.formModel).then(
        (r) => {
          // We don't need to cloneDeep here, but just to be sure...
          this.formModel = cloneDeep(r.data.profile);
          const uploadAvatar = this.$refs.uploadAvatar as InstanceType<
            typeof UploadAvatar
          >;
          uploadAvatar.setUserData(r.data);
          $notifySuccess("Your profile has been updated");
        },
        () => $notifyDanger("Failed to update your profile, please try again"),
      );
    },
  },
});
</script>
