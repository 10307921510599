import type { DocumentsState } from "./DocumentsState";
import type {
  Document,
  Folder,
} from "@drVue/store/modules/room/documents/DocumentsApiService";
import type { Dictionary } from "@drVue/types";
import type { MutationTree } from "vuex";

export interface SetStatePayload {
  rootFolder: Folder;

  folders: Dictionary<Folder>;
  files: Dictionary<Document>;
  foldersByUid: Dictionary<Folder>;
  filesByUid: Dictionary<Document>;

  foldersList: Folder[];
  filesList: Document[];
}

export const documentsMutations: MutationTree<DocumentsState> = {
  setState(state: DocumentsState, payload: SetStatePayload) {
    state.rootFolder = payload.rootFolder;

    state.folders = payload.folders;
    state.files = payload.files;
    state.foldersByUid = payload.foldersByUid;
    state.filesByUid = payload.filesByUid;

    state.foldersList = payload.foldersList;
    state.filesList = payload.filesList;
  },

  setIsLoading(state: DocumentsState, isLoading: boolean) {
    state.isLoading = isLoading;
  },

  setIsError(state: DocumentsState, isError: boolean) {
    state.isError = isError;
  },
  updateMaxNestedPathsLengths(state, lengths: Dictionary<number>) {
    state.folderMaxNestedPathsLengths = lengths;
  },
};
