<template>
  <div
    :class="{
      [$style.avatar]: true,
      [$style.xs]: size === SIZES.xs,
      [$style.sm]: size === SIZES.sm,
      [$style.lg]: size === SIZES.lg,
      [$style.xl]: size === SIZES.xl,
      [$style.xxl]: size === SIZES.xxl,
    }"
    :style="backgroundStyle"
  >
    <template v-if="!url">
      <span v-if="initials" :class="$style.initials">{{ initials }}</span>

      <DrIcon v-else name="user" :size="size" :class="$style.placeholder" />
    </template>
  </div>
</template>

<script setup lang="ts">
/**
 * The component displays the user's avatar in circle format.
 * The image by `url` is displayed in priority, if it exists.
 * Otherwise, the initials of the name are displayed.
 * If no username is specified, then a "placeholder" icon is displayed.
 */

import { computed } from "vue";

import { SIZES } from "../constants";
import DrIcon from "../dr-icon/DrIcon.vue";
import { getColorByString } from "../utils";

import type { Avatar } from "@shared/ui/dr-avatar/types";

interface Props {
  /**
   * Avatar image file's url.
   */
  url?: Avatar["url"];
  /**
   * Full user name like "Larry Taylor" (used for initials).
   */
  name?: Avatar["name"];
  /**
   * Unique identifier.
   * Used to set the background color (if not specified, then the name is used).
   */
  identifier?: Avatar["name"];
  /**
   * Avatar's size
   */
  size?: Avatar["size"];
}

const props = withDefaults(defineProps<Props>(), {
  url: "",
  name: "",
  identifier: "",
  size: SIZES.md,
});

const initials = computed(() => {
  if (!props.name) {
    return "";
  }

  const parts = props.name.split(" ");
  return `${parts[0].at(0)}${parts[1] ? parts[1].at(0) : ""}`;
});

const backgroundStyle = computed(() => {
  if (props.url) {
    return {
      backgroundImage: `url(${props.url})`,
    };
  }

  if (props.name) {
    return {
      backgroundColor: getColorByString(props.identifier || props.name),
    };
  }

  return {};
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography" as typo;

$xs: 14px;
$sm: 18px;
$md: 24px;
$lg: 32px;
$xl: 38px;
$xxl: 48px;
$xs_text: 5px;
$sm_text: 7px;
$md_text: 10px;
$lg_text: 12px;
$xl_text: 14px;
$xxl_text: 18px;

.avatar {
  --size: #{$md};
  --text-size: #{$md-text};

  position: relative;
  display: inline-grid;
  align-items: center;
  justify-content: center;
  height: var(--size);
  width: var(--size);
  border-radius: 50%;
  overflow: hidden;
  font-family: typo.$font-default-family;
  font-size: var(--text-size);
  background-color: colors.$pr-200;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.xs {
  --size: #{$xs};
  --text-size: #{$xs-text};
}
.sm {
  --size: #{$sm};
  --text-size: #{$sm-text};
}
.lg {
  --size: #{$lg};
  --text-size: #{$lg-text};
}
.xl {
  --size: #{$xl};
  --text-size: #{$xl-text};
}
.xxl {
  --size: #{$xxl};
  --text-size: #{$xxl-text};
}

.initials {
  text-transform: uppercase;
  font-size: inherit;
  line-height: 1;
  color: #fff;
}

.placeholder {
  color: colors.$pr-500;
  transform-origin: center center;
  transform: scale(0.7);
}
</style>
