<template>
  <div
    :class="{
      [$style.container]: true,
      [$style.container_isEditable]: !isReadOnly,
    }"
    @click="edit"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
interface Props {
  isReadOnly: boolean;
}

const props = defineProps<Props>();

interface Emits {
  (e: "edit"): void;
}

const emit = defineEmits<Emits>();

const edit = (e: MouseEvent) => {
  if (props.isReadOnly) {
    return; // Continue bubbling so the modal opens up
  }
  e.stopPropagation();
  emit("edit");
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.container {
  align-items: center;
  border-radius: 6px;
  border: 1px solid transparent;
  display: inline-flex;
  gap: 6px;
  height: 32px;
  padding: 2px 6px;
  user-select: none;
  width: 100%;
}

.container_isEditable {
  transition: border 0.2s ease-in-out;
  &:hover {
    border: 1px solid colors.$sc-400;
  }
}
</style>
