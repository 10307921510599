import { APP_SETTINGS, ROOM_DATA } from "@setups/data";

const firmAdminIntroVideo = {
  title: "Admin Onboarding",
  embedCode: `<iframe src="https://player.vimeo.com/video/842563904" frameborder="0" 
                        allowfullscreen webkitallowfullscreen msallowfullscreen></iframe>`,
  duration: "04:05 minutes",
  adminOnly: true,
  key: "fr-admin-onboarding",
};

const dealAdminIntroVideo = {
  title: "Admin Onboarding",
  embedCode: `<iframe src="https://player.vimeo.com/video/842938022" frameborder="0" 
                        allowfullscreen webkitallowfullscreen msallowfullscreen></iframe>`,
  duration: "05:03 minutes",
  adminOnly: true,
  key: "dr-admin-onboarding",
};

const dealroomUserIntroVideo = {
  title: "User Onboarding",
  embedCode: `<iframe src="https://player.vimeo.com/video/843906437" frameborder="0" 
                        allowfullscreen webkitallowfullscreen msallowfullscreen></iframe>`,
  duration: "02:50 minutes",
  adminOnly: false,
  key: "dr-user-onboarding",
};

const drVideos = [dealAdminIntroVideo, dealroomUserIntroVideo];
const frVideo = [firmAdminIntroVideo];

const isDealRoom = APP_SETTINGS.WEBSITE.IS_DEALROOM;

const isAdmin =
  !ROOM_DATA.userPermissions || ROOM_DATA.userPermissions.administrator;

function hasViewableVideos() {
  return getViewableVideosModels().length > 0;
}

function getViewableVideosModels() {
  let models = angular.copy(isDealRoom ? drVideos : frVideo);

  if (!isAdmin) models = models.filter((v: any) => !v.adminOnly);

  return models;
}

function getIntroVideoModel() {
  if (isAdmin) {
    return isDealRoom ? dealAdminIntroVideo : firmAdminIntroVideo;
  }

  return dealroomUserIntroVideo;
}

export default {
  hasViewableVideos,
  getViewableVideosModels,
  getIntroVideoModel,
};
