import { FieldItemTypes } from "@app/vue/api-service/client-dashboard/enums";
import { FieldSchemaType } from "./types";

import type { FieldSchemaTypeDefault, TypedFieldSchema } from "./types";
import type { FieldItem } from "@drVue/api-service/client-dashboard";

export const CUSTOM_FIELD_TO_SCHEMA_MAP: Record<
  FieldItemTypes,
  FieldSchemaTypeDefault
> = {
  [FieldItemTypes.Text]: FieldSchemaType.LinkifiedText,
  [FieldItemTypes.Number]: FieldSchemaType.Number,
  [FieldItemTypes.Date]: FieldSchemaType.Date,
  [FieldItemTypes.Select]: FieldSchemaType.Select,
  [FieldItemTypes.MultiSelect]: FieldSchemaType.MultiSelect,
};

export const mapCustomFieldToSchema = (
  fieldItem: FieldItem,
): TypedFieldSchema<any> => {
  return {
    label: fieldItem.label,
    prop: `custom_data.${fieldItem.key}`,
    type: CUSTOM_FIELD_TO_SCHEMA_MAP[fieldItem.field_type],
    extra: fieldItem.extra,
  };
};
