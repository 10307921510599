<template>
  <div>
    <ElForm
      ref="form"
      :model="ruleData"
      :rules="formRules"
      label-position="top"
      :disabled="redactionSessionId !== null"
      @submit.prevent
    >
      <ElFormItem label="Text" prop="text">
        <ElInput v-model="ruleData.text" placeholder="Type here to redact" />
      </ElFormItem>
      <ElFormItem>
        <ElButton
          v-if="!redactionSessionId"
          :loading="isPreviewLoading"
          type="primary"
          @click="onPreview"
        >
          Preview
        </ElButton>
      </ElFormItem>
    </ElForm>
    <ElForm v-if="redactionSessionId">
      <ElFormItem>
        <ElButton
          type="primary"
          :loading="isApplyProcessing"
          @click="onApplyRedaction"
        >
          Apply redaction
        </ElButton>
        <ElButton @click="onRedactionCancel">Cancel</ElButton>
      </ElFormItem>
    </ElForm>
  </div>
</template>

<script lang="ts">
import { cloneDeep } from "lodash-es";
import { defineComponent } from "vue";

import { PrizmRedactionApiService } from "@drVue/components/room/viewer/api";

import type {
  RedactionApply,
  RedactionPreview,
  RedactionRule,
} from "@drVue/components/room/viewer/api";
import type { ElForm } from "element-plus";
import type { PropType } from "vue";

interface Data {
  api: PrizmRedactionApiService;
  isPreviewLoading: boolean;
  isApplyProcessing: boolean;
  formRules: any;
  ruleData: RedactionRule;
  redactionSessionId: string | null;
}

export default defineComponent({
  name: "ViewerRedactionForm",
  props: {
    sessionId: { required: true, type: String as PropType<string> },
    documentId: { required: true, type: Number as PropType<number> },
    versionId: { required: true, type: Number as PropType<number> },
  },
  emits: ["previewGenerated", "redactionCancelled"],
  data(): Data {
    return {
      api: new PrizmRedactionApiService(),
      isPreviewLoading: false,
      isApplyProcessing: false,
      formRules: {
        text: [
          {
            required: true,
            message: "Please input text",
            trigger: "blur",
          },
        ],
      },
      ruleData: { text: "" },
      redactionSessionId: null,
    };
  },
  methods: {
    createPreview() {
      this.isPreviewLoading = true;

      const data: RedactionPreview = {
        document_version_id: this.versionId,
        original_session_id: this.sessionId,
        rule: cloneDeep(this.ruleData),
      };
      this.api
        .createPreview(this.documentId, data)
        .then((respData) => {
          this.redactionSessionId = respData.session_id;
          this.$emit("previewGenerated", this.redactionSessionId);
        })
        .finally(() => {
          this.isPreviewLoading = false;
        });
    },
    onApplyRedaction() {
      if (this.isApplyProcessing || !this.redactionSessionId) {
        return;
      }
      const data: RedactionApply = {
        document_version_id: this.versionId,
        redaction_preview_session_id: this.redactionSessionId,
      };
      this.api.applyRedaction(this.documentId, data).then((resp) => {
        // remove version query parameter to use updated version
        window.location.replace(window.location.pathname);
      });
    },
    onRedactionCancel() {
      this.redactionSessionId = null;
      this.$emit("redactionCancelled");
    },
    onPreview() {
      const form = this.$refs["form"] as typeof ElForm;
      form.validate((valid: boolean) => {
        if (valid) {
          this.createPreview();
        }
      });
    },
  },
});
</script>
