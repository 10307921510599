import { ROOM_DATA } from "@setups/data";
import { categoryUrl } from "@setups/room-urls";
import categories_categoryInformationHtml from "../../../templates/components/categories/category-information.html?raw";

export default function drCategoryInformation() {
  return {
    template: categories_categoryInformationHtml,
    bindings: {
      categoryId: "<",
      category: "<?",
      isLink: "<?",
      isHoverable: "<",
      isArchived: "<",
    },
    controller: [
      "$scope",
      "$state",
      "CategoriesService",
      function ($scope, $state, CategoriesService) {
        var $ctrl = this;
        $ctrl.Categories = CategoriesService;
        $ctrl.$onInit = function () {
          $ctrl.isLink = angular.isDefined($ctrl.isLink) ? $ctrl.isLink : true;

          if (angular.isUndefined($ctrl.category)) {
            var watch = $scope.$watch("$ctrl.Categories.loading", function () {
              if (!CategoriesService.isLoaded()) {
                return;
              }

              watch(); // unwatch

              $ctrl.category = CategoriesService.categories[$ctrl.categoryId];
              if (!$ctrl.category) return;

              setInfo();
            });
          } else {
            if ($ctrl.category.is_archived) {
              $ctrl.isLink = false;
              $ctrl.isHoverable = false;
            }
            setInfo();
          }
        };

        function setInfo() {
          $ctrl.link = $ctrl.isLink
            ? categoryUrl(ROOM_DATA.url, $ctrl.category.id)
            : "";
        }
      },
    ],
  };
}
