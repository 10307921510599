<template>
  <EditFieldContainerWithButtons
    :is-form-submitting="editProps.isFormSubmitting"
    @save="editProps.submitField"
    @discard="editProps.quitEditModeConfirm"
  >
    <DrDatepicker
      ref="pickerRef"
      type="datetime"
      clearable
      style="width: 100%"
      popper-class="dr-popper--inline-control"
      :validate-event="false"
      :placeholder="editProps.schema.placeholder"
      :name="editProps.schema.prop"
      :model-value="editProps.veeField.value"
      :readonly="editProps.schema.isReadOnly"
      format="MMM D, YYYY h:mm A"
      @update:model-value="editProps.veeField.onChange"
      @blur="editProps.veeField.onBlur"
    />
  </EditFieldContainerWithButtons>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { DrDatepicker } from "@shared/ui/dr-datepicker";

import EditFieldContainerWithButtons from "./EditFieldContainerWithButtons.vue";

import type { EditFieldProps } from "../types";
import type CommonPicker from "element-plus/lib/components/time-picker/src/common/picker.vue";

interface Props {
  editProps: EditFieldProps;
}
defineProps<Props>();

const pickerRef = ref<InstanceType<typeof CommonPicker> | null>(null);

onMounted(() => {
  pickerRef.value?.handleOpen();
  pickerRef.value?.focus();
});
</script>
