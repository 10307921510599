import { defineStore } from "pinia";
import { computed } from "vue";

import { generateList } from "@drVue/store/pinia/helpers/generators";
import { AiTemplatesApiService } from "./api";

import type { AiTemplate } from "@drVue/components/room/ai/types";

const api = new AiTemplatesApiService();

export const useAiTemplatesStore = defineStore("roomAiTemplates", () => {
  const {
    list,
    dict,
    load,
    create,
    remove,
    isLoading,
    isLoadError,
    applyChanges,
  } = generateList<AiTemplate, Pick<AiTemplate, "question">>({
    name: "Room ai templates",
    load: api.loadTemplates.bind(api),
    create: api.createTemplate.bind(api),
    remove: api.deleteTemplate.bind(api),
  });

  const dictByQuestion = computed(() => {
    return list.value.reduce<Record<string, AiTemplate>>((acc, template) => {
      acc[template.question] = template;
      return acc;
    }, {});
  });

  return {
    list,
    dict,
    dictByQuestion,
    isLoading,
    isLoadError,
    load,
    create,
    remove,
    applyChanges,
  };
});
