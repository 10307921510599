<template>
  <DrLayoutContentCentered>
    <AuthGeneral />
    <div v-for="provider in openIdProviders" :key="provider.id">
      <OpenIDIntegration :provider="provider" />
    </div>

    <div v-if="!!samlProvider">
      <SAMLIntegration />
    </div>
  </DrLayoutContentCentered>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DrLayoutContentCentered } from "@shared/ui/dr-layouts";

import { ORG_MEMBER_DATA } from "@setups/data";
import {
  OpenIDProviders,
  OpenIDProvidersService,
} from "@drVue/api-service/client-dashboard";
import DrStore from "@drVue/store";
import AuthGeneral from "./General";
import OpenIDIntegration from "./OpenIDIntegration";
import SAMLIntegration from "./SAMLIntegration";

import type { OpenIDProvider } from "@drVue/api-service/client-dashboard";

interface Data {
  store: any;
  providersService: any;
}

export default defineComponent({
  name: "SettingsAuth",
  components: {
    DrLayoutContentCentered,
    OpenIDIntegration,
    SAMLIntegration,
    AuthGeneral,
  },
  data(): Data {
    return {
      store: DrStore,
      providersService: new OpenIDProvidersService(),
    };
  },
  computed: {
    openIdProviders(): OpenIDProvider[] {
      return ORG_MEMBER_DATA.client.enabled_openid_providers
        .filter((p) => p !== OpenIDProviders.Saml)
        .map((p_id: OpenIDProviders) => this.providersService.getById(p_id));
    },
    samlProvider(): OpenIDProvider | undefined {
      if (
        !ORG_MEMBER_DATA.client.enabled_openid_providers.includes(
          OpenIDProviders.Saml,
        )
      )
        return undefined;
      return this.providersService.getById(OpenIDProviders.Saml);
    },
  },
});
</script>
