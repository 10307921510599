<template>
  <span
    class="vxe-cell--checkbox"
    :class="{
      'is--checked': params.checked,
      'is--disabled': params.disabled,
      'is--indeterminate':
        opts.indeterminateField && !params.checked
          ? params.row[opts.indeterminateField]
          : params.indeterminate,
    }"
    @click="emitClick"
  >
    <span
      v-if="params.visible"
      class="vxe-checkbox--icon"
      :class="
        params.indeterminate
          ? 'vxe-icon-checkbox-indeterminate-fill'
          : params.checked
            ? 'vxe-icon-checkbox-checked-fill'
            : 'vxe-icon-checkbox-unchecked'
      "
    />
  </span>
</template>

<script setup lang="ts" generic="T">
// This is an adopted version of renderCheckboxCellByProp() method of
// https://github.com/x-extends/vxe-table/blob/master/packages/table/src/cell.ts
// You probably don't want or need to change something here.

import type { CheckboxParams } from "./types";

interface Props {
  params: CheckboxParams;
}

const props = defineProps<Props>();

const opts = props.params.$table.getComputeMaps().computeCheckboxOpts;

const emitClick = (e: Event) => {
  if (!props.params.disabled && props.params.visible) {
    props.params.$table.triggerCheckRowEvent(
      e,
      props.params,
      !props.params.checked,
    );
  }
};
</script>
