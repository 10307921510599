import type { State } from "./state";
import type { RootState } from "@drVue/store/state";
import type { GetterTree } from "vuex";

export const getters: GetterTree<State, RootState> = {
  redirectPath:
    (state: State) =>
    (rootPath: string): string => {
      return state.tabsLastRoutes[rootPath];
    },
};
