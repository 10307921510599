/**
 * Here are useful functions (utilities) common to all "dr-common" components.
 */

import { LEGEND_COLORS } from "./constants";

export const simpleHash = (str: string): number => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }

  return parseInt(hash.toString(10));
};

export const getColorByString = (string: string) => {
  return LEGEND_COLORS.at(simpleHash(string) % LEGEND_COLORS.length) as string;
};
