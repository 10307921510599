import { defineStore } from "pinia";

import { YourWorkApiService } from "@drVue/api-service/client-dashboard/your_work";
import { generateList } from "@drVue/store/pinia/helpers/generators";

import type { UserRoomFinding } from "@drVue/api-service/client-dashboard/types";

export type { UserRoomFinding };

const api = new YourWorkApiService();

export const useYourWorkFindingsStore = defineStore(
  "yourWorkRoomFindings",
  () => {
    const { list, dict, load, isLoading, isLoadError } =
      generateList<UserRoomFinding>({
        name: "Your work findings",
        load: api.getFindings.bind(api),
      });

    return {
      list,
      dict,
      isLoading,
      isLoadError,
      load,
    };
  },
);
