<template>
  <DrPanelWrapper>
    <template #title>
      <ElButton size="small" :class="$style.closeBtn" @click="$emit('close')">
        <DrIcon name="cross" size="sm" />
      </ElButton>
      <span>Filters</span>
    </template>

    <template #title-action>
      <ElButton
        icon-right
        size="small"
        :disabled="!hasActiveFilter"
        :class="$style.textBtn"
        @click="clearFilters"
      >
        Reset all
        <template #icon>
          <DrIcon name="redo" size="sm" />
        </template>
      </ElButton>
    </template>

    <template #subtitle>
      <ElInput
        ref="searchFiltersFieldRef"
        v-model="searchFiltersText"
        placeholder="Search filters..."
      >
        <template #prefix>
          <DrIcon size="sm" name="search" />
        </template>
      </ElInput>
    </template>

    <DrFormWrapper>
      <ElFormItem v-if="shownFiltersMap.type" label="Type">
        <ElSelect v-model="typeModelValue" multiple clearable>
          <ElOption
            v-for="type in typeOptions"
            :key="type.id"
            :label="type.name"
            :value="type.id"
          >
            <div :class="$style.option">
              <DrIcon
                size="sm"
                name="exclamation-triangle"
                :style="{ color: type.color }"
              />
              <div :class="$style.text">{{ type.name }}</div>
            </div>
          </ElOption>
        </ElSelect>
      </ElFormItem>

      <ElFormItem v-if="shownFiltersMap.status" label="Status">
        <ElSelect v-model="statusModelValue" multiple clearable>
          <ElOption
            v-for="status in statusOptions"
            :key="status.id"
            :label="status.name"
            :value="status.id"
          >
            <div :class="$style.option">
              <DrIconStatus :color="status.color" />
              <div :class="$style.text">{{ status.name }}</div>
            </div>
          </ElOption>
        </ElSelect>
      </ElFormItem>

      <ElFormItem v-if="shownFiltersMap.severity" label="Severity">
        <ElSelect v-model="severityModelValue" multiple clearable>
          <ElOption
            v-for="severity in severityOptions"
            :key="severity.id"
            :label="severity.name"
            :value="severity.id"
          />
        </ElSelect>
      </ElFormItem>

      <ElFormItem v-if="shownFiltersMap.likelihood" label="Likelihood">
        <ElSelect v-model="likelihoodModelValue" multiple clearable>
          <ElOption
            v-for="likelihood in likelihoodOptions"
            :key="likelihood.id"
            :label="likelihood.name"
            :value="likelihood.id"
          />
        </ElSelect>
      </ElFormItem>

      <ElFormItem v-if="shownFiltersMap.assignee" label="Assignee">
        <UsersTreeSelect
          v-model="assigneeModelValue"
          :is-loaded="isMembersAndGroupsLoaded"
          :members-list="membersList"
          :groups-list="groupsList"
          :get-users-of-group="getUsersOfGroup"
        />
      </ElFormItem>

      <ElFormItem
        v-for="field in filteredCustomFields"
        :key="field.id"
        :label="field.label"
      >
        <DrDatepicker
          v-if="field.field_type === FieldItemTypes.Date"
          type="daterange"
          start-placeholder="From"
          end-placeholder="To"
          align="center"
          :model-value="getCustomFieldValue(field.key)"
          @update:model-value="
            (value: any) => setCustomFieldValue(field.key, value, 'RANGE')
          "
        />

        <ElSelect
          v-else-if="
            field.field_type === FieldItemTypes.Select ||
            field.field_type === FieldItemTypes.MultiSelect
          "
          clearable
          multiple
          placeholder="Select"
          class="el-select--full-width"
          :model-value="getCustomFieldValue(field.key)"
          @change="
            (value: any) =>
              setCustomFieldValue(
                field.key,
                value,
                field.field_type === FieldItemTypes.Select ? 'ANY' : 'ALL',
              )
          "
        >
          <template v-if="'options' in field.extra">
            <ElTooltip
              v-for="item in field.extra.options"
              :key="item"
              :content="item"
              :show-after="400"
            >
              <ElOption
                :class="$style.dropdownItem"
                :label="item"
                :value="item"
              />
            </ElTooltip>
          </template>
        </ElSelect>

        <ElInput
          v-else-if="field.field_type === FieldItemTypes.Number"
          clearable
          placeholder="Type numeric value"
          type="number"
          :model-value="getCustomFieldValue(field.key) as number"
          @input="(value) => setCustomFieldValue(field.key, value, 'EQ')"
          @wheel.prevent
        />

        <ElAutocomplete
          v-else
          clearable
          placeholder="Type to search..."
          class="el-autocomplete--full-width"
          :label="field.key"
          :fetch-suggestions="
            (query, cb) => getTextFieldAutocomplete(query, cb, field.key)
          "
          :trigger-on-focus="false"
          :debounce="0"
          :model-value="getCustomFieldValue(field.key) as string"
          @update:model-value="
            (value) => setCustomFieldValue(field.key, value, 'CONTAINS')
          "
        >
          <template #default="{ item }">
            <DrTruncatedTextTooltip :content="item.value">
              <div :class="$style.dropdownItem">
                {{ item.value }}
              </div>
            </DrTruncatedTextTooltip>
          </template>
        </ElAutocomplete>
      </ElFormItem>
    </DrFormWrapper>
  </DrPanelWrapper>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import { DrDatepicker } from "@shared/ui/dr-datepicker";
import { DrFormWrapper } from "@shared/ui/dr-form";
import { DrIcon } from "@shared/ui/dr-icon";
import DrIconStatus from "@shared/ui/dr-icon-status";
import { DrPanelWrapper } from "@shared/ui/dr-panels";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import { FieldItemTypes } from "@drVue/api-service/client-dashboard";
import {
  FindingLikelihoodList,
  FindingSeverityList,
} from "@drVue/api-service/modules/findings/types";
import UsersTreeSelect from "@drVue/components/room/UsersTreeSelect.vue";
import DrStore from "@drVue/store";
import { pinia } from "@drVue/store/pinia";
import { useFindingsStatusesStore } from "@drVue/store/pinia/pipeline/findings-statuses";
import { useFindingsTypesStore } from "@drVue/store/pinia/pipeline/findings-types";

import type {
  FindingsCustomDataFiltersModel,
  FindingsCustomDataFiltersUpdate,
  FindingsToolbarFiltersModel,
  FindingsToolbarFiltersUpdate,
} from "./types";
import type { CustomDataType } from "@app/ng/tasks/services/ts/types/Filters";
import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type {
  FindingLikelihoodKey,
  FindingSeverityKey,
} from "@drVue/api-service/modules/findings/types";
import type { RoomGroup } from "@drVue/store/modules/client-dashboard/deals/types";
import type { RoomMember } from "@drVue/store/modules/room/members/RoomMembersApiService";

interface Props {
  filters: FindingsToolbarFiltersModel;
  customDataFilters: FindingsCustomDataFiltersModel;
  customFields: FieldItem[];
  hasActiveFilter: boolean;
  getTextFieldAutocomplete: (
    query: string,
    cb: (results: Record<string, any>[]) => void,
    fieldKey: string,
  ) => void;
}

interface Emits {
  (event: "update:filter", payload: FindingsToolbarFiltersUpdate): void;
  (
    event: "update:custom-data-filter",
    payload: FindingsCustomDataFiltersUpdate,
  ): void;
  (event: "close"): void;
  (event: "clear"): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const findingsStatusesStore = useFindingsStatusesStore(pinia);
const findingsTypesStore = useFindingsTypesStore(pinia);

const searchFiltersText = ref("");
const searchFiltersFieldRef = ref<HTMLInputElement | null>(null);

const shownFiltersMap = computed(() => {
  const search = searchFiltersText.value.trim().toLowerCase();
  const skip = !search;

  return {
    type: skip || "type".includes(search),
    severity: skip || "severity".includes(search),
    likelihood: skip || "likelihood".includes(search),
    status: skip || "status".includes(search),
    assignee: skip || "assignee".includes(search),
  };
});

const clearFilters = () => {
  emit("clear");
};

const typeOptions = computed(() => findingsTypesStore.list);
const typeModelValue = computed({
  get() {
    return props.filters.type_id;
  },
  set(checkedIds: string[]) {
    emit("update:filter", {
      field: "type_id",
      value: checkedIds,
    });
  },
});

const statusOptions = computed(() => findingsStatusesStore.list);
const statusModelValue = computed({
  get() {
    return props.filters.status_id;
  },
  set(checkedIds: string[]) {
    emit("update:filter", {
      field: "status_id",
      value: checkedIds,
    });
  },
});

const severityOptions = computed(() => {
  return FindingSeverityList.map((severity) => ({
    id: severity.value,
    name: severity.label,
  }));
});
const severityModelValue = computed({
  get() {
    return props.filters.severity;
  },
  set(checkedIds: FindingSeverityKey[]) {
    emit("update:filter", {
      field: "severity",
      value: checkedIds,
    });
  },
});

const likelihoodOptions = computed(() => {
  return FindingLikelihoodList.map((likelihood) => ({
    id: likelihood.value,
    name: likelihood.label,
  }));
});
const likelihoodModelValue = computed({
  get() {
    return props.filters.likelihood;
  },
  set(checkedIds: FindingLikelihoodKey[]) {
    emit("update:filter", {
      field: "likelihood",
      value: checkedIds,
    });
  },
});

const membersList = computed(() => DrStore.state.room.members.membersList);
const groupsList = computed(() => DrStore.state.room.groups.pgroupsList);

const isMembersAndGroupsLoaded = computed(() => {
  const m = DrStore.state.room.members;
  const g = DrStore.state.room.groups;

  return (
    m.isLoading === false &&
    m.isError === false &&
    g.isLoading === false &&
    g.isError === false
  );
});

/**
 * In "UsersTreeSelect" used "id" as identification, so we transform it.
 */
const assigneeModelValue = computed({
  get() {
    return props.filters.assignees.map(
      (uid) => DrStore.state.room.members.membersByUid[uid].id,
    );
  },
  set(checkedIds: RoomMember["id"][]) {
    emit("update:filter", {
      field: "assignees",
      value: checkedIds.map((id) => DrStore.state.room.members.members[id].uid),
    });
  },
});

const getUsersOfGroup = (group: RoomGroup) => {
  return membersList.value.reduce((acc: RoomMember[], m: RoomMember) => {
    if (!m.pending && m.pgroup.id === group.id) acc.push(m);

    return acc;
  }, []);
};

const filteredCustomFields = computed(() => {
  const search = searchFiltersText.value.trim().toLowerCase();

  if (!search) return props.customFields;

  return props.customFields.filter((field) =>
    field.label.toLowerCase().includes(search),
  );
});

const getCustomFieldValue = (fieldId: string) => {
  return props.customDataFilters[fieldId]?.value;
};

const setCustomFieldValue = (
  fieldId: string,
  value: any,
  op: CustomDataType["op"] = "EQ",
) => {
  emit("update:custom-data-filter", {
    field: fieldId,
    value: {
      value,
      op,
    },
  });
};

onMounted(() => {
  /**
   * It is required to use this timeout:
   * 1) in parent FindingsOverviewContent we use DrLayoutContent
   *    and render this component in the "aside" slot;
   * 2) since with the "destroy-aside-on-close" option enabled,
   *    the contents of the slot are mounted immediately
   *    at the moment the "show-aside" property is set
   *    (i.e. even before the panel has completely moved out);
   * 3) then setting the focus to a field that is still out of view
   *    creates some kind of "glitch" effect
   *    (the appearance of the panel is jerky and even moves the table over the left edge).
   *
   * 4) Therefore, when we set the focus after the full appearance of the "aside",
   *    then everything is fine.
   *
   * @note aside panel transition duration = 300ms.
   */
  setTimeout(() => {
    searchFiltersFieldRef.value?.focus();
  }, 350);
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;

.textBtn:global(.el-button) {
  --el-button-bg-color: transparent;
  --el-button-border-color: transparent;
  --el-button-disabled-bg-color: transparent;
  --el-button-disabled-border-color: transparent;
}

.closeBtn:global(.el-button) {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border-radius: 6px;
}

.option {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: spacing.$xs;
  overflow: hidden;
  font: typo.$body_regular;
}

.text {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.optionItem_isSelected {
  font: typo.$body_medium;
  color: colors.$sc-600;
}
</style>
