import VERBS from "./verbs";

angular
  .module("dealroom.analytics.service.labels", [
    "dealroom.documents",
    "dealroom.task",
  ])
  .service("ActivityLabelService", ActivityLabelService);

ActivityLabelService.$inject = [
  "RoomConfig",
  "DocumentsService",
  "TasksService",
  "CategoriesService",
];
function ActivityLabelService(
  RoomConfig,
  DocumentsService,
  TasksService,
  CategoriesService,
) {
  const roomHasTasks = RoomConfig.userPermissions.viewTasks;

  const service = {
    label: getActivityLabel,
    item: getActivityItem,
    itemId: getActivityItemId,
  };

  return service;

  function getActivityParams({ verb }) {
    // -> {label, item_src}
    const params = VERBS[verb] || {};
    if (params.tasksDepended && !roomHasTasks) return {};
    return params;
  }

  function getActivityLabel(d) {
    const { label = null } = getActivityParams(d);
    return label;
  }

  function getActivityItem(d) {
    // -> {item id, item type, is_archived, is_removed}
    const { item_src } = getActivityParams(d);
    if (!item_src) return null;
    // item_src :> 'target' | 'object' | null
    const item = angular.copy(d[item_src]);
    if (!item) return null;

    const instance = getInstance(item);
    item.is_archived = instance.is_archived;
    item.is_removed = instance.is_removed;

    return item;
  }

  function getActivityItemId(d, expectedType) {
    const item = getActivityItem(d);
    if (!item) return null;
    if (expectedType && item.type !== expectedType) return null;
    return item.id;
  }

  function getInstance({ type, id }) {
    const archivedInstance = { is_removed: true, is_archived: true };
    const service = getService(type);
    if (!service) return archivedInstance;
    return service(id) || archivedInstance;

    function getService(type) {
      switch (type) {
        case "document":
          return (id) => DocumentsService.Files[id];
        case "folder":
          return (id) => DocumentsService.Folders[id];
        case "task":
          return (id) => TasksService.tasks[id];
        case "category":
          return (id) => CategoriesService.categories[id];
      }
    }
  }
}
