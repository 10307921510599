import { ApiService } from "@drVue/api-service";
import { $notifyDanger } from "@drVue/common";

export interface RedactionRule {
  text: string;
}

export interface RedactionResp {
  session_id: string;
}

export interface RedactionPreview {
  document_version_id: number;
  original_session_id: string;
  rule: RedactionRule;
}

export interface RedactionApply {
  document_version_id: number;
  redaction_preview_session_id: string;
}

export class PrizmRedactionApiService extends ApiService {
  createPreview(documentId: number, data: RedactionPreview) {
    const url =
      this.Urls["api:room:document_detail_prizm_redaction_preview"](documentId);
    return this.post<RedactionResp, RedactionPreview>(url, data).catch(
      (error) => {
        $notifyDanger("Failed to create redaction preview.");
        return Promise.reject(error);
      },
    );
  }

  applyRedaction(documentId: number, data: RedactionApply) {
    const url =
      this.Urls["api:room:document_detail_prizm_redaction_apply"](documentId);
    return this.post(url, data).catch((error) => {
      $notifyDanger("Failed to apply redaction.");
      return Promise.reject(error);
    });
  }
}
