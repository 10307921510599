import exportHtml from "./index.html?raw";

ExportDropdownController.$inject = [
  "$element",
  "AnalyticsDataDispatcher",
  "AnalyticsActivityDataService",
  "AnalyticsExportExcelService",
];
angular
  .module("dealroom.analytics.dropdown.export", [
    "dealroom.analytics.service.export",
  ])
  .component("drAnalyticsExportDropdown", {
    bindings: { excel: "@" },
    template: exportHtml,
    controller: ExportDropdownController,
  });

function ExportDropdownController(
  $element,
  AnalyticsDataDispatcher,
  AnalyticsActivityDataService,
  AnalyticsExportExcelService,
) {
  const $ctrl = this;
  $ctrl.exportToExcel = function () {
    AnalyticsExportExcelService.exportToExcel($ctrl.excel);
  };
  $ctrl.$onInit = function () {
    $ctrl.isLoaded = AnalyticsActivityDataService.isLoaded;
    AnalyticsDataDispatcher.on(
      "batchIsLoaded",
      (batch, isLast) => ($ctrl.isLoaded = isLast),
    );
  };
}
