import columnHtml from "./index.html?raw";

angular
  .module("dealroom.analytics.linechart.legend.column", [])
  .directive("drAnalyticsLinechartLegendColumn", () => ({
    scope: {
      d: "<drAnalyticsLinechartLegendColumn",
    },
    template: columnHtml,
  }));
