StatusesDropdownController.$inject = [
  "$element",
  "AnalyticsDropdownPromisedItemsService",
  "ActivityVisibleCategoriesService",
];
import DrStore from "@drVue/store";

angular
  .module("dealroom.analytics.dropdown.statuses", [
    "dealroom.analytics.dropdown",

    "dealroom.analytics.service.data",

    "dealroom.task",
  ])
  .component("drAnalyticsStatusesDropdown", {
    controller: StatusesDropdownController,
  });

function StatusesDropdownController(
  $element,
  AnalyticsDropdownPromisedItemsService,
  ActivityVisibleCategoriesService,
) {
  const promise =
    ActivityVisibleCategoriesService.loadingPromise.then(createScope);
  AnalyticsDropdownPromisedItemsService.render(promise);

  function createScope() {
    const ctrl = {
      label: "Statuses",
      uid: "statuses",
      multiselect: true,
      items: getItems(),
      handleSelect,
    };
    return { ctrl, $element };

    function handleSelect(selected = []) {
      ActivityVisibleCategoriesService.setStatuses(selected.map((s) => s.id));
    }

    function getItems() {
      return DrStore.state.room.tasksStatuses.list.map((s) => {
        s = angular.copy(s);
        s.label = s.name;
        return s;
      });
    }
  }
}
