<template>
  <DrDrawer
    size="large"
    title=""
    header-white
    :shown="shown"
    :custom-class="$style.drawer"
    @close="close"
  >
    <template #title>
      <div :class="$style.synergy">
        {{ synergy?.title || "" }}
      </div>
    </template>

    <template #title-right>
      <DrIcon
        v-if="isAbleToEditSynergyOrValueDriver"
        :class="$style.trash"
        name="trash"
        size="sm"
        @click="handleDelete"
      />
    </template>

    <template #description>
      <hr />
      <div :class="$style.title">
        <DynamicForm
          :entity="valueDriver"
          :schema="valueDriverTitleSchema"
          :submit-fn="onSubmit"
        />
      </div>
    </template>

    <template #default>
      <TaskList
        title="Enabled by"
        :view-only="!isAbleToEditSynergyOrValueDriver"
        :task-ids="enabledByTasksIds"
        allow-change
        allow-remove
        @change="handleChange"
        @remove="handleRemove"
        @click="handleClick"
      />
    </template>

    <template #footer>
      <div />
    </template>
  </DrDrawer>
</template>

<script lang="ts" setup>
import { ElMessageBox } from "element-plus";
import { differenceBy } from "lodash-es";
import { computed, ref } from "vue";
import { DrDrawer } from "@shared/ui/dr-drawer";
import { validateString } from "@shared/ui/dr-dynamic-form/utils";
import { DrIcon } from "@shared/ui/dr-icon";

import { ROOM_MEMBER_DATA } from "@setups/data";
import { TaskFieldAccessType } from "@setups/enums";
import { taskUrl } from "@setups/room-urls";
import { insightTrack, RoomSynergiesEvent } from "@app/insight";
import { ROOM_DATA } from "@app/setups";
import { $notifyDanger, $notifySuccess } from "@drVue/common";
import DynamicForm from "@drVue/components/client-dashboard/dynamic-form/DynamicForm.vue";
import EditTextField from "@drVue/components/client-dashboard/dynamic-form/Fields/Edit/Text.vue";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import { TitleView } from "@drVue/components/room/tasks/TaskDetails/fields/title";
import TaskList from "@drVue/components/room/tasks/TaskDetails/lists/tasks/TaskList.vue";
import { useSynergiesStore } from "@drVue/store/modules/room/synergies/synergies";
import { useValueDriversStore } from "@drVue/store/modules/room/synergies/value-drivers";
import { pinia } from "@drVue/store/pinia";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";

import type { FormSchema } from "@drVue/components/client-dashboard/dynamic-form/types";
import type { ValueDriver } from "@drVue/store/modules/room/synergies/SynergiesApiService";
import type { Task } from "@drVue/store/pinia/room/tasks";

const shown = ref(false);
const currentValueDriverId = ref<ValueDriver["id"] | null>(null);

const tasksStore = useTasksStore(pinia);
const synergiesStore = useSynergiesStore(pinia);
const valueDriversStore = useValueDriversStore(pinia);

const isAbleToEditSynergyOrValueDriver = computed(
  () => ROOM_MEMBER_DATA.group.synergies_access === TaskFieldAccessType.Edit,
);

const valueDriver = computed(() => {
  if (!currentValueDriverId.value) return null;

  return valueDriversStore.dict[currentValueDriverId.value];
});

const synergy = computed(() => {
  if (!valueDriver.value) return null;

  return synergiesStore.dict[valueDriver.value.synergy_id];
});

const enabledByTasks = computed<Task[]>(() => {
  if (!valueDriver.value) return [];
  return valueDriver.value.tasks.map((t) => tasksStore.tasksByUid[t.task_uid]);
});

const enabledByTasksIds = computed<Task["id"][]>(() => {
  if (!valueDriver.value) return [];
  return enabledByTasks.value.map((t) => t.id);
});

const handleChange = (payload: Task["uid"][]) => {
  // TODO: Remove this unnecessary conversion between `uid` and `id`.
  const toAdd = payload.map((uid) => tasksStore.tasksByUid[uid]);
  const toRemove = differenceBy(enabledByTasks.value, toAdd, "uid");

  valueDriversStore
    .updateValueDriverTasks(
      currentValueDriverId.value!,
      toAdd.map(({ uid: task_uid }) => ({ task_uid })),
      toRemove.map(({ uid: task_uid }) => ({ task_uid })),
    )
    .then(() => {
      insightTrack(RoomSynergiesEvent.EnabledByUpdated, {
        added_count: toAdd.length.toString(),
        removed_count: toRemove.length.toString(),
      });
    });
};

const handleRemove = (task: Task) => {
  valueDriversStore
    .updateValueDriverTasks(
      currentValueDriverId.value!,
      [],
      [{ task_uid: task.uid }],
    )
    .then(() => {
      insightTrack(RoomSynergiesEvent.EnabledByRemoved);
    });
};

const handleClick = (task: Task) => {
  window.open(taskUrl(ROOM_DATA.url, task.key), "_blank");
};

const handleDelete = () => {
  if (!valueDriver.value) return;

  const driverId = valueDriver.value.id;
  const driverTitle = valueDriver.value.title;

  ElMessageBox({
    type: "warning",
    title: "Delete Value Driver",
    message: `Are you sure you want to delete "${driverTitle}" value driver? Caution, this action cannot be undone.`,
    confirmButtonText: "Delete",
    confirmButtonClass: "el-button--warning",
    showCancelButton: true,
    showClose: true,
    cancelButtonText: "No",
    closeOnHashChange: true,
    closeOnPressEscape: true,
  }).then(() => {
    close();

    valueDriversStore.remove(driverId).then(
      () => {
        $notifySuccess("Value driver has been deleted");
        insightTrack(RoomSynergiesEvent.ValueDriverDeleted);
      },
      () => $notifyDanger("Failed to delete value driver"),
    );
  });
};

const open = (valueDriverId: ValueDriver["id"]) => {
  shown.value = true;
  currentValueDriverId.value = valueDriverId;
};

const close = () => {
  shown.value = false;
  currentValueDriverId.value = null;
};

const onSubmit = (value: Partial<ValueDriver>) => {
  if (!currentValueDriverId.value) return Promise.reject();
  return valueDriversStore
    .update(currentValueDriverId.value, value)
    .then((r) => {
      insightTrack(RoomSynergiesEvent.ValueDriverRenamed);
      return r;
    });
};

const valueDriverTitleSchema = computed(
  (): FormSchema => ({
    fields: [
      {
        type: FieldSchemaType.Custom,
        prop: "title",
        placeholder: "Specify title",
        rules: validateString()
          .required(`"Title" is required`)
          .min(8, `"Title" must be at least 8 characters`),
        viewComponent: TitleView,
        editComponent: EditTextField,
        isReadOnly: !isAbleToEditSynergyOrValueDriver.value,
      },
    ],
  }),
);

defineExpose({
  open,
  close,
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.drawer {
  width: 756px !important;
}

.title {
  margin-bottom: -10px;
}

.synergy {
  font: typography.$body_regular;
}

.trash {
  color: colors.$pr-400;

  &:hover {
    cursor: pointer;
    color: colors.$pr-600;
  }
}
</style>
