<template>
  <div
    :class="{
      [$style.item]: true,
      [$style.item_isEditing]: isEditing,
    }"
  >
    <template v-if="!isEditing">
      <i
        v-if="batch.type === 'file'"
        class="mimetypes"
        :class="batch.iconClass"
      />
      <DrIcon v-else-if="batch.type === 'folder'" name="folder" />
    </template>

    <span
      :class="{
        [$style.name]: true,
        [$style.name_isEditing]: isEditing,
      }"
    >
      <DynamicField
        mode="table"
        :schema="schema"
        :entity="batch"
        :submit-fn="onSubmit"
        :class="$style.fullWidth"
        @request-edit="(grant) => grant()"
        @toggle-edit="toggleEdit"
      />
      <DrPopup
        ref="popupRef"
        :width="400"
        v-if="!isEditing && duplicate"
        title="Potential duplicate"
      >
        <template #reference>
          <div :class="$style.dupLink">Duplicate found</div>
        </template>

        Potential duplicate of this file found in the data room:

        <a
          :href="documentViewUrl(ROOM_DATA.url, duplicate.id)"
          target="_blank"
          >{{ duplicatePath }}</a
        >

        <div>How do you want to proceed?</div>

        <div :class="$style.dupActions">
          <ElButton size="small" @click="closePopup">Upload anyway</ElButton>
          <ElButton
            type="primary"
            size="small"
            @click="() => onBatchAction(batch)"
            >{{ getDuplicatePrimaryButtonText(batch, duplicate) }}</ElButton
          >
        </div>
      </DrPopup>
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed, inject, ref } from "vue";
import DrIcon from "@shared/ui/dr-icon";

import { ROOM_DATA } from "@app/setups";
import { documentViewUrl } from "@app/setups/room-urls";
import {
  type Document,
  type Folder,
} from "@app/vue/store/modules/room/documents/DocumentsApiService";
import DynamicField from "@drVue/components/client-dashboard/dynamic-form/DynamicField.vue";
import FilenameEdit from "@drVue/components/client-dashboard/dynamic-form/Fields/Edit/Filename.vue";
import TextView from "@drVue/components/client-dashboard/dynamic-form/Fields/View/Text.vue";
import {
  type FieldSchema,
  FieldSchemaType,
} from "@drVue/components/client-dashboard/dynamic-form/types";
import DrPopup from "../dr-popups/DrPopup.vue";
import { type Batch, UploadListBatchInjectionKey } from "./types";

interface Props {
  batch: Batch;
  duplicate: Document | undefined;
}

const props = defineProps<Props>();

const popupRef = ref<InstanceType<typeof DrPopup> | null>(null);

const { getDuplicatePrimaryButtonText, onBatchAction, onBatchRenamed } = inject(
  UploadListBatchInjectionKey,
  {
    getDuplicatePrimaryButtonText: (
      batch: Batch,
      duplicate: Document | undefined,
    ) => "",
    onBatchAction: computed(() => (batch: Batch) => {}),
    onBatchRenamed: computed(() => (batch: Batch) => {}),
  },
);

const isEditing = ref(false);

const schema: FieldSchema = {
  type: FieldSchemaType.Custom,
  prop: "name",
  viewComponent: TextView,
  editComponent: FilenameEdit,
};

const duplicatePath = computed(() => {
  if (!props.duplicate) return "";

  let path = props.duplicate.name;
  let cursor: Folder | undefined = props.duplicate.parent;
  while (cursor) {
    path = `${cursor.name}/${path}`;
    cursor = cursor.parent;
  }

  return path;
});

const closePopup = () => {
  popupRef.value?.hide();
};

const onSubmit = (updates: any) => {
  onBatchRenamed.value({ ...props.batch, ...updates });

  return Promise.resolve();
};

const toggleEdit = (prop: string | null) => {
  isEditing.value = prop !== null;
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

.item {
  border: 1px solid colors.$pr-300;
  border-bottom: none;
  display: flex;
  align-items: center;
  padding-left: 10px;
  min-height: 44px;
}

.item_isEditing {
  padding: 6px 8px 2px;
  background-color: colors.$pr-50;
}

.item:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.item:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom: 1px solid colors.$pr-300;
}

.fullWidth {
  width: 100%;
}

.name {
  width: 100%;
  margin-left: 8px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.name_isEditing {
  margin-left: 0;
}

.dupLink {
  color: colors.$pr-500;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-style: dotted;
  flex-shrink: 0;
  margin: 0 10px;
}

.dupActions {
  margin-top: 10px;
  text-align: right;
}
</style>
