<template>
  <TextViewContent :value="value" @click.stop="enterEditMode" />
</template>

<script setup lang="ts">
import { computed } from "vue";

import TextViewContent from "./TextViewContent.vue";

import type { ViewFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";

interface Props {
  viewProps: ViewFieldProps;
}

const props = defineProps<Props>();

const value = computed(() => props.viewProps.entityValue?.toString() ?? "-");

const enterEditMode = (e: MouseEvent) => {
  if (e.target && e.target instanceof HTMLAnchorElement) return;

  props.viewProps.enterEditMode();
};
</script>
