<template>
  <div
    class="client-settings-panel"
    :class="{ 'client-settings-panel-no-drag': editingItem }"
  >
    <div v-if="!editingItem">
      <span
        v-if="isDraggable"
        class="pull-left client-settings-panel__drag-icon"
      >
        <DrIcon size="sm" name="grip-vertical" />
      </span>

      <slot name="content" />
      <span class="pull-right client-settings-panel__menu">
        <a class="cursor-pointer" @click="showEdit()"> Edit </a>
      </span>
    </div>
    <slot v-if="editingItem" name="editForm" :close-handler="cancelEdit" />
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { DrIcon } from "@shared/ui/dr-icon";

interface Props {
  isDraggable?: boolean;
}

withDefaults(defineProps<Props>(), {
  isDraggable: false,
});

const editingItem = ref(false);

const showEdit = () => {
  editingItem.value = true;
};
const cancelEdit = () => {
  editingItem.value = false;
};
</script>
