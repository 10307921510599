AnalyticsTasksAssigneeDistribution.$inject = [
  "$element",
  "AnalyticsTemplatesFactory",
];
import * as d3 from "d3";

angular
  .module("dealroom.analytics.assignees-distribution", [
    "dealroom.analytics.factory.templates",
  ])
  .component("drAnalyticsTasksAssigneeDistribution", {
    bindings: { dispatcher: "<" },
    controller: AnalyticsTasksAssigneeDistribution,
  });

const CLASSES = {
  root: "assignees-distribution",
  name: "assignees-distribution__name",
  bar: "assignees-distribution__bar",
};

const SELECTORS = Object.keys(CLASSES).reduce((b, n) => {
  b[n] = "." + CLASSES[n];
  return b;
}, {});

function AnalyticsTasksAssigneeDistribution(
  $element,
  AnalyticsTemplatesFactory,
) {
  const $ctrl = this;
  const rootElement = d3.select($element[0]);
  const pk = "drAnalyticsTasksAssigneeDistribution";
  $ctrl.$onInit = function () {
    update(); // render empty state
    $ctrl.dispatcher.on("update." + pk, update);
  };

  function update(data = [], isPercentage = false) {
    // [{key, value}, ...]
    if (data.length === 0) {
      data = [{ key: null, value: [] }];
    }

    data = data.map((d) => {
      d = angular.copy(d);

      const total = d.value.reduce((c, { value }) => c + value, 0);
      d.value = d.value.map((s) => {
        if (isPercentage === true) {
          s.value = Math.round((s.value / total) * 100);
          s.prefix = "%";
        }

        return s;
      });

      return d;
    });

    const elements = rootElement.selectAll(SELECTORS.root).data(data);

    elements.exit().each(removeBar);

    elements
      .enter()
      .append("div")
      .attr("class", CLASSES.root)
      .each(createBar)
      .merge(elements)
      .each(updateBar);
  }

  function createBar({ key, value }) {
    const element = d3.select(this);

    element.append("div").attr("class", CLASSES.name);
    element
      .append("div")
      .attr("class", CLASSES.bar)
      .property("dispatcher", renderProgressBar);

    function renderProgressBar() {
      const d = {
        type: "progressbar",
        showPercentage: true,
        value,
        dispatcher: true,
      };
      const { dispatcher } = AnalyticsTemplatesFactory.render(this, d);
      return dispatcher;
    }
  }

  function removeBar(d, i) {
    const element = d3.select(this);
    const bar = element.select(SELECTORS.bar);
    const name = element.select(SELECTORS.name);
    AnalyticsTemplatesFactory.remove(bar.node());
    AnalyticsTemplatesFactory.remove(name.node());

    element.remove();
  }

  function updateBar({ key, value }) {
    const element = d3.select(this);
    const name = element.select(SELECTORS.name);
    if (name.property("key") !== key) {
      name.property("key", key);
      AnalyticsTemplatesFactory.render(name.node(), { type: "group", id: key });
    }

    const bar = element.select(SELECTORS.bar);
    const oldValue = bar.property("value");
    if (isChanged(oldValue, value)) {
      const dispatcher = bar.property("dispatcher");
      dispatcher.call("update", null, value);
      bar.property("value", value);
    }

    function isChanged(oldV, newV) {
      if (!oldV || oldV.length === 0 || !newV || newV.length === 0) return true;
      return oldV.find(
        (o, i) => newV[i].value !== o.value || newV[i].prefix !== o.prefix,
      );
    }
  }
}
