<template>
  <div class="el-tabs__nav-wrap el-tabs__nav-wrap--simple is-top">
    <div class="el-tabs__nav-scroll">
      <div class="el-tabs__nav is-top">
        <div
          v-for="tab in tabs"
          :key="tab"
          :class="{
            'el-tabs__item--simple-active is-active': tab === activeTab,
          }"
          class="el-tabs__item el-tabs__item--simple is-top text-capitalize"
          @click="$emit('tab-click', tab)"
        >
          {{ tab }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { PropType } from "vue";

// simple tabnav with styles reused from
// https://element.eleme.io/#/en-US/component/tabs
// this tabnav can be used on same row with other elements

export default defineComponent({
  name: "SimpleTabNav",
  props: {
    tabs: { required: true, type: Array as PropType<string[]> },
    activeTab: { required: true, type: String as PropType<string> },
  },
  emits: ["tab-click"],
});
</script>
