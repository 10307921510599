<template>
  <DrDatepicker
    ref="pickerRef"
    type="date"
    clearable
    style="width: 100%"
    :validate-event="false"
    :model-value="editProps.value"
    :name="editProps.schema.prop"
    :readonly="editProps.schema.isReadOnly"
    :placeholder="editProps.schema.placeholder"
    @update:model-value="editProps.veeField.onChange"
    @blur="editProps.veeField.onBlur"
  />
</template>

<script setup lang="ts">
import { ref } from "vue";

import { DrDatepicker } from "../../dr-datepicker";

import type { FieldProps } from "../types";
import type CommonPicker from "element-plus/lib/components/time-picker/src/common/picker.vue";

interface Props {
  editProps: FieldProps;
}

defineProps<Props>();

const pickerRef = ref<InstanceType<typeof CommonPicker> | null>(null);
</script>
