<template>
  <DrDrawer
    fixed-footer
    size="large"
    title="New room"
    :shown="isDrawerVisible"
    @close="isDrawerVisible = false"
  >
    <div
      v-loading="isFormSubmitting"
      :class="{
        [$style.roomDialogWrapper]: true,
        [$style.roomDialogWrapper_isSubmitting]: isFormSubmitting,
      }"
    >
      <RoomForm
        :room-data="roomData"
        :form-errors="formErrors"
        :reset-error-fn="resetErrors"
        @update="handleUpdate"
      />
    </div>
    <template #footer>
      <ElButton :disabled="isFormSubmitting" @click="close"> Cancel </ElButton>
      <ElButton
        type="primary"
        :disabled="isFormSubmitting"
        @click="handleSubmit"
        data-testid="create-room-button"
      >
        Create room
      </ElButton>
    </template>
  </DrDrawer>
</template>

<script setup lang="ts">
import { cloneDeep } from "lodash-es";
import { ref, watch } from "vue";
import { DrDrawer } from "@shared/ui/dr-drawer";
import { useFormHelper } from "@shared/ui/dr-form";

import { ORG_MEMBER_DATA } from "@app/setups";
import { $notifyDanger } from "@drVue/common";
import DrStore from "@drVue/store";
import { DealsApiService } from "@drVue/store/modules/client-dashboard/deals/DealsApiService";
import RoomForm from "./RoomForm.vue";

import type { RoomCreateData } from "@drVue/api-service/common/rooms";
import type {
  Deal,
  Room,
} from "@drVue/store/modules/client-dashboard/deals/types";

const api = new DealsApiService();

const emit = defineEmits(["submit", "update"]);

const isDrawerVisible = ref(false);

const { formErrors, hookFormSubmitPromise, isFormSubmitting, resetErrors } =
  useFormHelper<Room>();

watch(
  () => formErrors.value,
  () => {
    if (formErrors.value.non_field_errors) {
      if (Array.isArray(formErrors.value.non_field_errors)) {
        formErrors.value.non_field_errors.forEach((error) => {
          $notifyDanger(error);
        });
      } else {
        $notifyDanger(formErrors.value.non_field_errors);
      }
    }
  },
);

const roomDataTemplate = {
  title: "",
  subdomain: "",
  client: ORG_MEMBER_DATA.client.id,
  copy_options: undefined!,
};

const roomData = ref<RoomCreateData>(cloneDeep(roomDataTemplate));
const dealId = ref<number | null>(null);

function handleUpdate(data: RoomCreateData) {
  roomData.value = data;
}

async function handleSubmit() {
  // dealId is guaranteed to be not null here, since it set in open() method
  await hookFormSubmitPromise(
    api.createRoomForADeal(dealId.value!, roomData.value),
  );
  await DrStore.dispatch("clientDashboard/deals/load");
  close();
}

function open({ id, ...deal }: Deal) {
  dealId.value = id;
  roomData.value = { ...roomData.value, ...deal };
  isDrawerVisible.value = true;
}

function close() {
  roomData.value = cloneDeep(roomDataTemplate);
  isDrawerVisible.value = false;
}

defineExpose({ open });
</script>

<style module lang="scss">
// Both classes below are only needed until DrDrawer's block-loader is fixed
.roomDialogWrapper {
  height: calc(100vh - 136px);
}
.roomDialogWrapper_isSubmitting {
  overflow: hidden;
}
</style>
