<template>
  <DrLayoutContentCenteredSection title="Enable DealRoom AI Analysis">
    <template #description>
      Enable DealRoom AI Analysis feature to automatically extract key
      information and create quick summary reports from due diligence documents
    </template>
    <ElForm
      ref="formRef"
      label-position="top"
      :model="formModel"
      :rules="formRules"
      :disabled="isFormSubmitting"
    >
      <ElFormItem
        prop="acceptTerms"
        :error="formErrors.acceptTerms"
        v-if="!IS_TERMS_ACCEPTED"
      >
        <ElCheckbox v-model="formModel.acceptTerms">
          I agree to the
          <a href="https://dealroom.net/legal/ai-feature-terms" target="_blank">
            AI Feature Terms of Use
          </a>
        </ElCheckbox>
      </ElFormItem>
      <div>
        <ElButton
          type="primary"
          :loading="isFormSubmitting"
          @click="toggleAiFeature(formRef)"
        >
          {{ IS_TERMS_ACCEPTED ? "Disable" : "Enable" }} AI
        </ElButton>
      </div>

      <ElAlert
        v-if="formErrors.non_field_errors"
        :title="formErrors.non_field_errors"
      />
    </ElForm>
  </DrLayoutContentCenteredSection>
</template>

<script lang="ts" setup>
import { reactive, ref } from "vue";
import { useStore } from "vuex";
import { useFormHelper } from "@shared/ui/dr-form";
import { DrLayoutContentCenteredSection } from "@shared/ui/dr-layouts";

import { ORG_MEMBER_DATA } from "@app/setups";
import { $notifyDanger } from "@drVue/common";

import type { FormInstance, FormRules } from "element-plus";

const $store = useStore();

const IS_TERMS_ACCEPTED = ORG_MEMBER_DATA.client.is_ai_tos_accepted;
interface FormModel {
  acceptTerms: boolean;
}
const formRef = ref<FormInstance>();
const formModel = reactive<FormModel>({
  acceptTerms: IS_TERMS_ACCEPTED,
});
const formRules = reactive<FormRules>({
  acceptTerms: [
    {
      validator: (rule: any, value: any, callback: any) => {
        if (!value) {
          callback(new Error("Please accept the terms."));
        } else {
          callback();
        }
      },
      trigger: "blur",
    },
  ],
});

const { formErrors, hookFormSubmitPromise, isFormSubmitting } =
  useFormHelper<FormModel>();

const toggleAiFeature = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid) => {
    if (valid) {
      hookFormSubmitPromise(
        $store.dispatch("clientDashboard/settings/update", {
          is_ai_tos_accepted: !IS_TERMS_ACCEPTED,
        }),
      ).then(
        () => {
          window.location.reload();
        },
        () => $notifyDanger(`Failed to toggle AI access.`),
      );
    }
  });
};
</script>
