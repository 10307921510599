import { ApiService } from "@drVue/api-service";

import type { FileHandledServerResponse } from "../../types";
import type {
  Finding,
  FindingAssigneesPayload,
  FindingCategoriesPayload,
  FindingComment,
  FindingCommentCreatePayload,
  FindingCommentUpdatePayload,
  FindingCreatePayload,
  FindingDocumentsPayload,
  FindingFoldersPayload,
  FindingFollowersPayload,
  FindingReorderPayload,
  FindingTasksPayload,
  FindingUpdatePayload,
} from "./types";

export class FindingsApiService extends ApiService {
  public async load() {
    const url: string = this.Urls["api:room:findings-list"]();
    return this.get<Finding[]>(url);
  }

  public async loadArchived() {
    const url: string = this.Urls["api:room:findings-archived"]();
    return this.get<Finding[]>(url);
  }

  /**
   * Load finding`s details by key
   * @param key
   * @returns
   */
  public async loadByKey(key: Finding["key"]) {
    const url: string = this.Urls["api:room:findings-detail-by-key"](key);
    return this.get<Finding>(url);
  }

  /**
   * Creates new finding
   * @param payload
   * @returns
   */
  public async create(payload: FindingCreatePayload) {
    const url = this.Urls["api:room:findings-list"]();
    return this.post<Finding>(url, payload);
  }

  /**
   * Updates existing finding partially
   * @param id
   * @param changes
   * @returns
   */
  public async update(id: Finding["id"], changes: FindingUpdatePayload) {
    const url = this.Urls["api:room:findings-detail"](id);
    return this.patch<Finding>(url, changes);
  }

  /**
   * Deletes specific finding
   * @param id
   * @returns
   */
  public async remove(id: Finding["id"]): Promise<void> {
    const url = this.Urls["api:room:findings-detail"](id);
    return this.delete(url).then(() => undefined);
  }

  /**
   * Restores multiple findings
   * @param ids
   * @returns Restored findings
   */
  public async restoreBulk(ids: Finding["id"][]): Promise<Finding[]> {
    const url = this.Urls["api:room:findings-bulk-restore"]();
    return this.post(url, { finding_ids: ids });
  }

  /**
   * Export multiple findings as excel-file
   * @param ids
   * @returns
   */
  public async exportBulkExcel(
    ids: Finding["id"][],
  ): Promise<FileHandledServerResponse> {
    const url = this.Urls["api:room:findings-bulk-export"]();
    return this.postAndGetFile(url, {
      finding_ids: ids,
    });
  }

  /**
   * Updates selected finding's finding_assignees list
   * @param id
   * @param assignees
   * @returns
   */
  public async assignees(
    id: Finding["id"],
    assignees: FindingAssigneesPayload,
  ) {
    const url = this.Urls["api:room:findings-detail-assignees-bulk"](id);
    return this.post<void>(url, assignees);
  }

  /**
   * Updates selected finding's finding_followers list
   * @param id
   * @param followers
   * @returns
   */
  public async followers(
    id: Finding["id"],
    followers: FindingFollowersPayload,
  ) {
    const url = this.Urls["api:room:findings-detail-followers-bulk"](id);
    return this.post<void>(url, followers);
  }

  /**
   * Updates selected finding's documents list
   * @param id
   * @param documents
   * @returns
   */
  public async documents(
    id: Finding["id"],
    documents: FindingDocumentsPayload,
  ) {
    const url = this.Urls["api:room:findings-detail-documents-bulk"](id);
    return this.post<void>(url, documents);
  }

  /**
   * Updates selected finding's folders list
   * @param id
   * @param folders
   * @returns
   */
  public async folders(id: Finding["id"], folders: FindingFoldersPayload) {
    const url = this.Urls["api:room:findings-detail-folders-bulk"](id);
    return this.post<void>(url, folders);
  }

  /**
   * Updates selected finding's attachcategory_set list
   * @param id
   * @param categories
   * @returns
   */
  public async categories(
    id: Finding["id"],
    categories: FindingCategoriesPayload,
  ) {
    const url = this.Urls["api:room:findings-detail-categories-bulk"](id);
    return this.post<void>(url, categories);
  }

  /**
   * Updates selected finding's attachtask_set list
   * @param id
   * @param tasks
   * @returns
   */
  public async tasks(id: Finding["id"], tasks: FindingTasksPayload) {
    const url = this.Urls["api:room:findings-detail-tasks-bulk"](id);
    return this.post<void>(url, tasks);
  }

  /**
   * Get comments list for specific finding
   * @param id
   * @returns
   */
  public async loadComments(id: Finding["id"]) {
    const url: string = this.Urls["api:room:findings-detail-comments-list"](id);
    return this.get<FindingComment[]>(url);
  }

  /**
   * Add new comment to specific finding
   * @param id
   * @param payload
   * @returns
   */
  public async createComment(
    id: Finding["id"],
    payload: FindingCommentCreatePayload,
  ) {
    const url = this.Urls["api:room:findings-detail-comments-list"](id);
    return this.post<FindingComment>(url, payload);
  }

  /**
   * Update specific finding`s comment
   * @param id
   * @param comment_id
   * @param payload
   * @returns
   */
  public async updateComment(
    id: Finding["id"],
    comment_id: FindingComment["id"],
    payload: FindingCommentUpdatePayload,
  ) {
    const url = this.Urls["api:room:findings_detail_comments_detail"](
      id,
      comment_id,
    );
    return this.patch<FindingComment>(url, payload);
  }

  /**
   * Deletes specific finding`s comment
   * @param id
   * @returns
   */
  public async removeComment(
    id: Finding["id"],
    comment_id: FindingComment["id"],
  ): Promise<void> {
    const url = this.Urls["api:room:findings_detail_comments_detail"](
      id,
      comment_id,
    );
    return this.delete(url).then(() => undefined);
  }

  /**
   * Reorder finding
   * @param id
   * @param payload
   * @returns
   */
  public async reorderFinding(
    id: Finding["id"],
    payload: FindingReorderPayload,
  ) {
    const url = this.Urls["api:room:findings-order"](id);
    return this.post<void>(url, payload);
  }
}
