import { defineStore } from "pinia";
import { computed } from "vue";

import { FindingsStatusesApiService } from "@drVue/api-service/modules/findings-statuses";
import {
  FindingStatusType,
  FindingStatusTypesArray,
} from "@drVue/api-service/modules/findings-statuses/types";
import { generateList } from "@drVue/store/pinia/helpers/generators";

import type {
  FindingStatus,
  FindingStatusCreateOrUpdatePayload,
} from "@drVue/api-service/modules/findings-statuses/types";

type FindingStatusFilled = FindingStatus & { typeLabel: string };

export type {
  FindingStatus,
  FindingStatusCreateOrUpdatePayload,
  FindingStatusFilled,
};
export { FindingStatusType };

const api = new FindingsStatusesApiService();

const typeLabel: Record<FindingStatusType, string> = {
  open: "Open",
  inprogress: "In Progress",
  closed: "Closed",
};

const typeOrder: Record<FindingStatusType, number> = {
  open: 0,
  inprogress: 1,
  closed: 2,
};

export const useFindingsStatusesStore = defineStore(
  "pipelineFindingsStatuses",
  () => {
    const { list, dict, load, create, update, remove, isLoading, isLoadError } =
      generateList<
        FindingStatus,
        FindingStatusCreateOrUpdatePayload,
        FindingStatusCreateOrUpdatePayload
      >({
        name: "Pipeline findings statuses",
        load: api.load.bind(api),
        create: api.create.bind(api),
        update: api.update.bind(api),
        remove: api.remove.bind(api),
      });

    const fillList = (items: FindingStatus[]) => {
      list.value = items;
    };

    const listSorted = computed(() =>
      [...list.value].sort((statusA, statusB) => {
        const aIndex = typeOrder[statusA.type];
        const bIndex = typeOrder[statusB.type];

        if (aIndex === bIndex) {
          return statusA.name
            .toLowerCase()
            .localeCompare(statusB.name.toLowerCase());
        }

        return aIndex > bIndex ? 1 : -1;
      }),
    );

    const listByType = computed(() => {
      return FindingStatusTypesArray.reduce<
        Record<FindingStatusType, FindingStatus[]>
      >(
        (acc, type) => {
          acc[type] = listSorted.value.filter((item) => item.type === type);
          return acc;
        },
        {
          open: [],
          inprogress: [],
          closed: [],
        },
      );
    });

    const typesList = computed(() => {
      return FindingStatusTypesArray.reduce<
        { value: FindingStatusType; label: string }[]
      >((acc, type) => {
        acc.push({
          value: type,
          label: typeLabel[type],
        });
        return acc;
      }, []);
    });

    const filledList = computed(() =>
      listSorted.value.map((status) => ({
        ...status,
        typeLabel: typeLabel[status.type],
      })),
    );

    return {
      list: listSorted,
      dict,
      isLoading,
      isLoadError,
      load,
      fillList,
      create,
      update,
      remove,
      typeLabel,
      listByType,
      filledList,
      typesList,
    };
  },
);
