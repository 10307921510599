import { cloneDeep } from "lodash-es";

import type { CustomView } from "./types";

export function copyView(view: CustomView, resetSlug: boolean = false) {
  const cloned = cloneDeep(view);
  if (resetSlug) {
    (cloned as any).slug = undefined;
  }
  return cloned;
}
