<template>
  <DrLayoutContentCenteredSection title="BI Reports">
    <ElButton type="primary" plain :loading="isLoading" @click="updateFields">
      <span>Update BI Reports fields</span>
    </ElButton>
  </DrLayoutContentCenteredSection>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { DrLayoutContentCenteredSection } from "@shared/ui/dr-layouts";

import { $notifyDanger, $notifySuccess } from "@drVue/common";
import { LookerApiService } from "@drVue/store/modules/client-dashboard/looker/LookerApiService";

const api = new LookerApiService();

const isLoading = ref(false);

const updateFields = () => {
  if (isLoading.value) {
    return;
  }

  isLoading.value = true;

  api
    .updateModel()
    .then(() => {
      $notifySuccess("BI Reports fields are updated");
    })
    .catch(() => {
      $notifyDanger("Failed to update BI Reports fields");
    })
    .finally(() => {
      isLoading.value = false;
    });
};
</script>
