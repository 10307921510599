import { h } from "vue";
import { TableInlineEditor } from "@shared/ui/table-inline-editor";

import { findingDetailsUrl } from "@setups/room-urls";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import RoomCell from "@drVue/components/client-dashboard/your-work/tasks/tableCells/TasksTableCellRoom.vue";
import LikelihoodCell from "@drVue/components/room/findings/cells/LikelihoodCell.vue";
import SeverityCell from "@drVue/components/room/findings/cells/SeverityCell.vue";
import {
  StatusCellEdit,
  StatusCellView,
} from "@drVue/components/room/findings/cells/StatusCell";
import TypeCell from "@drVue/components/room/findings/cells/TypeCell.vue";
import { TableColumnsBase } from "@drVue/TableColumnsBase";
import CellTitle from "./cells/CellTitle.vue";

import type {
  YourWorkFindingsTableColumn,
  YourWorkFindingsTableRow,
} from "./types";
import type { CustomViewColumn } from "@setups/types";

export default class TableColumns extends TableColumnsBase<YourWorkFindingsTableRow> {
  constructor(
    private readonly userColumnsSettings: Record<string, CustomViewColumn>,
  ) {
    super();
  }

  inlineEditor = new TableInlineEditor(() => Promise.resolve(), "id");

  getUserColumns(): Record<string, CustomViewColumn> {
    return this.userColumnsSettings;
  }

  getTableColumns(): YourWorkFindingsTableColumn[] {
    return [
      this.keyColumn(),
      this.typeColumn(),
      this.titleColumn(),
      this.statusColumn(),
      this.severityColumn(),
      this.likelihoodColumn(),
      this.roomColumn(),
    ];
  }

  private keyColumn(): YourWorkFindingsTableColumn {
    return {
      field: "key",
      title: "ID",
      minWidth: 45,
      width: 60,
      sortable: true,
    };
  }

  private typeColumn(): YourWorkFindingsTableColumn {
    return {
      field: "type_id",
      title: "Type",
      minWidth: 45,
      width: 115,
      sortable: true,
      slots: {
        default: ({ row }) => h(TypeCell, { typeUid: row.type_id }),
      },
    };
  }

  private titleColumn(): YourWorkFindingsTableColumn {
    return {
      field: "title",
      title: "Title",
      width: 462,
      sortable: true,
      slots: {
        default: ({ row }) => [
          h(CellTitle, {
            title: row.title,
            url: findingDetailsUrl(row.room.url, row.key),
          }),
        ],
      },
    };
  }

  private statusColumn(): YourWorkFindingsTableColumn {
    return {
      field: "status_id",
      title: "Status",
      minWidth: 45,
      width: 145,
      sortable: true,
      slots: {
        default: (params) => {
          return this.inlineEditor.renderInlineField(
            {
              isReadOnly: true,
              type: FieldSchemaType.Custom,
              prop: "status_id",
              label: "Status",
              optimistic: true,
              editComponent: StatusCellEdit,
              viewComponent: StatusCellView,
            },
            params,
          );
        },
      },
    };
  }

  private severityColumn(): YourWorkFindingsTableColumn {
    return {
      field: "severity",
      title: "Severity",
      minWidth: 45,
      width: 115,
      sortable: true,
      slots: {
        default: ({ row }) => h(SeverityCell, { severity: row.severity }),
      },
    };
  }

  private likelihoodColumn(): YourWorkFindingsTableColumn {
    return {
      field: "likelihood",
      title: "Likelihood",
      minWidth: 45,
      width: 115,
      sortable: true,
      slots: {
        default: ({ row }) => h(LikelihoodCell, { likelihood: row.likelihood }),
      },
    };
  }

  private roomColumn(): YourWorkFindingsTableColumn {
    return {
      field: "room",
      title: "Room",
      minWidth: 150,
      width: 213,
      sortable: true,
      slots: {
        default: ({ row }) =>
          h(RoomCell, {
            title: row.room.title,
            logo: row.room.logo,
            url: row.room.url,
          }),
      },
    };
  }
}
