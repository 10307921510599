<template>
  <DrDrawer
    header-white
    no-footer
    condensed
    title="Saved prompts"
    size="custom"
    :shown="shown"
    :custom-class="$style.drawer"
    @close="hide"
  >
    <template #description> Click the prompt to apply it. </template>

    <template #sub-header>
      <ElInput
        v-model="searchText"
        placeholder="Search..."
        size="default"
        clearable
        :disabled="aiTemplatesStore.isLoading"
        :class="$style.input"
      >
        <template #prefix>
          <DrIcon name="search" size="sm" />
        </template>
      </ElInput>
    </template>

    <template #default>
      <ElAlert
        v-if="aiTemplatesStore.isLoadError"
        type="error"
        title="Failed to load templates"
      />

      <div :class="$style.templatesList" v-loading="aiTemplatesStore.isLoading">
        <AiTemplateRow
          v-for="template in filteredTemplates"
          :key="template.id"
          :template="template"
          @apply="onApply(template.question)"
        />
      </div>
    </template>
  </DrDrawer>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { DrDrawer } from "@shared/ui/dr-drawer";
import { DrIcon } from "@shared/ui/dr-icon";
import { useAsyncState } from "@vueuse/core";

import { Document, flexSearchAllResultsArray } from "@app/flex";
import { DocVerQaApiService } from "@drVue/components/room/ai/api";
import { pinia } from "@drVue/store/pinia";
import AiTemplateRow from "./AiTemplateRow.vue";
import { useAiTemplatesStore } from "./AiTemplatesStore";

import type { DocVerQA } from "./types";

interface Props {
  docUid: string;
  appliedQA: DocVerQA[];
}

interface Events {
  (e: "new-applied"): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Events>();

const aiTemplatesStore = useAiTemplatesStore(pinia);
const api = new DocVerQaApiService();

const shown = ref(false);
const searchText = ref("");

const filteredTemplates = computed(() => {
  if (!searchText.value) {
    return aiTemplatesStore.list;
  }

  const flexIndex = new Document({
    document: {
      id: "id",
      index: [
        {
          field: "question",
          charset: "latin:advanced",
          tokenize: "full",
        },
      ],
    },
  });

  aiTemplatesStore.list.forEach((template) => flexIndex.add(template));

  const foundSortedIds = flexSearchAllResultsArray(flexIndex, searchText.value);

  return foundSortedIds.map((id) => aiTemplatesStore.dict[id]);
});

const { execute: applyQuestions } = useAsyncState(
  (question: string) => {
    return api.newBulkQA(props.docUid, [{ question }]).then(() => {
      emit("new-applied");
      hide();
    });
  },
  undefined,
  { immediate: false },
);

const onApply = (question: string) => applyQuestions(0, question);

const show = () => {
  shown.value = true;
};
const hide = () => {
  shown.value = false;
};

defineExpose({
  show,
  hide,
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.drawer {
  width: 486px;
}

.templatesList {
  display: flex;
  flex-direction: column;
  gap: spacing.$xs;
  padding-bottom: spacing.$l;
}
</style>
