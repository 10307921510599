<template>
  <DrLayoutContent>
    <div
      class="embedded-wrapper"
      :class="{
        'text-center': !embedUrl,
        'color-red': isLoadError,
      }"
    >
      <DrLoader v-if="isLoading" />

      <template v-if="isLoadError">
        <h3>An error occurred.</h3>
        <b>Please try again later.</b>
      </template>

      <iframe v-if="embedUrl" :src="embedUrl" class="embedded-iframe" />
    </div>
  </DrLayoutContent>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DrLayoutContent } from "@shared/ui/dr-layouts";
import DrLoader from "@shared/ui/dr-loader";

import { LookerApiService } from "@drVue/store/modules/client-dashboard/looker/LookerApiService";

interface Data {
  isLoading: boolean;
  isLoadError: boolean;
  embedUrl: string | null;
}

export default defineComponent({
  name: "LookerMain",
  components: { DrLayoutContent, DrLoader },
  data(): Data {
    return {
      isLoading: false,
      isLoadError: true,
      embedUrl: null,
    };
  },
  created() {
    this.loadUrl();
  },
  methods: {
    loadUrl() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.isLoadError = false;
      const api = new LookerApiService();
      api
        .embedUrl()
        .then(
          (data) => {
            this.embedUrl = data.url;
          },
          () => {
            this.isLoadError = true;
          },
        )
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
</script>
<style scoped>
.embedded-wrapper {
  width: 100%;
  height: 100%;
  line-height: 0; /* prevent vertical scrollbar of iframe */
}
.embedded-iframe {
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

.text-center {
  padding: 20px;
}
</style>
