<template>
  <div
    :style="avatarStyle"
    :class="{ 'avatar-holder--invite': isInvite }"
    class="avatar-holder"
  >
    <span :style="fontSizeStyle">
      <img
        v-if="avatar.url"
        :src="avatar.url"
        :alt="avatar.initial"
        :style="avatarStyle"
        class="avatar-holder__avatar"
      />

      <span v-else-if="avatar.initial">{{ avatar.initial }}</span>

      <img
        v-else-if="isInvite"
        alt="Invite"
        :src="unregisteredSvgUrl"
        class="avatar-holder__unregistered-icon"
      />

      <i v-else class="fa fa-user-secret fa-2x" aria-hidden="true" />
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import unregisteredSvgUrl from "./unregistered.svg";

import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { PropType } from "vue";

export default defineComponent({
  name: "UserAvatar",
  props: {
    user: { required: true, type: Object as PropType<OrgUser> },
    size: {
      required: false,
      default: "25px",
      type: String as PropType<string>,
    },
    fontSize: {
      required: false,
      default: null,
      type: String as PropType<string>,
    },
  },
  computed: {
    unregisteredSvgUrl() {
      return unregisteredSvgUrl;
    },
    isInvite(): any {
      return this.user?.pending;
    },
    avatarStyle(): any {
      return { width: this.size, height: this.size };
    },
    fontSizeStyle(): any {
      const maxFZ = 16,
        prcnt = 0.3;
      let fontSize = this.fontSize;
      if (!fontSize) {
        const size = parseInt(this.size as string);
        fontSize = Math.min(maxFZ, Math.ceil(size * prcnt)) + "px";
      }

      return { "font-size": fontSize };
    },
    avatar(): any {
      const avatar: { [key: string]: any } = {
        size: this.size,
        isInvite: this.isInvite,
      };
      const user = this.user as OrgUser;
      if (!avatar.isInvite && !user.pending) {
        avatar.initial = user.initial;
        if (user.avatar && user.avatar.thumbnail) {
          if (this.size) {
            if (this.size.endsWith("px")) {
              const sizePx = parseInt(this.size as string);
              avatar.url =
                sizePx > 34 ? user.avatar.reduced : user.avatar.thumbnail;
            } else {
              avatar.url = user.avatar.reduced;
            }
          }
        }
      }
      return avatar;
    },
  },
});
</script>
