import templates_confirmDialogHtml from "../../../templates/confirm-dialog.html?raw";

ArchivedCategoriesService.$inject = [
  "$q",
  "$sce",
  "$http",
  "$uibModal",
  "URLS",
  "CategoriesService",
  "AlertService",
];
import { pinia } from "@drVue/store/pinia";
import { useCategoriesArchivedStore } from "@drVue/store/pinia/room/categoriesArchived";

const categoriesArchivedStore = useCategoriesArchivedStore(pinia);

export default function ArchivedCategoriesService(
  $q,
  $sce,
  $http,
  $uibModal,
  URLS,
  CategoriesService,
  AlertService,
) {
  var service = {
    // categoriesList: undefined,
    // loading: undefined,
    // error: undefined,
    // loadingPromise: undefined,
    // load: load,
    isLoaded: isLoaded,
    restoreCategory: restoreCategory,
  };

  Object.defineProperties(service, {
    loadingPromise: {
      get: function () {
        return categoriesArchivedStore.loadingPromise;
      },
    },
    categories: {
      get: function () {
        return categoriesArchivedStore.categories;
      },
    },
    categoriesList: {
      get: function () {
        return categoriesArchivedStore.categoriesList;
      },
    },
    loading: {
      get: function () {
        return categoriesArchivedStore.isLoading;
      },
    },
    error: {
      get: function () {
        return categoriesArchivedStore.isError;
      },
    },
    load: {
      get: function () {
        return categoriesArchivedStore.load;
      },
    },
  });

  return service;

  function isLoaded() {
    return service.loading === false && service.error === false;
  }

  function restoreCategory(category) {
    var categoryType = CategoriesService.getCategoryType(category.parent);
    var modalOptions = {
      headerText: category.parent ? "Restore Category" : "Restore List",
      actionButtonText: "Restore",
      bodyText:
        "All the sub-categories and requests will be restored." +
        " Are you sure you want to restore this " +
        categoryType +
        "?",
    };

    return $uibModal.open({
      template: templates_confirmDialogHtml,
      controllerAs: "ctrl",
      controller: [
        "$scope",
        "$uibModalInstance",
        "CategoriesService",
        function ($scope, $uibModalInstance, CategoriesService) {
          this.modalOptions = modalOptions;

          this.submit = function () {
            var elem = CategoriesService.getCategoryForAlert(category, {
              withType: true,
              skipHref: true,
            });

            categoriesArchivedStore.restoreCategory(category.id).then(
              () => {
                AlertService.success(
                  $sce.trustAsHtml(elem + " has been restored."),
                );
              },
              (error) => {
                AlertService.danger(
                  $sce.trustAsHtml("Failed to restore " + elem + "."),
                );
              },
            );

            $uibModalInstance.close();
          };
        },
      ],
    });
  }
}
