<template>
  <div />
</template>

<script lang="ts">
// see https://github.com/arcadeJHS/AngularVueIntegration/blob/2e0f2ca5802f5e13bdbc116a702a42169052e993/README.md#angular-components-nested-inside-vue-components
// for details of implementation
import { defineComponent } from "vue";

import { NG_APP_ID } from "./constants";

import type { PropType } from "vue";

function safeApply(scope: any, fn: any = undefined) {
  const phase = scope.$root.$$phase;
  if (phase == "$apply" || phase == "$digest") {
    if (fn && typeof fn === "function") {
      fn();
    }
  } else {
    scope.$apply(fn);
  }
}

interface AngularComponent {
  template: string;
  bindings: { [key: string]: any };
}

interface Data {
  ctrlUnwatch: (() => void) | null;
  angularLoadTimerId: number | null;
}

export default defineComponent({
  name: "AngularComponent",
  props: {
    component: { required: true, type: Object as PropType<AngularComponent> },
    appElement: {
      required: false,
      default: null,
      type: Object as PropType<HTMLElement | null>,
    },
  },
  data(): Data {
    return {
      ctrlUnwatch: null,
      angularLoadTimerId: null,
    };
  },
  mounted() {
    if (this.isAngularLoaded()) {
      this.mountAngularElement();
    } else {
      this.angularLoadTimerId = window.setInterval(() => {
        if (this.isAngularLoaded()) {
          this.mountAngularElement();
          clearInterval(this.angularLoadTimerId || undefined);
        }
      }, 100);
    }
  },
  unmounted() {
    if (this.ctrlUnwatch) {
      this.ctrlUnwatch();
    }
    if (this.angularLoadTimerId) {
      clearInterval(this.angularLoadTimerId);
    }
  },
  methods: {
    isAngularLoaded() {
      const el = this.appElement || document.getElementById(NG_APP_ID);
      return el && el.className.indexOf("ng-scope") > -1;
    },
    mountAngularElement() {
      const el = angular.element(this.$el);
      let scope: any;

      el.injector().invoke([
        "$compile",
        "$rootScope",
        ($compile: any, $rootScope: any) => {
          scope = angular.extend($rootScope.$new(), {
            $ctrl: this.component.bindings,
          });
          el.replaceWith($compile(this.component.template)(scope));
        },
      ]);

      this.ctrlUnwatch = this.$watch(
        "component.$ctrl",
        (ctrl: any) => {
          scope.$ctrl = angular.merge(scope.$ctrl, ctrl);
          safeApply(scope);
        },
        { deep: true },
      );
    },
  },
});
</script>
