<template>
  <ElTooltip
    trigger="hover"
    :content="content"
    :disabled="disabled || !content || hideTooltip"
    :placement="placement"
    :show-after="200"
    :popper-class="$style.popper"
  >
    <div
      ref="wrapper"
      :class="{
        [$style.wrapper]: true,
        [$style.wrapper_isSingleLine]: !hasShavedText,
      }"
    >
      <slot>
        {{ content }}
      </slot>
    </div>
  </ElTooltip>
</template>

<script lang="ts" setup>
/**
 * A tooltip component for wrapping single-line text (main case)
 * that only appears on hover when the text is clipped
 * (does not fit within the available width of the parent container).
 *
 * There is also a second case for multiline output:
 * here it checks that the text inside is "shaved"
 * (i.e. the "shave" class is used inside slot).
 */

import { onMounted, ref, watch } from "vue";
import { useDebounceFn, useResizeObserver } from "@vueuse/core";

import type { Placement } from "element-plus";

interface Props {
  content: string;
  disabled?: boolean;
  placement?: Placement;
  hasShavedText?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  placement: "top",
  hasShavedText: false,
});

const wrapper = ref<HTMLElement | null>(null);
const hideTooltip = ref(false);
const updateHideTooltip = () => {
  if (wrapper.value) {
    if (props.hasShavedText) {
      const shaveEl = wrapper.value.querySelector(".shave");
      if (shaveEl) {
        hideTooltip.value = shaveEl.scrollHeight <= shaveEl.clientHeight;
      } else {
        hideTooltip.value = true;
      }
    } else {
      hideTooltip.value =
        wrapper.value.offsetWidth >= wrapper.value.scrollWidth;
    }
  }
};
const debouncedUpdateHideTooltip = useDebounceFn(updateHideTooltip, 500);

watch(
  () => props.content,
  () => {
    updateHideTooltip();
  },
  {
    flush: "post",
  },
);

useResizeObserver(wrapper, debouncedUpdateHideTooltip);

onMounted(() => {
  updateHideTooltip();
});
</script>

<style lang="scss" module>
@import "@app/styles/scss/mixins";

.popper {
  @include dont-break-out();

  border-radius: 2px;
  font-size: 11px;
  max-width: 400px;
  white-space: normal;
}

.wrapper {
  max-width: 100%;
  flex-shrink: 3;
}

.wrapper_isSingleLine {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
