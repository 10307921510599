<template>
  <DrDrawer
    size="large"
    fixed-footer
    :title="title"
    :submit-btn="submitBtnText"
    :shown="shown"
    :is-submitting="isFormSubmitting"
    @submit="handleSubmit"
    @close="checkAndHandleClose"
    @cancel="resetAndClose"
  >
    <DrDynamicFormClassic
      ref="formRef"
      :schema="findingStatusFormSchema"
      :entity="findingStatusFormEntity"
      :errors="formErrors"
      @update="handleUpdateForm"
      @submit="handleSubmit"
    />

    <ElAlert
      v-if="formErrors.non_field_errors"
      type="error"
      :title="formErrors.non_field_errors"
    />
  </DrDrawer>
</template>

<script lang="ts" setup>
import { ElMessageBox } from "element-plus";
import { cloneDeep, isEqual, set } from "lodash-es";
import { computed, reactive, ref, watch } from "vue";
import { DrDrawer } from "@shared/ui/dr-drawer";
import { DrDynamicFormClassic } from "@shared/ui/dr-dynamic-form";
import { validateString } from "@shared/ui/dr-dynamic-form/utils";
import { useFormHelper } from "@shared/ui/dr-form";

import {
  insightTrack,
  OrgSettingsFindingsStatusesTrackingEvent,
} from "@app/insight";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import { pinia } from "@drVue/store/pinia";
import {
  FindingStatusType,
  useFindingsStatusesStore,
} from "@drVue/store/pinia/pipeline/findings-statuses";

import type { FindingStatusCreateOrUpdatePayload } from "@drVue/store/pinia/pipeline/findings-statuses";
import type { UpdatePayload } from "@shared/ui/dr-dynamic-form/types";
import type { FormSchema } from "@shared/ui/dr-dynamic-form/types";

const findingsStatusesStore = useFindingsStatusesStore(pinia);

interface Props {
  shown: boolean;
  statusId?: string | null;
}

interface Emits {
  (event: "update:shown", isShown: boolean): void;
}

const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(), { statusId: null });

const title = computed(() =>
  props.statusId ? "Edit finding status" : "New finding status",
);

const submitBtnText = computed(() => (props.statusId ? "Update" : "Create"));

const findingStatusEmptyEntity: FindingStatusCreateOrUpdatePayload = {
  name: "",
  color: "#ff0000",
  type: FindingStatusType.Open,
};

const findingStatusPristineEntity =
  reactive<FindingStatusCreateOrUpdatePayload>(
    cloneDeep(findingStatusEmptyEntity),
  );

const findingStatusFormEntity = reactive<FindingStatusCreateOrUpdatePayload>(
  cloneDeep(findingStatusEmptyEntity),
);

const formRef = ref<InstanceType<typeof DrDynamicFormClassic> | null>(null);

const {
  formErrors,
  hookFormSubmitPromise,
  isFormSubmitting,
  resetErrors,
  resetError,
} = useFormHelper<FindingStatusCreateOrUpdatePayload>();

const findingStatusFormSchema = computed<FormSchema>(() => {
  return [
    {
      type: FieldSchemaType.Text,
      prop: "name",
      label: "Status name",
      placeholder: "Specify name",
      rules: validateString().required("Status name is required"),
      required: true,
    },
    {
      type: FieldSchemaType.Select,
      prop: "type",
      label: "Type",
      placeholder: "Select type",
      isReadOnly: !!props.statusId,
      extra: {
        select_options: findingsStatusesStore.typesList,
      },
      rules: validateString().required("Type is required"),
      required: true,
    },
    {
      type: FieldSchemaType.Color,
      prop: "color",
      label: "Color",
      rules: validateString().required("Color is required"),
      required: true,
    },
  ];
});

const handleUpdateForm = ({ field, value }: UpdatePayload) => {
  resetError(field);
  set(findingStatusFormEntity, field, value);
};

const resetForms = () => {
  resetErrors();
  Object.assign(findingStatusFormEntity, cloneDeep(findingStatusEmptyEntity));
  formRef.value?.reset();
};

const handleClose = () => {
  emit("update:shown", false);
};

const resetAndClose = () => {
  resetForms();
  handleClose();
};

watch(
  props,
  () => {
    if (!props.shown) return;

    resetForms();

    if (props.statusId) {
      const status = findingsStatusesStore.dict[props.statusId];
      Object.assign(findingStatusFormEntity, cloneDeep(status));
    }

    Object.assign(findingStatusPristineEntity, findingStatusFormEntity);
  },
  { immediate: true },
);

const checkAndHandleClose = () => {
  if (isEqual(findingStatusFormEntity, findingStatusPristineEntity)) {
    handleClose();
  } else {
    const message = props.statusId
      ? "You have unsaved changes made to the editable finding status form."
      : "You have unsaved changes made to the new finding status form.";

    ElMessageBox.confirm(
      `${message} Are you sure you wish to discard these changes?`,
      "Unsaved changes",
      {
        confirmButtonText: "Discard",
        cancelButtonText: "Cancel",
        showClose: false,
      },
    ).then(resetAndClose);
  }
};

const handleSubmit = async () => {
  if (!formRef.value) return;

  resetErrors();
  const isValid = await formRef.value.validate();

  if (isValid) {
    let promise;
    if (props.statusId) {
      promise = findingsStatusesStore.update(
        props.statusId,
        findingStatusFormEntity,
      );
    } else {
      promise = findingsStatusesStore.create(findingStatusFormEntity);
    }
    await hookFormSubmitPromise(promise);
    insightTrack(
      props.statusId
        ? OrgSettingsFindingsStatusesTrackingEvent.StatusChanged
        : OrgSettingsFindingsStatusesTrackingEvent.StatusAdded,
    );

    resetAndClose();
  }
};
</script>
