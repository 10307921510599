import tableHtml from "./index.html?raw";

AnalyticsDatagridTableController.$inject = ["$element", "$q"];
import "./body";
import "./header-cell";
import "./sorting";

angular
  .module("dealroom.analytics.datagrid-table.table", [
    "dealroom.analytics.datagrid-table.table.header-cell",
    "dealroom.analytics.datagrid-table.table.body",
    "dealroom.analytics.datagrid-table.table.sorting",
  ])
  .component("drAnalyticsDatagridTable", {
    bindings: {
      uid: "@",
      limitTo: "<",
      cells: "<",
      dispatcher: "<",
      defaultSort: "<",
    },
    controller: AnalyticsDatagridTableController,
    template: tableHtml,
  });

function AnalyticsDatagridTableController($element, $q) {
  const pk = "AnalyticsDatagridTableController";
  const $ctrl = this;
  $ctrl.$onInit = function () {
    $ctrl.cells = [
      {
        label: "Title",
        class: "datagrid-table__column--title",
        key: "title",
      },
    ].concat($ctrl.cells);

    $ctrl.defaultSort =
      $ctrl.cells.find((c) => c.defaultSort === true) || $ctrl.defaultSort;

    $ctrl.dispatcher.on("update." + pk, update);
    $ctrl.dispatcher.on("sortBy." + pk, initSorting);
  };
  const initSortingPromise = $q.defer();

  function initSorting(sortBy) {
    initSortingPromise.resolve();
  }

  function update(rows) {
    initSortingPromise.promise.then(() => showTable(rows));
  }

  function showTable(rows) {
    $element.toggleClass("ng-hide", rows.length === 0);
  }
}
