import * as Sentry from "@sentry/browser";

(function () {
  "use strict";

  const sentry = angular.module("dealroom.sentry", []).config([
    "$provide",
    function ($provide) {
      $provide.decorator("$exceptionHandler", [
        "$delegate",
        function ($delegate) {
          const angularPattern =
            /^\[((?:[$a-zA-Z0-9]+:)?(?:[$a-zA-Z0-9]+))\] (.*?)\n?(\S+)$/;

          return function (exception, cause) {
            Sentry.withScope((scope) => {
              if (cause) scope.setExtra("cause", cause);

              scope.addEventProcessor((event) => {
                const ex =
                  event.exception &&
                  event.exception.values &&
                  event.exception.values[0];

                if (ex) {
                  const matches = angularPattern.exec(ex.value || "");

                  if (matches) {
                    // This type now becomes something like: $rootScope:inprog
                    ex.type = matches[1];
                    ex.value = matches[2];

                    event.message = `${ex.type}: ${ex.value}`;

                    // auto set a new tag specifically for the angular error url
                    event.extra = {
                      ...event.extra,
                      angularDocs: matches[3].substring(0, 250),
                    };
                  }
                }

                return event;
              });

              // https://docs.sentry.io/platforms/javascript/enriching-events/scopes/#current-scope
              Sentry.captureException(exception);
            });

            $delegate(exception, cause);
          };
        },
      ]);
    },
  ]);
})();
