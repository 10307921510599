import { h } from "vue";

import CellCompleted from "./tableCells/CellCompleted.vue";
import CellDeal from "./tableCells/CellDeal.vue";
import CellDueDate from "./tableCells/CellDueDate.vue";
import CellIsResolved from "./tableCells/CellIsResolved.vue";
import CellTitle from "./tableCells/CellTitle.vue";

import type {
  YourWorkDealTasksTableColumn,
  YourWorkDealTasksTableRow,
} from "./types";

type DealTasksTableRowType = { row: YourWorkDealTasksTableRow };

export default class TableColumns {
  get columns(): YourWorkDealTasksTableColumn[] {
    return [
      this.columnIsResolved(),
      this.columnDueDate(),
      this.columnTitle(),
      this.columnDeal(),
      this.columnCompleted(),
    ];
  }

  private columnIsResolved(): YourWorkDealTasksTableColumn {
    return {
      sortable: false,
      field: "is_resolved",
      title: "",
      minWidth: 38,
      width: 38,
      slots: {
        default: ({ row }: DealTasksTableRowType) => [
          h(CellIsResolved, {
            isResolved: row.is_resolved,
          }),
        ],
      },
    };
  }

  private columnDueDate(): YourWorkDealTasksTableColumn {
    return {
      sortable: true,
      field: "due_datetime",
      title: "Due date",
      width: 170,
      resizable: false,
      slots: {
        default: ({ row }: DealTasksTableRowType) => [
          h(CellDueDate, {
            dueDate: row.due_datetime,
            isOverdue: !!(
              !row.is_resolved &&
              row.due_datetime &&
              Date.now() > (row.due_datetime?.getTime() || 0)
            ),
          }),
        ],
      },
    };
  }

  private columnTitle(): YourWorkDealTasksTableColumn {
    return {
      sortable: true,
      field: "title",
      title: "Title",
      minWidth: 400,
      slots: {
        default: ({ row }: DealTasksTableRowType) => [
          h(CellTitle, {
            title: row.title,
            url: `${row.deal.url}?tab=tasks`,
          }),
        ],
      },
    };
  }

  private columnDeal(): YourWorkDealTasksTableColumn {
    return {
      sortable: true,
      field: "deal_uid",
      title: "Deal",
      minWidth: 130,
      width: 300,
      resizable: true,
      slots: {
        default: ({ row }: DealTasksTableRowType) => [
          h(CellDeal, {
            title: row.deal.title,
            url: row.deal.url,
          }),
        ],
      },
    };
  }

  private columnCompleted(): YourWorkDealTasksTableColumn {
    return {
      sortable: true,
      field: "date_resolved",
      title: "Date completed",
      width: 130,
      resizable: false,
      slots: {
        default: ({ row }: DealTasksTableRowType) => [
          h(CellCompleted, {
            date: row.date_resolved,
          }),
        ],
      },
    };
  }
}
