<template>
  <div :class="$style.wrapper" v-loading="aiReportsStore.isLoading">
    <CreateReportForm />
    <CreateReportFieldForm />
    <ElDivider />
    <AiDevReportDelete />
  </div>
</template>

<script lang="ts" setup>
import { pinia } from "@drVue/store/pinia";
import { useAiReportsStore } from "./AiReportsStore";
import AiDevReportDelete from "./dev/AiDevReportDelete.vue";
import CreateReportFieldForm from "./dev/CreateReportFieldForm.vue";
import CreateReportForm from "./dev/CreateReportForm.vue";

const aiReportsStore = useAiReportsStore(pinia);
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  margin-top: spacing.$m;
}
</style>
