import analyticsCardBurndownHtml from "./index.html?raw";

Controller.$inject = [
  "AnalyticsDispatcherFactory",
  "AnalyticsBurndownDataTotalService",
];
import "./data";
import "./tooltip";

angular
  .module("dealroom.analytics.card.burndown", [
    "dealroom.analytics.card.burndown.data",
    "dealroom.analytics.card.burndown.tooltip",

    "dealroom.analytics.card.chart.header",
    "dealroom.analytics.factory.dispatcher",
  ])
  .component("drAnalyticsTasksBurndownCard", {
    template: analyticsCardBurndownHtml,
    controller: Controller,
  });

function Controller(
  AnalyticsDispatcherFactory,
  AnalyticsBurndownDataTotalService,
) {
  const $ctrl = this;
  $ctrl.name = "Requests Burndown";
  $ctrl.dispatcher = AnalyticsDispatcherFactory("update", "nested");
  $ctrl.dispatcher.on("nested.base", handleNested);

  $ctrl.handleSelect = handleSelect;
  $ctrl.handleAggregationChecked = handleAggregationChecked;

  $ctrl.types = [
    {
      title: "Amount of not resolved requests",
      label: "Absolute Numbers",
      default: true,
    },
    {
      title: "Percentage of not resolved requests",
      label: "Percentage",
      getValue: getOpenPercentage,
      totalLabels: "%",
    },
  ];

  let _aggregate, _nested, _selectedType;

  function handleNested(n) {
    _nested = n;
    mkStats();
  }

  function handleSelect(item) {
    _selectedType = item;
    mkStats();
  }

  function handleAggregationChecked(a) {
    _aggregate = a;
    mkStats();
  }

  function getValuesFromData(data) {
    const getValue = _selectedType.getValue || getOpenCount;
    return data.map(({ ticks, title, key, color }) => {
      const line = {
        title,
        key,
        color,
        ticks: ticks.map(convert),
        label: "N/A",
      };
      if (ticks.length > 0) {
        line.total = line.label = line.ticks[0].value;
        if (_selectedType.totalLabels) {
          line.label = `${line.total}${_selectedType.totalLabels}`;
        }
      }
      return line;
    });

    function convert(tick) {
      return {
        dt: tick.dt,
        tooltip: tick.tooltip,
        value: getValue(tick),
      };
    }
  }

  function mkStats() {
    if (_nested === undefined) return;
    if (_aggregate === undefined) return;
    if (_selectedType === undefined) return;

    const data = _aggregate
      ? [AnalyticsBurndownDataTotalService.total(_nested)]
      : _nested;

    $ctrl.dispatcher.call("update", getValuesFromData(data), {
      yPrefix: _selectedType.totalLabels,
    });
  }
}

function getOpenCount({ open }) {
  return open;
}

function getOpenPercentage({ open, total }) {
  if (total === 0) return 0;
  return Math.round((open / total) * 100);
}
