import {
  SubscriptionActivateHelp,
  SubscriptionSelect,
} from "@shared/ui/billing";
import { loadStripe } from "@stripe/stripe-js/pure";

import { PaymentTypes } from "@setups/enums";
import { Guides, showPendoGuideById } from "@app/insight";
import templates_confirmDialogHtml from "../templates/confirm-dialog.html?raw";
import cardElementHtml from "./card-element.html?raw";
import discountHtml from "./discount.html?raw";
import invoicesHtml from "./invoices.html?raw";
import subscriptionHtml from "./subscription.html?raw";

angular
  .module("dealroom.billing", [
    "ngMessages",
    "ui.router",
    "angular.filter",
    "dealroom.config",
    "dealroom.room",
    "ngVue",
  ])
  .provider("RoomBillingAPIService", BillingAPIProviderBuilder("room"))
  .provider("ClientBillingAPIService", BillingAPIProviderBuilder("client"))
  .provider("RoomBillingService", BillingProviderBuilder("room"))
  .provider("ClientBillingService", BillingProviderBuilder("client"))
  .component("billingSubscription", billingSubscription())
  .component("billingStripeCard", billingStripeCard())
  .component("billingInvoices", billingInvoices())
  .component("billingDiscount", billingDiscount())
  .component("roomActivateSupportButton", roomActivateSupportButton())
  .component("billingCreditCardIcon", billingCreditCardIcon())
  .value("VueSubscriptionSelect", SubscriptionSelect)
  .value("VueSubscriptionActivateHelp", SubscriptionActivateHelp);

function BillingAPIProviderBuilder(billingType) {
  const apiPath = `api:billing:${billingType}s`;
  return function () {
    this.$get = [
      "$http",
      "$window",
      "URLS",
      "RoomConfig",
      "ORG_MEMBER_DATA",
      function ($http, $window, URLS, RoomConfig, ORG_MEMBER_DATA) {
        const objId =
          billingType === "room" ? RoomConfig.id : ORG_MEMBER_DATA.client.id;
        return {
          information: information,
          subscribe: subscribe,
          cancel: cancel,
          reactivate: reactivate,
          payInvoice: payInvoice,
          addSource: addSource,
          addDiscount: addDiscount,
          removeDiscount: removeDiscount,
        };

        function information() {
          return $http.get(URLS[`${apiPath}:info`](objId));
        }

        function subscribe(data) {
          const url = URLS[`${apiPath}:subscription`](objId);
          return $http.post(url, data);
        }

        function cancel() {
          const url = URLS[`${apiPath}:subscription`](objId);
          return $http.delete(url);
        }

        function reactivate() {
          const url = URLS[`${apiPath}:subscription-reactivate`](objId);
          return $http.post(url, {});
        }

        function payInvoice(number) {
          const url = URLS[`${apiPath}:subscription-invoice-pay`](
            objId,
            number,
          );
          return $http.post(url, {});
        }

        function addSource(data) {
          const url = URLS[`${apiPath}:sources`](objId);
          return $http.post(url, data);
        }

        function addDiscount(data) {
          const url = URLS[`${apiPath}:discount`](objId);
          return $http.post(url, data);
        }

        function removeDiscount(data) {
          const url = URLS[`${apiPath}:discount`](objId);
          return $http.delete(url, data);
        }
      },
    ];
  };
}

function BillingProviderBuilder(billingType) {
  return function () {
    this.$get = [
      "$q",
      "$window",
      "AlertService",
      "RoomBillingAPIService",
      "ClientBillingAPIService",
      function (
        $q,
        $window,
        AlertService,
        RoomBillingAPIService,
        ClientBillingAPIService,
      ) {
        const apiService =
          billingType === "room"
            ? RoomBillingAPIService
            : ClientBillingAPIService;
        const service = {
          billingType,
          loadingInfo: false,
          updateInProgress: false,
          subscription: undefined,
          invoices: undefined,
          source: undefined,
          syncBillingInfo: syncBillingInfo,
          subscribe: subscribe,
          cancel: cancel,
          reactivate: reactivate,
          addCardSource: addCardSource,
          addDiscount: addDiscount,
          removeDiscount: removeDiscount,
          payInvoice: payInvoice,
        };
        let billingSyncPromise;
        return service;

        function syncBillingInfo() {
          if (service.loadingInfo) {
            return billingSyncPromise;
          }
          service.loadingInfo = true;
          billingSyncPromise = apiService
            .information()
            .then(
              (resp) => {
                service.email = resp.data.email;
                service.subscription = resp.data.subscription;
                service.invoices = resp.data.invoices;
                service.source = resp.data.source;
                service.discount = resp.data.discount;
              },
              (errorResp) => {
                AlertService.danger("Failed to load subscription information.");
                return $q.reject(errorResp);
              },
            )
            .finally(() => {
              service.loadingInfo = false;
            });
          return billingSyncPromise;
        }

        function cancel() {
          if (service.updateInProgress) {
            return;
          }
          service.updateInProgress = true;
          apiService
            .cancel()
            .then(
              (resp) => {
                window.location.reload();
                return resp;
              },
              (errorResp) => {
                AlertService.danger("Failed to cancel.");
                return $q.reject(errorResp);
              },
            )
            .finally(() => {
              service.updateInProgress = false;
            });
        }

        function reactivate() {
          if (service.updateInProgress) {
            return;
          }
          service.updateInProgress = true;
          apiService
            .reactivate()
            .then(
              (resp) => {
                syncBillingInfo();
              },
              (errorResp) => {
                AlertService.danger("Failed to reactivate.");
                return $q.reject(errorResp);
              },
            )
            .finally(() => {
              service.updateInProgress = false;
            });
        }

        function addCardSource(source, setDefault) {
          if (service.updateInProgress) {
            return;
          }
          service.updateInProgress = true;
          const data = {
            id: source.id,
            type: source.type,
            default: !!setDefault,
          };
          return apiService
            .addSource(data)
            .then((resp) => {
              syncBillingInfo();
            })
            .finally(() => {
              service.updateInProgress = false;
            });
        }

        function subscribe(sub_title) {
          if (service.updateInProgress) {
            return;
          }
          service.updateInProgress = true;
          const data = {
            sub_title: sub_title,
          };
          apiService
            .subscribe(data)
            .then(
              (resp) => {
                $window.location.reload();
              },
              (errorResp) => {
                let msg = "Failed to subscribe.";
                if (errorResp.data && errorResp.data.detail) {
                  msg += " " + errorResp.data.detail;
                }
                AlertService.danger(msg);
                return $q.reject(errorResp);
              },
            )
            .finally(() => {
              service.updateInProgress = false;
            });
        }

        function payInvoice(invoice) {
          if (service.updateInProgress) {
            return;
          }
          service.updateInProgress = true;
          return apiService
            .payInvoice(invoice.number)
            .then(
              (resp) => {
                syncBillingInfo();
                return resp;
              },
              (errorResp) => {
                AlertService.danger("Failed to pay invoice.");
                return $q.reject(errorResp);
              },
            )
            .finally(() => {
              service.updateInProgress = false;
            });
        }

        function addDiscount(coupon_code) {
          if (service.updateInProgress) {
            return;
          }
          service.updateInProgress = true;
          return apiService
            .addDiscount({ coupon_code })
            .then(
              (resp) => {
                syncBillingInfo();
                return resp;
              },
              (errorResp) => {
                let msg = "Failed to apply coupon code.";
                if (errorResp.data && errorResp.data.detail) {
                  msg += " " + errorResp.data.detail;
                }
                AlertService.danger(msg);
                return $q.reject(errorResp);
              },
            )
            .finally(() => {
              service.updateInProgress = false;
            });
        }

        function removeDiscount() {
          if (service.updateInProgress) {
            return;
          }
          service.updateInProgress = true;
          return apiService
            .removeDiscount()
            .then(
              (resp) => {
                syncBillingInfo();
                return resp;
              },
              (errorResp) => {
                let msg = "Failed to remove discount";
                if (errorResp.data && errorResp.data.detail) {
                  msg += " " + errorResp.data.detail;
                }
                AlertService.danger(msg);
                return $q.reject(errorResp);
              },
            )
            .finally(() => {
              service.updateInProgress = false;
            });
        }
      },
    ];
  };
}

function roomActivateSupportButton() {
  return {
    template: `
    <span>
    <button class="btn btn-primary btn-lg" 
      ng-if="!$ctrl.isLink"
      ng-click="$ctrl.activate()">
      Activate
    </button>
    <a href="" 
       ng-if="$ctrl.isLink" 
       ng-click="$ctrl.activate()">Contact Support</a>
</span>`,
    bindings: {
      isLink: "<",
    },
    controller: [
      "FeedbackService",
      function (FeedbackService) {
        const $ctrl = this;
        $ctrl.activate = FeedbackService.activate;
      },
    ],
  };
}

function billingDiscount() {
  return {
    template: discountHtml,
    controller: [
      "$uibModal",
      "RoomConfig",
      "RoomBillingService",
      "ClientBillingService",
      function (
        $uibModal,
        RoomConfig,
        RoomBillingService,
        ClientBillingService,
      ) {
        const $ctrl = this;
        $ctrl.billing =
          RoomConfig.payment_type === "stripe_room"
            ? RoomBillingService
            : ClientBillingService;

        $ctrl.confirmRemoveDiscount = function () {
          $uibModal.open({
            template: templates_confirmDialogHtml,
            controllerAs: "ctrl",
            controller: [
              "$scope",
              "$uibModalInstance",
              function ($scope, $uibModalInstance) {
                this.modalOptions = {
                  headerText: `Remove discount`,
                  actionButtonText: "Remove",
                  bodyText: "Are you sure to remove the discount?",
                };

                this.submit = function () {
                  $ctrl.billing.removeDiscount();
                  $uibModalInstance.close();
                };
              },
            ],
          });
        };
      },
    ],
  };
}

function billingStripeCard() {
  return {
    template: cardElementHtml,
    bindings: {
      complete: "=",
      registerSubmit: "&",
    },
    controller: [
      "$q",
      "$element",
      "$timeout",
      "STRIPE_PUBLIC_KEY",
      "RoomConfig",
      "drSafeApply",
      function (
        $q,
        $element,
        $timeout,
        STRIPE_PUBLIC_KEY,
        RoomConfig,
        drSafeApply,
      ) {
        const $ctrl = this;
        let stripe;
        let cardElement;
        $ctrl.stripeInitialized = false;

        $ctrl.$onInit = function () {
          $ctrl.complete = false;

          loadStripe(STRIPE_PUBLIC_KEY).then((stripe) => {
            initializeStripeElements(stripe);
          });
        };

        function initializeStripeElements(stripe) {
          $ctrl.stripeInitialized = true;
          const cardDomElement = $element.find(".billing-card__element")[0];
          const alertDomElement = $element.find(".billing-card__alerts")[0];

          const elements = stripe.elements();
          cardElement = elements.create("card");
          cardElement.mount(cardDomElement);

          cardElement.addEventListener("change", function (event) {
            $ctrl.complete = event.complete;
            alertDomElement.textContent = event.error
              ? event.error.message
              : "";
            drSafeApply.apply();
          });

          function submitCard() {
            return stripe.createSource(cardElement, { type: "card" });
          }
          $ctrl.registerSubmit({ method: submitCard });
          drSafeApply.apply();
        }
      },
    ],
  };
}

function billingSubscription() {
  return {
    template: subscriptionHtml,
    controller: [
      "$element",
      "$timeout",
      "$q",
      "WEBSITE",
      "AlertService",
      "RoomConfig",
      "UserData",
      "RoomBillingService",
      "ClientBillingService",
      function (
        $element,
        $timeout,
        $q,
        WEBSITE,
        AlertService,
        RoomConfig,
        UserData,
        RoomBillingService,
        ClientBillingService,
      ) {
        const $ctrl = this;
        $ctrl.stripeCheckoutInitialized = false;
        $ctrl.WEBSITE = WEBSITE;
        $ctrl.UserData = UserData;
        $ctrl.RoomConfig = RoomConfig;
        $ctrl.activate = activate;
        $ctrl.cancel = cancel;
        $ctrl.submitCard = undefined;
        $ctrl.registerSubmitCard = registerSubmitCard;
        $ctrl.updatePaymentMethod = updatePaymentMethod;
        $ctrl.isSendInvoicesBilling = isSendInvoicesBilling;
        $ctrl.selectPlan = selectPlan;
        $ctrl.selectedPlan = undefined;
        $ctrl.planPaymentTypes = RoomConfig.id
          ? [PaymentTypes.StripeClient, PaymentTypes.StripeRoom]
          : [PaymentTypes.StripeClient, PaymentTypes.PipelineOnly];
        $ctrl.showCardUpdate = false;

        function setBilling(isRoomBilling) {
          $ctrl.billing = isRoomBilling
            ? RoomBillingService
            : ClientBillingService;
          $ctrl.billing.syncBillingInfo();
        }

        setBilling(!!RoomConfig.id);

        function registerSubmitCard(method) {
          $ctrl.submitCard = method;
        }

        function activate() {
          if (!$ctrl.selectedPlan) {
            AlertService.danger("Please select pricing plan.");
            return false;
          }
          const plan = $ctrl.selectedPlan;
          if ($ctrl.billing.source && $ctrl.billing.source.type) {
            $ctrl.billing.subscribe(plan);
          } else {
            updatePaymentMethod(plan);
          }
        }

        function cancel() {
          if (WEBSITE.IS_DEALROOM) {
            $ctrl.billing.cancel();
          } else {
            showPendoGuideById(Guides.FR_Cancel_Subscription_Button).then(
              () => {
                $ctrl.billing.cancel();
              },
            );
          }
        }

        function isSendInvoicesBilling() {
          return (
            $ctrl.billing.subscription &&
            $ctrl.billing.subscription.collection_method === "send_invoice"
          );
        }

        function selectPlan(plan) {
          $ctrl.selectedPlan = plan.title;
          const isRoomBillingType = $ctrl.billing.billingType === "room";
          const isRoomPlanSelected =
            plan.payment_type === PaymentTypes.StripeRoom;
          if (isRoomPlanSelected !== isRoomBillingType) {
            setBilling(isRoomPlanSelected);
          }
        }

        function updatePaymentMethod(subscribeToPlan) {
          if (!$ctrl.cardValid) {
            AlertService.danger("Please enter valid payment card details.");
            return false;
          }
          $ctrl.billing.updateInProgress = true;
          $ctrl.submitCard().then(stripeCardSourceCreateHandler, () => {
            $ctrl.billing.updateInProgress = false;
            AlertService.danger(
              "Failed to add card. Please try again or contact our support.",
            );
          });

          function stripeCardSourceCreateHandler(result) {
            $ctrl.billing.updateInProgress = false;
            if (result.error) {
              $timeout(() => {
                AlertService.danger(result.error.message);
              });
              return;
            }
            const source = result.source;
            // check if the card supports 3DS
            if (source.card.three_d_secure === "required") {
              $timeout(() => {
                AlertService.danger(
                  "This card can not be used for subscriptions.",
                );
              });
              return;
            }
            $ctrl.billing.addCardSource(source, true).then(
              (resp) => {
                if (subscribeToPlan) {
                  $ctrl.billing.subscribe(subscribeToPlan);
                }
                $ctrl.showCardUpdate = false;
              },
              (errorResp) => {
                $timeout(() => {
                  let error = "Failed to add a new card. ";
                  if (errorResp.data.non_field_errors) {
                    error += errorResp.data.non_field_errors.join(", ");
                  } else {
                    error += "Please try again or contact our support.";
                  }
                  AlertService.danger(error);
                });
              },
            );
          }
        }
      },
    ],
  };
}

function billingInvoices() {
  return {
    template: invoicesHtml,
    bindings: {
      invoices: "<",
      showPayNow: "<",
    },
    controller: [
      "AlertService",
      "RoomBillingService",
      function (AlertService, RoomBillingService) {
        const $ctrl = this;
        $ctrl.pay = function (invoice) {
          RoomBillingService.payInvoice(invoice).then(
            (resp) => {},
            (errorResp) => {
              const msg = errorResp.data.detail || "";
              AlertService.danger("Failed to pay invoice.\n" + msg);
            },
          );
        };
      },
    ],
  };
}

function billingCreditCardIcon() {
  return {
    template: '<i class="color-gray fa {{ $ctrl.getIcon() }}"></i>',
    bindings: {
      brand: "<",
    },
    controller: function () {
      const $ctrl = this;
      const branIcons = {
        "American Express": "cc-amex",
        "Diners Club": "cc-diners-club",
        Discover: "cc-discover",
        JCB: "cc-jcb",
        MasterCard: "cc-mastercard",
        Visa: "cc-visa",
      };
      const fallbackIcon = "cc";
      $ctrl.getIcon = function () {
        return "fa-" + (branIcons[$ctrl.brand] || fallbackIcon);
      };
    },
  };
}
