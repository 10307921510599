<template>
  <div class="billing-sub-activate-help__wrapper">
    <div v-if="!isSubmitted" class="billing-sub-activate-help__contact-wrapper">
      <div class="billing-sub-activate-help__contact-text">
        Need help activating your subscription or would like to activate a
        different plan?
      </div>
      <div class="billing-sub-activate-help__contact-button">
        <ElButton :loading="isSubmitting" @click="activate">
          Contact us
        </ElButton>
      </div>
    </div>
    <div v-if="isSubmitted" class="billing-sub-activate-help__success">
      Thanks for your interest in our product.<br />
      Account executives will contact you shortly.
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { FeedbackApiService } from "@drVue/api-service/common/feedback";

interface Data {
  isSubmitting: boolean;
  isSubmitted: boolean;
}

export default defineComponent({
  name: "SubscriptionActivateHelp",
  data(): Data {
    return {
      isSubmitting: false,
      isSubmitted: false,
    };
  },
  methods: {
    activate() {
      this.isSubmitting = true;
      new FeedbackApiService()
        .activate(true)
        .then(() => {
          this.isSubmitted = true;
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
});
</script>
