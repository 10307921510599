export default function drDocumentsMatchText() {
  return {
    bindings: {
      text: "<",
    },
    template:
      '<div class="documents-list-table__match-elem dont-break-out"' +
      ' ng-bind-html="$ctrl.html"' +
      ' ng-if="$ctrl.text"' +
      "></<div>",
    controller: [
      "$sanitize",
      "$scope",
      function ($sanitize, $scope) {
        var $ctrl = this;
        $scope.$watch("$ctrl.text", function () {
          if ($ctrl.text) {
            // use search engine's highlighted excerpt directly;
            // sanitize html by removing unsafe tags
            $ctrl.html = $sanitize($ctrl.text);
          }
        });
      },
    ],
  };
}
