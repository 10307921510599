<template>
  <div :class="$style.container">
    <div :class="$style.header">{{ titleText }}</div>
    <DrDynamicFormClassic
      ref="formRef"
      :schema="schema"
      :entity="localEntity"
      :errors="formErrors"
      @update="handleUpdate"
    />
    <div :class="$style.footer">
      <ElButton @click="handleCancel">Cancel</ElButton>
      <ElButton type="primary" @click="handleSubmit">{{ submitText }}</ElButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { cloneDeep } from "lodash-es";
import { isEqual } from "lodash-es";
import { computed, ref, watch } from "vue";
import { DrDynamicFormClassic } from "@shared/ui/dr-dynamic-form";
import { validateString } from "@shared/ui/dr-dynamic-form/utils";

import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";

import type { DealTask, DealTaskUpdatePayload } from "../use-deal-tasks";
import type { FormSchema } from "@shared/ui/dr-dynamic-form/types";
import type { FormErrors } from "@shared/ui/dr-form/useFormHelper";

// allow edit exist task
type DealTaskFormData = Partial<DealTask>;

interface Props {
  modelValue: DealTaskFormData;
  formErrors: FormErrors<DealTaskUpdatePayload>;
  groupedAssignees: {
    label: string;
    options: { label: string; value: string; isDisabled?: true }[];
  }[];
}
const props = defineProps<Props>();

interface Emits {
  (e: "update:modelValue", arg: DealTaskFormData): void;
  (e: "submit"): void;
  (e: "cancel"): void;
}
const emit = defineEmits<Emits>();

const localEntity = ref(cloneDeep(props.modelValue));
const formRef = ref<InstanceType<typeof DrDynamicFormClassic> | null>(null);

watch(
  () => props.modelValue,
  (newModelValue) => {
    if (!isEqual(localEntity.value, newModelValue)) {
      localEntity.value = cloneDeep(newModelValue);
      formRef.value?.reset({ values: cloneDeep(newModelValue) });
    }
  },
);

const titleText = computed(() => {
  if (props.modelValue.id) {
    return "Edit task";
  }

  return "New task";
});

const submitText = computed(() => {
  if (props.modelValue.id) {
    return "Update task";
  }

  return "Create task";
});

const handleUpdate = (arg: { field: string; value: any }) => {
  const field = arg.field as keyof DealTaskUpdatePayload;
  localEntity.value[field] = arg.value;
  emit("update:modelValue", localEntity.value);
};

const groupedAssignees = computed(() => {
  const groupedOptions = [...props.groupedAssignees];

  if (props.modelValue.assigned_to_id) {
    const assignedIdInOptions = groupedOptions.some((group) =>
      group.options.some(
        (option) => option.value === props.modelValue.assigned_to_id,
      ),
    );

    if (!assignedIdInOptions) {
      groupedOptions.unshift({
        label: "Members without access",
        options: [
          {
            label: "Anonymous",
            value: props.modelValue.assigned_to_id,
            isDisabled: true,
          },
        ],
      });
    }
  }

  return groupedOptions;
});

const schema = computed<FormSchema<any, DealTaskUpdatePayload>>(() => [
  {
    label: "Title",
    prop: "title",
    required: true,
    type: FieldSchemaType.Text,
    placeholder: "Enter the title...",
    rules: validateString()
      .required("Title is required")
      .max(300, "The title must not exceed 300 characters"),
    extra: {
      autofocus: true,
    },
  },
  {
    label: "Description",
    prop: "description",
    required: false,
    type: FieldSchemaType.Richtext,
    placeholder: "Write description",
  },
  {
    label: "Due Date",
    prop: "due_datetime",
    required: true,
    type: FieldSchemaType.DateTime,
    rules: validateString().required("Due Date is required"),
  },
  {
    label: "Assign to",
    prop: "assigned_to_id",
    required: false,
    type: FieldSchemaType.Select,
    extra: {
      select_options_grouped: groupedAssignees.value,
    },
  },
]);

const handleCancel = () => {
  formRef.value?.reset();
  emit("cancel");
};

const handleSubmit = async () => {
  if (!formRef.value) return;

  const isValid = await formRef.value.validate();

  if (isValid) {
    emit("submit");
  }
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.container {
  border: 1px solid colors.$pr-200;
  border-radius: 8px;
  padding: 16px 20px;
  background: white;
  box-shadow: 0 2px 3px 0 rgba(9, 25, 56, 0.05);
  margin-bottom: 8px;
}

.header {
  font: typography.$subtitle_semibold;
  margin-bottom: 12px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid colors.$pr-150;
  margin: 20px -20px 0 -20px;
  padding: 12px 20px 0 20px;
}
</style>
