<template>
  <FindingsCreatePanel ref="createFindingPanelRef" />

  <DrItemList :items="linkedFindings">
    <template #header>
      <DrItemListHeader
        title="Findings"
        :light-weight="!linkedFindings.length"
        :allow-action="!viewOnly"
        @action="addFinding"
      />
    </template>
    <template #item="{ item }">
      <FindingItem
        :id="item.id"
        :key-string="item.key"
        :title="item.title"
        :key-color="findingsStatusesColorsDict[item.status_id]"
        :type-color="findingsTypesColorsDict[item.type_id]"
        :assignee-uids="item.assignees"
        :disallow-remove="viewOnly"
        @remove="handleRemove"
        @click="handleClick"
      />
    </template>
  </DrItemList>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { DrItemList, DrItemListHeader } from "@shared/ui/dr-item-list";

import { insightTrack, TaskDetailsTrackingEvent } from "@app/insight";
import { $notifyDanger } from "@drVue/common";
import FindingsCreatePanel from "@drVue/components/room/findings/FindingsCreatePanel.vue";
import { pinia } from "@drVue/store/pinia";
import { useFindingsStatusesStore } from "@drVue/store/pinia/pipeline/findings-statuses";
import { useFindingsTypesStore } from "@drVue/store/pinia/pipeline/findings-types";
import { useFindingsStore } from "@drVue/store/pinia/room/findings";
import { useTasksStore } from "@drVue/store/pinia/room/tasks";
import FindingItem from "./FindingItem.vue";

import type { HEXColor } from "@drVue/utils/color";

interface Props {
  taskUid: string;
  viewOnly: boolean;
}

const props = defineProps<Props>();

const tasksStore = useTasksStore(pinia);

const createFindingPanelRef = ref<InstanceType<
  typeof FindingsCreatePanel
> | null>(null);

const addFinding = () =>
  createFindingPanelRef.value?.open({
    taskUid: props.taskUid,
  });

const findingsStore = useFindingsStore(pinia);
const linkedFindings = computed(
  () => findingsStore.listByTaskUid[props.taskUid] ?? [],
);

const findingsStatusesStore = useFindingsStatusesStore(pinia);
const findingsStatusesColorsDict = computed(() =>
  findingsStatusesStore.list.reduce(
    (acc, item) => {
      acc[item.id] = item.color;
      return acc;
    },
    {} as Record<string, HEXColor>,
  ),
);

const findingsTypesStore = useFindingsTypesStore(pinia);
const findingsTypesColorsDict = computed(() =>
  findingsTypesStore.list.reduce(
    (acc, item) => {
      acc[item.id] = item.color;
      return acc;
    },
    {} as Record<string, HEXColor>,
  ),
);

const handleRemove = async (findingId: string) => {
  try {
    await tasksStore.unlinkFinding(props.taskUid, findingId);
  } catch {
    $notifyDanger("Failed to unlink finding from task");
  }
};

const handleClick = () => insightTrack(TaskDetailsTrackingEvent.FindingOpened);

/**
 * We specifically use `findingsStore.listByTaskUid[props.taskUid]` (instead of `linkedFindings`)
 * to get a "number" or "undefined", then comparisons will be performed
 * only when the number of `linkedFindings` actually changes,
 * and not at the start (when the "oldCount" value is undefined).
 */
watch(
  () => findingsStore.listByTaskUid[props.taskUid]?.length,
  (newCount, oldCount) => {
    if (newCount > oldCount) {
      insightTrack(TaskDetailsTrackingEvent.FindingAdded);
    }
    if (newCount < oldCount) {
      insightTrack(TaskDetailsTrackingEvent.FindingRemoved);
    }
  },
);
</script>

<style scoped lang="scss"></style>
