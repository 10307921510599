import tableHtml from "../table.html?raw";

UsersDatagrid.$inject = [
  "RoomConfig",
  "PermissionsService",
  "ActivityGroupPermissionsVisibleService",
];
import * as d3 from "d3";

import DrStore from "@drVue/store";
import getCells from "./cells";

angular
  .module("dealroom.analytics.datagrid-table.users", [
    "dealroom.analytics.datagrid-table",
    "dealroom.analytics.service.data",
    "dealroom.members",
  ])
  .component("drUsersDatagrid", {
    bindings: {
      cls: "@",
      type: "@",
    },
    template: tableHtml,
    controller: UsersDatagrid,
  });

function UsersDatagrid(
  RoomConfig,
  PermissionsService,
  ActivityGroupPermissionsVisibleService,
) {
  const $ctrl = this;
  const roomHasTasks = RoomConfig.userPermissions.viewTasks;

  $ctrl.$onInit = function () {
    $ctrl.cells = getCells(roomHasTasks, $ctrl.type);
    $ctrl.activityRowCells = ["timestamp", "verb", "target"];
    $ctrl.filterBy = "users";
    $ctrl.nestByArray = ["group", "user"];
    $ctrl.tableUid = "UsersDatagrid-" + $ctrl.type;

    $ctrl.verbsForFilter = $ctrl.type === "documents" ? true : undefined;
    $ctrl.subParentFieldName = undefined;
  };

  $ctrl.getRootItems = getRootItems;
  $ctrl.getRowFromItem = getRowFromItem;

  function getRowFromItem(item) {
    if (item.pgroup !== undefined) return userRow(item);
    if (item.is_administrator !== undefined) return pgroupRow(item);
    return console.error(
      "GetRowFromItem: Unknown type",
      item,
      angular.copy(item.activeMembers),
    );

    function userRow(user) {
      if (user.pgroup === undefined) return {};
      return {
        type: "user",
        id: user.id,
        sortName: user.name,
      };
    }

    function pgroupRow(pgroup) {
      const row = {
        type: "group",
        id: pgroup.id,
        class: "datagrid-table__row--top",
        sortName: pgroup.name,
      };
      let members = DrStore.getters["room/members/activeByGroupId"](pgroup.id);
      members = members?.filter((m) => !m.pending).sort(sortMembers);
      if (members?.length > 0) {
        row.getOrderedChilds = () => members;
      }
      return row;
    }
  }

  function getRootItems() {
    if (!ActivityGroupPermissionsVisibleService.pgroupIds) return [];
    return ActivityGroupPermissionsVisibleService.visiblePgroupIds
      .map((pgroupId) => PermissionsService.pgroups[pgroupId])
      .sort(sortGroups);
  }

  function sortMembers(a, b) {
    return d3.descending(a.name.toLowerCase(), b.name.toLowerCase());
  }

  function sortGroups(a, b) {
    return d3.ascending(a.id, b.id);
  }
}
