<template>
  <DrPopup
    v-if="tasksIds.length"
    placement="bottom"
    width="200"
    trigger="click"
    popper-class="related-requests-popover"
  >
    <div class="related-requests-popover__title">Related Requests</div>
    <div v-for="c in categories" :key="c.catId">
      <p class="related-requests-popover__category">
        {{ getCategoryAncestors(c.catId) || "" }}
      </p>
      <div v-for="t in c.tasks" :key="t.id">
        <a href="" @click.prevent.stop="openTaskDetails(t.key)"
          >#{{ t.key }} {{ t.title }}</a
        >
      </div>
    </div>
    <template #reference>
      <div>
        <ElTooltip content="Related Requests" placement="top">
          <span>
            <i class="far fa-tasks" style="padding: 6px 4px" />
            {{ tasksIds ? tasksIds.length : "" }}
          </span>
        </ElTooltip>
      </div>
    </template>
  </DrPopup>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrPopup from "@shared/ui/dr-popups/DrPopup.vue";

import { DOC_ITEM_TYPES } from "@drVue/store/modules/room/documents/DocumentsApiService";

import type { DocsItem } from "@drVue/store/modules/room/documents/DocumentsApiService";
import type { Category } from "@drVue/store/pinia/room/categories";
import type { Task, TaskId } from "@drVue/store/pinia/room/tasks";
import type { Dictionary } from "@drVue/types";
import type { PropType } from "vue";

interface RelatedCategory {
  catId: number;
  order: number;
  tasks: Task[];
}

export default defineComponent({
  name: "RelatedRequestsCell",
  components: { DrPopup },
  props: {
    row: { required: true, type: Object as PropType<DocsItem> },
    tasksService: { required: true, type: Object as PropType<any> },
    categoriesService: { required: true, type: Object as PropType<any> },
  },
  computed: {
    isTasksServiceLoading(): any {
      if (!this.tasksService) return true;
      return this.tasksService.tasksLoading;
    },
    isCategoriesServiceLoading(): any {
      if (!this.categoriesService) return true;
      return this.categoriesService.loading;
    },
    tasksIds(): number[] {
      if (this.isTasksServiceLoading || this.isCategoriesServiceLoading) {
        return [];
      }

      let relatedTasks: TaskId[] | null = null;

      const getFolderTasks = "room/tasksRelated/getFolderTasks";
      const getDocumentTasks = "room/tasksRelated/getDocumentTasks";

      if (this.row.type === DOC_ITEM_TYPES.Folder) {
        relatedTasks = this.$store.getters[getFolderTasks](this.row.id) || [];
      }

      if (this.row.type === DOC_ITEM_TYPES.Document) {
        relatedTasks = this.$store.getters[getDocumentTasks](this.row.id) || [];
      }

      if (relatedTasks === null) throw new Error("Unsupported row type.");

      return relatedTasks.map((task) => task.task_id) as number[];
    },
    categories(): RelatedCategory[] {
      const relatedTasksByCategories: Dictionary<Task[]> = {};

      this.tasksIds.forEach((taskId: number) => {
        const task = this.tasksService.tasks[taskId];
        if (!task) return;

        const tasks = relatedTasksByCategories[task.category_id] || [];
        tasks.push(task);

        relatedTasksByCategories[task.category_id] = tasks;
      });

      const categories = Object.keys(relatedTasksByCategories).reduce(
        (acc, catId) => {
          const tasks = relatedTasksByCategories[catId];
          tasks.sort((a, b) => a.order - b.order);

          acc.push({
            catId: parseInt(catId),
            order: this.categoriesService.categories[catId].treePosition,
            tasks: tasks,
          });

          return acc;
        },
        [] as RelatedCategory[],
      );

      categories.sort(
        (a: RelatedCategory, b: RelatedCategory) => a.order - b.order,
      );

      return categories;
    },
  },
  methods: {
    getCategoryAncestors(categoryId: number) {
      if (this.isCategoriesServiceLoading) return null;

      const parent = this.categoriesService.categories[categoryId];
      const ancestors = parent.parents.map((c: Category) => c.name);

      ancestors.push(parent.name);

      return ancestors.join(" / ");
    },
    openTaskDetails(key: string) {
      this.tasksService.openTaskDetails(key);
    },
  },
});
</script>
