import type {
  Document,
  Folder,
} from "@drVue/store/modules/room/documents/DocumentsApiService";
import type { Dictionary } from "@drVue/types";

export class DocumentsState {
  public rootFolder: Folder | null = null;

  public folders: Dictionary<Folder> = {};
  public files: Dictionary<Document> = {};
  public foldersByUid: Dictionary<Folder> = {};
  public filesByUid: Dictionary<Document> = {};

  public foldersList: Folder[] = [];
  public filesList: Document[] = [];

  public folderMaxNestedPathsLengths: Dictionary<number> = {};

  public isLoading: boolean | null = null;
  public isError: boolean | null = null;
}
