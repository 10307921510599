<template>
  <div style="width: 100%">
    <DrTagsInput
      ref="input"
      v-model="tag"
      :tags="tags"
      :validation="validation"
      :autocomplete-items="filteredItems"
      :allow-edit-tags="true"
      placeholder="Enter email(s)"
      @tags-changed="onTagsChanged"
      @before-adding-tag="onBeforeAddingTag"
      @before-saving-tag="onBeforeSavingTag"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrTagsInput from "@shared/ui/dr-email-input/DrTagsInput";
import { createTags } from "@sipec/vue3-tags-input";

import { isStringContains } from "@drVue/common";
import parse from "@drVue/components/client-dashboard/users/AddUserModal/emails-parser";

import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type {
  IBeforeAddingTagParam,
  IBeforeSavingTagParam,
  ITag,
  IValidationRule,
} from "@sipec/vue3-tags-input";
import type { PropType } from "vue";

export interface Email {
  address: string;

  isValid?: boolean;
}

const tagInputInvalidClass = "ti-invalid-email";

interface Data {
  tag: string;
  tags: ITag[];
  validation: IValidationRule[];
}

export default defineComponent({
  name: "DrEmailInput",
  components: {
    DrTagsInput,
    //VueTagsInput,
  },
  inject: {
    form: { from: "elForm", default: null },
    formItem: { from: "elFormItem", default: null },
  },
  props: {
    modelValue: { required: true, type: Array as PropType<any> },
    users: { required: true, type: Array as PropType<OrgUser[]> },
  },
  emits: ["update:modelValue"],
  data(): Data {
    return {
      tag: "",
      tags: [],
      validation: [
        {
          classes: tagInputInvalidClass,
          rule: (tag: ITag) => {
            const result = parse(tag.text);

            const hasOnlyOneEmail = result.parsed.length === 1;
            const hasErrors = result.errors.length > 0;

            return !hasOnlyOneEmail || hasErrors;
          },
          disableAdd: false,
        },
      ],
    };
  },
  computed: {
    filteredItems(): any {
      return this.users
        .filter((u: OrgUser) => {
          if (this.tags.some((t) => t.text === u.email)) {
            // already added
            return false;
          }

          if (isStringContains(this.tag, u.email)) {
            return true;
          }

          return !u.pending && isStringContains(this.tag, u.name || "");
        })
        .map((u: OrgUser) => {
          let text = u.email;
          if (!u.pending) {
            text = `${u.name} <${u.email}>`;
          }
          return { text };
        });
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newVal: any, oldVal: any) {
        if (newVal?.length === 0 && oldVal?.length === 0) {
          // Just a ref change, returning...
          return;
        }

        const emails = this.modelValue.map((i: any) => i.address);

        this.tags = createTags(emails, this.validation);
        this.tag = "";

        const thany = this as any;
        if (thany.form && thany.formItem) {
          thany.form.validateField([thany.formItem.prop]);
        }
      },
    },
  },

  methods: {
    performAddTags() {
      const $input = this.$refs["input"] as any;
      $input.performAddTags(this.tag);
    },
    onBeforeAddingTag(val: IBeforeAddingTagParam) {
      const result = parse(val.tag.text);
      if (result.parsed.length === 1 && !result.errors.length) {
        val.tag.text = result.parsed[0];
      } else {
        if (val.tag.tiClasses.indexOf(tagInputInvalidClass) === -1) {
          val.tag.tiClasses.push(tagInputInvalidClass);
        }
      }
      val.addTag();
    },
    onBeforeSavingTag(val: IBeforeSavingTagParam) {
      const result = parse(val.tag.text);
      if (result.parsed.length === 1 && !result.errors.length) {
        val.tag.text = result.parsed[0];
        val.saveTag();
      }
    },
    onTagsChanged(tags: ITag[]) {
      const emails = tags.map(
        (t: ITag): Email => ({
          address: t.text,
          isValid: t.tiClasses.indexOf(tagInputInvalidClass) === -1,
        }),
      );
      this.$emit("update:modelValue", emails);
    },
  },
});
</script>
