<template>
  <div
    class="truncate"
    :class="$style.container"
    :style="{ backgroundColor, color: textColor }"
    @click.stop="viewProps.enterEditMode"
  >
    <DrTruncatedTextTooltip :content="text">
      {{ text }}
    </DrTruncatedTextTooltip>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import { hexToRgb, isDarkColor } from "@drVue/utils/color";

import type { SelectFieldOption } from "@drVue/api-service/client-dashboard/types";
import type { ViewFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { HEXColor } from "@drVue/utils/color";

interface Props {
  viewProps: ViewFieldProps;
}

const props = defineProps<Props>();

// TODO: Remove this?
// viewValue was a string "Option 1, Option 2" before, how is it possible to
// equal to a label?
const backgroundColor = computed<HEXColor | "transparent">(
  () =>
    props.viewProps.schema.extra?.select_options?.find(
      ({ label }: SelectFieldOption) => label === props.viewProps.entityValue,
    )?.color ?? "transparent",
);

const text = computed(() => {
  const value = props.viewProps.entityValue;
  if (!value) return "-";

  if (Array.isArray(value)) {
    return value.length ? value.join(", ") : "-";
  }

  return value;
});

const textColor = computed<HEXColor>(() => {
  if (
    backgroundColor.value === "transparent" ||
    !isDarkColor(hexToRgb(backgroundColor.value))
  ) {
    return "#000000";
  } else {
    return "#ffffff";
  }
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.container {
  border-radius: 6px;
  border: 1px solid transparent;
  padding: 2px 6px;
  height: 28px;
  line-height: 22px;
  user-select: none;
  width: 100%;

  &:hover {
    border: 1px solid colors.$sc-400;
  }
}
</style>
