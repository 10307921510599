<template>
  <DrTooltip :content="reviewerNames">
    <ViewFieldWrapper
      :is-read-only="!!viewProps.schema.isReadOnly"
      @edit="viewProps.enterEditMode"
    >
      <ParticipantsView :view-props="viewPropsWithAvatars">
        <template v-if="!!props.viewProps.entityValue.length" #icon>
          <DrSvgIcon :class="$style.reviewStatusIcon" :name="iconName" />
        </template>
      </ParticipantsView>
      <span v-if="reviewers.length === 1">{{ reviewerNames }}</span>
    </ViewFieldWrapper>
  </DrTooltip>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DrSvgIcon } from "@shared/ui/dr-svg-icon";
import { DrTooltip } from "@shared/ui/dr-tooltip";

import { DrStore } from "@app/vue";
import { ParticipantsView } from "@drVue/components/room/tasks/shared/widgets/participants";
import ViewFieldWrapper from "../ViewCellWrapper.vue";

import type { ViewFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { ParticipantUserV1, Task } from "@drVue/store/pinia/room/tasks";
import type { Avatar } from "@shared/ui/dr-avatar";

interface Props {
  viewProps: ViewFieldProps<
    ParticipantUserV1[],
    Task,
    { actionLabel: string; isReviewComplete: boolean }
  >;
}

const props = defineProps<Props>();

const reviewers = computed(() => {
  const members = DrStore.state.room.members;
  if (members.isError || !members.membersList.length) return [];

  return props.viewProps.entityValue.map(
    ({ user_id }) => members.members[user_id],
  );
});

const reviewerNames = computed(() =>
  reviewers.value
    .map(({ name }) => {
      const [firstName, lastName] = name.split(" ");
      return `${firstName[0]}. ${lastName}`;
    })
    .join(", "),
);

const viewPropsWithAvatars = computed<ViewFieldProps<Avatar[], Task>>(() => ({
  ...props.viewProps,
  entityValue: reviewers.value.map(({ name, avatar }) => ({
    name,
    url: avatar.thumbnail,
  })),
}));

const iconName = computed(() =>
  props.viewProps.schema.extra?.isReviewComplete
    ? "square-check"
    : "triangle-exclamation",
);
</script>

<style module lang="scss">
.reviewStatusIcon {
  margin-bottom: -10px;
}
</style>
