<template>
  <DrPopup
    class="popover"
    :visible="visibility"
    :persistent="false"
    :teleported="true"
    :width="250"
    popper-class="el-popover--no-padding"
    @show="onPopupShow"
  >
    <div
      :class="{
        [$style.container]: true,
        [$style.container_isBusy]: isBusy,
      }"
    >
      <DrNewLoader v-if="isBusy" overlay />
      <ElForm>
        <ElFormItem
          :class="$style.selectFormItem"
          class="el-form-item--hidden-suffix"
        >
          <!--
            Keep in mind that 'persistent' is set to 'true'! It does not mean it
            will be kept in the DOM the whole time, only while DrPopup is shown,
            which on the other hand has 'persistent' set to 'false'. There is
            an annoying bug in ElSelect that forces us to click inside of its
            input to update the values of labels inside of it. Otherwise, only
            the ID of the label is shown within the label box.
          -->
          <ElSelect
            ref="selectRef"
            :model-value="modelValue"
            :popper-class="$style.popper_hiddenSelectDropdown"
            :class="$style.elSelect_forLabels"
            :filter-method="setFilterText"
            :placeholder="editProps.schema.placeholder"
            persistent
            style="width: 100%"
            filterable
            multiple
            @update:model-value="handleModelValue"
            @remove-tag="deselectLabel"
            @keyup="createAndAddLabel"
          >
            <ElOption
              v-for="label in tasksLabels.list"
              :key="label.id"
              :value="label.id"
              :label="label.name"
            />
          </ElSelect>
        </ElFormItem>
      </ElForm>
      <hr :class="$style.line" />
      <div
        v-if="filterText.length && !exactMatchedLabel"
        :class="$style.addButtonContainer"
      >
        <!--
          Internally, ElSelect has a v-click-outside directive on its wrapper
          element. This directive calls the internal handleClose() callback
          whenever a click occurs "outside" of the wrapper (including its
          ancestors). Despite being called v-click-outside, the directive uses
          'mouseup' and 'mousedown' events. Listeners are added to the document
          object.

          To handle clicks on the button, we need to follow these steps:
            1. Use the capture phase to...
            2. call stopPropagation() before the v-click-outside 'mousedown'
               event to prevent diffing.

          This will prevent the handleClose() callback from being called.
        -->
        <ElButton
          :class="$style.addButton"
          :loading="isBusy"
          :disabled="isBusy"
          type="primary"
          plain
          size="small"
          @mousedown.capture.stop="createAndAddLabel"
        >
          Add '{{ filterText }}' label
        </ElButton>
      </div>
      <div :class="$style.labels">
        <div v-if="filteredLabels.length === 0" :class="$style.notFound">
          No labels found
        </div>
        <LabelsEditControl
          v-for="label in filteredLabels"
          :key="label.id"
          :label="label"
          :is-selected="selectedLabelsDict[label.id] ?? false"
          @click="toggleLabel(label.id)"
          @busy="onBusy"
          @update="onLabelUpdate"
          @delete="onLabelDelete"
        />
      </div>
      <hr :class="$style.line" />
      <div :class="$style.actions">
        <ElButton
          size="small"
          @click="execIfChanged(() => editProps.quitEditModeConfirm())"
          >Cancel</ElButton
        >
        <ElButton
          size="small"
          type="primary"
          :disabled="!isValueChanged"
          @click="execIfChanged(editProps.submitField)"
        >
          Save
        </ElButton>
      </div>
    </div>
    <template #reference>
      <Labels :ids="ids" @click.stop="() => editProps.quitEditModeConfirm()" />
    </template>
  </DrPopup>
</template>

<script setup lang="ts">
import { ElSelect } from "element-plus";
import { xor } from "lodash-es";
import { computed, nextTick, onMounted, ref, unref } from "vue";
import { DrNewLoader } from "@shared/ui/dr-loader";
import { DrPopup } from "@shared/ui/dr-popups";

import Labels from "@drVue/components/room/tasks/shared/widgets/labels/Labels.vue";
import LabelsEditControl from "@drVue/components/room/tasks/shared/widgets/labels/LabelsEditControl.vue";
import { pinia } from "@drVue/store/pinia";
import {
  LABEL_BG_COLOR,
  LABEL_COLOR,
  LABEL_MAX_LENGTH,
} from "@drVue/store/pinia/room/tasksLabels/constants";
import { useTasksLabelsStore } from "@drVue/store/pinia/room/tasksLabels/tasksLabels";
import { useVisibility } from "../../../TasksTable/cells/useVisibilty";

import type { EditFieldProps } from "@drVue/components/client-dashboard/dynamic-form/Fields/types";
import type { Task } from "@drVue/store/pinia/room/tasks";
import type { TaskLabel } from "@drVue/store/pinia/room/tasksLabels/tasksLabelApi";

interface Props {
  editProps: EditFieldProps<number[], Task>;
}

const tasksLabels = useTasksLabelsStore(pinia);

const selectRef = ref<InstanceType<typeof ElSelect> | null>(null);
const props = defineProps<Props>();

const isBusy = ref(false);

const initialValue = ref<number[]>([...props.editProps.value]);
const modelValue = ref<number[]>([...props.editProps.value]);
const ids = computed(
  () =>
    props.editProps.entity[
      props.editProps.schema.prop as keyof Task & "labels"
    ],
);

const isValueChanged = computed(
  () => xor(initialValue.value, modelValue.value).length > 0,
);

const onBusy = (_isBusy: boolean) => {
  isBusy.value = _isBusy;
};

/**
 * @note ElSelect component bug:
 *       when we press "Enter" in the select input field
 *       component trigger "@update:model-value" event with an incorrect value as argument
 *       so we have to get the correct value via a ref
 */
const handleModelValue = () => {
  const value = selectRef.value?.modelValue as TaskLabel["id"][];
  if (value && xor(modelValue.value, value).length) {
    updateVeeField(value);
  }
};

const syncModelValue = (resetInitial = false) => {
  modelValue.value = [...props.editProps.value];
  if (resetInitial) {
    initialValue.value = [...props.editProps.value];
  }
};

const updateVeeField = async (value: unknown) => {
  props.editProps.veeField.onChange(value);

  await nextTick();
  syncModelValue();

  selectRef.value?.blur();
  selectRef.value?.focus();
};

const deselectLabel = (labelId: number) => {
  const currentModelValue = [...modelValue.value];

  const i = currentModelValue.indexOf(labelId);
  currentModelValue.splice(i, 1);

  updateVeeField(currentModelValue);
};

const toggleLabel = (labelId: number) => {
  const currentModelValue = [...modelValue.value];

  const i = currentModelValue.indexOf(labelId);
  const isSelected = i > -1;
  if (isSelected) {
    currentModelValue.splice(i, 1);
  } else {
    currentModelValue.push(labelId);
  }

  updateVeeField(currentModelValue);
};

const onLabelUpdate = (
  label: TaskLabel,
  onDone: (isEditing: boolean) => void,
) => {
  isBusy.value = true;

  tasksLabels
    .update(label)
    .then(() => {
      onDone(false);
    })
    .finally(() => {
      selectRef.value?.focus();
      isBusy.value = false;
    });
};

const onLabelDelete = (l: TaskLabel) => {
  isBusy.value = true;

  tasksLabels
    .delete(l.id)
    .then(async () => {
      /**
       * @note at this moment the label has already been successfully
       *       removed from all tasks in the "tasks" store
       *       (in "taskLabels" store used `deleteTasksLabel` method from "tasks" store)
       *       so the `props.editProps.value` is already updated
       */
      const isSelected = modelValue.value.indexOf(l.id) > -1;
      if (isSelected) {
        props.editProps.forceUpdateValue();
        await nextTick();
        syncModelValue(true);
      }
    })
    .finally(() => {
      isBusy.value = false;
    });
};

const selectedLabelsDict = computed(() => {
  return unref(modelValue).reduce(
    (dict, id) => {
      dict[id] = true;
      return dict;
    },
    {} as Record<number, boolean>,
  );
});

const filterText = ref<string>("");
const setFilterText = (value: string) => {
  filterText.value = value.trim().slice(0, LABEL_MAX_LENGTH);
};

const exactMatchedLabel = computed((): TaskLabel | null => {
  if (filterText.value === "") return null;

  return (
    tasksLabels.list.find(
      (l) => l.name.toLowerCase() === filterText.value.toLowerCase(),
    ) ?? null
  );
});

const filteredLabels = computed(() => {
  if (filterText.value === "") return tasksLabels.list;

  return tasksLabels.list.filter((l) =>
    l.name.toLowerCase().includes(filterText.value.toLowerCase()),
  );
});

const createAndAddLabel = (e: PointerEvent | KeyboardEvent) => {
  if (e instanceof PointerEvent && e.button !== 0) return;
  if (e instanceof KeyboardEvent && e.key !== "Enter") return;

  if (exactMatchedLabel.value !== null) {
    toggleLabel(exactMatchedLabel.value.id);
    return;
  }

  isBusy.value = true;
  tasksLabels
    .create({
      name: filterText.value,
      label_color: LABEL_BG_COLOR,
      bg_color: LABEL_COLOR,
    })
    .then(async (createdLabel) => {
      filterText.value = "";
      toggleLabel(createdLabel.id);
    })
    .finally(() => {
      isBusy.value = false;
    });
};

const execIfChanged = (fn: () => void) => {
  if (isValueChanged.value) fn();
  else props.editProps.quitEditMode();
};

const visibility = useVisibility();

const onPopupShow = () => {
  selectRef.value?.inputRef?.focus();
};

onMounted(() => {
  props.editProps.setHasValueRemained(
    () => xor(initialValue.value, modelValue.value).length === 0,
  );
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

.popper_hiddenSelectDropdown {
  display: none;
}

.elSelect_forLabels {
  margin-bottom: 10px;

  :global(.el-input:not(.el-input--disabled) .el-input__wrapper) {
    box-shadow: none !important;
  }

  :global(.el-input.is-focus:not(.el-input--disabled) .el-input__wrapper) {
    box-shadow: none !important;
  }

  :global(.el-select-tags-wrapper.has-prefix) {
    margin-left: unset !important;
  }

  :global(.el-select__tags .el-tag) {
    margin-top: unset !important;
    margin-bottom: unset !important;
  }
}

.selectFormItem {
  margin-bottom: 0 !important;
}

.container {
  padding: 12px;
  background-color: #fff;
  border-radius: 6px;
  width: 250px;
  position: relative;
}

.container_isBusy {
  pointer-events: none;
}

.line {
  margin: 0 -12px;
}

.labels {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 250px;
  overflow: auto;
  margin: 12px 0;
  padding-right: 6px;
}

.notFound {
  margin-bottom: 12px;
  margin-left: 2px;
}

.actions {
  display: flex;
  justify-content: right;
  margin: 12px 0 0 0;
}

.pencil {
  font-size: 12px;
  margin-left: auto;
}

.addButtonContainer {
  margin: 6px 6px 0;
}

.addButton {
  width: 100%;
}
</style>
