import Sortable from "sortablejs";
import { onBeforeUnmount, ref, unref, watchEffect } from "vue";

import type { Ref } from "vue";
import type { TableReactData, VxeGridInstance } from "vxe-table";

const disableHover = (grid: VxeGridInstance) => {
  // setTimeout to pass the VXE. It's going to setHoverRow() right now.
  setTimeout(() => {
    const table = unref(grid.getRefMaps().refTable);
    table.clearHoverRow();

    // In VXE internals if Date.now() < lastScrollTime, then hover event is
    // being fired. We offset the lastScrollTime value to the future to disable
    // the hovering.
    table.reactData.lastScrollTime = Date.now() + 1000 * 60 * 60;
  });
};

const enableHover = (grid: VxeGridInstance) => {
  // VXE is going to setHoverRow() again, so we keep the hovering disabled for
  // a while (100 ms).
  setTimeout(() => {
    const table = unref(grid.getRefMaps().refTable);
    table.reactData.lastScrollTime = 0;
  }, 100);
};

export const useFindingsReorder = <Key = string>(
  gridRef: Ref<VxeGridInstance | null>,
  onEnd: (
    moveId: Key,
    prevId: Key | null,
    afterId: Key | null,
    callbackReorderingCompleted: () => void,
  ) => void,
  immediateActivate = false,
) => {
  let sortable: Sortable | null;
  let sortableInitTimeoutId: number | undefined;
  const isActive = ref<Boolean>(immediateActivate);

  const initSortableJS = (selector: string) => {
    destroySortableJS();

    const grid = unref(gridRef);
    if (!grid) {
      return;
    }

    const tableElement = grid.$el.querySelector(selector);
    sortable = Sortable.create(tableElement, {
      animation: 100,
      // We explicitly set forceFallback to true, as HTML5 D&D is not working
      // properly in our case with VXE table.
      forceFallback: true,

      // See <DragCell /> component for details.
      handle: ".drag-handle",

      removeCloneOnHide: true,
      onStart: (e) => {
        document.body.classList.add("grabbing");

        disableHover(grid);
      },
      onMove: (e) => {
        disableHover(grid);
        return true;
      },
      onEnd: (e) => {
        document.body.classList.remove("grabbing");

        if (e.oldIndex === e.newIndex) {
          enableHover(grid);
          return;
        }

        const isReorderUp = (e.newIndex ?? 0) < (e.oldIndex ?? 0);

        const rowId = e.item.getAttribute("rowid");
        if (!rowId) {
          enableHover(grid);
          return;
        }

        const itemToMove = grid.getRowById(rowId);

        let beforeId = null;
        let afterId = null;

        if (isReorderUp) {
          const beforeItemEl = e.item.nextElementSibling! as HTMLElement;
          const beforeItemRowId = beforeItemEl.getAttribute("rowid");

          if (!beforeItemRowId) return;

          const beforeItem = grid.getRowById(beforeItemRowId);
          beforeId = beforeItem.id;
        } else {
          const prevItemEl = e.item.previousSibling! as HTMLElement;
          const prevItemRowId = prevItemEl.getAttribute("rowid");

          if (!prevItemRowId) return;

          const prevItem = grid.getRowById(prevItemRowId);
          afterId = prevItem.id;
        }

        enableHover(grid);

        onEnd(itemToMove.id, beforeId, afterId, () => {
          /**
           * Fixing the problem of a duplicate reordred row appearing:
           ** - when we have a lot of entries
           ** - and we move the row up/down outside the current viewport of the table;
           ** - the table according virtual scrolling will re-render the set of rows
           ** - but the row added by Sortable remains.
           *
           * @note "setTimeout" is needed to wait until the vxe-table completes the output (re-rendering)
           *        of rows after they are updated in the store.
           */
          setTimeout(() => {
            const result = e.from.querySelectorAll(`tr[rowid="${rowId}"]`);
            const hasDuplacate = result.length > 1;
            if (hasDuplacate) {
              e.item.remove();
            }
          }, 0);
        });
      },
    });
  };

  const destroySortableJS = () => {
    clearTimeout(sortableInitTimeoutId);

    if (sortable) {
      sortable.destroy();
      sortable = null;
    }
  };

  const activateSortable = () => {
    if (!gridRef.value) {
      // grid is not initialized
      return;
    }

    const table = unref(gridRef.value.getRefMaps().refTable);
    if (!table) {
      // data is not loaded
      return;
    }

    const reactData: TableReactData = table.reactData;
    if (reactData === null) return;

    const selector =
      reactData.overflowX && reactData.hasFixedColumn
        ? ".vxe-table--fixed-wrapper tbody"
        : ".vxe-table--main-wrapper tbody";

    clearTimeout(sortableInitTimeoutId);
    sortableInitTimeoutId = window.setTimeout(() => initSortableJS(selector));
  };

  watchEffect(() => {
    if (isActive.value) {
      activateSortable(); // NB. deps of activate method will also trigger watchEffect
    } else {
      destroySortableJS();
    }
  });

  onBeforeUnmount(destroySortableJS);

  const enable = () => {
    isActive.value = true;
  };

  const disable = () => {
    isActive.value = false;
  };

  return { enable, disable };
};
