import { values } from "lodash-es";

import groupFilter_userFilterHtml from "../../templates/components/group-filter/user-filter.html?raw";

/**
 * How to use user-filter:
 *   in template
 *    <user-filter
 *       include-archived="true/false"
 *       callback="ctrl.callback({selected: selected})"
 *       for="{{widgetName}}">
 *     </user-filter>
 *
 *    in controller (example)
 *      self.callback = function(data){
 *        // data.selected contains selected users and groups (if all users from group is selected) as object
 *        // { users: [id1, id2, ... idN], groupHidden: [selectedGroupId1, ..selectedGroupIdN] }
 *        return data.selected;
 *      }
 **/

(function () {
  "use strict";
  UserFilterController.$inject = [
    "$scope",
    "TreeFilterSelectedService",
    "PermissionsService",
    "MembersService",
  ];
  angular
    .module("dealroom.group-filter.directive", [
      "ui.bootstrap",
      "dealroom.date-fns",
      "dealroom.config",
      "dealroom.common",
      /**
       * new tree filter directive
       **/
      "dealroom.tree-filter.directive",
    ])
    .directive("userFilter", function () {
      return {
        restrict: "E",
        replace: true,
        scope: true,
        bindToController: {
          group: "@",
          for: "@",
          includeArchived: "<",
          allowSelectAll: "<",
          treeOptions: "<", // see TreeFilterSelectedService options
          callback: "&",
        },
        template: groupFilter_userFilterHtml,
        controller: UserFilterController,
        controllerAs: "ctrl",
      };
    });

  function UserFilterController(
    $scope,
    TreeFilterSelectedService,
    PermissionsService,
    MembersService,
  ) {
    var self = this;

    $scope.Permissions = PermissionsService;
    $scope.Members = MembersService;
    $scope.$watchCollection(
      "[ctrl.includeArchived, Permissions.loading, Members.loading]",
      function () {
        if (!PermissionsService.isLoaded() || !MembersService.isLoaded()) {
          return;
        }
        updateMembersData();
      },
    );

    function onTreeChange() {
      var data = { selected: undefined };
      if (!self.tree.isAllSelected || self.allowSelectAll) {
        data.selected = self.tree.selectedChildsList;
      }
      self.callback(data);
    }

    self.$onInit = function () {
      self.tree = new TreeFilterSelectedService(
        [],
        onTreeChange,
        getKey,
        getChild,
        self.treeOptions,
      );
    };

    var membersInGroup;

    function updateMembersData() {
      membersInGroup = {};
      var allGroups = values(PermissionsService.pgroups);
      allGroups.forEach(function (pgroup) {
        membersInGroup[pgroup.id] = [];
      });
      Object.keys(MembersService.members).forEach(function (memberKey) {
        var member = MembersService.members[memberKey];

        var groupId = member.pgroup.id;

        // TODO: include archived groups in API response for admins
        var groupMembers = membersInGroup[groupId] || [];
        if (!member.is_archived || self.includeArchived) {
          groupMembers.push(member);
        }
        membersInGroup[groupId] = groupMembers;
      });

      var roots = allGroups.filter(function (pgroup) {
        return (
          (!pgroup.is_archived || self.includeArchived) &&
          membersInGroup[pgroup.id].length > 0
        );
      });
      self.tree.refresh(roots);
      if (self.group) {
        var group = PermissionsService.pgroups[self.group];
        if (group) {
          self.tree.unselectAll();
          self.tree.toggleSelected(group);
        }
      }
    }

    function isMember(item) {
      return !!item.pgroup;
    }

    function getKey(item) {
      var prefix = isMember(item) ? "member" : "pgroup";
      return prefix + "." + item.id;
    }

    function getChild(item) {
      if (isMember(item)) {
        return;
      }
      return membersInGroup[item.id];
    }
  }
})();
