<template>
  <div>
    <DrLoader v-if="isLoading" />
    <div v-if="!isLoading">
      <br />
      <div class="dash-settings__header">
        <span class="dash-settings__header-text">
          {{ provider.name }} Integration
        </span>
        <span class="dash-settings__header-description" />
      </div>

      <!-- @vue-skip: integrationData is null when isLoading -->
      <div v-if="!isEditing">
        <div v-if="integrationData.app_client_id">
          <div>
            <p><b>Title:</b> {{ integrationData.title }}</p>
            <p v-if="provider.id !== OpenIDProviders.Google">
              <b>Metadata URI:</b>
              {{ integrationData.openid_configuration_url }}
            </p>
            <p><b>Client ID:</b> {{ integrationData.app_client_id }}</p>
          </div>
          <ElButton size="small" @click="isEditing = true">
            Update {{ provider.name }} integration
          </ElButton>
        </div>
        <div v-if="!integrationData.app_client_id">
          <ElButton
            size="small"
            class="text-uppercase"
            @click="isEditing = true"
          >
            Create {{ provider.name }} integration
          </ElButton>
        </div>
      </div>
      <!-- !isEditing -->

      <!-- @vue-skip: integrationData is null when isLoading -->
      <div v-if="isEditing">
        <div
          v-if="!integrationData.app_client_id"
          class="dash-settings__header"
        >
          <p><strong>How to enable SSO</strong></p>
          <ol>
            <li>
              add OpenID Connect app (e.g., DealRoom App) to your
              {{ provider.name }}'s workspace/org
            </li>
            <li>
              set the app's <samp>Resource URL</samp> to
              <a href="#" @click="copyLink(resourceUrl)"
                ><samp>{{ resourceUrl }}</samp></a
              >
              (click to copy)
            </li>
            <li>
              set the app's <samp>Redirect URI</samp> to
              <a href="#" @click="copyLink(oauth2ClientCallbackUrl)"
                ><samp>{{ oauth2ClientCallbackUrl }}</samp></a
              >
              (click to copy)
            </li>
            <li>allow certain users to use the app</li>
            <li>
              specify
              <span v-if="provider.id !== OpenIDProviders.Google">
                <samp>OpenID Connect metadata uri</samp>
                (typically ends with
                <samp>/.well-known/openid-configuration)</samp>,
              </span>
              the app's client id and client secret here
            </li>
          </ol>
        </div>

        <EditForm
          v-if="isEditing && integrationData"
          :integration-data="integrationData"
          :provider="provider"
          @close="stopEditing"
        />
      </div>
      <!-- isEditing -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrLoader from "@shared/ui/dr-loader";

import { ORG_MEMBER_DATA } from "@setups/data";
import { Urls } from "@setups/index";
import {
  OpenIDIntegrationApiService,
  OpenIDProviders,
} from "@drVue/api-service/client-dashboard";
import { $notifyDanger, $notifySuccess, copyToClipboard } from "@drVue/common";
import EditForm from "./EditForm";

import type {
  OpenIDIntegration,
  OpenIDProvider,
} from "@drVue/api-service/client-dashboard";
import type { PropType } from "vue";

interface Data {
  OpenIDProviders: any;
  isLoading: boolean;
  isEditing: boolean;
  resourceUrl: any;
  oauth2ClientCallbackUrl: any;
  integrationData: OpenIDIntegration | null;
}

export default defineComponent({
  name: "OpenIDIntegration",
  components: {
    DrLoader,
    EditForm,
  },
  props: {
    provider: { required: true, type: Object as PropType<OpenIDProvider> },
  },
  data(): Data {
    return {
      OpenIDProviders: OpenIDProviders,
      isLoading: true,
      isEditing: false,
      resourceUrl: ORG_MEMBER_DATA.client.url,
      oauth2ClientCallbackUrl: Urls["oauth2-client-callback"](),
      integrationData: null,
    };
  },
  mounted() {
    this.isLoading = true;
    const api = new OpenIDIntegrationApiService(this.provider.id);
    api
      .details()
      .then((value: OpenIDIntegration) => {
        this.isLoading = false;
        this.integrationData = value;
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          // integration is not setup yet
          this.isLoading = false;
          this.integrationData = {
            title: this.provider.name,
          } as OpenIDIntegration;
          return;
        }
        $notifyDanger(`Failed to load ${this.provider.name} integration info`);
        throw error;
      });
  },
  methods: {
    copyLink(link: string) {
      if (copyToClipboard(link)) {
        $notifySuccess("The URL has been copied.");
      } else {
        $notifyDanger("Failed to copy URL to clipboard.");
      }
    },
    stopEditing(updatedIntegration: OpenIDIntegration) {
      if (updatedIntegration) {
        this.integrationData = updatedIntegration;
      }
      this.isEditing = false;
    },
  },
});
</script>
