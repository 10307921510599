import "./counter.js";

import * as d3 from "d3";

angular
  .module("dealroom.analytics.card.burndown.data.tick", [
    "dealroom.analytics.card.burndown.data.tick.counter",

    "dealroom.analytics.card.burndown.tooltip",
  ])
  .service("AnalyticsBurndownTickFactrory", Factory);

Factory.$inject = [
  "AnalyticsBurndownTicksCounter",
  "ActivityFilterDatesService",
  "AnalyticsBurndownTickTooltip",
];
function Factory(
  AnalyticsBurndownTicksCounter,
  ActivityFilterDatesService,
  AnalyticsBurndownTickTooltip,
) {
  return {
    fromData,
    fromTicks,
  };

  function getEveryDay() {
    const EVERYDAY = ActivityFilterDatesService.getEveryDayRange(true) || [];
    return EVERYDAY.reverse();
  }

  function fromData(data, stat) {
    const tasksStates = AnalyticsBurndownTicksCounter.create(data);
    const dtsFromTodayToEnd = getEveryDay();
    const ticks = dtsFromTodayToEnd
      .reduce(getTick, [])
      .filter(ActivityFilterDatesService.isLessDateEnd);
    return ticks;

    function getTick(bucket, dt, i) {
      const prev = bucket[i - 1];
      const counter = tasksStates[dt] || tasksStates.empty;
      const tick = new Tick({
        dt,
        stat,
        prev,
        counter,
      });
      bucket.push(tick);
      return bucket;
    }
  }

  function fromTicks(ticks, aggrFn) {
    const stackedTicks = ticks.reduce(stackByDt, {});
    return d3.entries(stackedTicks).map(getTick);

    function stackByDt(bucket, { ticks }) {
      ticks.forEach((tick) => {
        if (bucket[tick.dt] === undefined) bucket[tick.dt] = [];
        bucket[tick.dt].push(tick);
      });
      return bucket;
    }

    function getTick(entry) {
      const dt = new Date(entry.key);
      const ticks = entry.value;

      const opens = [];
      const totals = [];
      const counters = {};

      ticks.forEach((tick) => {
        opens.push(tick.open);
        totals.push(tick.total);
        Object.keys(tick.counter).forEach((name) => {
          if (counters[name] === undefined) counters[name] = [];
          counters[name].push(tick.counter[name]);
        });
      });

      const counter = Object.keys(counters).reduce((bucket, name) => {
        bucket[name] = aggrFn(counters[name]);
        return bucket;
      }, {});
      const open = aggrFn(opens);
      const total = aggrFn(totals);

      return {
        dt,
        open,
        total,
        tooltip: AnalyticsBurndownTickTooltip.get(open, total, counter),
      };
    }
  }

  function Tick({ dt, stat, prev, counter }) {
    // public: dt, value, counter, tooltip
    this.dt = dt;
    const [total, open] = getValue(stat, prev);
    this.open = open;
    this.total = total;
    this.counter = counter; // total activity for today

    this.tooltip = AnalyticsBurndownTickTooltip.get(
      this.open,
      this.total,
      this.counter,
    );

    prev = null;
    stat = null;

    function getValue(stat, prev) {
      if (!prev) {
        // current date
        const total = stat.tasks.get().length;
        const resolved = stat.tasks.get({ statusType: "resolved" }).length;
        const open = total - resolved;
        return [total, open];
      } else {
        const total = Math.max(0, prev.total - prev.counter.differenceTotal);
        const open = Math.max(0, prev.open - prev.counter.differenceOpened);
        return [total, open];
      }
    }
  }
}

function datesDesc(a, b) {
  return d3.descending(new Date(a), new Date(b));
}
