import { computed, reactive } from "vue";

import { createDictionary } from "@drVue/common";

type NumberOrStringOf<T> = {
  [K in keyof T]: T[K] extends string | number ? K : never;
}[keyof T];

export const useSelected = <
  TEntity,
  TId extends NumberOrStringOf<TEntity>,
>() => {
  const selectedDict = reactive(createDictionary<boolean>());

  type IdValue = TEntity[TId] extends string ? string : number;

  const selectedList = computed<IdValue[]>(() => {
    const keys = Object.keys(selectedDict);
    if (Number.isNaN(parseFloat(keys[0]))) {
      return keys as IdValue[];
    }

    return keys.map(parseFloat) as IdValue[];
  });

  const toggle = (id: IdValue) => {
    if (selectedDict[id]) {
      delete selectedDict[id];
    } else {
      selectedDict[id] = true;
    }
  };

  const select = (id: IdValue) => {
    selectedDict[id] = true;
  };

  const unselect = (id: IdValue) => {
    delete selectedDict[id];
  };

  return {
    selectedDict,
    selectedList,
    select,
    unselect,
    toggle,
  };
};
