<template>
  <!-- .drag-handle class is special, used by Sortable.js, do not remove! -->
  <i
    class="fas fa-grip-vertical drag-handle"
    :title="title"
    :class="{
      [$style.grip]: true,
      [$style.isHidden]: isHidden,
      [$style.isDisabled]: isDisabled,
    }"
    @mousedown="preventIfDisabled"
    @click="preventIfDisabled"
    @mouseup="preventIfDisabled"
    @dragstart="preventIfDisabled"
    @drag="preventIfDisabled"
    @dragend="preventIfDisabled"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
  isHidden: boolean;
  isDisabled: boolean;
}

const props = defineProps<Props>();

const title = computed(() =>
  props.isDisabled
    ? "You cannot drag requests when\nsorting/filtering is enabled"
    : "Drag to reorder",
);

const preventIfDisabled = (e: MouseEvent) => {
  if (props.isDisabled) {
    e.preventDefault();
    e.stopPropagation();
  }
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.grip {
  color: colors.$pr-500;
  opacity: 0;
  transition: opacity 0.1s ease-out;
  cursor: grab;
  height: 38px;
  width: 100%;
  padding-top: 12px;
}

:global(.row--hover) {
  .grip {
    opacity: 1;
  }
}

.isHidden {
  visibility: hidden;
  pointer-events: none;
}

.isDisabled {
  color: colors.$pr-200;
  cursor: not-allowed;
}
</style>
