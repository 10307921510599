<template>
  <div :class="$style.container">
    <DrIcon :class="$style.icon" size="sm" name="exclamation-triangle" />
    <span>{{ name }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import DrIcon from "@shared/ui/dr-icon";

import { pinia } from "@drVue/store/pinia";
import { useFindingsTypesStore } from "@drVue/store/pinia/pipeline/findings-types";

import type { FindingType } from "@drVue/store/pinia/pipeline/findings-types";
import type { HEXColor } from "@drVue/utils/color";

interface Props {
  typeUid: string;
}

const props = defineProps<Props>();

const findingsTypeStore = useFindingsTypesStore(pinia);

const findingType = computed<FindingType>(
  () => findingsTypeStore.dict[props.typeUid],
);

const name = computed<string>(() => findingType.value?.name ?? "-");

const color = computed<HEXColor | "transparent">(
  () => findingType.value?.color ?? "transparent",
);
</script>

<style module lang="scss">
.container {
  display: inline-flex;
  gap: 6px;
  align-items: center;
}

.icon {
  color: v-bind(color);
}
</style>
