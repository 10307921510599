<template>
  <div>
    <Chart />
    <PhasesList />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import Chart from "./Chart";
import PhasesList from "./PhasesList.vue";

export default defineComponent({
  name: "TimelineView",
  components: {
    Chart,
    PhasesList,
  },
});
</script>
