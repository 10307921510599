<template>
  <ElPopover
    ref="refPopover"
    visible
    :width="width"
    :height="height"
    :popper-class="[$style.popper, $style.popper_isHidden]"
    :popper-options="popperOptions"
    :virtual-ref="reference"
    :placement="placement"
    @update:visible="updatePopover()"
  >
    <div ref="refContainer">
      <slot />
    </div>
  </ElPopover>
</template>

<script lang="ts" setup>
import { ElPopover, type Options } from "element-plus";
import { nextTick, ref } from "vue";
import { onClickOutside } from "@vueuse/core";

import { POPUP_SIZES } from "@drVue/shared/ui/constants";

import type { ComponentInstance } from "vue";

interface Props {
  reference: HTMLElement;
  placement?: ComponentInstance<typeof ElPopover>["placement"];
  width?: number;
  height?: number;
}

interface Emits {
  (e: "close"): void;
}

withDefaults(defineProps<Props>(), {
  placement: "right",
  width: POPUP_SIZES.folderMenu.width,
  height: POPUP_SIZES.folderMenu.height,
});

const emit = defineEmits<Emits>();

const refPopover = ref<ComponentInstance<typeof ElPopover> | undefined>();
const refContainer = ref<HTMLDivElement | undefined>();

const updatePopover = async () => {
  await nextTick();
  refPopover.value?.popperRef?.popperInstanceRef?.forceUpdate();
};

const popperOptions: Partial<Options> = {
  modifiers: [
    {
      name: "flip",
      options: { fallbackPlacements: ["right", "right-start"] },
    },
    {
      name: "offset",
      options: { offset: [-20, 10] },
    },
    {
      name: "arrow",
      options: { padding: 20 },
    },
  ],
};

onClickOutside(refContainer, () => {
  emit("close");
});
</script>

<style lang="scss" module>
.popper {
  padding: 0 !important;
}

.popper_isHidden:global {
  &.el-popper[data-popper-reference-hidden="true"] {
    display: none;
  }
}
</style>
