import { h } from "vue";
import DrIcon from "@shared/ui/dr-icon";

import ActionsCell from "./cells/ActionsCell.vue";

import type { DrVxeTableColumn } from "@drVue/components/types";
import type { FindingType } from "@drVue/store/pinia/pipeline/findings-types";

export default class TableColumns {
  get columns(): DrVxeTableColumn<FindingType>[] {
    return [this.nameColumn(), this.iconColumn(), this.actionsCell()];
  }

  private nameColumn(): DrVxeTableColumn<FindingType> {
    return {
      field: "name",
      title: "Name",
      minWidth: 200,
      width: 200,
    };
  }

  private iconColumn(): DrVxeTableColumn<FindingType> {
    return {
      field: "color",
      title: "Icon",
      minWidth: 200,
      width: 200,
      slots: {
        default: ({ row }) => [
          h(DrIcon, {
            style: { color: row.color },
            name: "exclamation-triangle",
          }),
        ],
      },
    };
  }

  private actionsCell(): DrVxeTableColumn<FindingType> {
    return {
      field: "",
      title: "",
      slots: {
        default: ({ row }) => [
          h(ActionsCell, {
            findingTypeId: row.id,
          }),
        ],
      },
    };
  }
}
