import * as d3 from "d3";

import COLORS from "./colors";
// import PALETTE from './palette';

const COLOR_SCHEME = d3.schemeCategory10;

angular
  .module("dealroom.analytics.service.colors-scheme", [])
  .service("AnalyticsColorsSchemeService", AnalyticsColorsSchemeService)
  .service("AnalyticsColorsSchemeFabric", AnalyticsColorsSchemeFabric);

function AnalyticsColorsSchemeService() {
  return COLORS;
}

function AnalyticsColorsSchemeFabric() {
  return function () {
    const _SCHEME = {
      0: COLORS.blue.normal,
      1: COLORS.orange.normal,
      2: COLORS.green.normal,
      3: COLORS.red.normal,
    };
    const _EMPTY_COLOR = COLORS.grey.normal;
    let _COLORS = [];

    getColor.EMPTY_COLOR = _EMPTY_COLOR;

    return getColor;

    function getColor(key) {
      if (!key) return _EMPTY_COLOR;
      // if (_COLORS.length < 1) _COLORS = d3.shuffle(PALETTE.slice());
      if (_COLORS.length < 1) _COLORS = d3.shuffle(COLOR_SCHEME.slice());
      if (_SCHEME[key] === undefined) _SCHEME[key] = _COLORS.pop();
      return _SCHEME[key];
    }
  };
}
