<template>
  <ElForm
    ref="formRef"
    label-position="top"
    :rules="formRules"
    :model="{ name, parentId }"
  >
    <ElFormItem label="Parent" prop="parentId">
      <ElTreeSelect
        :class="$style.parentNodeTree"
        :teleported="false"
        node-key="id"
        filterable
        check-strictly
        check-on-click-node
        :expand-on-click-node="false"
        :default-expanded-keys="[parentId]"
        :data="tree"
        :model-value="parent?.name"
        @update:model-value="handleUpdateParentId"
      >
        <template #default="{ data, node }">
          <slot name="item" v-bind="{ item: data, node }" />
        </template>
      </ElTreeSelect>
    </ElFormItem>
    <ElFormItem label="Name" prop="name">
      <ElInput
        autofocus
        :model-value="name"
        @update:model-value="$emit('update:name', $event)"
      />
    </ElFormItem>
  </ElForm>
</template>

<script setup lang="ts">
import { ElForm } from "element-plus";
import { computed, reactive } from "vue";

import type { NodeId } from "../types";
import type { Tree } from "@shared/ui/dr-tree";
import type { FormRules } from "element-plus";

interface Props {
  tree: Tree;
  parentId: NodeId;
  name: string;
}

const props = defineProps<Props>();

interface Emits {
  (e: "update:name", name: string): void;
  (e: "update:parentId", id: NodeId): void;
}

const emit = defineEmits<Emits>();

const parent = computed(() => {
  const stack = [...props.tree];

  while (stack.length) {
    const node = stack.pop();

    if (!node) continue;
    if (node.id === props.parentId) return node;
    if (node.children) stack.push(...node.children);
  }

  return null;
});

const formRules = reactive<FormRules>({
  parentId: {
    required: true,
    message: "Please select a parent",
    trigger: "blur",
  },
  name: {
    required: true,
    message: "Please enter name",
    trigger: "blur",
  },
});

const handleUpdateParentId = (id?: number) => {
  if (id && id !== props.parentId) {
    emit("update:parentId", id);
  }
};
</script>

<style module lang="scss">
.parentNodeTree {
  width: 100%;
}
</style>
