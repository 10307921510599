import { ApiService } from "@drVue/api-service";

import type { Deal, IDealCreateData } from "./types";
import type { RoomCreateData } from "@drVue/api-service/common/rooms";
import type { AxiosRequestConfig } from "axios";

export type DealPatchRequestData = Partial<
  Pick<Deal, "title" | "start_date" | "phase" | "phases_info" | "custom_data">
>;

export class DealsApiService extends ApiService {
  public async list() {
    const url: string = this.Urls["api:client-dashboard:deals-list"]();
    return await this.get<Deal[]>(url);
  }

  public async getById(id: number) {
    const url: string = this.Urls["api:client-dashboard:deals-detail"](id);
    return await this.get<Deal>(url);
  }

  public async createDeal(deal: IDealCreateData): Promise<Deal> {
    const url = this.Urls["api:client-dashboard:deals-list"]();
    return this.post(url, deal);
  }

  public async createRoomForADeal(
    dealId: number,
    roomData: RoomCreateData,
  ): Promise<void> {
    const url = this.Urls["api:client-dashboard:deals-detail-room"](dealId);
    return this.post(url, roomData);
  }

  public async updateDeal(dirtyDeal: Deal) {
    const url: string = this.Urls["api:client-dashboard:deals-detail"](
      dirtyDeal.id,
    );

    const payload = {
      custom_data: dirtyDeal.custom_data,
      phases_info: dirtyDeal.phases_info,
      phase: dirtyDeal.phase,
      start_date: dirtyDeal.start_date,
    };

    return await this.put<Deal>(url, payload);
  }

  public async patchDeal(dealId: number, data: DealPatchRequestData) {
    const url: string = this.Urls["api:client-dashboard:deals-detail"](dealId);

    return await this.patch<Deal>(url, data);
  }

  public async restoreRoom(dealId: number) {
    const url =
      this.Urls["api:client-dashboard:deals-detail-room-restore"](dealId);
    return this.post<void>(url, null);
  }

  public async deleteRoom(dealId: number) {
    const url = this.Urls["api:client-dashboard:deals-detail-room"](dealId);
    return this.delete<void>(url);
  }

  public getTrash() {
    const url: string = this.Urls["api:client-dashboard:deals-archived-list"]();
    return this.get<Deal[]>(url);
  }

  public async deleteDeal(dealId: number) {
    const url: string = this.Urls["api:client-dashboard:deals-detail"](dealId);

    return await this.delete(url);
  }

  public async permanentlyDeleteDeal(dealId: number) {
    const url: string =
      this.Urls["api:client-dashboard:deals-archived-detail"](dealId);

    return await this.delete(url);
  }

  public restoreDeal(dealId: number) {
    const url: string =
      this.Urls["api:client-dashboard:deals-archived-detail-restore"](dealId);
    return this.post(url, null);
  }

  public bulkUpdateContacts(
    dealId: number,
    usersIdsToAdd: string[],
    usersIdsToRemove: string[],
  ) {
    const url: string =
      this.Urls["api:client-dashboard:deals-detail-contacts-bulk"](dealId);

    return this.post(url, {
      add: usersIdsToAdd,
      remove: usersIdsToRemove,
    });
  }

  public listContacts(dealId: number) {
    const url: string =
      this.Urls["api:client-dashboard:deals-detail-contacts-list"](dealId);
    return this.get<string[]>(url);
  }

  public attachFiles(dealId: number, file: { file_id: string }) {
    const url =
      this.Urls["api:client-dashboard:deals-detail-attachments-bulk"](dealId);
    return this.post(url, { add: [file] });
  }

  public uploadFile(
    file: File,
    config: AxiosRequestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    },
  ): Promise<{ id: string }> {
    const formData = new FormData();
    formData.set("file", file);

    if (!config.headers) {
      config.headers = {
        "Content-Type": "multipart/form-data",
      };
    }

    return this.post(
      this.Urls["api:client-dashboard:files-list"](),
      formData,
      config,
    );
  }

  public deleteFile(dealId: number, file_id: string) {
    const url =
      this.Urls["api:client-dashboard:deals-detail-attachments-bulk"](dealId);
    return this.post(url, { remove: [{ file_id }] });
  }

  public retrieveFilePath(dealId: number, fileId: string) {
    return this.Urls["api:client-dashboard:deals-detail-attachments-download"](
      dealId,
      fileId,
    );
  }
}
