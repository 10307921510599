<template>
  <div
    :class="{
      [$style.tree]: true,
      [$style.tree_checkboxLeaf]: onlyLeafCheckbox,
      [$style.tree_checkSignOnRight]: checkSignOnRight,
      [$style.tree_flatView]: flatView,
      [$style.tree_rootIsFlat]: treeRootIsFlat,
    }"
  >
    <ElTree
      v-show="localData.length && !(noFilterTextMatches || isPending)"
      ref="treeRef"
      :node-key="nodeKey"
      :default-expand-all="defaultExpandAll || flatView"
      :indent="nodeIndent"
      :data="localData"
      :props="treeProps"
      :filter-node-method="filterMethod"
      :show-checkbox="showCheckbox || checkSignOnRight"
      :check-on-click-node="checkOnClickNode"
      :expand-on-click-node="expandOnClickNode"
      :default-expanded-keys="defaultExpandedKeys"
      :default-checked-keys="modelValue"
      :check-strictly="checkStrictly"
      @check-change="handleCheckChange"
    >
      <template
        #default="{
          node,
          data: item,
        }: {
          node: Node;
          data: TreeItem<TreeItemExt>;
        }"
      >
        <slot name="item" v-bind="{ item, node }">
          <div
            :class="{
              [$style.item]: true,
              [$style.item_isChecked]: node.checked,
            }"
          >
            <slot
              name="item-prefix"
              v-bind="{
                item,
                node,
                className: $style.itemPrefix,
              }"
            />

            <span :class="$style.itemName">
              <slot name="item-name" v-bind="{ item, node }">
                {{ item.name }}
              </slot>
            </span>

            <slot
              v-if="!checkSignOnRight"
              name="item-postfix"
              v-bind="{
                item,
                node,
                className: $style.itemSuffix,
              }"
            />

            <div v-else-if="node.checked" :class="$style.itemSuffix">
              <DrIcon size="sm" name="check" />
            </div>
          </div>
        </slot>
      </template>
    </ElTree>

    <div v-show="noFilterTextMatches || isPending" :class="$style.noMatches">
      <template v-if="!isPending">
        <div :class="$style.noMatchesTitle">
          No matches for
          <b>{{ filterText }}</b
          >.
        </div>
        <ElButton :class="$style.linkBtn" @click="emit('reset-filter')">
          Reset
          <template #icon>
            <DrIcon name="redo" size="sm" />
          </template>
        </ElButton>
      </template>

      <DrSkeletonList width="70%" :animate="isPending" />
    </div>
  </div>
</template>

<script setup lang="ts" generic="TreeItemExt">
import { ElButton, ElTree } from "element-plus"; // In timeline el-components are required to be explicitly imported
import fuzzaldrinPlus from "fuzzaldrin-plus";
import { isEqual } from "lodash-es";
import { computed, onMounted, ref, watch } from "vue";

import { DrIcon } from "../dr-icon";
import { DrSkeletonList } from "../dr-skeleton";

import type { Tree, TreeItem } from "./types";
import type Node from "element-plus/es/components/tree/src/model/node";
import type TreeTypes from "element-plus/es/components/tree/src/tree.type";
import type { Ref } from "vue";

interface Props {
  data: Tree<TreeItemExt>;
  modelValue: TreeItem["id"][];
  filterText?: string;
  showCheckbox?: boolean;
  checkOnClickNode?: boolean;
  expandOnClickNode?: boolean;
  defaultExpandAll?: boolean;
  defaultExpandedKeys?: TreeItem["id"][];
  /** Emit only checked "leaf" nodes (node without children) */
  onlyLeafValue?: boolean;
  /** Data is loading/processing/preparing */
  isPending?: boolean;
  /** Show checkbox only for "leaf" nodes  */
  onlyLeafCheckbox?: boolean;
  /** Whether checked state of a node not affects its father and child nodes */
  checkStrictly?: boolean;
  /** Show "check" sign on right side instead of classic checkbox to the left of item */
  checkSignOnRight?: boolean;
  filterNodeMethod?: TreeTypes.FilterNodeMethodFunction;
  nodeKey?: string;
  /**
   * Display tree in "flat" view without left padding of leaves
   * only one nesting level is allowed
   */
  flatView?: boolean;
  /**
   * If this parameter is enabled, then for cases where the filter conditions match
   * the group name, its child elements will also be displayed.
   */
  showChildrenOnFilteredParent?: boolean;
}

interface Events {
  (event: "update:model-value", value: TreeItem["id"][]): void;
  (event: "reset-filter"): void;
}

interface Slots {
  item(props: { item: TreeItem<TreeItemExt>; node: Node }): void;
  "item-prefix"(props: {
    item: TreeItem<TreeItemExt>;
    node: Node;
    className: string;
  }): void;
  "item-name"(props: { item: TreeItem<TreeItemExt>; node: Node }): void;
  "item-postfix"(props: {
    item: TreeItem<TreeItemExt>;
    node: Node;
    className: string;
  }): void;
}

const props = withDefaults(defineProps<Props>(), {
  filterText: "",
  showCheckbox: false,
  checkOnClickNode: false,
  expandOnClickNode: false,
  defaultExpandAll: false,
  defaultExpandedKeys: () => [],
  onlyLeafValue: false,
  isPending: false,
  onlyLeafCheckbox: false,
  checkStrictly: false,
  checkSignOnRight: false,
  filterNodeMethod: undefined,
  nodeKey: "id",
  flatView: false,
  showChildrenOnFilteredParent: false,
});

const emit = defineEmits<Events>();

defineSlots<Slots>();

const treeRootIsFlat = computed(
  () => !props.data.some((item) => !!item?.children?.length),
);

const filterMethodDefault: TreeTypes.FilterNodeMethodFunction = (
  query: string,
  data: TreeTypes.TreeNodeData,
  node: Node,
) => {
  const item = data as TreeItem; // since we set data type as `data: TTree;` (TTree simply = TTreeItem[];)

  if (!query) return true;

  const score = fuzzaldrinPlus.score(item.name, query);

  if (
    score === 0 &&
    props.showChildrenOnFilteredParent &&
    node.isLeaf &&
    node.parent
  ) {
    const parentName = node.parent.data?.name;
    if (parentName) {
      const parentScore = fuzzaldrinPlus.score(parentName, query);
      return parentScore > 0;
    }
  }

  return score > 0;
};
const filterMethod = computed(
  () => props.filterNodeMethod || filterMethodDefault,
);

const treeRef = ref<InstanceType<typeof ElTree> | null>(null);
const treeProps: TreeTypes.TreeOptionProps = {
  children: "children",
  label: "name",
  class: (_data, node) => {
    if (node.level === 1) {
      if (node.isLeaf) {
        return "is-root-leaf";
      }
      return "is-root-node";
    }
    return "";
  },
};
let handleCheckChangeTimeoutId: number | null = null;
const noFilterTextMatches = ref(false);

const handleCheckChange = () => {
  if (handleCheckChangeTimeoutId) {
    clearTimeout(handleCheckChangeTimeoutId);
  }

  handleCheckChangeTimeoutId = window.setTimeout(emitValue);
};

const emitValue = () => {
  const checkedIds = treeRef.value?.getCheckedKeys(props.onlyLeafValue);
  if (!checkedIds || isEqual(checkedIds, props.modelValue)) return;

  emit("update:model-value", checkedIds);
};

const filterTree = (query: string) => {
  if (treeRef.value) {
    treeRef.value.filter(query);
    noFilterTextMatches.value = !treeRef.value.root.visible;
  }
};

const nodeIndent = computed(() => (props.checkSignOnRight ? 0 : 6));

watch(
  () => props.modelValue,
  (value) => {
    treeRef.value?.setCheckedKeys(value);
    filterTree(props.filterText);
  },
);

const localData = ref(props.data) as Ref<typeof props.data>;
watch(
  () => props.data,
  (value) => {
    if (!isEqual(localData.value, value)) {
      localData.value = props.data;
    }
  },
);

watch(
  () => props.filterText,
  (value) => {
    filterTree(value.trim());
  },
);

onMounted(() => {
  if (props.filterText.trim()) {
    filterTree(props.filterText);
  }
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;
@use "@app/styles/scss/values";

.noMatches {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: spacing.$xl;
}

.noMatchesTitle {
  grid-area: title;
  text-align: center;
  font: typo.$body_regular;

  b {
    font: typo.$body_semibold;
  }
}

.linkBtn {
  margin: spacing.$s 0 spacing.$xl;

  &:global(.el-button) {
    --el-button-bg-color: transparent;
    --el-button-border-color: transparent;
    --el-button-outline-color: transparent;
    --el-button-text-color: #{colors.$sc-600};
    --el-button-icon-color: #{colors.$sc-600};

    &:focus {
      --el-button-text-color: #{colors.$sc-600};
    }
  }
}

.tree {
  :global {
    .el-tree {
      --el-tree-node-hover-bg-color: #{colors.$pr-100};
      --el-tree-text-color: #{colors.$pr-900};
      --el-tree-expand-icon-color: #{colors.$pr-400};

      .el-tree-node__content {
        height: values.$base-input-height;
        max-width: 100%;
        overflow: hidden;
        border-radius: values.$base-border-radius;

        > label.el-checkbox {
          margin-right: spacing.$xs;
        }

        .el-tree-node__label {
          flex-grow: 1;
          display: inline-grid;
        }

        // show disabled state of node
        .el-checkbox.is-disabled ~ * {
          cursor: not-allowed;
        }
      }

      .el-tree-node__children {
        padding-left: 40px;
      }

      .el-tree-node.is-current:not(:hover) > .el-tree-node__content {
        background-color: transparent;
      }
    }
  }

  &:not(.tree_checkSignOnRight) {
    :global {
      .el-tree {
        .el-tree-node:not(.is-root-leaf) .el-tree-node__expand-icon.is-leaf {
          display: none;
        }
      }
    }
  }
}

.tree_rootIsFlat :global {
  .el-tree .el-tree-node.is-root-leaf .el-tree-node__expand-icon.is-leaf {
    width: spacing.$xs;
    padding: 0;
  }
}

.tree_checkboxLeaf :global {
  .el-tree {
    .el-tree-node__children {
      padding-left: 18px;
    }

    .el-tree-node__expand-icon:not(.is-leaf) + .el-checkbox {
      display: none;
    }
  }
}

.tree_flatView :global {
  .el-tree {
    margin-left: -6px;

    .el-tree-node .el-tree-node__expand-icon {
      display: none;
    }

    .el-tree-node__children {
      padding-left: 0;
    }

    .el-tree-node.is-root-node > .el-tree-node__content {
      position: relative;
      padding-left: 6px !important;
      margin-bottom: 16px;
      overflow: visible;

      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -8px;
        left: 6px;
        right: 0;
        border-top: solid 1px colors.$pr-200;
      }
    }
  }
}

.item {
  display: flex;
  height: values.$base-input-height;
  overflow: hidden;
  align-items: center;
  gap: spacing.$xs;
  font: typo.$body_regular;
  width: 100%;
}

.itemPrefix,
.itemSuffix {
  flex: 0 0 auto;
}

.itemSuffix {
  padding-right: spacing.$xs;
}

.itemName {
  flex: 1 1 auto;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: colors.$pr-900;
}

.item_isChecked .itemName {
  color: colors.$sc-600;
}

.tree_checkSignOnRight {
  :global {
    .el-tree {
      .el-checkbox {
        display: none;
      }

      .el-tree-node__children {
        padding-left: 20px;
      }

      .el-tree-node__expand-icon.is-leaf ~ .el-tree-node__label {
        padding-left: spacing.$xxs;
      }
    }
  }

  .item_isChecked {
    color: colors.$sc-600;
    font: typo.$body_medium;
  }
}
</style>
