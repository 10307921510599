<template>
  <ElLink
    :class="$style.link"
    class="truncate"
    :href="downloadUrl"
    target="_blank"
  >
    <div :class="$style.linkInner" class="truncate">
      <i
        class="mimetypes"
        :class="[$style.icon, getIconClass(attachment.file.mimetype)]"
      />
      <div class="truncate">
        {{ attachment.file.name }}
      </div>
    </div>
  </ElLink>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import getIconClass from "@app/common/mimetype";
import { attachmentDownloadUrl } from "@drVue/store/modules/room/emails/EmailsApiService";

import type { EmailAttachment } from "@drVue/store/modules/room/emails/EmailsApiService";

const props = defineProps<{ attachment: EmailAttachment; emailId: string }>();

const downloadUrl = computed(() =>
  attachmentDownloadUrl(props.emailId, props.attachment.file.id),
);
</script>

<style module lang="scss">
@use "@app/styles/scss/typography.scss";

.icon {
  flex-shrink: 0;
}
.link {
  font-size: typography.$font-size-base;
  font-weight: typography.$font-weight-medium;
  margin: 2px 8px;
  :global {
    .el-link__inner {
      overflow: hidden;
    }
  }
}
.linkInner {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}
</style>
