<template>
  <DrLayoutContentCenteredSection title="Finding types">
    <template #description>
      Set finding types to organize your team's workflow in rooms of your
      organization.
    </template>
    <template #title-right>
      <ElButton type="primary" @click="showCreateModal = true">
        <span>New type</span>
        <i class="far fa-plus" />
      </ElButton>
    </template>
    <div :class="$style.content">
      <FindingTypesForm />
      <FindingTypeEditOrCreatePanel v-model:shown="showCreateModal" />
    </div>
  </DrLayoutContentCenteredSection>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { DrLayoutContentCenteredSection } from "@shared/ui/dr-layouts";

import FindingTypeEditOrCreatePanel from "@drVue/components/room/findings/settings/FindingTypeEditOrCreatePanel.vue";
import FindingTypesForm from "@drVue/components/room/findings/settings/FindingTypesForm.vue";

const showCreateModal = ref(false);
</script>

<style module lang="scss">
.content {
  min-height: 225px;
}
</style>
