import { trim } from "lodash-es";

import { createDictionary } from "@app/vue/common";

import type { Folder } from "@app/vue/store/modules/room/documents/DocumentsApiService";

const STOPWORDS = createDictionary({
  a: true,
  an: true,
  and: true,
  the: true,
  "&": true,
  "": true,
});

function convertToWordArray(str: string): WordArray {
  return str
    .toLowerCase()
    .split(" ")
    .reduce<WordArray>((acc, word) => {
      const trimmedWord = trim(word);
      if (!STOPWORDS[trimmedWord]) acc.push(trimmedWord);

      return acc;
    }, []);
}

type WordArray = string[];
type Path = WordArray[];

function getFolderPathsMap(rootFolder: Folder) {
  const paths = new Map<number, Path>(); // folder id, path reversed (root at the end)

  function addPath(folder: Folder) {
    let path = [convertToWordArray(folder.name)];

    if (folder.parent_id && paths.has(folder.parent_id)) {
      path = paths.get(folder.parent_id)!.concat(path);
    }

    paths.set(folder.id, path);

    folder.folders.forEach(addPath);
  }

  rootFolder.folders.forEach(addPath);

  return paths;
}

function pathScore(categoryPath: Path, folderPath: Path) {
  return window.path_score(categoryPath, folderPath);
}

export function suggestUploadFolder(
  fullPath: string,
  rootFolder: Folder,
  folders: Record<string, Folder>,
) {
  const categoryPath = fullPath.split("\\").map(convertToWordArray);
  const folderPathsMap = getFolderPathsMap(rootFolder);

  let bestScore = -Infinity;
  let bestFolderId = rootFolder.id;

  for (const [folderId, folderPath] of folderPathsMap.entries()) {
    const folder = folders[folderId];
    if (!folder.edit) continue; // upload is not allowed, skip

    const score = pathScore(categoryPath, folderPath);
    if (score > bestScore) {
      bestScore = score;
      bestFolderId = folderId;
    }
  }

  return bestFolderId;
}
