<template>
  <div :class="$style.wrapper">
    <div :class="$style.actions">
      <slot name="actions" v-bind="{ buttonIconClass: $style.buttonIcon }" />
    </div>

    <div :class="$style.list">
      <slot name="list" />
    </div>

    <slot name="footer" />
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.wrapper {
  min-height: 100%;
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  gap: spacing.$xs;
}

.actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: spacing.$m;
}

.buttonIcon {
  margin-left: spacing.$xxs;
}
</style>
