import DrStore from "@drVue/store";

angular
  .module("dealroom.analytics.service.data.grouppermissions", [
    "dealroom.analytics.service.data.loader",
    "dealroom.analytics.service.data.dispatcher",

    "dealroom.members",
  ])
  .service(
    "ActivityGroupPermissionsVisibleService",
    ActivityGroupPermissionsVisibleService,
  );

ActivityGroupPermissionsVisibleService.$inject = [
  "PermissionsService",
  "AnalyticsDataDispatcher",
  "ActivityRoomServiceLoadedPromise",
];
function ActivityGroupPermissionsVisibleService(
  PermissionsService,
  AnalyticsDataDispatcher,
  ActivityRoomServiceLoadedPromise,
) {
  const service = {
    set: setPgroups,
    // toggle: togglePgroupId,
    isVisible: isIdVisible,
    // isUserVisible: isUserVisible,

    getSelectedGroupsAsString,

    pgroupIds: [],

    visiblePgroupIds: [],
    visibleUsersIds: [],

    loadingPromise: ActivityRoomServiceLoadedPromise.then(processData),
  };
  const pgroupsToUsers = {};
  return service;

  function getSelectedGroupsAsString() {
    if (service.visiblePgroupIds.length === service.pgroupIds.length) {
      return "All";
    }
    return service.visiblePgroupIds
      .map((pgroupId) => {
        return PermissionsService.pgroups[pgroupId].name;
      })
      .join(", ");
  }

  function processData() {
    service.pgroupIds = PermissionsService.pgroupsList.reduce(function (
      bucket,
      pgroup,
    ) {
      if (pgroup.is_archived === false) {
        bucket.push(pgroup.id);
        const members =
          DrStore.getters["room/members/activeDictionaryByGroupId"][pgroup.id];
        pgroupsToUsers[pgroup.id] = members
          .filter((u) => !u.pending)
          .map((u) => u.id);
      }
      return bucket;
    }, []);
  }

  function setPgroups(ids = service.pgroupIds) {
    service.visiblePgroupIds = ids.map(function (i) {
      return +i; // use numbers!
    });
    service.visibleUsersIds = service.visiblePgroupIds.reduce((bucket, i) => {
      return bucket.concat(pgroupsToUsers[i]);
    }, []);
    AnalyticsDataDispatcher.call("filtersChanged");
  }

  // function togglePgroupId(pgroupId, checked) {
  //   var ids = service.visiblePgroupIds.slice();
  //   var index = ids.indexOf(+pgroupId);
  //   if (checked && index === -1) {
  //     ids.push(pgroupId);
  //   }
  //   else if (!checked && index !== -1) {
  //     ids.splice(index, 1);
  //   }
  //   if (ids.length != service.visiblePgroupIds.length) {
  //     setPgroups(ids);
  //   }
  //   return isSomeChecked();
  // }

  // function isSomeChecked() {
  //   return service.visiblePgroupIds.length > 0;
  // }

  function isIdVisible(pgroupId) {
    return (service.visiblePgroupIds || []).indexOf(+pgroupId) > -1;
  }

  // function isUserVisible(userId) {
  //   var pgroupId = usersToPgroup[userId];
  //   return isIdVisible(pgroupId);
  // }
}
