import { Tags } from "@drVue/components/user-dashboard/playbooks/types";

import type { Playbook } from "@drVue/components/user-dashboard/playbooks/types";

const playbooks: Playbook[] = [
  {
    id: "master_dd",
    title: "Master Due Diligence Playbook",
    description:
      "The master due diligence playbook is intended to provide an inclusive and wide-ranging list of initial due diligence requests that can be used for any target in any type of transaction. Sellers can use these playbook to get ahead of the game by anticipating buyer requests, while buyers can prepare their initial diligence requests.",
    requests: ["Legal", "Financial", "Commercial", "HR"],
    tags: [Tags.DueDiligence],
    resources: [
      {
        url: "https://dealroom.net/blog/buy-side-due-diligence",
        title: "How to Conduct Buy-Side Due Diligence + playbook",
      },
      {
        url: "https://dealroom.net/blog/sell-side-due-diligence",
        title: "How to Conduct Sell-Side Due Diligence + playbook",
      },
      {
        url: "https://dealroom.net/blog/the-evolution-of-the-q-a-feature-for-m-a-due-diligence",
        title: "The Evolution of M&A Due Diligence Request Management",
      },
    ],
  },
  {
    id: "sell_side_dd",
    title: "Sell Side Due Diligence Playbook",
    description:
      "For any organization beginning the often laborious and stressful process of attracting a merger, acquisition, or even investment capital, we’re here to offer you a two-fer for the ages – a detailed playbook to make certain you’re addressing all of the items critical to a seller’s due diligence process as well as our sage guidance and best practices to use throughout the process.",
    requests: [
      "Legal",
      "Valuation",
      "Tax",
      "Financial",
      "Information Technology",
      "EH&S",
      "Intellectual Property",
      "HR",
    ],
    tags: [Tags.DueDiligence],
    resources: [
      {
        url: "https://dealroom.net/blog/sell-side-due-diligence",
        title: "How to Conduct Sell-Side Due Diligence + playbook",
      },
      {
        url: "https://dealroom.net/blog/important-lessons-on-the-seller-side-of-m-a",
        title: "Important Sell Side M&A Process Lessons",
      },
      {
        url: "https://dealroom.net/blog/biggest-challenges-in-mergers-and-acquisitions-for-sellers",
        title:
          "What are Some of the Biggest Challenges in M&A Transactions for Sellers?",
      },
    ],
  },
  {
    id: "buy_side_dd",
    title: "Buy Side Due Diligence Playbook",
    description:
      "Conducting due diligence is one of the most important parts of the M&A process for a buyer to systemize. That means knowing what to look for and where in a target company. This buy side due diligence playbook provides you with a detailed checklist to ensure you’re not overlooking anything - regardless of how seemingly inconsequential - that could destroy value in your transaction.",
    requests: [
      "Legal",
      "Valuation",
      "Tax",
      "Financial",
      "Information Technology",
      "EH&S",
      "Intellectual Property",
      "HR",
    ],
    tags: [Tags.DueDiligence],
    resources: [
      {
        url: "https://dealroom.net/blog/buy-side-due-diligence",
        title: "How to Conduct Buy-Side Due Diligence + playbook",
      },
      {
        url: "https://dealroom.net/blog/important-lessons-on-the-seller-side-of-m-a",
        title: "Important Sell Side M&A Process Lessons",
      },
    ],
  },
  {
    id: "real_estate_dd",
    title: "Real Estate Due Diligence Playbook",
    description:
      "The real estate due diligence playbook is specifically tailored to transactions involving commercial real estate assets. This playbook is applicable whether you’re investing in individual properties or a large multi-use development. The playbook may be customized to accommodate the unique needs of your team or your deal. ",
    requests: [
      "Property information",
      "Operational information",
      "Survey, title, and zoning",
      "Financial information",
      "Governmental",
      "Tenancy",
      "Legal, insurance, and contracts",
    ],
    tags: [Tags.DueDiligence],
    resources: [
      {
        url: "https://dealroom.net/blog/how-to-conduct-due-diligence-when-buying-a-business",
        title:
          "How to Conduct Due Diligence When Buying a Business: Advice from M&A Experts",
      },
      {
        url: "https://dealroom.net/blog/valuation-approach-for-diligence-and-integration",
        title:
          "How the Approach to Valuation Frames Success for Diligence and Integration",
      },
      {
        url: "https://dealroom.net/blog/the-increasing-significance-of-m-a-due-diligence",
        title: "The Increasing Significance of M&A Due Diligence",
      },
    ],
  },
  {
    id: "hedge_fund_investment_dd",
    title: "Hedge Fudge Investment Due Diligence Playbook",
    description:
      "The hedge fund investment due diligence playbook provides basic questions you’ll need to have answered before entrusting a fund with your money. Keep in mind that these questions are suited for the preliminary stages of investing in a hedge fund.",
    requests: [
      "Strategy",
      "Structure",
      "Fees",
      "Compliance",
      "Tax",
      "Miscellaneous items",
    ],
    tags: [Tags.DueDiligence],
    resources: [
      {
        url: "https://dealroom.net/blog/how-to-conduct-due-diligence-when-buying-a-business",
        title:
          "How to Conduct Due Diligence When Buying a Business: Advice from M&A Experts",
      },
      {
        url: "https://dealroom.net/blog/mergers-and-acquisitions-transaction-risks-and-ways-to-mitigate-them",
        title: "10 Most Common M&A Risks and Ways to Mitigate Them",
      },
      {
        url: "https://dealroom.net/blog/guide-on-deal-fever",
        title: "M&A Risk Management Basics & How To Prevent Deal Fever",
      },
    ],
  },
  {
    id: "operational_dd",
    title: "Operational Due Diligence Playbook",
    description:
      "When planning an acquisition, investors and businesses need to be informed on the operational structure that the target company uses. Use this playbook to organize the facts focused on the operational structure and new measures of the target company.",
    requests: [
      "Organizational structure",
      "Insurance relevant items",
      "Staff of the target company",
      "Governance",
      "Policies items for audit",
      "Risks handling requirements",
      "Pre Acquisition compliance",
    ],
    tags: [Tags.DueDiligence],
    resources: [
      {
        url: "https://dealroom.net/books/an-introductory-guide-to-talent-focused-acquisitions",
        title: "An Introductory Guide to Talent Focused Acquisitions",
      },
      {
        url: "https://dealroom.net/blog/sell-side-due-diligence",
        title: "How to Conduct Sell-Side Due Diligence + playbook",
      },
      {
        url: "https://dealroom.net/blog/buy-side-due-diligence",
        title: "How to Conduct Buy-Side Due Diligence + playbook",
      },
    ],
  },
  {
    id: "compliance_dd",
    title: "Compliance Due Diligence Playbook",
    description:
      "This compliance due diligence playbook will provide you with an overview of all the considerations and risks that require attention before going through the M&A process. It outlines practical steps and tips for how to thoroughly analyze the targeted business.",
    requests: [
      "Pre acquisition steps",
      "How to complete",
      "General Compliance and Ethics Program",
      "Risk factors that require your attention",
      "Starting negotiations",
      "Post-merger process",
    ],
    tags: [Tags.DueDiligence],
    resources: [
      {
        url: "https://dealroom.net/blog/business-acquisition-letter-of-intent",
        title:
          "How to Draft and Negotiate a Business Acquisition Letter of Intent",
      },
      {
        url: "https://dealroom.net/blog/a-buy-side-perspective-using-agile-principles",
        title: "A Buy-Side Perspective: Using Agile Principles",
      },
      {
        url: "https://dealroom.net/blog/how-to-conduct-due-diligence-when-buying-a-business",
        title:
          "How to Conduct Due Diligence When Buying a Business: Advice from M&A Experts",
      },
    ],
  },
  {
    id: "asset_purchase_dd",
    title: "Asset Purchase Due Diligence Playbook",
    description:
      "This playbook focuses on legal due diligence undertaken in connection with an asset purchase. Using this playbook, the buyer and its advisers can utilize a series of questions to be put to the seller.",
    requests: [
      "Financial statements",
      "Current or pending litigations",
      "Constitutional restrictions",
      "Contractual obligations",
      "Intellectual property considerations",
      "Regulatory obligations",
      "Miscellaneous liabilities",
    ],
    tags: [Tags.DueDiligence],
    resources: [
      {
        url: "https://dealroom.net/blog/management-due-diligence",
        title: "Tips for Preparing and Conducting Successful Due Diligence",
      },
      {
        url: "https://dealroom.net/blog/how-to-conduct-due-diligence-when-buying-a-business",
        title:
          "How to Conduct Due Diligence When Buying a Business: Advice from M&A Experts",
      },
      {
        url: "https://dealroom.net/blog/valuation-approach-for-diligence-and-integration",
        title:
          "How the Approach to Valuation Frames Success for Diligence and Integration",
      },
    ],
  },
  {
    id: "hr_dd",
    title: "HR Due Diligence Playbook",
    description:
      "Human Resources (HR) plays a key role in the mergers and acquisition process, enabling companies to analyze and assess the human capital of the merging companies. This playbook will allow you to identify issues, risks, costs, opportunities and potential savings to extract as much value as possible from HR.",
    requests: [
      "General HR",
      "Agreements",
      "Benefits",
      "Policies",
      "Compliance",
    ],
    tags: [Tags.DueDiligence],
    resources: [
      {
        url: "https://dealroom.net/blog/guide-to-mergers-acquisitions-due-diligence-from-hr",
        title:
          "The HR Practitioner’s Guide to Mergers & Acquisitions Due Diligence",
      },
      {
        url: "https://dealroom.net/blog/moving-employees-during-divestitures-and-carve-outs",
        title:
          "HR’s Best Practices for Protecting Employees and Deal Value During Divestitures and Carve-Outs",
      },
      {
        url: "https://dealroom.net/blog/guide-to-hr-role-in-divestitures",
        title: "A Comprehensive Guide to HR’s Role In Divestitures",
      },
    ],
  },
  {
    id: "tax_dd",
    title: "Tax Due Diligence Playbook",
    description:
      "This playbook provides you with everything you require to conduct a comprehensive examination of different types of taxes that could impact your business, as well as issues surrounding tax jurisdictions and other crucial taxing items that a company should know about. ",
    requests: [
      "Tax information from the selling side",
      "Tax correspondences",
      "General",
      "Federal tax information",
      "State and local tax information",
      "Compensation tax information",
      "Possible international tax information",
    ],
    tags: [Tags.DueDiligence],
    resources: [
      {
        url: "https://dealroom.net/blog/valuation-approach-for-diligence-and-integration",
        title:
          "How the Approach to Valuation Frames Success for Diligence and Integration",
      },
      {
        url: "https://dealroom.net/blog/company-valuation-methods",
        title: "A Review of Business Valuation Methods Available to Buyers",
      },
      {
        url: "https://dealroom.net/blog/buy-side-due-diligence",
        title: "How to Conduct Buy-Side Due Diligence + playbook",
      },
    ],
  },
  {
    id: "finance_dd",
    title: "Financial Due Diligence Playbook",
    description:
      "This playbook provides you with everything you require to conduct a comprehensive examination of different types of taxes that could impact your business, as well as issues surrounding tax jurisdictions and other crucial taxing items that a company should know about. ",
    requests: [
      "Tax information from the selling side",
      "Tax correspondences",
      "General",
      "Federal tax information",
      "State and local tax information",
      "Compensation tax information",
      "Possible international tax information",
    ],
    tags: [Tags.DueDiligence],
    resources: [
      {
        url: "https://dealroom.net/blog/valuation-approach-for-diligence-and-integration",
        title:
          "How the Approach to Valuation Frames Success for Diligence and Integration",
      },
      {
        url: "https://dealroom.net/blog/company-valuation-methods",
        title: "A Review of Business Valuation Methods Available to Buyers",
      },
      {
        url: "https://dealroom.net/blog/buy-side-due-diligence",
        title: "How to Conduct Buy-Side Due Diligence + playbook",
      },
    ],
  },
  {
    id: "oil_and_gas_dd",
    title: "Oil and Gas Due Diligence Playbook",
    description:
      "Investing in or acquiring an oil or gas company comes with a specific set of risks that require attention. This playbook can help you navigate your transaction, identify red flags, and enable you to appraise the true value of an investment in the energy industry. ",
    requests: [
      "Corporate",
      "Land",
      "Legal Contracts",
      "Environmental",
      "Financial",
    ],
    tags: [Tags.DueDiligence],
    resources: [
      {
        url: "https://dealroom.net/blog/management-due-diligence",
        title: "Tips for Preparing and Conducting Successful Due Diligence",
      },
      {
        url: "https://dealroom.net/blog/due-diligence-documents",
        title: "Typical Due Diligence Documents Required for M&A + playbook",
      },
      {
        url: "https://dealroom.net/blog/management-due-diligence",
        title: "Tips for Preparing and Conducting Successful Due Diligence",
      },
    ],
  },
  {
    id: "accounting_dd",
    title: "Accounting Due Diligence Playbook",
    description:
      "Performing proper accounting due diligence is a crucial preemptive measure to closing any investment deal, merger, or acquisition. This playbook takes the users through each of the accounting issues that require attention in a step-by-step fashion.",
    requests: [
      "General information",
      "Sales and earnings",
      "Plant and Real Estate",
      "Assets",
      "Liabilities",
      "Financial data",
      "Terms and projected financials",
    ],
    tags: [Tags.DueDiligence],
    resources: [
      {
        url: "https://dealroom.net/blog/summit-recap-avoiding-valuation-surprises-and-accounting-for-m-a-transactions",
        title:
          "Summit Recap: Avoiding Valuation Surprises and Accounting for M&A Transactions",
      },
      {
        url: "http://how-to-plan-a-divestiture-from-an-accounting-perspective",
        title: "How To Plan a Divestiture From an Accounting Perspective",
      },
      {
        url: "https://dealroom.net/blog/7-ways-excel-fails-at-managing-m-a-due-diligence",
        title: "Use of a Due Diligence Tracker for Managing M&A Due Diligence",
      },
    ],
  },
  {
    id: "it_dd",
    title: "IT Due Diligence Playbook",
    description:
      "Conducting thorough IT due diligence and execution is essential to maximizing a deal’s value creation. Every company is now a technology company to some extent, so IT due diligence is an essential step in extracting value from a deal. This playbook enables a buyer or investor to ask questions that allow them to learn about a company’s technology infrastructure and accurately evaluate a potential transaction or investment.",
    requests: [
      "General IT Administration",
      "Software",
      "Hardware",
      "Privacy, Data Management",
      "IT Security",
      "IT Staff",
      "Operations, Procedures",
    ],
    tags: [Tags.DueDiligence],
    resources: [
      {
        url: "https://dealroom.net/blog/evolving-technology-trends-in-mergers-and-acquisitions",
        title: "How Trending Technology is Impacting the M&A Industry",
      },
      {
        url: "https://dealroom.net/blog/m-a-technology-software-tools-overview",
        title: "M&A Technology Tools to Use for Successful M&A Transactions",
      },
      {
        url: "https://dealroom.net/blog/how-technology-is-changing-investment-banking",
        title: "How Technology is Changing Investment Banking",
      },
    ],
  },
  {
    id: "biotech_dd",
    title: "Biotechnology Due Diligence Playbook",
    description:
      "Biotech company acquisitions often host a great number of challenges, making the due diligence process that much more important. This playbook is built for those looking to predict, prepare, and prevent the unique issues and complications that can arise during a biotech company acquisition.",
    requests: [
      "Legal",
      "Valuation",
      "Compliance",
      "Marketing",
      "Manufacturing protocols",
      "Investigative research",
      "Tax and Finance",
      "Current agreements",
      "Intellectual Property",
    ],
    tags: [Tags.DueDiligence],
    resources: [
      {
        url: "https://dealroom.net/blog/evolving-technology-trends-in-mergers-and-acquisitions",
        title: "How Trending Technology is Impacting the M&A Industry",
      },
      {
        url: "https://dealroom.net/blog/buy-side-due-diligence",
        title: "How to Conduct Buy-Side Due Diligence + playbook",
      },
      {
        url: "https://dealroom.net/blog/7-ways-excel-fails-at-managing-m-a-due-diligence",
        title: "Use of a Due Diligence Tracker for Managing M&A Due Diligence",
      },
    ],
  },
  {
    id: "ipo_dd",
    title: "Due Diligence Playbook for IPO",
    description:
      "Growing evidence from the past decade suggests that even the most experienced investment banks are overlooking certain elements of pre-IPO due diligence in an effort to get companies on the market as quickly as possible. This isn’t a good start for the company or its investors. This playbook aims to provide you with a checklist of all the issues you need to consider when planning an IPO.",
    requests: [
      "Organization",
      "License and Tax",
      "Company Employees and Board Information",
      "Financial",
      "Service and Customer",
      "Property Related Items",
    ],
    tags: [Tags.DueDiligence, Tags.IPO],
    resources: [
      {
        url: "https://dealroom.net/blog/how-to-prepare-for-an-ipo",
        title: "Preparing for an IPO: A 6-Step Guide",
      },
      {
        url: "https://dealroom.net/books/top-10-m-a-tips-and-practices",
        title: "Top 10 M&A Tips and Practices",
      },
    ],
  },
  {
    id: "legal_dd",
    title: "Legal Due Diligence Playbook",
    description:
      "Utilyze this playbook to collect and organize the necessary information related to the target company. It gives both the buyer and seller the chance to scrutinize any legal risks, such as lawsuits or Intellectual property details, before closing the deal. By understanding the target company and any potential liabilities, you are able to make an informed decision in the M&A transaction.",
    requests: [
      "General",
      "Organization/Ownership",
      "Litigation",
      "Product/Service",
      "Contracts/Obligations",
      "Permits/Licenses",
      "Intellectual Property",
      "HR/Operations",
      "Environmental",
    ],
    tags: [Tags.DueDiligence],
    resources: [
      {
        url: "https://dealroom.net/blog/how-to-prepare-for-an-ipo",
        title: "Preparing for an IPO: A 6-Step Guide",
      },
      {
        url: "https://dealroom.net/books/top-10-m-a-tips-and-practices",
        title: "Top 10 M&A Tips and Practices",
      },
    ],
  },
  {
    id: "private_equity_dd",
    title: "Private Equity Due Diligence Playbook",
    description:
      "This playbook provides a framework of initial due diligence requests for companies in the private equity space. The general partner (GP) and limited partner (LP) will both require specific information about the transaction. This ranges from general information about the company and its culture to more in-depth analysis of internal processes.",
    requests: [
      "Group companies",
      "Bank and financial liabilities",
      "Real property and other assets",
      "Intellectual property",
      "Contracts",
      "Employees and employee benefits",
      "Compliance",
      "Environment",
      "Environment",
    ],
    tags: [Tags.DueDiligence, Tags.PrivateEquity],
    resources: [
      {
        url: "https://dealroom.net/blog/legal-due-diligence",
        title: "The Ins and Outs of Legal Due Diligence",
      },
      {
        url: "https://dealroom.net/blog/balancing-legal-and-business-concerns-in-m-a-transactions",
        title: "Balancing Legal and Business Concerns in M&A Transactions",
      },
      {
        url: "https://dealroom.net/blog/balancing-legal-and-business-concerns-in-m-a-transactions",
        title: "The Role of M&A Lawyers, Real Stories from an M&A Attorney",
      },
    ],
  },
  {
    id: "manufacturing_dd",
    title: "Manufacturing Due Diligence Playbook",
    description:
      "Utilyze this playbook to access a pre-made list of requests that focus on areas of due diligence where issues most often arise for manufacturing targets. The playbook is customizable to meet the specific circumstances of your deal.",
    requests: [
      "Legal",
      "Commercial",
      "Financial",
      "HR",
      "IT",
      "Intellectual property",
      "Tax",
      "Environmental, health, and safety",
    ],
    tags: [Tags.DueDiligence],
    resources: [
      {
        url: "https://dealroom.net/blog/due-diligence-documents",
        title: "Typical Due Diligence Documents Required for M&A + playbook",
      },
      {
        url: "https://dealroom.net/blog/7-ways-excel-fails-at-managing-m-a-due-diligence",
        title: "Use of a Due Diligence Tracker for Managing M&A Due Diligence",
      },
    ],
  },
  {
    id: "healthcare_dd",
    title: "Healthcare Due Diligence Playbook",
    description:
      "Access this playbook and receive a comprehensive list of due diligence requests items, tailored to the healthcare industry. The requests are prioritized to focus on areas of due diligence where issues most often arise for healthcare targets.",
    requests: [
      "Legal",
      "Commercial",
      "Financial",
      "HR",
      "IT",
      "Intellectual property",
      "Tax",
    ],
    tags: [Tags.DueDiligence],
    resources: [
      {
        url: "https://dealroom.net/blog/management-due-diligence",
        title: "Tips for Preparing and Conducting Successful Due Diligence",
      },
      {
        url: "https://dealroom.net/blog/how-to-conduct-due-diligence-when-buying-a-business",
        title:
          "How to Conduct Due Diligence When Buying a Business: Advice from M&A Experts",
      },
      {
        url: "https://dealroom.net/blog/due-diligence-documents",
        title: "Typical Due Diligence Documents Required for M&A + playbook",
      },
    ],
  },
  {
    id: "nonprofit_dd",
    title: "Nonprofit Merger Due Diligence Playbook",
    description:
      "When using this playbook, all parties, including the nonprofit’s board, will have a clear insight into the merging company’s items of interest by providing the necessary documentations for a review.",
    requests: [
      "Organizational",
      "Financial",
      "Legal and tax",
      "HR",
      "Organizational policy",
      "Company’s confidential data",
      "Real and intellectual property",
    ],
    tags: [Tags.DueDiligence],
    resources: [
      {
        url: "https://dealroom.net/blog/typical-due-diligence-questions-to-ask",
        title: "Typical Due Diligence Questions to Ask + playbook",
      },
      {
        url: "https://dealroom.net/blog/7-ways-excel-fails-at-managing-m-a-due-diligence",
        title: "Use of a Due Diligence Tracker for Managing M&A Due Diligence",
      },
    ],
  },
  {
    id: "venture_capital_dd",
    title: "Venture Capital Due Diligence Playbook",
    description:
      "This playbook provides a list of due diligence requests that can be used by a venture capital firm to assess potential investment. Categories include compliance issues, market overview, operational data, and more. This playbook is designed to reduce the investors' risk by understanding the issues and challenges embedded in a business proposal.",
    requests: [
      "Corporate structure",
      "Agreements of material importance",
      "Real estate",
      "Intellectual property and IT",
      "Labor issues",
      "Compliance issues",
      "Operational and technical data",
      "Litigation",
      "Financial",
      "Taxes",
      "Market overview",
      "Other information",
    ],
    tags: [Tags.DueDiligence],
    resources: [
      {
        url: "https://dealroom.net/blog/working-with-private-sellers",
        title: "Working With Private Sellers",
      },
      {
        url: "https://dealroom.net/blog/top-10-best-private-equity-podcasts",
        title: "10 Best Private Equity Podcasts",
      },
      {
        url: "https://dealroom.net/blog/buy-side-due-diligence",
        title: "How to Conduct Buy-Side Due Diligence",
      },
    ],
  },
  {
    id: "post_merger",
    title: "Post Merger Integration Playbook",
    description:
      "Use this playbook to access a framework for integration requests 30, 60, and 100 days post close. The initial requests help prompt basic information for an overview of the target company and we encourage teams to customize the list for their needs.",
    requests: [
      "Close plus 30 days",
      "Close plus 60 days",
      "Close plus 100 days",
    ],
    tags: [Tags.Integration],
    resources: [
      {
        url: "https://dealroom.net/books/12-biggest-mistakes-in-m-a-integration",
        title: "12 Biggest Mistakes in M&A Integration",
      },
      {
        url: "https://dealroom.net/blog/how-proactive-integration-engagement-can-differentiate-value-in-deals",
        title:
          "How Proactive Integration Engagement Can Differentiate Value in Deals",
      },
      {
        url: "https://dealroom.net/blog/why-m-a-integrations-fail",
        title:
          "Top 5 Reasons of M&A Integration Failures & How to Prevent Them",
      },
    ],
  },
  {
    id: "int_day1",
    title: "Integration Plan Day 1 Readiness Playbook",
    description:
      "Conducting proper and thorough integration is vital to establishing an effective Day 1 plan. Deal value creation will ultimately hinge on how well organizations integrate on all levels, and this playbook will help ensure nothing essential is missed in the process.",
    requests: [
      "Sales and Operations",
      "Human Resources and Employee Onboarding",
      "Real Estate and Leasing",
      "IT and Telephony",
      "Finance and Legal Entity",
      "Communications and Change Management",
    ],
    tags: [Tags.Integration],
    resources: [
      {
        url: "https://dealroom.net/blog/corporate-development-and-integration",
        title:
          "Summit Recap: Bridging the Gap Between Corporate Development and Integration",
      },
      {
        url: "https://dealroom.net/blog/virtualizing-the-people-experience-during-integration",
        title:
          "Virtual Summit Recap: Virtualizing the People Experience During Integration",
      },
      {
        url: "https://dealroom.net/blog/how-to-manage-mergers-and-acquisitions-integration",
        title:
          "How Cisco Manages M&A Integration: Strategy & Process Explained",
      },
    ],
  },
  {
    id: "capital_raise",
    title: "Capital Raise (Equity or Debt) Playbook",
    description:
      "DealRoom knows first-hand that as a start-up, the beginning can be overwhelming and rife with challenges. Raising capital through equity or debt is one way to boost your funds and reputation. However, seeking the right funding is easier said than done. With our Raising Capital Playbook, you’ll be walked through the process.",
    requests: [
      "Capital-Formation Strategy",
      "Preparing Confidential Info",
      "Develop a Winning Pitch Deck",
      "Meetings and Immersion",
      "Creation of a Pro-Forma Cap Table",
      "Firm's Efficacy and Legality",
      "Seal the Deal",
    ],
    tags: [Tags.CapitalRaise],
    resources: [],
  },
  {
    id: "finance_int",
    title: "Finance Integration Playbook",
    description:
      "This playbook is tailored to assist practitioners to accomplish the financial aspects of integration, and  provides a framework for the necessary finance-related responsibilities that should be met for successful integration.",
    requests: [
      "Budgeting and Forecasting",
      "Financial Accounting",
      "Financial Asset",
      "Financial Credit and Transition",
      "Financial Organization",
      "Financial System",
      "Insurance",
      "Procurement",
      "Program Exit Strategy",
      "Reporting",
      "Taxes and Legality",
      "Treasury and Cash Management",
    ],
    tags: [Tags.Integration],
    resources: [],
  },
  {
    id: "technology_dd",
    title: "Technology Due Diligence Playbook",
    description:
      "Deals in the tech industry bring their own unique challenges. This playbook is tailored to ensure vital technology company information is structured correctly, save your time and simplify the process.",
    requests: [
      "Communication and Collaboration ",
      "Corporate and Leadership",
      "Cybersecurity",
      "Disputes and Compliance",
      "Employees",
      "Environmental, Health and Safety",
      "Financials",
      "Intellectual Property",
      "Legal",
      "Technology Assessment",
    ],
    tags: [Tags.DueDiligence],
    resources: [],
  },

  {
    id: "environment_dd",
    title: "Environmental Due Diligence Playbook",
    description:
      "A common error among those purchasing a company or commercial real estate is overlooking the importance of conducting a detailed investigation of the environmental factors that can significantly reduce the target’s value after the acquisition. This due diligence playbook will help you to properly assess the environmental issues that will impact on the value of the asset, now and in the future.",
    requests: ["General Information", "Main environmental matters"],
    tags: [Tags.DueDiligence],
    resources: [],
  },

  {
    id: "ip_dd",
    title: "IP Due Diligence Playbook",
    description:
      "Accurately assess the value of intellectual property during a transaction with this pre-built due diligence playbook.",
    requests: [
      "Trademark",
      "Copyrights",
      "Patent",
      "Domain names",
      "Industrial designs",
      "Trade secrets policies",
      "Goodwill",
      "IP documentation",
    ],
    tags: [Tags.DueDiligence],
    resources: [],
  },

  {
    id: "small_business_dd",
    title: "Small Business Due Diligence Playbook",
    description:
      "This playbook will help you to conduct small business due diligence. It contains all the necessary documentation and items to prove the target company’s well-being. It will help you to execute the due diligence process thoroughly by inspecting all the crucial business aspects.",
    requests: [
      "Organizational and operations",
      "Financial",
      "Legal",
      "Material and partnership contracts",
      "Company’s intellectual and material property items",
      "Employee and board of directors items",
    ],
    tags: [Tags.DueDiligence],
    resources: [],
  },

  {
    id: "esg_dd",
    title: "ESG Due Diligence Playbook",
    description:
      "Our ESG due diligence playbook will help you identify any environmental, social and governance issues and risks with your investments or to prepare for external ESG diligence process.",
    requests: [
      "Environmental issues and risks",
      "Social issues and risks",
      "Governance issues and risks",
    ],
    tags: [Tags.DueDiligence],
    resources: [],
  },
];

export default playbooks;
