<template>
  <div v-if="isInviteValid === null">
    <div class="page-loader" />
  </div>

  <div v-else-if="isInviteValid" class="auth-form">
    <div class="auth-form__title text-center">New account</div>
    <ElForm
      ref="registrationForm"
      :model="registrationModel"
      :disabled="isSubmitting"
      :rules="validationRules"
      :label-position="'top'"
      size="small"
      @submit.prevent="register"
    >
      <ElFormItem
        type="text"
        prop="email"
        label="Email"
        :error="getBackendError('email')"
      >
        <!-- User can't edit email of the Invite! -->
        <ElInput
          v-model="registrationModel.email"
          name="email"
          autocomplete="username"
          size="large"
          disabled
        />
      </ElFormItem>

      <ElFormItem
        prop="first_name"
        label="First Name"
        :error="getBackendError('first_name')"
      >
        <ElInput
          v-model="registrationModel.first_name"
          name="first_name"
          autocomplete="given-name"
          size="large"
        />
      </ElFormItem>

      <ElFormItem
        prop="last_name"
        label="Last Name"
        :error="getBackendError('last_name')"
      >
        <ElInput
          v-model="registrationModel.last_name"
          name="last_name"
          autocomplete="family-name"
          size="large"
        />
      </ElFormItem>

      <ElFormItem
        prop="company"
        label="Company"
        :error="getBackendError('company')"
      >
        <ElInput
          v-model="registrationModel.company"
          name="company"
          autocomplete="organization"
          size="large"
        />
      </ElFormItem>

      <ElFormItem prop="title" label="Title" :error="getBackendError('title')">
        <ElInput
          v-model="registrationModel.title"
          name="title"
          autocomplete="organization-title"
          size="large"
        />
      </ElFormItem>

      <ElFormItem
        prop="office_number"
        label="Phone Number"
        :error="getBackendError('office_number')"
      >
        <ElInput
          v-model="registrationModel.office_number"
          name="office_number"
          autocomplete="tel"
          size="large"
          type="text"
        />
      </ElFormItem>

      <ElFormItem
        class="el-form-item--password"
        prop="password"
        label="Create a password"
        :error="getBackendError('password')"
      >
        <ElInput
          v-model="registrationModel.password"
          name="password"
          show-password
          autocomplete="new-password"
          size="large"
        />
      </ElFormItem>

      <ElFormItem
        class="el-form-item--password"
        prop="passwordConfirmation"
        label="Confirm password"
      >
        <ElInput
          v-model="registrationModel.passwordConfirmation"
          name="passwordConfirmation"
          show-password
          autocomplete="new-password"
          size="large"
        />
      </ElFormItem>

      <ElFormItem
        class="el-form-item--no-spacing"
        prop="subscribe"
        :error="getBackendError('subscribe')"
      >
        <ElCheckbox
          v-model="registrationModel.subscribe"
          name="subscribe"
          style="white-space: normal; height: auto"
        >
          I agree to receive marketing communications with updates of DealRoom’s
          products and/or services.
        </ElCheckbox>
      </ElFormItem>

      <ElFormItem
        prop="agree_policies"
        :error="getBackendError('agree_policies')"
      >
        <ElCheckbox
          v-model="registrationModel.agree_policies"
          name="agree_policies"
        >
          I agree to the
          <a ng-href="/terms" target="_blank">Terms of Use</a>
          and
          <a ng-href="/privacy" target="_blank">Privacy Policy</a>
          upon signup.
        </ElCheckbox>
      </ElFormItem>

      <ElButton
        class="auth-form__submit"
        type="primary"
        native-type="submit"
        :loading="isSubmitting"
        :disabled="isSubmitting"
        size="large"
      >
        Sign up
      </ElButton>
    </ElForm>
  </div>

  <div v-else>
    <div>
      <div
        class="alert alert-danger alert-block alert-dismissible fade in"
        role="alert"
      >
        The invite link is invalid. It is possible it has already been used.
        <br />
        Please
        <RouterLink class="auth-form__forgot" to="/auth">login</RouterLink>
        or request another invitation.
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrForm from "@shared/ui/dr-form";

import PasswordService from "@app/common/password-service";
import { getTimezone } from "@drVue/common";
import { AuthApiService } from "@drVue/components/auth/AuthApiService";

import type { Dictionary } from "@drVue/types";
import type { ElForm } from "element-plus";

interface RegistrationModel {
  email: string;
  first_name: string;
  last_name: string;
  company: string;
  title: string;
  office_number: string;
  password: string;
  passwordConfirmation?: string;
  subscribe: boolean;
  agree_policies: boolean;
}

interface Data {
  api: AuthApiService;
  isInviteValid: null | boolean;
  registrationModel: RegistrationModel;
  validationRules: Dictionary<any[]>;
}

export default defineComponent({
  extends: DrForm,
  data: function (): Data {
    return {
      api: new AuthApiService(),
      isInviteValid: null,
      registrationModel: {
        email: "",
        first_name: "",
        last_name: "",
        company: "",
        title: "",
        office_number: "",
        password: "",
        passwordConfirmation: "",
        subscribe: false,
        agree_policies: false,
      },
      validationRules: {
        email: [
          {
            required: true,
            trigger: "blur",
            message: "This field may not be blank.",
          },
          {
            max: 254,
            trigger: "blur",
            message: "Ensure this field has no more than 254 characters.",
          },
        ],
        first_name: [
          {
            required: true,
            trigger: "blur",
            message: "This field may not be blank.",
          },
          {
            max: 50,
            message: "Ensure this field has no more than 50 characters.",
          },
        ],
        last_name: [
          {
            required: true,
            trigger: "blur",
            message: "This field may not be blank.",
          },
          {
            max: 50,
            message: "Ensure this field has no more than 50 characters.",
          },
        ],
        company: [
          {
            required: true,
            trigger: "blur",
            message: "This field may not be blank.",
          },
          {
            max: 100,
            message: "Ensure this field has no more than 100 characters.",
          },
        ],
        title: [
          {
            required: true,
            trigger: "blur",
            message: "This field may not be blank.",
          },
          {
            max: 100,
            message: "Ensure this field has no more than 100 characters.",
          },
        ],
        office_number: [
          {
            required: true,
            trigger: "blur",
            message: "This field may not be blank.",
          },
          {
            min: 7,
            message: "Ensure this field has at least 7 characters.",
          },
          {
            pattern: /^[0-9-+\s()]+$/,
            message:
              'Please use digits, symbols "+", "-", round brackets and spaces.',
          },
          {
            max: 25,
            message: "Ensure this field has no more than 25 characters.",
          },
        ],
        password: [
          {
            required: true,
            trigger: "blur",
            message: "This field may not be blank.",
          },
          {
            validator: (
              rule: any,
              value: string,
              callback: (error?: Error | string) => void,
            ) => {
              // Was validated by 'required' rule above.
              if (!value || value.length === 0) callback();

              const result = PasswordService.test(value);
              if (result.errors.length > 0) {
                callback(result.errors.join("\n"));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
        passwordConfirmation: [
          {
            required: true,
            trigger: "blur",
            message: "This field may not be blank.",
          },
          {
            validator: (
              rule: any,
              value: string,
              callback: (error?: Error | string) => void,
            ) => {
              if (!value || value.length === 0) callback();

              if (
                (this as any).registrationModel.password ===
                (this as any).registrationModel.passwordConfirmation
              ) {
                callback();
              } else {
                callback("Passwords must match.");
              }
            },
            trigger: ["blur", "change"],
          },
        ],
        subscribe: [{ required: false }],
        agree_policies: [
          {
            validator: (
              rule: any,
              value: boolean,
              callback: (error?: Error | string) => void,
            ) => {
              if (value) {
                callback();
              } else {
                callback("You must accept the policies.");
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  beforeMount() {
    const checkRequest = {
      confirmation_key: this.$route.params.confirmation_key,
      invite_check_only: true,
    };

    const checkPromise = this.api.register(checkRequest);
    this.submitPromise(checkPromise).then(
      (r) => {
        this.isInviteValid = true;
        this.registrationModel.email = r.data["email"];
      },
      () => (this.isInviteValid = false),
    );
  },
  methods: {
    getRegistrationForm() {
      return this.$refs["registrationForm"] as typeof ElForm;
    },
    register: function () {
      if (this.isSubmitting) return;

      this.getRegistrationForm()
        .validate()
        .then(
          () => {
            const registerRequest = {
              ...this.registrationModel,
              confirmation_key: this.$route.params.confirmation_key,
              timezone: getTimezone(),
            };

            delete registerRequest.passwordConfirmation;

            const registerPromise = this.api.register(registerRequest);
            this.submitPromise(registerPromise).then(() => {
              this.isSubmitting = true;
              location.href = this.api.home_url;
            });
          },
          () => {}, // A-la catch(e) {}, don't delete.
        );
    },
  },
});
</script>
