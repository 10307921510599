<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <div v-if="!meta.isEditable">
    <div class="dash-content-panel-info__item-label">Phone</div>
    <div class="dash-content-panel-info__item-value">
      <DrTruncatedTextTooltip
        v-if="item.office_number"
        :content="item.office_number"
      >
        <a :href="'tel:' + item.office_number" target="_blank">
          <i class="fas fa-phone-alt dash-content-panel-info__item-icon" />
          {{ item.office_number }}
        </a>
      </DrTruncatedTextTooltip>
      <span v-else>-</span>
    </div>
  </div>
  <div v-else>
    <ElFormItem
      key="office_number"
      label="Phone"
      :error="getBackendError('office_number')"
    >
      <ElInput v-model="item['office_number']" clearable />
    </ElFormItem>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { PropType } from "vue";

export default defineComponent({
  name: "ViewOrEditPhoneField",
  components: {
    DrTruncatedTextTooltip,
  },
  props: {
    item: { required: true, type: Object as PropType<OrgUser> },
    meta: { required: true, type: Object as PropType<any> },
    getBackendError: {
      required: false,
      type: Function as PropType<(fieldPath: string) => string>,
      default: () => "",
    },
  },
});
</script>
