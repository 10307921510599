import type { State } from "./state";
import type { Item, ItemPayload, Order, RootState } from "./types";
import type { ClientDashboardApiService } from "@drVue/api-service/client-dashboard";
import type { ActionContext, ActionTree } from "vuex";

type IContext = ActionContext<State, RootState>;

export function getActions(
  api: ClientDashboardApiService<Item>,
): ActionTree<State, RootState> {
  return {
    addItem,
    deleteItem,
    editItem,
    reorder,
  };

  function addItem({ commit }: IContext, { item }: ItemPayload): Promise<Item> {
    return api.addItem(item).then((edited: Item) => {
      commit("addItem", { item: edited });
      return edited;
    });
  }

  function deleteItem(
    { dispatch, commit }: IContext,
    { item }: ItemPayload,
  ): Promise<void> {
    return api.deleteItem(item).then(() => {
      // to update phases on existing deals with this phase
      dispatch("clientDashboard/deals/load", null, { root: true });

      return commit("deleteItem", { id: item.id });
    });
  }

  function editItem(
    { commit }: IContext,
    { item }: ItemPayload,
  ): Promise<Item> {
    return api.editItem(item).then((edited: Item) => {
      commit("editItem", { item: edited });
      return edited;
    });
  }

  function reorder(
    { commit }: IContext,
    { data }: { data: Order[] },
  ): Promise<void> {
    return api.reorder(data).then(() => commit("reorder", { data }));
  }
}
