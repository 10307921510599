import { diff } from "./helpers/diff";

import type { TaskComparator } from "../types/TaskComparator";

export const new_comments_count: TaskComparator = (
  a,
  b,
  isReversed = false,
) => {
  let d = diff(a.new_comments_count, b.new_comments_count, isReversed);
  if (d === 0) d = diff(a.comments_count, b.comments_count, isReversed);

  return d;
};
