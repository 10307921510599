<template>
  <component
    :is="rootTag"
    ref="itemRef"
    :class="{
      [$style.item]: true,
      [$style.item_isExpanded]: isExpanded,
      [$style.item_isActive]: isActive,
      [$style.item_styleWarning]: type === MainMenuItemStyle.Warning,
      [$style.item_styleError]: type === MainMenuItemStyle.Error,
      [$style.item_hasIcon]: !!$slots.icon || !!icon || dropCapAsIcon,
      [$style.item_hasText]: !!$slots.default || !!$slots.text || !!text,
      [$style.item_isReadonly]: readonly,
      [$style.item_isDisabled]: disabled,
    }"
    :href="href"
    @click="handleSelect($event)"
    @mouseenter="tooltipVisible = true"
    @mouseleave="tooltipVisible = false"
  >
    <div
      v-if="$slots.icon || icon || dropCapAsIcon"
      :class="[
        $style.icon,
        {
          [$style.iconSlotDisabled]: disabled && !!$slots.icon,
        },
      ]"
    >
      <slot name="icon">
        <div v-if="dropCapAsIcon" :class="$style.dropCap">
          {{ dropCap }}
        </div>
        <!-- @vue-ignore -->
        <DrIcon v-else :name="icon" />
      </slot>

      <div v-if="hasDot && !isExpanded" :class="$style.attentionDot" />
    </div>

    <div v-if="$slots.default || $slots.text || text" :class="$style.content">
      <slot name="default">
        <div :class="$style.text">
          <slot name="text">
            {{ text }}
            <DrChip
              v-if="isBeta && !disabled"
              text="beta"
              color="red"
              :class="$style.chip"
            />
            <div v-if="hasDot" :class="$style.attentionDot" />
          </slot>
        </div>

        <div v-if="$slots.right" :class="$style.rightSlot">
          <slot name="right" />
        </div>
      </slot>
    </div>

    <ElTooltip
      effect="menutooltip"
      placement="right"
      :offset="16"
      :visible="tooltipVisible"
      :content="text"
      :disabled="!showTooltip || isExpanded || !text.length"
      virtual-triggering
      :virtual-ref="itemRef"
    />
  </component>
</template>

<script setup lang="ts">
/**
 * Main menu item.
 * Сontent is divided into 2 main blocks: icon and element text.
 * Text block may have additional content by slot at right side.
 * It is possible to specify the output with an icon of the first letter of the text.
 */

import { computed, ref } from "vue";

import DrChip from "../dr-chip";
import DrIcon from "../dr-icon";
import { MainMenuItemStyle } from "./types";

import type { IconName } from "../dr-icon";

interface Props {
  isExpanded?: boolean;
  isActive?: boolean;
  isBeta?: boolean;
  text?: string;
  icon?: IconName | "";
  dropCapAsIcon?: boolean;
  type?: MainMenuItemStyle;
  disabled?: boolean;
  showTooltip?: boolean;
  readonly?: boolean;
  href?: string;
  hasNews?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  isExpanded: false,
  isActive: false,
  isBeta: false,
  text: "",
  icon: "",
  dropCapAsIcon: false,
  type: MainMenuItemStyle.Default,
  disabled: false,
  showTooltip: false,
  readonly: false,
  href: "",
  hasNews: false,
});

const emit = defineEmits<{
  (event: "select", newTab: boolean): void;
}>();

const itemRef = ref();
const tooltipVisible = ref(false);
const rootTag = props.href ? "a" : "div";

const dropCap = computed(() => (props.text || "").charAt(0).toUpperCase());
const hasDot = computed(() => props.hasNews && !props.disabled);

const handleSelect = ($event: MouseEvent) => {
  if (props.disabled || props.readonly) {
    $event.stopPropagation();
    $event.preventDefault();
    return;
  }
  if (!props.href) emit("select", $event.ctrlKey || $event.metaKey);
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography" as typo;

$colors_ad-warn: #ff974b;
$colors_red-chip: #f53e75;

$space: 8px;
$item-radius: 8px;
$item-size: 32px;
$drop-cap-size: 24px;
$drop-cap-radius: 6px;

:global {
  .el-popper.is-menutooltip {
    background-color: colors.$pr-800;
    color: colors.$pr-0;
  }

  .el-popper.is-menutooltip .el-popper__arrow::before {
    background-color: colors.$pr-800;
    right: 0;
  }
}

.chip {
  margin-left: 10px;
}

.attentionDot {
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: colors.$ad-error;
  outline: solid 3px colors.$sc-1000;
}

.item {
  --item-color: #{colors.$pr-350};
  --item-hover-color: #{colors.$pr-0};
  --item-active-color: #{colors.$pr-0};
  --item-bg: #{rgb(colors.$ad-hover-dark, 0%)};
  --item-hover-bg: #{rgb(colors.$ad-hover-dark, 100%)};
  --item-active-bg: #{colors.$sc-600};

  box-sizing: border-box;
  height: $item-size;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  align-items: center;
  overflow: hidden;
  border-radius: $item-radius;
  color: var(--item-color);
  font: typo.$body_regular;
  background-color: var(--item-bg);
  cursor: pointer;
  text-decoration: none;

  transition-timing-function: ease-out;
  transition-duration: 150ms;
  transition-property: background-color, color;
}

.item_hasIcon.item_hasText {
  grid-template-columns: $item-size 1fr;
}

.icon {
  position: relative;
  width: $item-size;
  height: $item-size;
  display: flex;
  align-items: center;
  justify-content: center;
  color: colors.$pr-500;

  .attentionDot {
    position: absolute;
    top: 8px;
    right: 0;
    z-index: 1;
  }
}

.dropCap {
  width: $drop-cap-size;
  height: $drop-cap-size;
  display: flex;
  align-items: center;
  justify-content: center;
  font: typo.$body_semibold;
  color: colors.$pr-300;
  background-color: colors.$ad-hover-dark;
  border-radius: $drop-cap-radius;
  transition: inherit;

  .item:hover & {
    color: colors.$pr-800;
    background-color: colors.$pr-0;
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  overflow: hidden;
  opacity: 0;
  transform: translateX(3px);
  transition-timing-function: ease-out;
  transition-duration: 150ms;
  transition-property: transform, opacity;

  .item_isExpanded & {
    transform: translateX(0);
    opacity: 1;
  }
}

.text {
  max-width: 100%;
  flex: 1 0 auto;
  overflow: hidden;
  padding-left: 2px;
  padding-right: $space;
  white-space: nowrap;
  text-overflow: ellipsis;

  .attentionDot {
    margin-left: 4px;
  }
}

.rightSlot {
  flex-grow: 0;
  flex-shrink: 1;
  padding-right: $space;
}

// Variations
.item_isActive {
  font: typo.$body_semibold;
}

.item:hover,
.item_isActive {
  .icon {
    color: inherit;
  }
}

.item:hover,
.item:focus {
  color: var(--item-hover-color);
  background-color: var(--item-hover-bg);
  text-decoration: none;
}

.item_isActive,
.item_isActive:hover,
.item_isActive:focus {
  color: var(--item-active-color);
  background-color: var(--item-active-bg);
}

.item_isReadonly {
  --item-color: #{colors.$pr-350};
  --item-hover-color: #{colors.$pr-350};
  --item-active-color: #{colors.$pr-350};
  --item-bg: transparent;
  --item-hover-bg: transparent;
  --item-active-bg: transparent;

  cursor: default;

  .icon {
    color: colors.$pr-500;
  }
}

.item_isDisabled {
  --item-color: #{colors.$pr-500};
  --item-hover-color: #{colors.$pr-500};
  --item-active-color: #{colors.$pr-500};
  --item-bg: transparent;
  --item-hover-bg: transparent;
  --item-active-bg: transparent;

  cursor: not-allowed;

  .icon,
  &:hover .icon,
  &.item_isActive .icon {
    color: colors.$pr-600;
  }
}

.iconSlotDisabled {
  opacity: 0.4;
}

.item_styleWarning {
  --item-color: #{$colors_ad-warn};
  --item-bg: #{rgb($colors_ad-warn, 0%)};
  --item-hover-color: #{colors.$pr-900};
  --item-hover-bg: #{rgb($colors_ad-warn, 100%)};
  --item-active-color: #{colors.$pr-900};
  --item-active-bg: #{rgb($colors_ad-warn, 100%)};

  transition-property: background-color, color, box-shadow;
  box-shadow: 0 0 0 1px #243651 inset;

  &:hover,
  &.item_isActive {
    box-shadow: 0 0 0 1px $colors_ad-warn inset;
  }
}

.item_styleError {
  --item-color: #{colors.$pr-0};
  --item-bg: #{colors.$ad-error};
  --item-hover-color: #{colors.$pr-0};
  --item-hover-bg: #{colors.$ad-error};
  --item-active-color: #{colors.$pr-0};
  --item-active-bg: #{colors.$ad-error};

  font: typo.$body_semibold;
}

.item_styleWarning,
.item_styleError {
  .icon {
    color: inherit;
  }
}
</style>
