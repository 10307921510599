<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <div>
    <DrToolbarFilterButton
      label="Filters"
      icon="filter"
      :is-active="tasksFilterService.isActive()"
      :hide-dropdown="!tasksFilterService.isActive()"
      @click="togglePanel"
      @clear="tasksFilterService.clearListFilters()"
    />

    <ElDrawer
      v-model="isDialogVisible"
      :append-to-body="true"
      :modal="true"
      :with-header="false"
      size="400px"
    >
      <ElForm label-position="top" class="form-filters">
        <ElFormItem>
          <ElButton
            class="form-filters__item form-filters__item--full-width"
            type="primary"
            :disabled="!tasksFilterService.isActive()"
            @click="tasksFilterService.clearListFilters()"
          >
            CLEAR
          </ElButton>
        </ElFormItem>

        <ElFormItem v-if="canManageTasks" label="Assignee:">
          <UsersTreeSelect
            v-model="tasksFilterService.filters.assignees"
            :is-loaded="isMembersAndGroupsLoaded"
            :members-list="membersList"
            :groups-list="groupsList"
            :get-users-of-group="getUsersOfGroup"
          />
        </ElFormItem>

        <ElFormItem v-if="canManageTasks" label="Reviewer:">
          <UsersTreeSelect
            v-model="tasksFilterService.filters.reviewers"
            :is-loaded="isMembersAndGroupsLoaded"
            :members-list="membersList"
            :groups-list="groupsList"
            :get-users-of-group="getUsersOfGroup"
          />
        </ElFormItem>

        <ElFormItem v-if="canManageTasks" label="Creator:">
          <UsersTreeSelect
            v-model="tasksFilterService.filters.creators"
            :is-loaded="isMembersAndGroupsLoaded"
            :members-list="membersList"
            :groups-list="groupsList"
            :get-users-of-group="getUsersOfGroup"
          />
        </ElFormItem>

        <ElFormItem v-if="canManageTasks" label="Follower:">
          <UsersTreeSelect
            v-model="tasksFilterService.filters.followers"
            :is-loaded="isMembersAndGroupsLoaded"
            :members-list="membersList"
            :groups-list="groupsList"
            :get-users-of-group="getUsersOfGroup"
          />
        </ElFormItem>

        <ElFormItem label="Status:">
          <ElSelect
            v-model="tasksFilterService.filters.statuses"
            multiple
            clearable
            filterable
            class="el-select--full-width"
          >
            <ElOption
              v-for="s in statuses"
              :key="s.id"
              :label="s.name"
              :value="s.id"
            />
          </ElSelect>
        </ElFormItem>

        <ElFormItem v-if="canManageTasks" label="Priority:">
          <ElSelect
            v-model="tasksFilterService.filters.priorities"
            multiple
            clearable
            filterable
            class="el-select--full-width"
          >
            <ElOption
              v-for="p in priorities"
              :key="p.value"
              :label="p.label"
              :value="p.value"
            />
          </ElSelect>
        </ElFormItem>

        <ElFormItem v-if="canManageTasks" label="Label:">
          <ElSelect
            v-model="tasksFilterService.filters.labels"
            multiple
            clearable
            filterable
            class="el-select--full-width"
          >
            <ElOption
              v-for="l in labels"
              :key="l.id"
              :label="l.name"
              :value="l.id"
            />
          </ElSelect>
        </ElFormItem>

        <ElFormItem v-if="canManageTasks" label="Updated:">
          <DrDatepicker
            v-model="tasksFilterService.filters.updatedRange"
            class="form-filters__item form-filters__item--full-width"
            type="daterange"
            unlink-panels
            clearable
            :picker-options="pickerOptions"
          />
        </ElFormItem>

        <ElFormItem v-if="canManageTasks" label="Due Date:">
          <DrDatepicker
            v-model="tasksFilterService.filters.dueDateRange"
            class="form-filters__item form-filters__item--full-width"
            type="daterange"
            unlink-panels
            clearable
            :picker-options="pickerOptions"
          />
        </ElFormItem>

        <ElFormItem v-if="canManageTasks">
          <ElCheckbox
            v-model="tasksFilterService.filters.showOverdueOnly"
            border
            class="el-checkbox--full-width"
          >
            Show Overdue Only
          </ElCheckbox>
        </ElFormItem>

        <ElFormItem v-if="canManageTasks">
          <ElCheckbox
            v-model="tasksFilterService.filters.needReviewOnly"
            border
            class="el-checkbox--full-width"
          >
            Show Only Unreviewed
          </ElCheckbox>
        </ElFormItem>
      </ElForm>
    </ElDrawer>
  </div>
</template>

<script lang="ts">
import { endOfDay, startOfDay } from "date-fns";
import { defineComponent } from "vue";
import { Close as CloseIcon } from "@element-plus/icons-vue";
import { DrDatepicker } from "@shared/ui/dr-datepicker";
import { DrToolbarFilterButton } from "@shared/ui/dr-toolbar";

import { ROOM_DATA } from "@setups/data";
import { TasksFilterServiceProxy } from "@app/ng/serviceProxies";
import UsersTreeSelect from "@app/vue/components/room/UsersTreeSelect.vue";
import { useTasksLabelsStore } from "@app/vue/store/pinia/room/tasksLabels/tasksLabels";
import { pinia } from "@drVue/store/pinia";

import type { RoomGroup } from "@drVue/store/modules/client-dashboard/deals/types";
import type { RoomMember } from "@drVue/store/modules/room/members/RoomMembersApiService";
import type { TaskLabel } from "@drVue/store/pinia/room/tasksLabels/tasksLabelApi";

interface Data {
  canManageTasks: any;
  isDialogVisible: boolean;
  pickerOptions: object;
  updatedRange: Date[];
  priorities: { label: string; value: string }[];
  tasksFilterService: TasksFilterServiceProxy;
}

const tasksLabels = useTasksLabelsStore(pinia);

export default defineComponent({
  name: "TaskListFilter",
  components: {
    DrDatepicker,
    DrToolbarFilterButton,
    UsersTreeSelect,
  },
  emits: ["updated"],
  data(): Data {
    const thany = this as any;

    return {
      canManageTasks: ROOM_DATA.userPermissions.canManageTasks,
      isDialogVisible: false,
      tasksFilterService: new TasksFilterServiceProxy(),
      pickerOptions: {
        shortcuts: [
          {
            text: "Today",
            onClick: (picker: any) => thany.last(picker, 0),
          },
          {
            text: "Yesterday",
            onClick: (picker: any) => thany.last(picker, 1, 1),
          },
          {
            text: "Last week",
            onClick: (picker: any) => thany.last(picker, 6),
          },
          {
            text: "Last month",
            onClick: (picker: any) => thany.last(picker, 29),
          },
          {
            text: "Last 3 months",
            onClick: (picker: any) => thany.last(picker, 89),
          },
        ],
      },
      updatedRange: [],
      priorities: [
        {
          label: "Low",
          value: "low",
        },
        {
          label: "Medium",
          value: "medium",
        },
        {
          label: "High",
          value: "high",
        },
      ],
    };
  },
  computed: {
    CloseIcon(): any {
      return CloseIcon;
    },
    labels(): TaskLabel[] {
      return tasksLabels.list;
    },
    statuses(): any {
      return this.$store.state.room.tasksStatuses.list;
    },
    membersList(): RoomMember[] {
      return this.$store.state.room.members.membersList;
    },
    groupsList(): RoomGroup[] {
      return this.$store.state.room.groups.pgroupsList;
    },
    isMembersAndGroupsLoaded(): any {
      const m = this.$store.state.room.members;
      const g = this.$store.state.room.groups;

      return (
        m.isLoading === false &&
        m.isError === false &&
        g.isLoading === false &&
        g.isError === false
      );
    },
  },
  updated() {
    // We use the hook to trigger angular digest!
    this.$emit("updated");
  },
  methods: {
    getUsersOfGroup(group: RoomGroup) {
      return this.membersList.reduce((acc: RoomMember[], m: RoomMember) => {
        if (!m.pending && m.pgroup.id === group.id) acc.push(m);

        return acc;
      }, []);
    },
    last(picker: any, subtractFromStart: number, subtractFromEnd: number = 0) {
      const end = endOfDay(new Date());
      const start = startOfDay(new Date());

      end.setTime(end.getTime() - 3600 * 1000 * 24 * subtractFromEnd);
      start.setTime(start.getTime() - 3600 * 1000 * 24 * subtractFromStart);

      picker.$emit("pick", [start, end]);
    },
    togglePanel() {
      this.isDialogVisible = !this.isDialogVisible;
    },
  },
});
</script>

<style lang="scss" scoped>
.form-filters {
  padding: 20px;

  &__item {
    &--full-width {
      width: 100%;
    }
  }
}
</style>
