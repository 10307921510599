import "./service.controllers.js";

import CONTENTS from "./content.js";

const LOADER_TIMEOUT = 1;
const RENDER_TIMEOUT = 2;

angular
  .module("dealroom.analytics.factory.templates", [
    "dealroom.analytics.factory.templates.controllers",
  ])
  .service("AnalyticsTemplatesFactory", AnalyticsTemplatesFactory);

AnalyticsTemplatesFactory.$inject = [
  "$compile",
  "$rootScope",
  "AnalyticsTemplatesControllersService",
];
function AnalyticsTemplatesFactory(
  $compile,
  $rootScope,
  AnalyticsTemplatesControllersService,
) {
  return {
    render,
    remove,
  };

  function remove(node) {
    cancelRender(node);

    const scope = node.__scope__;
    if (scope !== undefined) {
      window.setTimeout(() => {
        scope.$destroy();
        $(node).remove();
      }, 0);
    } else {
      $(node).remove(); // just remove node
    }
  }

  function render(node, d) {
    cancelRender(node);
    const ctrl = AnalyticsTemplatesControllersService.getController(d);
    if (ctrl instanceof Object) {
      renderTemplate(node, ctrl);
    }
    return ctrl;
  }

  function renderTemplate(node, ctrl) {
    node.__scope__ = createScope(node);
    if (node.__scope__ === null) return;

    node.setAttribute("timer-id", window.setTimeout(_render, RENDER_TIMEOUT));

    function _render() {
      removeLoader(node);
      node.removeAttribute("timer-id");
      if (isDestroyed(node)) return;

      if (ctrl.src !== undefined) {
        // set component content
        console.assert(CONTENTS[ctrl.src] !== undefined, ctrl);
        node.innerHTML = CONTENTS[ctrl.src];
      } else {
        // directive attrs
        ctrl.attrs.forEach(([name, value]) => {
          node.setAttribute(name, value);
        });
      }

      node.__scope__.$ctrl = ctrl;
      const el = $compile(node)(node.__scope__);
      node.__scope__.$digest();
    }
  }

  function isDestroyed(node) {
    return node.__scope__.$$destroyed;
  }

  function createScope(node) {
    if (node.__scope__ !== undefined) {
      if (isDestroyed(node)) return;
      node.__scope__.$destroy();
      // remove all old scope attributes
      d3.values(node.attributes).forEach((a) => {
        if (/::\$ctrl/.test(a.value) === false) return;
        node.setAttribute(a.name, null);
      });
    } else {
      createLoader(node);
    }

    const scope = $rootScope.$new();
    scope.$on("$destroy", () => {
      cancelRender(node);
    });
    return scope;
  }

  function cancelRender(node) {
    ["timer-id", "loader-timer"].forEach((attr) => cancelTimer(node, attr));
  }

  function createLoader(node) {
    node.setAttribute(
      "loader-timer",
      window.setTimeout(_render, LOADER_TIMEOUT),
    );

    function _render() {
      const loader = document.createElement("dr-loader");
      loader.innerHTML = `
        <div class="loader-holder">
          <i class="loader"><i>
          </i></i>
        </div>
      `;
      node.appendChild(loader);
    }
  }

  function cancelTimer(node, attr) {
    const timerId = node.getAttribute(attr);
    window.clearInterval(timerId);
    node.setAttribute(attr, null);
  }

  function removeLoader(node) {
    cancelTimer(node, "loader-timer");
    const loader = node.querySelector("dr-loader");
    loader && $(loader).remove();
  }
}
