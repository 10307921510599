<template>
  <DrLoader v-if="!actions" overlay />

  <div v-else :class="$style.container">
    <div :class="[$style.section, $style.sectionSave]">
      <ElButton
        :disabled="!hasChanges"
        :loading="loading"
        :class="$style.buttonEnd"
        type="primary"
        @click="save"
      >
        Save changes&nbsp;<DrIcon name="save" />
      </ElButton>
    </div>

    <div :class="$style.section">
      <h4>Request management</h4>

      <DrCheckbox
        :model-value="actions.canManageTasks"
        id="canManageTasks"
        :class="$style.row"
        :disabled="loading"
        @update:model-value="toggleCanManageTasks($event === true)"
      >
        Allow members to:
        <ul :class="$style.labelPadding">
          <li>Assign and be set as a request reviewer/assignee/follower</li>
          <li>Manage all requests parameters</li>
          <li>Post private comments</li>
        </ul>
      </DrCheckbox>

      <DrCheckbox
        v-model="actions.canCreateTasks"
        id="canCreateTasks"
        :disabled="loading || !actions.canManageTasks"
        :class="$style.row"
        @update:model-value="hasChanges = true"
      >
        <div :class="$style.labelPadding">Create requests and categories</div>
      </DrCheckbox>

      <DrCheckbox
        v-model="actions.canDeleteTasks"
        id="canDeleteTasks"
        :disabled="loading || !actions.canManageTasks"
        :class="$style.row"
        @update:model-value="hasChanges = true"
      >
        <div :class="$style.labelPadding">Delete requests and categories</div>
      </DrCheckbox>

      <DrRadioStackLabeled
        v-model="actions.task_start_and_due_dates_access"
        :class="$style.row"
        :items="radioButtonItems"
        inline
        :disabled="loading || !actions.canManageTasks"
        @update:model-value="hasChanges = true"
      >
        <div :class="$style.labelPadding">Start and Due Dates</div>
      </DrRadioStackLabeled>

      <DrRadioStackLabeled
        v-if="ROOM_DATA.enableTaskCustomFields"
        v-model="actions.taskCustomFieldsAccess"
        :class="$style.row"
        :items="radioButtonItems"
        inline
        :disabled="loading || !actions.canManageTasks"
        @update:model-value="hasChanges = true"
      >
        <div :class="$style.labelPadding">Custom fields</div>
      </DrRadioStackLabeled>
    </div>

    <div :class="$style.section">
      <h4>Approve public comments</h4>

      <DrCheckbox
        v-model="actions.need_comments_approve"
        id="need_comments_approve"
        label="Admins must review and approve group comments before they're public"
        :disabled="loading"
        :class="$style.row"
        @update:model-value="hasChanges = true"
      />
    </div>

    <div :class="$style.section">
      <h4>View and manage findings for the corresponding request categories</h4>

      <DrCheckbox
        v-model="actions.canManageFindings"
        id="canManageFindings"
        label="Create new findings, view findings tab & access findings relevant to group's requests/categories"
        :disabled="loading"
        :class="$style.row"
        @update:model-value="hasChanges = true"
      />
    </div>

    <div :class="$style.section">
      <h4>Access inheritance for new categories</h4>

      <DrCheckbox
        :model-value="
          actions.category_default_permissions_mode !== PermissionMode.DenyAll
        "
        id="InheritAlways"
        label="Inherit parent's category access"
        :disabled="loading"
        :class="$style.row"
        radio
        @update:model-value="
          toggleCategoryDefaultPermissionsMode(PermissionMode.InheritAlways)
        "
      />

      <DrCheckbox
        :model-value="
          actions.category_default_permissions_mode ===
          PermissionMode.InheritNoConflict
        "
        id="InheritNoConflict"
        :disabled="loading"
        :class="$style.row"
        @update:model-value="
          toggleCategoryDefaultPermissionsMode(PermissionMode.InheritNoConflict)
        "
      >
        <div :class="$style.labelPadding">
          Set "No access" if this group has mixed access among subcategories of
          the parent category
        </div>
      </DrCheckbox>

      <DrCheckbox
        :model-value="
          actions.category_default_permissions_mode === PermissionMode.DenyAll
        "
        id="DenyAll"
        label="Deny access entirely"
        :disabled="loading"
        :class="$style.row"
        radio
        @update:model-value="
          toggleCategoryDefaultPermissionsMode(PermissionMode.DenyAll)
        "
      />
    </div>

    <div :class="$style.section">
      <div :class="$style.headerWorklist">
        <div>
          <h4>Worklists</h4>
          <div>Allow members from groups to view worklists</div>
        </div>

        <ElButton
          ref="addCategoryBtnRef"
          type="primary"
          :disabled="loading"
          :class="$style.buttonEnd"
          @click="handleAddCategory(addCategoryBtnRef)"
        >
          New worklist
        </ElButton>
      </div>

      <div :class="$style.categories">
        <div :class="$style.headerCategories">
          <ElCheckbox
            :model-value="isAllCategoriesAllowed"
            :class="$style.checkbox"
            :indeterminate="
              !isAllCategoriesAllowed &&
              actions.categories.some((item) => item.allowed)
            "
            :disabled="loading"
            @update:model-value="toggleAllCategories($event === true)"
          >
            Select all
          </ElCheckbox>
        </div>

        <RequestPermissionsCategoryItem
          v-for="category in actions.categories.filter(
            (item) => item.parent_id === null,
          )"
          :key="category.id"
          :category="category"
          :categories="actions.categories"
          :disabled="loading"
          init-is-open
          @update:allowed="updateCategoriesAllowed($event.id, $event.allowed)"
          @create:category="handleAddCategory"
        />
      </div>
    </div>
  </div>

  <CategoryCreateOrRenameDialog
    ref="categoryCreateDialogRef"
    @added="handleCategoryAdded"
  />
</template>

<script setup lang="ts">
import { ElButton } from "element-plus";
import { computed, onBeforeMount, ref, unref } from "vue";
import DrCheckbox from "@shared/ui/dr-checkbox";
import DrIcon from "@shared/ui/dr-icon/DrIcon.vue";
import DrLoader from "@shared/ui/dr-loader/DrLoader.vue";
import DrRadioStackLabeled from "@shared/ui/dr-radio/DrRadioStackLabeled.vue";

import { ROOM_DATA } from "@setups/data";
import { TaskFieldAccessType } from "@app/setups/enums";
import { DrStore } from "@app/vue";
import { PermissionMode } from "@app/vue/store/modules/room/groups/GroupsApiService";
import CategoryCreateOrRenameDialog from "@drVue/components/room/tasks/shared/CategoryCreateOrRenameDialog.vue";
import RequestPermissionsCategoryItem from "./components/RequestPermissionsCategoryItem.vue";

import type { RoomActions } from "@drVue/store/modules/room/groups/GroupsActions";
import type { RadioStackItem } from "@shared/ui/dr-radio/types";
import type { ComponentPublicInstance } from "vue";

const radioButtonItems: RadioStackItem<TaskFieldAccessType>[] = [
  {
    id: TaskFieldAccessType.NoAccess,
    name: "No access",
  },
  {
    id: TaskFieldAccessType.View,
    name: "View only",
  },
  {
    id: TaskFieldAccessType.Edit,
    name: "View and edit",
  },
];

interface Props {
  pgroupId: number;
}

const props = defineProps<Props>();

const categoryCreateDialogRef =
  ref<InstanceType<typeof CategoryCreateOrRenameDialog>>();

const actions = ref<RoomActions | undefined>();

const hasChanges = ref(false);
const loading = ref(false);

const addCategoryBtnRef = ref<InstanceType<typeof ElButton>>();

const refetchActions = (preserveValues?: boolean): void => {
  DrStore.dispatch("room/groups/getActions", props.pgroupId).then(
    (value: { [Key in number]: RoomActions }) => {
      if (preserveValues && actions.value && value[props.pgroupId]) {
        actions.value = {
          ...value[props.pgroupId],

          canManageTasks: actions.value.canManageTasks,
          canCreateTasks: actions.value.canCreateTasks,
          canDeleteTasks: actions.value.canDeleteTasks,

          task_start_and_due_dates_access:
            actions.value.task_start_and_due_dates_access,
          taskCustomFieldsAccess: actions.value.taskCustomFieldsAccess,

          need_comments_approve: actions.value.need_comments_approve,
          canManageFindings: actions.value.canManageFindings,

          categories: value[props.pgroupId].categories.map((category) => {
            const allowedPreserved: boolean | undefined =
              actions.value?.categories.find(
                (item) => item.id === category.id,
              )?.allowed;

            return {
              ...category,
              allowed: allowedPreserved ?? category.allowed,
            };
          }),
        };
      } else {
        actions.value = value[props.pgroupId];
      }
    },
  );
};

const save = (): void => {
  if (loading.value) return;

  loading.value = true;

  DrStore.dispatch("room/groups/updateActions", {
    pgroupId: props.pgroupId,
    actions: actions.value,
  })
    .then(() => {
      refetchActions();

      hasChanges.value = false;
    })
    .finally(() => {
      loading.value = false;
    });
};

const isAllCategoriesAllowed = computed<boolean>(
  () => actions.value?.categories.every((item) => item.allowed) ?? false,
);

const updateCategoriesAllowed = (id: number, value: boolean): void => {
  if (!actions.value) return;

  const index = actions.value.categories.findIndex((item) => item.id === id);

  if (index === -1 || actions.value.categories[index].allowed === value) return;

  actions.value.categories[index].allowed = value;

  hasChanges.value = true;
};

const toggleAllCategories = (value: boolean): void => {
  actions.value?.categories.forEach((item) => (item.allowed = value));

  hasChanges.value = true;
};

const handleAddCategory = (
  ref?: HTMLElement | InstanceType<typeof ElButton> | null,
  parent?: RoomActions["categories"][number],
): void => {
  if (ref) {
    const parsedRef =
      ref instanceof HTMLElement
        ? ref
        : unref((ref as ComponentPublicInstance).$el);

    categoryCreateDialogRef.value?.show(parsedRef, {
      type: "create",
      parentId: parent?.id,
    });
  }
};

const handleCategoryAdded = () => refetchActions(true);

const resetForm = (): void => {
  if (!actions.value) return;

  actions.value.canCreateTasks = false;
  actions.value.canDeleteTasks = false;

  actions.value.task_start_and_due_dates_access = TaskFieldAccessType.NoAccess;
  actions.value.taskCustomFieldsAccess = TaskFieldAccessType.NoAccess;
};

const toggleCanManageTasks = (value: boolean): void => {
  if (!actions.value) return;

  actions.value.canManageTasks = value;

  if (!value) resetForm();

  hasChanges.value = true;
};

const toggleCategoryDefaultPermissionsMode = (value: PermissionMode): void => {
  if (!actions.value) return;

  hasChanges.value = true;

  if (
    PermissionMode.InheritAlways === value &&
    PermissionMode.InheritNoConflict ===
      actions.value.category_default_permissions_mode
  )
    return;

  if (
    PermissionMode.InheritNoConflict === value &&
    PermissionMode.InheritNoConflict ===
      actions.value.category_default_permissions_mode
  ) {
    actions.value.category_default_permissions_mode =
      PermissionMode.InheritAlways;

    return;
  }

  actions.value.category_default_permissions_mode = value;
};

onBeforeMount(() => {
  refetchActions();
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.container {
  height: calc(100vh - 110px);
  overflow: auto;
  margin-right: -16px;
  padding-right: 16px;
}

.row {
  border-bottom: 1px solid colors.$pr-150;

  &:first-of-type {
    border-top: 1px solid colors.$pr-150;
  }

  ul {
    list-style: disc;
    line-height: 20px;
  }

  .labelPadding {
    padding-left: 20px;
  }
}

.section {
  margin-bottom: 32px;

  h4 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .headerWorklist {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 8px;
    margin-bottom: 16px;

    h4 {
      margin-bottom: 4px;
    }

    .buttonEnd {
      align-self: flex-end;
    }
  }

  .categories {
    border: 1px solid colors.$pr-150;
    border-radius: 12px;
    overflow: hidden;

    .headerCategories {
      background: colors.$pr-50;
      display: grid;
      grid-template-rows: 38px;

      .checkbox {
        padding-left: 20px;
        height: 100%;
      }

      .label {
        font-weight: 600;
      }
    }
  }
}

.sectionSave {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: flex-end;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 100;
}
</style>
