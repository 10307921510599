<template>
  <EditFieldContainerWithButtons
    :is-form-submitting="editProps.isFormSubmitting"
    @save="editProps.submitField"
    @discard="editProps.quitEditModeConfirm"
  >
    <!-- @vue-ignore: v-bind -->
    <ElInput
      ref="inputRef"
      :validate-event="false"
      :model-value="editProps.value"
      :placeholder="editProps.schema.placeholder"
      v-bind="editProps.veeField"
    />
  </EditFieldContainerWithButtons>
</template>

<script setup lang="ts">
import { ElInput } from "element-plus";
import { onMounted, ref } from "vue";

import EditFieldContainerWithButtons from "./EditFieldContainerWithButtons.vue";

import type { EditFieldProps } from "../types";

interface Props {
  editProps: EditFieldProps;
}

defineProps<Props>();

const inputRef = ref<InstanceType<typeof ElInput> | null>(null);
onMounted(() => {
  inputRef.value?.focus();
});
</script>
