<template>
  <DrItemListItem
    :allow-remove="!disallowRemove"
    :single-mode="singleMode"
    @remove="$emit('remove', id)"
  >
    <a
      :class="$style.container"
      :href="findingUrl"
      target="_blank"
      @click="$emit('click', id)"
    >
      <DrIcon :class="$style.icon" name="exclamation-triangle" />
      <span :class="$style.key">#{{ keyString }}</span>
      <span class="truncate" :class="$style.title">{{ title }}</span>
      <DrUserBox disable-label :users="assignees" />
    </a>
  </DrItemListItem>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrItemListItem } from "@shared/ui/dr-item-list";
import { DrUserBox } from "@shared/ui/dr-user-box";

import { ROOM_DATA } from "@setups/index";
import { findingDetailsUrl } from "@setups/room-urls";
import { DrStore } from "@app/vue";

import type { HEXColor } from "@drVue/utils/color";

const PR400: HEXColor = "#8c9caf";

interface Props {
  id: string;
  title: string;
  keyString: string;
  keyColor?: HEXColor;
  typeColor?: HEXColor;
  assigneeUids: { user_id: string }[];
  disallowRemove: boolean;
  singleMode?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  keyColor: PR400,
  typeColor: PR400,
});

interface Emits {
  (e: "remove", id: Props["id"]): void;
  (e: "click", id: Props["id"]): void;
}

defineEmits<Emits>();

const findingUrl = computed(() =>
  findingDetailsUrl(ROOM_DATA.url, props.keyString),
);

const assignees = computed(() =>
  props.assigneeUids.map(
    ({ user_id }) => DrStore.state.room.members.membersByUid[user_id],
  ),
);
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.container {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 30px auto 1fr auto;

  &:focus {
    text-decoration: none;
  }
}

.icon {
  color: v-bind(typeColor);
}

.key {
  font: typography.$body_regular;
  color: v-bind(keyColor);
}

.title {
  font: typography.$body_regular;
  color: colors.$pr-900;
  margin-left: 8px;
  margin-right: 4px;
}
</style>
