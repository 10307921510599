import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";

import Login from "@drVue/components/auth/Login.vue";
import PasswordReset from "@drVue/components/auth/PasswordReset.vue";
import SetPassword from "@drVue/components/auth/PasswordSet.vue";
import Registration from "@drVue/components/auth/Registration.vue";
import SsoLogin from "@drVue/components/auth/SsoLogin.vue";
import { initSentry } from "@drVue/components/initSentry";
import App from "./AuthApp.vue";

import type { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  // We also have server-side 302 redirect from "/" to "/auth".
  { path: "/", redirect: "/auth" },
  { path: "/auth", component: Login },
  { path: "/sso", component: SsoLogin },
  { path: "/reset", component: PasswordReset },
  { path: "/reset/:uidb36/:key", component: SetPassword },
  { path: "/registration/:confirmation_key", component: Registration },
];

export function createAuthApp() {
  const app = createApp(App);
  const router = createRouter({
    history: createWebHistory("/account/"),
    routes,
  });

  initSentry(app, router);

  app.use(router);

  return app;
}
