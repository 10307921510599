<template>
  <div class="all-deals-views-container__row" @click="open">
    <div class="all-deals-views-container__subrow">
      <i class="fas fa-eye" />
      <DrTruncatedTextTooltip :content="view.name">
        {{ view.name }}
      </DrTruncatedTextTooltip>
    </div>
    <ElDropdown
      v-if="!view.is_default"
      ref="menu"
      trigger="click"
      @command="handleCommand"
      @visible-change="menuVisibleChange"
    >
      <ElLink
        type="primary"
        class="truncate"
        :underline="false"
        :class="{ 'is-active': isOptionsMenuShown }"
      >
        Options
        <ElIcon class="el-icon--right">
          <i class="fas fa-caret-down" />
        </ElIcon>
      </ElLink>
      <template #dropdown>
        <ElDropdownMenu>
          <ElDropdownItem :command="DROPDOWN_COMMANDS.rename">
            Rename
          </ElDropdownItem>
          <ElDropdownItem :command="DROPDOWN_COMMANDS.manageSharing">
            Manage sharing
          </ElDropdownItem>
          <ElDropdownItem :command="DROPDOWN_COMMANDS.delete" divided>
            Delete
          </ElDropdownItem>
        </ElDropdownMenu>
      </template>
    </ElDropdown>
    <EditDealsViewDialog
      v-if="editViewOptions.visible"
      :view="view"
      :show-name-input="editViewOptions.showNameInput"
      :show-sharing-input="editViewOptions.showSharingInput"
      @close="closeEditDialog"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { isNavigationFailure } from "vue-router";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import EditDealsViewDialog from "./EditDealsViewDialog.vue";

import type { Dictionary } from "@drVue/types";
import type { CustomView } from "@setups/types";
import type ElDropdown from "element-plus/es/components/dropdown";
import type { PropType } from "vue";

enum DROPDOWN_COMMANDS {
  rename,
  manageSharing,
  delete,
}

interface Data {
  isOptionsMenuShown: boolean;
  editViewOptions: Dictionary<boolean>;
  DROPDOWN_COMMANDS: any;
}

export default defineComponent({
  name: "AllDealsViewsRow",
  components: { DrTruncatedTextTooltip, EditDealsViewDialog },
  props: {
    view: { required: true, type: Object as PropType<CustomView> },
  },
  emits: ["select"],
  data(): Data {
    return {
      isOptionsMenuShown: false,
      editViewOptions: {
        visible: false,
        showNameInput: false,
        showSharingInput: false,
      },
      DROPDOWN_COMMANDS: DROPDOWN_COMMANDS,
    };
  },
  methods: {
    menuVisibleChange(value: boolean) {
      this.isOptionsMenuShown = value;
    },
    async open(event: Event) {
      /**
       * We prevent the click event from bubbling from the <el-dropdown> element,
       * because now the component uses the @command concept and there is no effect
       * by apply something like
       `
        @click.native.stop
       `
       */
      if ((this.$refs.menu as typeof ElDropdown)?.$el.contains(event.target)) {
        return;
      }

      this.$emit("select");
      try {
        await this.$router.push(`/deals/${this.view.slug}`);
      } catch (e) {
        if (isNavigationFailure(e)) {
          if (e.name !== "NavigationDuplicated") {
            throw e;
          }
        }
      }
    },
    deleteView() {
      this.$msgbox({
        type: "warning",
        title: "Delete saved view",
        message: `Are you sure you want to remove view "${this.view.name}"?`,
        confirmButtonClass: "el-button--danger",
        confirmButtonText: "Remove",
        showCancelButton: true,
        showClose: true,
        cancelButtonText: "No",
        closeOnHashChange: false,
        closeOnPressEscape: false,
        beforeClose: async (action, instance, done) => {
          if (action === "confirm") {
            instance.showCancelButton = false;
            //instance.showClose = false;
            instance.confirmButtonLoading = true;

            await this.$store.dispatch("common/customViews/delete", this.view);
            done();
            instance.showCancelButton = true;
            //instance.showClose = true;
            instance.confirmButtonLoading = false;
          } else {
            done();
          }
        },
      }).catch(() => {});
    },
    handleCommand(command: DROPDOWN_COMMANDS) {
      switch (command) {
        case DROPDOWN_COMMANDS.rename:
          this.showEditDialog("showNameInput");
          break;
        case DROPDOWN_COMMANDS.manageSharing:
          this.showEditDialog("showSharingInput");
          break;
        case DROPDOWN_COMMANDS.delete:
          this.deleteView();
          break;
      }
    },
    showEditDialog(showInput: string) {
      Object.keys(this.editViewOptions).forEach((key) => {
        this.editViewOptions[key] = false;
      });
      this.editViewOptions[showInput] = true;
      this.editViewOptions.visible = true;
    },
    closeEditDialog() {
      this.editViewOptions.visible = false;
    },
  },
});
</script>
