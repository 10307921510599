import * as owasp from "owasp-password-strength-test";

// OWASP is a singleton and there is a rule added in password-validator.js
//
// owasp.tests.required.push(function (password: string) {
//   if (password !== password.trim()) {
//     return "Please don't begin or end your password with blank space.";
//   }
// });

owasp.config({
  allowPassphrases: false,
  maxLength: 128,
  minLength: 10,
  minPhraseLength: 20,
  minOptionalTestsToPass: 3,
});

class Service {
  test(password: string) {
    return owasp.test(password);
  }

  getPopoverText() {
    return `Please enter password with ${owasp.configs.minLength} or more characters, numbers, lowercase, uppercase, and special characters.`;
  }
}

const PasswordService = new Service();

export default PasswordService;
