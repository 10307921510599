<template>
  <div
    :class="{
      [$style.container]: true,
      [$style.container_4cols]: $slots['action'],
    }"
  >
    <ElSelect
      :model-value="modelValue.label"
      value-key="id"
      @update:model-value="handleKeyOptionChange"
    >
      <ElOption
        v-for="keyOption in keyOptions"
        :key="keyOption.id"
        :value="keyOption"
        :label="keyOption.label"
      />
    </ElSelect>
    <div>
      <slot name="inner-op-name" />
    </div>
    <ElFormItem :error="valueError">
      <ElSelect
        :model-value="modelValue.children"
        multiple
        value-key="id"
        @update:model-value="handleValueOptionChange"
      >
        <ElOption
          v-for="valueOption in valueOptions"
          :key="valueOption.id"
          :value="valueOption"
          :label="valueOption.label"
        />
      </ElSelect>
    </ElFormItem>
    <div v-show="$slots['action']">
      <slot name="action" />
    </div>
    <ElDivider
      v-if="$slots['outer-op-name']"
      :class="$style.footer"
      content-position="left"
    >
      <slot name="outer-op-name" />
    </ElDivider>
  </div>
</template>

<script setup lang="ts">
import type { SelectOption } from "./types";

interface Props {
  modelValue: SelectOption;
  keyOptions: SelectOption[];
  valueOptions: SelectOption[];
  valueError?: string;
}
const props = defineProps<Props>();

interface Emits {
  (e: "update:modelValue", payload: Partial<SelectOption>): void;
}
const emit = defineEmits<Emits>();

function handleKeyOptionChange({ id, label, children }: Partial<SelectOption>) {
  const firstChildKey = Object.keys(children!)[0];
  // TODO: specify children as dict
  // TS doesn't know that SelectOption#children is a dict, so we're casting indexer type
  const firstChildObj = children![firstChildKey as unknown as number];
  emit("update:modelValue", { id, label, children: [firstChildObj] });
}

function handleValueOptionChange(children: SelectOption[]) {
  emit("update:modelValue", { ...props.modelValue, children });
}
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.container {
  display: grid;
  grid-template-columns: 156px auto 220px;
  grid-template-rows: auto auto;
  column-gap: 5px;
  align-items: center;
  color: colors.$pr-400;
}

.container_4cols {
  grid-template-columns: 156px auto 220px 20px;
}

.footer {
  grid-column-start: 1;
  grid-column-end: 4;
  margin: 14px 0 !important;

  :global(.el-divider__text) {
    background: colors.$pr-50;
    font: typography.$body_regular;
    color: colors.$pr-400;
    left: -8px;
    padding-right: 8px;
  }
}
</style>
