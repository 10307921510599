import fuzzaldrinPlus from "fuzzaldrin-plus";
import { cloneDeep } from "lodash-es";
import { escape } from "lodash-es";
import { isEmpty } from "lodash-es";
import { uniq } from "lodash-es";
import VueAsyncOperationDialogContent from "@shared/ui/async-operation/AsyncOperationDialogContent.vue";

import { DrStore } from "@drVue";
import { isStringContains } from "@drVue/common";
import VueAddUserModalContent from "@drVue/components/client-dashboard/users/AddUserModal/content.vue";
import VueCreateCategoryDialog from "@drVue/components/room/settings/request-permissions/CreateCategoryDialogFromAngular.vue";
import VueDeleteCategoryDialog from "@drVue/components/room/settings/request-permissions/DeleteCategoryDialogFromAngular.vue";
import VueRequestPermissionsPage from "@drVue/components/room/settings/request-permissions/RequestPermissionsPage.vue";
import processDocuments from "./ng/documents/services/helpers/processDocuments";
import restoreExpandedFoldersState from "./ng/documents/services/helpers/restoreExpandedFoldersState";
import icons_combinedPermissionIcoHtml from "./templates/components/icons/combined-permission-ico.html?raw";
import icons_downloadOriginalPermissionsHtml from "./templates/components/icons/download-original-permissions.html?raw";
import icons_downloadWatermarkedPermissionsHtml from "./templates/components/icons/download-watermarked-permissions.html?raw";
import icons_editPermissionsHtml from "./templates/components/icons/edit-permissions.html?raw";
import icons_viewPermissionsHtml from "./templates/components/icons/view-permissions.html?raw";
import templates_confirmDialogHtml from "./templates/confirm-dialog.html?raw";
import members_exportPermMatrixDialogHtml from "./templates/members/export-perm-matrix-dialog.html?raw";
import members_inviteModalHtml from "./templates/members/invite-modal.html?raw";
import members_pgroupActionHtml from "./templates/members/pgroup-action.html?raw";

(function (angular) {
  "use strict";
  drFixedColumnsPermissionTable.$inject = ["$timeout"];
  PermissionsHighlightDirective.$inject = ["$timeout"];
  MembersArchivedController.$inject = ["$scope", "PermissionsService"];
  MemberBulkRequestsController.$inject = [
    "$scope",
    "$q",
    "RoomConfig",
    "PermissionsService",
    "AlertService",
  ];
  MembersActionsController.$inject = [
    "$scope",
    "$state",
    "$stateParams",
    "$uibModal",
    "RoomConfig",
    "PermissionsService",
    "AlertService",
    "MembersService",
    "CategoriesService",
  ];
  MembersPermissionsSimpleController.$inject = [
    "$scope",
    "$state",
    "$stateParams",
  ];
  MembersFilesController.$inject = [
    "$rootScope",
    "$scope",
    "$state",
    "$stateParams",
    "$q",
    "$filter",
    "$uibModal",
    "PermissionsService",
    "PermissionsApiService",
    "DocumentsService",
    "AlertService",
  ];
  MembersDetailController.$inject = ["$scope", "$state", "MembersService"];
  MembersListController.$inject = ["$scope", "$filter"];
  MembersController.$inject = [
    "$scope",
    "$state",
    "PermissionsService",
    "MembersService",
    "RoomConfig",
    "$timeout",
  ];
  MembersService.$inject = [
    "$uibModal",
    "$window",
    "$q",
    "$sce",
    "$state",
    "$http",
    "URLS",
    "PermissionsApiService",
    "AlertService",
    "ManageMembersService",
    "RoomApiService",
    "RoomConfig",
  ];
  PermissionsService.$inject = [
    "$rootScope",
    "$q",
    "$uibModal",
    "$sce",
    "$state",
    "APP_SETTINGS",
    "MembersService",
    "AlertService",
    "CategoriesService",
  ];
  PermissionsApiService.$inject = ["$http", "URLS"];
  angular
    .module("dealroom.members", [
      "ui.bootstrap",

      "dealroom.documents",
      "dealroom.table-sort.directive",

      "dealroom.config",
      "dealroom.common",
      "dealroom.task",
    ])
    .service("PermissionsApiService", PermissionsApiService)
    .service("PermissionsService", PermissionsService)
    .service("MembersService", MembersService)
    .controller("MembersController", MembersController)
    .controller("MembersListController", MembersListController)
    .controller("MembersDetailController", MembersDetailController)
    .controller("MembersFilesController", MembersFilesController)
    .controller("MembersActionsController", MembersActionsController)
    .controller(
      "MembersPermissionsSimpleController",
      MembersPermissionsSimpleController,
    )
    .controller("MemberBulkRequestsController", MemberBulkRequestsController)
    .controller("MembersArchivedController", MembersArchivedController)
    .directive("drPermissionsHighlight", PermissionsHighlightDirective)
    .directive("drFixedColumnsPermissionTable", drFixedColumnsPermissionTable)
    .component("drViewPermissionsIco", drViewPermissionsIco())
    .component(
      "drDownloadWatermarkedPermissionsIco",
      drDownloadWatermarkedPermissionsIco(),
    )
    .component(
      "drDownloadOriginalPermissionsIco",
      drDownloadOriginalPermissionsIco(),
    )
    .component("drEditPermissionsIco", drEditPermissionsIco())
    .component("drCombinedPermissionsIco", drCombinedPermissionsIco())
    .value("VueAddUserModalContent", VueAddUserModalContent)
    .value("VueAsyncOperationDialogContent", VueAsyncOperationDialogContent)
    .value("VueRequestPermissionsPage", VueRequestPermissionsPage)
    .value("VueCreateCategoryDialog", VueCreateCategoryDialog)
    .value("VueDeleteCategoryDialog", VueDeleteCategoryDialog);

  function PermissionsApiService($http, URLS) {
    return {
      getItems: function getItems(permissionId) {
        let url;
        if (permissionId) {
          url = URLS["api:room:pgroup_items"](permissionId);
        } else {
          url = URLS["api:room:pgroup_list_items"]();
        }
        return $http.get(url);
      },

      updatePermissions: function updatePermissions(
        pgroupId,
        folders_updates,
        files_updates,
      ) {
        var url = URLS["api:room:pgroup_change_file_permissions"](pgroupId);
        var data = {
          folders: folders_updates,
          files: files_updates,
        };
        return $http.post(url, data);
      },
    };
  }

  /* End PermissionsApiService */

  function PermissionsService(
    $rootScope,
    $q,
    $uibModal,
    $sce,
    $state,
    APP_SETTINGS,
    MembersService,
    AlertService,
    CategoriesService,
  ) {
    copyPGroupModalController.$inject = [
      "$scope",
      "pgroup",
      "PermissionsService",
    ];
    const service = {
      DefaultPermissionsMode: {
        InheritAlways: "inherit_always",
        InheritNoConflict: "inherit_no_conflict",
        DenyAll: "deny_all",
      },

      isLoaded: function isLoaded() {
        return (
          DrStore.state.room.groups.isError === false &&
          DrStore.state.room.groups.isLoading === false
        );
      },

      updateGroupsList: (skipErrorAlert) =>
        DrStore.dispatch("room/groups/load", skipErrorAlert),

      getActions: (pgroupId) =>
        DrStore.dispatch("room/groups/getActions", pgroupId),

      updateActions: (pgroupId, actions, skipSuccessMsg) =>
        DrStore.dispatch("room/groups/updateActions", {
          pgroupId,
          actions,
          skipSuccessMsg,
        }),

      renamePGroup: renamePGroup, // pGroupActionModal
      createPGroup: createPGroup, // pGroupActionModal
      copyPGroup: copyPGroup, // pGroupActionModal
      deletePGroup: deletePGroup,
      restorePgroups: (pgroups) =>
        DrStore.dispatch("room/groups/restore", pgroups),

      isCategoryViewable: isCategoryViewable,
    };

    Object.defineProperties(service, {
      updatePromise: {
        get: () => DrStore.state.room.groups.updatePromise,
      },
      pgroups: {
        get: () => DrStore.state.room.groups.pgroups,
      },
      pgroupsList: {
        get: () => DrStore.state.room.groups.pgroupsList,
      },
      archivedPgroupsList: {
        get: () => DrStore.state.room.groups.archivedPgroupsList,
      },
    });

    return service;

    // two helper function for pgroup rename/creare/copy pgroup modal window
    function modalSubmit(params, pgroup) {
      const $scope = params.$scope;
      const api = params.api;
      const msg = params.msg;

      return function () {
        if ($scope.form.$valid) {
          $scope.form.$setSubmitted();
          const data = {
            name: $scope.ctrl.name,
            is_administrator: $scope.ctrl.is_administrator,
          };
          const promise = pgroup ? api(pgroup.id, data) : api(data);
          promise.then(
            function successCallback(r) {
              // if user will close modal with ESC right after submitting by ENTER -
              // callback will be raised after the modal closing
              if (!$scope.$$destroyed) {
                $scope.$close(r.data || r);
                $scope.form.name = "";
              }
              AlertService.success($sce.trustAsHtml(msg));
              if (params.updateMembers) {
                return DrStore.dispatch("room/members/load");
              }
            },
            function errorCallback(error) {
              if (error.response.data.name) {
                if (!$scope.$$destroyed) {
                  $scope.form.$setPristine();
                }
                AlertService.danger(error.response.data.name);
              } else {
                if (!$scope.$$destroyed) {
                  $scope.$close();
                }
                AlertService.danger("Request is failed.");
              }
              return $q.reject(error);
            },
          );
        }
      };
    }

    function pGroupActionModal(params, pgroup) {
      let controller = params.controller;
      if (!controller) {
        controller = function ($scope, pgroup) {
          this.modal = params.modalOptions;
          this.form = undefined;
          if (pgroup) {
            // rename pgroup
            this.name = pgroup.name;
          } else {
            // create a new pgroup
            this.is_administrator = false;
          }

          params.$scope = $scope;
          this.submit = modalSubmit(params, pgroup);
        };
        controller.$inject = ["$scope", "pgroup"];
      }

      const modalInstance = $uibModal.open({
        template: members_pgroupActionHtml,
        controllerAs: "ctrl",
        resolve: {
          pgroup: () => pgroup,
        },
        controller: controller,
      });

      return modalInstance.result.then(function (pgroup) {
        if (params.go) {
          $state.go(
            $state.current.name,
            { pgroupId: pgroup.id },
            { location: true, reload: false },
          );
        }

        return DrStore.dispatch("room/groups/load").then(() => pgroup);
      });
    }

    function renamePGroup(pgroup) {
      var modalOptions = {
        title: "Rename group",
        label: "New name",
        submit: "Save",
      };

      return pGroupActionModal(
        {
          modalOptions: modalOptions,
          api: (pgroupId, data) =>
            DrStore.dispatch("room/groups/edit", {
              pgroupId,
              data,
            }),
          msg:
            "Group " +
            `<strong>${escape(pgroup.name)}</strong>` +
            " has been renamed.",
          updateMembers: true,
        },
        pgroup,
      );
    }

    function createPGroup() {
      var modalOptions = {
        title: "Add group",
        label: "Name",
        showInheritCategoryAccessWarning: APP_SETTINGS.WEBSITE.IS_DEALROOM,
        submit: "Add",
      };

      return pGroupActionModal({
        modalOptions: modalOptions,
        api: (data) => DrStore.dispatch("room/groups/create", data),
        msg: "New group is created. Please, <strong>set file permissions</strong> for a new group.",
        go: false,
      }).then((pgroup) => {
        if ($state.current.name === "members.tasks") {
          $state.go("members.tasks", { pgroupId: pgroup.id });
        } else {
          $state.go("members.files", { pgroupId: pgroup.id });
        }
      });
    }

    function copyPGroup(pgroup) {
      return pGroupActionModal(
        {
          controller: copyPGroupModalController,
          go: true,
        },
        pgroup,
      );
    }

    function copyPGroupModalController($scope, pgroup, PermissionsService) {
      var self = this;
      self.modal = {
        title: "Copy group",
        label: "Name",
        submit: "Copy",
      };

      var numberRegex = /(.*)\(([0-9]+)\)$/;
      var defaultName = pgroup.name;
      var currentNumber = 1;
      if (defaultName.match(numberRegex)) {
        var res = defaultName.match(numberRegex);
        defaultName = res[1].trim();
        currentNumber = parseInt(res[2]) + 1;
      }
      var existingPermission = DrStore.state.room.groups.pgroupsList.map(
        (pgroup) => pgroup.name,
      );

      var newName, prefix;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        prefix = " (" + currentNumber + ")";
        newName = defaultName + prefix;
        if (newName.length > 30) {
          newName =
            defaultName.substring(0, defaultName.length - prefix.length) +
            prefix;
        }
        if (existingPermission.indexOf(newName) === -1) {
          self.name = newName;
          break;
        }
        currentNumber += 1;
      }
      $scope.$watch("ctrl.form", function (form) {
        if (form) {
          //submit button is disabled while ctrl.form.name.$pristine
          self.name.$setDirty();
        }
      });
      self.is_administrator = pgroup.is_administrator;
      self.submit = modalSubmit(
        {
          $scope: $scope,
          api: (pgroupId, data) =>
            DrStore.dispatch("room/groups/copy", {
              pgroupId,
              data,
            }),
          msg:
            "Group <strong>" +
            escape(pgroup.name) +
            " </strong> has been copied to <strong>" +
            escape(self.name) +
            "</strong>.",
        },
        pgroup,
      );
    }

    function deletePGroup(pgroup) {
      const modalOptions = {
        headerText: "Delete Group",
        actionButtonText: "Delete",
        bodyText: `Are you sure you want to delete ${pgroup.name} permission group?`,
      };

      const membersCount = DrStore.getters["room/members/activeByGroupId"](
        pgroup.id,
      ).length;
      if (membersCount) {
        modalOptions.bodyText +=
          ` It contains ${membersCount} member${membersCount > 1 ? "s" : ""}.` +
          " They will be removed from this room and from all requests.";
      }

      return $uibModal.open({
        template: templates_confirmDialogHtml,
        controllerAs: "ctrl",
        controller: [
          "$scope",
          "$uibModalInstance",
          function ($scope, $uibModalInstance) {
            this.modalOptions = modalOptions;
            this.submit = function () {
              DrStore.dispatch("room/groups/delete", pgroup.id).then(
                function onSuccess() {
                  $state.go(
                    "members.list",
                    { pgroupId: undefined },
                    { location: true, reload: false },
                  );

                  $uibModalInstance.close();
                },
                function onError() {
                  $uibModalInstance.dismiss();
                  AlertService.danger(
                    'Failed to delete group "' + pgroup.name + '".',
                  );
                },
              );
            };
          },
        ],
      });
    }

    function isCategoryViewable(pgroupId, categoryId) {
      if (!service.isLoaded() || !CategoriesService.isLoaded()) return;

      const pgroup = service.pgroups[pgroupId];
      if (!pgroup) return;

      const category = CategoriesService.categories[categoryId];
      if (!category) return;

      return pgroup.viewable_categories_ids.includes(category.id);
    }
  }

  function MembersService(
    $uibModal,
    $window,
    $q,
    $sce,
    $state,
    $http,
    URLS,
    PermissionsApiService,
    AlertService,
    ManageMembersService,
    RoomApiService,
    RoomConfig,
  ) {
    var service = {
      // also see Object.defineProperties below for additional methods

      isLoaded: function isLoaded() {
        return (
          DrStore.state.room.members.isError === false &&
          DrStore.state.room.members.isLoading === false
        );
      },

      // DO NOT USE THIS METHOD! Use DrStore.dispatch("room/members/load") instead.
      // Used only in frontend/app/analytics/analytics.service.data/load.js
      updateMembersList() {
        return DrStore.dispatch("room/members/load");
      },

      inviteMembers: function inviteMembers(pgroupId) {
        return inviteMembersModal({
          pgroupId: pgroupId,
          data: {
            emails: undefined,
            message: undefined,
          },
          modalOptions: {
            header: "Add member",
          },
          isInvite: true,
        });
      },

      bulkMoveMembers: function bulkMoveMembers(pgroupMembers) {
        const emails = pgroupMembers.map((m) => ({
          address: m.email,
          isValid: true,
        }));

        return inviteMembersModal({
          data: { emails: emails },
          modalOptions: {
            header: "Bulk Members Move Update",
          },
        });
      },

      moveMember: function moveMember(pgroupId, member) {
        const data = { members: [{ email: member.email }] };
        const method = getManageMembersMethod(pgroupId);

        ManageMembersService.manage(method, data, false).then(() =>
          DrStore.dispatch("room/members/load"),
        );
      },

      removeMembers: function removeMembers(members) {
        // {all: [], invites: [], members: []}
        // set message depends on the count of members / invites
        var msg = "Are you sure you want to ";
        var taskWarning = "will be removed from all requests.";
        if (members.all.length === 1) {
          if (members.all[0].pending) {
            msg += "cancel the invite?";
          } else {
            msg += "remove the selected member? User " + taskWarning;
          }
        } else {
          var i_count = members.invites.length;
          var m_count = members.members.length;
          if (m_count) {
            msg += "remove " + m_count + " member" + (m_count > 1 ? "s" : "");
            msg += i_count ? " and " : "?";
          }
          if (i_count) {
            msg +=
              "cancel " + i_count + " invite" + (i_count > 1 ? "s" : "") + "?";
          }

          if (m_count) {
            msg += " They " + taskWarning;
          }
        }

        // set header depends on the count of members
        var headerText = "Remove Member";
        if (members.all.length > 1) {
          headerText += "s";
        }

        var modalOptions = {
          headerText: headerText,
          actionButtonText: "Remove",
          bodyText: msg,
        };

        $uibModal.open({
          template: templates_confirmDialogHtml,
          controllerAs: "ctrl",
          controller: [
            "$scope",
            "$uibModalInstance",
            "$q",
            function ($scope, $uibModalInstance, $q) {
              this.modalOptions = modalOptions;
              this.submit = async function () {
                await DrStore.dispatch(
                  "room/members/removeMembers",
                  members.all,
                );
                $uibModalInstance.dismiss();
              };
            },
          ],
        });
      },

      exportMembers: function exportMembers(pgroupId) {
        var url = pgroupId
          ? URLS["api:room:pgroup_members_export"](pgroupId)
          : URLS["api:room:pgroup_list_members-export"]();
        $window.open(url, "_blank");
      },

      exportPermissionMatrix: function exportPermissionMatrix() {
        var url = URLS["api:room:pgroup_list_matrix-export"]();

        return $http.get(url).then(
          (resp) => {
            $uibModal.open({
              template: members_exportPermMatrixDialogHtml,
              controllerAs: "$ctrl",
              controller: function () {
                const $ctrl = this;
                $ctrl.operationLabel = "Export permissions";
                $ctrl.operationUid = resp.data.operation;
              },
            });
          },
          () => {
            AlertService.danger("Failed to export permissions");
          },
        );
      },

      flattenMembers: function flattenMembers(members, searchQuery) {
        // return flat list of members and group

        // get users per group
        var groupsMap = {};
        var groupMembers = {};
        members.forEach(function (member) {
          var pgroup = member.pgroup;
          groupsMap[pgroup.id] = pgroup;
          var _members = groupMembers[pgroup.id] || [];
          _members.push(member);
          groupMembers[pgroup.id] = _members;
        });
        var pgroups = Object.keys(groupsMap).map(function (k) {
          return groupsMap[k];
        });
        sortGroups(pgroups);

        // build flat list of users and groups
        var resultMembers = [];
        pgroups.forEach(function (pgroup) {
          resultMembers.push(pgroup);
          var _members = groupMembers[pgroup.id];
          sortMembers(_members);
          resultMembers = resultMembers.concat(_members);
        });

        return filterMembers(resultMembers);

        function sortMembers(members) {
          members.sort(function (a, b) {
            // show current user always first in users dropdown
            if (a.id === RoomConfig.currentUser.id) {
              return -2;
            }
            if (b.id === RoomConfig.currentUser.id) {
              return 2;
            }
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            return nameA > nameB ? 1 : -1;
          });
        }

        function sortGroups(groups) {
          groups.sort(function (a, b) {
            // show current user always first in users dropdown
            if (a.id === RoomConfig.userPermissions.id) {
              return -2;
            }
            if (b.id === RoomConfig.userPermissions.id) {
              return 2;
            }
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            return nameA > nameB ? 1 : -1;
          });
        }

        function filterMembers(members) {
          if (!searchQuery) {
            return members;
          }

          var scores = {};
          members = members.filter(function (member) {
            if (!member.pgroup) {
              return false;
            }
            var content =
              member.first_name + " " + member.last_name + " " + member.email;
            var score = fuzzaldrinPlus.score(content, searchQuery);
            scores[member.id] = score;
            return score > 0;
          });
          members.sort(function (mA, mB) {
            return scores[mB.id] - scores[mA.id];
          });
          return members;
        }
      },

      isInviteResending: function (id) {
        return DrStore.state.room.members.inviteResending[id];
      },
    };

    Object.defineProperties(service, {
      loading: {
        get: () => DrStore.state.room.members.isLoading,
      },
      error: {
        get: () => DrStore.state.room.members.isError,
      },
      members: {
        get: () => DrStore.state.room.members.members,
      },
      invites: {
        get: () => DrStore.state.room.members.invites,
      },
      membersList: {
        get: () => DrStore.state.room.members.membersList,
      },
      invitesList: {
        get: () => DrStore.state.room.members.invitesList,
      },
      activeMembersList: {
        get: () => DrStore.state.room.members.activeMembersList,
      },
      updatePromise: {
        get: () => DrStore.state.room.members.updatePromise,
      },
    });

    return service;

    function getManageMembersMethod(pgroupId) {
      return function (data, submit) {
        if (submit) {
          return DrStore.dispatch("room/members/addMembers", {
            pgroupId,
            data,
          });
        } else {
          return DrStore.dispatch("room/members/addMembersCheck", {
            pgroupId,
            data,
          });
        }
      };
    }

    function inviteMembersModal(params) {
      var modalInstance = $uibModal.open({
        template: members_inviteModalHtml,
        controllerAs: "ctrl",
        resolve: {
          params: function () {
            return params;
          },
        },
        controller: [
          "$scope",
          "$http",
          "$q",
          "URLS",
          "$uibModal",
          "$filter",
          "RoomConfig",
          "params",
          "PermissionsService",
          "MembersService",
          "ORG_MEMBER_DATA",
          function (
            $scope,
            $http,
            $q,
            URLS,
            $uibModal,
            $filter,
            RoomConfig,
            params,
            PermissionsService,
            MembersService,
            ORG_MEMBER_DATA,
          ) {
            $scope.modalOptions = params.modalOptions;

            // The modal will disallow to select a Room if it set via props.
            $scope.roomId = RoomConfig.id;
            $scope.groupId = params.pgroupId;

            // The modal will pre-fill the emails input if we pass it via props.
            // Used for "Bulk Move" action.
            $scope.emails = params.data.emails;

            $scope.api = {
              inviteCheck: (pgroupId, data) =>
                DrStore.dispatch("room/members/addMembersCheck", {
                  pgroupId,
                  data,
                }),
              invite: (pgroupId, data) =>
                DrStore.dispatch("room/members/addMembers", { pgroupId, data }),
              bulkMakeAdmin: (req) => {
                // We don't have "Add as administrator(s) to all your rooms" on Room's side.
                throw new Error("Invalid method");
              },
            };

            $scope.closeModal = function () {
              DrStore.dispatch("room/members/load");
              $scope.$close();
            };

            initSuggestedUsers();
            initRooms();

            function initSuggestedUsers() {
              $scope.users = [];
              if (ORG_MEMBER_DATA.client.enable_dashboard) {
                $http
                  .get(URLS["api:client-dashboard:org-users-list"]())
                  .then((resp) => {
                    $scope.users = resp.data;
                    // expecting {email, name}
                    $scope.users.forEach((user) => {
                      user.name =
                        (user.first_name || "") + " " + (user.last_name || "");
                      user.name = user.name.trim();
                    });
                    $scope.users.sort(
                      (a, b) =>
                        a.name.localeCompare(b.name) ||
                        a.email.localeCompare(b.email),
                    );
                  });
              }
            }

            function initRooms() {
              $scope.rooms = [{ id: RoomConfig.id, groups: [] }];
              $q.when(PermissionsService.updatePromise).then(() => {
                $scope.rooms = [
                  {
                    id: RoomConfig.id,
                    groups: PermissionsService.pgroupsList.filter((pg) => {
                      const pgroup = RoomConfig.userPermissions;
                      return (
                        pgroup.administrator ||
                        // non-admin invite to their group
                        (pgroup.invite && pgroup.id === pg.id)
                      );
                    }),
                  },
                ];
              });
            }
          },
        ],
      });

      return modalInstance.result;
    }
  }

  /* End MembersService */

  function MembersController(
    $scope,
    $state,
    PermissionsService,
    MembersService,
    RoomConfig,
    $timeout,
  ) {
    // go to members list by default
    if ($state.current.name === "members") {
      $state.go("members.list");
    }
    // the main controller under all other controllers
    // keep stuff in $scope to access from children controllers
    var self = this;
    self.Permissions = PermissionsService;
    self.Members = MembersService;
    self.userPermissions = RoomConfig.userPermissions;
    self.$state = $state;
    self.pgroupId = $state.params.pgroupId;

    self.getActiveByGroupId = DrStore.getters["room/members/activeByGroupId"];
    self.getPendingByGroupId = DrStore.getters["room/members/pendingByGroupId"];

    // hack to keep scroll position in sidebar
    // if new group is selected, members controller will be reloaded
    // whole members template is reloaded as well (including sidebar)
    // to keep scroll position, it is passed explicitly in openGroup as hidden parameter
    // TODO: add root controller for members to keep same sidebar for whole tab (like in tasks, docs..)
    $scope.$watch("membersCtrl.Permissions.loading", function () {
      if (self.Permissions.loading) return;
      $timeout(function () {
        var scrollPosition = $state.params.scrollPosition;
        var sidebar = $(".room-sidebar");
        sidebar.scrollTop(scrollPosition);
      });
    });

    self.userPermissions.canManageMembers = function () {
      if (self.userPermissions.administrator) {
        return true;
      }

      return (
        self.userPermissions.invite && self.userPermissions.id === self.pgroupId
      );
    };
    self.userPermissions.canInviteMember = function (invite) {
      if (self.userPermissions.administrator) {
        return true;
      }

      if (invite && invite.pgroup.id != self.userPermissions.id) {
        return false;
      }

      return (
        self.userPermissions.invite &&
        (!self.pgroupId || self.userPermissions.id === self.pgroupId)
      );
    };

    // check user access to information
    var watchGroups = $scope.$watch(
      "membersCtrl.Permissions.pgroups",
      function (pgroups) {
        if (!pgroups) {
          return;
        }
        watchGroups(); // unwatch
        var navigateBackToList = false;
        self.pgroup = pgroups[self.pgroupId];

        // check selected group exists
        if (self.pgroupId && angular.isUndefined(self.pgroup)) {
          self.pgroupId = undefined; // open all groups
          navigateBackToList = true;
        }

        // check access to child states
        if (self.userPermissions.administrator) {
          const disallowedStates = [];

          if (self.pgroup) {
            if (self.pgroup.is_administrator) {
              disallowedStates.push("members.files");
              disallowedStates.push("members.tasks");
              if (self.pgroup.builtin_admin_pgroup) {
                disallowedStates.push("members.others");
              }
            }
          } else {
            disallowedStates.push("members.others");
          }

          if (disallowedStates.indexOf($state.current.name) > -1) {
            navigateBackToList = true;
          }
        } else {
          // non-admins can see only members list and member detail states
          if (
            ["members.list", "members.detail"].indexOf($state.current.name) > -1
          ) {
            navigateBackToList = true;
          }
        }

        if (navigateBackToList) {
          $state.go("members.list", { pgroupId: self.pgroupId });
        }
      },
    );

    self.groupPermissionSettingsDisabled = function (hideForAdmins) {
      if (angular.isUndefined(self.pgroup)) {
        return true;
      }
      if (self.pgroup.builtin_admin_pgroup) {
        return true;
      }
      if (self.pgroupId && self.pgroupId == self.userPermissions.id) {
        return true;
      }
      if (hideForAdmins && self.pgroup.is_administrator) {
        return true;
      }
      return false;
    };

    self.disabledSettingTooltipText = "Group has administrator permissions";
    self.showDisabledSettingTooltip = function () {
      return (
        self.pgroup &&
        self.pgroup.is_administrator &&
        !self.pgroup.builtin_admin_pgroup
      );
    };

    self.isGroupActive = function (pgroup) {
      return self.pgroupId === pgroup.id;
    };

    self.openGroup = function (pgroup) {
      var sidebar = $(".room-sidebar");
      $state.go("members.list", {
        pgroupId: pgroup.id,
        scrollPosition: sidebar.scrollTop(),
      });
    };

    self.membersMenu = function (pgroup) {
      var menu = [];
      if (
        !RoomConfig.userPermissions.administrator ||
        pgroup.builtin_admin_pgroup
      ) {
        return menu;
      }

      menu = [
        [
          "Copy",
          function () {
            PermissionsService.copyPGroup(pgroup);
          },
        ],

        [
          "Rename",
          function () {
            PermissionsService.renamePGroup(pgroup);
          },
        ],

        [
          "Delete",
          function () {
            PermissionsService.deletePGroup(pgroup);
          },
        ],
      ];

      return menu;
    };
  }

  /* End MembersController */

  function MembersListController($scope, $filter) {
    var self = this;
    var membersCtrl = $scope.membersCtrl;
    self.isAllMembersSelected = false;
    self.searchText = undefined;
    self.filteredMembers = [];
    self.orderedMembers = [];
    self.searchText = "";

    function updateFilteredMembers() {
      self.filteredMembers = self.searchText
        ? $filter("filterBy")(
            self.orderedMembers,
            [
              "email",
              "first_name",
              "last_name",
              "title",
              "company",
              "office_number",
            ],

            self.searchText,
          )
        : self.orderedMembers;

      self.updateIsAllMembersSelected();
    }

    $scope.$watchGroup(
      [
        "membersCtrl.Members.activeMembersList",
        "membersCtrl.Permissions.pgroups",
      ],

      function () {
        if (
          !membersCtrl.Members.isLoaded() ||
          !membersCtrl.Permissions.isLoaded()
        ) {
          return;
        }
        if (
          angular.isUndefined(membersCtrl.Members.activeMembersList) ||
          membersCtrl.Members.activeMembersList.length === 0
        ) {
          return;
        }
        if (
          angular.isUndefined(membersCtrl.Permissions.pgroups) ||
          Object.keys(membersCtrl.Permissions.pgroups).length === 0
        ) {
          return;
        }

        if (membersCtrl.pgroupId) {
          var pgroup = membersCtrl.Permissions.pgroups[membersCtrl.pgroupId];
          // if someone tryis to access not existed pgroup - don't do anything,
          // just wait, because root controller'll go to all pgroups page
          if (!pgroup) {
            return;
          }
          self.members = DrStore.getters["room/members/activeByGroupId"](
            membersCtrl.pgroupId,
          );
        } else {
          self.members = membersCtrl.Members.activeMembersList;
        }

        // create new self.orderedMembers each time members list have been changed
        self.setOrder(self.order, true);
      },
    );

    $scope.$watch("ctrl.searchText", () => {
      updateFilteredMembers();
    });

    self.resendInvite = function (invite) {
      DrStore.dispatch("room/members/resendInvite", { invite });
    };

    self.isMembersSelected = function () {
      // is there at least one selected user (for displaing the toolbar)
      if (!self.members || self.members.length === 0) {
        return false;
      }
      return self.members.filter(function (member) {
        return member.Selected;
      }).length;
    };

    self.getSelectedMembers = function () {
      // an object with: all (all selected members), members (only members list), invites (invites list)
      return self.filteredMembers.reduce(
        function (bucket, member) {
          // don't touch the owner!
          if (!member.room_owner && member.Selected) {
            bucket.all.push(member);
            if (member.pending) {
              bucket.invites.push(member);
            } else {
              bucket.members.push(member);
            }
          }
          return bucket;
        },
        { members: [], invites: [], all: [] },
      );
    };

    self.updateIsAllMembersSelected = function () {
      // check if all users are still selected after click a member checkbox
      self.isAllMembersSelected = self.filteredMembers.every(function (member) {
        return member.Selected || member.room_owner;
      });
    };

    self.toggleAllMembers = function () {
      // select / unselect all members
      const value = !self.isAllMembersSelected;
      self.members.forEach(function (member) {
        // don't touch the owner!
        if (!member.room_owner) {
          member.Selected = value;
        }
      });
      self.updateIsAllMembersSelected();
    };

    self.resetMembersSelection = function () {
      // switch of all selection
      self.isAllMembersSelected = true;
      self.toggleAllMembers();
    };

    self.order = {
      by: "name",
      reversed: false,
    };

    self.setOrder = function (order, isMembersUpdated) {
      if (angular.equals(self.order, order) && !isMembersUpdated) return;
      self.order = order;
      self.orderedMembers = self.members.sort(function (a, b) {
        // invites should be always at the bottom
        if (a.pending && !b.pending) {
          return 1;
        } else if (!a.pending && b.pending) {
          return -1;
        }

        var valA = a[self.order.by],
          valB = b[self.order.by];

        if (self.order.by == "pgroup") {
          valA = a.pgroup.name;
          valB = b.pgroup.name;
        }
        if (self.order.by == "name" && a.pending && b.pending) {
          valA = a.email;
          valB = b.email;
        }

        var order = 0;
        if (valA > valB) {
          order = 1;
        } else if (valA < valB) {
          order = -1;
        }

        if (self.order.reversed) {
          order *= -1;
        }
        return order;
      });

      updateFilteredMembers();
    };
  }

  /* End MembersListController */

  function MembersArchivedController($scope, PermissionsService) {
    const ctrl = this;

    ctrl.isAllSelected = false;
    ctrl.order = { by: "name", reversed: false };
    ctrl.setOrder = setOrder;
    ctrl.searchText = undefined;
    ctrl.filteredGroups = [];
    ctrl.selectedCount = 0;
    ctrl.toggleAll = toggleAll;
    ctrl.onSelectionUpdate = onSelectionUpdate;
    ctrl.indeterminateSelected = indeterminateSelected;
    ctrl.getSelectedGroups = getSelectedGroups;
    ctrl.Permissions = PermissionsService;

    function updateFilteredGroups() {
      ctrl.filteredGroups = PermissionsService.archivedPgroupsList || [];
      if (ctrl.searchText) {
        ctrl.filteredGroups = ctrl.filteredGroups.filter((pgroup) => {
          return isStringContains(ctrl.searchText, pgroup.name);
        });
      }
      onSelectionUpdate();
    }

    $scope.$watch("ctrl.searchText", () => {
      updateFilteredGroups();
    });
    $scope.$watchCollection("ctrl.Permissions.pgroups", () => {
      updateFilteredGroups();
    });

    function toggleAll() {
      ctrl.isAllSelected = !ctrl.isAllSelected;
      ctrl.filteredGroups.forEach(function (pgroup) {
        pgroup.Selected = ctrl.isAllSelected;
      });
      ctrl.selectedCount = ctrl.isAllSelected ? ctrl.filteredGroups.length : 0;
    }

    function onSelectionUpdate() {
      ctrl.selectedCount = ctrl.filteredGroups.filter((pgroup) => {
        return pgroup.Selected;
      }).length;
      ctrl.isAllSelected = ctrl.selectedCount === ctrl.filteredGroups.length;
    }

    function getSelectedGroups() {
      return ctrl.filteredGroups.filter(function (group) {
        return group.Selected;
      });
    }

    function indeterminateSelected() {
      return (
        ctrl.selectedCount > 0 &&
        ctrl.selectedCount !== ctrl.filteredGroups.length
      );
    }

    function setOrder(order) {
      ctrl.order.by = order.by;
      ctrl.order.reversed = order.reversed;
    }
  }
  /* End MembersArchivedController */

  function MembersDetailController($scope, $state, MembersService) {
    var self = this;
    var membersCtrl = $scope.membersCtrl;
    self.userId = $state.params.userId;

    var watchMembers = $scope.$watch(
      () => {
        return MembersService.members;
      },
      function (members) {
        if (
          !(members && Object.keys(members).length > 0) ||
          !MembersService.isLoaded()
        ) {
          return;
        }

        self.user = members[self.userId];
        watchMembers(); // unwatch

        if (!self.user) {
          // unknown user, back to users list
          $state.go("members.list");
          return;
        }
        // set an actual pgroup active in sidebar
        // has to be set in root controller by that time
        if (
          !membersCtrl.pgroupId ||
          membersCtrl.pgroupId !== self.user.pgroup.id
        ) {
          $state.go("members.detail", {
            userId: self.userId,
            pgroupId: self.user.pgroup ? self.user.pgroup.id : undefined,
          });
        }
      },
    );
  }

  /* End MembersDetailController */

  function MembersFilesController(
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $q,
    $filter,
    $uibModal,
    PermissionsService,
    PermissionsApiService,
    DocumentsService,
    AlertService,
  ) {
    var self = this;
    self.pgroupId = $stateParams.pgroupId;
    self.Documents = DocumentsService;

    self.loading = false;
    self.error = false;

    self.dirty = false;
    self.saving = false;

    self.order = {
      by: "treePosition",
      reversed: false,
    };
    self.setOrder = function (order) {
      self.order.reversed = order.reversed;
      self.order.by = order.by;
      if (!self.loading && !self.error && self.rootFolder.items) {
        setDataService();
      }
    };

    self.Folders = {};
    self.Files = {};
    self.rootFolder = {};
    self.dataService = { type: "folder" }; // either rootFolder or fake root searchFolder

    self.paddingStyle = function (item) {
      // i can't use searchQuery here because it can be deleted by user
      if (!item || self.searchQuery) {
        return;
      }
      return { "padding-left": item.parent.parents.length * 12 + "px" };
    };
    self.toggleExpanded = function (item) {
      item.expanded = !item.expanded;
      setDataService();
    };

    self.itemTrackingKey = itemTrackingKey;
    self.toggleItemView = toggleItemView;
    self.toggleItemDownloadWatermarked = toggleItemDownloadWatermarked;
    self.toggleItemDownloadOrigin = toggleItemDownloadOrigin;
    self.toggleItemEdit = toggleItemEdit;
    self.setItemPermission = setItemPermission;
    self.searchFiles = syncTree;
    self.savePermissions = savePermissions;
    self.permissions = { file: {}, folder: {} }; // {folder: {group_id: {view:true, edit: false ... }}
    self.originalPermissions = {};
    self.isPropagated = { file: {}, folder: {} }; // {folder: {group_id: {view:true, edit: false ... }}
    self.gridGroups = [];

    self.createPGroup = function () {
      PermissionsService.createPGroup();
    };

    let confirmationStateTo;
    const stopWatchingStateChange = $rootScope.$on(
      "$stateChangeStart",
      function (event, toState, toParams) {
        if (!self.dirty) {
          return;
        }
        event.preventDefault(); // prevent location change to new state
        // open modal only only on first $stateChangeStart event
        // and remember last even values
        const needToOpenModal = !confirmationStateTo;
        confirmationStateTo = { state: toState, params: toParams };
        if (!needToOpenModal) {
          return;
        }
        $uibModal
          .open({
            template: templates_confirmDialogHtml,
            controllerAs: "ctrl",
            controller: [
              "$scope",
              "$uibModalInstance",
              function ($scope, $uibModalInstance) {
                this.modalOptions = {
                  headerText: "Unsaved changes",
                  actionButtonText: "Discard",
                  bodyText:
                    "You have unsaved changes made to the permissions. Are you sure you wish to discard these changes?",
                };

                this.submit = function () {
                  stopWatchingStateChange(); // stop event watch, so next $state.go will not trigger it
                  $state.go(
                    confirmationStateTo.state,
                    confirmationStateTo.params,
                  );

                  $uibModalInstance.close();
                };
              },
            ],
          })
          // suppress unhandled promise warning
          .result.catch(() => {})
          .finally(() => {
            confirmationStateTo = undefined;
          });
      },
    );

    $scope.$on("$destroy", () => {
      stopWatchingStateChange();
    });

    var permFields = [
      "view",
      "download_watermarked",
      "download_original",
      "edit",
    ];

    const permIndexes = permFields.reduce((acc, field, index) => {
      acc[field] = index;
      return acc;
    }, {});
    syncTree();

    function hasPermissionChanges() {
      const changes = getPermissionChanges();
      for (const objChanges of Object.values(changes)) {
        if (!isEmpty(objChanges)) {
          return true;
        }
      }
      return false;
    }

    function getPermissionChanges() {
      function getGroupChanges(objType, objIdKey, groupId) {
        const original = self.originalPermissions[objType][groupId];
        const current = self.permissions[objType][groupId];
        const changes = [];
        Object.keys(original).forEach((objId) => {
          const originalPerm = original[objId];
          const currentPerm = current[objId];
          const hasChanges = originalPerm.some((val, index) => {
            return val !== currentPerm[index];
          });
          if (!hasChanges) {
            return;
          }
          const objPerms = {};
          objPerms[objIdKey] = objId;
          permFields.forEach((field, index) => {
            objPerms[field] = currentPerm[index];
          });
          changes.push(objPerms);
        });
        return changes;
      }

      function getTypeChanges(objType, objIdKey) {
        const changes = {};
        Object.keys(self.originalPermissions[objType]).forEach((groupId) => {
          const pgroupChanges = getGroupChanges(objType, objIdKey, groupId);
          if (!isEmpty(pgroupChanges)) {
            changes[groupId] = pgroupChanges;
          }
        });
        return changes;
      }

      // changes contains updated perm values, perms without changes aren't included
      return {
        // {type: {groupId: {objId: changes}
        folder: getTypeChanges("folder", "folder_id"),
        file: getTypeChanges("file", "document_id"),
      };
    }

    function syncTree() {
      if (self.loading) {
        return;
      }

      self.loading = true;
      self.error = false;
      PermissionsApiService.getItems(self.pgroupId).then(
        function successCallback(resp) {
          var data = processDocuments(
            resp.data.items.folders,
            resp.data.items.documents,
          );

          restoreExpandedFoldersState(data.folders, self.Folders);

          self.rootFolder = data.rootFolder;
          // keep original items for recreating items in setDataService
          self.rootFolder.originalItems = data.rootFolder.items;
          self.Files = data.files;
          self.Folders = data.folders;

          self.permissions = {
            folder: resp.data.permissions.folders,
            file: resp.data.permissions.documents,
          };
          self.originalPermissions = cloneDeep(self.permissions);
          self.gridGroups = PermissionsService.pgroupsList
            .filter((group) => resp.data.permissions.folders[group.id])
            .map((group) => group.id);

          setDataService();

          self.loading = self.error = self.dirty = false;
          return resp;
        },
        function errorCallback() {
          self.error = true;
          self.loading = false;
          var msg = "Failed to load files for group " + self.pgroupId;
          if (self.searchQuery) {
            msg += ' with query "' + self.searchQuery + '"';
          }
          AlertService.danger(msg);
        },
      );
    }

    function itemTrackingKey(item) {
      return item.type + item.id;
    }

    function setDataService() {
      function sort(items) {
        return $filter("orderBy")(items, self.order.by, self.order.reversed);
      }
      function addChildren(expandedItems, item) {
        expandedItems.push(item);
        if (item.expanded && item.items) {
          var orderedItems = sort(item.items);
          orderedItems.reduce(addChildren, expandedItems);
        }
        return expandedItems;
      }
      if (self.searchQuery) {
        var items = DocumentsService.findItems(
          self.searchQuery,
          self.Files,
          self.Folders,
        );

        self.dataService = { items: sort(items), type: "folder", id: null };
      } else {
        self.dataService = self.rootFolder;
        self.dataService.items = sort(
          self.rootFolder.originalItems.reduce(addChildren, []),
        );
      }

      updateSearchDataServicePerms();
      fullUpdatePropagated();
    }

    function updateSearchDataServicePerms() {
      if (!self.searchQuery) {
        return;
      }
      function permissionsValue(groupId, name) {
        // if there at least one item with false -> dataService has to be false
        return !self.dataService.items.filter(function (item) {
          return !self.permissions[item.type][groupId][item.id][name];
        }).length;
      }
      Object.keys(self.permissions["folder"]).forEach((groupId) => {
        const groupPerms =
          self.permissions["folder"][groupId][self.dataService.id] || {};
        permFields.forEach(function (perm) {
          if (groupPerms[perm] === undefined) {
            groupPerms[perm] = permissionsValue(groupId, perm);
          }
        });
        self.permissions["folder"][groupId][self.dataService.id] = groupPerms;
      });
    }

    function fullUpdatePropagated() {
      Object.keys(self.permissions["folder"]).forEach((groupId) => {
        updatePgroupPropagated(groupId);
      });
    }

    function updatePgroupPropagated(groupId) {
      self.isPropagated.folder[groupId] = {};
      self.isPropagated.file[groupId] = {};
      updateFolderPropagated(groupId, self.dataService);
    }

    function updateFolderPropagated(groupId, folder) {
      const folderPropagated = permFields.map(() => true);
      const folderPerms = self.permissions[folder.type][groupId][folder.id];
      folder.items.forEach(function (child) {
        if (child.type === "folder") {
          const childPropagated = updateFolderPropagated(groupId, child);
          for (let index = 0; index < permFields.length; index++) {
            folderPropagated[index] =
              folderPropagated[index] && childPropagated[index];
          }
        }
        const childPerms = self.permissions[child.type][groupId][child.id];
        for (let index = 0; index < permFields.length; index++) {
          folderPropagated[index] =
            folderPropagated[index] && childPerms[index] === folderPerms[index];
        }
      });
      const pgroupProp = self.isPropagated["folder"][groupId] || {};
      pgroupProp[folder.id] = folderPropagated;
      self.isPropagated["folder"][groupId] = pgroupProp;
      return folderPropagated;
    }

    function makeParentsViewable(groupId, item) {
      let parents = [];
      if (item.type === "folder") {
        parents = item.parents;
      } else {
        parents = item.parent.parents.concat([item.parent]);
      }
      parents = parents.filter((folder) => !!folder.parent_id);
      for (const folder of parents) {
        const folderPerm = self.permissions[folder.type][groupId][folder.id];
        folderPerm[0] = true;
      }
    }

    function propagatePerm(groupId, item, updates) {
      const itemPerms = self.permissions[item.type][groupId][item.id];
      for (let index = 0; index < permFields.length; index++) {
        if (index in updates) {
          itemPerms[index] = updates[index];
        }
      }
      if (item.type === "folder") {
        item.items.forEach(function (child) {
          propagatePerm(groupId, child, updates);
        });
      }
    }

    function savePermissions() {
      if (self.saving) {
        return;
      }
      self.saving = true;
      const changes = getPermissionChanges();
      const promises = [];
      const groups = uniq(
        Object.keys(changes["folder"]).concat(Object.keys(changes["file"])),
      );

      groups.forEach((groupId) => {
        const foldersChanges = changes["folder"][groupId] || [];
        const filesChanges = changes["file"][groupId] || [];
        const promise = PermissionsApiService.updatePermissions(
          groupId,
          foldersChanges,
          filesChanges,
        );

        promises.push(promise);
      });
      $q.all(promises)
        .catch(function (error) {
          AlertService.danger(
            (error.data && error.data.detail) || "Failed to update permission",
          );
        })
        .finally(function () {
          syncTree();
          self.saving = false;
        });
    }

    function updateItemPerm(groupId, item, updates) {
      const itemPerms = self.permissions[item.type][groupId][item.id];
      if (updates[0] && !itemPerms[0]) {
        makeParentsViewable(groupId, item);
      }
      propagatePerm(groupId, item, updates);
      updatePgroupPropagated(groupId);
      updateSearchDataServicePerms();

      self.dirty = hasPermissionChanges();
    }

    function setItemPermission(groupId, item, permKey) {
      if (self.loading || self.saving) {
        return;
      }
      const updates = [];
      const permIndex = permIndexes[permKey];
      permFields.forEach((field, index) => {
        updates.push(permIndex !== undefined && permIndex >= index);
      });
      updateItemPerm(groupId, item, updates);
    }

    function toggleItemField(groupId, item, fieldIndex) {
      if (self.loading || self.saving) {
        return;
      }
      const newValue =
        !self.permissions[item.type][groupId][item.id][fieldIndex];
      const updates = {};
      for (let permIndex = 0; permIndex < permFields.length; permIndex++) {
        if (newValue && permIndex <= fieldIndex) {
          updates[permIndex] = true;
        }
        if (!newValue && permIndex >= fieldIndex) {
          updates[permIndex] = false;
        }
      }
      updateItemPerm(groupId, item, updates);
    }

    function toggleItemView(groupId, item) {
      toggleItemField(groupId, item, permIndexes.view);
    }

    function toggleItemDownloadWatermarked(groupId, item) {
      toggleItemField(groupId, item, permIndexes.download_watermarked);
    }

    function toggleItemDownloadOrigin(groupId, item) {
      toggleItemField(groupId, item, permIndexes.download_original);
    }

    function toggleItemEdit(groupId, item) {
      toggleItemField(groupId, item, permIndexes.edit);
    }
  }

  /* End MembersFilesController */

  const CATEGORY_SELECTED_STATES = {
    UNSELECTED: 0,
    PARTIAL: 1,
    SELECTED: 2,
  };

  class CategoriesPermState {
    constructor() {
      this._isExpanded = {};
      // all properties except this should not be used directly (private)
      this.categoriesFlatTree = [];
    }
    _rebuildFlatTree() {
      this.categoriesFlatTree = [];
      this.allCategoriesIds.forEach((catId) => {
        if (this.ancestors[catId].every((ancId) => this._isExpanded[ancId])) {
          this.categoriesFlatTree.push(this.categories[catId]);
        }
      });
    }
    toggleExpanded(catId) {
      const isExpanded = !this._isExpanded[catId];
      if (isExpanded) {
        this._isExpanded[catId] = isExpanded;
      } else {
        this.descedants[catId].forEach(
          (descId) => (this._isExpanded[descId] = isExpanded),
        );
      }
      this._rebuildFlatTree();
    }
    hasChild(catId) {
      return this.descedants[catId].length > 1;
    }
    isExpanded(catId) {
      return !!this._isExpanded[catId];
    }

    _updateCategories(categories) {
      this.roots = [];
      this.categories = {};
      this.allCategoriesIds = [];
      this.descedants = {}; // {cat_id: [..desc ids including cat itself]}
      this.ancestors = {}; // {cat_id: [ancestors ids, root first])
      categories.map((cat) => {
        this.categories[cat.id] = cat;
        if (cat.parent_id) {
          this.ancestors[cat.id] = this.ancestors[cat.parent_id].concat([
            cat.parent_id,
          ]);

          this.ancestors[cat.id].forEach((anc_id) => {
            this.descedants[anc_id].push(cat.id);
          });
        } else {
          this.ancestors[cat.id] = [];
          this.roots.push(cat.id);
        }
        this.descedants[cat.id] = [cat.id];
      });

      const permsState = this;
      function cmpCategoriesTreeOrder(c1, c2) {
        function getCategoryOrderList(c) {
          // get list of category's path orders, i.e. [root.order, ..., c.order]
          return permsState.ancestors[c.id]
            .map((anc_id) => permsState.categories[anc_id].order)
            .concat([permsState.categories[c.id].order]);
        }

        function cmpArrays(a, b) {
          // compare numeric arrays lexicographically
          for (let i = 0; i < Math.min(a.length, b.length); ++i) {
            if (a[i] !== b[i]) {
              return a[i] - b[i];
            }
          }
          return a.length - b.length;
        }
        return cmpArrays(getCategoryOrderList(c1), getCategoryOrderList(c2));
      }

      const orderedCategories = [...categories];
      // order categories by tree order
      orderedCategories.sort(cmpCategoriesTreeOrder);

      this.allCategoriesIds = orderedCategories.map((c) => c.id);
      this._rebuildFlatTree();
    }
    _updateSelected(actions) {
      Object.keys(actions).forEach((pgroupId) => {
        const groupState = {};
        actions[pgroupId].categories.forEach((cat) => {
          groupState[cat.id] = cat.allowed
            ? CATEGORY_SELECTED_STATES.SELECTED
            : CATEGORY_SELECTED_STATES.UNSELECTED;
        });
        this.selected[pgroupId] = groupState;
        this._updatePGroupIndeterminate(pgroupId);
      });
    }
    _updatePGroupIndeterminate(pgroupId) {
      const groupState = this.selected[pgroupId];
      this.allCategoriesIds.forEach((catId) => {
        if (groupState[catId]) {
          const allChildSelected = this.descedants[catId].every(
            (descId) =>
              groupState[descId] === CATEGORY_SELECTED_STATES.SELECTED,
          );

          if (allChildSelected) {
            groupState[catId] = CATEGORY_SELECTED_STATES.SELECTED;
          } else {
            const someChildSelected = this.descedants[catId].some(
              (descId) =>
                groupState[descId] === CATEGORY_SELECTED_STATES.SELECTED,
            );

            groupState[catId] = someChildSelected
              ? CATEGORY_SELECTED_STATES.PARTIAL
              : CATEGORY_SELECTED_STATES.UNSELECTED;
          }
        }
      });
      const allSelected = this.roots.every(
        (rootId) => groupState[rootId] === CATEGORY_SELECTED_STATES.SELECTED,
      );

      if (allSelected) {
        groupState[null] = CATEGORY_SELECTED_STATES.SELECTED;
      } else {
        const someSelected = this.roots.some(
          (rootId) =>
            groupState[rootId] !== CATEGORY_SELECTED_STATES.UNSELECTED,
        );

        groupState[null] = someSelected
          ? CATEGORY_SELECTED_STATES.PARTIAL
          : CATEGORY_SELECTED_STATES.UNSELECTED;
      }
    }

    updateState(actions) {
      this.selected = {}; // {pgroup: {cat_id: CATEGORY_SELECTED_STATES values}}
      const someGroupId = Object.keys(actions)[0];
      this._updateCategories(actions[someGroupId].categories);
      this._updateSelected(actions);
    }

    toggleSelected(pgroupId, catId) {
      const value =
        this.selected[pgroupId][catId] !== CATEGORY_SELECTED_STATES.SELECTED
          ? CATEGORY_SELECTED_STATES.SELECTED
          : CATEGORY_SELECTED_STATES.UNSELECTED;
      let toUpdate =
        catId === null ? this.allCategoriesIds : this.descedants[catId];
      if (value === CATEGORY_SELECTED_STATES.SELECTED) {
        toUpdate = toUpdate.concat(this.ancestors[catId]);
      }
      toUpdate.forEach((cId) => (this.selected[pgroupId][cId] = value));
      this._updatePGroupIndeterminate(pgroupId);
    }

    getSelectedState(pgroupId, catId) {
      return this.selected[pgroupId][catId];
    }
    getLevel(catId) {
      return this.ancestors[catId].length;
    }

    resetSelectedAll(pgroupId) {
      this.allCategoriesIds.forEach(
        (cId) =>
          (this.selected[pgroupId][cId] = CATEGORY_SELECTED_STATES.UNSELECTED),
      );

      this._updatePGroupIndeterminate(pgroupId);
    }
  }

  function MemberBulkRequestsController(
    $scope,
    $q,
    RoomConfig,
    PermissionsService,
    AlertService,
  ) {
    const $ctrl = this;
    $ctrl.loading = true;
    $ctrl.actions = undefined;
    $ctrl.catPermState = new CategoriesPermState();
    $ctrl.STATES = CATEGORY_SELECTED_STATES;
    $ctrl.categories = undefined;
    $ctrl.permGroups = undefined;
    $ctrl.toggleSelected = toggleSelected;
    $ctrl.getSelected = getSelected;
    $ctrl.getLevel = getLevel;
    $ctrl.isAnyGroupHasChanged = isAnyGroupHasChanged;
    $ctrl.save = save;
    $ctrl.createCategory = createCategory;
    $ctrl.isCreateCategoryShown = false;
    $ctrl.closeCreateCategoryDialog = closeCreateCategoryDialog;
    $ctrl.handleNewCategoryAdded = handleNewCategoryAdded;
    $ctrl.handleCategoryDeleted = handleCategoryDeleted;

    $ctrl.createPGroup = function () {
      PermissionsService.createPGroup();
    };

    let isUpdated = false;
    let isGroupUpdated = {}; // groupdId: boolean

    loadActions();

    $scope.$on("$destroy", () => {
      if (isUpdated) {
        DrStore.dispatch("room/groups/load");
        DrStore.dispatch("room/members/load");
      }
    });

    function loadActions() {
      PermissionsService.getActions().then(function (data) {
        $ctrl.actions = data;
        updateActionsData();
        isUpdated = false;
        isGroupUpdated = {};
        $ctrl.loading = false;
      });
    }

    function updateActionsData() {
      $ctrl.categories = [];
      $ctrl.permGroups = [];
      $ctrl.values = {};
      const pgroupId = Object.keys($ctrl.actions)[0];
      if (!pgroupId) {
        return;
      }
      $ctrl.permGroups = PermissionsService.pgroupsList
        .filter((group) => $ctrl.actions[group.id] && !group.is_administrator)
        .map((group) => group.id);
      $ctrl.catPermState.updateState($ctrl.actions);
    }

    function isAnyGroupHasChanged() {
      return Object.keys(isGroupUpdated).some(
        (pgroupId) => isGroupUpdated[pgroupId],
      );
    }

    function toggleSelected(pgroupId, catId) {
      isGroupUpdated[pgroupId] = true;
      $ctrl.catPermState.toggleSelected(pgroupId, catId);
    }

    function getSelected(pgroupId, catId) {
      return $ctrl.catPermState.getSelectedState(pgroupId, catId);
    }

    function getLevel(catId) {
      return $ctrl.catPermState.getLevel(catId);
    }

    function createCategory() {
      $ctrl.isCreateCategoryShown = true;
    }

    function closeCreateCategoryDialog() {
      $ctrl.isCreateCategoryShown = false;
    }

    function handleNewCategoryAdded() {
      loadActions();
    }

    function handleCategoryDeleted() {
      loadActions();
    }

    function save() {
      if ($ctrl.loading) {
        return;
      }
      $ctrl.loading = true;
      const updatedGroupIds = Object.keys(isGroupUpdated).filter(
        (pgroupId) => isGroupUpdated[pgroupId],
      );

      const updatePromises = updatedGroupIds.map((pgroupId) => {
        return updatePGroupActions(pgroupId);
      });
      $q.all(updatePromises).then(
        () => {
          AlertService.success("Permissions have been updated.");
          isUpdated = true;
          isGroupUpdated = {};
          updateActionsData();
          $ctrl.loading = false;
        },
        () => {
          AlertService.danger("Failed to save requests permissions.");
        },
      );
    }

    function updatePGroupActions(pgroupId) {
      const actions = $ctrl.actions[pgroupId];
      actions.categories.forEach((cat) => {
        cat.allowed =
          $ctrl.catPermState.getSelectedState(pgroupId, cat.id) !==
          CATEGORY_SELECTED_STATES.UNSELECTED;
      });
      return PermissionsService.updateActions(pgroupId, actions, true).then(
        function successCallback(data) {
          $ctrl.actions[pgroupId] = data[pgroupId];
        },
      );
    }
  }

  function MembersPermissionsSimpleController($scope, $state, $stateParams) {
    this.pgroupId = $stateParams.pgroupId;
  }

  function MembersActionsController(
    $scope,
    $state,
    $stateParams,
    $uibModal,
    RoomConfig,
    PermissionsService,
  ) {
    var self = this;
    var name =
      $state.current.name === "members.tasks" ? "categories" : "groups";
    var membersCtrl = $scope.membersCtrl;
    self.loading = true;
    self.RoomConfig = RoomConfig;
    let areActionsChanged = false;
    const pgroupId = $stateParams.pgroupId;
    self.pgroupId = pgroupId;
    self.catPermState = new CategoriesPermState();
    self.STATES = CATEGORY_SELECTED_STATES;
    self.toggleCatSelected = toggleCatSelected;
    self.resetSelectedCategories = resetSelectedCategories;
    self.toggleCanManageTasks = toggleCanManageTasks;
    self.getCatSelected = getCatSelected;
    self.getCatLevel = getCatLevel;

    self.permsOpts = {
      shouldInherit: undefined,
      handleConflicts: undefined,
    };
    self.categoryPermsOpts = {
      shouldInherit: undefined,
      handleConflicts: undefined,
    };
    self.PermsModes = PermissionsService.DefaultPermissionsMode;
    PermissionsService.getActions(pgroupId).then(function (data) {
      self.actions = data[pgroupId];
      self.catPermState.updateState(data);
      self.checkIfAllSelected(name);
      self.loading = false;
      self.permsOpts = {
        shouldInherit:
          self.actions.default_permissions_mode !== self.PermsModes.DenyAll,
        handleConflicts:
          self.actions.default_permissions_mode ===
          self.PermsModes.InheritNoConflict,
      };
      self.categoryPermsOpts = {
        shouldInherit:
          self.actions.category_default_permissions_mode !==
          self.PermsModes.DenyAll,
        handleConflicts:
          self.actions.category_default_permissions_mode ===
          self.PermsModes.InheritNoConflict,
      };
    });
    self.selectAll = selectAll;
    self.checkIfAllSelected = checkIfAllSelected;
    self.save = save;

    $scope.$on("$destroy", () => {
      if (areActionsChanged) {
        DrStore.dispatch("room/groups/load");
        DrStore.dispatch("room/members/load");
      }
    });

    function selectAll(name) {
      self.actions[name].forEach(function (item) {
        item.allowed = self.isAllSelected;
      });
    }

    function checkIfAllSelected(name) {
      self.isAllSelected = !self.actions[name].filter(function (item) {
        return !item.allowed;
      })[0];
    }

    function updateActions() {
      if (!RoomConfig.userPermissions.administrator) {
        return;
      }
      self.actions.categories.forEach((cat) => {
        cat.allowed =
          self.catPermState.getSelectedState(pgroupId, cat.id) !==
          CATEGORY_SELECTED_STATES.UNSELECTED;
      });
      return PermissionsService.updateActions(pgroupId, self.actions).then(
        function successCallback(data) {
          self.actions = data[pgroupId];
          self.catPermState.updateState(data);
        },
      );
    }

    function toggleCatSelected(catId) {
      self.form.$setDirty();
      self.catPermState.toggleSelected(pgroupId, catId);
    }

    function resetSelectedCategories() {
      self.catPermState.resetSelectedAll(pgroupId);
    }

    function toggleCanManageTasks() {
      self.actions.canManageTasks = !self.actions.canManageTasks;

      if (!self.actions.canManageTasks) {
        self.actions.taskCustomFieldsAccess = "no_access";
        self.actions.task_start_and_due_dates_access = "no_access";
        self.actions.need_comments_approve = false;
        self.actions.canManageFindings = false;
        self.categoryPermsOpts.shouldInherit = false;

        self.resetSelectedCategories();
      }

      self.form.$setDirty();
    }

    function getCatSelected(catId) {
      return self.catPermState.getSelectedState(pgroupId, catId);
    }

    function getCatLevel(catId) {
      return self.catPermState.getLevel(catId);
    }

    function save(form) {
      if (
        !self.actions.isAdministrator ||
        membersCtrl.pgroup.is_administrator
      ) {
        return saveChanges();
      }

      var modalOptions = {
        headerText: "Grant administrator permissions",
        actionButtonText: "Grant",
        bodyText:
          'Are you sure you want to grant group "' +
          membersCtrl.pgroup.name +
          '" administrator permissions?',
      };
      return $uibModal.open({
        template: templates_confirmDialogHtml,
        controllerAs: "ctrl",
        controller: [
          "$scope",
          "$uibModalInstance",
          function ($scope, $uibModalInstance) {
            this.modalOptions = modalOptions;
            this.submit = function () {
              saveChanges();
              $uibModalInstance.close();
            };
          },
        ],
      }).result;

      function saveChanges() {
        if (!self.permsOpts.shouldInherit) {
          self.actions.default_permissions_mode = self.PermsModes.DenyAll;
        } else if (self.permsOpts.handleConflicts) {
          self.actions.default_permissions_mode =
            self.PermsModes.InheritNoConflict;
        } else {
          self.actions.default_permissions_mode = self.PermsModes.InheritAlways;
        }

        if (!self.categoryPermsOpts.shouldInherit) {
          self.actions.category_default_permissions_mode =
            self.PermsModes.DenyAll;
        } else if (self.categoryPermsOpts.handleConflicts) {
          self.actions.category_default_permissions_mode =
            self.PermsModes.InheritNoConflict;
        } else {
          self.actions.category_default_permissions_mode =
            self.PermsModes.InheritAlways;
        }

        areActionsChanged = true;
        updateActions().then(function successCallback() {
          membersCtrl.pgroup.is_administrator = self.actions.isAdministrator;
          form.$setPristine();
        });
      }
    }
  }

  /* End MembersActionsController */

  function PermissionsHighlightDirective($timeout) {
    var childrenCls = ".filepermissions-table__icon-container";
    var beActiveCls = "be-active";
    var beNotActiveCls = "be-not-active";
    var activeClass = "active";

    function highlightSiblings(elem, direction, siblingCls) {
      var selector =
        direction == "prev" ? "previousElementSibling" : "nextElementSibling";
      var sibling = elem.parentElement[selector];
      if (!sibling) {
        return;
      }
      elem = sibling.querySelector(childrenCls);
      if (!elem) {
        return;
      }
      angular.element(elem).addClass(siblingCls);
      return highlightSiblings(elem, direction, siblingCls);
    }

    function isElementDisabled(elem) {
      // item state can be temporary changed to disabled
      return elem.classList.contains("disabled");
    }

    return {
      scope: {
        ignoreDisabled: "<drPermissionsHighlight",
      },
      link: function ($scope, $elem) {
        childrenCls =
          childrenCls + ($scope.ignoreDisabled ? "" : ":not(.disabled)");

        function initClick() {
          var children = $elem.find(childrenCls);
          children.each(function (i, ch) {
            ch.onmouseenter = function () {
              if (isElementDisabled(this)) {
                return;
              }
              // color the follow siblings to gray
              highlightSiblings(this, "next", beNotActiveCls);
              // color the prev siblings to blue
              highlightSiblings(this, "prev", beActiveCls);
              // this element toggle its color woth :hover css style
            };
            ch.onmouseleave = function () {
              // remove all tmp classes
              children.removeClass(beNotActiveCls);
              children.removeClass(beActiveCls);
            };
            ch.onclick = function () {
              if (isElementDisabled(this)) {
                return;
              }
              // color the prev siblings to blue
              highlightSiblings(this, "prev", activeClass);
              // and togle elem original color
              angular.element(this).toggleClass(activeClass);
            };
          });
        }

        $timeout(initClick);
      },
    };
  }
  /* End PermissionsHighlightDirective */

  function drFixedColumnsPermissionTable($timeout) {
    return {
      restrict: "A",
      link: function (scope, element) {
        const table = element;
        let styleElement;

        initStyleElement();
        scope.$on("$destroy", function () {
          $(styleElement).remove();
          table.off("scroll.dr-fixed-column");
        });

        function initStyleElement() {
          styleElement = document.createElement("style");
          styleElement.type = "text/css";
          document.getElementsByTagName("head")[0].appendChild(styleElement);
        }

        function activate() {
          const fixedColumnClass = ".fixed-cols-table__column";
          const headerRow = table.find(".flex-table__row--header");
          const fixedColumns = table
            .find(`.flex-table__row--header ${fixedColumnClass}`)
            .toArray();

          table.off("scroll.dr-fixed-column");

          table.on("scroll.dr-fixed-column", () => {
            const scrollX = table[0].scrollLeft;
            const scrollY = table[0].scrollTop;
            // keep header always visible
            headerRow.css("transform", `translate(0, ${scrollY}px)`);
            // change stylesheet so fixed columns are always visible
            styleElement.innerHTML = fixedColumns
              .map((el, index, columns) => {
                const border =
                  index === columns.length - 1
                    ? "border-right: 1px solid #d9dadc"
                    : "";

                return `${fixedColumnClass}-${index} {transform: translate(${scrollX}px, 0); ${border}}`;
              })
              .join("\n");
          });
        }

        $timeout(function () {
          activate();
        }, 0);
      },
    };
  }

  function drViewPermissionsIco() {
    return {
      template: icons_viewPermissionsHtml,
      bindings: {
        active: "<",
        propagated: "<",
        disabled: "<",
        processingError: "<",
      },
    };
  }

  function drDownloadWatermarkedPermissionsIco() {
    return {
      template: icons_downloadWatermarkedPermissionsHtml,
      bindings: {
        active: "<",
        propagated: "<",
        disabled: "<",
        processingError: "<",
      },
    };
  }

  function drDownloadOriginalPermissionsIco() {
    return {
      template: icons_downloadOriginalPermissionsHtml,
      bindings: {
        active: "<",
        propagated: "<",
        disabled: "<",
      },
    };
  }

  function drEditPermissionsIco() {
    return {
      template: icons_editPermissionsHtml,
      bindings: {
        active: "<",
        propagated: "<",
        disabled: "<",
      },
    };
  }

  function drCombinedPermissionsIco() {
    return {
      template: icons_combinedPermissionIcoHtml,
      bindings: {
        setPermission: "<",
        permissions: "<",
        propagated: "<",
        disabled: "<",
        groupId: "<",
        item: "<",
      },
    };
  }
})(angular);
