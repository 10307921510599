<template>
  <div>
    <div class="dash-content-panel-info__item-label">Email</div>
    <div class="dash-content-panel-info__item-value">
      <DrTruncatedTextTooltip :content="item.email">
        <a :href="'mailto:' + item.email" target="_blank">
          <i class="fas fa-envelope dash-content-panel-info__item-icon" />
          {{ item.email }}
        </a>
      </DrTruncatedTextTooltip>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { PropType } from "vue";

export default defineComponent({
  name: "ViewUserEmailField",
  components: {
    DrTruncatedTextTooltip,
  },
  props: {
    item: { required: true, type: Object as PropType<OrgUser> },
  },
});
</script>
