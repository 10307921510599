import { h } from "vue";

import ActionsCell from "./cells/ActionsCell.vue";
import TitleCell from "./cells/TitleCell.vue";

import type { FindingStatusesTableRow } from "./types";
import type { DrVxeTableColumn } from "@drVue/components/types";

export type ColumnsHandlers = {
  onEdit: (id: FindingStatusesTableRow["id"]) => void;
  onRemove: (id: FindingStatusesTableRow["id"]) => void;
};

export default class TableColumns {
  constructor(private handlers: ColumnsHandlers) {}

  get columns(): DrVxeTableColumn<FindingStatusesTableRow>[] {
    return [this.nameColumn(), this.typeColumn(), this.actionsCell()];
  }

  private nameColumn(): DrVxeTableColumn<FindingStatusesTableRow> {
    return {
      field: "name",
      title: "Status name",
      minWidth: 100,
      width: 200,
      slots: {
        default: ({ row }) => {
          return [h(TitleCell, { name: row.name, color: row.color })];
        },
      },
    };
  }

  private typeColumn(): DrVxeTableColumn<FindingStatusesTableRow> {
    return {
      field: "typeLabel",
      title: "Type",
      minWidth: 100,
      width: 200,
    };
  }

  private actionsCell(): DrVxeTableColumn<FindingStatusesTableRow> {
    return {
      field: "",
      title: "",
      slots: {
        default: ({ row }) => [
          h(ActionsCell, {
            findingStatusId: row.id,
            onEdit: this.handlers.onEdit,
            onRemove: this.handlers.onRemove,
          }),
        ],
      },
    };
  }
}
