import { computed, ref, watch } from "vue";

import { DealGroupsApiService } from "@drVue/api-service/modules/deal-groups";
import { createDictionary } from "@drVue/common";

import type { GroupWithAccess } from "@drVue/api-service/modules/deal-groups/types";
import type { ComputedRef } from "vue";

type DealGroupWithAccess = GroupWithAccess;

export type { DealGroupWithAccess };

type DealId = number;

const $api = new DealGroupsApiService();

export const useDealGroups = (dealId: ComputedRef<DealId>) => {
  const list = ref<DealGroupWithAccess[]>([]);
  const isLoading = ref(false);
  const isLoadError = ref(false);

  const dict = computed(() =>
    list.value.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, createDictionary<DealGroupWithAccess>()),
  );

  const listOfAccepted = computed(() =>
    list.value
      .map((group) => {
        const filteredGroup = { ...group };
        filteredGroup.members = filteredGroup.members.filter(
          (member) => !member.pending,
        );
        return filteredGroup;
      })
      .filter((group) => !!group.members.length),
  );

  const load = async () => {
    if (!dealId.value) {
      list.value = [];
      return [];
    }

    isLoading.value = true;
    isLoadError.value = false;

    try {
      list.value = await $api.loadGroupsWithAccess(dealId.value);
      isLoading.value = false;
      return list.value;
    } catch (err) {
      isLoading.value = false;
      isLoadError.value = true;
      throw err;
    }
  };

  watch(dealId, load, { immediate: true });

  return {
    list,
    dict,
    isLoading,
    isLoadError,
    load,
    listOfAccepted,
  };
};
