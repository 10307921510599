import type { SubscriptionPlanTypes } from "./enums";
import type {
  OrganizationGroupDealAccessType,
  TaskFieldAccessType,
} from "./enums";
import type { OrganizationMemberLookerAccess, PaymentTypes } from "./enums";
import type { AiAccess } from "@app/setups/enums";
import type { FieldItem } from "@drVue/api-service/client-dashboard";
import type { FindingStatus } from "@drVue/api-service/modules/findings-statuses/types";
import type { FindingType } from "@drVue/api-service/modules/findings-types/types";
import type { TaskStatus } from "@drVue/store/modules/room/tasks-statuses/types";
import type { CustomView } from "@setups/types";

export interface Data {
  [key: string]: any;
}

export interface WebsiteData {
  ENV: string;
  NAME: string;
  HOST: string;
  URL: string;
  DEFAULT_SUBSCRIPTION: number;
  OPEN_ROOM_CREATE: boolean;
  IS_DEALROOM: boolean;
  LOGO_SVG: string;
}

export interface SentrySettings {
  dsn: string;
  release: string;
}

export interface SettingsData {
  STATIC_URL: string;
  CSRF_COOKIE_NAME: string;
  WEBSITE: WebsiteData;
  CONTACT_EMAIL: string;
  SALES_EMAIL: string;
  STRIPE_PUBLIC_KEY: string;
  PENDO_SITE_KEY: string | null;
  CRISP_WEBSITE_ID: string | null;
  PAYMENT_TYPES: PaymentTypes;
  DEBUG: boolean;
  SENTRY: SentrySettings;
}

export interface ClientData {
  id: number;
  uid: string;
  public_name: string;
  url: string;
  enable_bulk_invites: boolean;
  enable_dashboard: boolean;
  enable_lists_tabs: boolean;
  enable_domain: boolean;
  enable_public_api: boolean;
  enable_looker: boolean;
  enable_documents_ocr: boolean;
  enable_outlook: boolean;
  enable_synergies: boolean;
  enable_deal_room_admin_permission: boolean;
  enable_deal_tasks: boolean;
  enable_task_custom_fields: boolean;
  enable_findings: boolean;
  enable_finding_custom_fields: boolean;
  enable_ai: boolean;
  is_ai_tos_accepted: boolean;
  domain: string;
  require_2fa: boolean;
  require_sso: boolean;
  sso_allowed_domain: string;
  bg_color: string;
  show_billing: boolean;
  custom_fields: any;
  enabled_openid_providers: any[];
  room_phases: any[];
}

export interface DealScopeCriteria {
  op_type: "AND";
  operands: {
    op_type: "FIELD_ANY_OF";
    field_key: "phase" | string; // custom field string uid
    values: string[];
  }[];
}

export interface OrganizationGroupData {
  id: number;
  uid: string;
  name: string;
  is_administrator: boolean;
  deals_access_type: OrganizationGroupDealAccessType;
  can_create_deals: boolean;
  can_create_rooms: boolean;
  can_edit_deals: boolean;
  deals_scope_criteria: null | DealScopeCriteria;
}

export interface OrganizationMemberData {
  id: number;
  group: OrganizationGroupData;
  has_billing_access: boolean;
  client: ClientData;
  user_id: string; // user_XXXX
  /**
   * @deprecated This field is confusing and should be replaced with `user_id`
   * TODO: ask BE team to remove this field after you delete this comment and the field
   */
  user: string;
}

export interface RoomGroupData {
  id: number;
  uid: string;
  name: string;
  is_administrator: boolean;
  // undefined if findings feature is disabled for the org
  is_findings_managers?: boolean;

  /**
   * task perms fields
   * undefined if tasks feature is disabled for the site
   * (task feature is enabled for DealRoom and disabled for FirmRoom)
   * for room admin these fields are always true if available
   */
  is_task_managers?: boolean;
  can_create_tasks?: boolean;
  can_delete_tasks?: boolean;
  task_custom_fields_access?: TaskFieldAccessType;
  task_start_and_due_dates_access?: TaskFieldAccessType;
  /**
   * end of task perms fields
   */
  synergies_access: TaskFieldAccessType;
  synergy_tracking_fields_access: TaskFieldAccessType;
  ai_access: AiAccess;
}

export interface CurrentRoomMemberData {
  user_id: string; // user_XXXX
  group: RoomGroupData;
}

export interface UserOrganization {
  id: number;
  public_name: string;
  url: string;
}

export interface UserData {
  id: number;
  uid: string; // global user id, gusr_XXXX
  username: string;
  email: string;
  is_anonymous: boolean;
  is_authenticated: boolean;
  profile: UserProfile;
  pgroup?: number;
  is_staff: boolean;
  logged_in_first_time: boolean;
  org_memberships: OrganizationMemberData[];
  organizations: UserOrganization[];
  crisp_verify_signature: string | null;

  // for compatibility with members list api
  initial: string;
  name: string;
  avatar?: UserAvatar;
}

export interface UserAvatar {
  reduced: string | null;
  thumbnail: string | null;
}

export interface UserProfile {
  avatar: UserAvatar;
  company: string;
  email: string;
  first_name: string;
  headline: string;
  id: number;
  initial: string;
  last_name: string;
  name: string;
  notification_document_upload: boolean;
  notification_due_date: boolean;
  notification_hour_end: number;
  notification_hour_start: number;
  notification_new_task: boolean;
  notification_new_finding: boolean;
  notification_schedule: string;
  notification_weekday: number;
  notification_email_per_room: boolean;
  notifications_room_selection_type: any;
  notifications_included_rooms: string[];
  notifications_excluded_rooms: string[];
  office_number: string;
  timezone: string;
  title: string;
  user_id: number;
}

export interface CurrentOrganizationMemberData extends OrganizationMemberData {
  looker_access: OrganizationMemberLookerAccess;
  preferences: {
    default_deals_view?: string | null;
    pinned_deals_views?: string[];
  };
}

export interface UserDashboardData {
  allow_create_new_trial_client: boolean;
  new_trial_days: number;
  is_client_specified: boolean;
}

export interface SubscriptionWarning {
  title: string;
  message: string;
  action: "open_billing";
  context: null | { [key in SubscriptionPlanTypes]: { quantity: number } };
}

export interface SynergySettings {
  enable: boolean;
  interval: "month" | "quarter";
  from_date: string;
  to_date: string;
}

export interface RoomData {
  id: number;
  uid: string;
  client_id: number;
  client: {
    id: number;
    uid: string;
    public_name: string;
  };
  subdomain?: string;
  slug: string;
  title: string;
  url: string;
  logo: string | null;
  enableListsTabs: boolean;
  userPermissions: {
    id: number;
    viewTasks: boolean;
    administrator: boolean;
    needCommentsApprove: boolean;
    canManageTasks: boolean;
    canCreateTasks: boolean;
    canDeleteTasks: boolean;
    isFindingsAccessible: boolean;
    defaultComments: boolean;
  };
  copyOperationUID?: string;
  custom_fields: FieldItem[];
  custom_views: CustomView[];
  enableAssignGroups: boolean;
  enableBulkDownload: boolean;
  enableClientSynergies: boolean;
  enableTaskListKey: boolean;
  enableFindings: boolean;
  enableFindingCustomFields: boolean;
  enableTaskCustomFields: boolean;
  isAiEnabled: boolean;
  enableNewUploadDialog: boolean;

  is_paid: boolean;
  isTrialRoom: boolean;
  subscriptionWarning?: SubscriptionWarning;
  allowSubscriptionSelfActivate?: boolean;
  taskStatuses: TaskStatus[];
  findingStatuses?: FindingStatus[];
  findingTypes?: FindingType[];
  synergySettings?: SynergySettings;
}

export interface DashboardData {
  custom_views: CustomView[];
  is_trial: boolean;
  is_paid: boolean;
  payment_type: PaymentTypes;
  subscriptionWarning: SubscriptionWarning | null;
}

export interface ManagementData {
  is_staff: boolean;
  is_superuser: boolean;
}

export interface RedirectRoom {
  TITLE: string;
  LOGO: string;
}

// initial data setup (defined in angular.html template)
export const APP_SETTINGS: SettingsData = parseDjDataJson("settings-data");
export const USER_DATA: UserData = parseDjDataJson("user-data", true);

export const ROOM_DATA: RoomData = parseDjDataJson("room-data", true);
export const MANAGEMENT_DATA: ManagementData = parseDjDataJson(
  "management-data",
  true,
);

export const ORG_MEMBER_DATA: CurrentOrganizationMemberData = parseDjDataJson(
  "org-member-data",
  true,
);

export const ROOM_MEMBER_DATA: CurrentRoomMemberData = parseDjDataJson(
  "room-member-data",
  true,
);

export const CLIENT_DASHBOARD_DATA: DashboardData = parseDjDataJson(
  "dashboard-data",
  true,
);
export const USER_DASHBOARD_DATA: UserDashboardData = parseDjDataJson(
  "user-dashboard-data",
  true,
);
export const REDIRECT_ROOM: RedirectRoom = parseDjDataJson(
  "redirect-room",
  true,
);

const ngAppEl = document.getElementById("ng-app-root");
export const NG_APP: string = (ngAppEl && ngAppEl.getAttribute("ng-app")) || "";

const csrfTokenRe = new RegExp(
  `(?:^|; )${APP_SETTINGS.CSRF_COOKIE_NAME}=([^;]*)`,
);

export function getCsrfToken(): string {
  const matches = document.cookie.match(csrfTokenRe);

  return matches ? matches[1] : "";
}

export function parseDjDataJson(elementID: string, ignoreEmpty = false): any {
  // parses injected to template data
  const element: HTMLElement | null = document.getElementById(elementID);
  if (!element) {
    if (ignoreEmpty) {
      return {};
    } else {
      throw new Error(`${elementID} can not be loaded`);
    }
  }

  return JSON.parse(element.textContent as string);
}
