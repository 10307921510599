<template>
  <DrDrawer
    size="large"
    title="Summaries overview"
    :shown="isVisible"
    no-footer
    @opened="emit('opened')"
    @close="handleClose()"
    @closed="emit('close')"
  >
    <AiDocReportsSummary :report-type-id="reportTypeId" />
  </DrDrawer>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";

import DrDrawer from "@app/vue/shared/ui/dr-drawer/DrDrawer.vue";
import AiDocReportsSummary from "./AiDocReportsSummary.vue";

import type { AiReport } from "../types";

interface Props {
  reportTypeId: AiReport["id"];
}

defineProps<Props>();

interface Emits {
  (e: "opened"): void;
  (e: "close"): void;
}

const emit = defineEmits<Emits>();

const isVisible = ref(false);

const handleClose = () => {
  isVisible.value = false;
};

onMounted(() => {
  isVisible.value = true;
});
</script>
