<template>
  <ElSelect
    :model-value="editProps.value"
    :placeholder="editProps.schema.placeholder"
    filterable
    multiple
    :filter-method="filterMethod"
    @change="handleChange"
  >
    <ElOption
      v-for="l in filteredLabels"
      :key="l.id"
      :label="l.name"
      :value="l.id"
    />
  </ElSelect>
</template>

<script setup lang="ts">
import { ref } from "vue";

import { pinia } from "@drVue/store/pinia";
import { useTasksLabelsStore } from "@drVue/store/pinia/room/tasksLabels/tasksLabels";

import type { FieldProps } from "@shared/ui/dr-dynamic-form/types";

interface Props {
  editProps: FieldProps;
}

const props = defineProps<Props>();

const handleChange = (value: number[]) => {
  props.editProps.veeField.onChange(value);
};

const tasksLabels = useTasksLabelsStore(pinia);

const filteredLabels = ref<any[]>(tasksLabels.list);
const filterMethod = (value: string) => {
  filteredLabels.value = tasksLabels.list.filter((l) =>
    l.name.toLowerCase().includes(value.toLowerCase()),
  );
};
</script>
