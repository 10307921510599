import type { IconName } from "../dr-icon";
import type { SubscriptionWarning } from "@setups/data";

export type MainMenuItem = {
  id: string;
  name: string;
  icon: IconName;
  place: "top" | "bottom" | "account";
  isActive: boolean;
  isBeta?: boolean;
  href?: string;
  hasNews?: boolean;
};

export type MainMenuRoom = {
  id: string;
  name: string;
  logo: string;
  isActive: boolean;
  url?: string;
};

export type MainMenuOrganization = {
  id: string;
  name: string;
  isActive: boolean;
  href: string;
};

export type MainMenuUser = {
  id: string;
  name: string;
  /** User's avatar image url */
  avatar: string;
};

/** Subscription details */
export type MainMenuBillingInfo = {
  isAccessible: boolean;
  isRoomBilling: boolean;
  billingObjectId: number | null;
  subscriptionWarning: SubscriptionWarning | null;
  supportEmail: string;
  hasBillingAccess: boolean;
};

export enum MainMenuItemStyle {
  Default = "default",
  Warning = "warning",
  Error = "error",
}

export enum MainMenuModalPosition {
  Top = "top",
  Bottom = "bottom",
}
