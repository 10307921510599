<template>
  <DrPopup
    paddingless
    persistent
    trigger="focus"
    @show="tasksTreeBrowserSelectRef?.focusSearchField()"
    @hide="tasksTreeBrowserSelectRef?.resetLocalValue()"
  >
    <template #reference="{ show }">
      <slot name="reference" v-bind="{ show }" />
    </template>

    <template #default="{ hide }">
      <TasksTreeBrowserSelect
        ref="tasksTreeBrowserSelectRef"
        v-model="model"
        :expand-on-click-node="expandOnClickNode"
        :only-task-value="onlyTaskValue"
        :check-strictly="checkStrictly"
        :filter-method="filterMethod"
        @reset="hide()"
        @update:model-value="hide()"
      />
    </template>
  </DrPopup>
</template>

<script lang="ts" setup>
import { type ComponentInstance, ref } from "vue";
import { DrPopup } from "@shared/ui/dr-popups";

import TasksTreeBrowserSelect from "./TasksTreeBrowserSelect.vue";

import type { Category } from "@drVue/store/pinia/room/categories/api";
import type { Task } from "@drVue/store/pinia/room/tasks";

type TreeValue = (Task["uid"] | Category["uid"])[];

type TasksBrowserInstance = ComponentInstance<typeof TasksTreeBrowserSelect>;

interface Props {
  expandOnClickNode?: boolean;
  onlyTaskValue?: boolean;
  checkStrictly?: boolean;
  filterMethod?: (task: Task) => boolean;
}

withDefaults(defineProps<Props>(), {
  expandOnClickNode: false,
  onlyTaskValue: false,
  checkStrictly: false,
  filterMethod: undefined,
});

const model = defineModel<TreeValue>({ default: [] });

const tasksTreeBrowserSelectRef = ref<TasksBrowserInstance | undefined>();

const focusSearchField: TasksBrowserInstance["focusSearchField"] = () => {
  return tasksTreeBrowserSelectRef.value?.focusSearchField();
};

const resetLocalValue: TasksBrowserInstance["resetLocalValue"] = () => {
  return tasksTreeBrowserSelectRef.value?.resetLocalValue();
};

defineExpose({
  focusSearchField,
  resetLocalValue,
});
</script>
