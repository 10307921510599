<template>
  <DrVxeGrid
    ref="grid"
    :columns="tableColumns.columns"
    :data="rows"
    :loading="loading"
    :merge-cells="mergeCells"
    @cell-click="onCellClick"
  >
    <template #empty>No requests found</template>
  </DrVxeGrid>
</template>

<script lang="ts">
import { sortBy } from "lodash-es";
import { defineComponent } from "vue";
import DrVxeGrid from "@shared/ui/dr-vxe-grid";

import { taskUrl } from "@setups/room-urls";
import TableColumns from "./tableColumns";
import { TableRowTypes } from "./types";

import type { ITableRow } from "./types";
import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";
import type {
  UserParticipantTaskCategory,
  UserParticipantTaskData,
} from "@drVue/store/modules/client-dashboard/org-users/OrgUsersApiService";
import type { PropType } from "vue";

interface Data {
  tableColumns: TableColumns;
  rows: ITableRow[];
  mergeCells: any[];
}

function sortRoomCategoriesPaths(paths: UserParticipantTaskCategory[][]) {
  paths.sort((a, b) => {
    const aOrder = a.map((c) => c.order);
    const bOrder = b.map((c) => c.order);
    const longest = Math.max(aOrder.length, bOrder.length);
    for (let i = 0; i < longest; i++) {
      const aiVal = aOrder[i];
      const biVal = bOrder[i];
      if (aiVal === undefined) {
        return -1;
      }
      if (biVal === undefined) {
        return 1;
      }
      if (aiVal !== biVal) return aiVal > biVal ? 1 : -1;
    }
    return 0;
  });
}

export default defineComponent({
  name: "DocumentsTable",
  components: {
    DrVxeGrid,
  },
  props: {
    loading: { required: true, type: Boolean as PropType<boolean> },
    tasks: {
      required: true,
      type: Array as PropType<UserParticipantTaskData[]>,
    },
  },
  data(): Data {
    return {
      tableColumns: new TableColumns(),
      rows: [],
      mergeCells: [],
    };
  },
  watch: {
    tasks() {
      this.updateRows();
    },
  },
  methods: {
    updateRows() {
      const categoryPath: { [key: number]: UserParticipantTaskCategory[] } = {};
      const tasksPerCat: { [key: number]: UserParticipantTaskData[] } = {};
      const roomCategories: { [key: number]: Set<number> } = {};

      for (const task of this.tasks) {
        const catId = task.categories[task.categories.length - 1].id;
        categoryPath[catId] = task.categories;

        if (!roomCategories[task.room_id]) {
          roomCategories[task.room_id] = new Set();
        }
        roomCategories[task.room_id].add(catId);

        if (!tasksPerCat[catId]) {
          tasksPerCat[catId] = [];
        }
        tasksPerCat[catId].push(task);
      }

      // create list of rooms with tasks
      const getDeal =
        this.$store.getters["clientDashboard/deals/getDealByRoomId"];
      const deals: Deal[] = Object.keys(roomCategories).map((roomId) => {
        return getDeal(parseInt(roomId));
      });
      sortBy(deals, ["name"]);

      // start filling rows
      // first group tasks by deals
      // then get all categories in the deal and sort by level/order
      // for each of the categories - add category itself first, then all of associated tasks
      const rows: ITableRow[] = [];
      const merges = [];

      for (const deal of deals) {
        if (!deal.room) {
          continue;
        }
        const roomCatPaths: UserParticipantTaskCategory[][] = [];
        roomCategories[deal.room.id].forEach((catId) => {
          const catPath = categoryPath[catId];
          roomCatPaths.push(catPath);
        });
        sortRoomCategoriesPaths(roomCatPaths);
        for (const catPath of roomCatPaths) {
          rows.push({
            type: TableRowTypes.Category,
            category: { deal, path: catPath },
          });
          merges.push({
            row: rows.length - 1,
            col: 0,
            rowspan: 1,
            colspan: 4,
          });

          const catId = catPath[catPath.length - 1].id;
          for (const task of tasksPerCat[catId]) {
            rows.push({
              type: TableRowTypes.Task,
              task: task,
            });
          }
        }
      }

      this.rows = rows;
      this.mergeCells = merges;
    },
    onCellClick({ row, column }: { $event: any; row: any; column: any }) {
      const k = column.getKey();
      if (k === "title") {
        const room = this.$store.getters["clientDashboard/deals/getRoomById"](
          row.task.room_id,
        );
        window.open(taskUrl(room.permalink, row.task.key), "_blank");
      }
    },
  },
});
</script>
