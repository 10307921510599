import type { TaskPredicate } from "@app/ng/tasks/services/ts/types/TaskPredicate";

export const updatedRange: TaskPredicate = (task, filters) => {
  if (!task.last_updated) return true;

  const updatedRange = filters.updatedRange;
  if (!updatedRange[0] || !updatedRange[1]) return true;

  return (
    task.last_updated >= updatedRange[0] && task.last_updated <= updatedRange[1]
  );
};
