<template>
  <div class="task-participants-list__row">
    <GroupInformation :group="group" />
    <i
      class="fa fa-times task-participants-list__remove-icon"
      @click="$emit('remove', value)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import GroupInformation from "@drVue/components/room/groups/GroupInformation.vue";

import type { ParticipantGroup } from "@drVue/store/pinia/room/tasks";
import type { PropType } from "vue";

export default defineComponent({
  name: "ParticipantGroupRow",
  components: { GroupInformation },
  props: {
    value: { required: true, type: Object as PropType<ParticipantGroup> },
  },
  emits: ["remove"],
  computed: {
    group(): any {
      const groups = this.$store.state.room.groups.pgroups;
      return groups[this.value.group_id];
    },
  },
});
</script>
