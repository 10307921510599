<template>
  <Transition
    :enter-from-class="$style.roomsPanel_enterFrom"
    :leave-to-class="$style.roomsPanel_leaveTo"
    @after-enter="focusRoomsInput"
    @after-leave="clearRoomsInput"
  >
    <div v-if="visible" :class="$style.roomsPanel">
      <div :class="$style.roomsPanelHeader">
        <DrIcon
          name="angle-left"
          size="sm"
          :class="$style.roomsPanelBackBtn"
          @click="$emit('hide')"
        />
        <div :class="$style.roomsPanelTitle">
          All rooms ({{ rooms.length }})
        </div>
        <div
          v-if="showAddBtn"
          :class="$style.roomsPanelBtn"
          @click="emit('add')"
        >
          <DrIcon size="sm" name="plus" :class="$style.icon" />
        </div>
        <ElInput
          ref="filterInputRef"
          v-model="filterInputText"
          placeholder="Search..."
          :class="$style.roomsPanelInput"
          @input="updateSearchTextByInput"
        />
      </div>

      <div :class="$style.roomsPanelBody">
        <ElScrollbar ref="scrollbar" :class="$style.roomsPanelList">
          <TransitionGroup
            type="animation"
            :enter-active-class="$style.item_enterActive"
            :leave-active-class="$style.item_leaveActive"
          >
            <a
              v-for="room in filteredRooms"
              :key="room.id"
              :class="{
                [$style.roomItem]: true,
                [$style.roomItem_isActive]: room.isActive,
              }"
              :href="room.url"
              @click.prevent="selectRoom(room)"
            >
              <div :class="$style.roomItemLogo">
                <DrLogo
                  :url="room.logo"
                  :name="room.name"
                  :class="$style.logo"
                />
              </div>

              <div :class="$style.roomItemText">
                {{ room.name }}
              </div>
            </a>

            <div v-if="!filteredRooms.length" :class="$style.noResults">
              No results found
            </div>
          </TransitionGroup>
        </ElScrollbar>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useDebounceFn } from "@vueuse/core";

import DrIcon from "../dr-icon";
import DrLogo from "../dr-logo";

import type { MainMenuRoom } from "./types";

interface Props {
  visible?: boolean;
  rooms?: MainMenuRoom[];
  showAddBtn?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  visible: false,
  rooms: () => [],
  showAddBtn: false,
});

const emit = defineEmits<{
  (e: "select", room: MainMenuRoom): void;
  (e: "hide"): void;
  (e: "add"): void;
}>();

const filterInputRef = ref();
const filterInputText = ref("");
const filterSearchText = ref(""); //separating input and search value

const updateSearchTextByInput = useDebounceFn(() => {
  filterSearchText.value = filterInputText.value;
}, 500);

const selectRoom = (room: MainMenuRoom) => {
  if (!room.isActive) {
    emit("select", room);
  }
};

const filteredRooms = computed(() => {
  if (filterSearchText.value) {
    const searchText = filterSearchText.value.toLocaleLowerCase();
    const searchTextLength = searchText.length;

    return props.rooms.filter((r) => {
      const roomName = r.name.toLocaleLowerCase();
      if (roomName.length >= searchTextLength) {
        return roomName.includes(searchText);
      } else {
        return searchText.includes(roomName);
      }
    });
  }

  return props.rooms;
});

const focusRoomsInput = () => {
  filterInputRef.value?.focus();
};
const clearRoomsInput = () => {
  filterInputText.value = "";
  filterSearchText.value = "";
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography" as typo;

$space: 8px;
$space-sm: 4px;
$space-lg: 12px;
$space-dbl: 16px;

$panel-bg: colors.$sc-1000;
$panel-bg_dark: colors.$sc-1100;
$panel-zindex: 1001;
$transition-function: ease-in-out;
$transition-duration: 200ms;

$item-radius: 8px;
$item-size: 32px;
$input-radius: 7px;

.noResults {
  height: $item-size;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: colors.$pr-350;
  font: typo.$body_regular;
}

.roomItem {
  --item-color: #{colors.$pr-350};
  --item-hover-color: #{colors.$pr-0};
  --item-active-color: #{colors.$pr-0};
  --item-bg: #{rgb(colors.$ad-hover-dark, 0%)};
  --item-hover-bg: #{rgb(colors.$ad-hover-dark, 100%)};
  --item-active-bg: #{colors.$sc-600};

  box-sizing: border-box;
  height: $item-size;
  display: grid;
  grid-template-columns: $item-size 1fr;
  align-items: center;
  overflow: hidden;
  border-radius: $item-radius;
  color: var(--item-color);
  font: typo.$body_regular;
  background-color: var(--item-bg);
  cursor: pointer;

  transition-timing-function: ease-out;
  transition-duration: 200ms;
  transition-property: background-color, color;
}

.roomItem_isActive {
  font: typo.$body_semibold;
}

.roomItemLogo {
  width: $item-size;
  height: $item-size;
  display: flex;
  align-items: center;
  justify-content: center;
  color: colors.$pr-500;
}

.roomItemText {
  overflow: hidden;
  padding-left: 2px;
  padding-right: $space;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.roomItem:hover,
.roomItem_isActive {
  .logo {
    color: inherit;
  }
}

.roomItem:hover {
  color: var(--item-hover-color);
  background-color: var(--item-hover-bg);
}

.roomItem_isActive,
.roomItem_isActive:hover {
  color: var(--item-active-color);
  background-color: var(--item-active-bg);
}

@keyframes itemEnter {
  0% {
    opacity: 0;
    height: 0;
    transform: translateX(30px);
  }

  50% {
    opacity: 0;
    height: $item-size;
    transform: translateX(30px);
  }

  100% {
    opacity: 1;
    height: $item-size;
    transform: translateX(0);
  }
}

.item_enterActive {
  animation: itemEnter 0.3s $transition-function;
}
.item_leaveActive {
  animation: itemEnter 0.3s $transition-function reverse;
}

.roomsPanel {
  position: absolute;
  inset: 0;
  z-index: 200;
  display: grid;
  grid-template-rows: min-content 1fr;
  background-color: $panel-bg;
  transition-property: transform, opacity;
  transition-timing-function: $transition-function;
  transition-duration: $transition-duration;
}
.roomsPanel_enterFrom,
.roomsPanel_leaveTo {
  opacity: 0.7;
  transform: translateX(-100%);
}

.roomsPanelHeader {
  display: grid;
  gap: $space;
  grid-template-columns: min-content 1fr;
  grid-template-areas:
    "icon title btn"
    "input input input";
  padding: $space-lg $space-lg $space-dbl;
  align-items: center;
  color: #fff;
  background-color: $panel-bg_dark;
  border-bottom: solid 1px rgb(colors.$sc-50, 12%);
}

.roomsPanelBody {
  overflow-y: auto;
}

.roomsPanelList {
  padding: $space $space 0;
}

.roomsPanelBackBtn {
  grid-area: icon;
  opacity: 0.4;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.roomsPanelBtn {
  grid-area: btn;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid colors.$pr-700;
  border-radius: 4px;
  color: colors.$pr-500;
  cursor: pointer;
  transition-timing-function: $transition-function;
  transition-duration: $transition-duration;
  transition-property: background-color, color;

  &:hover {
    color: colors.$pr-900;
    background-color: colors.$pr-0;
  }
}

.roomsPanelTitle {
  grid-area: title;
  font: typo.$body_semibold;
}

.roomsPanelInput {
  --el-input-text-color: #{colors.$pr-0};
  --el-input-placeholder-color: #{colors.$pr-400};
  --el-input-hover-border-color: #{colors.$pr-400};
  --el-input-bg-color: transparent;
  --el-input-border-radius: #{$input-radius};

  grid-area: input;
  font: typo.$body_regular;
  background-color: transparent;

  :global {
    .el-input__wrapper {
      color: var(--el-input-text-color);
      box-shadow: 0 0 0 1px colors.$pr-700 inset !important;

      &:hover,
      &:focus-within {
        box-shadow: 0 0 0 1px var(--el-input-hover-border-color) inset !important;
      }
    }
  }
}
</style>
