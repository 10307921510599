import * as d3 from "d3";

angular
  .module("dealroom.analytics.linechart.service.config", [
    "dealroom.analytics.service.colors-scheme",
  ])
  .service("AnalyticsLinechartConfigService", ConfigService);

const STROKE_WIDTH = 1.5;

function ConfigService() {
  const { XAXIS, YAXIS, SHADOW } = getParams();
  return {
    XAXIS,
    YAXIS,
    SHADOW,
    getDefaultData,
    getCircleRadius,

    STROKE_WIDTH,
    MIN_OPACITY: 0.4,

    HOVER_TIMEOUT: 400,
    ZERO: 1.1,
  };
}

function getCircleRadius(daysCount) {
  const CIRCLE_SIZE_RANGE = [7, 3 + STROKE_WIDTH];
  const DAYS_DOMAIN = [7, 300];
  const CIRCLE_SIZE_SCALE = d3
    .scaleLog()
    .domain(DAYS_DOMAIN)
    .range(CIRCLE_SIZE_RANGE);

  daysCount = Math.max(DAYS_DOMAIN[0], daysCount);
  const r = CIRCLE_SIZE_SCALE(daysCount);
  return Math.max(r, CIRCLE_SIZE_RANGE[1]);
}

function getDefaultData() {
  const DEFAULT_DAYS_COUNT = 7;
  const DEFAULT_LINES_COUNT = 4;

  return d3.range(DEFAULT_LINES_COUNT).map((i) => ({
    total: null,
    ticks: getTicks(),
  }));

  function getTicks() {
    const startDate = new Date();
    const endDate = d3.timeDay.offset(startDate, DEFAULT_DAYS_COUNT);
    return d3.timeDay
      .range(startDate, endDate)
      .map((dt) => ({ dt, value: null }));
  }
}

function getParams() {
  const YAXIS = {
    height: 200,
    ticks: 4,
  };
  const XAXIS = {
    name: {
      height: 13,
      top: 3,
    },
    padding: 25,
  };
  XAXIS.name.full = XAXIS.name.height + XAXIS.name.top;

  const SHADOW = {
    top: 3,
    left: 3,
    dev: 3,
    op: 0.5,
    w: 180,
  };
  SHADOW.marginTop = (SHADOW.top * SHADOW.w) / 100;
  return { YAXIS, XAXIS, SHADOW };
}
