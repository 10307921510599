import { onBeforeUnmount, watch } from "vue";

import type { Ref } from "vue";
import type { VxeGridInstance } from "vxe-table";

const VAR_SCROLL_LEFT = "--dr-grid-scroll-left";
const VAR_OVERFLOW_RIGHT = "--dr-grid-overflow-right";

export const useGridVars = (gridRef: Ref<VxeGridInstance | null>) => {
  let resizeObserver: ResizeObserver | null = null;

  let scroller: HTMLElement | null = null;
  let container: HTMLElement | null = null;

  const updateGridVars = () => {
    if (!scroller || !container) return;

    container.style.setProperty(VAR_SCROLL_LEFT, `${scroller.scrollLeft}px`);
    container.style.setProperty(
      VAR_OVERFLOW_RIGHT,
      `${scroller.scrollWidth - scroller.clientWidth}px`,
    );
  };

  const destroy = () => {
    if (resizeObserver) {
      resizeObserver.disconnect();
      resizeObserver = null;
    }

    if (scroller) {
      scroller.removeEventListener("scroll", updateGridVars);
      scroller = null;
    }

    if (container) {
      container.style.removeProperty(VAR_SCROLL_LEFT);
      container.style.removeProperty(VAR_OVERFLOW_RIGHT);
      container = null;
    }
  };

  watch(gridRef, (newGrid, oldGrid) => {
    if (newGrid === null || newGrid === undefined) {
      destroy();
      return;
    }

    if (newGrid !== oldGrid) destroy();

    container = newGrid.$el.querySelector(".vxe-table--main-wrapper");
    scroller = newGrid.$el.querySelector(
      ".vxe-table--body-wrapper.body--wrapper",
    );

    if (!scroller || !container) return;

    scroller.addEventListener("scroll", updateGridVars, { passive: true });

    resizeObserver = new ResizeObserver(updateGridVars);
    resizeObserver.observe(scroller);
  });

  onBeforeUnmount(destroy);

  return updateGridVars;
};
