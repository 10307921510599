import { createApp } from "vue";

import { initSentry } from "@drVue/components/initSentry";
import App from "./UserDashboardApp.vue";

export function createUserDashboardApp() {
  const app = createApp(App);
  initSentry(app, null);
  return app;
}
