<template>
  <DrItemListItem
    :class="$style.wrapper"
    :allow-remove="!disallowRemove"
    @remove="$emit('remove', attachment)"
  >
    <a
      :href="attachmentUrl"
      :class="$style.container"
      draggable="false"
      target="_blank"
    >
      <i
        v-if="attachment.type === 'file'"
        class="mimetypes"
        :class="[$style.icon, getIconClass(attachment.mimetype)]"
      />
      <DrIcon
        v-else-if="attachment.type === 'folder'"
        :class="[$style.icon, $style.folderIcon]"
        name="folder"
      />
      <DrTruncatedTextTooltip :content="attachment.name">
        <span :class="$style.name">
          {{ attachment.name }}
        </span>
      </DrTruncatedTextTooltip>
      <div :class="$style.right">
        <div :class="$style.actions">
          <DownloadDocumentButton
            v-if="attachment.type === 'file'"
            :download-original="attachment.download_original"
            :document-id="attachment.id"
          >
            <template #default="{ openDialog }">
              <DrIcon
                name="download"
                :class="$style.actionsIcon"
                @click.stop.prevent="openDialog"
              />
            </template>
          </DownloadDocumentButton>
        </div>
        <div :class="$style.dateAdded">{{ formattedDateAdded }}</div>
      </div>
    </a>
  </DrItemListItem>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import DrIcon from "@shared/ui/dr-icon";
import { DrItemListItem } from "@shared/ui/dr-item-list";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import { ROOM_DATA } from "@setups/data";
import { documentViewUrl, folderUrl } from "@setups/room-urls";
import getIconClass from "@app/common/mimetype";
import DownloadDocumentButton from "@drVue/components/room/viewer/DownloadDocumentButton.vue";
import { drUserTime } from "@drVue/filters/drUserTime";
import { DOC_ITEM_TYPES } from "@drVue/store/modules/room/documents/DocumentsApiService";

import type { Attachment } from "./types";

interface Props {
  attachment: Attachment;
  disallowRemove: boolean;
}

interface Emits {
  (e: "remove", attachment: Attachment): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const formattedDateAdded = computed(() =>
  drUserTime(props.attachment.dateCreated),
);

const attachmentUrl = computed(() =>
  props.attachment.type === DOC_ITEM_TYPES.Folder
    ? folderUrl(ROOM_DATA.url, props.attachment.id)
    : documentViewUrl(ROOM_DATA.url, props.attachment.id),
);
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";
@use "@app/styles/scss/spacing";

.wrapper:hover {
  .actions {
    display: flex;
  }

  .dateAdded {
    display: none;
  }
}

.container {
  text-decoration: none !important;
  height: 32px;
  width: 100%;
  display: grid;
  grid-template-columns: 32px auto min-content;
  align-items: center;
  margin-left: -6px;
}

.name {
  font: typography.$body_regular;
  color: colors.$pr-900;
}

.icon {
  scale: 0.8;
  place-self: center;
}

.folderIcon {
  scale: unset;
  color: colors.$pr-400;
}

.right {
  white-space: nowrap;
}

.actions {
  display: none;
  color: colors.$pr-500;
}

.actionsIcon:hover {
  color: colors.$pr-900;
}

.dateAdded {
  font: typography.$caption_regular;
  color: colors.$pr-400;
}
</style>
