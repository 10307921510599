(function () {
  "use strict";
  var moduleTableSortDirective = angular.module(
    "dealroom.table-sort.directive",
    ["dealroom.common"],
  );

  moduleTableSortDirective.directive("drTableSort", [
    function () {
      return {
        scope: {
          drTableSortCallback: "&",
          drTableSortInitial: "=",
          drTableSortReset: "<",
        },
        bindToController: true,
        controllerAs: "ctrl",
        controller: [
          "$scope",
          "drSafeApply",
          function ($scope, drSafeApply) {
            var self = this;
            var headings = [];
            const criteriaHeading = {};

            const defaultOrder =
              self.drTableSortInitial && angular.copy(self.drTableSortInitial);
            let currentOrder = defaultOrder;

            self.registerHeading = function (criteria, headingElement) {
              criteriaHeading[criteria] = headingElement;
              var txt = headingElement.text(),
                pos =
                  headingElement.parents("thead").height() / 2 -
                  headingElement.find("fas") / 2;
              headingElement.html(
                '<span class="dr-tablesort-header">' + txt + "</span>",
              );
              headingElement.append(
                '<i class="fas dr-tablesort-direction" style="top:' +
                  pos +
                  'px"></i>',
              );
              headings.push(headingElement);
            };

            self.setOrder = function (element, criteria, reverse) {
              reverse = angular.isDefined(reverse)
                ? reverse
                : element.hasClass("tablesort-asc");
              // disabled revert on all elements
              for (var i = 0; i < headings.length; i++) {
                headings[i]
                  .removeClass("tablesort-desc")
                  .removeClass("tablesort-asc");
                headings[i]
                  .find(".fas")
                  .removeClass("fa-caret-down")
                  .removeClass("fa-caret-up");
              }
              // sort on the first click, the opposite direction on the second click,
              // then reset to drTableSortInitial order on the third click
              if (
                currentOrder &&
                currentOrder.by === criteria &&
                currentOrder.reversed &&
                !reverse &&
                self.drTableSortReset &&
                defaultOrder
              ) {
                criteria = defaultOrder.by;
                reverse = defaultOrder.reversed;
                element = criteriaHeading[criteria];
              }

              if (element) {
                // might became undefined on order reset
                // show sort in column element
                var sortClass = reverse ? "tablesort-desc" : "tablesort-asc";
                element.addClass(sortClass);
                var elDirection = element.find(".fas");
                elDirection.toggleClass("fa-caret-up", !reverse);
                elDirection.toggleClass("fa-caret-down", reverse);
              }

              // fire callback
              currentOrder = {
                by: criteria,
                reversed: reverse,
              };
              drSafeApply.apply(function () {
                self.drTableSortCallback({ order: angular.copy(currentOrder) });
              });
            };
          },
        ],
      };
    },
  ]);

  moduleTableSortDirective.directive("drTableSortCriteria", function () {
    return {
      require: "^drTableSort",
      link: function (scope, element, attrs, drTableSortCtrl) {
        var criteria = attrs.drTableSortCriteria;
        drTableSortCtrl.registerHeading(criteria, element);
        var clickingCallback = function (event) {
          drTableSortCtrl.setOrder(element, criteria);
        };
        element.bind("click", clickingCallback);
        element.addClass("dr-tablesort-sortable");
        if ("drTableSortDefault" in attrs) {
          drTableSortCtrl.setOrder(
            element,
            criteria,
            "drTableSortAsc" in attrs,
          );
        } else if (
          drTableSortCtrl.drTableSortInitial &&
          drTableSortCtrl.drTableSortInitial.by == criteria
        ) {
          drTableSortCtrl.setOrder(
            element,
            criteria,
            drTableSortCtrl.drTableSortInitial.reversed,
          );
        }
      },
    };
  });
})();
