<template>
  <div>
    <div v-if="!chartData.length" class="dash-rooms-timeline__no_rooms">
      <span> No deals found </span>
    </div>
    <div ref="chartContainer" class="dash-rooms-timeline__chart_wrapper" />
  </div>
</template>

<script lang="ts">
import { endOfDay, isDate, parse } from "date-fns";
import { defineComponent } from "vue";

import { ZOOM } from "@setups/types";
import DrStore from "@drVue/store";
import { TimelineChart } from "./chart";

import type { DealData } from "./chart";
import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";
import type { ZoomLevel } from "@setups/types";

interface Data {
  chart: TimelineChart | null;
  showChartSidebar: boolean;
}

export default defineComponent({
  name: "TimelineChartComp",
  data(): Data {
    return {
      chart: null,
      showChartSidebar: false,
    };
  },
  computed: {
    zoomLevel(): ZoomLevel {
      return (
        this.$store.state.common.customViews.current.settings.timelineZoom ||
        ZOOM.FitToScreen
      );
    },
    chartData(): DealData[] {
      let deals = DrStore.state.clientDashboard.deals.items;
      deals = DrStore.getters["clientDashboard/filters/filterDeals"](deals);
      const getPhase = DrStore.getters["clientDashboard/phases/getPhaseById"];
      return deals.map((deal) => {
        return {
          id: deal.id,
          title: deal.title,
          from: deal.start_date,
          to: this.getEndDate(deal),
          logo: deal.room?.logo,
          color: getPhase(deal.phase).color,
        };
      });
    },
    redrawChanged() {
      // run once for all handlers
      const { chartData, zoomLevel } = this as any;
      return { chartData, zoomLevel };
    },
  },
  watch: {
    redrawChanged() {
      this.redraw();
    },
  },
  mounted() {
    window.addEventListener("resize", this.redraw);
    this.redraw();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.redraw);
  },
  methods: {
    onSidebarToggle() {
      this.redraw();
    },
    getEndDate(deal: Deal) {
      // fixme: getRoomEndDateFieldKey -> getDealEndDateFieldKey
      const key =
        DrStore.getters["clientDashboard/settings/getRoomEndDateFieldKey"]();
      if (key && deal.custom_data[key]) {
        const date = isDate(deal.custom_data[key])
          ? deal.custom_data[key]
          : parse(deal.custom_data[key], "yyyy-MM-dd", new Date());

        return endOfDay(date);
      }

      return null;
    },
    openDeal(rowData: any) {
      const deal = DrStore.getters["clientDashboard/deals/getDealById"](
        rowData.id,
      );
      if (deal) {
        this.$router.push(`/deals/details/${deal.uid}`);
      }
    },
    toggleChartSidebar() {
      this.showChartSidebar = !this.showChartSidebar;
      this.redraw();
    },
    redraw() {
      const chartContainer = this.$refs.chartContainer as HTMLElement;
      let scrollPosition;
      if (this.chart) {
        scrollPosition = this.chart.getScrollPosition();
        this.chart.destroy();
        this.chart = null;
      }
      if (!this.chartData.length) {
        return;
      }

      this.chart = new TimelineChart(this.chartData, chartContainer, {
        zoomLevel: this.zoomLevel,
        showSidebar: this.showChartSidebar,
      });
      this.chart.on("itemClick", this.openDeal);
      this.chart.on("toggleSidebar", this.toggleChartSidebar);
      if (scrollPosition) {
        this.chart.setScrollPosition(scrollPosition.top, scrollPosition.left);
      }
    },
  },
});
</script>
