import type { AsyncOperationStatuses } from "@app/vue/store/modules/room/user-async-operation/AsyncOperationApiService";

export type DocVerQaID = `dvqa_${string}`;

export interface DocVerQAReview {
  status: "LIKE" | "DISLIKE";
  comment: string;
}

export const DocVerQAStatuses = {
  PENDING: "pending",
  PROCESSING: "processing",
  SUCCESS: "success",
  FAIL: "fail",
} as const;

export interface AiExcerpt {
  page: number;
  snippet: string;
}

export interface AiValue {
  shortAnswer?: string;
  explanation?: string;
  excerpts?: AiExcerpt[];
  isError?: boolean;
}

export interface DocVerQA {
  id: DocVerQaID;
  author_id: string;
  date_created: Date;
  question: string;
  answer: string;
  review: DocVerQAReview | null;
  status: (typeof DocVerQAStatuses)[keyof typeof DocVerQAStatuses];
}

export interface AiTemplate {
  id: string;
  author_id: string;
  date_created: string; // "2024-07-17T15:14:44.067Z"
  question: string;
}

export interface AiReportField {
  id: string;
  author_id: string;
  date_created: Date;
  prompt_template_hash: string;
  label: string;
  prompt_template: string;
  prompt_context?: {
    answer_definition: string | null;
  };
}

export interface AiReport {
  id: string;
  label: string;
  author_id: string;
  date_created: Date;
  fields: AiReportField[];
}

export type AiReportEditable = Partial<Pick<AiReport, "label">>;

export interface AiDocReportField {
  id: `rttf_${string}`;
  author_id: string;
  report_type_template_field_id: AiReportField["id"];
  date_created: Date;
  prompt_template_hash: string;
  value: AiValue;
  status: AsyncOperationStatuses;
  review_status: "LIKE" | "DISLIKE" | null;
}

export interface AiDocReport {
  id: string;
  author_id: string;
  date_created: Date;
  fields: AiDocReportField[];
  report_type_template_id: string;
  document_id: number;
}

export interface AiDocReportSummary {
  id: `rtfs_${string}`;
  report_type_template_field_id: AiDocReportField["id"];
  text: string;
  status: AsyncOperationStatuses;
}

export type TabIds = "Chat" | "Templates" | "Reports" | "AI Dev";

export const DEFAULT_TAB = "Reports" as const satisfies TabIds;

export type AiReportSuggestion = {
  label: string;
  key: string;
  fields: { label: string }[];
};
