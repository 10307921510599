<template>
  <DrLayoutContentCenteredSection title="Request statuses">
    <template #description>
      Set request statuses to organize your team's workflow in rooms of your
      organization.
    </template>
    <template #title-right>
      <ElButton type="primary" @click="openCreateModal">
        <span>New status</span>
        <i class="far fa-plus" />
      </ElButton>
    </template>
    <div>
      <TaskStatuses :class="$style.content" />
      <TaskStatusesCreateOrEditModal ref="modal" />
    </div>
  </DrLayoutContentCenteredSection>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { DrLayoutContentCenteredSection } from "@shared/ui/dr-layouts";

import TaskStatuses from "@drVue/components/room/settings/TaskStatuses.vue";
import TaskStatusesCreateOrEditModal from "@drVue/components/room/settings/TaskStatusesCreateOrEditModal.vue";

const modal = ref<InstanceType<typeof TaskStatusesCreateOrEditModal> | null>(
  null,
);

const openCreateModal = () => {
  modal.value?.openCreateModal();
};
</script>

<style module lang="scss">
.content {
  min-height: 225px;
}
</style>
