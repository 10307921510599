<template>
  <ElButton
    :loading="isLoadingExportReports"
    :disabled="reportTypeId === null || disabled"
    @click="exportReports"
  >
    <DrIcon name="download" :class="$style.buttonIcon" />
    Export
  </ElButton>
</template>

<script lang="ts" setup>
import { ElButton } from "element-plus";
import { ref } from "vue";

import { AiReportsEvent, insightTrack } from "@app/insight";
import DrIcon from "@app/vue/shared/ui/dr-icon/DrIcon.vue";
import { $notifyDanger, dowloadFile } from "@drVue/common";
import { AiReportsApiService } from "@drVue/components/room/ai/api";

import type { AiReport } from "@drVue/components/room/ai/types";

interface Props {
  reportTypeId: AiReport["id"] | null;
  disabled?: boolean;
}

const props = defineProps<Props>();

const api = new AiReportsApiService();

const isLoadingExportReports = ref(false);

const exportReports = () => {
  if (props.reportTypeId === null) return;

  isLoadingExportReports.value = true;

  insightTrack(AiReportsEvent.ReportsExport);

  api
    .exportReports(props.reportTypeId)
    .then((response) => {
      dowloadFile(response.file, response.filename);
    })
    .catch(() => {
      $notifyDanger("Failed to export reports");
    })
    .finally(() => {
      isLoadingExportReports.value = false;
    });
};
</script>

<style module lang="scss">
.buttonIcon {
  margin-right: 8px;
}
</style>
