import { h } from "vue";

import PhaseCell from "@drVue/components/client-dashboard/deals/DealsTable/cells/PhaseCell.vue";
import { drUserTime } from "@drVue/filters/drUserTime";
import DrStore from "@drVue/store";
import MenuDealCell from "./MenuDealCell.vue";

import type { DrVxeTableColumn } from "@drVue/components/types";
import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";

interface Handlers {
  onRestore: (deal: Deal) => void;
  onDelete: (deal: Deal) => void;
}

export default class TableColumns {
  handlers: Handlers;

  constructor(handlers: Handlers) {
    this.handlers = handlers;
  }

  get columns(): DrVxeTableColumn[] {
    const columns: DrVxeTableColumn[] = [
      this.titleColumn(),
      this.phaseColumn(),
      this.startDateColumn(),
      this.menuColumn(),
    ];

    // make all columns sortable and restore saved config
    columns.forEach((column) => {
      column.sortable = true;
    });

    return columns;
  }

  private titleColumn(): DrVxeTableColumn {
    return {
      field: "title",
      title: "Title",
      fixed: "left",
      minWidth: 150,
      width: 150,
    };
  }

  private phaseColumn(): DrVxeTableColumn {
    const phaseById = DrStore.getters["clientDashboard/phases/getPhaseById"];
    return {
      field: "phase",
      title: "Phase",
      width: 140,
      sortBy: ({ row }: { row: Deal }) => phaseById(row.phase).order,
      slots: {
        default: ({ row }: any) => {
          const phase = phaseById(row.phase);
          return [h(PhaseCell, { phase })];
        },
      },
    };
  }

  private startDateColumn(): DrVxeTableColumn {
    return {
      field: "start_date",
      title: "Start Date",
      width: 140,
      sortBy: ({ row }) => row.start_date?.getTime(),
      slots: {
        default: ({ row }: any) => {
          const text = drUserTime(row.start_date, "full-date") as string;
          return [text];
        },
      },
    };
  }

  private menuColumn(): DrVxeTableColumn {
    return {
      sortable: false,
      resizable: false,
      align: "center",
      field: "restore_action",
      title: "",
      minWidth: 185,
      slots: {
        default: ({ row }: { row: any }) => {
          return [
            h(MenuDealCell, {
              deal: row,
              onRestore: this.handlers.onRestore,
              onDelete: this.handlers.onDelete,
            }),
          ];
        },
      },
    };
  }
}
