<template>
  <div :class="$style.wrapper" @click="$emit('apply')">
    {{ template.question }}
    <div :class="$style.actions">
      <ElPopconfirm
        title="Are you sure to delete this template question?"
        :hide-icon="true"
        :width="230"
        :confirm-button-type="'warning'"
        @confirm="onConfirmDelete"
      >
        <template #reference>
          <ElButton text size="small" :class="$style.actionsBtn" @click.stop>
            <DrIcon size="sm" name="trash" />
          </ElButton>
        </template>
      </ElPopconfirm>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { DrIcon } from "@shared/ui/dr-icon";

import { pinia } from "@drVue/store/pinia";
import { useAiTemplatesStore } from "./AiTemplatesStore";

import type { AiTemplate } from "./types";

interface Props {
  template: AiTemplate;
}

interface Events {
  (e: "apply"): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Events>();

const aiTemplatesStore = useAiTemplatesStore(pinia);

const onConfirmDelete = () => {
  aiTemplatesStore.remove(props.template.id);
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: spacing.$xxs;
  padding: 6px 8px 6px 12px;
  font: typography.$body_regular;
  color: colors.$pr-900;
  border: 1px solid colors.$pr-150;
  border-radius: spacing.$xs;

  &:hover {
    cursor: pointer;
    border-color: colors.$sc-400;

    .actions {
      opacity: 1;
    }
  }
}

.wrapperAllowApply {
  cursor: pointer;

  &:hover {
    color: colors.$sc-600;
  }
}

.actionsBtn {
  --el-button-icon-color: #{colors.$pr-400};
}

.actions {
  opacity: 0;
}
</style>
