import type { Dictionary } from "@drVue/types";

interface State {
  flatTree: any[];
  expanded: Dictionary<boolean>;
}

export class DocumentsTreeService {
  static rootFolder: null | any = null;

  static instances: DocumentsTreeService[] = [];

  static setRootFolder(rootFolder: any) {
    DocumentsTreeService.rootFolder = rootFolder;
    for (const i of DocumentsTreeService.instances) {
      i._rebuildFlatTree();
    }
  }

  // @Out
  state: State = { flatTree: [], expanded: {} };

  constructor() {
    DocumentsTreeService.instances.push(this);
    this._rebuildFlatTree();
  }

  destroy() {
    const i = DocumentsTreeService.instances.indexOf(this);
    DocumentsTreeService.instances.splice(i, 1);
  }

  expandAll(folder = DocumentsTreeService.rootFolder) {
    if (!folder) return;

    // folder.folders contains only folder's children.
    for (const child of folder.folders) {
      this.state.expanded[child.id] = true;
      this.expandAll(child);
    }

    if (folder === DocumentsTreeService.rootFolder) {
      this._rebuildFlatTree();
    }
  }

  collapseAll(folder = DocumentsTreeService.rootFolder) {
    if (!folder) return;

    // folder.folders contains only folder's children.
    for (const child of folder.folders) {
      this.state.expanded[child.id] = false;
      this.collapseAll(child);
    }

    if (folder === DocumentsTreeService.rootFolder) {
      this._rebuildFlatTree();
    }
  }

  expandFolder(folder: any) {
    if (!folder) return;

    this.state.expanded[folder.id] = true;

    // folder.parents is a chain to the root.
    for (const p of folder.parents) {
      this.state.expanded[p.id] = true;
    }

    this._rebuildFlatTree();
  }

  toggleFolder(folder: any) {
    if (!folder) return;

    const isExpanded = this.state.expanded[folder.id];
    if (isExpanded) {
      this.state.expanded[folder.id] = false;
      this._rebuildFlatTree();
    } else {
      this.expandFolder(folder);
    }
  }

  _rebuildFlatTree() {
    this.state.flatTree = this._buildFlatTree();
  }

  _buildFlatTree(folder = DocumentsTreeService.rootFolder) {
    if (folder === null) return [];

    const folders = [folder];

    const isNotExpanded = !this.state.expanded[folder.id];
    const hasParent = folder.parent_id;
    if (isNotExpanded && hasParent) {
      return folders;
    } else {
      for (const subfolder of folder.folders) {
        folders.push(...this._buildFlatTree(subfolder));
      }
    }

    return folders;
  }
}

export const LeftSidebarDocumentsTree = new DocumentsTreeService();
