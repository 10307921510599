import { parseDjDataJson } from "@setups/data";

export enum OpenIDProviders {
  Idaptive = "idaptive",
  Google = "google",
  Saml = "saml",
}

export interface OpenIDProvider {
  id: OpenIDProviders;
  name: string;
  logo: string;
}

export interface OpenIDIntegration {
  id: number;
  provider_id: string;
  title: string;
  provider: OpenIDProvider;
}

export class OpenIDProvidersService {
  public readonly providers: OpenIDProvider[];

  constructor() {
    this.providers = [
      {
        id: OpenIDProviders.Idaptive,
        name: "Idaptive",
        logo: "/assets/internal/images/logo-idaptive.png",
      },
      {
        id: OpenIDProviders.Google,
        name: "Google",
        logo: "/assets/internal/images/logo-google.png",
      },
      {
        //fixme: OpenIDProviders -> SSOProviders
        id: OpenIDProviders.Saml,
        name: "SAML",
        logo: "",
      },
    ];
  }

  public getById(providerId: OpenIDProviders): OpenIDProvider {
    return this.providers.find((p) => p.id === providerId) as OpenIDProvider;
  }
}

export function getCurrentClientSsoIntegrations(): OpenIDIntegration[] {
  const service = new OpenIDProvidersService();
  const integrations = parseDjDataJson("openid-integrations", true) || [];
  integrations.forEach(
    (i: OpenIDIntegration) =>
      (i.provider = service.getById(i.provider_id as OpenIDProviders)),
  );
  return integrations;
}
