import datesRangeHtml from "./index.html?raw";

DatesRangeDropdownController.$inject = ["ActivityFilterDatesService"];
import { convertBtzToPtz } from "@shared/ui/dr-datepicker/DrDatepicker.vue";

angular
  .module("dealroom.analytics.dropdown.dates-range", [
    "dealroom.analytics.service.filter",
  ])
  .component("drAnalyticsDatesRangeDropdown", {
    template: datesRangeHtml,
    controller: DatesRangeDropdownController,
  });

function DatesRangeDropdownController(ActivityFilterDatesService) {
  const $ctrl = this;

  const now = new Date();
  const { y, m, d } = {
    y: now.getFullYear(),
    m: now.getMonth(),
    d: now.getDate(),
  };
  const initial = ($ctrl.initialDateRangeValue = [
    convertBtzToPtz(new Date(y, m, d - 7, 0, 0, 0, 0)),
    convertBtzToPtz(new Date(y, m, d, 23, 59, 59, 999)),
  ]);

  $ctrl.onDateRangeChanged = function (range) {
    ActivityFilterDatesService.setDatesRange(range[0], range[1]);
  };

  ActivityFilterDatesService.setDatesRange(initial[0], initial[1]);
}
