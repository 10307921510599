<template>
  <div class="edit-user-details-form">
    <div class="edit-user-details-form__header">
      <h4 class="edit-user-details-form__title">
        <b>User details</b>
      </h4>

      <div class="edit-user-details-form__edit-buttons">
        <ElButton
          :disabled="isFormSubmitting"
          type="default"
          @click="$emit('close')"
        >
          Cancel
        </ElButton>

        <ElButton
          :disabled="!isFormChanged || isFormSubmitting"
          :loading="isFormSubmitting"
          type="primary"
          @click="save"
        >
          Save changes
        </ElButton>
      </div>
    </div>
    <div class="edit-user-details-form__form-wrapper">
      <!-- @vue-skip -->
      <Info
        v-if="isOpened"
        ref="info"
        :class="$style.panelInfo"
        :item="user"
        :extra-view-fields="extraViewFields"
        :extra-edit-fields="extraEditFields"
        item-type="user"
        item-label="user"
        save-action="clientDashboard/orgUsers/update"
        :is-initial-edit-state="true"
        @form-state-changed="setFormState"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Info from "@shared/ui/info";

import ViewTextField from "@drVue/components/client-dashboard/users/UserDetails/CustomFields/View/ViewTextField.vue";
import ViewUserEmailField from "@drVue/components/client-dashboard/users/UserDetails/CustomFields/View/ViewUserEmailField.vue";
import ViewOrEditPhoneField from "@drVue/components/client-dashboard/users/UserDetails/CustomFields/ViewOrEdit/ViewOrEditPhoneField.vue";
import ViewOrEditTextField from "@drVue/components/client-dashboard/users/UserDetails/CustomFields/ViewOrEdit/ViewOrEditTextField.vue";

import type { CustomDataType } from "@drVue/store/modules/client-dashboard/fields/types";
import type { PropType } from "vue";

interface Data {
  isFormChanged: boolean;
  isFormEditing: boolean;
  isFormSubmitting: boolean;
}

interface InfoItem {
  id: number;
  custom_data: CustomDataType;
  pending: boolean;
}

export default defineComponent({
  name: "EditUserDetailsForm",

  components: {
    Info,
  },

  props: {
    user: { type: Object as PropType<InfoItem | null>, required: true },
    isOpened: { type: Boolean, required: true },
  },

  emits: ["close"],

  data(): Data {
    return {
      isFormChanged: false,
      isFormEditing: false,
      isFormSubmitting: false,
    };
  },

  computed: {
    extraViewFields(): any {
      if (!this.user) return;

      // prettier-ignore
      return [
        { key: "email", component: ViewUserEmailField },
        { key: "first_name", component: ViewTextField, meta: { label: "First name", fieldPath: "first_name" } },
        { key: "last_name", component: ViewTextField, meta: { label: "Last name", fieldPath: "last_name"} },
        { key: "company", component: ViewTextField, meta: { label: "Company", fieldPath: "company" } },
        { key: "role", component: ViewTextField, meta: { label: "Role", fieldPath: "title"} },
        { key: "phone", component: ViewOrEditPhoneField, meta: { isEditable: false} },
      ];
    },
    extraEditFields(): any {
      if (!this.user) return;

      // prettier-ignore
      return [
        { key: "email", component: ViewUserEmailField },
        { key: "first_name", component: ViewOrEditTextField, 
          meta: { label: "First name", fieldPath: "first_name", isEditable: this.user.pending } },
        { key: "last_name", component: ViewOrEditTextField, 
          meta: { label: "Last name", fieldPath: "last_name", isEditable: this.user.pending } },
        { key: "company", component: ViewOrEditTextField, 
          meta: { label: "Company", fieldPath: "company", isEditable: this.user.pending } },
        { key: "role", component: ViewOrEditTextField, 
          meta: { label: "Role", fieldPath: "title", isEditable: this.user.pending } },
        { key: "phone", component: ViewOrEditPhoneField, 
          meta: { label: "Phone", fieldPath: "office_number", isEditable: this.user.pending } },
      ];
    },
  },

  methods: {
    save() {
      (this.$refs.info as any).save();
      this.$emit("close");
    },
    setFormState({
      isChanged,
      isEditing,
      isSubmitting,
    }: {
      isChanged: boolean;
      isEditing: boolean;
      isSubmitting: boolean;
    }) {
      this.isFormChanged = isChanged;
      this.isFormEditing = isEditing;
      this.isFormSubmitting = isSubmitting;
    },
  },
});
</script>

<style scoped lang="scss">
@import "/app/styles/scss/_colors.scss";
@import "/app/styles/scss/typography.scss";

// Edit user details form
.edit-user-details-form {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  position: relative;
  overflow-x: hidden;

  &__header {
    height: 100px;
    padding: 0 30px;
    border-bottom: 1px #eaeaea solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    margin-bottom: 0;
  }

  &__form-wrapper {
    padding: 25px;
    box-sizing: border-box;
  }

  &__edit-buttons {
    display: grid;
    grid-template-columns: 95px 135px;
    gap: 10px;
  }
}
</style>

<style lang="scss" module>
/* 
 * Fixing an issue where long content stretches the column 
 * instead of clipping to the available width.
 */
.panelInfo {
  overflow: hidden;

  :global(.dash-content-panel-row) {
    display: grid;
    grid-template-columns: 1fr 33% 33%;

    > * {
      min-width: 0;
    }
  }
}
</style>
