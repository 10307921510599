<template>
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M18.4391 12.7695L18.597 12.7871L18.6847 12.8748L18.8251 13.1556L19.1059 13.8048L19.3515 14.3663L19.6498 15.0507L19.7902 15.3314L19.913 15.542L20.071 15.735L20.2991 15.9631L20.5974 16.1737L20.8781 16.3141L21.5976 16.6299L22.124 16.8581L22.6855 17.1037L23.1067 17.2967L23.1944 17.3669L23.2295 17.4547L23.2119 17.5775L23.1242 17.6828L22.9838 17.753L22.2644 18.0688L20.9308 18.6479L20.65 18.7883L20.4044 18.9462L20.1587 19.1568L20.1412 19.1919H20.1061L20.071 19.262L19.913 19.4551L19.8078 19.613L19.6147 20.0166L19.3866 20.543L19.0708 21.2624L18.8427 21.7889L18.6847 22.1223L18.5795 22.21L18.4742 22.2275L18.3689 22.1749L18.2987 22.0872L18.0881 21.6309L17.5091 20.2798L17.316 19.8411L17.1581 19.5428L16.9826 19.3147L16.8247 19.1392L16.5615 18.9286L16.2281 18.7356L15.5964 18.4549L14.877 18.139L14.1751 17.8232L13.8943 17.7003L13.789 17.6126L13.7715 17.4371L13.8241 17.3494L13.9119 17.2792L15.3858 16.6299L16.0351 16.3492L16.3334 16.2088L16.5966 16.0333L16.8247 15.8403L17.0353 15.6122L17.2108 15.3314L17.3511 15.0507L17.7021 14.2435L17.9477 13.682L18.2461 12.9976L18.3162 12.8573L18.404 12.7871L18.4391 12.7695Z"
      :fill="active ? `url(#${gradientId})` : 'currentColor'"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.875 4.72991H14.1667C14.1667 3.57754 13.1951 2.64062 12 2.64062C10.8049 2.64062 9.83333 3.57754 9.83333 4.72991H7.125C6.22786 4.72991 5.5 5.43178 5.5 6.29687V17.7879C5.5 18.653 6.22786 19.3549 7.125 19.3549H13.9205C12.6501 18.8051 12.1795 18.3444 12.2151 17.4231C12.235 16.9056 12.4336 16.5401 12.8404 16.221H11.1875C11.0385 16.221 10.9167 16.1035 10.9167 15.9598V15.4375C10.9167 15.2939 11.0385 15.1763 11.1875 15.1763H15.0008C15.9198 14.7915 15.8852 14.6812 16.5182 13.2064C17.0814 11.7951 17.5769 11.1987 18.5 11.1987V6.29687C18.5 5.43178 17.7721 4.72991 16.875 4.72991ZM7.9375 15.6987C7.9375 16.1328 8.29974 16.4821 8.75 16.4821C9.20026 16.4821 9.5625 16.1328 9.5625 15.6987C9.5625 15.2645 9.20026 14.9152 8.75 14.9152C8.29974 14.9152 7.9375 15.2645 7.9375 15.6987ZM7.9375 12.5647C7.9375 12.9989 8.29974 13.3482 8.75 13.3482C9.20026 13.3482 9.5625 12.9989 9.5625 12.5647C9.5625 12.1306 9.20026 11.7812 8.75 11.7812C8.29974 11.7812 7.9375 12.1306 7.9375 12.5647ZM7.9375 9.4308C7.9375 9.86498 8.29974 10.2143 8.75 10.2143C9.20026 10.2143 9.5625 9.86498 9.5625 9.4308C9.5625 8.99662 9.20026 8.64732 8.75 8.64732C8.29974 8.64732 7.9375 8.99662 7.9375 9.4308ZM12.8125 4.72991C12.8125 4.29573 12.4503 3.94643 12 3.94643C11.5497 3.94643 11.1875 4.29573 11.1875 4.72991C11.1875 5.16409 11.5497 5.51339 12 5.51339C12.4503 5.51339 12.8125 5.16409 12.8125 4.72991ZM16.0625 13.0871C16.2115 13.0871 16.3333 12.9695 16.3333 12.8259V12.3036C16.3333 12.1599 16.2115 12.0424 16.0625 12.0424H11.1875C11.0385 12.0424 10.9167 12.1599 10.9167 12.3036V12.8259C10.9167 12.9695 11.0385 13.0871 11.1875 13.0871H16.0625ZM16.0625 9.95312C16.2115 9.95312 16.3333 9.8356 16.3333 9.69196V9.16964C16.3333 9.026 16.2115 8.90848 16.0625 8.90848H11.1875C11.0385 8.90848 10.9167 9.026 10.9167 9.16964V9.69196C10.9167 9.8356 11.0385 9.95312 11.1875 9.95312H16.0625Z"
      :fill="active ? `url(#${gradientId})` : 'currentColor'"
    />

    <defs>
      <linearGradient
        :id="gradientId"
        x1="0.5"
        y1="8.99777"
        x2="12.9443"
        y2="8.88942"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2B49E8" />
        <stop offset="1" stop-color="#795AD2" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script setup lang="ts">
defineProps<{
  active?: boolean;
}>();

const gradientId = "paint0_linear_22146_39067";
</script>
