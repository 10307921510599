import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { FindingsApiService } from "@drVue/api-service/modules/findings";
import { createDictionary } from "@drVue/common";

import type {
  Finding,
  FindingCreatePayload,
  FindingUpdatePayload,
} from "@drVue/api-service/modules/findings/types";

export type { Finding, FindingCreatePayload, FindingUpdatePayload };

const api = new FindingsApiService();

export const useFindingsArchiveStore = defineStore(
  "roomFindingsArchive",
  () => {
    const isLoading = ref(false);
    const isLoadError = ref(false);

    const list = ref<Finding[]>([]);

    const dict = computed(() => {
      return Object.freeze(
        list.value.reduce((dict, finding) => {
          dict[finding.id] = finding;
          return dict;
        }, createDictionary<Finding>()),
      );
    });

    const load = () => {
      isLoading.value = true;
      isLoadError.value = false;

      return api
        .loadArchived()
        .then(
          (archivedFindings) => {
            list.value = archivedFindings;

            isLoadError.value = false;
          },
          () => {
            isLoadError.value = true;
          },
        )
        .finally(() => {
          isLoading.value = false;
        });
    };

    const restore = async (id: Finding["id"]) => {
      await api.restoreBulk([id]);

      const i = list.value.findIndex((f) => f.id === id);
      list.value.splice(i, 1);
    };

    return {
      isLoading,
      isLoadError,
      list,
      dict,
      load,
      restore,
    };
  },
);
