import { cloneDeep } from "lodash-es";
import { computed, ref } from "vue";

import { createDictionary } from "@drVue/common";

import type { Ref } from "vue";

export type EntityBase = {
  id: string | number;
};

export type EntityListParams<
  T extends EntityBase,
  C extends Partial<T> = Partial<T>,
  U extends Partial<T> = Partial<T>,
> = {
  name: string;
  load: {
    (): Promise<T[]>;
  };
  create?: {
    (payload: C): Promise<T>;
  };
  update?: {
    (id: T["id"], payload: U): Promise<T>;
  };
  remove?: {
    (id: T["id"]): Promise<void>;
  };
};

const generateList = <
  E extends EntityBase,
  C extends Partial<E> = Partial<E>,
  U extends Partial<E> = Partial<E>,
>(
  params: EntityListParams<E, C, U>,
) => {
  /**
   * @remark we use custing `as Ref<E[]>` to fix problem "UnwrapRefSimple" next in dict
   * @see https://github.com/vuejs/core/issues/2136
   */
  const list = ref<E[]>([]) as Ref<E[]>;
  const dict = computed(() =>
    Object.freeze(
      list.value.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, createDictionary<E>()),
    ),
  );

  const _isLoading = ref<boolean>(false);
  const _isLoadError = ref<boolean>(false);
  const isLoading = computed(() => _isLoading.value);
  const isLoadError = computed(() => _isLoadError.value);

  const applyChanges = (changes: Partial<E>, key: keyof E = "id") => {
    if (changes[key] === undefined) {
      return false;
    }

    const index = list.value.findIndex((item) => item[key] === changes[key]);
    if (index === -1) return false;

    const updatedItem = {
      ...cloneDeep(list.value[index]),
      ...changes,
    };

    /** @todo fix after debug vxe-table strange behaviour */
    // list.value[index] = updatedItem;
    if (index > -1) {
      list.value = list.value.map((item, i) => {
        if (index === i) return updatedItem;
        return item;
      });
    }
    return true;
  };

  const load = async () => {
    _isLoading.value = true;
    _isLoadError.value = false;

    try {
      list.value = await params.load();
      return list.value;
    } catch (err) {
      _isLoadError.value = true;
      /** @note to use in "hookFormSubmitPromise" by "useFormHelper" */
      throw err;
    } finally {
      _isLoading.value = false;
    }
  };

  const create = async (payload: C) => {
    if (!params.create) {
      throw new Error(
        `The api method for creating an entity "${params.name}" instance is not registered.`,
      );
    }

    const createdItem = await params.create(payload);
    /** @todo fix after debug vxe-table strange behaviour */
    // this.list.value.push(createdItem);
    list.value = [...list.value, createdItem];

    return createdItem;
  };

  const update = async (id: E["id"], payload: U) => {
    if (!params.update) {
      throw new Error(
        `The api method for updating an entity "${params.name}" instance is not registered.`,
      );
    }

    const updatedItem = await params.update(id, payload);
    const i = list.value.findIndex((item) => item.id === updatedItem.id);
    /** @todo fix after debug vxe-table strange behaviour */
    // if (i > -1) this.list.value[i] = updatedItem;
    if (i > -1) {
      list.value = list.value.map((item, index) => {
        if (i === index) return updatedItem;
        return item;
      });
    }

    return updatedItem;
  };

  const remove = async (id: E["id"]) => {
    if (!params.remove) {
      throw new Error(
        `The api method for removing an entity "${params.name}" instance is not registered.`,
      );
    }

    await params.remove(id);
    const i = list.value.findIndex((item) => item.id === id);
    if (i > -1) list.value.splice(i, 1);
  };

  return {
    list,
    dict,
    isLoading,
    isLoadError,
    load,
    create,
    update,
    remove,
    applyChanges,
  };
};

export { generateList };
