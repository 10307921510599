import { h } from "vue";
import { UserEmail, UserOfficeNumber } from "@shared/ui/users";
import UserInvite from "@shared/ui/users/user-invite/UserInvite.vue";

import { ORG_MEMBER_DATA } from "@setups/data";
import { capitalize } from "@drVue/common";

import type { DrVxeTableColumn } from "@drVue/components/types";
import type { OrganizationGroup } from "@drVue/store/pinia/pipeline/org-groups";
import type { OrganizationMember } from "@drVue/store/pinia/pipeline/org-members";

export type TableDataRow = {
  member: OrganizationMember;
  group: OrganizationGroup;
};

export default class TableColumns {
  get columns(): DrVxeTableColumn<TableDataRow>[] {
    const columns = [this.name(), this.group()];
    if (ORG_MEMBER_DATA.client.enable_looker) {
      columns.push(this.lookerAccess());
    }

    columns.push(
      ...[this.company(), this.title(), this.email(), this.officeNumber()],
    );
    return columns;
  }

  private name(): DrVxeTableColumn<TableDataRow> {
    return {
      sortable: true,
      field: "member.user.name",
      title: "Name",
      fixed: "left",
      className: "members-table__user-name-cell",
      minWidth: 220,
      slots: {
        default: ({ row }) => {
          return [
            h(UserInvite, {
              memberId: row.member.id,
              user: row.member.user,
              isLink: true,
              showTooltip: true,
              size: "20px",
            }),
          ];
        },
      },
    };
  }

  private group(): DrVxeTableColumn<TableDataRow> {
    return {
      sortable: true,
      field: "group.name",
      title: "Permission Set",
      minWidth: 150,
    };
  }

  private lookerAccess(): DrVxeTableColumn<TableDataRow> {
    return {
      sortable: false,
      resizable: false,
      field: "member.looker_access",
      title: "BI Reports Access",
      width: 140,
      slots: {
        default: ({ row }) => {
          const accessLabel =
            row.group.is_administrator || row.group.deals_access_type === "all"
              ? row.member.looker_access
              : "-";

          return [h("span", capitalize(accessLabel, true))];
        },
      },
    };
  }

  private company(): DrVxeTableColumn<TableDataRow> {
    return {
      sortable: true,
      field: "member.user.company",
      title: "Company",
      minWidth: 150,
    };
  }

  private title(): DrVxeTableColumn<TableDataRow> {
    return {
      sortable: true,
      field: "member.user.title",
      title: "Title",
      minWidth: 180,
    };
  }

  private email(): DrVxeTableColumn<TableDataRow> {
    return {
      sortable: true,
      field: "member.user.email",
      title: "Email",
      minWidth: 250,
      width: 250,
      slots: {
        default: ({ row }) => {
          return [h(UserEmail, { email: row.member.user.email })];
        },
      },
    };
  }

  private officeNumber(): DrVxeTableColumn<TableDataRow> {
    return {
      sortable: true,
      resizable: false,
      field: "member.user.office_number",
      title: "Phone",
      minWidth: 150,
      width: 150,
      slots: {
        default: ({ row }) => {
          return [
            h(UserOfficeNumber, {
              officeNumber: row.member.user.office_number || "",
            }),
          ];
        },
      },
    };
  }
}
