import { createStore } from "vuex";
import { NG_APP_ID } from "@shared/ui/angular/constants";

import { clientDashboard } from "./modules/client-dashboard";
import { common } from "./modules/common";
import { room } from "./modules/room";

import type { RootState } from "./state";
import type { ModuleTree } from "vuex";

const modules: ModuleTree<RootState> = {
  clientDashboard,
  room,
  common,
};

export default createStore({
  modules,
  strict: false,
  plugins: [
    function (store) {
      if (typeof angular === "undefined") return;

      const $appElement = (angular as any).element(
        document.getElementById(NG_APP_ID),
      );

      if (!$appElement) return;

      let debounceTimeoutId: number;

      store.subscribe($applyAsync);
      store.subscribeAction({ after: $applyAsync });

      function $applyAsync() {
        clearTimeout(debounceTimeoutId);
        debounceTimeoutId = window.setTimeout(() => {
          const scope = $appElement.scope();
          if (scope) scope.$applyAsync();
        });
      }
    },
  ],
});
