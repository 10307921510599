<template>
  <div :class="$style.container">
    <Tab
      text="Blank room"
      icon-name="folder"
      :is-active="activeTab === RoomFormTabs.BLANK"
      @click="$emit('tab-click', RoomFormTabs.BLANK)"
    />
    <Tab
      text="Use playbook"
      icon-name="layer-group"
      :is-active="activeTab === RoomFormTabs.PLAYBOOK"
      @click="$emit('tab-click', RoomFormTabs.PLAYBOOK)"
    />
    <Tab
      text="Copy room"
      icon-name="copy"
      :is-active="activeTab === RoomFormTabs.COPY"
      @click="$emit('tab-click', RoomFormTabs.COPY)"
    />
    <span v-if="activeTab === RoomFormTabs.BLANK" :class="$style.description">
      Start with empty room without any predefined folder structure or tasks.
    </span>
    <span
      v-if="activeTab === RoomFormTabs.PLAYBOOK"
      :class="$style.description"
    >
      Choose a playbook that has pre-populated categories and requests.
    </span>
    <span v-if="activeTab === RoomFormTabs.COPY" :class="$style.description">
      Populate the new room with items from your another room.
    </span>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

import { INITIAL_DATA_TYPES } from "@drVue/components/user-dashboard/room-create/utils";
import Tab from "./RoomFormTab.vue";
import { RoomFormTabs } from "./types";

import type { RoomFormTab } from "./types";

interface Props {
  activeTab: RoomFormTab;
}

defineProps<Props>();

const blankButton = {
  id: "blank",
  type: INITIAL_DATA_TYPES.Blank,
  label: "Keep blank",
  icon: "fa-plus",
};
const copyButton = {
  id: "copy",
  type: INITIAL_DATA_TYPES.CopyRoom,
  label: "Copy room",
  icon: "fa-clone",
};
const templateButton = {
  id: "template",
  type: INITIAL_DATA_TYPES.Template,
  label: "Use playbook",
  icon: "fa-book",
};

const switchDataTypeButtons = [blankButton, copyButton, templateButton];
const createBy = ref();
const selectedPlaybook = ref(null);
const setCreateBy = (value: any) => {
  createBy.value = value;
  selectedPlaybook.value = null;
};

defineEmits<{ (event: "tab-click", payload: RoomFormTab): void }>();
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.container {
  grid-template-areas: "tab tab tab" "desc desc desc";
  grid-template-columns: repeat(3, auto);
  grid-template-rows: 32px 40px;
  display: grid;
  gap: 12px;

  border-bottom: 1px solid colors.$pr-150;
}

.tabButton {
  grid-area: tab;
  min-height: 30px;
  border-radius: 8px;
  border: 1px solid colors.$pr-300;

  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  gap: 8px;

  div {
    justify-self: flex-end;
  }
}

.description {
  grid-area: desc;
  font: typography.$body_regular;
  color: colors.$pr-700;
}
</style>
