import type { OrgUsersState } from "./OrgUsersState";
import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { RootState } from "@drVue/store/state";
import type { Dictionary } from "@drVue/types";
import type { GetterTree } from "vuex";

export const orgUsersGetters: GetterTree<OrgUsersState, RootState> = {
  byId:
    (state) =>
    (id: string): OrgUser | undefined => {
      return state.items.find((us) => us.id === id && !us.pending);
    },
  byIds:
    (state) =>
    (ids: string[]): OrgUser[] => {
      const dict = ids.reduce(
        (dict, id) => {
          dict[id] = true;
          return dict;
        },
        Object.create(null) as Dictionary<boolean>,
      );

      return state.items.filter((u) => dict[u.id] && !u.pending);
    },
  mapByEmail: (state): Dictionary<OrgUser> => {
    return state.items.reduce(
      (d, u) => {
        if (!u.pending) {
          d[u.email] = u;
        }

        return d;
      },
      Object.create(null) as Dictionary<OrgUser>,
    );
  },
  byLegacyId:
    (state) =>
    (legacyId: number): OrgUser | undefined => {
      return state.items.find((us) => us.user_id === legacyId);
    },
};
