export interface UploadItem {
  path: string;
  name?: string; // Files have name attr
}

export function sortUploadItems<I extends UploadItem>(items: I[]): I[] {
  const sorter = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  }).compare;

  return items.sort((a: I, b: I) => {
    if (a.name && b.name && a.path === b.path) {
      // sort files with in same folder by name
      return sorter(a.name, b.name);
    }

    return sorter(a.path, b.path);
  });
}
