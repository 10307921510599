import { useEventBus } from "@vueuse/core";

import type { TaskContextAction } from "@drVue/components/room/tasks/TasksTable/makeTableMenu";
import type { Task } from "@drVue/store/pinia/room/tasks";

export type TaskPayload = { task: Task; reference: HTMLElement };
export type TaskEvent =
  (typeof TaskContextAction)[keyof typeof TaskContextAction];

export const useTaskTableBus = () => {
  return useEventBus<TaskEvent, TaskPayload>("tasks-table");
};
