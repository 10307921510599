<template>
  <ElButton
    icon-right
    :class="{
      [$style.button]: true,
      [$style.button_isActive]: isActive,
      [$style.button_noFocusable]: noFocus,
    }"
    :disabled="disabled"
    @click="emit('click')"
  >
    <span v-if="icon || $slots.prefix" :class="$style.prefix">
      <slot name="prefix">
        <DrIcon v-if="icon" :name="icon" size="xs" />
      </slot>
    </span>

    <span v-if="title" :class="$style.title">{{ title }}</span>

    <template v-if="!hideDropdown">
      <DrIcon v-if="isActive" name="cross" size="xs" @click.stop="clear" />
      <DrIcon v-else name="caret-down" size="sm" />
    </template>
  </ElButton>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import DrIcon from "../dr-icon";

import type { IconName } from "../dr-icon";

interface Props {
  /** Static filter button title */
  label?: string;
  /** Filter activity status (when options are selected, etc.) */
  isActive?: boolean;
  /**
   * An optional icon is displayed to the left of the title.
   * Can be customized via slot "prefix". */
  icon?: IconName;
  /**
   * The optional number of selected "filter options" is displayed on the right side of the title.
   * But only in the "isActive=true" state. */
  counter?: number;
  /**
   * This flag allows you to hide the dropdown icon.
   * For example, when a button opens some kind of "drawer" panel, and not a popup with select options. */
  hideDropdown?: boolean;
  /** Disabled state */
  disabled?: boolean;
  /** Disabled focus state */
  noFocus?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  label: "",
  isActive: false,
  icon: undefined,
  counter: 0,
  hideDropdown: false,
  disabled: false,
  noFocus: false,
});

const emit = defineEmits(["click", "clear"]);

const clear = () => {
  emit("clear");
};

const title = computed(() => {
  if (props.isActive && props.counter) {
    return `${props.label} (${props.counter})`;
  }
  return props.label;
});
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography" as typo;

.button:global(.el-button) {
  /** 
   * For some reason, the radius customization `$allButtonsBorderRadius`
   * from `frontend\app\styles\libs\element-ui.scss` were not applied here.
  */
  --el-border-radius-base: 8px;

  --el-button-text-color: #{colors.$pr-900};
  --el-button-bg-color: #{colors.$pr-50};
  --el-button-border-color: transparent;

  --el-button-hover-text-color: #{colors.$sc-600};
  --el-button-hover-bg-color: #{colors.$pr-0};
  --el-button-hover-border-color: #{colors.$sc-500};

  --el-button-active-text-color: #{colors.$sc-600};
  --el-button-active-bg-color: #{colors.$sc-100};
  --el-button-active-border-color: transparent;

  --el-button-outline-color: #{colors.$sc-400};

  --el-button-icon-color: #{colors.$pr-500};
  --el-button-icon-hover-color: #{colors.$sc-600};
  --el-button-icon-active-color: #{colors.$sc-600};
  --el-button-icon-focus-color: #{colors.$pr-600};
  --el-button-icon-disabled-color: #{colors.$pr-350};

  --el-button-icon-dropdown-color: #{colors.$pr-400};
  --el-button-icon-dropdown-hover-color: #{colors.$sc-500};
  --el-button-icon-dropdown-active-color: #{colors.$sc-600};
  --el-button-icon-dropdown-focus-color: #{colors.$pr-600};
  --el-button-icon-dropdown-disabled-color: #{colors.$pr-350};

  max-width: 100%;
  padding: spacing.$xxs spacing.$xs;
  overflow: hidden;
  font: typo.$body_regular;

  &:focus {
    outline-offset: 0;
  }

  > span {
    display: inline-grid;
    grid-auto-flow: column;
    gap: spacing.$xxs;
    align-items: center;
    max-width: 100%;
    overflow: hidden;
  }
}

.button_isActive:global(.el-button) {
  --el-button-text-color: #{colors.$sc-600};
  --el-button-bg-color: #{colors.$sc-100};
  --el-button-icon-color: #{colors.$sc-600};

  font: typo.$body_medium;
}

.button_isActive.button_noFocusable:global(.el-button) {
  &:focus {
    --el-button-icon-focus-color: #{colors.$sc-600};
  }
}

.button_isActive:not(.button_noFocusable):global(.el-button) {
  &:focus {
    color: colors.$pr-900;
    font: typo.$body_regular;
  }
}

.button_noFocusable:global(.el-button) {
  &:focus {
    outline: none !important;
  }

  &:focus:hover {
    color: var(--el-button-hover-text-color);
    border-color: var(--el-button-hover-border-color);
    background-color: var(--el-button-hover-bg-color);
  }
}

.prefix {
  display: inline-flex;
  align-items: center;
}

.title {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
