import * as d3 from "d3";

import { MIN_WIDTH } from "./service.data.js";

const SHRINK_DURATION = 700;
const EXPAND_DURATION = 1200;
const LABEL_ATTR = "label";
const SHRINK_EASE = {
  bg: d3.easePolyOut.exponent(3),
  width: d3.easeQuadInOut,
  left: d3.easeQuadInOut,
  labelOpacity: d3.easePolyOut.exponent(3),
};
const EXPAND_EASE = {
  bg: d3.easePolyOut.exponent(1),
  width: d3.easeExpOut,
  left: d3.easeExpOut,
  labelOpacity: d3.easePolyOut.exponent(1),
};

export default { shrink, expand, getStyleAsAttr, LABEL_ATTR };

function getStyleAsAttr({ bg, width, left }, zIndex) {
  // have to use !important for overwrite bootstrap print settings
  // but it is not possible to set `node.style.prop = 'value!important'`;
  const style = {
    "background-color": `${bg}!important`,
    width: width + "px",
    left: left + "px",
    "z-index": zIndex,
  };
  return Object.keys(style).reduce((s, n) => s + `${n}:${style[n]};`, "");
}

function tweenStyle(node, d, shrink) {
  const label = node.querySelector(`[${LABEL_ATTR}]`);
  const interpolate = _getInterpolates(node, label, d, shrink);

  return function (t) {
    const attrs = Object.keys(interpolate).reduce((b, n) => {
      b[n] = interpolate[n](t);
      return b;
    }, {});
    const style = getStyleAsAttr(attrs, d.zIndex);
    node.setAttribute("style", style);
    if (label) {
      label.style.opacity = attrs.width > MIN_WIDTH ? attrs.labelOpacity : 0;
    }
  };

  function _getInterpolates(node, label, d, shrink) {
    const params = _getParams(node, label, d, shrink);
    const ease = _getEase(shrink);
    return Object.keys(params).reduce((b, n) => {
      console.assert(ease[n] !== undefined, n);
      b[n] = _interpolate(params[n], ease[n]);
      return b;
    }, {});

    function _interpolate([start, end], e) {
      const i = d3.interpolate(start, end);
      return function (t) {
        return i(e(t));
      };
    }

    function _getEase(shrink) {
      return shrink ? SHRINK_EASE : EXPAND_EASE;
    }

    function _getParams(node, label, d, shrink) {
      const params = Object.keys(d.end).reduce((b, n) => {
        b[n] = shrink ? [d.end[n], d.start[n]] : [d.start[n], d.end[n]];
        return b;
      }, {});

      params.bg[0] = node.style["background-color"];
      params.width[0] = parseFloat(node.style["width"], 10);
      params.left[0] = parseFloat(node.style["left"], 10);
      if (label) {
        params.labelOpacity[0] = parseInt(label.style["opacity"]);
      }
      return params;
    }
  }
}

function shrink(selection, onLastEnd = Function.prototype) {
  if (selection.empty()) return onLastEnd();
  const lastI = selection.size() - 1;
  return selection
    .transition("shrink")
    .duration(SHRINK_DURATION)
    .tween("attr.style", function (d) {
      return tweenStyle(this, d, true);
    })
    .on("end", (d, i) => i === lastI && onLastEnd());
}

function expand(selection) {
  if (selection.empty()) return;
  return selection
    .transition("expand")
    .duration(EXPAND_DURATION)
    .tween("attr.style", function (d) {
      return tweenStyle(this, d);
    });
}
