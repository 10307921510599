import * as d3 from "d3";

angular
  .module("dealroom.analytics.linechart.axis", [
    "dealroom.analytics.linechart.service.config",
  ])
  .directive("drAnalyticsLinechartAxis", () => ({
    scope: {
      type: "@",
      drAnalyticsLinechartAxis: "<",
    },
    restrict: "A",
    controller: Controller,
  }));

Controller.$inject = ["$scope", "$element", "AnalyticsLinechartConfigService"];
function Controller($scope, $element, AnalyticsLinechartConfigService) {
  const TYPE = $scope.type;
  const DISPATCHER = $scope.drAnalyticsLinechartAxis;

  const pk = "drAnalyticsLinechartAxis-" + $scope.$id;

  const createTicks = TYPE === "x" ? createX : createY;

  const rootElement = d3
    .select($element[0])
    .classed("analytics-axis", true)
    .classed(`analytics-axis--${TYPE}`, true);

  DISPATCHER.on("update." + pk, createTicks);

  function createX(params) {
    const axisFn = d3.axisBottom;
    const axisParams = AnalyticsLinechartConfigService.XAXIS;

    const axis = axisFn(params.xScale).tickSize(0);
    const h = params.height + axisParams.name.top + params.r;

    rootElement.selectAll("*").remove();
    rootElement
      .attr("transform", `translate(0, ${h})`)
      .call(axis)
      .selectAll(".tick")
      .each(showText);

    const visibleKeys = rootElement
      .selectAll(".tick")
      .filter(function () {
        const text = d3.select(this).selectAll("text");
        // return text.size() > 0 && /\d/.test(text.text());
        return !text.empty();
      })
      .data()
      .map((dt) => dt.toString());
    DISPATCHER.call("visibleKeys", null, visibleKeys, params);

    function showText(dt) {
      const tick = d3.select(this);
      const text = tick.select("text");
      if (text.text().match(/AM$|PM$/g)) {
        tick.remove();
      } else if (dt.getDate() === 1) {
        // short months names
        text.text(d3.timeFormat("%b")(dt));
      }
    }
  }

  function createY({ yScale, width, maxValue, yPrefix }) {
    const axisFn = d3.axisLeft;
    const axisParams = AnalyticsLinechartConfigService.YAXIS;

    const [min, max] = yScale.domain();
    const range = max - min;
    const step = range / axisParams.ticks;
    const values = d3.range(min, max + step, step);
    const axis = axisFn(yScale)
      .tickSizeInner(-width)
      .tickSizeOuter(0)
      .tickValues(values)
      .tickFormat(format);
    const left =
      typeof maxValue === "number"
        ? AnalyticsLinechartConfigService.XAXIS.padding
        : 0;
    rootElement.selectAll("*").remove();
    rootElement.attr("transform", `translate(${left}, 0)`).call(axis);

    function format(value, i) {
      const defRounded = _format();
      if (max < 1000) {
        if (range >= axisParams.ticks) return defRounded;
        else return skipValues(defRounded, max > 1);
      } else {
        let rounded = value < 1000 ? defRounded : _format(",.2s");
        if (step > 100) return rounded;
        else {
          if (value > 1000) rounded = _format(",.3s");
          if (step > 10) return rounded;
          const passOdd =
            (axisParams.ticks % 2 === 0 && step > 2.5) ||
            (axisParams.ticks % 2 !== 0 && step > 5);
          return skipValues(rounded, passOdd);
        }
      }

      function _format(s = ".0f") {
        return d3.format(s)(value) + yPrefix;
      }

      function skipValues(rounded, passOdd) {
        if (i === 0 || i === axisParams.ticks) return rounded;
        if (passOdd) {
          if (axisParams.ticks % 2 === 0 && i % 2 === 0) return rounded;
          if (axisParams.ticks % 2 !== 0 && i % 2 !== 0) return rounded;
        }
        return "";
      }
    }
  }
}
