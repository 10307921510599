import { startOfDay } from "date-fns";

import { dealsHistory, getHistoryState } from "./utils/deals-history";

import type { State } from "./state";
import type { RootState } from "./types";
import type { IDealsDailyData, IDealsHistoryData } from "./utils/deals-history";
import type { GetterTree } from "vuex";

export const getters: GetterTree<State, RootState> = {
  fullHistory(state, localGetters, rootState): IDealsHistoryData {
    const deals = rootState.clientDashboard.deals.items;
    return dealsHistory(deals, state.actions);
  },

  historyState(state, localGetters) {
    return (date: Date): IDealsDailyData | void => {
      if (!state.actions.length || date < startOfDay(state.actions[0].dt)) {
        return;
      }
      return getHistoryState(localGetters.fullHistory, date);
    };
  },
};
