export class NotificationsHtmlHelper {
  unwrapClass: string = "notification-stacked--unwrap";
  viewedClass: string = "notification-stacked-item__unread-mark--viewed";

  toggleWrapping(el: Element) {
    const classList = el.classList;
    if (classList.contains(this.unwrapClass)) {
      classList.remove(this.unwrapClass);
    } else {
      classList.add(this.unwrapClass);
    }
  }

  toggleRead(el: Element) {
    if (el.classList.contains(this.viewedClass)) {
      el.classList.remove(this.viewedClass);
    } else {
      el.classList.add(this.viewedClass);
    }
  }

  isNoticeViewed(el: Element) {
    return el.classList.contains(this.viewedClass);
  }

  markAllAsRead(el: Element) {
    el.querySelectorAll(".notification-stacked-item__unread-mark").forEach(
      (m) => m.classList.add("notification-stacked-item__unread-mark--viewed"),
    );
  }
}
