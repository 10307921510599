<template>
  <div>
    <DrTooltip raw-content :content="tooltip">
      <div :class="$style.container">
        <div
          v-for="bar in bars"
          :key="bar.color"
          :class="$style.item"
          :style="{
            background: bar.color,
            width: bar.percentage + '%',
          }"
        >
          <span v-if="bar.percentage >= 10">{{
            isViewAbsolute ? bar.count : bar.percentage + "%"
          }}</span>
        </div>
      </div>
    </DrTooltip>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { makeBullet, makeGrid } from "@shared/ui/dr-progress-bar/utils";
import { DrTooltip } from "@shared/ui/dr-tooltip";

import type { Progress } from "@shared/ui/dr-progress-bar/types";

interface Props {
  progress: Progress;
  isViewAbsolute: boolean;
}
const props = defineProps<Props>();

function computePercentage(count: number, total: number): number {
  return Number((count / (total / 100)).toFixed(0));
}

const bars = computed(() => {
  return props.progress.items
    .filter((bar) => computePercentage(bar.count, props.progress.total) > 0)
    .map((bar) => ({
      ...bar,
      percentage: computePercentage(bar.count, props.progress.total),
    }));
});

const tooltip = computed(() => {
  return makeGrid(
    bars.value.length,
    4,
    `${bars.value
      .map((bar) => {
        const bullet = makeBullet(bar.color);
        const name = `<div style="color: gray">${bar.name}</div>`;
        const count = `<div style="text-align: right">${bar.count} <span style="color: gray">/</span></div>`;
        const percentage = `<div>${
          (bar.percentage.toFixed(0) || 1) + "%"
        }</div>`;

        return bullet + name + count + percentage;
      })
      .join("")}<div></div><div>Total: ${props.progress.total}</div>`,
  );
});
</script>

<style module lang="scss">
$border-radius: 2px;

.container {
  width: 100%;
  height: 12px;
  display: flex;
}
.item {
  height: 100%;
  color: white;
  font-size: 10px;
  line-height: 10px;
  padding: 1px 0 1px 4px;
  &:first-child {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
  &:last-child {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
