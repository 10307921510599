Controller.$inject = ["$scope", "$element"];
import * as d3 from "d3";

angular
  .module("dealroom.analytics.svg.linegradient", [])
  .directive("drAnalyticsFilterLinegradient", () => ({
    scope: {
      drAnalyticsFilterLinegradient: "@",
      lineGradientColors: "<",
      lineGradientHorizontal: "<",
    },
    controller: Controller,
    restrict: "A",
    controllerAs: "$ctrl",
  }));

function Controller($scope, $element) {
  const id = $scope.drAnalyticsFilterLinegradient;
  const horizontal = $scope.lineGradientHorizontal;
  const colors = $scope.lineGradientColors;
  const defs = d3.select($element[0]);

  const [tag, cls] = ["linearGradient", "lineargradient"];
  const gradient = defs
    .append(tag)
    .attr("class", cls)
    .attr("id", id)
    .attr("x2", (horizontal ? 100 : 0) + "%")
    .attr("x1", 0 + "%")
    .attr("y2", 0 + "%")
    .attr("y1", (horizontal ? 0 : 100) + "%");
  const step = 100 / (colors.length - 1);
  const stops = gradient.selectAll("stop").data(colors);
  stops.exit().remove();
  stops
    .enter()
    .append("stop")
    .merge(stops)
    .attr("stop-opacity", 1)
    .attr("offset", (color, i) => i * step + "%")
    .attr("stop-color", (color) => color);
}
