import tasksHtml from "./index.html?raw";

RequestsProgressDatagrid.$inject = [
  "TasksService",
  "ActivityVisibleCategoriesService",
];
angular
  .module("dealroom.analytics.datagrid-table.tasks.progress", [
    "dealroom.analytics.service.data",

    "dealroom.task",
  ])
  .component("drTasksProgressDatagrid", {
    bindings: { cls: "@" },
    template: tasksHtml,
    controller: RequestsProgressDatagrid,
  });

const CELLS = [
  {
    label: "Views",
    class: "datagrid-table__column--data",
    verbs: ["task_viewed"],
    src: "total",
    defaultSort: true,
  },
];

const TABLE_ROWS_LENGTH = 20;

function RequestsProgressDatagrid(
  TasksService,
  ActivityVisibleCategoriesService,
) {
  const $ctrl = this;

  $ctrl.cells = CELLS;

  $ctrl.nestByArray = ["task"];
  $ctrl.tableUid = "RequestsProgress";

  $ctrl.getRootItems = getRootItems;

  $ctrl.verbsForFilter = true;
  $ctrl.subParentFieldName = undefined;
  $ctrl.limitTo = TABLE_ROWS_LENGTH;

  $ctrl.showPath = true;

  function getRootItems() {
    if (!TasksService.isLoaded()) return [];

    return ActivityVisibleCategoriesService.visibleStatsSummary.taskIds.map(
      (taskId) => TasksService.tasks[taskId],
    );
  }
}
