import { DEFAULT_DEALS_VIEWS } from "@drVue/store/modules/common/custom-views/default";
import { copyView } from "./utils";

import type { CustomView } from "./types";

export class State {
  items: CustomView[] = [];
  current: CustomView = copyView(DEFAULT_DEALS_VIEWS[0]);
  isLoading: boolean = false;
  isError: boolean = false;
}
