<template>
  <div class="contact__info">
    <div v-if="name" class="contact__name">
      <DrTruncatedTextTooltip :content="name">
        {{ name }}
      </DrTruncatedTextTooltip>
    </div>
    <div v-if="title" class="contact__title">
      <DrTruncatedTextTooltip :content="title">
        {{ title }}
      </DrTruncatedTextTooltip>
    </div>
    <div v-if="contact.email" class="contact__email">
      <DrTruncatedTextTooltip :content="contact.email">
        <a :href="`mailto:${contact.email}`" target="_blank" @click.stop>
          {{ contact.email }}
        </a>
      </DrTruncatedTextTooltip>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";

interface Props {
  contact: OrgUser;
}

const props = defineProps<Props>();

const name = computed(() => {
  const firstname = props.contact.first_name || "";
  const lastname = props.contact.last_name || "";

  return `${firstname} ${lastname}`.trim() || "---";
});
const title = computed(() => {
  const c = props.contact;
  const at = `${c.title && c.company ? " at " : ""}`;

  return `${c.title || ""}${at}${c.company || ""}`.trim();
});
</script>
