import type { HEXColor } from "@drVue/utils/color";

export function makeBullet(color: HEXColor, width = 10, height = 10) {
  return `<div style="background: ${color}; width: ${width}px; height: ${height}px; border-radius: 50%"></div>`;
}

export function makeGrid(rows: number, cols: number, content: string) {
  return `<div style="display: grid; grid-template-columns: repeat(${cols}, auto); grid-template-rows: repeat(${rows}, auto); align-items: center; gap: 5px;">
            ${content}
          </div>`;
}
