import { cloneDeep, keyBy } from "lodash-es";
import { computed } from "vue";

import {
  type CustomView,
  type CustomViewColumn,
  CustomViewLayouts,
  CustomViewObjectTypes,
} from "@app/setups/types";
import { DrStore } from "@app/vue";
import { DEFAULT_TASK_GANTT_VIEW as DEFAULT_TASK_TIMELINE_VIEW } from "@app/vue/store/modules/common/custom-views/default";
import { handleUpdates, saveView } from "../TaskOverviewPage/viewUtils";

import type { DrVxeTableColumn } from "@app/vue/components/types";
import type { RootState } from "@app/vue/store/state";
import type { Store } from "vuex";

interface GanttColumn {
  field: string;
  title: string;
}

const ERR_MISSING_COLUMNS =
  "GANTT columns configuration is missing in the Task Timeline view. Please ensure the columns are properly defined.";

function getTaskTimelineView() {
  const taskViews = DrStore.getters["common/customViews/byObjectType"](
    CustomViewObjectTypes.Task,
  ) as CustomView[];

  return cloneDeep(
    taskViews.find(
      (v) => v.is_personal && v.layout === CustomViewLayouts.Timeline,
    ) ?? DEFAULT_TASK_TIMELINE_VIEW,
  );
}

export function saveTaskTimelineView(
  $store: Store<RootState>,
  updates: CustomViewColumn[],
) {
  const taskTimelineView = getTaskTimelineView();
  if (!taskTimelineView.settings.columns) {
    throw new Error(ERR_MISSING_COLUMNS);
  }

  handleUpdates(taskTimelineView.settings.columns, updates);
  saveView($store, taskTimelineView);
}

export function resetTaskTimelineView($store: Store<RootState>) {
  const view = getTaskTimelineView();
  view.settings.columns = cloneDeep(
    DEFAULT_TASK_TIMELINE_VIEW.settings.columns,
  );

  saveView($store, view);
}

// Typically, the tableColumns object contains all columns for the table.
// However, in this case, we must define the columns separately.
const ganttColumns: readonly GanttColumn[] = Object.freeze([
  Object.freeze({
    field: "_text",
    title: "Request",
  }),
  Object.freeze({
    field: "_status",
    title: "Status",
  }),
  Object.freeze({
    field: "_start_date",
    title: "Start date",
  }),
  Object.freeze({
    field: "_due_date",
    title: "Due date",
  }),
  Object.freeze({
    field: "_assignees",
    title: "Assignees",
  }),
  Object.freeze({
    field: "_priority",
    title: "Priority",
  }),
]);

export default function () {
  const ganttColumnConfigs = computed((): DrVxeTableColumn[] => {
    const taskTimelineView = getTaskTimelineView();
    if (!taskTimelineView.settings.columns) {
      throw new Error(ERR_MISSING_COLUMNS);
    }

    const cfgByField = keyBy(taskTimelineView.settings.columns, "field");

    return ganttColumns
      .slice()
      .sort((a, b) => {
        const aOrder = cfgByField[a.field]?.order ?? 0;
        const bOrder = cfgByField[b.field]?.order ?? 0;

        return aOrder - bOrder;
      })
      .map(
        (col, i): DrVxeTableColumn => ({
          ...col,
          visible: !cfgByField[col.field]?.hidden,
          // Workaround: Pass 'order' through DrVxeTableColumn.params.order
          // to the <DhtmlxGantt> component.
          params: {
            order: i,
          },
        }),
      );
  });

  return ganttColumnConfigs;
}
