import { format, isDate, isValid } from "date-fns";
import { toDate } from "date-fns-tz";

import { USER_DATA } from "../../setups";

angular.module("dealroom.date-fns", []).filter("dfnsFormat", function () {
  return function (input, formatString) {
    if (input === null || input === undefined) return "";

    if (typeof input === "string") {
      input = toDate(input, { timeZone: USER_DATA.profile.timezone });
    }

    const isNumber = typeof input === "number" && !Number.isNaN(input);
    if (isNumber || (isDate(input) && isValid(input))) {
      return format(input, formatString);
    }

    return "";
  };
});
