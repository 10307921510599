TaskDetailsPageController.$inject = [
  "$scope",
  "$state",
  "$stateParams",
  "RoomConfig",
  "TasksService",
  "CategoriesService",
  "resolvedWhenTasksAndCategoriesLoaded",
];

export default function TaskDetailsPageController(
  $scope,
  $state,
  $stateParams,
  RoomConfig,
  TasksService,
  CategoriesService,
  resolvedWhenTasksAndCategoriesLoaded,
) {
  const routeParams = {};
  if (RoomConfig.enableListsTabs) {
    const task = TasksService.tasksList.find(
      (t) => t.key === $stateParams.taskId,
    );

    routeParams.listId = task
      ? task.category_id
      : CategoriesService.rootCategories[0]?.id;
  }

  $state
    // Switch back to the list view, but don't update the URL.
    .go("tasks.list.overview", routeParams, { location: false })
    .then(() => {
      if (!window.DR.isNewRequestDetailsOpened) {
        TasksService.openTaskDetails($stateParams.taskId);
      }
    });
}
