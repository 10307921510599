import { Urls } from "@setups/urls";
import { ApiService } from "@drVue/api-service";
import { postAndGetFile } from "@drVue/common";

import type { UserRoomFinding, UserTask } from "./types";

export class YourWorkApiService {
  api: ApiService = new ApiService();

  getTasks() {
    const url: string = this.api.Urls["api:client-dashboard:your-work-tasks"]();
    return this.api.get<UserTask[]>(url);
  }

  exportTasks(ids: UserTask["id"][]) {
    const url = Urls["api:client-dashboard:your-work-tasks-export-xlsx"]();
    postAndGetFile(url, {
      tasks_ids: ids.join(","),
    });
    return;
  }

  getFindings() {
    const url: string =
      this.api.Urls["api:client-dashboard:your-work-findings"]();
    return this.api.get<UserRoomFinding[]>(url);
  }
}
