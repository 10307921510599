<template>
  <div v-show="false" ref="editorWrapperRef">
    <DrEditor :model-value="description!" :editable="false" />
  </div>
  <div :class="$style.container">
    <div :class="$style.header">
      <span>
        <span :class="$style.headerEmphasize">Task</span>
        added by {{ addedBy }} • {{ dateAdded }}
      </span>
      <ElDropdown @command="handleMenuCommand">
        <template #default>
          <DrIcon :class="$style.menuTrigger" name="ellipsis-h" />
        </template>
        <template #dropdown>
          <div :class="$style.menu">
            <ElDropdownItem command="toggle">
              <span
                :class="{
                  [$style.blackText]: !is_resolved,
                  [$style.grayText]: is_resolved,
                }"
              >
                Complete
              </span>
              <span :class="$style.grayText">&nbsp;/&nbsp;</span>
              <span
                :class="{
                  [$style.blackText]: is_resolved,
                  [$style.grayText]: !is_resolved,
                }"
              >
                Uncomplete
              </span>
            </ElDropdownItem>
            <ElDropdownItem command="edit">Edit</ElDropdownItem>
            <ElDropdownItem command="delete">Delete</ElDropdownItem>
          </div>
        </template>
      </ElDropdown>
    </div>
    <div :class="$style.title">
      <DrSvgIcon
        :name="
          `${is_resolved ? 'round-check-green' : 'round-check-gray'}` as SvgIconName
        "
        :class="$style.checkIcon"
        @click="$emit('toggle-resolved', id, !is_resolved)"
      />
      <span :class="{ [$style.strokeText]: is_resolved }">{{ title }}</span>
    </div>
    <div :class="$style.body">
      <span :class="$style.grayText">Due date:</span>
      <span :class="$style.blackText">
        {{ dueDate }} <span :class="$style.grayText">{{ dueWithin }}</span>
      </span>

      <span :class="$style.grayText">Assignee:</span>
      <DrUserBox v-if="assignedTo" disable-tooltip :users="[assignedTo]" />
      <span v-else-if="assignedToUnknown" :class="$style.grayText">
        Anonymous
      </span>
      <span v-else :class="$style.grayText">Not assigned</span>

      <template v-if="descriptionText">
        <span :class="$style.grayText">Description:</span>
        <span :class="$style.blackText">{{ descriptionText }}</span>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
} from "date-fns";
import { computed, nextTick, ref, watch } from "vue";
import { DrIcon } from "@shared/ui/dr-icon";
import { DrSvgIcon, type SvgIconName } from "@shared/ui/dr-svg-icon";
import { DrUserBox } from "@shared/ui/dr-user-box";
import DrEditor from "@shared/ui/editor";

import { drUserTime } from "@drVue/filters/drUserTime";
import $utils from "@drVue/utils";

import type { DealTask } from "../use-deal-tasks";
import type { User } from "@shared/ui/dr-user-box";

interface Props extends DealTask {
  assigneesDict: Record<NonNullable<DealTask["assigned_to_id"]>, User>;
}

interface Emits {
  (e: "toggle-resolved", id: DealTask["id"], status: boolean): void;
  (e: "remove", id: DealTask["id"]): void;
  (e: "edit", id: DealTask["id"]): void;
}
const emit = defineEmits<Emits>();

const props = defineProps<Props>();

const editorWrapperRef = ref<InstanceType<typeof HTMLSpanElement> | null>(null);
const descriptionText = ref("");

const assignedTo = computed(() => {
  const user = props.assigned_to_id
    ? props.assigneesDict[props.assigned_to_id]
    : null;
  if (!user) return;

  // just to fix types
  if (user.name === null) {
    user.name = "";
  }
  if (user.first_name === null) {
    user.first_name = "";
  }
  if (user.last_name === null) {
    user.last_name = "";
  }

  return user;
});

const assignedToUnknown = computed(
  () => !assignedTo.value && props.assigned_to_id,
);

const addedBy = computed<string>(() => {
  const user = props.assigneesDict[props.added_by_id];

  if (!user) return "Anonymous";
  return `${user.first_name} ${user.last_name}`;
});

const dateAdded = computed<string>(() => {
  return drUserTime(props.date_added, "short-dt") ?? "N/A";
});

const dueDate = computed<string>(() => {
  return drUserTime(props.due_datetime, "full-dt") ?? "N/A";
});

const dueWithin = computed<string | null>(() => {
  if (props.is_resolved) {
    return "";
  }

  const currentDate = new Date();
  const isOverdue = currentDate > props.due_datetime;
  let deltaString = "";

  const daysDiff = isOverdue
    ? differenceInDays(currentDate, props.due_datetime)
    : differenceInDays(props.due_datetime, currentDate);
  const weeksDiff = isOverdue
    ? differenceInWeeks(currentDate, props.due_datetime)
    : differenceInWeeks(props.due_datetime, currentDate);
  const monthsDiff = isOverdue
    ? differenceInMonths(currentDate, props.due_datetime)
    : differenceInMonths(props.due_datetime, currentDate);
  const yearsDiff = isOverdue
    ? differenceInYears(currentDate, props.due_datetime)
    : differenceInYears(props.due_datetime, currentDate);

  if (daysDiff < 1) {
    deltaString = "(today)";
  } else if (daysDiff <= 6) {
    deltaString = `${daysDiff} ${$utils.text.pluralize("day", daysDiff)}`;
  } else if (weeksDiff <= 3) {
    deltaString = `${weeksDiff} ${$utils.text.pluralize("week", weeksDiff)}`;
  } else if (monthsDiff <= 11) {
    deltaString = `${monthsDiff} ${$utils.text.pluralize("month", monthsDiff)}`;
  } else {
    deltaString = `${yearsDiff} ${$utils.text.pluralize("year", yearsDiff)}`;
  }

  if (isOverdue) {
    return `(${deltaString} overdue)`;
  } else {
    if (deltaString === "(today)") {
      return deltaString;
    }
    return `(In ${deltaString})`;
  }
});

function handleMenuCommand(command: "toggle" | "edit" | "delete") {
  switch (command) {
    case "toggle":
      emit("toggle-resolved", props.id, !props.is_resolved);
      break;
    case "delete":
      emit("remove", props.id);
      break;
    case "edit":
      emit("edit", props.id);
  }
}

watch(
  () => props.description,
  () => {
    nextTick(() => {
      descriptionText.value = $utils.text.getSingleLineFromHtml(
        editorWrapperRef.value?.innerHTML ?? "",
      );
    });
  },
  { immediate: true },
);
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.container {
  border-radius: 8px;
  padding: 12px 12px 20px 12px;
  background: white;
  box-shadow: 0 2px 3px 0 rgba(9, 25, 56, 0.05);
  margin-bottom: 8px;
}

.header {
  font: typography.$caption_regular;
  color: colors.$pr-500;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.headerEmphasize {
  color: colors.$sc-600;
}

.title {
  font: typography.$subtitle_semibold;
  font-weight: 500;
  margin-bottom: 12px;
  display: flex;
  gap: 6px;
  align-items: center;
}

.body {
  display: grid;
  grid-template-columns: 104px auto;
  row-gap: 8px;
  font: typography.$caption_regular;
}

.grayText {
  color: colors.$pr-500;
}

.blackText {
  color: colors.$pr-900;
}

.strokeText {
  text-decoration: line-through;
}

.checkIcon {
  cursor: pointer;
}

.menuTrigger {
  cursor: pointer;
  color: colors.$pr-350;
  transition: color 150ms ease-in-out;
  &:hover {
    color: black;
  }
}

.menu {
  padding: 12px;

  :global(.el-dropdown-menu__item) {
    padding: 4px 8px;
    border-radius: 8px;
    &:hover {
      color: unset;
    }
  }
}
</style>
