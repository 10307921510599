<template>
  <div class="auth-form">
    <div class="auth-form__title text-center">Single Sign On</div>
    <div class="auth-form__description text-center">
      Your organization uses Single Sign On (SSO) with
      {{ APP_SETTINGS.WEBSITE.NAME }}. Please sign in using your SSO
      credentials.
    </div>

    <ElForm
      ref="ssoFormRef"
      label-position="top"
      require-asterisk-position="right"
      :model="ssoFormModel"
      :rules="formRules"
      :disabled="isFormSubmitting"
      @submit.prevent="submit(ssoFormRef)"
    >
      <ElFormItem :error="formErrors.email" prop="email" required>
        <ElInput
          v-model="ssoFormModel.email"
          autocomplete="username"
          placeholder="Work Email"
          size="large"
        >
          <template #prepend>
            <i class="fal fa-envelope" />
          </template>
        </ElInput>
      </ElFormItem>

      <ElAlert
        v-if="formErrors.non_field_errors"
        class="auth-form__alert"
        :title="formErrors.non_field_errors"
        type="error"
        show-icon
        :closable="false"
      />

      <div class="text-right">
        <ElButton
          type="primary"
          native-type="submit"
          class="auth-form__submit"
          size="large"
          :loading="isFormSubmitting"
        >
          Log in
        </ElButton>
      </div>
    </ElForm>
    <div class="text-center">
      <RouterLink
        v-if="!isFormSubmitting"
        class="auth-form__sign-in-with-email"
        to="/auth"
      >
        <u>Sign in without SSO</u>
      </RouterLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, ref } from "vue";
import { useFormHelper } from "@shared/ui/dr-form";

import { APP_SETTINGS } from "@setups/data";
import { $notifyDanger } from "@drVue/common";
import { AuthApiService } from "@drVue/components/auth/AuthApiService";

import type { FormInstance, FormRules } from "element-plus";

interface SsoLoginModel {
  email: string;
}
const ssoFormRef = ref<FormInstance>();
const ssoFormModel = reactive<SsoLoginModel>({
  email: "",
});
const formRules = reactive<FormRules>({
  email: [
    {
      type: "email",
      required: true,
      message: "Email is required.",
    },
  ],
});

const { formErrors, hookFormSubmitPromise, isFormSubmitting } =
  useFormHelper<SsoLoginModel>();

const api = new AuthApiService();

const submit = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid) => {
    if (!valid) {
      return;
    }

    hookFormSubmitPromise(
      api.sign_in_sso_by_email({ email: ssoFormModel.email }),
    ).then(
      ({ data }) => {
        window.location.href = data.redirect_url;
      },
      () => $notifyDanger(`Failed to login with SSO.`),
    );
  });
};
</script>
