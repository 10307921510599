import { createApp } from "vue";

import UnpaidApp from "../client-dashboard/unpaid-app.vue";
import { initSentry } from "../initSentry";

export function createRoomUnpaidApp() {
  const app = createApp(UnpaidApp);
  initSentry(app, null);
  return app;
}
