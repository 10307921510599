<template>
  <ElButton size="small" ref="buttonRef" @click="toggleVisible">
    Create field &nbsp;
    <i class="far fa-plus" />
  </ElButton>

  <ElPopover
    ref="popoverRef"
    :virtual-ref="buttonRef"
    trigger="click"
    :width="POPUP_SIZES.folderMenu.width"
    :visible="visible"
  >
    <DrDynamicFormClassic
      ref="form"
      :entity="model"
      :schema="labelSchema"
      :errors="formErrors"
      :disabled="isFormSubmitting"
      @update="model[$event.field as keyof Model] = $event.value"
      @submit.stop="submit"
    />
    <ElAlert
      v-if="formErrors.non_field_errors"
      type="error"
      :title="formErrors.non_field_errors"
    />
    <div :class="$style.formActions">
      <ElButton @click="resetAndClose" :disabled="isFormSubmitting">
        Cancel
      </ElButton>
      <ElButton
        type="primary"
        :loading="isFormSubmitting"
        @click="submit"
        :disabled="isFormSubmitting"
      >
        Add field
      </ElButton>
    </div>
  </ElPopover>
</template>

<script setup lang="ts">
import { type ComponentInstance, computed, reactive, ref } from "vue";
import { POPUP_SIZES } from "@shared/ui/constants";
import { DrDynamicFormClassic } from "@shared/ui/dr-dynamic-form";
import { validateString } from "@shared/ui/dr-dynamic-form/utils";
import { useFormHelper } from "@shared/ui/dr-form";

import { AiReportsEvent, insightTrack } from "@app/insight";
import { $notifySuccess } from "@drVue/common";
import { FieldSchemaType } from "@drVue/components/client-dashboard/dynamic-form/types";
import { useAiReportsStore } from "@drVue/components/room/ai/AiReportsStore";
import { pinia } from "@drVue/store/pinia";

import type { AiReport } from "@drVue/components/room/ai/types";
import type { FormSchema } from "@shared/ui/dr-dynamic-form/types";

interface Props {
  reportId: AiReport["id"];
}

const props = defineProps<Props>();

const visible = ref(false);
const buttonRef = ref();
const popoverRef = ref();

const aiReportsStore = useAiReportsStore(pinia);

const form = ref<ComponentInstance<typeof DrDynamicFormClassic> | undefined>();

const model = reactive({
  label: "",
});
type Model = typeof model;

const { formErrors, hookFormSubmitPromise, isFormSubmitting, resetErrors } =
  useFormHelper<Model>();

const labelSchema = computed(
  (): FormSchema => [
    {
      type: FieldSchemaType.Text,
      required: true,
      isReadOnly: isFormSubmitting.value,
      prop: "label",
      label: "Label",
      placeholder: "Field label",
      rules: validateString().required("This field may not be blank"),
    },
  ],
);

const submit = async () => {
  if (isFormSubmitting.value) return;

  resetErrors();

  const result = await form.value?.validate().catch((error) => error);
  if (result !== true) return;
  const label = model.label;

  hookFormSubmitPromise(
    aiReportsStore.generateAndCreateNewField(props.reportId, label),
    "Failed to create report field",
  ).then(() => {
    insightTrack(AiReportsEvent.FieldAdded, { source: "manually" });

    $notifySuccess(`Report field "${label}" created`);
    resetAndClose();
  });
};

const resetForms = () => {
  resetErrors();
  model.label = "";

  form.value?.reset({
    label: "",
  });
};

const handleClose = () => {
  visible.value = false;
};

const resetAndClose = () => {
  resetForms();
  handleClose();
};

const toggleVisible = () => {
  if (visible.value) {
    resetAndClose();
  } else {
    visible.value = true;
  }
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.formActions {
  display: flex;
  justify-content: flex-end;
  margin-top: spacing.$s;
}
</style>
