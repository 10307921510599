<template>
  <div>
    <DrLoader v-if="isLoading" />
    <div v-if="!isLoading">
      <br />
      <div class="dash-settings__header">
        <span class="dash-settings__header-text">
          {{ provider.name }} Integration
        </span>
        <span class="dash-settings__header-description" />
      </div>

      <!-- @vue-skip: integrationData -->
      <div v-if="!isEditing">
        <div v-if="integrationData.entity_id">
          <div>
            <p><b>Title:</b> {{ integrationData.title }}</p>
            <p>
              <b>Always Update Users Through SAML Attributes On Login:</b>
              <span v-if="integrationData.allow_update_user_on_login">
                enabled</span
              >
              <span v-else> disabled</span>
            </p>
          </div>

          <hr />
          <strong>Identity provider settings</strong>
          <br /><br />

          <div>
            <p><b>Entity ID:</b> {{ integrationData.entity_id }}</p>
            <p><b>Single Sign On URL:</b> {{ integrationData.sso_url }}</p>
            <p><b>Single Log Out URL:</b> {{ integrationData.slo_url }}</p>
            <p>
              <b>x509 public certificate:</b> {{ integrationData.x509cert }}
            </p>
          </div>

          <div>
            <hr />
            <strong>Attribute mapping</strong>
            <br /><br />

            <p><b>Identifier:</b> {{ integrationData.mapping.ident }}</p>
            <p><b>Email:</b> {{ integrationData.mapping.email }}</p>
            <p><b>First Name:</b> {{ integrationData.mapping.first_name }}</p>
            <p><b>Last Name:</b> {{ integrationData.mapping.last_name }}</p>
            <p><b>Title:</b> {{ integrationData.mapping.title }}</p>
            <p><b>Phone:</b> {{ integrationData.mapping.office_number }}</p>
          </div>

          <ElButton size="small" @click="isEditing = true">
            Update {{ provider.name }} integration
          </ElButton>
        </div>
        <div v-if="!integrationData.entity_id">
          <ElButton
            size="small"
            class="text-uppercase"
            @click="isEditing = true"
          >
            Create {{ provider.name }} integration
          </ElButton>
        </div>
      </div>
      <!-- !isEditing -->

      <!-- @vue-skip: integrationData -->
      <div v-if="isEditing">
        <div v-if="!integrationData.entity_id" class="dash-settings__header">
          <p>
            Before connecting DealRoom to your Identity Provider (IdP), it’s
            important to first register DealRoom as an application on the IdP’s
            side.
          </p>
          <p>DealRoom's SAML endpoints are:</p>
          <ul>
            <li>
              Metadata (Entity ID/Metadata URL):
              <a href="#" @click="copyLink(entityIdUrl)"
                ><samp>{{ entityIdUrl }}</samp></a
              >
              (click to copy)
            </li>
            <li>
              ACS (Assertion Consumer Service):
              <a href="#" @click="copyLink(acsUrl)"
                ><samp>{{ acsUrl }}</samp></a
              >
              (click to copy)
            </li>
            <li>
              SLS (Single Logout Service):
              <a href="#" @click="copyLink(slsUrl)"
                ><samp>{{ slsUrl }}</samp></a
              >
              (click to copy)
            </li>
          </ul>
        </div>

        <hr />
        <p>
          <strong>Identity provider settings</strong>
        </p>

        <EditForm
          v-if="isEditing"
          :integration-data="integrationData"
          :provider="provider"
          @close="stopEditing"
        />
      </div>
      <!-- isEditing -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DrLoader from "@shared/ui/dr-loader";

import { Urls } from "@setups/index";
import {
  OpenIDIntegrationApiService,
  OpenIDProviders,
  OpenIDProvidersService,
} from "@drVue/api-service/client-dashboard";
import { $notifyDanger, $notifySuccess, copyToClipboard } from "@drVue/common";
import EditForm from "./EditForm";

import type { OpenIDIntegration } from "@drVue/api-service/client-dashboard";

interface Data {
  isLoading: boolean;
  isEditing: boolean;
  entityIdUrl: any;
  acsUrl: any;
  slsUrl: any;
  integrationData: { [key: string]: any } | null;
  provider: any;
}

export default defineComponent({
  name: "SAMLIntegration",
  components: {
    DrLoader,
    EditForm,
  },
  data(): Data {
    return {
      isLoading: true,
      isEditing: false,
      entityIdUrl: Urls["saml_metadata"](),
      acsUrl: Urls["saml_acs"](),
      slsUrl: Urls["saml_sls"](),
      integrationData: null,
      provider: new OpenIDProvidersService().getById(OpenIDProviders.Saml),
    };
  },
  mounted() {
    this.isLoading = true;
    const api = new OpenIDIntegrationApiService(this.provider.id);
    api
      .details()
      .then((value: OpenIDIntegration) => {
        this.isLoading = false;
        this.integrationData = value;
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          // integration is not setup yet
          this.isLoading = false;
          this.integrationData = {
            title: this.provider.name,
            mapping: {},
            allow_update_user_on_login: true,
          };
          return;
        }
        $notifyDanger(`Failed to load ${this.provider.name} integration info`);
        throw error;
      });
  },
  methods: {
    copyLink(link: string) {
      if (copyToClipboard(link)) {
        $notifySuccess("The URL has been copied.");
      } else {
        $notifyDanger("Failed to copy URL to clipboard.");
      }
    },
    stopEditing(updatedIntegration: OpenIDIntegration) {
      if (updatedIntegration) {
        this.integrationData = updatedIntegration;
      }
      this.isEditing = false;
    },
  },
});
</script>
