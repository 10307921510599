<template>
  <div class="rooms-section">
    <!-- Header -->
    <div class="header rooms-section__header">
      <div class="header__title">Deals {{ `(${dealsOfContact.length})` }}</div>

      <ElButton
        v-if="false"
        class="header__invite-button"
        type="text"
        size="default"
        @click="() => {}"
      >
        <template #icon>
          <i class="fas fa-user-plus" />
        </template>
        Link to deal
      </ElButton>
    </div>
    <!-- / Header -->

    <div class="rooms-section__rooms-list-wrapper">
      <div class="rooms-section__rooms-list">
        <div v-if="dealsOfContact.length === 0" class="no-rooms-text">
          This user hasn’t been added to any deal
        </div>
        <a
          v-for="deal in dealsOfContact"
          :key="deal.id"
          class="room rooms-section__room"
          :href="deal.room?.permalink"
          target="_blank"
        >
          <ElImage
            v-if="deal.room?.logo"
            class="room__logo"
            fit="contain"
            :src="deal.room.logo"
          />

          <span v-else class="room__logo room__logo--placeholder">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="512"
              height="512"
              viewBox="0 0 512 512"
            >
              <path
                d="M0,512V0H512V512H0ZM223.368,67.1H67.1V444.9H223.368V67.1Zm221.53,0H288.632V186.6H444.9V67.1Zm0,184.762H288.632V444.9H444.9V251.864Z"
              />
            </svg>
          </span>

          <span class="room__name-n-group">
            <span class="room__name">
              {{ deal.title }}
            </span>
          </span>

          <span
            class="room__remove-btn"
            @click.stop.prevent="remove(user, deal)"
          >
            <i class="fa fa-remove" />
          </span>
        </a>
        <!-- / Room -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { UpdateContactsPayload } from "@drVue/store/modules/client-dashboard/deals/DealsActions";
import type { Deal } from "@drVue/store/modules/client-dashboard/deals/types";
import type { OrgUser } from "@drVue/store/modules/client-dashboard/org-users/types";
import type { PropType } from "vue";

export default defineComponent({
  name: "DealsList",
  props: {
    user: { required: true, type: Object as PropType<OrgUser> },
    searchQuery: { default: "", type: String as PropType<string> },
  },
  computed: {
    dealsOfContact(): Deal[] {
      return this.$store.getters["clientDashboard/deals/getDealsOfContact"](
        this.user.id,
      );
    },
  },
  methods: {
    remove(contact: OrgUser, deal: Deal) {
      this.$confirm(
        `Are you sure you want to remove the contact?`,
        `Remove contact`,
        {
          confirmButtonClass: "el-button--warning",
          confirmButtonText: "Remove",
          cancelButtonText: "Cancel",
          type: "warning",
        },
      ).then(() => {
        this.$store.dispatch("clientDashboard/deals/updateContacts", {
          dealId: deal.id,
          usersIdsToAdd: [],
          usersIdsToRemove: [contact.id],
        } as UpdateContactsPayload);
      });
    },
  },
});
</script>

<style scoped lang="scss">
@use "@app/styles/scss/_colors.scss";

.no-rooms-text {
  padding: 7px 25px 8px;
  color: colors.$pr-500;
}

.room {
  padding: 7px 25px 8px;
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  z-index: 1;
  cursor: pointer;
  user-select: none;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0 auto auto 0;
    background: rgba(colors.$pr-50, 0);
    transition: background-color 0.15s ease;
  }

  &:hover {
    background: rgba(colors.$pr-50, 1);
  }

  &__logo {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    border-radius: 3px;
    position: relative;
    overflow: hidden;

    &--placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
      background: colors.$pr-300;

      svg {
        width: 50%;
        height: 50%;
        fill: #fff;
      }
    }
  }

  &__name-n-group {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__name {
    margin-bottom: 2px;
    font-weight: 600 !important;
    font-size: 14px;
    line-height: 19px;
    color: colors.$pr-800;
  }
  &__group {
    font-size: 13px;
    line-height: 18px;
    color: colors.$pr-500;
  }

  &__remove-btn {
    min-width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
    color: rgba(colors.$pr-400, 0.9);
    transition: color 0.15s ease;

    &:hover {
      color: rgba(colors.$red, 1);
    }
  }
}

// Header
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: colors.$pr-800;
  }

  &__invite-button {
    align-self: center;
  }
}

// Rooms section
.rooms-section {
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  &__header {
    padding-top: 8px;
    margin-bottom: 18px;
  }

  &__rooms-list-wrapper {
    width: calc(100% + 50px);
    margin: 0 -25px;
  }
  &__rooms-list {
    max-height: calc(50vh - 60px);
    padding-bottom: 18px;
    box-sizing: border-box;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
</style>
