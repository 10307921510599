Controller.$inject = [
  "$scope",
  "$element",
  "$compile",
  "AnalyticsColorsSchemeFabric",
];
import "./column";

import * as d3 from "d3";

import dataToRows from "./data";

angular
  .module("dealroom.analytics.linechart.legend", [
    "dealroom.analytics.linechart.legend.column",

    "dealroom.analytics.service.colors-scheme",
  ])
  .component("drAnalyticsLinechartLegend", {
    bindings: {
      dispatcher: "<",
    },
    controller: Controller,
  });

const CLASSES = {
  row: "analytics-card__row",
  column: "analytics-card__column analytics-card__column--md",
};
const COLUMN_SELECTOR = ".analytics-card__column";

function Controller($scope, $element, $compile, AnalyticsColorsSchemeFabric) {
  const $ctrl = this;
  const pk = "drAnalyticsLinechartLegend";
  const rootElement = d3.select($element[0]);

  $ctrl.$onInit = function () {
    $ctrl.dispatcher.on("update." + pk, update);
  };

  function update({ data }) {
    data = dataToRows(data, AnalyticsColorsSchemeFabric.EMPTY_COLOR);

    const rows = getRows(data);
    const columns = rows.selectAll(COLUMN_SELECTOR).data(
      (columns) => columns,
      ({ key }, i) => key || i,
    );

    columns.exit().each(removeColumn);

    columns
      .enter()
      .append(createColumn)
      .merge(columns)
      .each(renderColumn)
      .each(bindHover)
      .each(updateTotal);

    function removeColumn(d) {
      const column = d3.select(this);
      angular.element(this).scope().$destroy();
      if (d.key) {
        $ctrl.dispatcher.call("removeHover", null, column);
      }
      column.remove();
    }

    function bindHover(d) {
      const column = d3.select(this);
      if (d.key) {
        $ctrl.dispatcher.call("addHover", null, column);
      }
    }

    function renderColumn(d) {
      this.setAttribute("dr-analytics-linechart-legend-column", "::d");
      const columnScope = $scope.$new();
      columnScope.d = d;
      $compile(this)(columnScope);
      columnScope.$digest();
    }

    function createColumn(d) {
      const element = document.createElement(d.link ? "a" : "div");
      if (d.link) {
        element.href = d.link;
        element.target = "_blank";
      }
      element.className = CLASSES.column;
      return element;
    }

    function updateTotal(d) {
      const column = d3.select(this);
      column
        .select("[dr-analytics-linechart-legend-column-value]")
        .html(`&nbsp;-&nbsp;${d.label}`);
    }

    function getRows(data) {
      const rows = rootElement.selectAll("." + CLASSES.row).data(data);
      rows.exit().each(removeRow);

      return rows.enter().append("div").attr("class", CLASSES.row).merge(rows);

      function removeRow() {
        const row = d3.select(this);
        row.selectAll(COLUMN_SELECTOR).each(removeColumn);
        row.remove();
      }
    }
  }
}
