import DrStore from "@drVue/store";

angular
  .module("dealroom.analytics.card.burndown.data.tick.counter", [
    "dealroom.task",
    "dealroom.analytics.service.data",
  ])
  .service("AnalyticsBurndownTicksCounter", AnalyticsBurndownTicksCounter);

AnalyticsBurndownTicksCounter.$inject = ["ActivityTasksService"];
function AnalyticsBurndownTicksCounter(ActivityTasksService) {
  return { create };

  function create(data) {
    // << {taskId: [d, .. ], ..}
    // >> {dt: state, ..}
    const states = Object.keys(data).reduce((bucket, taskId) => {
      const taskStates = getTaskStates(taskId, data[taskId]);
      taskStates.forEach(({ dt, state }) => {
        if (bucket[dt] === undefined) bucket[dt] = getEmptyState();
        Object.keys(state).forEach(
          (field) => (bucket[dt][field] += state[field]),
        );
      });
      return bucket;
    }, {});
    states.empty = getEmptyState();
    return states;
  }

  function getTaskStates(taskId, data) {
    const task = ActivityTasksService.tasks[taskId];
    const isResolvedNow = isResolvedStatus(task.status_id);

    if (task.is_archived) data = removeActivityAfterArchiving(data);
    else data = data.filter((d) => d.verb !== "task_is_archived");

    const nestedByDt = data.reduce((bucket, d) => {
      const type = ["task_add", "task_status", "task_status"].includes(d.verb)
        ? d.verb
        : null;
      if (type === null) return bucket;

      if (bucket[d.date] === undefined) bucket[d.date] = {};

      if (type === "task_status") {
        if (bucket[d.date][type] === undefined) {
          bucket[d.date][type] = [d, d];
        } else {
          const [first, last] = bucket[d.date][type];
          if (d.dt < first.dt) bucket[d.date][type][0] = d;
          else if (d.dt > last.dt) bucket[d.date][type][1] = d;
        }
      } else {
        bucket[d.date][type] = true;
      }

      return bucket;
    }, {});

    const states = Object.keys(nestedByDt).map((dt) => {
      const currStats = nestedByDt[dt];
      const state = getEmptyState();

      const status = currStats["task_status"] || [];
      let fromResolved = _getStatus(status[0], "old_value_id");
      let toResolved =
        fromResolved === undefined
          ? undefined
          : _getStatus(status[1], "new_value_id");

      if (currStats["task_add"] === true) {
        state.created = 1;
        state.differenceTotal += 1;
        state.differenceOpened += 1;

        fromResolved = false;
        // task can be created with "resolved" status
        if (toResolved === undefined) {
          toResolved = findNextStatus(data, isResolvedNow);
        }
      }

      if (currStats["task_is_archived"] === true) {
        state.archived = 1;
        state.differenceTotal -= 1;

        const isOpen =
          (fromResolved === false && toResolved === false) ||
          (fromResolved === undefined && isResolvedNow === false);
        if (isOpen) {
          state.differenceOpened -= 1;
        }
      }

      if (fromResolved === false && toResolved === true) {
        state.differenceOpened -= 1;
      } else if (fromResolved === true && toResolved === false) {
        state.differenceOpened += 1;
      }

      if (fromResolved) state.fromResolved = 1;
      if (toResolved) state.toResolved = 1;

      return { dt, state };

      function _getStatus(d, field) {
        if (!d) return undefined;
        return isResolvedStatus(d.content[field]);
      }
    });
    return states;
  }

  function findNextStatus(data, isResolvedNow) {
    const d = data
      .filter(({ verb }) => verb === "task_status")
      .sort(datesAsc)[0]; // 1 ... 31

    return d ? isResolvedStatus(d.content["old_value_id"]) : isResolvedNow;
  }

  function isResolvedStatus(id) {
    return DrStore.state.room.tasksStatuses.byTypes.resolved.includes(id);
  }

  function removeActivityAfterArchiving(data) {
    // if !archivedDt >>
    //   task was archived earlier than this period
    const archiveD = data.filter(isArchiveActivity).sort(datesAsc).pop();

    if (!archiveD) return [];

    return data.filter(isBeforeArchivingOrIsOtherArching);

    function isBeforeArchivingOrIsOtherArching(d) {
      if (d.date <= archiveD.date) return false;
      if (d.verb === "task_is_archived" && d.id !== archiveD.date) return false;
      return true;
    }

    function isArchiveActivity(d) {
      return d.verb === "task_is_archived" && d.content.new_value === true;
    }
  }
}

function datesAsc(a, b) {
  return d3.ascending(new Date(a.dt), new Date(b.dt));
}

function getEmptyState() {
  return {
    differenceTotal: 0,
    differenceOpened: 0,

    archived: 0,
    created: 0,
    toResolved: 0,
    fromResolved: 0,
  };
}
