<template>
  <div
    v-if="!isCurrentUser"
    class="task-user-reminder"
    :class="{ 'task-user-reminder--already-sent': isSentAlready }"
    @click="send"
  >
    <span v-if="isSentAlready">Reminded</span>
    <span v-else>Remind</span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { USER_DATA } from "@setups/data";
import { $notifyWarning } from "@drVue/common";

import type { Task } from "@drVue/store/pinia/room/tasks";
import type { PropType } from "vue";

export default defineComponent({
  name: "ParticipantUserRemind",
  props: {
    userId: { required: true, type: Number as PropType<number> },
    task: { required: true, type: Object as PropType<Task> },
  },
  emits: ["remind"],
  computed: {
    isCurrentUser(): any {
      return USER_DATA.id === this.userId;
    },
    isSentAlready(): any {
      return !!this.task.users_reminded?.some(
        (us) => us.user_id == this.userId,
      );
    },
  },
  methods: {
    send() {
      if (this.isSentAlready) {
        $notifyWarning(
          "You already sent a reminder to the user for this request today. Try tomorrow.",
        );
        return;
      }
      this.$emit("remind");
    },
  },
});
</script>
