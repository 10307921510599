<template>
  <DrDrawer
    size="large"
    title="Customize summary"
    :shown="isOpen"
    submit-btn="Save"
    @submit="submit"
    @close="handleClose"
    @cancel="handleClose"
    @closed="$emit('close')"
  >
    <DrDynamicFormClassic
      ref="form"
      :entity="model"
      :schema="formSchema"
      :errors="formErrors"
      :disabled="isFormSubmitting"
      @update="model[$event.field as keyof AiReportEditable] = $event.value"
      @submit="submit"
    />

    <div :class="$style.fields">
      <div :class="$style.blockTitle">
        Summary fields
        <AddFieldPopover :report-id="report.id" />
      </div>
      <div :class="$style.blockDescription">
        Add fields from the suggested list or create new ones that specify the
        information you need to extract from the document.
      </div>

      <div>
        <EditReportFieldRow
          :field="field"
          v-for="field in reportFromStore.fields"
          :key="field.id"
          @delete="deleteField(field)"
        />
      </div>
    </div>

    <div>
      <div :class="$style.blockTitle">Suggested fields</div>
      <div :class="$style.blockDescription">
        These fields are suggested based on the summary name you provided.
      </div>

      <SuggestedReportFields :report="reportFromStore" />
    </div>

    <template #controls>
      <ElPopconfirm
        :title="`Are you sure you want to delete &quot;${report.label}&quot;?`"
        :hide-icon="true"
        :width="400"
        :confirm-button-type="'warning'"
        @confirm="deleteReport"
      >
        <template #reference>
          <ElButton type="danger" plain :class="$style.buttonDelete"
            >Delete summary</ElButton
          >
        </template>
      </ElPopconfirm>
    </template>
  </DrDrawer>
</template>

<script lang="ts" setup>
import { ElButton } from "element-plus";
import { type ComponentInstance, computed, reactive, ref } from "vue";
import { DrDrawer } from "@shared/ui/dr-drawer";

import { AiReportsEvent, insightTrack } from "@app/insight";
import { FieldSchemaType } from "@app/vue/components/client-dashboard/dynamic-form/types";
import DrDynamicFormClassic from "@app/vue/shared/ui/dr-dynamic-form/DrDynamicFormClassic.vue";
import { validateString } from "@app/vue/shared/ui/dr-dynamic-form/utils";
import { useFormHelper } from "@app/vue/shared/ui/dr-form";
import { $notifyDanger } from "@drVue/common";
import { pinia } from "@drVue/store/pinia";
import { useAiReportsStore } from "../AiReportsStore";
import AddFieldPopover from "./AddFieldPopover.vue";
import EditReportFieldRow from "./EditReportFieldRow.vue";
import SuggestedReportFields from "./SuggestedReportFields.vue";

import type { AiReport, AiReportEditable, AiReportField } from "../types";
import type { FormSchema } from "@app/vue/shared/ui/dr-dynamic-form/types";

interface Props {
  report: AiReport;
}

interface Emits {
  (event: "close"): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const isOpen = ref(true);
const form = ref<ComponentInstance<typeof DrDynamicFormClassic> | undefined>();

const model = reactive<AiReportEditable>({
  label: props.report.label ?? "",
});

const { formErrors, hookFormSubmitPromise, isFormSubmitting, resetErrors } =
  useFormHelper<typeof model>();

const aiReportsStore = useAiReportsStore(pinia);

const reportFromStore = computed<AiReport>(
  () => aiReportsStore.dict[props.report.id] ?? props.report,
);

const formSchema = computed(
  (): FormSchema => [
    {
      type: FieldSchemaType.Text,
      required: true,
      isReadOnly: isFormSubmitting.value,
      prop: "label",
      label: "Summary name",
      placeholder: "Summary name",
      rules: validateString().required("This field may not be blank"),
    },
  ],
);

const handleClose = () => {
  isOpen.value = false;
};

const deleteReport = () => {
  aiReportsStore.remove(props.report.id).then(
    () => {
      insightTrack(AiReportsEvent.ReportDeleted);

      handleClose();
    },
    () => {
      $notifyDanger("Failed to delete summary");
    },
  );
};

const deleteField = (field: AiReportField) => {
  aiReportsStore.deleteReportField(props.report.id, field.id).then(
    () => {
      insightTrack(AiReportsEvent.FieldDeleted);
    },
    () => {
      $notifyDanger("Failed to delete summary field");
    },
  );
};

const submit = async () => {
  if (isFormSubmitting.value) return;

  resetErrors();

  if (
    Object.keys(model).every(
      (key) =>
        props.report[key as keyof AiReportEditable] ===
        model[key as keyof AiReportEditable],
    )
  ) {
    handleClose();
    return;
  }

  const result = await form.value?.validate().catch((error) => error);
  if (result !== true) return;

  hookFormSubmitPromise(
    aiReportsStore.update(props.report.id, { label: model.label }),
    "Failed to update summary",
  ).then((data) => {
    insightTrack(AiReportsEvent.ReportModified);

    handleClose();
  });
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";
@use "@app/styles/scss/spacing";
@use "@app/styles/scss/typography";

.blockTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: typography.$font-size-title;
  font-weight: 700;
}

.blockDescription {
  margin: 6px 0 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: colors.$pr-600;
}

.noFieldsText {
  color: colors.$pr-500;
}

.fields {
  margin: spacing.$xl 0 spacing.$xxl;
}

.buttonDelete {
  margin-right: auto;
}
</style>
