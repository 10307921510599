import type { TasksRelatedState } from "./TasksRelatedState";
import type {
  DocumentId,
  FolderId,
  TaskId,
} from "@drVue/store/pinia/room/tasks";
import type { Dictionary } from "@drVue/types";
import type { MutationTree } from "vuex";

interface SetStatePayload {
  documentTasks: Dictionary<TaskId[]>;
  folderTasks: Dictionary<TaskId[]>;

  taskDocs: Dictionary<DocumentId[]>;
  taskFolders: Dictionary<FolderId[]>;
}

export const tasksRelatedMutations: MutationTree<TasksRelatedState> = {
  setState(state: TasksRelatedState, payload: SetStatePayload) {
    state.documentTasks = payload.documentTasks;
    state.folderTasks = payload.folderTasks;

    state.taskDocs = payload.taskDocs;
    state.taskFolders = payload.taskFolders;
  },

  setIsReady(state: TasksRelatedState, isReady: boolean) {
    state.isReady = isReady;
  },
};
