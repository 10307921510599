<template>
  <ElInput
    placeholder="Search"
    :model-value="modelValue"
    clearable
    @input="
      (value) => {
        $emit('update:modelValue', value);
      }
    "
  >
    <template #prefix>
      <i class="fa fa-search" />
    </template>
  </ElInput>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import type { PropType } from "vue";

export default defineComponent({
  name: "SearchInput",
  props: {
    modelValue: { required: true, type: String as PropType<string> },
  },
  emits: ["update:modelValue"],
});
</script>
