import fuzzaldrinPlus from "fuzzaldrin-plus";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { DrStore } from "@app/vue";

import type {
  Room,
  RoomGroup,
} from "@app/vue/store/modules/client-dashboard/deals/types";

export type SelectableRoom = Room & {
  _isChecked: boolean;
  _selectedGroup: RoomGroup | undefined;
};
type RoomId = number;

export const useRoomGroupsStore = defineStore("roomGroups", () => {
  const selectedGroups = ref(new Map<RoomId, RoomGroup>());
  const filterText = ref("");

  const rooms = computed(() => {
    const r = DrStore.getters["clientDashboard/deals/getRooms"] as Room[];
    return r.reduce<SelectableRoom[]>((acc, room: Room) => {
      if (room.is_archived || room.groups.length === 0) return acc;
      if (
        filterText.value.trim() !== "" &&
        fuzzaldrinPlus.score(room.title, filterText.value) === 0
      ) {
        return acc;
      }

      acc.push({
        ...room,
        _isChecked: selectedGroups.value.has(room.id),
        _selectedGroup: selectedGroups.value.get(room.id),
      });
      return acc;
    }, []);
  });

  const toggleRoomGroup = (roomId: RoomId, roomGroup: RoomGroup) => {
    if (selectedGroups.value.has(roomId)) selectedGroups.value.delete(roomId);
    else selectedGroups.value.set(roomId, roomGroup);
  };

  const selectRoomGroup = (roomId: RoomId, roomGroup: RoomGroup) => {
    selectedGroups.value.set(roomId, roomGroup);
  };

  const unselectRoomGroup = (roomId: RoomId) => {
    selectedGroups.value.delete(roomId);
  };

  const selectAll = () => {
    rooms.value.forEach((room) => {
      if (selectedGroups.value.has(room.id)) return;
      selectedGroups.value.set(room.id, room.groups[0]);
    });
  };

  const unselectAll = () => {
    rooms.value.forEach((room) => selectedGroups.value.delete(room.id));
  };

  return {
    rooms,
    selectedGroups,
    filterText,
    toggleRoomGroup,
    selectRoomGroup,
    unselectRoomGroup,
    selectAll,
    unselectAll,
  };
});
