<template>
  <div class="truncate" :class="$style.container">
    <DrTruncatedTextTooltip v-if="text" :content="text">
      <span v-html="linkifiedTextHtml" />
    </DrTruncatedTextTooltip>
    <span v-else>-</span>
  </div>
</template>

<script setup lang="ts">
import linkifyStr from "linkify-string";
import { computed } from "vue";
import { DrTruncatedTextTooltip } from "@shared/ui/dr-tooltip";

interface Props {
  value: string | null | undefined;
}

const props = defineProps<Props>();

const text = computed(() => {
  if (!props.value) return "";
  return props.value.toString();
});

const linkifiedTextHtml = computed(() => {
  if (!props.value) return "";

  return linkifyStr(props.value.toString(), {
    attributes: {
      target: "_blank",
    },
  });
});
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";

.container {
  border-radius: 6px;
  transition: all 0.2s ease-out;
  border: 1px solid transparent;
  padding: 2px 6px;
  height: 28px;
  line-height: 22px;
  user-select: none;
  width: 100%;

  &:hover {
    border: 1px solid colors.$sc-400;
  }
}
</style>
