<template>
  <div
    :class="{
      [$style.container]: true,
      [$style.container_isSmallIndent]: smallIndent,
    }"
    @click="edit"
  >
    <span v-if="label" :class="$style.label">{{ label }}</span>
    <div
      :class="{
        [$style.content]: true,
        [$style.content_isEditable]: !isReadOnly,
      }"
    >
      <slot />
    </div>
    <slot name="footer" />
  </div>
</template>

<script setup lang="ts">
interface Props {
  label?: string;
  isReadOnly: boolean;
  smallIndent?: boolean;
}

const props = defineProps<Props>();

interface Emits {
  (e: "edit"): void;
}

const emit = defineEmits<Emits>();

const edit = () => {
  if (props.isReadOnly) {
    return;
  }
  emit("edit");
};
</script>

<style module lang="scss">
@use "@app/styles/scss/colors";
@use "@app/styles/scss/typography";

.container {
  display: flex;
  flex-direction: column;
  user-select: none;
  width: 100%;
}

.container_isSmallIndent {
  margin-bottom: 8px;
}

.content {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  font: typography.$body_regular;
  gap: 6px;
  min-height: 32px;
  margin-left: -6px;
  padding: 2px 6px;
}

.content_isEditable {
  transition: background-color 200ms ease-in-out;
  &:hover {
    background-color: colors.$pr-100;
  }
}

.label {
  color: colors.$pr-600;
  font: typography.$caption_regular;
}
</style>
