import { h } from "vue";

import PermissionGroupCell from "./PermissionGroupCell.vue";

import type { SelectableRoom } from "./store";
import type { DrVxeTableColumn } from "@app/vue/components/types";

export default class TableColumns {
  constructor() {}

  get columns(): DrVxeTableColumn<SelectableRoom>[] {
    return this.getTableColumns();
  }

  getTableColumns(): DrVxeTableColumn<SelectableRoom>[] {
    return [this.checkboxColumn(), this.titleColumn(), this.groupsColumn()];
  }

  private checkboxColumn(): DrVxeTableColumn<SelectableRoom> {
    return {
      field: "_isChecked",
      type: "checkbox",
      title: "",
      fixed: "left",
      resizable: false,
      width: 35,
      showHeaderOverflow: false,
      className: "dr-vxe-column--centered",
      headerClassName: "dr-vxe-column--centered",
      sortable: false,
    };
  }

  private titleColumn(): DrVxeTableColumn<SelectableRoom> {
    return {
      field: "title",
      title: "Room",
      resizable: false,
    };
  }

  private groupsColumn(): DrVxeTableColumn<SelectableRoom> {
    return {
      field: "groups",
      title: "Permission Group",
      resizable: false,
      slots: {
        default: ({ row }) => {
          return h(PermissionGroupCell, { row });
        },
      },
    };
  }
}
