import { defineStore } from "pinia";
import { computed } from "vue";

import { createDictionary } from "@drVue/common";
import { synergiesApi } from "@drVue/store/modules/room/synergies/SynergiesApiService";
import { generateList } from "@drVue/store/pinia/helpers/generators";

import type { CustomDataType } from "@drVue/store/modules/client-dashboard/fields/types";
import type {
  CreateValueDriverPayload,
  PeriodData,
  UpdateValueDriverPayload,
  ValueDriver,
} from "@drVue/store/modules/room/synergies/SynergiesApiService";
import type { TaskUid } from "@drVue/store/pinia/room/tasks";

export const useValueDriversStore = defineStore("value-drivers", () => {
  const {
    list,
    dict,
    load,
    create,
    update,
    remove,
    isLoading,
    isLoadError,
    applyChanges,
  } = generateList<
    ValueDriver,
    CreateValueDriverPayload,
    UpdateValueDriverPayload
  >({
    name: "ValueDrivers",
    load: synergiesApi.loadValueDrivers.bind(synergiesApi),
    create: synergiesApi.createValueDriver.bind(synergiesApi),
    update: synergiesApi.updateValueDriver.bind(synergiesApi),
    remove: synergiesApi.deleteValueDriver.bind(synergiesApi),
  });

  const dictByTaskId = computed(() => {
    return Object.freeze(
      list.value.reduce((dict, drv) => {
        for (const task of drv.tasks) {
          if (!dict[task.task_uid]) dict[task.task_uid] = [];
          dict[task.task_uid].push(drv);
        }

        return dict;
      }, createDictionary<ValueDriver[]>()),
    );
  });

  const dictBySynergyId = computed(() => {
    return Object.freeze(
      list.value.reduce((dict, drv) => {
        if (!dict[drv.synergy_id]) dict[drv.synergy_id] = [];
        dict[drv.synergy_id].push(drv);
        return dict;
      }, createDictionary<ValueDriver[]>()),
    );
  });

  const patch = (
    id: ValueDriver["id"],
    payload: UpdateValueDriverPayload,
  ): Promise<ValueDriver> => {
    return synergiesApi.patchValueDriver(id, payload).then((drv) => {
      applyChanges(drv);
      return drv;
    });
  };

  const patchPeriod = (
    valueDriverId: ValueDriver["id"],
    date: string,
    data: CustomDataType,
  ): Promise<PeriodData> => {
    return synergiesApi
      .patchPeriod(valueDriverId, date, data)
      .then((updatedPeriod) => {
        const drv = list.value.find((s) => s.id === valueDriverId);
        if (!drv || !drv.periods_data) return updatedPeriod;

        const i = drv.periods_data.findIndex(
          (p) => p.start_date.getTime() === updatedPeriod.start_date.getTime(),
        );

        if (i > -1) drv.periods_data.splice(i, 1, updatedPeriod);
        else drv.periods_data.push(updatedPeriod);

        return updatedPeriod;
      });
  };

  const updateValueDriverTasks = (
    valueDriverId: ValueDriver["id"],
    add: TaskUid[],
    remove: TaskUid[],
  ): Promise<ValueDriver> => {
    return synergiesApi
      .updateValueDriverTasks(valueDriverId, add, remove)
      .then((updatedDriver) => {
        applyChanges(updatedDriver, "id");
        return updatedDriver;
      });
  };

  return {
    list,
    dict,
    dictBySynergyId,
    dictByTaskId,
    load,
    create,
    update,
    remove,
    patch,
    patchPeriod,
    updateValueDriverTasks,
  };
});
