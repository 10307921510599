<template>
  <ElDialog
    v-model="isModalVisible"
    title="Move deal"
    width="500px"
    :show-close="true"
    :append-to-body="true"
    :before-close="beforeClose"
    @close="close"
  >
    <div v-if="isModalVisible && phaseInfo">
      <ElForm v-loading="isSubmitting" label-position="top">
        Before you can move <b>{{ deal.title }}</b> to
        <b>{{ phase.name }}</b> please confirm this information
        <ElDivider />
        <ElFormItem>
          <template #label="">
            Actual start date of <b>{{ phase.name }}</b>
          </template>
          <!-- @vue-ignore: v-model -->
          <DrDatePicker
            v-model="phaseInfo.actual_start_date"
            type="date"
            clearable
            style="width: 100%"
          />
        </ElFormItem>

        <ElFormItem class="el-form-item--pull-right">
          <ElButton @click="close">Cancel</ElButton>
          <ElButton type="primary" @click="save"> Save </ElButton>
        </ElFormItem>
      </ElForm>
    </div>
  </ElDialog>
</template>

<script lang="ts">
import { cloneDeep } from "lodash-es";
import { defineComponent } from "vue";
import DrDatePicker from "@shared/ui/dr-datepicker/DrDatepicker.vue";
import DrForm from "@shared/ui/dr-form";

import type { PhaseItem } from "@drVue/api-service/client-dashboard";
import type { DealPatchPayload } from "@drVue/store/modules/client-dashboard/deals/DealsActions";
import type {
  Deal,
  DealPhaseInfo,
} from "@drVue/store/modules/client-dashboard/deals/types";

interface Data {
  phaseInfo: DealPhaseInfo | null;
  isModalVisible: boolean;
}

export default defineComponent({
  components: { DrDatePicker },
  extends: DrForm,
  emits: ["closed"],
  data(): Data {
    return {
      phaseInfo: null,
      isModalVisible: false,
    };
  },
  computed: {
    phase(): PhaseItem {
      return this.$store.getters["clientDashboard/phases/getPhaseById"](
        this.phaseInfo!.phase_id,
      );
    },
    deal(): Deal {
      return this.$store.getters["clientDashboard/deals/getDealById"](
        this.phaseInfo?.deal_id,
      );
    },
  },
  methods: {
    save() {
      const payload: DealPatchPayload = {
        dealId: this.deal.id,
        updates: {
          phase: this.phaseInfo!.phase_id,
          phases_info: {
            [this.phaseInfo!.phase_id]: cloneDeep(this.phaseInfo!),
          },
        },
      };
      this.submitAction(
        "clientDashboard/deals/patchDeal",
        payload,
        "Failed to save deal",
      ).then(() => {
        this.close();
      });
    },
    open(phaseInfo: DealPhaseInfo) {
      if (this.phaseInfo) {
        throw new Error("MoveDealToPhaseDialog: phaseInfo is already set");
      }
      this.phaseInfo = cloneDeep(phaseInfo);
      this.isModalVisible = true;
    },
    close() {
      this.isModalVisible = false;
      this.phaseInfo = null;
    },
    beforeClose(callback: () => void) {
      if (!this.isSubmitting) {
        callback();
      }
    },
  },
});
</script>
