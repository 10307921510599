/** Icon names according to the styleguide (as they are called in Figma) */
export const ICONS_NAMES = {
  "angle-left": "fas fa-angle-left",
  "angle-right": "far fa-angle-right",
  archive: "fas fa-archive",
  arrows: "fas fa-arrows",
  "arrow-down": "fas fa-arrow-down",
  "arrow-left": "fas fa-arrow-left",
  "arrows-h": "fas fa-arrows-h",
  "arrows-v": "fas fa-arrows-v",
  "arrow-to-left": "far fa-arrow-to-left",
  ban: "fas fa-ban",
  bars: "fas fa-bars",
  "tasks-alt": "fas fa-tasks-alt",
  bell: "fas fa-bell",
  bookmark: "fas fa-bookmark",
  "bookmark-o": "fa fa-bookmark-o",
  calendar: "fas fa-calendar",
  "calendar-plus": "fa fa-calendar-plus-o",
  "caret-down": "fas fa-caret-down",
  "caret-right": "fas fa-caret-right",
  "caret-up": "fas fa-caret-up",
  check: "fas fa-check",
  "chevron-left": "fas fa-chevron-left",
  "chevron-right": "fas fa-chevron-right",
  "chevron-double-left": "fas fa-chevron-double-left",
  "chevron-double-right": "fas fa-chevron-double-right",
  clock: "fas fa-clock",
  "clock-o": "fa fa-clock-o",
  clone: "fa fa-clone fa-lg",
  "code-branch": "fas fa-code-branch",
  cogwheel: "fas fa-cog",
  columns: "fas fa-columns",
  comment: "fas fa-comment",
  "comment-exclamation": "fas fa-comment-exclamation",
  copy: "fas fa-copy",
  cross: "fas fa-times",
  cursor: "fas fa-i-cursor",
  "dot-circle": "far fa-dot-circle",
  download: "fas fa-download",
  "ellipsis-h": "far fa-ellipsis-h",
  "ellipsis-hs": "fas fa-ellipsis-h",
  envelope: "fas fa-envelope",
  "exclamation-triangle": "fas fa-exclamation-triangle",
  "external-link": "fas fa-external-link",
  eye: "fas fa-eye",
  file: "fas fa-file",
  filter: "fas fa-filter",
  flag: "fas fa-flag",
  folder: "fas fa-folder",
  "folder-open": "fas fa-folder-open",
  "folder-plus": "fas fa-folder-plus",
  "folder-plus-regular": "far fa-folder-plus",
  "grip-vertical": "fas fa-grip-vertical",
  hand: "fas fa-hand-paper",
  house: "fas fa-home-alt",
  "inbox-out": "fas fa-inbox-out",
  indent: "fas fa-indent",
  "info-circle": "fas fa-info-circle",
  "layer-group": "fas fa-layer-group",
  link: "fas fa-link",
  list: "fas fa-list",
  lock: "fas fa-lock",
  "lock-open": "fal fa-lock-open",
  minus: "fas fa-minus",
  outdent: "fas fa-outdent",
  palette: "fas fa-palette",
  paperclip: "fas fa-paperclip",
  pen: "fas fa-pen",
  pencil: "fas fa-pencil",
  plus: "fas fa-plus",
  "plus-r": "far fa-plus",
  "plus-circle": "fas fa-plus-circle",
  poll: "fas fa-poll",
  print: "fas fa-print",
  question: "fas fa-question",
  redo: "fas fa-redo",
  remove: "fa fa-remove",
  "rotate-right": "fa fa-rotate-right",
  save: "fas fa-save",
  search: "fas fa-search",
  "search-plus": "fas fa-search-plus",
  "sign-in": "fas fa-sign-in",
  "sign-out": "fa fa-sign-out",
  "sign-out-alt": "fas fa-sign-out-alt",
  "sliders-h": "fas fa-sliders-h",
  star: "fas fa-star",
  stream: "far fa-stream",
  tags: "fas fa-tags",
  tasks: "fas fa-tasks",
  "thumbs-up": "fas fa-thumbs-up",
  "thumbs-down": "fas fa-thumbs-down",
  trash: "fas fa-trash",
  "trash-restore": "fas fa-trash-restore",
  undo: "fas fa-undo",
  upload: "fas fa-upload",
  user: "fas fa-user",
  "user-check": "fas fa-user-check",
  "user-plus": "fas fa-user-plus",
  users: "fas fa-user-friends",
  wallet: "fas fa-wallet",
} as const;

export type IconName = keyof typeof ICONS_NAMES;
