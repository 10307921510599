/* tslint:disable:no-magic-numbers */
/* tslint:disable:no-this-assignment */
import { d3 } from "@shared/ui";
import {
  barPath,
  BaseTimelineChart,
  truncateTextNode,
} from "@shared/ui/timeline";

import type { DataViewport, TimelineData } from "@shared/ui/timeline";

const DEAL_TIMELINE = {
  barHeight: 40,
  barCornerRadius: 5,
  logoWidth: 60,
  logoHeight: 30,
  logoOffsetX: 29,
  titleLogoOffset: 10,
  titleTruncateWidth: 120,
  leftIconOffsetX: 5,
  rightIconOffsetX: 5, // offset from right border of deal rect,
  rightIconWidth: 25,
  sidebarDateFmt: d3.timeFormat("%b %y"),
};

interface DealDataViewport extends DataViewport {
  logoShown: boolean;
}

export interface DealData extends TimelineData {
  logo: string;
  title: string;
  color: string;
}

const DEAL_TIMELINE_REQUIRED_WIDTH = {
  logo: {
    withoutRightIcon: DEAL_TIMELINE.logoOffsetX + DEAL_TIMELINE.logoWidth,
    withRightIcon:
      DEAL_TIMELINE.logoOffsetX +
      DEAL_TIMELINE.logoWidth +
      DEAL_TIMELINE.rightIconOffsetX +
      DEAL_TIMELINE.rightIconWidth,
  },
};

const titleTextLength: { [key: string]: number } = {};

function getTitleNodeLength(node: SVGTextElement) {
  const text = node.textContent;
  if (!text) {
    return 0;
  }
  if (titleTextLength[text] === undefined) {
    titleTextLength[text] = node.getComputedTextLength();
  }

  return titleTextLength[text];
}

function titleCachedTruncateNode(node: SVGTextElement, maxWidth: number) {
  let text = node.textContent || "";
  let nodeText = text;
  let textLength = getTitleNodeLength(node);
  while (textLength > maxWidth && text.length > 0) {
    text = text.slice(0, -1);
    nodeText = text + "...";
    if (titleTextLength[nodeText] === undefined) {
      node.textContent = nodeText;
    }
    textLength = getTitleNodeLength(node);
  }
  node.textContent = nodeText;
}

export class TimelineChart extends BaseTimelineChart<DealData> {
  protected updateVisibleViewport() {
    const viewport = this.layout.getVisibleDataArea();
    const viewportData = this.getCurrentViewportData();
    const self = this;
    this.timelineSelection // right icon
      .selectAll<SVGTextElement, DealData>(
        "text.dash-rooms-timeline__d_room_right-icon",
      )
      .each(function (this, deal, index: number, groups: any) {
        const itemViewport = viewportData[deal.id];
        if (!itemViewport.isVisible) {
          return;
        }
        if (itemViewport.rightIconShown) {
          const x = Math.max(
            // on narrow deal keep space for right icon
            DEAL_TIMELINE.logoOffsetX +
              DEAL_TIMELINE.rightIconWidth +
              DEAL_TIMELINE.rightIconOffsetX,
            itemViewport.offsetTo - DEAL_TIMELINE.rightIconOffsetX,
          );
          this.setAttribute("x", x.toString());
          this.setAttribute("visibility", "visible");
        } else {
          this.setAttribute("visibility", "hidden");
        }
      });

    this.timelineSelection // left icon (phase or arrow to left)
      .selectAll<SVGGElement, DealData>(
        "g.dash-rooms-timeline__d_room_left-icon",
      )
      .each(function (this, dealData, index: number, groups: any) {
        const dealViewport = viewportData[dealData.id];
        if (!dealViewport.isVisible) {
          return;
        }
        const icon = dealViewport.offsetFrom === 0 ? "phase" : "arrow";
        const iconWidth = icon === "phase" ? 6 : 16;
        const offsetX = Math.min(
          Math.max((dealViewport.visibleWidth - iconWidth) / 2, 0),
          DEAL_TIMELINE.leftIconOffsetX,
        );
        this.setAttribute(
          "transform",
          `translate(${dealViewport.offsetFrom + offsetX}, 0)`,
        );
        this.setAttribute("icon", icon);
      });
    this.timelineSelection
      .selectAll<
        SVGImageElement,
        DealData
      >("image.dash-rooms-timeline__d_room_logo")
      .each(function (this, dealData, index: number, groups: any) {
        const dealViewport = viewportData[dealData.id] as DealDataViewport;
        if (!dealViewport.isVisible || !dealData.logo) {
          return;
        }
        if (dealViewport.logoShown) {
          this.setAttribute("visibility", "visible");
          this.setAttribute(
            "x",
            (dealViewport.offsetFrom + DEAL_TIMELINE.logoOffsetX).toString(),
          );
        } else {
          this.setAttribute("visibility", "hidden");
        }
      });
    this.timelineSelection
      .selectAll<
        SVGTextElement,
        DealData
      >("text.dash-rooms-timeline__d_room_title")
      .each(function (this, dealData, index: number, groups: any) {
        const dealViewport = viewportData[dealData.id] as DealDataViewport;
        if (!dealViewport.isVisible) {
          return;
        }
        this.textContent = dealData.title;
        const offsetRightVis =
          DEAL_TIMELINE.logoOffsetX +
          (dealViewport.logoShown
            ? DEAL_TIMELINE.logoWidth + DEAL_TIMELINE.titleLogoOffset
            : 0);
        const titleAbsFrom =
          self.dataPositions[dealData.id].fromX +
          dealViewport.offsetFrom +
          offsetRightVis;
        let titleSpace = viewport.XTo - titleAbsFrom;
        if (dealViewport.rightIconShown) {
          // title should not overflow right icon, recalc space
          titleSpace =
            dealViewport.visibleWidth -
            offsetRightVis -
            DEAL_TIMELINE.rightIconWidth +
            DEAL_TIMELINE.rightIconOffsetX;
        }
        let x = dealViewport.offsetFrom + offsetRightVis;
        const textLength = getTitleNodeLength(this);
        if (titleSpace < textLength) {
          // no enought space to show full title
          if (titleSpace >= DEAL_TIMELINE.titleTruncateWidth) {
            // truncate and show at usual place
            titleCachedTruncateNode(this, titleSpace);
          } else {
            // show at the right of bar
            x = -textLength - DEAL_TIMELINE.leftIconOffsetX;
          }
        }
        this.setAttribute("x", x.toString());
      });

    return viewportData;
  }

  protected renderSidebarData() {
    const rows = this.sidebarData
      .append("g")
      .selectAll("g")
      .data(this.data)
      .enter()
      .append("g")
      .attr("class", "dash-rooms-timeline__d_sidebar_room_group")
      .on("click", (deal: DealData) => this.itemClick(deal))
      .attr(
        "transform",
        (deal: DealData) =>
          `translate(${this.config.sidebarPaddingSides}, ${
            this.dataPositions[deal.id].topY
          })`,
      );
    // title
    const titleMaxWidth =
      this.sidebarWidth - this.config.sidebarPaddingSides * 2;
    const firstLineY = 28;
    rows
      .append("text")
      .attr("class", "dash-rooms-timeline__d_sidebar_room_title")
      .attr("y", firstLineY)
      .attr("x", 0)
      .text((deal: DealData) => deal.title)
      .each(function (this: SVGTextElement) {
        truncateTextNode(this, titleMaxWidth);
      });
    // store from date text position
    // so end date node can be alighted
    const secondLineY = 48;
    const fromTextLength: number[] = [];
    rows
      .append("text")
      .attr("class", "dash-rooms-timeline__d_sidebar_room_date")
      .attr("y", secondLineY)
      .attr("x", 0)
      .text((deal: DealData) => DEAL_TIMELINE.sidebarDateFmt(deal.from) + " - ")
      .each(function (this: SVGTextElement) {
        fromTextLength.push(this.getComputedTextLength());
      });
    // date end
    rows
      .append("text")
      .attr("class", (deal: DealData) =>
        deal.to
          ? "dash-rooms-timeline__d_sidebar_room_date"
          : "dash-rooms-timeline__d_sidebar_room_no-date",
      )
      .attr("y", secondLineY)
      .text((deal: DealData) =>
        deal.to ? DEAL_TIMELINE.sidebarDateFmt(deal.to) : "No close date",
      )
      .each((deal: DealData, index: number, groups: any) => {
        const textEl = groups[index];
        textEl.setAttribute("x", (fromTextLength[index] + 3).toString());
      });
  }

  protected renderSidebarHeaderContent() {
    const icon = this.config.showSidebar ? "\uf100" : "\uf101";
    this.sidebarHeader
      .append("text")
      .attr("class", "dash-rooms-timeline__d_sidebar_header-toggle")
      .on("click", () => this.toggleSidebar())
      .attr("x", this.sidebarWidth - 28)
      .attr("y", 23)
      .text(icon);

    if (this.config.showSidebar) {
      this.sidebarHeader
        .append("text")
        .attr("class", "dash-rooms-timeline__d_sidebar_header-text")
        .text("Rooms")
        .attr("x", this.config.sidebarPaddingSides)
        .attr("y", 22);
    }
  }

  protected getCurrentViewportData(): { [key: number]: DealDataViewport } {
    const data = super.getCurrentViewportData() as {
      [key: number]: DealDataViewport;
    };
    this.data.forEach((deal) => {
      const dealViewport = data[deal.id];
      const widthRequireKey = dealViewport.rightIconShown
        ? "withRightIcon"
        : "withoutRightIcon";
      dealViewport.logoShown = !!(
        deal.logo &&
        dealViewport.visibleWidth >=
          DEAL_TIMELINE_REQUIRED_WIDTH.logo[widthRequireKey]
      );
    });

    return data;
  }

  protected renderDataTimeline() {
    // create group for each deal and offset by it's start position in timeline
    const barPos = (deal: DealData) =>
      this.dataPositions[deal.id].topY +
      (this.config.rowHeight - DEAL_TIMELINE.barHeight) / 2;
    const rowsSel = this.rows
      .selectAll("g")
      .data(this.data)
      .enter()
      .append("g")
      .attr("class", "dash-rooms-timeline__d_room_group")
      .on("click", (deal: DealData) => this.itemClick(deal))
      .attr(
        "transform",
        (deal: DealData) =>
          `translate(${this.dataPositions[deal.id].fromX}, ${barPos(deal)})`,
      );
    // data bar background
    rowsSel
      .filter((deal: DealData) => {
        const pos = this.dataPositions[deal.id];
        return pos.toX > pos.fromX;
      })
      .append("path")
      .attr("class", "dash-rooms-timeline__d_room_bar")
      .attr("d", (deal: DealData) => {
        const roundRightCorner = !!deal.to;
        return barPath(
          this.dataPositions[deal.id],
          DEAL_TIMELINE.barHeight,
          DEAL_TIMELINE.barCornerRadius,
          roundRightCorner,
        );
      });
    const iconY = 26;
    // left icon
    const leftIconGroup = rowsSel
      .append("g")
      .attr("class", "dash-rooms-timeline__d_room_left-icon")
      .attr("fill", (deal: DealData) => deal.color);
    leftIconGroup.append("text").attr("y", iconY).text("\uf048");
    leftIconGroup
      .append("rect")
      .attr("y", 5)
      .attr("width", 6)
      .attr("height", DEAL_TIMELINE.barHeight - 10)
      .attr("rx", 2)
      .attr("ry", 2);
    // logo
    rowsSel
      .append("image")
      .attr("class", "dash-rooms-timeline__d_room_logo")
      .attr("y", (DEAL_TIMELINE.barHeight - DEAL_TIMELINE.logoHeight) / 2)
      .attr("width", DEAL_TIMELINE.logoWidth)
      .attr("height", DEAL_TIMELINE.logoHeight)
      .attr("xlink:href", (deal: DealData) => deal.logo);
    // right icon
    rowsSel
      .append("text")
      .attr("class", "dash-rooms-timeline__d_room_right-icon")
      .attr("y", iconY)
      .text((deal: DealData) => (deal.to ? "\uf051" : "\uf534"));
    if (!this.config.showSidebar) {
      // title
      rowsSel
        .append("text")
        .attr("class", "dash-rooms-timeline__d_room_title")
        .attr("y", DEAL_TIMELINE.barHeight / 2 + 5)
        .text((deal: DealData) => deal.title);
    }

    return rowsSel;
  }
}
