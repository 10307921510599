<template>
  <DrDrawer
    size="large"
    fixed-footer
    :title="titleText"
    :submit-btn="submitBtnText"
    :shown="shown"
    :disallow-submit="memberDataNotChanged"
    :is-submitting="isFormSubmitting"
    @submit="handleSubmit"
    @close="handleClose"
    @cancel="handleCancel"
  >
    <template v-if="memberData" #title-prefix>
      <DrAvatar
        size="lg"
        :identifier="memberData!.user.email"
        :name="memberData!.user.name!"
        :url="memberData!.user.avatar?.reduced"
      />
    </template>

    <DrFormWrapper
      :model="memberFormModel"
      :rules="formRules"
      :get-form-ref="getFormRef"
    >
      <ElFormItem label="Email" required prop="email" :error="formErrors.email">
        <ElInput
          v-model="memberFormModel.email"
          placeholder="Provide new member email"
          :disabled="!!memberData"
        />
      </ElFormItem>

      <DrFormBlock title="Pipeline permission set">
        <ElFormItem hide-label prop="group_id" :error="formErrors.group_id">
          <ElSelect
            v-model="memberFormModel.group_id"
            placeholder="Select permission set"
          >
            <ElOption
              v-for="group in orgGroupsList"
              :key="group.id"
              :value="group.id"
              :label="group.name"
            />
          </ElSelect>
        </ElFormItem>
      </DrFormBlock>

      <DrFormBlock title="Reports access">
        <template #description>
          User will be able to see and access your organization's reports.
        </template>

        <ElRadioGroup
          v-model="memberFormModel.looker_access"
          :disabled="disallowLookerAccess"
        >
          <ElRadio :label="OrganizationMemberLookerAccess.Disabled">
            Disabled
          </ElRadio>
          <ElRadio :label="OrganizationMemberLookerAccess.Viewer">
            Viewer
          </ElRadio>
          <ElRadio :label="OrganizationMemberLookerAccess.Editor">
            Editor
          </ElRadio>
        </ElRadioGroup>
      </DrFormBlock>

      <DrFormBlock v-if="memberData" title="Disable pipeline access">
        <ElButton icon-right :class="$style.btnRemove" @click="removeMember">
          Remove from pipeline
          <template #icon>
            <DrIcon name="cross" size="sm" />
          </template>
        </ElButton>
      </DrFormBlock>

      <ElAlert
        v-if="formErrors.non_field_errors"
        type="error"
        :title="formErrors.non_field_errors"
      />
    </DrFormWrapper>
  </DrDrawer>
</template>

<script lang="ts" setup>
import { isEqual } from "lodash-es";
import { computed, reactive, ref, watch } from "vue";
import { DrAvatar } from "@shared/ui/dr-avatar";
import { DrDrawer } from "@shared/ui/dr-drawer";
import { DrFormBlock, DrFormWrapper, useFormHelper } from "@shared/ui/dr-form";
import { DrIcon } from "@shared/ui/dr-icon";

import { OrganizationMemberLookerAccess } from "@setups/enums";
import { pinia } from "@drVue/store/pinia";
import { useOrganizationGroups } from "@drVue/store/pinia/pipeline/org-groups";
import { useOrganizationMembers } from "@drVue/store/pinia/pipeline/org-members";

import type { OrganizationMember } from "@drVue/store/pinia/pipeline/org-members";
import type { FormInstance, FormRules } from "@shared/ui/dr-form";

type LocalOrganizationMember = {
  email: OrganizationMember["user"]["email"];
  group_id: OrganizationMember["group_id"] | undefined;
} & Pick<OrganizationMember, "looker_access">;

interface Props {
  shown: boolean;
  memberId: OrganizationMember["id"] | null;
  groupId?: OrganizationMember["group_id"] | null;
}

const props = withDefaults(defineProps<Props>(), {
  groupId: null,
});

const emit = defineEmits<{
  (event: "update:shown", isShown: boolean): void;
  (event: "removed"): void;
}>();

const orgGroupsStore = useOrganizationGroups(pinia);
const orgMembersStore = useOrganizationMembers(pinia);

const orgGroupsList = computed(() =>
  orgGroupsStore.list.map((group) => ({ id: group.id, name: group.name })),
);

const titleText = computed(() => {
  if (memberData.value) {
    const user = memberData.value.user;
    return user.name || [user.first_name || "", user.last_name || ""].join(" ");
  }
  return "Invite new member";
});
const submitBtnText = computed(() => (props.memberId ? "Save" : "Create"));
const memberData = computed<OrganizationMember | null>(() => {
  if (props.memberId) {
    return orgMembersStore.dict[props.memberId] || null;
  }

  return null;
});

const getLocalMemberData = (
  member?: OrganizationMember | null,
): LocalOrganizationMember => {
  return {
    email: member ? member.user.email : "",
    group_id: member ? member.group_id : undefined,
    looker_access: member
      ? member.looker_access
      : OrganizationMemberLookerAccess.Disabled,
  };
};
const memberFormModel = reactive<LocalOrganizationMember>(getLocalMemberData());
watch(
  () => memberData.value,
  (value) => {
    Object.assign(memberFormModel, getLocalMemberData(value));
  },
);

/** Set group for inviting member. */
watch(
  () => props.shown,
  (shown) => {
    if (shown && !props.memberId && props.groupId) {
      memberFormModel.group_id = props.groupId;
    }
  },
  {
    immediate: true,
    flush: "post",
  },
);

const formRef = ref<FormInstance>();
const getFormRef = (form: FormInstance) => {
  formRef.value = form;
};

const formRules = reactive<FormRules>({
  email: [
    {
      required: true,
      trigger: "blur",
      type: "email",
      message: "New member valid email is required.",
    },
  ],
  group_id: [
    {
      required: true,
      message: "Permission set is required.",
    },
  ],
});

const { formErrors, hookFormSubmitPromise, isFormSubmitting, resetErrors } =
  useFormHelper<LocalOrganizationMember>();

const memberGroupData = computed(() => {
  const { group_id } = memberFormModel;
  if (!group_id) {
    return null;
  }

  return orgGroupsStore.dict[group_id];
});

const isAdminOrgGroup = computed(() => {
  return memberGroupData.value?.is_administrator || false;
});

const disallowLookerAccess = computed(() => {
  return !(
    isAdminOrgGroup.value || memberGroupData.value?.deals_access_type === "all"
  );
});
watch(
  () => disallowLookerAccess.value,
  (value) => {
    if (value) {
      memberFormModel.looker_access = OrganizationMemberLookerAccess.Disabled;
    }
  },
);

const memberDataNotChanged = computed(() =>
  isEqual(memberFormModel, getLocalMemberData(memberData.value)),
);

const handleSubmit = () => {
  if (!formRef.value) return;

  resetErrors();

  formRef.value.validate(async (valid) => {
    if (valid) {
      const payload = {
        group_id: memberFormModel.group_id!,
        looker_access: memberFormModel.looker_access,
      };

      if (memberData.value) {
        await hookFormSubmitPromise(
          orgMembersStore.update(memberData.value.id, payload),
        );
      } else {
        await hookFormSubmitPromise(
          orgMembersStore.create({ email: memberFormModel.email, ...payload }),
        );
        Object.assign(memberFormModel, getLocalMemberData());
      }

      handleClose();
    }
  });
};

const removeMember = () => {
  if (!memberData.value) {
    return;
  }

  hookFormSubmitPromise(orgMembersStore.remove(memberData.value.id)).then(
    () => {
      emit("removed");
      handleClose();
    },
  );
};

const handleCancel = () => {
  Object.assign(memberFormModel, getLocalMemberData(memberData.value));
  handleClose();
};
const handleClose = () => {
  resetErrors();
  emit("update:shown", false);
};
</script>

<style lang="scss" module>
@use "@app/styles/scss/colors";

.btnRemove:global(.el-button) {
  --el-button-text-color: #{colors.$ad-error};
  --el-button-bg-color: #fff;
  --el-button-border-color: #{colors.$ad-error};
  --el-button-outline-color: transparent;
  --el-button-hover-text-color: #fff;
  --el-button-hover-bg-color: #{colors.$ad-error};
  --el-button-hover-border-color: #{colors.$ad-error};
  --el-button-active-text-color: #fff;
  --el-button-active-bg-color: #{colors.$ad-error};
  --el-button-active-border-color: #{colors.$ad-error};
  --el-button-icon-color: #{colors.$ad-error};
  --el-button-icon-hover-color: #fff;
}
</style>
